import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { item } from "examples/Sidenav/styles/sidenavItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Accordians from "./Accordians";
import DashboardLayout from "components/DashboardLayout";
import { APIs } from "Services/APIs";

function RequisitionFulFillment() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [getResponse, setResponse] = useState([]);
  const [approvedQty, setApprovedQty] = useState(0);
  const [givenQty, setGivenQty] = useState("");
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [wareHouseList, setWarehouseList] = useState(
   []
  );
  function getWarehouseData() {


    axios
      .get(
        `${APIs.warehouseServiceApi}/get?recStatus=OPEN`
      )
      .then((response) => {
   
        setWarehouseList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
      getOptionLabel: (option) => option?.vehicle?.name ? option?.name + " (" + option?.vehicle?.name + ")" : option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const ButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent:"flex-end",
    alignItems: "center",
  };



  async function handleSubmit(updateObject, resList) {
    for (let i = 0; i < updateObject?.medrequisitiongendtoList?.length; i++) {
      delete updateObject?.medrequisitiongendtoList?.[i]?.input;
      if (
        updateObject?.medrequisitiongendtoList?.[i]?.quantityrcvd === "" ||
        updateObject?.medrequisitiongendtoList?.[i]?.quantityrcvd === null ||
        typeof updateObject?.medrequisitiongendtoList?.[i]?.quantityrcvd ===
          "undefined"
      ) {
        updateObject.medrequisitiongendtoList[i].quantityrcvd = "0";
      }
      updateObject.medrequisitiongendtoList[i].recstatus = "RECIEVED";
    }
    updateObject.medrequisitiongentxndto.recstatus = "RECIEVED";

    // update-line-items=====
    axios
      .put(
        `${APIs.medrequisitiongenServiceApi}/update`,
        updateObject?.medrequisitiongendtoList
      )

      .then((response) => {
        getDataBywharehouse();
        updateMedTxn(updateObject?.medrequisitiongentxndto, resList);
      })
      .catch((error) => {
        console.log(error);
      });
  }

 async function getMmuDataByWarehouse() {
    

    await axios
      .get(
        `${APIs.mmuCreationService}/get`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);

    await axios
      .get(
        `${APIs.medrequisitiongenServiceApi}/getRequisitionAggregation?recStatus=OPEN&owhid=${wharehouse?.name}`
      )
      .then((response) => {
        response?.data?.data?.medRequisitionAggregationList?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "whid" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
            ],
            rows: [],
          };
          item?.medrequisitiongendtoList?.map((i) => {
            dataObj?.rows?.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response?.data?.data?.medRequisitionAggregationList);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `${APIs.medrequisitiongenServiceApi}/getRequisitionAggregation?recStatus=OPEN&mmucode=${mmucode}`
      )
      .then((response) => {
        response?.data?.data?.medRequisitionAggregationList?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "mmu", accessor: "mmucode" },
              { Header: " type", accessor: "itemtype" },

              { Header: "Requested Qty", accessor: "quantityrqst" },
            ],
            rows: [],
          };
          item?.medrequisitiongendtoList?.map((i) => {
            dataObj?.rows?.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response?.data?.data?.medRequisitionAggregationList);


        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
 

  useEffect(() => { 
    getWarehouseData();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} mt={2} lineHeight={1}>
            <div style={addButtonStyle}>
                <span
                   style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                  Requisition Fulfillment
                  </MDTypography>
                </span>

              
              </div>
              <Grid container spacing={2} mt={1} mb={2}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getDataBywharehouse(newValue);
                        getMmuDataByWarehouse(newValue);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            <div>
              {getResponse?.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Requested Id :
                          </text>
                          {" " + values?.medrequisitiongentxndto?.mrnumber}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Submitted By :
                          </text>
                          {" " + values?.medrequisitiongentxndto?.createdby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>STATUS :</text>
                          {" " + values?.medrequisitiongentxndto?.recstatus}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Date :</text>

                          {"" +
                            new Date(
                              values?.medrequisitiongentxndto?.createddate
                            ).toDateString()}

                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                  <div style={ButtonStyle}>
                  <MDButton
                        circular="true"
                        color="info"
                        size="small"
                        variant="gradient"
                        onClick={() => handleSubmit(values, getResponse)}
                      >
                        Submit
                      </MDButton>
                  
                    </div>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values?.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default RequisitionFulFillment;

