/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    lastName,
    username,
    email,
    password,
    repeatPassword,
   gender,
    phonenumber,
    role,
    departmentvalue,
    designationvalue,
    rolecode
   
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
 [lastName.name]: "",
  [username.name]: "",
  [gender.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
 
 
  [rolecode.name]: "",
  [phonenumber.name]: "",
  // [role.name]: "",
  [designationvalue.name]: "",
  [departmentvalue.name]: "",

};

export default initialValues;