import * as Yup from "yup";
import checkout from "..//schemas/form";

const {
  formField: {
    name,
    testname,
    investigationname,
    range,
 
    uom,
    investigationList
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [testname.name]: Yup.string().required(testname.errorMsg),
    // [range.name]: Yup.string().required(range.errorMsg),
    // [uom.name]: Yup.string().required(uom.errorMsg),
    // [investigationname.name]: Yup.string().required(investigationname.errorMsg),
    // investigationList: Yup.array().min(1, 'Investigation list cannot be empty'),
    // investigationList: Yup.array()
    // .min(1, 'Investigation list cannot be empty')
    // .required('Investigation list is required'),
  }),
];

export default validations;