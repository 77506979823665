import React from 'react'
import '../TopBooked/style.css'
import { Button, Grid } from '@mui/material'
const ButtonA = () => {
  return (
    <div>
       <div className="btn-container" >
     <Grid className="btn-grid">
       <Button >
       <span className="btn-text"> See all tests</span> 
       </Button>
     </Grid>
   </div>
    </div>
  )
}

export default ButtonA
