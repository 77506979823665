import PropTypes from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";

// @mui material components
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField"

import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import axios from "../../../../../../axiosinstance"
import { BarLoader } from "react-spinners";

function CategoryInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const [projectValue, setProjectValue] = useState(null);
  const [projectList, setProjectList] = useState("");

  const [categoryValue, setCategoryValue] = useState(null);
  const [subcategorytValue, setSubcategorytValue] = useState(null);
  const [subDistrictValue, setSubDistrictValue] = useState(null);
  const [specailizationValue, setSpecailizationValue] = useState(null);

  const [categoryList, setCategoryList] = useState("");
  const [specailizationList, setSpecailizationList] = useState("");
  const [subcategoryList, setSubcategoryList] = useState("");
  const [specailizationresponse, setSpecailizationresponse] = useState("");
  // const [profileid, setProfileid] = useState(JSON.parse(localStorage.getItem("mmu-auth")).profileid);
  const [profileid, setProfileid] = useState("789");
  const defaultPropsCategory = {
    options: !categoryList ? [{ name: "Loading...", id: 0 }] : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
 
  const defaultPropssubcategory = {
    options: !subcategoryList ? [{ name: "Loading...", id: 0 }] : subcategoryList,
    getOptionLabel: (option) =>
      option.name ,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  
  const defaultPropsspecailization = {
    options: !specailizationList ? [{ name: "Loading...", id: 0 }] : specailizationList,
    getOptionLabel: (option) =>
      option.name ,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const dataObj = {
    columns: [
      // { Header: "category", accessor: "values.category" },
      // { Header: "Subcategory", accessor: "pincode.state.name" },
      { Header: "Subcategory", accessor: "name" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);
  const removeItem = function (uniqueKey) {
    const objWithIdIndex = tableData.rows.findIndex(
      (obj) => obj.name === uniqueKey
    );
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    values.specailizationresponseDataList = tableData.rows;
   
  };
  const { formField, values, errors, touched } = formData;

  const {

 
    category,
    subcategory,
    specailization
    
  } = formField;
  var { address: addressV, createproject: createprojectV } = values;

  async function getSubCategory(depth,hierarchicalcode) {
    //setting pincode===========
    await axios
      .get(
        `category-service/api/Category/v1/getWithHierarchy?depth=${depth}&hierarchicalcode=${hierarchicalcode}`
      )
      .then((response) => {
        setSubcategoryList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getspecailization(depth, hierarchicalcode) {
    //setting pincode===========
    await axios
      .get(
        `category-service/api/Category/v1/getWithHierarchy?depth=${depth}&hierarchicalcode=${hierarchicalcode}`
      )
      .then((response) => {
        setSpecailizationList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }


  const addData = () => {
   
    console.log("123", specailizationresponse);
console.log("124",specailizationresponse.name)
    const Specailizationname = {
      name: specailizationresponse?.name,
    
        userid:"",
        profileid:profileid,
        resourcecode:"",
        category:specailizationresponse
        
    };
    console.log("129", Specailizationname);
    Specailizationname.action = (
      <span>
        <Link to={{}}>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeItem(specailizationresponse?.name)}
          />
        </Link>
      </span>
    );

    let filter = tableData.rows.filter(
      (a) => a.name == specailizationresponse.name
    );

    if (filter.length < 1) {
      tableData.rows.push(specailizationresponse);

      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      values.specailizationresponseDataList = tableData.rows;
      console.log("value", tableData.rows);
    }
  };
  async function getCategory() {

    await axios
      .get(
        `category-service/api/Category/v1/getWithHierarchy?depth=1`
      )
      .then((response) => {
        setCategoryList(response.data.data.dtoList);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });

  }

  useEffect(() => {
    getCategory();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Category</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsCategory}
                value={categoryValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  console.log("244",newValue)
                  getSubCategory(2,newValue.hierarchicalcode)
                  setCategoryValue(newValue);
                  if (newValue == null) {
                    values.category = "";

                  }
                  else {
                    values.category = newValue.name;

                  }


                }}
                renderInput={(params) => (
                  <FormField
                    {...params}

                    type={category.type}
                    label={category.label}
                    name={category.name}


                    // error={errors.category && touched.category}
                    // success={values.category.length > 0 && !errors.category}


                  />
                )}

              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssubcategory}
                value={subcategorytValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                 
                  setSubcategorytValue(newValue);
                  getspecailization(3,newValue.hierarchicalcode)
                  if (newValue == null) {
                    values.subcategory = "";

                  }
                  else {
                    values.subcategory = newValue.name;

                  }


                }}
                renderInput={(params) => (
                  <FormField
                    {...params}

                    type={subcategory.type}
                    label={subcategory.label}
                    name={subcategory.name}


                    // error={errors.category && touched.category}
                    // success={values.subcategory.length > 0 && !errors.category}


                  />
                )}

              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
              <Autocomplete
                {...defaultPropsspecailization}
                value={specailizationValue}
                onChange={(event, newValue) => {
                  setSpecailizationValue(newValue);
                  setSpecailizationresponse(newValue)
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <FormField
                    {...params}

                    type={specailization.type}
                    label={specailization.label}
                    name={specailization.name}

                   
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>


          </Grid>



          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid>

        </MDBox>
      )}
    </MDBox>
  );
}


// typechecking props for CategoryInfo
CategoryInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CategoryInfo;


