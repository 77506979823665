
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create User

export const createState = createAsyncThunk("createState", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `pincode-service/api/pincode/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getState = createAsyncThunk("getState", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `pincode-service/api/pincode/v1/get?type=STATE`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateState = createAsyncThunk("updateState", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `pincode-service/api/pincode/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteState = createAsyncThunk("deleteState", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `pincode-service/api/pincode/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




