
import React, { useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Grid, Dialog, DialogActions } from '@mui/material';
import TextField from '@mui/material/TextField';
import MDButton from 'components/MDButton';
import { Box } from '@mui/material';
import profileImage from '../../assets/images/consultindia.png';
import DashboardLayout from 'components/DashboardLayout';
import NewAgeantRrgistration from 'app/MIC/agent/newAgentRegistration';
import { Link } from 'react-router-dom';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import PropTypes from "prop-types";

const ProfileCard = ({ data }) => {

    return (
                    <Card>
            <CardMedia
                component="img"
                height="200"
                image={data.image}
                alt="Card Image"
            />
            <CardContent>
                <MDTypography variant="body2" color="textSecondary">
                    <MDBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDTypography variant="h6" color="text">Name</MDTypography>
                        <MDTypography variant="body2" color="textSecondary">{data.name}</MDTypography>
                    </MDBox>
                </MDTypography>
                <Typography variant="body2" color="textSecondary">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <MDTypography variant="h6" color="text">Referral code</MDTypography>
                        <MDTypography variant="body2" color="textSecondary">{data.referralCode}</MDTypography>
                    </Box>
                </Typography>
                <Typography variant="body2" color="textSecondary">
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', textAlign: "left" }}>
                        <MDTypography variant="h6" color="text">Resources added</MDTypography>
                        <MDTypography variant="body2" color="textSecondary">{data.resourcesAdded}</MDTypography>
                    </Box>
                </Typography>
                {data?.path &&
                <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "10px" }} >
                <Link to={data.path}>
                  <MDButton variant="outlined" color="info"> View </MDButton>
                </Link>
              </Box>}
            </CardContent>
            </Card>
        
    );
};
// Typechecking props for the ComplexStatisticsCard
ProfileCard.propTypes = {

    name: PropTypes.string.isRequired,
    referralCode: PropTypes.string.isRequired,
    resourcesAdded: PropTypes.string.isRequired,

};
export default ProfileCard;
