

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { APIs } from "Services/APIs";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { getRole, updateRole } from "../../../Admin/AdminWorkspace/Role Master/service/Role";
import MDButton from "components/MDButton";

const Roleedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedRole, openpopup, setopenpopup,tableDataAction } = props;

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Role Name is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedRole?.name || "",
      description: selectedRole?.description || "",
    },
    validationSchema,
    onSubmit: async(values) => {
      try {
      // Create a copy of selectedRole with updated values
      const updatedBrand = {
        ...selectedRole,
        name: values.name,
        description: values.description,
      };

     
      await axios.put(
        `${APIs.roleService}/update`,
        updatedBrand
        );
       
       
      } catch (error) {
        console.error(error);
      }

    
      setopenpopup(false);
    },
  });

  useEffect(() => {
    // Update the form values when selectedRole changes
    formik.setValues({
      name: selectedRole?.name || "",
      description: selectedRole?.description || "",
    });
  }, [selectedRole]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
       style={{
        backgroundColor: "#039147",
        color: "#fff",
        textAlign: "center",
      }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent >
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>Role Name <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label=<span>Role Description <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
        </DialogContent>
      
        <DialogActions style={{ justifyContent:"right" }}>
        <MDButton onClick={handleClose} size="small" color="light">
            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient" size="small" color="info">
            Update
          </MDButton>
          
        </DialogActions>
      </form>
    </Dialog>
  );
};



export default Roleedit
