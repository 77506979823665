import React from "react";
import "./Scheduledemo.css";
import { Grid, useMediaQuery, Button } from "@mui/material";
import Scheduledemoform from "./Scheduledemoform";
import imag4 from "../../findDoctor/assets/images/For Corporates.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ScheduleaDemo = () => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  const scheduledemodata = [
    {
      logo: imag4,
    },
    {
      logo: imag4,
    },
    {
      logo: imag4,
    },
  ];
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Grid mt={23} className="demomaincontainer">
      {isSmallScreen ? (
        <Slider {...sliderSettings}>
          {scheduledemodata.map((card, index) => (
            <div key={index}>
              <Grid className="demo-card-B">
                <img
                  className="demo-card-imge"
                  src={card.logo}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column-reverse",
                  justifyContent: "space-around",
                }}
              >
                <Grid className="demo-card-a">
                  <div className="schduleform">
                    <Grid item xs={12}>
                      <Grid item xs={12} justifyContent={"flex-start"} mt={0}>
                        <h2 className="besttreatment">Best Treatment </h2>
                      </Grid>
                      <Grid item xs={12} justifyContent={"flex-start"} mt={1}>
                        <h2 className="besttreatment">for</h2>
                      </Grid>
                      <Grid item xs={12} justifyContent={"flex-start"} mt={1}>
                        <h2 className="besttreatment">Best Employees</h2>
                      </Grid>
                      <Grid item container className="button-container" mt={2}>
                        <Button
                          variant="contained"
                          color="primary"
                          className="scheduledemobtn"
                        >
                          Schedule a Demo
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </div>
            </div>
          ))}
        </Slider>
      ) : (
        <Grid item className="demoimage1">
          <div className="schduleform1">
            <Scheduledemoform />
          </div>
        </Grid>
      )}
    </Grid>
  );
};

export default ScheduleaDemo;
// import React from 'react';
// import { Grid } from '@mui/material';
// import Scheduledemoform from './Scheduledemoform';
// import imag4 from "../../assets/images/ezgif.com-loop-count (1).gif";
// import "./Scheduledemo.css"; // Import CSS file for custom styling

// const ScheduleaDemo = () => {
//   return (
//     <div className='demomaincontainer'>
//       <div className="backgroundImageContainer">
//         <img src={imag4} alt="Background" className="backgroundImage" />
//         <Grid className="formContainer">
//           {/* <Scheduledemoform /> */}
//         </Grid>
//       </div>
//     </div>
//   );
// }

// export default ScheduleaDemo;
