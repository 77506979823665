import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
   state,
    project,
    district,
    subDistrict,
  
   
    pincode,
   
   
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [state.name]: Yup.string().required(state.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [subDistrict.name]: Yup.string().required(subDistrict.errorMsg),
     [pincode.name]: Yup.string().required(pincode.errorMsg),
     
   
     [project.name]: Yup.string().required(project.errorMsg),

      
    
    
  }),
  
];

export default validations;
