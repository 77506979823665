import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import { ROLE_CODE } from "privateComponents/codes";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const QualificationEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, selectedUser, openpopup, setopenpopup, handleClose, getData } =
    props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading1, setLoading1] = useState(false);
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object({
    qualificationname: Yup.string().required("Qualification Name is required"),
    qualificationboard: Yup.string().required(
      "Qualification Board is required"
    ),
    grade: Yup.string().required("Grade is required"),
    qualificationmode: Yup.string().required("Qualification Mode is required"),
    qualificationgradetype: Yup.string().required(
      "Qualification Grade Type  is required"
    ),
    startdate: Yup.date().required("Start Date is required"),
    enddate: Yup.date()
      .required("End Date is required")
      .test(
        "is-not-before-start",
        "End Date cannot be before start date",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isSameOrBefore = start <= end;
          return isSameOrBefore;
        }
      )
      .test(
        "is-not-same-as-start",
        "End Date cannot be the same as start date",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isNotSameDate = start.getTime() !== end.getTime();
          return isNotSameDate;
        }
      ),
  });

  const initialValues = {
    qualificationname: selectedUser?.qualificationname || "",
    grade: selectedUser?.qualificationgrade || "",
    qualificationboard: selectedUser?.qualificationboard || "",
    qualificationmode: selectedUser?.qualificationmode || "",
    qualificationgradetype: selectedUser?.qualificationgradetype || "",
    startdate: selectedUser?.startdate || "",
    enddate: selectedUser?.enddate || "",
  };

  const onSubmit = async (values) => {
    setLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,
        qualificationname: values.qualificationname.trim(),
        qualificationboard: values.qualificationboard.trim(),
        qualificationgrade: values.grade.trim(),
        qualificationmode: values.qualificationmode,
        qualificationgradetype: values.qualificationgradetype,
        startdate: values.startdate,
        enddate: values.enddate,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.qualificationServiceApi}/update`, updatedUser);
      handleClose();
      swal(
        "Success!",
        
          "Qualification updated successfully.",
        
      );

      auth.rolecode === ROLE_CODE.admincode ? getData(selectedUser?.profileid) : getData(auth?.profileid);
    
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoading1(false);
    }
  };

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              Qualification Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="qualificationname"
                            value={values.qualificationname}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="qualificationname"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="qualificationmode"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Mode{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="qualificationmode"
                              id="qualificationmode"
                              value={values.qualificationmode}
                              onChange={(e) => {
                                const selectedMode = e.target.value;
                                setFieldValue(
                                  "qualificationmode",
                                  selectedMode
                                );
                              }}
                              error={
                                touched.qualificationmode &&
                                Boolean(errors.qualificationmode)
                              }
                            >
                              <option value=""></option>

                              <option value="Regular">Regular</option>
                              <option value="Open">Open</option>
                            </Select>
                            <ErrorMessage
                              name="qualificationmode"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              Qualification Board
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="qualificationboard"
                            value={values.qualificationboard}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="qualificationboard"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="mode"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Type{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="qualificationgradetype"
                              id="qualificationgradetype"
                              value={values.qualificationgradetype}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue(
                                  "qualificationgradetype",
                                  selectedType
                                );
                              }}
                              error={
                                touched.qualificationgradetype &&
                                Boolean(errors.qualificationgradetype)
                              }
                            >
                              <option value=""></option>

                              <option value="Percentage">Percentage</option>
                              <option value="CGP">CGP</option>
                            </Select>
                            <ErrorMessage
                              name="qualificationgradetype"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              Grade
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="grade"
                            value={values.grade}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="grade"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Start Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="startdate"
                              variant="standard"
                              value={values.startdate}
                              onChange={(e) => {
                                const startdateValue = e.target.value;
                                setFieldValue("startdate", startdateValue);
                              }}
                              error={
                                touched.startdate && Boolean(errors.startdate)
                              }
                            />
                            <ErrorMessage
                              name="startdate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                End Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="enddate"
                              variant="standard"
                              value={values.enddate}
                              onChange={(e) => {
                                const enddateValue = e.target.value;
                                setFieldValue("enddate", enddateValue);
                              }}
                              error={touched.endDate && Boolean(errors.enddate)}
                            />
                            <ErrorMessage
                              name="enddate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loading1 ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default QualificationEdit;
