import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{statecode, shortname, statename},
} = checkout;

const validations = [
  Yup.object().shape({
    [statecode.name]: Yup.string().required(statecode.errorMsg),
    [shortname.name]: Yup.string().required(shortname.errorMsg),
   
    [statename.name]: Yup.string().required(statename.errorMsg),
  }),
];

export default validations;
