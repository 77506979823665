import { Button, Card, Stack, Tooltip } from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { labappCode } from "static/labappCode";
import { deleteProjectServiceData } from "redux/Actions/getProjectServiceData";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import CreateLabprojectpopup from "./CreateLabprojectpopup";
import Editlab from "./Editlab";
import { labListAction } from "redux/Actions/labListAction";


const LabProjectList = () => {
  const { getLabList, AuthReducer, tableActionRow } = useSelector((state) => state);
  const { loading } = useSelector((state) => state.getLabList);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const location = useLocation();
  const [openpopupLabCreate, setopenpopupLabCreate] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  useEffect(() => {
    handleLabProjectList();
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        {/* <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip> */}
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  const modifiedRows = getLabList?.data?.dtoList?.map((row) => {
    const pinCode = row.pinCode || {};
    const address = `${pinCode.village?.name || ""}, ${
      pinCode.subdistrict?.name || ""
    }, ${pinCode.district?.name || ""}, ${pinCode.state?.name || ""} - ${
      pinCode.pincode || ""
    }`;
    return {
      ...row,
      address,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "desc", label: "Description" },
    { id: "code", label: "Code" },
    { id: "projecthirarichalcode", label: "Parent Hierarchy" },
    { id: "address", label: "Address" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        labListAction({
          url: `${APIs.projectServiceApi}/get?type=${
            labappCode?.mappingcode
          }&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
        })
      );
    }
  };

  const handleClear = () => {
    setFilterData({});
    dispatch(
      labListAction({
        url: `${APIs.projectServiceApi}/get?type=${
            labappCode?.mappingcode
        }&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleClose = () => {
    setopenpopup(false);
  };
  const handleClosecreate = () => {
    setopenpopupLabCreate(false);
  };

  const handleLabProjectList = () =>{
    if (filterData?.name || nameFilter) {
      dispatch(labListAction({url: `${APIs.projectServiceApi}/get?type=${labappCode?.mappingcode}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter || filterData.name}`,
        })
      );
    } else {
      dispatch(
        labListAction({
          url: `${APIs.projectServiceApi}/get?type=${
            labappCode?.mappingcode
          }&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  }
  

  const handleOpenEditDialog = (row) => {
    setSelectedUser(row);
    setopenpopup(true);
  };

  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteProjectServiceData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            navigate("/app/admin/lab-project-list");
            handleLabProjectList();
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }
  return (
    <DashboardLayout>
      {loading ? (
        <Card>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card>
          <MDBox px={3} pt={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Lab Project List
              </MDTypography>
              <MDButton
                color="info"
                onClick={() => setopenpopupLabCreate(true)}
              >
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={getLabList?.data?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopup && (
        <Editlab
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Lab Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          passData={handleLabProjectList}
        />
      )}
      {openpopupLabCreate && (
        <CreateLabprojectpopup
        openpopupLabCreate={openpopupLabCreate}
        setopenpopupLabCreate={setopenpopupLabCreate}
          handleClose={handleClosecreate}
          passData={handleLabProjectList}
        />
      )}
    </DashboardLayout>
  );
};
export default LabProjectList;
