import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDAvatar from "components/MDAvatar";
import { useNavigate } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import axios from "../../../../axiosinstance";
import swal from "sweetalert";

import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import { v4 as uuidv4 } from "uuid";
import { useFormik } from "formik";
import * as Yup from "yup";
// Images
import productImage from "assets/images/products/product-11.jpg";
import { MoonLoader } from "react-spinners";

import { CircularProgress, MenuItem, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { element } from "prop-types";
import doctorImage from "assets/images/doctors-default-img.png";
import doctorprofile from "static/doctorprofile";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";
import { capitalizeWords } from "static/methods/capitalizedWords";

function Appointment(props) {
  const validationSchema = Yup.object().shape({
    // Define validation rules for your form fields
    payment: Yup.string().required("Please select payment method"),

    familymember: Yup.string().required("Field is required"),
    selectedCategory: Yup.string().required("Please select a category"),
    selectedVisitCategory: Yup.string().required(
      "Please select a visit category"
    ),
    address: Yup.string().when("selectedVisitCategory", {
      is: "HOME_VISIT", // Adjust this based on your actual value for home visit
      then: Yup.string().required("Please provide an address for home visit"),
      otherwise: Yup.string().notRequired(),
    }),
  });

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  // Use the URLSearchParams API to access the query parameter
  const queryParams = new URLSearchParams(location.search);
  const doctorid = queryParams.get("id");
  const visitId = queryParams.get("visitid");
  const [profileid, setProfileId] = useState(doctorid);

  const [paymentvalue, setPaymentvalue] = useState(null);
  const [loader, setLoader] = useState(true);

  const [doctorfess, setDoctorfees] = useState(props?.date?.doctorfess);
  const [isLoading, setIsLoading] = useState(false);

  const [patientrecpanist, setPatientrecpanist] = useState(
    props?.date?.patientrecpanist
  );
  const [familypatient, setFamilypatient] = useState(null);
  const [data, setData] = useState();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedVisitCategory, setSelectedVisitCategory] = useState("");
  const [userCategories, setUserCategories] = useState([]);
  const [userVisitCategories, setUserVisitCategories] = useState([]);
  let [familydata, setFamilydata] = useState([]);
  const { firstline, secondline, district, city, state, country, postcode } =
    props.date.doctorobject.addresslist[0];
  const address = `${firstline}, ${secondline}, ${district}, ${city}, ${state}, ${country}- ${postcode}`;
  const initialValues = {
    payment: "",
    familymember: auth.rolecode === ROLE_CODE.patientcode ? auth.profileid : "",
    selectedCategory: "",
    selectedVisitCategory: "",
    purposeOfVisit: "",
    address: address ? address : "",
  };
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };
  let docAddress = props?.date?.doctorobject?.addresslist[0];

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const options = ["Pay on Counter", "Online"];
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      // setIsLoading(true);
      // values.familymember = JSON.parse(values.familymember);
      const getData = {
        approvedby: "",
        approveddate: "",
        approver: "string",
        approvertype: "string",
        classname: "string",
        pricelist: doctorfess,
        code: "string",
        createdby: auth.profileid,
        createddate: "",
        doctor: data[0],
        profileid: profileid,
        documentofproof: "string",
        id: null,
        latlong: "string",
        location: "",
        modifiedby: "string",
        modifieddate: "",
        type: "booking",
        ordernumber: "string",
        paymentmethod: values.payment,
        patient: values.selctedfamilymember
          ? values?.selctedfamilymember
          : auth,
        slot: props.date.slotobject,
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "SYNCED",
      };

      if (visitId == "null") {
        createVisit(
          data[0]?.profileid,
          values.selctedfamilymember
            ? values.selctedfamilymember?.profileid
            : auth.profileid,
          getData
        );
      } else {
        updateVisit(
          data[0].profileid,
          data[0].name,
          values.selctedfamilymember
            ? values.selctedfamilymember?.profileid
            : auth.profileid,
          getData
        );
      }
    },
  });

  const bookingHandler = async (getData) => {
    setIsLoading(true);
    await axios
      .post(`${APIs.bookingServiceApi}/create`, getData)
      .then((response) => {
        // createVisit(response?.data?.data?.dto?.doctor?.profileid, values.familymember.profileid )
        const roomid = uuidv4().split("-").slice(0, 3).join("-");
        const roominfoobj = {
          bookingid: response?.data?.data?.dto?.ordernumber,
          doctorid: response?.data?.data?.dto?.doctor?.profileid,
          id: null,
          link: `https://softalk.softwaremathematics.com/join/${roomid}`,
          profileid: auth.profileid,
          recstatus: "OPEN",
          roomid: roomid,
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        // updateOrderNumber(getData?.visit, response?.data?.data?.dto?.ordernumber)
        roomIdGeneration(roominfoobj);
        // ... (other code)
        navigate(`/app/${auth.rolename.toLowerCase()}/detail-page`, {
          state: {
            name: data[0]?.name,
            experience: data[0]?.experience,
            age: data[0]?.age,
            paymentmethod: paymentvalue,
            ordernumber: response?.data?.data?.dto?.ordernumber,
            paymentmethod: response?.data?.data?.dto?.paymentmethod,
            date: props?.date?.date,
            time: props?.date?.time,
            doctorfess: doctorfess,
            pagelabel: props?.pagelabel,
          },
        });
        swal(
          "Success!",
          "Meet with your Doctor on" + " " + props?.date?.time + ".",
          "success"
        );
        setIsLoading(false);
      })
      .catch((error) => {
        // ... (other code)
        console.log(error);
        setIsLoading(false);
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
  };

  // const updateOrderNumber = async ( visitData, ordernumber) => {
  //   console.log("update booking number 200")
  //   visitData = {
  //     ...visitData,
  //     ordernumber:ordernumber,
  //     modifiedby:auth?.profileid
  //   };

  //   await axios.put(`${APIs.visitServiceApi}/update`, visitData)
  //     .then((response) => {
  //       console.log(response, "update visit response.")
  //     })
  //     .catch((error) => {
  //       console.log(error, "Create visit error");
  //     });
  // };

  const createVisit = async (doctorId, patientId, getData) => {
    let body = {
      createdby: auth.profileid,
      doctorid: doctorId,
      mmucode: doctorId,
      // visitcreated: getData.paymentmethod,
      visitcategory: formik.values.selectedVisitCategory,
      category: formik.values.selectedCategory,
      reasonofvisit: formik.values.purposeOfVisit,
      patientservice: formik.values.address,
      paymenttype: "UNPAID",
      patientid: patientId,
      recstatus: "SCHEDULED",
      status: "ACTIVE",
      visittype: "NEW",
      doctorname: "",
      address: docAddress
        ? docAddress?.firstline +
          " " +
          docAddress?.secondline +
          " " +
          docAddress?.city +
          " " +
          docAddress?.state
        : "",
      parentvisitid: "",
      childvisitid: "",
    };

    await axios
      .post(`${APIs.visitServiceApi}/create`, body)
      .then((response) => {
        getData = {
          ...getData,
          visit: response.data.data.dto,
          visitid: response.data.data.dto.visitid,
          recstatus: "SCHEDULED",
        };
        bookingHandler(getData);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
  };

  const updateVisit = async (doctorId, doctorName, patientId, getData) => {
    let body = {
      ...props?.visit,
      recstatus: "SCHEDULED",
      visitcategory: formik.values.selectedVisitCategory,
      category: formik.values.selectedCategory,
      reasonofvisit: formik.values.purposeOfVisit,
      patientservice: formik.values.address,
      modifiedby: auth.profileid,
      doctorid: doctorId,
      mmucode: doctorId,
    };

    await axios
      .put(`${APIs.visitServiceApi}/update`, body)
      .then((response) => {
        getData = {
          ...getData,
          visit: response.data.data.dto,
          visitid: visitId,
          recstatus: "SCHEDULED",
        };
        bookingHandler(getData);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
  };

  async function roomIdGeneration(roominfo) {
    try {
      const response = await axios.post(
        `${APIs.roomIdGenerationServiceApi}/create`,
        roominfo
      );
      return response;
    } catch (error) {
      console.error("API error:", error);
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
      throw error;
    }
  }
  async function getvisit(profileid) {
    await axios
      .get(
        `${APIs.userCatgoryServiceApi}/get?category.type=VISIT&profileid=${profileid}`
      )
      .then((response) => {
        const data = response.data?.data?.dtoList;

        setUserVisitCategories(data);
      })
      .catch((e) => {
        console.log("error", e);
        swal(
          "Oops!",
          e?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
  }
  async function get(profileid) {
    await axios
      .get(`${APIs.summaryServiceApi}/get?profileid=${profileid}`)
      .then((response) => {
        setData(response.data?.data?.dtoList);
        let category = response.data?.data?.dtoList[0]?.usercategories.filter(
          (item) => item.category.type == "CAT"
        );
        setUserCategories(category);
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log("error", e);
        swal(
          "Oops!",
          e?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
    await axios
      .get(`${APIs.loginServiceApi}/get?createdby=${auth.profileid}`)
      .then((response) => {
        const newData = [...response.data?.data?.dtoList, auth];

        if (auth.rolecode === ROLE_CODE.patientcode) {
          setFamilydata(newData);
        } else {
          setFamilydata(response.data?.data?.dtoList);
        }
        setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
        console.log("error", e);
        swal(
          "Oops!",
          e?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
      });
  }

  useEffect(() => {
    get(profileid);
    getvisit(profileid);
  }, [profileid]);

  return (
    <>
      <MDBox>
        {loader && (
          <Grid item xs={12} md={12}>
            <MDBox mt={30} ml={25}>
              <CircularProgress />
            </MDBox>
          </Grid>
        )}
        {!loader && data && (
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} md={6}>
              <MDBox display="flex" alignItems="center">
                <MDBox lineHeight={1}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Date: {props?.date?.date}
                  </MDTypography>
                  <MDTypography variant="h6" fontWeight="medium">
                    Time: {props?.date?.time}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
              <MDTypography variant="h6" fontWeight="medium"></MDTypography>
              <MDBox mt={0.5}></MDBox>
            </Grid>

            <Grid item xs={12} md={6}>
              <MDBox display="flex" alignItems="center">
                <MDBox mr={2}>
                  <MDAvatar size="xxl" src={doctorImage} alt="Gold Glasses" />
                </MDBox>
                <MDBox lineHeight={1} mt={2}>
                  <MDTypography variant="button" color="text">
                    <b>Name: {data[0]?.name}</b>
                  </MDTypography>
                  <MDBox lineHeight={1}>
                    <MDTypography variant="button" color="text">
                      <b>Experience: {data[0]?.experience}</b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="button" color="text">
                      <b>Age: {data[0]?.age}</b>
                    </MDTypography>
                  </MDBox>
                  <MDBox mb={2}>
                    <MDTypography variant="button" color="text">
                      <MDButton variant="gradient" color="warning" size="small">
                        {" "}
                        {doctorfess[0]?.currency} {doctorfess[0]?.value}
                      </MDButton>
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Grid>

            <Grid item xs={12} md={6} sx={{ textAlign: "right" }}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={() => handleGoBack()}
              >
                Change date time
              </MDButton>
              <MDBox mt={0.5}>
                <MDTypography variant="button" color="text">
                  Do you want to change?&nbsp;
                  <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    color="text"
                    fontWeight="regular"
                  >
                    Date and Time click here
                  </MDTypography>
                  .
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <MDBox p={3}>
                <MDBox width="100%" overflow="auto">
                  <Table sx={{ minWidth: "32rem" }}>
                    <MDBox component="thead">
                      <TableRow style={{ backgroundColor: "#039147" }}>
                        <MDBox
                          component="th"
                          width={{ xs: "45%", md: "50%" }}
                          py={1.5}
                          px={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Item
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Qty
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Rate
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Amount
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </MDBox>
                    <TableBody>
                      {/* {response?.map((values) => ( */}
                      <TableRow>
                        <MDBox
                          component="td"
                          textAlign="left"
                          p={1}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {/* {values.name} */}
                            {doctorfess[0]?.name}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {/* {values.age} */}1
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            ₹ {doctorfess[0]?.value}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            ₹ {doctorfess[0]?.value}
                          </MDTypography>
                        </MDBox>
                      </TableRow>

                      {/* ))} */}

                      <TableRow>
                        <MDBox
                          component="td"
                          textAlign="left"
                          p={1}
                          sx={borderBottom}
                        />
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        />
                      </TableRow>
                      <TableRow>
                        <MDBox
                          component="td"
                          textAlign="left"
                          p={1}
                          sx={borderBottom}
                        />
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        />
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="h5">Total:</MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          {/* <MDTypography variant="h5">₹ {calculatePriceWithGST()}</MDTypography> */}
                          <MDTypography variant="h5">
                            ₹ {doctorfess[0]?.value}
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MDBox>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={12}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={3}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    mt={1}
                    // style={{
                    //   marginBottom: "20px",
                    //   display: "flex",
                    //   flexDirection: "column",
                    // }}
                  >
                    {/* <select
                      id="payment"
                      name="payment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.payment}
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "8px",
                        marginBottom: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        boxSizing: "border-box",
                        fontSize: "14px",
                      }}
                    >
                      <option value="" label="Select Payment Method" />
                      {options.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                    {formik.touched.payment && formik.errors.payment && (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "0px",
                        }}
                      >
                        {formik.errors.payment}
                      </div>
                    )} */}
                    <TextField
                      select
                      label="Select Payment Method"
                      name="payment"
                      value={formik.values.payment}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                          paddingRight: "24px",
                        },
                        endAdornment: (
                          <ArrowDropDownIcon
                            style={{
                              position: "absolute",
                              right: "1px",
                              top: "calc(50% - 12px)",
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      SelectProps={{
                        style: {
                          height: "40px",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <MenuItem value="">Select Payment Method</MenuItem>
                      {options?.map((option, index) => (
                        <MenuItem key={index} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.payment && formik.errors.payment && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.payment}
                      </div>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4} mt={1}>
                    {/* <label htmlFor="familymember">Select an option:</label> */}
                    {/* <select
                      id="familymember"
                      name="familymember"
                      onBlur={formik.handleBlur}
                      style={{
                        width: "100%",
                        padding: "10px",
                        marginTop: "8px",
                        marginBottom: "8px",
                        border: "1px solid #ccc",
                        borderRadius: "4px",
                        boxSizing: "border-box",
                        fontSize: "14px",
                      }}
                      onChange={(e) => {
                        formik.handleChange(e);
                        const selectedOption = e.target.value;
                        const selctedFamilymember = familydata?.find(
                          (i) => i?.profileid === selectedOption
                        );
                        formik.setFieldValue(
                          "selctedfamilymember",
                          selctedFamilymember
                        );
                        formik.setFieldValue("familymember", selectedOption);
                      }}
                      value={formik.values.familymember}
                    >
                      {auth.rolecode === "PA001" ? (
                        <option value="" label="Select Family Member" />
                      ) : (
                        <option value="" label="Select Patient" />
                      )}
                      {familydata.map((option, index) => (
                        <option key={option.id} value={option?.profileid}>
                          {option.name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.familymember &&
                      formik.errors.familymember && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.familymember}
                        </div>
                      )} */}
                    <TextField
                      select
                      label={
                        auth.rolecode === "PA001"
                          ? "Select Family Member"
                          : "Select Patient"
                      }
                      name="familymember"
                      value={formik.values.familymember}
                      onChange={(e) => {
                        formik.handleChange(e);
                        const selectedOption = e.target.value;
                        const selctedFamilymember = familydata?.find(
                          (i) => i?.profileid === selectedOption
                        );
                        formik.setFieldValue(
                          "selctedfamilymember",
                          selctedFamilymember
                        );
                        formik.setFieldValue("familymember", selectedOption);
                      }}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                          paddingRight: "24px",
                        },
                        endAdornment: (
                          <ArrowDropDownIcon
                            style={{
                              position: "absolute",
                              right: "1px",
                              top: "calc(50% - 12px)",
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      SelectProps={{
                        style: {
                          height: "40px",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      {auth.rolecode === "PA001" ? (
                        <MenuItem value="">Select Family Member</MenuItem>
                      ) : (
                        <MenuItem value="">Select Patient</MenuItem>
                      )}
                      {familydata?.map((option, index) => (
                        <MenuItem key={option.id} value={option?.profileid}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.familymember &&
                      formik.errors.familymember && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.familymember}
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} md={4} mt={1}>
                    <TextField
                      select
                      label="Select Category"
                      name="selectedCategory"
                      value={formik.values.selectedCategory}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                          paddingRight: "24px",
                        },
                        endAdornment: (
                          <ArrowDropDownIcon
                            style={{
                              position: "absolute",
                              right: "1px",
                              top: "calc(50% - 12px)",
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      SelectProps={{
                        style: {
                          height: "40px",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <MenuItem value="">Select category</MenuItem>
                      {userCategories?.map((userCategory, index) => (
                        <MenuItem
                          key={index}
                          value={userCategory.category.name}
                        >
                          {capitalizeWords(userCategory.category.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.selectedCategory &&
                      formik.errors.selectedCategory && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.selectedCategory}
                        </div>
                      )}
                  </Grid>
                </Grid>
                <Grid container spacing={3} mt={1}>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <TextField
                      select
                      label="Select Visit Category"
                      name="selectedVisitCategory"
                      value={formik.values.selectedVisitCategory}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                        },
                        endAdornment: (
                          <ArrowDropDownIcon
                            style={{
                              position: "absolute",
                              right: "1px",
                              top: "calc(50% - 12px)",
                            }}
                          />
                        ),
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      SelectProps={{
                        style: {
                          height: "40px",
                          backgroundColor: "white",
                        },
                      }}
                    >
                      <MenuItem value="">Select Visit category</MenuItem>
                      {userVisitCategories.map((userVisitCategories, index) => (
                        <MenuItem
                          key={index}
                          value={userVisitCategories.category.value}
                        >
                          {capitalizeWords(userVisitCategories.category.name)}
                        </MenuItem>
                      ))}
                    </TextField>
                    {formik.touched.selectedVisitCategory &&
                      formik.errors.selectedVisitCategory && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          {formik.errors.selectedVisitCategory}
                        </div>
                      )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextField
                      label="Purpose of Visit"
                      name="purposeOfVisit"
                      value={formik.values.purposeOfVisit}
                      onChange={formik.handleChange}
                      multiline
                      rows={1}
                      fullWidth
                      InputProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                      InputLabelProps={{
                        style: {
                          backgroundColor: "white",
                        },
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {formik.values.selectedVisitCategory === "HOME_VISIT" && (
                      <TextField
                        label="Address"
                        name="address"
                        value={formik.values.address}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        fullWidth
                        multiline
                        rows={3}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "4px",
                        }}
                      />
                    )}
                    {formik.touched.address && formik.errors.address && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.address}
                      </div>
                    )}
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <MDButton
                      type="submit"
                      color="success"
                      size="small"
                      disabled={isLoading || !doctorfess[0]}
                    >
                      {isLoading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : (
                        "BOOK NOW"
                      )}
                    </MDButton>
                  </Grid>
                </Grid>
              </form>
            </Grid>
          </Grid>
        )}
      </MDBox>
    </>
  );
}

export default Appointment;
