import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import { FormHelperText } from "@mui/material";
import { MoonLoader } from "react-spinners";
import axios from "../../../../axiosinstance";
import { ROLE_CODE } from "privateComponents/codes";
import swal from "sweetalert"; 

const PatientEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectePatient, openpopup, setopenpopup, getPatient } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    age: Yup.string().required("Age is required"),
    gender: Yup.string().required("Gender is required"),
   
    relation: Yup.string().when([], {
      is: () => auth.rolecode === ROLE_CODE.patientcode,
      then: Yup.string().required("Relation is required"),
      otherwise: Yup.string().notRequired(),
    }),
    
    phoneno: Yup.string()
      .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
      .when("phone", {
        is: (value) => value && value.length > 0,
        then: Yup.string().required("Phone number is required if provided"),
        otherwise: Yup.string().notRequired(),
      }),
  });

  const formik = useFormik({
    initialValues: {
      name: selectePatient?.name || "",
      age: selectePatient?.age || "",
      gender: selectePatient?.sex || "",
      phoneno: selectePatient?.mobileno || "",
      relation: selectePatient?.relation || ""
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const updatedPatient = {
        ...selectePatient,
        name: values.name,
        age: values.age,
        sex: values.gender,
        mobileno: values.phoneno,
        relation: values.relation,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios
        .put(`${APIs.loginServiceApi}/update`, updatedPatient)
        .then((response) => {
          setIsLoading(false);
          swal("Update Successful!", "Patient Updated Successfully.", "success");
          setopenpopup(false);
          getPatient(ROLE_CODE.patientcode);
        })
        .catch((error) => {
          swal("Oops!", error?.response?.data?.aceErrors?.[0]?.errorMessage || "Request failed with status code 404.", "warning");
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    formik.setValues({
      name: selectePatient?.name || "",
      age: selectePatient?.age || "",
      gender: selectePatient?.sex || "",
      phoneno: selectePatient?.mobileno || "",
      relation: selectePatient?.relation || ""
    });
  }, [selectePatient]);

  const handleClose = () => {
    setopenpopup(false);
    formik.setValues({
      name: selectePatient?.name || "",
      age: selectePatient?.age || "",
      gender: selectePatient?.sex || "",
      phoneno: selectePatient?.mobileno || "",
      relation: selectePatient?.relation || ""
    });
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle style={{ backgroundColor: "#039147", color: "#fff", textAlign: "center" }}>
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label={<span>Name<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="gender"
                style={{ marginLeft: "-14px", paddingBottom: "2px", marginTop: "2px" }}
              >
                Select Gender <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup>
              </InputLabel>
              <Select
                native
                fullWidth
                variant="standard"
                style={{ width: "100%" }}
                name="gender"
                id="gender"
                value={formik.values.gender}
                onChange={(e) => formik.setFieldValue("gender", e.target.value)}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
                <option value="Other">Other</option>
              </Select>
              {formik.touched.gender && formik.errors.gender && (
                <FormHelperText style={{ color: "red", fontSize: "12px", marginLeft: "2px" }}>
                  {formik.errors.gender}
                </FormHelperText>
              )}
            </FormControl>
          </MDBox>

          {auth?.rolecode === ROLE_CODE.patientcode && (
            <MDBox mb={2}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="relation"
                  style={{ marginLeft: "-14px", paddingBottom: "2px", marginTop: "2px" }}
                >
                  Relation <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup>
                </InputLabel>
                <Select
                  native
                  fullWidth
                  variant="standard"
                  style={{ width: "100%" }}
                  name="relation"
                  id="relation"
                  value={formik.values.relation}
                  onChange={(e) => formik.setFieldValue("relation", e.target.value)}
                >
                  <option value=""></option>
                  <option value="Mother">Mother</option>
                  <option value="Father">Father</option>
                  <option value="Son">Son</option>
                  <option value="Brother">Brother</option>
                  <option value="Daughter">Daughter</option>
                  <option value="Sister">Sister</option>
                  <option value="Uncle">Uncle</option>
                  <option value="Aunty">Aunty</option>
                  <option value="GrandFather">GrandFather</option>
                  <option value="GrandMother">GrandMother</option>
                </Select>
                {formik.touched.relation && formik.errors.relation && (
                  <FormHelperText style={{ color: "red", fontSize: "12px", marginLeft: "2px" }}>
                    {formik.errors.relation}
                  </FormHelperText>
                )}
              </FormControl>
            </MDBox>
          )}

          <MDBox mb={2}>
            <MDInput
              type="number"
              label={<span>Age<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
              variant="standard"
              fullWidth
              id="age"
              name="age"
              value={formik.values.age}
              onChange={formik.handleChange}
              error={formik.touched.age && Boolean(formik.errors.age)}
              helperText={
                formik.touched.age && formik.errors.age ? (
                  <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                    {formik.errors.age}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="number"
              label="Mobile No"
              variant="standard"
              fullWidth
              id="phoneno"
              name="phoneno"
              value={formik.values.phoneno}
              onChange={formik.handleChange}
              error={formik.touched.phoneno && Boolean(formik.errors.phoneno)}
              helperText={
                formik.touched.phoneno && formik.errors.phoneno ? (
                  <p style={{ fontWeight: "400", color: "red", fontSize: "12px" }}>
                    {formik.errors.phoneno}
                  </p>
                ) : null
              }
            />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} size="small" color="light">
            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient" color="info" size="small">
            {isLoading ? (
              <MoonLoader color="#f2fefa" size={16} />
            ) : (
              "Update"
            )}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default PatientEdit;
