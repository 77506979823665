/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "role-form",
  formField: {
    firstLine: {
      name: "firstLine",
      label: "First Line",
      type: "text",
      errorMsg: "First line is required.",
    },
    secondLine: {
      name: "secondLine",
      label: "Second Line",
      type: "text",
      errorMsg: "Second line is required.",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
      errorMsg: "Country is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",
    },
    subdistrict: {
      name: "subdistrict",
      label: "Sub District",
      type: "text",
      errorMsg: "Sub district is required.",
    },
    cityVillage: {
      name: "cityVillage",
      label: "City/Village",
      type: "text",
      errorMsg: "City/Village is required.",
    },
    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "number",
      errorMsg: "Pincode is required.",
    },
    addresstype: {
      name: "addresstype",
      label: "Address Type",
      type: "text",
      errorMsg: "Address Type is required.",
    },
  },
};

export default form;
