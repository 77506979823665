import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import axios from "../../../axiosinstance";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import {
  deleteLoginServiceData,
  getLoginServiceData,
} from "redux/Actions/getLoginServiceData";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import MDBadgeDot from "components/MDBadgeDot";
import { pincodeAssociationAction } from "redux/Actions/getPincodeAssociationAction";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { labappCode } from "static/labappCode";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import TrackStatus from "components/TrackStatus";
import PreviewIcon from "@mui/icons-material/Preview";
import BookingView from "./Bookingview/BookingView";
import { ROLE_CODE } from "privateComponents/codes";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadReport from "app/pharmacy/uploadReport/UploadReport";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import Footer from "examples/Footer";

const LabTestList = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const {
    tableData,
    AuthReducer,
    tableActionRow,
    getMMUDetails,
    getProjectDetails,
    getProjectTeam, getPincodeAssociation
  } = useSelector((state) => state);
  const { loading } = useSelector((state) => state.tableData);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [openpopupRole, setopenpopupRole] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [roleList, setRoleList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const { department } = useSelector((state) => state.department);
  const { designation } = useSelector((state) => state.designation);
  
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };
  const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);
  const [activeStep, setActiveStep] = useState("");
  const [selecteddata, setSelectedData] = useState({});
  const [openstatustracker, setOpenstatustracker] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const location = useLocation();
  const iconStyle2 = {
    color: "#039147",
    marginRight: "9px",
    cursor: "pointer",
  };
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [openreport, setopenreport] = useState(false);
  const addButtonStyle = {
    display: "flex",

    fontSize: "2em",

    flexDirection: "column",

    marginRight: "10px",
  };

  const nameFilter = new URLSearchParams(location.search).get("name");

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    getUsers();
  }, []);

  const getUsers = () => {
    if (
      auth?.rolecode === ROLE_CODE.labcode ||
      auth?.rolecode === ROLE_CODE.childlabcode
    ) {
      if (nameFilter) {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?mmu.code=${
              getProjectDetails?.details?.mmucreation?.code
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter}`,
          })
        );
      } else {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?mmu.code=${
              getProjectDetails?.details?.mmucreation?.code
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      }
    } else {
      if (nameFilter) {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?createdby=${
              auth?.profileid
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter}`,
          })
        );
      } else {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?createdby=${
              auth?.profileid
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      }
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (item) => {
    return (
      <span>
        <Tooltip title="Lab Booking Information" arrow>
          <PreviewIcon
            fontSize="medium"
            style={iconStyle2}
            // onClick={() => viewPopup(item)}
            onClick={() =>
              navigate(
                `/app/${auth?.rolename.toLowerCase()}/lab-booking-information`,
                { state: { selecteddata: item } }
              )
            }
          />
        </Tooltip>
      </span>
    );
  };
  const actionButtons2 = (item) => {
    return (
      <span>
        <Tooltip title="View Status" arrow>
          <TrackChangesIcon
            fontSize="medium"
            style={iconStyle2}
            onClick={() => statustrack(item)}
          />
        </Tooltip>
      </span>
    );
  };
  const actionButtons3 = (item) => {
    return (
      <span>
        <Tooltip title="Upload Report" arrow>
          <CloudUploadIcon
            fontSize="medium"
            style={iconStyle2}
            onClick={() => UploadDocument(item)}
          />
        </Tooltip>
      </span>
    );
  };
  const steps = [
    { label: "SCHEDULED", date: "2 Feb 2024" },
    { label: "REACHED", date: "3 Feb 2024" },
    { label: "SAMPLE COLLECTED", date: "3 Feb 2024" },
    { label: "REPORT UPLOAD SOON", date: "4 Feb 2024" },
    { label: "REPORT UPLOAD", date: "4 Feb 2024" },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled.";
      case 1:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, and the sample has been collected.";

      case 2:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, the sample has been collected, and the report will be uploaded soon after the test.";
      case 3:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, the sample has been collected, and the report has been uploaded.";
      default:
        return "";
    }
  };
  function statustrack(item) {
    setShowConfirmation(true);

    switch (item.recstatus) {
      case "SCHEDULED":
        setActiveStep(1);
        break;
      case "REACHED":
        setActiveStep(2);
        break;
      case "SAMPLE COLLECTED":
        setActiveStep(3);
        break;
      case "REPORT UPLOAD SOON":
        setActiveStep(4);
        break;
      case "REPORT UPLOAD":
        setActiveStep(5);
        break;
       
      default:
        console.log("Unknown status:", item.recstatus);
    }
  }
  function viewPopup(item) {
    setopenpopup(true);
    setSelectedData(item);
  }

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => ({
    ...row,
    labname: row?.mmu?.name,
    patientname: row?.patient?.name,
    dateandtime: new Date(row?.createddate).toDateString(),
    action: actionButtons(row),
    action2: actionButtons2(row),
    action3: actionButtons3(row),
  }));

  const columns = [
    { id: "labname", label: "Lab Name" },
    { id: "patientname", label: "Patient Name" },
    { id: "ordernumber", label: "Order Id" },
    { id: "action2", label: "Status" },
    { id: "dateandtime", label: "Assigned date & time/slot" },
    { id: "action", label: "Action" },
  ];

  const columns2 = [
    { id: "action3", label: "Upload Report" },
    { id: "patientname", label: "Patient Name" },
    { id: "ordernumber", label: "Order Id" },
    { id: "action2", label: "Status" },
    { id: "dateandtime", label: "Assigned date & time/slot" },
    { id: "action", label: "Action" },
  ];
  function UploadDocument(item) {
    setopenreport(true);
    setSelectedData(item);
  }
  const handleSearch = () => {
    setCurrentPage(1);
    if (
      auth?.rolecode === ROLE_CODE.labcode ||
      auth?.rolecode === ROLE_CODE.childlabcode
    ) {
      if (filterData?.name) {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?mmu.code=${
              getProjectDetails?.details?.mmucreation?.code
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter || filterData.name}`,
          })
        );
      } else {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?mmu.code=${
              getProjectDetails?.details?.mmucreation?.code
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      }
    } else {
      if (filterData?.name) {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?createdby=${
              auth?.profileid
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}&st=${nameFilter || filterData.name}`,
          })
        );
      } else {
        dispatch(
          tableDataAction({
            url: `${APIs.bookingServiceApi}/get?createdby=${
              auth?.profileid
            }&type=labtest&ispageable=true&page=${
              currentPage - 1
            }&size=${perPage}`,
          })
        );
      }
    }
  };

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleClear = () => {
    setFilterData({});

    if (
      auth?.rolecode === ROLE_CODE.labcode ||
      auth?.rolecode === ROLE_CODE.childlabcode
    ) {
      dispatch(
        tableDataAction({
          url: `${APIs.bookingServiceApi}/get?mmu.code=${
            getProjectDetails?.details?.mmucreation?.code
          }&type=labtest&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.bookingServiceApi}/get?createdby=${
            auth?.profileid
          }&type=labtest&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  };

  const handleClose = () => {
    setopenpopup(false);
  };

  const updateTableDataAction = () => {
    if (
      auth?.rolecode === ROLE_CODE.labcode ||
      auth?.rolecode === ROLE_CODE.childlabcode
    ) {
      dispatch(
        tableDataAction({
          url: `${APIs.bookingServiceApi}/get?mmu.code=${
            getProjectDetails?.details?.mmucreation?.code
          }&type=labtest&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.bookingServiceApi}/get?createdby=${
            auth?.profileid
          }&type=labtest&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  };

  return (
    <>
      <MDBox
        mt={
          (isMidScreen && auth?.rolecode === ROLE_CODE.labcode) ||
          auth?.rolecode === ROLE_CODE.childlabcode
            ? 10
            : 0
        }
      >
        <Card
          style={{
            marginLeft:
              auth?.rolecode === ROLE_CODE.labcode ||
              auth?.rolecode === ROLE_CODE.childlabcode
                ? "0px"
                : "22px",
            marginRight:
              auth?.rolecode === ROLE_CODE.labcode ||
              auth?.rolecode === ROLE_CODE.childlabcode
                ? "0px"
                : "22px",
            marginTop:
              auth?.rolecode === ROLE_CODE.labcode ||
              auth?.rolecode === ROLE_CODE.childlabcode
                ? "6%"
                : "2%",
          }}
        >
          <MDBox
            p={3}
            lineHeight={1}
            mt={
              auth?.rolecode === ROLE_CODE.labcode ||
              auth?.rolecode === ROLE_CODE.childlabcode
                ? 0
                : isMidScreen
                ? 23
                : auth.rolecode === ROLE_CODE.collectiveagentcode
                ? 6
                : 20
            }
          >
            <div style={addButtonStyle}>
              {auth?.rolecode === ROLE_CODE.labcode ||
              auth?.rolecode === ROLE_CODE.childlabcode ? (
                <MDTypography variant="h5" fontWeight="medium">
                  Booking List
                </MDTypography>
              ) : (
                <MDTypography variant="h5" fontWeight="medium">
                  My Lab Tests
                </MDTypography>
              )}
              {auth.rolecode === ROLE_CODE.patientcode ? (
                <Link
                  style={{
                    alignSelf: "flex-end",

                    marginRight: "10px",

                    marginTop: "-23px",

                    color: "#344767",
                  }}
                  to={`/app/${auth?.rolename?.toLowerCase()}/lab-test-cart`}
                  onClick={() => {
                    localStorage.removeItem("labtest-code");
                    localStorage.removeItem("selectedLabTests");
                    localStorage.removeItem("selectedLocation");
                    localStorage.removeItem("labformData");
                    localStorage.removeItem("selectedTiming");
                    localStorage.removeItem("selectedPharmacy");
                    localStorage.removeItem("selectedDate");
                    localStorage.removeItem("selectedPerson");
                    localStorage.removeItem("dayNumber");
                    localStorage.removeItem("paymentmethod");
                    localStorage.removeItem("selectedAddressIndex");
                  }}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </MDBox>
          {loading ? (
            <Card>
              <>
                <SkeletonFilterCmp filterArray={filters} />
                <SkeletonTableCmp columns={columns} perPage={perPage} />
              </>
            </Card>
          ) : (
            <>
              <FilterCmp
                filterArray={filters}
                filterData={filterData}
                setFilterData={setFilterData}
                handleSearch={handleSearch}
                handleClear={handleClear}
              />

              <TableCmp
                columns={
                  auth?.rolecode === ROLE_CODE.labcode ||
                  auth?.rolecode === ROLE_CODE.childlabcode
                    ? columns2
                    : columns
                }
                rows={modifiedRows}
                setCurrentPage={setCurrentPage}
                pageCount={tableData?.rowData?.page?.totalPages}
                setPerPage={setPerPage}
                perPage={perPage}
                currentPage={currentPage}
              />
            </>
          )}
        </Card>

        {openreport && (
          <UploadReport
            openreport={openreport}
            setopenreport={setopenreport}
            title={"Upload Report"}
            selecteddata={selecteddata}
            getData={updateTableDataAction}
          />
        )}
        {selecteddata && Object.keys(selecteddata).length > 0 && openpopup && (
          <BookingView
            openpopup={openpopup}
            setopenpopup={setopenpopup}
            title={"Lab Booking Information"}
            selectedData={selecteddata}
          />
        )}
        {showConfirmation && (
          <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
            <TrackStatus
              getStepContent={getStepContent}
              steps={steps}
              activeStep={activeStep}
            />
            <DialogActions>
              <MDButton onClick={handleCloseConfirmation}>Close</MDButton>
            </DialogActions>
          </Dialog>
        )}
      </MDBox>
      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {auth?.rolecode === ROLE_CODE?.patientcode && !isMidScreen ? (
        <MedharvaFooter />
      ) : (
        <> </>
      )}
      {auth?.rolecode === ROLE_CODE?.patientcode && isMidScreen && (
        <FooterNavbar />
      )}
    </>
  );
};
export default LabTestList;
