
import Kidneyfunctiontest from "../assets/images/Toplab/Kidneyfunctiontest.png";
import Pregnencytestwoman from "../assets/images/Toplab/Pregnencytestwoman.png";
import Vitamindtest from "../assets/images/Toplab/Vitamindtest.png";
import bloodtest from "../assets/images/Toplab/bloodtest.png";
import Liverfunctiontest from "../assets/images/Toplab/Liverfunctiontest.png";
// import Kidneyfunctiontest from "../assets/images/Toplab/Kidneyfunctiontest.png";



const Toplabimages = {
    "Kidney Function Tests":Kidneyfunctiontest ,
    "Pregnancy Test":Pregnencytestwoman ,
    "Vitamin D Test":Vitamindtest ,
    "Blood Test": bloodtest,
    "Liver Function Tests":Liverfunctiontest ,
    // "Urology":Urology,
    

};

export default Toplabimages;
