import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { micApprovalListAction } from "redux/Actions/micApprovalListAction";

const initialState = {
  tableData: {},
  loading: false,
  error: null,
};

export const getMicApprovalListSlice = createSlice({
  name: "getMicApprovalList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(micApprovalListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(micApprovalListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.tableData = action.payload;
        state.error = null;
      })
      .addCase(micApprovalListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getMicApprovalListSlice.reducer;