import React, { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar"
import MobileNavbar from "../findDoctor/navbar/mobileNavbar/mobileNavbar"
import MedharvaPlus from "app/findDoctor/medharvaPlus/medharvaPlus";
import WhatUserSay from "app/findDoctor/whatUserSay/whatUserSay";
import EmailSubscribe from "app/findDoctor/emailSubscribe/emailSubscribe";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import MeddicineHome from "./Medicine/MeddicineHome";
import MedicinesButton from "./medicinesButton/medicinesButton";
import MedicineCards from "./MedicalStore/MedicineCards";
import Ourserviceextends from "./Our Service Extends/ourserviceextends";
import Ourservicefinal from "./Our Service Extends/ourservicefinal";

const Medicine = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="Medicines" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <MeddicineHome />
      <MedicinesButton />
      <MedicineCards />
      <Ourserviceextends />
      <Ourservicefinal />
      <MedharvaPlus />
      <WhatUserSay />
      <EmailSubscribe />
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Medicines" /> : <></>}
    </div>
  );
};

export default Medicine;
