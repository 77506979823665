import React from "react";
import Section1 from "./Section1";
import WebsiteLogo from "../../../../../assets/svg/sitelogo.svg";
import DoctorLogo from "../../../../../assets/svg/doctorlogo.svg";
import VideoLogo from "../../../../../assets/svg/videoconsult.svg";
import Labtest from "../../../../../assets/svg/lablogo.svg";
import Medicines from "../../../../../assets/svg/medicinelogo.svg";
import Corporates from "../../../../../assets/svg/corporateslogo.svg";
import Providers from "../../../../../assets/svg/providerslogo.svg";
import { Grid } from "@mui/material";
import "./Section1.css";

const CategoryNavbarInfo = ({pagelabel}) => {
  const data = [
    {
      image: WebsiteLogo,
      service: "Medharva Care",
      text: "Find care near you",
      navigates: "/app/home/medharva-care"
    },
    { image: DoctorLogo, service: "Find Doctors", text: "Find top doctors", navigates: "/app/home/find-doctor/speciality" },
    {
      image: VideoLogo,
      service: "Video Consultation",
      text: "Video Consulting",
      navigates: "/app/home/online-consultation"
    },
    { image: Labtest, service: "Lab Test", text: "Book tests & Checkup", navigates: "/app/home/lab-test" },
    { image: Medicines, service: "Medicines", text: "Top pharamacies", navigates: "/app/home/medicine" },
    { image: Corporates, service: "Corporates", text: "Wellness Plans", navigates: "/app/home/for-corporates" },
    {
      image: Providers,
      service: "Providers",
      text: "Software for Institutions",
      navigates: "/app/home/for-providers"
    },
  ];
  const data2 = ["Contact us", "Data Security", "Help", "Terms of Service"];
  return (
    <div>
      <Grid container className="section1item" mt={1} ml={0.5}>
        {data.map((item, index) => (
          <Section1
            key={index}
            image={item.image}
            service={item.service}
            text={item.text}
            navigates={item.navigates}
            pagelabel={pagelabel}
          />
        ))}
      </Grid>
      <Grid container sx={{ display:"grid", placeItems:"center" }}>
      <hr
        style={{ width: "80%", border: "2px rgba(0, 145, 71, 0.50) solid" }}
      />
      </Grid>
      <Grid container mt={3} xs={12} ml={5}>
        {data2.map((item, index) => (
          <Grid item xs={12} className="categorynavitem2" mb={3}>
            <button className="categorynavitem2text">{item}</button>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default CategoryNavbarInfo;
