import { useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Room } from "./Room";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import "./Room.css";

export const Landing = ({ roomId, setOrderNumber }) => {
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoTrack, setlocalVideoTrack] = useState(null);
  const videoRef = useRef(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
//   const { roomId } = useParams();
  const videoCallbutton = {
    fontSize: "15px",
    color: "#039147",
    boxShadow: "0px 2px 4.3px 0px #00000040",
    padding: "9",
    "&:hover": {
      backgroundColor: "#039147",
      color: "#ffffff",
      boxShadow: "0px 2px 4.3px 0px #00000040",
    },
    "&:focus:not(:hover)": {
      backgroundColor: "#039147",
      color: "#ffffff",
      boxShadow: "0px 2px 4.3px 0px #00000040",
    },
  };

  const [joined, setJoined] = useState(false);

  const getCam = async () => {
    const stream = await window.navigator.mediaDevices.getUserMedia({
      video: true,
      audio: true,
    });
    // MediaStream
    const audioTrack = stream.getAudioTracks()[0];
    const videoTrack = stream.getVideoTracks()[0];
    setLocalAudioTrack(audioTrack);
    setlocalVideoTrack(videoTrack);
    if (!videoRef.current) {
      return;
    }
    videoRef.current.srcObject = new MediaStream([videoTrack]);
    videoRef.current?.play();
    // MediaStream
  };

  useEffect(() => {
    if (videoRef && videoRef.current) {
      getCam();
    }
  }, [videoRef]);

  if (!joined) {
    return (
      <div style={{ overflowX: "hidden" }}>
        {/* <video autoPlay ref={videoRef}></video> */}
        <Grid container mt={3} mb={3} className="videoContainer">
          <Grid item className="videoCallvideo">
            <video autoPlay ref={videoRef} className="videoItemLanding" />
          </Grid>
        </Grid>

        {/* <button onClick={() => {
                setJoined(true);
            }}>Join</button> */}
        <Grid container mb={3} className="videoCallvideo">
          <MDButton
            sx={videoCallbutton}
            onClick={() => {
              setJoined(true);
            }}
          >
            Join
          </MDButton>
        </Grid>
      </div>
    );
  }

  return (
    <Room
      name={auth?.name}
      roomId={roomId}
      setOrderNumber={setOrderNumber}
      localAudioTrack={localAudioTrack}
      localVideoTrack={localVideoTrack}
      setLocalAudioTrack={setLocalAudioTrack}
      setlocalVideoTrack={setlocalVideoTrack}
    />
  );
};
