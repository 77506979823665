import React from 'react'
import AgentProcess from './AgentProcess'
import AgentBenefits from './AgentBenefits'
import {Card, Grid, Box } from "@mui/material";
import AnalyticsCard from 'components/Analytics';
import DashboardLayout from 'components/DashboardLayout';

const Analytics = () => {
  const grapgData = [
    {icon:"", title:"Total Profiles", count:30, path:'/app'},
    {icon:"", title:"Opening", count:20, path:'/app'},
    {icon:"", title:"Closing", count:10, path:'/app'},
    {icon:"", title:"Commission", count:10, path:'/app'},
  ]
  return (
    <DashboardLayout>
    <div style={{marginTop:"70px"}}>
       <Card sx={{padding:"2%", marginTop:"2%"}}>
        <Grid container spacing={3}>
            {grapgData.map((item, index)=>(
             
            <Grid item xs={12} md={6} lg={3} key={index}>
                {/* <Link to={item.path}> */}
                <AnalyticsCard data={item} />
                {/* </Link> */}
            </Grid>
        
            ))}
        </Grid>
      </Card>
        <AgentProcess/>
        <AgentBenefits/>
    </div>
    </DashboardLayout>
    
  )
}

export default Analytics