import Swal from "sweetalert2";

let swalBackground = 'rgba(0, 0, 0, 0.7)';
export const swalMessage = (type, message, timer = 3000)=> {

    switch (type) {
        case 'warning':
        case 'error':
            Swal.fire({
                background: swalBackground,
                position: 'center',
                icon: type,
                title: type,
                text: message,
                showClass: { popup: 'animate__animated animate__rubberBand' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            // playSound('alert');
            break;
        case 'info':
        case 'success':
            Swal.fire({
                background: swalBackground,
                position: 'center',
                icon: type,
                title: type,
                text: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        case 'success-html':
            Swal.fire({
                background: swalBackground,
                position: 'center',
                icon: 'success',
                title: 'Success',
                html: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        case 'toast':
            const Toast = Swal.mixin({
                background: swalBackground,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: timer,
                timerProgressBar: true,
            });
            Toast.fire({
                icon: 'info',
                title: message,
                showClass: { popup: 'animate__animated animate__fadeInDown' },
                hideClass: { popup: 'animate__animated animate__fadeOutUp' },
            });
            break;
        // ......
        default:
            alert(message);
    }
}