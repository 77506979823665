import React from "react";
import { Grid, Card, Button, useMediaQuery } from "@mui/material";
import "./CorpoeatesPremium.css";
import imag4 from "../../findDoctor/assets/images/premiumimg.svg";
import imag5 from "../../findDoctor/assets/images/logopremium.svg";
import imag6 from "../../findDoctor/assets/images/trueimg.svg";
import imag7 from "../../findDoctor/assets/images/subtractleft.svg";
import imag8 from "../../findDoctor/assets/images/subtractright.svg";
import imag9 from "../../findDoctor/assets/images/starpremium.svg";
import imag10 from "../../findDoctor/assets/images/rightcornerpremium.svg";
import imag11 from "../../findDoctor/assets/images/rightcorner1.svg";

const CorpoeatesPremium = () => {
  const isSmallScreen = useMediaQuery("(max-width:880px)");
  const isSmallScreen1 = useMediaQuery("(max-width:1050px)");

  return (
    <div className="premiumcantainer">
      <Grid container mt={6} mb={6} className="premiumcantainer1">
        <Card className="premiumcard">
          <Grid
            container
            spacing={1}
            direction={isSmallScreen ? "column" : "row"}
            justifyContent="center"
          >
            <Grid
              item
              xs={isSmallScreen1 ? 5 : 4}
              mt={isSmallScreen ? 3 : isSmallScreen1 ? 3 : -5}
              container
              direction="column"
              justifyContent="center"
              alignItems={isSmallScreen ? "center" : "flex-start"}
              className="premiumcolumn1"
            >
              <Grid item>
                <img
                  src={imag5}
                  alt="Left Side Image"
                  className="left-side-image"
                />
              </Grid>
              <Grid item>
                <div>
                  <span className="h2medhervapremium">
                    MEDHARVA{" "}
                    <img
                      src={imag9}
                      alt="Inline Image"
                      className="inline-imageM"
                      style={{ marginLeft: "-18px" }}
                    />
                  </span>
                </div>
              </Grid>
              <Grid item>
                <div className="h2medhervapremium1">PREMIUM</div>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                className="inline-image-container"
              >
                <img src={imag7} alt="Inline Image" className="inline-image" />
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                className="benefit-item-container1"
              >
                <img src={imag6} alt="Inline Image" className="inline-imageT" />
                <div className="p1medhervapremium">All Health Benefits</div>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                className="benefit-item-container2"
              >
                <img src={imag6} alt="Inline Image" className="inline-imageT" />
                <div className="p1medhervapremium">Group Insurance Cover</div>
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent={isSmallScreen ? "center" : "flex-start"}
                className="benefit-item-container3"
              >
                <img src={imag6} alt="Inline Image" className="inline-imageT" />
                <div className="p1medhervapremium">More Benefits</div>
              </Grid>
              <Grid
                item
                container
                justifyContent={"flex-end"}
                alignItems={"flex-start"}
                className="inline-image-container1"
              >
                <img src={imag8} alt="Inline Image" className="inline-image" />
              </Grid>
              <Grid
                item
                mt={3}
                alignSelf={"center"}
                className="p2medhervapremium-container"
              >
                <div className="p2medhervapremium">360 HOLISTIC SERVICE</div>
              </Grid>
            </Grid>

            {isSmallScreen ? (
              <Grid container spacing={1} direction={"column-reverse"}>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  className="premiumcolumn2"
                >
                  <img src={imag4} alt="Image 2" className="image2" />
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="center"
                  className="premiumcolumn3"
                >
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    className="corner-images-container"
                  >
                    <img src={imag10} alt="Image 2" className="corner-image" />
                    <img src={imag11} alt="Image 2" className="corner-image" />
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                    className="button-container"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className="knowmorebutton"
                    >
                      Know more
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={isSmallScreen1 ? 3 : 4}
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  className="premiumcolumn2"
                >
                  <img src={imag4} alt="Image 2" className="image2" />
                </Grid>
                <Grid
                  item
                  xs={4}
                  container
                  justifyContent="center"
                  className="premiumcolumn3"
                >
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="flex-start"
                    className="corner-images-container"
                  >
                    <img src={imag10} alt="Image 2" className="corner-image" />
                    <img src={imag11} alt="Image 2" className="corner-image" />
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="flex-end"
                    className="button-container"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className="knowmorebutton"
                    >
                      Know More
                    </Button>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Card>
      </Grid>
    </div>
  );
};

export default CorpoeatesPremium;
