import React, { useEffect, useState } from "react";
import "./prescriptionMedicines.css";
import { Grid } from "@mui/material";
import MedicineShare from "../../findDoctor/assets/images/medicinesshare.jpg";
import { useNavigate } from "react-router-dom";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";

const PrescriptionMedicines = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 900);
  const [checkboxes, setCheckboxes] = useState({
    orderEverything: false,
    mentionSpecificMedicines: false,
  });
  const [textareaValue, setTextareaValue] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes((prevCheckboxes) => ({
      ...prevCheckboxes,
      [checkboxName]: !prevCheckboxes[checkboxName],
    }));
  };

  const handleTextareaChange = (event) => {
    setTextareaValue(event.target.value);
  };

  const handleSubmit = () => {
    console.log("Selected Checkboxes: ", checkboxes);

    if (checkboxes.mentionSpecificMedicines) {
      console.log("Textarea Value: ", textareaValue);
    }
    navigate("/app/pages/users/medicine");
  };

  return (
    <>
      {isSmallScreen ? <></> : <Navbar pagelabel="Medicines" />}
      {isSmallScreen ? <MobileNavbar /> : <></>}
      <div className="prescriptionMedicinesroot">
        <Grid
          item
          ml={isSmallScreen ? undefined : 7}
          pt={23}
          className="prescriptionMedicines"
        >
          {isSmallScreen ? "Prescription" : "Medicines"}
        </Grid>
        <Grid
          item
          xs={11}
          ml={isSmallScreen ? 4 : 7}
          mr={isSmallScreen ? 4 : 7}
          mt={5}
          className="prescriptionContainerparent"
        >
          <Grid
            item
            xs={isSmallScreen ? 12 : 4}
            className="prescriptionMedicinesimgitem"
          >
            <img
              src={MedicineShare}
              alt="medshare logo"
              className="prescriptionMedicinesimg"
            />
          </Grid>
          <Grid
            item
            xs={isSmallScreen ? 12 : 7}
            className="prescriptionContainer"
          >
            {isSmallScreen ? (
              <></>
            ) : (
              <Grid item className="prescriptionMedicines">
                Prescription
              </Grid>
            )}
            <Grid item mt={checkboxes.mentionSpecificMedicines ? 3 : 0}>
              <Grid item mb={2} className="prescriptionMedicinesitem">
                <input
                  type="checkbox"
                  name="Order everything as per prescription"
                  className="prescriptionMedicinesRadio"
                  checked={checkboxes.orderEverything}
                  onChange={() => handleCheckboxChange("orderEverything")}
                />{" "}
                &nbsp; Order everything as per prescription
              </Grid>
              <Grid item className="prescriptionMedicinesitem">
                <input
                  type="checkbox"
                  name="Mention specific medicines"
                  className="prescriptionMedicinesRadio"
                  checked={checkboxes.mentionSpecificMedicines}
                  onChange={() =>
                    handleCheckboxChange("mentionSpecificMedicines")
                  }
                />{" "}
                &nbsp; Mention specific medicines
              </Grid>
              {checkboxes.mentionSpecificMedicines && (
                <Grid item mt={2} ml={6}>
                  <textarea
                    placeholder="Enter specific medicines here..."
                    className="mentionSpecificMedicinesInput"
                    rows={4}
                    value={textareaValue}
                    onChange={handleTextareaChange}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <div className="navprescriptionMedicines">
          <Grid container mt={10} xs={12} mb={isSmallScreen ? 10 : 5}>
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() =>
                    navigate("/app/pages/users/upload-prescription")
                  }
                >
                  Previous
                </button>
              </div>
              <div>
                <button className="next" onClick={handleSubmit}>
                  Next
                </button>
              </div>
            </div>
          </Grid>
        </div>
      </div>
      {isSmallScreen ? <></> : <MedharvaFooter />}
      {isSmallScreen ? <FooterNavbar pagelabel="Medicines" /> : <></>}
    </>
  );
};

export default PrescriptionMedicines;
