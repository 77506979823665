import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./userInformation.css";
import logoimage from "../../assets/images/bruce-mars.jpg";
import PhotoSection from "./photoSection";
import Section1 from "app/findDoctor/navbar/desktopNavbar/Section1";
import Section2 from "app/findDoctor/navbar/desktopNavbar/Section2";
import { useSelector } from "react-redux";
import axios from "../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { ROLE_CODE } from "privateComponents/codes";

const UserInformation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 800);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 1055);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [patientaddress, setPatientAddress] = useState(
    JSON.parse(localStorage.getItem("address"))
  );

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 800);
      setIsMidScreen(window.innerWidth <= 1055);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="userinformationroot">
      <Grid container className="navbarcontainer1">
        <Section1 />
      </Grid>
      <Grid container mt={3}>
        <Section2 />
      </Grid>
      <Formik
        initialValues={{
          name: auth?.name ? auth?.name : "",
          gender: auth?.sex ? auth?.sex : "",
          age: auth?.age ? auth?.age : "",
          bloodgroup: auth?.bloodgroup,
          email: auth?.emailid ? auth?.emailid : "",
          houseno: patientaddress ? patientaddress[0]?.firstline : "",
          colony: patientaddress ? patientaddress[0]?.secondline : "",
          state: patientaddress ? patientaddress[0]?.state : "",
          country: patientaddress ? patientaddress[0]?.country : "",
          city: patientaddress ? patientaddress[0]?.city : "",
          pincode: patientaddress ? patientaddress[0]?.postcode : "",
          language: auth?.language,
          phonenumber: auth?.mobileno ? auth?.mobileno : "",
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Name is required"),
          gender: Yup.string().required("Gender is required"),

          age: Yup.string().required("Age is required"),

          email: Yup.string()
            .email("Invalid email format")
            .required("Email is required"),

          phonenumber: Yup.string()
            .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
            .required("Phone number is required"),
        })}
        onSubmit={async (values, { setSubmitting }) => {
          setIsLoading(true);
          const formData = {
            ...auth,
            sex: values.gender,
            age: values.age,
            language: values.language,
            bloodgroup: values.bloodgroup,
            emailid: values.email,
            name: values.name,
            mobileno: values.phonenumber,
            logincount: "2",
          };

          const addresData = {
            firstline: values.houseno,
            addresstype: "Default",
            secondline: values.colony,
            country: values.country,
            state: values.state,
            city: values.city,
            postcode: values.pincode,
            recstatus: "PRIMARY",
            status: "ACTIVE",
            syncstatus: "synced",
            profileid: auth.profileid,
          };

          let updateAddress;
          if (patientaddress && patientaddress.length > 0) {
            updateAddress = {
              ...patientaddress[0],
              firstline: values.houseno,
              secondline: values.colony,
              country: values.country,
              state: values.state,
              city: values.city,
              postcode: values.pincode,
            };
          } else {
            updateAddress = {
              firstline: values.houseno,
              secondline: values.colony,
              country: values.country,
              state: values.state,
              city: values.city,
              postcode: values.pincode,
            };
          }

          try {
            const response = await axios.put(
              "login-service-mongo/api/loginservice/v2/update",
              formData
            );

            localStorage.setItem(
              "login-auth",
              JSON.stringify(response?.data?.data?.dto)
            );
            localStorage.setItem(
              "profile-Name",
              JSON.stringify(response?.data?.data?.dto?.name)
            );

            dispatch({
              type: "UPDATE_AUTH_DATA",
              payload: response.data.data.dto,
            });

            if (
              localStorage.getItem("address") &&
              localStorage.getItem("address")?.id !== null
            ) {
              const addressResponse = await axios.put(
                "address-service-mongo/api/address/v2/update",
                updateAddress
              );
              localStorage.setItem(
                "address",
                JSON.stringify([addressResponse.data.data.dto])
              );
            } else {
              const newAddressResponse = await axios.post(
                "address-service-mongo/api/address/v2/create",
                addresData
              );
              localStorage.setItem(
                "address",
                JSON.stringify([newAddressResponse.data.data.dto])
              );
            }
            if (auth.rolecode === ROLE_CODE.patientcode) {
              navigate("/app/patient/profile");
            } else {
              navigate("/app/nurse/profile");
            }
            swal({
              icon: "success",
              title: "Good job!",
              content: {
                element: "div",
                attributes: {
                  innerHTML: `
                    <p style="text-align: center;">
                      Your personal information has been updated successfully.
                    </p>
                  `,
                },
              },
            });
            setFormSubmitted(true);
          } catch (error) {
            swal(
              "Oops!",
              error?.response?.data?.aceErrors[0]?.errorMessage,
              "warning"
            );
          } finally {
            setSubmitting(false);
            setIsLoading(false);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container xs={10.5} mt={8} className="userinformationitem">
              <Grid item xs={12} mb={2} mr={3} className="userinformationtitle">
                <div>Profile Photo</div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="userinformationnext2"
                >
                  {loading ? (
                    <>
                      <MoonLoader color="red" size={16} />
                    </>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </Grid>
              <PhotoSection image={logoimage} />
              <button
                type="submit"
                disabled={isSubmitting}
                className="userinformationnext"
              >
                {loading ? (
                  <>
                    <MoonLoader color="red" size={16} />
                  </>
                ) : (
                  "Save Changes"
                )}
              </button>
            </Grid>

            <Grid item xs={12} className="userinformationheader" mt={10}>
              Personal Information
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="name">
                  Name <span style={{ color: "red" }}>*</span>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="name" component="div" />
                    </p>
                  }
                </label>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="gender">
                  Gender <span style={{ color: "red" }}>*</span>
                  <Field
                    as="select"
                    id="gender"
                    name="gender"
                    className="userinformationlabelinput"
                  >
                    <option value="">Select Gender</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Field>
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="gender" component="div" />
                    </p>
                  }
                </label>
              </Grid>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="age">
                  Age <span style={{ color: "red" }}>*</span>
                  <Field
                    type="number"
                    id="age"
                    name="age"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="age" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="bloodgroup">
                  Blood Group
                  <Field
                    as="select"
                    id="bloodgroup"
                    name="bloodgroup"
                    className="userinformationlabelinput"
                  >
                    <option value="">Select Blood Group </option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </Field>
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="bloodgroup" component="div" />
                    </p>
                  }
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12} className="userinformationheader" mt={3}>
              {/* Email Address */}
              Contact
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="email">
                  Email <span style={{ color: "red" }}>*</span>
                  <Field
                    type="email"
                    id="email"
                    disabled="true"
                    name="email"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="email" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="phonenumber">
                  Phone Number <span style={{ color: "red" }}>*</span>
                  <Field
                    // type="number"
                    id="phonenumber"
                    name="phonenumber"
                    className="userinformationlabelinput"
                    onKeyDown={(e) => {
                      if (
                        !/[0-9\b\t]/.test(e.key) &&
                        ![
                          "ArrowLeft",
                          "ArrowRight",
                          "ArrowUp",
                          "ArrowDown",
                          "Delete",
                          "Backspace",
                          "Tab",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="phonenumber" component="div" />
                    </p>
                  }
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12} className="userinformationheader" mt={3}>
              Address
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="houseno">
                  House No./Street Name/Area
                  <Field
                    type="text"
                    id="houseno"
                    name="houseno"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="houseno" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="colony">
                  Colony/Street/Locality
                  <Field
                    type="text"
                    id="colony"
                    name="colony"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="colony" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="city">
                  {" "}
                  City
                  <Field
                    type="text"
                    id="city"
                    name="city"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="city" component="div" />
                    </p>
                  }
                </label>
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="state">
                  State
                  <Field
                    type="text"
                    id="state"
                    name="state"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="state" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="name">
                  Country
                  <Field
                    type="text"
                    id="country"
                    name="country"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="country" component="div" />
                    </p>
                  }
                </label>
              </Grid>

              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="name">
                  {" "}
                  Pincode
                  <Field
                    type="number"
                    id="pincode"
                    name="pincode"
                    className="userinformationlabelinput"
                  />
                  {
                    <p
                      style={{
                        fontWeight: "400",
                        color: "red",
                        fontSize: "12px",
                        textAlign: "left",
                      }}
                    >
                      <ErrorMessage name="pincode" component="div" />
                    </p>
                  }
                </label>
              </Grid>
            </Grid>
            <Grid item xs={12} className="userinformationheader" mt={3}>
              Other Information
            </Grid>
            <Grid container spacing={3} mb={8}>
              <Grid item xs={12} sm={4}>
                <label className="userinformationlabel" htmlFor="language">
                  Language
                  <Field
                    type="text"
                    id="language"
                    name="language"
                    className="userinformationlabelinput"
                  />
                </label>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UserInformation;
