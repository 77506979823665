import React, { useState } from "react";
import { Card } from "@material-ui/core";
import { Button, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { IoCall } from "react-icons/io5";
import { RiShareBoxLine } from "react-icons/ri";
import { RiShareForwardLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { capitalizeWords } from "static/methods/capitalizedWords";
import axios from "../../../axiosinstance";
import { APIs } from "Services/APIs";

const LabQueueCard = ({ data, handleDoctor }) => {
  const { getUserCategory } = useSelector((state) => state);

  const getUserCategoryNameByValue = (value) => {
    for (let i = 0; i < getUserCategory.visitCategory.length; i++) {
      if (getUserCategory.visitCategory[i]?.category?.value === value) {
        return capitalizeWords(getUserCategory.visitCategory[i]?.category.name);
      }
    }
    return "";
  };

  const patientDetails = [
    {
      action: "Name",
      accept: data?.patientobj?.name,
    },

    {
      action: "Age",
      accept: data?.patientobj?.age,
    },
    {
      action: "Patient Id",
      accept: data?.patientobj?.patientid,
    },
    {
      action: "Mobile No",
      accept: data?.patientobj?.mobileno,
    },
    {
      action: "Date",
      accept: new Date(data?.slot?.startdate)
        .toLocaleString("en-US", {
          timeZone: "UTC",
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true,
        })
        .replace(",", ""),
    },

    {
      action: "Payment Type",
      accept: (
        <MDButton
          color={data?.paymentmethod
            == "Pay on Counter" ? "info" : "error"}
          size="small"
        >
          {" "}
          {" " + data?.paymentmethod}
        </MDButton>
      ),
    },

    // {
    //   action: "Category",
    //   accept: " " + data?.visit?.category === null ? "" : data?.visit?.category,
    // },
    // {
    //   action: "Visit Type",
    //   accept:  data?.type
    // },
  ];

  return (
    <>
      <Card
        style={{
          margin: "5px 0",
          padding: "10px",
          boxShadow: "0px 2px 4.3px 0px #00000040",
          borderRadius: "8px",

          // transition: 'transform 0.3s ease-in-out',
        }}
        // onMouseEnter={(e) => {
        //   e.currentTarget.style.transform = 'scale(1.1)';
        // }}
        // onMouseLeave={(e) => {
        //   e.currentTarget.style.transform = 'scale(1)';
        // }}
      >
        <MDBox
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          sx={{
            marginRight: "16px",
            marginLeft: "16px",
          }}
        >
          <MDButton
            variant="outlined"
            size="small"
            color="info"
            onClick={() => handleDoctor(data)} 
          >
          Create Report
          </MDButton>
        </MDBox>
        <Grid mt={2}>
          {patientDetails?.map((item, index) => (
            <Grid
              key={index}
              item
              ml={2}
              xs={12}
              sm={12}
              sx={{
                display: "flex",
                gap: "5%",
              }}
            >
              <Grid item sx={{ wordWrap: "break-word", width: "25%" }}>
                <MDTypography variant="body2" color="dark" fontWeight="medium">
                  {item.action}
                </MDTypography>
              </Grid>
              <Grid item>
                <MDTypography
                  variant="body2"
                  color="dark"
                  fontWeight="medium"
                  style={{ textAlign: "center" }}
                >
                  :
                </MDTypography>
              </Grid>
              <Grid item sx={{ wordWrap: "break-word", width: "50%" }}>
                <MDTypography variant="body2" fontWeight="regular" color="dark">
                  {" "}
                  {item.accept}{" "}
                </MDTypography>
              </Grid>
            </Grid>
          ))}
        </Grid>
       
      </Card>
    </>
  );
};

export default LabQueueCard;
