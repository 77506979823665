import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { updateUser } from "../service/User";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
// import { updateDesignation } from "../service/Designation";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),

  username: Yup.string().required("Username is required"),

  lastname: Yup.string().required("Lastname is required"),
  rolecode: Yup.string().required("rolecode is required"),
  departmentvalue: Yup.string().required("Department is required"),
  designationvalue: Yup.string().required("Designation is required"),

  designationvalue: Yup.string().required("Designation is required"),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email format"),
  phoneno: Yup.string()
    .required("Number is required")
    .matches(/^\d{10}$/, "Phone number must be 10 digits"),
});

const UserEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedUser, openpopup, setopenpopup } = props;
  const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);
  const { department } = useSelector((state) => state.department);

  const [designationList, setDesignationList] = useState([]);

  const onSubmit = (values) => {
    console.log("values", values);
    const updatedUser = {
      ...selectedUser,

      departmentcode: values.department
        ? values.department.code
        : selectedUser?.departmentcode,
      departmentname: values.department
        ? values.department.name
        : selectedUser?.departmentname,
      designationcode: values.designation
        ? values.designation.code
        : selectedUser?.designationcode,
      designationname: values.designation
        ? values.designation.name
        : selectedUser?.designationname,
      emailid: values.email,
      firstname: values.name,
      lastname: values.lastname,
      phoneno: values.phoneno,
      rolecode: values.roleName ? values.rolecode : selectedUser.rolecode,
      username: values.username,
      rolename: values.roleName ? values.roleName : selectedUser.rolename,
    };
    console.log("updateuser", updatedUser);
    dispatch(updateUser(updatedUser));
    setopenpopup(false);
  };

  const initialValues = {
    name: selectedUser?.firstname,
    lastname: selectedUser?.lastname,
    rolecode: selectedUser?.rolecode,
    username: selectedUser?.username,
    departmentvalue: selectedUser?.departmentcode,
    designationvalue: selectedUser?.designationcode,
    email: selectedUser?.emailid,
    phoneno: selectedUser?.phoneno,
  };

  const handleClose = () => {
    setopenpopup(false);
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === selectedUser?.departmentcode
    );
    setDesignationList(result);

    // for (let i = 0; i < designation.length; i++) {
    //   if (
    //     designation[i]?.hierarchicalcode ===
    //     selectedUser?.parenthierarchicalcode
    //   ) {
    //     setSelectDes(designation[i].hierarchicalcode);
    //   }
    // }
  };
  function getDesignationData(code) {

    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getRole());
    const defaultProductList = designation.filter(
      (p) => p.deptcode === selectedUser?.departmentcode
    );
    setDesignationList(defaultProductList);
    // setSelectDes("")

    // for (let i = 0; i < designation.length; i++) {
    //   console.log(designation);
    //   if (
    //     designation[i]?.hierarchicalcode ===
    //     selectedDesignation?.parenthierarchicalcode
    //   ) {

    //     setSelectDes(designation[i].hierarchicalcode);
    //   }
    // }
  }, [selectedUser]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "blue",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent style={{ width: 550, height: 320 }}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Name"
                  variant="standard"
                  fullWidth
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.name && touched.name && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.name}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Lastname"
                  variant="standard"
                  fullWidth
                  id="lastname"
                  name="lastname"
                  value={values.lastname}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.lastname && touched.lastname && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.lastname}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Username"
                  variant="standard"
                  fullWidth
                  id="username"
                  name="username"
                  value={values.username}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.username && touched.username && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.username}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl
                  fullWidth
                  error={!!errors.rolecode && touched.rolecode}
                >
                  <InputLabel
                    htmlFor="rolecode"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Role
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="rolecode"
                    id="rolecode"
                    value={values.rolecode}
                    onChange={(e) => {
                      const rolecode = e.target.value;
                      const selectedRoleObj =
                        role.find((obj) => obj.rolecode === rolecode) || {};

                      // const roleName =
                      //   e.target.options[e.target.selectedIndex].text;
                      setFieldValue("roleName", selectedRoleObj.name);
                      setFieldValue("rolecode", rolecode);
                    }}
                  >
                    <option value=""></option>
                    {role.map((obj) => (
                      <option key={obj.id} value={obj.rolecode}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.rolecode && touched.rolecode && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.rolecode}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmentvalue"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Department
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      const selectedDepartmentObj =
                        department.find(
                          (obj) => obj.code === departmentvalue
                        ) || {};

                      getDesignationData(e.target.value);
                      setFieldValue("department", selectedDepartmentObj);

                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.departmentvalue && touched.departmentvalue && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.departmentvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmentvalue"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={values.designationvalue}
                    onChange={(e) => {
                      const designationvalue = e.target.value;

                      const designationobj =
                        designation.find(
                          (obj) => obj.code === designationvalue
                        ) || {};

                      setFieldValue("designation", designationobj);
                      setFieldValue("designationvalue", designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designationList.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.designationvalue && touched.designationvalue && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.designationvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="email"
                  variant="standard"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.email && touched.email && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.email}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="number"
                  label="Number"
                  variant="standard"
                  fullWidth
                  id="phoneno"
                  name="phoneno"
                  value={values.phoneno}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.phoneno && touched.phoneno && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.phoneno}
                  </FormHelperText>
                )}
              </MDBox>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UserEdit;
