import React from "react";
import Card from "@mui/material/Card";
import { APIs } from "Services/APIs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { Autocomplete, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useSelector } from "react-redux";
import AddressEdit from "app/Doctor/DoctorWorkspace/AddressInformation/new-address/AddressEdit";
import { ROLE_CODE } from "privateComponents/codes";
import NewAddress from "app/Doctor/DoctorWorkspace/AddressInformation/new-address";


function AddressList({ sendDataToAddress }) {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [openpopup, setOpenPopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [err, setErr] = useState("");
const {getMMUDetails} = useSelector(state => state)
  const linkTo =
    ROLE_CODE.labcode === auth.rolecode || ROLE_CODE.childlabcode === auth.rolecode
      ? `/app/lab/new-address`
      : `/app/${auth.rolename?.toLowerCase()}/new-address`;

  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyles = {
    fontSize: "1.5em",
    color: "#039147",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const handleClose = () => {
 
    setOpenPopup(false);
  };

  async function update(object) {
    await axios
      .put(`${APIs.addressServiceApi}/update`, object)
      .then((response) => {
      
        getData(profileId);
      })
      .catch((e) => {
        console.log("error", e);
        getData(profileId);
      });
  }

  const toggler = (checked, request) => {
    
    delete request.action;
    delete request.delete;
    if (checked) {
      request.recstatus = "PRIMARY";

  
    } else {
      request.recstatus = "OPEN";

      
    }
    update(request);
  };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      
      if (willDelete) {
        axios.put(`${APIs.addressServiceApi}/delete`, item).then((response) => {
        
          getData(profileId);
        });
        swal("Your Address record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your Address record is safe!");
      }
    });
  }

  async function onUpdatedata(object) {
    setSelectedUser(object);
    setOpenPopup(true);
  }

  async function getData(id) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "first line", accessor: "firstline" },
        { Header: "second line", accessor: "secondline" },
        { Header: "city", accessor: "city" },
        { Header: "District", accessor: "district" },
        { Header: "Sub District", accessor: "subdistrict" },
        { Header: "State", accessor: "state" },
        { Header: "country", accessor: "country" },
        { Header: "primary add.", accessor: "action" },
        { Header: "action", accessor: "delete" },
      ],
      rows: [],
    };
const api = ROLE_CODE?.labcode === auth?.rolecode || ROLE_CODE?.childlabcode === auth?.rolecode ? `${APIs.addressServiceApi}/get?profileid=${id}&mmucode=${getMMUDetails?.data?.mmucode}`:`${APIs.addressServiceApi}/get?profileid=${id}`;
    await axios
      .get(api)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          sendDataToAddress(response?.data?.data?.dtoList);
          if (item.recstatus == "PRIMARY") {
            // setChecked(true);
            item.action = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch
                    onClick={(e) => toggler(e.target.checked, item)}
                    defaultChecked
                  />
                }
                label=""
              />
            );
          }
          if (item.recstatus == "OPEN") {
            // setChecked(true);
            item.action = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch onClick={(e) => toggler(e.target.checked, item)} />
                }
                label=""
              />
            );
          }
          item.delete = (
            <span>
              <Tooltip title="Edit">
                <Link to={{}}>
                  <FaRegEdit
                    style={iconStyles}
                    onClick={() => {
                      onUpdatedata(item);
                      setOpenPopup(true);
                    }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Delete">
                <Link to={{}}>
                  <MdDelete
                    style={iconStyle}
                    onClick={() => {
                      onDelete(item);
                    }}
                  />
                </Link>
              </Tooltip>
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  function handleAddButton() {
    setPopup(true);
  
  }
  useEffect(() => {
    getData(profileId);
  }, []);
  return (
    <>
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Address List
                  </MDTypography>
                </span>
                <Tooltip title="Add Address">
                  {/* <Link
                    style={{
                      alignSelf: "start",
                      color: "#344767",
                    }}
                    to={linkTo}
                  > */}
                    <MDButton color="info" onClick={handleAddButton}>ADD</MDButton>
                  {/* </Link> */}
                </Tooltip>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
        {openpopup && (
          <AddressEdit
            openpopup={openpopup}
            setopenpopup={setOpenPopup}
            title={"Address Edit"}
            selectedUser={selectedUser}
            handleClose={handleClose}
            getData={getData}
          />
        )}
 
        {popup && (
        <NewAddress
        popup={popup}
        setPopup={setPopup}
        getData={getData}
        />
      )}
      </MDBox>
    </>
  );
}

export default AddressList;
