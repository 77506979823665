
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create role

export const createOtp = createAsyncThunk("createOtp", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `otps-service/api/Otps/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getOtp = createAsyncThunk("getOtp", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `otps-service/api/Otps/v1/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
// export const updateRole = createAsyncThunk("updateRole", async (data, {rejectWithValue}) => {
//   console.log("formData", data);

//   try {
//     const response = await axios.put(
//       `role-service-mongo/api/role/v2/update`,
//       data
     
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });
// export const deleteRole = createAsyncThunk("deleteRole", async (data, {rejectWithValue}) => {
//   console.log("formData", data);

//   try {
//     const response = await axios.put(
//       `role-service-mongo/api/role/v2/delete`,
//       data
     
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });




