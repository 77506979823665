import React from "react";
import "./Scheduleformdemo.css";
import {
  Grid,
  Card,
  TextField,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

const Scheduledemoform = () => {
  const validationSchema = Yup.object({
    Name: Yup.string().required("Name is required"),

    organizationName: Yup.string().required("Organization Name is required"),

    contectNumber: Yup.string().required("Contact Number is required"),

    officialEmailID: Yup.string().required("Official Email ID is required"),

    organizationSize: Yup.number().required("Organization Size is required"),

    interestedIn: Yup.string().required("Interested In is required"),
  });

  const handleRequestCallback = (values, { resetForm }) => {
    console.log(values);
    resetForm();
  };
  return (
    <div>
      <Grid container>
        <Grid item sm={12} md={12} className="gridform1">
          <Card className="formcard">
            <Formik
              initialValues={{
                Name: "",
                organizationName: "",
                contectNumber: "",
                officialEmailID: "",
                organizationSize: "",
                interestedIn: "",
              }}
              onSubmit={handleRequestCallback}
              validationSchema={validationSchema}
            >
              {({ errors, touched }) => (
                <Form>
                  <Grid item xs={12} className="formheadings">
                    <h2 className="headingdemo">Schedule a Demo</h2>
                  </Grid>
                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="Name">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Name"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="text"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.Name && touched.Name && (
                            <div className="error">{errors.Name}</div>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="organizationName">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Organization Name"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="text"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.organizationName &&
                            touched.organizationName && (
                              <div className="error">
                                {errors.organizationName}
                              </div>
                            )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="contectNumber">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Contact Number"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="number"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.contectNumber && touched.contectNumber && (
                            <div className="error">{errors.contectNumber}</div>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="officialEmailID">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Official Email ID"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="email"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.officialEmailID &&
                            touched.officialEmailID && (
                              <div className="error">
                                {errors.officialEmailID}
                              </div>
                            )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="organizationSize">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Organization Size"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="number"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.organizationSize &&
                            touched.organizationSize && (
                              <div className="error">
                                {errors.organizationSize}
                              </div>
                            )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} mt={2} className="forminput1">
                    <Field name="interestedIn">
                      {({ field }) => (
                        <FormControl fullWidth>
                          <TextField
                            {...field}
                            placeholder="Interested In"
                            variant="outlined"
                            className="selecttextfield"
                            sx={{
                              border: "2px solid #EEEEEE",
                              background: "#EEEEEE",
                              borderRadius: "8px",
                            }}
                            color="success"
                            size="small"
                            type="text"
                            InputProps={{
                              classes: {
                                input: "inputplaceholders",
                              },
                            }}
                          />
                          {errors.interestedIn && touched.interestedIn && (
                            <div className="error">{errors.interestedIn}</div>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Grid>

                  <Grid item xs={12} mt={4} className="forminput1">
                    <Button
                      variant="outlined"
                      type="submit"
                      sx={{
                        width: "100%",
                        height: "47px",
                        color: "#FFFFFF",
                        border: "3px solid #039147",
                        background: "#039147",
                        borderRadius: "8px",
                        fontFamily: "Poppins",
                        fontWeight: 500,
                        padding: "10px",
                        borderColor: "#039147",
                        fontSize: "20px",
                        textTransform: "none",
                        "&:hover": {
                          background: "#FFFFFF",
                          color: "#039147",
                          border: "2px solid #039147",
                        },
                      }}
                    >
                      Schedule a demo
                    </Button>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Scheduledemoform;
