
const form = {
  formId: "new-user-form",
  formField: {
    mmu: {
      name: "mmu",
      label: "MMU",
      type: "select",
      errorMsg: "MMU is required.",
    }, 
   
    
    lab: {
      name: "lab",
      label: "LAB Name",
      type: "select",
      errorMsg: "LAB name is required.",
    },
   
   
   
   
  },
};

export default form;
