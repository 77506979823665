import { useState } from "react";

import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import DialogTitle from '@mui/material/DialogTitle';
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import MDButton from "components/MDButton";

const Prescription = (props) => {
    const {tittle,children,openprescrption,setOpenprescrption,data}=props


    console.log("data",data)
    const [Updatename, setUpdatename] = useState("");
 
  
  const [description, setDescription] = useState("");
  const handleClosehistory = () => {
   
    setOpenprescrption(false);
  };
  const handlenewtab = (presid) => {
    // PRd0faf2be-917f-4d70-b752-0e4f6c1ef8de210624044610085
   
    const url = `/app/doctor/prescription-view?id=${presid}`;
    window.open(url, '_blank'); 
  };

  return (
    
 <Dialog open={openprescrption} onClose={handleClosehistory} fullWidth maxWidth="md" >
 <DialogTitle
            style={{
              backgroundColor: "#039147",
              color: "#fff",
              textAlign: "center",
            }}
          >
           {tittle}
          </DialogTitle>

<DialogContent >
    
    <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Grid item container spacing={1} style={{ margin: "2px" }}>
                <Grid item xs={12} sm={11} sx={{display :"grid",placeItems:"center"}}>
                  <MDTypography variant="h6" fontWeight="medium">
                    Patient ID :{data?.patient?.patientid}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>

    <h5 style={{ margin: "1px" }}>#Visit ID: {data?.visit?.visitid}</h5>
   
    {data?.prescriptionlist?.map((preslist, index) => (

<Grid
item
xs={12}
sm={9}
style={{
    border: "4px solid #727375",
    borderRadius: "9px",
    marginTop: "25px",
    marginLeft: "35px",
    cursor:"pointer",

}}
onClick={()=>handlenewtab(preslist?.presid)}
>
<Grid item container spacing={1} style={{ margin: "2px" }}>
        <Grid item xs={12} sm={11} sx={{display :"grid",placeItems:"center"}}>
          <MDTypography variant="h6" fontWeight="medium">
       {preslist?.name}
          </MDTypography>
        </Grid>
      </Grid>

    <Grid item xs={12} sm={11} style={{ margin: "5px" }} >
        <h5>
            Prescription ID : {preslist?.presid}
        </h5>
    </Grid>
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "4px" }}>
  <MDButton
    size="small"
    color="info"
    onClick={() => handlenewtab(preslist?.presid)}
  >
    view prescription
  </MDButton>
</div>

</Grid>


    ))}
   
</DialogContent>
<DialogActions>
    <Button onClick={handleClosehistory}>Cancel</Button>
   
</DialogActions>
</Dialog>
  )
}


export default Prescription;