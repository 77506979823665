import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const diseaseListAction = 
   createAsyncThunk("getDiseaseList", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.createdby){
        url = APIs.diseaseServiceApi +`/get?createdby=${data.createdby}`
    }else{
          url = APIs.diseaseServiceApi +`/get`
      }
        let respData = await getAllUsers(url, "")
        return respData.dtoList;

    } catch (error) {
      return rejectWithValue(error);
    }
  });