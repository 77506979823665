import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const MMUListAction = 
   createAsyncThunk("MMUListAction", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.createdby){
        url = `${APIs.mmuCreationService}/get?createdby=${data.createdby}`
      }else if(data && data.url){
        url = data.url
      }else if(data && data.mappingcode){
        url = `${APIs.mmuCreationService}/getByPageV2?mappingcode=${data.mappingcode}`
      } else {
        url = `${APIs.mmuCreationService}/get`;
      }
        let respData = await getAllUsers(url, "")
        return respData || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });