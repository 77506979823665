import DashboardLayout from 'components/DashboardLayout'
import MDBox from 'components/MDBox'
import React from 'react'
import Card from "@mui/material/Card";
import MDTypography from 'components/MDTypography';
import { Link } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";

const SampleCard = ({title}) => {
  return (  
            <MDBox >
                <Card>
                  <Grid style={{ height: "150px", width: "310px"}}></Grid>
                </Card>
            <MDTypography mt={2} style={{display:"flex",justifyContent:"center",}}>{title}</MDTypography>
            </MDBox>     
  )
  SampleCard.propTypes = {
    title: PropTypes.string.isRequired,
    } 
}

export default SampleCard