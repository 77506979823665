import React from 'react'
import doc1image from "../assets/images/DeepakGanga.png"
import doc2image from "../assets/images/DeepakKrishnamurthy_.png"
import defaultimage from "../assets/images/doctors-default-img.png"



const doctorprofile = (id) => {
 switch(id) {
    case "c838fc5b-fab8-43cc-af4a-f68a2f5968b3" :
        return doc1image;
    case "22a05351-6ba5-49c6-80ec-97792603f7b1" :
        return doc2image  ; 
    default : 
        return defaultimage ;   

 }
}

export default doctorprofile;
