import React from "react";
import { Grid, Card, useMediaQuery, Avatar } from "@mui/material";
import Ourservice1 from "./Ourservice1";
import Ourservice from "./Ourservice";
const Ourservicefinal = () => {
  const isSmallScreen = useMediaQuery("(max-width:900px)");
  return <div>{isSmallScreen ? <Ourservice1 /> : <Ourservice />}</div>;
};

export default Ourservicefinal;
