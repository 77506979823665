import React, { useState } from 'react'
import "./Providerplan.css";
import { Grid, Card, Button, useMediaQuery  } from '@mui/material';
import imag1 from "../../findDoctor/assets/images/corporate logo.png";
import { FaCheck } from "react-icons/fa6";


const Providerplan = () => {
    const isMobile = useMediaQuery('(max-width:880px)');
    const [plan, setPlan] = useState('Yearly');
    const corporatesData = [
      { logo: imag1 }, { logo: imag1 }, { logo: imag1 }, { logo: imag1 }, { logo: imag1 },
    ];
    const handlePlanChange = (e) => {
      setPlan(e.target.value);
    };
  

  return (
    <div className='providerwellnessdiv'>

      <div className="providercontainerwellness">
        <div className="providerheading-container">

          <h2 className='providerswitchplanheading1'>Medharva</h2>

        </div>
        <div className="providerheading-container">

          <h2 className='providerswitchplanheading2'>Provider</h2>

        </div>

        <div className="providerheading-containerpara">

          <p className='providerswitchplanpara1'>{isMobile ? "Top companies use Medharva’s MMS systems to make their inner workings simplified" : "Top companies use Medharva’s software to manage their workings."}</p>

        </div>
        <div className="providerheading-containerlogo">

        {corporatesData.slice(0, isMobile ? 4 : corporatesData.length).map((card, index) => (
            <Grid item xs={12} sm={12} key={index} className='providercorporateslogoimgcontainer'>

              <img src={card.logo} alt="Service Logo" className="providercorporateslogoimg" />


            </Grid>
          ))}

        </div>
        <div className="providerswitches-containerwellness">
          <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" checked={plan === 'Yearly'} onChange={handlePlanChange} />
          <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked={plan === 'Monthly'} onChange={handlePlanChange} />
          <label htmlFor="switchYearly" className='providerwellnessswitchbtn'>Annualy</label>
          <label htmlFor="switchMonthly" className='providerwellnessswitchbtn'>Monthly</label>

          <div className="providerswitch-wrapperwellness">
            <div className="providerswitchwellnessplan">
              <div className='providerwellnessswitchbtn'>Annualy</div>
              <div className='providerwellnessswitchbtn'>Monthly</div>


            </div>
          </div>
        </div>

{/* stater */}

        <div className='providerswitchcontainermain'>
          <div className='providerswitchcontainer1'>
            <div className="providerstarter-container">

              <h2 className='providerstarter-heading1'>Starter</h2>

            </div>
            <div className="providerstarter-container">

              <h2 className='providerstarter-prise1'> {plan === 'Monthly' ? '$100/' : '$80/'}<span className='providerstarter-prise1span'>mo</span></h2>

            </div>
            <div className="providerstarter-containerpara">

              <p className='providerswitchplanpara'>Billed Annualy</p>

            </div>
            <div className="providerstarter-containerpara2">

              <p className='providerswitchplanpara2'>Let’s get started</p>

            </div>
            <div className="providerstarter-containerpara2">
              <div className='providerswitchpageline'></div>
            </div>
            <div className="providerstarter-containerpara3h">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>1</span> seat</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>10</span> sessions</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>23</span> members</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>1</span> monthly get-together</p>

            </div>
            <div className="providerstarter-containerpara22">
              <div className='providerswitchpageline'></div>
            </div>
            <div className="providerstarter-containerpara3h">

              <p className='providerswitchplanpara3'><span className='providerswitchplanpara3span1'>Pro includes</span></p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Basic amenities</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Discounts upto 60%</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> SOS Ambulance Service</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> 24/7 Online Pharmacy</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Personal Whatsapp support</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> VR training</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Get discounts at every </p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Medharva Care centre</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Mediclaim</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> 1 on 1 physchotherapy sessions</p>

            </div>
            <div className="providerstarter-containerpara3btn">

              <Button variant="contained" color="primary" className='providergetstaartedbtn'>get started</Button>

            </div>

          </div>


{/* Premium */}

          <div className='providerswitchcontainer2'>
            <div className="providerstarter-container">

              <h2 className='providerstarter-heading1'>Premium</h2>

            </div>
            <div className="providerstarter-container">

              <h2 className='providerstarter-prise1'>{plan === 'Monthly' ? '$250/' : '$200/'}<span className='providerstarter-prise1span'>mo</span></h2>

            </div>
            <div className="providerstarter-containerpara">

              <p className='providerswitchplanpara'>Billed Annualy</p>

            </div>
            <div className="providerstarter-containerpara2">

              <p className='providerswitchplanpara2'>Let’s get started</p>

            </div>
            <div className="providerstarter-containerpara2">
              <div className='providerswitchpageline'></div>
            </div>
            <div className="providerstarter-containerpara3h">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>1</span> seat</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>10</span> sessions</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>23</span> members</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerswitchplanpara3span'>1</span> monthly get-together</p>

            </div>
            <div className="providerstarter-containerpara22">
              <div className='providerswitchpageline'></div>
            </div>
            <div className="providerstarter-containerpara3h">

              <p className='providerswitchplanpara3'><span className='providerswitchplanpara3span1'>Pro includes</span></p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Basic amenities</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Discounts upto 60%</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> SOS Ambulance Service</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> 24/7 Online Pharmacy</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Personal Whatsapp support</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> VR training</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Get discounts at every </p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Medharva Care centre</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> Mediclaim</p>

            </div>
            <div className="providerstarter-containerpara3">

              <p className='providerswitchplanpara3'> <span className='providerplanpara3span'><FaCheck /></span> 1 on 1 physchotherapy sessions</p>

            </div>
            <div className="providerstarter-containerpara3btn">

              <Button variant="contained" color="primary" className='providergetstaartedbtn'>get started</Button>

            </div>
          </div>



        </div>
      </div>


    </div>
  )
}

export default Providerplan
