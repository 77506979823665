import { createSlice } from "@reduxjs/toolkit";

interface TableActionRow {
  rowData: any;
  actionType: "edit" | "delete" | ""
}

const initialState: TableActionRow = {
  rowData: {} ,
  actionType: ""
};

export const tableActionRowSlice = createSlice({
    name: "tableActionData",
    initialState,
    reducers: {
    //   setActionRowData(state, action) {
    //     state.rowData = action.payload;
    //     state.actionType = ""; 
    //   },
      setActionTypeRow(state, action) {
        state.rowData = action.payload.data;
        state.actionType = action.payload.type;
      },
    //   setEditRowData(state) {
    //     state.actionType = "edit"; 
    //   },
      clearActionOfRow(state) {
        state.actionType = "";
      },
      clearActionRowData(state) {
        state.rowData = {};
        state.actionType = "";
      },
    },
  });

  export const { setActionTypeRow, clearActionRowData, clearActionOfRow } = tableActionRowSlice.actions;

export default tableActionRowSlice.reducer;
