import {
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../axiosinstance";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteDepartmentData } from "redux/Actions/departmentAction";

import { deleteDesignationData } from "redux/Actions/designationAction";
import { micappCode } from "static/micappCode";
import PincodeAssociationEdit from "./PincodeAssociationEdit";
import PincodeAssociation from "./PincodeAssociation";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const PincodeAssociationList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const location = useLocation();
  const [selectedUser, setselectedUser] = useState(null);
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupUser, setopenpopupUser] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeAssociationService}/get?mappingcode=${
            micappCode?.mappingcode
          }&createdby=${auth.profileid}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeAssociationService}/get?mappingcode=${
            micappCode?.mappingcode
          }&&createdby=${auth.profileid}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (filterData?.name || nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeAssociationService}/get?mappingcode=${
            micappCode?.mappingcode
          }&createdby=${auth.profileid}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter || filterData.name}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeAssociationService}/get?mappingcode=${
            micappCode?.mappingcode
          }&createdby=${auth.profileid}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  }, [currentPage, perPage]);

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    return {
      ...row,
      state: row?.pincode?.state?.name,
      district: row?.pincode?.district?.name,
      subdistrict: row?.pincode?.subdistrict?.name,
      village: row?.pincode?.village?.name,
      pincode: row?.pincode?.village?.code,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "projectname", label: "MIC Name" },
    { id: "projectcode", label: "MIC Code" },
    { id: "state", label: "State" },
    { id: "district", label: "District" },
    { id: "subdistrict", label: "Sub District" },
    { id: "village", label: "Village" },
    { id: "pincode", label: "Pincode" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeAssociationService}/get?mappingcode=${
            micappCode?.mappingcode
          }&createdby=${
            auth.profileid
          }&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
        })
      );
    }
  };

  const handleClear = () => {
    setFilterData({});
    // navigate("/app/mic/create-mic")
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeAssociationService}/get?mappingcode=${
          micappCode?.mappingcode
        }&createdby=${auth.profileid}&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleOpenEditDialog = (row) => {
    setselectedUser(row);
    setopenpopup(true);
  };
  const handleClose = () => {
    setopenpopupUser(false);
  };
  const passData = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeAssociationService}/get?mappingcode=${
          micappCode?.mappingcode
        }&createdby=${auth.profileid}&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleCloseEdit = () => {
    setopenpopup(false);
  };
  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: `You will not be able to recover this ${row.name}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deletePincodeData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            dispatch(
              tableDataAction({
                url: `${APIs.pincodeAssociationService}/get?mappingcode=${
                  micappCode?.mappingcode
                }&createdby=${auth.profileid}&ispageable=true&page=${
                  currentPage - 1
                }&size=${perPage}`,
              })
            );
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }
  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "5%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "5%" }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Pincode Association List
              </MDTypography>
              <MDButton color="info" onClick={() => setopenpopupUser(true)}>
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopup && (
        <PincodeAssociationEdit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Pincode Association Edit"}
          selectedUser={selectedUser}
          handleClose={handleCloseEdit}
          passData={passData}
        />
      )}
      {openpopupUser && (
        <PincodeAssociation
          openpopupUser={openpopupUser}
          setopenpopupUser={setopenpopupUser}
          handleClose={handleClose}
          passData={passData}
        />
      )}
    </DashboardLayout>
  );
};

export default PincodeAssociationList;
