
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create role

export const createClinic = createAsyncThunk("createClinic", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.post(
      `mmucreation-service/api/MMUCreation/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getClinic = createAsyncThunk("getClinic", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.get(
      `mmucreation-service/api/MMUCreation/v1/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateClinic = createAsyncThunk("updateClinic", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.put(
      `mmucreation-service/api/MMUCreation/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteClinic = createAsyncThunk("deleteClinic", async (data, {rejectWithValue}) => {
  

  try {
    const response = await axios.put(
      `mmucreation-service/api/MMUCreation/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




