import React, { useState } from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "components/DashboardLayout";
import { Link } from "react-router-dom";

function OnboaredList() {
  const [tableDatas, setTableDatas] = useState({
    columns: [
      { Header: "Sr.no", accessor: "SrNo" },
      { Header: "Resource Name", accessor: "ResourceName" },
      { Header: "Role", accessor: "Role" },
      { Header: "Agent Commission", accessor: "AgentCommission" },
      { Header: "Resource Commission", accessor: "ResourceCommission" },
      { Header: "Registration Data", accessor: "RegistrationStatus" },
      { Header: "Registration Form", accessor: "action" },
    ],
    rows: [
      {
        SrNo: "1",
        ResourceName: "John Doe",
        Role: "Agent",
        AgentCommission: "10%",
        ResourceCommission: "5%",
        RegistrationStatus: "Registered",
        action: (
          <div>
            <Link to="/app/mic/agents/view-profile">
            <button>View</button>
            </Link>
          </div>
        ),
      },
      {
        SrNo: "2",
        ResourceName: "Jane Smith",
        Role: "Manager",
        AgentCommission: "15%",
        ResourceCommission: "7%",
        RegistrationStatus: "Not Registered",
        action: (
          <div>
             <Link to="/app/mic/agents/view-profile">
            <button>View</button>
            </Link>
          </div>
        ),
      },
    ],
  });

  return (
    <DashboardLayout>
      <MDBox mt={4}>
        <Card>
          <DataTable table={tableDatas} entriesPerPage={false} showTotalEntries={false} canSearch={true} />
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}

export default OnboaredList;