import {
  combineReducers,
  legacy_createStore as createStore,
  applyMiddleware,
} from "redux";
import thunk from "redux-thunk";
import AuthReducer from "./Reducers/AuthReducer";
import { composeWithDevTools } from "redux-devtools-extension";
import role from "app/Admin/AdminWorkspace/Role Master/reducer/roleDetailSlice";
import category from "app/Admin/AdminWorkspace/categoryMaster/reducer/categoryDetailSlice";
import department from "app/Admin/AdminWorkspace/departmentMaster/reducer/departmentDetailSlice";
import designation from "app/Admin/AdminWorkspace/designationMaster/reducer/designationDetailSlice";
import user from "../components/Resuable/UserMaster/reducer/userDetailSlice";
import statelist from "../components/Resuable/StateMaster/reducer/stateDetailSlice";
import district from "../components/Resuable/DistrictMaster/reducer/districtDetailSlice";
import subdistrict from "../components/Resuable/subDistrict/reducer/subdistrictDetailSlice";
import pincode from "../components/Resuable/CreatePincode/reducer/pincodeDetailSlice";
import projectpincode from "../components/Resuable/ProjectPincode/reducer/projectpincodeDetailSlice";
import projectclinic from "../components/Resuable/Projectclinic/reducer/projectclinicDetailSlice";
import project from "../components/Resuable/projectTeam/reducer/projectDetailSlice";
import projectTeam from "../components/Resuable/projectTeam/reducer/projectteamDetailSlice";
import association from "../components/Resuable/Association/reducer/associationDetailSlice";
import otp from "../components/Resuable/Admin/NewRegistration/reducer/otpDetailSlice";
import clinic from "app/Admin/AdminWorkspace/Clinic Master/reducer/clinicDetailSlice";
import create from "../components/Resuable/Create Project/reducer/projectcreateDetailSlice";
import resource from "./slice/resourceDetailSlice";
import registrationresourceDetail from "./slice/registrationresourceDetailSlice";
import tableData from "./slices/tableData";
import tableActionRow from "./slices/tableActionRow";
import doctorDetails from "./slices/doctorDetailsSlice";
import loginUserList from "./slices/loginUserList";
import getDeparment from "./slices/getDeparment";
import getDesignation from "./slices/getDesignation";
import disease from "app/Admin/AdminWorkspace/diseasesMaster/reducer/diseaseDetailSlice";
import getUserCategory from "./slices/getUserCategory";
import getProjectTeam from "./slices/getProjectTeam";
import getPincodeAssociation from "./slices/getPincodeAssociation";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import getMicList from "./slices/getMicList";
import getRoles from "./slices/getRoles";
import getSummaryServiceData from "./slices/getSummaryServiceData";
import getDiseaseList from "./slices/getDiseaseList";
import getTestMasterList from "./slices/getTestMasterList";
import getFrequencyMasterList from "./slices/getFrequencyMasterList";
import getBookingList from "./slices/getBookingList";
import getAggregationList from "./slices/getAggregationList";
import getAddLabCart from "./slices/getAddLabCart";
import getMicDetails from "./slices/getMicDetails";
import getMicApprovalList from "./slices/getMicApprovalList";
import getMMUList from "./slices/getMMUList";
import getLabList from "./slices/getLabList";
import getMMUDetails from "./slices/getMMUDetails";
import getLabEquipmentsList from "./slices/getLabEquipmentsList";
import getProjectDetails from "./slices/getProjectDetails";

const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'AuthReducer', 
    'role', 
    'category',
    'department', 
    'designation', 
    'user', 
    'statelist', 
    'district', 
    'subdistrict', 
    'pincode', 
    'projectpincode', 
    'projectclinic', 
    'project', 
    'projectTeam', 
    'association', 
    'otp', 
    'clinic', 
    'create', 
    'resource', 
    'registrationresourceDetail', 
    'tableData', 
    'tableActionRow', 
    'loginUserList', 
    'getDeparment', 
    'getDesignation', 
    'disease', 
    'getUserCategory', 
    'getProjectTeam', 
    'getPincodeAssociation',
    'getMicList',
    'getRoles',
    'getSummaryServiceData',
    'getDiseaseList',
    'getTestMasterList',
    'getFrequencyMasterList',
    'getBookingList',
    'getAggregationList',
    'getAddLabCart',
    'getMicDetails',
    'getMicApprovalList',
    'getMMUDetails',
    'getProjectDetails',
  ]
};

const rootReducer = combineReducers({
  AuthReducer,
  role,
  category,
  department,
  designation,
  user,
  statelist,
  district,
  subdistrict,
  pincode,
  projectpincode,
  projectclinic,
  project,
  projectTeam,
  association,
  otp,
  clinic,
  create,
  resource,
  registrationresourceDetail,
  tableData,
  tableActionRow,
  loginUserList,
  getDeparment,
  getDesignation,
  disease,
  getUserCategory,
  getProjectTeam,
  getPincodeAssociation,
  getMicList,
  getRoles,
  getSummaryServiceData,
  getDiseaseList,
  getTestMasterList,
  getFrequencyMasterList,
  getBookingList,
  getAggregationList,
  getAddLabCart,
  getMicDetails,
  getMicApprovalList,
  getMMUList,
  getLabList,
  getMMUDetails,
  getLabEquipmentsList,
  getProjectDetails,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

const persistor = persistStore(store);

export { store, persistor };









// import {
//   combineReducers,
//   legacy_createStore as createStore,
//   applyMiddleware,
// } from "redux";
// import thunk from "redux-thunk";
// import AuthReducer from "./Reducers/AuthReducer";
// import { composeWithDevTools } from "redux-devtools-extension";
// import role from "app/Admin/AdminWorkspace/Role Master/reducer/roleDetailSlice";
// import department from "app/Admin/AdminWorkspace/departmentMaster/reducer/departmentDetailSlice";
// import designation from "app/Admin/AdminWorkspace/designationMaster/reducer/designationDetailSlice";
// import user from "../components/Resuable/UserMaster/reducer/userDetailSlice";
// import statelist from "../components/Resuable/StateMaster/reducer/stateDetailSlice";
// import district from "../components/Resuable/DistrictMaster/reducer/districtDetailSlice";
// import subdistrict from "../components/Resuable/subDistrict/reducer/subdistrictDetailSlice";
// import pincode from "../components/Resuable/CreatePincode/reducer/pincodeDetailSlice";
// import projectpincode from "../components/Resuable/ProjectPincode/reducer/projectpincodeDetailSlice";
// import projectclinic from "../components/Resuable/Projectclinic/reducer/projectclinicDetailSlice";
// import project from "../components/Resuable/projectTeam/reducer/projectDetailSlice";

// import projectTeam from "../components/Resuable/projectTeam/reducer/projectteamDetailSlice";
// import association from "../components/Resuable/Association/reducer/associationDetailSlice";
// import otp from "../components/Resuable/Admin/NewRegistration/reducer/otpDetailSlice"
// import clinic from "app/Admin/AdminWorkspace/Clinic Master/reducer/clinicDetailSlice"
// import create from "../components/Resuable/Create Project/reducer/projectcreateDetailSlice"
// import resource from "./slice/resourceDetailSlice"
// // import eventlist from "../app/findDoctor/doctorsExpanded/appointment/reducer/eventDetailSlice"

// // new 
// import registrationresourceDetail from "./slice/registrationresourceDetailSlice"
// import tableData from "./slices/tableData";
// import tableActionRow from "./slices/tableActionRow";
// import doctorDetails from "./slices/doctorDetailsSlice";
// import loginUserList from "./slices/loginUserList";
// import getDeparment from "./slices/getDeparment";
// import getDesignation from "./slices/getDesignation";
// import  disease from "app/Admin/AdminWorkspace/diseasesMaster/reducer/diseaseDetailSlice";
// import getUserCategory from "./slices/getUserCategory";
// import getProjectTeam from "./slices/getProjectTeam";
// import getPincodeAssociation from "./slices/getPincodeAssociation";
// import { persistStore, persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
//   // Optionally, specify which slices to persist selectively:
//   whitelist: [getDeparment, getDesignation, disease, getUserCategory, getProjectTeam, getPincodeAssociation]
// };

// const persistedReducer = persistReducer(persistConfig, combineReducers({
//   AuthReducer,
//     role,
//     department,
//     designation,
//     user,
//     statelist,
//     district,
//     subdistrict,
//     pincode,
//     projectpincode,
//     projectclinic,
//     project,
//     projectTeam,
//     association,
//     otp,
//     clinic,
//     create,
//     resource,
//     registrationresourceDetail,
//     tableData,
//     tableActionRow,
//     loginUserList,
//     getDeparment,
//     getDesignation,
//     disease,
//     getUserCategory,
//     getProjectTeam,
//     getPincodeAssociation,
// }));

// const store = createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)));
// const persistor = persistStore(store);

// export { store, persistor };



// // const store = createStore(
// //   combineReducers({
// //     AuthReducer,
// //     role,
// //     department,
// //     designation,
// //     user,
// //     statelist,
// //     district,
// //     subdistrict,
// //     pincode,
// //     projectpincode,
// //     projectclinic,
// //     project,
// //     projectTeam,
// //     association,
// //     otp,
// //     clinic,
// //     create,
// //     resource,
// //     registrationresourceDetail,
// //     tableData,
// //     tableActionRow,
// //     loginUserList,
// //     getDeparment,
// //     getDesignation,
// //     disease,
// //     getUserCategory,
// //     getProjectTeam,
// //     getPincodeAssociation,
// //     // eventlist
// //   }),
// //   composeWithDevTools(applyMiddleware(thunk))
// // );

// // export default store;
