import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
import { MdOutlineLibraryAdd } from "react-icons/md";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
//edit icon
import { FaRegEdit } from "react-icons/fa";
import { deleteClinic, getClinic } from "../service/Clinic";
import Clinicedit from "./Clinicedit";
import CreateClinic from "./CreateClinic";
import MDButton from "components/MDButton";

function ClinicList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clinic, loading } = useSelector((state) => state.clinic);
  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [openpopupClinic, setopenpopupClinic] = useState(false);

  const [selectedClinic, setSelectedClinic] = useState(null);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function editBrand(item) {
    console.log("item", item);

    setopenpopup(true);
    setSelectedClinic(item);
  }

  function onDelete(item) {
    // Show a confirmation dialog using SweetAlert
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User clicked "Delete," proceed with deletion
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });

        // Dispatch the deleteBrand action
        dispatch(deleteClinic(item))
          .then(() => {
            // After successful deletion, close the Swal dialog
            swal("Deleted!", "Your item has been deleted.", "success");
            // Fetch the updated brand list
          })
          .catch((error) => {
            // Handle errors, display an error message, and close the Swal dialog
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        // User clicked "Cancel," do nothing
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getClinic());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  if (loading) {
    // Render a centered circular loader
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>
  
   
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Clinic Master
                  </MDTypography>
                </span>

                <span
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                >
                  {/* <MDButton
                    color="success"
                    onClick={() => setopenpopupClinic(true)}
                  >
                    ADD
                  </MDButton> */}
                  <MdOutlineLibraryAdd
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                    cursor: "pointer"
                  }}
                  // onClick={handleAddButton}
                  onClick={() => setopenpopupClinic(true)}
                />
                </span>
              </div>
            </MDBox>
            <DataTable
              canSearch={true}
              table={{
                columns: [
                  { Header: "name", accessor: "name" },
                  { Header: "description", accessor: "description" },
                  {
                    Header: "Created Date",
                    accessor: "createddate",
                    Cell: ({ value }) => {
                      const formattedDate = new Date(value).toDateString();
                      return formattedDate;
                    },
                  },

                  {
                    Header: "Action",
                    accessor: "action",

                    Cell: ({ row }) => (
                      <div>
                        <Link to={{}}>
                          <FaRegEdit
                            style={iconStyleedit}
                            onClick={() => {
                              editBrand(row.original);
                            }}
                          />
                        </Link>

                        <Link to={{}}>
                          <MdDelete
                            style={iconStyle}
                            onClick={() => {
                              onDelete(row.original);
                            }}
                          />
                        </Link>
                      </div>
                    ),
                  },
                ],
                rows: clinic || [],
              }}
            />
          </Card>
        </MDBox>
      </MDBox>
      <Clinicedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Clinic Edit"}
        selectedClinic={selectedClinic}
      />
      <CreateClinic
        openpopupClinic={openpopupClinic}
        setopenpopupClinic={setopenpopupClinic}
      />
    </DashboardLayout>
  );
}

export default ClinicList;
