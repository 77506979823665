import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const projectDetailsAction = 
   createAsyncThunk("projectDetailsAction", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.profileId){
        url = APIs.projectServiceApi +`/getProjectAndMMUByProfileId?profileId=${data.profileId}`
      }
      else if(data && data.clear){
        return {}
      } 
      else {
        url = APIs.projectServiceApi +`/get`
      }
        let respData = await getAllUsers(url, "")
        return respData?.dto || {};

    } catch (error) {
      return rejectWithValue(error);
    }
  });