import DashboardLayout from 'components/DashboardLayout';
import MDBox from 'components/MDBox';
import DoctorAddressList from 'components/Resuable/Admin/Doctor Qualification/DoctorAddressList';
import DoctorCategoryList from 'components/Resuable/Admin/Doctor Qualification/DoctorCategoryList';
import DoctorQualification from 'components/Resuable/Admin/Doctor Qualification/DoctorQualification';
import DoctorSlot from 'components/Resuable/Admin/Doctor Qualification/DoctorSlot';
import ProfileInformation from 'components/Shared/profileInformation/ProfileInformation';
import React from 'react'

const AdminProfile = () => {
  return (
    <div>
        <ProfileInformation />
        <DashboardLayout>
        <MDBox px={2.5}>
     <DoctorQualification />
     <DoctorCategoryList />
     <DoctorAddressList />
     {/* <DoctorSlot /> */}
       </MDBox>
       </DashboardLayout>
    </div>
  )
}

export default AdminProfile;