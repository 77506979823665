import {
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../axiosinstance";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteDepartmentData } from "redux/Actions/departmentAction";
import { deleteDesignationData } from "redux/Actions/designationAction";
import { micappCode } from "static/micappCode";
import CreateProjectTeamPopup from "./create-team/CreateProjectTeamPopup";
import ProjectTeamedit from "./create-team/componets/ProjectTeamedit";
import { deleteProjectTeamData } from "redux/Actions/projectTeamAction";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const TeamAssociationList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const { getProjectDetails } = useSelector((state) => state);
  const location = useLocation();
  const [selectedUser, setselectedUser] = useState(null);
  const [projectList, setProjectList] = useState([]);
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupProjectTeam, setopenpopupProjectTeam] = useState(false);
  const [selectedProjectTeam, setselectedProjectTeam] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
    {
      type: "select",
      placeholder: "Select MIC",
      variant: "outlined",
      label: "Select MIC",
      name: "selectedProject",
      page: "micteamAssociation",
      options: projectList || [],
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        {/* <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip> */}
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
            getProjectDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
            getProjectDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    // if (filterData?.name || nameFilter) {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
    //       getProjectDetails?.details?.projecthirarichalcode
    //     }&ispageable=true&page=${
    //         currentPage - 1
    //       }&size=${perPage}&st=${nameFilter || filterData.name}`,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
    //       getProjectDetails?.details?.projecthirarichalcode
    //     }&ispageable=true&page=${
    //         currentPage - 1
    //       }&size=${perPage}`,
    //     })
    //   );
    // }
    handleTeamListData();
  }, [currentPage, perPage]);

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    return {
      ...row,
      department: row?.projectteams?.login?.departmentname
        ? row?.projectteams?.login?.departmentname
        : "",
      designation: row?.projectteams?.login?.designationname
        ? row?.projectteams?.login?.designationname
        : "",
      role: row?.projectteams?.login?.rolename
        ? row?.projectteams?.login?.rolename
        : "",
      // name: row?.projectteams?.login?.name ? row?.projectteams?.login?.name : "",
      name: row?.projectteams?.login?.name,
      email: row?.projectteams?.login?.emailid,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "projectname", label: "MIC Name" },
    { id: "email", label: "Email Id" },
    { id: "department", label: "Department" },
    { id: "designation", label: "Designation" },
    { id: "role", label: "Role" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    handleTeamListData();
    // if (filterData?.name) {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
    //       getProjectDetails?.details?.projecthirarichalcode
    //     }&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
    //     })
    //   );
    // }
  };

  const handleClear = () => {
    setFilterData({});
    dispatch(
      tableDataAction({
        url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
          getProjectDetails?.details?.projecthirarichalcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };

  const passData = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${
          getProjectDetails?.details?.projecthirarichalcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };

  const handleCreate = () => {
    setopenpopupProjectTeam(false);
  };

  const handleEdit = () => {
    setopenpopup(false);
  };

  const handleOpenEditDialog = (row) => {
    setselectedProjectTeam(row);
    setopenpopup(true);
  };

  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: `You will not be able to recover this ${row.projectname}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteProjectTeamData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            handleClear();
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  const handleTeamListData = () => {
    let url = "";

    if (filterData && filterData.selectedProject && filterData.name) {
      url = `${APIs.projectTeamService}/getByPageV2?mappingcode=${
        getProjectDetails?.details?.projecthirarichalcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&projectcode=${
        filterData.selectedProject
      }&st=${filterData?.name}`;
    } else if (filterData && filterData.selectedProject) {
      url = `${APIs.projectTeamService}/getByPageV2?mappingcode=${
        getProjectDetails?.details?.projecthirarichalcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&projectcode=${
        filterData.selectedProject
      }`;
    } else if (filterData && filterData.name) {
      url = `${APIs.projectTeamService}/getByPageV2?mappingcode=${
        getProjectDetails?.details?.projecthirarichalcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${
        filterData?.name
      }`;
    } else {
      url = `${APIs.projectTeamService}/getByPageV2?mappingcode=${
        getProjectDetails?.details?.projecthirarichalcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}`;
    }
    dispatch(tableDataAction({ url: url }));
  };

  const getProjectList = async () => {
    await axios
      .get(
        `${APIs?.projectServiceApi}/getByPageV2?projectcode=${getProjectDetails?.details?.projecthirarichalcode}`
      )
      .then((response) => {
        const data =
          response?.data?.data?.dtoList?.map((item) => ({
            ...item,
            value: item.code,
            label: item.name,
          })) || [];
        setProjectList(data);
      })
      .catch((error) => {
        setProjectList([]);
      });
  };

  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "5%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "5%" }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Team Association List
              </MDTypography>
              <MDButton
                color="info"
                onClick={() => setopenpopupProjectTeam(true)}
              >
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopupProjectTeam && (
        <CreateProjectTeamPopup
          openpopupProjectTeam={openpopupProjectTeam}
          setopenpopupProjectTeam={setopenpopupProjectTeam}
          handleClose={handleCreate}
          passData={handleTeamListData}
        />
      )}
      {openpopup && (
        <ProjectTeamedit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"MIC Team Edit"}
          selectedProjectTeam={selectedProjectTeam}
          handleClose={handleEdit}
          passData={passData}
        />
      )}
    </DashboardLayout>
  );
};

export default TeamAssociationList;
