import React, { useEffect, useState } from "react";

import { Dialog, DialogContent, DialogTitle, Grid } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Search from "app/findDoctor/assets/images/Search.svg";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Navbar from "app/findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { useSelector } from "react-redux";
import axios from "axios";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

const MapPopup = (props) => {
  const { title, openpopup, setopenpopup, onDataTransfer, center, data2 } = props;
  const navigate = useNavigate();
  const Location = JSON.parse(localStorage.getItem("userLocation"));

  const [mapCenter, setMapCenter] = useState({
    lat: Location?.latitude || 28.6139,
    lng: Location?.longitude || 77.209,
  });

  const [searchAddress, setSearchAddress] = useState("");
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [manualMarkerLocation, setManualMarkerLocation] = useState(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [selectedState, setSelectedState] = useState("");
  const customMarkerImageUrl =
    "https://i.pinimg.com/474x/67/c3/d6/67c3d63e215e034e01d45c8256d720d3.jpg";

  const data = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const stateCoordinates = {
    "Andhra Pradesh": { lat: 15.9129, lng: 79.74 },
    "Arunachal Pradesh": { lat: 28.218, lng: 94.7278 },
    Assam: { lat: 26.2006, lng: 92.9376 },
    Bihar: { lat: 25.0961, lng: 85.3131 },
    Chhattisgarh: { lat: 21.2787, lng: 81.8661 },
    Goa: { lat: 15.2993, lng: 74.124 },
    Gujarat: { lat: 22.2587, lng: 71.1924 },
    Haryana: { lat: 29.0588, lng: 76.0856 },
    "Himachal Pradesh": { lat: 31.1048, lng: 77.1734 },
    Jharkhand: { lat: 23.6102, lng: 85.2799 },
    Karnataka: { lat: 15.3173, lng: 75.7139 },
    Kerala: { lat: 10.8505, lng: 76.2711 },
    "Madhya Pradesh": { lat: 22.9734, lng: 78.6569 },
    Maharashtra: { lat: 19.7515, lng: 75.7139 },
    Manipur: { lat: 24.6637, lng: 93.9063 },
    Meghalaya: { lat: 25.467, lng: 91.3662 },
    Mizoram: { lat: 23.1645, lng: 92.9376 },
    Nagaland: { lat: 26.1584, lng: 94.5624 },
    Odisha: { lat: 20.9517, lng: 85.0985 },
    Punjab: { lat: 31.1471, lng: 75.3412 },
    Rajasthan: { lat: 27.0238, lng: 74.2179 },
    Sikkim: { lat: 27.533, lng: 88.5122 },
    "Tamil Nadu": { lat: 11.1271, lng: 78.6569 },
    Telangana: { lat: 18.1124, lng: 79.0193 },
    Tripura: { lat: 23.9408, lng: 91.9882 },
    "Uttar Pradesh": { lat: 26.8467, lng: 80.9462 },
    Uttarakhand: { lat: 30.0668, lng: 79.0193 },
    "West Bengal": { lat: 22.9868, lng: 87.855 },
    // Union Territories
    "Andaman and Nicobar Islands": { lat: 11.7401, lng: 92.6586 },
    Chandigarh: { lat: 30.7333, lng: 76.7794 },
    "Dadra and Nagar Haveli and Daman and Diu": { lat: 20.3974, lng: 72.8328 },
    "Lakshadweep": { lat: 10.5667, lng: 72.6417 },
    "Delhi (NCT)": { lat: 28.7041, lng: 77.1025 },
    "Puducherry": { lat: 11.9416, lng: 79.8083 },
    Ladakh: { lat: 34.1526, lng: 77.5771 },
    "Jammu and Kashmir": { lat: 33.7782, lng: 76.5762 }
  };
  
  const handleStateChange = (selectedState) => {
    setSearchAddress("");

    if (stateCoordinates[selectedState]) {
      const { lat, lng } = stateCoordinates[selectedState];
      setMapCenter({ lat, lng });
    }
  };

  const [selectedButton, setSelectedButton] = useState("Home");

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleClose = () => {
    setopenpopup(false);
  };
  const onPlaceChanged = () => {
    setIsLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchAddress }, (results, status) => {
      if (status === "OK" && results[0]) {
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());

        setMapCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });

        setSearchedLocation({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });

        const addressComponents = results[0].address_components;
        let stateName = "";
        for (let i = 0; i < addressComponents.length; i++) {
          const types = addressComponents[i].types;
          if (types.includes("administrative_area_level_1")) {
            stateName = addressComponents[i].long_name;
            break;
          }
        }

        setSelectedState(stateName);
      } else {
        console.error("Geocoding failed:", status);
      }
      setIsLoading(false);
    });
  };

  const handleSearch = () => {
    onPlaceChanged();
  };

  const handlesearchchange = (e) => {
    setSearchAddress(e.target.value);
    setTimeout(() => {
      handleSearch();
    }, 4000);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);

  const validationSchema = Yup.object().shape({
    completeAddress: Yup.string().required("Complete Address is required"),
  });

  const fetchAreaInformation = async (lat, lng) => {
   
    setIsLoading(true);
    const tokens = "AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0";
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${tokens}`
      );
      const result = response.data;

      if (result.status === "OK") {
        const addressComponents = result.results[0].address_components;

        // Concatenate all address components to form the full address
        let fullAddress = "";
        for (let component of addressComponents) {
          fullAddress += component.long_name + ", ";
        }

        // Trim any trailing comma and space
        fullAddress = fullAddress.replace(/,\s*$/, "");
        let state = "";
for (let component of addressComponents) {
  if (component.types.includes("administrative_area_level_1")) {
    state = component.long_name;
    break; // Exit loop once state is found
  }
}
setSelectedState(state)
        
        setSearchAddress(fullAddress);

        return fullAddress;
      } else {
        throw new Error(
          result.error_message || "Error fetching area information"
        );
      }
    } catch (error) {
      console.error("Error fetching area information:", error);
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapClick = async (e) => {
    setIsLoading(true);
    // localStorage.removeItem("selectedLocation");
    const clickedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setSearchedLocation(clickedLocation);

    setManualMarkerLocation(clickedLocation);
    const areaInfo = await fetchAreaInformation(
      clickedLocation.lat,
      clickedLocation.lng
    );

    setManualMarkerLocation();

    const areaInfoParts = areaInfo.split(", ");
    const stateName = areaInfoParts[areaInfoParts.length - 3];

    // Check if the state name exists in the data array
    if (data.includes(stateName)) {
      setSelectedState(stateName);
    } else {
      console.log("State not found in the data array.");
    }

    setSearchAddress(areaInfo);
    setIsLoading(false);
  };

  const saveMapClick = () => {
     onDataTransfer(searchedLocation);
    handleClose();
  };

  useEffect(() => {
    fetchAreaInformation(mapCenter?.lat, mapCenter?.lng);
    const Location = {
      lat: mapCenter?.lat,
      lng: mapCenter?.lng,
    };
    setSearchedLocation(Location);
  }, []);

  return (
    <Dialog open={openpopup} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <MDBox>
          {isLoading && <div className="loader">Loading...</div>}

          <Grid container mt={2} mb={2} xs={12} gap={1}>
            <Grid item xs={isSmallScreen ? 12 : 3.8}>
              <select
                className="specificlocationinput"
                style={{ border: "1px solid #039147", borderRadius: "5px" }}
                id="selectedState"
                name="selectedState"
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  handleStateChange(e.target.value);
                }}
              >
                <option value="">Select any State</option>
                {data?.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid item xs={isSmallScreen ? 12 : 8}>
              <input
                type="text"
                id="specificlocationinput"
                style={{ border: "1px solid #039147" }}
                value={searchAddress}
                placeholder="Search for city, localities and more"
                className="specificlocationinput"
                onChange={(e) => handlesearchchange(e)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={2} mb={2}>
            <LoadScript googleMapsApiKey="AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0">
              <GoogleMap
                center={mapCenter}
                // center={center.length<=0? mapCenter : [Number(center[0]), Number(center[1])]}
                zoom={14}
                mapContainerStyle={{ width: "100%", height: "400px" }}
                onClick={(e) => {
                  handleMapClick(e);
                }}
              >
                {manualMarkerLocation &&
                  window.google &&
                  window.google.maps && (
                    <Marker
                      position={manualMarkerLocation}
                      icon={{
                        url: customMarkerImageUrl,
                        scaledSize: new window.google.maps.Size(30, 30),
                      }}
                    />
                  )}
                {searchedLocation && <Marker position={searchedLocation} />}
              </GoogleMap>
            </LoadScript>
          </Grid>
          <Grid container xs={isSmallScreen ? 12 : 10} mt={3}>
            {searchedLocation && (
              <MDButton
                size="small"
                color="info"
                mt={2}
                onClick={() => {
                  saveMapClick();
                }}
              >
                SaVE LOCATION
              </MDButton>
            )}
          </Grid>
        </MDBox>
      </DialogContent>
    </Dialog>
  );
};

export default MapPopup;
