export const labEquipments = [
    {
        "id": 1,
        "createdby": "labtech1",
        "createddate": "2024-01-10T08:30:46.918+00:00",
        "modifiedby": "labtech2",
        "modifieddate": "2024-02-15T12:15:30.918+00:00",
        "approver": "supervisor1",
        "approvertype": "Quality",
        "approvedby": "supervisor1",
        "approveddate": "2024-02-16T09:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "certificate.pdf",
        "location": "Lab A",
        "tempid": "EQP20240101",
        "tanentid": "MMT20240418",
        "tags": ["calibrated", "critical"],
        "mappingcode": "MC001",
        "createdbyname": "John Doe",
        "modifiedbyname": "Jane Smith",
        "name": "Centrifuge",
        "type": "labEquipment",
        "stationcode": "ST001",
        "nextstationcode": "ST002",
        "stationname": "Station 1",
        "classname": "ItemMaster",
        "code": "EQP001",
        "description": "High-speed centrifuge for sample separation",
        "packaging": "Box",
        "taxable": "yes",
        "taxcode": "TX123",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP001",
        "rework": "no",
        "modelno": "CF1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "Centrifugal Co.",
            "power": "1000W",
            "speed": "15000 RPM"
        },
        "pricename": "Standard Price",
        "linecode": "LC001",
        "linename": "Lab Equipments",
        "mmucode": "MMU001",
        "sapcode": "1234-5678-90",
        "uom": "1"
    },
    {
        "id": 2,
        "createdby": "labtech2",
        "createddate": "2024-03-20T10:30:00.918+00:00",
        "modifiedby": "labtech3",
        "modifieddate": "2024-03-25T11:15:00.918+00:00",
        "approver": "supervisor2",
        "approvertype": "Safety",
        "approvedby": "supervisor2",
        "approveddate": "2024-03-26T10:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "IN USE",
        "documentofproof": "manual.pdf",
        "location": "Lab B",
        "tempid": "EQP20240320",
        "tanentid": "MMT20240418",
        "tags": ["calibrated"],
        "mappingcode": "MC002",
        "createdbyname": "Alice Brown",
        "modifiedbyname": "Bob Johnson",
        "name": "Microscope",
        "type": "labEquipment",
        "stationcode": "ST002",
        "nextstationcode": "ST003",
        "stationname": "Station 2",
        "classname": "ItemMaster",
        "code": "EQP002",
        "description": "Digital microscope with 1000x magnification",
        "packaging": "Box",
        "taxable": "yes",
        "taxcode": "TX124",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP002",
        "rework": "no",
        "modelno": "MS1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "MicroTech",
            "magnification": "1000x",
            "resolution": "1080p"
        },
        "pricename": "Standard Price",
        "linecode": "LC002",
        "linename": "Lab Equipments",
        "mmucode": "MMU002",
        "sapcode": "2345-6789-01",
        "uom": "1"
    },
    {
        "id": 3,
        "createdby": "labtech3",
        "createddate": "2024-04-10T14:20:46.918+00:00",
        "modifiedby": "labtech4",
        "modifieddate": "2024-04-12T09:00:00.918+00:00",
        "approver": "supervisor3",
        "approvertype": "Quality",
        "approvedby": "supervisor3",
        "approveddate": "2024-04-13T11:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "maintenance_record.pdf",
        "location": "Lab C",
        "tempid": "EQP20240410",
        "tanentid": "MMT20240418",
        "tags": ["new"],
        "mappingcode": "MC003",
        "createdbyname": "Charlie Green",
        "modifiedbyname": "Dave White",
        "name": "Spectrophotometer",
        "type": "labEquipment",
        "stationcode": "ST003",
        "nextstationcode": "ST004",
        "stationname": "Station 3",
        "classname": "ItemMaster",
        "code": "EQP003",
        "description": "UV-Visible Spectrophotometer for sample analysis",
        "packaging": "Crate",
        "taxable": "yes",
        "taxcode": "TX125",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP003",
        "rework": "no",
        "modelno": "SP1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "SpectroLab",
            "wavelength": "200-800 nm",
            "accuracy": "±0.5 nm"
        },
        "pricename": "Standard Price",
        "linecode": "LC003",
        "linename": "Lab Equipments",
        "mmucode": "MMU003",
        "sapcode": "3456-7890-12",
        "uom": "1"
    },
    {
        "id": 4,
        "createdby": "labtech4",
        "createddate": "2024-05-05T11:50:46.918+00:00",
        "modifiedby": "labtech5",
        "modifieddate": "2024-05-10T14:30:00.918+00:00",
        "approver": "supervisor4",
        "approvertype": "Safety",
        "approvedby": "supervisor4",
        "approveddate": "2024-05-12T08:45:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "user_manual.pdf",
        "location": "Lab D",
        "tempid": "EQP20240505",
        "tanentid": "MMT20240418",
        "tags": ["calibrated", "new"],
        "mappingcode": "MC004",
        "createdbyname": "Eve Black",
        "modifiedbyname": "Frank Brown",
        "name": "Autoclave",
        "type": "labEquipment",
        "stationcode": "ST004",
        "nextstationcode": "ST005",
        "stationname": "Station 4",
        "classname": "ItemMaster",
        "code": "EQP004",
        "description": "Autoclave for sterilizing lab equipment",
        "packaging": "Pallet",
        "taxable": "yes",
        "taxcode": "TX126",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP004",
        "rework": "no",
        "modelno": "AC1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "SterilTech",
            "capacity": "50 liters",
            "temperature": "121°C"
        },
        "pricename": "Standard Price",
        "linecode": "LC004",
        "linename": "Lab Equipments",
        "mmucode": "MMU004",
        "sapcode": "4567-8901-23",
        "uom": "1"
    },
    {
        "id": 5,
        "createdby": "labtech5",
        "createddate": "2024-06-20T04:50:46.918+00:00",
        "modifiedby": "labtech6",
        "modifieddate": "2024-06-22T09:30:00.918+00:00",
        "approver": "supervisor5",
        "approvertype": "Quality",
        "approvedby": "supervisor5",
        "approveddate": "2024-06-23T10:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "IN USE",
        "documentofproof": "calibration_record.pdf",
        "location": "Lab E",
        "tempid": "EQP20240620",
        "tanentid": "MMT20240418",
        "tags": ["calibrated"],
        "mappingcode": "MC005",
        "createdbyname": "George Gray",
        "modifiedbyname": "Hannah White",
        "name": "pH Meter",
        "type": "labEquipment",
        "stationcode": "ST005",
        "nextstationcode": "ST006",
        "stationname": "Station 5",
        "classname": "ItemMaster",
        "code": "EQP005",
        "description": "Digital pH meter for measuring acidity",
        "packaging": "Box",
        "taxable": "yes",
        "taxcode": "TX127",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP005",
        "rework": "no",
        "modelno": "PH1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "AcidTech",
            "range": "0-14 pH",
            "resolution": "0.01 pH"
        },
        "pricename": "Standard Price",
        "linecode": "LC005",
        "linename": "Lab Equipments",
        "mmucode": "MMU005",
        "sapcode": "5678-9012-34",
        "uom": "1"
    },
    {
        "id": 6,
        "createdby": "labtech6",
        "createddate": "2024-07-01T08:45:46.918+00:00",
        "modifiedby": "labtech7",
        "modifieddate": "2024-07-05T12:00:00.918+00:00",
        "approver": "supervisor6",
        "approvertype": "Safety",
        "approvedby": "supervisor6",
        "approveddate": "2024-07-06T09:15:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "safety_certificate.pdf",
        "location": "Lab F",
        "tempid": "EQP20240701",
        "tanentid": "MMT20240418",
        "tags": ["calibrated", "critical"],
        "mappingcode": "MC006",
        "createdbyname": "Ivy Green",
        "modifiedbyname": "Jack Brown",
        "name": "Balance Scale",
        "type": "labEquipment",
        "stationcode": "ST006",
        "nextstationcode": "ST007",
        "stationname": "Station 6",
        "classname": "ItemMaster",
        "code": "EQP006",
        "description": "Precision balance scale for weight measurement",
        "packaging": "Box",
        "taxable": "yes",
        "taxcode": "TX128",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP006",
        "rework": "no",
        "modelno": "BS1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "BalanceTech",
            "capacity": "200g",
            "precision": "0.001g"
        },
        "pricename": "Standard Price",
        "linecode": "LC006",
        "linename": "Lab Equipments",
        "mmucode": "MMU006",
        "sapcode": "6789-0123-45",
        "uom": "1"
    },
    {
        "id": 7,
        "createdby": "labtech7",
        "createddate": "2024-07-15T10:15:46.918+00:00",
        "modifiedby": "labtech8",
        "modifieddate": "2024-07-20T11:30:00.918+00:00",
        "approver": "supervisor7",
        "approvertype": "Quality",
        "approvedby": "supervisor7",
        "approveddate": "2024-07-21T10:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "IN USE",
        "documentofproof": "inspection_report.pdf",
        "location": "Lab G",
        "tempid": "EQP20240715",
        "tanentid": "MMT20240418",
        "tags": ["calibrated"],
        "mappingcode": "MC007",
        "createdbyname": "Kelly White",
        "modifiedbyname": "Liam Green",
        "name": "Incubator",
        "type": "labEquipment",
        "stationcode": "ST007",
        "nextstationcode": "ST008",
        "stationname": "Station 7",
        "classname": "ItemMaster",
        "code": "EQP007",
        "description": "Incubator for growing cell cultures",
        "packaging": "Crate",
        "taxable": "yes",
        "taxcode": "TX129",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP007",
        "rework": "no",
        "modelno": "IC1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "IncubaLab",
            "temperature": "37°C",
            "capacity": "100 liters"
        },
        "pricename": "Standard Price",
        "linecode": "LC007",
        "linename": "Lab Equipments",
        "mmucode": "MMU007",
        "sapcode": "7890-1234-56",
        "uom": "1"
    },
    {
        "id": 8,
        "createdby": "labtech8",
        "createddate": "2024-08-01T14:20:46.918+00:00",
        "modifiedby": "labtech9",
        "modifieddate": "2024-08-05T15:00:00.918+00:00",
        "approver": "supervisor8",
        "approvertype": "Safety",
        "approvedby": "supervisor8",
        "approveddate": "2024-08-06T13:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "warranty.pdf",
        "location": "Lab H",
        "tempid": "EQP20240801",
        "tanentid": "MMT20240418",
        "tags": ["new"],
        "mappingcode": "MC008",
        "createdbyname": "Mia Blue",
        "modifiedbyname": "Noah White",
        "name": "Water Bath",
        "type": "labEquipment",
        "stationcode": "ST008",
        "nextstationcode": "ST009",
        "stationname": "Station 8",
        "classname": "ItemMaster",
        "code": "EQP008",
        "description": "Water bath for sample incubation",
        "packaging": "Pallet",
        "taxable": "yes",
        "taxcode": "TX130",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP008",
        "rework": "no",
        "modelno": "WB1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "AquaLab",
            "temperature_range": "20-100°C",
            "capacity": "20 liters"
        },
        "pricename": "Standard Price",
        "linecode": "LC008",
        "linename": "Lab Equipments",
        "mmucode": "MMU008",
        "sapcode": "8901-2345-67",
        "uom": "1"
    },
    {
        "id": 9,
        "createdby": "labtech9",
        "createddate": "2024-08-10T09:30:46.918+00:00",
        "modifiedby": "labtech10",
        "modifieddate": "2024-08-15T11:00:00.918+00:00",
        "approver": "supervisor9",
        "approvertype": "Quality",
        "approvedby": "supervisor9",
        "approveddate": "2024-08-16T09:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "IN USE",
        "documentofproof": "inspection_report.pdf",
        "location": "Lab I",
        "tempid": "EQP20240810",
        "tanentid": "MMT20240418",
        "tags": ["calibrated", "critical"],
        "mappingcode": "MC009",
        "createdbyname": "Olivia Red",
        "modifiedbyname": "Peter Yellow",
        "name": "Fume Hood",
        "type": "labEquipment",
        "stationcode": "ST009",
        "nextstationcode": "ST010",
        "stationname": "Station 9",
        "classname": "ItemMaster",
        "code": "EQP009",
        "description": "Fume hood for safe chemical handling",
        "packaging": "Crate",
        "taxable": "yes",
        "taxcode": "TX131",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP009",
        "rework": "no",
        "modelno": "FH1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "SafeAir",
            "airflow": "1000 CFM",
            "dimensions": "6x4x3 ft"
        },
        "pricename": "Standard Price",
        "linecode": "LC009",
        "linename": "Lab Equipments",
        "mmucode": "MMU009",
        "sapcode": "9012-3456-78",
        "uom": "1"
    },
    {
        "id": 10,
        "createdby": "labtech10",
        "createddate": "2024-08-20T12:50:46.918+00:00",
        "modifiedby": "labtech11",
        "modifieddate": "2024-08-25T14:30:00.918+00:00",
        "approver": "supervisor10",
        "approvertype": "Safety",
        "approvedby": "supervisor10",
        "approveddate": "2024-08-26T13:00:00.918+00:00",
        "latlong": "40.748817,-73.985428",
        "recstatus": "active",
        "syncstatus": "synced",
        "status": "AVAILABLE",
        "documentofproof": "maintenance_record.pdf",
        "location": "Lab J",
        "tempid": "EQP20240820",
        "tanentid": "MMT20240418",
        "tags": ["new"],
        "mappingcode": "MC010",
        "createdbyname": "Quincy Blue",
        "modifiedbyname": "Riley Green",
        "name": "Pipette",
        "type": "labEquipment",
        "stationcode": "ST010",
        "nextstationcode": "ST011",
        "stationname": "Station 10",
        "classname": "ItemMaster",
        "code": "EQP010",
        "description": "Precision pipette for liquid handling",
        "packaging": "Box",
        "taxable": "yes",
        "taxcode": "TX132",
        "taxcategory": "Laboratory Equipment",
        "bypass": "no",
        "setuppart": "SP010",
        "rework": "no",
        "modelno": "PP1000",
        "reworkcount": "0",
        "totalreworkcount": "0",
        "attributes": {
            "brand": "PipetteTech",
            "volume_range": "0.1-10 mL",
            "precision": "±0.1%"
        },
        "pricename": "Standard Price",
        "linecode": "LC010",
        "linename": "Lab Equipments",
        "mmucode": "MMU010",
        "sapcode": "0123-4567-89",
        "uom": "1"
    }
]
