import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";;
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";

import DashboardLayout from "components/DashboardLayout";
import { navigate } from "ionicons/icons";
import SampleCard from "components/Cards/StatisticsCards/simpalCards";

 
function ManageCard({ data }) {
    const [loading, setLoading] = useState(true);
    const [color] = useState("#344767");
    const navigate = useNavigate();

    console.log(data, "data 17")

    return (
        <SampleCard
            title={data.title}
        />
    );
}


export default ManageCard;