
import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { name,taxable,taxcategory,taxcode },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    

  [taxable.name]: Yup.string().required(taxable.errorMsg),
  [taxcategory.name]: Yup.string().required(taxcategory.errorMsg),
  [taxcode.name]: Yup.string().required(taxcode.errorMsg),
  

 
  }),
];

export default validations;
