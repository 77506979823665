import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from "@mui/material";
import MDButton from "components/MDButton";
import { Formik, Form, Field } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { APIs } from "Services/APIs";
import * as Yup from "yup";
import axios from "../../../axiosinstance"

// Define validation schema with Yup
const validationSchema = Yup.object().shape({
  collectiveAgent: Yup.string().required("Collective Agent is required"),
});

const AssignCollectiveAgent = (props) => {
  const { popup, setPopup,selcteddata,handleApi } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const [agentdata, setAgentdata] = useState([]);
  const handleClose = () => {
    setPopup(false);
  };

  const handleSubmit = (values) => {
      updateBooking(values)
   
  };
  
  async function updateBooking(data) {
   
    const willDelete = await swal({
      title: "Are you sure?",
      text: `You will not be able to change staus again`,
      icon: "warning",
      buttons: ["Cancel", "Update"],
      dangerMode: true,
    }); 

    if (willDelete) {
      swal("Updateing...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      try {
        const updateData={
          ...selcteddata,
         visitid : data?.collectiveAgent,
        }
        await axios.put(`${APIs.bookingServiceApi}/update`, updateData);
        swal("Update!", "Sample collected successfully", "success");
         handleApi();
        handleClose()
     
      } catch (error) {
        swal("Error", "An error occurred while deleting the item.", "error");
      }
    } else {
      swal("Cancelled", "Your status is not changed.", "info");

    }
  }

  async function getFamily(profileid) {
    await axios
      .get(`${APIs.loginServiceApi}/get?createdby=${profileid}&rolename=CollectiveAgent&isactive=ACTIVE`)
      .then((response) => {
     setAgentdata(response.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getFamily(auth.profileid);
  }, []);

  return (
    <div>
      <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "green",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Assign Collective Agent
        </DialogTitle>
        <Formik
          initialValues={{ collectiveAgent: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <DialogContent>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    <span>
                      Select Collective Agent
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    variant="standard"
                    style={{ width: "100%" }}
                    name="collectiveAgent"
                    value={values.collectiveAgent}
                    onChange={handleChange}
                    sx={{ marginLeft: "0px" }}
                    error={
                      touched.collectiveAgent && Boolean(errors.collectiveAgent)
                    }
                  >
                    <option value=""></option>

                    {agentdata?.map((option) => (
                      <option key={option?.id} value={option?.profileid}>
                        {option?.name}
                      </option>
                    ))}
                  </Select>
                  {touched.collectiveAgent && errors.collectiveAgent && (
                    <div style={{ color: "red", fontSize: "12px" }}>
                      {errors.collectiveAgent}
                    </div>
                  )}
                </FormControl>
              </DialogContent>
              <DialogActions>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  onClick={handleClose}
                >
                  Cancel
                </MDButton>
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                >
                Update
                </MDButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </div>
  );
};

export default AssignCollectiveAgent;
