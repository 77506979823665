import { Card, Dialog } from '@material-ui/core'
import DashboardLayout from 'components/DashboardLayout'
import FilterCmp from 'components/Filters/FilterCmp'
import Negotiate from 'components/Popup/Negotiate'
import TableCmp from 'components/Tables/TableCmp'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { tableDataAction } from 'redux/Actions/tableDataAction';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import { APIs } from 'Services/APIs'
import { directoryFilter } from 'static/filters/directoryFilter';
import tableActionRow, { clearActionOfRow, clearActionRowData } from 'redux/slices/tableActionRow'
import { ROLE_CODE } from "privateComponents/codes";

const OnboardedList = () => {

    const { tableData, AuthReducer, tableActionRow } = useSelector(state => state);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData]= useState()
    const [filterData, setFilterData] = useState({})
    const [perPage, setPerPage] = useState(5)
    const location = useLocation();
    const nameFilter = new URLSearchParams(location.search).get('name');

    useEffect(async()=>{
      setFilterData({...filterData, name:nameFilter})
      if(nameFilter){
        dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}&ispageable=true&page=${currentPage-1}&size=${perPage}&st=${nameFilter}`}))
      }else{
        // dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
        dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}`}))
      }
      },[])

      useEffect(()=>{
        if(filterData?.name || nameFilter){
          dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}&ispageable=true&page=${currentPage-1}&size=${perPage}&st=${nameFilter || filterData.name}`}))
        }else{
          // dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
          dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}`}))
        }
    },[ currentPage,perPage ])
    
  
  const filters = [
    {type:"text", placeholder:"Enter name", variant:"outlined", label:"Name", name:"name", page: "allList" }
]
  const columns = [
    { id:"name", label: "Doctor's Name", accessor: "doctorName" },
    { id:"agentName",  label: "Agent Name", accessor: "agentName" },
    { id:"noofPatients",  label: "No. of Patients", accessor: "noofPatients" },
    { id:"registrationDate",  label: "Registration Date", accessor: "registrationDate" },
  ]

  const actionButtons = ["view"]

const closeNegotiate =()=> {
      setOpenNegotiate(false);
      dispatch(clearActionRowData())
  }

  const handleSearch =()=>{
    setCurrentPage(1)
     if(filterData?.name){
       dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`}))
     }
   }
   
   const handleClear =()=>{
     setFilterData({})
     navigate("/app/mic/doctors/total-doctors")
     dispatch(tableDataAction({ url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}`}))
   }
 
  return (
    <DashboardLayout>
        <Card style={{marginTop:"5%"}}>
            <FilterCmp filterArray={filters} filterData={filterData} setFilterData={setFilterData}
              handleSearch={handleSearch} handleClear={handleClear} />
            <TableCmp columns={columns} rows={tableData?.rowData?.dtoList} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage} perPage={perPage} currentPage={currentPage}
              />
        </Card>
    </DashboardLayout>
  )
}

export default OnboardedList