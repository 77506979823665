/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First Name is required.",
    },
    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age  is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },

    gender: {
      name: "gender",
      label: "Gender",
      type: "select",
      errorMsg: "Gender is required.",
    },

    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email Address is required.",
      invalidMsg: "Your email address is invalid",
    },

    phonenumber: {
      name: "phonenumber",
      label: "Phone Number",
      type: "number",
      invalidMsg: "Phone Number must be at least 10 characters.",
      errorMsg: "Phone Number is required.",
    },
  },
};

export default form;
