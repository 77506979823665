
import React from 'react'
import Mcare from'../../findDoctor/assets/images/McareFacilities.png'
import Hassistance from'../../findDoctor/assets/images/healthassistance.png'
import Fwellness from'../../findDoctor/assets/images/Financialwellness.png'
import healthaccessibility from'../../findDoctor/assets/images/healthaccessibility.png'
import Healthplanning1 from'../../findDoctor/assets/images/Healthplanning1.png'
import { Grid,  } from '@mui/material'
import '../MCare/style.css'
const Mcarefacilities = () => {
    const cardsData = [
    {
     
        title: 'Health Assistance',
        image:Hassistance,
      },
      {
    
        title: 'Financial Welness',
        image: Fwellness,
      },
      {
    
        title: 'Health Accessibility',
        image: healthaccessibility,
      },
      {
        
        title: 'Health Planning', 
        image: Healthplanning1,
      },
    ]
  return (
    <div className='M-care-A'>
    <Grid mt={2} > 
      <Grid className='M-care-A-1'>
       <Grid className='M-care-B'>
       <div className='M-care-C' >
        <Grid>
       <Grid className='M-care-D'>Medharva Care Facilities
       </Grid>
       <Grid className='M-care-E' > Enhancing Healthcare with Medharva Care<br/><br/></Grid>
       <div className ='M-care-F' >
  <Grid className='M-care-G'  >
    {cardsData.slice(0, 2).map((i, index) => (
      <Grid className='M-care-H'  key={index} >
       <Grid >
         <img src={i.image} className='M-care-I'/>
         </Grid>
        <Grid className='M-care-J'>
          {i.title}
        </Grid>
      </Grid>
    ))}
  </Grid>
  <Grid className='M-care-K' >
    {cardsData.slice(2).map((i, index) => (
      <Grid className='M-care-L'  key={index} >
        <img src={i.image} className='M-care-I' />
        <Grid className='M-care-M'>
          {i.title}
        </Grid>
      </Grid>
    ))}
  </Grid>
</div>
       </Grid>
       <Grid className='M-care-Y' >
        <img className='M-care-Z'
        src={Mcare}
       
        />
       </Grid>
       </div>
       </Grid>
      </Grid>
    </Grid>
    </div>
  )
}

export default Mcarefacilities

