import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{ clinicname},
} = checkout;

const validations = [
  Yup.object().shape({
   
    [clinicname.name]: Yup.string().required(clinicname.errorMsg),
   

  }),
];

export default validations;
