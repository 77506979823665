// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { MdDelete } from "react-icons/md";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import { useDispatch, useSelector } from "react-redux";
// import { MdOutlineLibraryAdd } from "react-icons/md";

// import CircularProgress from "@mui/material/CircularProgress";
// import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

// import { FaRegEdit } from "react-icons/fa";
// import { getProject, deleteProject } from "../service/Projectcreate";
// import ProjectCreateEdit from "./ProjectCreateedit";
// import ProjectCreate from "./ProjectCreate";
// import MDButton from "components/MDButton";
// import { APIs } from "Services/APIs";
// import { micappCode } from "static/micappCode";
// import { getMicListAction } from "redux/Actions/micListAction";

// function ProjectCreateList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   // const { create, loading } = useSelector((state) => state.create);
//   const { getMicList, loading } = useSelector((state) => state);
//   const { projectpincode } = useSelector((state) => state.projectpincode);
//   // const [loading, setLoading] = useState(true);

//   const [openpopup, setopenpopup] = useState(false);
//   const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);
//   const [item, setItem] = useState({});
//   const [selectedUser, setSelectedUser] = useState(null);

//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     justifyContent: "space-between",
//     alignItems: "center",
//   };

//   function editBrand(item) {
//     setopenpopup(true);
//     setSelectedUser(item);
//   }

//   const handleClose = () => {
//     setopenpopup(false);
//   };

//   function onDelete(item) {
//     // Show a confirmation dialog using SweetAlert
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         // User clicked "Delete," proceed with deletion
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });

//         // Dispatch the deleteBrand action
//         dispatch(deleteProject(item))
//           .then(() => {
//             // After successful deletion, close the Swal dialog
//             swal("Deleted!", "Your item has been deleted.", "success");
//             // Fetch the updated brand list
//           })
//           .catch((error) => {
//             // Handle errors, display an error message, and close the Swal dialog
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         // User clicked "Cancel," do nothing
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   useEffect(() => {
//     // dispatch(getProject({url:`${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}`}));
//     dispatch(getMicListAction({url:`${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}`}));
//   }, [dispatch]);

//   const passData = () => {
//     dispatch(getMicListAction({url:`${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}`}));
//   }

//   const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
//   const iconStyleedit = {
//     fontSize: "1.5em",
//     color: "#039147",
//     marginRight: "9px",
//   };

//   const handleOpenProjectCreate = () => {
//     setopenpopupProjectCreate(true); // Set openpopupProjectCreate to true to open the popup
//   };

//   return (
//     <DashboardLayout>
//       <MDBox>
//         <MDBox mt={1}>
//           <Card>
//             {getMicList?.loading ? (
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   height: "100vh",
//                 }}
//               >
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
//                   <div style={addButtonStyle}>
//                     <span
//                       style={{
//                         alignSelf: "center",
//                       }}
//                     >
//                       <MDTypography variant="h5" fontWeight="medium">
//                         MIC List
//                       </MDTypography>
//                     </span>

//                     <span
//                       style={{
//                         alignSelf: "start",
//                         color: "#344767",
//                       }}
//                     >
//                       <MDButton
//                         color="success"
//                         onClick={handleOpenProjectCreate}
//                       >
//                         ADD
//                       </MDButton>
//                     </span>
//                   </div>
//                 </MDBox>
//                 <DataTable
//                   canSearch={true}
//                   table={{
//                     columns: [
//                       { Header: "name", accessor: "name" },
//                       { Header: "description", accessor: "desc" },
//                       { Header: "Code", accessor: "code" },
//                       {
//                         Header: "Project Hierarchy",
//                         accessor: "projecthirarichalcode",
//                       },

//                       {
//                         Header: "Address",
//                         accessor: "address",
//                         Cell: ({ row }) => {
//                           if (row.original?.pinCode == null) {
//                             return <span>{""}</span>;
//                           }

//                           const {
//                             pinCode: {
//                               village: { name: villageName },
//                               pincode,
//                               subdistrict: { name: subdistrictName },
//                               district: { name: districtName },
//                               state: { name: stateName },
//                             },
//                           } = row?.original;

//                           const address = `${
//                             villageName || ""
//                           }, ${subdistrictName}, ${districtName}, ${stateName} - ${pincode}`;
//                           return <span>{address}</span>;
//                         },
//                       },
//                       {
//                         Header: "Created By",
//                         accessor: "createdby",
//                       },

//                       {
//                         Header: "Action",
//                         accessor: "action",

//                         Cell: ({ row }) => (
//                           <div>
//                             <Link to={{}}>
//                               <FaRegEdit
//                                 style={iconStyleedit}
//                                 onClick={() => {
//                                   editBrand(row.original);
//                                 }}
//                               />
//                             </Link>

//                             <Link to={{}}>
//                               <MdDelete
//                                 style={iconStyle}
//                                 onClick={() => {
//                                   onDelete(row.original);
//                                 }}
//                               />
//                             </Link>
//                           </div>
//                         ),
//                       },
//                     ],
//                     rows: getMicList?.list || [],
//                   }}
//                 />
//               </>
//             )}
//           </Card>
//         </MDBox>
//       </MDBox>
//       {openpopup &&
//       <ProjectCreateEdit
//         openpopup={openpopup}
//         setopenpopup={setopenpopup}
//         title={"MIC Edit"}
//         selectedUser={selectedUser}
//         handleClose={handleClose}
//       />}
// {openpopupProjectCreate &&(
//     <ProjectCreate
//     openpopupProjectCreate={openpopupProjectCreate}
//     setopenpopupProjectCreate={setopenpopupProjectCreate}
//     passData={passData}
//   />
//   )
// }

//     </DashboardLayout>
//   );
// }

// export default ProjectCreateList;

import { Button, Card, Stack, Tooltip } from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { micappCode } from "static/micappCode";
import { deleteProjectServiceData } from "redux/Actions/getProjectServiceData";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import getMicDetails from "redux/slices/getMicDetails";
import ProjectCreateedit from "./ProjectCreateedit";
import ProjectCreate from "./ProjectCreate";

const ProjectCreateList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const { getProjectTeam, getMicDetails } = useSelector((state) => state);
  // const PER_PAGE = 5;
  const location = useLocation();
  const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.projectServiceApi}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.projectServiceApi}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (filterData?.name || nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.projectServiceApi}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${
            nameFilter || filterData.name
          }`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.projectServiceApi}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        {/* <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip> */}
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    const pinCode = row.pinCode || {};
    const address = `${pinCode.village?.name || ""}, ${
      pinCode.subdistrict?.name || ""
    }, ${pinCode.district?.name || ""}, ${pinCode.state?.name || ""} - ${
      pinCode.pincode || ""
    }`;
    return {
      ...row,
      address,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "desc", label: "Description" },
    { id: "code", label: "Code" },
    { id: "projecthirarichalcode", label: "Parent Hierarchy" },
    { id: "address", label: "Address" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        tableDataAction({
          url: `${APIs.projectServiceApi}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
        })
      );
    }
  };

  const handleClear = () => {
    setFilterData({});
    // navigate("/app/mic/create-mic");
    dispatch(
      tableDataAction({
        url: `${APIs.projectServiceApi}/get?type=${
          micappCode?.mappingcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };
  const handleClose = () => {
    setopenpopup(false);
  };
  const handleClosecreate = () => {
    setopenpopupProjectCreate(false);
  };
  handleClosecreate;
  const passData = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.projectServiceApi}/get?type=${
          micappCode?.mappingcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };
  const handleOpenEditDialog = (row) => {
    setSelectedUser(row);
    setopenpopup(true);
  };
  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteProjectServiceData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            dispatch(
              tableDataAction({
                url: `${APIs.projectServiceApi}/get?type=${
                  micappCode?.mappingcode
                }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
              })
            );
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }
  return (
    <DashboardLayout>
      {loading ? (
        <Card>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                MIC List
              </MDTypography>
              <MDButton
                color="info"
                onClick={() => setopenpopupProjectCreate(true)}
              >
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopup && (
        <ProjectCreateedit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"MIC Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          passData={passData}
        />
      )}
      {openpopupProjectCreate && (
        <ProjectCreate
          openpopupProjectCreate={openpopupProjectCreate}
          setopenpopupProjectCreate={setopenpopupProjectCreate}
          passData={passData}
        />
      )}
    </DashboardLayout>
  );
};
export default ProjectCreateList;
