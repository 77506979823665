import React, { useEffect, useState } from 'react';
import './singhupsellers.css'
import { Grid } from '@mui/material'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import { MoonLoader } from "react-spinners";
import axios from "../../../../axiosinstance";

const SinghupSellers = () => {
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    registeredName: Yup.string().required('Registered Name is required'),
    registrationNumber: Yup.number().required('Registration Number is required'),
    LicenseNumber: Yup.number().required('License Number is required'),
    PrimaryAddress: Yup.string().required('Primary Address is required'),
    WorkingHours: Yup.number().required('Working Hours is required'),
  });
  const handleRequestCallback = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    const formData = {
        name: values.registeredName,
        // age: values.Pharmacylicense,
        emailid: values.LicenseNumber,
        isactive: "INACTIVE",
        rolename: "LAB",
        rolecode: "LB001",
        description:values.PrimaryAddress,    
        mobileno: values.registrationNumber,
        experience:values.WorkingHours,
        profileid: "string",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
        createdby:"",
      };
      await axios
      .post("login-service-mongo/api/loginservice/v2/create", formData)

      .then((response) => {
        swal(
          "Registration Successful!",
          "Your account has been created successfully.",
          "success"
        );
        console.log(values);
        resetForm();
      })
      .catch((error) => {
        console.log(error);
        swal("Oops!", 
        (error.response && error.response.data && error.response.data.aceErrors) ?
          (error.response.data.aceErrors[0]?.errorCode === "K008" ?
            "User Already Registered with the same Email id !!" :
            error.response.data.aceErrors[0]?.errorMessage) :
          "Request failed with status code 404.",
        "warning"
      );
      
    
    resetForm();
    setIsLoading(false);
});
setSubmitting(false);
setIsLoading(false);
};
  return (
    <div className='medharwa-singup-lap-a'>
      <Grid className='medharwa-singup-lap-b' >

     
      <Formik
      initialValues={{
        registeredName: '',
        registrationNumber: '',
        LicenseNumber: '',
        PrimaryAddress: '',
        WorkingHours: ''
      }}
     
      validationSchema ={validationSchema} 
      onSubmit={handleRequestCallback}
    >
      <Form >
        <Grid mt={2} htmlFor="registeredName" className='label-registraion'>Registered Name</Grid>
        <Field
          type="text"
          id="registeredName"
          name="registeredName"
          className='input-text-area'
        />
        <ErrorMessage name="registeredName" component="div" className="error-message" />
        <Grid mt={2} htmlFor="registrationNumber" className='label-registraion'>Registration Number</Grid>
        <Field
          type="number"
          id="registrationNumber"
          name="registrationNumber"
          className='input-text-area'
        />
        <ErrorMessage name="registrationNumber" component="div" className="error-message" />
         <Grid mt={2} htmlFor="LicenseNumber" className='label-registraion'>License Number</Grid>
        <Field
          type="number"
          id="LicenseNumber"
          name="LicenseNumber"
          className='input-text-area'
        />
        <ErrorMessage name="LicenseNumber" component="div" className="error-message" />
  <Grid mt={2} htmlFor="PrimaryAddress" className='label-registraion'>Primary Address</Grid>
        <Field
          type="text"
          id="PrimaryAddress"
          name="PrimaryAddress"
          className='input-text-area'
        />
          <ErrorMessage name="PrimaryAddress" component="div" className="error-message" />
          <Grid mt={2} htmlFor="WorkingHourrs" className='label-registraion'>Working hours</Grid>
        <Field
          type="number"
          id="WorkingHours"
          name="WorkingHours"
          className='input-text-area'
        />
          <ErrorMessage name="WorkingHours" component="div" className="error-message" />
        {/* Submit Button */}
       
       <Grid  container justifyContent={'center'}>
       {/* <button type="submit" className='singup-aply-btn'>Apply Now </button> */}
       <button type="submit" className="singup-aply-btn-phramyicist" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#FFFFFF" size={20} /> : "Apply Now"}
        </button>
       </Grid>
      </Form>
    </Formik>
      </Grid>
    </div>
  )
}

export default SinghupSellers
