import { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import "./style.css"; // CSS file for styling
import swal from 'sweetalert';
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import FileUpload from "react-material-file-upload";
import axios from "../../../axiosinstance";
import { Formik, Form, Field, ErrorMessage } from "formik";
import hand from "../../../assets/images/hand.png"  //image
import * as Yup from "yup";
import { useSelector } from "react-redux";
const validationSchema = Yup.object().shape({

  description: Yup.string().required("Description is required"),
  date: Yup.string().required("Prescription Date is required"),

  category: Yup.string().required("Category is required"),

  familymember: Yup.string().required("Family member is required"),
});
const UploadDocument = (props) => {
  const { tittle, uploadpopup, setUploadpopup, getData } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [files, setFiles] = useState([]);
  const [submitFlag, setSubmitFlag] = useState(false);
  const [familydata, setFamilydata] = useState([]);
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("login-auth")));
  const [profileid, setProfileid] = useState(loginUser?.profileid);

  const [uploadBy, setUploadBy] = useState(loginUser?.name);

  // file upload function===========
  function onfileChange(e) {
    console.log(e);
    setFiles(e);
    setSubmitFlag(true);
  }

  // familydata.forEach((option) => {

  //   if (
  //     option.name === localStorage.getItem("profile-Name")?.replace(/"/g, "")
  //   ) {

  //     option.name = "Self";
  //   }
  //   return option;
  // });


  //handle submit===================
  const handleSubmit = (values) => {
    const formData = new FormData();

    // Update the formData object
    formData.append("file", files[0]);
    const request = {
      resourcecode: "MEDHARVA",
      username: uploadBy,

      doctype: values.category,
      date: values.date,
      description: values.description,
      model: values.familymember,
      code: profileid,
    };
    const myJSON = JSON.stringify(request);
    formData.append("body", myJSON);

    axios
      .post(
        // "bulk-import/api/bulk_import/v1/upload",
        "new-upload-service-mongo/api/attachment/upload",
        formData
      )

      .then((response) => {
        getData()
        swal({
          content: {
            element: 'img',
            attributes: {
              src: hand,
              width: 165,
              height: 150
            },
          },
          title: 'Uploaded successfully.',
        });

      })
      .catch((error) => {
        console.log(error);
      });

    handleClose();
  };
  // close pop-up function=================
  const handleClose = () => {
    setFiles([]);
    setSubmitFlag(false);
    setUploadpopup(false);
  };

  async function get(profileid) {

    await axios
      .get(
        `login-service-mongo/api/loginservice/v2/get?createdby=${profileid}`
      )
      .then((response) => {



        const newData = [...response.data?.data?.dtoList, auth];

        setFamilydata(newData);


        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }

  useEffect(() => {
    get(auth.profileid);
  }, []);
  return (
    <Dialog open={uploadpopup} onClose={handleClose}>
      <DialogTitle style={{ backgroundColor: "#039147", color: "#fff", textAlign: "center" }}>{tittle}</DialogTitle>
      <DialogContent>
        <div className="form-container">
          <Formik
            initialValues={{ name: "", description: "",date:"", category: "", familymember: "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>

              <div className="form-group">
                <label htmlFor="description">Description:</label>
                <Field
                  type="textarea"
                  id="description"
                  name="description"
                  className="form-input"
                  style={{ width: "450px", height: "45px", backgroundColor: "white" }}
                />
                <ErrorMessage
                  name="description"
                  component="span"
                  className="error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="date">Prescription Date:</label>
                <Field
                  type="date"
                  id="date"
                  name="date"
                  className="form-input"
                  style={{ width: "450px", height: "45px", backgroundColor: "white" }}
                />
                <ErrorMessage
                  name="date"
                  component="span"
                  className="error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="familymember">Family Member:</label>
                <Field
                  as="select"
                  id="familymember"
                  name="familymember"
                  className="form-input"
                >
                  <option value="">Select a Family Member</option>
                  {familydata.map((option, index) => (
                    <option key={option.id} value={option.profileid}>
                      {option.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage
                  name="familymember"
                  component="span"
                  className="error"
                />
              </div>
              <div className="form-group">
                <label htmlFor="category">Category:</label>
                <Field
                  as="select"
                  id="category"
                  name="category"
                  className="form-input"
                >
                  <option value="">Select a category</option>
                  <option value="Lab Test">Lab Test</option>
                  <option value="Prescription">Prescription</option>
                  <option value="General">General</option>
                </Field>
                <ErrorMessage
                  name="category"
                  component="span"
                  className="error"
                />
              </div>

              <MDBox
                className="Upload"
                style={{
                  marginRight: "0px",
                  marginLeft: "0px",
                  marginTop: "10px",
                }}
              >

                <h5 style={{ margin: "1px", textAlign: "center" }}>
                  Please Select the file from Your System which you want to upload
                </h5>
                <FileUpload
                  buttonText={<span style={{ color: "white" }}>Upload</span>}
                  value={files}
                  onChange={onfileChange}
                />
                {/* {!loading && ( */}
                <MDButton
                  disabled={!submitFlag}
                  style={{ margin: "10px" }}
                  type="submit"
                  variant="gradient"
                  color="dark"
                >
                  {/* {finalSubmitLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Final Upload"
                )} */}
                  submit
                </MDButton>
                {/* )} */}
              </MDBox>
            </Form>
          </Formik>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default UploadDocument;
