import React from "react";
import "./hover.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import { Grid } from "@mui/material";
const DoctorNavbar = () => {
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  return (
    <div className="popover__content">
      {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
  
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   </Grid>
   <Grid item xs={12} sm={6}>
   {auth?.name}
 
   </Grid>
   </Grid> */}
   <div className="modal-area">
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   <p style={{ marginLeft: "10px",fontSize:"16px" }}>{auth?.name}</p>
   </div>


    
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person
        </Icon>

        <Link to="/app/doctor/profile">My Profile</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          check_circle
        </Icon>
        <Link to="/app/pages/users/my-booking">My Booking</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          local_hospital
        </Icon>

        <Link to="/app/page/patient-queue">Patient Queue</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          medical_services
        </Icon>
        <Link to="/app/page/my-Prescription">My Prescription</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          search
        </Icon>
        <Link to="/app/page/search-patient">Search Patient</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person_add
        </Icon>
        <Link to="/app/page/create-nurse">Create Nurse</Link>
      </div>
     <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/create-price">Create Price</Link>
      </div> 
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          logout
        </Icon>
        <a href="#" onClick={() => reduxDispatch({ type: "LOGOUT_FULFILLED" })}>
  Log Out
</a>

      </div>
    </div>
  );
};

export default DoctorNavbar;
