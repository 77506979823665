import { Card, Grid, IconButton, TextField } from "@mui/material";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Socket, io } from "socket.io-client";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import VideocamIcon from "@mui/icons-material/Videocam";
import VideocamOffIcon from "@mui/icons-material/VideocamOff";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ScreenShareIcon from "@mui/icons-material/ScreenShare";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ChatIcon from "@mui/icons-material/Chat";
import "./Room.css";
import { useReactMediaRecorder } from "react-media-recorder";
import axios from '../../axiosinstance';
import { swalMessage } from "static/swalMessage/swalMessage";
import { APIs } from "Services/APIs";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";

// const URL = "http://localhost:3000";
const URL = "https://softalk.softwaremathematics.com/";

export const Room = ({ name, roomId, localAudioTrack, localVideoTrack, setlocalVideoTrack, setLocalAudioTrack, setOrderNumber }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [lobby, setLobby] = useState(true);
    const [socket, setSocket] = useState(null);
    const [sendingPc, setSendingPc] = useState(null);
    const [receivingPc, setReceivingPc] = useState(null);
    const [remoteVideoTrack, setRemoteVideoTrack] = useState(null);
    const [remoteAudioTrack, setRemoteAudioTrack] = useState(null);
    const [remoteMediaStream, setRemoteMediaStream] = useState(null);
    const remoteVideoRef = useRef();
    const localVideoRef = useRef();
    const [micOn, setMicOn] = useState(true);
    const [videoOn, setVideoOn] = useState(true);
    const [chatOpen, setChatOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const [timeElapsed, setTimeElapsed] = useState(0);
    const navigate = useNavigate();
    const [remoteUserName, setRemoteUserName] = useState("");
    const [userCount, setUserCount] = useState(0);

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
    });
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
    const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ screen: true, video: true, audio: true });
    const [blobUrl, setBlobUrl] = useState(mediaBlobUrl);

    const iceServers = {
        iceServers: [
            {
                urls: "stun:stun.l.google.com:19302",

            },
            {

                // urls: "turn:167.86.78.5:3478",
                // username: "gaurav",
                // credential: "gaurav123",
                urls: "turn:japi.softwaremathematics.com",
                username: "admin",
                credential: "admin",

            },
        ]
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeElapsed((prevTime) => prevTime + 1);
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (seconds) => {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;
        return `${hrs.toString().padStart(2, "0")}:${mins
            .toString()
            .padStart(2, "0")}:${secs.toString().padStart(2, "0")}`;
    };

    const videoCallbutton = {
        height: "60px",
        width: "60px",
        borderRadius: "100%",
        color: "#039147",
        boxShadow: "0px 2px 4.3px 0px #00000040",
        padding: "0",
        "&:hover": {
            backgroundColor: "#039147",
            color: "#ffffff",
            boxShadow: "0px 2px 4.3px 0px #00000040",
        },
        "&:focus:not(:hover)": {
            backgroundColor: "#039147",
            color: "#ffffff",
            boxShadow: "0px 2px 4.3px 0px #00000040",
        },
    };

    const endVideoCallBtn = {
        color: "#ffffff",
        height: "60px",
        width: "60px",
        borderRadius: "100%",
        boxShadow: "0px 2px 4.3px 0px #00000040",
        padding: "0",
    };

    const toggleMic = () => {
        if (localAudioTrack) {
            localAudioTrack.enabled = !localAudioTrack.enabled;
            setMicOn(localAudioTrack.enabled);
        }
    };

    const toggleVideo = () => {
        if (localVideoTrack) {
            localVideoTrack.enabled = !localVideoTrack.enabled;
            setVideoOn(localVideoTrack.enabled);
        }
    };

    const startScreenShare = async () => {
        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
            });
            const screenTrack = screenStream.getVideoTracks()[0];
            const sender = sendingPc
                .getSenders()
                .find((sender) => sender.track.kind === "video");

            if (sender) {
                sender.replaceTrack(screenTrack);
            }

            screenTrack.onended = () => {
                const sender = sendingPc
                    .getSenders()
                    .find((sender) => sender.track.kind === "video");
                if (sender) {
                    sender.replaceTrack(localVideoTrack);
                }
                setVideoOn(true);
            };

            setVideoOn(false);
        } catch (error) {
            console.error("Error starting screen share:", error);
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(
            `${window.location.origin}${window.location.pathname}`
        );
        setCopied(true);
        setTimeout(() => setCopied(false), 1500);
    };

    useEffect(() => {
        const socket = io(URL, {
            query: {
                user: name,
                roomId: roomId,
            },
        });

        socket.on("user-count-update", ({ roomId, count }) => {
            setUserCount(count);
        });

        socket.on("send-offer", async ({ roomId, senderName }) => {
            console.log("sending offer");
            setLobby(false);
            const pc = new RTCPeerConnection(iceServers);

            setSendingPc(pc);
            if (localVideoTrack) {
                console.error("added tack");
                console.log(localVideoTrack);
                pc.addTrack(localVideoTrack);
            }
            if (localAudioTrack) {
                console.error("added tack");
                console.log(localAudioTrack);
                pc.addTrack(localAudioTrack);
            }

            pc.onicecandidate = async (e) => {
                console.log("receiving ice candidate locally");
                if (e.candidate) {
                    socket.emit("add-ice-candidate", {
                        candidate: e.candidate,
                        type: "sender",
                        roomId,
                    });
                }
            };

            pc.onnegotiationneeded = async () => {
                console.log("on negotiation neeeded, sending offer");
                const sdp = await pc.createOffer();
                //@ts-ignore
                pc.setLocalDescription(sdp);
                socket.emit("offer", {
                    sdp,
                    roomId,
                    senderName,
                });
            };
        });

        socket.on("offer", async ({ roomId, sdp: remoteSdp, senderName }) => {
            console.log("received offer");
            setLobby(false);
            const pc = new RTCPeerConnection(iceServers);

            pc.setRemoteDescription(remoteSdp);
            const sdp = await pc.createAnswer();
            //@ts-ignore
            pc.setLocalDescription(sdp);
            const stream = new MediaStream();
            if (remoteVideoRef.current) {
                remoteVideoRef.current.srcObject = stream;
            }

            setRemoteMediaStream(stream);
            // trickle ice
            setReceivingPc(pc);
            setRemoteUserName(senderName);
            window.pcr = pc;
            pc.ontrack = (e) => {
                alert("ontrack");
            };

            pc.onicecandidate = async (e) => {
                if (!e.candidate) {
                    return;
                }
                console.log("omn ice candidate on receiving seide");
                if (e.candidate) {
                    socket.emit("add-ice-candidate", {
                        candidate: e.candidate,
                        type: "receiver",
                        roomId,
                    });
                }
            };

            socket.emit("answer", {
                roomId,
                sdp: sdp,
            });
            setTimeout(() => {
                let track1 = pc?.getTransceivers()?.[0]?.receiver?.track;
                let track2 = pc?.getTransceivers()?.[1]?.receiver?.track;
                console.log(track1);
                if (track1.kind === "video") {
                    setRemoteAudioTrack(track2);
                    setRemoteVideoTrack(track1);
                } else {
                    setRemoteAudioTrack(track1);
                    setRemoteVideoTrack(track2);
                }
                //@ts-ignore
                remoteVideoRef?.current?.srcObject?.addTrack(track1);
                //@ts-ignore
                remoteVideoRef?.current?.srcObject?.addTrack(track2);
                //@ts-ignore
                remoteVideoRef?.current?.play();
            }, 3000);
        });

        socket.on("answer", ({ roomId, sdp: remoteSdp }) => {
            setLobby(false);
            setSendingPc((pc) => {
                pc?.setRemoteDescription(remoteSdp);
                return pc;
            });
            console.log("loop closed");
        });

        socket.on("lobby", () => {
            setLobby(true);
        });

        socket.on("add-ice-candidate", ({ candidate, type }) => {
            console.log("add ice candidate from remote");
            console.log({ candidate, type });
            if (type == "sender") {
                setReceivingPc((pc) => {
                    if (!pc) {
                        console.error("receicng pc nout found");
                    } else {
                        console.error(pc.ontrack);
                    }
                    pc?.addIceCandidate(candidate);
                    return pc;
                });
            } else {
                setSendingPc((pc) => {
                    if (!pc) {
                        console.error("sending pc nout found");
                    } else {
                        // console.error(pc.ontrack)
                    }
                    pc?.addIceCandidate(candidate);
                    return pc;
                });
            }
        });

        setSocket(socket);
    }, [name]);

    useEffect(() => {
        if (localVideoRef.current) {
            if (localVideoTrack) {
                const stream = new MediaStream([localVideoTrack]);
                localVideoRef.current.srcObject = stream;
                localVideoRef.current?.play()
                if(auth.rolecode === ROLE_CODE.doctorcode) startRecording()
                    .catch((error) => console.error("Play error:", error));
            } else {
                localVideoRef.current.srcObject = null;
            }
        }
    }, [localVideoRef]);

    const turnOffMediaDevices = () => {
        if (localVideoTrack) {
            localVideoTrack.stop();
        }

        if (localAudioTrack) {
            localAudioTrack.stop();
        }

        if (localVideoRef.current && localVideoRef.current.srcObject) {
            localVideoRef.current.srcObject
                .getTracks()
                .forEach((track) => track.stop());
            localVideoRef.current.srcObject = null;
        }
    };

        useEffect(() => {
            return () => {
                handleEndCall();
            }
        }, [])

    const handleEndCall = () => {
        console.log("Handle end call is called!")
        if (localAudioTrack) {
            localAudioTrack.stop();
            setLocalAudioTrack(null);
        }

        if (localVideoTrack) {
            localVideoTrack.stop();
            setlocalVideoTrack(null);
        }

        if (sendingPc) {
            sendingPc.close();
        }

        if (receivingPc) {
            receivingPc.close();
        }

        if(auth.rolecode !== ROLE_CODE.doctorcode){
            navigate(-1);
        }else{
            setOrderNumber("")
            // return;
        }

    };

    // --------------------------------------------  Video Recordings  -------------------------------------------------


    const saveVideo = async (blob) => {
        console.log("Save video is called 393");
        console.log("blobbbb", blob);
        setOrderNumber(null);
    
        if (blob instanceof Blob) {
            const fileName = `${blob.recFileName || 'recorded-video.webm'}`;
            const file = new File([blob], fileName, { type: blob.type });
    
            console.log("Converted Blob to File", file);
    
            const formData = new FormData();
            formData.append("video", file);
    
            try {
                const response = await axios.post(`${APIs.newUploadServiceMongoApi}/${roomId}`, formData);
                handleEndCall();
                swalMessage('success', "Video has been uploaded successfully!");
            } catch (error) {
                swalMessage('error', "Error uploading video to the API");
                handleEndCall();
                console.error("Error fetching data from the API: ", error);
            }
        } else {
            console.error("Invalid Blob data received");
            handleEndCall();
        }
    };

    const handleMediaBlobUrl = async(mediaBlobUrl) => {
        console.log("handle Media BlobUrl called")
        console.log(mediaBlobUrl, "mediaBlobUrl 450")
        // stopRecording();
        if (mediaBlobUrl) {
            fetch(mediaBlobUrl)
                .then(response => response.blob())
                .then(blob => {
                    console.log(blob , "Blob response data 455");
                    // setRecordedVideoURL(URL.createObjectURL(blob));
                    // uploadVideo(blob);
                    saveVideo(blob);
                })
                .catch(error => {
                    console.log(error, "handle media error 461")
                    console.error('Error converting mediaBlobUrl to Blob:', error);
                    handleEndCall();
                });
        }
    };

    const handleStopRecording = async() => {
        console.log(" handle stop recording is called 445")
        if(auth.rolecode !== ROLE_CODE.doctorcode){
            handleEndCall();
        }else{
            stopRecording();
        }
    }

    console.log(mediaBlobUrl, "mediaBlobUrl 449")
    useEffect(()=>{
        if(mediaBlobUrl && auth.rolecode === ROLE_CODE.doctorcode){
            handleMediaBlobUrl(mediaBlobUrl);
        }else if(mediaBlobUrl){
            handleEndCall();
        }else{
            return;
        }
    },[mediaBlobUrl && mediaBlobUrl])
    

    return (
        <div>
            <Card sx={{ maxHeight: "200vw", position: "relative" }}>
                <Grid container className="videoCallvideo">
                    <Grid item xs={11.5} mt={2} pr={4} className="videoCallvideo3">
                        <Grid item className="videoCallvideo2">
                            <Grid item>
                                {/* <MDButton
                                    sx={{ height: "60px", width: "60px", borderRadius: "100%" }}
                                    onClick={() => {
                                        turnOffMediaDevices();
                                        navigate(-1);
                                    }}
                                >
                                    <KeyboardArrowLeftIcon className="videoCallicon" />
                                </MDButton> */}
                            </Grid>
                            &nbsp; &nbsp;
                            <Grid item>
                                <MDTypography variant="h4">Online Consultation</MDTypography>
                                <div className="videoCallinfo">
                                    {formattedDate} &nbsp; | &nbsp;
                                    <PeopleAltIcon /> &nbsp; {userCount + 1}{" "}
                                    {userCount == 0 ? "User" : "Users"} &nbsp; | &nbsp;
                                    <AccessTimeFilledIcon /> &nbsp; {formatTime(timeElapsed)}
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item className="videoCallvideo2">
                        </Grid>
                    </Grid>

                    <Grid item xs={11} mt={2} className="videoContainer">
                        <Grid item xs={10} md={5.5} className="videoCallvideo">
                            <video autoPlay ref={localVideoRef} className="videoItem" />
                            <span className="nameOverlay">{name}</span>
                        </Grid>
                        <Grid item xs={10} md={5.5} className="videoCallvideo">
                            <video ref={remoteVideoRef} className="videoItem" />
                            <span className="nameOverlay">{remoteUserName}</span>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        mt={1}
                        xs={12}
                        md={6}
                        lg={4}
                        mb={3}
                        className="videoCallvideo2"
                    >
                        <Grid item>
                            <MDButton
                                sx={{
                                    ...videoCallbutton,
                                    backgroundColor: micOn ? "#ffffff" : "#039147",
                                    color: micOn ? "#039147" : "#ffffff",
                                }}
                                onClick={toggleMic}
                            >
                                {micOn ? (
                                    <MicIcon className="videoCallicon" />
                                ) : (
                                    <MicOffIcon className="videoCallicon" />
                                )}
                            </MDButton>
                        </Grid>
                        <Grid item>
                            <MDButton
                                sx={{
                                    ...videoCallbutton,
                                    backgroundColor: videoOn ? "#ffffff" : "#039147",
                                    color: videoOn ? "#039147" : "#ffffff",
                                }}
                                onClick={toggleVideo}
                            >
                                {videoOn ? (
                                    <VideocamIcon className="videoCallicon" />
                                ) : (
                                    <VideocamOffIcon className="videoCallicon" />
                                )}
                            </MDButton>
                        </Grid>
                        {/* <Grid item>
                            <MDButton sx={videoCallbutton} onClick={startScreenShare}>
                                <ScreenShareIcon className="videoCallicon" />
                            </MDButton>
                        </Grid> */}
                        <Grid item>
                            <MDButton
                                sx={endVideoCallBtn}
                                variant="contained"
                                color="error"
                                onClick={handleStopRecording}
                            >
                                <CallEndIcon className="videoCallicon2" />
                            </MDButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};
