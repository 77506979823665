import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    project,
     clinic,
   
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [project.name]: Yup.string().required(project.errorMsg),
    [clinic.name]: Yup.string().required(clinic.errorMsg),
      
  }),
 
];

export default validations;
