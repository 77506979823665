import { Card, Dialog } from '@material-ui/core'
import DashboardLayout from 'components/DashboardLayout'
import FilterCmp from 'components/Filters/FilterCmp'
import Negotiate from 'components/Popup/Negotiate'
import TableCmp from 'components/Tables/TableCmp'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { tableDataAction } from 'redux/Actions/tableDataAction';
import Swal, {SweetAlertOptions} from 'sweetalert2';
import { APIs } from 'Services/APIs'
import { directoryFilter } from 'static/filters/directoryFilter';
import tableActionRow, { clearActionOfRow, clearActionRowData } from 'redux/slices/tableActionRow'
const Transactions = () => {

    const { tableData, AuthReducer, tableActionRow } = useSelector(state => state);
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData]= useState()
    const [filterData, setFilterData] = useState({})
    const [openNegotiate, setOpenNegotiate] = useState(false);
    const [negotiateValue, setNegotiateValue] = useState()
    const navigate = useNavigate();
    const PER_PAGE = 5;
    // useEffect(()=>{
    //     dispatch(tableDataAction({ page:"directory", profileId:AuthReducer?.auth?.profileid}))
    // },[])

    // useEffect(()=>{
    //     dispatch(tableDataAction({name:"currentPage", value:currentPage, page:"allList"}))
    // },[currentPage])
    useEffect(async()=>{
      dispatch(tableDataAction({ url:`${APIs.getProfileList+AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${PER_PAGE}`}))
    },[])
    
    useEffect(()=>{
      dispatch(tableDataAction({ url:`${APIs.getProfileList+AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${PER_PAGE}`}))
  },[currentPage])
  useEffect(() => {
  const rowData = [
    {
        nurseName: "Dr. John Doe",
        agentComission: "$300",
        nurseComission: "$700",
        view: "Pending"
      },
      {
        nurseName: "Dr. Jane Smith",
        agentComission: "$250",
        nurseComission: "$650",
        view: "Pending"
      },
      {
        nurseName: "Dr. Emily Johnson",
        agentComission: "$400",
        nurseComission: "$800",
        view: "Paid"
      },
      {
        nurseName: "Dr. Michael Brown",
        agentComission: "$350",
        nurseComission: "$750",
        view: "Paid"
      }
  ];
  setRowData(rowData);
}, []);

  const columns = [
    { id:"nurseName", label: "Nurse's Name", accessor: "nurseName" },
    { id:"nurseComission",  label: "Nurse Comission", accessor: "nurseComission" },
    { id:"agentComission",  label: "Agent Comission", accessor: "agentComission" },
    
   
    
    
    {
      id: "view",
      label: "Transaction Status",
      accessor: "view",
      Cell: ({ row }) => (
        <button onClick={() => handleRowAction("view", row)}>View</button>
      )
    },
  

  ]

  const actionButtons = ["view", "negotiate"]

  useEffect(()=>{
    if(tableActionRow.actionType === "negotiate"){
      setOpenNegotiate(true);
      return 
  }else if(tableActionRow.actionType === "view"){
    navigate('/app/mic/doctor/view-profile');
  }
  },[tableActionRow.actionType])

const closeNegotiate =()=> {
      setOpenNegotiate(false);
      dispatch(clearActionRowData())
  }

  // filter handler

  const handleSearch =()=>{
    console.log(filterData, "Filter data")
  }

  const handleClear =()=>{
    setFilterData({})
  }

  return (
    <DashboardLayout>
    <Card style={{marginTop:"5%"}}>
        <FilterCmp filterArray={tableData?.filterArray} filterData={filterData} setFilterData={setFilterData}
          handleSearch={handleSearch} handleClear={handleClear} />
        <TableCmp columns={columns} rows={rowData} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages}   />
    </Card>
</DashboardLayout>
  )
}

export default Transactions;