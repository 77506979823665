import React, { useEffect, useState } from "react";
import {
  Card,
  FormControlLabel,
  Switch,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Autocomplete,
} from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import DataTable from "examples/Tables/DataTable";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableVisit from "components/Skeleton/SkeletonTableVisit";
import { ROLE_CODE } from "privateComponents/codes";

function VisitCategory({ sendDataToVisit }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [usercategory, setUsercategory] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [open, setOpen] = useState(false);
  const {getMMUDetails, getProjectDetails } = useSelector(state => state)
  const profileId = JSON.parse(localStorage.getItem("login-auth"))?.profileid;
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const toggler = async (checked, request, matchcategory) => {

    if(ROLE_CODE?.labcode === auth?.rolecode && getProjectDetails?.details?.mmucreation === null){
      swal("You can not perform this action, because you are not assign with lab", {
        icon: 'error',
      })
      return;
    }

    const body = {
      recstatus: "OPEN",
      syncstatus: "SYNCED",
      createdby: auth.profileid,
      mmucode:auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode ? getProjectDetails?.details?.mmucreation?.code : "",
      id: checked === true ? null : matchcategory?.id,
      status: checked === true ? "ACTIVE" : "INACTIVE",
      profileid: auth.profileid,
      resourcecode: "HEALTHCARE",
      category: request,
      mmucode: getProjectDetails?.details?.mmucreation?.code
    };
    try {
      await axios.post(`${APIs.userCatgoryServiceApi}/create`, body);
      masterVisitList();
      //    getUserVisit(auth?.profileid);
    } catch (error) {
      console.log("Create visit error", error);
    }
  };

  const columns = [
    { id: "name", label: "Name" },
    { id: "action", label: "Action" },
  ];
  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];
  const masterVisitList = async () => {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };

    try {
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/get?type=${
          auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode? "labvisit" : "VISIT"
        }`
      );
      const masterData = response?.data.data?.dtoList;
      sendDataToVisit(masterData);
const api = ROLE_CODE?.labcode === auth?.rolecode || ROLE_CODE?.childlabcode === auth?.rolecode ? `${APIs.userCatgoryServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}`:`${APIs.userCatgoryServiceApi}/get?createdby=${auth.profileid}`;
      const updatedUserCategory = await axios.get(
        api
      );
      const userCategoryData = updatedUserCategory?.data?.data?.dtoList;
      setUsercategory(userCategoryData);

      masterData.forEach((item) => {
        const matchedCategory = userCategoryData.find(
          (url) => url?.category?.code === item?.code
        );

        if (matchedCategory) {
          if (matchedCategory.status === "ACTIVE") {
            item.action = (
              <FormControlLabel
                name={matchedCategory.id}
                control={
                  <Switch
                    onClick={(e) =>
                      toggler(e.target.checked, item, matchedCategory)
                    }
                    defaultChecked
                  />
                }
                label=""
              />
            );
          } else if (matchedCategory.status === "INACTIVE") {
            item.action = (
              <FormControlLabel
                name={matchedCategory.id}
                control={
                  <Switch
                    onClick={(e) =>
                      toggler(e.target.checked, item, matchedCategory)
                    }
                  />
                }
                label=""
              />
            );
          }
        } else {
          item.action = (
            <FormControlLabel
              name={item.id}
              control={
                <Switch
                  onClick={(e) =>
                    toggler(e.target.checked, item, matchedCategory)
                  }
                />
              }
              label=""
            />
          );
        }

        item.fcreateddate = new Date(item.createddate).toDateString();
        dataObj.rows.push(item);
      });

      setTableDatas(dataObj);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  };

  useEffect(() => {
    masterVisitList();
  }, []);

  return (
    // <MDBox pt={3}>
    //   <MDBox>
    //     <Card>
    //       <MDBox pl={3} pr={3} lineHeight={1}>
    //         <div style={addButtonStyle}>
    //           <span
    //             style={{
    //               alignSelf: "flex-start",
    //               marginTop: "20px",
    //             }}
    //           >
    //             <MDTypography variant="h5" fontWeight="medium">
    //               Visit Category List
    //             </MDTypography>
    //           </span>
    //         </div>
    //       </MDBox>
    //       <BarLoader
    //         color={color}
    //         loading={loading}
    //         aria-label="Loading Spinner"
    //       />
    //       {!loading && <DataTable table={tableDatas} canSearch={true} />}
    //     </Card>
    //   </MDBox>
    // </MDBox>
    <MDBox pt={3}>
      <MDBox>
        <Card>
          <MDBox pl={3} pr={3} lineHeight={1}>
            <div style={addButtonStyle}>
              <span
                style={{
                  alignSelf: "flex-start",
                  marginTop: "20px",
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  Visit Category List 
                </MDTypography>
              </span>
            </div>
          </MDBox>
          {loading ? (
            <Card style={{ marginTop: "5%" }}>
              {/* <SkeletonFilterCmp filterArray={filters} /> */}
              <SkeletonTableVisit columns={columns} perPage={10} rows={3} />
            </Card>
          ) : (
            <DataTable table={tableDatas} canSearch={true} />
          )}
        </Card>
      </MDBox>
    </MDBox>
  );
}

export default VisitCategory;
