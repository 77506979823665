import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import validations from "components/Resuable/Create Nurse/schemas/validations";
import form from "components/Resuable/Create Nurse/schemas/form";
import initialValues from "components/Resuable/Create Nurse/schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "axiosinstance";
import Dialog from "@mui/material/Dialog";
import NurseInfo from "components/Resuable/Create Nurse/components/NurseInfo";
import { ROLE_CODE } from "privateComponents/codes";
import { CircularProgress } from "@mui/material";
import { labappCode } from "static/labappCode";

function getSteps() {
  return ["Frequency Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <NurseInfo formData={formData} />;
    default:
      return null;
  }
}

export default function MyTeamPouup(props) {
  const { popup, setPopup, getApiResponse, setLoader, loader } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.disease);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectDetails } = useSelector((state) => state);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const createTeamassociation = async (profileobj, actions) => {
    const requestData = {
      // mmucode: getProjectDetails?.details?.mmucreation?.code,
      mmuname: getProjectDetails?.details?.mmucreation?.name,
      mmucode: profileobj?.mmucode,
      createdby: auth?.profileid,
      createdbyname: auth?.name,
      profileid: profileobj?.profileid,
      rolecode: profileobj?.rolecode,
      rolename: profileobj?.rolename,
      fullname: profileobj?.name,
      firstname: profileobj?.firstname,
      lastname: profileobj?.lastname,
      type: labappCode?.mappingcode,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
    };

    const response = await axios.post(
      `${APIs?.mmuteamAssociation}/create`,
      requestData
    );
    if (!response?.error) {
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });

      setIsLoading(false);
      actions.setSubmitting(false);
      actions.resetForm();
      setActiveStep(0);
      getApiResponse(auth?.profileid);
      handleClose();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text: error?.message || "something wents wrong",
      });
      setIsLoading(false);

      handleClose();
    }
  };

  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const getData = {
      age: values?.age,
      sex: values?.gender,
      emailid: values.email,
      name: values?.lastName
        ? values.firstName + " " + values.lastName
        : values.firstName,
      firstname: values.firstName,
      lastname: values.lastName,
      isactive: "ACTIVE",
      rolename: "CollectiveAgent",
      rolecode: ROLE_CODE?.collectiveagentcode,
      logincount: "7",
      type: labappCode?.mappingcode,
      mobileno: values.phonenumber,
      password: values.password,
      recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",
      mmucode: getProjectDetails?.details?.mmucreation?.code,
      createdby: auth?.profileid,
    };

    await axios

      .post(`${APIs.loginServiceApi}/create`, getData)
      .then((response) => {
        createTeamassociation(response?.data?.data?.dto, actions);
      })
      .catch((error) => {
        swal(
          "Oops!",
          error?.response &&
            error.response?.data &&
            error?.response?.data?.aceErrors
            ? error?.response?.data?.aceErrors?.[0]?.errorCode === "K008"
              ? "User Already Registered with the same Email id !!"
              : error?.response?.data?.aceErrors?.[0]?.errorMessage
            : "Request failed with status code 404.",
          "warning"
        );
        handleClose();
        setIsLoading(false);
      });
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
  const handleClose = () => {
    setPopup(false);
  };

  return (
    <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-2}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Add New Team Member
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  {loader ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "30vh",
                      }}
                    >
                      <CircularProgress
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                        }}
                      />
                    </div>
                  ) : (
                    <>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <MDBox
                            mt={5}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                              >
                                Back
                              </MDButton>
                            )}
                            {/* <Grid item xs={12} sm={9}>
                          <MDButton
                            variant="gradient"
                            color="info"
                            // size="small"
                            onClick={handleClose}
                          >
                            Cancel
                          </MDButton>
                        </Grid> */}
                            <MDButton
                              type="submit"
                              variant="gradient"
                              color="info"
                              // disabled={isLoading}
                            >
                              {isLoading ? (
                                <MoonLoader color="#f2fefa" size={16} />
                              ) : (
                                "Submit"
                              )}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </>
                  )}
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
