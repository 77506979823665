import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import imag1 from "../../../../assets/images/consultwithdr.png";
import imag2 from "../../../../assets/images/Ordermedicines.png";
import imag3 from "../../../../assets/images/Viewmedicialrecord.png";
import imag4 from "../../../../assets/images/booktest.png";


import { Button, Grid } from "@mui/material";

import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";

const Cards = () => {
const navigate =useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [user, setUser] = useState("home")

  useEffect(()=>{
    if(auth?.rolecode === ROLE_CODE.patientcode){
      setUser("patient")
    }else if(auth?.rolecode === ROLE_CODE.nursecode){
      setUser("nurse")
    }

  },[auth])

  const cardsData = [
    {
      id:"1",
      logo: imag1,
      description: "consult with a doctor",
      link: "+ {`/app/${user}/speciality`}+",
    },
    {
      id:"2",
      logo: imag2,
      description: "order medicines",
      link: "/app/nurse/patient-prescription",
    },
    {
      id:"3",
      logo: imag3,
      description: "view medical report",
      link: "/app/nurse/patient-prescription",
    },
    {
      id:"4",
      logo: imag4,
      description: "Book test",
      link: "/app/nurse/patient-prescription",
    },
   
  ];
  const handleCardClick = (card) => {
    if (!auth || !auth.rolecode) {
      // Set the dynamic navigation link in local storage
   
  
      // Delay the navigation to the sign-in page to allow time for localStorage to be set
      setTimeout(() => {
        navigate("/app/login");
      }, 500);
      localStorage.setItem("dynamic-navigation", card.link);
    } else {
    navigate(card.link)
    }
  };
  
  
  
  return (
    <Grid mt={5} className="card-Border-button">
      <div className="card-Border-button-A">
        {cardsData.map((card, index) => (
          <Grid
            className="card-Border-button-AB"
            key={index}
            sx={{ textAlign: "center" }}
          >
            <Link to="">
              <img className="card-img1" src={card.logo} alt={`Card ${index}`} />
              <Grid>
                <Button
                  className="card-Border-button-D"
                  sx={{
                    color: "#7E7E7E",
                    "&:hover": { backgroundColor: "transparent" },
                    fontWeight: "600",
                    wordWrap: "break-word",
                    fontSize: "20px",
                    textTransform: "none",
                    paddingTop: "1%",
                  }}
                  onClick={() => handleCardClick(card)}
                >
                  {card.description}
                </Button>
              </Grid>
            </Link>
          </Grid>
        ))}
      </div>
    </Grid>
  );
};

export default Cards;
