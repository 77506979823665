export const variables = {
    showFeeType:"fees",
    assign: "ASSIGNED",
    approved : "APPROVED",
    address:[
        {name:"1 KM", value:"1000",id:1},
        {name:"3 KM", value:"3000",id:2},
        {name:"5 KM", value:"5000",id:3},
        {name:"7 KM", value:"7000",id:4},
        {name:"10 KM", value:"10000",id:5},
    ]
    
}