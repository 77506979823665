import { Autocomplete, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Stack, Switch, Tooltip } from '@mui/material'
import FilterCmp from 'components/Filters/FilterCmp.tsx'
import DataTable from 'components/Tables/DataTable'
import axios from "../../../axiosinstance";
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { tableDataAction } from 'redux/Actions/tableDataAction'
import { directoryFilter } from 'static/filters/directoryFilter';
import TableCmp from 'components/Tables/TableCmp';
import { APIs } from 'Services/APIs'
import Swal, { SweetAlertOptions } from 'sweetalert2';
import tableActionRow, { clearActionOfRow, clearActionRowData, setActionTypeRow } from 'redux/slices/tableActionRow'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import SkeletonFilterCmp from 'components/Skeleton/SkeletonFilterCmp'
import SkeletonTableCmp from 'components/Skeleton/SkeletonTableCmp'
import DashboardLayout from 'components/DashboardLayout'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDButton from 'components/MDButton'
import MDInput from 'components/MDInput'
import {
  deleteLoginServiceData,
  getLoginServiceData,
} from "redux/Actions/getLoginServiceData";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import MDBadgeDot from 'components/MDBadgeDot';
import { pincodeAssociationAction } from 'redux/Actions/getPincodeAssociationAction';
import { FaRegEdit } from 'react-icons/fa';
import { MdDelete, MdOutlineLibraryAdd } from 'react-icons/md';
import { labappCode } from 'static/labappCode';
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import TrackStatus from 'components/TrackStatus';
import PreviewIcon from "@mui/icons-material/Preview";
import { ROLE_CODE } from 'privateComponents/codes';
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import UploadReport from 'app/pharmacy/uploadReport/UploadReport';
import MyTeamPouup from 'app/lab/MyTeamPopup';
// import MyTeamPouup from 'app/labTest/labTechnician/MyTeamPopup';

const Myteam = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { tableData, AuthReducer, tableActionRow, getMMUDetails, getProjectDetails } = useSelector(state => state)
  const { loading } = useSelector(state => state.tableData)
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState()
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10)
  // const [selectedmmu, setSelectedmmu] = useState({});
  const iconStyleedit = { fontSize: "1.5em", cursor: 'pointer', color: '#039147' }; 
  const iconStyle = { fontSize: "1.5em", cursor: 'pointer', color: 'red' };
  const [popup, setPopup] = useState(false);
  const [loader, setLoader] = useState(false);
  const location = useLocation();
  const iconStyle2 = { color: "#039147", marginRight: "9px", cursor: "pointer" };
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const addButtonStyle = {
    display: "flex",

    fontSize: "2em",

    flexDirection: "column",

    marginRight: "10px",
  };

  const nameFilter = new URLSearchParams(location.search).get('name');

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter })
    getUsers()
  }, [])

  const getUsers = () => {
    if (auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      if (nameFilter) {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${nameFilter}` }))
      } else {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
      }
    }else{
    if (nameFilter) {
      dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${nameFilter}` }))
    } else {
      dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
    }
  }
  }

  useEffect(() => {
    getUsers()
  }, [currentPage, perPage])

  const filters = [
    { type: "text", placeholder: "Enter name", variant: "outlined", label: "Name", name: "name", page: "allList" }
  ]
  
  const actionButtons = (item) => {
    return (
      <span>
         <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                onDelete(item);
              }}
            />
          </div>
        </Tooltip>
    </span>
    )
  }
  const actionButtons2 = (item) => {
    return(
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
    <FormControlLabel
      name={item.id}
      control={
        <Switch
          checked={item?.isactive === "ACTIVE"}
          onClick={(e) => toggler(e.target.checked, item)}
        />
      }
      label=""
    />
  </div>
    )
  }






  const modifiedRows = tableData?.rowData?.dtoList?.map(row => ({
    ...row,
    action: actionButtons(row),
    action2: actionButtons2(row),


  }));

  const columns =  [
    
      { id: "name", label: "Name" },
      { id: "rolename", label: "Role" },
      { id: "emailid", label: "Email Id" },
      { id: "mobileno", label: "Mobile No" },
      { id: "action2", label: "Status" },
      { id: "action", label: "Delete" }
    ]

   
 
  const handleSearch = () => {
    setCurrentPage(1)
    if (auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      if (filterData?.name) {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${nameFilter || filterData.name}` }))
      } else {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
      }
    }else{
      if (filterData?.name) {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${nameFilter || filterData.name}` }))
      } else {
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
      }
    }
   
  }



  const handleClear = () => {
    setFilterData({})
    
    if (auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      
    dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
    }else{

      dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
    }

  }

  const handleClose = () => {
    setopenpopup(false);
   
  };

  const updateTableDataAction = () => {
    if (auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode) {
      
      dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?mmucode=${getProjectDetails?.details?.mmucreation?.code}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
      }else{
  
        dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/get?rolecode=${ROLE_CODE?.nursecode}&createdby=${auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
      }
  }
  function update(object) {
    axios
      .put(`${APIs.loginServiceApi}/update`, object)
      .then((response) => {
        const updatedData = tableData?.rowData?.dtoList?.map(row => 
          row.id === object.id ? { ...row, isactive: response?.data?.data?.dto?.isactive } : row
        );
        swal("", "Status update Successfully.", "success");
        updateTableDataAction();
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const toggler = (checked, request) => {
    const updatedRequest = { ...request };
    delete updatedRequest?.switch;
  
    updatedRequest.isactive = checked ? "ACTIVE" : "INACTIVE";
    
    update(updatedRequest);
  };
  async function onDelete(item) {
   
    swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover this ${item.rolename}.`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
  
      if (willDelete) {
        axios.put(`${APIs.loginServiceApi}/delete`, item).then((response) => {
          updateTableDataAction();
        });
        swal("Poof! Your Data record has been deleted!", {
          icon: "success",
        });
      } else {
        swal(`Your ${item?.name} record is safe!`);
      }
    });
  }
  async function handleAddButton() {

    if(ROLE_CODE?.labcode === auth?.rolecode && getProjectDetails?.details?.mmucreation === null){
      swal("You can not perform this action, because you are not assign with lab", {
        icon: 'error',
      })
      return;
    }

    setPopup(true);
  }

  return (
    <MDBox mt={auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode ? 0 : 17}>
      <MDBox mt={isMidScreen ? 15 : 0}>
        <Card  style={{ marginLeft: auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode
          ? "0px" : "22px", marginRight: auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode
          ? "0px" : "22px" ,marginTop:auth?.rolecode === ROLE_CODE.labcode || auth?.rolecode === ROLE_CODE.childlabcode ? "6%" : "2%"}}>
          <MDBox p={3} lineHeight={1} >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
              My Team
              </MDTypography>
              {auth?.rolecode === ROLE_CODE.labcode ||
                auth?.rolecode === ROLE_CODE.childlabcode ? (
                
                    <MDButton color="info" onClick={handleAddButton}>
                      ADD
                    </MDButton>
                
                ) : (
                  <></>
                )}
            </div>
          </MDBox>
          {loading ? (
        <Card>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <>
          <FilterCmp filterArray={filters} filterData={filterData} setFilterData={setFilterData}
            handleSearch={handleSearch} handleClear={handleClear} />

          <TableCmp columns={columns} rows={modifiedRows} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage} perPage={perPage} currentPage={currentPage}
          />
          </>
      )}
        </Card>
     
        {popup && (
        <MyTeamPouup
          popup={popup}
          setLoader={setLoader}
          loader={loader}
          setPopup={setPopup}
          getApiResponse={updateTableDataAction}
        />
      )}
        </MDBox>
        </MDBox>
  )
}
export default Myteam;