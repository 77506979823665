import { Card } from '@material-ui/core'
import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SucessLogo from '../../../assets/images/Sucessfull.png';
import { Grid, CardContent, Typography, Button, Dialog } from '@mui/material';
import DashboardLayout from 'components/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import Negotiate from 'components/Popup/Negotiate';
import { clearActionOfRow } from 'redux/slices/tableActionRow';
import { useDispatch } from 'react-redux';
const AgentMicProfile = () => {
  const [openPopup, setOpenPopup] = useState(false);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(clearActionOfRow())
  })
  const data = [
    {
      action: 'Role',
      accept: "Doctor"
    },
    {
      action: 'Name',
      accept: "Akash sirohi"
    },
    {
      action: 'Age',
      accept: "25"
    },
    {
      action: 'Gender',
      accept: "Male"
    },
    {
      action: 'DOB',
      accept: "29/04/1998"
    },
    {
      action: 'Ph.NO',
      accept: "8506864887"
    },
    {
      action: 'Email',
      accept: "akashsirohi@77567@gmail.com"
    },
    {
      action: 'Language',
      accept: "Hindi",
    },



  ]

  const adress = [
    {
      action: 'Address',
      accept: "greater noida dari railway road station up 203207 "
    },
    {
      action: 'Country',
      accept: "india"
    },
    {
      action: 'Zip Code',
      accept: "203207"
    },
  ]
  const data1 = [
    {
      serial: "1",
      degree: "Degree added",
      mode: "Mode",
      gradeType: "Grade Type",
      grade: "Grade",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    },
    {
      serial: "2",
      degree: "Degree added",
      mode: "Mode",
      gradeType: "Grade Type",
      grade: "Grade",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    },
    {
      serial: "3",
      degree: "Degree added",
      mode: "Mode",
      gradeType: "Grade Type",
      grade: "Grade",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    },

  ]
  const data2 = [
    {
      serialNo: "1",
      job: "Job Title",
      organisation: "Name of organisation",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    },
    {
      serialNo: "2",
      job: "Job Title",
      organisation: "Name of organisation",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    },
    {
      serialNo: "3",
      job: "Job Title",
      organisation: "Name of organisation",
      sDate: "29/04/1998",
      eDate: "29/04/3000"
    }
  ]
  //pop
  const handleNegotiateClick = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  return (
    <DashboardLayout>
      <Grid mt={5} sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center" }}>
        <Grid sx={{ width: "860px", hight: "auto" }}>
          <Card >
            <Grid mt={4} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" >Personal Detail</MDTypography>
            </Grid>

            <Grid container spacing={1} ml={2} mt={2} sx={{ maxWidth: "100%" }}>
              {data.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} sx={{ display: "flex", gap: "5%", flexWrap: "wrap" }}>
                  <Grid item xs={12} sm={3} sx={{ wordWrap: "break-word" }}>
                    <MDTypography variant="h6" color="text" fontWeight="medium" >
                      {item.action}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={0.5} sx={{ textAlign: 'center', wordWrap: "break-word" }}>
                    <MDTypography variant="h6" color="text" fontWeight="medium" >
                      :
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={7} sx={{ wordWrap: "break-word" }}>
                    <MDTypography variant="h6" fontWeight="medium" >
                      {item.accept}
                    </MDTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>


            <Grid mt={2} sx={borderBottom}></Grid>
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" >Address Detail</MDTypography>   </Grid>
            <Grid>
              {adress.map((item, index) => (
                <Grid key={index} item ml={3} xs={12} sm={12} sx={{ display: "flex", gap: "5%" }}>
                  <Grid item xs={12} sm={1.1}>
                    <MDTypography variant="h6" color="text" fontWeight="medium" style={{ width: '100%' }}>
                      {item.action}
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={0.5}>
                    <MDTypography variant="h6" color="text" fontWeight="medium" style={{ width: '100%', textAlign: 'center' }}>
                      :
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={9} sx={{ wordWrap: "break-word" }}>
                    <MDTypography variant="h6" fontWeight="medium"  >
                      {item.accept}
                    </MDTypography>
                  </Grid>
                </Grid>
              ))}

            </Grid>
            <Grid mt={2} sx={borderBottom}></Grid>
            {/* second */}
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" > Educaton Detail</MDTypography>
            </Grid>
            <MDBox p={2}>
              <MDBox width="100%" overflow="auto">
                <Table sx={{ minWidth: "32rem" }}>
                  <MDBox component="thead">
                    <TableRow >
                      <MDBox
                        component="th"
                        width={{ xs: "45%", md: "10%" }}
                        py={1}
                        px={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Sr No.
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Name of Degree/ Deploma
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Mode
                        </MDTypography>
                      </MDBox>

                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Grade
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          start date
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          End date
                        </MDTypography>
                      </MDBox>
                    </TableRow>

                  </MDBox>


                  {data1.map((item, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.serial}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.degree}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.mode}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.grade}
                          </MDTypography>
                        </MDBox>  <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.sDate}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.eDate}
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </TableBody>
                  ))}


                </Table>
              </MDBox>

            </MDBox>
            <Grid mt={2} sx={borderBottom}></Grid>
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" >Work Experince</MDTypography>
            </Grid>
            <MDBox p={3}>
              <MDBox width="100%" overflow="auto">
                <Table sx={{ minWidth: "32rem" }}>
                  <MDBox component="thead">
                    <TableRow >
                      <MDBox
                        component="th"
                        width={{ xs: "45%", md: "10%" }}
                        py={1}
                        px={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Sr No.
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          job title
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          Name of organisation
                        </MDTypography>
                      </MDBox>

                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          start date
                        </MDTypography>
                      </MDBox>
                      <MDBox
                        component="th"
                        py={1.5}
                        pl={3}
                        pr={1}
                        textAlign="left"
                        sx={borderBottom}
                      >
                        <MDTypography variant="h6" color="text" fontWeight="medium">
                          End date
                        </MDTypography>
                      </MDBox>
                    </TableRow>

                  </MDBox>


                  {data2.map((item, index) => (
                    <TableBody key={index}>
                      <TableRow>
                        <MDBox component="td" textAlign="left" p={1} sx={borderBottom}>
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.serialNo}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.job}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.organisation}
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.sDate}
                          </MDTypography>
                        </MDBox>
                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="body2" color="text" fontWeight="regular">
                            {item.eDate}
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </TableBody>
                  ))}


                </Table>
              </MDBox>

            </MDBox>
            <div>
              <MDButton onClick={handleNegotiateClick}>negotiate</MDButton>
              <Dialog fullWidth maxWidth="md" open={openPopup} onClose={handleClosePopup}>
                <Negotiate onClose={handleClosePopup} />
              </Dialog>
            </div>
          </Card>

        </Grid>

      </Grid>
    </DashboardLayout>
  )
}

export default AgentMicProfile