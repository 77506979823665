
import checkout from "../schemas/form";
const {
  formField: { name,  operation, },
} = checkout;

const initialValues = {
  [name.name]: "",

  [operation.name]: "",
 
};

export default initialValues;
