import React from 'react';
import Paper from '@mui/material/Paper';

import { FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Stack } from "@mui/material";

const PaginationFliter = ({ setCurrentPage, pageCount, setPerPage, perPage, option, currentPage, maxWidth = '200px' }) => {

  const handlePagination = (event, newPage) => {
    setCurrentPage(newPage)
  }

  return (
    <>
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>


        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ padding: '1%', gap: 2 }}>
          <FormControl variant="outlined" sx={{ width: "9%" }}>
            <InputLabel id={"per-page-label"}>Rows per page</InputLabel>
            <Select
              labelId="per=page-label"
              style={{ height: "35px" }}
              id="perpage-select"
              name="per-page"
              onChange={(e) => setPerPage(Number(e.target.value))}
              value={perPage}
              label="Rows per page"
            >
              {option?.map((i) => (
                <MenuItem value={i}>{i}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Pagination count={pageCount && pageCount} page={currentPage} onChange={handlePagination} color="info" />
        </Stack>
      </Paper>
    </>
  )
}

export default PaginationFliter;