import DashboardLayout from 'components/DashboardLayout'
import MDBox from 'components/MDBox'
import ProfileInformation from 'components/Shared/profileInformation/ProfileInformation'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { MMUDetailsAction } from 'redux/Actions/mmuDetailsAction'






const CollectiveAgentPofile = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(()=>{
    dispatch(MMUDetailsAction({ createdby: auth.createdby }));
  },[auth]);

 
  return (
  <>
    <ProfileInformation />
    </>

  )
}

export default CollectiveAgentPofile
