import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  useMediaQuery,
} from "@mui/material";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import Skeleton from "components/Skeleton/Skeleton";

const VitalsPopup = (props) => {
  const {
    openvitals,
    setOpenvitals,
    patientVitals,
    vitalsMasterList,
    setVitalsMasterList,
    isLoading,
  } = props;
  const [loading, setLoading] = useState(false);
  // const [updateMasterList, setUpdateMasterList] = useState([...vitalsMasterList]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const isLargeScreen = useMediaQuery('(min-width:600px)');

  async function createVitals(objectList) {
    setLoading(true);
    console.log(objectList);
    await axios
      .post(`${APIs.vitalsServiceApi}/createList`, objectList)
      .then((response) => {
        setLoading(false);
        setOpenvitals(false);
        swal("Good job!", "Vitals have been Created Successfully!", {
          icon: "success",
        });
      })
      .catch((e) => {
        console.log("error", e);
        swal("Oops!", "Something went wrong!", "error");
        setLoading(false);
      });
  }

  async function updateVitals(objectList) {
    setLoading(true);
    console.log(objectList);
    await axios
      .put(`${APIs.vitalsServiceApi}/updateList`, objectList)
      .then((response) => {
        setLoading(false);
        setOpenvitals(false);
        swal("Good job!", "Vitals have been Updated Successfully!", {
          icon: "success",
        });
      })
      .catch((e) => {
        console.log("error", e);
        swal("Oops!", "Something went wrong!", "error");
        setLoading(false);
      });
  }

  const handleValue = (value, index) => {
    let tempData = [...vitalsMasterList];
    tempData[index].value = value;
    setVitalsMasterList(tempData);
  };

  console.log(vitalsMasterList, "vitalsMasterList 63");

  function handleCreateVitals() {
    if (patientVitals?.length > 0) {
      for (let i = 0; i < vitalsMasterList.length; i++) {
        vitalsMasterList[i].recstatus = "OPEN";
        vitalsMasterList[i].modifiedby = auth.profileid;
        vitalsMasterList[i].visitid = props.bookingPatient.visitid;
      }
      updateVitals(vitalsMasterList);
    } else {
      for (let i = 0; i < vitalsMasterList.length; i++) {
        vitalsMasterList[i].id = null;
        vitalsMasterList[i].recstatus = "OPEN";
        vitalsMasterList[i].createdby = auth.profileid;
        vitalsMasterList[i].visitid = props.bookingPatient.visitid;
      }
      createVitals(vitalsMasterList);
    }
  }

  // useEffect(()=>{
  //     getVitalMaster()
  // },[])
  function handleClosevitals() {
    setOpenvitals(false);
  }
  return (
    <Dialog open={openvitals} onClose={handleClosevitals} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "green",
          color: "#fff",
          textAlign: "center",
        }}
      >
        Create Vital
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Grid container spacing={3} ml={6} pt={2}>
            {[...Array(3)].map((_, index) => (
              <Grid item key={index} xs={12} sm={8}>
                <Skeleton variant="rectangular" width="100%" height={40} />
                <Skeleton variant="text" width="60%" />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid container style={{ marginTop: "10px" }} spacing={0}>
            {vitalsMasterList?.length > 0 &&
              vitalsMasterList?.map((k, index) => (
                // <h1>{k.name} </h1>

                <Grid
                  container
                  spacing={isSmallScreen ? 0 : 3}
                  ml={isSmallScreen ? 0 : 5}
                  display="flex"
                  alignItems="center"
                >


                  <Grid item mt={2} xs={10} sm={isLargeScreen ? 8 : 4}>
                    {/* {isSmallScreen && (
                      <Grid item mt={2} xs={2} sm={4}>
                        <MDTypography variant="h6" fontWeight="medium">
                          {k.uom}
                        </MDTypography>
                      </Grid>
                    )} */}
                    {k.type == "VALUE" && (
                      <MDInput
                        type="number"
                        label={k?.name
                          ?.split("-")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                        variant="outlined"
                        name={k.name}
                        style={{ width: "100%" }}
                        value={k.value}
                        onChange={(e) => handleValue(e.target.value, index)}
                        // onChange={(e) => {
                        //   k.value = e.target.value;
                        // }}
                        placeholder={k?.name
                          ?.split("-")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      />
                    )}
                    {k.type == "FLAG" && (
                      <FormControlLabel
                        name={k.name}
                        control={
                          <Switch
                            onClick={(e) => {
                              k.value = e.target.checked;
                            }}
                          // defaultChecked
                          />
                        }
                        label={k.name}
                      />
                    )}

                  </Grid>
                  {/* {isLargeScreen && ( */}
                    <Grid item mt={2} ml={isLargeScreen ? 0 : .5} xs={1.5} sm={4}>
                      <MDTypography variant="h6" fontWeight="medium">
                        {k.uom}
                      </MDTypography>
                    </Grid>
                  {/* )} */}
                </Grid>
              ))}
          </Grid>
        )}
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleClosevitals}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleCreateVitals}
        >
          {loading ? (
            <MoonLoader color="#f2fefa" size={16} />
          ) : patientVitals?.length > 0 ? (
            "Update"
          ) : (
            "Create"
          )}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default VitalsPopup;
