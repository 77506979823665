import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axiosinstance";
//create role

// export const createRegistrationResource = createAsyncThunk("createRegistrationResource", async (data, {rejectWithValue}) => {
//   console.log("formData", data);

//   try {
//     const response = await axios.post(
//       `profile-service-mongo/api/ProfileEntity/v3/create`,
//       data
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });

export const createRegistrationResource = async (data) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `profile-service-mongo/api/ProfileEntity/v3/create`,

      data
    );
    return await response.data;
  } catch (error) {
    console.log(error);
    //   return rejectWithValue(error);
  }
};

export const getResgistrationResource = createAsyncThunk(
  "getResgistrationResource",
  async (data, { rejectWithValue }) => {
    console.log("formData", data);

    try {
      const response = await axios.get(
        `profile-service-mongo/api/ProfileEntity/v3/get`
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
