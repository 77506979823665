import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
// Import form validation, schema, and initial values
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import swal from "sweetalert";

import { Dialog } from "@mui/material";
import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance";
import ItemInfo from "./itemInfo";


function getSteps() {
  return ["Item Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ItemInfo formData={formData} />;
    default:
      return null;
  }
}

export default function CreateitemPopup(props) {
  const { itemPopup, setItemPopup, tittle, getApiResponse } = props;
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const requestData = {
      createdby: auth?.name,
     name: values.name,
     recstatus: "OPEN",
    status: "ACTIVE",
      syncstatus: "SYNCED",
      taxable: values.taxable,
      taxcategory: values.taxcategory,
      taxcode: values.taxcode,
      type: "M_PRICE_ITEM",
    
    };

    try {
      const response = await axios.post(
        `${APIs.itemmasterServiceApi}/create`,
        requestData
      );

      if (response.status === 200 || response.status === 201) {
        setItemPopup(false);
        swal("Good job!", "Data has been created successfully!", {
          icon: "success",
        });
        setIsLoading(false);
        actions.resetForm();
        getApiResponse();
      } else {
        throw new Error("Unexpected response status: " + response?.status);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      swal({
        icon: "error",
        title: "Error",
        text:
          error.response?.data?.aceErrors?.[0]?.errormessage ||
          "Something went wrong",
      });
      setIsLoading(false);
      handleClose();
    }
  };

 
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
   
      submitForm(values, actions);
    } else {
      
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setItemPopup(false);
  };

  // Render the component
  return (
    <Dialog open={itemPopup} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-2}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        {tittle}
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
