// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
//date picker
import MDDatePicker from "components/MDDatePicker";
import { useFormikContext } from "formik";
//dropdown
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function InvestmentItemInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldError } = useFormikContext();

  const [endDate, setEndDate] = useState(new Date());
  // const [closingDate, setClosingDate] = useState(new Date());
  const [color] = useState("#344767");
  const currentDate = new Date().toISOString().split("T")[0];
  const date = currentDate;

  const { formField, values, errors, touched } = formData;

  const {
    comisssion,
    investmentterm,
    amount,
    transationNo,
    enddate,
    startdate,
  } = formField;
  var {
    comisssion: comisssionV,
    investmentterm: investmenttermV,
    amount: amountV,
    transationNo: transationNoV,
    startdate: startdateV,
    enddate: enddateV,
  } = values;

  values.enddate = enddateV || date;
  values.startdate = startdateV || date;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add Investment</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.commissiontype && touched.commissiontype}
              >
                <InputLabel
                  htmlFor="mmu"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Commission Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="commissiontype"
                  id="commissiontype"
                  value={values.commissiontype}
                  onChange={(e) => {
                    const commissiontype = e.target.value;

                    setFieldValue("commissiontype", commissiontype);
                  }}
                >
                  <option value=""></option>

                  <option value="percentage">Percentage</option>
                  <option value="flat">Flat</option>
                </Select>
                {errors.commissiontype && touched.commissiontype && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.commissiontype}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={comisssion.type}
                label=<span>
                  {comisssion.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={comisssion.name}
                value={comisssionV}
                placeholder={comisssion.placeholder}
                error={errors.comisssion && touched.comisssion}
                success={comisssion.length > 0 && !errors.comisssion}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={investmentterm.type}
                label=<span>
                  {investmentterm.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={investmentterm.name}
                value={investmenttermV}
                placeholder={investmentterm.placeholder}
                error={errors.investmentterm && touched.investmentterm}
                success={investmenttermV.length > 0 && !errors.investmentterm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={amount.type}
                label=<span>
                  {amount.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={amount.name}
                value={amountV}
                placeholder={amount.placeholder}
                error={errors.amount && touched.amount}
                success={amountV.length > 0 && !errors.amount}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={transationNo.type}
                label=<span>
                  {transationNo.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={transationNo.name}
                value={transationNoV}
                placeholder={transationNo.placeholder}
                error={errors.transationNo && touched.transationNo}
                success={transationNoV.length > 0 && !errors.transationNo}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <FormField
                type={startdate.type}
                label=<span>
                  {startdate.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={startdate.name}
                value={startdateV}
                placeholder={startdate.placeholder}
                error={errors.startdate && touched.startdate}
                success={startdateV.length > 0 && !errors.startdate}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <FormField
                type={enddate.type}
                label=<span>
                  {enddate.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={enddate.name}
                value={enddateV}
                placeholder={enddate.placeholder}
                error={errors.enddate && touched.enddate}
                success={enddateV.length > 0 && !errors.enddate}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for InvestmentItemInfo
InvestmentItemInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default InvestmentItemInfo;
