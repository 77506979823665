// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete, Select } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { APIs } from 'Services/APIs';
import axios from "../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { ErrorMessage, useFormikContext } from "formik";

function VillageInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const { setFieldValue, validateField } = useFormikContext();
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
 
 
  const [subdistrictValue, setSubDistrictValue] = React.useState(null);
  const [subdistrictList, setSubDistrictList] = useState([]);
  const { formField, values, errors, touched } = formData;

  const defaultPropsState = {
    options: stateList && stateList.length > 0 ? stateList : [
      {
        state: {
          shortname: "- -",
          name: "Loading...",
        },
        id: 0,
      },
    ],
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsDistrict = {
    options: districtList && districtList.length > 0 ? districtList : [
      {
        district: {
          shortname: "- -",
          name: "Loading...",
        },
        id: 0,
      },
    ],
    getOptionLabel: (option) =>
      option.district?.name + " (" + option.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsSubDistrict = {
    options: subdistrictList && subdistrictList.length > 0 ? subdistrictList : [
      {
        subdistrictList: {
          shortname: "- -",
          name: "Loading...",
        },
        id: 0,
      },
    ],
    getOptionLabel: (option) =>
      option.subdistrict?.name + " (" + option.subdistrict?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { villageCode, shortname, villagename, state,district,subdistrict ,pincode} = formField;
  var {
    villageCode: villageCodeV,

    shortname: shortnameV,
    pincode: pincodeV,
    villagename: villagenameV,
  } = values;

  async function getData() {
   
      await axios.get(`${APIs.pincodeServiceApi}/get?type=STATE`)
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDistrictData(code) {
   
    await axios.get(`${APIs.pincodeServiceApi}/get?type=DISTRICT&state.code=${code}`)
    .then((response) => {
      setDistrictList(response?.data?.data?.dtoList);

      setLoading(false);
    })

    .catch((e) => {
      console.log("error", e);
    });
}
async function getSubDistrictData(code) {
   
  await axios.get(`${APIs.pincodeServiceApi}/get?type=SUB-DISTRICT&district.code=${code}`)
  .then((response) => {
    setSubDistrictList(response?.data?.data?.dtoList);

    setLoading(false);
  })

  .catch((e) => {
    console.log("error", e);
  });
}
  const handleStateChange = (event, newValue) => {
    setStateValue(newValue);
    setFieldValue('stateobj', newValue?.state || null);
    setFieldValue('state', newValue?.state?.code || '');
    validateField('state');
  
    if (newValue) {
      getDistrictData(newValue.state.code);
    } else {
      setDistrictList([]);
      setDistrictValue(null);
      setFieldValue('districtobj', null);
      setFieldValue('district', '');
      validateField('district');
      setSubDistrictList([]);
      setSubDistrictValue(null);
      setFieldValue('subdistrictobj', null);
      setFieldValue('subdistrict', '');
      validateField('subdistrict');
    }
  };
  
  const handleDistrictChange = (event, newValue) => {
    setDistrictValue(newValue);
    setFieldValue('districtobj', newValue?.district || null);
    setFieldValue('district', newValue?.district?.code || '');
    validateField('district');
  
    if (newValue) {
      getSubDistrictData(newValue.state.code);
    } else {
      setSubDistrictList([]);
      setSubDistrictValue(null);
      setFieldValue('subdistrictobj', null);
      setFieldValue('subdistrict', '');
      validateField('subdistrict');
    }
  };
  const handleSubDistrictChange = (event, newValue) => {
    setSubDistrictValue(newValue);
    setFieldValue('subdistrictobj', newValue?.subdistrict || null);
    setFieldValue('subdistrict', newValue?.subdistrict?.code || '');
    validateField('subdistrict');
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Village Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={handleStateChange}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={stateValue != null && !errors.state && touched.state}
                    variant="standard"
                    label=<span>{state.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  />
                   <ErrorMessage
               name="state"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>   
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDistrict}
                value={districtValue}
                onChange={handleDistrictChange}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.district && touched.district}
                    success={districtValue != null && !errors.district && touched.district}
                    variant="standard"
                    label=<span>{district.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  />
                   <ErrorMessage
               name="district"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>   
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsSubDistrict}
                value={subdistrictValue}
                onChange={handleSubDistrictChange}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.subdistrict && touched.subdistrict}
                    success={subdistrictValue != null && !errors.subdistrict && touched.subdistrict}
                    variant="standard"
                    label=<span>{subdistrict.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  />
                   <ErrorMessage
                  name="subdistrict"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>   
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={villagename.type}
                label=<span>{villagename.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={villagename.name}
                value={villagenameV}
                placeholder={villagename.placeholder}
                error={errors.villagename && touched.villagename}
                success={villagenameV.length > 0 && !errors.villagename}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shortname.type}
                label=<span>{shortname.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={shortname.name}
                value={shortnameV}
                placeholder={shortname.placeholder}
                error={errors.shortname && touched.shortname}
                success={shortnameV.length > 0 && !errors.shortname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={villageCode.type}
                label=<span>{villageCode.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={villageCode.name}
                value={villageCodeV}
                placeholder={villageCode.placeholder}
                error={errors.villageCode && touched.villageCode}
                success={villageCodeV.length > 0 && !errors.villageCode}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
        
            
            <Grid item xs={12} sm={6}>
              <FormField
                type={pincode.type}
                label=<span>{pincode.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={pincode.name}
                value={pincodeV}
                placeholder={pincode.placeholder}
                error={errors.pincode && touched.pincode}
                success={pincodeV.length > 0 && !errors.pincode}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for VillageInfo
VillageInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default VillageInfo;
