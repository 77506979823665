import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { micappCode } from "static/micappCode";
// Import form validation, schema, and initial values
import validations from "components/Resuable/Create Project/schemas/validations";
import form from "components/Resuable/Create Project/schemas/form";
import initialValues from "components/Resuable/Create Project/schemas/initialValues";

import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";

import { createProject } from "components/Resuable/Create Project/service/Projectcreate";
import ProjectCreateInfo from "components/Resuable/Create Project/components/projectcreateInfo";
// Function to define form steps
function getSteps() {
  return ["Project Create Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ProjectCreateInfo formData={formData} />;
    default:
      return null;
  }
}

export default function CreateMicpopup(props) {
  const {
    openpopupProjectCreate,
    setopenpopupProjectCreate,
    handleClose,
    passData,
  } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.create);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectTeam, getMicDetails } = useSelector((state) => state);
  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const submitForm = async (values, actions) => {
    values?.locationObj?.forEach((item) => {
      delete item?.uid;
      delete item?.action;
    });
    const locationArray = values?.locationObj?.map((item) => [
      parseFloat(item.letitude),
      parseFloat(item.longtitude),
    ]);
    setIsLoading(true);

    const requestData = {
      name: values.name,
      polygoncoordinates: {
        type: "Polygon",
        coordinates: locationArray,
      },
      desc: values.description,
      parentproject: values.projecthirarichalcode
        ? values.projecthirarichalcode
        : getMicDetails?.details?.code,
      projecthirarichalcode: values?.projecthierarchy
        ? values?.projecthierarchy
        : getMicDetails?.details?.projecthirarichalcode,
      pinCode: values.pincodeobj,
      mappingcode: values?.projecthierarchy
        ? values?.projecthierarchy
        : getMicDetails?.details?.projecthirarichalcode,
      type: micappCode?.mappingcode,
      createdby: auth.profileid,
      createdbyname: auth.name,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "synced",
    };

    const response = await dispatch(createProject(requestData));
    if (!response.error && !loading) {
      actions.resetForm();
      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });
      passData();
    } else {
      actions.resetForm();
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data?.aceErrors?.[0]?.errorMessage ||
          "Something went wrong",
      });
      setIsLoading(false);
      handleClose();
    }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (!values.locationObj || values.locationObj.length === 0) {
      swal({
        title: "Validation Error",
        text: "Location list cannot be empty.",
        icon: "error",
      });
      return;
    }
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <Dialog
      open={openpopupProjectCreate}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 6 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-2}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Create MIC
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={5}>
                    <MDBox padding={2}>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={8}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
