import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Pagination,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import "./selectLab.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance";
import CircularProgress from "@mui/material/CircularProgress";
import swal from "sweetalert";
import "./pharmacies/pharmacies.css";
import "./pricingDetails/pricingDetails.css";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { useDispatch } from "react-redux";
import { labAddCartAction } from "redux/Actions/labAddCart";
import LeftArrow from "../../../assets/images/leftarrow.svg";
import RightArrow from "../../../assets/images/rightarrow.svg";
import image2 from "../../findDoctor/assets/images/Search.svg";
import LocationOnIcon from "../../../assets/images/location_icon.png";
import MDButton from "components/MDButton";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { variables } from "static/Variables";
import MDInput from "components/MDInput";
import MapPopup from "components/Mappopup";


const SelectLab = () => {
  const [selectedDay, setSelectedDay] = useState(
    () => parseInt(localStorage.getItem("dayNumber")) || new Date().getDate()
  );
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(
    localStorage.getItem("selectedDate")
      ? new Date(localStorage.getItem("selectedDate"))
      : new Date()
  );
  const [selectedTiming, setSelectedTiming] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 985);
  const [pharmacies, setPharmacies] = useState([]);
  const [filteredPharmacies, setFilteredPharmacies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedPrices, setSelectedPrices] = useState([]);
  const [selectedPharmacy, setSelectedPharmacy] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isHomeVisit, setIsHomeVisit] = useState(false);
  const { getAddLabCart } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [expandedPharmacyId, setExpandedPharmacyId] = useState(null);
  const [searchInput, setSearchInput] = useState({ name: "", address: "" });
  const [labResponse, setLabResponse] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [rangekm, setRangekm] = useState(null);
  const [openpopup, setopenpopup] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [maplocation, setMaplocation] = useState(null);

  const handleAccordionToggle = (pharmacyId) => {
    setExpandedPharmacyId((prevId) =>
      prevId === pharmacyId ? null : pharmacyId
    );
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth <= 985);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getPharmacy = async (selectedDate, isHomeVisit, searchInput) => {
    setLoading(true);
    try {
      const selectedLocation = JSON.parse(localStorage.getItem("userLocation"));
      const homeVisitParam = isHomeVisit ? "&homevisit=true" : "";
      let selDate = moment(selectedDate).format("YYYY/MM/DD");
      let payload = {
        nearLocation: null,
        pageable: {
          page: currentPage - 1,
          size: 10,
          sortBy: "city",
          sortDirection: "DESC",
        },
        polygonCoordinates: null,
        // "searchItems": {"name":searchInput?.name},
        searchOnFields: [
          "name",
          "address.firstline",
          "address.secondline",
          "address.city",
          "address.district",
          "address.subdistrict",
          "address.state",
          "address.country",
        ],
        searchText: getAddLabCart.searchInput,
      };

      let newPayload = {}
      if (getAddLabCart.userCoordinate?.length > 0 && getAddLabCart.areaRange) {
        console.log(Number(getAddLabCart?.userCoordinate[0]), "vvvvv")
        let lat = Number(getAddLabCart?.userCoordinate[0])
        let lng = Number(getAddLabCart?.userCoordinate[1])
        let nearLoc = { "nearLocation": [lat, lng] , "maxDistance": Number(getAddLabCart.areaRange.value) }
        newPayload = {...payload, nearLocation: nearLoc}
      } else {
        newPayload = payload
      }

      if (selectedLocation && !getAddLabCart?.homeVisit) {
        const response = await axios.post(
          `${APIs.mmuCreationService}/getByPage?date=${selDate}`,
          newPayload
        );
        setPharmacies(response?.data?.data?.page?.content || []);
        setLabResponse(response?.data?.data?.page);
      } else if (getAddLabCart?.homeVisit) {
        const response = await axios.post(
          `${APIs.mmuCreationService}/getByPage?date=${selDate}&homevisit=true`,
          newPayload
        );
        setPharmacies(response?.data?.data?.page?.content || []);
        setLabResponse(response?.data?.data?.page);
      } else {
        const response = await axios.post(
          `${APIs.mmuCreationService}/getByPage?date=${selDate}`,
          newPayload
        );
        setPharmacies(response?.data?.data?.page?.content || []);
        setLabResponse(response?.data?.data?.page);
      }
    } catch (error) {
      swal(
        "Oops!",
        error?.response &&
          error?.response?.data &&
          error?.response?.data?.aceErrors
          ? error?.response?.data?.aceErrors?.[0]?.errorCode === "HG02"
            ? "Something went wrong."
            : error?.response?.data?.aceErrors?.[0]?.errorMessage
          : "Request failed with status code 404.",
        "warning"
      );
      console.error("Error fetching pharmacies:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPharmacy(getAddLabCart.date, getAddLabCart.homeVisit, getAddLabCart.searchInput);
  }, [getAddLabCart.searchInput, currentPage, getAddLabCart?.areaRange?.value, getAddLabCart?.userCoordinate]);

  // const filterPharmaciesByDate = () => {
  //   const labTestCodes = localStorage.getItem("labtest-code")?.split(",") || [];
  //   setFilteredPharmacies(
  //     pharmacies?.filter((pharmacy) =>
  //       pharmacy?.price?.some((category) =>
  //         labTestCodes?.includes(category?.code)
  //       )
  //     )
  //   );
  // };
  const filterPharmaciesByDate = () => {
    const labTestCodes = getAddLabCart?.labtestCode || [];
     setFilteredPharmacies(
      pharmacies?.filter((pharmacy) =>
        pharmacy?.price?.some((category) =>
          labTestCodes?.includes(category?.code)
        )
      )
    );
  };


  useEffect(() => {
    filterPharmaciesByDate();
  }, [pharmacies]);

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const monthYearString = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(currentMonth);

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const handlePreviousMonth = () => {
    const today = new Date();
    const newDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );

    if (newDate < today) {
      setCurrentMonth(new Date(today.getFullYear(), today.getMonth(), 1));
    } else {
      setCurrentMonth(newDate);
    }
  };

  const generateDaysForMonth = (month) => {
    const today = new Date();
    const firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const daysInMonth = [];

    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      const dayName = day.toLocaleDateString("en-US", { weekday: "short" });
      const dayNumber = day.getDate();
      const isToday = isSameDay(day, today);
      const isPastDay = day < today;

      if (!isPastDay || isToday) {
        daysInMonth.push({ dayName, dayNumber, isToday, isPastDay });
      }
    }

    return daysInMonth;
  };

  const transformSlot = (slot) => {
    const startTime = new Date(slot.startdate);
    const endTime = new Date(slot.enddate);

    return {
      starttime: startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      }),
      endtime: endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "UTC",
      }),
    };
  };

  const nextDays = generateDaysForMonth(currentMonth);

  useEffect(() => {
    const storedDayNumber = localStorage.getItem("dayNumber");
    if (storedDayNumber) setSelectedDay(parseInt(storedDayNumber));
    getPharmacy(getAddLabCart.date, getAddLabCart.homeVisit, getAddLabCart.searchInput);
  }, []);


  const handleNextButtonClick = () => {
    if (getAddLabCart?.labs?.length === 0) {
      swal(
        "",
        "Please select at least one pharmacy and test price before proceeding.",
        "warning"
      );
      return;
    }

    navigate(`/app/${auth?.rolename?.toLowerCase()}/select-person`, {
      state: {
        // item: location?.state?.item,
        item: getAddLabCart?.selectedTestType,
        pharmacies: getAddLabCart?.labs,
      },
    });

    const data = getAddLabCart?.labs?.filter(
      (item) => item.pharmacy && item.timing && item.price !== null
    );

    localStorage.setItem("selectedLabs", JSON.stringify(data));
  };
  // range props=========================================

  const defaultspropsRange = {
    options: !variables?.address ? [{ name: "Loading...", id: 0 }] : variables?.address,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  // ------------------------------------------------ Data Handler --------------------------------------------------------

  const selectHomeVisitHandler = (isChecked) => {
    dispatch(
      labAddCartAction({
        ...getAddLabCart,
        labs: [],
        selectedPrices: [],
        homeVisit: isChecked,
      })
    );
    getPharmacy(getAddLabCart.date, isChecked, getAddLabCart.searchInput);
  };

  const selectDateHandler = (dayNumber) => {
    const newSelectedDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      dayNumber
    );
    const formattedDate = newSelectedDate.toLocaleDateString("en-CA");
    setSelectedDay(dayNumber);
    setSelectedDate(newSelectedDate);
    dispatch(
      labAddCartAction({
        ...getAddLabCart,
        labs: [],
        selectedPrices: [],
        date: formattedDate,
        dateNumber: dayNumber,
      })
    );
    getPharmacy(formattedDate, getAddLabCart.homeVisit, getAddLabCart.searchInput);
  };

  const selectSlotHandler = async (slot, pharmacy) => {
    const isSlotSelected = getAddLabCart?.labs?.some(
      (p) =>
        p?.pharmacy?.id === pharmacy?.id &&
        p?.timing?.startdate === slot?.startdate
    );

    if (isSlotSelected) {
      let selPharmacy = getAddLabCart?.labs?.filter(
        (p) =>
          p?.pharmacy?.id === pharmacy?.id ||
          p?.timing?.startdate === slot?.startdate
      );
      let selPrices = await handleSelectedPricesData(selPharmacy);
      const updatedPharmacy = getAddLabCart?.labs?.filter(
        (p) =>
          p?.pharmacy?.id !== pharmacy?.id ||
          p?.timing?.startdate !== slot?.startdate
      );
      if (selPrices == undefined) {
        selPrices = [];
      }

      dispatch(
        labAddCartAction({
          ...getAddLabCart,
          labs: updatedPharmacy,
          selectedPrices: selPrices,
        })
      );

      // const hasSelectedSlots = updatedPharmacy?.some(
      //   (p) => p?.pharmacy?.id === pharmacy?.id
      // );
      // if (!hasSelectedSlots) {
      //   // setSelectedPharmacy((prev) => prev.filter((p) => p?.pharmacy?.id !== pharmacy?.id));
      //   let tempData = getAddLabCart?.labs?.filter((p) => p?.pharmacy?.id !== pharmacy?.id);
      //   dispatch(labAddCartAction({ ...getAddLabCart, labs: tempData }));
      // } else {
      //   // setSelectedPharmacy(updatedPharmacy);
      //   dispatch(labAddCartAction({ ...getAddLabCart, labs: updatedPharmacy }))
      // }
    } else {
      const existingPharmacyIndex = getAddLabCart?.labs?.findIndex(
        (p) => p?.pharmacy?.id === pharmacy?.id
      );

      if (existingPharmacyIndex > -1) {
        let selPharmacy = getAddLabCart?.labs?.filter(
          (p) =>
            p?.pharmacy?.id === pharmacy?.id ||
            p?.timing?.startdate === slot?.startdate
        );
        let selPrices = await handleSelectedPricesData(selPharmacy);
        if (selPrices == undefined) {
          selPrices = [];
        }
        const updatedPharmacies = [...getAddLabCart?.labs];
        updatedPharmacies[existingPharmacyIndex] = {
          ...updatedPharmacies[existingPharmacyIndex],
          timing: slot,
          price: null,
        };
        // setSelectedPharmacy(updatedPharmacies);
        dispatch(
          labAddCartAction({
            ...getAddLabCart,
            labs: updatedPharmacies,
            selectedPrices: selPrices,
          })
        );
      } else {
        const newPharmacyData = {
          pharmacy,
          timing: slot,
          price: null,
        };
        // setSelectedPharmacy((prev) => [...prev, newPharmacyData]);
        dispatch(
          labAddCartAction({
            ...getAddLabCart,
            labs: [...getAddLabCart.labs, newPharmacyData],
          })
        );
      }
    }
  };

  const handleSelectedPricesData = (selPharmacy) => {
    // let prices = getAddLabCart?.selectedPrices?.filter(item=> item.code !== selPharmacy)
    let mappedPrices = getAddLabCart?.selectedPrices?.map((i) => {
      let foundPrice = selPharmacy?.price?.filter(
        (price) => price?.code !== i?.code
      );
      return foundPrice;
    });
  };

  const selectTestHandler = (item, priceItem) => {
    const isSlotSelected = getAddLabCart?.labs?.some(
      (selected) => selected?.pharmacy?.id === item?.id
    );

    if (isSlotSelected) {
      handlePriceSelection(priceItem, item?.id, item?.slots);
    } else {
      swal({
        icon: "warning",
        title: "No Slot Selected!",
        text: "Please select a slot before choosing a price.",
      });
    }
  };

  const swalMessage = (errMsg) => {
    swal({
      icon: "warning",
      title: "Test is already selected!",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            <p style="text-align: center;">
              ${errMsg}
            </p>
          `,
        },
      },
    });
  };

  const handlePriceSelection = async (priceItem, pharmacyId, slot) => {
    if (!priceItem) return;
    const isPriceSelectedInAnotherPharmacy =
      getAddLabCart?.selectedPrices?.some(
        (selectedPrice) =>
          selectedPrice?.code === priceItem?.code &&
          selectedPrice?.pharmacyId !== pharmacyId &&
          selectedPrice?.id !== priceItem?.id
      );

    if (isPriceSelectedInAnotherPharmacy) {
      let errMsg = `The ${priceItem?.pricename} is already selected for another pharmacy. Please choose a different test.`;
      swalMessage(errMsg);
      return;
    }

    const isPriceSelectedInSamePharmacy = getAddLabCart?.selectedPrices?.some(
      (selectedPrice) =>
        selectedPrice?.code === priceItem?.code &&
        selectedPrice?.pharmacyId == pharmacyId &&
        selectedPrice?.id !== priceItem?.id
    );

    if (isPriceSelectedInSamePharmacy) {
      let errMsg = `The ${priceItem?.pricename} is already selected for same pharmacy. Please choose a different test.`;
      swalMessage(errMsg);
      return;
    }

    let updatedPrices = null;

    let isPriceSelected = getAddLabCart?.selectedPrices?.some(
      (price) => price?.id === priceItem?.id && price?.pharmacyId === pharmacyId
    );

    updatedPrices = (await isPriceSelected)
      ? getAddLabCart?.selectedPrices?.filter(
        (price) =>
          !(price?.id === priceItem?.id && price?.pharmacyId === pharmacyId)
      )
      : [
        ...getAddLabCart?.selectedPrices,
        { id: priceItem?.id, pharmacyId, code: priceItem?.code },
      ];

    // dispatch(labAddCartAction({ ...getAddLabCart, selectedPrices: updatedPrices }))

    let isSlotSelected = null;

    for (const i of slot) {
      isSlotSelected = await getAddLabCart?.labs?.find(
        (selected) =>
          selected?.pharmacy?.id === pharmacyId &&
          selected?.timing?.startdate === i?.startdate &&
          selected?.timing?.enddate === i?.enddate
      );

      if (isSlotSelected) {
        break;
      }
    }

    settingPharmacy(
      pharmacyId,
      isSlotSelected?.timing,
      isSlotSelected?.pharmacy,
      updatedPrices
    );
  };

  const settingPharmacy = (pharmacyId, slot, lab, updatedPrices) => {
    const existingPharmacyIndex = getAddLabCart?.labs?.findIndex(
      (p) => p?.pharmacy?.id === pharmacyId
    );

    if (existingPharmacyIndex > -1) {
      const updatedPharmacies = [...getAddLabCart?.labs];

      const updatedPharmaciesPrice = updatedPrices?.map((price) => {
        const matchingPrices = getAddLabCart?.labs
          .filter((item) => item?.pharmacy?.id === price?.pharmacyId)
          .flatMap((pharm) =>
            pharm.pharmacy.price.filter((p) => p?.id === price?.id)
          );

        return {
          pharmacyId: price.pharmacyId,
          priceList: matchingPrices?.length > 0 ? matchingPrices?.[0] : null,
        };
      });

      const existingPharmacy = updatedPharmacies[existingPharmacyIndex];

      const validPrices = updatedPharmaciesPrice?.filter((i) => i.priceList);

      const mappedPrices = validPrices?.map((i) => {
        const foundPrice = existingPharmacy?.pharmacy?.price?.find(
          (price) => price?.id === i?.priceList?.id
        );
        return foundPrice;
      });

      const finalPrices = mappedPrices?.filter((price) => price);

      updatedPharmacies[existingPharmacyIndex] = {
        ...existingPharmacy,
        timing: slot,
        price: finalPrices,
      };

      // setSelectedPharmacy(updatedPharmacies);
      dispatch(
        labAddCartAction({
          ...getAddLabCart,
          labs: updatedPharmacies,
          selectedPrices: updatedPrices,
        })
      );
    } else {
      const newPharmacyData = {
        lab,
        timing: slot,
        price: updatedPrices,
      };

      // setSelectedPharmacy((prev) => [...prev, newPharmacyData]);
      dispatch(
        labAddCartAction({
          ...getAddLabCart,
          labs: [...getAddLabCart?.labs, newPharmacyData],
          selectedPrices: updatedPrices,
        })
      );
    }
  };

  console.log(getAddLabCart, "getAddLabCart 574");

  const handleTestCheckbox = (priceItem, pharmacy) => {
    if (price?.pharmacyId !== item?.id) {
      return false;
    }

    getAddLabCart?.selectedPrices?.some(
      (price) => price?.id === priceItem?.id && price?.pharmacyId === item?.id
    );
  };

  // map popup data========================
  const handleDataTransfer = (data) => {
    let lat = data.lat.toFixed(2)
    let lng = data.lng.toFixed(2)
    dispatch(labAddCartAction({ ...getAddLabCart, userCoordinate: [lat, lng] }))

    // setMaplocation(data);
  };

  return (
    <>
      <div className={isSmallScreen ? "selectlabroot" : ""}>
        <Grid
          item
          xs={11}
          ml={isSmallScreen ? 0 : 5}
          mt={23}
          className="selectlabroot2"
        >
          <Grid item className="selectlabitem">
            Select Lab
          </Grid>
          <Grid item mt={1}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={{ transform: "scale(1.2)" }}
                  checked={getAddLabCart.homeVisit}
                  onChange={(event) =>
                    selectHomeVisitHandler(event.target.checked)
                  }
                />
              }
              label="Home Visit"
              sx={{
                "& .MuiFormControlLabel-label": {
                  fontSize: "1.2rem",
                  fontFamily: "Montserrat",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          mt={0}
          ml={isSmallScreen ? 0 : 5}
          xs={11}
          className="selectlabdaysitem"
        >
          <Grid item className="appointmentitem" mb={2} xs={12}>
            <button className="selectlabarrow" onClick={handlePreviousMonth}>
              <img
                src={LeftArrow}
                alt="LeftArrow Logo"
                className="selectlabimgsize1"
              />
            </button>
            <div className="monthYearString">{monthYearString}</div>
            <button className="selectlabarrow" onClick={handleNextMonth}>
              <img
                src={RightArrow}
                alt="RightArrow Logo"
                className="selectlabimgsize1"
              />
            </button>
          </Grid>
          <div className="selectlabdays-container">
            {nextDays?.map((day, index) => (
              <button
                className={`selectlabdaysgrid ${day.isToday ? "selectlabtoday" : ""
                  } ${day.isPastDay ? "selectlabpast-day" : ""} ${day.dayNumber === getAddLabCart.dateNumber
                    ? "selectlabselected22"
                    : ""
                  }`}
                key={index}
                onClick={() => selectDateHandler(day.dayNumber)}
              >
                <div className="selectlabdatename">{day.dayName}</div>
                <div className="selectlabdate">{day.dayNumber}</div>
              </button>
            ))}
          </div>
        </Grid>
        <Grid container mt={2} mb={3} className="selectlabtestselect" pt={3}>
          <Grid item xs={8} pt={1} className="selectlabtestselect1">
            <Grid item xs={6} className="selectlabtest1">
              <img src={image2} alt="Search Logo" className="selectlabimg1" />
              <input
                type="text"
                id="selectlabinput1"
                placeholder="Search by Lab name or Address..."
                className="selectlabinput"
                value={getAddLabCart.searchInput}
                onChange={(e) =>
                  // setSearchInput({ ...searchInput, address: e.target.value })
                  dispatch(labAddCartAction({ ...getAddLabCart, searchInput: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={4} >
              {/* <img src={image2} alt="Search Logo" className="selectlabimg1" />
              <input
                type="text"
                id="selectlabinput2"
                placeholder="Search labs by lab name"
                className="selectlabinput"
                value={searchInput.name}
                onChange={(e) => setSearchInput({ ...searchInput, name: e.target.value })}
              /> */}
              <Autocomplete
                {...defaultspropsRange}
                sx={{
                  backgroundColor: '#ffffff',
                  '& .MuiAutocomplete-inputRoot': {
                    backgroundColor: '#ffffff',
                  },
                  '& .MuiAutocomplete-paper': {
                    backgroundColor: '#ffffff',
                  },
                }}
                value={getAddLabCart?.areaRange}
                onChange={(event, newValue) => {
                  dispatch(labAddCartAction({ ...getAddLabCart, areaRange: newValue }))

                  //  setRangekm(newValue);


                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}

                    backgroundColor="#ffffff"
                    variant="outlined"
                    label="Select Range"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            className="selectlabtestselect2"
            onClick={() => setopenpopup(true)}
          >
            <img
              src={LocationOnIcon}
              alt="LocationOnIcon"
              className="selectlabimg2"
            />
            &nbsp;Add Location
          </Grid>
        </Grid>

        {/* **********Pharmacy Component****************** */}

        <Grid item xs={12} mt={1} pb={5} className="selectlabcontainer">
          <Grid item xs={isSmallScreen ? 11 : 7.5}>
            <Grid item className="selectlabpharmacy">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: "280px",
                    // marginTop: "-20px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : filteredPharmacies?.length > 0 ? (
                filteredPharmacies?.map((item, index) => (
                  <div className="selectlabpharmacy1" key={index}>
                    <Grid
                      item
                      key={index}
                      mt={2}
                      ml={isSmallScreen ? 1 : 9}
                      className="pharmaciesspaceitem"
                    >
                      <Grid item xs={12} className="pharmaciesspaceitem2">
                        <Grid
                          item
                          xs={8}
                          ml={3}
                          mt={2}
                          className="pharmaciesstartitem"
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                sx={{ transform: "scale(1.2)" }}
                                checked={getAddLabCart?.labs?.some(
                                  (p) => p?.pharmacy?.id === item?.id
                                )}
                              />
                            }
                            label=""
                          />

                          <Grid flex>
                            <Stack className="pharmaciesnumber">
                              {item?.name}
                            </Stack>
                            <Box sx={{ fontSize: "14px" }}>
                              {item?.address?.firstline}
                              {", "}
                              {item?.address?.secondline}
                              {", "}
                              {item?.address?.city}
                              {", "}
                              {item?.address?.state}
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          xs={4}
                          mt={2}
                          className="pharmaciesslotsheading"
                          key={item.id}
                          expanded={expandedPharmacyId === item?.id}
                          onClick={() => handleAccordionToggle(item?.id)}
                        >
                          Slots available&nbsp;
                          {expandedPharmacyId === item?.id ? (
                            <ArrowDropUpIcon fontSize="medium" />
                          ) : (
                            <ArrowDropDownIcon fontSize="medium" />
                          )}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} ml={8} mt={2}>
                        <Grid item className="pharmacieslotitem">
                          {expandedPharmacyId === item?.id &&
                            item?.slots?.map((slot, index) => {
                              const transformedSlot = transformSlot(slot);
                              const isSlotSelected = getAddLabCart?.labs?.some(
                                (selected) =>
                                  selected?.pharmacy?.id === item?.id &&
                                  selected?.timing?.startdate ===
                                  slot?.startdate &&
                                  selected?.timing?.enddate === slot?.enddate
                              );

                              return (
                                <Tooltip
                                  title={slot?.averageusercount}
                                  placement="bottom"
                                  key={index}
                                >
                                  <button
                                    className={`pharmaciestimings ${isSlotSelected ? "selected-timing" : ""
                                      }`}
                                    onClick={() =>
                                      selectSlotHandler(slot, item)
                                    }
                                    disabled={
                                      slot?.averageusercount <= 0 ? true : false
                                    }
                                  >
                                    {transformedSlot?.starttime} -{" "}
                                    {transformedSlot?.endtime}
                                  </button>
                                </Tooltip>
                              );
                            })}
                          {item.price
                            .filter((priceItem) =>
                              localStorage
                                .getItem("labtest-code")
                                ?.includes(priceItem.code)
                            )
                            .map((priceItem, index) => (
                              <Grid
                                container
                                spacing={2}
                                mt={2}
                                ml={0.1}
                                key={index}
                                className="pricingdetailsitem4"
                              >
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        sx={{ transform: "scale(1.2)" }}
                                        checked={
                                          priceItem &&
                                          getAddLabCart?.selectedPrices?.some(
                                            (price) =>
                                              price?.id === priceItem?.id &&
                                              price?.pharmacyId === item?.id
                                          )
                                        }
                                        // checked={handleTestCheckbox(priceItem, item)}
                                        onChange={() =>
                                          selectTestHandler(item, priceItem)
                                        }
                                      />
                                    }
                                    label={
                                      priceItem?.category?.name ||
                                      priceItem?.pricename
                                    }
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  {priceItem.type}
                                </Grid>

                                <Grid item xs={12} sm={2}>
                                  ₹ {priceItem.value}
                                </Grid>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                ))
              ) : (
                <div className="selectlabpharmacy1 pharmaciesspaceitem">
                  No pharmacies available
                </div>
              )}
            </Grid>

            {/* *************Pagination**************** */}

            <Grid item xs={12} className="selectlabcontainer">
              <Pagination
                count={labResponse?.totalPages}
                variant="outlined"
                shape="rounded"
                boundaryCount={2}
                siblingCount={0}
                defaultPage={currentPage}
                onChange={(event, newPage) => setCurrentPage(newPage)}
                sx={{
                  "& .MuiPaginationItem-root": {
                    backgroundColor: "#ffffff",
                    fontWeight: "bold",
                  },
                  "& .MuiPaginationItem-root.Mui-selected": {
                    backgroundColor: "#ffffff",
                    border: "2px solid #039147",
                  },
                }}
              />
            </Grid>
          </Grid>

          {/* **********Pricing Detail Component****************** */}

          <Grid container xs={3} ml={3} mt={2}>
            <Grid container className="pricingdetailscontainer">
              <Grid item mt={3} ml={4} className="pricingdetailsitem">
                Pricing Details
              </Grid>

              {getAddLabCart?.labs?.length > 0 ? (
                getAddLabCart?.labs?.map((pharmacyData, pharmacyIndex) => {
                  const filteredPrices = pharmacyData?.price?.filter((item) => {
                    const selectedIdsWithPharmacyId =
                      filteredPharmacies?.flatMap((pharmacy) =>
                        pharmacy.price
                          .filter((priceItem) =>
                            getAddLabCart?.selectedPrices.some(
                              (selected) =>
                                selected?.id === priceItem?.id &&
                                selected?.pharmacyId === pharmacy?.id
                            )
                          )
                          ?.map((priceItem) => ({
                            id: priceItem?.id,
                            pharmacyId: pharmacy?.id,
                          }))
                      );

                    return selectedIdsWithPharmacyId.some(
                      (selected) =>
                        selected?.id === item?.id &&
                        selected?.pharmacyId === pharmacyData?.pharmacy?.id
                    );
                  });

                  return (
                    <React.Fragment key={pharmacyIndex}>
                      <Grid item ml={4} xs={10.5}>
                        {filteredPrices?.length > 0 && (
                          <Grid
                            container
                            mt={2}
                            className="pricingdetailsitem4"
                          >
                            <Grid item>Test Name</Grid>
                            <Grid item>Test Mode</Grid>
                            {/* <Grid item>Lab Name</Grid> */}
                            <Grid item>₹ Price</Grid>
                            {filteredPrices?.map((item, index) => (
                              <Grid
                                container
                                mt={2}
                                key={index}
                                className="pricingdetailsitem4"
                              >
                                <Grid item>{item?.pricename}</Grid>
                                <Grid item>{item?.type}</Grid>
                                {/* <Grid item>{pharmacyData?.pharmacy?.name}</Grid> */}
                                <Grid item>₹ {item?.value}</Grid>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                      {pharmacyData?.pharmacy?.name && (
                        <Grid
                          item
                          mt={3}
                          ml={4}
                          xs={10.5}
                          className="pricingdetailsitem3"
                        >
                          <div>Total for {pharmacyData?.pharmacy?.name}</div>
                          <div>
                            ₹{" "}
                            {filteredPrices
                              ? filteredPrices?.reduce(
                                (total, item) =>
                                  total + parseInt(item?.value, 10),
                                0
                              )
                              : 0}
                          </div>
                        </Grid>
                      )}
                    </React.Fragment>
                  );
                })
              ) : (
                <>
                  <Grid
                    item
                    mt={3}
                    ml={4}
                    xs={12}
                    className="pricingdetailsitem"
                  >
                    <div>
                      No test is selected. <br />
                      Please select any test to continue.
                    </div>
                  </Grid>
                  <Grid
                    item
                    mt={3}
                    ml={4}
                    xs={10.5}
                    className="pricingdetailsitem3"
                  >
                    <div>Total</div>
                    <div>₹ 0</div>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          {/* **********Pricing Detail Component Ends Here****************** */}
        </Grid>
        <div className="selectlabroot">
          <Grid
            container
            xs={12}
            mb={isSmallScreen ? 10 : 5}
            className="selectlabroot2"
          >
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() =>
                    navigate(
                      `/app/${auth?.rolename?.toLowerCase()}/lab-test-cart`
                    )
                  }
                >
                  Previous
                </button>
              </div>
              <div>
                <button className="next" onClick={handleNextButtonClick}>
                  Next
                </button>
              </div>
            </div>
            {openpopup ? (
              <MapPopup
                openpopup={openpopup}
                center={getAddLabCart.userCoordinate}
                setopenpopup={setopenpopup}
                title={"Select Location"}
                onDataTransfer={handleDataTransfer}

              />
            ) : (
              <></>
            )}
          </Grid>
        </div>
      </div>
    </>
  );
};

export default SelectLab;