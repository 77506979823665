import { Grid } from "@mui/material";
import React from "react";
import "./Section1.css";
import { useNavigate } from "react-router-dom";

const Section1 = ({ image, service, text, navigates, pagelabel }) => {
  const isMedicines = service === "Medicines";
  const navigate = useNavigate();

  return (
    <div>
      <Grid item mb={3}>
        <button
          className={`section1text ${pagelabel === service ? "selected" : ""}`}
          onClick={() => navigate(navigates)}
        >
          <img src={image} alt="Logo" className="section1img" />
          <span
            className={`section1service ${
              isMedicines ? "medicines-service" : ""
            }`}
          >
            {service} -
          </span>
          &nbsp;
          <span className="section1text">{text}</span>
        </button>
      </Grid>
    </div>
  );
};

export default Section1;
