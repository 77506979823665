import { createSlice } from "@reduxjs/toolkit";
import { createRegistrationResource, getResgistrationResource } from "Services/RegistrationResourceservice";

 
 
const initialState = {
  registrationresource: [],
  loading: false,
  error: null,
};
 
export const registrationresourceDetail = createSlice({
  name: "registrationresourceDetail",
  initialState,
  extraReducers: {
  [createRegistrationResource.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createRegistrationResource.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.registrationresource.push(action.payload.data.dto);
    },
    [createRegistrationResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getResgistrationResource.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getResgistrationResource.fulfilled]: (state, action) => {
      state.loading = false;
      state.registrationresource = action?.payload?.data?.dtoList;
 
    },
    [getResgistrationResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
 
   
  },
});
 
export default registrationresourceDetail.reducer;