import React, { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
/// NewUser page components

// NewUser layout schemas for form and form feilds

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import PersonalDetail from "./components/personalDetail";
import ContactDetail from "./components/contactDetail";

import { useDispatch } from "react-redux";

import UploadDocument from "./components/UploadDocument";
import { MoonLoader } from "react-spinners";

import swal from "sweetalert";
import MDTypography from "components/MDTypography";
import { Dialog, DialogActions } from "@mui/material";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import ResourceConfirmation from "../../../components/TrackStatus";
import { Step, StepLabel, Stepper } from "@material-ui/core";
import AddressDetail from "./components/addressDetails";
import SpecificLocation from "app/labTest/specificLocation/specificLocation";
import CommissionDetail from "./components/commissionDetails";
import Qualifications from "./components/Qualifications";
import ProfessionalInformation from "./components/professionalInformation";
import { createResource } from "Services/Resources";
import Preview from "components/Preview/Preview";
import { useSelector } from "react-redux";
import { createRegistrationResource } from "Services/RegistrationResourceservice";

const theme = createTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        "&$completed": {
          color: "green",
        },
        "&$active": {
          color: "green",
        },
      },
    },
  },
});

function getSteps() {
  return [
    "Personal Detail",
    "Address Detail",
    "Professional Information",
    "Qualifications",
    "Upload Documents",
    "Commission Detail",
  ];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <PersonalDetail formData={formData} />;

    case 1:
      return <AddressDetail formData={formData} />;

    case 2:
      return <ProfessionalInformation formData={formData} />;
    case 3:
      return <Qualifications formData={formData} />;
    case 4:
      return <UploadDocument formData={formData} />;
    case 5:
      return <CommissionDetail formData={formData} />;

    default:
      return null;
  }
}
function RegistrationResource() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [loading, setIsLoading] = useState(false); // Loader state
  const [previewData, setPreviewData] = useState({});

  const location = useLocation();
  const rolename = new URLSearchParams(location.search).get("rolename");
  const rolecode = new URLSearchParams(location.search).get("roleCode");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const error = useSelector((state) => state.registrationresource);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    setShowPreview(true);
  };

  const handleSubmit = (values, actions) => {
    setPreviewData(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const submitPreviewform = async (data) => {
    setIsLoading(true);

    const languagesArray = data.preferedlanguage.split(",");
    let professionalinformations = [];
    let qualifications = [];
    let documents = [];
 
    const submitData = {
      personalinformation: {
        firstname: data?.firstname,
        lastname: data?.lastname,
        name: data?.firstname + " " + data?.lastname,
        dateofbirth: data?.dateofbirth,
        sex: data?.gender,
        emailid: data?.emailId,
        phoneno: data.phoneno,
        languages: languagesArray,
        createdby: auth?.profileid,
        rolecode: rolecode,
        resourcecode: "HEALTHCARE",
        rolename: rolename,

        status: "ACTIVE",
        recstatus: "OPEN",
      },
      commission: {
        agentcomission: data.agentcomission,
        agentcomissiontype: data.agentcomissiontype,
        createdby: auth.profileid,
        resourcecomission: data.resourcecomission,
        resourcecomissiontype: data.resourcecomissiontype,
        status: "ACTIVE",
      },
      address: {
        firstline: data.firstline,
        secondline: data.secondline,
        pincode: data.pincodeobj,

        country: data.country,

        location: {
          coordinates: data.location,
          type: "Point",
        },
        createdby: auth.profileid,
        status: "ACTIVE",
      },
    };

    for (let i = 0; i < data?.personalinformation?.length; i++) {
      const dataval = {
        jobtitle: data.personalinformation[i].jobtitle,
        orgname: data.personalinformation[i].orgname,
        dateofjoin: data.personalinformation[i].dateofjoin,
        dateofleave: data.personalinformation[i].dateofleave,
        createdby: auth.profileid,
        status: "ACTIVE",
      };
      professionalinformations.push(dataval);
    }

    // Loop through qualification information data
    for (let i = 0; i < data?.qualificationinformation?.length; i++) {
      const dataval = {
        qualificationname: data.qualificationinformation[i].qualificationname,
        qualificationmode: data.qualificationinformation[i].qualificationmode,
        qualificationgradetype:
          data.qualificationinformation[i].qualificationgradetype,
        qualificationgrade: data.qualificationinformation[i].qualificationgrade,
        startdate: data.qualificationinformation[i].startdate,
        enddate: data.qualificationinformation[i].enddate,
        board: data.qualificationinformation[i].qualificationboard,
        documentofproof: data.qualificationinformation[i]?.uploadDocument.docid,
        createdby: auth.profileid,
        status: "ACTIVE",
      };
      qualifications.push(dataval);
    }
    for (let i = 0; i < data?.document?.length; i++) {
      const dataval = {
        name: data.document[i].name,
        description: data.document[i].filename,
        documentofproof: data.document[i].docid,
        cratedby: auth.profileid,
        status: "ACTIVE",
      };
      documents.push(dataval);
    }

    submitData.recstatus = "REGISTERED";
    // submitData.resourcecode = resourcecode;
    // submitData.resourcename = rolename;
    submitData.professionalinformations = professionalinformations;
    submitData.qualifications = qualifications;
    submitData.documents = documents;

    try {
      const response = await createRegistrationResource(submitData);
      swal("", "Registration done successfully.", "success");

      navigate("/app/agent/directory");
    } catch (error) {
      // Handle API error
      console.error("API error:", error);

      swal("Oops!", "An error occurred while submitting data.", "error");
    }
  };

  const handleClosePreview = () => {
    setShowPreview(false);
  };
  return (
    <DashboardLayout>
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 10 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h6"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Registration Form
                        </MDTypography>
                      </MDBox>
                    </MDBox>

                    <MDBox mt={2}>
                      <ThemeProvider theme={theme}>
                        <Stepper activeStep={activeStep} alternativeLabel>
                          {steps.map((label) => (
                            <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      </ThemeProvider>
                    </MDBox>

                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}

                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                          >
                            {isLastStep ? "Preview" : "next"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
        open={showPreview}
        onClose={handleClosePreview}
        fullWidth
        maxWidth="md"
      >
        <Preview data={previewData} rolename={rolename} />
        <DialogActions>
          <MDButton onClick={handleClosePreview} color="info">
            Close
          </MDButton>
          <MDButton onClick={() => submitPreviewform(previewData)} color="info">
            {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
          </MDButton>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default RegistrationResource;
