import Analytics from "layouts/dashboards/analytics";

import ProfileOverview from "layouts/pages/profile/profile-overview";

import SignInBasic from "layouts/authentication/sign-in/basic";

// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";

import Otp from "layouts/pages/otp/Otp";
import Createslot from "layouts/pages/slotcreation";

import NewAddress from "layouts/pages/AddressInformation/new-address";

import QualificationForm from "layouts/pages/qualification/new-qualification";
import NewCategory from "layouts/pages/category";

import NewUser from "layouts/pages/users/new-user";

import DoctorDetail from "layouts/pages/doctorDetail";

import FinalAppointment from "components/Resuable/appointment";
import Detailpage from "layouts/pages/Details";


import MedharvaFooter from "components/Resuable/Medharvafooter/MedharvaFooter";
import PatientReportcard from "components/Resuable/Patient Detail/PatientReportcard";
import PatientQueue from "components/Resuable/Patient Que/PatientQueue";
import PrescriptionList from "components/Resuable/PrescriptionList/PrescriptionList";
import PrescrpitionHistory from "components/Resuable/PrescriptionList/PrescrpitionHistory";
import Invoice from "components/Resuable/Invoice/Invoice";
import OperationMaster from "components/Resuable/Operation-Master";
import CreateUom from "components/Resuable/UOM-Master/CreateUom";
import CreateGst from "components/Resuable/GstMaster/CreateGst";
import CreateItem from "components/Resuable/ItemMaster/CreateItem";
import CreatePrice from "components/Resuable/PriceMaster/CreatePrice";

import Searchpatient from "components/Resuable/Search Patient/SearchPatient";
import Prescriptionview from "components/Resuable/PrescriptionList/Prescriptionview";
import UploadPrescription from "components/Resuable/Upload Prescription/UploadPrescription";
import ForgotPassword from "components/Resuable/ForgotPassword/ForgotPassword";
import ForgotPasswordOtp from "components/Resuable/ForgotPassword/ForgotPasswordOtp";
import ResetPassword from "components/Resuable/ForgotPassword/ResetPassword";

import Patientform from "components/Resuable/Patient Form/Patientform";
import PatientList from "components/Resuable/Patient Form/PatientList";

import Createnurse from "components/Resuable/Create Nurse/Createnurse";
import AddFamilymember from "components/Resuable/Add Family member/AddFamilymember";
import Familylist from "components/Resuable/Add Family member/Familylist";
import PatientPrescriptionlist from "components/Resuable/PrescriptionList/PatientPrescriptionlist";

import AdminRegistration from "components/Resuable/Admin/Registration";
import AdminOtp from "components/Resuable/Admin/AdminOtp";
import AdminPatientregistratin from "components/Resuable/Admin/AdminPatientregistratin";

import AdminCreatePrice from "components/Resuable/Admin/Admin Price/AdminCreatePrice";




import UserList from "components/Resuable/UserMaster/components/UserList";
import AssociationList from "components/Resuable/Association/components/AssociationList";
import ProjectPincodeList from "components/Resuable/ProjectPincode/components/ProjectPincodeList";
import ProjectclinicList from "components/Resuable/Projectclinic/components/ProjectclinicList";
import ProjectTeamList from "components/Resuable/projectTeam/componets/ProjectTeamList";
import InvestmnetList from "components/Resuable/InvestmnetPanel/components/InvestmnetList";
import CreateUser from "components/Resuable/Admin/NewRegistration/components/CreateUser";

import ProjectCreateList from "components/Resuable/Create Project/components/ProjectCreateList";
import InvestmentRedeem from "components/Resuable/InvestmnetPanel/InvestmentRedeem";
import Investment from "components/Resuable/InvestmnetPanel/components/CreateInvestmnetPopup";
import BankDetail from "components/Resuable/InvestmnetPanel/BankDetail/BankDetail";
import Familytree from "components/Resuable/InvestmnetPanel/familytree";
import AgentAnalytics from "components/Resuable/InvestmnetPanel/AgentAnalytics";
import WithDrawalapproval from "components/Resuable/InvestmnetPanel/WithDrawalapproval";
import InvestmentApproval from "components/Resuable/InvestmnetPanel/InvestmentApproval";
import FindDoctor from "app/findDoctor/FindDoctor";
import Login from "app/login/Login";
// import Speciality from "app/findDoctor/ashutosh/Speciality";
// import DoctorsPage from "app/findDoctor/ashutosh/Doctor Page/DoctorsPage";

import OnlineConsult from "app/onlineConsult/OnlineConsult";
import UserInformation from "components/UserInformation/userInformation";
import LabTest from "app/labTest/labTest";
import SpecificLocation from "app/labTest/specificLocation/specificLocation";

import SelectPerson from "app/labTest/Select Person/selectperson";
import SelectAddres from "app/labTest/BookLabTest/SelectAddres";
import Medicine from "app/medicine/medicine";
import UploadPrescription2 from "app/medicine/uploadPrescription/uploadPrescription";
import PrescriptionMedicines from "app/medicine/prescriptionMedicines/prescriptionMedicines";
import SelectMedicineCategory from "app/medicine/selectMedicineCategory/selectMedicineCategory";
import Selectcategory from "app/medicine/Select Category Checkout/Selectcategory";

import PharmacyCategory from "app/pharmacy/pharmacy category/PharmacyCategory";
import PharmacyPrice from "app/pharmacy/pharmacy category/pharmacy price/PharmacyPrice";



import LabTestList from "app/labTest/labTestList/labTestList";
import MedharvaCare from "app/medharvaCare/medharvaCare";
import AgentDoctor from "app/Agent create doctor/AgentDoctor";
import AgentDoctorlist from "app/Agent create doctor/AgentDoctorlist";
import ForCorporates from "app/forCorporates/forCorporates";
import ForProviders from "app/forProviders/forProviders";
import SellersSignup from "app/findDoctor/Signup Agent/SellersSignup";

// new import 
// import DoctorHome from "app/Doctor/Home";
import MainHome from "layouts/pages/home/Home";
import DepartmentList from "app/Admin/AdminWorkspace/departmentMaster/components/DepartmentList";
import RoleList from "app/Admin/AdminWorkspace/Role Master/componets/RoleList";
import DesignationList from "app/Admin/AdminWorkspace/designationMaster/components/DesignationList";
import ApprovalRolelist from "app/Admin/Aprrovedrole/ApprovalRolelist";
import AddDoctordetail from "app/Admin/AdminWorkspace/AddDoctor Detail/AddDoctordetail";
import AddPharmacyDetail from "app/Admin/AdminWorkspace/Add pharmacy/AddPharmacyDetail";
import PharmacyAddresslist from "app/Admin/AdminWorkspace/Add pharmacy/PharmacyAddresslist";
import PharmacyCategorylist from "app/Admin/AdminWorkspace/Add pharmacy/PharmacyCategorylist";
import AddPharmacyPrice from "app/Admin/AdminWorkspace/Add pharmacy/AddPharmacyPrice";
import ClinicList from "app/Admin/AdminWorkspace/Clinic Master/componets/ClinicList";
import DoctorsExpanded from "components/Resuable/PatientNurseshared/doctorsExpanded/doctorsExpanded";
import SelectLabTest from "app/labTest/Select Lab Test/selectlabTest";
import SelectLab from "app/labTest/selectLab/selectLab";
import Doctorsignup from "app/Doctor/DoctorWorkspace/doctorsignup/Doctorsignup";
import VideoConsultation from "app/Doctor/videoConsultation/VideoConsultation";
import DynamicComponent from "app/Admin/AdminWorkspace/admin create role/DynamicComponent";


 
const routes = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      // new routes 

      {
        name: "Main Home",
        key: "main-home",
        route: "/app/home/:slug",
        component: <MainHome />,
      },

      // {
      //   name: "Doctor Home",
      //   key: "doctor-home",
      //   route: "/app/doctor",
      //   component: <DoctorHome />,
      // },

      // end new routes 

      // {
      //   name: "My Profile",
      //   key: "my-profile",
      //   route: "/app/pages/profile/profile-overview",
      //   component: <ProfileOverview />,
      // },

      {
        name: "signin",
        key: "signin",
        route: "/app/authentication/sign-in/basic",
        component: <Login />,
      },
      // {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/app/home/find-doctors",
      //   component: <ProfileSummary />,
      // },

      //  {
      //   name: "Logout",
      //   key: "logout",
      //   route: "/app/home/find-doctors",
      //   component: <FindDoctor />,
      // },
      // {
      //   name: "speciality",
      //   key: "speciality",
      //   route: "/app/finddoctor/Speciality",
      //   component: <Speciality/>,
      // },
      // {
      //   name: "doctorsPage",
      //   key: "doctorsPage",
      //   route: "/app/finddoctor/DoctorsPage",
      //   component: <DoctorsPage/>,
      // },
      {
        name: "doctorsExpanded",
        key: "doctorsExpanded",
        route: "/app/finddoctor/doctorsExpanded",
        component: <DoctorsExpanded/>,
      },
      // test dynamice component
      {
        name: "Dynamic Component",
        key: "dynamiccomponent",
        route: "/app/finddoctor/DynamicComponent",
        component: <DynamicComponent/>,
      },
     
      {
        name: "Pharmacy Category",
        key: "pharmacycategory",
        route: "/app/pharmacy/pharmacycategory",
        component: <PharmacyCategory/>,
      },
      {
        name: "Add Pharmacy Detail",
        key: "pharmacycategory",
        route: "/app/pharmacy/pharmacydetail",
        component: <AddPharmacyDetail/>,
      },
     
      {
        name: "Pharmacy Address Detail",
        key: "PharmacyAddresslist",
        route: "/app/pharmacy/pharmacyaddressdetail",
        component: <PharmacyAddresslist/>,
      },
      {
        name: "Pharmacy Category Detail",
        key: "Pharmacycategorylist",
        route: "/app/pharmacy/pharmacycategorylist",
        component: <PharmacyCategorylist/>,
      },
      
      {
        name: "Pharmacy Price",
        key: "pharmacyprice",
        route: "/app/pharmacyprice/pharmacyprice",
        component: <PharmacyPrice/>,
      },
      {
        name: "Create Pharmacy Price",
        key: "adminpharmacyprice",
        route: "/app/pharmacyprice/adminpharmacyprice",
        component: <AddPharmacyPrice/>,
      },
      
      {
        name: "Forgot Password",
        key: "forgot-password",
        route: "/app/pages/users/forgot-password",
        component: <ForgotPassword />,
      },
      {
        name: "Forgot Password OTP",
        key: "forgot-paasword-otp",
        route: "/app/pages/users/forgot-paasword-otp",
        component: <ForgotPasswordOtp />,
      },
      {
        name: "Reset-PASSWORD",
        key: "reset-password",
        route: "/app/pages/users/reset-password",
        component: <ResetPassword />,
      },
      {
        name: "Patient-Information",
        key: "patient-information",
        route: "/app/pages/users/patient-information",
        component: <UserInformation/>,
      },
      
     
      {
        name: "My Lab Tests",
        key: "my-labtests",
        route: "/app/pages/users/my-labtests",
        component: <LabTestList />,
      },
      {
        name: "Online Consult ",
        key: "online-consult",
        route: "/app/pages/users/online-consult",
        component: <OnlineConsult />,
      },
      {
        name: "Lab Test",
        key: "lab-test",
        route: "/app/pages/users/lab-test",
        component: <LabTest />,
      },
      {
        name: "Specific Location",
        key: "specific-location",
        route: "/app/pages/users/specific-location",
        component: <SpecificLocation />,
      },
      {
        name: "Lab Test Cart",
        key: "lab-test-cart",
        route: "/app/pages/users/lab-test-cart",
        component: <SelectLabTest />,
      },
      {
        name: "Select Lab",
        key: "select-lab",
        route: "/app/pages/users/select-lab",
        component: <SelectLab />,
      },
      {
        name: "Select Person",
        key: "select-person",
        route: "/app/pages/users/select-person",
        component: <SelectPerson />,
      },
      {
        name: "Select Address",
        key: "select-address",
        route: "/app/pages/users/select-address",
        component: <SelectAddres />,
      },
      {
        name: "Medicine",
        key: "medicine",
        route: "/app/pages/users/medicine",
        component: <Medicine />,
      },
      {
        name: "Upload Prescription",
        key: "upload-prescription",
        route: "/app/pages/users/upload-prescription",
        component: <UploadPrescription2 />,
      },
      {
        name: "Get Medicine",
        key: "get-medicine",
        route: "/app/pages/users/get-medicine",
        component: <PrescriptionMedicines />,
      },
      {
        name: "Medicine Category",
        key: "medicine-category",
        route: "/app/pages/users/medicine-category",
        component: <SelectMedicineCategory />,
      },
      {
        name: "Medicine Cart",
        key: "medicine-cart",
        route: "/app/pages/users/medicine-cart",
        component: <Selectcategory />,
      },

      {
        name: "Medharva Care",
        key: "medharva-care",
        route: "/app/pages/users/medharva-care",
        component: <MedharvaCare />,
      },

      {
        name: "For Corporates",
        key: "for-corporates",
        route: "/app/pages/users/for-corporates",
        component: <ForCorporates />,
      },

      {
        name: "For Providers",
        key: "for-providers",
        route: "/app/pages/users/for-providers",
        component: <ForProviders />,
      },
    
      {
        name: "New Doctorsignup",
        key: "new-doctorsignup",
        route: "/app/pages/users/new-doctorsignup",
        component: <Doctorsignup/>,
      },
     
      {
        name: "Sellers Doctorsignup",
        key: "new-sellerssignup",
        route: "/app/pages/users/new-sellerssignup",
        component: <SellersSignup/>,
      },
      {
        name: "New Otp",
        key: "new-otp",
        route: "/app/pages/otp/new-otp",
        component: <Otp />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "/app/dashboards/analytics",
        component: <Analytics />,
      },
      {
        name: "New Qualification",
        key: "new-qualification",
        route: "/app/layout/pages/profile/new-qualification",
        component: <QualificationForm />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",
    name: "Slot Master",
    key: "marolemaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Slot",
        key: "CreateSlot",
        route: "/app/layout/pages/profile/create-slot",
        component: <Createslot />,
      },
      
      {
        name: "New Address",
        key: "new-address",
        route: "/app/layout/pages/profile/new-adress",
        component: <NewAddress />,
      },
      {
        name: "New Category",
        key: "new-category",
        route: "/app/layout/pages/profile/new-category",
        component: <NewCategory />,
      },
     
      {
        name: "Doctor Detail",
        key: "doctor-detail",
        route: "/app/layout/pages/profile/doctor-detail",
        component: <NewUser />,
      },

      {
        name: "Doctor Details",
        key: "doctor-details",
        route: "/app/page/doctor-details",
        component: <DoctorDetail />,
      },
      {
        name: "Search Doctor",
        key: "search-doctor",
        route: "/app/page/search-doctor",
        component: <FindDoctor/>,
      },
      {
        name: "Final Appointment",
        key: "final-appointment",
        route: "/app/page/final-appointment",
        component: <FinalAppointment />,
      },
      {
        name: "Detail-page",
        key: "detail-page",
        route: "/app/page/detail-page",
        component: <Detailpage />,
      },
      {
        name: "Medharva footer",
        key: "medharva-footer",
        route: "/app/page/medharva-footer",
        component: <MedharvaFooter />,
      },
      {
        name: "Patient Detail",
        key: "PatientReport-card",
        route: "/app/page/PatientReport-card",
        component: <PatientReportcard />,
      },
      {
        name: "Doctor List",
        key: "agentdoctor-card",
        route: "/app/page/agentDoctorlist",
        component: <AgentDoctorlist/>,
      },

     
      {
        name: "Create Doctor",
        key: "Report-card",
        route: "/app/page/finddoctor/Agentdoctor",
        component: <AgentDoctor/>,
      },

     
      {
        name: "Patient Queue",
        key: "Patientpatient-queue",
        route: "/app/page/patient-queue",
        component: <PatientQueue />,
      },
      {
        name: "My Prescription",
        key: "my-Prescription",
        route: "/app/page/my-Prescription",
        component: <PrescriptionList />,
      },
      {
        name: "Prescription-History",
        key: "prescription-history",
        route: "/app/page/prescription-history",
        component: <PrescrpitionHistory />,
      },
      {
        name: "Invoice Queue",
        key: "invoice-gernator",
        route: "/app/page/invoice-gernator",
        component: <Invoice />,
      },
      {
        name: "Operation-Master",
        key: "operation-master",
        route: "/app/page/operation-master",
        component: <OperationMaster />,
      },
      {
        name: "Create-Uom",
        key: "create-uom",
        route: "/app/page/create-uom",
        component: <CreateUom />,
      },
      {
        name: "Create-Gst",
        key: "create-gst",
        route: "/app/page/create-gst",
        component: <CreateGst />,
      },
      {
        name: "Create-Item",
        key: "create-item",
        route: "/app/page/create-item",
        component: <CreateItem />,
      },
      {
        name: "Create Price",
        key: "create-price",
        route: "/app/page/create-price",
        component: <CreatePrice />,
      },
      
      {
        name: "Search Patient",
        key: "Search-patient",
        route: "/app/page/search-patient",
        component: <Searchpatient />,
      },
      {
        name: "Prescription View",
        key: "prescription-view",
        route: "/app/page/prescription-view",
        component: <Prescriptionview />,
      },
      {
        name: "Video Consultaion",
        key: "video-consultation",
        route: "/app/page/video-consultation",
        component: <VideoConsultation />,
      },
      {
        name: "Manage EMR",
        key: "manage-emr",
        route: "/app/page/manage-emr",
        component: <UploadPrescription/>,
      },
      {
        name: "PATIENT-FORM",
        key: "patient-form",
        route: "/app/page/patient-form",
        component: <Patientform/>,
      },
      {
        name: "PATIENT List",
        key: "patient-list",
        route: "/app/page/patient-list",
        component: <PatientList/>,
      },
     
      {
        name: " Create Nurse",
        key: "create-nurse",
        route: "/app/page/create-nurse",
        component: <Createnurse/>,
      },
      {
        name: "Add Family Member",
      key: "addFamilymember",
      route: "/app/pages/users/addFamilymember",
      component: <AddFamilymember/>,
    },
    {
      name: "My Family ",
    key: "family-list",
    route: "/app/pages/users/family-list",
    component: <Familylist/>,
  },
 
  {
    name: "Approval Request",
    key: "adminrole-list",
    route: "/app/admin/approval-list",
    component: <ApprovalRolelist/>,
  },
  {
    name: "Registration Form",
    key: "admin-registration",
    route: "/app/page/admin-registration",
    component: <AdminRegistration/>,
  },
  {
    name: "Otp",
    key: "admin-otp",
    route: "/app/page/admin-otp",
    component: <AdminOtp/>,
  },
  {
    name: "Patient Registration",
    key: "admin-patientregistration",
    route: "/app/page/admin-patientregistration",
    component: <AdminPatientregistratin/>,
  },
  {
    name: "Add Doctor Detail",
    key: "admin-doctordetail",
    route: "/app/page/admin-doctordetail",
    component: <AddDoctordetail/>,
  },
  {
    name: "Crate Price",
    key: "admin-createprice",
    route: "/app/page/admin-createprice",
    component: <AdminCreatePrice/>,
  },



  {
    name: "Create Role",
    key: "role-list",
    route: "/app/page/role",
    component: <RoleList/>,
  },
  {
    name: "Create Department",
    key: "department-list",
    route: "/app/page/departmentlist",
    component: <DepartmentList/>,
  },
  {
    name: "Create Department",
    key: "designation-list",
    route: "/app/page/designationlist",
    component: <DesignationList/>,
  },
  {
    name: "Create User",
    key: "user-list",
    route: "/app/page/userlist",
    component: <UserList/>,
  },
  {
    name: "Create Association",
    key: "association-list",
    route: "/app/page/associationList",
    component: <AssociationList/>,
  },
  
  {
    name: "Create ProjectPincode",
    key: "project-pincode",
    route: "/app/page/project-pincode",
    component: <ProjectPincodeList/>,
  },
  {
    name: "Create Projectclinic",
    key: "project-cliniclist",
    route: "/app/page/project-cliniclist",
    component: <ProjectclinicList/>,
  },
  {
    name: "Create Projectteam",
    key: "project-team",
    route: "/app/page/project-team",
    component: <ProjectTeamList/>,
  },

  {
    name: "Create Investment",
    key: "investmnet-list",
    route: "/app/page/investmnet-list",
    component: <InvestmnetList/>,
  },
  {
    name: "Create User",
    key: "createuser",
    route: "/app/page/create-user",
    component: <CreateUser/>,
  },
  {
    name: "Clinic Master",
    key: "clinic-master",
    route: "/app/page/clinic-master",
    component: <ClinicList/>,
  },
  {
    name: "Create MIC",
    key: "create-mic",
    route: "/app/page/create-mic",
    component: <ProjectCreateList/>,
  },
  {
    name: "Add Investment",
    key: "add-investment",
    route: "/pages/users/add-investment",
    component: <Investment/>,
  },
  {
    name: "Investment Withdrawal",
    key: "investmentredeem",
    route: "/pages/users/investment-redeem",
    component: <InvestmentRedeem/>,
  },
  {
    name: "Bank Details",
    key: "bank-details",
    route: "/pages/users/bank-details",
    component: <BankDetail/>,
  },
  {
    name: "Profile List",
    key: "profile-list",
    route: "/pages/users/profile-list",
    component: <Familytree/>,
  },
  {
    name: "Analytics",
    key: "analytics",
    route: "/dashboards/analytics",
    component: <AgentAnalytics/>,
  },
  {
    name: "Withdrawal Approval",
    key: "withdrawalapproval",
    route: "/app/pages/users/withdrawal-approval",
    component: <WithDrawalapproval/>,
  },
  {
    name: "Investment Approval",
    key: "InvestmentApproval",
    route: "/app/pages/users/investment-approval",
    component: <InvestmentApproval/>,
  },
    ],
  },
];

export default routes;
