import { Button } from "@mui/material";
import React from "react";
import "./navigateButton.css";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


const navigateButton = (props) => {
// console.log(props)
  const navigate = useNavigate();
  const code = localStorage.getItem("doctor-code");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const handleNextClick = () => {
    if (props?.grandparentData) {
      navigate(`/app/${auth.rolename.toLowerCase()}/final-appointment?id=${props.profileid}&visitid=${props.visitId}`, {
        state: {
          date: props?.date,
          time: props?.grandparentData,
          profileid: props?.profileid,
          doctorobject: props?.doctorobject,
          doctorfess: props?.doctorfess,
          slotobject:props?.slot,
          pagelabel:props?.pagelabel,
          visitId: props?.visitId,
          visit: props?.visit
        },
      });
    } else {
    
      swal({
        title: "Warning",
        text: "Pick a slot before moving on to the next step.",
        icon: "warning",
        dangerMode: true,
      })
      
    }
  };

  return (
    <div className="spacebutton">
      <div>
        <button
          className="previous"
          onClick={() =>
            navigate(`/app/home/doctor-booking`, {
              state: {
                code: code,
              },
            })
          }
        >
          Previous
        </button>
      </div>
      <div>
        <button className="next" onClick={handleNextClick}>
          Next
        </button>
      </div>
    </div>
  );
};

export default navigateButton;
