import React, { useState } from "react";
import "./appointment.css";
import { Grid } from "@mui/material";
import { Tooltip } from "@mui/material";

const Timing = ({
  image,
  timings,
  selectedTiming,
  setSelectedTiming,
  onGrandchildData,
  label,
  slotlist,
}) => {
  const customSort = (a, b) => {
    const timeA = parseTime(a.time);
    const timeB = parseTime(b.time);

    if (timeA === null && timeB === null) {
      return 0; 
    } else if (timeA === null) {
      return 1; 
    } else if (timeB === null) {
      return -1; 
    } else {
      
      return timeA - timeB;
    }
  };
 
  // const enabledSlots = timings.filter(timing => !timing.disabled);
  // const slotCount = enabledSlots.length ? enabledSlots[0].averageCount * enabledSlots.length : 0;

  const parseTime = (timeString) => {
    const regex = /(\d{1,2}):(\d{2})\s*(AM|PM)?/i;
    const match = timeString.match(regex);
    
    if (!match) {
      return null; 
    }
    
    let hours = parseInt(match[1], 10);
    const minutes = parseInt(match[2], 10);
    const period = match[3] ? match[3].toUpperCase() : undefined;

  
    if (period === 'PM' && hours !== 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    return hours * 60 + minutes;
  };

  const sendDataToGrandparent = (value, time) => {
    setSelectedTiming(time);
    onGrandchildData(time, value);
  };

  const sortedTimings = [...timings].sort(customSort);

  // const timingsList = sortedTimings.map((timing, index) => (
  //   timing.disabled ? (
  //     <button
  //       className={`timings ${
  //         selectedTiming === timing.time ? "selected22" : ""
  //       } ${timing.disabled || timing.averageCount <= 0 ? "disabled" : ""}`}
  //       onClick={() => sendDataToGrandparent(timing.timevalue, timing.time)}
  //       disabled={timing.disabled || timing.averageCount <= 0}
  //       key={index}
  //     >
  //       {timing.time}
  //     </button>
  //   ) : (
  //     <Tooltip title={`Available slot: ${timing.averageCount}`} arrow key={index}>
  //       <button
  //         className={`timings ${
  //           selectedTiming === timing.time ? "selected22" : ""
  //         } ${timing.disabled || timing.averageCount <= 0 ? "disabled" : ""}`}
  //         onClick={() => sendDataToGrandparent(timing.timevalue, timing.time)}
  //         disabled={timing.disabled || timing.averageCount <= 0}
  //       >
  //         {timing.time}
  //       </button>
  //     </Tooltip>
  //   )
  // ));
  const timingsList = sortedTimings.map((timing, index) => {
    const isDisabled = timing.disabled || timing.averageCount <= 0;
    const button = (
      <button
        className={`timings ${selectedTiming === timing.time ? "selected22" : ""} ${isDisabled ? "disabled" : ""}`}
        onClick={() => sendDataToGrandparent(timing.timevalue, timing.time)}
        disabled={isDisabled}
        key={index}
      >
        {timing.time}
      </button>
    );
  
    return isDisabled ? (
      button
    ) : (
      <Tooltip title={`Available slot: ${timing.averageCount}`} arrow key={index}>
        {button}
      </Tooltip>
    );
  });

  return (
    <div>
      <Grid container mt={2} xs={12} className="spaceitem">
        
        <Grid container xs={12} className="spaceitem2">
          <Grid item className="startitem" ml={3}>
            <img src={image} alt="Logo" className="imgsize3" />
            <div className="morning">{label}</div>
          </Grid>
          <Grid item>
          <button className="buttonstyle1">{timings[0]?timings[0]?.averageCount*timings.length :0} slots</button>
          {/* <button className="buttonstyle1">{slotCount} slots</button> */}
          </Grid>
        </Grid>
        <Grid item mt={1} ml={3}>
          {timingsList.length === 0 ? <span style={{color:"green" , fontSize:"16px"  }}>No slot available</span> : timingsList}
        </Grid>
      </Grid>
    </div>
  );
};

export default Timing;
