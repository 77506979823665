

import React, { useState, useEffect } from "react";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import "./Navbar.css";
import medharvaImage from "../../../assets/images/Medharva-logo.svg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu";
import MDButton from "components/MDButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate } from "react-router-dom";
import SignInpopup from "layouts/authentication/sign-in/SignInpopup";
import PatientNavbar from "./PatientNavbar";
import DoctorNavbar from "./DoctorNavbar";
import Adninnavbar from "./Adninnavbar";
import NurseNavbar from "./NurseNavbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";

import { useSelector } from "react-redux";
import AppBar from "@mui/material/AppBar";
import Pharmacynavbar from "./Pharmacynavbar";
const UpaarNavbar = () => {
  const navigate = useNavigate();
  const [openpopup, setopenpopup] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1280);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedLink, setSelectedLink] = useState("MEDHARVA");
  const [scrolling, setScrolling] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const [isAccordion2Expanded, setIsAccordion2Expanded] = useState(false);

  const corporatesDropdownContent = [
    {
      label: "Corporates Plans",
      url: "#",
    },
    {
      label: "Health & Wellness Plans",
      url: "#",
    },
  ];

  const providerDropdownContent = [
    {
      label: "Software For Hospitals",
      url: "#",
    },
  ];

  function Signin() {
    setopenpopup(true);
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1280);
    };
  
    const handleScroll = () => {
      setScrolling(window.scrollY > 0);
    };
  
    const handleDropdownClose = (event) => {
      const dropdown = document.querySelector(".dropdownupaar-content");
      const menuButton = document.querySelector(".menuicon");
  
      if (
        dropdown &&
        !dropdown.contains(event.target) &&
        !menuButton.contains(event.target)
      ) {
        setDropdownVisible(false);
      }
    };
  
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleDropdownClose);
  
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleDropdownClose);
    };
  }, [dropdownVisible]);

  return (
    <AppBar>
      <div className={`navbar ${scrolling ? "scrolling" : ""}`}>
        <div className="navpart1">
          <div className="number">
            <a href="tel:+919313891689">
              <PhoneEnabledIcon className="phoneicon" /> &nbsp;&nbsp; +91
              9313891689
            </a>
          </div>
          <div className="mail">
            <a href="mailto:info@medharva.com">
              <EmailIcon className="mailicon" /> &nbsp;&nbsp; info@medharva.com
            </a>
          </div>
        </div>

        <div className="navpart2">
          <div className="icon1">
            <a href="https://www.facebook.com/people/Medharva/100094882181344/">
              <FacebookOutlinedIcon fontSize="small" color="white" />
            </a>
            &nbsp;
          </div>
          <div className="icon2">
            <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQHYgi_oEcf6ugAAAYsDpbOoxaHUNV1GxHjpkQgCa-0JCvSZl1Tmu2zRIphjr0U6paJRvEKYhdp_YUkcjDTnUBBrto3p6o8l2ifpZ3C0jINXzCaMecK8N6Z_YEYtTGEFglBbWO0=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fmedharva%2F">
              <LinkedInIcon fontSize="small" color="white" />
            </a>
            &nbsp;
          </div>
          <div className="icon3">
            <a href="https://www.instagram.com/_medharva_/">
              <InstagramIcon fontSize="small" color="white" />
            </a>
            &nbsp;
          </div>
          <div className="icon4">
            <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fmedharva">
              <TwitterIcon fontSize="small" color="white" />
            </a>
            &nbsp;
          </div>
        </div>
      </div>

      <div className="navbar1">
        <div className="logo">
          <a href="https://medharva.com/">
            <img src={medharvaImage} alt="logo" height={90} />
          </a>
        </div>

        <div className="headers">
          {isMobile ? (
            <div className="loginavatar">
              <div class="popover__wrapper" >
                <a>
                  <IconButton size="large">
                    <Icon style={{ color: "#039147" }}>account_circle</Icon>
                  </IconButton>
                </a>
                {auth?.rolecode === "PA001" && <PatientNavbar />}

                {auth?.rolecode === "DO001" && <DoctorNavbar />}
                {auth?.rolecode === "AD001" && <Adninnavbar />}
                {auth?.rolecode === "RE001" && <NurseNavbar />}
                {auth?.rolecode === "PH257" && <Pharmacynavbar />}
              </div>
              <div className="dropdownupaar">
                <MDButton
                  size="large"
                  color="info"
                  variant="gradient"
                  sx={{ height: "50px", width: "10px", borderRadius: "5px" }}
                  onClick={() => setDropdownVisible(!dropdownVisible)}
                >
                  <MenuIcon className="menuicon" color="white" />
                </MDButton>
                {dropdownVisible && (
                  <div className="dropdownupaar-content">
                    <a href="https://medharva.com/"><b>Home</b></a>
                    <a
                      href="/app"
                      className={
                        selectedLink === "MEDHARVA"
                          ? "selected"
                          : "dropdown-link"
                      }
                    >
                      <b>Find Doctors</b>
                    </a>
                    <a href="https://medharva.com/online-consultation/">
                      <b>Online Consultation</b>
                    </a>
                    <a href="https://medharva.com/lab-test/">
                      <b>Lab Test</b>
                    </a>
                    <a href="https://medharva.com/home-shop/">
                      <b>Medicines</b>
                    </a>
                    <a href="#">
                      <b>Medharva Care</b>
                    </a>
                    <Accordion
                      disablegutters
                      elevation={0}
                      expanded={isAccordionExpanded}
                      onChange={(event, isExpanded) =>
                        setIsAccordionExpanded(isExpanded)
                      }
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`accordiansummary ${
                          isAccordionExpanded ? "expand" : ""
                        }`}
                      >
                        <span className="newword2">New</span> &nbsp; For
                        Corporates{" "}
                      </AccordionSummary>
                      <AccordionDetails
                        className="accordiandetails"
                        style={{ marginBottom: -30 }}
                      >
                        {corporatesDropdownContent.map((item, index) => (
                          <a key={index} href={item.url}>
                            {item.label}
                          </a>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      disablegutters
                      expanded={isAccordion2Expanded}
                      onChange={(event, isExpanded) =>
                        setIsAccordion2Expanded(isExpanded)
                      }
                      elevation={0}
                      sx={{
                        "&:before": {
                          display: "none",
                        },
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className={`accordiansummary ${
                          isAccordion2Expanded ? "expanded" : ""
                        }`}
                      >
                        For Provider
                      </AccordionSummary>
                      <AccordionDetails className="accordiandetails">
                        {providerDropdownContent.map((item, index) => (
                          <a key={index} href={item.url}>
                            {item.label}
                          </a>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="headername">
                <a href="https://medharva.com/"><b>Home</b></a>
              </div>
              <div className="headername">
                <a
                  href="/app"
                  className={selectedLink === "MEDHARVA" ? "selected" : ""}
                >
                  <b>Find Doctors</b>
                </a>
              </div>
              <div className="headername">
                <a href="https://medharva.com/online-consultation/">
                  <b>Online Consultation</b>
                </a>
              </div>
              <div className="headername">
                <a href="https://medharva.com/lab-test/">
                  <b>Lab Test</b>
                </a>
              </div>
              <div className="headername">
                <a href="https://medharva.com/home-shop/">
                  <b>Medicines</b>
                </a>
              </div>
              <div className="headername">
                <a href="#">
                  <b>Medharva Care</b>
                </a>
              </div>
              <div className="headername">
                <a href="#">
                  <span className="newword">New</span> For Corporates{" "}
                  <sub>
                    <ArrowDropDownIcon fontSize="small" />
                  </sub>
                  <div className="dropdown-content-corporates">
                    <a href="#">Corporates Plans</a>
                    <a href="#">Health & Wellness Plans</a>
                  </div>
                </a>
              </div>
              <div className="headername">
                <a href="#">
                  For Provider{" "}
                  <sub>
                    <ArrowDropDownIcon fontSize="small" />
                  </sub>
                  <div className="dropdown-content-corporates">
                    <a href="#">Software For Hospitals</a>
                  </div>
                </a>
              </div>
              <div class="popover__wrapper">
                <a >
                  <IconButton size="large">
                    <Icon style={{ color: "#039147" }}>account_circle</Icon>
                  </IconButton>
                </a>
                {auth?.rolecode === "PA001" && <PatientNavbar />}

                {auth?.rolecode === "DO001" && <DoctorNavbar />}
                {auth?.rolecode === "AD001" && <Adninnavbar />}
                {auth?.rolecode === "RE001" && <NurseNavbar />}
                {auth?.rolecode === "PH257" && <Pharmacynavbar />}
                
              </div>
            </>
          )}
        </div>
      </div>
    </AppBar>
  );
};

export default UpaarNavbar;
