/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {  
    category,
    qualificationName,
   
    board,
    grade,
    startDate,
   
    endDate,
 address1,
  subcategory,
   zip, twitter },
} = checkout;

const validations = [
  Yup.object().shape({
   // [category.name]: Yup.string().required(category.errorMsg),
    // [subcategory.name]: Yup.string().required(subcategory.errorMsg),
    // [zip.name]: Yup.string().required(zip.errorMsg).min(6, zip.invalidMsg),
  }),
  Yup.object().shape({
    [qualificationName.name]: Yup.string().required(qualificationName.errorMsg),
 
    [grade.name]: Yup.string().required(grade.errorMsg),
    [startDate.name]: Yup.string().required(startDate.errorMsg),
    [board.name]: Yup.string().required(board.errorMsg),
    [endDate.name]: Yup.string().required(endDate.errorMsg),
  }),
 
  Yup.object().shape({
    [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];

export default validations;
