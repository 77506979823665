import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";

const initialState = {
  allCategory:[],
  visitCategory: [],
  category: [],
  loading: false,
  error: null,
};

const getCategoryByType=(arr, type)=>{
    let data = []
    for(let i=0; i< arr?.length; i++){
        if(arr[i].category.type === type){
            data.push(arr[i])
        }
    }
    return data;
}

export const getUserCategorySlice = createSlice({
  name: "getUserCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userCategoryAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userCategoryAction.fulfilled, (state, action) => {
        state.loading = false;
        state.allCategory = action.payload;
        state.visitCategory = getCategoryByType(action.payload, "VISIT");
        state.category = getCategoryByType(action.payload, "CAT");
        state.error = null;
      })
      .addCase(userCategoryAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getUserCategorySlice.reducer;