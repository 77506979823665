import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Autocomplete, FormControlLabel, Switch } from "@mui/material";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";

const PharmacyAddresslist = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);

  const [doctorList, setDoctorlist] = useState("");
  const [color] = useState("#344767");
  const dataObj = {
    columns: [
      { Header: "first line", accessor: "firstline" },
      { Header: "second line", accessor: "secondline" },
      { Header: "city", accessor: "city" },
      { Header: "Distric", accessor: "district" },
      { Header: "State", accessor: "state" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultPropsDoctor = {
    options: !doctorList ? [{ name: "Loading...", id: 0 }] : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          // Delete the item from the server
          await axios.put("address-service-mongo/api/address/v2/delete", item);

          // Update the state by filtering out the deleted item
          setTableDatas((prevData) => {
            const newRows = prevData.rows.filter((row) => row.id !== item.id);
            return { ...prevData, rows: newRows };
          });

          swal("Your Address record has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting item:", error);
          swal("Error deleting record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your Address is safe!");
      }
    });
  }

  const getRole = (id) => {
    setLoading(true);
    axios

      .get(`address-service-mongo/api/address/v2/get?profileid=${id}`)

      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  async function getPharmacyData(code) {
    await axios
      .get(
        `login-service-mongo/api/loginservice/v2/get?rolecode=${code}&recstatus=APPROVED`
      )
      .then((response) => {
        setDoctorlist(response.data?.data?.dtoList);

        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }
  useEffect(() => {
    getPharmacyData("PH257");
  }, []);
  return (
    <DashboardLayout>
  
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Address List
        </MDTypography>
        <MDBox pl={3} pr={3} lineHeight={1}>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            ></span>
          </div>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
              {...defaultPropsDoctor}
              onChange={(event, newValue) => {
                console.log(newValue);

                getRole(newValue?.profileid);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Pharmacy" />
              )}
            />
          </Grid>
        </Grid>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default PharmacyAddresslist;
