import React from 'react'
import ProfileInformation from 'components/Shared/profileInformation/ProfileInformation';
import BankDetail from 'components/Resuable/InvestmnetPanel/BankDetail/BankDetail';
import MDBox from 'components/MDBox';
const AgentProfile = () => {
  return (
    <>
        <ProfileInformation />
       <MDBox px={2.5}>
       <BankDetail/>
       </MDBox>
       
    </>
  )
}

export default AgentProfile;