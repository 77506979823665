import React, { useEffect, useState } from "react";
import imag1 from "../../../../../assets/images/img1.png";
import Slider from "react-slick";
// import ButtnSlider from "./ButtnSlider";
import Text from "./Text";
import { Grid } from "@mui/material";
import "./card.css";
import axios from "../../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import ButtonSlider from "./ButtonSlider";
import Specialitiesimages from "static/Specialities";

const Card = ({pagelabel}) => {
  const navigate = useNavigate();
  const [subcategoryList, setSubcategoryList] = useState([]);

  async function getSubCategory() {
    //setting pincode===========
    await axios
      .get(
        `category-service/api/Category/v1/getWithHierarchy?depth=2&hierarchicalcode=`
      )
      .then((response) => {
        setSubcategoryList(response?.data?.data?.dtoList);
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const [showTopButtnSlider, setShowTopButtnSlider] = useState(
    window.innerWidth <= 1000
  );
  useEffect(() => {
    const handleResize = () => {
      setShowTopButtnSlider(window.innerWidth <= 1000);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    getSubCategory();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.2,
    slidesToScroll: 1,
    centerPadding: "70px",
    // marginLeft: '15px',
    centerMode: true,
    // initialSlide:0,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "1%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerPadding: "70px",
        },
      },

      {
        breakpoint: 758,
        settings: {
          slidesToShow: 1,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 655,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          dots: true,
          speed: 500,
          slidesToShow: 1.1,
          // centerMode: true,
          centerPadding: "4%",
          initialSlide: 3,
          slidesToScroll: 2,
          appendDots: (dots) => <ul>{dots.slice(0, 3)}</ul>,
        },
      },
    ],
  };

  return (
    <div>
      <div>
        <Grid
          mt={5}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginRight: "8%",
          }}
        >
          <div>{<Text />}</div>
          {!showTopButtnSlider && <ButtonSlider />}
        </Grid>
        <div className="card-main">
          <Slider {...sliderSettings}>
            {subcategoryList.map((card, index) => (
              <div
                key={index}
                onClick={() => {
                  localStorage.setItem("doctor-code", card?.code);
                  navigate(`/app/home/doctor-booking`, {
                    state: {
                      code: card?.code,
                      pagelabel: pagelabel,
                    },
                  });
                }}
                style={{ cursor: "pointer" }}
              >
                <Grid className="card-border">
                  <div className="card-border-A">
                    <Grid className="card-border-B">
                      <img
                        className="card-image"
                        src={Specialitiesimages[card?.name]}
                        alt={`Card ${index}`}
                      />
                    </Grid>
                    <Grid className="card-title">{card?.name}</Grid>
                    {/* <Grid className="card-discription">{card?.code}</Grid> */}
                  </div>
                </Grid>
              </div>
            ))}
          </Slider>
        </div>
        <div>{showTopButtnSlider && <ButtonSlider />}</div>
      </div>
    </div>
  );
};

export default Card;
