import { Grid } from "@mui/material";
import React from "react";
import Profile from "../../../assets/images/Profile.svg";
import "./ProfileNavbarInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ProfileNavbarInfo = ({ label }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const data2 = [
    "My appointments",
    "My Lab Tests",
    "My Prescription",
    "Manage EMR",
    "Family List",
    // "My Medical Orders",
    // "My Online Consultations",
    // "My Feedback",
    // "View/Update Profile",
    // "Settings",
    "Log Out",
  ];

  const handleNavigation = (route) => {
    if (route === "Log Out") {
      reduxDispatch({ type: "LOGOUT_FULFILLED" });
    } else if (route === "My appointments") {
      navigate("/app/pages/users/my-booking");
    } else if (route === "My Lab Tests") {
      navigate("/app/pages/users/my-labtests");
    } else if (route === "My Prescription") {
      navigate("/app/page/patient-Prescription");
    } else if (route === "Manage EMR") {
      navigate("/app/page/manage-emr");
    }
    else if (route === "Family List") {
      navigate("/app/pages/users/family-list");
    }
   
  };

  return (
    <div>
      <Grid container ml={1} mt={3} xs={12}>
        <Grid
          item
          xs={12}
          className="profilenavbaritem"
          onClick={() => navigate("/app/pages/users/patient-information")}
        >
          <img src={Profile} alt="Logo" className="profilenavbarimg" />
          <span>
            {auth?.name ? (
              auth.name
                .split(" ")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")
            ) : (
              <></>
            )}
          </span>
        </Grid>
        <Grid item xs={10} ml={9} className="profilenavbaritem2">
          <div>{auth.mobileno ? "+91" + auth?.mobileno : <></>}</div>
        </Grid>
        <Grid item xs={12} mt={5} ml={5}>
          {/* Conditional rendering based on auth.logincount */}
          {auth.logincount > 1 ? (
            data2.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                className="categorynavitem2"
                mb={3}
              >
                <button
                  className={
                    label == "sidebar"
                      ? "categorynavitem2text"
                      : "categorynavitem2texttype2"
                  }
                  onClick={() => handleNavigation(item)}
                >
                  {item}
                </button>
              </Grid>
            ))
          ) : (
            // Render only logout button if logincount is not greater than 1
            <Grid item xs={12} className="categorynavitem2" mb={3}>
              <button
                className="categorynavitem2text"
                onClick={() => handleNavigation("Log Out")}
              >
                Log Out
              </button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfileNavbarInfo;
