import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { mmu, departmentname, designationname, profilename },
} = checkout;

const validations = [
  // Yup.object().shape({
  //   [mmu.name]: Yup.string().required(mmu.errorMsg),
  //   [departmentname.name]: Yup.string().required(departmentname.errorMsg),
  //   [designationname.name]: Yup.string().required(designationname.errorMsg),
  //   [profilename.name]: Yup.string().required(profilename.errorMsg),
  // }),
];

export default validations;
