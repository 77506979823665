import { createSlice } from "@reduxjs/toolkit";
import { createOtp, getOtp } from "../service/Otp";





const initialState = {
  otp: [],
  loading: false,
  error: null,
};

export const otpDetail = createSlice({
  name: "otpDetail",
  initialState,
  extraReducers: {
    [createOtp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createOtp.fulfilled]: (state, action) => {
      console.log("state",state.otp)
      state.loading = false;
      state.otp.push(action.payload.data.dto);
    },
    [createOtp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getOtp.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getOtp.fulfilled]: (state, action) => {
      state.loading = false;
      state.tp = action.payload.data.dtoList;

    },
    [getOtp.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
   
   
  
  },
});

export default otpDetail.reducer;
