// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// otpinfo page components

import FormField from "../FormField";

import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "../../../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { APIs } from "Services/APIs";
function OtpInfo({ formData, resettype }) {
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = React.useState(60);
  const [disabled, setDisabled] = useState(counter == 0 ? true : false);
  const [timerOn, setTimerOn] = useState(true);
  const [code, setCode] = useState(
    JSON.parse(localStorage.getItem("user-code"))?.code
  );
  const [emailid, setEmailId] = useState(
    JSON.parse(localStorage.getItem("user-code"))?.emailid
  );
  // const [countdown, setCountDown]=useState("")
  // const countdown = `${counter/60}:${counter%60}`
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { otp } = formField;
  var { otp: otpV } = values;

  React.useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  async function reSend(code) {
    const otp = {
      createdby: "HEALTHCARE WEB UI",
      id: null,
      // typevalue: "VERIFY",
      otptype: "EMAIL",
      recstatus: "OPEN",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      userid: code,
    };
    if (resettype) {
      axios
        .get(
          `${APIs?.otpServiceApiv2}/forget/password?msgCode=EDT&otpOn=EMAIL&resourceCode=HEALTHCARE&userid=${emailid}`
        )
        .then((response) => {
          swal(
            "Success!",
            "OTP has been sent to your registered email address.",
            "success"
          );
        })
        .catch((error) => {
          error.response.data === "404 page not found"
            ? swal("Oops!", "Something went wrong.", "warning")
            : swal(
                "Oops!",
                error?.response?.data?.aceErrors?.[0]?.errorMessage,
                "warning"
              );
        });
    } else {
      await axios
        .post(`${APIs?.otpServiceApiv1}/create`, otp)

        .then((response) => {
          swal(
            "Success!",
            "OTP has been sent to your registered email address.",
            "success"
          );
        })
        .catch((error) => {
          error.response.data === "404 page not found"
            ? swal("Oops!", "Something went wrong.", "warning")
            : swal(
                "Oops!",
                error?.response?.data?.aceErrors?.[0]?.errorMessage,
                "warning"
              );
        });
    }

    setCounter(60);
  }

  return (
    <MDBox sx={{ display: "grid", placeItems: "center" }}>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <h5 sx={{ display: "grid", placeItems: "center" }}>
            Please enter the verification OTP{" "}
          </h5>
          <FormField
            type={otp.type}
            label={otp.label}
            name={otp.name}
            value={otpV}
            placeholder={otp.placeholder}
            error={errors.otp && touched.otp}
            success={otpV.length > 0 && !errors.otp}
          />

          <MDBox sx={{ display: "grid", placeItems: "center" }}>
            <MDTypography
              variant="button"
              color="error"
              fontWeight="medium"
              textGradient
            >
              Resend code in 00:{counter}
            </MDTypography>
          </MDBox>

          <MDBox sx={{ display: "grid", placeItems: "center" }}>
            <Link to={{}}>
              <MDTypography
                onClick={() => reSend(code)}
                variant="button"
                color="info"
                fontWeight="medium"
                textGradient
              >
                Resend OTP
              </MDTypography>
            </Link>
          </MDBox>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for OtpInfo
OtpInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default OtpInfo;
