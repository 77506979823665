
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create project

export const createProject = createAsyncThunk("createProject", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `project-service/api/Project/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getProject = createAsyncThunk("getProject", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `https://api.thehansfoundation.org/project-service/api/Project/v1/get`,
      
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateProject = createAsyncThunk("updateProject", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `https://api.thehansfoundation.org/project-service/api/Project/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteProject = createAsyncThunk("deleteProject", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `https://api.thehansfoundation.org/project-service/api/Project/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




