import React from 'react';


const shimmerKeyframes = `
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const styles = `
  .shimmer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(224, 224, 224, 0.3) 0%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(224, 224, 224, 0.3) 100%
    );
    animation: loading 1.5s infinite;
  }
`;

const ShimmerSkeleton = ({ width = '100%', height = '1em', borderRadius = '4px', variant = 'rectangular' }) => {
  const style = {
    width: width,
    height: height,
    borderRadius: variant === 'circular' ? '50%' : borderRadius,
    margin: '4px 0',
    position: 'relative',
    backgroundColor: '#e0e0e0',
    overflow: 'hidden',
  };

  return (
    <div style={style}>
      <div className="shimmer" />
    </div>
  );
};

const HomeSidenavSkeleton = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      <style>{shimmerKeyframes}</style>
      <style>{styles}</style>

      <div style={{ width: '250px', backgroundColor: '#777', padding: '20px', color: 'white' ,marginLeft:"5px",marginBottom:"20px",borderRadius:"3px"}}>
        <ShimmerSkeleton width="80px" height="80px" variant="circular" />
        <ShimmerSkeleton width="150px" height="20px" style={{ marginTop: '20px' }} />
        <ShimmerSkeleton width="100px" height="15px" style={{ marginTop: '10px' }} />
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="30px" style={{ marginTop: '100px' }}  />
        </div>
        
      </div>

      <div style={{ flex: 1, padding: '30px' }}>
 
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '50px' }}>
        <ShimmerSkeleton width="50%" height="50px" style={{ marginBottom: '80px',marginTop:"50px" }} />
        <ShimmerSkeleton width="30%" height="50px" style={{ marginBottom: '80px',marginTop:"50px" }} />
        </div>
     
        <ShimmerSkeleton width="100%" height="200px" borderRadius="8px" />
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '30px' }}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop:"-30px"}}>
            <ShimmerSkeleton width="100px" height="100px" variant="circular" />
            <div>
              <ShimmerSkeleton width="200px" height="30px" />
              <ShimmerSkeleton width="150px" height="20px" style={{ marginTop: '10px' }} />
            </div>
          </div>
          <ShimmerSkeleton width="150px" height="40px" />
        </div>
      
        <div style={{ marginTop: '20px' }}>
          <ShimmerSkeleton width="100%" height="300px" />
          <div style={{ marginTop: '30px' }}>
          <ShimmerSkeleton width="100%" height="200px" style={{ marginTop: '30px' }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSidenavSkeleton;

