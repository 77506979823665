
import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { name, operation },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    
  [operation.name]: Yup.string().required(operation.errorMsg),

 
  }),
];

export default validations;
