



// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux"; 



function DiseaseInfo({ formData }) {

  const [color] = useState("#344767");
  const [diseaseValue, setDiseaseValue] = React.useState(null);
  const { formField, values, errors, touched } = formData;
    const dispatch = useDispatch();
  const {disease, loading} = useSelector((state) => state.disease);
  console.log(disease)
  const defaultPropsDisease = {
    options: disease.length <= 0
      ? [
          { name: "Communicable Disease",code:"X" },
          { name: "Non-Communicable Disease",code:"y"},
              ]
      : disease,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const { DiseaseName,  DiseaseDescrption,DiseaseType} = formField;
  var {
    DiseaseName: DiseaseNameV,
     DiseaseDescrption:  DiseaseDescrptionV,
     DiseaseType: DiseaseTypeV,
  
    
  } = values;
 

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Disease Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={DiseaseName.type}
                label=<span>{DiseaseName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={DiseaseName.name}
                value={DiseaseNameV}
                placeholder={DiseaseName.placeholder} 
                error={errors.DiseaseName && touched.DiseaseName}
                success={DiseaseNameV.length > 0 && !errors.DiseaseName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={ DiseaseDescrption.type}
                label={ DiseaseDescrption.label}
                name={ DiseaseDescrption.name}
                value={ DiseaseDescrptionV}
                placeholder={ DiseaseDescrption.placeholder}
                error={errors.DiseaseDescrption && touched. DiseaseDescrption}
                success={ DiseaseDescrptionV.length > 0 && !errors. DiseaseDescrption}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
             <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsDisease}
                value={diseaseValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setDiseaseValue(newValue);

                  values.DiseaseType = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    type={ DiseaseType.type}
                    error={errors.DiseaseType && touched.DiseaseType}
                    // success={DiseaseTypeV.length > 0 && !errors.DiseaseType}
                    variant="standard"
                    label={DiseaseType.label}
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}


DiseaseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DiseaseInfo;
