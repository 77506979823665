import {
  Autocomplete,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import Appointment from "components/Resuable/PatientNurseshared/doctorsExpanded/appointment/appointment";
import DataTable from "examples/Tables/DataTable";

import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";

import * as Yup from "yup";

const PrescriptionForm = (props) => {
  const {
    aggregiatepatient,
    // handleClose,
    // open,
    setSelectedDisease,
    setChiefcomplaint,
    chiefcomplaint,
    setRemark,
    remark,
    handleSubmit,
    setFrequencydata,
    columns,
    data,
    setSelectedTestlist,
    handleAssignFollowUp,
    showAppointments,
    updateData,
    handleAppointment,
    isLoading,
    handlePrescription,
    // videoUrl,
    orderNumber,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getDiseaseList, getTestMasterList, getFrequencyMasterList } =
    useSelector((state) => state);
  const initialValues = {
    medicine: "",
    uom: "",
    duration: "",
    dose: "",
    frequency: "",
  };
  const validationSchema = Yup.object({
    medicine: Yup.string().required("Medicine is required"),
    uom: Yup.string().required("UOM is required"),
    frequency: Yup.string().required("Frequency is required"),
    duration: Yup.string().required("Duration is required"),
    dose: Yup.string().required("Dose is required"),
  });

  const defaultPropsdisease = {
    options: !getDiseaseList?.list
      ? [{ name: "Loading...", id: 0 }]
      : getDiseaseList?.list,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsLabTest = {
    options: !getTestMasterList?.list
      ? [{ name: "Loading", id: "X" }]
      : getTestMasterList?.list,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // const [permissionsGranted, setPermissionsGranted] = useState(false);

  // useEffect(() => {
  //     const requestPermissions = async () => {
  //         try {
  //             // Request screen capture permission
  //             await navigator.mediaDevices.getDisplayMedia({ video: true });

  //             // Request camera and microphone permission
  //             await navigator.mediaDevices.getUserMedia({ video: true, audio: true });

  //             setPermissionsGranted(true);
  //         } catch (error) {
  //             console.error('Error requesting permissions', error);
  //         }
  //     };

  //     requestPermissions();
  // }, []);

  return (
    <Grid container mb={3} mt={3} justifyContent="center">
      <Grid item xs={11.5}>
        <Card>
          <DialogTitle
            style={{
              backgroundColor: "#039147",
              color: "#fff",
              textAlign: "center",
              borderRadius: "10px 10px 0px 0px",
            }}
          >
            Prescription
          </DialogTitle>
          <DialogContent>
            {/* video part  */}
            {/* {videoUrl && (
          <Grid fullWidth>
            <iframe
              src={videoUrl}
              title="Video Call"
              width="1150"
              height="700"
              allow="camera; microphone; display-capture"
            ></iframe>
          </Grid>
        )} */}

            {/* this one for upper layer */}
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Grid item container spacing={1} style={{ margin: "2px" }}>
                <Grid
                  item
                  xs={12}
                  sm={11}
                  sx={{ display: "grid", placeItems: "center" }}
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Patient ID : {aggregiatepatient?.patient?.patientid}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>

            {/* This one for down layer */}
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Name"
                  size="large"
                  value={aggregiatepatient?.patient?.name}
                />
              </Grid>

              {/* Village Input box */}
              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Gender"
                  size="large"
                  value={aggregiatepatient?.patient?.gender}

                  // multiline
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Age"
                  size="large"
                  value={aggregiatepatient?.patient?.age}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  defaultValue={[]}
                  onChange={(event, newValue) => {
                    const selectedNames = newValue?.map((item) => item?.name);

                    setSelectedDisease(selectedNames);
                  }}
                  {...defaultPropsdisease}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Disease"
                      placeholder="Search..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  style={{ width: "100%" }}
                  label="Chief Complaint"
                  size="medium"
                  type="text"
                  value={chiefcomplaint}
                  onChange={(e) => setChiefcomplaint(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  style={{ width: "100%" }}
                  label="Remark"
                  size="medium"
                  type="text"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Medicine
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="text"
                            name="medicine"
                            variant="standard"
                            value={values.medicine}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("medicine", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="medicine"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              UOM
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="text"
                            name="medicine"
                            variant="standard"
                            value={values.uom}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("uom", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="uom"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="frequency"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Frequency (times/day){" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="frequency"
                            id="frequency"
                            value={values.frequency}
                            onChange={(e) => {
                              const selectedType = e.target.value;
                              const selectedFrequency =
                                getFrequencyMasterList?.list.find(
                                  (obj) => obj.description === e.target.value
                                );
                              setFrequencydata(selectedFrequency);
                              setFieldValue("frequency", selectedType);
                            }}
                            error={
                              touched.frequency && Boolean(errors.frequency)
                            }
                          >
                            <option value=""></option>

                            {getFrequencyMasterList?.list.map((obj) => (
                              <option key={obj.id} value={obj.description}>
                                {obj.description}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="frequency"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Dose (quantity per time)
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="number"
                            name="dose"
                            variant="standard"
                            value={values.dose}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("dose", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="dose"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Duration
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="number"
                            name="duration"
                            variant="standard"
                            value={values.duration}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("duration", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="duration"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          size="small"
                        >
                          Add
                        </MDButton>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                        pagination={false}
                      />
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  defaultValue={[]}
                  onChange={(event, newValue) => {
                    console.log("909", newValue);
                    setSelectedTestlist(newValue);
                  }}
                  {...defaultPropsLabTest}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Lab Test"
                      placeholder="Select"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppointments}
                      onChange={handleAssignFollowUp}
                    />
                  }
                  label="Assign Follow Up"
                />
              </FormGroup>
              <Grid px={8} alignItems="center">
                {showAppointments && (
                  <Appointment
                    // doctor={doctor}
                    onGrandchildData={handleAppointment}
                    doctorprofileid={auth?.profileid}
                    updateData={updateData}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginTop: "7px",
                  marginRight: "7px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                  disabled={isLoading}
                  onClick={() => {
                    handlePrescription();
                  }}
                >
                  {isLoading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit Prescription"
                  )}
                </MDButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Card>
      </Grid>
    </Grid>
  );
};

export default PrescriptionForm;
