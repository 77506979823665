// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import { createProject } from "components/Resuable/Create Project/service/Projectcreate";
import { labappCode } from "static/labappCode";
import { MMUListAction } from "redux/Actions/MMUListAction";
import { labListAction } from "redux/Actions/labListAction";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";

function LabAssociationInfo({ formData }) {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();

 const { create } = useSelector((state) => state.create);
  const [color] = useState("#344767");
  const { getLabList, getMMUList, getProjectDetails } = useSelector((state) => state);


  const { formField, values, errors, touched } = formData;

  const { firstName } = formField;
  var { firstName: firstNameV } = values;




  // useEffect(() => {

  //   dispatch(MMUListAction({ createdby: auth?.profileid }));
    // dispatch(labListAction({ url : `${APIs.projectServiceApi}/get?createdby=${auth.profileid}&mappingcode=${labappCode.mappingcode}`}
    // ));
 
  // }, []); 
  useEffect(() => {
   
    if (auth.rolecode === ROLE_CODE.admincode) {
      dispatch(MMUListAction());
      
      dispatch(labListAction({ url : `${APIs.projectServiceApi}/get?type=${labappCode.mappingcode}`}
      ));
    } else {
      dispatch(MMUListAction({ mappingcode: getProjectDetails?.details?.projecthirarichalcode }));
      dispatch(labListAction({ url : `${APIs.projectServiceApi}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&type=${labappCode.mappingcode}`}
      ));
    }
  }, []);


  return (
    <MDBox>
      <BarLoader color={color} loading={getMMUList?.loading} aria-label="Loading Spinner" />
      {!getMMUList?.loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.lab && touched.lab}
              >
                <InputLabel
                  htmlFor="lab"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  LAB Project Selection
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="lab"
                  id="lab"
                  value={values.lab}
                  onChange={(e) => {
                    const lab= e.target.value;
                    const labObj =
                    getLabList?.data?.dtoList?.find((obj) => obj.code === lab) || {};

                    setFieldValue("labobj", labObj);
                    setFieldValue("lab", lab);
                  }}
                >
                  <option value=""></option>
                  {getLabList.data.dtoList?.map((obj) => (
                    <option key={obj?.id} value={obj?.code}>
                      {obj?.name}
                    </option>
                  ))}
                </Select>
                {errors.lab && touched.lab && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.lab}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.mmu && touched.mmu}>
                <InputLabel
                  htmlFor="mmu"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Select LAB
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="mmu"
                  id="mmu"
                  value={values.mmu}
                  onChange={(e) => {
                    const mmu = e.target.value;

                  const mmuname =  getMMUList?.data?.dtoList?.find((obj) => obj.code === mmu) || {};
                   
                    setFieldValue("mmuname", mmuname);
                    setFieldValue("mmu", mmu);
                  }}
                >
                  <option value=""></option>
                  {getMMUList?.data?.dtoList?.map((obj) => (
                    <option key={obj?.id} value={obj?.code}>
                      {obj?.name}
                    </option>
                  ))}
                </Select>
                {errors.mmu && touched.mmu && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.mmu}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectPincodeInfo
LabAssociationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default LabAssociationInfo;
