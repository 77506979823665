
const form = {
  formId: "new-designation-form",
  formField: {
   sno: {
      name: "sno",
      label: "Serial No.",
      type: "text",
      errorMsg: "Serial No. is required.",
    },
    heading: {
      name: "heading",
      label: "Heading",
      type: "text",
      errorMsg: "Heading is required.",
    },
   
   type: {
      name: "type",
      label: "Type",
      type: "select",
      errorMsg: "Type is required.",
    },
    description : {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Description is required.",
    },
    schedules : {
      name: "schedules",
      label: "Schedule",
      type: "text",
      errorMsg: "Schedule is required.",
    },
    condition : {
      name: "condition",
      label: "Condition",
      type: "text",
      errorMsg: "Condition is required.",
    },
    compensation : {
      name: "compensation",
      label: "Compensation",
      type: "text",
      errorMsg: "Compensation is required.",
    },

    cgst : {
      name: "cgst",
      label: "CGST",
      type: "number",
      errorMsg: "CGST is required.",
    },
    sgst : {
      name: "sgst",
      label: "SGST",
      type: "number",
      errorMsg: "SGST is required.",
    },
    igst : {
      name: "igst",
      label: "IGST",
      type: "number",
      errorMsg: "IGST is required.",
    },
 
 
 
 
 
 
  },
};

export default form;
