import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CategoryNavbarInfo from "./CategoryNavbarInfo/CategoryNavbarInfo";
import "./CategoryNavbarInfo/Section1.css"
import NavProfileInfo from "../navbar/profileInfo/NavProfileInfo";
// import CloseIcon from '@mui/icons-material/Close';

export default function Category({ open, onClose, contentType, pagelabel }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("anchor", anchor);
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const renderContent = (contentType) => {
    switch (contentType) {
      case "category":
        return <CategoryNavbarInfo pagelabel={pagelabel} />;
      case "profile":
        return <NavProfileInfo label="sidebar" />;
      default:
        return null;
    }
  };

  const list = (anchor) => (
    <Box
      className="boxcontainer"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
       {/* <button className="onclosenav" onClick={onClose}><CloseIcon /></button> */}
       {renderContent(contentType)}
    </Box>
  );

  return (
    <div>
      <Drawer anchor="left" open={open} onClose={onClose}>
        {list("left")}
      </Drawer>
    </div>
  );
}
