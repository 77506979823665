
import checkout from "../schemas/form";
const {
  formField: { departmentName, departmentDescrption, department  },
} = checkout;

const initialValues = {
  [departmentName.name]: "",
  [departmentDescrption.name]: "",
  
 
  // [department.name]: "",
};

export default initialValues;
