// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import MDInput from "components/MDInput";
// import MDBox from "components/MDBox";

// import { useDispatch } from "react-redux";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { Formik, Form, Field } from "formik";
// import {
//   FormControl,
//   InputLabel,
//   Select,
//   FormHelperText,
// } from "@material-ui/core";
// import { useSelector } from "react-redux";

// import { updateDesignation } from "../../../Admin/AdminWorkspace/designationMaster/service/Designation";
// import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
// import MDButton from "components/MDButton";
// import { APIs } from "Services/APIs";
// import { micappCode } from "static/micappCode";
// import axios from "../../../../axiosinstance";
// import { MoonLoader } from "react-spinners";

// const validationSchema = Yup.object().shape({
//   designationname: Yup.string().required("Name is required"),
//   departmentvalue: Yup.string().required("Department is required"),

//   description: Yup.string().required("Description is required"),
// });

// const DesignationEditform = (props) => {
//   const dispatch = useDispatch();
//   const { title, selectedDesignation, openpopup, setopenpopup } = props;
//   const [department, setDepartment] = useState([]);
//   const [loading, setIsLoading] = useState(false);
//   const [designation, setDesignation] = useState([]);
//   const [selectdes, setSelectDes] = useState("");
//   const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

//   const onSubmit = async(values) => {
//     setIsLoading(true)

//   const updatedDesignation = {
//       ...selectedDesignation,
//       name:values.designationname,
//     deptcode: values.departmentvalue,
//     description:values.description,
//     modifiedby: auth.profileid,
//     modifiedbyname: auth.name,
// parenthierarchicalcode: values.designationvalue ? values.designationvalue :"",
//     hierarchicalcode:values.designationvalue?values.designationvalue+"-"+selectedDesignation.code:""

//     }
//     const response = await dispatch(updateDesignation(updatedDesignation));

//     if (!response.error) {
//       setIsLoading(false);
//       handleClose();
//       swal("Good job!", "Data has been updated successfully!", {
//         icon: "success",
//       });

//     } else {
//       swal({
//         icon: "error",
//         title: "Error",
//         text:
//           response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
//           "Something wents wrong.",
//       });
//       setIsLoading(false);
//       // handleClose()
//     }

//   };

//   const initialValues = {
//     departmentvalue: selectedDesignation?.deptcode,
//     designationname: selectedDesignation?.name,
//     description: selectedDesignation?.description,
//   };

//   const handleClose = () => {
//     setopenpopup(false);
//     setSelectDes("")

//       for (let i = 0; i < designation.length; i++) {

//         if (
//           designation[i]?.hierarchicalcode ===
//           selectedDesignation?.parenthierarchicalcode
//         ) {

//           setSelectDes(designation[i].hierarchicalcode);

//         }

//       }

//   };
//   const getData  = async()=>{
//     try {
//       const response = await axios.get(
//         `${APIs.designationServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
//       );
//       setDesignation(response?.data?.data?.dtoList);
//     } catch (error) {
//       console.log("error", error);
//     }
//     try {
//       const response = await axios.get(
//         `${APIs.deparmentServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
//       );
//       setDepartment(response?.data?.data?.dtoList);
//     } catch (error) {
//       console.log("error", error);
//     }

//   }
//   // useEffect(() => {

//   //   getData();
//   //   setSelectDes("")

//   //   for (let i = 0; i < designation.length; i++) {
//   //     console.log(designation);
//   //     if (
//   //       designation[i]?.hierarchicalcode ===
//   //       selectedDesignation?.parenthierarchicalcode
//   //     ) {

//   //       setSelectDes(designation[i].hierarchicalcode);
//   //     }
//   //   }
//   // }, [selectedDesignation]);
//   useEffect(() => {
//     dispatch(getDepartment());
//     setSelectDes("")

//     for (let i = 0; i < designation.length; i++) {
//       console.log(designation);
//       if (
//         designation[i]?.hierarchicalcode ===
//         selectedDesignation?.parenthierarchicalcode
//       ) {

//         setSelectDes(designation[i].hierarchicalcode);
//       }
//     }
//   }, [selectedDesignation]);

//   return (
//     <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
//     <DialogTitle
//      style={{
//       backgroundColor: "#039147",
//       color: "#fff",
//       textAlign: "center",
//     }}
//     >
//       {title}
//     </DialogTitle>
//       <Formik
//         initialValues={initialValues}
//         validationSchema={validationSchema}
//         onSubmit={onSubmit}
//       >
//         {({ values, errors, touched, setFieldValue, handleChange }) => (
//           <Form>
//             <DialogContent style={{ width: 550, height: 320 }}>
//               <MDBox mb={2}>
//                 <MDInput
//                   type="text"
//                   label=<span>Designation Name <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
//                   variant="standard"
//                   fullWidth
//                   id="designationname"
//                   name="designationname"
//                   value={values.designationname}
//                   onChange={handleChange}
//                   InputLabelProps={{
//                     style: { paddingBottom: "2px" },
//                   }}
//                 />
//                 {errors.designationname && touched.designationname && (
//                   <FormHelperText error style={{ marginLeft: "0px" }}>
//                     {errors.designationname}
//                   </FormHelperText>
//                 )}
//               </MDBox>
//               <MDBox mb={2}>
//                 <MDInput
//                   type="text"
//                   label=<span> Designation Description <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
//                   variant="standard"
//                   fullWidth
//                   id="description"
//                   name="description"
//                   value={values.description}
//                   onChange={handleChange}
//                   InputLabelProps={{
//                     style: { paddingBottom: "2px" },
//                   }}
//                 />
//                 {errors.description && touched.description && (
//                   <FormHelperText error style={{ marginLeft: "0px" }}>
//                     {errors.description}
//                   </FormHelperText>
//                 )}
//               </MDBox>
//               <MDBox mb={2}>
//                 {/* Department Dropdown */}
//                 <FormControl
//                   fullWidth
//                   error={!!errors.departmentvalue && touched.departmentvalue}
//                 >
//                   <InputLabel
//                     htmlFor="departmentvalue"
//                     style={{
//                       paddingBottom: "2px",
//                       marginTop: "2px",
//                     }}
//                   >
//                     <span> Department <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>

//                   </InputLabel>
//                   <Select
//                     native
//                     variant="standard"
//                     style={{ width: "100%" }}
//                     name="departmentvalue"
//                     id="departmentvalue"
//                     value={values.departmentvalue}
//                     onChange={(e) => {
//                       const departmentvalue = e.target.value;
//                       setFieldValue("departmentvalue", departmentvalue);
//                     }}
//                   >
//                     <option value=""></option>
//                     {department.map((obj) => (
//                       <option key={obj.id} value={obj.code}>
//                         {obj.name}
//                       </option>
//                     ))}
//                   </Select>
//                   {errors.departmentvalue && touched.departmentvalue && (
//                     <FormHelperText error style={{ marginLeft: "0px" }}>
//                       {errors.departmentvalue}
//                     </FormHelperText>
//                   )}
//                 </FormControl>
//               </MDBox>
//               <MDBox mb={2}>
//                 {/* Department Dropdown */}
//                 <FormControl fullWidth>
//                   <InputLabel
//                     htmlFor="designationvalue"
//                     style={{
//                       paddingBottom: "2px",
//                       marginTop: "2px",
//                     }}
//                   >
//                   Parent  Designation
//                   </InputLabel>
//                   <Select
//                     native
//                     variant="standard"
//                     style={{ width: "100%" }}
//                     name="designationvalue"
//                     id="designationvalue"
//                     value={selectdes}
//                     onChange={(e) => {
//                       const designationvalue = e.target.value;

//                       setFieldValue("designationvalue", designationvalue);
//                        setSelectDes(designationvalue)
//                     }}
//                   >
//                     <option value=""></option>
//                     {designation.map((obj) => (

//                       <option key={obj.id} value={obj.hierarchicalcode}>
//                         {obj.name}
//                       </option>
//                     ))}
//                   </Select>
//                 </FormControl>
//               </MDBox>

//             </DialogContent>
//             <DialogActions style={{ justifyContent:"right" }}>
//         <MDButton onClick={handleClose} size="small" color="light">
//             Cancel
//           </MDButton>
//           <MDButton type="submit" variant="gradient" size="small" color="info">
//           {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
//           </MDButton>

//         </DialogActions>
//           </Form>
//         )}
//       </Formik>
//     </Dialog>
//   );
// };

// export default DesignationEditform;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { updateDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { micappCode } from "static/micappCode";
import { labappCode } from "static/labappCode";
import axios from "../../../axiosinstance";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";

const validationSchema = Yup.object().shape({
  designationname: Yup.string().required("Designation Name is required."),
  departmentvalue: Yup.string().required("Department is required."),
});

const DesignationEditform = (props) => {
  const dispatch = useDispatch();
  const { title, selectedDesignation, openpopup, setopenpopup, passData } =
    props;

  const [selectdes, setSelectDes] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const createdby = auth?.rolecode === ROLE_CODE?.miccode ? "" : `createdby=${auth?.profileid}`;
  const [designation, setDesignation] = useState([]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const updatedDesignation = {
      ...selectedDesignation,
      name: values.designationname,
      deptcode: values.departmentvalue,
      description: values.description,

      parenthierarchicalcode: values.designationvalue
        ? values.designationvalue
        : "",
      hierarchicalcode: values.designationvalue
        ? values.designationvalue + "-" + selectedDesignation.code
        : selectedDesignation.code,
    };
    dispatch(updateDesignation(updatedDesignation));
    setopenpopup(false);

    const response = await dispatch(updateDesignation(updatedDesignation));

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been updated successfully!", {
        icon: "success",
      });
      passData();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      // handleClose()
    }
  };

  const initialValues = {
    designationvalue: selectedDesignation?.parenthierarchicalcode,
    departmentvalue: selectedDesignation?.deptcode,
    designationname: selectedDesignation?.name,
    description: selectedDesignation?.description,
  };

  const handleClose = () => {
    setopenpopup(false);

    const initialValues = {
      designationvalue: selectedDesignation?.parenthierarchicalcode,
      departmentvalue: selectedDesignation?.deptcode,
      designationname: selectedDesignation?.name,
      description: selectedDesignation?.description,
    };

    // setSelectDes("");

    // for (let i = 0; i < designation.length; i++) {
    //   if (
    //     designation[i]?.hierarchicalcode ===
    //     selectedDesignation?.parenthierarchicalcode
    //   ) {
    //     setSelectDes(designation[i].hierarchicalcode);
    //   }
    // }
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.deparmentServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  async function getDesignationData(code) {
    try {
      const response = await axios.get(
        `${APIs.designationServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDesignation(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    setSelectDes("");
    getData();
    getDesignationData();
    // for (let i = 0; i < designation.length; i++) {
    //   console.log(designation);
    //   if (
    //     designation[i]?.hierarchicalcode ===
    //     selectedDesignation?.parenthierarchicalcode
    //   ) {
    //     setSelectDes(designation[i].hierarchicalcode);
    //   }
    // }
  }, [selectedDesignation]);

  // useEffect(() => {
  //   getData();
  //   getDesignationData();

  // }, []);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent style={{ width: 550, height: 320 }}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span>
                    Designation Name
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  variant="standard"
                  fullWidth
                  id="designationname"
                  name="designationname"
                  value={values.designationname}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.designationname && touched.designationname && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.designationname}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label="Designation Description"
                  variant="standard"
                  fullWidth
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  InputLabelProps={{
                    style: { paddingBottom: "2px" },
                  }}
                />
                {errors.description && touched.description && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.description}
                  </FormHelperText>
                )}
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmentvalue"
                    style={{
                      paddingBottom: "2px",
                      marginBottom: "2px",
                    }}
                    shrink={Boolean(values.departmentvalue)}
                  >
                    Department
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        // position: "relative",
                        // top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.departmentvalue && touched.departmentvalue && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.departmentvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
              <MDBox mb={2}>
                {/* Department Dropdown */}
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="designationvalue"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                    shrink={Boolean(values.designationvalue)}
                  >
                    Designation
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={values.designationvalue}
                    onChange={(e) => {
                      const designationvalue = e.target.value;

                      setFieldValue("designationvalue", designationvalue);
                      setSelectDes(designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designation?.map((obj) => (
                      <option key={obj?.id} value={obj?.hierarchicalcode}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <MDButton onClick={handleClose} size="small" color="light">
                Cancel
              </MDButton>
              <MDButton
                type="submit"
                variant="gradient"
                size="small"
                color="info"
              >
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DesignationEditform;
