/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "layouts/pages/users/new-user/schemas/form";

const {
  formField: {
    category,
    qualificationName,
    board,
  startDate,
  grade,
  endDate,
    mode,
    type,
    address,
    slot,
     count,
   
     date,
     startTime,
     endTime,
    
    projectselection,
   
    subcategory,
    specailization,
    
    pincode,
    twitter,
    facebook,
    instagram,
    publicEmail,
    bio,
  },
} = checkout;

const initialValues = {
  [category.name]: "",
  [qualificationName.name]: "",
  [board.name]: "",
  [endDate.name]: "",
  [grade.name]: "",
  [type.name]: "",
  [mode.name]:"",
  [startDate.name]: "",
 
  [projectselection.name]: "",
 [ specailization.name]:"",
   
    [subcategory.name]: "",
    
    [pincode.name]: "",

    [address.name]:"",
    [slot.name]:"",
    [count.name]: "",
    [slot.name]: "",
    [date.name]: "",
  
     [startTime.name]: "",
     [endTime.name]: "",

  [twitter.name]: "",
  [facebook.name]: "",
  [instagram.name]: "",
  [publicEmail.name]: "",
  [bio.name]: "",
};

export default initialValues;
