import React, { useEffect, useState } from "react";
import "../TopBooked/style.css";
import toplabimage from "../../findDoctor/assets/images/lab.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import ButtonA from "./ButtonA";
import TextTab from "./TextTab";
import axios from "../../../axiosinstance";

const Tbookedlab = () => {
  const [labTest, setLabTest] = useState([]);

  async function getLabTest() {
    await axios
      .get(
        `category-service/api/Category/v1/get?type=labtest`
      )
      .then((response) => {
        setLabTest(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getLabTest();
  }, []);

  // const Data = [
  //   {
  //     title: "Complete Blood Test",
  //     role: "E-reports on same day",
  //     label: "Starting at $150",
  //     buttonA: "Know more",
  //     buttonB: "Book now",
  //     image: toplabimage,
  //   },
  //   {
  //     title: "Complete Blood Test",
  //     role: "E-reports on same day",
  //     label: "Starting at $150",
  //     buttonA: "Know more",
  //     buttonB: "Book now",
  //     image: toplabimage,
  //   },
  //   {
  //     title: "Complete Blood Test",
  //     role: "E-reports on same day",
  //     label: "Starting at $150",
  //     buttonA: "Know more",
  //     buttonB: "Book now",
  //     image: toplabimage,
  //   },
  //   {
  //     title: "Complete Blood Test",
  //     role: "E-reports on same day",
  //     label: "Starting at $150",
  //     buttonA: "Know more",
  //     buttonB: "Book now",
  //     image: toplabimage,
  //   },
  //   {
  //     title: "Complete Blood Test",
  //     role: "E-reports on same day",
  //     label: "Starting at $150",
  //     buttonA: "Know more",
  //     buttonB: "Book now",
  //     description: "Depression, Anxiety, OCD, Anxiety, Phobias",
  //     image: toplabimage,
  //   },
  // ];
  const [showTopButtnSliderA, setShowTopButtnSliderA] = useState(
    window.innerWidth <= 1000
  );
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  useEffect(() => {
    const handleResize = () => {
      setShowTopButtnSliderA(window.innerWidth <= 1000);
      setIsMidScreen(window.innerWidth <= 900);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.2,
    centerPadding: "68px",
    centerMode: true,
    slidesToScroll: 1,
    customPaging: (i) => <button className="custom-dot">{i + 1}</button>,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: "1%",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 2,
          centerPadding: "5%",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerPadding: "10px",
        },
      },

      {
        breakpoint: 758,
        settings: {
          slidesToShow: 1,
          centerPadding: "10%",
        },
      },
      {
        breakpoint: 655,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          dots: true,
          speed: 500,
          slidesToShow: 1.1,
          // centerMode: true,
          centerPadding: "6.9%",
          initialSlide: 0,
          slidesToScroll: 2,
          appendDots: (dots) => <ul>{dots.slice(0, 3)}</ul>,
        },
      },
    ],
  };
  return (
    <div>
      <Grid mt={isMidScreen ? undefined : -7} className="Main-D-1">
        <div>{<TextTab />}</div>
        {!showTopButtnSliderA && <ButtonA />}
      </Grid>
      <div
        className="Main-HW"
        style={{ maxWidth: "1262px", height: "505px", margin: "auto" }}
      >
        <Slider {...sliderSettings}>
          {labTest.map((item, index) => (
            <div key={index}>
              <Grid className="card-border-1">
                <div className="card-border-2">
                  <Grid className="card-border-3">
                    <img
                      className="card-image-4"
                      src={toplabimage}
                      alt={`Card ${index}`}
                    />
                  </Grid>
                  <Grid className="card-title-5">
                    <Grid item xs={12} className="card-title-5a">{item.name}</Grid>
                    <Grid item xs={12} className="card-title-5b">{item.desc}</Grid>
                  </Grid>

                  <Grid item mt={3} className="card-label">
                    <Grid className="card-label-a">Starting at&nbsp;<b>₹{item.value}</b></Grid>
                  </Grid>
                  <Grid className="btn-card-A1">
                    <Grid className="btn-card-A2">
                      <Button
                        sx={{
                          width: "120px",
                          color: "#039147",
                          backgroundColor: "white",
                          border: "1px solid #039147",
                          height: "29px",
                          fontSize: "10px",
                          textTransform: "none",
                          lineHeight: "15px",
                          fontWeight: "400",
                          "&:hover": {
                            backgroundColor: "#039147",
                            borderRadius: "2px",
                            color: "white",
                          },
                        }}
                      >
                        Know more
                      </Button>
                    </Grid>
                    <Grid className="btn-card-B1">
                      <Button
                        sx={{
                          width: "120px",
                          color: "#ffffff",
                          backgroundColor: "#039147",
                          border: "1px solid #039147",
                          height: "29px",
                          fontSize: "10px",
                          textTransform: "none",
                          lineHeight: "15px",
                          fontWeight: "400",
                          "&:hover": {
                            backgroundColor: "white",
                            borderRadius: "2px",
                            color: "#039147",
                          },
                        }}
                      >
                        Book now
                      </Button>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </div>
          ))}
        </Slider>
      </div>
      <div>{showTopButtnSliderA && <ButtonA />}</div>
    </div>
  );
};
export default Tbookedlab;
