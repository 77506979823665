/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import checkout from "../schemas/form";

const {
  formField: {
    name,
    testname,
    investigationname,
    range,
    AssignEquipment,
    mapware,
    EquipmentList,
    uom,
    investigationList
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [testname.name]: "",
  // [EquipmentList.name]: "",

  [range.name]: "",
  [investigationname.name]: "",
  [uom.name]: "",
  [investigationList.name]: [],
};
export default initialValues;
