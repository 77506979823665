import * as Yup from "yup";
import checkout from "components/Resuable/Patientsignup/schemas/form";

const {
  formField: {
    firstName,
    // lastName,
 
    email,
    password,
   
    repeatPassword,
   age,
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phonenumber.name]: Yup.string()
    .required(phonenumber.errorMsg)
    .min(10, phonenumber.invalidMsg)
    .max(10, phonenumber.invalidMsg),
   
    
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  //Yup.object().shape({
  
    // [phonenumber.name]: Yup.string()
    //   .required(phonenumber.errorMsg)
    //   .min(10, phonenumber.invalidMsg)
    //   .max(10, phonenumber.invalidMsg),
   // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
 
  // }),
  Yup.object().shape({
    [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];

export default validations;
