/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useEffect, useState } from "react";

// formik components

// @mui material components

// Material Dashboard 2 PRO React components

import { getData, slot } from "Services/Slot";

// EditProduct page components

import ProductInfo from "layouts/ecommerce/products/edit-product/components/ProductInfo";
import Slotbooking from "components/Resuable/doctor-detail/components/Slot";
import Pricing from "layouts/ecommerce/products/edit-product/components/Pricing";
import DoctorDetailInfo from "components/Resuable/doctor-detail/components/DoctorDetailInfo";

import DoctorImage from "components/Resuable/doctor-detail/components/DoctorImage";
import productImage from "assets/images/products/product-11.jpg";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

function DoctorDetail() {
  const location = useLocation();
  // Use the URLSearchParams API to access the query parameter
  const queryParams = new URLSearchParams(location.search);
  const doctorid = queryParams.get("id");

  const [response, setResponse] = useState();
  // const [profileid, setProfileId] = useState(JSON.parse(localStorage.getItem("doctorprofileid"))?.profileid)
  const [profileid, setProfileId] = useState(doctorid);
  const [address, setAddress] = useState([]);
  const [doctorfess, setDoctorfees] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  function getData(addressdata) {
    setAddress(addressdata);
  }
  async function get(profileid) {
    await axios
      .get(
        `/profile-service-mongo/api/ProfileEntity/v2/getAggregated?profileid=${profileid}`
      )
      .then((response) => {
        console.log(78, response);
        setResponse(response.data?.data?.profileaggregation);
      })
      .catch((e) => {
        console.log("error", e);
      });

    try {
      const response = await axios.get(
        `/price-service/api/price/v1/get?profileid=${profileid}&type=fees`
      );
      console.log("data", response.data.data.dtoList);
      setDoctorfees(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    get(profileid);
  }, []);

  return (
    <DashboardLayout>
      <UpaarNavbar />

      <MDBox my={3} mt={12}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            {/* <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Make the changes below
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  We’re constantly trying to express ourselves and actualize our dreams. If you have
                  the opportunity to play.
                </MDTypography>
              </MDBox>
            </Grid> */}
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                {/* <MDButton variant="gradient" color="info">
                  save
                </MDButton> */}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4} mt={3}>
            {response && (
              <DoctorImage
                data={response}
                addressdata={address}
                doctorfess={doctorfess}
                doctorid={doctorid}
              />
            )}
          </Grid>
          <Grid item xs={12} lg={8} mt={4}>
            {response && <DoctorDetailInfo data={response} />}
            <div style={{ marginTop: "5px" }}>
              {auth?.rolecode === "PA001" ? (
                <Slotbooking
                  data={profileid}
                  disabled={false}
                  doctorobject={location?.state?.doctorobject}
                  getData={getData}
                  doctorfess={doctorfess}
                  doctorid={doctorid}
                />
              ) : (
                <Slotbooking
                  data={profileid}
                  disabled={false}
                  doctorobject={location?.state?.doctorobject}
                  getData={getData}
                  doctorfess={doctorfess}
                  patientrecpanist={location?.state?.recpanistpatientid}
                  doctorid={doctorid}
                />
              )}
            </div>
          </Grid>
          {/* <Grid item xs={12} lg={4}>
            <Socials />
          </Grid> */}
          {/* <Grid item xs={12} lg={8}>
            <Pricing />
          </Grid> */}
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default DoctorDetail;
