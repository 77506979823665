import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const getLoginServiceData = 
   createAsyncThunk("getLoginUserData", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.createdby){
        url = APIs.loginServiceApi +`/get?createdby=${data.createdby}`
    }else{
          url = APIs.loginServiceApi +`/get`
      }
        let userData = await getAllUsers(url, "")
        return { userList: userData , filterArray: [] } ;

    } catch (error) {
      return rejectWithValue(error);
    }
  });
 
  export const deleteLoginServiceData = createAsyncThunk("deleteLoginServiceData", async (data, {rejectWithValue}) => {
    console.log("formData", data);
  
    try {
      const response = await axios.put(
        `login-service-mongo/api/loginservice/v2/delete`,
        data
       
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });