// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// @mui material components
import Grid from "@mui/material/Grid";


// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { Autocomplete } from "@mui/material";
import axios from "axios";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";

function AdditionalInfo({ formData }) {
  const { formField, values, errors, touched } = formData;
  const { phonenumber, email} = formField;
  const { phonenumber: phonenumberV,email: emailV  } = values;


return (
    <MDBox>
      <MDTypography variant="h5" fontWeight="bold">
        Additional Information
      </MDTypography>
      <MDBox mt={1.625}>
      

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={phonenumber.type}
              label=<span>{phonenumber.label} <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              name={phonenumber.name}
              value={phonenumberV}
              placeholder={phonenumber.placeholder}
              error={errors.phonenumber && touched.phonenumber}
              success={phonenumberV.length > 0 && !errors.phonenumber}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label=<span>{email.label} <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>
          </Grid>
          
          

      </MDBox>
    </MDBox>
  );
}

// typechecking props for Address
AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};



export default AdditionalInfo;
