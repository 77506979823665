import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete, FormControl, InputLabel, Select } from "@mui/material";
import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const Diseaseedit = (props) => {
  const dispatch = useDispatch();
  const {
    title,
    selectedDisease,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { disease, loading } = useSelector((state) => state.disease);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const validationSchema = Yup.object({
    name: Yup.string().required("Disease Name is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedDisease?.name || "",
      description: selectedDisease?.description || "",
      diseasetype: selectedDisease?.type || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const updatedDisease = {
          ...selectedDisease,
          name: values.name,
          description: values.description,
          type: values.diseasetype,
          modifiedby: auth.profileid,
          modifiedbyname: auth.name,
        };
        const response = await axios.put(
          `${APIs.diseaseServiceApi}/update`,
          updatedDisease
        );
        handleClose();
        handleEditreset();
        swal("Success!", "Disease updated successfully.", "success");
      } catch (error) {
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage ||
            "something wents wrong",
          "error"
        );
      } finally {
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      name: selectedDisease?.name || "",
      description: selectedDisease?.description || "",
      diseasetype: selectedDisease?.type || "",
    });
  }, [selectedDisease]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                Disease Name
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Disease Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="diseasetype"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Disease Type
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="diseasetype"
                id="diseasetype"
                value={formik.values.diseasetype}
                onChange={(e, newValue) => {
                  const diseasetype = e.target.value;

                  formik.setFieldValue("diseasetype", diseasetype);
                }}
              >
                <option value=""></option>
                {disease?.length <= 0 ? (
                  <>
                    <option value="Communicable Disease">
                      Communicable Disease
                    </option>
                    <option value="Non-Communicable Disease">
                      Non-Communicable Disease
                    </option>
                  </>
                ) : (
                  disease?.map((obj) => (
                    <option key={obj.id} value={obj.name}>
                      {obj.name}
                    </option>
                  ))
                )}
              </Select>
            </FormControl>
          </MDBox>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose} color="light" size="small">
            Cancel
          </MDButton>

          <MDButton type="submit" color="info" size="small">
            {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Diseaseedit;
