import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import swal from "sweetalert";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";

import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";

function InvestmentApproval() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  async function update(item) {
    item.modifiedby = JSON.parse(localStorage.getItem("login-auth")).name;

    await axios
      .put(
        // "/investmentprofile-service/api/investmentprofile/v1/update",
        "/investmentitem-service/api/investmentitem/v1/update",
        // "http://localhost:6012/api/investmentprofile/v1/update",
        item
      )
      .then((response) => {
        swal("Investment Approved!", "Succesfully update!");

        getData();
      })
      .catch((error) => {
        swal("Withdrawal Approved!", "Oops something went wrong!");
      });
  }

  async function updateData(item) {
    item.recstatus = "APPROVED";
    update(item);
  }

  async function rejectData(item) {
    item.recstatus = "REJECT";
    update(item);
  }

  function getData() {
    var referalcode = JSON.parse(
      localStorage.getItem("investment-profile")
    )?.referalcode;
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "investmentprofile.name" },
        { Header: "age", accessor: "investmentprofile.age" },

        { Header: "referalcode", accessor: "investmentprofile.referalcode" },

        { Header: "commision type", accessor: "investmentitem.commissiontype" },
        { Header: "commission", accessor: "investmentitem.commission" },
        { Header: "opening balance", accessor: "investmentitem.amount" },
        {
          Header: "investment term (MM)",
          accessor: "investmentitem.investmentterm",
        },
        { Header: "start date", accessor: "investmentitem.fstartdate" },
        { Header: "end date", accessor: "investmentitem.fenddate" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        // `/investmentprofile-service/api/investmentprofile/v1/get?recstatus=WITHDRAWL_REQUESTED`
        // `http://localhost:6012/api/investmentprofile/v1/getAggregationByProfileV2?referalcode=ADA1384&recstatus=OPEN`
        `/investmentprofile-service/api/investmentprofile/v1/getAggregationByProfileV2?referalcode=${referalcode}&recstatus=OPEN`
      )
      .then((response) => {
        response?.data?.data?.investmentProfileAggregationV2?.map((item) => {
          item.action = (
            <span>
              <span>
                <Link to={{}}>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => {
                      updateData(item?.investmentitem);
                    }}
                  >
                    Approved
                  </MDButton>
                </Link>
              </span>
              &nbsp;
              <span>
                <Link to={{}}>
                  <MDButton
                    color="info"
                    size="small"
                    onClick={() => {
                      rejectData(item?.investmentitem);
                    }}
                  >
                    Reject
                  </MDButton>
                </Link>
              </span>
            </span>
          );
          item.investmentitem.fstartdate = new Date(
            item.investmentitem.startdate
          ).toDateString();
          item.investmentitem.fenddate = new Date(
            item.investmentitem.enddate
          ).toDateString();

          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <DashboardLayout>
    
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Investment Approval
                  </MDTypography>
                </span>

                {/* <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/profile/create-role"}
                >
                  <MdOutlineLibraryAdd />
                </Link> */}
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default InvestmentApproval;
