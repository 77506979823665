// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import { useDispatch,useSelector } from "react-redux";
import { useFormikContext } from "formik";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { getCategory } from "../../service/Category";

function CategoryInfo({ formData }) {
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();
  const { category, loading } = useSelector((state) => state.category);
  const [color] = useState("#344767");



  const { formField, values, errors, touched } = formData;

  const { categoryName, categoryDescrption,categoryType,parentcategory,categoryvalue } = formField;
  var { categoryName: categoryNameV, categoryDescrption: categoryDescrptionV,categoryType:categoryTypeV,parentcategory:parentcategoryV,categoryvalue:categoryvalueV } = values;
useEffect(()=>{
dispatch(getCategory())
},[])
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Category Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={categoryName.type}
                label=<span>{categoryName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={categoryName.name}
                value={categoryNameV}
                placeholder={categoryName.placeholder}
                error={errors.categoryName && touched.categoryName}
                success={categoryNameV.length > 0 && !errors.categoryName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={categoryDescrption.type}
                label={categoryDescrption.label}
                name={categoryDescrption.name}
                value={categoryDescrptionV}
                placeholder={categoryDescrption.placeholder}
                error={errors.categoryDescrption && touched.categoryDescrption}
                success={categoryDescrptionV.length > 0 && !errors.categoryDescrption}
              />
            </Grid>
              <Grid item xs={12} sm={6}>
              <FormField
                type={categoryType.type}
                label=<span>{categoryType.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={categoryType.name}
                value={categoryTypeV}
                placeholder={categoryType.placeholder}
                error={errors.categoryType && touched.categoryType}
                success={categoryTypeV.length > 0 && !errors.categoryType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={categoryvalue.type}
                label={categoryvalue.label}
                name={categoryvalue.name}
                value={categoryvalueV}
                placeholder={categoryvalue.placeholder}
                error={errors.categoryvalue && touched.categoryvalue}
                // success={categoryvalueV.length > 0 && !errors.categoryvalue}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={!!errors.parentcategory && touched.parentcategory}
            >
              <InputLabel
                htmlFor="parentcategory"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
               Parent Category
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="parentcategory"
                id="parentcategory"
                value={values.parentcategory}
                onChange={(e) => {
                  const parentcategory = e.target.value;

                  const selectedDepartmentObj = category.find(obj => obj.code === parentcategory) || {};
                  console.log("selectedDepartmentObj",selectedDepartmentObj.hierarchicalcode)

                
                   setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                  setFieldValue("parentcategory", parentcategory);
                }}
              >
                <option value=""></option>
                {category?.map((obj) => (
                  <option key={obj.id} value={obj.code}>
                    {obj.name}
                  </option>
                ))}
              </Select>
              {errors.parentcategory && touched.parentcategory && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.parentcategory}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          </Grid>
   
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for CategoryInfo
CategoryInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CategoryInfo;
