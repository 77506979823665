import { Card, Dialog } from '@material-ui/core'
import DashboardLayout from 'components/DashboardLayout'
import FilterCmp from 'components/Filters/FilterCmp'
import Negotiate from 'components/Popup/Negotiate'
import TableCmp from 'components/Tables/TableCmp'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { tableDataAction } from 'redux/Actions/tableDataAction';
import { clearActionRowData } from 'redux/slices/tableActionRow'
import Swal, {SweetAlertOptions} from 'sweetalert2';

const OnboardedList = () => {

    const { tableData, AuthReducer, tableActionRow } = useSelector(state => state);
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData]= useState()
    const [filterData, setFilterData] = useState({})
    // const [openNegotiate, setOpenNegotiate] = useState(false);
    // const [negotiateValue, setNegotiateValue] = useState()
    const navigate = useNavigate();

    useEffect(async()=>{
        dispatch(tableDataAction({ page:"directory", profileId:AuthReducer?.auth?.profileid}))
    },[])
    

    useEffect(()=>{
        dispatch(tableDataAction({name:"currentPage", value:currentPage, page:"allList"}))
    },[currentPage])
    const modifiedRows = tableData?.rowData?.dtoList?.map(row => ({
      ...row,
      name: `${row.firstname} ${row.lastname}` 
  }));
  const columns = [
    { id:"resourceName", label: "Resource Name", accessor: "resourceName" },
    { id:"role",  label: "Role", accessor: "role" },
    { id:"agentCommission",  label: "Agent Commission", accessor: "agentCommission" },
    { id:"resourceCommission",  label: "Resource Commission", accessor: "resourceCommission" },
    { id:"registrationDate",  label: "Registration Date", accessor: "registrationDate" },
  ]

  const actionButtons = ["view"]

    if(tableActionRow.actionType === "view"){
     const profileid = tableActionRow?.rowData?.profileid
      // dispatch(clearActionOfRow())
      navigate('/app/mic/agents/view-profile');
    }

const closeNegotiate =()=> {
      setOpenNegotiate(false);
      dispatch(clearActionRowData())
  }

  // filter handler

  const handleSearch =()=>{
    console.log(filterData, "Filter data")
  }

  const handleClear =()=>{
    setFilterData({})
  }
 
  return (
    <DashboardLayout>
        <Card style={{marginTop:"5%"}}>
            <FilterCmp filterArray={tableData?.filterArray} filterData={filterData} setFilterData={setFilterData}
              handleSearch={handleSearch} handleClear={handleClear} />
            <TableCmp columns={columns} rows={modifiedRows} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages} actionButtons={actionButtons} />
        </Card>
    </DashboardLayout>
  )
}

export default OnboardedList