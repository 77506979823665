import { createSlice } from "@reduxjs/toolkit";
import { createState, deleteState, getState, updateState } from "../service/State";





const initialState = {
  statelist: [],
  loading: false,
  error: null,
};

export const stateDetail = createSlice({
  name: "stateDetail",
  initialState,
  extraReducers: {
    [createState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createState.fulfilled]: (state, action) => {
      console.log("state",state.statelist)
      state.loading = false;
      state.statelist.push(action.payload.data.dto);
    },
    [createState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getState.fulfilled]: (state, action) => {
      state.loading = false;
      state.statelist = action.payload.data.dtoList;

    },
    [getState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteState.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.statelist = state.statelist.filter((statelist) => statelist.id !== id);
      }
    },
    [deleteState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateState.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateState.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.statelist.findIndex(statelist => statelist.id === action.payload.data.dto.id)
      state.statelist[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateState.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default stateDetail.reducer;
