import React from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import ProfileSkeleton from '../../../components/Skeleton/ProfileSkeleton';

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDAvatar from 'components/MDAvatar';
import { ROLE_CODE } from "privateComponents/codes";
// Images
import logoimage from '../../../assets/images/bruce-mars.jpg';
import ChangePassword from 'components/Resuable/Change Password/ChangePassword';
import { WrapText } from '@material-ui/icons';

const PatientInfo = () => {
  const navigate = useNavigate();
  const [loadingState, setLoadingState] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [patientaddress, setPatientAddress] = useState(
    JSON.parse(localStorage.getItem('address'))
  );
  const [openpopupPassword, setopenpopupPassword] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setLoadingState(true);
    }, 500);
  }, []);

  return (
    <Grid mt={26}>
      <MDBox mt={{ xs: 4, md: 10 }} mb={{ xs: 4, md: 8 }}>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={8}>
            <Card>
              <MDBox p={3} ml={5}>
                <Grid container spacing={1.5} sx={{ display: 'flex' }}>
                  <Grid
                    container
                    xs={12}
                    sm={4}
                    mt={3}
                    sx={{
                      display: 'flex',
                      justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                      order: { xs: 1, sm: 3 },
                    }}
                  >
                 
                    <Grid
  container
  direction="row"
  spacing={1}
  xs={12}
  sm={5}
  mt={1}
  sx={{ display: 'flex', justifyContent: 'flex-end' }}
>


  <Grid item>
    {loadingState ? (
      <MDButton
        color='info'
        size='small'
        onClick={() => {
          setopenpopupPassword(true);
        }}
        style={{whiteSpace:"nowrap"}}
      >
        Change Password
      </MDButton>
    ) : (
      <ProfileSkeleton variant='rectangular' width={100} height={36} />
    )}
  </Grid>
 
</Grid>

                  </Grid>
                  <Grid item xs={12} sm={3} mt={2} sx={{ order: { xs: 2, sm: 1 } }}>
                    <MDBox>
                      {loadingState ? (
                        <MDAvatar size='xxl' src={logoimage} alt='Gold Glasses' />
                      ) : (
                        <ProfileSkeleton variant='circular' width={100} height={100} />
                      )}
                    </MDBox>
                    <Grid item mt={2}>
    {loadingState ? (
      <MDButton
        color='info'
        size='small'
        // onClick={() => {
        //   navigate('/app/patient/patient-information');
        // }}
        onClick={() => {

          
          auth?.rolecode === ROLE_CODE.patientcode
            ? navigate("/app/patient/patient-information")
            : navigate("/app/nurse/nurse-information");
        }}
        style={{whiteSpace:"nowrap"}}
      >
        Edit Profile
      </MDButton>
    ) : (
      <ProfileSkeleton variant='rectangular' width={100} height={36} />
    )}
  </Grid>
                  </Grid>
                  <Grid item xs={12} sm={4.5} mt={3} sx={{ order: { xs: 3, sm: 2 } }}>
                    {loadingState ? (
                      <>
                        <MDTypography variant='h6' fontWeight='medium'>
                          Name :<span style={{ marginLeft: '4%' }}> {auth?.name}</span>
                        </MDTypography>
                        <MDTypography variant='h6' fontWeight='medium'>
                          Gender :<span style={{ marginLeft: '4%' }}> {auth?.sex}</span>
                        </MDTypography>
                        <MDTypography variant='h6' fontWeight='medium'>
                          Age : <span style={{ marginLeft: '4%' }}>{auth?.age}</span>
                        </MDTypography>
                        <MDTypography variant='h6' fontWeight='medium'>
                          Blood Group : <span style={{ marginLeft: '4%' }}>{auth?.bloodgroup}</span>
                        </MDTypography>
                      </>
                    ) : (
                      <>
                        <ProfileSkeleton width='80%' />
                        <ProfileSkeleton width='60%' />
                        <ProfileSkeleton width='70%' />
                        <ProfileSkeleton width='50%' />
                      </>
                    )}
                  </Grid>
                </Grid>
                <Grid container spacing={0.5} mt={1}>
                  {loadingState ? (
                    <>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Email : <span style={{ marginLeft: '4%' }}>{auth?.emailid}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Phone No : <span style={{ marginLeft: '4%' }}>{auth?.mobileno}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Language : <span style={{ marginLeft: '4%' }}>{auth?.language}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          House no : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.firstline : ''}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Colony : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.secondline : ''}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          City : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.city : ''}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          State : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.state : ''}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Country : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.country : ''}</span>
                        </MDTypography>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <MDTypography variant='h6' fontWeight='medium' mt={3}>
                          Pincode : <span style={{ marginLeft: '4%' }}>{patientaddress ? patientaddress[0]?.postcode : ''}</span>
                        </MDTypography>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='80%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='60%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='70%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='50%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='80%' />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <ProfileSkeleton width='60%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='70%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='50%' />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProfileSkeleton width='80%' />
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <ChangePassword
      title = {"Change Password"}
      openpopupPassword = {openpopupPassword}
      setopenpopupPassword = {setopenpopupPassword}
      />
    </Grid>
  );
};

export default PatientInfo;

