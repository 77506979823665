import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import axios from "../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaRegEdit } from "react-icons/fa";
import { MdCheckBox, MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Checkbox,
  TextField,
  Typography,
} from "@mui/material";

import DateTimePicker from "react-datetime-picker";
import { APIs } from "Services/APIs";
import { useSelector } from "react-redux";
import DashboardLayout from "components/DashboardLayout";

function BulkApprovedScheduler() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [enddate, setEnddate] = useState(new Date());
  const [address, setAddress] = useState("");
  const [startdate, setStartdate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [defaultMMU, setDefaultMMU] = useState();
  const [mmucode, setMmucode] = useState("");
  const [mmuList, setMmuList] = useState([]);
  const [updateObject, setUpdateObject] = useState();
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [checkusers, setcheckeduser] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [eventList, setEventList] = useState([]);
  const [approvedQty, setApprovedQty] = useState(0);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [mmuCodeList, setmmuCodeList] = useState(
    []
  );
  const [getResponse, setResponse] = useState([]);
  function getMMUData() {
    axios
      .get(
        `${APIs.mmuCreationService}/get`
      )
      .then((response) => {
   
        setMmuList(response?.data?.data?.dtoList);
        setmmuCodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + " (" + option.vehicle?.name + ")" : option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  function tConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { 
      time = time.slice(1);
      time[5] = +time[0] < 12 ? ' am' : ' pm';
      time[0] = +time[0] % 12 || 12;
    }
    return time.join(''); 
  }

  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    var df =  moment(date, format).tz("Asia/Kolkata").format(format);
    return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
}
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const handleChange = (e, resEventList) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempEvent = resEventList.map((event) => {
        return { ...event, isChecked: checked };
      });
      dataObj.rows = tempEvent;
    } else {
      let tempEvent = resEventList.map((event) => {
        event.title === name ? { ...event, isChecked: checked } : event;
        if (event.title === name) {
        }
      });
      dataObj.rows = tempEvent;
      setTableDatas(dataObj);
    }
  };
  const handleChangeV2 = (e, resEventList = eventList) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      for (let i = 0; i < resEventList.length; i++) {
        resEventList[i].checkbox.props.checked = true;
        resEventList[i].isChecked = true;
      }
    } else {
      for (let i = 0; i < resEventList.length; i++) {
        if (resEventList[i].title === name) {
          resEventList[i].checkbox.props.checked = true;
          resEventList[i].isChecked = true;
        }
      }
    }
  };

  const dataObj = {
    columns: [
      { Header: "title", accessor: "title", width: "25%" },
      { Header: "address", accessor: "finalAddress", width: "30%" },
      { Header: "start date", accessor: "fstartdate" },
      { Header: "end date", accessor: "fenddate" },
      { Header: "action", accessor: "action", width: "12%" },
    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.name + "(" + option.vehicle.name + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onUpdatedata(object) {
    setTitle(object.title);
    setAddress(object.address);
    setEnddate(new Date(object.enddate));
    setStartdate(new Date(object.startdate));
    setUpdateObject(object);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

 

  async function onDelete(item, mmu) {
    if (
      window.confirm("Are you sure that you want to delete that user record")
    ) {
      delete item.action;
      await axios
        .put(
          `${APIs.eventServiceApi}/delete`,
          item
        )
        .then((response) => {
          getData(response?.data?.data?.dto?.mmucode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function updateEvent(updateObject, recstatus, color) {
    updateObject.recstatus = recstatus;
    updateObject.documentofproof = color;
    updateObject.modifiedby = auth?.name;
    updateObject.approvedby = auth?.name;
    delete updateObject.action;

    if (window.confirm("Are you sure that you want to Approved this record")) {
      await axios
        .put(
          `${APIs.eventServiceApi}/update`,
          updateObject
        )
        .then((response) => {
          setOpen(false);
          getData(response?.data?.data?.dto?.mmucode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function getData(mmucode) {
    setLoading(true);


    axios
      .get(
        `${APIs.eventServiceApi}/get?mmucode=${mmucode}&recstatus=OPEN`
      )
      .then((response) => {
        dataObj.rows = [];

        response?.data?.data?.dtoList?.map((item) => {
          item.startdateformated=convertTZ(item.startdate);
          item.enddateformated=convertTZ(item.enddate);
          item.action = (
            <span>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  updateEvent(item, "APPROVED", "#2db551");
                }}
              >
                Approved
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  updateEvent(item, "REJECTED", "#b52d2d");
                }}
              >
                Reject
              </MDButton>
            </span>
          );
          item.isChecked = false;

          if (typeof item?.pincode !== "undefined" && item?.pincode !== null) {
            item.finalAddress =
              item.pincode?.village?.name +
              " (" +
              item.pincode?.pincode +
              ") " +
              item.pincode?.subdistrict?.name +
              " " +
              item.pincode?.district?.name +
              " " +
              item.pincode?.state?.name;
          }

          dataObj.rows.push(item);
        });
        setEventList(response?.data?.data?.dtoList);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }


  useEffect(() => {
    getMMUData();

  }, []);
  const tranactionalObj = {
    mmuid: "",
    mrnumber: "",
    ponnumber: "",
    whid: "",
    approvedby: "",
    approveddate: "",
    blockid: "",
    createdby: "",
    createddate: null,
    documentofproof: "",
    flowtype: "",
    id: null,
    itemname: "",
    itemtype: "",
    latlong: "",
    location: null,
    mmucode: "",
    modifiedby: "",
    modifieddate: "",
    quantity: approvedQty,
    referencenumber: "",
    reqLineitemid: "",
    sourcereference: "",
    targetreference: "",
    uom: "",
    recstatus: "OPEN",
    status: "ACTIVE",
    syncstatus: "SYNCED",
  };

  function handleSubmit(request, recStatus) {
    request.status = recStatus;
    request.modifiedby = auth?.name;
    request.modifieddate = new Date();
    updateBulkSchedule(request);
  }

  async function getMmuDataByWarehouse(whcode) {
    setLoading(true);

    await axios
      .get(
        `${APIs.mmuCreationService}/get?warehouse.whmmucode=${whcode}`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);

    await axios
      .get(
        `${APIs.medrequisitiongenServiceApi}/getRequisitionAggregation?recStatus=OPEN&whid=${wharehouse}`
      )
      .then((response) => {
        response?.data?.data?.medRequisitionAggregationList?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "whid" },
              { Header: " type", accessor: "itemtype" },
              { Header: "Requested Qty", accessor: "quantityrqst" },
            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response.data.data.medRequisitionAggregationList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `${APIs.bulkImportApi}/getByStatus?status=UPLOADED&mmucode=${mmucode}`
      )
      .then((response) => {
        response?.data?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "title", accessor: "title" },
              { Header: "mmucode", accessor: "mmucode" },
              { Header: "address", accessor: "village" },
              { Header: "start date", accessor: "startdate" },
              { Header: "end date", accessor: "enddate" },
            ],
            rows: [],
          };
          item.csvdatalist.map((i) => {
            i.fstartdate=convertTZ(i.startdate);
          i.fenddate=convertTZ(i.enddate);
            if (
              typeof i.pincodeValue?.pincode !== "undefined" &&
              i.pincodeValue?.pincode !== null
            ) {
              i.village =
                i.pincodeValue.pincode?.village?.name +
                " " +
                i.pincodeValue.pincode?.subdistrict?.name +
                " (" +
                i.pincodeValue.pincode?.pincode +
                ") " +
                i.pincodeValue.pincode?.district?.name +
                " " +
                i.pincodeValue.pincode?.state?.name;
            }
            dataObj.rows.push(i);
          });

          item.lineItem = dataObj;
        });
        setResponse(response?.data);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const updateBulkSchedule = async (object) => {
    await axios
      .put(
        `${APIs.bulkImportApi}/update`,
        object
      )

      .then((response) => {
        var mmucode = response?.data?.mmucode;
        getDataBymmu(mmucode);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <DashboardLayout>
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Bulk Approval
                  </MDTypography>
                </span>
                <Grid container spacing={3} my={1}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            <div>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Filename :{" "}
                          </text>
                          {values.filename}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            MMU Code :{" "}
                          </text>
                          {values.mmucode}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Uploaded By :{" "}
                          </text>
                          {values.uploadedby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Uploaded Date :{" "}
                          </text>

                          {new Date(values.uploadeddate).toDateString()}

                          <MDButton
                            circular="true"
                            color="success"
                            size="small"
                            style={{
                              marginLeft: "150px",
                              marginRight: "15px",
                            }}
                            variant="gradient"
                            onClick={() => handleSubmit(values, "APPROVED")}
                          >
                            Approved
                          </MDButton>
                          <MDButton
                            size="small"
                            variant="gradient"
                            circular="true"
                            color="error"
                            onClick={() => handleSubmit(values, "REJECT")}
                          >
                            Reject
                          </MDButton>
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values.lineItem}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default BulkApprovedScheduler;
