import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { shortname, districtname, state, districCode },
} = checkout;

const validations = [
  Yup.object().shape({
    [shortname.name]: Yup.string().required(shortname.errorMsg),

    [districtname.name]: Yup.string().required(districtname.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [districCode.name]: Yup.string().required(districCode.errorMsg),
  }),
];

export default validations;
