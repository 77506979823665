// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import axios from "../../../../axiosinstance";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import { APIs } from "Services/APIs";
import { labappCode } from "static/labappCode";
import { micappCode } from "static/micappCode";
import { ROLE_CODE } from "privateComponents/codes";

function DesignationInfo({ formData }) {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();
  // const { department, loading } = useSelector((state) => state.department);
  const [department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(false);
  // const { designation } = useSelector((state) => state.designation);
  const [designation, setDesignation] = useState([]);
  const [color] = useState("#344767");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const createdby = auth?.rolecode === ROLE_CODE?.miccode ? "" : `createdby=${auth?.profileid}`;

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);
  const [value, setValue] = React.useState(null);

  const [designationList, setDsignationList] = useState("");
  const { formField, values, errors, touched } = formData;
  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.deparmentServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  async function getDesignationData(code) {
    try {
      const response = await axios.get(
        `${APIs.designationServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDesignation(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(async () => {
    getData();
    getDesignationData();
    // if (values?.departmentvalue !== "") {
    //   getDesignationData(values?.departmentvalue)
    // }
  }, []);
  const defaultProps = {
    options: !department ? [{ name: "Loading...", id: 0 }] : department,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropDesignation = {
    options: !designation ? [{ name: "Loading...", id: 0 }] : designation,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { designationName, designationDescription } = formField;
  var {
    designationName: designationNameV,
    designationDescription: designationDescriptionV,
    hierarchicalCode: hierarchicalCodeV,
  } = values;

  // useEffect(() => {
  //   dispatch(getDepartment());
  //   // dispatch(getDesignation());
  // }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Designation Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={designationName.type}
                label=<span>
                  {designationName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={designationName.name}
                value={designationNameV}
                placeholder={designationName.placeholder}
                error={errors.designationName && touched.designationName}
                success={designationNameV.length > 0 && !errors.designationName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={designationDescription.type}
                label={designationDescription.label}
                name={designationDescription.name}
                value={designationDescriptionV}
                placeholder={designationDescription.placeholder}
                error={
                  errors.designationDescription &&
                  touched.designationDescription
                }
                success={
                  designationDescriptionV.length > 0 &&
                  !errors.designationDescription
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} sm={6}>
             

              <Autocomplete
                {...defaultProps}
                value={value}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setValue(newValue);

                  values.department = newValue?.code;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.department && touched.departmentList}
                    success={department.length > 0 && !errors.department}
                    variant="standard"
                    label={department.label}
                  />
                )}
              />
              </Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.departmentvalue && touched.departmentvalue}
              >
                <InputLabel
                  htmlFor="departmentvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Department
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="departmentvalue"
                  id="departmentvalue"
                  value={values.departmentvalue}
                  onChange={(e) => {
                    const departmentvalue = e.target.value;

                    // const selectedDepartmentObj = department.find(obj => obj.code === productdepartmnet) || {};
                    // console.log("selectedDepartmentObj",selectedDepartmentObj.hierarchicalcode)

                    setFieldValue("departmentvalue", departmentvalue);
                    //  setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                  }}
                >
                  <option value=""></option>
                  {department.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.departmentvalue && touched.departmentvalue && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.departmentvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {/* <Autocomplete
                 {...defaultPropDesignation}
                value={hierarchicalCode}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setHierarchicalCode(newValue);

                  values.designation = newValue.hierarchicalcode;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                   
                    variant="standard"
                    label="Designation"
                  />
                )}
              /> */}

              <FormControl fullWidth>
                <InputLabel
                  htmlFor="designationvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Parent Designation
                  {/* <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup> */}
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="designationvalue"
                  id="designationvalue"
                  value={values.designationvalue}
                  onChange={(e) => {
                    const designationvalue = e.target.value;

                    // const selectedDepartmentObj = department.find(obj => obj.code === productdepartmnet) || {};
                    // console.log("selectedDepartmentObj",selectedDepartmentObj.hierarchicalcode)

                    setFieldValue("designation", designationvalue);
                    //  setFieldValue("hierarchicalcode", selectedDepartmentObj.hierarchicalcode);
                  }}
                >
                  <option value=""></option>
                  {designation.map((obj) => (
                    <option key={obj.id} value={obj.hierarchicalcode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DesignationInfo
DesignationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DesignationInfo;
