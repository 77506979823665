import React from 'react'
import '../MedicalStore/stylea.css'
const Text02 = () => {
    return (
      <div>
          <div className='text-slider-0a'>
     <div className='text-slider-0B'>
      <span className='text-slider-0C' 
      >Popular Medicines
     </span>
     {/* <span className='text-slider-0D'>Fromyour truted brands</span> */}
     </div>
          </div>
       
      </div>
    )
  }
  
  export default Text02
