import axios from "axios";
 
 
 const instance = axios.create({
   baseURL: 'https://sm.softwaremathematics.com/',
 });

 instance.defaults.headers.common['X-Tenant']='MET20240418';
//  instance.defaults.headers.common['X-Tenant']='TET120240418';
 
 export default instance;
  
 