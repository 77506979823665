import { Grid } from "@mui/material";
import React from "react";
import "./testimonials.css";

const testimonialsProfile = ({ image, text, name }) => {
  return (
    <>
      <Grid item xs={4.5} className="testimonialscontainer2">
        <Grid item>
          <img src={image} alt="Logo" className="testimonialimg" />
        </Grid>
        <Grid item className="testimonialtext">
          {text}
        </Grid>
        <Grid item mt={3} className="testimonialname">
          -{name}
        </Grid>
      </Grid>
    </>
  );
};

export default testimonialsProfile;
