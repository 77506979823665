// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components

import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { createProject } from "components/Resuable/Create Project/service/Projectcreate";
import { getClinic } from "app/Admin/AdminWorkspace/Clinic Master/service/Clinic";




function ProjectclinicInfo({ formData }) {
  const dispatch = useDispatch();


 
  const {create,loading} = useSelector((state) => state. create);
  const {clinic} = useSelector((state) => state. clinic);

  const [color] = useState("#344767");

 

  const { setFieldValue, setFieldError } = useFormikContext();


  const { formField, values, errors, touched } = formData;

  const {
    firstName,
   
  } = formField;
  var {
    firstName: firstNameV,
    
  } = values;

  
 
  
    useEffect(() => {
      dispatch(createProject());
   
      dispatch(getClinic());
     
      
     
    }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Project Clinic</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
         
          
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.project && touched.project}
              >
                <InputLabel
                  htmlFor="project"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Project selection
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="project"
                  id="project"
                  value={values.project}
                  onChange={(e) => {
                    const project = e.target.value;
                    const projectObj =
                      create.find((obj) => obj.code === project) || {};

                  
                    setFieldValue("projectobj", projectObj);
                    setFieldValue("project", project);
                  }}
                >
                  <option value=""></option>
                  {create.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.project && touched.project && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.project}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.clinic && touched.clinic}
              >
                <InputLabel
                  htmlFor="clinic"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Clinic
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="clinic"
                  id="clinic"
                  value={values.clinic}
                  onChange={(e) => {
                    
                   const cliniccode = e.target.value;
                    const clinicObj =
                    clinic.find((obj) => obj.code === cliniccode) || {};

                  console.log("clinicObj",clinicObj)
                    setFieldValue("clinicObj", clinicObj);
                    setFieldValue("clinic", cliniccode);
                  }}
                

                  
                >
                  <option value=""></option>
                  {clinic.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.clinic && touched.clinic && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.clinic}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectclinicInfo
ProjectclinicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectclinicInfo;


