import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { pincodeAssociationAction } from "redux/Actions/getPincodeAssociationAction";

const initialState = {
  pinCodes: [],
  optionList: [],
  loading: false,
  error: null,
};

const handleOptionList =(arr)=>{
  let data = arr.map(item=> ({label:item?.pincode?.state?.name+" "+item?.pincode?.district.name+" "+item?.pincode?.village?.name, value:item?.pincode?.village?.shortname }))
  return data
}

export const getPincodeAssociationSlice = createSlice({
  name: "getPincodeAssociation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pincodeAssociationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(pincodeAssociationAction.fulfilled, (state, action) => {
        state.loading = false;
        state.pinCodes = action.payload;
        state.optionList = handleOptionList(action.payload);
        state.error = null;
      })
      .addCase(pincodeAssociationAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getPincodeAssociationSlice.reducer;