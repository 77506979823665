import ProfileOverview from "layouts/pages/profile/profile-overview";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import Icon from "@mui/material/Icon";
import ProfileSummary from "layouts/pages/Profilesummary";
import InvestmentRedeem from "components/Resuable/InvestmnetPanel/InvestmentRedeem";
import BankDetail from "components/Resuable/InvestmnetPanel/BankDetail/BankDetail";
import Familytree from "components/Resuable/InvestmnetPanel/familytree";
import AgentAnalytics from "components/Resuable/InvestmnetPanel/AgentAnalytics";
import WithDrawalapproval from "components/Resuable/InvestmnetPanel/WithDrawalapproval";
import InvestmentApproval from "components/Resuable/InvestmnetPanel/InvestmentApproval";
import FindDoctor from "app/findDoctor/FindDoctor";
import AgentDoctor from "app/Agent create doctor/AgentDoctor";
import AgentDoctorlist from "app/Agent create doctor/AgentDoctorlist";

const routesAgent = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/app/agent/profile",
        component: <ProfileOverview />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/app/home/find-doctors",
        component: <FindDoctor />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },
  // Render each item individually

  {
    type: "item",
    name: "Analytics",
    // key: "analytics",
    route: "/app/agent/analytics",
  },
  {
    type: "item",
    name: "Resources",
    route: "/app/agent/resources",
  },
  {
    type: "item",
    name: "Directory",
    route: "/app/agent/directory",
  },
  {
    type: "item",
    name: "Profile List",

    route: "/app/agent/profile-list",
  },
];

export default routesAgent;
