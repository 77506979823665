import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import Cities from "../assets/images/cities.png";
import Hospitals from "../assets/images/hospitals.png";
import Customers from "../assets/images/customers.png";
import Doctors from "../assets/images/doctors.png";
import "./numberUpdates.css";
import NumberUpdatesInfo from "./numberUpdatesInfo";
import GirlDoingMeditaion from "../assets/images/Girl doing meditation.svg";

const NumberUpdates = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    {
      image: Cities,
      text: "200+ Cities",
    },
    {
      image: Hospitals,
      text: "490+ Hospitals",
    },
    {
      image: Customers,
      text: "1500+ Doctors",
    },
    {
      image: Doctors,
      text: "4000+ Doctors",
    },
  ];

  return (
    <div>
      <Grid container className="numberupdatescontainer" mt={5} mb={5}>
        <Grid
          item
          xs={isSmallScreen ? 10 : undefined}
          className="numberupdatescontainer2"
        >
          <Grid item className="numberupdatesitem">
            {data.map((item, index) => (
              <NumberUpdatesInfo
                key={index}
                image={item.image}
                text={item.text}
              />
            ))}
          </Grid>
          {isSmallScreen ? (
            <Grid item mt={-4}>
              <span className="meditationlogospan">
                <img
                  src={GirlDoingMeditaion}
                  alt="Meditation Logo"
                  className="meditationlogo"
                />
              </span>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default NumberUpdates;
