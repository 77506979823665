import { Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, Select, TextField } from '@mui/material';
import MDButton from 'components/MDButton';
import { Formik, Form, Field } from 'formik';
import React, { useState } from 'react';
import { MoonLoader } from 'react-spinners';
import { APIs } from 'Services/APIs';
import * as Yup from 'yup';
import axios from "../../../axiosinstance";

const validationSchema = Yup.object().shape({
    otp: Yup.string()
        .required('OTP is required')
        .matches(/^\d{6}$/, 'OTP must be a 6-digit number'),
});

const VerificationOtp = (props) => {
    const {
        openpopupotp,
        setOpenPopupOtp,
        selectedotpdata,
        handleClose,
        getData
    } = props;

    const [loading, setLoading] = useState(false);

    const finalSubmit = async (actions) => {
       const Data = {
        ...selectedotpdata,
        recstatus:"REACHED"
       }
       
  

        const response = await axios.put(
            `${APIs.bookingServiceApi}/update`,
            Data
        );
        handleClose();
        swal("Success!", "You've successfully reached the patient's address, verified the OTP, and updated the order status to 'REACHED'.", "success");

        if (!response.error && !loading) {
            actions.resetForm();

            getData();
            handleClose();

        } else {
            actions.resetForm();
            swal({
                icon: "error",
                title: "Error",
                text: error?.message,
            });
         
            handleClose();
          
        }


    };

    const handleSubmit = async (values,actions) => {
        setLoading(true);
  
    
        await axios
        .post(
          `${APIs?.otpServiceApiv1}/confirm?otp=${values?.otp}&userid=${selectedotpdata?.patient?.emailid}&msgType=FORGET`
        )
    
        .then((response) => {
            setLoading(false);
          swal({
            icon: "success",
            title: "OTP Verified!",
            content: {
              element: "div",
              attributes: {
                innerHTML: `
                  <p style="text-align: center;">
                    OTP has been successfully verified.
                  </p>
                `,
              },
            },
          });
          finalSubmit(actions)
    
        })
        .catch((e) => {
            setLoading(false);
          swal(
            "Oops!",
            e?.response?.data?.aceErrors?.[0]?.errorMessage,
            "warning"
          );
        });

    };

    return (
        <div>
            <Dialog
                open={openpopupotp}
                onClose={handleClose}
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle
                    style={{
                        backgroundColor: "#039147",
                        color: "#fff",
                        textAlign: "center",
                    }}
                >
                    OTP Verification
                </DialogTitle>
                <Formik
                    initialValues={{ otp: '' }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, errors, touched }) => (
                        <Form>
                            <DialogContent>
                                <FormControl fullWidth margin="normal">
                                    <TextField
                                        id="otp"
                                        name="otp"
                                        label="Please Enter Valid OTP"
                                        value={values.otp}
                                        onChange={handleChange}
                                        error={touched.otp && Boolean(errors.otp)}
                                        helperText={touched.otp && errors.otp}
                                        fullWidth
                                        variant="outlined"
                                        inputProps={{ maxLength: 6 }}
                                    />
                                </FormControl>
                            </DialogContent>
                            <DialogActions>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="small"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </MDButton>
                                <MDButton
                                    variant="gradient"
                                    color="info"
                                    size="small"
                                    type="submit"
                                >
                                    {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
                                </MDButton>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    );
};

export default VerificationOtp;
