import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import { Button, Grid, Input } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { UploadDocumnet } from "Services/UploadDocumnet";
import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import swal from "sweetalert";
import FileUpload from "react-material-file-upload";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import MDInput from "components/MDInput";

function Qualifications({ formData }) {
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [idCounter, setIdCounter] = useState(0);
  const currentDate = new Date();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();
  const [data, setData] = useState(
    formData.values.qualificationinformation || []
  );
  const { formField, values, errors, touched } = formData;
  const [uploadresponse, setUploadresponse] = useState({
    docid: "",
    filename: "",
  });
  const [files, setFiles] = useState([]);

  const onFileChange = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const request = {
        resourcecode: "MEDHARVA",
        username: auth.name,
        code: auth.rolecode,
      };
      formData.append("body", JSON.stringify(request));
      setLoading(true);
      try {
        const data = await UploadDocumnet(formData);
        setUploadresponse({ docid: data?.docid, filename: data?.filename });
      } catch (error) {
        swal(
          "Oops!",
          "An error occurred while uploading the file. Please try again later.",
          "error"
        );
      }
      setLoading(false);
    }
  };

  const initialValues = {
    qualificationmode: "",
    qualificationgrade: "",
    qualificationname: "",
    qualificationboard: "",
    qualificationgradetype: "",
    startdate: currentDate.toISOString().split("T")[0],
    enddate: currentDate.toISOString().split("T")[0],
    documentofproof: null,
  };

  const validationSchema = Yup.object({
    qualificationmode: Yup.string().required("Qualification mode is required."),
    qualificationgrade: Yup.string().required("Grade is required."),
    qualificationboard: Yup.string().required("Board is required."),
    qualificationname: Yup.string().required("Qualification name is required."),
    qualificationgradetype: Yup.string().required("Type is required."),
    startdate: Yup.date().required("Start date is required."),
    enddate: Yup.date()
      .required("End date is required.")
      .test(
        "is-not-before-start",
        "End date cannot be before start date.",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isSameOrBefore = start <= end;
          return isSameOrBefore;
        }
      )
      .test(
        "is-not-same-as-start",
        "End date cannot be the same as start date.",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isNotSameDate = start.getTime() !== end.getTime();
          return isNotSameDate;
        }
      ),
  });

  const handleSubmit = (values) => {
    if (
      !values.qualificationboard.trim() ||
      !values.qualificationgrade.trim() ||
      !values.qualificationname.trim() ||
      !values.qualificationmode.trim() ||
      !values.qualificationgradetype.trim() ||
      values.startdate > values.enddate
    ) {
      if (
        !values.qualificationboard.trim() ||
        !values.qualificationgrade.trim() ||
        !values.qualificationname.trim() ||
        !values.qualificationmode.trim() ||
        !values.qualificationgradetype.trim()
      ) {
        swal("oops!", "Please fill in all fields.", "error");
      }
      // if (!values.organization.trim()) {
      //   alert("Please enter an organization");
      // }
      if (values.startdate > values.enddate) {
        swal("oops!", "Start date cannot be greater than end date!", "error");
      }
      return; // Exit the function if validation fails
    }
    if (!uploadresponse?.docid || uploadresponse?.docid === "") {
      swal("oops!", "Please upload document first.", "error");
    } else {
      const newData = {
        uid: idCounter,
        ...values,
        id: null,
        uploadDocument: uploadresponse,

        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "synced",

        profileid:
          auth.rolecode === "AD001"
            ? location?.state?.profileid
            : auth?.profileid,
      };
      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      setFiles([]);
      setUploadresponse({ docid: "", filename: "" });
      formData.errors.qualificationinformation = "";
      // resetForm();
      values.qualificationboard = "";
      values.qualificationgrade = "";
      values.qualificationname = "";
      values.qualificationmode = "";
      values.qualificationgradetype = "";

      values.startdate = currentDate.toISOString().split("T")[0];
      values.enddate = currentDate.toISOString().split("T")[0];
    }
  };
  formData.values.qualificationinformation = data;
  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "Name of Degree/Diploma", accessor: "qualificationname" },
    { Header: "mode", accessor: "qualificationmode" },
    { Header: "board", accessor: "qualificationboard" },
    { Header: "grade type", accessor: "qualificationgradetype" },

    { Header: "grade", accessor: "qualificationgrade" },
    { Header: "startdate", accessor: "startdate" },
    { Header: "enddate", accessor: "enddate" },
    { Header: "Name", accessor: "uploadDocument.filename" },

    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeData(row.values.uid)}
          />
        </span>
      ),
      width: "12%",
    },
  ];

  return (
    <MDBox>
      <Grid>
        {errors.qualificationinformation && (
          <FormHelperText error>
            {errors.qualificationinformation}
          </FormHelperText>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <MDBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Name of Degree
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        type="text"
                        name="qualificationname"
                        variant="standard"
                        value={values.qualificationname}
                        onChange={(e) => {
                          const nameValue = e.target.value;
                          setFieldValue("qualificationname", nameValue);
                        }}
                      />

                      <ErrorMessage
                        name="qualificationname"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="qualificationmode"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                      >
                        <span>
                          Mode{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="qualificationmode"
                        id="qualificationmode"
                        value={values.qualificationmode}
                        onChange={(e) => {
                          const selectedMode = e.target.value;
                          setFieldValue("qualificationmode", selectedMode);
                        }}
                        error={
                          touched.qualificationmode &&
                          Boolean(errors.qualificationmode)
                        }
                      >
                        <option value=""></option>

                        <option value="Regular">Regular</option>
                        <option value="Open">Open</option>
                      </Select>
                      <ErrorMessage
                        name="qualificationmode"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Board{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        type="text"
                        name="qualificationboard"
                        variant="standard"
                        value={values.qualificationboard}
                        onChange={(e) => {
                          const nameValue = e.target.value;
                          setFieldValue("qualificationboard", nameValue);
                        }}
                      />

                      <ErrorMessage
                        name="qualificationboard"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="mode"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                      >
                        <span>
                          Grade Type{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                      </InputLabel>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="qualificationgradetype"
                        id="qualificationgradetype"
                        value={values.qualificationgradetype}
                        onChange={(e) => {
                          const selectedType = e.target.value;
                          setFieldValue("qualificationgradetype", selectedType);
                        }}
                        error={
                          touched.qualificationgradetype &&
                          Boolean(errors.qualificationgradetype)
                        }
                      >
                        <option value=""></option>

                        <option value="Percentage">Percentage</option>
                        <option value="CGP">CGP</option>
                      </Select>
                      <ErrorMessage
                        name="qualificationgradetype"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Grade{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        type="text"
                        name="qualificationgrade"
                        variant="standard"
                        value={values.qualificationgrade}
                        onChange={(e) => {
                          const nameValue = e.target.value;
                          setFieldValue("qualificationgrade", nameValue);
                        }}
                      />

                      <ErrorMessage
                        name="qualificationgrade"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Start Date
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        type="date"
                        name="startdate"
                        variant="standard"
                        value={values.startdate}
                        onChange={(e) => {
                          const startdateValue = e.target.value;
                          setFieldValue("startdate", startdateValue);
                        }}
                        error={touched.startdate && Boolean(errors.startdate)}
                      />
                      <ErrorMessage
                        name="startdate"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          End Date
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        type="date"
                        name="enddate"
                        variant="standard"
                        value={values.enddate}
                        onChange={(e) => {
                          const enddateValue = e.target.value;
                          setFieldValue("enddate", enddateValue);
                        }}
                        error={touched.enddate && Boolean(errors.enddate)}
                      />
                      <ErrorMessage
                        name="enddate"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={8} sx={{ display: "flex" }}>
                    {uploadresponse.length === 0 ? (
                      errors.document && (
                        <FormHelperText error>{errors.document}</FormHelperText>
                      )
                    ) : (
                      <></>
                    )}

                    <Button
                      component="label"
                      variant="contained"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={
                        loading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          <CloudUploadIcon />
                        )
                      }
                      style={{
                        marginBottom: "10px",
                        borderWidth: "1px",
                        color: "white",
                      }}
                    >
                      Upload
                      <input
                        type="file"
                        onChange={onFileChange}
                        style={{ display: "none" }}
                        tabIndex={-1}
                      />
                    </Button>
                    <Grid item xs={12} sm={4} ml={1} mt={1}>
                      <MDInput
                        variant="standard"
                        value={uploadresponse?.filename}
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <MDButton
                      variant="gradient"
                      color="info"
                      size="small"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(values);
                      }}
                    >
                      Add +
                    </MDButton>
                  </Grid>
                </Grid>
              </MDBox>

              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  border: "1px solid #727375",
                  borderRadius: "5px",
                  marginTop: "15px",
                  marginBottom: "10px",
                  marginLeft: "5px",
                  marginRight: "5px",
                }}
              >
                <DataTable
                  showTotalEntries={false}
                  entriesPerPage={false}
                  table={{ columns, rows: data, uploadresponse }}
                />
              </Grid>
              <MDBox
                display="flex"
                justifyContent="flex-end"
                marginRight="10px"
                marginBottom="8px"
              ></MDBox>
            </Form>
          )}
        </Formik>
      </Grid>
    </MDBox>
  );
}
Qualifications.propTypes = {
  formData: PropTypes.object.isRequired,
};
export default Qualifications;
