
import React, { useEffect, useState } from 'react';
import "./styles.css";
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import Card from "@mui/material/Card";
import axios from "../../../../axiosinstance"
import MDAvatar from 'components/MDAvatar';
import MDBox from 'components/MDBox';
import image from "assets/images/team-4.jpg"



const FamilyTree = () => {

  const [iProfile] = useState(JSON.parse(localStorage.getItem("investment-profile")));
  const [data, setData] = useState({
    id: 'root',
    name: 'loading...',
    dto: { name: 'loading....' }
  });


  function getData(referalCode) {
    // setLoading(true);
    axios
      .get(
        `/investmentprofile-service/api/investmentprofile/v1/getTree/${referalCode}`
      )
      .then((response) => {
        // response.data.data.tree.id = "root"
        setData(response?.data?.data?.tree);
      
        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }


 

  const renderTree = (node) => (
    <li key={node?.id}>
      <a href="#" style={{ fontSize: '15px' }}>  <MDAvatar src={image} alt={node?.name} shadow="md" />&nbsp;{node?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}<br/> Age: {node?.dto?.age}</a>
      {node?.children && node?.children?.length > 0 && (
        <ul>{node?.children?.map((child) => renderTree(child))}</ul>
      )}
    </li> 
  );

    useEffect(() => {
    getData(iProfile?.referalcode);
  }, []);

  return (
        <DashboardLayout>
      {/* <DashboardNavbar /> */}
    <Card style={{paddingBottom:20,justifyContent:"center",display:"flex",marginTop:'4%',overflowX:"auto"}}>
    <div className="tree">
      <ul> {renderTree(data)}</ul>
    </div>
    </Card>
    </DashboardLayout>
  );
};

export default FamilyTree;



