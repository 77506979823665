import React, { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar"
import MobileNavbar from "../findDoctor/navbar/mobileNavbar/mobileNavbar"


import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import LabTestHome from "./labTestHome/LabTestHome";
import Tbookedlab from "./TopBooked/Tbookedlab";
import HealthChecks from "./Healthcaretest/HealthChecks";
import CallbackForm from "./Call back form/Callback";
import ConditionBasedTest from "./conditionBasedTest/conditionBasedTest";
import WorkProcessAtMedharva from "./workProcessAtMedharva/workProcessAtMedharva";
import WhatUserSay from "layouts/pages/home/components/whatUserSay/whatUserSay";
import EmailSubscribe from "layouts/pages/home/components/emailSubscribe/emailSubscribe";
import MedharvaPlus from "layouts/pages/home/components/medharvaPlus/medharvaPlus";
import MedharvaFooter from "components/Resuable/Medharvafooter/MedharvaFooter";
import Cards from "layouts/pages/home/components/Cards";

const LabTest = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="Lab Test" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <LabTestHome />
      {isSmallScreen ? <></> : <Cards />}
      <Tbookedlab />
      <HealthChecks />
      <CallbackForm />
      {isMidScreen ? <></> : <ConditionBasedTest /> }
      <MedharvaPlus />
      {isMidScreen ? <></> : <WorkProcessAtMedharva /> }
      <WhatUserSay />
      <EmailSubscribe />
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Lab Test" /> : <></>}
    </div>
  );
};

export default LabTest;
