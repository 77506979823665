import React from 'react';

const shimmerKeyframes = `
  @keyframes loading {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
`;

const styles = `
  .shimmer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      to right,
      rgba(224, 224, 224, 0.3) 0%,
      rgba(255, 255, 255, 0.6) 50%,
      rgba(224, 224, 224, 0.3) 100%
    );
    animation: loading 1.5s infinite;
  }
`;

const ShimmerSkeleton = ({ width = '100%', height = '1em', borderRadius = '4px', variant = 'rectangular' }) => {
  const style = {
    width: width,
    height: height,
    borderRadius: variant === 'circular' ? '50%' : borderRadius,
    margin: '4px 0',
    position: 'relative',
    backgroundColor: '#e0e0e0',
    overflow: 'hidden',
  };

  return (
    <div style={style}>
      <div className="shimmer" />
    </div>
  );
};

const HomeSkeleton = () => {
  return (
    <div style={{ padding: '20px',marginTop:"300px" }}>
      <style>{shimmerKeyframes}</style>
      <style>{styles}</style>
      <ShimmerSkeleton width="100%" height="50px" />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '20px' ,marginTop:"30px"}}>
        <ShimmerSkeleton width="150px" height="40px" />
        <div style={{ display: 'flex', gap: '20px' }}>
          <ShimmerSkeleton width="100px" height="30px" />
          <ShimmerSkeleton width="120px" height="30px" />
          <ShimmerSkeleton width="90px" height="30px" />
          <ShimmerSkeleton width="110px" height="30px" />
        </div>
        <ShimmerSkeleton width="300px" height="30px" />
        <ShimmerSkeleton width="150px" height="30px" />
        <ShimmerSkeleton width="150px" height="30px" />
      </div>
      <ShimmerSkeleton width="100%" height="5px" />
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingBottom: '20px', }}>
    <div style={{ display: 'flex', gap: '100px' ,marginLeft:"100px",marginRight:"100px"}}>
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
      <ShimmerSkeleton width="100px" height="30px" />
    </div>
  </div>
      <div style={{ textAlign: 'center', paddingTop: '30px',marginRight:"100px",marginLeft:"100px" }}>
        <ShimmerSkeleton width="100%" height="500px" />    
      </div>
      <div style={{ textAlign: 'center', paddingTop: '30px',marginRight:"100px",marginLeft:"100px" }}>
        <ShimmerSkeleton width="100%" height="150px" />      
      </div>
    </div>
  );
};

export default HomeSkeleton;
