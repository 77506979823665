import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import { Autocomplete } from "@mui/material";
import axios from "../../../axiosinstance";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import { labappCode } from "static/labappCode";
import { micappCode } from "static/micappCode";
import { MoonLoader } from "react-spinners";
import { ROLE_CODE } from "privateComponents/codes";

const DepartmentEditform = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedDepartmnet,
    openpopup,
    setopenpopup,
    tableDataAction,
    passData,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [department, setDepartment] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [selectDep, setSelectDep] = useState("");
  const createdby = auth?.rolecode === ROLE_CODE?.miccode ? "" : `createdby=${auth?.profileid}`;

  const defaultProps = {
    options: !department ? [{ name: "Loading...", id: 0 }] : department,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  // console.log("select",select)

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
      name: selectedDepartmnet?.name || "",
      description: selectedDepartmnet?.description || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      // Create a copy of selectedDepartmnet with updated values
      const updatedDepartmnet = {
        ...selectedDepartmnet,
        name: values.name,
        description: values.description,
        parentdepcode: values.hierarchicalcode
          ? values.parentdepcode
          : selectedDepartmnet?.parentdepcode,
        parenthierarchicalcode: values.hierarchicalcode
          ? values.parenthierarchicalcode
          : selectedDepartmnet?.parenthierarchicalcode,
        hierarchicalcode: values.hierarchicalcode
          ? values.hierarchicalcode
          : selectedDepartmnet?.hierarchicalcode,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };
      const response = await axios.put(
        `${APIs.deparmentServiceApi}/update`,
        updatedDepartmnet
      );

      if (!response.error) {
        setIsLoading(false);
        handleClose();
        passData();
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
      } else {
        swal({
          icon: "error",
          title: "Error",
          text:
            response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
            "Something wents wrong.",
        });
        setIsLoading(false);
        // handleClose()
      }

      setopenpopup(false);
    },
  });

  useEffect(() => {
    setSelectDep({ name: "Loading...", id: 0 });
    if (selectedDepartmnet) {
      for (let i = 0; i < department.length; i++) {
        if (department[i].code === selectedDepartmnet.parentdepcode) {
          setSelectDep(department[i]);
        }
      }
    }
    // Update the form values when selectedDepartmnet changes
    formik.setValues({
      name: selectedDepartmnet?.name || "",
      description: selectedDepartmnet?.description || "",
    });
  }, [selectedDepartmnet]);

  const handleClose = () => {
    setopenpopup(false);
    setSelectDep({ name: "Loading...", id: 0 });

    if (selectedDepartmnet) {
      for (let i = 0; i < department.length; i++) {
        if (department[i].code === selectedDepartmnet.parentdepcode) {
          setSelectDep(department[i]);
        }
      }
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.deparmentServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <MDBox mb={2} mt={1}>
            <MDInput
              type="text"
              label=<span>
                {" "}
                Department Name{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              variant="standard"
              fullWidth
              id="name"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={
                formik.touched.name && formik.errors.name ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.name}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Department Description"
              variant="standard"
              fullWidth
              id="description"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              InputLabelProps={{
                style: { paddingBottom: "2px" },
              }}
              error={
                formik.touched.description && Boolean(formik.errors.description)
              }
              helperText={
                formik.touched.description && formik.errors.description ? (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {formik.errors.description}
                  </p>
                ) : null
              }
            />
          </MDBox>
          <MDBox mb={2}>
            <Autocomplete
              {...defaultProps}
              value={selectDep}
              onChange={(event, newValue) => {
                console.log(newValue);
                // handleChange(event);
                if (newValue === null) {
                  formik.values.parentdepcode = null;
                  formik.values.parenthierarchicalcode = null;
                  formik.values.hierarchicalcode = selectedDepartmnet.code;

                  setSelectDep(newValue);
                } else {
                  formik.values.parentdepcode = newValue?.code;
                  formik.values.parenthierarchicalcode =
                    newValue?.hierarchicalcode;
                  formik.values.hierarchicalcode =
                    newValue?.hierarchicalcode + "-" + selectedDepartmnet.code;
                  setSelectDep(newValue);
                }
              }}
              renderInput={(params) => (
                <MDInput
                  {...params}
                  variant="standard"
                  label="Parent Department"
                />
              )}
            />
          </MDBox>
        </DialogContent>

        <DialogActions style={{ justifyContent: "right" }}>
          <MDButton onClick={handleClose} size="small" color="light">
            Cancel
          </MDButton>
          <MDButton type="submit" variant="gradient" size="small" color="info">
            {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DepartmentEditform;
