import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const getProjectTeamAction = 
   createAsyncThunk("getProjectTeam", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.emailId){
        url = APIs.projectTeamService +`/get?projectteams.login.emailid=${data.emailId}`
      }else if(data && data.url){
        url = data.url
      } else {
        url = APIs.projectTeamService +`/get`
      }
        let respData = await getAllUsers(url, "")
        console.log(respData?.dtoList, "respData 20")
        return respData?.dtoList || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });