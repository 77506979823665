import { createSlice } from "@reduxjs/toolkit";
import { createClinic, deleteClinic, getClinic, updateClinic } from "../service/Clinic";






const initialState = {
  clinic: [],
  loading: false,
  error: null,
};

export const clinicDetail = createSlice({
  name: "clinicDetail",
  initialState,
  extraReducers: {
    [createClinic.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createClinic.fulfilled]: (state, action) => {
      console.log("state",state.clinic)
      state.loading = false;
      state.clinic.push(action.payload.data.dto);
    },
    [createClinic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getClinic.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getClinic.fulfilled]: (state, action) => {
      state.loading = false;
      state.clinic = action.payload.data.dtoList;

    },
    [getClinic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteClinic.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteClinic.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.clinic = state.clinic.filter((clinic) => clinic.id !== id);
      }
    },
    [deleteClinic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateClinic.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateClinic.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.clinic.findIndex(clinic => clinic.id === action.payload.data.dto.id)
      state.clinic[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateClinic.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default clinicDetail.reducer;
