import { createSlice } from "@reduxjs/toolkit";
import { createAssociation, deleteAssociation, getAssociation, updateAssociation } from "../service/Association";





const initialState = {
  association: [],
  loading: false,
  error: null,
};

export const associationDetail = createSlice({
  name: "associationDetail",
  initialState,
  extraReducers: {
    [createAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createAssociation.fulfilled]: (state, action) => {
      console.log("state",state.user)
      state.loading = false;
      state.association.push(action.payload.data.dto);
    },
    [createAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      state.association = action.payload.data.dtoList;

    },
    [getAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.association = state.association.filter((association) => association.id !== id);
      }
    },
    [deleteAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateAssociation.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateAssociation.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.association.findIndex(association => association.id === action.payload.data.dto.id)
      state.association[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateAssociation.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default associationDetail.reducer;
