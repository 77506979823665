import React, { useEffect, useState } from "react";
import "./medharvaPlus.css";
import { Grid } from "@mui/material";
import Service from "../assets/images/Service.svg";
import Discount from "../assets/images/Discount.svg";
import Frame from "../assets/images/Frame.svg";

const MedharvaPlus = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 600);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 1250);
  const [isSmallerScreen, setIsSmallerScreen] = useState(window.innerWidth <= 300);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 600);
      setIsMidScreen(window.innerWidth <= 1250);
      setIsSmallerScreen(window.innerWidth <= 300);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Grid item className="rootmedharvaplusitem">
        <Grid item mt={5} className="medharvaplusitemtext">
          <span>Medharva</span>
          &nbsp;
          <span className="plus">PLUS+</span>
        </Grid>
        <Grid item className="medharvaplusitemtext2">
          {isSmallScreen ? (
            <div>One of a kind medical service</div>
          ) : (
            <div>
              Dedicated services, special discounts, partner benefits & 24 hours
              tech <br />
              support.
            </div>
          )}
        </Grid>
        <Grid
          container
          mt={isSmallScreen ? 0 : 5}
          xs={isSmallScreen ? 12 : isMidScreen ? 10 : 8}
          className="medharvaplusitem1"
        >
          <Grid
            item
            xs={isSmallScreen ? 12 : isMidScreen ? 12 : 5.3}
            className="dedicatedServicesSpecialContainer"
          >
            {isSmallScreen ? (
              <>
                <Grid item mb={3} className="dedicatedServicesitem">
                  <img src={Service} alt="Logo" className="liststyleimg" />
                  Dedicated Services
                </Grid>
                <Grid item mb={3} className="dedicatedServicesitem">
                  <img src={Discount} alt="Logo" className="liststyleimg" />
                  Special discounts
                </Grid>
                <Grid item mb={3} className="dedicatedServicesitem">
                  <img src={Frame} alt="Logo" className="liststyleimg" />
                  24/7 support
                </Grid>
              </>
            ) : (
              <ul>
                <li className="dedicatedServicesitemlist">
                  Dedicated <br className="brstyle"/> Services
                </li>
                <li className="dedicatedServicesitemlist">
                  Special <br className="brstyle"/> discounts
                </li>
                <li className="dedicatedServicesitemlist">24/7 support</li>
              </ul>
            )}
          </Grid>
          <Grid item mb={isSmallerScreen ? 3 : 0} mt={isSmallScreen ? 1 : 0} className="plan1">
            <div className="monthly">Monthly</div>
            <div className="rupeemonthly">₹199</div>
            <div className="flatOffContainer">
              Flat 20% <br className="brstyle" />
              OFF
            </div>
            <button className="getStarted">get started</button>
          </Grid>
          <Grid item className="plan2" mt={isSmallScreen ? 1 : 0}>
            <div className="monthly">Yearly</div>
            <div className="rupeeyearly">₹1999</div>
            <div className="flatOffContainer">
              Flat 40% <br className="brstyle" />
              OFF
            </div>
            <button className="getStarted2">get started</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default MedharvaPlus;
