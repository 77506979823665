import DashboardLayout from 'components/DashboardLayout'
import React, { useEffect } from 'react'
import {Card, Grid, Box } from "@mui/material";
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard';
import AnalyticsCard from 'components/Analytics';
import ManageCard from '../../../components/ManageCard/manageCard';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Link } from 'react-router-dom';
import { tableDataAction } from 'redux/Actions/tableDataAction';
import { useSelector } from 'react-redux';
import { APIs } from 'Services/APIs'
import { useDispatch } from 'react-redux';
import { ROLE_CODE } from "privateComponents/codes";
const Analytics = () => {
  const dispatch = useDispatch()
  const { tableData, AuthReducer, tableActionRow } = useSelector(state => state)
  const totalCount = tableData?.rowData?.dtoList?.length
  const grapgData = [
    {icon:"", title:"Total Agents", count:totalCount, path:'/app/mic/agents/total-agents'},
    {icon:"", title:"Total Transactions", count:20, path:'/app/mic/agents/transaction'},
    // {icon:"", title:"Total Clinic", count:10, path:'/app/mic/agents/clinic'},
  ]
  const manageData = [
    { title:"New Registrations", path:'/app/mic/agents'},
    { title:"Resource Approval",path:'/app/mic/agents'},
  ]
  useEffect(async()=>{
    const roleCode = ROLE_CODE. agentcode;
    const isActive ='ACTIVE';
    const completeUrl = `${APIs.getLoginUser}&rolecode=${roleCode}&isactive=${isActive}`;
    dispatch(tableDataAction({url:completeUrl}))
  },[])
  return (
    <DashboardLayout>
     
      <Card sx={{padding:"2%", marginTop:"2%"}}>
        <Grid container spacing={3}>
            {grapgData.map((item, index)=>(
             
            <Grid item xs={12} md={6} lg={3} key={index}>
                <Link to={item.path}>
                <AnalyticsCard data={item} />
                </Link>
            </Grid>
        
            ))}
        </Grid>
      </Card>
      <MDBox mt={2}>

      <MDTypography varient="h6">Manage</MDTypography>
      </MDBox>

      <Card sx={{padding:"2%", marginTop:"2%"}}>
      <Grid container spacing={3}>
            {manageData.map((item, index)=>(
             
            <Grid item xs={12} md={6} lg={3} key={index}>
                {/* <Link to={item.path}> */}
                <ManageCard data={item}/>
                {/* </Link> */}
            </Grid>
        
            ))}
        </Grid>
     
      </Card>
    </DashboardLayout>
  )
}

export default Analytics
