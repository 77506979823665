
import checkout from "../schemas/form";
 
const {
  formField: {
 
   //puchasedetail
  
 age,
   firstName,
   lastName,
 gender,
email,
mobilenumber,
role,
doctor,
description,
experience,
password,
repeatPassword

  },
} = checkout;

const initialValues = {




[age.name]:"",


[firstName.name]:"",
[lastName.name]:"",
 [gender.name]: "",
 [email.name]:"",
 [mobilenumber.name]:"",
 [role.name]:"",
[description.name]:"",
[experience.name]:"",
 [doctor.name]:"",

 [password.name]: "",
 [repeatPassword.name]: "",
};

export default initialValues;
