import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Grid, TextField } from "@mui/material";
import MDBadge from "components/MDBadge";
import DashboardLayout from "components/DashboardLayout";
import RequsitionPopup from "./RequsitionPopup";
import { APIs } from "Services/APIs";

const RequsitionList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [color] = useState("#344767")
  const [item, setItem] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [wareHouseList, setWarehouseList] = useState([]);
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [getResponse, setResponse] = useState([]);
  const [givenQty, setGivenQty] = useState("");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  function getWarehouseData() {


    axios
      .get(
        `${APIs.warehouseServiceApi}/get?recStatus=OPEN`
      )
      .then((response) => {
   
        setWarehouseList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const [tableDatas, setTableDatas] = useState([]);

  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
    getOptionLabel: (option) => option?.vehicle?.name ? option.name + " (" + option?.vehicle?.name + ")" : option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);
    await axios
      .get(
        `${APIs.medrequisitiongenServiceApi}/getRequisitionAggregation?recStatus=OPEN&owhid=${wharehouse?.name}`
      )
      .then((response) => {
        response?.data?.data?.medRequisitionAggregationList?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "Uom", accessor: "uom" },
              { Header: "warehouse", accessor: "owhname" },
              { Header: " type", accessor: "itemtype" },

              {
                Header: "Requested Qty",
                accessor: "quantityrqst",
              },
              {
                Header: "Qty Approved",
                accessor: "quantityapproved",
              },

        
            ],
            rows: [],
          };
          item?.medrequisitiongendtoList?.map((i) => {
            i.input = (
              <MDInput
                type="number"
                variant="outlined"
                size="small"
                onChange={(e) => setGivenQty(e.target.value)}
                style={{ width: "70px" }}
              />
            );
            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response?.data?.data?.medRequisitionAggregationList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmucode) {
    setLoading(true);

    await axios
      .get(
        `${APIs.medrequisitiongenServiceApi}/getRequisitionAggregation?recStatus=OPEN&mmucode=${mmucode}`
      )
      .then((response) => {
        response?.data?.data?.medRequisitionAggregationList?.map((item) => {
          const dataObj = {
            columns: [
              { Header: "name", accessor: "name" },
              { Header: "mmu", accessor: "mmucode" },
              { Header: " type", accessor: "itemtype" },
              { Header: "Uom", accessor: "uom" },

              {
                Header: "Requested Qty",
                accessor: "quantityrqst",
              },
              {
                Header: "Qty Approved",
                accessor: "quantityapproved",
              },

            ],
            rows: [],
          };
          item.medrequisitiongendtoList.map((i) => {
            if (
              i.quantityapproved === i.quantityrcvd &&
              i.quantityapproved !== "" &&
              i.quantityrcvd !== "" &&
              typeof i.quantityapproved !== "undefined" &&
              typeof i.quantityrcvd !== "undefined"
            ) {
              i.input = (
                <MDInput
                  type="number"
                  variant="outlined"
                  size="small"
                  disabled="true"
                  style={{ width: "70px" }}
                />
              );
            } else {
              i.input = (
                <MDInput
                  type="number"
                  variant="outlined"
                  size="small"
                  onChange={(e) => setGivenQty(e.target.value)}
                  style={{ width: "70px" }}
                />
              );
            }

            dataObj.rows.push(i);
          });

          item.dataObj = dataObj;
        });
        setResponse(response?.data?.data?.medRequisitionAggregationList);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getMmuDataByWarehouse() {
    

    await axios
      .get(
        `${APIs.mmuCreationService}/get`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const handleClose = () => {
    setopenpopup(false);
  
  };
  useEffect(() => {
    getWarehouseData();
  }, []);
  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
          <MDBox pl={3} pr={3} mt={1} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                   style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Requsition List
                  </MDTypography>
                </span>

                <span
                style={{
                  alignSelf: "start",
                  color: "#344767",
                }}
              >
                <MDButton color="info" onClick={() => setopenpopup(true)}>
                  ADD
                </MDButton>
              </span>
              </div>
              <Grid container spacing={2} mb={1}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                     
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getDataBywharehouse(newValue);
                        getMmuDataByWarehouse(newValue);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                     
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            <div>
              {getResponse?.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            REQUESTED ID :
                          </text>
                          {" " + values?.medrequisitiongentxndto?.mrnumber}
                          {values?.medrequisitiongentxndto?.ponumber && (
                            <MDBadge
                              badgeContent="PO Request"
                              size="md"
                              style={{ marginLeft: "12px" }}
                              color="primary"
                              gradient
                            />
                          )}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            SUBMITED by :
                          </text>
                          {" " + values?.medrequisitiongentxndto?.createdby}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>STATUS :</text>
                          {values?.medrequisitiongentxndto?.recstatus ==
                          "OPEN" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#f0a326",
                              }}
                            >
                              {" " + values?.medrequisitiongentxndto?.recstatus}
                            </text>
                          ) : values?.medrequisitiongentxndto?.recstatus ==
                            "RECIEVED" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#05f221",
                              }}
                            >
                              {" " + values?.medrequisitiongentxndto?.recstatus}
                            </text>
                          ) : values?.medrequisitiongentxndto?.recstatus ==
                            "APPROVED" ? (
                            <text
                              style={{
                                fontWeight: "bold",
                                color: "#057ff2",
                              }}
                            >
                              {" " + values?.medrequisitiongentxndto?.recstatus}
                            </text>
                          ) : (
                            " " + values?.medrequisitiongentxndto?.recstatus
                          )}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>DATE :</text>

                          {" " +
                            new Date(
                              values?.medrequisitiongentxndto?.createddate
                            ).toDateString()}
                        </div>
                        {values?.medrequisitiongentxndto?.ponumber && (
                          <div>
                            <text style={{ fontWeight: "bold" }}>
                              PO Number :
                            </text>

                            {" " + values?.medrequisitiongentxndto?.ponumber}
                          </div>
                        )}
                        {values?.medrequisitiongentxndto?.ponumber && (
                          <div>
                            <text style={{ fontWeight: "bold" }}>Vendor :</text>

                            {" " + values?.medrequisitiongentxndto?.vendor}
                          </div>
                        )}
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <text style={{ color: "#FF00FF" }}>
                        <DataTable
                          table={values?.dataObj}
                          showTotalEntries={false}
                          entriesPerPage={false}
                        />
                      </text>
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              ))}
            </div>
          </Card>
        </MDBox>
      </MDBox>
      {openpopup && (
        <RequsitionPopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Create Requisition"}
          handleClose={handleClose}
        passdata={getWarehouseData}
        />
      )}
      </DashboardLayout>
  );
};

export default RequsitionList;
