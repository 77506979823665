import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import swal from "sweetalert";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import profilePicture from "../../../assets/images/products/No-data-found.png";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import MDBadge from "components/MDBadge";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import VitalsPopup from "./VitalsPopup";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { useDispatch } from "react-redux";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { capitalizeWords } from "static/methods/capitalizedWords";

// import FormField from "../FormField";
const Searchpatient = ({ doctorProfileId }) => {
  const comp = "SM";

  let selectedName = "";

  const [open, setOpen] = React.useState(false);
  const [openedit, setOpenedit] = React.useState(false);
  const [selectid, setSelectid] = useState(null);
  const [selectdoctor, setSelectdoctor] = useState(null);
  const [idcardList, setIdcardList] = useState("");
  const [doctorList, setDoctorList] = useState("");
  const [visittypeList, setvisittypelist] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [docno, setDocno] = useState("");
  const [docType, setDocType] = useState("");
  const [selectvisittype, setSelectvisittype] = useState(null);
  const [selectvisitcategory, setSelectvisitcategory] = useState(null);
  const [openhistory, setOpenhistory] = React.useState(false);
  const [Loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [editobj, setEditobj] = useState({});
  const [errors, setErrors] = useState({
    doctorError: false,
    visitTypeError: false,
    visitCategoryError: false,
    paymentError: false,
  });
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getUserCategory } = useSelector(state => state);

  const [mmuvalue, setMmuvalue] = useState(null);
  const [openvitals, setOpenvitals] = useState(false);
  const [searched, setSearched] = useState(null);
  const [getResponse, setResponse] = useState([]);
  const [mmucode, setMmucode] = useState(null);
  const [givenQty, setGivenQty] = useState("");
  const [selectedPatient, setSelectedPatient] = useState();

  const [getApiResponse, setApiResponse] = useState([]);
  const [idList, setidList] = useState("");
  const [patientId, setPatientId] = useState();
  const [bookingPatient, setBookingPatient] = useState();
  const [patientVisitRec, setPatientVisitRec] = useState();
  const [payment, setPayment] = useState();
  const dispatch = useDispatch();

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const visitObj = {
    visitid: patientVisitRec?.visitid,
    category: patientVisitRec?.category,
    id: patientVisitRec?.id,
    patientid: selectedPatient?.patientid,
    patienttempid: selectedPatient?.patienttempid,
    firstscreening: "",
    doctorid: selectdoctor?.profileid,
    // doctorid: selectdoctor?.id.toString(),
    mmucode: selectdoctor?.profileid,
    visittype: selectvisittype?.code,
    visitcategory: selectvisitcategory?.value,
    paymenttype: payment?.code,
    // createdby: auth?.name,
    modifiedby: auth.profileid,
    recstatus: "ASSIGNED",
    syncstatus: "NOT_SYNCED",
    status: "ACTIVE",
  };

  // assign doctor popup drop down :- for select doctor=======================
  const defaultPropsDoctor = {
    options: !doctorList
      ? [{ fullname: "Loading....", profileid: "" }]
      : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) =>
      option.profileid === value.profileid,
  };

  defaultPropsDoctor.options = defaultPropsDoctor?.options?.map((option) => {
    if (
      option?.profileid === auth.profileid
      // option?.name === localStorage.getItem("profile-Name")?.replace(/"/g, "")
    ) {
      return { ...option, name: "Self" };
    }
    return option;
  });

  const defaultPropsvisittype = {
    options: !visittypeList
      ? [
          { name: "New", code: "NEW" },
          { name: "Follow Up", code: "FOLLOW_UP" },
          { name: "Referal", code: "REFERAL" },
        ]
      : visittypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const paymentOptiona = {
    options: [
      { name: "Paid", code: "PAID" },
      { name: "Unpaid", code: "UNPAID" },
    ],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const getCatList =(cat)=>{
    let data = cat?.map(item=> item.category);
    return data;
  }

  const defaultPropsVisitcategory = {
    options: !getUserCategory.visitCategory
      ? [
          { name: "Loading...", code: "" },
          // { name: "Hospital Visit", code: "OFFLINE" },
          // { name: "Online Visit", code: "ONLINE" },
        ]
      : getCatList(getUserCategory.visitCategory),
    getOptionLabel: (option) => capitalizeWords(option.name),
    isOptionEqualToValue: (option, value) => option.value === value.value,
  };

  // editpopup drop down :- for select id card=======================
  const defaultPropsId = {
    options: !idList ? [{ name: "Loading...", id: 0 }] : idList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function updatePatientbookinglist(bookingobj) {
    setLoading(true);

    await axios
      .put(`booking-service/api/Booking/v1/update`, bookingobj)
      .then((response) => {
        getpatient(doctorProfileId);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    const formErrors = {
      doctorError: !selectdoctor,
      visitTypeError: !selectvisittype,
      visitCategoryError: !selectvisitcategory,
      paymentError: !payment,
    };

    setErrors(formErrors);

    if (
      formErrors.doctorError ||
      formErrors.visitTypeError ||
      formErrors.visitCategoryError ||
      formErrors.paymentError
    ) {
      return;
    }

    createVisit(visitObj);
    handleClose();
  };

  const handleDoctorChange = (_, newValue) => {
    setSelectdoctor(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, doctorError: false }));
  };

  const handlePayment = (_, newValue) => {
    console.log(newValue, "newValue 236");
    setPayment(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, paymentError: false }));
  };

  const handleVisitTypeChange = (_, newValue) => {
    setSelectvisittype(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, visitTypeError: false }));
  };

  const handleVisitCategoryChange = (_, newValue) => {
    setSelectvisitcategory(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, visitCategoryError: false }));
  };
  const handleDoctorFieldFocus = () => {
    if (!selectdoctor) {
      setErrors((prevErrors) => ({ ...prevErrors, doctorError: true }));
    }
  };

  const handleVisitTypeFieldFocus = () => {
    if (!selectvisittype) {
      setErrors((prevErrors) => ({ ...prevErrors, visitTypeError: true }));
    }
  };

  const handlePaymentFieldFocus = () => {
    if (!payment) {
      setErrors((prevErrors) => ({ ...prevErrors, paymentError: true }));
    }
  };

  const handleVisitCategoryFieldFocus = () => {
    if (!selectvisitcategory) {
      setErrors((prevErrors) => ({ ...prevErrors, visitCategoryError: true }));
    }
  };
  // edit handle close====================

  const handleCloseedit = () => {
    setOpenedit(false);
  };
  const handleClosehistory = () => {
    setOpenhistory(false);
  };
  async function getpatient(profileId) {
    setLoading(true);

    // await axios.get(`aggregation-service/api/Aggregation/v1/get?mmucode=${profileId}&recstatus=SCHEDULED`)
    await axios.get(`booking-service/api/Booking/v1/get?doctor.profileid=${profileId}&recstatus=SCHEDULED`)
      .then((response) => {
        setResponse(response?.data?.data?.dtoList);
        setDoctorList([response.data?.data?.dtoList[0]?.doctor]);
        setApiResponse(response?.data?.data?.dtoList);
        setLoading(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
        setIsLoading(false);
      });
  }

  //view history data ============================
  const [historyDataList, setHistoryDataList] = useState();
  async function historyData(patientObject) {
    // var mmucode = JSON.parse(localStorage.getItem("association"))[0].mmucode;
    setPatientId(patientObject?.patientobj?.patientid);
    // var patientId = patientObject.patientid;
    setLoading(true);

    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/get?mmucode=${doctorProfileId}&patientid=${patientObject?.patientobj?.patientid}&recstatus=PRESCRIBED`
      )
      .then((response) => {
        setHistoryDataList(response?.data?.data?.visitAggregationDTOList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  async function createVisit(object) {
    setIsLoading(true);

    await axios
      .put(`${APIs.visitServiceApi}/update`, object)
      .then((response) => {
        bookingPatient.visitid = response.data?.data?.dto?.visitid;
        bookingPatient.recstatus = "ASSIGNED";
        updatePatientbookinglist(bookingPatient);
        setLoading(false);
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  }
  const [finalPatientObject, setFinalPatientObject] = useState();
  async function editPatient(object, itemIdList) {
    for (let i = 0; i < itemIdList.length; i++) {
      if (itemIdList[i].name == object.documenttype) {
        setSelectid(itemIdList[i]);
      }
    }

    setEditobj(object);

    setMobileno(object.mobileno);
    setDocno(object.documentno);
    // setSelectid({ name: object.documenttype})
  }

  //search button
  const requestSearch = (keyData) => {
    //setSearched(key);
    const searchdata = getResponse.filter((key) => {
      if (
        key?.patientobj?.name.toLowerCase().includes(keyData.toLowerCase()) ||
        key?.patientobj?.mobileno.includes(keyData) ||
        key?.patientobj?.patientid.toLowerCase().includes(keyData.toLowerCase())
      ) {
        return key;
      }
    });
    if (keyData === null || keyData.length === 0) {
      setResponse(getApiResponse);
    } else setResponse(searchdata);
  };

  const getUserCategoryNameByValue = (value) =>{
    for(let i=0; i<getUserCategory.visitCategory.length; i++){
      if(getUserCategory.visitCategory[i]?.category?.value === value){
        return capitalizeWords(getUserCategory.visitCategory[i]?.category.name)
      }
    }
    return "";
  }

  useEffect(() => {
    getpatient(doctorProfileId);
    dispatch(userCategoryAction({ profileid:auth.profileid }))
  }, []);

  const handleAssignDoctor = (values) => {
    console.log(values, "patient data");
    if (values?.visit?.paymenttype === "PAID") {
      setPayment({ name: "Paid", code: "PAID" });
    } else {
      setPayment({ name: "Unpaid", code: "UNPAID" });
    }

    if (values.visit.visittype == "REFERAL") {
      setSelectvisittype({ name: "Referal", code: "REFERAL" });
    } else if (values.visit.visittype == "FOLLOW_UP") {
      setSelectvisittype({ name: "Follow Up", code: "FOLLOW_UP" });
    } else {
      setSelectvisittype({ name: "New", code: "NEW" });
    }

    if (values.visit.visitcategory == "OFFLINE") {
      setSelectvisitcategory({ name: "Hospital Visit", value: "OFFLINE" });
    } else if (values.visit.visitcategory == "ONLINE") {
      setSelectvisitcategory({ name: "Online Visit", value: "ONLINE" });
    }else{
      setSelectvisitcategory({ name: "Home Visit", value: "HOME_VISIT" });
    }
    setSelectdoctor(values.doctor);

    setPatientVisitRec(values.visit);
    setSelectedPatient(values?.patientobj);
    setBookingPatient(values);
    setOpen(true);
  };

  //------------------------------------- vitals handler ------------------------------------------------

  const [patientVitals, setPatientVitals] = useState();
  const [vitalsMasterList, setVitalsMasterList] = useState([]);

  const handleCreateVitals = (values) => {
    setOpenvitals(true);
    setIsLoading(true)
    // getPatientVitals(values.visitid)
    getVitalMaster(values.visitid);
    setBookingPatient(values);
  };

  async function getVitalMaster(visitId) {
    await axios
      .get(`${APIs.vitalsMasterServiceApi}/get`)
      .then((response) => {
        // setVitalsMasterList(response?.data?.data?.dtoList);
        getPatientVitals(visitId, response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const getPatientVitals = async (visitId, masterList) => {
    await axios
      .get(`${APIs.vitalsServiceApi}/get?visitid=${visitId}`)
      .then((response) => {
        let temp = response.data.data.dtoList;
        if (temp.length > 0) {
          const updatedList = masterList.map((item, index) => ({
            ...item,
            value: temp[index]?.value,
            id: temp[index]?.id,
            createdby: temp[index]?.createdby,
          }));
          setVitalsMasterList(updatedList);
          // setVitalsMasterList(temp);
          // setOpenvitals(true);
          setIsLoading(false)
        } else {
          setVitalsMasterList(masterList);
          // setOpenvitals(true);
          setIsLoading(false)
        }
        setPatientVitals(response.data.data.dtoList);
      })
      .catch((error) => {
        // setOpenvitals(true);
        setIsLoading(false)
      });
  };

  console.log(getUserCategory, "getUserCategory 477")

  return (
    <DashboardLayout>
      {/* <UpaarNavbar/> */}
      <MDBox pt={3}>
        <MDBox mt={14}>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Search Patient 
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                    <MDInput
                      placeholder="Search..."
                      value={searched}
                      onChange={(e) => {
                        requestSearch(e.target.value);
                        setSearched(e.target.value);
                      }}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={Loading}
              aria-label="Loading Spinner"
            /> 

            <div>
            {getResponse?.length === 0 && !Loading ? (
                       <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
                       <img
                   src={profilePicture}
                   alt="Brooklyn Alice"
                   style={{ 
                     width: '100%', 
                     maxWidth: '200px', 
                     height: 'auto', 
                    
                   }} 
                 />
               
                      
                            </MDBox>
      ) : (
        <>
              {getResponse.map((values) => (
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>
                      <div style={{ fontSize: "15px", color: "#7b809a" }}>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Name :</text>
                          {" " + values?.patientobj?.name}
                          {values.recstatus == "ASSIGNED" && (
                            <MDBadge
                              badgeContent={values.recstatus}
                              size="md"
                              style={{ marginLeft: "12px" }}
                              color="primary"
                              gradient
                            />
                          )}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Age :</text>
                          {" " + values?.patientobj?.age}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Patient ID :
                          </text>
                          {" " + values?.patientobj?.patientid}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Mobile No :
                          </text>
                          {" " + values?.patientobj?.mobileno}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>Date :</text>
                          {" " +
                            new Date(values?.slot?.startdate)
                              .toLocaleString("en-US", {
                                timeZone: "UTC",
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                                hour: "2-digit",
                                minute: "2-digit",
                                second: "2-digit",
                                hour12: true,
                              })
                              .replace(",", "")}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Payment Type :{" "}
                          </text>
                          <MDButton color={values?.visit?.paymenttype =="PAID"?"sucess":"error"} size="small"> {" " + values?.visit?.paymenttype}</MDButton>
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Category :
                          </text>
                          {" " + values?.visit?.category===null?"" : values?.visit?.category}
                        </div>
                        <div>
                          <text style={{ fontWeight: "bold" }}>
                            Visit Type :
                          </text>
                          {" " + getUserCategoryNameByValue(values?.visit?.visitcategory)}
                        </div>
                      </div>
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => handleAssignDoctor(values)}
                        >
                          Assign Doctor
                        </MDButton>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <MDButton
                          variant="gradient"
                          color="info"
                          size="small"
                          type="submit"
                          onClick={() => handleCreateVitals(values)}
                        >
                          Vitals
                        </MDButton>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
              </>)}
            </div>
          </Card>
        </MDBox>
      </MDBox>

      {/* assign Doctor popup================================================= */}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Assign Doctor
        </DialogTitle>

        <DialogContent
          style={{
            width: "90%",
            maxWidth: 550,
            height: "90%",
            maxHeight: 450,
            margin: "auto",
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} mt={3}>
              <Autocomplete
                {...defaultPropsDoctor}
                value={selectdoctor}
                onChange={handleDoctorChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Doctor User"
                    required
                    error={errors.doctorError}
                    helperText={
                      errors.doctorError ? "Please select a doctor" : ""
                    }
                    onFocus={handleDoctorFieldFocus}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                {...defaultPropsvisittype}
                value={selectvisittype}
                onChange={handleVisitTypeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Visit Type"
                    required
                    error={errors.visitTypeError}
                    helperText={
                      errors.visitTypeError ? "Please select a visit type" : ""
                    }
                    onFocus={handleVisitTypeFieldFocus}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                {...paymentOptiona}
                value={payment}
                onChange={(e, val) => handlePayment(e, val)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Payment"
                    required
                    error={errors.paymentError}
                    helperText={
                      errors.paymentError ? "Please select payment" : ""
                    }
                    onFocus={handlePaymentFieldFocus}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Autocomplete
                {...defaultPropsVisitcategory}
                value={selectvisitcategory}
                onChange={handleVisitCategoryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Visit Category"
                    required
                    error={errors.visitCategoryError}
                    helperText={
                      errors.visitCategoryError
                        ? "Please select a visit category"
                        : ""
                    }
                    onFocus={handleVisitCategoryFieldFocus}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDInput
                style={{ width: "100%" }}
                onChange={(e) => {
                  visitObj.firstscreening = e.target.value;
                }}
                label="Additional Information..."
                size="large"
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <MDButton onClick={handleClose} color="success" size="small">
            Cancel
          </MDButton>

          <MDButton
            onClick={handleSubmit}
            color="info"
            size="small"
            disabled={isLoading}
          >
            {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
          </MDButton>
        </DialogActions>
      </Dialog>

      {/* view history popup ===============================================*/}
      <Dialog open={openhistory} onClose={handleClosehistory}>
        <DialogTitle
          style={{
            backgroundColor: "blue",
            color: "#fff",
            textAlign: "center",
          }}
        >
          View History
        </DialogTitle>
        {historyDataList && (
          <DialogContent style={{ width: "100%", maxWidth: 550, height: 350 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={1}>
                <ExpandCircleDownIcon
                  fontSize="medium"
                  style={{ marginTop: "18px" }}
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <MDTypography
                  variant="h5"
                  fontWeight="medium"
                  style={{ marginTop: "18px" }}
                >
                  Patient ID : {patientId}
                </MDTypography>
              </Grid>
            </Grid>

            <h5 style={{ margin: "1px" }}>
              #Visit ID: {historyDataList[0]?.visit?.visitid}
            </h5>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={9}>
                <div
                  style={{
                    border: "1px solid #727375",
                    borderRadius: "9px",
                    marginTop: "25px",
                    marginLeft: "35px",
                  }}
                >
                  <h5 style={{ margin: "1px", textAlign: "center" }}>
                    Prescription Result:
                  </h5>

                  {historyDataList[0]?.prescriptionlist.map((i) => (
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      style={{ margin: "5px" }}
                      key={i.presid}
                    >
                      <h5>Prescription ID : {i.presid}</h5>
                    </Grid>
                  ))}
                </div>
              </Grid>

              <Grid item xs={12} sm={9}>
                <div
                  style={{
                    border: "1px solid #727375",
                    borderRadius: "9px",
                    marginTop: "25px",
                    marginLeft: "35px",
                  }}
                >
                  <h5 style={{ margin: "1px", textAlign: "center" }}>
                    Lab Test Result:
                  </h5>

                  {historyDataList[0]?.prescriptionlist.map((i) => (
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      style={{ margin: "5px" }}
                      key={i.presid}
                    >
                      <h5>Prescription ID : {i.presid}</h5>
                    </Grid>
                  ))}
                </div>
              </Grid>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClosehistory}>Cancel</Button>
          <Button>Update</Button>
        </DialogActions>
      </Dialog>

      <VitalsPopup
        openvitals={openvitals}
        setOpenvitals={setOpenvitals}
        patientVitals={patientVitals}
        vitalsMasterList={vitalsMasterList}
        setVitalsMasterList={setVitalsMasterList}
        bookingPatient={bookingPatient}
        isLoading={isLoading}
      />
    </DashboardLayout>
  );
};

export default Searchpatient;
