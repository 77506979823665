
import React from 'react';
import PropTypes from 'prop-types';

const ProfileSkeleton = ({ width = '100%', height = '1em',alignSelf='center', borderRadius = '4px', variant }) => {
  const style = {
    display: 'inline-block',
    backgroundColor: '#e0e0e0',
    width: width,
    height: height,
    borderRadius: variant === 'circular' ? '50%' : borderRadius,
    margin: '4px 0',
    alignSelf:alignSelf
  };

  return <div style={style}></div>;
};

ProfileSkeleton.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  borderRadius: PropTypes.string,
  variant: PropTypes.oneOf(['circular', 'rectangular']),
};

export default ProfileSkeleton;

