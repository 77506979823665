/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    lastName,

    email,
    password,
    repeatPassword,
    experience,
    phonenumber,
    age,
    gender,
    description,
    department,
    designation,
    state,
    district,
    subDistrict,
    pincode
   
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [gender.name]: "",
  //[description.name]: "",
 
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [state.name]: "",
  [district.name]: "",
  [subDistrict.name]: "",
  [pincode.name]: "",
  [experience.name]:"",
  [phonenumber.name]: "",
  [age.name]: "",
  [designation.name]: "",
  [department.name]: "",

};

export default initialValues;
