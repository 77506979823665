import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from "@mui/material";
import MDInput from "components/MDInput";
import React, { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import MDTypography from "components/MDTypography";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { capitalizeWords } from "static/methods/capitalizedWords";
import { Autocomplete, TextField } from "@mui/material";

const AssignPopup = (props) => {
  const {
    openassign,
    setAssign,
    doctorList,
    values,
    getpatient,
    doctorProfileId,
  } = props;

  const { getUserCategory } = useSelector((state) => state);
  const [errors, setErrors] = useState({
    doctorError: false,
    visitTypeError: false,
    visitCategoryError: false,
    paymentError: false,
  });

  // const [updateMasterList, setUpdateMasterList] = useState([...vitalsMasterList]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [selectvisittype, setSelectvisittype] = useState(null);
  const [selectvisitcategory, setSelectvisitcategory] = useState(null);
  const [selectdoctor, setSelectdoctor] = useState(null);
  const [payment, setPayment] = useState();
  const [bookingPatient, setBookingPatient] = useState();
  const [patientVisitRec, setPatientVisitRec] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [firstscreening, setFirstscreening] = useState("");

  const defaultPropsDoctor = {
    options:
      Array.isArray(doctorList) && doctorList.length > 0
        ? doctorList
        : [{ fullname: "Loading....", profileid: "" }],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) =>
      option.profileid === value.profileid,
  };

  defaultPropsDoctor.options = doctorList?.map((option) => {
    if (option?.profileid === auth.profileid) {
      return { ...option, name: "Self" };
    }
    return option;
  });

  const defaultPropsvisittype = {
    options: [
      { name: "New", code: "NEW" },
      { name: "Follow Up", code: "FOLLOW_UP" },
      { name: "Referal", code: "REFERAL" },
    ],

    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const getCatList = (cat) => {
    let data = cat?.map((item) => item.category);
    return data;
  };
  const defaultPropsVisitcategory = {
    options: !getUserCategory.visitCategory
      ? [
          { name: "Loading...", code: "" },
          // { name: "Hospital Visit", code: "OFFLINE" },
          // { name: "Online Visit", code: "ONLINE" },
        ]
      : getCatList(getUserCategory.visitCategory),
    getOptionLabel: (option) => capitalizeWords(option.name),
    isOptionEqualToValue: (option, value) => option.value === value.value,
  };

  const paymentOptiona = {
    options: [
      { name: "Paid", code: "PAID" },
      { name: "Unpaid", code: "UNPAID" },
    ],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const handleAssignDoctor = (values) => {
    if (values?.visit?.paymenttype === "PAID") {
      setPayment({ name: "Paid", code: "PAID" });
    } else {
      setPayment({ name: "Unpaid", code: "UNPAID" });
    }

    if (values.visit.visittype == "REFERAL") {
      setSelectvisittype({ name: "Referal", code: "REFERAL" });
    } else if (values.visit.visittype == "FOLLOW_UP") {
      setSelectvisittype({ name: "Follow Up", code: "FOLLOW_UP" });
    } else {
      setSelectvisittype({ name: "New", code: "NEW" });
    }

    if (values.visit.visitcategory == "OFFLINE") {
      setSelectvisitcategory({ name: "Hospital Visit", value: "OFFLINE" });
    } else if (values.visit.visitcategory == "ONLINE") {
      setSelectvisitcategory({ name: "Online Visit", value: "ONLINE" });
    } else {
      setSelectvisitcategory({ name: "Home Visit", value: "HOME_VISIT" });
    }
    setSelectdoctor(values.doctor);

    setPatientVisitRec(values.visit);
    setSelectedPatient(values?.patientobj);
    setBookingPatient(values);
  };

  function handleCloseassign() {
    setAssign(false);
    setFirstscreening("");
  }

  async function updatePatientbookinglist(bookingobj) {
    setIsLoading(true);

    await axios
      .put(`${APIs.bookingServiceApi}/update`, bookingobj)
      .then((response) => {
        getpatient();
        setIsLoading(false);

        handleCloseassign();
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  }

  async function updateVisit(object) {
    setIsLoading(true);

    await axios
      .put(`${APIs.visitServiceApi}/update`, object)
      .then((response) => {
        let bookingData = {
          ...bookingPatient,
          visitid: response.data?.data?.dto?.visitid,
          recstatus: "ASSIGNED",
        };
        // bookingPatient.visitid = response.data?.data?.dto?.visitid;
        // bookingPatient.recstatus = "ASSIGNED";
        swal(
          "Assigned!",
          "The doctor has been successfully assigned.",
          "success"
        );
        updatePatientbookinglist(bookingData);

        setIsLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  }
  const handleSubmit = () => {
    setIsLoading(true);
    const formErrors = {
      doctorError: !selectdoctor,
      visitTypeError: !selectvisittype,
      visitCategoryError: !selectvisitcategory,
      paymentError: !payment,
    };

    setErrors(formErrors);

    if (
      formErrors.doctorError ||
      formErrors.visitTypeError ||
      formErrors.visitCategoryError ||
      formErrors.paymentError
    ) {
      setIsLoading(false);
      return;
    }
    const visitObj = {
      visitid: patientVisitRec?.visitid,
      category: patientVisitRec?.category,
      id: patientVisitRec?.id,
      patientid: selectedPatient?.patientid,
      patienttempid: selectedPatient?.patienttempid,
      firstscreening: firstscreening,
      doctorid: selectdoctor?.profileid,
      // doctorid: selectdoctor?.id.toString(),
      mmucode: selectdoctor?.profileid,
      visittype: selectvisittype?.code,
      visitcategory: selectvisitcategory?.value,
      paymenttype: payment?.code,
      // createdby: auth?.name,
      modifiedby: auth.profileid,
      recstatus: "ASSIGNED",
      syncstatus: "NOT_SYNCED",
      status: "ACTIVE",
    };

    updateVisit(visitObj);

    // handleCloseassign();
  };

  const handleDoctorChange = (_, newValue) => {
    setSelectdoctor(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, doctorError: false }));
  };

  const handlePayment = (_, newValue) => {
    setPayment(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, paymentError: false }));
  };

  const handleVisitTypeChange = (_, newValue) => {
    setSelectvisittype(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, visitTypeError: false }));
  };

  const handleVisitCategoryChange = (_, newValue) => {
    setSelectvisitcategory(newValue);
    setErrors((prevErrors) => ({ ...prevErrors, visitCategoryError: false }));
  };
  const handleDoctorFieldFocus = () => {
    if (!selectdoctor) {
      setErrors((prevErrors) => ({ ...prevErrors, doctorError: true }));
    }
  };

  const handleVisitTypeFieldFocus = () => {
    if (!selectvisittype) {
      setErrors((prevErrors) => ({ ...prevErrors, visitTypeError: true }));
    }
  };

  const handlePaymentFieldFocus = () => {
    if (!payment) {
      setErrors((prevErrors) => ({ ...prevErrors, paymentError: true }));
    }
  };

  const handleVisitCategoryFieldFocus = () => {
    if (!selectvisitcategory) {
      setErrors((prevErrors) => ({ ...prevErrors, visitCategoryError: true }));
    }
  };
  useEffect(() => {
    handleAssignDoctor(values);
  }, []);
  return (
    <Dialog open={openassign} onClose={handleCloseassign} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "green",
          color: "#fff",
          textAlign: "center",
        }}
      > 
        Assign Doctor
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} mt={3}>
            <Autocomplete
              {...defaultPropsDoctor}
              value={selectdoctor}
              onChange={handleDoctorChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Doctor User"
                  required
                  error={errors.doctorError}
                  helperText={
                    errors.doctorError ? "Please select a doctor." : ""
                  }
                  onFocus={handleDoctorFieldFocus}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      marginLeft: errors.doctorError ? "0" : "0",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              {...defaultPropsvisittype}
              value={selectvisittype}
              onChange={handleVisitTypeChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Visit Type"
                  required
                  error={errors.visitTypeError}
                  helperText={
                    errors.visitTypeError ? "Please select a visit type." : ""
                  }
                  onFocus={handleVisitTypeFieldFocus}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      marginLeft: errors.visitTypeError ? "0" : "0",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              {...paymentOptiona}
              value={payment}
              onChange={(e, val) => handlePayment(e, val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Payment"
                  required
                  error={errors.paymentError}
                  helperText={
                    errors.paymentError ? "Please select payment." : ""
                  }
                  onFocus={handlePaymentFieldFocus}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      marginLeft: errors.paymentError ? "0" : "0",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Autocomplete
              {...defaultPropsVisitcategory}
              value={selectvisitcategory}
              onChange={handleVisitCategoryChange}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Visit Category"
                  required
                  error={errors.visitCategoryError}
                  helperText={
                    errors.visitCategoryError
                      ? "Please select a visit category."
                      : ""
                  }
                  onFocus={handleVisitCategoryFieldFocus}
                  sx={{
                    "& .MuiFormHelperText-root": {
                      marginLeft: errors.visitCategoryError ? "0" : "0",
                    },
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDInput
              style={{ width: "100%" }}
              value={firstscreening}
              onChange={(e) => {
                setFirstscreening(e.target.value);
              }}
              label="Additional Information..."
              size="large"
              multiline
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleCloseassign} color="success" size="small">
          Cancel
        </MDButton>

        <MDButton
          onClick={handleSubmit}
          color="info"
          size="small"
          // disabled={isLoading}
        >
          {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssignPopup;
