import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import { Formik, Form, Field, ErrorMessage } from "formik";

import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import { UploadDocumnet } from "Services/UploadDocumnet";
import FileUpload from "react-material-file-upload";
import { useSelector } from "react-redux";
import DataTable from "examples/Tables/DataTable";
import { FormHelperText } from "@mui/material";
import swal from "sweetalert";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import FormControl from "@mui/material/FormControl";

function UploadDocument({ formData }) {
  const fileInputRef = useRef(null);
  const { formField, values, errors, touched } = formData;

  const [uploadresponse, setUploadresponse] = useState(
    formData.values.document || []
  );
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentname, setDocumentname] = useState("");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  function onfileChange(e) {
    setFiles(e);
    finalupload(e);
  }

  async function finalupload(e) {
    // Check if 'e' is not empty and has at least one element
    if (e && e.length > 0) {
      const formData = new FormData();

      // Update the formData object
      formData.append("file", e[0]);
      const request = {
        resourcecode: "MEDHARVA",
        username: auth.name,
        code: auth.rolecode,
      };
      const myJSON = JSON.stringify(request);
      formData.append("body", myJSON);
      setLoading(true);
      const data = await UploadDocumnet(formData);
      // values.document = data.docid;

      setUploadresponse([
        ...uploadresponse,
        { docid: data?.docid, filename: data?.filename, name: documentname },
      ]);

      setFiles([]);
      setDocumentname("");
      setLoading(false);
    } else {
      swal("oops!", "Please enter a all field!", "error");
      setLoading(false);
    }
  }

  // async function finalupload(e) {

  //   if (e && e.length > 0) {
  //     const formData = new FormData();

  //     // Update the formData object
  //     formData.append("file", e[0]);
  //     const request = {
  //       resourcecode: "MEDHARVA",
  //       username: auth.name,
  //       code: auth.rolecode,
  //     };
  //     const myJSON = JSON.stringify(request);
  //     formData.append("body", myJSON);
  //     setLoading(true);
  //     const data = await UploadDocumnet(formData);

  // if(!data){
  // swal("Oops!", "An error occurred while uploading the file. Please try again later", "error");
  //     setLoading(false);
  //     setFiles([]);
  // }
  // else{
  // setUploadresponse([
  //   ...uploadresponse,
  //   { docid: data?.docid, filename: data?.filename },
  // ]);

  // setFiles([]);
  // setLoading(false);
  // }

  //   }
  // }

  const removeData = (id) => {
    const newData = uploadresponse.filter((item) => item.docid !== id);

    setUploadresponse(newData);
  };

  useEffect(() => {
    // Update formData.values.document whenever selectedFiles changes
    formData.values.document = uploadresponse;
  }, [uploadresponse, formData.values.document]);

  return (
    <MDBox>
      <MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            {uploadresponse.length === 0 ? (
              errors.document && (
                <FormHelperText error>{errors.document}</FormHelperText>
              )
            ) : (
              <></>
            )}
            <Grid item xs={12} sm={4} mb={4}>
              <FormControl fullWidth>
                <InputLabel
                  htmlFor="documentname"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                ></InputLabel>
                <Select
                  native
                  variant="outlined"
                  style={{ width: "100%" }}
                  name="documentname"
                  id="documentname"
                  value={documentname}
                  onChange={(e) => {
                    const selectedMode = e.target.value;
                    setDocumentname(e.target.value);
                  }}
                >
                  <option value="">Select Document Type</option>

                  <option value="Aadhaar Card">Aadhaar Card</option>
                  <option value="PAN Card">PAN Card</option>
                  <option value="Driving Licence">Driving Licence</option>
                  <option value="Voter ID Card">Voter ID Card</option>
                </Select>
                <ErrorMessage
                  name="documentname"
                  component="div"
                  style={{ color: "red", fontSize: "12px" }}
                />
              </FormControl>
            </Grid>

            <FileUpload
              buttonText={
                <span
                  style={{
                    color: "white",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {loading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Upload"
                  )}
                </span>
              }
              value={files}
              onChange={onfileChange}
              disabled={documentname === ""}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={{
                columns: [
                  { Header: "Document name", accessor: "name" },
                  // { Header: "filename", accessor: "filename" },
                  { Header: "document id", accessor: "docid" },
                  {
                    Header: "Action",
                    accessor: "uid",
                    Cell: ({ row }) => (
                      <span>
                        <FaRegTimesCircle
                          style={{
                            color: "#f44336",
                            width: "25px",
                            height: "25px",
                            cursor: "pointer",
                          }}
                          onClick={() => removeData(row.values.docid)}
                        />
                      </span>
                    ),
                    width: "12%",
                  },
                ],
                rows: uploadresponse,
              }}
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

UploadDocument.propTypes = {
  formData: PropTypes.object.isRequired,
};

export default UploadDocument;

// async function finalupload(e) {

//   if (e && e.length > 0) {
//     const formData = new FormData();

//     // Update the formData object
//     formData.append("file", e[0]);
//     const request = {
//       resourcecode: "MEDHARVA",
//       username: auth.name,
//       code: auth.rolecode,
//     };
//     const myJSON = JSON.stringify(request);
//     formData.append("body", myJSON);
//     setLoading(true);
//     const data = await UploadDocumnet(formData);

// if(!data){
// swal("Oops!", "An error occurred while uploading the file. Please try again later", "error");
//     setLoading(false);
//     setFiles([]);
// }
// else{
// setUploadresponse([
//   ...uploadresponse,
//   { docid: data?.docid, filename: data?.filename },
// ]);

// setFiles([]);
// setLoading(false);
// }

//   }
// }
