import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import ProfileInformation from "components/Shared/profileInformation/ProfileInformation";
import React, { useEffect, useState } from "react";
import CategoryList from "components/Shared/categoryList/CategoryList";
import AddressList from "components/Shared/addressList/AddressList";
import Slotbooking from "components/Shared/Slot";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import VisitCategory from "components/Shared/vistcategory/VisitCategory";
import LabPrice from "../labCategory/labPrice/LabPrice";
import { MMUDetailsAction } from "redux/Actions/mmuDetailsAction";
import { getProjectTeamAction } from "redux/Actions/getProjectTeamAction";
import { micDetailsAction } from "redux/Actions/micDetailsAction";

const LabPofile = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const [visit, setVisit] = useState([]);

  useEffect(() => {
    // dispatch(MMUDetailsAction({ createdby: auth.createdby }));
    dispatch(getProjectTeamAction({ emailId: auth.emailid }));
    // dispatch(micDetailsAction({ profileId: auth?.profileid }));
  }, [auth]);

  const handleDataFromAddress = (data) => {
    setAddress(data);
  };
  const handleDataFromCategory = (data) => {
    setCategory(data);
  };
  const handleDataFromVisit = (data) => {
    setVisit(data);
  };

  return (
    <>
      <ProfileInformation />

      <MDBox px={2.5}>
        <CategoryList sendDataToCategory={handleDataFromCategory} />
      </MDBox>
      <MDBox px={2.5}>
        {/* <AddressList sendDataToAddress={handleDataFromAddress}  /> */}
        <VisitCategory sendDataToVisit={handleDataFromVisit} />
      </MDBox>
      <MDBox px={2.5} mt={2.5}>
        <LabPrice />
      </MDBox>
      <MDBox px={2.5} mt={2.5}>
        <Slotbooking data={auth?.profileid} disabled={true} />
      </MDBox>
    </>
  );
};

export default LabPofile;
