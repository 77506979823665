import checkout from "../schemas/form";
const {
  formField: {
    name,
    description,
    state,
    district,
    subDistrict,
    pincode,
    firstline,
    secondline,
    country,
    latitude,
    longitude,
  },
} = checkout;

const initialValues = {
  [name.name]: "",
  [description.name]: "",
  [firstline.name]: "",
  [secondline.name]: "",
  [country.name]: "",
  [state.name]: "",
  [district.name]: "",
  [subDistrict.name]: "",
  [pincode.name]: "",
  [latitude.name]: "",
  [longitude.name]: "",
};

export default initialValues;
