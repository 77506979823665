import { createSlice } from "@reduxjs/toolkit";
import {createProject,getProject,updateProject,deleteProject} from "../service/Projectcreate";






const initialState = {
  create: [],
  loading: false,
  error: null,
};

export const projectcreateDetail = createSlice({
  name: "projectcreateDetail",
  initialState,
  extraReducers: {
    [createProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProject.fulfilled]: (state, action) => {
      console.log("state",state.create)
      state.loading = false;
      state.create.push(action.payload.data.dto);
    },
    [createProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.create = action.payload.data.dtoList;

    },
    [getProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProject.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.create = state.create.filter((create) => create.id !== id);
      }
    },
    [deleteProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProject.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.create.findIndex(create => create.id === action.payload.data.dto.id)
      state.create[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default projectcreateDetail.reducer;
