

import PropTypes from "prop-types";
import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";
import { BarLoader } from "react-spinners";
import { ErrorMessage, useFormikContext, Field } from "formik";
import axios from "../../../../../axiosinstance";
import { APIs } from 'Services/APIs';
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import FormField from "../FormField";

function VitalsInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const { setFieldValue, validateField } = useFormikContext();
  const [vitalsTypeValue, setVitalsTypeValue] = useState(null);
  const [uomValue, setUomValue] = useState(null);
  const [vitalList, setVitalList] = useState([]);
  const [uomList, setUomList] = useState([]);
  const { formField, values, errors, touched } = formData;

  const defaultProps = {
    options: vitalList.length ? vitalList : [{ name: "Loading...", id: 0 }],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsuom = {
    options: uomList.length ? uomList : [{ name: "Loading...", id: 0 }],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const { vitalsName, vitalsType, vitalrange, uom } = formField;
  const { vitalsName: vitalsNameV, vitalrange: vitalrangeV } = values;

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        const vitalResponse = await axios.get(`${APIs.typeMasterServiceApi}/get?category=VITAL`);
        setVitalList(vitalResponse.data.data.dtoList);

        const uomResponse = await axios.get(`${APIs.typeMasterServiceApi}/get?category=VITAL_UOM`);
        setUomList(uomResponse.data.data.dtoList);
      } catch (error) {
        console.log("error", error);
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  const handleVitalsTypeChange = (event, newValue) => {
    setVitalsTypeValue(newValue);
    setFieldValue('vitalsType', newValue?.name || '');
  
    validateField('vitalsType');
  };

  const handleUomChange = (event, newValue) => {
    setUomValue(newValue);
    setFieldValue('uom', newValue?.name || '');
 
    validateField('uom');
  };

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Create Vitals</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={vitalsName.type}
                label=<span>{vitalsName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={vitalsName.name}
                value={vitalsNameV}
                placeholder={vitalsName.placeholder}
                error={errors.vitalsName && touched.vitalsName}
                success={vitalsNameV.length > 0 && !errors.vitalsName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="vitalsType">
                {({ field }) => (
                  <Autocomplete
                    {...defaultProps}
                    value={vitalsTypeValue}
                    onChange={handleVitalsTypeChange}
                    renderInput={(params) => (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <MDInput
                          {...params}
                          {...field}
                          error={errors.vitalsType && touched.vitalsType}
                          success={vitalsTypeValue != null && !errors.vitalsType && touched.vitalsType}
                          variant="standard"
                          label=<span>{vitalsType.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                        />
                        <ErrorMessage
                          name="vitalsType"
                          component="div"
                          style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
                        />
                      </div>
                    )}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={vitalrange.type}
                label=<span>{vitalrange.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={vitalrange.name}
                value={vitalrangeV}
                placeholder={vitalrange.placeholder}
                error={errors.vitalrange && touched.vitalrange}
                success={vitalrangeV.length > 0 && !errors.vitalrange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Field name="uom">
                {({ field }) => (
                  <Autocomplete
                    {...defaultPropsuom}
                    value={uomValue}
                    onChange={handleUomChange}
                    renderInput={(params) => (
                      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <MDInput
                          {...params}
                          {...field}
                          error={errors.uom && touched.uom}
                          success={uomValue != null && !errors.uom && touched.uom}
                          variant="standard"
                          label=<span>{uom.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                        />
                        <ErrorMessage
                          name="uom"
                          component="div"
                          style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
                        />
                      </div>
                    )}
                  />
                )}
              </Field>
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for VitalsInfo
VitalsInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default VitalsInfo;


