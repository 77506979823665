import {
  Autocomplete,
  Button,
  Card,
  Grid,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import CreateVillagePouup from "./CreateVillagePouup";
import VillageEdit from "./VillageEdit";

const VillageList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10);
  // const PER_PAGE = 5;
  const location = useLocation();
  const [openpopupVillage, setopenpopupVillage] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };
  const [stateList, setStateList] = useState("");

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${
            APIs.pincodeServiceApi
          }/get?type=VILLAGE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (filterData?.name || nameFilter) {
      dispatch(
        tableDataAction({
          url: `${
            APIs.pincodeServiceApi
          }/get?type=VILLAGE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter || filterData.name}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${
            APIs.pincodeServiceApi
          }/get?type=VILLAGE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  }, [currentPage, perPage]);

  //https://smapi.softwaremathematics.com/pincode-service/api/pincode/v1/get?type=VILLAGE&district.code=105&state.code=8&subdistrict.code=505
  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
    // {type:"select", placeholder:"Select State", variant:"outlined", label:"Select State", name:"selectedMic", options:[{label:"Bengaluru", value:"Bengaluru"},{label:"Noida", value:"Noida"},{label:"Gurugram", value:"Gurugram"},{label:"Pune", value:"Pune"}] , page: "allList" },
    // {type:"select", placeholder:"Select District", variant:"outlined", label:"Select District", name:"selectedMic", options:[{label:"Bengaluru", value:"Bengaluru"},{label:"Noida", value:"Noida"},{label:"Gurugram", value:"Gurugram"},{label:"Pune", value:"Pune"}] , page: "allList" },
    // {type:"select", placeholder:"Select Sub-District", variant:"outlined", label:"Select Sub-District", name:"selectedMic", options:[{label:"Bengaluru", value:"Bengaluru"},{label:"Noida", value:"Noida"},{label:"Gurugram", value:"Gurugram"},{label:"Pune", value:"Pune"}] , page: "allList" }
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    const statename = row?.state?.name;
    const subdistrictname = row?.subdistrict?.name;
    const districtname = row?.district?.name;
    const villagename = row?.village?.name;
    const shortname = row?.village?.shortname;
    const villagecode = row?.village?.code;
    const createdbyname = row?.createdbyname
      ? row?.createdbyname
      : row?.createdby;
    return {
      ...row,
      villagename,
      districtname,
      shortname,
      villagecode,
      createdbyname,
      subdistrictname,
      statename,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "villagename", label: "Village Name" },
    { id: "shortname", label: "Short Name" },
    { id: "villagecode", label: "Village Code" },
    { id: "subdistrictname", label: "Sub District Name" },
    { id: "districtname", label: "District Name" },
    { id: "statename", label: "State Name" },
    { id: "createdbyname", label: "Created by" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        tableDataAction({
          url: `${
            APIs.pincodeServiceApi
          }/get?type=VILLAGE&ispageable=true&page=${0}&size=${perPage}&st=${
            filterData.name
          }`,
        })
      );
    }
  };

  const handleClear = () => {
    setFilterData({});
    // navigate("/app/mic/create-mic")
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=VILLAGE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleClose = () => {
    setopenpopup(false);
    setopenpopupVillage(false);
  };
  const handleCreatereset = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=VILLAGE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleEditreset = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=VILLAGE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleOpenEditDialog = (row) => {
    setSelectedUser(row);
    setopenpopup(true);
  };

  async function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          swal("Deleting...", {
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
          });

          await axios.put(`${APIs.pincodeServiceApi}/delete`, row);

          swal("Deleted!", "Your item has been deleted.", "success");

          dispatch(
            tableDataAction({
              url: `${
                APIs.pincodeServiceApi
              }/get?type=VILLAGE&ispageable=true&page=${
                currentPage - 1
              }&size=${perPage}`,
            })
          );
        } catch (error) {
          console.error("Error during delete request:", error);
          swal(
            "Oops!",
            error?.response?.data?.aceErrors?.[0]?.errorMessage ||
              "Something went wrong.",
            "error"
          );
        }
      } else {
        console.log("User cancelled deletion");
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "-4%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "-4%" }}>
          <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
            <div style={addButtonStyle}>
              <span
                style={{
                  alignSelf: "center",
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  Village List
                </MDTypography>
              </span>

              <span
                style={{
                  alignSelf: "start",
                  color: "#344767",
                }}
              >
                <MDButton
                  color="info"
                  onClick={() => setopenpopupVillage(true)}
                >
                  ADD
                </MDButton>
              </span>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
            maxWidth="100px"
          />
        </Card>
      )}
      {openpopup && (
        <VillageEdit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Village Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          handleEditreset={handleEditreset}
        />
      )}
      {openpopupVillage && (
        <CreateVillagePouup
          openpopupVillage={openpopupVillage}
          setopenpopupVillage={setopenpopupVillage}
          handleClose={handleClose}
          handleCreatereset={handleCreatereset}
        />
      )}
    </DashboardLayout>
  );
};
export default VillageList;

//https://smapi.softwaremathematics.com/pincode-service/api/pincode/v1/get?type=VILLAGE&district.code=105&state.code=8&subdistrict.code=505
