
import checkout from "../schemas/form";
const {
  formField: {name,taxable,taxcategory,taxcode },
} = checkout;

const initialValues = {
  [name.name]: "",
[taxable.name]: "",
[taxcategory.name]: "",
[taxcode.name]: "",

};

export default initialValues;
