import { Grid } from "@mui/material";
import React from "react";
import "./whyMedharvaCare.css";
import Diet from "../../findDoctor/assets/images/diet.png";
import Mood from "../../findDoctor/assets/images/mood.png";
import HealthInsurance from "../../findDoctor/assets/images/healthinsurance.png";
import Care from "../../findDoctor/assets/images/care.png";
import WhyMedharvaCareInfo from "./whyMedharvaCareInfo";

const whyMedharvaCare = () => {
  const data = [
    {
      image: Diet,
      heading: "1. Personalized Health Plans:",
      text: "Medharva Care believes health isn't one-size-fits-all. With personalized plans, they cater to your needs, whether managing chronic conditions or focusing on prevention. It's a unique roadmap for your well-being.",
    },
    {
      image: Mood,
      heading: "2. Guidance and Solutions:",
      text: "Healthcare can be confusing, especially with complex diagnoses. Medharva Care is your guide, offering expert advice. Their team equips you with the knowledge to make informed decisions, empowering you in your healthcare journey.",
    },
    {
      image: HealthInsurance,
      heading: "3. Financial Assistance and Management:",
      text: "Medharva Care understands financial barriers in healthcare. They help navigate insurance, manage medical expenses, and optimize finances. Your financial situation won't hinder access to necessary care.",
    },
    {
      image: Care,
      heading: "4. Extended Reach to Underserved Areas:",
      text: "Medharva Care goes beyond boundaries, reaching areas without easy access to hospitals. Leveraging technology and a network of professionals, they ensure healthcare reaches those in need, no matter where they are.",
    },
  ];

  return (
    <div>
      <Grid item className="whymedharvacarecontainer">
        <Grid item xs={12} mt={5} className="whymedharvacareitem">
          Why Medharva Care
        </Grid>
        <Grid
          container
          mt={-4}
          xs={10}
          pt={10}
          className="whymedharvacarecontainerroot"
        >
          {data.map((item, index) => (
            <WhyMedharvaCareInfo
              key={index}
              index={index}
              image={item.image}
              heading={item.heading}
              text={item.text}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default whyMedharvaCare;
