// import { useState,useEffect } from "react";

// // Import form-related components and dependencies
// import { Formik, Form } from "formik";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// // Import form validation, schema, and initial values
// // import initialValues from "../schemas/initialValues";
// // import validations from "../schemas/validations";
// // import form from "../schemas/form";

// import { useNavigate } from "react-router-dom";

// // Import loader component and Redux-related dependencies
// import { MoonLoader } from "react-spinners";
// import { useDispatch } from "react-redux";

// import { useSelector } from "react-redux";
// import swal from "sweetalert";

// import Dialog from "@mui/material/Dialog";
// import ResourceInfo from "./resourceInfo";
// import * as Yup from "yup";

// // Function to define form steps
// // function getSteps() {
// //   return ["Resource Info"];
// // }

// // Function to get content for each form step
// function getStepContent(stepIndex, formData) {
//   switch (stepIndex) {
//     case 0:
//       return <ResourceInfo formData={formData} />;
//     default:
//       return null;
//   }
// }

// // Create the CreateBrand functional component
// export default function CreateResourcepopup(props) {

//   // ----------------------------------------------- Schemas --------------------------------------------

//   const form = {
//     formId: "role-form",
//     formField: {
//       resourcename: {
//         name: "resourcename",
//         label: "Resource Name",
//         type: "text",
//         errorMsg: "Resource Name is required.",
//       },
//       resourceDescrption: {
//         name: "resourceDescrption",
//         label: "Resource Description",
//         type: "textarea",
//       },
//        // upload Document
//      document: {
//       name: "document",
//       label: "Upload Invoice",
//       type: "text",
//       errorMsg: "Document is required.",

//     },
//     },
//   };

//   const {
//     formField: { resourcename, resourceDescrption },
//   } = form;

//   const initialValues = {
//     [resourcename.name]: "",
//     [resourceDescrption.name]: "",
//     [document.name]: null,
//   };

//   const validations = [
//     Yup.object().shape({
//       [resourcename.name]: Yup.string().required(resourcename.errorMsg),
//       [resourceDescrption.name]: Yup.string(),

//       [document.name]: Yup.mixed()
//     .required(document.errorMsg)
//     .test("fileType", "Invalid file type", (value) => {
//       if (!value) return true; // Skip validation if no file is selected
//       return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
//     })
//     .test("fileSize", "File is too large", (value) => {
//       if (!value) return true; // Skip validation if no file is selected
//       return value.size <= 5242880; // 5MB maximum file size
//     }),
//     }),
//   ];

//   const { openpopup, setopenpopup } = props;
//   const navigate = useNavigate();
//   const [activeStep, setActiveStep] = useState(0);
//   const [isLoading, setIsLoading] = useState(false); // Loader state
//   // const steps = getSteps();
//   const { formId, formField } = form;
//   const currentValidation = validations[activeStep];
//   // const isLastStep = activeStep === steps.length - 1;
//    const {error,loading} = useSelector((state) => state.role);

//    // Function to handle going back to the previous step
//   const dispatch = useDispatch();

//   // Function to submit the form
//   const submitForm = async (values, actions) => {

//   console.log(values)

//   };

//   // Function to handle form submission
//   const handleSubmit = (values, actions) => {

//     console.log("Form values:", values);

//     if (isLastStep) {
//       // If it's the last step, submit the form
//       submitForm(values, actions);
//     } else {
//       // If not the last step, move to the next step
//       setActiveStep(activeStep + 1);
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   };

//   const handleClose = () => {
//     setopenpopup(false);
//   };

//   // Render the component
//   return (

//     <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">

//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ mt: 8 }}
//         >
//           <Grid item xs={12} lg={12}>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={currentValidation}
//               onSubmit={handleSubmit}
//             >
//               {({ values, errors, touched, isSubmitting }) => (
//                 <Form id={formId} autoComplete="off">
//                   <Card>
//                     <MDBox mx={2} mt={-3}>
//                       <MDBox
//                         variant="gradient"
//                         bgColor="info"
//                         borderRadius="lg"
//                         coloredShadow="success"
//                         mx={2}
//                         mt={-3}
//                         p={2}
//                         mb={-1}
//                         textAlign="center"
//                       >
//                         <MDTypography
//                           variant="h4"
//                           fontWeight="small"
//                           color="white"
//                           mt={1}
//                         >
//
//                         </MDTypography>
//                       </MDBox>
//                     </MDBox>
//                     <MDBox p={3}>
//                       <MDBox>
//                         {getStepContent(activeStep, {
//                           values,
//                           touched,
//                           formField,
//                           errors,
//                         })}
//                         <MDBox
//                           mt={5}
//                           width="100%"
//                           display="flex"
//                           justifyContent="end"
//                         >
//                           {/* {activeStep === 0 ? (
//                             <MDBox />
//                           ) : (
//                             <MDButton
//                               variant="gradient"
//                               color="light"
//                               onClick={handleBack}
//                             >
//                               Back
//                             </MDButton>
//                           )} */}
//                           <MDButton
//                             type="submit"
//                             variant="gradient"
//                             color="dark"
//                             disabled={isLoading}
//                           >
//                             {loading ? (
//                               <MoonLoader color="#f2fefa" size={16} />
//                             ) : (
//                               "Submit"
//                             )}
//                           </MDButton>
//                         </MDBox>
//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Form>
//               )}
//             </Formik>
//           </Grid>
//         </Grid>
//       </Dialog>

//   );
// }

import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Import form validation, schema, and initial values
import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import form from "../schemas/form";

import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";
import ResourceInfo from "./resourceInfo";
import { createResource } from "Services/Resources";

// Function to define form steps
function getSteps() {
  return ["Role Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <ResourceInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function CreateResourcepopup(props) {
  const { openpopup, setopenpopup } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.resource);

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    setIsLoading(true);
    console.log("Form values:", values);
    if (values.document === undefined) {
      swal("oops!", "Please upload file First!", "error");
      actions.setSubmitting(false);
    } else {
      const requestData = {
        name: values.resourcename,
        description: values.resourceDescrption,
        documentofproof: values.document,
  
        recstatus: "PENDING",
        // resourcecode: "Medharva",
        status: "Active",
        syncstatus: "SYNCED",
      };
  
      const response = await dispatch(createResource(requestData));
  
      if (!response.error && !loading) {
        actions.resetForm();
  
        setIsLoading(false);
        setopenpopup(false);
        //  navigate('/app/agent/resources-list')
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",
          text: error?.message,
        });
        setIsLoading(false);
        handleClose();
      }
    }
    actions.setSubmitting(false);

    
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleClose = (e) => {
    setopenpopup(false);
  };

  // Render the component
  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Resource Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="dark"
                          // disabled={isLoading}
                        >
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
