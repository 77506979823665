import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/Shared/DashboardNavbar";
import React, { Suspense, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useMaterialUIController } from "context";
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";

import { labRoutes } from "routes/labRoutes";
import Sidenav from "components/Sidenav";
import NewAddress from "app/Doctor/DoctorWorkspace/AddressInformation/new-address";
import NewCategory from "app/Doctor/DoctorWorkspace/category";
import AddressList from "components/Shared/addressList/AddressList";

// import Slotbooking from 'components/Shared/Slot';
import Slotbooking from "components/Resuable/doctor-detail/components/Slot";
import Createslot from "app/Doctor/DoctorWorkspace/slotcreation";
import LabTestList from "app/labTest/labTestList/labTestList";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import LabPofile from "./profile/labPofile";
import LabCategory from "./labCategory/labCategory";
import LabPrice from "./labCategory/labPrice/LabPrice";
import Myteam from "app/Doctor/myteam/Myteam";
import CategoryList from "components/Shared/categoryList/CategoryList";
import LabReport from "./labReport/LabReport";
import TestReportList from "./labReport/TestReportList";
import SearchLabPatient from "./searchPatient/SearchLabPatient";
import LabQueue from "./labQueue/LabQueue";
import LabAssociationList from "./LabAssociation";
import LabUserList from "./labUser/LabUserList";
import LabList from "./labUser/LabList";
import TeamAssociationLabList from "./labUser/TeamAssociationLabList";
import Lablist from "./LabList";
import LabTeamAssociationList from "./LabTEAMASSOCIATION";
import DepartmentList from "components/Shared/department/DepartmentList";
import DesignationList from "components/Shared/designation/DesignationList";
import HomeSidenavSkeleton from "components/Skeleton/HomeSidenavSkeleton";
import { projectDetailsAction } from "redux/Actions/projectDetailsAction";
import swal from "sweetalert";
import BookingView from "app/labTest/labTestList/Bookingview/BookingView";


const LabLayout = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectDetails } = useSelector(state => state);
  const [controller] = useMaterialUIController();
  const { slug } = useParams();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;
  const [address, setAddress] = useState([]);
  const [category, setCategory] = useState([]);
  const handleDataFromAddress = (data) => {
    setAddress(data);
  };
  const handleDataFromCategory = (data) => {
    setCategory(data);
  };

  useEffect(()=>{
    dispatch(projectDetailsAction({ profileId: auth.profileid }))
  },[auth])

  useEffect(()=>{
    if(getProjectDetails?.error !== null && getProjectDetails?.error?.response?.data?.aceErrors[0]?.errorMessage){
      console.log(getProjectDetails?.error?.response, "error data")
      // let message = getProjectDetails?.error?.response?.data?.aceErrors[0]?.errorMessage
      let message = "You have not assigned with any project."
      swal(message, {
        icon: 'error',
        buttons: {
          confirm: {
            text: 'OK',
            value: true,
          },
        },
      }).then((value) => {
        if (value) {
          dispatch(projectDetailsAction({ clear: true }))
          dispatch({ type: "LOGOUT_FULFILLED" })
        }
      });
    }
    
  },[getProjectDetails.error, slug])  

  


  return (
    <>
      <Suspense fallback={<HomeSidenavSkeleton />}>
      <DashboardLayout>
        <div style={{ position: "fixed", width: "100%", zIndex: "1000" }}>
          <DashboardNavbar />
        </div>
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Medharva"
          routes={labRoutes}
        />

        {slug === "profile" && <LabPofile />}
        {slug === "new-address" && <NewAddress />}
        {slug === "lab-category" && <LabCategory />}
        {slug === "myteam" && <Myteam />}
        {slug === "test-report" && <TestReportList />}
        {slug ==="lab-booking-information" && <BookingView />}
        {slug === "test-report-View" && <LabReport />}
        <div style={{ marginTop: "50px" }}>
          {slug === "addresslist" && (
            <AddressList sendDataToAddress={handleDataFromAddress} />
          )}
          {slug === "categorylist" && (
            <CategoryList sendDataToCategory={handleDataFromCategory} />
          )}
          {slug === "pricelist" && <LabPrice />}
          {slug === "create-slot" && <Createslot />}
          {slug === "booking-list" && <LabTestList />}
          {slug === "lab-list" && <Lablist />}
          {slug === "lab-association" && <LabAssociationList />}
          {slug === "lab-team-association" && <LabTeamAssociationList/>}

        </div>
        <div style={{ marginTop: "70px" }}>
          {slug === "slotbooking" && (
            <Slotbooking data={auth?.profileid} disabled={true} />
          )}
        </div>
        {slug === "search-patient" && (
          <SearchLabPatient labProfileId={auth.profileid} />
        )}
        {slug === "lab-queue" && <LabQueue labProfileId={auth.profileid} />}
      </DashboardLayout>
      {slug==="user-list" &&  <LabUserList />}
      {slug==="lab-project-list" &&  <LabList />} 
      {slug==="lab-project-team-association" &&  <TeamAssociationLabList />} 
      
      {slug ==="department-master" && <DepartmentList />}
      {slug ==="designation-master" && <DesignationList />}
      {/* {slug==="lab-team-association" &&  <TeamAssociationLabList />} */}
      </Suspense>
    </>
  );
};

export default LabLayout; 
