import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux"; 
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";

//edit icon
import { FaRegEdit } from "react-icons/fa";


import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";


import ProjectclinicPopup from "./ProjectclinicPopup";
import { deleteProjectclinic, getProjectclinic } from "../service/Projectclinic";
import ProjectClinicEdit from "./ProjectClinicEdit";


function ProjectclinicList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {projectclinic, loading} = useSelector((state) => state.projectclinic);
  // const [loading, setLoading] = useState(true);
 
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openpopupUser, setopenpopupUser] = useState(false);


function editBrand(item){
      setopenpopup(true);
     setSelectedUser(item)
}


function onDelete(item) {
  // Show a confirmation dialog using SweetAlert
  swal({
    title: "Are you sure?",
    text: "You will not be able to recover this item!",
    icon: "warning",
    buttons: ["Cancel", "Delete"],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      // User clicked "Delete," proceed with deletion
      swal("Deleting...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      // Dispatch the deleteBrand action
      dispatch(deleteProjectclinic(item))
        .then(() => {
          // After successful deletion, close the Swal dialog
          swal("Deleted!", "Your item has been deleted.", "success");
          // Fetch the updated brand list
        
        })
        .catch((error) => {
          // Handle errors, display an error message, and close the Swal dialog
          swal("Error", "An error occurred while deleting the item.", "error");
        });
    } else {
      // User clicked "Cancel," do nothing
      swal("Cancelled", "Your item is safe.", "info");
    }
  });
}




useEffect(() => {
  dispatch(getProjectclinic());
}, [])

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  
  if (loading) {
    // Render a centered circular loader
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <DashboardLayout>

      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                Project Clinic
                  </MDTypography>
                </span>

                <span
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                >
                  <MDButton color='success' onClick={() => setopenpopupUser(true)}>ADD</MDButton>
                </span>
              </div>
            </MDBox>
      <DataTable
        canSearch={true}
        table={{
          columns: [
            { Header: "project", accessor: "projectname" },
        { Header: "project code", accessor: "projectcode" },
        { Header: "mmu", accessor: "mmu.name" },
        { Header: "mmu code", accessor: "mmu.code" },
        { Header: "created by", accessor: "createdby" },
      
           
            {
              Header: "Action",
              accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                       editBrand(row.original)
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: projectclinic || [],
        }}
      />
      </Card>
      </MDBox>
      </MDBox>
      <ProjectClinicEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
       title={"Project Clinic Edit"}
       selectedUser={selectedUser}
      />
      <ProjectclinicPopup
        openpopupUser={openpopupUser}
        setopenpopupUser={setopenpopupUser}
      />
    </DashboardLayout>
  );
}

export default ProjectclinicList;




