import * as Yup from "yup";
import checkout from "../schemas/form";


const {
  formField: {
    gender,
    dateofbirth,
    emailId,
    phoneno,
    preferedlanguage,
    firstname,
    secondline,
    password,
    repeatPassword,
    firstline,
    subDistrict,
    state,
    document,
    country,
    pincode,
    agentcomissiontype,
    agentcomission,
    resourcecomission,
    resourcecomissiontype,
    personalinformation,
    qualificationinformation,
    location,
    district
  },
} = checkout;

const validations = [


  //personal detail
  Yup.object().shape({
    [firstname.name]: Yup.string().required(firstname.errorMsg),
    // password: Yup.string()
    // .matches(
    //   /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //   {
    //     message:
    //       "Password must be at least 8 characters long and contain at least one letter, one number, and one special character",
    //     excludeEmptyString: true,
    //   }
    // )
    // .required("Password is Required"),
    // repeatPassword: Yup.string()
    // .oneOf([Yup.ref("password"), null], "Passwords must match")
    // .required("Confirm Password is Required"),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [dateofbirth.name]: Yup.date()
      .max(new Date(Date.now() - 1), 'Date of Birth must be in the past')
      .required('Date of Birth is required'),
    [emailId.name]: Yup.string().required(emailId.errorMsg).email(emailId.invalidMsg),
    [phoneno.name]: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    [preferedlanguage.name]: Yup.string().required(preferedlanguage.errorMsg),
  }),

  // address detail
  Yup.object().shape({
    [firstline.name]: Yup.string().required("First Line is required"),
   
    [secondline.name]: Yup.string().required("Second Line is required"),
    [subDistrict.name]: Yup.string().required(subDistrict.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
     [country.name]: Yup.string().required(country.errorMsg),
    [pincode.name]: Yup.string().required(pincode.errorMsg),
   
      location: Yup.array()
        .min(1, "At least one item is required") // Ensure the array has at least one item
        .required("At least one item is required"), // Ensure the array itself is not null or undefined
  
  
  }),

  // upload document
  Yup.object().shape({
    personalinformation: Yup.array()
      .min(1, "At least one item is required") // Ensure the array has at least one item
      .required("At least one item is required"), // Ensure the array itself is not null or undefined
  }),

  Yup.object().shape({
    qualificationinformation: Yup.array()
      .min(1, "At least one item is required") // Ensure the array has at least one item
      .required("At least one item is required"), // Ensure the array itself is not null or undefined
  }),


  // Yup.object().shape({

  //   [document.name]: Yup.array()
  //   .min(1, "At least one file is required") // Require at least one file
  //   .of(
  //     Yup.mixed()
  //       .test("fileType", "Invalid file type", (value) => {
  //         if (!value) return true; // Skip validation if no file is selected
  //         return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
  //       })
  //       .test("fileSize", "File is too large", (value) => {
  //         if (!value) return true; // Skip validation if no file is selected
  //         return value.size <= 5242880; // 5MB maximum file size
  //       })
  //   )

  // }),
  Yup.object().shape({
    document: Yup.array()
      .min(1, "At least one item is required") // Ensure the array has at least one item
      .required("At least one Documnet is required"), // Ensure the array itself is not null or undefined
  }),

  // Yup.object().shape({

  //   [document.name]: Yup.mixed()
  //     .required(document.errorMsg)
  //     .test("fileType", "Invalid file type", (value) => {
  //       if (!value) return true; // Skip validation if no file is selected
  //       return ['image/jpeg', 'image/png', 'application/pdf'].includes(value.type);
  //     })
  //     .test("fileSize", "File is too large", (value) => {
  //       if (!value) return true; // Skip validation if no file is selected
  //       return value.size <= 5242880; // 5MB maximum file size
  //     }),


  // }),
  Yup.object().shape({
    [resourcecomission.name]: Yup.string().required(resourcecomission.errorMsg),
    [resourcecomissiontype.name]: Yup.string().required(resourcecomissiontype.errorMsg),
    [agentcomissiontype.name]: Yup.string().required(agentcomissiontype.errorMsg),
    [agentcomission.name]: Yup.string().required(agentcomission.errorMsg),
 
  }),

];

export default validations;











