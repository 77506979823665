import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getUser } from "components/Resuable/UserMaster/service/User";

import { updateAssociation } from "../service/Association";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { getClinic } from "app/Admin/AdminWorkspace/Clinic Master/service/Clinic";
// import { updateDesignation } from "../service/Designation";

const validationSchema = Yup.object().shape({
  clinic: Yup.string().required("Clinic is required"),

  profile: Yup.string().required("Profile is required"),

  rolecode: Yup.string().required("rolecode is required"),
});

const AssociationEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedUser, openpopup, setopenpopup } = props;
  const { role } = useSelector((state) => state.role);
  const { error, loading } = useSelector((state) => state.association);

  const { user } = useSelector((state) => state.user);

  const { clinic } = useSelector((state) => state.clinic);

  const [UserList, setUserList] = useState([]);

  const onSubmit = async (values) => {
    const updatedUser = {
      ...selectedUser,

      rolecode: values.roleName ? values.rolecode : selectedUser.rolecode,
      username: values.selectedProfileObj
        ? values.selectedProfileObj?.username
        : selectedUser.username,
      rolename: values.roleName ? values.roleName : selectedUser.rolename,
      profileid: values.selectedProfileObj
        ? values.selectedProfileObj.profileid
        : selectedUser.profileid,
      // firstname: values.selectedProfileObj.firstname,
      // lastname: values.selectedProfileObj.lastname,
      fullname: values.selectedProfileObj
        ? values.selectedProfileObj.name
        : selectedUser.fullname,
      mmucode: values.selectedmmuObj
        ? values.selectedmmuObj.code
        : selectedUser.mmucode,
      mmuname: values.selectedmmuObj
        ? values.selectedmmuObj.name
        : selectedUser.mmuname,

      recstatus: "OPEN",
      syncstatus: "SYNCED",
      status: "ACTIVE",
    };

    const response = await dispatch(updateAssociation(updatedUser));

    if (!response.error && !loading) {
      setopenpopup(false);
      swal("Good job!", "Association has been successfully updated.", "success");
    } else {
      handleClose();

      swal({
        icon: "error",
        title: "Error",
        text: error?.message
          ? error?.message
          : "An error occurred while updating the item.",
      });
    }
  };

  const initialValues = {
    clinic: selectedUser?.mmucode,

    rolecode: selectedUser?.rolecode,
    profile: selectedUser?.profileid,
  };

  const handleClose = () => {
    setopenpopup(false);
    const userList = user?.filter(
      (userItem) => userItem?.rolecode === selectedUser?.rolecode
    );
    setUserList(userList);
  };
  function getProfileByRoleCode(code) {
    const result = user.filter((userItem) => userItem.rolecode === code);
    setUserList(result);
  }

  useEffect(() => {
    dispatch(getClinic());
    dispatch(getUser());
    dispatch(getRole());
    const userList = user?.filter(
      (userItem) => userItem?.rolecode === selectedUser?.rolecode
    );
    setUserList(userList);
    // setSelectDes("")
  }, [selectedUser]);

  return (
    <Dialog open={openpopup} onClose={handleClose}>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent style={{ width: 550, height: 220 }}>
              <FormControl fullWidth error={!!errors.clinic && touched.clinic}>
                <InputLabel
                  htmlFor="clinic"
                  style={{
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Clinic
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="clinic"
                  id="clinic"
                  value={values.clinic}
                  onChange={(e) => {
                    const clinicode = e.target.value;

                    const selectedmmuObj =
                      clinic.find((obj) => obj.code === clinicode) || {};

                    setFieldValue("selectedmmuObj", selectedmmuObj);
                    setFieldValue("clinic", clinicode);
                  }}
                >
                  <option value=""></option>
                  {clinic.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.clinic && touched.clinic && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.clinic}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl
                fullWidth
                error={!!errors.rolecode && touched.rolecode}
              >
                <InputLabel
                  htmlFor="rolecode"
                  style={{
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Role
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="rolecode"
                  id="rolecode"
                  value={values.rolecode}
                  onChange={(e) => {
                    const rolecode = e.target.value;
                    getProfileByRoleCode(e.target.value);
                    const selectedRoleObj =
                      role.find((obj) => obj.rolecode === rolecode) || {};

                    // const roleName =
                    //   e.target.options[e.target.selectedIndex].text;
                    setFieldValue("roleName", selectedRoleObj.name);
                    setFieldValue("rolecode", rolecode);
                  }}
                >
                  <option value=""></option>
                  {role.map((obj) => (
                    <option key={obj.id} value={obj.rolecode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.rolecode && touched.rolecode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.rolecode}
                  </FormHelperText>
                )}
              </FormControl>

              <MDBox mb={2}>
                <FormControl
                  fullWidth
                  error={!!errors.profile && touched.profile}
                >
                  <InputLabel
                    htmlFor="profile"
                    style={{
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Profile
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="profile"
                    id="profile"
                    value={values.profile}
                    onChange={(e) => {
                      const profile = e.target.value;
                      const selectedProfileObj =
                        UserList.find((obj) => obj.profileid === profile) || {};

                      setFieldValue("selectedProfileObj", selectedProfileObj);
                      setFieldValue("profile", profile);
                    }}
                  >
                    <option value=""></option>
                    {UserList.map((obj) => (
                      <option key={obj.id} value={obj.profileid}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.profile && touched.profile && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.profile}
                    </FormHelperText>
                  )}
                </FormControl>
              </MDBox>
            </DialogContent>
            <DialogActions>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={handleClose}
              >
                Cancel
              </MDButton>
              <MDButton
                size="small"
                variant="gradient"
                color="success"
                type="submit"
                // disabled={isSubmitting}
              >
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default AssociationEdit;
