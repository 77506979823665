import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
// NewUser page components

// import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds

import validations from "./new-doctor/schemas/validations";
import form from "./new-doctor/schemas/form";
import initialValues from "./new-doctor/schemas/initialValues";

// image
import bgImage from "assets/images/doctorsignupimg3.png";
import axios from "../../../../axiosinstance";

import { CircularProgress } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

import DoctorInfo from "./new-doctor/components/DoctorInfo";
import CoverLayout from "layouts/authentication/components/CoverLayout";

function getSteps() {
  return ["Doctor Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <DoctorInfo formData={formData} />;

    default:
      return null;
  }
}

function Doctorsignup() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [isLoading, setIsLoading] = useState(false);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const formData = {
      createddate: null,
      experience: values.experience,
      sex: values.gender,
      description: values.description,
      age: values.age,
      departmentcode: values.department.code,
      departmentname: values.department.name,
      designationcode: values.designation.code,
      designationname: values.designation.name,

      emailid: values.email,
      name: values.lastName
        ? "Dr. " +
          values.firstName.replace(/^(Dr|DR)\.?/i, "").trim() +
          " " +
          values.lastName
        : "Dr. " + values.firstName.replace(/^(Dr|DR)\.?/i, "").trim(),
      id: null,

      rolename: "DOCTOR",
      rolecode: "DO001",
      isactive: "OPEN",

      mobileno: values.phonenumber,

      password: values.password,
      profileid: "string",
      recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",

      createdby: "HEALTHCARE WEB UI",
    };

    await axios
      .post("login-service-mongo/api/loginservice/v2/create", formData)

      .then((response) => {
        navigate("/app/login");
        swal("Success", "Doctor has been registered successfully.", "success");
      })
      .catch((error) => {
        let errMsg = error?.response?.data?.aceErrors[0]?.errorMessage;
        console.log(errMsg, "124error");
        setIsLoading(false);
        swal("Oops!", errMsg || "Something wents wrong.", "warning");
      });

    // actions.setSubmitting(false);
    // actions.resetForm();

    // setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <MDBox
      sx={{
        overflowX: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
      }}
    >
      <CoverLayout image={bgImage}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form id={formId} autoComplete="off">
              <Card
                sx={{
                  position: "relative",
                  width: {
                    xs: "80vw",
                    sm: "80vw",
                    md: "70vw",
                    lg: "60vw",
                    xl: "35rem",
                  },
                  margin: "0 auto",
                  transform: "translateX(-50%)",
                  left: "50%",
                  boxShadow: 3,
                  marginBottom: "3vw"
                }}
              >
                <MDBox mx={2} mt={-3}>
                  <MDBox
                    variant="gradient"
                    bgColor="info"
                    borderRadius="lg"
                    coloredShadow="success"
                    mx={2}
                    mt={-1}
                    p={3}
                    mb={1}
                    textAlign="center"
                  >
                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      color="white"
                      mt={2}
                    >
                      Sign up
                    </MDTypography>
                    <MDTypography
                      display="block"
                      variant="button"
                      color="white"
                      my={1}
                    >
                      Enter your details and password to register
                    </MDTypography>
                  </MDBox>
                </MDBox>
                <MDBox p={3}>
                  {getStepContent(activeStep, {
                    values,
                    touched,
                    formField,
                    errors,
                  })}
                  <MDBox
                    mt={2}
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                  >
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton
                        variant="gradient"
                        color="light"
                        onClick={handleBack}
                      >
                        Back
                      </MDButton>
                    )}
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : (
                        "Submit"
                      )}
                    </MDButton>
                  </MDBox>
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    <span style={{ fontSize: "12px" }}>
                      Already have an account?
                    </span>
                    <Link
                      style={{
                        marginLeft: "5px",
                        fontWeight: "bold",
                        color: "green",
                        fontSize: "16px",
                      }}
                      to={"/app/login"}
                    >
                      Sign In
                    </Link>
                  </div>
                </MDBox>
              </Card>
            </Form>
          )}
        </Formik>
      </CoverLayout>
    </MDBox>
  );
}

export default Doctorsignup;
