
import Icon from "@mui/material/Icon";

import SearchIcon from '@mui/icons-material/Search';
import SearchDoctor from "components/Resuable/profile-summary/components/profiSummaryInfo/SearchDoctor";
import { Link } from "react-router-dom";


function getData(data){
 console.log("data",data)

}

 
const pageRoutes = [

  { 
    name: "Home",
    href: "https://medharva.com/",
    description: "All about overview, quick start, license and contents",
    icon: <Icon>account_balance</Icon>,
  },

  {
    name: "Our Services",
    href: "https://medharva.com/our-services/",
    description: "All about overview, quick start, license and contents",
    icon: <Icon>memory</Icon>,
  },

  {
    name: "About",
    href: "https://medharva.com/about/",
    description: "All about overview, quick start, license and contents",
    icon: <Icon>article</Icon>,
  },

 



];

export default pageRoutes;
