import React from 'react'

const TextTab = () => {
  return (
    <div>
       <div className='text-a'>
   <div className='text-B'>
    <span className='text-C' >Top booked lab tests
   </span>
  
   </div>
        </div>
    </div>
  )
}

export default TextTab
