import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "components/DashboardLayout";

function PharmacyCategory() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [idCounter, setIdCounter] = useState(0);
  const [categoryname, setCategoryname] = useState(null);

  const [subcategoryresponse, setSubcategoryresponse] = useState("");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();

  const initialValues = {
    category: "",
    // subcategory: "",
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required"),
    // subcategory: Yup.string().required("Subcategory is required"),
  });

  //   const handleSubmit = (values, { resetForm }) => {

  //     const newData = {
  //       uid: idCounter,

  //       //   subcategory: values.subcategory,

  //       category: categoryname,
  //       showcategoryname:categoryname?.name

  //     };
  //     setData([...data, newData]);
  //     setIdCounter(idCounter + 1);
  //     resetForm();
  //   };
  const handleSubmit = (values, { resetForm }) => {
    // Check if the categoryname is already present in the data array
    const isDuplicate = data.some(
      (item) => item.showcategoryname === categoryname?.name
    );

    if (isDuplicate) {
      swal(
        "Alert!",
        "Duplicate entry! Please choose a different category!",
        "warning"
      );
    } else {
      const newData = {
        uid: idCounter,
        category: categoryname,
        showcategoryname: categoryname?.name,
      };
      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      resetForm();
    }
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "category", accessor: "showcategoryname" },
    // { Header: "subcategory", accessor: "subcategory" },

    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        // <button onClick={() => removeData(row.values.id)}>Remove</button>
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];
  async function finasubmit() {
    setLoading(true);
    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setLoading(false);
    } else {
      var objectList = [];
      for (let i = 0; i < data.length; i++) {
        const specailizationData = {
          category: data[i].category,
          createdby: "ANKIT",
          createddate: "",
          documentofproof: "",
          id: null,
          latlong: "",
          location: "",
          modifiedby: "",
          modifieddate: "",
          profileid:
            auth?.rolecode === "AD001"
              ? location?.state?.profileid
              : auth?.profileid,
          recstatus: "OPEN",
          resourcecode: "HEALTHCARE",
          status: "Active",
          syncstatus: "SYNCED",
          tempid: "",
          userid: "string",
        };
        objectList.push(specailizationData);
      }
      console.log("specailizationData", objectList);

      await axios
        .post("usercategory-service/api/Usercategory/v1/createList", objectList)

        .then((response) => {
          swal("Good job!", "Category has been Added!", "success");
          setLoading(false);
          navigate("app/pharmacy/profile");
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setLoading(false);
        });
    }
  }
  //   async function getSubCategory(depth, hierarchicalcode) {
  //     //setting pincode===========
  //     await axios
  //       .get(
  //         `category-service/api/Category/v1/getWithHierarchy?depth=${depth}&hierarchicalcode=${hierarchicalcode}`
  //       )
  //       .then((response) => {
  //         setSubcategoryList(response.data.data.dtoList);
  //         setLoading(false);
  //       })
  //       .catch((e) => {
  //         console.log("error", e);
  //       });
  //   }

  async function getCategory() {
    await axios
      .get(`category-service/api/Category/v1/get?type=labtest`)
      .then((response) => {
        setCategoryList(response.data.data.dtoList);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getCategory();
  }, []);

 
  return (
   
      
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: auth?.rolecode === "PH257" ? 18 : 3 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          Category Information
                        </MDTypography>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="white"
                          my={1}
                        >
                          Enter your detail
                        </MDTypography>
                      </MDBox>
                      {auth.rolecode === "AD001" ? (
                        <MDTypography variant="h5">
                          Selected : {location?.state?.name}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={5}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="category"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Category{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="category"
                              id="category"
                              value={values.category}
                              onChange={(e) => {
                                const selectedMode = e.target.value;
                                setFieldValue("category", selectedMode);

                                // Find the selected object from categoryList
                                const selectedObject = categoryList.find(
                                  (obj) => obj.hierarchicalcode === selectedMode
                                );

                                setCategoryname(selectedObject);
                                // getSubCategory(2, selectedMode);
                              }}
                              error={
                                touched.category && Boolean(errors.category)
                              }
                            >
                              <option value=""></option>
                              {categoryList.map((obj) => (
                                <option
                                  key={obj.id}
                                  value={obj.hierarchicalcode}
                                >
                                  {obj.name}
                                </option>
                              ))}
                            </Select>

                            <ErrorMessage
                              name="category"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        {/* <Grid item xs={12} sm={5}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="subcategory"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              subcategory{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="subcategory"
                              id="subcategory"
                              value={values.subcategory}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                console.log(selectedType);
                                setFieldValue("subcategory", selectedType);
                                const selectedObject = subcategoryList.find(
                                  (obj) => obj.name === selectedType
                                );

                                setSubcategoryresponse(selectedObject);
                              }}
                              error={
                                touched.subcategory &&
                                Boolean(errors.subcategory)
                              }
                            >
                              <option value=""></option>

                              {subcategoryList.map((obj) => (
                                <option key={obj.id} value={obj.name}>
                                  {obj.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="subcategory"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid> */}

                        <Grid item xs={12} sm={2}>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            size="small"
                          >
                            Add
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                      />
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      marginRight="10px"
                      marginBottom="8px"
                    >
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => finasubmit()}
                      >
                        {loading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "submit"
                        )}
                      </MDButton>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    
   
  );
}

export default PharmacyCategory;
