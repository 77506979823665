import { useState,useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Import form validation, schema, and initial values
import initialValues from "../schemas/initialValues";
import validations from "../schemas/validations";
import form from "../schemas/form";

import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";


import { useSelector } from "react-redux";
import swal from "sweetalert";
import axios from "../../../../../axiosinstance";
import Dialog from "@mui/material/Dialog";
import PatientInfo from "./patientInfo";
import { APIs } from "Services/APIs";

// Function to define form steps
function getSteps() {
  return ["Role Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <PatientInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function PatientAddressPopup(props) {
  const { openpopup, setopenpopup,getAddress } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  
   const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    
  
  
    
    setIsLoading(true)
    console.log("Form values:", values);
   
   const requestData = {
    classname: "Address",
    profileid:
       auth?.profileid,
    firstline: values.firstLine,
    secondline: values.secondLine,
    addresstype: values.addresstype,
    postcode: values.pincode,
    country: values.country,
    state: values.state,
    district: values.district,
    city: values.cityVillage,
    subdistrict: values.subdistrict,
    recstatus: "OPEN",
    status: "ACTIVE",
    syncstatus: "SYNCED",

   };

   await axios
        .post(`${APIs.addressServiceApi}/create`, requestData)
 
        .then((response) => {
        
          swal("Good job!", "Address has been Added!", "success");
          getAddress(auth?.profileid)
          handleClose()
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setIsLoading(false);
        });
   
    
   
     
   
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    console.log("Form values:", values);

    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
 

  const handleClose = () => {
    setopenpopup(false);
  };

 

  // Render the component
  return (

    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
    
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ mt: 8 }}
        >
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched,setFieldValue, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Address Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          setFieldValue,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    
  );
}

