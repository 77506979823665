/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "category-form",
  formField: {
    categoryName: {
      name: "categoryName",
      label: "Category Name",
      type: "text",
      errorMsg: "Category name is required.",
    },
    categoryDescrption: {
      name: "categoryDescrption",
      label: "Category Description",
      type: "text",
      errorMsg: "Category description is required.",
    },
    categoryType: {
      name: "categoryType",
      label: "Category Type",
      type: "text",
      errorMsg: "Category type is required.",
    },
    categoryvalue: {
      name: "categoryvalue",
      label: "Value",
      type: "text",
      errorMsg: "Value is required.",
    },
    parentcategory: {
      name: "parentcategory",
      label: "Parent Category",
      type: "text",
      errorMsg: "Parent category is required.",
    },
  },
};

export default form;
