import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
   profile,
   mmu,
    rolecode,
   
  },
} = checkout;

const validations = [
  Yup.object().shape({
  
    [rolecode.name]: Yup.string().required(rolecode.errorMsg),
    [mmu.name]: Yup.string().required(mmu.errorMsg),
    [profile.name]: Yup.string().required(profile.errorMsg),

   
  }),

];

export default validations;
