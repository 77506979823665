
const form = {
  formId: "new-item-form",
  formField: {
   name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    taxable: {
      name: "taxable",
      label: "Taxable",
      type: "select",
      errorMsg: "Taxable is required.",
    },
   
   taxcategory: {
      name: "taxcategory",
      label: "Tax Category",
      type: "select",
      errorMsg: "Tax category is required.",
    },
    taxcode : {
      name: "taxcode",
      label: "Tax Code",
      type: "select",
      errorMsg: "Tax code is required.",
    },
    
 
 
 
 
  },
};

export default form;
