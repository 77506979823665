/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    qualificationName: {
      name: "qualificationName",
      label: "Qualification Name",
      type: "text",
      errorMsg: "Qualification name is required.",
    },
    mode: {
      name: "mode",
      label: "Mode",
      type: "text",
      errorMsg: "Mode is required.",
    },
    startDate: {
      name: "startDate",
       
      type: "date",
      errorMsg: "Start date is required.",
    },
    board: {
      name: "board",
      label: "Board",
      type: "text",
      errorMsg: "Board is required.",
    },
    
    type: {
      name: "type",
      label: " Type",  
      type: "text",
      errorMsg: "Type is required.",
    },
    grade: {
      name: "grade",
      label: "Grade",  
      type: "text",
      errorMsg: "Grade is required.",
    },
    
    endDate: {
      name: "endDate",
      label: "End Date",  
      type: "date",
      errorMsg: "End date is required.",
    },
    projectselection: {
      name: "projectselection",
      label: "Project Selection",
      type: "text",
      errorMsg: "Project selection is required.",
    },
    category: {
      name: "category",
      label: "Category",
      type: "text",
      errorMsg: "Category is required.",
    },
    subcategory: {
      name: "subcategory",
      label: "Sub Category",
      type: "text",
      errorMsg: "Sub category is required.",
    },
     specailization: {
      name: "specailization",
      label: "Specailization",
      type: "text",
      errorMsg: "Specailization is required.",
    },

    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "number",
      errorMsg: "Pincode is required.",
    },
    address: {
      name: "address",
      label: "Address Selection",
      type: "text",
      errorMsg: "Address name is required.",
    },
    slot: {
      name: "slot",
      label: "Slot Per Patient",
      type: "text",
      errorMsg: "Slot size is required.",
    },
    date: {
      name: "date",
      
      type: "date",
      errorMsg: "Date is required.",
    },
    startTime: {
      name: "startTime",
      label: "Start Time",
      type: "time",
      errorMsg: "Start time is required.",
    },
    endTime: {
      name: "endTime",
      label: "End Time",
      type: "time",
      errorMsg: "End time is required.",
    },


    count: {
      name: "count",
      label: "Average Count Per Slot",
      type: "text",
      errorMsg: "Count is required.",
    },



    twitter: {
      name: "twitter",
      label: "Twitter Handle",
      type: "text",
      errorMsg: "Twitter profile is required.",
    },
    facebook: {
      name: "facebook",
      label: "Facebook Account",
      type: "text",
    },
    instagram: {
      name: "instagram",
      label: "Instagram Account",
      type: "text",
    },
    publicEmail: {
      name: "publicEmail",
      label: "Public Email",
      type: "email",
    },
    bio: {
      name: "bio",
      label: "Bio",
    },
  },
};

export default form;
