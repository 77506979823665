import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance";
import { BarLoader, MoonLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";

import { FormControlLabel, Grid, Switch } from "@mui/material";
import swal from "sweetalert";
import { Form, Formik, useFormik } from "formik";
import * as Yup from "yup";
import FormField from "../components/FormField";
import MDButton from "components/MDButton";

function BankDetail() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [createloading, setCreateLoading] = useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [selectedValue, setSelectedValue] = React.useState("a");
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = useState(false);
  const [bankName, setBankName] = useState();
  const [response, setResponse] = useState([]);
  const [ifscCode, setIfscCode] = useState();
  const [holderName, setHolderName] = useState();
  const [accNumber, setAccNumber] = useState();
  const [profile, setProfile] = useState(
    JSON.parse(localStorage.getItem("login-auth"))
  );
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const initialValues = {
    bankName: "",
    ifscCode: "",
    holderName: "",
    accNumber: "",
  };

  const validationSchema = Yup.object({
    bankName: Yup.string().required("Bank Name is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
    holderName: Yup.string().required("Holder Name is required"),
    accNumber: Yup.string().required("Account Number is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: addData,
  });

  function radioCheck(e) {
    console.log("57", e.target.checked);
    // setChecked(e.target.checked)
    if (e.target.checked == true) {
      console.log("PRIMARY");
      // setChecked(true)
    } else {
      console.log("OPEN");
      // setChecked(true)
    }

    // console.log("58", checked);
    // setSelectedValue(event.target.value);
    //  alert('hellow')
  }
  function update(object) {
    axios
      .put(
        `/bankaccounts1-service/api/Bankaccounts1/v1/update`,
        // `http://localhost:6001/api/Bankaccounts1/v1/update`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        getData(profile?.profileid);
      })
      .catch((e) => {
        console.log("error", e);
        getData(profile?.profileid);
      });
  }

  const toggler = (checked, request) => {
    console.log("toggle 86", checked);
    delete request.action;
    if (checked) {
      request.recstatus = "PRIMARY";
    } else {
      request.recstatus = "OPEN";
    }
    update(request);
  };

  function onDelete(object) {
    object.status = "INACTIVE";
    swal({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this Bank Detail.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        update(object);
      }
    });
  }

  function getData(profileid) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: " bank name", accessor: "bankname" },
        { Header: "ifsc code", accessor: "ifsc" },
        { Header: "Acc holder name", accessor: "holdername" },
        { Header: "Account number", accessor: "accountno" },
        { Header: "Set as primary", accessor: "action" },
        { Header: "delete", accessor: "delete" },
      ],
      rows: [],
    };
    axios
      .get(
        `/bankaccounts1-service/api/Bankaccounts1/v1/get?profileid=${profileid}`
        // `http://localhost:6001/api/Bankaccounts1/v1/get?profileid=${profileid}`,
      )
      .then((response) => {
        setResponse(response.data.data.dtoList);
        response.data.data.dtoList.map((item) => {
          if (item.recstatus == "PRIMARY") {
            // setChecked(true);
            item.action = (
              <FormControlLabel
                name={item.accountno}
                control={
                  <Switch
                    onClick={(e) => toggler(e.target.checked, item)}
                    defaultChecked
                  />
                }
                label=""
              />
            );
          }
          if (item.recstatus == "OPEN") {
            // setChecked(true);
            item.action = (
              <span>
                <FormControlLabel
                  name={item.accountno}
                  control={
                    <Switch onClick={(e) => toggler(e.target.checked, item)} />
                  }
                  label=""
                />
              </span>
            );
          }

          item.delete = (
            <Link to={{}}>
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  onDelete(item);
                }}
              />
            </Link>
          );
          // item.createddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function create(object) {
    axios
      .post(
        `/bankaccounts1-service/api/Bankaccounts1/v1/create`,
        // `http://localhost:6001/api/Bankaccounts1/v1/create`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        setOpen(false);
        getData(profile?.profileid);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function addData(values) {
    setCreateLoading(true);
    const dataObj = {
      bankname: values.bankName,
      ifsc: values.ifscCode,
      accountno: values.accNumber,
      holdername: values.holderName,
      profileid: profile?.profileid,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
    };
    console.log(dataObj);
    create(dataObj);
    // getData(profile?.profileid)
    setCreateLoading(false);
    
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  useEffect(() => {
    getData(profile?.profileid);
  }, []);
  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <MDBox pt={3}>
        <MDBox>
          <Card style={{marginTop:'-4%'}}>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Bank Details
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={""}
                >
                  {response.length >= 3 ? (
                    <MdOutlineLibraryAdd
                      onClick={() => {
                        swal("You are exceeding the limit of Bank Details..");
                      }}
                    />
                  ) : (
                    <MdOutlineLibraryAdd
                      onClick={() => {
                        setOpen(true);
                      }}
                    />
                  )}
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={addData}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={0}
                        p={2}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          Add Bank Detail
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={0}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="bankName"
                            label=<span>
                              Bank Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="bankName"
                            value={values.bankName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="ifscCode"
                            label=<span>
                              IFSC Code
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="ifscCode"
                            value={values.ifscCode}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="holderName"
                            label=<span>
                              Account Holder Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="holderName"
                            value={values.holderName}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="accNumber"
                            label=<span>
                              Account Holder Number
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="accNumber"
                            value={values.accNumber}
                          />
                        </Grid>
                      </Grid>
                      <MDBox
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="10px"
                      >
                        <MDButton color="success" onClick={handleClose}>
                          Cancel
                        </MDButton>
                        &nbsp;
                        <MDButton color="info" type="submit">
                          {createloading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Add"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default BankDetail;
