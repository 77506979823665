
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create role

export const createDisease = createAsyncThunk("createDisease", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.post(
      `disease-service/api/Disease/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getDisease = createAsyncThunk("getDisease", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.get(
      `disease-service/api/Disease/v1/get`,
      
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateDisease = createAsyncThunk("updateDisease", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.put(
      `disease-service/api/Disease/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteDisease = createAsyncThunk("deleteDisease", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `disease-service/api/Disease/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




