import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';
import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
// import Pagination from '@mui/material/Pagination';
import "./Skeleton.css"; // Make sure to use the custom CSS styles if needed

const SkeletonTableCmp = ({ columns, perPage }) => {

  // Generate a skeleton row with the same number of cells as the columns array
  const renderSkeletonRow = (numColumns) => (
    <TableRow>
      {[...Array(numColumns)]?.map((_, index) => (
        <TableCell key={index} align="center">
          <Skeleton variant="rectangular" height={40} classes={{ root: 'skeleton' }} />
        </TableCell>
      ))}
    </TableRow>
  );

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ width: "100%", height: "57vh" }}>
        <Table aria-label="skeleton table">
          <TableHead sx={{ display: "contents" }}>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column?.id} align="center" style={{ minWidth: 145 }}>
                  <Skeleton variant="text" width="80%" classes={{ root: 'skeleton' }} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {columns?.map((_, index) => (
              <React.Fragment key={index}>
                {renderSkeletonRow(columns?.length)}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ padding: '1%', gap: 2 }}>
        <FormControl variant="outlined" sx={{ width: "9%" }}>
          <InputLabel id={"per-page-label"}>Rows per page</InputLabel>
          <Select
            labelId="per-page-label"
            style={{ height: "35px" }}
            id="perpage-select"
            name="per-page"
            value={perPage}
            label="Rows per page"
          >
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
          </Select>
        </FormControl>
        {/* <Pagination count={10} color="primary" /> */}
      </Stack>
    </Paper>
  );
}

export default SkeletonTableCmp;


