import { Grid } from "@mui/material";
import React from "react";
import "./medicinesButton.css";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BrowseMedicines from "../../findDoctor/assets/images/browsemedicines.png";
import { useNavigate } from "react-router-dom";

const MedicinesButton = () => {
  const navigate = useNavigate();
  return (
    <div className="medicinebuttonroot">
      <Grid container pt={7} xs={12} className="medicinebuttoncontainer">
        <button className="medicinebutton" onClick={() => navigate("/app/pages/users/upload-prescription")}>
          <Grid item>
            <ArrowDropUpIcon fontSize="large" className="medicinebutton1icon" />
          </Grid>
          <Grid item className="medicinebutton1text">
            Upload your prescription
          </Grid>
        </button>
        <button className="medicinebutton2" onClick={() => navigate("/app/pages/users/medicine-category")}>
          <Grid item>
            <img src={BrowseMedicines} alt="med image" className="medicinebutton2icon" />
          </Grid>
          <Grid item className="medicinebutton1text">
            Browse Medicines
          </Grid>
        </button>
      </Grid>
    </div>
  );
};

export default MedicinesButton;
