import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const FrequencyEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [value, setValue] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [vitalList, setVitalList] = useState([]);
  const [uomList, setUomList] = useState([]);

  const validationSchema = Yup.object({
    countperday: Yup.string().required("Count per day is required."),
    frequencyDescription: Yup.string().required(
      "Frequency description is required."
    ),
  });

  const initialValues = {
    countperday: selectedUser?.countperday || "",

    frequencyDescription: selectedUser?.description || "",
  };

  const onSubmit = async (values) => {
    setLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,
        countperday: values.countperday.trim(),
        description: values.frequencyDescription.trim(),
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.frequencymasterServiceApi}/update`, updatedUser);
      handleClose();
      handleEditreset();
      swal("Success!", "Frequency updated successfully.", "success");
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoading1(false);
    }
  };

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, errors, touched }) => (
              <Form>
                <MDBox mt={1.625}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        label=<span>
                          Frequency Description
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        fullWidth
                        name="frequencyDescription"
                        value={values.frequencyDescription.trim()}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <ErrorMessage
                          name="frequencyDescription"
                          component="div"
                          style={{
                            color: "red",
                            fontSize: "12px",
                            textAlign: "left",
                            marginTop: "4px",
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        label=<span>
                          Count Per Day
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        fullWidth
                        name="countperday"
                        value={values.countperday.trim()}
                        onChange={handleChange}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <ErrorMessage
                          name="countperday"
                          component="div"
                          style={{
                            color: "red",
                            fontSize: "12px",
                            textAlign: "left",
                            marginTop: "4px",
                          }}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </MDBox>
                <DialogActions>
                  <MDButton onClick={handleClose} color="light" size="small">
                    Cancel
                  </MDButton>

                  <MDButton type="submit" color="info" size="small">
                    {loading1 ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Update"
                    )}
                  </MDButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default FrequencyEdit;
