
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create User

export const createUser = createAsyncThunk("createUser", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `login-service-mongo/api/loginservice/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getUser = createAsyncThunk("getUser", async (data, {rejectWithValue}) => {
  console.log("formData", data);
  
  try {
    const response = await axios.get(
      `login-service-mongo/api/loginservice/v2/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateUser = createAsyncThunk("updateUser", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `profile-service-mongo/api/ProfileEntity/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteUser = createAsyncThunk("deleteUser", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `profile-service-mongo/api/ProfileEntity/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




