
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Grid,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { updateRole } from "../../../Admin/AdminWorkspace/Role Master/service/Role"; // Adjust the import path
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getUser } from "components/Resuable/UserMaster/service/User";
import { micappCode } from "static/micappCode";
import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance"
import { getRoleAction } from "redux/Actions/roleAction";
import { tableDataAction } from "redux/Actions/tableDataAction";


const UserEdit = ({ openpopup, setopenpopup, title, selectedRole ,handleClose, updateTableDataAction}) => {
 
  const dispatch = useDispatch();
  // const [roleList, setRoleList] = useState([]);
  const { getRoles } = useSelector((state) => state);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  // const [designation, setDesignation] = useState([]);

  const [userList, setUserList] = useState([]);

  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer); 


  const validationSchema = Yup.object({
    firstname: Yup.string().required("First Name is required"),
    // lastname: Yup.string().required("Last Name is required"),
    username: Yup.string().required("Username is required"),
    selectrole: Yup.string().required("Role is required"),
    selectdep: Yup.string().required("Department is required"),
    selectdesi: Yup.string().required("Designation is required"),
    phoneno: Yup.string().required("Phone number is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    gender: Yup.string().required("Gender is required"),
  });


  async function getDesignationData(code) {
    try {
      const response = await axios.get(
        // `${APIs.designationServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
             `${APIs.designationServiceApi}/get`
      );
       setDesignation(response?.data?.data?.dtoList)
      const result = response?.data?.data?.dtoList.filter(
        (designationItem) => designationItem.deptcode === code
      );
      if (result?.length > 0) {
        setDesignationList(result);
      } else {
        formik.setFieldValue("selectdesi", "");
        setDesignationList([]);
      }
    } catch (error) {
      console.log("error", error);
    }



  }
  useEffect(() => {
    if (selectedRole) {
      getDesignationData(selectedRole.departmentcode)
      formik.setValues({
        firstname: selectedRole.firstname || "",
        lastname: selectedRole.lastname || "",
        username: selectedRole.username || "",
        selectrole: selectedRole.rolecode || "",
        selectdep: selectedRole.departmentcode || "",
        selectdesi: selectedRole.designationcode || "",
        phoneno: selectedRole.mobileno || "",
        email: selectedRole.emailid || "",
        gender: selectedRole.sex || "",
      });
    }
  }, [selectedRole]);



  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      username: "",
      selectrole: "",
      selectdep: "",
      selectdesi: "",
      phoneno: "",
      email: "",
      gender: "",
    },
    validationSchema,
    onSubmit: async (values) => {
     setIsLoading(true)
      try {
        const data = {
          ...selectedRole,
          departmentcode: values.department ? values.department.code : selectedRole.departmentcode,
          departmentname: values.department ? values.department.name : selectedRole.departmentname,
          designationcode: values.designation ? values.designation.code : selectedRole.designationcode,
          designationname: values.designation ? values.designation.name : selectedRole.designationname,
          emailid: values.email,
          firstname: values.firstname,
          lastname: values.lastname,
          sex: values.gender,
          mobileno: values.phoneno,
          rolecode: values.selectrole,
          rolename: values.rolename ? values.rolename : selectedRole.rolename,
          username: values.username,
          modifiedby: auth.profileid,

          modifiedbyname: auth.name,

        };
        
        await axios.put(
          `${APIs.loginServiceApi}/update`,
          data
        );
        
        setIsLoading(false)
        handleClose();
        updateTableDataAction();
        

      } catch (error) {
        swal("Oops!", error?.response?.data?.aceErrors[0]?.errorMessage || "Something wents wrong.", "error");
        setIsLoading(false)      }
     
    },
  });


  const getData = async () => {

    try {
      const response = await axios.get(
        // `${APIs.deparmentServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
           `${APIs.deparmentServiceApi}/get`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }


  }
  useEffect(async () => {

    getData();
    dispatch(getRoleAction())
  }, []);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle style={{ backgroundColor: "#039147", color: "#fff", textAlign: "center" }}>
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
        {loading? ( 
        <CircularProgress style={{ position: 'absolute', top: '50%', left: '50%' }} />
      ) :(
          <Grid container spacing={3} mt={1}>
            <Grid item xs={12} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span> First Name <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  variant="standard"
                  fullWidth
                  name="firstname"
                  value={formik.values.firstname}
                  onChange={formik.handleChange}
                />
              
                {formik.errors.firstname && formik.touched.firstname && (
                  <FormHelperText error>{formik.errors.firstname}</FormHelperText>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span> Last Name </span>
                  variant="standard"
                  fullWidth
                  name="lastname"
                  value={formik.values.lastname}
                  onChange={formik.handleChange}
                />
                {formik.errors.lastname && formik.touched.lastname && (
                  <FormHelperText error>{formik.errors.lastname}</FormHelperText>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span> Username <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  variant="standard"
                  fullWidth
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                />
                {formik.errors.username && formik.touched.username && (
                  <FormHelperText error>{formik.errors.username}</FormHelperText>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="selectrole"
                 shrink={Boolean(formik.values.selectrole)}
                 style={{ marginLeft: "-14px", paddingBottom: "2px", marginTop: "2px" }} 
                ><span>Role <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span></InputLabel>
                <Select
                  native
                  variant="standard"
                  name="selectrole"
                  value={formik.values.selectrole}

                  onChange={(e) => {
                    formik.handleChange(e)
                    const rolename = getRoles?.list?.find(role => role.rolecode === e.target.value);
                    formik.setFieldValue('rolename', rolename?.name);
                  }}
                >
                  <option value=""></option>
                  {getRoles?.list?.map((role) => (
                    <option key={role.id} value={role.rolecode} >
                      {role.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.selectrole && formik.touched.selectrole && (
                  <FormHelperText error sx={{marginLeft: "0px"}}>{formik.errors.selectrole}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="selectdep"
                 shrink={Boolean(formik.values.selectdep)}
                 style={{ marginLeft: "-14px", paddingBottom: "2px", marginTop: "2px" }} 
                >
            
                <span>Department <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span></InputLabel>
                <Select
                  native
                  variant="standard"
                  name="selectdep"
                  value={formik.values.selectdep}
                  shrink={Boolean(formik.values.selectdep)}
                  // onChange={(e) => {
                  //   formik.handleChange(e);
                  //   // Set Designation List based on Department
                  //   const departmentCode = e.target.value;
                  //    getDesignationData(e.target.value);
                  // }}
                  onChange={(e) => {
                    formik.handleChange(e);
                    const departmentCode = e.target.value;
                    getDesignationData(e.target.value);
                    const selectedDepartment =
                      department.find((obj) => obj.code === departmentCode) ||
                      {};
                    formik.setFieldValue("department", selectedDepartment)


                  }}
                >
                  <option value=""></option>
                  {department?.map((dep) => (
                    <option key={dep.id} value={dep.code}>
                      {dep.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.selectdep && formik.touched.selectdep && (
                  <FormHelperText error sx={{marginLeft: "0px"}}>{formik.errors.selectdep}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel htmlFor="selectdesi" shrink={Boolean(formik.values.selectdesi)}
                 style={{ marginLeft: "-14px", paddingBottom: "2px", marginTop: "2px" }} 
                ><span>Designation <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span></InputLabel>
                <Select
                  native
                  variant="standard"
                  name="selectdesi"
                  value={formik.values.selectdesi}

                  // onChange={formik.handleChange}
                  onChange={(e) => {
                    formik.handleChange(e)
                    const designationvalue = e.target.value;

                    const designationobj =
                      designation.find(
                        (obj) => obj.code === designationvalue
                      ) || {};

                    formik.setFieldValue("designation", designationobj)
                  }}
                >
                  <option value=""></option>
                  {designationList?.map((desig) => (
                    <option key={desig.id} value={desig.code}>
                      {desig.name}
                    </option>
                  ))}
                </Select>
                {formik.errors.selectdesi && formik.touched.selectdesi && (
                  <FormHelperText error sx={{marginLeft: "0px"}}>{formik.errors.selectdesi}</FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
             
              >
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(formik.values.gender)}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={formik.values.gender}
                  onChange={(e) => {
                    

                    formik.setFieldValue("gender", e.target.value);
                  
                  }}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  
                </Select>
                {formik.errors.gender && formik.touched.gender && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {formik.errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="text"
                  label=<span>Phone No <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  variant="standard"
                  fullWidth
                  name="phoneno"
                  value={formik.values.phoneno}
                  onChange={formik.handleChange}
                />
                {formik.errors.phoneno && formik.touched.phoneno && (
                  <FormHelperText error>{formik.errors.phoneno}</FormHelperText>
                )}
              </MDBox>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={2}>
                <MDInput
                  type="email"
                  label=<span> Email <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  variant="standard"
                  fullWidth
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
                {formik.errors.email && formik.touched.email && (
                  <FormHelperText error>{formik.errors.email}</FormHelperText>
                )}
              </MDBox>
            </Grid>
          </Grid>
              )}
        </DialogContent>
        <DialogActions>
          <MDButton
           onClick={handleClose}
            color="light"
             size="small"
           
           >Cancel</MDButton>


          <MDButton
            type="submit" color="info" size="small"
          >
            {loading ? (
              <MoonLoader color="#f2fefa" size={16} />
            ) : (
              "Submit"
            )}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default UserEdit;
