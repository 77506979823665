import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../../axiosinstance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function QualificationForm() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [idCounter, setIdCounter] = useState(0);
  const currentDate = new Date();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();

  const initialValues = {
    qualificationmode: "",
    qualificationgrade: "",
    qualificationname: "",
    qualificationboard: "",
    qualificationgradetype: "",
    startdate: currentDate.toISOString().split("T")[0],
    enddate: currentDate.toISOString().split("T")[0],
  };

  const validationSchema = Yup.object({
    qualificationmode: Yup.string().required("Qualification mode is required."),
    qualificationgrade: Yup.string().required("Grade is required."),
    qualificationboard: Yup.string().required("Board is required."),
    qualificationname: Yup.string().required("Qualification name is required."),
    qualificationgradetype: Yup.string().required("Type is required."),
    startdate: Yup.date().required("Start date is required."),
    enddate: Yup.date()
      .required("End date is required.")
      .test(
        "is-not-before-start",
        "End date cannot be before start date.",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isSameOrBefore = start <= end;
          return isSameOrBefore;
        }
      )
      .test(
        "is-not-same-as-start",
        "End date cannot be the same as start date.",
        function (enddate) {
          if (!enddate || !this.parent.startdate) {
            return true; // Return true if either date is empty (you can adjust this as needed)
          }
          const start = new Date(this.parent.startdate);
          const end = new Date(enddate);
          const isNotSameDate = start.getTime() !== end.getTime();
          return isNotSameDate;
        }
      ),
  });

  const handleSubmit = (values, { resetForm }) => {
    const newData = {
      uid: idCounter,
      ...values,
      id: null,

      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "synced",

      profileid:
        auth.rolecode === "AD001"
          ? location?.state?.profileid
          : auth?.profileid,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    resetForm();
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "qualification name", accessor: "qualificationname" },
    { Header: "qualification mode", accessor: "qualificationmode" },
    { Header: "board", accessor: "qualificationboard" },
    { Header: "grade", accessor: "qualificationgrade" },
    { Header: "start date", accessor: "startdate" },
    { Header: "end date", accessor: "enddate" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        // <button onClick={() => removeData(row.values.id)}>Remove</button>
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
      width: "12%",
    },
  ];
  async function finasubmit() {
    setLoading(true);
    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setLoading(false);
    } else {
      for (let i = 0; i < data.length; i++) {
        delete data[i].uid;
        console.log("After delete:", data[i]);
      }
      await axios
        .post(
          "qualification-service-mongo/api/qualification/v2/createList",
          data
        )

        .then((response) => {
          setData([]);
          swal(
            "Good job!",
            "Qualification has been added successfully.",
            "success"
          );
          navigate(`/app/${auth.rolename?.toLowerCase()}/profile`);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setLoading(false);
        });
    }
  }

  return (
    <DashboardLayout>
      {/* {auth?.rolecode === "DO001"?    <UpaarNavbar/>:<></>} */}
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: auth?.rolecode === "DO001" ? 18 : 3 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          Qualification Information
                        </MDTypography>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="white"
                          my={1}
                        >
                          Enter your detail
                        </MDTypography>
                      </MDBox>
                      {auth.rolecode === "AD001" ? (
                        <MDTypography variant="h5">
                          Selected Doctor: {location?.state?.name}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                    <MDBox p={3}>
                      <Grid container spacing={3} mt={1}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Qualification Name
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="qualificationname"
                              variant="standard"
                              value={values.qualificationname}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("qualificationname", nameValue);
                              }}
                            />

                            <ErrorMessage
                              name="qualificationname"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="qualificationmode"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Mode{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="qualificationmode"
                              id="qualificationmode"
                              value={values.qualificationmode}
                              onChange={(e) => {
                                const selectedMode = e.target.value;
                                setFieldValue(
                                  "qualificationmode",
                                  selectedMode
                                );
                              }}
                              error={
                                touched.qualificationmode &&
                                Boolean(errors.qualificationmode)
                              }
                            >
                              <option value=""></option>

                              <option value="Regular">Regular</option>
                              <option value="Open">Open</option>
                            </Select>
                            <ErrorMessage
                              name="qualificationmode"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Board
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="qualificationboard"
                              variant="standard"
                              value={values.qualificationboard}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("qualificationboard", nameValue);
                              }}
                            />

                            <ErrorMessage
                              name="qualificationboard"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="mode"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                            >
                              Type{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="qualificationgradetype"
                              id="qualificationgradetype"
                              value={values.qualificationgradetype}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue(
                                  "qualificationgradetype",
                                  selectedType
                                );
                              }}
                              error={
                                touched.qualificationgradetype &&
                                Boolean(errors.qualificationgradetype)
                              }
                            >
                              <option value=""></option>

                              <option value="Percentage">Percentage</option>
                              <option value="CGP">CGP</option>
                            </Select>
                            <ErrorMessage
                              name="qualificationgradetype"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Grade
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="qualificationgrade"
                              variant="standard"
                              value={values.qualificationgrade}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("qualificationgrade", nameValue);
                              }}
                            />

                            <ErrorMessage
                              name="qualificationgrade"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Start Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="startdate"
                              variant="standard"
                              value={values.startdate}
                              onChange={(e) => {
                                const startdateValue = e.target.value;
                                setFieldValue("startdate", startdateValue);
                              }}
                              error={
                                touched.startdate && Boolean(errors.startdate)
                              }
                            />
                            <ErrorMessage
                              name="startdate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                End Date
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="date"
                              name="enddate"
                              variant="standard"
                              value={values.enddate}
                              onChange={(e) => {
                                const enddateValue = e.target.value;
                                setFieldValue("enddate", enddateValue);
                              }}
                              error={touched.endDate && Boolean(errors.enddate)}
                            />
                            <ErrorMessage
                              name="enddate"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            size="small"
                          >
                            Add
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                      />
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      marginRight="10px"
                      marginBottom="8px"
                    >
                      <MDButton
                        variant="gradient"
                        color="dark"
                        onClick={() => finasubmit()}
                      >
                        {loading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "submit"
                        )}
                      </MDButton>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default QualificationForm;
