import React, { useState, useCallback, useEffect } from "react";
import { Grid, Card, useMediaQuery } from "@mui/material";
import "./style2.css";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import image1 from "../../findDoctor/assets/images/profile098.jpg";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { useDispatch } from "react-redux";
import { labAddCartAction } from "redux/Actions/labAddCart";
import { APIs } from "Services/APIs";

const SelectPerson = () => {
  const smallScreen = useMediaQuery("(max-width:600px)");
  const msmallScreen = useMediaQuery("(max-width:1050px)");
  const navigate = useNavigate();
  const [familydata, setFamilydata] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);
  const { getAddLabCart } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [selectedPersons, setSelectedPersons] = useState(null);
  // useEffect(() => {
  //   const savedSelection = JSON.parse(localStorage.getItem("selectedPerson"));
  //   if (savedSelection !== null) {
  //     setSelectedPersons(savedSelection);
  //   }
  // }, []);
  const handleSelectPerson = (item) => {
    // const updatedSelection = index;
    // setSelectedPersons(updatedSelection);
    // Save selection to local storage
    dispatch(labAddCartAction({ ...getAddLabCart, selectedPerson:item }))
    // localStorage.setItem("selectedPerson", JSON.stringify(updatedSelection));
  };
  // const handleResetSelection = () => {
  //   setSelectedPersons(null);
  //   dispatch(labAddCartAction({ ...getAddLabCart, selectedPersons: null }))
  //   localStorage.removeItem("selectedPersons");
  // };
  // const handleSelectPerson = (index) => {
  //   if (selectedPersons.includes(index)) {
  //     setSelectedPersons(selectedPersons.filter((item) => item !== index));
  //   } else {
  //     setSelectedPersons([...selectedPersons, index]);
  //   }
  // };

  // const handleResetSelection = () => {
  //   setSelectedPersons([]);
  // };
  // const handleFormSubmit = useCallback(() => {
  //   if (selectedPersons.length === 0) {
  //     swal("Oops...!", "Please select at least one person.!", "error");

  //     return;
  //   }
  //   handleResetSelection();
  //   navigate(`/app/${auth.rolename}/selected-addres`, {
  //     state: {
  //       item: location?.state?.item,
  //       pharmacy: location?.state?.pharmacy,
  //       family: selectedPersons.map((index) => familydata[index]),
  //     },
  //   });
  // }, [selectedPersons]);
  const handleFormSubmit=()=>{
    if (getAddLabCart?.selectedPerson && getAddLabCart?.selectedPerson?.id) {
     
      navigate(`/app/${auth?.rolename?.toLowerCase()}/selected-address`)

    }
    else{
      swal("Oops...!", "Please select at least one person.!", "error");
    }

  }

  async function getFamily(profileid) {
    await axios
      .get(`${APIs.loginServiceApi}/get?createdby=${profileid}`)
      .then((response) => {
        const newData = [...response.data?.data?.dtoList, auth];
 
        setFamilydata(newData);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getFamily(auth.profileid);
  }, []);

  return (
    <>
 
      <div className="personclass">
        <Grid container spacing={2} mt={23} className="select12">
          <Grid item xs={12} md={12} ml={3}>
            <h2 className="selecth22">
              Who is this test for? <br />
              <span>Select one member below</span>
            </h2>
          </Grid>
          {familydata.map((person, index) => (
            <Card
              key={index}
              className="selectcard1"
              sx={{ borderRadius: "8px" }}
            >
              <Grid container spacing={2}>
                <Grid container item xs={12} className="grid11">
                  <Grid
                    item
                    xs={smallScreen ? 1.5 : 1}
                    mt={2}
                    className="grid11"
                  >
                    <img
                      className="profileimg22"
                      alt="image logo"
                      src={image1}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={smallScreen ? 7 : 4}
                    ml={smallScreen ? -1 : msmallScreen ? -2 : -3}
                    className="grid11"
                  >
                    <h2 className="selectheadinh3">
                      {" "}
                      {auth.name == person.name ? "Self" : person.name}
                      <br />
                      <span>{person.age} old</span>{" "}
                    </h2>
                  </Grid>
                  <Grid
                    item
                    xs={smallScreen ? 3.5 : 7}
                    mt={smallScreen ? 1 : 0.5}
                    className="grid12"
                  >
                    <FormControl>
                      <Radio
                        checked={person.id === getAddLabCart?.selectedPerson?.id}
                        onChange={() => handleSelectPerson(person)}
                        // onClick={handleResetSelection}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            width: { xs: 26, sm: 36, md: 39, lg: 39, xl: 39 },
                            height: { xs: 26, sm: 36, md: 39, lg: 39, xl: 39 },
                            color: "green",
                          },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container item xs={12} className="grid14">
                  {/* <Grid item xs={smallScreen ? 4 : 2} md={2} className="grid13">
                  <Button
                    variant="text"
                    className="button00"
                    sx={{
                      width: { xs: 90, sm: 100, md: 120, lg: 142, xl: 142 },
                      // height: "36px",
                      color: "#000000",
                      display: "flex",
                      border: "2px solid #FFCDA8",
                      backgroundColor: "#FFCDA8",
                      textTransform: "none",
                      fontFamily: "Poppins",
                      borderRadius: "100px",
                      "&:hover": {
                        backgroundColor: "#FFCDA8",
                        color: "#000000",
                      },
                    }}
                  >
                    {person.weight}
                  </Button>
                </Grid>
                <Grid item xs={smallScreen ? 4 : 2} className="grid15">
                  <Button
                    variant="text"
                    className="button00"
                    sx={{
                      width: { xs: 90, sm: 100, md: 120, lg: 142, xl: 142 },
                      // height: "36px",
                      color: "#000000",
                      border: "2px solid #B2FFFA",
                      backgroundColor: "#B2FFFA",
                      fontFamily: "Poppins",

                      textTransform: "none",
                      borderRadius: "100px",
                      "&:hover": {
                        backgroundColor: "#B2FFFA",
                        color: "#000000",
                      },
                    }}
                  >
                    {person.hight}
                  </Button>
                </Grid> */}
                  <Grid item xs={smallScreen ? 4 : 2} className="grid15">
                    <Button
                      variant="text"
                      className="button00"
                      sx={{
                        width: { xs: 90, sm: 100, md: 120, lg: 142, xl: 142 },
                        // height: "36px",
                        color: "#000000",
                        border: "2px solid #E9BBFF",
                        backgroundColor: "#E9BBFF",
                        fontFamily: "Poppins",

                        textTransform: "none",
                        borderRadius: "100px",
                        "&:hover": {
                          backgroundColor: "#E9BBFF",
                          color: "#000000",
                        },
                      }}
                    >
                      {person.sex}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {/* )} */}
            </Card>
          ))}
          <Grid container item xs={12} mb={isMidScreen ? 10 : undefined}>
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() => navigate(`/app/${auth?.rolename?.toLowerCase()}/select-lab`)}
                >
                  Previous
                </button>
              </div>
              <div>
                <button className="next" onClick={handleFormSubmit}>
                  Next
                </button>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
 
    </>
  );
};
export default SelectPerson;
