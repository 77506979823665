
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../../axiosinstance";
//create project

export const createProjectTeam = createAsyncThunk("createProjectTeam", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.post(
      `project-service/api/ProjectTeam/v1/createlist`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getProjectTeam = createAsyncThunk("getProjectTeam", async (data, {rejectWithValue}) => {

  try {
    if (data === undefined){
    const response = await axios.get(
      `project-service/api/ProjectTeam/v1/get`,
     
    );
    return await response.data;
    }
    else{
      const response = await axios.get(
        `project-service/api/ProjectTeam/v1/get?projectcode=${data}`,
       
      );
      return await response.data;
    }
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateProjectTeam = createAsyncThunk("updateProjectTeam", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `project-service/api/ProjectTeam/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteProjectTeam = createAsyncThunk("deleteProjectTeam", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `project-service/api/ProjectTeam/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




