import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import validations from "components/LABTEAMASSOCIATION/schemas/validations";
import form from "components/LABTEAMASSOCIATION/schemas/form";
import initialValues from "components/LABTEAMASSOCIATION/schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import axios from "../../../../axiosinstance";
import { labappCode } from "static/labappCode";
import { APIs } from "Services/APIs";
import AssociationTeamInfo from "components/LABTEAMASSOCIATION/components/AssociationTeamInfo";

function getSteps() {
  return ["Association Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <AssociationTeamInfo formData={formData} />;
    default:
      return null;
  }
}

export default function CreateTeamassociationPopup(props) {
  const { openassociation, setOpenassociation, passData } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.role);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const submitForm = async (values, actions) => {
    // setIsLoading(true);

    // const requestData = {
    //   mmucode: values?.mmuname?.code,
    //   mmuname: values?.mmuname?.name,
    //   createdby: auth?.profileid,
    //   createdbyname: auth?.name,
    //   profileid: values?.profilenameobject?.profileid,
    //   rolecode: values?.profilenameobject?.rolecode,
    //   rolename: values?.profilenameobject?.rolename,
    //   fullname: values?.profilenameobject?.name,
    //   firstname: values?.profilenameobject?.firstname,
    //   lastname: values?.profilenameobject?.lastname,
    //   mappingcode: values?.mmuname?.mappingcode,
    //   type: labappCode?.mappingcode,
    //   recstatus: "OPEN",
    //   status: "ACTIVE",
    //   syncstatus: "SYNCED",
    // };

    if (values?.teamList?.length === 0) {
      swal("Oops", "Please add data in the table first!", "error");
      return;
    } else {
      setIsLoading(true);
      let finalObjectlist = [];
      let data = values?.teamList;
      for (let i = 0; i < values?.teamList?.length; i++) {
        const requestData = {
          mmucode: data[i]?.mmu?.code,
          mmuname: data[i]?.mmuname,
          createdby: auth?.profileid,
          createdbyname: auth?.name,
          profileid: data[i]?.projectteams?.profileid,
          rolecode: data[i]?.projectteams?.rolecode,
          rolename: data[i]?.projectteams?.rolename,
          fullname: data[i]?.projectteams?.name,
          firstname: data[i]?.projectteams?.firstname,
          lastname: data[i]?.projectteams?.lastname,
          mappingcode: data[i]?.mmu?.mappingcode,
          type: labappCode?.mappingcode,
          recstatus: "OPEN",
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        finalObjectlist.push(requestData);
      }

      // console.log(requestData, "requestData 81");
      // console.log(values, "values 82");
      // console.log(values?.teamList, "values RK");
      // console.log(finalObjectlist, "values finalll");
      // return;

      const response = await axios.post(
        `${APIs?.mmuteamAssociation}/createlist`,
        finalObjectlist
      );
      if (!response?.error) {
        swal("Good job!", "Data has been created successfully!", {
          icon: "success",
        });
        actions.resetForm();

        setIsLoading(false);
        passData();
        handleClose();
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",
          text: error?.message,
        });
        setIsLoading(false);

        handleClose();
      }
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const handleClose = () => {
    setOpenassociation(false);
  };

  return (
    <Dialog
      open={openassociation}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Team Create Association
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
