import React, { useEffect, useState } from "react";
import { Grid, CircularProgress } from "@mui/material";
import "./appointment.css";
import LeftArrow from "../../../../../assets/images/leftarrow.svg";
import RightArrow from "../../../../../assets/images/rightarrow.svg";
import Sun from "../../../../../assets/images/sun 1.svg";
import Moon from "../../../../../assets/svg/moon.svg";
import Timing from "./timing";
import Sunset from "../../../../../assets/images/sunset 1.svg";
import axios from "../../../../../axiosinstance";
import moment from "moment-timezone";

const Appointment = ({ onGrandchildData, doctorprofileid, updateData }) => {
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());
  const [parentdate, setParentdate] = useState(dateFormat(new Date()));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000);
  const [slotlist, setSlotlist] = useState([]);
  const [timings, setTimings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTiming, setSelectedTiming] = useState(null);
 
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  async function getSlot(doctorprofileid, date) {
    setLoading(true);
    try {
      const response = await axios.get(
        `event-service/api/event/v1/get?profileid=${doctorprofileid}&&date=${date}`
      );
      if (response?.data?.data?.listSize === 0) {
        swal("Warning!", `Slot not available for this ${date}.`, "warning");
        setTimings([]);
      } else {
        response.data?.data?.dtoList.forEach((values) => {
          var date = values.startdate;
          var timeonly = date.substring(11, 19);
          var hms = timeonly; // your input string
          var a = hms.split(":"); // split it at the colons
          var correctTime = ("a", a[0] + ":" + a[1]);
  
          values.slottime = correctTime;
          values.averageusercount = values.averageusercount || 0;
          values.button = values.averageusercount * 1 === 0;
        });

      //   const formattedTimes = response.data?.data?.dtoList?.map((i) => {
      //       const formattedTime = formatTime(i.slottime);
      //       return { time: formattedTime, averageCount: i.averageusercount,timevalue:i };
      //   });
      //   setTimings(formattedTimes);
      //   console.log("formattedTimes",formattedTimes)
      //   setSlotlist(response.data?.data?.dtoList);
      // }
      const formattedTimes = response.data?.data?.dtoList?.map((i) => {
        const formattedTime = formatTime(i.slottime);
        const isDisabled = isPastTime(i.slottime, date);
        return { time: formattedTime, averageCount: i.averageusercount, timevalue: i, disabled: isDisabled };
      });
      setTimings(formattedTimes);
      setSlotlist(response.data?.data?.dtoList);
    }
    } catch (error) {
      console.log("error", error);
      swal("Warning!", "Error fetching data. Please try again.", "warning");
    } finally {
      setLoading(false);
    }
  }

  function formatTime(timeString) {
    return moment(timeString, "HH:mm").format("h:mm A");
  }
  function isPastTime(timeString, date) {
    const now = moment();
    const slotTime = moment(`${date} ${timeString}`, "YYYY/MM/DD HH:mm");
    return now.isAfter(slotTime);
  }

  const sendDataToParent = (e) => {
    updateData(e);
  };

  function dateFormat(date) {
    return moment(date).format("YYYY/MM/DD");
  }

  useEffect(() => {
    getSlot(doctorprofileid, dateFormat(new Date()));
    sendDataToParent(dateFormat(new Date()));
  }, []);

  const [currentMonth, setCurrentMonth] = useState(new Date());

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  const handlePreviousMonth = () => {
    const today = new Date();
    const newDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth() - 1,
      1
    );

    if (newDate < today) {
      setCurrentMonth(new Date(today.getFullYear(), today.getMonth(), 1));
    } else {
      setCurrentMonth(newDate);
    }
  };

  const generateDaysForMonth = (month) => {
    const today = new Date();
    const firstDay = new Date(month.getFullYear(), month.getMonth(), 1);
    const lastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    const daysInMonth = [];

    for (let day = firstDay; day <= lastDay; day.setDate(day.getDate() + 1)) {
      const dayName = day.toLocaleDateString("en-US", { weekday: "short" });
      const dayNumber = day.getDate();
      const isToday = isSameDay(day, today);
      const isPastDay = day < today;

      if (!isPastDay || isToday) {
        daysInMonth.push({ dayName, dayNumber, isToday, isPastDay });
      }
    }

    return daysInMonth;
  };

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const handleDayClick = (dayNumber) => {
    setSelectedDay(dayNumber);
    const newSelectedDate = new Date(
      currentMonth.getFullYear(),
      currentMonth.getMonth(),
      dayNumber
    );
    setSelectedDate(newSelectedDate);
    sendDataToParent(moment(newSelectedDate).format("YYYY/MM/DD"));
    getSlot(doctorprofileid, moment(newSelectedDate).format("YYYY/MM/DD"));
  };

  const nextDays = generateDaysForMonth(currentMonth);

  const monthYearString = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
  }).format(currentMonth);

  const amTimings = timings.filter((timing) => {
    return timing.time.includes("AM") ; 
  });
  
  const pmTimings = timings.filter((timing) => {
    return timing.time.includes("PM") && moment(timing.time, "h:mm A").hours() < 16 ; 
  });
  
  const eveningTimings = timings.filter((timing) => {
    return timing.time.includes("PM") && moment(timing.time, "h:mm A").hours() >= 16 ; 
  });
  
  return (
    <div>
      <Grid
        container
        xs={isSmallScreen ? 10.6 : 10}
        className="appointmentcontainer"
      >
        <Grid item>
          <div className="appointmentlabel">Book appointment</div>
        </Grid>
        <Grid item className="appointmentitem" mt={3} xs={12}>
          <button className="arrow" onClick={handlePreviousMonth}>
            <img src={LeftArrow} alt="LeftArrow Logo" className="imgsize1" />
          </button>
          <div className="monthYearString">{monthYearString}</div>
          <button className="arrow" onClick={handleNextMonth}>
            <img src={RightArrow} alt="RightArrow Logo" className="imgsize1" />
          </button>
        </Grid>
        <Grid item mt={1} xs={12} className="daysitem">
          <div className="days-container">
            {nextDays.map((day, index) => (
              <button
                className={`daysgrid ${day.isToday ? "today" : ""} ${
                  day.isPastDay ? "past-day" : ""
                } ${day.dayNumber === selectedDay ? "selected22" : ""}`}
                key={index}
                onClick={() => handleDayClick(day.dayNumber)}
              >
                <div className="datename">{day.dayName}</div>
                <div className="date">{day.dayNumber}</div>
              </button>
            ))}
          </div>
        </Grid>
        <Grid container mt={1} xs={12}>
          {loading ? (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <CircularProgress />
            </div>
          ) : (
            <div>
              <Grid container mt={1} xs={12}>
                <Timing
                  image={Sun}
                  timings={amTimings.length === 0 ? []: amTimings}
                 slotlist={slotlist}
                  label="Morning"
                  onGrandchildData={onGrandchildData}
                  selectedTiming={selectedTiming}
                  setSelectedTiming={setSelectedTiming}
                
              
                />
                
              </Grid>
              <Grid container xs={12}>
                <Timing
                  image={Sunset}
                  timings={pmTimings.length === 0 ? []: pmTimings}
                   slotlist={slotlist}
                  label="Afternoon"
                  onGrandchildData={onGrandchildData}
                  selectedTiming={selectedTiming}
  setSelectedTiming={setSelectedTiming}
              
                />
                 
              </Grid>
              <Grid container xs={12}>
                <Timing
                  image={Moon}
                  timings={eveningTimings.length === 0 ? []: eveningTimings}
                  slotlist={slotlist}
                  label="Evening"
                  onGrandchildData={onGrandchildData}
                  selectedTiming={selectedTiming}
                  setSelectedTiming={setSelectedTiming}
                />
              
              </Grid>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default Appointment;
