import axios from "../../axiosinstance"

export const logIn = (data) => async (dispatch) => {
    dispatch({ type: "LOGIN_PENDING", payload: "" })
    try {

        const resp = await axios.post('login-service-mongo/api/loginservice/v2/login', data)

        dispatch({ type: "LOGIN_FULFILLED", payload: resp.data })
        getpatientaddress();
        getInvestmentprofile()
      
       
    } catch (error) {
        dispatch({ type: "LOGIN_REJECTED", payload: error.response?.data })
    }
   
    async function getInvestmentprofile() {
        var profileId = JSON.parse(localStorage.getItem("login-auth"))?.profileid;

        await axios
            .get(
                 `investmentprofile-service/api/investmentprofile/v1/get?profileid=${profileId}`
              //  `http://localhost:6012/api/investmentprofile/v1/get?profileid=${profileId}`
            )
            .then((response) => {
             
                if (response.data.data.dtoList.length > 0) {
                    localStorage.setItem(
                        "investment-profile",
                        JSON.stringify(response?.data?.data?.dtoList[0])
                    );
                }

            })
            .catch((e) => {
                console.log("error", e);
            });



    }
    async function getpatientaddress() {
        var profileId = JSON.parse(localStorage.getItem("login-auth"))?.profileid;
    
        await axios
            .get(
                `address-service-mongo/api/address/v2/get?profileid=${profileId}`
            )
            .then((response) => {
             
                if (response.data.data.dtoList.length > 0) {
                 
                    const primaryAddresses = response.data.data.dtoList.filter(address => address.recstatus === 'PRIMARY');
                 
                    if (primaryAddresses.length > 0) {
                        localStorage.setItem(
                            "address",
                            JSON.stringify(primaryAddresses)
                        );
                    }
                }
            })
            .catch((e) => {
                console.log("error", e);
            });
    }
    
}


