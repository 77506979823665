import React, { useEffect, useState } from "react";
import axios from "../../../../axiosinstance";
import { Autocomplete, Card, Grid, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import AdminPriceInfo from "./AdminPriceInfo";
import MDButton from "components/MDButton";

const AdminCreatePrice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [pricePopup, setPricePopup] = useState(false);
  const [apiresponse, setApiResponse] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "red",
    marginRight: "9px",
  };

  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "Description", accessor: "description" },
      { Header: "type", accessor: "type" },
      { Header: "Price type", accessor: "valuetype" },
      { Header: "currency", accessor: "currency" },
      { Header: "Value", accessor: "value" },
      { Header: "uom", accessor: "uom" },
      { Header: "Tax Preference", accessor: "taxinclusiveflag" },

      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsDoctor = {
    options: !doctorOptions ? [{ name: "Loading...", id: 0 }] : doctorOptions,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        axios.put(`${APIs.priceServiceApi}/delete`, item).then((response) => {
          console.log(response);
          getApiResponse();
        });
        swal("Your price record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your price record is safe!");
      }
    });
  }
  function handleAddButton() {
    setPricePopup(true);
    setSelectedItem(null);
  }

  function getApiResponse(code) {
    setLoading(true);

    axios
      .get(`${APIs.priceServiceApi}/get?profileid=${code}`)
      .then((response) => {
        setApiResponse(response?.data?.data?.dtoList);
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <FaRegEdit
                style={iconStyleedit}
                onClick={() => {
                  setSelectedItem(item);
                  setPricePopup(true);
                }}
              />

              <MdDelete
                style={iconStyle}
                onClick={() => {
                  onDelete(item);
                }}
              />
            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.loginServiceApi}/get?rolecode=DO001&recstatus=APPROVED`
      );
      setDoctorOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Doctor Price List
                  </MDTypography>
                </span>
                <span
                  style={{
                    alignSelf: "start",
                    color: "#344767",
                  }}
                >
                  <MDButton color="info" onClick={handleAddButton}>
                    ADD
                  </MDButton>
                </span>
              </div>
            </MDBox>

            <Grid item xs={12} sm={2.5} mr={3} mt={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                style={{ marginLeft: "20px" }}
                {...defaultPropsDoctor}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSelectedDoctor(newValue?.profileid);
                  getApiResponse(newValue?.profileid);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Doctor" />
                )}
              />
            </Grid>

            <BarLoader
              color="#344767"
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
      {pricePopup && (
        <AdminPriceInfo
          pricePopup={pricePopup}
          setPricePopup={setPricePopup}
          data={selectedItem}
          getApiResponse={getApiResponse}
          apiresponse={apiresponse}
          selectedDoctor={selectedDoctor}
        />
      )}
    </DashboardLayout>
  );
};

export default AdminCreatePrice;
