import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import MDButton from "components/MDButton";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";
import { useFormikContext } from "formik";

function CommissionDetail({ formData }) {
  const { setFieldValue, setFieldError } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const handleChange = (event) => {
    const { name, value } = event.target;

    setFieldValue(name, value);
  };
  const handleBlur = (event) => {
    const { name, value } = event.target;
  };
  // Inside the module where auth is defined, export it

  const {
    agentcomissiontype,
    resourcecomissiontype,
    agentcomission,
    resourcecomission,
  } = formField;
  const {
    agentcomissiontype: agentcomissiontypeV,
    agentcomission: agentcomissionV,
    resourcecomissiontype: resourcecomissiontypeV,
    resourcecomission: resourcecomissionV,
  } = values;

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={!!errors.agentcomissiontype && touched.agentcomissiontype}
            >
              <InputLabel
                htmlFor="agentcomissiontype"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                <span>
                  Agent Commission Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="agentcomissiontype"
                id="agentcomissiontype"
                value={agentcomissiontypeV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Flat">Flat</option>
                <option value="Percentage">Percentage</option>
              </Select>
              {errors.agentcomissiontype && touched.agentcomissiontype && (
                <FormHelperText error sx={{marginLeft: "0px", fontWeight:"400"}}>
                  {errors.agentcomissiontype}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
              <FormField
                type={agentcomission.type}
                label=<span>
                  {agentcomission.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={agentcomission.name}
                value={agentcomissionV}
                placeholder={agentcomission.placeholder}
                error={errors.agentcomission && touched.agentcomission}
                success={agentcomissionV.length > 0 && !errors.agentcomission}
              />
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={
                !!errors.resourcecomissiontype && touched.resourcecomissiontype
              }
            >
              <InputLabel
                htmlFor="resourcecomissiontype"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                <span>
                  Resource Commission Type
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="resourcecomissiontype"
                id="resourcecomissiontype"
                value={resourcecomissiontypeV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Flat">Flat</option>
                <option value="Percentage">Percentage</option>
              </Select>
              {errors.resourcecomissiontype &&
                touched.resourcecomissiontype && (
                  <FormHelperText error sx={{marginLeft: "0px", fontWeight:"400"}}>
                    {errors.resourcecomissiontype}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
              <FormField
                type={resourcecomission.type}
                label=<span>
                  {resourcecomission.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={resourcecomission.name}
                value={resourcecomissionV}
                placeholder={resourcecomission.placeholder}
                error={errors.resourcecomission && touched.resourcecomission}
                success={
                  resourcecomissionV.length > 0 && !errors.resourcecomission
                }
              />
            </div>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

CommissionDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default CommissionDetail;
