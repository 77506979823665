// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import { Autocomplete, CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import FormField from "../FormField";
import axios from "../../../../../axiosinstance";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { BarLoader } from "react-spinners";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import { getProject } from "components/Resuable/Create Project/service/Projectcreate";
import { APIs } from "Services/APIs";
import { micappCode } from "static/micappCode";
import { ROLE_CODE } from "privateComponents/codes";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import { FaRegTimesCircle } from "react-icons/fa";
function ProjectCreateInfo({ formData }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [color] = useState("#344767");
  const [statelist, setStateList] = useState([]);
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [loading, setLoading] = useState(false);
  const { setFieldValue, setFieldError } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const [parenthierachicalValue, setparenthierachicalValue] = useState(null);
  const [parenthierachicalList, setparenthierachicalList] = useState("");
  const [data, setData] = useState([]);
  const [idCounter, setIdCounter] = useState(0);
  const [projectLoading, setProjectLoading] = useState(false);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [subDistrictLoading, setSubDistrictLoading] = useState(false);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const { getProjectTeam, getProjectDetails } = useSelector((state) => state);
  const [errorMessages, setErrorMessages] = useState({
    letitude: "",
    longtitude: "",
  });
  const { name, description } = formField;

  var { name: nameV, description: descriptionV } = values;

  const defaultPropsparenthierachical = {
    options: !parenthierachicalList
      ? [{ name: "Loading...", id: 0 }]
      : parenthierachicalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function getStateData() {
    setLoading(true);
    try {
      const response = await dispatch(getState());
      if (!response.error) {
        if (
          response?.payload?.data?.dtoList &&
          response?.payload?.data?.listSize > 0
        ) {
          setStateList(response?.payload?.data?.dtoList);
        } else {
          swal("", "No State data found.", "warning");
        }
      } else {
        swal(
          "Oops!",
          `${response?.payload?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
      }
    } catch (error) {
      swal("Oops!", "Something wents wrong.", "warning");
    }
    setLoading(false);
  }

  async function getDistrictData(statecode) {
    setDistrictLoading(true);
    try {
      const response = await dispatch(getDistrict(statecode));
      if (!response.error) {
        if (
          response?.payload?.data?.dtoList &&
          response?.payload?.data?.listSize > 0
        ) {
          setDistrict(response?.payload?.data?.dtoList);
        } else {
          swal("", "No District data found.", "warning");
        }
      } else {
        swal(
          "Oops!",
          `${response?.payload?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
      }
    } catch (error) {
      swal("Oops!", "Something wents wrong.", "warning");
    }
    setDistrictLoading(false);
  }

  async function getSubDistrictData(statecode, districtCode) {
    setSubDistrictLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };
    try {
      const response = await dispatch(getSubDistrict(data));
      if (!response.error) {
        if (
          response?.payload?.data?.dtoList &&
          response?.payload?.data?.listSize > 0
        ) {
          setSubDistrict(response.payload.data.dtoList);
        } else {
          swal("", "No Sub District data found.", "warning");
        }
      } else {
        swal(
          "Oops!",
          `${response?.payload?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
      }
    } catch (error) {
      swal("Oops!", "Something wents wrong.", "warning");
    }
    setSubDistrictLoading(false);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    setPincodeLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    try {
      const response = await dispatch(getPincode(data));
      if (!response.error) {
        if (
          response?.payload?.data?.dtoList &&
          response?.payload?.data?.listSize > 0
        ) {
          setPincodeList(response?.payload?.data?.dtoList);
        } else {
          swal("", "No Pincode data found.", "warning");
        }
      } else {
        swal(
          "Oops!",
          `${response?.payload?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
      }
    } catch (error) {
      swal("Oops!", "Something wents wrong.", "warning");
    }
    setPincodeLoading(false);
  }

  async function getData() {
    setProjectLoading(true);
    let url =
      auth?.rolecode === ROLE_CODE?.admincode
        ? `${APIs.projectServiceApi}/get?type=${micappCode?.mappingcode}`
        : `${APIs.projectServiceApi}/getByPageV2?projectcode=${getProjectDetails?.details?.projecthirarichalcode}&type=${micappCode?.mappingcode}`;
    try {
      const response = await axios.get(url);
      if (!response.error) {
        setProjectList(response.data.data.dtoList);
        setparenthierachicalList(response.data.data.dtoList);
      } else {
        swal(
          "Oops!",
          `${response?.payload?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
      }
    } catch (error) {
      swal("Oops!", "Something wents wrong.", "warning");
    }
    setProjectLoading(false);
  }

  const addData = () => {
    let localErrorMessages = {
      letitude: "",
      longtitude: "",
    };
    if (!values.letitude) {
      localErrorMessages.letitude = "Latitude is required.";
    }

    if (!values.longtitude) {
      localErrorMessages.longtitude = "Longitude is required.";
    }

    if (localErrorMessages.letitude || localErrorMessages.longtitude) {
      setErrorMessages(localErrorMessages);
      swal("", "Latitude and Longitude cannot be empty!", "warning");
      return;
    }
    const newData = {
      uid: idCounter,
      letitude: values?.letitude,
      longtitude: values?.longtitude,
    };

    setData([...data, newData]);
    setIdCounter(idCounter + 1);

    const locationObj = [...data, newData].map((item) => [
      item.letitude,
      item.longtitude,
    ]);

    (values.letitude = ""), (values.longtitude = "");
  };
  useEffect(() => {
    values.locationObj = data;
  }, [data]);
  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };
  const columns = [
    { Header: "Latitude", accessor: "letitude" },
    { Header: "Longitude", accessor: "longtitude" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeData(row.values.uid)}
          />
        </span>
      ),
    },
  ];

  useEffect(() => {
    getData();
    getStateData();
  }, [dispatch]);

  return (
    <MDBox>
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={name.type}
              name={name.name}
              value={nameV}
              placeholder={name.placeholder}
              error={errors.name && touched.name}
              success={nameV.length > 0 && !errors.name}
              label=<span>
                {name.label}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label=<span>{description.label}</span>
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              // error={errors.machineDescription && touched.machineDescription}
              // success={machineDescriptionV.length > 0 && !errors.machineDescription}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={
                !!errors.projecthirarichalcode && touched.projecthirarichalcode
              }
            >
              <InputLabel
                htmlFor="projecthirarichalcode"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Parent Hierarchical
                {/* <sup
                   style={{
                     color: "red",
                     fontSize: "small",
                     fontWeight: "bolder",
                     position: "relative",
                     top: "2px",
                   }}
                 >
                   *
                 </sup> */}
              </InputLabel>
              <div style={{ position: "relative", marginTop: "10px" }}>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="projecthirarichalcode"
                  id="projecthirarichalcode"
                  value={values.projecthirarichalcode}
                  onChange={(e) => {
                    const parentproject = e.target.value;

                    const projecthirarichalcode = projectList?.find(
                      (i) => i.code === parentproject
                    )?.projecthirarichalcode;
                    // getData(e.target.value);
                    setFieldValue("projecthierarchy", projecthirarichalcode);
                    setFieldValue("projecthirarichalcode", parentproject);
                  }}
                >
                  <option value=""></option>
                  {projectList?.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {projectLoading && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "30%",
                      transform: "translateY(-50%)",
                      marginRight: "20px",
                    }}
                  />
                )}
              </div>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.state && touched.state}>
              <InputLabel
                htmlFor="state"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                State
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <div style={{ position: "relative", marginTop: "10px" }}>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="state"
                  id="state"
                  value={values.state}
                  onChange={(e) => {
                    const state = e.target.value;
                    setDistrict([]);
                    setSubDistrict([]);
                    setPincodeList([]);
                    if (state) {
                      getDistrictData(e.target.value);
                    }
                    setFieldValue("district", "");
                    setFieldValue("subDistrict", "");
                    setFieldValue("pincode", "");
                    setFieldValue("state", state);
                  }}
                >
                  <option value=""></option>
                  {statelist?.map((obj) => (
                    <option key={obj.id} value={obj.state?.code}>
                      {obj.state.name}
                    </option>
                  ))}
                </Select>
                {loading && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "30%",
                      transform: "translateY(-50%)",
                      marginRight: "20px",
                    }}
                  />
                )}
              </div>
              {errors.state && touched.state && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.state}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <FormControl
              fullWidth
              error={!!errors.district && touched.district}
            >
              <InputLabel
                htmlFor="district"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                District
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <div style={{ position: "relative", marginTop: "10px" }}>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="district"
                  id="district"
                  value={values.district}
                  onChange={(e) => {
                    const districtvalue = e.target.value;
                    setFieldValue("subDistrict", "");
                    setFieldValue("pincode", "");
                    setSubDistrict([]);
                    setPincodeList([]);
                    const selectedDistrictobj =
                      district?.find(
                        (obj) => obj.district.code === e.target.value
                      ) || {};
                    if (districtvalue) {
                      getSubDistrictData(
                        selectedDistrictobj.state?.code,
                        selectedDistrictobj.district?.code
                      );
                    }
                    setFieldValue("district", districtvalue);
                  }}
                >
                  <option value=""></option>
                  {district.map((obj) => (
                    <option key={obj.id} value={obj.district?.code}>
                      {obj.district?.name}
                    </option>
                  ))}
                </Select>
                {districtLoading && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "30%",
                      transform: "translateY(-50%)",
                      marginRight: "20px",
                    }}
                  />
                )}
              </div>
              {errors.district && touched.district && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.district}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <FormControl
              fullWidth
              error={!!errors.subDistrict && touched.subDistrict}
            >
              <InputLabel
                htmlFor="subDistrict"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Sub District
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <div style={{ position: "relative", marginTop: "10px" }}>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="subDistrict"
                  id="subDistrict"
                  value={values.subDistrict}
                  onChange={(e) => {
                    const subDistrictvalue = e.target.value;
                    setFieldValue("pincode", "");
                    setPincodeList([]);
                    const selectedPincodeobj =
                      subdistrict?.find(
                        (obj) => obj.subdistrict?.code === e.target.value
                      ) || {};
                    if (subDistrictvalue) {
                      getVillageData(
                        selectedPincodeobj.state?.code,
                        selectedPincodeobj.district?.code,
                        selectedPincodeobj.subdistrict?.code
                      );
                    }
                    setFieldValue("subDistrict", subDistrictvalue);
                  }}
                >
                  <option value=""></option>
                  {subdistrict?.map((obj) => (
                    <option key={obj.id} value={obj.subdistrict.code}>
                      {obj.subdistrict.name}
                    </option>
                  ))}
                </Select>
                {subDistrictLoading && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "30%",
                      transform: "translateY(-50%)",
                      marginRight: "20px",
                    }}
                  />
                )}
              </div>
              {errors.subDistrict && touched.subDistrict && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.subDistrict}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} mt={1}>
            <FormControl fullWidth error={!!errors.pincode && touched.pincode}>
              <InputLabel
                htmlFor="pincode"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Pincode
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <div style={{ position: "relative", marginTop: "10px" }}>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="pincode"
                  id="pincode"
                  value={values.pincode}
                  onChange={(e) => {
                    const pincode = e.target.value;
                    const selectedPincodeObj =
                      pincodelist.find(
                        (obj) => obj.village.code === e.target.value
                      ) || {};

                    setFieldValue("pincodeobj", selectedPincodeObj);
                    setFieldValue("pincode", pincode);
                  }}
                >
                  <option value=""></option>
                  {pincodelist?.map((obj) => (
                    <option key={obj.id} value={obj.village.code}>
                      {obj.village?.name}
                    </option>
                  ))}
                </Select>
                {pincodeLoading && (
                  <CircularProgress
                    color="inherit"
                    size={20}
                    style={{
                      position: "absolute",
                      right: 0,
                      top: "30%",
                      transform: "translateY(-50%)",
                      marginRight: "20px",
                    }}
                  />
                )}
              </div>
              {errors.pincode && touched.pincode && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.pincode}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>

        <MDTypography
          variant="h5"
          fontWeight="small"
          color="black"
          mt={3}
          mb={1}
        >
          Add Location
        </MDTypography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label=<span>
                Latitude
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              name="letitude"
              value={values.letitude}
              placeholder="Enter Latitude"
              onChange={(e) => setFieldValue("letitude", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label=<span>
                Longitude
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              name="longtitude"
              value={values.longtitude}
              placeholder="Enter Longitude"
              onChange={(e) => setFieldValue("longtitude", e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} display="flex" justifyContent="flex-end">
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={addData}
            >
              Add
            </MDButton>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <DataTable
              table={{ columns, rows: data }}
              canSearch={false}
              entriesPerPage={false}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

// typechecking props for MachineInfo
ProjectCreateInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectCreateInfo;
