import React from "react";
import OtherProfile from "./OtherProfile";
import Rolecode from "../../../../Rolecode";

import FindDoctor from "app/findDoctor/FindDoctor";
import UserInformation from "../../../../components/UserInformation/userInformation";
import { useSelector } from "react-redux";
const Overview = () => {
  const code = Rolecode();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  return (
    <>
      {code?.patientcode && auth.logincount == 1 ? (
        <UserInformation/>
      ) : code?.patientcode ? (
        <FindDoctor />
      ) : (
        <OtherProfile />
      )}
    </>
  );
};


export default Overview;
