import * as Yup from "yup";
import checkout from "components/Resuable/components/otp/new-otp/schemas/form";

const {
  formField: {
   
   
    otp
   
  
    
  },
} = checkout;

const validations = [
  Yup.object().shape({

    [otp.name]: Yup.string()
    .required(otp.errorMsg)
    .min(6, otp.invalidMsg)
    .max(6, otp.invalidMsg),
    
  }),
 
];

export default validations;
