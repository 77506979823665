import axios from "../../../../axiosinstance";
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { useFormik, Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Grid from "@mui/material/Grid";
import { Autocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { FaRegTimesCircle } from "react-icons/fa";
import MDTypography from "components/MDTypography";
import FormField from "./createTestInfo/FormField";

const TestEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const [loadingfilter, setLoadingfilter] = useState(true);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading1, setIsLoading1] = useState(false);
  const [uomvalue, setUomvalue] = useState(null);
  const [uomList, setUomList] = useState([]);
  const [errorMessages, setErrorMessages] = useState({
    investigationname: "",
    range: "",
    uom: "",
  });
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "Investigation Name", accessor: "name" },
      { Header: "UOM", accessor: "uom" },
      { Header: "Range", accessor: "range" },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ row: { original } }) => (
          <div>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveItem(original.name)}
            />
          </div>
        ),
      },
    ],
    rows: selectedUser?.investigationlist || [],
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required."),
    testname: Yup.string().required("Test name is required."),
    // investigationList: Yup.array()
    //   .min(1, "Investigation list cannot be empty")
    //   .required("Investigation list is required"),
  });

  const initialValues = {
    name: selectedUser?.name || "",
    testname: selectedUser?.testname || "",
    investigationname: "",
    range: "",
    uom: "",
    investigationList: selectedUser?.investigationlist || [],
  };

  const handleRemoveItem = (name) => {
    setTableData((prevTableData) => {
      const updatedRows = prevTableData.rows.filter(
        (item) => item.name !== name
      );
      formik.setFieldValue("investigationList", updatedRows);
      return {
        ...prevTableData,
        rows: updatedRows,
      };
    });
  };
  const removeItems = (id) => {
    setTableData((prevTableData) => {
      const updatedRows = prevTableData.rows.filter((item) => item.id !== id);
      setFieldValue("investigationList", updatedRows);
      const newState = {
        ...prevTableData,
        rows: updatedRows,
      };

      console.log("Updated state:", newState);

      return newState;
    });
  };

  const addData = (values) => {
    let localErrorMessages = {
      investigationname: "",
      range: "",
      uom: "",
    };
    if (!values.investigationname) {
      localErrorMessages.investigationname = "Investigation name is required.";
    }

    if (!values.range) {
      localErrorMessages.range = "Range is required.";
    }

    if (!values.uom) {
      localErrorMessages.uom = "UOM is required.";
    }

    if (
      localErrorMessages.investigationname ||
      localErrorMessages.range ||
      localErrorMessages.uom
    ) {
      setErrorMessages(localErrorMessages);
      swal("", "Investigation name, Range, UOM cannot be empty!", "warning");
      return;
    }
    const newId = new Date().getTime();

    const investigationObject = {
      id: newId,
      name: values.investigationname,
      range: values.range,
      uom: values.uom,
      action: (
        <span>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => handleRemoveItem(values.investigationname)}
          />
        </span>
      ),
    };

    const filter = tableData.rows.some(
      (a) => a.name === investigationObject.name
    );
    if (!filter) {
      const updatedRows = [...tableData.rows, investigationObject];
      setTableData({ ...tableData, rows: updatedRows });
      formik.setFieldValue("investigationList", updatedRows);
    }
    formik.setFieldValue("investigationname", "");
    formik.setFieldValue("range", "");
    formik.setFieldValue("uom", "");
    setUomvalue(null);
  };
  const handleInputChange = (e, field) => {
    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [field]: "",
    }));
    values[field] = e.target.value;
  };
  async function getData() {
    try {
      const response = await axios.get(
        `${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS`
      );
      setUomList(response.data.data.dtoList);
      setLoadingfilter(false);
    } catch (e) {
      console.log("error", e);
      setLoadingfilter(false);
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      console.log(values);
      if (!values.investigationList.length) {
        console.log(values);
        swal({
          title: "Validation Error",
          text: "Investigation list cannot be empty",
          icon: "error",
        });
        return;
      }
      setIsLoading1(true);
      try {
        values?.investigationList?.forEach((item) => {
          delete item?.id;
          delete item?.action;
        });
        const updatedUser = {
          ...selectedUser,
          investigationlist: values.investigationList,
          testname: values.testname,
          name: values.name,
          modifiedby: auth.profileid,
          modifiedbyname: auth.name,
        };
        await axios.put(`${APIs.testServiceApi}/update`, updatedUser);
        setIsLoading1(false);
        handleClose();
        handleEditreset();
        swal("Success!", "Test updated successfully.", "success");
      } catch (error) {
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage ||
            "Something went wrong.",
          "error"
        );
        setIsLoading1(false);
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);
  // useEffect(() => {
  //   values.investigationList = tableData.rows;
  // }, [tableData.rows]);
  useEffect(() => {
    setTableData((prev) => ({
      ...prev,
      rows: selectedUser?.investigationlist || [],
    }));
  }, [selectedUser]);

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={formik.handleSubmit}
        >
          {({ values, errors, touched, setFieldValue, handleChange }) => (
            <Form>
              {loadingfilter ? (
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : (
                <DialogContent>
                  <MDBox>
                    <MDBox lineHeight={0}>
                      <MDTypography variant="h5">Test Edit </MDTypography>
                      <MDTypography variant="button" color="text">
                        Edit mandatory information
                      </MDTypography>
                    </MDBox>

                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label={
                              <span>
                                Name
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                            }
                            name="name"
                            value={values.name}
                            placeholder="Name"
                            error={errors.name && touched.name}
                            success={values.name.length > 0 && !errors.name}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label={
                              <span>
                                Test Name
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                            }
                            name="testname"
                            value={values.testname}
                            placeholder="Test Name"
                            error={errors.testname && touched.testname}
                            success={
                              values.testname.length > 0 && !errors.testname
                            }
                            onChange={handleChange}
                          />
                        </Grid>
                      </Grid>
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="black"
                        mt={1}
                      >
                        Investigation list
                      </MDTypography>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="text"
                            label={
                              <span>
                                Investigation Name
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                            }
                            name="investigationname"
                            value={values.investigationname}
                            placeholder="Investigation Name"
                            error={
                              errors.investigationname &&
                              touched.investigationname
                            }
                            success={
                              values.investigationname.length > 0 &&
                              !errors.investigationname
                            }
                            onChange={handleChange}
                          />
                          {errorMessages.investigationname && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              mt={0}
                              style={{
                                display: "block",
                                marginTop: "-5px",
                              }}
                            >
                              {errorMessages.investigationname}
                            </MDTypography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            options={uomList}
                            getOptionLabel={(option) => option.name}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                            value={uomvalue}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "uom",
                                newValue ? newValue.name : ""
                              );
                              setUomvalue(newValue);
                            }}
                            renderInput={(params) => (
                              <MDInput
                                {...params}
                                label=<span>
                                  UOM
                                  <sup
                                    style={{
                                      color: "red",
                                      fontSize: "small",
                                      fontWeight: "bolder",
                                      position: "relative",
                                      top: "2px",
                                    }}
                                  >
                                    {" "}
                                    *
                                  </sup>
                                </span>
                                name="uom"
                                placeholder="UOM"
                                variant="standard"
                                InputLabelProps={{ shrink: true }}
                              />
                            )}
                          />
                          {errorMessages.uom && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              mt={1}
                              style={{
                                display: "block",
                                marginTop: "5px",
                              }}
                            >
                              {errorMessages.uom}
                            </MDTypography>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormField
                            type="number"
                            label=<span>
                              Range
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="range"
                            value={values.range}
                            placeholder="Range"
                            onChange={handleChange}
                          />
                          {errorMessages.range && (
                            <MDTypography
                              variant="caption"
                              color="error"
                              mt={0}
                              style={{
                                display: "block",
                                marginTop: "-5px",
                              }}
                            >
                              {errorMessages.range}
                            </MDTypography>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <MDButton
                            color="success"
                            variant="outlined"
                            onClick={() => addData(values, setFieldValue)}
                          >
                            Add
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>
                  </MDBox>

                  <Grid item xs={12} sm={12} md={12} mt={1}>
                    {tableData?.rows?.length === 0 && (
                      <MDTypography variant="caption" color="error" mt={-3}>
                        {errors.investigationList ||
                          "Investigation list cannot be empty."}
                      </MDTypography>
                    )}
                    <DataTable
                      table={tableData}
                      entriesPerPage={false}
                      canSearch={false}
                      showTotalEntries={false}
                      isSorted={false}
                      noEndBorder
                      defaultEntries={10}
                      pagination={false}
                    />
                  </Grid>
                </DialogContent>
              )}
              <DialogActions>
                <MDButton onClick={handleClose} color="light" size="small">
                  Cancel
                </MDButton>
                <MDButton type="submit" color="info" size="small">
                  {loading1 ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit"
                  )}
                </MDButton>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </DashboardLayout>
  );
};

export default TestEdit;
