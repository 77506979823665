import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";


// Import form validation, schema, and initial values
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";


import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";

// import { createUser } from "../service/User";
import AssociationInfo from "./AssociationInfo";
import { createAssociation } from "../service/Association";

// Function to define form steps
function getSteps() {
  return ["Association Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <AssociationInfo formData={formData} />;
    // case 1:
    //   return <Address formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function CreateAssociationPopup(props) {
  const { openpopupUser, setopenpopupUser } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.association);

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
 
    // setIsLoading(true)
     const associationData = {
       rolecode: values.rolecode,
      username: values.selectedProfileObj?.username,
      rolename: values.roleName,
      profileid: values.selectedProfileObj.profileid,
      // firstname: values.selectedProfileObj.firstname,
      // lastname: values.selectedProfileObj.lastname,
      fullname:values.selectedProfileObj.name,
      mmucode: values.selectedmmuObj.code,
      mmuname: values.selectedmmuObj.name,

      recstatus: "OPEN",
      syncstatus: "SYNCED",
      status: "ACTIVE",
    };
   

     const response = await dispatch(createAssociation(associationData));

     if (!response.error && !loading) {
       actions.resetForm();

       setIsLoading(false)
       setopenpopupUser(false)
     } else {
       actions.resetForm();
       swal({
         icon: "error",
         title: "Error",
         text: error?.message,
       });
       setIsLoading(false)
       handleClose()
     }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
   

    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };
 

  const handleClose = () => {
    setopenpopupUser(false);
  };

  // Render the component
  return (
    <Dialog open={openpopupUser} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-3}>
                    {/* <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper> */}
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Association Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            back
                          </MDButton>
                        )}
                        <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
