// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import axios from "../../../../../axiosinstance";
// vitals page components

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import swal from "sweetalert";
import { getProject } from "components/Resuable/Create Project/service/Projectcreate";
import { getProjectTeam } from "../../service/ProjectTeam";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Switch,
} from "@mui/material";
import Select from "@mui/material/Select";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getUser } from "components/Resuable/UserMaster/service/User";
import { getMicListAction } from "redux/Actions/micListAction";
import { APIs } from "Services/APIs";
import { micappCode } from "static/micappCode";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { tableAlreadyExistData } from "static/methods/tableAlreadyExistData";
import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";

function ProjectTeamInfo({ formData }) {
  const [color] = useState("#344767");
  const { setFieldValue, setFieldError } = useFormikContext();
  const { project, loading } = useSelector((state) => state.project);
  const { department } = useSelector((state) => state.department);
  const { designation } = useSelector((state) => state.designation);
  const { user, loading4 } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [designationList, setDesignationList] = useState([]);
  const [projectList, setprojectList] = useState([]);
  const [userList, setUserList] = useState([]);
  const { getMicList } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [idCounter, setIdCounter] = useState(0);

  const { formField, values, touched } = formData;

  const [errors, setErrors] = useState({});

  const { projectname, departmentname, designationname, profilename } =
    formField;
  var {
    projectname: projectV,
    departmentname: departmentnameV,
    designationname: designationnameV,
    profilename: profilenameV,
  } = values;

  // async function getProfileDataByDeptCode(departmentcode) {
  //   await axios
  //     .get(
  //       `login-service-mongo/api/loginservice/v2/get?departmentcode=${departmentcode}`
  //     )
  //     .then((response) => {
  //       setUserList(response.data.data.dtoList);
  //       // setDesignationList(response.data.data.dtoList);
  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //     });
  // }
  async function getprofileData(designationcode) {
    await axios
      .get(
        `login-service-mongo/api/loginservice/v2/get?designationcode=${designationcode}&recstatus=APPROVED`
      )
      .then((response) => {
        if (response?.data?.data?.listSize > 0) {
          setUserList(response?.data?.data?.dtoList);
        } else {
          swal(
            "",
            "No profile data found for the selected designation!",
            "warning"
          );
        }
      })
      .catch((error) => {
        swal(
          "Error retrieving profile data.",
          `${error?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
        console.log("error", e);
      });
  }

  async function getDesignationData(code) {
    await axios
      .get(`${APIs?.designationServiceApi}/get?deptcode=${code}`)
      .then((response) => {
        if (response?.data?.data?.listSize > 0) {
          setDesignationList(response?.data?.data?.dtoList);
        } else {
          swal(
            "",
            "No designation data found for the selected department!",
            "warning"
          );
        }
      })
      .catch((error) => {
        swal(
          "Error retrieving designation data.",
          `${error?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
        console.log("error", e);
        return;
      });
  }
  useEffect(() => {
    dispatch(
      getMicListAction({
        url: `${APIs?.projectServiceApi}/get?type=${micappCode?.mappingcode}`,
      })
    );

    dispatch(getDepartment());
    // dispatch(getDesignation());
    // dispatch(getUser());
  }, [dispatch]);

  const handleSubmit = async () => {
    let hasError = false;

    let newErrors = {};

    if (!values.projectname) {
      newErrors.projectname = "MIC is required.";
      hasError = true;
    }

    if (!values.departmentname) {
      newErrors.departmentname = "Department is required.";
      hasError = true;
    }

    if (!values.designationname) {
      newErrors.designationname = "Designation is required.";
      hasError = true;
    }

    if (!values.profilename) {
      newErrors.profilename = "Profile is required.";
      hasError = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }

    if (!hasError) {
      if (await tableAlreadyExistData(data, values?.profilenameobject?.id))
        return;

      const newData = {
        uid: idCounter,
        projectcode: values?.projectname,
        projectname: values?.projectnamevalue,
        projectteams: values?.profilenameobject,
        mappingcode: values?.projecthirarichalcode,
        department: values?.departmentname,
        departmentname: values?.profilenameobject?.departmentname,
        designation: values?.designationname,
        designationname: values?.profilenameobject?.designationname,
        name: values?.profilenameobject?.name,
      };

      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      setFieldValue("projectname", "");
      setFieldValue("departmentname", "");
      setFieldValue("designationname", "");
      setFieldValue("profilename", "");
      values.teamList = [...data, newData];
    }
  };

  useEffect(() => {
    values.teamList = data;
  }, [data]);

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "MIC Name", accessor: "projectname" },
    { Header: "Department", accessor: "departmentname" },
    { Header: "Designation", accessor: "designationname" },
    { Header: "Profile", accessor: "name" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];

  return (
    <MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <>
          <MDBox mt={1.625}>
            <Grid container spacing={3} mt={1}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={projectname.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {projectname?.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={projectname.name}
                    id={projectname.name}
                    value={projectV}
                    onChange={(e) => {
                      const projectvalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      const projectnamevalue =
                        getMicList?.list?.find(
                          (obj) => obj?.code === projectvalue
                        ) || {};
                      setFieldValue("projectnamevalue", projectnamevalue?.name);
                      setFieldValue("projectname", projectvalue);
                      setFieldValue(
                        "projecthirarichalcode",
                        projectnamevalue?.projecthirarichalcode
                      );
                    }}
                  >
                    <option value=""></option>
                    {getMicList?.list?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.projectname && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.projectname}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={departmentname.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {departmentname.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={departmentname.name}
                    id={departmentname.name}
                    value={departmentnameV}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      getDesignationData(e.target.value);
                      // getProfileDataByDeptCode(e.target.value);

                      setFieldValue("departmentname", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.departmentname && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.departmentname}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={designationname.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {designationname.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={designationname.name}
                    id={designationname.name}
                    value={designationnameV}
                    onChange={(e) => {
                      const designationvalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      const departmentvalue = values?.departmentname;

                      // if (!designationvalue) {
                      //   getProfileDataByDeptCode(departmentvalue);
                      // } else {
                      getprofileData(designationvalue);
                      // }
                      setFieldValue("designationname", designationvalue);
                      if (designationvalue) {
                        const data = {
                          departmentvalue: departmentvalue,
                          designationvalue: designationvalue,
                        };
                      }
                    }}
                  >
                    <option value=""></option>
                    {designationList?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.designationname && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.designationname}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor={profilename.name}
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    {profilename.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name={profilename.name}
                    id={profilename.name}
                    value={profilenameV}
                    onChange={(e) => {
                      const profilenamevalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      const profilenameobject =
                        userList?.find((obj) => obj?.id == e.target.value) ||
                        {};

                      setFieldValue("profilenameobject", profilenameobject);
                      setFieldValue("profilename", profilenamevalue);
                    }}
                  >
                    <option value=""></option>
                    {userList?.map((obj) => (
                      <option key={obj?.id} value={obj?.id}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.profilename && (
                    <FormHelperText
                      error
                      style={{
                        marginLeft: "0px",
                        fontWeight: 400,
                        marginTop: 5,
                      }}
                    >
                      {errors.profilename}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-end"
              >
                <MDButton
                  onClick={() => handleSubmit()}
                  variant="gradient"
                  color="info"
                  size="small"
                >
                  Add
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={{ columns, rows: data }}
            />
          </Grid>
        </>
      )}
    </MDBox>
  );
}

// typechecking props for ProjectTeamInfo
ProjectTeamInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectTeamInfo;
