
import Analytics from "layouts/dashboards/analytics";

import ProfileOverview from "layouts/pages/profile/profile-overview";



import SignInBasic from "layouts/authentication/sign-in/basic";


// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////



// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";



import PatientQueue from "components/Resuable/Patient Que/PatientQueue";

import CreatePrice from "components/Resuable/PriceMaster/CreatePrice";
import Searchpatient from "components/Resuable/Search Patient/SearchPatient";

import Createnurse from "components/Resuable/Create Nurse/Createnurse";
import PrescriptionList from "components/Resuable/PrescriptionList/PrescriptionList";
import FindDoctor from "app/findDoctor/FindDoctor";











const routesAfterlogin = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
    ? JSON.parse(localStorage.getItem("login-auth")).name :"",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
    
    {
      name: "Patient Queue",
      key: "Patientpatient-queue",
      route: "/app/page/patient-queue",
      component: <PatientQueue/>,
    },
    {
      name: "My Prescription",
      key: "my-Prescription",
      route: "/app/page/my-Prescription",
      component: <PrescriptionList/>,
    },
    
    
    {
      name: "Search Patient",
      key: "Search-patient",
      route: "/app/page/search-patient",
      component: <Searchpatient/>,
    },
   
    {
      name: "Create Nurse",
      key: "create-nurse",
      route: "/app/page/create-nurse",
      component: <Createnurse/>,
    },
    {
      name: "Create Price",
      key: "create-price",
      route: "/app/page/create-price",
      component: <CreatePrice />,
    },
    {
      name: "Logout",
      key: "logout",
      route: "/app/page/search-doctor?category=",
      component: <FindDoctor />,
    },
      // {
      //   name: "Invoice Queue",
      //   key: "invoice-gernator",
      //   route: "/app/page/invoice-gernator",
      //   component: <Invoice/>,
      // },
      
      
    ],
  },
  
 
 


  
 

  
  
 

 
];
export default routesAfterlogin