import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import "./avialabledoctorinfo.css";
import Calendar from "../../../../assets/svg/Calendar.svg";
import Simplification from "../../../../assets/svg/Simplification.svg";
import Rupee from "../../../../assets/svg/rupee.svg";
import { useNavigate } from "react-router-dom";
import ViewfullProfile from "./viewfullprofile/ViewfullProfile";
import doctorprofile from "static/doctorprofile";
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { setDoctorDetails, clearDoctorDetails } from '../../../../redux/slices/doctorDetails';
import { variables } from "static/Variables";
 
const Avialabledoctorinfo = ({ doctor, code, pagelabel }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [openpopup, setopenpopup] = useState(false);
  const [selecteddata, setSelectedData] = useState({});
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);


  const [isSmallestScreen, setIsSmallestScreen] = useState(
    window.innerWidth <= 300
  );
 
  const numberWithCommas = (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
      setIsSmallestScreen(window.innerWidth <= 300);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const profileDetail = (doctor) => {
    setopenpopup(true);
    setSelectedData(doctor);
  };
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
 
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };
 
  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  let categorys = doctor?.usercategories?.filter(item => item?.category?.type === "CAT");
  console.log(categorys,"cat")
  return (
    <>
      <div className="avialabledoctorroot">
        <Grid container className="avialabledoctorparentcontainer" xs={12}>
          <Grid
            item
            xs={isSmallestScreen ? 12 : undefined}
            className="avialabledoctorgriditem"
          >
            <img
              src={doctorprofile(doctor?.profileid)}
              alt="Profile-Img"
              className="avialabledoctorimagesize"
            />
          </Grid>
 
          <Grid
            container
            className="avialabledoctorgridcontainer"
            xs={isSmallestScreen ? 10 : 7.5}
            mt={isSmallestScreen ? 1 : undefined}
            ml={3}
          >
            <Grid
              item
              className="avialabledoctorgriditem2"
              xs={12}
              style={{
                display: "flex",
                justifyContent: isSmallestScreen
                  ? "space-evenly"
                  : "space-between",
                gap: 0,
              }}
            >
              <div className="avialabledoctorname">{doctor?.name}</div>
 
              <img
                src={Simplification}
                alt="Simplification Logo"
                className="avialabledoctorimagesize2"
              />
            </Grid>
            <Grid item mt={1} className="avialabledoctorgriditem" xs={12}>
              <div className="avialabledoctorabout">
                {/* {doctor?.qualificationDTOList?.map((item, index) => (
              
                <span>{item?.qualificationname}</span>
               
              ))}
                 */}
                <span>
                  {doctor?.qualificationDTOList ? (
                    doctor?.qualificationDTOList[0]?.qualificationname
                  ) : (
                    <></>
                  )}
                </span>
 
                {doctor?.address?.map(
                  (item, index) =>
                    item?.recstatus === "PRIMARY" && (
                      <span>
                        {" "}
                        | {isSmallScreen ? <></> : <br />}
                        {item?.first} {item?.secondline} {item?.city}{" "}
                        {item?.state}
                      </span>
                    )
                )}
              </div>
            </Grid>
            <Grid item xs={12} mb={3} className="avialabledoctorgriditem3">
      {categorys?.map((item, index) => (
        <React.Fragment key={index}>
          {index === 0 ? (
            <div className="avialabledoctorspecialization">
              {item?.category?.name}
            </div>
          ) : null}
          {index === 0 && categorys?.length > 1 ? (
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <React.Fragment>
                  {categorys?.map((category, index) => (
                    <div key={index}>{category?.category?.name}</div>
                  ))}
                </React.Fragment>
              }
            >
              <div
                className="avialabledoctorspecialization"
                onMouseEnter={handleTooltipOpen}
                onMouseLeave={handleTooltipClose}
              >
                +{categorys?.length - 1} more
              </div>
            </Tooltip>
          ) : null}
        </React.Fragment>
      ))}
    </Grid>
            <Grid
              container
              className="avialabledoctorgridcontainer2"
              xs={isSmallestScreen ? 12 : 9}
            >
              <Grid item className="avialabledoctorgriditem2">
                <img
                  src={Calendar}
                  alt="Calendar Logo"
                  className="avialabledoctorimagesize2"
                />
                &nbsp;
                <div className="avialabledoctortextdetail">
                  {doctor?.age} years
                </div>
              </Grid>
              <Grid item className="avialabledoctorgriditem2">
                <img
                  src={Rupee}
                  alt="Rupee Logo"
                  className="avialabledoctorimagesize2"
                />
                &nbsp;
                {doctor?.price?.map(
                  (item, index) =>
                    item?.status === "ACTIVE" && item?.type === variables?.showFeeType && (
                      <div key={index} className="avialabledoctortextdetail">
                        {numberWithCommas(item?.value)}
                      </div>
                    )
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} mt={2} className="avialabledoctorrootcontainer">
          <Grid container xs={12} className="avialabledoctorgriditem4">
            <Grid item>
              <button
                className="avialabledoctorbutton1"
                onClick={() => profileDetail(doctor)}
              >
                View full profile
              </button>
            </Grid>
            <Grid item>
              <button
                className="avialabledoctorbutton2"
                onClick={() => {
                  if (auth.rolecode) {
                  
                    navigate(
                      `/app/${auth?.rolename?.toLowerCase()}/doctors-Expanded`,
                      {
                        state: {
                          profileid: doctor?.profileid,
                          pagelabel: pagelabel,
                          price: doctor?.price
                        },
                      }
                    );
                  } else {
                    const currentPath = window.location.pathname + window.location.search + window.location.hash;

                    // Store the current relative path in local storage
                    localStorage.setItem('redirectUrl', currentPath);
                    navigate("/app/login");
                 
                    swal(
                      "Attention",
                      "Please log in before booking an appointment for a secure and personalized experience||.",
                      "warning"
                    );
                  }
                }}
              >
                  Book Appointment
                {/* {isSmallScreen ? "Consult Now" : "Book Appointment"} */} 
              </button>
            </Grid>
          </Grid>
        </Grid>
        {openpopup && (
          <ViewfullProfile
            openpopup={openpopup}
            setopenpopup={setopenpopup}
            title={"View Full Profile"}
            selecteddata={selecteddata}
          />
        )}
      </div>
    </>
  );
};
 
export default Avialabledoctorinfo;