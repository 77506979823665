import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const pincodeAssociationAction = 
   createAsyncThunk("getPincodeAssociation", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.projectCode){
        url = APIs.pincodeAssociationService +`/get?projectcode=${data.projectCode}`
      }else if(data && data.url){
        url = data.url
      } else {
        url = APIs.pincodeAssociationService +`/get`
      }
        let respData = await getAllUsers(url, "")
        console.log(respData?.dtoList, "respData 20")
        return respData?.dtoList || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });