import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axiosinstance";
 
 
export const UploadDocumnet = async (data) => {
    console.log("formData", data);
 
    try {
      const response = await axios.post(
        `new-upload-service-mongo/api/attachment/upload`,
     
        data
      );
      return await response.data;
    } catch (error) {
        console.log(error)
    //   return rejectWithValue(error);
    }
  };
  export const downloadurl = axios.defaults.baseURL + "new-upload-service-mongo/api/attachment/download";
 
// export const UploadDocumnet = createAsyncThunk("UploadDocumnet", async (data, {rejectWithValue}) => {
//     console.log("formData", data);
 
//     try {
//       const response = await axios.post(
//         `https://apidayco.softwaremathematics.com/new-upload-service-mongo/api/attachment/upload`,
     
//         data
//       );
//       return await response.data;
//     } catch (error) {
//       return rejectWithValue(error);
//     }
//   });