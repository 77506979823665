// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
import axios from "../../../../axiosinstance";
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { micappCode } from "static/micappCode";
import { labappCode } from "static/labappCode";
import { BarLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";

function DepartmentInfo({ formData }) {
  const [color] = useState("#344767");

  const [hierarchicalCode, setHierarchicalCode] = React.useState(null);

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { formField, values, errors, touched } = formData;
  const dispatch = useDispatch();
  // const {department, loading} = useSelector((state) => state.department);
  const [department, setDepartment] = useState([]);
  const [loading, setLoading] = useState(false);
  const createdby = auth?.rolecode === ROLE_CODE?.miccode ? "" : `createdby=${auth?.profileid}`;
  const defaultProps = {
    options: !department ? [{ name: "Loading...", id: 0 }] : department,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { departmentName, departmentDescrption } = formField;
  var {
    departmentName: departmentNameV,
    departmentDescrption: departmentDescrptionV,
  } = values;
  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.deparmentServiceApi}/get?mappingcode=${
          auth?.rolecode === ROLE_CODE?.miccode
            ? micappCode?.mappingcode
            : labappCode?.mappingcode
        }&${createdby}`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(async () => {
    getData();
    // if (values?.departmentvalue !== "") {
    //   getDesignationData(values?.departmentvalue)
    // }
  }, []);

  //  useEffect(() => {
  //   dispatch(getDepartment());
  // }, [])

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Department Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={departmentName.type}
                label=<span>
                  {departmentName.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={departmentName.name}
                value={departmentNameV}
                placeholder={departmentName.placeholder}
                error={errors.departmentName && touched.departmentName}
                success={departmentNameV.length > 0 && !errors.departmentName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={departmentDescrption.type}
                label={departmentDescrption.label}
                name={departmentDescrption.name}
                value={departmentDescrptionV}
                placeholder={departmentDescrption.placeholder}
                // error={errors.departmentDescrption && touched. departmentDescrption}
                success={
                  departmentDescrptionV.length > 0 &&
                  !errors.departmentDescrption
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultProps}
                value={hierarchicalCode}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setHierarchicalCode(newValue);
                  console.log("newValue", newValue);

                  values.department = newValue?.code;
                  values.parenthierarchical = newValue?.hierarchicalcode;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    // error={errors.department && touched.departmentList}
                    // success={department.length > 0 && !errors.department}
                    variant="standard"
                    label="Parent Department"
                  />
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DepartmentInfo
DepartmentInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DepartmentInfo;
