import { Grid,TextField, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import ProfileInfo from "./profileInfo/profileinfo";
import AboutInfo from "./about/aboutinfo";
import Profile from "../../../../assets/images/Profile.svg";
import Education from "../../../../assets/images/Education.svg";
import "./profileInfo/profileinfo.css";
import Appointment from "./appointment/appointment";
import NavigateButton from "./navigateButton/navigateButton";
import axios from "../../../../axiosinstance";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";



const DoctorsExpanded = (props) => {
  const location = useLocation();
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000);
  const [grandparentData, setGrandparentData] = useState(null);
  const [slot, setSlot] = useState(null);
  const [doctorfess, setDoctorfees] = useState([]);
  const [response, setResponse] = useState();

  // const doctorDetails = useSelector(state => state.doctorDetails.details);
  // console.log(doctorDetails,"doctorDetails")
  const [childData, setChildData] = useState("");


  const updateData = (data) => {
   
    setChildData(data);
  };

  // Callback function to receive data from the grandchild
  const handleGrandchildData = (dataFromGrandchild, value) => {
    console.log("dataFromGrandchild", dataFromGrandchild);
    setSlot(value);
    setGrandparentData(dataFromGrandchild);
  };

  const queryParams = new URLSearchParams(location.search);
  const docProfileId = queryParams.get("profileid");
  const visitId = queryParams.get("visitid");
  const vId = queryParams.get("vid");

  useEffect(() => {
    setDoctorfees(location?.state?.price)
    if(docProfileId){
      get(docProfileId);
    }else{
      get(location?.state?.profileid);
    }
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const doctor = {
    name: "Dr. Rabi Nath",
    specialization: "CHAIRMAN CARDIAC SCIENCE",
    location: "Apollo Kolkata",
    age: "38",
    fees: "1600",
    fields: ["Cardiac Sciences", "Adult CTV", "MBBS", "MS(General Surgery)"],
    about:
      "Dr. Rabi Nath is listed among the top cardiac surgeons in the country and has a vast surgical experience of over 20,000 cardiac surgeries. He is widely acknowledged for his expertise in treating Total Arterial Coronary Bypass Surgery, Paediatric Cardiac Surgery, Valve Repairs, Structural Heart Disease, and Surgery for Cardiac Failure. Dr. Kaul is an expert in the management of heart failure, which includes - LVAD -Left Ventricular Assist Device (Artificial Heart); Heart Transplant, and Implantation of various devices for heart failure. He has also performed over 5000 Minimally Invasive Cardiac Surgical procedures, Interventions for Structural Heart Disease, Peripheral Vascular and Endovascular Stent placement, Transcatheter Aortic Valve Replacement, Transcatheter Mitral Valve replacement transplants, and Left Ventricular Assist Device. Dr. Kaul completed his MBBS, MS (General Surgery) & M.Ch (Cardiothoracic and Vascular Surgery) from Bombay University and has an overall experience of more than 38 years.",
    education:
      "MBBS, MS (General Surgery), M.Ch (Cardiothoracic Surgery), Fellowship in Heart & Lung transplant, Hannover Medical School, Fellowship in Minimally Invasive Cardiac Surgery, Lipzig Heart Centre, Germany",
  };

  async function get(profileid) {
    await axios
      .get(
        `/profile-service-mongo/api/ProfileEntity/v2/getAggregated?profileid=${profileid}`
      )
      .then((response) => {
        const data = response.data?.data?.profileaggregation;
        setResponse(data);
      
       
      })
      .catch((e) => {
        console.log("error", e);
      });

    try {
      const response = await axios.get(
        `/price-service/api/price/v1/get?profileid=${profileid}&type=fees`
      );
     
      setDoctorfees(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  
  // useEffect(() => {
  //   get(profileid);
  //   get(location?.state?.profileid);
  //   setDoctorfees(location?.state?.price)
  //   console.log(location?.state?.price, "price 93")
  // }, []);
  const { state } = location;

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden",marginTop:"120px" }}>
     
      <Grid container className="rootcontainer">
        <Grid item xs={isSmallScreen ? 12 : 9}>
          <ProfileInfo
          
            response={response}
            doctorfess={doctorfess}
          />
        </Grid>
      </Grid>

      <Grid container className="rootcontainer" mt={isSmallScreen ? 3 : 10}>
        <Grid
          item
          xs={isSmallScreen ? 12 : 4}
          mr={isSmallScreen ? 3 : 10}
          ml={isSmallScreen ? 3 : 0}
        >
          <Grid item mb={3}>
            <AboutInfo
              about={response?.login?.description}
              label="About"
              image={Profile}
            />
          </Grid>
          <Grid item>
            <AboutInfo
              about={(response?.qualificationlist || []).map((item, index) => (
                <div key={index}>
                  {item?.qualificationname} {item?.qualificationboard}
                </div>
              ))}
              label="Qualification"
              image={Education}
            />
          </Grid>
        
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : 5}
          ml={isSmallScreen ? 6 : 0}
          mt={isSmallScreen ? 3 : 0}
        >
          <Appointment
            doctor={doctor}
            onGrandchildData={handleGrandchildData}
            doctorprofileid={docProfileId || location?.state?.profileid}
            updateData={updateData}
          /> 
        </Grid>
      </Grid>

      <Grid container className="rootcontainer" mt={10} mb={10}>
        <Grid item xs={isSmallScreen ? 11 : 10}>
          <NavigateButton
            grandparentData={grandparentData}
            doctorfess={doctorfess}
            doctorobject={response}
            profileid={docProfileId || location?.state?.profileid}
            date={childData}
            slot={slot}
            pagelabel={location?.state?.pagelabel}
            visitId={visitId}
            visit = {state?.visit}
          />
        </Grid>
      </Grid>
 
    </div>
  );
};
export default DoctorsExpanded;
