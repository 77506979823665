import { Button, Card, FormControlLabel, Stack, Switch } from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../axiosinstance";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteProjectServiceData } from "redux/Actions/getProjectServiceData";
import Roleedit from "./RoleEdit";
import CreateRolePopup from "./CreateRolePopup";
// import { deleteRoleData, getRolemethod } from "redux/Actions/roleAction";
import { micappCode } from "static/micappCode";

const RoleList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const location = useLocation();
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupRole, setopenpopupRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];
  const toggler = async (checked, item) => {
    try {
      const updatedUser = {
        ...item,
        recstatus: checked ? "APPROVED" : "PENDING", 
      };
  
      await axios.put(
      `${APIs.roleService}/update`,
        updatedUser
      );
      
      dispatch(tableDataAction({ url:`${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
    } catch (error) {
      console.error(error);
    }
  };
  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        <Button
          variant="text"
          color="error"
          onClick={() => {
            dispatch(setActionTypeRow({ type: "edit", data: row }));
            handleOpenEditDialog(row);
          }}
          style={{ border: "2px solid rgba(0,0,0,0.30)" }}
        >
          Edit
        </Button>
        <Button
          variant="text"
          color="error"
          onClick={() => {
            dispatch(setActionTypeRow({ type: "edit", data: row }));
            onDelete(row);
          }}
          style={{ border: "2px solid rgba(0,0,0,0.30)" }}
        >
          Delete
        </Button>
      </Stack>
    );
  };

  const actionButtons2 =(row)=>{     
    return (
      <Stack direction="row" sx={{display:"flex", justifyContent:"center"}}>
        <FormControlLabel
                        name={row.id}
                        control={
                          <Switch
                            onClick={(e) =>
                              toggler(
                                e.target.checked,
                                row,
                              )
                           
                            }
                            defaultChecked={row.recstatus === "APPROVED"}
                          />
                        }
                        label=""
                      />
      </Stack>
    )
  } 

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode.mappingcode}&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${nameFilter}` }));
    } else {
      dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}` }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (filterData?.name || nameFilter) {
      dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}&st=${nameFilter || filterData.name}` }));
    } else {
      dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}` }));
    }
  }, [currentPage, perPage]);


  useEffect(() => {
    if (!openpopup) {
      dispatch(tableDataAction({
      url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage - 1}&size=${perPage}`
      }));
    }
    if (!openpopupRole) {
      dispatch(tableDataAction({
       url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage - 1}&size=${perPage}`
      }));
    }
  }, [openpopup,openpopupRole]);

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    const pinCode = row.pinCode || {};
    const address = `${pinCode.village?.name || ""}, ${
      pinCode.subdistrict?.name || ""
    }, ${pinCode.district?.name || ""}, ${pinCode.state?.name || ""} - ${
      pinCode.pincode || ""
    }`;
    return {
      ...row,
      address,
      action: actionButtons(row),
      action2:actionButtons2(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "recstatus", label: "Status"},
    { id:"createdbyname",  label: "Created By"},
    { id:"action2",  label: "Change Status"},
    { id: "action", label: "Action" },
    
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}` }));
    }
  };

  const handleClear = () => {
    setFilterData({});
    // navigate("/app/mic/create-mic")
    dispatch(tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}` }));
  };
  const handleOpenEditDialog = (row) => {
    setSelectedRole(row);
    setopenpopup(true);
  };
  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: `You will not be able to recover this ${row.name}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteRoleData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            dispatch(
              tableDataAction({ url: `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}&ispageable=true&page=${currentPage-1}&size=${perPage}` })
            );
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }
  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "5%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "5%" }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Role List
              </MDTypography>
              <MDButton color="success" onClick={() => setopenpopupRole(true)}>
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      <Roleedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Role Edit"}
        selectedRole={selectedRole}
        tableDataAction={tableDataAction}
      />
      <CreateRolePopup
        openpopupRole={openpopupRole}
        setopenpopupRole={setopenpopupRole}
      />
    </DashboardLayout>
  );
};

export default RoleList;
