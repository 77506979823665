import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";
import axios from "../../../../../../axiosinstance"

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";

import { useSelector } from "react-redux";
import { Description } from "@material-ui/icons";

function RegistrationInfo({ formData }) {
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context
 

  const [color] = useState("#344767");
  const [doctors,setDoctors]=useState([])

  const { formField, values, errors, touched } = formData;

  const handleChange = (event) => {
   
    const { name, value } = event.target;
  
    setFieldValue(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };

  const { firstName, lastName, age, email, mobilenumber,password,repeatPassword,experience,description } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    gender: genderV,
    doctor: doctorV,
experience:experienceV,
description:descriptionV,
    age: ageV,
    email: emailV,
    mobilenumber: mobilenumberV,
    role: roleV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  async function getData(code) {
   
    await axios
    .get(
      `login-service-mongo/api/loginservice/v2/get?rolecode=${code}&recstatus=APPROVED`
    )
      .then((response) => {
      

        
      
    setDoctors(response.data.data.dtoList)
       
        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }

  ;


  useEffect(() => {
   getData("DO001")
   
  }, []);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Registration Form</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>

      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.gender && touched.gender}>
              <InputLabel htmlFor="gender" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Gender</InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="gender"
                id="gender"
                value={genderV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
              {errors.gender && touched.gender && (
                <FormHelperText error>{errors.gender}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        
      
          

          <Grid item xs={12} sm={6}>
          <FormField
                type={mobilenumber.type}
                label={mobilenumber.label}
                name={mobilenumber.name}
                value={mobilenumberV}
                placeholder={mobilenumber.placeholder}
                error={errors.mobilenumber && touched.mobilenumber}
                success={mobilenumberV.length > 0 && !errors.mobilenumber}
   
              />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.role && touched.role}>
              <InputLabel htmlFor="role" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Role</InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="role"
                id="role"
                value={roleV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="DO001">Doctor</option>
                <option value="RE001">Nurse</option>
              </Select>
              {errors.role && touched.role && (
                <FormHelperText error>{errors.role}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          {roleV === "RE001" && (
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth error={!!errors.doctor && touched.doctor}>
                  <InputLabel htmlFor="doctor" style={{marginLeft: "-14px", paddingBottom:"2px", marginTop:"2px" }}>Select Doctor </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="doctor"
                    id="doctor"
                    value={doctorV}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option value="" disabled></option>
                  {doctors.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.profileid}
                                >
                                  {option.name}
                                </option>
                              ))}
                  </Select>
                  {errors.doctor && touched.doctor && (
                    <FormHelperText error style={{marginLeft:"0px"}}>{errors.doctor}</FormHelperText>
                  )}
                </FormControl>
              </Grid> )}
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={experience.type}
              label={experience.label}
              name={experience.name}
              value={experienceV}
              placeholder={experience.placeholder}
              error={errors.experience && touched.experience}
              success={experienceV.length > 0 && !errors.experience}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              error={errors.description && touched.description}
              success={descriptionV.length > 0 && !errors.description}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
       

          <Grid item xs={12} sm={6}>
          <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                 success={emailV.length > 0 && !errors.email}
             
              />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={age.type}
              label={age.label}
              name={age.name}
              value={ageV}
              placeholder={age.placeholder}
              error={errors.age && touched.age}
              success={ageV.length > 0 && !errors.age}
              InputLabelProps={{

style: { paddingBottom: "2px" }}}
            />
          </Grid>
         
        </Grid>
        <Grid container spacing={3}>
       

        <Grid item xs={12} sm={6}>
            
            <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
             </Grid>
        
             <Grid item xs={12} sm={6}>
            <FormField
                type={repeatPassword.type}
                label={repeatPassword.label}
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
              </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

RegistrationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};





export default RegistrationInfo;
