import React from "react";
import "./hover.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
const PatientNavbar = () => {
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);



 

  
  return (
    <div className="popover__content">

<div className="modal-area">
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   <p style={{ marginLeft: "10px",fontSize:"16px" }}>{auth?.name}</p>
   </div>
    
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person
        </Icon>

        <Link to="/app/pages/profile/profile-overview">My Profile</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          search
        </Icon>
        <Link to="/app/home/find-doctors">Search Doctor</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          check_circle
        </Icon>
        <Link to="/app/pages/users/my-booking">My Booking</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
        family_restroom
        </Icon>

        <Link to="/app/pages/users/family-list">My Family</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          medical_services
        </Icon>
        <Link to="/app/page/patient-Prescription">My Prescription</Link>
      </div>
     
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
        biotech
        </Icon>
        <Link to="/app/page/manage-emr">Mananage EMR</Link>
      </div>
     

      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          logout
        </Icon>
        <a href="#" onClick={() => reduxDispatch({ type: "LOGOUT_FULFILLED" })}>
  Log Out
</a>

      </div>
    </div>
  );
};




export default PatientNavbar;