import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  useMediaQuery,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import axios from "axiosinstance";
import ViewReport from "./ViewReport";
import { CircularProgress} from "@mui/material";
import TourIcon from '@mui/icons-material/Tour';
const DynamicformPopup = (props) => {
  const {
    opendynamicform,
    setOpenDynamicform,
    selecteddata,
    onClose,
    handleApi,
  } = props;
  const [selectedTest, setSelectedTest] = useState("");
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [viewReport, setViewReport] = useState(false);
  const [categorydata, setCategorydata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [testloading,setTestloading] = useState(false)
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const handleTestChange = (event) => {
    const selectedValue = event.target.value;
    
    // Check if the selected value already exists in the category list
    const isTestAlreadyCreated = selecteddata?.categorylist?.some(
      (obj) => obj?.parentdepcode === selectedValue
    );
  
    if (isTestAlreadyCreated) {
      swal("Oops",   `${selecteddata?.pricelist
        .filter(i => i?.code === selectedValue)
        .map(i => `${i?.pricename}`)
      } is already created`, "error");
    } else {
      getCategorylist(selectedValue);
      setSelectedTest(selectedValue);
      setFormData({});
      setErrors({});
    }
  };
  

  async function getCategorylist(code) {
    setTestloading(true)
    await axios
      .get(`${APIs.categoryService}/get?parentdepcode=${code}`)
      .then((response) => {
        setCategorydata(response?.data?.data?.dtoList);
        setTestloading(false)
      })
      .catch((e) => {
        console.log("error", e);
        setTestloading(false)
      });
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } else {
      const currentField = categorydata.find(
        (test) => test.code === selectedTest
      );
      if (currentField) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: `${currentField.name} is required`,
        }));
      }
    }
  };

  const validateFields = () => {
    const tempErrors = {};
    categorydata.forEach((field) => {
      if (!formData[field.name]) {
        tempErrors[field.name] = `${field.name} is required`;
      } else if (field.type === "number" && isNaN(formData[field.name])) {
        tempErrors[field.name] = `${field.name} must be a number`;
      }
    });

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const finalSubmit = async (data) => {
    setLoading(true);

    const updatedArr = categorydata?.map((item) => {
      const testName = item.name;
      if (data[testName] !== undefined) {
        return { ...item, value: data[testName] };
      }
      return item; // Return the original item if there's no match
    });

    addCategory(updatedArr);
  };
 

  async function addCategory(bookingobj) {
   
    setLoading(true);
    const updatedCategoryList = [...(selecteddata?.categorylist || [])];
    const data = {
      ...selecteddata,
      categorylist: [...updatedCategoryList, ...bookingobj], // Merge old and new categories
    };

    try {
      await axios.put(`${APIs.bookingServiceApi}/update`, data);
      handleApi();
      handleClose();
      swal("Good job!", 
        `${selecteddata?.pricelist
          .filter(i => i?.code === selectedTest)
          .map(i => `${i?.pricename}`)
        } Report has been successfully created.`, 
        "success"
      );
    } catch (e) {
      console.log("error", e);
      swal("Error", "Something wents wrong.", "error");
    } finally {
      setLoading(false);
    }
  } 

  const handleSubmit = () => {
    if (!selectedTest) {
      swal("Validation Error", "Please select a test.", "error");
      return;
    }
    if (validateFields()) {
      const FormData = {
        ...formData,
        type: selectedTest,
      };
      finalSubmit(FormData);
    }
  };

  const handleClose = () => {
    setOpenDynamicform(false);
  };
  const testFields = selectedTest
    ? categorydata?.find((test) => test?.name === selectedTest)?.fields || []
    : [];

  return (
    <Dialog
      open={opendynamicform}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        Create Report
      </DialogTitle>
      <DialogContent>
      {selecteddata?.categorylist?.length > 0 && (
  <Grid mt={2}>
    <MDButton
      color="info"
      size="small"
      onClick={() => setViewReport(true)}
    >
      View Status
    </MDButton>
  </Grid>
)}

  <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel
            style={{
              marginLeft: "-14px",
              paddingBottom: "2px",
              marginTop: "2px",
            }}
          >
            <span>
              Select Lab Test
              <sup
                style={{
                  color: "red",
                  fontSize: "small",
                  fontWeight: "bolder",
                  position: "relative",
                  top: "2px",
                }}
              >
                {" "}
                *
              </sup>
            </span>
          </InputLabel>
          <Select
            native
            fullWidth
            variant="standard"
            style={{ width: "100%" }}
            value={selectedTest}
            onChange={handleTestChange}
            sx={{ marginLeft: "0px" }}
          >
            <option value=""></option>
            {selecteddata?.pricelist?.map((option) => (
              <option key={option?.id} value={option?.code}>
                {option?.pricename}
              </option>
            ))}
          </Select>
        </FormControl>
        {testloading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
<>

        {categorydata?.length > 0 && (
          <Grid container spacing={3}>
            {categorydata?.map((field, index) => (
              <React.Fragment key={index}>
                <Grid item xs={12}>
                  <b><TourIcon/> : {field?.parametervalue}</b>
                </Grid>
                <Grid item xs={10}>
                  <MDInput
                    type="text"
                    variant="standard"
                    label={
                      <span>
                        {field.name}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </span>
                    }
                    name={field.name}
                    value={formData[field.name] || ""}
                    onChange={handleInputChange}
                    fullWidth
                    error={!!errors[field.name]}
                    helperText={errors[field.name]}
                    FormHelperTextProps={{ style: { color: "red" } }}
                  />
                </Grid>
                <Grid item xs={2}>
                  {field?.parametername}
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        )}
        </>
         )}
      </DialogContent>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </MDButton>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleSubmit}
        >
          {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
        </MDButton>
      </DialogActions>
      {viewReport && (
        <ViewReport
          viewReport={viewReport}
          setViewReport={setViewReport}
          selecteddata={selecteddata}
          handleApi={handleApi}
        />
      )}
    </Dialog>
  );
};

export default DynamicformPopup;
