
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../../axiosinstance";
//create role

export const createDepartment = createAsyncThunk("createDepartment", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.post(
      `department-service-mongo/api/department/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getDepartment = createAsyncThunk("getDepartment", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.get(
      `department-service-mongo/api/department/v2/get`,
      
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateDepartment = createAsyncThunk("updateDepartment", async (data, {rejectWithValue}) => {


  try {
    const response = await axios.put(
      `department-service-mongo/api/department/v2/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteDepartment = createAsyncThunk("deleteDepartment", async (data, {rejectWithValue}) => {

  try {
    const response = await axios.put(
      `department-service-mongo/api/department/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




