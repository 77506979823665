import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { APIs } from "Services/APIs";
import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import { useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance";

import { MoonLoader } from "react-spinners";

import FamilyInfo from "./components/FamilyInfo";
import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { useSelector } from "react-redux";

function getSteps() {
  return ["Family Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <FamilyInfo formData={formData} />;
    default:
      return null;
  }
}

function AddFamilymember() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const isLastStep = activeStep === steps.length - 1;

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);

  // Function to submit the form

  async function otpData(code) {
    const otp = {
      createdby: "HEALTHCARE WEB UI",
      id: null,

      otptype: "EMAIL",
      recstatus: "OPEN",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",

      userid: code,
    };
    // setIsLoading(true);
    await axios
      .post(
        // 'http://194.163.159.164:9163/api/Otps/v1/create',
        // otp
        // 'http://194.163.159.164:9163/api/Otps/v1/create',otp
        `${APIs?.otpServiceApiv1}/create`,
        otp
      )

      .then((response) => {
        navigate("/app/page/admin-otp");

        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage,
          "warning"
        );
        // setIsLoading(false);
      });
  }

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const formData = {
      createddate: null,
      age: values.age,
      sex: values.gender,
      documentofproof: "",
      emailid: values.email,
      name: values.lastName
        ? values.firstName + " " + values.lastName
        : values.firstName,
      id: null,
      isactive: "ACTIVE",

      rolename: "PATIENT",
      rolecode: "PA001",
      isfamilymember: "true",
      mobileno: values.mobilenumber,
      relation: values.relation,
      password: "123456",
      profileid: "",
      recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",

      createdby: auth?.profileid,
    };
    axios
      .post(`${APIs.loginServiceApi}/create`, formData)
      .then((response) => {
        setIsLoading(true);
        swal(
          "Success!",
          "A new family member has been added successfully.",
          "success"
        );
        navigate("/app/patient/family-list");
      })
      .catch((error) => {
        swal(
          "Oops!",
          error?.response &&
            error.response?.data &&
            error?.response?.data?.aceErrors
            ? error?.response?.data?.aceErrors?.[0]?.errorCode === "K008"
              ? "User Already Registered with the same Email id !!"
              : error?.response?.data?.aceErrors?.[0]?.errorMessage
            : "Request failed with status code 404.",
          "warning"
        );
        setIsLoading(false);
      });
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      {/* {isMidScreen ? <></> : <Navbar />}
      {isMidScreen ? <MobileNavbar /> : <></>} */}

      <MDBox py={3} mb={isMidScreen ? 40 : 20} height="65vh" mt={23}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Family Member
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>

      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar /> : <></>}
    </>
  );
}

export default AddFamilymember;
