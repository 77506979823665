import React, { useEffect, useState } from "react";
import "./centerstyle.css";
import filter1 from "../../findDoctor/assets/images/Page-1.svg";
import filter2 from "../../findDoctor/assets/images/dropdownclose1.svg";
import line1 from "../../findDoctor/assets/images/Line 1.svg";
import CategoryDropdown from "./CategoryDropdown";
import { PriceDropdown } from "./priceDropdown";
// import { Loader } from "@googlemaps/js-api-loader";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Center = () => {
  const [centerDropdownVisible, setCenterDropdownVisible] = useState(false);
  const [priceDropdownVisible, setPriceDropdownVisible] = useState(false);
  const [rangeDropdownVisible, setRangeDropdownVisible] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  // useEffect(() => {
  //     const loadGoogleMapScript = async () => {
  //         const loader = new Loader({
  //             apiKey: "AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0",
  //             version: "weekly"
  //         });

  //         try {
  //             await loader.load();
  //             initMap();
  //         } catch (error) {
  //             console.error("Error loading Google Maps API:", error);
  //         }
  //     };

  //     const initMap = () => {
  //         if (currentLocation) {
  //             const map = new window.google.maps.Map(document.getElementById("map"), {
  //                 center: currentLocation,
  //                 zoom: 8
  //             });

  //             const directionsService = new window.google.maps.DirectionsService();
  //             const directionsDisplay = new window.google.maps.DirectionsRenderer();
  //             directionsDisplay.setMap(map);

  //             const end = "Location2";

  //             const request = {
  //                 origin: currentLocation,
  //                 destination: end,
  //                 travelMode: window.google.maps.TravelMode.DRIVING
  //             };

  //             directionsService.route(request, function (response, status) {
  //                 if (status === window.google.maps.DirectionsStatus.OK) {
  //                     directionsDisplay.setDirections(response);
  //                 } else {
  //                     console.error("Directions request failed due to " + status);
  //                 }
  //             });
  //         }
  //     };

  //     if (rangeDropdownVisible) {
  //         loadGoogleMapScript();
  //     }
  // }, [rangeDropdownVisible, currentLocation]);

  // useEffect(() => {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition(
  //             position => {
  //                 setCurrentLocation({
  //                     lat: position.coords.latitude,
  //                     lng: position.coords.longitude
  //                 });
  //             },
  //             error => {
  //                 console.error("Error getting user location:", error);
  //                 setCurrentLocation({ lat: 37.7749, lng: -122.4194 });
  //             }
  //         );
  //     } else {
  //         console.error("Geolocation is not supported by this browser.");
  //         setCurrentLocation({ lat: 37.7749, lng: -122.4194 });
  //     }
  // }, []);
  const [mapCenter, setMapCenter] = useState({
    lat: 27.1766701,
    lng: 78.0080745,
  });
  const [searchedLocation, setSearchedLocation] = useState(null);
  return (
    <div
      style={{
        // width: "1512px",
        // height: "1200px",
        background: "#F2F4EA",
        top: "80px",
      }}
    >
      <div className="frame-5">
        <div>
          <div className="filters">
            {!centerDropdownVisible && (
              <div onClick={() => setCenterDropdownVisible(true)}>
                <div className="text-wrapper22">Category</div>
                <img className="page22" alt="Page" src={filter1} />
              </div>
            )}
            {centerDropdownVisible && (
              <div onClick={() => setCenterDropdownVisible(false)}>
                <div className="text-wrapper23">Category</div>
                <img className="page22" alt="Page" src={filter2} />
              </div>
            )}
            {centerDropdownVisible && (
              <>
                <CategoryDropdown />
              </>
            )}
          </div>
        </div>

        <img className="line" alt="Line" src={line1} />
        <div>
          <div className="filters">
            {!priceDropdownVisible && (
              <div onClick={() => setPriceDropdownVisible(true)}>
                <div className="text-wrapper22">Price</div>
                <img className="page22" alt="Page" src={filter1} />
              </div>
            )}
            {priceDropdownVisible && (
              <div onClick={() => setPriceDropdownVisible(false)}>
                <div className="text-wrapper23">Price</div>
                <img className="page22" alt="Page" src={filter2} />
              </div>
            )}
            {priceDropdownVisible && (
              <>
                <PriceDropdown />
              </>
            )}
          </div>
        </div>

        <img className="line" alt="Line" src={line1} />
        <div>
          <div className="filters">
            {!rangeDropdownVisible && (
              <div onClick={() => setRangeDropdownVisible(true)}>
                <div className="text-wrapper24">Range in Kms</div>
                <img className="page22" alt="Page" src={filter1} />
              </div>
            )}
            {rangeDropdownVisible && (
              <div onClick={() => setRangeDropdownVisible(false)}>
                <div className="text-wrapper25">Range in Kms</div>
                <img className="page22" alt="Page" src={filter2} />
              </div>
            )}
            {rangeDropdownVisible && (
              <>
                <div className="rangedropdownmaindiv">
                  <div className="rangeinkmlocation">
                    <LoadScript googleMapsApiKey="AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0">
                      <GoogleMap
                        center={mapCenter}
                        zoom={8}
                        mapContainerStyle={{ height: "100%", width: "100%" }}
                        options={{
                          fullscreenControl:false,
                          streetViewControl: false,
                          mapTypeControl: false,
                          zoomControl: false,
                        }}
                      >
                        <Marker position={searchedLocation} />
                      </GoogleMap>
                    </LoadScript>
                  </div>
                  <div className="pricwdropdowninputdiv">
                    <div className="rangedropdownprice-input">
                      <TextField
                        label="From"
                        variant="standard"
                        defaultValue="Location1"
                      />
                    </div>
                    <div className="rangedropdownprice-input">
                      <TextField
                        label="To"
                        variant="standard"
                        defaultValue="Location2"
                      />
                    </div>
                  </div>
                  <div className="rangedropdownresult-buttons-container">
                  
                    <button className="centerrangebutton1">
                    Clear all 
                    </button>
                   
                      <button className="centerrangebutton12">
                      Show 40 results
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Center;
