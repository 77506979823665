
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create User

export const createProjectpincode = createAsyncThunk("createProjectpincode", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `/project-service/api/ProjectPincode/v1/create`, 
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getProjectpincode = createAsyncThunk("getProjectpincode", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `/project-service/api/ProjectPincode/v1/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateProjectpincode = createAsyncThunk("updateProjectpincode", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `/project-service/api/ProjectPincode/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteProjectpincode = createAsyncThunk("deleteProjectpincode", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `/project-service/api/ProjectPincode/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




