import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import MDBox from "components/MDBox";
import { Dialog, FormControlLabel, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../axiosinstance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "components/DashboardLayout";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { useDispatch } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
 

function LabCategory(props) {
  const { popup, setPopup,onUpdate} = props;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [idCounter, setIdCounter] = useState(0);
  const [categoryname, setCategoryname] = useState(null);
  const { getUserCategory, getProjectDetails } = useSelector((state) => state);
  const [subcategoryresponse, setSubcategoryresponse] = useState("");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  // const {getMMUDetails, } = useSelector(state => state)
  const location = useLocation();
  const initialValues = {
    category: "",
  };

  const validationSchema = Yup.object({
    category: Yup.string().required("Category is required."),
  });

  console.log(data);
  const handleSubmit = (values, { resetForm }) => {
    const newData = {
      uid: idCounter,
      category: categoryname,
      showcategoryname: categoryname?.name,
    };
    const userCategoryIds =
    getUserCategory?.category?.map((i) => i?.category?.code) || [];
    const isSpecializationIdInCategory = userCategoryIds.includes(
      categoryname?.code
    );
  
    if (!isSpecializationIdInCategory) {
      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      console.log("hi")
      resetForm();
    } else {
      swal("", "This category has already been registered to you.", "warning");
    }

  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "category", accessor: "showcategoryname" },

    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];
  async function finasubmit() {
    setLoading(true);
    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setLoading(false);
    } else {
      var objectList = [];
      for (let i = 0; i < data.length; i++) {
        const specailizationData = {
          category: { ...data[i].category, type: "CAT" },
          createdby: auth.profileid,
          createddate: "",
          documentofproof: "",
          id: null,
          latlong: "",
          location: "",
          mmucode:auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode ? getProjectDetails?.details?.mmucreation?.code : "",
          modifiedby: "",
          modifieddate: "",
          profileid:
            auth?.rolecode === "AD001"
              ? location?.state?.profileid
              : auth?.profileid,
          recstatus: "OPEN",
          resourcecode: "HEALTHCARE",
          status: "Active",
          syncstatus: "SYNCED",
          tempid: "",
          userid: "string",
        };
        objectList.push(specailizationData);
      }
      console.log("specailizationData", objectList);

      await axios
        .post("usercategory-service/api/Usercategory/v1/createList", objectList)

        .then((response) => {
          swal("Good job!", "Category has been Added!", "success");
          setLoading(false);
          handleClose();
          onUpdate(auth?.rolecode === ROLE_CODE.admincode ? selectedUser?.profileid : auth?.profileid);
          // navigate("app/pharmacy/profile");
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setLoading(false);
        });
    }
  }


  async function getCategory() {
    await axios
      .get(`category-service/api/Category/v1/get?type=labtest`)
      .then((response) => {
        setCategoryList(response?.data?.data?.dtoList);

        // setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const handleClose = () => {
    setPopup(false);
  };
  useEffect(() => {
    if(auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode){
      dispatch(userCategoryAction({ mmucode:getProjectDetails?.details?.mmucreation?.code}));
    }else{
    dispatch(userCategoryAction({ profileid: auth.profileid}));
  }
    // dispatch(userCategoryAction({ profileid: auth?.profileid }));
  }, []);
  useEffect(() => {
    getCategory();
  }, []);

  return (
    <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
    <MDBox py={3}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt:  3 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ values, setFieldValue, errors, touched }) => (
              <Form>
                {/* <Card sx={{ height: "100%" }}> */}
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-1}
                      p={3}
                      mb={1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="medium"
                        color="white"
                        mt={2}
                      >
                        Category Information
                      </MDTypography>
                      <MDTypography
                        display="block"
                        variant="button"
                        color="white"
                        my={1}
                      >
                        Enter your detail
                      </MDTypography>
                    </MDBox>
                    {auth.rolecode === "AD001" ? (
                      <MDTypography variant="h5">
                        Selected : {location?.state?.name}
                      </MDTypography>
                    ) : null}
                  </MDBox>
                  <MDBox p={3}>
                    <Grid container spacing={3} mt={1} alignItems="center">
                      <Grid item xs={12} sm={5}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="category"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Category{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="category"
                            id="category"
                            value={values.category}
                            onChange={(e) => {
                              const selectedMode = e.target.value;
                              setFieldValue("category", selectedMode);

                              // Find the selected object from categoryList
                              const selectedObject = categoryList.find(
                                (obj) => obj.hierarchicalcode === selectedMode
                              );

                              setCategoryname(selectedObject);
                              // getSubCategory(2, selectedMode);
                            }}
                            error={touched.category && Boolean(errors.category)}
                          >
                            <option value=""></option>
                            {categoryList.map((obj) => (
                              <option key={obj.id} value={obj.hierarchicalcode}>
                                {obj.name}
                              </option>
                            ))}
                          </Select>

                          <ErrorMessage
                            name="category"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                     
                      <Grid item xs={12} sm={2}>
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          size="small"
                        >
                          Add
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>

                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      border: "1px solid #727375",
                      borderRadius: "5px",
                      marginTop: "15px",
                      marginBottom: "10px",
                      marginLeft: "5px",
                      marginRight: "5px",
                    }}
                  >
                    <DataTable
                      showTotalEntries={false}
                      entriesPerPage={false}
                      table={{ columns, rows: data }}
                      imageMaxWidth={"100px"}
                    />
                  </Grid>
                  <MDBox
                    display="flex"
                    justifyContent="flex-end"
                    marginRight="10px"
                    marginBottom="8px"
                  >
                    <MDButton
                      variant="gradient"
                      color="info"
                      onClick={() => finasubmit()}
                    >
                      {loading ? (
                        <MoonLoader color="#f2fefa" size={16} />
                      ) : (
                        "submit"
                      )}
                    </MDButton>
                  </MDBox>
                {/* </Card> */}
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
    </Dialog>
  );
}

export default LabCategory;
