import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { designationAction } from "redux/Actions/designationAction";

const initialState = {
  designationList: [] ,
  loading: false,
  error: null,
};

export const getDesignationSlice = createSlice({
  name: "loginUserData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(designationAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(designationAction.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload 22")
        state.loading = false;
        state.designationList = action.payload;
        state.error = null;
      })
      .addCase(designationAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getDesignationSlice.reducer;
