import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "../FormField";
import MDButton from "components/MDButton";
import MapPopup from "../Map/MapPopup";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { BarLoader } from "react-spinners";

import { getState } from "components/Resuable/StateMaster/service/State";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";

function AddressDetail({ formData }) {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();
  const { formField, values, errors, touched } = formData;
  const [district, setDistric] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [color] = useState("#344767");

  const { statelist, loading } = useSelector((state) => state.statelist);

  const [openpopup, setopenpopup] = useState(false);
  // Inside the module where auth is defined, export it

  const { firstline, secondline, city, state, country, zip, location } =
    formField;
  const {
    firstline: firstlineV,
    secondline: secondlineV,
    country: countryV,
    location: locationV,
  } = values;

  const handleLocationButtonClick = () => {
    setopenpopup(true);
  };
  //distrtic data function
  async function getDistrictData(statecode) {
    const response = await dispatch(getDistrict(statecode));

    setDistric(response.payload.data.dtoList);
  }

  //get village data
  async function getVillageData(statecode, districtcode, subDistrictcode) {
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    setPincodeList(response.payload?.data?.dtoList);
  }
  //subdistric data
  async function getSubDistrictData(statecode, districtCode) {
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };

    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList);
  }
  const handleDataTransfer = (data) => {
    values.location = [data.lat, data.lng];
  };

  //pincodefilter
  async function pincodefilter() {
    const data = {
      statecode: values.state,
      districtCode: values.district,
      subDistrictcode: values.subDistrict,
    };
    const response = await dispatch(getPincode(data));
    setPincodeList(response.payload?.data?.dtoList);
  }

  const districtFilter = async () => {
    if (values.district !== "") {
      const response = await dispatch(getDistrict(values.state));

      setDistric(response.payload.data.dtoList);
    }
  };

  async function subdistrictfilter() {
    const data = {
      statecode: values.state,
      districtCode: values.district,
    };

    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList);
  }

  useEffect(() => {
    dispatch(getState());
    if (values.district !== "") {
      districtFilter();
    }
    if (values.subDistrict !== "") {
      subdistrictfilter();
    }
    if (values.pincode !== "") {
      pincodefilter();
    }
  }, []);

  return (
    <MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
                <FormField
                  type={firstline.type}
                  label=<span>
                    {firstline.label}{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={firstline.name}
                  value={firstlineV}
                  placeholder={firstline.placeholder}
                  error={errors.firstline && touched.firstline}
                  success={firstlineV.length > 0 && !errors.firstline}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={6}>
              <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
                <FormField
                  type={secondline.type}
                  label=<span>
                    {secondline.label}{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={secondline.name}
                  value={secondlineV}
                  placeholder={secondline.placeholder}
                  error={errors.secondline && touched.secondline}
                  success={secondlineV.length > 0 && !errors.secondline}
                />
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.state && touched.state}>
                <InputLabel
                  htmlFor="state"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  State
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="state"
                  id="state"
                  value={values.state}
                  onChange={(e) => {
                    const state = e.target.value;

                    getDistrictData(e.target.value);
                    setFieldValue("state", state);
                  }}
                >
                  <option value=""></option>
                  {statelist.map((obj) => (
                    <option key={obj.id} value={obj.state?.code}>
                      {obj.state.name}
                    </option>
                  ))}
                </Select>
                {errors.state && touched.state && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.state}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.district && touched.district}
              >
                <InputLabel
                  htmlFor="district"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  District
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="district"
                  id="district"
                  value={values.district}
                  onChange={(e) => {
                    const districtvalue = e.target.value;
                    const selectedDistrictobj =
                      district?.find(
                        (obj) => obj.district.code === e.target.value
                      ) || {};
                    getSubDistrictData(
                      selectedDistrictobj.state?.code,
                      selectedDistrictobj.district?.code
                    );
                    setFieldValue("district", districtvalue);
                  }}
                >
                  <option value=""></option>
                  {district.map((obj) => (
                    <option key={obj.id} value={obj.district?.code}>
                      {obj.district?.name}
                    </option>
                  ))}
                </Select>
                {errors.district && touched.district && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.district}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.subDistrict && touched.subDistrict}
              >
                <InputLabel
                  htmlFor="subDistrict"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Sub District
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="subDistrict"
                  id="subDistrict"
                  value={values.subDistrict}
                  onChange={(e) => {
                    const subDistrictvalue = e.target.value;
                    const selectedPincodeobj =
                      subdistrict?.find(
                        (obj) => obj.subdistrict?.code === e.target.value
                      ) || {};

                    getVillageData(
                      selectedPincodeobj.state?.code,
                      selectedPincodeobj.district?.code,
                      selectedPincodeobj.subdistrict?.code
                    );

                    setFieldValue("subDistrict", subDistrictvalue);
                  }}
                >
                  <option value=""></option>
                  {subdistrict.map((obj) => (
                    <option key={obj.id} value={obj.subdistrict.code}>
                      {obj.subdistrict.name}
                    </option>
                  ))}
                </Select>
                {errors.subDistrict && touched.subDistrict && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.subDistrict}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.pincode && touched.pincode}
              >
                <InputLabel
                  htmlFor="pincode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(values.pincode)}
                >
                  Pincode
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="pincode"
                  id="pincode"
                  value={values.pincode}
                  onChange={(e) => {
                    const pincode = e.target.value;
                    const selectedPincodeObj =
                      pincodelist.find(
                        (obj) => obj.village.code === e.target.value
                      ) || {};

                    setFieldValue("pincodeobj", selectedPincodeObj);
                    setFieldValue("pincode", pincode);
                  }}
                >
                  <option value=""></option>
                  {pincodelist.map((obj) => (
                    <option key={obj.id} value={obj.village.code}>
                      {obj.village?.name}
                    </option>
                  ))}
                </Select>
                {errors.pincode && touched.pincode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.pincode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
                <FormField
                  type={country.type}
                  label=<span>
                    {country.label}{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={country.name}
                  value={countryV}
                  placeholder={country.placeholder}
                  error={errors.country && touched.country}
                  success={countryV.length > 0 && !errors.country}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={4}>
              <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
                <FormField
                  type={location.type}
                  label=<span>
                    {location.label}{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={location.name}
                  disabled
                  value={locationV}
                  placeholder={location.placeholder}
                  error={errors.location && touched.location}
                  success={locationV.length > 0 && !errors.location}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={2}>
              <MDButton
                variant="outlined"
                color="info"
                size="small"
                onClick={handleLocationButtonClick}
              >
                Location{" "}
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}
      {openpopup ? (
        <MapPopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Select Location"}
          onDataTransfer={handleDataTransfer}
          data2={values}
        />
      ) : (
        <></>
      )}
    </MDBox>
  );
}

// typechecking props for AddressDetail
AddressDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AddressDetail;
