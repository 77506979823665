import { createSlice } from '@reduxjs/toolkit'
 
const initialState = {
  details: {},
}
 
export const doctorDetailsSlice = createSlice({
  name: 'doctorDetails',
  initialState,
  reducers: {
    setDoctorDetailst: (state, payload) => {
      state.details = payload;
    },
    clearDoctorDetails: (state) => {
      state.details = {}
    },
  },
})
 
export const { setDoctorDetails, clearDoctorDetails } = doctorDetailsSlice.actions
 
export default doctorDetailsSlice.reducer