import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import axios from ".././../../../../axiosinstance";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { FormControl, Grid, Select } from "@mui/material";
import MDInput from "components/MDInput";
import { useDispatch } from "react-redux";
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  gender: Yup.string().required("Gender is required"),
  age: Yup.number().required("Age is required").positive("Age must be positive"),
  mobileno: Yup.string().matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits').required("Mobile number is required"),
});

export default function EditProfile({ onClose, updateName }) {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const formik = useFormik({
    initialValues: {
      name: auth.name,
      gender: auth.sex,
      age: auth.age,
      mobileno: auth.mobileno,

    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log(values)
        setIsLoading(true)
      

      const data = {
        ...auth,
        sex: values.gender,
        name: values.name,
        age: values.age,
        mobileno: values.mobileno,
      };

      try {
        const response = await axios.put(
          "login-service-mongo/api/loginservice/v2/update",
          data
        );

        localStorage.setItem(
          "login-auth",
          JSON.stringify(response.data?.data?.dto)
        );
        localStorage.setItem(
          "profile-Name",
          JSON.stringify(response.data?.data?.dto?.name)
        );
        dispatch({
          type: "UPDATE_AUTH_DATA",
          payload: response.data.data.dto,
        });
        // window.location.reload();
        handleClose();
     
        resetForm();
        setIsLoading(false)
      } catch (error) {
        console.error("API error:", error);
        setIsLoading(false)
        swal("","Something went wrong.","error");
      }
    },
  });

  return (
    <div>
     
     <Dialog open={true} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >Edit Info</DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
           


            <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="name">Name</label>
              <MDInput
                type="text"
                id="name"
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              {formik.touched.age && formik.errors.age ? (
                <div style={{color:"red"}}>{formik.errors.name}</div>
              ) : null}
               </FormControl>
            </Grid>

            <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="age">Age</label>
              <MDInput
                type="number"
                id="age"
                name="age"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.age}
              />
              {formik.touched.age && formik.errors.age ? (
                <div style={{color:"red"}}>{formik.errors.age}</div>
              ) : null}
               </FormControl>
            </Grid>

            <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="gender">Gender</label>
              <Select
               native
               variant="outlined"
               style={{ width: "100%" }}
                id="gender"
                name="gender"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.gender}
              >
                <option value="" label="Select Gender" />
                <option value="Male" label="Male" />
                <option value="Female" label="Female" />
                <option value="Others" label="Others" />
              </Select>
              {formik.touched.gender && formik.errors.gender ? (
                <div style={{color:"red"}}>
                  {formik.errors.gender}
                </div>
              ) : null}
             </FormControl>
             </Grid>

           
            <Grid item xs={12}>
            <FormControl fullWidth>
              <label htmlFor="age">Mobile number</label>
              <MDInput
                type="number"
                id="mobileno"
                name="mobileno"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobileno}
              />
              {formik.touched.mobileno && formik.errors.mobileno ? (
                <div style={{color:"red"}}>{formik.errors.mobileno}</div>
              ) : null}
               </FormControl>
            </Grid>
          </DialogContent>

          <DialogActions>
          <MDButton color="info"
          size="small" onClick={handleClose}>Cancel </MDButton>
            <MDButton
                    type="submit"
                    size="small"
                    variant="gradient"
                    color="info"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Submit"
                    )}
                    </MDButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}
