import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{resourcename},
} = checkout;
 
const validations = [
  Yup.object().shape({
    [resourcename.name]: Yup.string().required(resourcename.errorMsg),
   
    
  }),
 
];
 
export default validations