import ProfileOverview from "layouts/pages/profile/profile-overview";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import FindDoctor from "app/findDoctor/FindDoctor";
import Icon from "@mui/material/Icon";





const routesMic = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/app/mic/profile",
        component: <ProfileOverview />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/app/home/find-doctors",
        component: <FindDoctor />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",

    name: "User Management",

    key: "usermanagement",
        icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        type: "item",
        name: "Create User",
        key: "user",
        route: "/app/mic/user",
       
      },
      {
        type: "item",
        name: "Approval List",
        key: "user",
        route: "/app/mic/approval-list",
       
      },
      // {
      //   type: "item",
      //   name: "Create Role",
      //   key: "role",
      //   route: "/app/mic/role",
      
      // },
      // {
      //   type: "item",
      //   name: "Create Department ",
      //   key: "department ",
      //   route: "/app/mic/create-department",
   
      // },
      // {
      //   type: "item",
      //   name: "Create Designation",
      //   key: "designation ",
      //   route: "/app/mic/create-designation",
    
      // },
    ],
  },
  {
    type: "collapse",

    name: "MIC Creation",

    key: "miccreation",
        icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        type: "item",
        name: "Create MIC",
        key: "createmic",
        route: "/app/mic/create-mic",
      
      },
      // {
      //   type: "item",
      //   name: "Create MIC",
      //   key: "createmic",
      //   route: "/app/mic/mic-list",
      
      // },
      // {
      //   type: "item",
      //   name: "Pincode Association",
      //   key: "micpincodeassociation",
      //   route: "/app/mic/pincode-association-list",
      
      // },
      {
        type: "item",
        name: "Team Association",
        key: "micteamassociation",
        route: "/app/mic/team-association-list",
      
      },


    ],
  },
  {
    type: "collapse",

    name: "Clinic Management",

    key: "clinicmanagement",
        icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        type: "item",
        name: "User",
        key: "user",
        route: "/app/mic/clinicuser",
      
      },
      {
        type: "item",
        name: "Role",
        key: "role",
        route: "/app/mic/clinicrole",
       
      },
    
    ],
  },
  {
    type: "collapse",

    name: "MIC Masters",

    key: "micmasters",
        icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Admin",
        key: "admin",
        type: "item",
        route: "/app/mic/admin",
      
      },
      {
        type: "item",
        name: "Agents",
        key: "agent",
        route: "/app/mic/agents/analytics",
      },
    
    
    
      {
        type: "item",
        name: "Doctors",
        key: "doctor",
        route: "/app/mic/doctors/analytics",
      },
      {
        type: "item",
        name: "Nurses",
        key: "nurse",
        route: "/app/mic/nurse/analytics",
      },
      {
        type: "item",
        name: "Pharmacies",
        key: "pharmacies",
        route: "/app/mic/pharmacies",
      },
      {
        type: "item",
        name: "Labs",
        key: "labs",
        route: "/app/mic/labs",
      },
      {
        type: "item",
        name: "Clinics",
        key: "clinic",
        route: "/app/mic/clinics/analytics",
      },
      {
        type: "item",
        name: "Resources",
        key: "resource",
        route: "/app/mic/resources/analytics",
      },
      
      
    ],
  },
  
 

  

];

export default routesMic;
