import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { allFilters } from "components/Filters/FilterCmpMd";
// import { userList } from "components/Tables/TableCmpMd";
import { tableDataAction } from "redux/Actions/tableDataAction";
// import { FilterArrayType } from "types/filter";
// import { ReturnDataType } from "types/filterData";

interface TableDataState {
  rowData: any; 
  filterArray: any[];
  loading: boolean;
  error: any;
}

// interface TableDataActionReturnValue {
//   userList: any[]; 
//   filterArray: FilterArrayType 
// }

const initialState: TableDataState = {
  rowData: {} ,
  filterArray:allFilters,
  loading: false,
  error: null,
};

export const tableDataSlice = createSlice({
  name: "tableData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(tableDataAction.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error state when pending
      })
      .addCase(tableDataAction.fulfilled, (state, action: PayloadAction<{userList:any, filterArray: any[]}>) => {
        state.loading = false;
        state.rowData = action.payload?.userList;
        state.filterArray = action.payload?.filterArray;
        state.error = null; // Reset error state when fulfilled
      })
      .addCase(tableDataAction.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
  // extraReducers: {
  //   [tableDataAction.pending]: (state) => {
  //     state.loading = true;
  //   },
  //   [tableDataAction.fulfilled]: (state, action) => {
  //     console.log("state 19 slices",action.payload)
  //     state.loading = false;
  //     state.rowData = action.payload?.userList;
  //     state.filterArray = action.payload?.filterArray;
  //   },
  //   [tableDataAction.rejected]: (state, action) => {
  //     state.loading = false;
  //     state.error = action.payload;
  //   }
  // },
  
});

export default tableDataSlice.reducer;
