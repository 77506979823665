import { Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./emailSubscribe.css";
import Mail from "../../../../../assets/svg/Mail.svg";
import Arrow from "../../../../../assets/svg/Arrow.svg";

const EmailSubscribe = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1300);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="rootemailSubscribe">
      <Grid container mt={5} mb={10} xs={9.3} className="emailSubscribeitem">
        <Grid
          item
          xs={isSmallScreen ? 12 : undefined}
          mr={isSmallScreen ? 0 : 7}
          ml={isSmallScreen ? 0 : 1}
          mb={isSmallScreen ? 2 : 0}
          className="emailSubscribeimgitem"
        >
          <img src={Mail} alt="Logo" className="emailSubscribeimg" />
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : undefined}
          mr={isSmallScreen ? 0 : 10}
          mb={isSmallScreen ? 2 : 0}
          className="emailSubscribetext"
        >
          Subscribe to our newsletter
          <br />
          for latest updates and offers
        </Grid>
        <Grid
          item
          mr={2}
          className="emailSubscribeTextFieldContainer"
        >
          <input
            type="text"
            id="customInput"
            placeholder="Enter your email"
            className="customInput"
          />
        </Grid>
        <Grid item>
          {isSmallScreen ? (
            <button className="emailSubscribebutton">
                <img src={Arrow} alt="Logo" className="emailSubscribeimg2"/>
            </button>
          ) : (
            <button className="emailSubscribebutton">Submit</button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default EmailSubscribe;
