import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{ name,
    state,
    district,
    subDistrict,
    pincode,
    firstline, country
   },
} = checkout;

const validations = [
  Yup.object().shape({
    [name.name]: Yup.string().required(name.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [subDistrict.name]: Yup.string().required(subDistrict.errorMsg),
    [pincode.name]: Yup.string().required(pincode.errorMsg),
    [firstline.name]: Yup.string().required(firstline.errorMsg),
    [country.name]: Yup.string().required(country.errorMsg),
  }),
];

export default validations;
