import React from "react";
import "./aboutinfo.css";
import { Grid } from "@mui/material";

const about = ({ about, label, image }) => {
  return (
    <div>
      <Grid item className="aboutcontainer">
        <Grid item className="aboutitem" ml={3}>
          <img src={image} alt="Logo" className="imgsize" />
          &nbsp;{label}
        </Grid>
        <Grid item className="aboutitem2" mt={3}>
          {about}
        </Grid>
      </Grid>
    </div>
  );
};

export default about;
