import { Button, Card, Stack, Tooltip } from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { micappCode } from "static/micappCode";
import { deleteProjectServiceData } from "redux/Actions/getProjectServiceData";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { MMUListAction } from "redux/Actions/MMUListAction";
import axios from "../../../axiosinstance";
import RemoveIcon from "@mui/icons-material/Remove";
import LabListCreate from "./LabListCreate";
import EditLabList from "./EditLabList";

const Lablist = () => {
  const { tableActionRow, getMMUList, getProjectDetails } = useSelector((state) => state);
  const { loading } = useSelector((state) => state.getMMUList);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  // const PER_PAGE = 5;
  const location = useLocation();
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupMMUListCreate, setopenpopupMMUListCreate] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        MMUListAction({
          url: `${APIs.mmuCreationService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}&st=${nameFilter}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    // if (filterData?.name || nameFilter) {
    //   dispatch(
    //     MMUListAction({
    //       url: `${APIs.mmuCreationService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}&st=${
    //         nameFilter || filterData.name
    //       }`,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     MMUListAction({
    //       url: `${APIs.mmuCreationService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}`,
    //     })
    //   );
    // }
    handleLabListData();
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const handleClose = () => {
    setopenpopup(false);
  };

  const handleOpenEditDialog = (row) => {
    setSelectedUser(row);
    setopenpopup(true);
  };

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
         <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  const modifiedRows = getMMUList?.data?.dtoList?.map((row) => {
    // const pinCode = row.pinCode || {};
    const address = row.address
      ? `${[
          row.address.firstline,
          row.address.secondline,
          row.address.city,
          row.address.subdistrict,
          row.address.district,
          row.address.state,
          row.address.country,
          row.address.postcode,
        ]
          .filter(Boolean)
          .join(", ")}`
      : "-";
    return {
      ...row,
      address,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "description", label: "Description" },
    { id: "address", label: "Address" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    handleLabListData();
    // if (filterData?.name) {
    //   dispatch(
    //     MMUListAction({
    //       url: `${APIs.mmuCreationService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
    //     })
    //   );
    // }
  };

  const handleClear = () => {
    setFilterData({});
    dispatch(
      MMUListAction({
        url: `${APIs.mmuCreationService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}`,
      })
    );
  };

  const handleClosecreate = () => {
    setopenpopupMMUListCreate(false);
  };

  // const passData = () => {
  //   dispatch(
  //     MMUListAction({
  //       url: `${APIs.mmuCreationService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}`,
  //     })
  //   );
  // };

  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.put(`${APIs?.mmuCreationService}/delete`, row);
          dispatch(
            MMUListAction({
              url: `${APIs.mmuCreationService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}`,
            })
          );
          swal("Deleted!", "Your item has been deleted.", "success");
        } catch (error) {
          swal("Error", "An error occurred while deleting the item.", "error");
        }
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  const handleLabListData = () =>{
    let url ="";

    if(filterData && filterData.name){
      url= `${APIs.mmuCreationService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`
    }else{
      url= `${APIs.mmuCreationService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}`
    }
    dispatch(MMUListAction({ url: url }));
    
  };


  return (
    <>
      {loading ? (
        <Card sx={{ marginTop: window.innerWidth <= 768 ? 15 : 10 }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card sx={{ marginTop: window.innerWidth <= 768 ? 15 : 10 }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Lab List
              </MDTypography>
              <MDButton
                color="info"
                onClick={() => setopenpopupMMUListCreate(true)}
              >
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={getMMUList?.data?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopupMMUListCreate && (
        <LabListCreate
          openpopupMMUListCreate={openpopupMMUListCreate}
          setopenpopupMMUListCreate={setopenpopupMMUListCreate}
          handleClose={handleClosecreate}
          passData={handleLabListData}
        />
      )}
      {openpopup && (
        <EditLabList
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Edit Lab"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          passData={handleLabListData}
        />
      )}
    </>
  );
};
export default Lablist;
