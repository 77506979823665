import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { APIs } from "Services/APIs";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { Grid } from "@mui/material";
import axios from "../../../../axiosinstance";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required."),
  taxable: Yup.string().required("Taxable is required."),
  taxcategory: Yup.string().required("Tax category is required."),
  taxcode: Yup.string().required("Tax code is required."),
});
const Itemedit = (props) => {
  const dispatch = useDispatch();
  const { tittle, selectedItem, itemedit, setItemedit, getApiResponse } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [loading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const initialValues = {
    name: selectedItem?.name,
    taxable: selectedItem?.taxable,
    taxcategory: selectedItem?.taxcategory,
    taxcode: selectedItem?.taxcode,
  };

  const handleClose = () => {
    setItemedit(false);
    const initialValues = {
      name: selectedItem?.name,
      taxable: selectedItem?.taxable,
      taxcategory: selectedItem?.taxcode,
    };
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const requestData = {
      ...selectedItem,
      modifiedby: auth?.profileid,
      modifiedbyname: auth?.name,
      createdby: auth?.name,
      name: values.name,

      taxable: values.taxable,
      taxcategory: values.taxcategory,
      taxcode: values.taxcode,
    };

    try {
      const response = await axios.put(
        `${APIs.itemmasterServiceApi}/update`,
        requestData
      );

      if (response.status === 200 || response.status === 201) {
        setItemedit(false);
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
        setIsLoading(false);

        getApiResponse();
      } else {
        throw new Error("Unexpected response status: " + response?.status);
      }
    } catch (error) {
      swal({
        icon: "error",
        tittle: "Error",
        text:
          error.response?.data?.aceErrors?.[0]?.errormessage ||
          "Something went wrong",
      });
      setIsLoading(false);
      handleClose();
    }
  };

  const taxCategory = async (category) => {
    try {
      const response = await axios.get(
        `${APIs.gstServiceApi}/get?type=${category}`
      );
      setOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      taxCategory(selectedItem?.taxcategory);
    }
    Formik.initialValues = {
      name: selectedItem?.name,
      taxable: selectedItem?.taxable,
      taxcategory: selectedItem?.taxcategory,
      taxcode: selectedItem?.taxcode,
    };
  }, [selectedItem]);

  return (
    <Dialog open={itemedit} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {tittle}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={3} padding={1}>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Name{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={
                      touched.name && errors.name ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.name}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="taxable"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Taxable
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="taxable"
                      id="taxable"
                      value={values.taxable}
                      onChange={(e) => {
                        const taxable = e.target.value;

                        setFieldValue("taxable", taxable);
                      }}
                    >
                      <option value=""></option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </Select>
                    {errors.taxable && touched.taxable && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.taxable}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="taxcategory"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Tax Category
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="taxcategory"
                      id="taxcategory"
                      value={values.taxcategory}
                      onChange={(e) => {
                        const taxcategory = e.target.value;
                        taxCategory(taxcategory);
                        setFieldValue("taxcode", "");
                        setFieldValue("taxcategory", taxcategory);
                      }}
                    >
                      <option value=""></option>
                      <option value="Goods">Goods</option>
                      <option value="Services">Services</option>
                    </Select>
                    {errors.taxcategory && touched.taxcategory && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.taxcategory}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="taxcode"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                      shrink={Boolean(values.taxcode)}
                    >
                      Tax Code
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="taxcode"
                      id="taxcode"
                      value={values.taxcode}
                      onChange={(e) => {
                        const taxcode = e.target.value;

                        setFieldValue("taxcode", taxcode);
                      }}
                    >
                      <option value=""></option>
                      {options.map((option) => (
                        <option key={option.id} value={option.heading}>
                          {option.heading}
                        </option>
                      ))}
                    </Select>
                    {errors.taxcode && touched.taxcode && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.taxcode}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Itemedit;
