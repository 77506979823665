import React from "react";
import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import { CircularProgress, Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import { APIs } from "Services/APIs";
import MDButton from "components/MDButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import "./price.css"; // Import the CSS file
import axios from "../../../../axiosinstance";

import MDBox from "components/MDBox";

const PriceInfo = (props) => {
  const { pricePopup, setPricePopup, data, getApiResponse, apiresponse } =
    props;
  const [currencyptions, setCurrencyOptions] = useState([]);
  const [itemoptions, setItemOptions] = useState([]);
  const [typeoptions, setTypeOptions] = useState([]);
  const [uomoptions, setUomOptions] = useState([]);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const [username] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.name
  );

  const [categoryoptions, setCategoryOptions] = useState([]);
  const [pricetypevalue, setPriceTypevalue] = useState("FLAT");
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),

    type: Yup.string().required("Type is required"),
    pricetype: Yup.string().required("Price Type is required"),
    uom: Yup.string().required("UOM is required"),
    unit: Yup.string().required("Unit is required"),

    currency: Yup.string().when("pricetype", {
      is: "Flat",
      then: Yup.string().required("This field is required"),
      //   .matches(/^\d+(\.\d+)?%?$/, 'Please enter a valid percentage'),
      // otherwise: Yup.string(),
    }),
    value: Yup.string().required("Value Text is required"),
    tax: Yup.string()
      .oneOf(["Tax Include", "Tax Exclude"], "Please select a tax")
      .required("Tax Preference is required"),
  });

  const handleClose = () => {
    setPricePopup(false); // Close the dialog
  };

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `login-service-mongo/api/loginservice/v2/get?rolecode=DO001&recstatus=APPROVED`
      );
      setDoctorOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }

    try {
      const response = await axios.get(
        `${APIs.itemmasterServiceApi}/getV3?type=M_PRICE_ITEM`
      );

      setItemOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // type list come from operation
    try {
      const response = await axios.get(
        `typemaster-service/api/TypeMaster/v1/get?category=M_PRICE_OPERATION&categorytype=M_DOCTOR_OPTIONS`
      );
      setTypeOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // Dropdown(comes from uom Master) for currency
    try {
      const response = await axios.get(
        `typemaster-service/api/TypeMaster/v1/get?categorytype=M_UOM_OPTIONS&category=Currency`
      );
      setCurrencyOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    // Dropdown(comes from uom Master) for uom
    try {
      const response = await axios.get(
        `typemaster-service/api/TypeMaster/v1/get?categorytype=M_UOM_OPTIONS&category=Time`
      );
      setUomOptions(response.data.data.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setPriceTypevalue(data?.currency);
  }, [data]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={pricePopup} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "white",
          textAlign: "center",
        }}
      >
        {data ? "Edit Price" : "Create Price"}
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "30vh",
            }}
          >
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          </div>
        ) : (
          <>
            <Formik
              initialValues={{
                description: data ? data?.description : "",
                type: data ? data.type : "",
                pricetype: data ? data?.valuetype : "",
                name: data ? data?.name : "",
                currency: data ? data?.currency : "",
                uom: data ? data.uom : "",
                tax: data ? data.taxinclusiveflag : "",
                value: data ? data?.value : "",
                unit: data ? data?.unit : "",
              }}
              validationSchema={validationSchema}
              // onSubmit={async (values, action) => {
              //   setLoading(true);

              //   try {
              //     const selectedOption = typeoptions.find(
              //       (option) => option.name === values.type
              //     );

              //     const descriptionOperation = selectedOption
              //       ? selectedOption.description
              //       : "";

              //     const itemOption = itemoptions.find(
              //       (option) => option.name === values.name
              //     );

              //     const sapcode = itemOption ? itemOption.sapcode : "";

              //     if (data != null) {
              //       const updateData = {
              //         ...data,

              //         createdby: username,
              //         currency: values.currency,
              //         description: values.description,
              //         itemcode: sapcode,
              //         modifiedby: username,
              //         modifieddate: null,
              //         name: values.name,
              //         operationtype: descriptionOperation,
              //         profileid: profileId,
              //         taxinclusiveflag: values.tax,
              //         type: values.type,
              //         unit: values.unit,
              //         uom: values.uom,
              //         value: values.value,
              //         valuetype: values.pricetype,
              //       };

              //       // Make an API request to update the existing operation
              //       await axios.put(
              //         "price-service/api/price/v1/update", // Adjust the endpoint accordingly
              //         updateData
              //       );
              //       swal("Good job!", "Price Update Successful", "success");
              //     } else {
              //       const newData = {
              //         createdby: username,
              //         currency: values.currency,
              //         description: values.description,
              //         itemcode: sapcode,
              //         modifiedby: username,
              //         modifieddate: null,
              //         name: values.name,
              //         operationtype: descriptionOperation,
              //         profileid: profileId,
              //         recstatus: "OPEN",
              //         status: "ACTIVE",
              //         syncstatus: "SYNCED",
              //         taxinclusiveflag: values.tax,
              //         type: values.type,
              //         unit: values.unit,
              //         uom: values.uom,
              //         value: values.value,
              //         valuetype: values.pricetype,
              //       };

              //       // Make an API request to create a new operation
              //       await axios.post(
              //         "price-service/api/price/v1/create", // Adjust the endpoint accordingly
              //         newData
              //       );

              //       swal("Good job!", "Price Create Successful", "success");
              //     }

              //     action.resetForm();
              //     setPricePopup(false);
              //     getApiResponse();
              //     getData();
              //     setLoading(false);
              //   } catch (error) {
              //     if (error.response?.data == null) {
              //       swal("Oops!", "Something went wrong!", "error");
              //     } else {
              //       swal(
              //         "Oops!",
              //         error.response?.data?.aceErrors[0]?.errorMessage,
              //         "warning"
              //       );
              //     }
              //     setLoading(false);
              //   }
              // }}

              onSubmit={async (values, action) => {
                setLoading(true);
                try {
                  const isDuplicateType = apiresponse.some((item) => {
                    if (data) {
                      return item.type === values.type && item.id !== data.id;
                    }
                    return item.type === values.type;
                  });

                  if (isDuplicateType) {
                    swal(
                      "Error",
                      "This type already exists. Please choose a different type.",
                      "error"
                    );
                    setLoading(false);
                    return;
                  }

                  const selectedOption = typeoptions.find(
                    (option) => option.name === values.type
                  );
                  const descriptionOperation = selectedOption
                    ? selectedOption.description
                    : "";
                  const itemOption = itemoptions.find(
                    (option) => option.name === values.name
                  );
                  const sapcode = itemOption ? itemOption.sapcode : "";

                  if (data != null) {
                    const updateData = {
                      ...data,
                      createdby: username,
                      currency: values.currency,
                      description: values.description,
                      itemcode: sapcode,
                      modifiedby: username,
                      modifieddate: null,
                      name: values.name,
                      operationtype: descriptionOperation,
                      profileid: profileId,
                      taxinclusiveflag: values.tax,
                      type: values.type,
                      unit: values.unit,
                      uom: values.uom,
                      value: values.value,
                      valuetype: values.pricetype,
                    };

                    await axios.put(
                      "price-service/api/price/v1/update",
                      updateData
                    );
                    swal("Good job!", "Price has been updated successfully.", "success");
                  } else {
                    const newData = {
                      createdby: username,
                      currency: values.currency,
                      description: values.description,
                      itemcode: sapcode,
                      modifiedby: username,
                      modifieddate: null,
                      name: values.name,
                      operationtype: descriptionOperation,
                      profileid: profileId,
                      recstatus: "OPEN",
                      status: "ACTIVE",
                      syncstatus: "SYNCED",
                      taxinclusiveflag: values.tax,
                      type: values.type,
                      unit: values.unit,
                      uom: values.uom,
                      value: values.value,
                      valuetype: values.pricetype,
                    };

                    await axios.post(
                      "price-service/api/price/v1/create",
                      newData
                    );
                    swal("Good job!", "Price has been created successfully.", "success");
                  }

                  action.resetForm();
                  setPricePopup(false);
                  getApiResponse(profileId);
                  getData();
                  setLoading(false);
                } catch (error) {
                  if (error.response?.data == null) {
                    swal("Oops!", "Something went wrong!", "error");
                  } else {
                    swal(
                      "Oops!",
                      error.response?.data?.aceErrors?.[0]?.errorMessage ||
                        "Something wents wrong.",
                      "warning"
                    );
                  }
                  setLoading(false);
                }
              }}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                  <MDBox p={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="name"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                            shrink={Boolean(values.name)}
                          >
                            Select Name{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            fullWidth
                            variant="standard"
                            style={{ width: "100%" }}
                            id="name"
                            name="name"
                            value={values.name}
                            onChange={(e) => {
                              const selectedName = e.target.value;

                              setFieldValue("name", selectedName);
                            }}
                            error={touched.name && Boolean(errors.name)}
                          >
                            <option value=""></option>
                            {itemoptions?.map((option) => (
                              <option key={option.id} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="name"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>Description</span>
                            name="description"
                            id="description"
                            value={values.description}
                            onChange={(e) => {
                              const nameDescription = e.target.value;
                              setFieldValue("description", nameDescription);
                            }}
                            variant="standard"
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="type"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                            shrink={Boolean(values.type)}
                          >
                            Type{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            fullWidth
                            variant="standard"
                            style={{ width: "100%" }}
                            id="type"
                            name="type"
                            value={values.type}
                            onChange={(e) => {
                              const selectedType = e.target.value;

                              setFieldValue("type", selectedType);
                            }}
                            error={touched.type && Boolean(errors.type)}
                          >
                            <option value=""></option>
                            {typeoptions?.map((option) => (
                              <option key={option.id} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="type"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="pricetype"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Price Type{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            fullWidth
                            variant="standard"
                            style={{ width: "100%" }}
                            name="pricetype"
                            id="pricetype"
                            value={values.pricetype}
                            onChange={(e) => {
                              const selectedType = e.target.value;
                              setFieldValue("pricetype", selectedType);
                              setPriceTypevalue(selectedType);
                              if (selectedType === "Percentage") {
                                setFieldValue("currency", "");
                              }
                            }}
                            error={
                              touched.pricetype && Boolean(errors.pricetype)
                            }
                          >
                            <option value=""></option>
                            <option value="Flat">Flat</option>
                            <option value="Percentage">Percentage</option>
                          </Select>
                          <ErrorMessage
                            name="pricetype"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      {pricetypevalue === "Percentage" ||
                      pricetypevalue === "" ? null : (
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="currency"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                              shrink={Boolean(values.currency)}
                            >
                              Currency{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              fullWidth
                              variant="standard"
                              style={{ width: "100%" }}
                              id="currency"
                              name="currency"
                              value={values.currency}
                              onChange={(e) => {
                                const selectedCurrency = e.target.value;

                                setFieldValue("currency", selectedCurrency);
                              }}
                              error={
                                touched.currency && Boolean(errors.currency)
                              }
                            >
                              <option value=""></option>
                              {currencyptions?.map((option) => (
                                <option
                                  key={option.id}
                                  value={option.displaytext}
                                >
                                  {option.displaytext}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="currency"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              Value
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="value"
                            id="value"
                            value={values.value}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("value", value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                !/[0-9\b\t]/.test(e.key) &&
                                ![
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "ArrowUp",
                                  "ArrowDown",
                                  "Delete",
                                  "Backspace",
                                  "Tab",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            variant="standard"
                            error={touched.value && Boolean(errors.value)}
                            helperText={touched.value && errors.value}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="uom"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                            shrink={Boolean(values.uom)}
                          >
                            UOM{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            fullWidth
                            variant="standard"
                            style={{ width: "100%" }}
                            id="uom"
                            name="uom"
                            value={values.uom}
                            onChange={(e) => {
                              const selectedUom = e.target.value;

                              setFieldValue("uom", selectedUom);
                            }}
                            error={touched.uom && Boolean(errors.uom)}
                          >
                            <option value=""></option>
                            {uomoptions?.map((option) => (
                              <option
                                key={option.id}
                                value={option.displaytext}
                              >
                                {option.displaytext}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="uom"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              Unit
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            name="unit"
                            id="unit"
                            value={values.unit}
                            onChange={(e) => {
                              const value = e.target.value;
                              setFieldValue("unit", value);
                            }}
                            onKeyDown={(e) => {
                              if (
                                !/[0-9\b\t]/.test(e.key) &&
                                ![
                                  "ArrowLeft",
                                  "ArrowRight",
                                  "ArrowUp",
                                  "ArrowDown",
                                  "Delete",
                                  "Backspace",
                                  "Tab",
                                ].includes(e.key)
                              ) {
                                e.preventDefault();
                              }
                            }}
                            variant="standard"
                            error={touched.unit && Boolean(errors.unit)}
                            helperText={touched.unit && errors.unit}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>

                    <Grid className="radio-container" mt={2}>
                      <span>
                        Tax Preference
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </span>
                      <label>
                        <Field
                          type="radio"
                          name="tax"
                          value="Tax Include"
                          style={{ width: "5%" }}
                        />
                        <span
                          className="radio-label"
                          style={{ fontSize: "15px" }}
                        >
                          Tax Include
                        </span>
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="tax"
                          value="Tax Exclude"
                          style={{ width: "5%" }}
                        />
                        <span
                          className="radio-label"
                          style={{ fontSize: "15px" }}
                        >
                          Tax Exclude
                        </span>
                      </label>
                      <ErrorMessage
                        name="tax"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </Grid>

                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} sm={9}>
                        <MDButton onClick={handleClose}>Cancel</MDButton>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MDButton type="submit" variant="gradient" color="info">
                          {loading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : data ? (
                            "Update"
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </Grid>
                    </Grid>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PriceInfo;
