import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { APIs } from "Services/APIs";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { Grid } from "@mui/material";
import axios from "../../../../axiosinstance";

const validationSchema = Yup.object().shape({
  sno: Yup.string().required("Serial No. is required."),
  heading: Yup.string().required("Heading is required."),
  type: Yup.string().required("Type is required."),
  description: Yup.string().required("Description is required."),
  schedules: Yup.string().required("Schedule is required."),
  compensation: Yup.string().required("Compensation is required."),
  condition: Yup.string().required("Condition is required."),
  cgst: Yup.string().required("CGST is required."),
  sgst: Yup.string().required("SGST is required."),
  igst: Yup.string().required("IGST is required."),
});

const Gstedit = (props) => {
  const dispatch = useDispatch();
  const { tittle, selectedGst, gstedit, setGstedit, getApiResponse } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [loading, setIsLoading] = useState(false);

  const initialValues = {
    sno: selectedGst?.sno,
    heading: selectedGst?.heading,
    type: selectedGst?.type,
    description: selectedGst?.description,
    schedules: selectedGst?.schedules,
    condition: selectedGst?.condition,
    compensation: selectedGst?.compensation,
    cgst: selectedGst?.cgst,
    sgst: selectedGst?.sgst,
    igst: selectedGst?.igst,
  };

  const handleClose = () => {
    setGstedit(false);
    const initialValues = {
      sno: selectedGst?.sno,
      heading: selectedGst?.heading,
      type: selectedGst?.type,
      description: selectedGst?.description,
      schedules: selectedGst?.schedules,
      condition: selectedGst?.condition,
      compensation: selectedGst?.compensation,
      cgst: selectedGst?.cgst,
      sgst: selectedGst?.sgst,
      igst: selectedGst?.igst,
    };
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const requestData = {
      ...selectedGst,
      modifiedby: auth?.profileid,
      modifiedbyname: auth?.name,
      cgst: values.cgst,

      compensation: values.compensation,
      condition: values.condition,

      description: values.description,

      heading: values.heading,

      type: values.type,
      igst: values.igst,
      schedules: values.schedules,
      sgst: values.sgst,
      sno: values.sno,
    };

    try {
      const response = await axios.put(
        `${APIs.gstServiceApi}/update`,
        requestData
      );

      if (response.status === 200 || response.status === 201) {
        setGstedit(false);
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
        setIsLoading(false);

        getApiResponse();
      } else {
        throw new Error("Unexpected response status: " + response?.status);
      }
    } catch (error) {
      swal({
        icon: "error",
        tittle: "Error",
        text:
          error.response?.data?.aceErrors?.[0]?.errormessage ||
          "Something went wrong",
      });
      setIsLoading(false);
      handleClose();
    }
  };

  // useEffect(() => {
  //   const initialValues = {

  //     name: selectedOperation?.name,
  //     description: selectedOperation?.operation,
  //   };

  // }, [selectedOperation]);

  return (
    <Dialog open={gstedit} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {tittle}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={3} padding={1}>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Serial No.{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="sno"
                    name="sno"
                    value={values.sno}
                    onChange={handleChange}
                    error={touched.sno && Boolean(errors.sno)}
                    helperText={
                      touched.sno && errors.sno ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.sno}
                        </p>
                      ) : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Heading{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="heading"
                    name="heading"
                    value={values.heading}
                    onChange={handleChange}
                    error={touched.heading && Boolean(errors.heading)}
                    helperText={
                      touched.heading && errors.heading ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.heading}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth error={!!errors.type && touched.type}>
                    <InputLabel
                      htmlFor="type"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Type
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="type"
                      id="type"
                      value={values.type}
                      onChange={(e) => {
                        const type = e.target.value;

                        setFieldValue("type", type);
                      }}
                    >
                      <option value=""></option>
                      <option value="Goods">Goods</option>
                      <option value="Services">Services</option>
                    </Select>
                    {errors.type && touched.type && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Description{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="description"
                    name="description"
                    value={values.description}
                    onChange={handleChange}
                    error={touched.description && Boolean(errors.description)}
                    helperText={
                      touched.description && errors.description ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.description}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Schedule{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="schedules"
                    name="schedules"
                    value={values.schedules}
                    onChange={handleChange}
                    error={touched.schedules && Boolean(errors.schedules)}
                    helperText={
                      touched.schedules && errors.schedules ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.schedules}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Condition{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="condition"
                    name="condition"
                    value={values.condition}
                    onChange={handleChange}
                    error={touched.condition && Boolean(errors.condition)}
                    helperText={
                      touched.condition && errors.condition ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.condition}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Compensation{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="compensation"
                    name="compensation"
                    value={values.compensation}
                    onChange={handleChange}
                    error={touched.compensation && Boolean(errors.compensation)}
                    helperText={
                      touched.compensation && errors.compensation ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.compensation}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="number"
                    label=<span>
                      CGST{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="cgst"
                    name="cgst"
                    value={values.cgst}
                    onChange={handleChange}
                    error={touched.cgst && Boolean(errors.cgst)}
                    helperText={
                      touched.cgst && errors.cgst ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.cgst}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="number"
                    label=<span>
                      SGST{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="sgst"
                    name="sgst"
                    value={values.sgst}
                    onChange={handleChange}
                    error={touched.sgst && Boolean(errors.sgst)}
                    helperText={
                      touched.sgst && errors.sgst ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.sgst}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="number"
                    label=<span>
                      IGST{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="igst"
                    name="igst"
                    value={values.igst}
                    onChange={handleChange}
                    error={touched.igst && Boolean(errors.igst)}
                    helperText={
                      touched.igst && errors.igst ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.igst}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default Gstedit;
