import { Grid } from "@mui/material";
import React, { useState } from "react";
import "./testimonials.css";
import TestimonialLogo from "../../../../../assets/images/TestimonialProfile.png";
import TestimonialsProfile from "./testimonialsProfile";
import LeftGroup from "../../../../../assets/svg/LeftGroup.svg";
import RightGroup from "../../../../../assets/svg/RightGroup.svg";

const Testimonials = () => {
  const data = [
    {
      image: TestimonialLogo,
      text: "This app is really flawless. Good for elderly people. The best part of the app is the home delivery of discounted price.",
      name: "J.krishnamurty",
    },
    {
      image: TestimonialLogo,
      text: "This app is really flawless. Good for elderly people. The best part of the app is the home delivery of discounted price.",
      name: "J.krishn",
    },
    {
      image: TestimonialLogo,
      text: "This app is really flawless. Good for elderly people. The best part of the app is the home delivery of discounted price.",
      name: "J.krishna",
    },
  ];

  const [startIndex, setStartIndex] = useState(0);

  const handleNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 1) % data.length);
  };

  const handlePrev = () => {
    setStartIndex((prevIndex) => (prevIndex - 1 + data.length) % data.length);
  };

  return (
    <div className="testimonialroot">
      <Grid container mt={7} className="testimonialscontainer">
        <Grid item className="testimonialsheading">
          Testimonials
        </Grid>
      </Grid>
      <Grid container mt={5} xs={12} className="testimonialscontainer1">
        <img src={LeftGroup} alt="Logo" className="testimonialimg2" onClick={handlePrev} />
        {Array.from({ length: 2 }).map((_, index) => {
          const testimonialIndex = (startIndex + index) % data.length;
          return (
            <TestimonialsProfile
              key={index}
              image={data[testimonialIndex]?.image}
              text={data[testimonialIndex]?.text}
              name={data[testimonialIndex]?.name}
            />
          );
        })}
        <img src={RightGroup} alt="Logo" className="testimonialimg3" onClick={handleNext} />
      </Grid>
    </div>
  );
};

export default Testimonials;
