// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";

function GstInfo({ formData }) {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();

  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { sno, heading,description,schedules,condition,compensation,cgst,sgst,igst  } = formField;
  var { 
    sno: snoV,
    heading:headingV,
    description:descriptionV,
    schedules:schedulesV,
    condition:conditionV,
    compensation:compensationV,
    cgst:cgstV,
    sgst:sgstV,
    igst:igstV

  } = values;

 
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Operation Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={sno.type}
                label=<span>
                  {sno.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={sno.name}
                value={snoV}
                placeholder={sno.placeholder}
                error={errors.sno && touched.sno}
                success={snoV.length > 0 && !errors.sno}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={heading.type}
                label=<span>
                  {heading.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={heading.name}
                value={headingV}
                placeholder={heading.placeholder}
                error={errors.heading && touched.heading}
                success={headingV.length > 0 && !errors.heading}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="type"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
             Type<sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="type"
                id="type"
                value={values.type}
                onChange={(e) => {
                  const type = e.target.value;

                  setFieldValue("type", type);
                }}
              >
                <option value=""></option>
                <option value="Goods">Goods</option>
                <option value="Services">Services</option>
              </Select>
              {errors.type && touched.type && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.type}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          
            <Grid item xs={12} sm={6}>
              <FormField
                type={description.type}
                label=<span>
                  {description.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            
          
          <Grid item xs={12} sm={6}>
          <FormField
            type={schedules.type}
            label=<span>
              {schedules.label}
              <sup
                style={{
                  color: "red",
                  fontSize: "small",
                  fontWeight: "bolder",
                  position: "relative",
                  top: "2px",
                }}
              >
                {" "}
                *
              </sup>
            </span>
            name={schedules.name}
            value={schedulesV}
            placeholder={schedules.placeholder}
            error={errors.schedules && touched.schedules}
            success={schedulesV.length > 0 && !errors.schedules}
          />
        </Grid>
      
        <Grid item xs={12} sm={6}>
        <FormField
          type={condition.type}
          label=<span>
            {condition.label}
            <sup
              style={{
                color: "red",
                fontSize: "small",
                fontWeight: "bolder",
                position: "relative",
                top: "2px",
              }}
            >
              {" "}
              *
            </sup>
          </span>
          name={condition.name}
          value={conditionV}
          placeholder={condition.placeholder}
          error={errors.condition && touched.condition}
          success={conditionV.length > 0 && !errors.condition}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
      <FormField
        type={compensation.type}
        label=<span>
          {compensation.label}
          <sup
            style={{
              color: "red",
              fontSize: "small",
              fontWeight: "bolder",
              position: "relative",
              top: "2px",
            }}
          >
            {" "}
            *
          </sup>
        </span>
        name={compensation.name}
        value={compensationV}
        placeholder={compensation.placeholder}
        error={errors.compensation && touched.compensation}
        success={compensationV.length > 0 && !errors.compensation}
      />
    </Grid>
    <Grid item xs={12} sm={6}>
    <FormField
      type={cgst.type}
      label=<span>
        {cgst.label}
        <sup
          style={{
            color: "red",
            fontSize: "small",
            fontWeight: "bolder",
            position: "relative",
            top: "2px",
          }}
        >
          {" "}
          *
        </sup>
      </span>
      name={cgst.name}
      value={cgstV}
      placeholder={cgst.placeholder}
      error={errors.cgst && touched.cgst}
      success={cgstV.length > 0 && !errors.cgst}
    />
  </Grid>
  <Grid item xs={12} sm={6}>
  <FormField
    type={sgst.type}
    label=<span>
      {sgst.label}
      <sup
        style={{
          color: "red",
          fontSize: "small",
          fontWeight: "bolder",
          position: "relative",
          top: "2px",
        }}
      >
        {" "}
        *
      </sup>
    </span>
    name={sgst.name}
    value={sgstV}
    placeholder={sgst.placeholder}
    error={errors.sgst && touched.sgst}
    success={sgstV.length > 0 && !errors.sgst}
  />
</Grid>
<Grid item xs={12} sm={6}>
<FormField
  type={igst.type}
  label=<span>
    {igst.label}
    <sup
      style={{
        color: "red",
        fontSize: "small",
        fontWeight: "bolder",
        position: "relative",
        top: "2px",
      }}
    >
      {" "}
      *
    </sup>
  </span>
  name={igst.name}
  value={igstV}
  placeholder={igst.placeholder}
  error={errors.igst && touched.igst}
  success={igstV.length > 0 && !errors.igst}
/>
</Grid>
         
          </Grid>
        </MDBox>
    
    </MDBox>
  );
}

// typechecking props for GstInfo
GstInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default GstInfo;
