import { Grid } from "@mui/material";
import React from "react";
import "./whyConsultOnline.css";

const whyConsultOnlineInfo = ({ image, heading, text }) => {
  return (
    <>
      <Grid item mb={5} className="whyconsultonlineitem2">
        <Grid item>
          <img src={image} alt="Logo" className="whyconsultonlineimg" />
        </Grid>
        <Grid item ml={3} mt={2} xs={8}>
          <div className="whyconsultonlineheading">{heading}</div>
          <div className="whyconsultonlinetext">
            {text}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default whyConsultOnlineInfo;
