import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

import Footer from "examples/Footer";
import './AgentBenefits.css'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';


const AgentBenefits = () => {

    return (
        <>
            <Grid mt={8}>
                <Card>
                    <Grid className='card-main'>
                        <Grid className='card-text' >Benefits Of Becoming a Medharva Agent</Grid>
                        <Grid className='card-process-1'>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Transparency In registration Process</Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Transparency In registration Process</Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid className='card-process-2'>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row', marginRight: '30px' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Negotiable comission above the fixed value</Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row', marginRight: '10px' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Negotiable comission above the fixed value</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className='card-process-3'>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Training and education on all services</Grid>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid className='check-button' sx={{ display: 'flex', flexDirection: 'row' }} >
                                    <Grid sx={{ marginRight: '10px', marginTop: '-0.7%' }}><CheckCircleOutlineIcon fontSize="medium" color="black" /></Grid>
                                    <Grid className='text-name' >Training and education on all services</Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
            <Footer />
        </>
    )
}

export default AgentBenefits
