// import { FilterArrayType } from "types/filter";

export const directoryFilter = [
    {type:"text", placeholder:"Piyush", variant:"outlined", label:"Name", name:"name", page: "allList" },
    {type:"number", placeholder:"age", label:"Age", variant:"outlined", name:"age", InputProps:{ inputProps: { min: 0, max: 40, step:3 } }, page: "allList"  },
    {type:"date", placeholder:"", label:"Start Date", variant:"outlined", name:"date", InputLabelProps:{ shrink: true }, page: "allList" },
    {type:"select", placeholder:"Select", label:"Select Office Location", variant:"outlined", name:"office", options:[{label:"Bengaluru", value:"Bengaluru"},{label:"Noida", value:"Noida"},{label:"Gurugram", value:"Gurugram"},{label:"Pune", value:"Pune"}] , page: "allList" },
    {type:"radio", placeholder:"", label:"Select Age", variant:"outlined", name:"age", options:[{label:"25", value:25},{label:"35", value:35}] , page: "allList" },
    {type:"checkbox", placeholder:"", label:"Office Locations", variant:"outlined", name:"office", options:[{label:"Bengaluru", value:"Bengaluru"},{label:"Noida", value:"Noida"}] , page: "allList" },
    {type:"range", placeholder:"", label:"Select Range", variant:"outlined", name:"charges", InputProps: {inputProps: {min: 0,max: 40,step: 3,},}, options:[] , page: "allList" },
]