
import React, { useState } from "react";
import { Card, Dialog, DialogActions, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import { downloadurl } from "../../Services/UploadDocumnet";
import MDTypography from "components/MDTypography";

import Skeleton from 'components/Skeleton/Skeleton';
import TrackStatus from "components/TrackStatus";

const ResourcesCard = ({ data }) => {
  const navigate = useNavigate();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [resourceCode, setResourceCode] = useState("");

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };


  const steps = [
    { label: 'Registration', date: '2 Feb 2024' },
    { label: 'Verification', date: '3 Feb 2024' },
    { label: 'Compliance Check', date: '4 Feb 2024' },
    { label: 'Approval', date: '4 Feb 2024' },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return 'Once the form is successfully submitted, the application is sent to the verification team.';
      case 1:
        return 'Medharva\'s dedicated team meticulously verifies the requirement and authenticity of the resource.';
      case 2:
        return 'After successful verification, based on the requirement and authenticity, the resource is added to Medharva\'s Resource List.';
      default:
        return '';
    }
  };

  const checkStatus = (code) => {
    setShowConfirmation(true);
    setResourceCode(code);
  };

  const fallbackImageUrl = `https://pics.craiyon.com/2023-06-07/a21669aac0b6449f84f1e070531f4902.webp`;

  return (
    <>
      <Card sx={{ marginTop: "2%", marginBottom: "2%", cursor: "pointer", }}>
        <Grid
          item
          xs={12}
          sm={5}
          md={4}
          lg={3}
          onClick={() =>
            data?.recstatus === "PENDING" ||   data?.recstatus === "OPEN"
              ? checkStatus(data?.resourcecode)
              : navigate(
                  `/app/agent/registration?roleCode=${data?.rolecode}&rolename=${data?.name}`
                )
          }
        >
          <Grid alignItems="center" justifyContent="center" direction="column">
            {data ? (
              <>
                <Grid sx={{ padding: "18px",marginLeft:"18px" }}>
                  <img
                  
                    className="resources-img"
                    src={`${downloadurl}/${data?.documentofproof}`}
                    alt={data?.description}
                    onError={(e) => {
                      e.target.onerror = null; // Prevents infinite loop if fallback image also fails
                      e.target.src = fallbackImageUrl;
                    }}
                  />
                  {data?.recstatus !== "APPROVED" && (
                  
                    <MDButton size="small" variant="contained" color="info" sx={{     position: "absolute",
                      top: "-2%",
                      left: "27%", whiteSpace: "nowrap"}}>PENDING FOR APPROVAL</MDButton>
                  )}
                </Grid>
                <MDTypography className="resource-description">
                  {data?.name}
                </MDTypography>
              </>
            ) : (
              <>
                <Skeleton width="100%" height="200px" />
                <Skeleton width="100%" height="20px" />
              </>
            )}
          </Grid>
        </Grid>
      </Card>
      <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
        <TrackStatus resourceCode={resourceCode} getStepContent={getStepContent} steps={steps} activeStep={2} />
        <DialogActions>
          <MDButton onClick={handleCloseConfirmation}>Close</MDButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ResourcesCard;

