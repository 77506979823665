import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { getSummaryServiceAction } from "redux/Actions/getSummaryServiceAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getSummaryServiceSlice = createSlice({
  name: "getSummaryServiceData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryServiceAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSummaryServiceAction.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        state.error = null;
      })
      .addCase(getSummaryServiceAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getSummaryServiceSlice.reducer;