import React, { useState } from 'react';
import { MenuItem, Select, InputBase, Avatar } from '@mui/material';
import { styled } from '@mui/system';
import image1 from '../../findDoctor/assets/images/dropdown.svg';

const GreenSelect = styled(Select)(({ theme }) => ({
  display: 'flex',
  width: "auto",
  height: "38px",
  backgroundColor: "#ffffff",
  alignItems: 'center',
  border: `2px solid #039147`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
}));

const GreenDropdown = ({ options, value, onChange }) => {
  const [open, setOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleSelectChange = (event) => {
    onChange(event);
    setOpen(false);
    // Filter out the selected option from the options list
    setFilteredOptions(options.filter(option => option !== event.target.value));
  };

  return  (
    <GreenSelect
    open={open}
    onClose={() => setOpen(false)}
    onOpen={() => setOpen(true)}
    value={value || ''}
    onChange={handleSelectChange}
    input={<InputBase />}
    startAdornment={<p>Sort</p>}
    endAdornment={
      <Avatar
        src={image1}
        sx={{ width: 22, height: 11, marginLeft: "5px" }}
        onClick={() => setOpen(!open)}
      />
    }
  >
  
    <MenuItem key={-1} value="">
      Sort
    </MenuItem>
  
   
    {filteredOptions.map((option, index) => (
      <MenuItem key={index} value={option}>
        {option}
      </MenuItem>
    ))}
  </GreenSelect>
  );
};

export default GreenDropdown;
