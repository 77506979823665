import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axiosinstance";
//create role
 
export const createResource = createAsyncThunk("createResource", async (data, {rejectWithValue}) => {
  console.log("formData", data);
 
  try {
    const response = await axios.post(
      `resource-service-mongo/api/resource/v2/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getResource = createAsyncThunk("getResource", async (data, {rejectWithValue}) => {
  console.log("formData", data);
 
  try {
    const response = await axios.get(
      `resource-service-mongo/api/resource/v2/get`,
      
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
 
// export const updateRole = createAsyncThunk("updateRole", async (data, {rejectWithValue}) => {
//   console.log("formData", data);
 
//   try {
//     const response = await axios.put(
//       `role-service-mongo/api/role/v2/update`,
//       data
     
//     );
//     return await response.data;
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });
export const deleteResource = createAsyncThunk("deleteResource", async (data, {rejectWithValue}) => {
  console.log("formData", data);
 
  try {
    const response = await axios.put(
      `resource-service-mongo/api/role/v2/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




