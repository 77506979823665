


import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { DiseaseName, DiseaseDescrption, DiseaseType  },
} = checkout;

const validations = [
  Yup.object().shape({
    [DiseaseName.name]: Yup.string().required(DiseaseName.errorMsg),
    // [DiseaseDescrption.name]: Yup.string().required(DiseaseDescrption.errorMsg),
    // [DiseaseType.name]: Yup.string().required(DiseaseType.errorMsg),
 
  }),
];

export default validations;
