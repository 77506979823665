import { Button, Card, Stack } from '@mui/material'
import FilterCmp from 'components/Filters/FilterCmp.tsx'
import DataTable from 'components/Tables/DataTable'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { tableDataAction } from 'redux/Actions/tableDataAction'
import { directoryFilter } from 'static/filters/directoryFilter';
import TableCmp from 'components/Tables/TableCmp';
import { APIs } from 'Services/APIs'
import Swal, {SweetAlertOptions} from 'sweetalert2';
import tableActionRow, { clearActionOfRow, clearActionRowData, setActionTypeRow } from 'redux/slices/tableActionRow'
import { useLocation, useNavigate } from 'react-router-dom'
import SkeletonFilterCmp from 'components/Skeleton/SkeletonFilterCmp'
import SkeletonTableCmp from 'components/Skeleton/SkeletonTableCmp'

const Directory = () => {

    const { tableData, AuthReducer, tableActionRow } = useSelector(state => state)
     const { loading} =  useSelector(state => state.tableData)
  //  const [ loading , setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData]= useState()
    const [filterData, setFilterData] = useState({});
    const [perPage, setPerPage] = useState(5)
    // const PER_PAGE = 5;
    const location = useLocation();

    const nameFilter = new URLSearchParams(location.search).get('name');

    useEffect(async()=>{
      setFilterData({...filterData, name:nameFilter})
      if(nameFilter){
        dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${perPage}&st=${nameFilter}`}))
      
      }else{
        dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
      }
      },[])
      
      useEffect(()=>{
        if(filterData?.name || nameFilter){
          dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${perPage}&st=${nameFilter || filterData.name}`}))
       
        }else{
          dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
        }
    },[ currentPage,perPage ])

    const filters = [
      {type:"text", placeholder:"Enter name", variant:"outlined", label:"Name", name:"name", page: "allList" }
  ]

  const actionButtons =(row)=>{
    return (
      <Stack direction="row" sx={{display:"flex", justifyContent:"center"}}>
        <Button variant="text" color="error" onClick={()=>dispatch(setActionTypeRow({type:"view", data:row})) }>
          View
        </Button>
      </Stack>
    )
  } 

    const modifiedRows = tableData?.rowData?.dtoList?.map(row => ({
        ...row,
        name: `${row.firstname} ${row.lastname}` ,
        action:actionButtons(row)
    }));

    const columns = [
          { id:"name", label: "Name" },
          { id:"dateofbirth",  label: "Data Of Birth" },
          { id:"rolename",  label: "Role" },
          { id:"sex",  label: "Gender" },
          { id:"emailid",  label: "Email" },
          { id:"phoneno",  label: "Phone Number" },
          { id:"action",  label: "Action" }
        ]

    // const actionButtons = ["view"]
    if(tableActionRow.actionType === "view"){
     const profileid = tableActionRow?.rowData?.profileid
      dispatch(clearActionOfRow())
      navigate(`/app/agent/view-profile?id=${profileid}`);
    }

  const handleSearch =()=>{
   setCurrentPage(1)
    if(filterData?.name){
      dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`}))
    }
  }
  
  const handleClear =()=>{
    setFilterData({})
    navigate("/app/agent/directory")
    dispatch(tableDataAction({ url:`${APIs.personalInformationServiceApi}/get?createdby=${AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${perPage}`}))
  }

  return (
    <DashboardLayout>
     {loading ? (
       <Card style={{marginTop:"5%"}}>
           <>
          <SkeletonFilterCmp filterArray={filters} />
          <SkeletonTableCmp  columns={columns} perPage={perPage}   />
        </>
          </Card>
     ) : (
      <Card style={{marginTop:"5%"}}>
      <FilterCmp filterArray={filters} filterData={filterData} setFilterData={setFilterData}
        handleSearch={handleSearch} handleClear={handleClear} />
      <TableCmp columns={columns} rows={modifiedRows} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages}
        setPerPage={setPerPage} perPage={perPage} currentPage={currentPage}
       />   
  </Card>
     )} 
    </DashboardLayout>
  )
}
export default Directory
