import React from 'react'
import "./Choosespeciality.css";
import image1 from '../../../../../assets/svg/chosedoctor123.svg'
import image2 from '../../../../../assets/svg/virticalline123.svg'
import image3 from '../../../../../assets/svg/Medicine.svg'
import image4 from '../../../../../assets/svg/doctor.svg'
import image5 from '../../../../../assets/svg/labtest.svg'
import image6 from '../../../../../assets/svg/buymedicine.svg'


const Choosespeciality = () => {
    return (
        <div className='Choosespecialitymaindiv'>   
        <div className='Choosespecialitydiv1'>
            <div className='Choosespecialitydiv2'>
                <div className='Choosespecialitydiv3'>
                <div className='Choosespecialitydiv4'>
                    <div className='imageChoosespecialitydiv4'>
                    <img src={image3} className='Choosespecialityimg2' />
                  <img src={image1} className='Choosespecialityimg' />

                
                  <img src={image2} className='Choosespecialityimg1' />
                  </div>
                </div>

               
                <div className='Choosespecialitydiv5'>
                    <h3 className='Choosespecialityheding1'>Choose a speciality </h3>
                    <p className='Choosespecialityspan'>Choose a specialty based on the medical condition you have. If you are unsure, you can consult with a general physician to guide you through your health condition. For COVID-19 consults, you can chat with a pulmonologist to analyse your Antibody test, RT-PCR test results, or help you with a COVID-19 treatment plan.</p>
                </div>
                </div>
            </div>
            <div className='Choosespecialitydiv2'>
                <div className='Choosespecialitydiv3'>
                <div className='Choosespecialitydiv4'>
                    <div className='imageChoosespecialitydiv4'>
                    <img src={image4} className='Choosespecialityimg2' />
                  <img src={image1} className='Choosespecialityimg' />

                
                  <img src={image2} className='Choosespecialityimg1' />
                  </div>
                </div>

               
                <div className='Choosespecialitydiv5'>
                    <h3 className='Choosespecialityheding1'>Choose your Doctor</h3>
                    <p className='Choosespecialityspan'>Consult a doctor online either through chat, audio call, or video call and address your health problems. Apart from medical advice, the doctor will also give lifestyle tips for your condition, guidelines on COVID-19 Home care, or guidelines on COVID-19 treatment and share a prescription.</p>
                </div>
                </div>
            </div>
            <div className='Choosespecialitydiv2'>
                <div className='Choosespecialitydiv3'>
                <div className='Choosespecialitydiv4'>
                    <div className='imageChoosespecialitydiv4'>
                    <img src={image5} className='Choosespecialityimg2' />
                  <img src={image1} className='Choosespecialityimg' />

                
                  <img src={image2} className='Choosespecialityimg1' />
                  </div>
                </div>

               
                <div className='Choosespecialitydiv5'>
                    <h3 className='Choosespecialityheding1'>Book Lab Test Online </h3>
                    <p className='Choosespecialityspan'>Get any lab test done, from the safety & comfort of your home. Your lab reports will be available to you on the app and a top doctor will analyse them, for free.</p>
                </div>
                </div>
            </div>
            <div className='Choosespecialitydiv2'>
                <div className='Choosespecialitydiv3'>
                <div className='Choosespecialitydiv4'>
                    <div className='imageChoosespecialitydiv4'>
                    <img src={image6} className='Choosespecialityimg2' />
                  <img src={image1} className='Choosespecialityimg' />

                
                  <img src={image2} className='Choosespecialityimg1' />
                  </div>
                </div>

               
                <div className='Choosespecialitydiv5'>
                    <h3 className='Choosespecialityheding1'>Buy Medicine Online </h3>
                    <p className='Choosespecialityspan'>You can buy medicines online through our app and get them delivered to your doorstep on the same day, all without you having to step out of home.</p>
                </div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Choosespeciality
