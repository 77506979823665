import { Button, Card, Stack, Tooltip } from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import CreateStatePouup from "./CreateStatePouup";
import StateEdit from "./StateEdit";

const StateList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10);
  // const PER_PAGE = 5;
  const location = useLocation();
  const [openpopupState, setopenpopupState] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (filterData?.name || nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter || filterData.name}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    }
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip>
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    const statename = row?.state?.name;
    const shortname = row?.state?.shortname;
    const statecode = row?.state?.code;
    const createdbyname = row?.createdbyname
      ? row?.createdbyname
      : row?.createdby;
    return {
      ...row,
      statename,
      shortname,
      statecode,
      createdbyname,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "statename", label: "State Name" },
    { id: "shortname", label: "Short Name" },
    { id: "statecode", label: "State Code" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        tableDataAction({
          url: `${
            APIs.pincodeServiceApi
          }/get?type=STATE&ispageable=true&page=${0}&size=${perPage}&st=${
            filterData.name
          }`,
        })
      );
    }
  };

  const handleClear = () => {
    setFilterData({});
    // navigate("/app/mic/create-mic")
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleClose = () => {
    setopenpopup(false);
    setopenpopupState(false);
  };
  const handlereset = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleresetcreate = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.pincodeServiceApi}/get?type=STATE&ispageable=true&page=${
          currentPage - 1
        }&size=${perPage}`,
      })
    );
  };
  const handleOpenEditDialog = (row) => {
    setSelectedUser(row);
    setopenpopup(true);
  };

  async function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          swal("Deleting...", {
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
          });

          await axios.put(`${APIs.pincodeServiceApi}/delete`, row);

          swal("Deleted!", "Your item has been deleted.", "success");

          dispatch(
            tableDataAction({
              url: `${
                APIs.pincodeServiceApi
              }/get?type=STATE&ispageable=true&page=${
                currentPage - 1
              }&size=${perPage}`,
            })
          );
        } catch (error) {
          console.error("Error during delete request:", error);
          swal(
            "Oops!",
            error?.response?.data?.aceErrors?.[0]?.errorMessage ||
              "Something went wrong.",
            "error"
          );
        }
      } else {
        console.log("User cancelled deletion");
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "-4%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "-4%" }}>
          <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
            <div style={addButtonStyle}>
              <span
                style={{
                  alignSelf: "center",
                }}
              >
                <MDTypography variant="h5" fontWeight="medium">
                  State List
                </MDTypography>
              </span>
              <span
                style={{
                  alignSelf: "start",
                  color: "#344767",
                }}
              >
                <MDButton color="info" onClick={() => setopenpopupState(true)}>
                  ADD
                </MDButton>
              </span>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopup && (
        <StateEdit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"State Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          handlereset={handlereset}
        />
      )}
      {openpopupState && (
        <CreateStatePouup
          openpopupState={openpopupState}
          setopenpopupState={setopenpopupState}
          handleClose={handleClose}
          handleresetcreate={handleresetcreate}
        />
      )}
    </DashboardLayout>
  );
};
export default StateList;
