import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";;
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { Link, useNavigate } from "react-router-dom";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import DashboardLayout from "components/DashboardLayout";
import { navigate } from "ionicons/icons";

function AnalyticsCard({ data }) {
    const [loading, setLoading] = useState(true);
    const [color] = useState("#344767");
    const navigate = useNavigate();

    console.log(data, "data 17")

    return (
        <ComplexStatisticsCard
            color="dark"
            icon="weekend"
            title={data.title}
            count={data.count}
        />
    );
}


export default AnalyticsCard;