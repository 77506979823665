import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import axios from "../../../../axiosinstance";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import CircularProgress from "@mui/material/CircularProgress";
import { updateProject } from "components/Resuable/Create Project/service/Projectcreate";
import { Link, useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import { labappCode } from "static/labappCode";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import MapPopup from "app/agent/registrationResource/components/Map/MapPopup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MDTypography from "components/MDTypography";
import FormField from "../diseasesMaster/FormField";
import { Autocomplete } from "@mui/material";
import DataTable from "examples/Tables/DataTable";
import { FaRegTimesCircle } from "react-icons/fa";


const RequsitionPopup = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, openpopup, setopenpopup, handleClose, passdata } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeloading, setTypeLoading] = useState(false);
  const [cuurentQuantity, setCuurentQuantity] = useState("");
  const [reqLineItemIdValue, setreqLineItemIdValue] = useState(null);
  const [reqQuantity, setReqQuantity] = useState("");
  const [demand, setDemand] = useState("");
  const [typelist, setTypeList] = useState([]);
  const [itemloading, setItemLoading] = useState(false);
  const [vendorDetail, setVendorDetail] = useState("");
  const [itemlist, setItemList] = useState([]);
  const [itemcodeloading, setItemcodeLoading] = useState(false);
  const [warehouseloading, setWarehouseloading] = useState(false);
  const [reqLineItemIdList, setreqLineItemIdList] = useState("");
  const [MMUItemCode, setMMUItemCode] = useState("");
  const [whid, setWHID] = useState("");
  const [owhid, setOWHID] = useState("");
  const [mmuCodeList, setmmuCodeList] = useState([]);
  const [quantityRequest, setQuantityRequest] = useState(null);
  const [unitprice, setUnitprice] = useState(null);
  const [finalMedRequisitionGenArr, setFinalMedRequisitionGenArr] =
    useState("");

  const [itype, setItype] = useState("");
  const [medRequisitionGenArr, setMedRequisitionGenArr] = useState([]);
  const [wareHouseList, setWarehouseList] = useState([]);
  const [inputData, setInputData] = useState({
    createdby: "",
    createdbyname: "",
    itemtype: "",
    mmucode: "",
    mmuid: "",
    mmuitemcode: "",
    mmuname: "",
    mrnumber: "",
    name: "",
    quantityapproved: "",
    quantitypndg: "",
    quantityrcvd: "",
    quantityrqst: "",
    recstatus: "OPEN",
    remarks: "",
    sapitemcode: "",
    status: "ACTIVE",
    syncstatus: "SYNCED",
    tempid: "",
    uom: "",
    whid: "",
    whname: "",
    owhid: "",
    owhname: "",
  });
  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },
      { Header: "UOM", accessor: "uom" },
      { Header: "Current Qty", accessor: "currentstock" },
      { Header: "Stock Request Qty", accessor: "stockquantityrqst" },
      { Header: "Requested Qty", accessor: "quantityrqst" },
      { Header: "unit price", accessor: "unitprice" },
      { Header: "net price", accessor: "netprice" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableData, setTableData] = useState(dataObj);

  const initialValues = {
    itemtype: "",
    type: "",
    mmucode: "",
    whid: "",
    owhid: "",
    vendorDetail: "",
    reqLineItemIdValue: null,
    quantityRequest: "",
    unitprice: "",
  };

  const defaultPropsreqLineItemId = {
    options: !reqLineItemIdList
      ? [{ name: "Loading...", id: 0 }]
      : reqLineItemIdList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  const onSubmit = async () => {

    if (finalMedRequisitionGenArr.length <= 0) {
      swal("Oops!", "Please add data in table first!.", "warning");
      return;
    }
    for (let i = 0; i < finalMedRequisitionGenArr.length; i++) {
      delete finalMedRequisitionGenArr[i].id;
      delete finalMedRequisitionGenArr[i].action;
      delete finalMedRequisitionGenArr[i].currentstock;
      delete finalMedRequisitionGenArr[i].loc;
      delete finalMedRequisitionGenArr[i].stockquantityrqst;
    }

    setIsLoading(true);

    const response = await axios.post(
      `${APIs.medrequisitiongenServiceApi}/createList`,
      finalMedRequisitionGenArr
    );

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      passdata();
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });
    } else {
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.data?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      handleClose();
    }
  };

  async function getItemData() {
    setItemLoading(true);
    const response = await axios.get(
      `${APIs.typeMasterServiceApi}/get?category=ITEM`
    );
    console.log(response);
    if (!response.error) {
      if (response?.data?.data?.dtoList && response?.data?.data?.listSize > 0) {
        setItemList(response?.data?.data?.dtoList);
        console.log(response?.data?.data?.dtoList);
      } else {
        swal("", "No Item data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.data?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setItemLoading(false);
  }
  async function getStockDataByMmu(mmucode) {
    await axios
    .get(`${APIs.stockServiceApi}/get?mmucode=${mmucode}`)
      .then((response) => {
        setreqLineItemIdList(response?.data?.data?.dtoList);
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getTypeData() {
    setTypeLoading(true);
    const response = await axios.get(
      `${APIs.typeMasterServiceApi}/get?category=WAREHOUSE`
    );

    if (!response.error) {
      if (response?.data?.data?.dtoList && response?.data?.data?.listSize > 0) {
        setTypeList(response?.data?.data?.dtoList);
      } else {
        swal("", "No Type data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.data?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setTypeLoading(false);
  }

  async function getItenCodeData() {
    setItemcodeLoading(true);
    const response = await axios.get(`${APIs.mmuCreationService}/get`);

    if (!response.error) {
      if (response?.data?.data?.dtoList && response?.data?.data?.listSize > 0) {
        setmmuCodeList(response?.data?.data?.dtoList);
      } else {
        swal("", "No  data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.data?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setItemcodeLoading(false);
  }

  const addData2 = (item, values, resetForm) => {
    if (
      !values.itemtype ||
      !values.type ||
      !values.mmucode ||
      !values.whid ||
      !values.vendorDetail ||
      !values.owhid ||
      !values.unitprice ||
      !values.quantityRequest ||
      !values.reqLineItemIdValue
    ) {
      swal("Oops!", "Please fill in all the required fields.", "warning");
      return;
    }

    var netprice = 1 * values.quantityRequest * values.unitprice;

    const finalObj = {
      createdby: auth?.profileid,
      id: item.id,
      itemtype: values.itemtype,
      mmucode: values.mmucode,
      mmuid: item.mmuid,
      mmuitemcode: item.mmuitemcode,
      mmuname: item.mmuname,
      name: item.name,
      quantityrqst: values.quantityRequest,
      recstatus: "OPEN",
      remarks: "",
      sapitemcode: item.itemcode,
      status: "ACTIVE",
      syncstatus: "SYNCED",
      tempid: "",
      uom: item.uom,
      whid: values.whid,
      whname: item.whname,
      owhid: values.owhid,
      owhname: item.owhname,
      loc: item.whid ? item.whname : item.mmuname,
      stockquantityrqst: item?.quantityrqst,
      currentstock: item?.currentstock,
      vendor: values.vendorDetail,
      unitprice: values.unitprice,
      netprice: netprice,
      ponumber: "PO",
      action: (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeItem(finalObj?.id)}
            />
          </Link>
        </span>
      ),
    };

    let filter = tableData.rows.filter((a) => a.id == finalObj.id);

    if (filter.length < 1) {
      medRequisitionGenArr.push(finalObj);
      tableData.rows.push(finalObj);
      setMedRequisitionGenArr(medRequisitionGenArr);
      dataObj.rows = tableData.rows;
      setTableData(dataObj);
      setFinalMedRequisitionGenArr(tableData.rows);
      resetForm();
      setreqLineItemIdList("");
      inputData.id = "";
      inputData.itemtype = "";
      inputData.name = "";
      inputData.itemcode = "";
      inputData.uom = "";
      inputData.quantityrqst = "";
      inputData.currentstock = "";
      setReqQuantity("");
      setDemand("");
      setCuurentQuantity("");
    }
  };
  const removeItem = function (id) {
    const objWithIdIndex = tableData.rows.findIndex((obj) => obj.id === id);
    console.log("i", objWithIdIndex);
    tableData.rows.splice(objWithIdIndex, 1);
    setTableData(tableData);
    setFinalMedRequisitionGenArr(tableData.rows);
    console.log(tableData.rows)
  };
  function getWarehouseData() {
    setWarehouseloading(true);
    axios
      .get(`${APIs.warehouseServiceApi}/get?recStatus=OPEN`)
      .then((response) => {
        setWarehouseList(response?.data?.data?.dtoList);
        setWarehouseloading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getTypeData();
    getItemData();
    getItenCodeData();
    getWarehouseData();
  }, []);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          handleChange,
          resetForm,
        }) => (
          <Form>
            <DialogContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl
                    fullWidth
                    error={!!errors.itemtype && touched.itemtype}
                  >
                    <InputLabel
                      htmlFor="itemtype"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Item Type
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="itemtype"
                        id="itemtype"
                        value={values.itemtype}
                        onChange={(e) => {
                          const itemtype = e.target.value;
                          setFieldValue("itemtype", itemtype);
                          setItype(itemtype?.name);
                        }}
                      >
                        <option value=""></option>
                        {itemlist?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {itemloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.itemtype && touched.itemtype && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.itemtype}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth error={!!errors.type && touched.type}>
                    <InputLabel
                      htmlFor="type"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Store Type
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="type"
                        id="type"
                        value={values.type}
                        onChange={(e) => {
                          const type = e.target.value;
                          setFieldValue("type", type);
                        }}
                      >
                        <option value=""></option>
                        {typelist?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {typeloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.type && touched.type && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.mmucode && touched.mmucode}
                  >
                    <InputLabel
                      htmlFor="mmucode"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Select MMU
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="mmucode"
                        id="mmucode"
                        value={values.mmucode}
                        onChange={(e) => {
                          const mmucode = e.target.value;
                          setFieldValue("mmucode", mmucode);
                          setMMUItemCode(mmucode);
                          getStockDataByMmu(mmucode);
                        }}
                      >
                        <option value=""></option>
                        {mmuCodeList?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {itemcodeloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.mmucode && touched.mmucode && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.mmucode}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={1}>
                <Grid item xs={12} sm={4}>
                  <FormControl fullWidth error={!!errors.whid && touched.whid}>
                    <InputLabel
                      htmlFor="whid"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Requesting Warehouse
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="whid"
                        id="whid"
                        value={values.whid}
                        onChange={(e) => {
                          const whid = e.target.value;
                          setFieldValue("whid", whid);
                          setWHID(whid);
                        }}
                      >
                        <option value=""></option>
                        {wareHouseList?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {warehouseloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.whid && touched.whid && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.whid}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormControl
                    fullWidth
                    error={!!errors.owhid && touched.owhid}
                  >
                    <InputLabel
                      htmlFor="owhid"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Servicing Warehouse
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="owhid"
                        id="owhid"
                        value={values.owhid}
                        onChange={(e) => {
                          const owhid = e.target.value;
                          setFieldValue("owhid", owhid);
                          setOWHID(owhid);
                        }}
                      >
                        <option value=""></option>
                        {wareHouseList?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {warehouseloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.owhid && touched.owhid && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.owhid}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDInput
                    fullWidth
                    label=<span>
                    Vendor detail...{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    size="large"
                    multiline
                    variant="standard"
                    value={values.vendorDetail}
                    onChange={(e) =>
                      setFieldValue("vendorDetail", e.target.value)
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    {...defaultPropsreqLineItemId}
                    value={values.reqLineItemIdValue}
                    onChange={(event, newValue) => {
                      setreqLineItemIdValue(newValue);
                      setFieldValue("reqLineItemIdValue", newValue);
                      inputData.id = newValue?.id;
                      inputData.itemtype = newValue?.itemtype;
                      inputData.name = newValue?.name;
                      inputData.itemcode = newValue?.itemcode;
                      inputData.uom = newValue?.uom;
                      inputData.quantityrqst = newValue?.quantityrqst;
                      inputData.currentstock = newValue?.currentstock;
                      setReqQuantity(newValue?.quantityrqst);
                      setDemand(newValue?.quantityindemand);
                      setCuurentQuantity(newValue?.currentstock);
                    }}
                    renderInput={(params) => (
                      <MDInput
                        {...params}
                        variant="standard"
                        label=<span>
                        Select Item{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label=<span>
                    Quantity{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="quantity"
                    value={values.quantityRequest}
                    placeholder="Quantity"
                    onChange={(e) => {
                      setFieldValue("quantityRequest", e.target.value);
                      setQuantityRequest(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <FormField
                    type="number"
                    label=<span>
                    Unit Price{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="price"
                    value={values.unitprice}
                    placeholder="Unit Price"
                    onChange={(e) => {
                      setFieldValue("unitprice", e.target.value);
                      setUnitprice(e.target.value);
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={2}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    style={{ marginTop: "10px" }}
                    onClick={() => addData2(inputData, values, resetForm)}
                  >
                    Add
                  </MDButton>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="number"
                    label="Current Quantity"
                    name="current_quantity"
                    value={cuurentQuantity}
                    placeholder="Cuurent Quantity"
                    disabled="true"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="number"
                    label="Requested Quantity"
                    name="req_quantity"
                    value={reqQuantity}
                    disabled="true"
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <FormField
                    type="number"
                    label="demand"
                    name="demand_quantity"
                    value={demand}
                    disabled="true"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  border: "1px solid #727375",
                  borderRadius: "5px",
                  marginTop: "25px",
                }}
              >
                <DataTable
                  showTotalEntries={false}
                  entriesPerPage={false}
                  table={tableData}
                  imageMaxWidth={"100px"}
                />
              </Grid>
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {isLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Submit"
                )}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default RequsitionPopup;
