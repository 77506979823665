// import React, { useState } from 'react';
// import { Grid, Card, TextField, InputLabel ,useMediaQuery } from '@mui/material';
// import "./style.css";
// import FormControl from "@mui/material/FormControl";
// import phoneImage from '../../assets/images/callimg1.png';
// import Button from "@mui/material/Button";
// const CallbackForm = () => {
//     const [phoneNumber, setPhoneNumber] = useState('');
//     const [email, setEmail] = useState('');
//     const [phoneError, setPhoneError] = useState('');
//     const [emailError, setEmailError] = useState('');
//     const isSmallScreen = useMediaQuery('(max-width:600px)');
//     const isLargeScreen = useMediaQuery('(min-width:600px)');
//     const validatePhoneNumber = () => {
//         if (!phoneNumber) {
//             setPhoneError('Phone number is required');
//             return false;
//         } else if (phoneNumber.length !== 9) {
//             setPhoneError('Phone number must be exactly 10 digits');
//             return false;
//         } else {
//             setPhoneError('');
//             return true;
//         }
//     };
//     const validateEmail = () => {
//         if (!email || !/\S+@\S+\.\S+/.test(email)) {
//             setEmailError('Invalid email address');
//             return false;
//         }
//         setEmailError('');
//         return true;
//     };
//     const handlePhoneNumberChange = (e) => {
//         e.preventDefault();
//         setPhoneNumber(e.target.value);
//         validatePhoneNumber();
//     };

//     const handleEmailChange = (e) => {
//         e.preventDefault();
//         setEmail(e.target.value);
//         validateEmail();
//     };
//     const handleRequestCallback = (e) => {
//         e.preventDefault();

//         const isPhoneValid = validatePhoneNumber();
//         const isEmailValid = validateEmail();

//         if (!isPhoneValid || !isEmailValid) {
//             return;
//         }

//         console.log({ phoneNumber, email });
//     };
//     return (
//         <div className='callback'>
//             <Grid container mb={2}  className='callbackmain' >
//                 <Grid item xs={12} sm={12} mt={isSmallScreen? 3:0}  className='selectheading'>
//                     {!isLargeScreen && (
//                         <h2 className='callh2'>Find the right test for you <br />
//                             <span>Request a callback from our expert</span>
//                         </h2>
//                     )}
//                 </Grid>
//                 <Card className='cardstyle' sx={{background:"#ffffff",height:"auto", borderRadius:"12px", padding:"0px 30px 30px 30px",margin:"30px 30px 20px 20px" }}>
//                     <Grid container spacing={0} mt={1} >
//                         <Grid item xs={12} md={5} >
//                             {!isSmallScreen && (
//                                 <h1 className='callh2'>Find the right test for you <br />
//                                     <span>Request a callback from our expert</span>
//                                 </h1>
//                             )}
//                             <form>
//                                 <Grid item xs={12} mt={isSmallScreen?2:0}>
//                                     <InputLabel className='inputselect' sx={{fontSize:{ xs: 15, sm: 20, md: 20, lg: 20, xl: 20 },fontWeight:500,lineHeight:"21px", marginBottom:"8px",color:"#131313",fontFamily:"Poppins" }}>Phone number <sup className='inputselectspan'>*</sup></InputLabel>
//                                     <FormControl fullWidth>
//                                         <TextField
//                                             placeholder="Please enter your phone number"
//                                             variant="outlined"
//                                             className='selecttextfield'
//                                             sx={{ border: '2px solid #039147'}}
//                                             color="success"
//                                             size="medium"
//                                             type='number'
//                                             value={phoneNumber}
//                                             onChange={handlePhoneNumberChange}
//                                             InputProps={{
//                                                 classes: {
//                                                     input: 'centered-placeholder'
//                                                 }
//                                             }}
//                                         />
//                                         {phoneError && <div className="error">{phoneError}</div>}
//                                     </FormControl>
//                                 </Grid>
//                                 <Grid item xs={12} mt={2}>
//                                     <InputLabel className='inputselect'  sx={{fontSize:{ xs: 15, sm: 20, md: 20, lg: 20, xl: 20 },fontWeight:500,lineHeight:"21px",marginBottom:"8px",color:"#131313",fontFamily:"Poppins" }}>Email <sup className='inputselectspan'>*</sup></InputLabel>
//                                     <FormControl fullWidth>
//                                         <TextField
//                                             placeholder="Please enter your email"
//                                             variant="outlined"
//                                             className='selecttextfield'
//                                             sx={{ border: '2px solid #039147' }}
//                                             color="success"
//                                             size="medium"
//                                             type='email'
//                                             value={email}
//                                             onChange={handleEmailChange}
//                                             InputProps={{
//                                                 classes: {
//                                                     input: 'centered-placeholder' 
//                                                 }
//                                             }}
//                                         />
//                                         {emailError && <div className="error">{emailError}</div>}
//                                     </FormControl>
//                                 </Grid>
//                                 <Grid item xs={12} mt={6} className='buttongrid'>
//                                     <Button variant="outlined" type="submit" onClick={handleRequestCallback}
//                                         sx={{
//                                             width: { xs: "auto", sm: 200, md: 289, lg: 289, xl: 289 },
//                                             height: "auto",
//                                             color: '#039147',
//                                             border: '3px solid #039147',
//                                             borderRadius: "8px",
//                                             padding:"10px",
//                                             borderColor: '#039147',
//                                             fontSize: { xs: 15 ,sm: 15, md: 20, lg: 20, xl: 20 },
//                                             textTransform: 'none',
//                                             '&:hover': {
//                                                 background: '#039147',
//                                                 color: '#ffffff',
//                                                 border: '2px solid #039147',
//                                             }
//                                         }}>
//                                         Request call back
//                                     </Button>
//                                 </Grid>
//                             </form>
//                         </Grid>
//                         <Grid item xs={12} md={2} className='column2'>
//                             <h2 className='column21'>OR</h2>
//                             <div className='column22' ></div>
//                         </Grid>
//                         <Grid item xs={12} md={5} className='column3' container>
//                             <Grid item xs={12} sm={12}    className='column39'>
//                                 <h2 className='h2call'>
//                                     <div className="page1"> <img className="page" src={phoneImage} alt="Phone Image" /> </div>Call us :</h2>
//                             </Grid>
//                             <Grid item xs={12} sm={10} mt={{ xs: 0, sm: 0, md: -25, lg: -25, xl: -25 }} className='column99'>
//                                 <Button variant="outlined" type="submit" 
//                                     sx={{
//                                         width: { xs: "auto", sm: 250, md: 350, lg: 390, xl: 480 },
//                                         height: { xs: "auto", sm: "auto", md: 88, lg: 88, xl: 88 },
//                                         padding: '10px',
//                                         fontSize: { xs: 18, sm: 27, md: 32, lg: 48, xl: 48 },
//                                         color: '#ffff',
//                                         fontFamily: "poppins",
//                                         borderRadius: "8px",
//                                         backgroundColor: "#039147",
//                                         borderColor: '#039147',
//                                         textTransform: 'none',
//                                         '&:hover': {
//                                             background: '#ffffff',
//                                             color: '#039147',
//                                             border: '1px solid #039147',
//                                         }
//                                     }} >
//                                     +123 456 789
//                                 </Button>
//                             </Grid>
//                         </Grid>
//                     </Grid>
//                 </Card>
//             </Grid>
//         </div>
//     );
// };
// export default CallbackForm;

import React from 'react';
import { Grid, Card, TextField, InputLabel, useMediaQuery } from '@mui/material';
import "./style.css";
import FormControl from "@mui/material/FormControl";
import phoneImage from '../../findDoctor/assets/images/callimg1.png';
import Button from "@mui/material/Button";
import { Formik, Form, Field } from 'formik';

const CallbackForm = () => {
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const isLargeScreen = useMediaQuery('(min-width:600px)');

    const validatePhoneNumber = (value) => {
        let error;
        if (!value) {
            error = 'Phone number is required';
        } else if (value.length !== 10) {
            error = 'Phone number must be exactly 10 digits';
        }
        return error;
    };

    const validateEmail = (value) => {
        let error;
        if (!value) {
            error = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(value)) {
            error = 'Invalid email address';
        }
        return error;
    };

    const handleRequestCallback = (values) => {
        console.log(values);
    };

    return (
        <div className='callback'>
            <Grid container mt={3} mb={2} className='callbackmain'>
                <Grid item xs={12} sm={12} mt={isSmallScreen ? 3 : 0} className='selectheading'>
                    {!isLargeScreen && (
                        <h2 className='callh2'>Find the right test for you <br />
                            <span>Request a callback from our expert</span>
                        </h2>
                    )}
                </Grid>
                <Card className='cardstyle' sx={{ background: "#ffffff", height: "auto", borderRadius: "12px", padding: "0px 30px 30px 30px", margin: "30px 30px 20px 20px" }}>
                    <Grid container spacing={0} mt={3}>
                        <Grid item xs={12} md={5}>
                            {!isSmallScreen && (
                                <h1 className='callh2'>Find the right test for you <br />
                                    <span>Request a callback from our expert</span>
                                </h1>
                            )}
                            <Formik
                                initialValues={{ phoneNumber: '', email: '' }}
                                onSubmit={handleRequestCallback}
                            >
                                {({ errors, touched }) => (
                                    <Form>
                                        <Grid item xs={12} mt={isSmallScreen ? 2 : 2}>
                                            <InputLabel className='inputselect' sx={{ fontSize: { xs: 15, sm: 20, md: 20, lg: 20, xl: 20 }, fontWeight: 500, lineHeight: "21px", marginBottom: "8px", color: "#131313", fontFamily: "Poppins" }}>Phone number <sup className='inputselectspan'>*</sup></InputLabel>
                                            <Field
                                                name="phoneNumber"
                                                validate={validatePhoneNumber}
                                            >
                                                {({ field }) => (
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            {...field}
                                                            placeholder="Please enter your phone number"
                                                            variant="outlined"
                                                            className='selecttextfield'
                                                            sx={{ border: '2px solid #039147' }}
                                                            color="success"
                                                            size="medium"
                                                            type='tel'
                                                            InputProps={{
                                                                classes: {
                                                                    input: 'centered-placeholder'
                                                                }
                                                            }}
                                                        />
                                                        {errors.phoneNumber && touched.phoneNumber && <div className="error">{errors.phoneNumber}</div>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <InputLabel className='inputselect' sx={{ fontSize: { xs: 15, sm: 20, md: 20, lg: 20, xl: 20 }, fontWeight: 500, lineHeight: "21px", marginBottom: "8px", color: "#131313", fontFamily: "Poppins" }}>Email <sup className='inputselectspan'>*</sup></InputLabel>
                                            <Field
                                                name="email"
                                                validate={validateEmail}
                                            >
                                                {({ field }) => (
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            {...field}
                                                            placeholder="Please enter your email"
                                                            variant="outlined"
                                                            className='selecttextfield'
                                                            sx={{ border: '2px solid #039147' }}
                                                            color="success"
                                                            size="medium"
                                                            type='email'
                                                            InputProps={{
                                                                classes: {
                                                                    input: 'centered-placeholder'
                                                                }
                                                            }}
                                                        />
                                                        {errors.email && touched.email && <div className="error">{errors.email}</div>}
                                                    </FormControl>
                                                )}
                                            </Field>
                                        </Grid>
                                        <Grid item xs={12} mt={6} className='buttongrid'>
                                            <Button variant="outlined" type="submit"
                                                sx={{
                                                    width: { xs: 200, sm: 200, md: 289, lg: 289, xl: 289 },
                                                    height: "auto",
                                                    color: '#039147',
                                                    border: '3px solid #039147',
                                                    borderRadius: "8px",
                                                    padding: "10px",
                                                    borderColor: '#039147',
                                                    fontSize: { xs: 15, sm: 15, md: 20, lg: 20, xl: 20 },
                                                    textTransform: 'none',
                                                    '&:hover': {
                                                        background: '#039147',
                                                        color: '#ffffff',
                                                        border: '2px solid #039147',
                                                    }
                                                }}>
                                                Request call back
                                            </Button>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid item xs={12} md={2} className='column2'>
                            <h2 className='column21'>OR</h2>
                            <div className='column22'></div>
                        </Grid>
                        <Grid item xs={12} md={5} className='column3' container>
                            <Grid item xs={12} sm={12} className='column39'>
                                <h2 className='h2call'>
                                    <div className="page1"> <img className="page" src={phoneImage} alt="Phone Image" /> </div>Call us :</h2>
                            </Grid>
                            <Grid item xs={12} sm={10} mt={{ xs: 0, sm: 0, md: -25, lg: -25, xl: -25 }} className='column99'>
                                <Button variant="outlined" type="submit"
                                    sx={{
                                        width: { xs: 200, sm: 280, md: 350, lg: 390, xl: 480 },
                                        height: { xs: "auto", sm: "auto", md: 88, lg: 88, xl: 88 },
                                        padding: '15px',
                                        fontSize: { xs: 18, sm: 27, md: 32, lg: 48, xl: 48 },
                                        color: '#ffff',
                                        fontFamily: "poppins",
                                        borderRadius: "8px",
                                        backgroundColor: "#039147",
                                        borderColor: '#039147',
                                        textTransform: 'none',
                                        '&:hover': {
                                            background: '#ffffff',
                                            color: '#039147',
                                            border: '1px solid #039147',
                                        }
                                    }} >
                                    +123 456 789
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </div>
    );
};

export default CallbackForm;

