import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Autocomplete, FormControlLabel, Switch } from "@mui/material";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { useDispatch } from "react-redux";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { useSelector } from "react-redux";
import MDButton from "components/MDButton";
import AdminChangePassword from "components/adminChnagePassword/AdminChangePassword";
import { APIs } from "Services/APIs";

const ApprovalRolelist = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [isactive, setIsactive] = useState("");
  const [code, setCode] = useState(null);
  const [color] = useState("#344767");
  const dispatch = useDispatch();
  const [openpopupPassword, setopenpopupPassword] = useState(false);
  const [passdata, setPassdata] = useState({});

  const { role } = useSelector((state) => state.role);
  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },
      {
        Header: "Role",
        accessor: "rolename",
      },
      { Header: "gender", accessor: "sex" },

      { Header: "Email", accessor: "emailid" },
      { Header: "Status", accessor: "isactive" },
      { Header: "change Status", accessor: "switch" },
      { Header: "change password", accessor: "changepassword" },

      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = {
    fontSize: "1.5em",
    color: "#344767",
    // marginRight: "9px",
    color: "red",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultPropsRole = {
    options: !role ? [{ name: "Loading...", id: 0 }] : role,
    getOptionLabel: (option) => option.name + " (" + option.rolecode + ") ",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          // Delete the item from the server
          await axios.put(
            `${APIs.loginServiceApi}/delete`,
            item
          );

          // Update the state by filtering out the deleted item
          setTableDatas((prevData) => {
            const newRows = prevData.rows.filter((row) => row.id !== item.id);
            return { ...prevData, rows: newRows };
          });

          swal("Your User has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting item:", error);
          swal("Error deleting record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your record is safe!");
      }
    });
  }

  function update(object) {
    axios
      .put(`${APIs.loginServiceApi}/update`, object)
      .then((response) => {
        setIsactive(response?.data?.data?.dto?.isactive);
        // Update the state with the modified data
        // setTableDatas((prevData) => {
        //   const updatedRows = prevData.rows.map((row) =>
        //     row.id === object.id ? { ...row, recstatus: object.recstatus } : row
        //   );
        //   return { ...prevData, rows: updatedRows };
        // });

        swal("", "Status updated successfully.", "success");
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const onChangePassword = (item) => {
  
    setPassdata(item);
    setopenpopupPassword(true);
  };

  const toggler = (checked, request) => {
    delete request.switch;
    // delete request.action;

    if (checked) {
      request.isactive = "ACTIVE";
    } else {
      request.isactive = "INACTIVE";
    }
    update(request);
  };

  const getData = (code) => {
    setLoading(true);
    axios

      .get(`${APIs.loginServiceApi}/get?rolecode=${code}`)

      .then((response) => {
        response.data.data.dtoList.map((item) => {
          if (item.isactive == "ACTIVE") {
            // setChecked(true);
            item.switch = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch
                    onClick={(e) => toggler(e.target.checked, item)}
                    defaultChecked
                  />
                }
                label=""
              />
            );
          }
          if (item.isactive == "INACTIVE") {
            // setChecked(true);
            item.switch = (
              <FormControlLabel
                name={item.id}
                control={
                  <Switch onClick={(e) => toggler(e.target.checked, item)} />
                }
                label=""
              />
            );
          }
          item.action = (
            <span>
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.changepassword = (
            <span>
              <Link to={{}}>
                <MDButton
                  size="small"
                  color="info"
                  onClick={() => {
                    onChangePassword(item);
                  }}
                >
                  change password
                </MDButton>
              </Link>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  useEffect(() => {
    // Call your function here
    getData(code);
  }, [isactive]);
  useEffect(() => {
    dispatch(getRole());
  }, []);
  return (
    <DashboardLayout>
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Approval List
        </MDTypography>

        <Grid item xs={12} sm={2.5} ml={3} mr={3} mt={3}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            // style={{ marginLeft: "20px" }}
            {...defaultPropsRole}
            onChange={(event, newValue) => {
              console.log(newValue);
              setCode(newValue?.rolecode);
              getData(newValue?.rolecode);
            }}
            renderInput={(params) => (
              <TextField {...params} label="Select Role" />
            )}
          />
        </Grid>

        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>
      <Footer />
      <AdminChangePassword
        title={"Change Password"}
        openpopupPassword={openpopupPassword}
        setopenpopupPassword={setopenpopupPassword}
        passdata={passdata}
      />
    </DashboardLayout>
  );
};

export default ApprovalRolelist;
