/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-lab-form",
  formField: {
    shortname: {
      name: "shortname",
      label: "Short Name",
      type: "text",
      errorMsg: "Short name is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "text",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",
    },
    subdistricCode: {
      name: "subdistricCode",
      label: "Sub District Code",
      type: "number",
      errorMsg: "Sub district code is required.",
    },
    subdistrictname: {
      name: "subdistrictname",
      label: "Sub District Name",
      type: "text",
      errorMsg: "Sub district name is required.",
    },
  },
};

export default form;
