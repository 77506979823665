import React, { useState } from "react";
import { Button, Grid } from "@mui/material";
import NeuroSurgery from "../../../../../assets/images/neurosuregery.png";
import SpineCLinic from "../../../../../assets/images/SpineClinic.png";
import ENTSurgery from "../../../../../assets/images/Entsurgery.png";

import SurgicalSpecalistInfo from "./SurgicalSpecalistinfo";
import { useNavigate } from "react-router-dom";
 
const SurgicalSpecalist = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3;

  
    const infos = [
      {
        label: "Neuro Surgery",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "At last year’s meeting in Colorado Springs, when my election as president was announced, my dear friend Dr. James C. Thompson of Galveston approached me with his characteristic broad smile and boyish demeanor and whispered in my ear, “There goes the neighborhood!” He was the first to acknowledge that my election as ASA President was unusual: I am the first minority to hold this office and a relatively recent immigrant from a small African village, Saganeiti, in a small African country, Eritrea, one of the youngest and poorest countries in the besieged Horn of Africa.Given these unlikely circumstances, I debated how to entitle my address. Should it be: “It can only happen in America,” or “From Saganeiti to San Francisco”? In the end, I chose neither. Instead, I have selected the title “Surgery: A Noble Profession in a Changing World.” While carrying a faint echo of the sentiments I just expressed, the title allows me to expand on the views and concepts I have acquired as a surgical department chair, dean of a school of medicine, and chancellor of a health sciences university at the end of the 20th century and the beginning of the 21st.",
        image: NeuroSurgery,
      },
      {
        label: "ENT Surgery",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "At last year’s meeting in Colorado Springs, when my election as president was announced, my dear friend Dr. James C. Thompson of Galveston approached me with his characteristic broad smile and boyish demeanor and whispered in my ear, “There goes the neighborhood!” He was the first to acknowledge that my election as ASA President was unusual: I am the first minority to hold this office and a relatively recent immigrant from a small African village, Saganeiti, in a small African country, Eritrea, one of the youngest and poorest countries in the besieged Horn of Africa.Given these unlikely circumstances, I debated how to entitle my address. Should it be: “It can only happen in America,” or “From Saganeiti to San Francisco”? In the end, I chose neither. Instead, I have selected the title “Surgery: A Noble Profession in a Changing World.” While carrying a faint echo of the sentiments I just expressed, the title allows me to expand on the views and concepts I have acquired as a surgical department chair, dean of a school of medicine, and chancellor of a health sciences university at the end of the 20th century and the beginning of the 21st.",
        image: ENTSurgery,
      },
     
      {
        label: "Spine Clinic",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "At last year’s meeting in Colorado Springs, when my election as president was announced, my dear friend Dr. James C. Thompson of Galveston approached me with his characteristic broad smile and boyish demeanor and whispered in my ear, “There goes the neighborhood!” He was the first to acknowledge that my election as ASA President was unusual: I am the first minority to hold this office and a relatively recent immigrant from a small African village, Saganeiti, in a small African country, Eritrea, one of the youngest and poorest countries in the besieged Horn of Africa.Given these unlikely circumstances, I debated how to entitle my address. Should it be: “It can only happen in America,” or “From Saganeiti to San Francisco”? In the end, I chose neither. Instead, I have selected the title “Surgery: A Noble Profession in a Changing World.” While carrying a faint echo of the sentiments I just expressed, the title allows me to expand on the views and concepts I have acquired as a surgical department chair, dean of a school of medicine, and chancellor of a health sciences university at the end of the 20th century and the beginning of the 21st.",
        image: SpineCLinic,
      },
     
     
      {
        label: "Ophthalmology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Ophthalmologist is a dedicated eye care specialist focusing on vision and eye health. Known for expertise in eye surgeries, refractive procedures, and treatment of eye diseases, Ophthalmologist strives to enhance and preserve patients' vision. Committed to delivering comprehensive eye care services for optimal visual outcomes.",
        image: SpineCLinic, 
      },
      {
        label: "Pediatric Dentistry",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Pediatric Dentist specializes in providing dental care for children and adolescents. With a focus on preventive and therapeutic dental treatments, Pediatric Dentist creates a positive and comfortable environment for young patients. Committed to promoting lifelong oral health habits in children.",
        image: ENTSurgery, 
      },
      {
        label: "Dermatology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Dermatologist specializes in diagnosing and treating skin conditions. With expertise in medical and cosmetic dermatology, they address a wide range of skin issues, from common rashes to complex dermatological conditions. Committed to promoting skin health, Dermatologist provides personalized care for every patient.",
        image: NeuroSurgery, 
      },
      {
        label: "Gastroenterology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Gastroenterologist is a medical expert specializing in the diagnosis and treatment of digestive system disorders. Renowned for expertise in endoscopic procedures, Gastroenterologist addresses conditions affecting the stomach, intestines, and related organs. Committed to providing comprehensive gastrointestinal care for patients.",
        image: NeuroSurgery, 
      },
      {
        label: "Rheumatology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Rheumatologist is a specialist in the diagnosis and treatment of autoimmune and inflammatory joint diseases. With a focus on managing conditions like arthritis, Rheumatologist provides personalized care to improve patients' quality of life. Committed to addressing musculoskeletal disorders and promoting overall well-being.",
        image: SpineCLinic, 
      },
      {
        label: "Pulmonology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Pulmonologist specializes in respiratory system health, focusing on the diagnosis and treatment of lung diseases. Known for expertise in managing conditions like asthma, COPD, and respiratory infections, Pulmonologist aims to enhance patients' lung function and overall respiratory well-being.",
        image: ENTSurgery, 
      },
      {
        label: "Obstetrics & Gynecology",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Obstetrician & Gynecologist provides comprehensive women's health care, covering aspects of pregnancy, childbirth, and gynecological issues. With expertise in both medical and surgical interventions, Obstetrician & Gynecologist is dedicated to ensuring the well-being of women at every stage of life.",
        image: NeuroSurgery, 
      },
      {
        label: "Orthopedic Surgery",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Orthopedic Surgeon is a skilled practitioner specializing in orthopedic surgery, dedicated to the musculoskeletal system's diagnosis and treatment. With expertise in joint replacements, sports injuries, and trauma surgery, Orthopedic Surgeon employs advanced techniques to restore mobility and improve patients' overall orthopedic health.",
        image: SpineCLinic, 
      },
      {
        label: "Plastic Surgery",
        text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
        fullText: "Plastic Surgeon specializes in cosmetic and reconstructive surgery, addressing aesthetic and functional concerns. With expertise in procedures like facelifts, breast augmentation, and reconstructive surgeries, Plastic Surgeon aims to enhance patients' confidence and well-being.",
        image: SpineCLinic, 
      },
      
    ];

  const totalPages = Math.ceil(infos.length / itemsPerPage);

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const renderPageButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          className={`pagebutton ${currentPage === i ? "active" : ""}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const visibleInfos = infos.slice(startIndex, endIndex);

  return (
    <div>
      <Grid container mt={5}>
        <Grid item xs={12} className="onlinecontainer">
          <div className="appointmenttext">Top surgical specialist</div>
          <button className="appointmentbutton" onClick={()=>navigate("/app/finddoctor/Speciality")}>Book appointment</button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        // style={{ marginTop: 3 }}
        className="rootonlinecontainer"
        ml={9}
      >
        {visibleInfos.map((info, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <SurgicalSpecalistInfo info={info} />
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 3, marginBottom: 10 }}
      >
        <Grid item xs={12} className="onlinecontainer">
          <span className="pageButtonsContainer">{renderPageButtons()}</span>
        </Grid>
      </Grid>
    </div>
  );
};

export default SurgicalSpecalist;
