const form = {
  formId: "new-user-form",
  formField: {
    mmu: {
      name: "mmu",
      label: "Select Lab",
      type: "select",
      errorMsg: "Lab is required.",
    },
    departmentname: {
      name: "departmentname",
      label: "Department",
      type: "select",
      errorMsg: "Department is required.",
    },
    designationname: {
      name: "designationname",
      label: "Designation",
      type: "select",
      errorMsg: "Designation is required.",
    },
    profilename: {
      name: "profilename",
      label: "Profile",
      type: "select",
      errorMsg: "Profile is required.",
    },
  },
};

export default form;
