import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const departmentAction = 
   createAsyncThunk("getDepartmentAction", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.designation){
        url = APIs.deparmentServiceApi +`/get?designation=${data.designation}`
    }else{
          url = APIs.deparmentServiceApi +`/get`
      }
        let respData = await getAllUsers(url, "")
        return respData.dtoList;

    } catch (error) {
      return rejectWithValue(error);
    }
  });

  export const deleteDepartmentData = createAsyncThunk("deleteDepartmentData", async (data, {rejectWithValue}) => {
    try {
         const response = await axios.put(
           `${APIs.deparmentServiceApi}/delete`,
           data
          
         );
         return await response.data;
       } catch (error) {
         return rejectWithValue(error);
       }
     });