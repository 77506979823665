import React, { useEffect, useState } from "react";
import imag1 from "../../../../assets/images/Rectangle.png";
import { Button, Grid } from "@mui/material";
import Slider from "react-slick";
import "./findDoctors.css";
import { useNavigate } from "react-router-dom";
import NumberUpdates from "../components/numberUpdates/numberUpdates";
import Cards from "../components/Cards";
import Card from "../components/card/Card";
import MedharvaPlus from "../components/medharvaPlus/medharvaPlus";
import Testimonials from "../components/testimonials/testimonials";
import WhatUserSay from "../components/whatUserSay/whatUserSay";
import EmailSubscribe from "../components/emailSubscribe/emailSubscribe";
import MDButton from "components/MDButton";

const FindDoctorHome = ({ pageLabel }) => {
  const navigate = useNavigate();
  const cardsDatas = [{ image: imag1 }, { image: imag1 }, { image: imag1 }];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);


  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ marginTop: isSmallScreen ? "25px" : "6px" }}> 
      <Slider {...sliderSettings}>
        {cardsDatas.map((card, index) => (
          <div key={index}>
            <Grid mt={23} className="home-card-A">
              <Grid className="home-card-B">
                <img
                  className="home-card-imge1"
                  src={card.image}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div className="home-card-C">
                <Grid className="home-car-D">Find India’s</Grid>
                <Grid className="home-car-E">Top doctors.</Grid>
              </div>
              <Grid className="custom-button-container">
                <MDButton  variant="gradient" color="info" white-space="nowrap">
                  <span
                    className="custom-button-text"
                    onClick={() =>
                      navigate("/app/home/find-doctor/speciality", {
                        state: {
                          pagelabel: "Find Doctors",
                        },
                      })
                    }
                  >
                    {" "}
                    Find and Book
                  </span>
                </MDButton>
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>

      {isSmallScreen ? <NumberUpdates /> : <Cards />}
      <Card pagelabel={pageLabel} />
      <Grid mt={3}>
      <MedharvaPlus />
      </Grid>
      {isMidScreen ? <></> : <Testimonials />}
      <WhatUserSay />
      <EmailSubscribe />
    </div>
  );
};

export default FindDoctorHome;
