import React, { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { Card, Tooltip } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import CreateitemPopup from "./components/CreateitemPopup";
import MDButton from "components/MDButton";
import Itemedit from "./components/ItemEdit";

const CreateItem = () => {
  // State variables
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableDatas, setTableDatas] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [itemPopup, setItemPopup] = useState(false);
  const [itemedit, setItemedit] = useState(false);
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  // Delete item function
  async function onDelete(item) {
    // Display confirmation dialog
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Make API request to delete item
        axios
          .put(`${APIs.itemmasterServiceApi}/delete`, item)
          .then((response) => {
            console.log(response);
            getApiResponse();
          });
        swal("Your role record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your operation record is safe!");
      }
    });
  }
  function handleAddButton() {
    setItemPopup(true);
    setSelectedItem(null);
  }

  // Fetch data from API
  async function getApiResponse() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "Taxable", accessor: "taxable" },
        { Header: "Tax Category", accessor: "taxcategory" },
        { Header: "Tax Code", accessor: "taxcode" },
        { Header: "Action", accessor: "action" },
      ],
      rows: [],
    };

    await axios
      .get(`${APIs.itemmasterServiceApi}/getV3?type=M_PRICE_ITEM`)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Tooltip title="Edit">
                <FaRegEdit
                  style={iconStyleedit}
                  onClick={() => {
                    setSelectedItem(item);
                    setItemedit(true);
                  }}
                />
              </Tooltip>

              <Tooltip title="Delete">
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Tooltip>
            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getApiResponse();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Item List
                  </MDTypography>
                </span>
                <span
                  style={{
                    alignSelf: "start",
                    color: "#344767",
                  }}
                >
                  <MDButton color="info" onClick={handleAddButton}>
                    ADD
                  </MDButton>
                </span>
              </div>
            </MDBox>
            <BarLoader
              color="#344767"
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />

      <Itemedit
        tittle={"Edit Item"}
        itemedit={itemedit}
        setItemedit={setItemedit}
        selectedItem={selectedItem}
        getApiResponse={getApiResponse}
      />
      <CreateitemPopup
        tittle={"Create Item"}
        getApiResponse={getApiResponse}
        itemPopup={itemPopup}
        setItemPopup={setItemPopup}
      />
    </DashboardLayout>
  );
};

export default CreateItem;
