import axios from "../axiosinstance";

export async function getAllUsers(url: string, payload:any) {
  try {
    let response:any;
    if(payload){
      response = await axios.get(url, { params: payload });
    }else{
      response = await axios.get(url);
    }
    const data = response.data.data;
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
