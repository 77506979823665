import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/Shared/DashboardNavbar";
import React, { Suspense } from "react";
import { useParams } from "react-router-dom";

import Sidenav from "components/Sidenav";
import { useMaterialUIController } from "context";
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";
import AdminProfile from "./profile/AdminProfile";

import CreateUser from "components/Resuable/Admin/NewRegistration/components/CreateUser";

import OperationMaster from "components/Resuable/Operation-Master";
import CreateUom from "components/Resuable/UOM-Master/CreateUom";
import CreateGst from "components/Resuable/GstMaster/CreateGst";
import CreateItem from "components/Resuable/ItemMaster/CreateItem";
import AdminCreatePrice from "components/Resuable/Admin/Admin Price/AdminCreatePrice";

import ProjectCreateList from "components/Resuable/Create Project/components/ProjectCreateList";
import AssociationList from "components/Resuable/Association/components/AssociationList";
import ProjectPincodeList from "components/Resuable/ProjectPincode/components/ProjectPincodeList";
import ProjectTeamList from "components/Resuable/projectTeam/componets/ProjectTeamList";
import InvestmentApproval from "components/Resuable/InvestmnetPanel/InvestmentApproval";
import WithDrawalapproval from "components/Resuable/InvestmnetPanel/WithDrawalapproval";
import ProjectclinicList from "components/Resuable/Projectclinic/components/ProjectclinicList";
import RoleList from "./AdminWorkspace/Role Master/componets/RoleList";
import DepartmentList from "./AdminWorkspace/departmentMaster/components/DepartmentList";
import DesignationList from "./AdminWorkspace/designationMaster/components/DesignationList";
import ApprovalRolelist from "./Aprrovedrole/ApprovalRolelist";
import AddDoctordetail from "./AdminWorkspace/AddDoctor Detail/AddDoctordetail";
import Createslot from "layouts/pages/slotcreation";
import QualificationForm from "layouts/pages/qualification/new-qualification";
import NewAddress from "layouts/pages/AddressInformation/new-address";
import NewCategory from "layouts/pages/category";
import PharmacyCategory from "app/pharmacy/pharmacy category/PharmacyCategory";
import AddPharmacyDetail from "./AdminWorkspace/Add pharmacy/AddPharmacyDetail";
import PharmacyAddresslist from "./AdminWorkspace/Add pharmacy/PharmacyAddresslist";
import PharmacyCategorylist from "./AdminWorkspace/Add pharmacy/PharmacyCategorylist";

import AddPharmacyPrice from "./AdminWorkspace/Add pharmacy/AddPharmacyPrice";
import ClinicList from "./AdminWorkspace/Clinic Master/componets/ClinicList";
import DiseaseList from "./AdminWorkspace/diseasesMaster/DiseaseList";

import { adminRoutes } from "routes/adminRoutes";
import TestList from "./AdminWorkspace/testMaster/TestList";
import ItemList from "./AdminWorkspace/itemMaster/ItemList";
import FrequencyList from "./AdminWorkspace/frequencyMaster/FrequencyList";
import TypeList from "./AdminWorkspace/typeMaster/TypeList";
import VitalsList from "./AdminWorkspace/vitalsMaster/VitalsList";
import VillageList from "./AdminWorkspace/villageMaster/VillageList";
import SubDistrictList from "./AdminWorkspace/subDistrictMaster/SubDistrictList";
import DistrictList from "./AdminWorkspace/districtMaster/DistrictList";
import StateList from "./AdminWorkspace/stateMaster/StateList";

import PincodeAssociationLabList from "./LabMasters/LabCreation/LabPincodeAssociation/PincodeAssociationLabList";
import CategoryList from "./AdminWorkspace/categoryMaster/componets/CategoryList";
import LabProjectList from "components/LabMaster/LabProjectMaster/CreateLabProject/LabProjectList";
import LabProjectTeamAssociationList from "components/LabMaster/LabProjectMaster/LabProjectTeamAssociation/LabProjectTeamAssociationList";
import DynamicComponent from "./AdminWorkspace/admin create role/DynamicComponent";
// import MMUTeamAssociationList from "./AdminWorkspace/MMUTEAMASSOCIATION";
// import MMUAssociationList from "./AdminWorkspace/MMUAssociation";
// import MMUList from "./AdminWorkspace/MMUList";
import WareHouseList from "./AdminWorkspace/WareHouseMaster/WareHouseList";

import LabAssociationList from "./AdminWorkspace/LabAssociation";
import LabTeamAssociationList from "./AdminWorkspace/LabTEAMASSOCIATION";
import Lablist from "./AdminWorkspace/LabList";
import UnderConstruction from "components/underConstruction/UnderConstruction";
import RequsitionList from "./AdminWorkspace/StockMaster/RequsitionList";
import RequsitionApprovalList from "./AdminWorkspace/StockMaster/RequsitionApprovalList";
import RequisitionFulFillment from "./AdminWorkspace/StockMaster/RequisitionFulFillment";
import StockList from "./AdminWorkspace/StockMaster/StockList";
import CreateScheduler from "./AdminWorkspace/MMUScheduler/CreateScheduler";
import ScheduleList from "./AdminWorkspace/MMUScheduler/ScheduleList";


import HomeSidenavSkeleton from "components/Skeleton/HomeSidenavSkeleton";
import UploadSchedule from "./AdminWorkspace/MMUScheduler/UploadSchedule/UploadSchedule";
import ApprovedScheduler from "./AdminWorkspace/MMUScheduler/ApprovedScheduler/ApprovedScheduler";
import BulkApprovedScheduler from "./AdminWorkspace/MMUScheduler/BulkApprovedScheduler/BulkApprovedScheduler";


const AdminLayout = () => {
  const [controller, dispatch] = useMaterialUIController();
  const { slug } = useParams();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  return (
    <>
    <Suspense fallback={<HomeSidenavSkeleton />}>
      <DashboardLayout>
        <DashboardNavbar />
        <Sidenav
          color={sidenavColor}
          brand={
            (transparentSidenav && !darkMode) || whiteSidenav
              ? brandDark
              : brandWhite
          }
          brandName="Medharva"
          routes={adminRoutes}
        />
        {slug === "pharmacy-category" && <PharmacyCategory />}
      </DashboardLayout>
      {slug === "profile" && <AdminProfile />}
      {slug === "role-list" && <RoleList />}
      {slug === "designation-list" && <DesignationList />}
      {slug === "department-list" && <DepartmentList />}
      {slug === "create-user" && <DynamicComponent />}
      {slug === "approval-list" && <ApprovalRolelist />}
      {slug === "doctor-detail" && <AddDoctordetail />}
      {slug === "pharmacy-detail" && <AddPharmacyDetail />}
      {slug === "pharmacy-address-detail" && <PharmacyAddresslist />}
      {slug === "pharmacy-category-list" && <PharmacyCategorylist />}
      {slug === "operation-master" && <OperationMaster />}
      {slug === "create-uom" && <CreateUom />}
      {slug === "create-gst" && <CreateGst />}
      {slug === "create-item" && <CreateItem />}
      {slug === "admin-doctor-price" && <AdminCreatePrice />}
      {slug === "admin-pharmacy-price" && <AddPharmacyPrice />}
      {slug === "clinic-master" && <ClinicList />}

      {slug === "create-mic" && <ProjectCreateList />}
      {slug === "association-list" && <AssociationList />}
      {slug === "project-pincode" && <ProjectPincodeList />}
      {slug === "project-clinic-list" && <ProjectclinicList />}
      {slug === "mic-team" && <ProjectTeamList />}
      {slug === "investment-approval" && <InvestmentApproval />}

      {slug === "withdrawal-approval" && <WithDrawalapproval />}
      {/* {slug==="withdrawal-approval" &&  <WithDrawalapproval/>} */}
      {slug === "new-qualification" && <QualificationForm />}
      {slug === "new-adress" && <NewAddress />}
      {slug === "new-category" && <NewCategory />}

      {slug === "create-slot" && <Createslot />}
      {slug === "disease-list" && <DiseaseList />}
      {slug === "category-list" && <CategoryList />}
      {slug === "test-list" && <TestList />}
      {slug === "item-list" && <ItemList />}
      {slug === "vitals-list" && <VitalsList />}
      {slug === "frequency-list" && <FrequencyList />}
      {slug === "type-list" && <TypeList />}
      {slug === "state-list" && <StateList />}
      {slug === "district-list" && <DistrictList />}
      {slug === "subdistrict-list" && <SubDistrictList />}
      {slug === "village-list" && <VillageList />} 
      {slug === "lab-project-list" && <LabProjectList />}
      {slug === "lab-project-team" && <LabProjectTeamAssociationList />}
      {/* {slug === "lab-project-pincode" && <PincodeAssociationLabList />} */}
      {/* mmu master */}
      {slug === "lab-list" && <Lablist />}
      {slug === "lab-association" && <LabAssociationList />}
      {slug === "lab-team-association" && <LabTeamAssociationList />}
      {slug === "warehouse-list" && <WareHouseList />}
      {slug === "requsition-list" && <RequsitionList />}
      {slug === "requsition-fulfilment" && <RequisitionFulFillment />}
      {slug === "requsition-approval" && <RequsitionApprovalList />}
      {slug === "stock-list" && <StockList />}
      {slug === "create-scheduler" && <CreateScheduler />}
      {slug === "schedule-list" && <ScheduleList />}
      {slug === "approved-scheduler" && <ApprovedScheduler />}
      {slug === "bulk-approved-scheduler" && <BulkApprovedScheduler />}
      {slug === "upload-schedule" && <UploadSchedule />}

      </Suspense>
    </>
  ); 
};

export default AdminLayout;
