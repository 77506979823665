// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";
import axios from "../../../../../axiosinstance"
import { APIs } from "Services/APIs"; 

function ItemInfo({ formData }) {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();
  const [options, setOptions] = useState([]);

  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { name,taxable,taxcategory,taxcode  } = formField;
  var { 
    name: nameV,
  } = values;
  
  const taxCategory = async (category) => {
    try {
      const response = await axios.get(
        `${APIs.gstServiceApi}/get?type=${category}`
      );
      setOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log('error', error);
    }
  };

 
 
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Item Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={name.type}
                label=<span>
                  {name.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="taxable"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
             Taxable<sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="taxable"
                id="taxable"
                value={values.taxable}
                onChange={(e) => {
                  const taxable = e.target.value;

                  setFieldValue("taxable", taxable);
                }}
              >
                <option value=""></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Select>
              {errors.taxable && touched.taxable && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.taxable}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="taxcategory"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
             Tax Category<sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="taxcategory"
                id="taxcategory"
                value={values.taxcategory}
                onChange={(e) => {
                  const taxcategory = e.target.value;
                  taxCategory(taxcategory)
                  setFieldValue("taxcategory", taxcategory);
                  
                }}
              >
                <option value=""></option>
                <option value="Goods">Goods</option>
                <option value="Services">Services</option>
              </Select>
              {errors.taxcategory && touched.taxcategory && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.taxcategory}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="taxcode"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
             Tax Code<sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="taxcode"
                id="taxcode"
                value={values.taxcode}
                onChange={(e) => {
                  const taxcode = e.target.value;

                  setFieldValue("taxcode", taxcode);
                }}
              >
                  <option value=""></option>
                 {options.map((option) => (
                    <option key={option.id} value={option.heading}>
                      {option.heading}
                    </option>
                  ))}
              </Select>
              {errors.taxcode && touched.taxcode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.taxcode}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
            
          
            
          
         
      
      


         
          </Grid>
        </MDBox>
    
    </MDBox>
  );
}

// typechecking props for itemInfo
ItemInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ItemInfo;
