import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Formik, Form, Field, ErrorMessage , setFieldError} from "formik";
import * as Yup from "yup";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";

import { Button, Card } from "@mui/material";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FileUpload from "react-material-file-upload";
import Swal from 'sweetalert2';
import logo from '../../../../assets/images/Sucessfull.png';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import { useSelector} from 'react-redux';
import SuccessfullPopup from "components/Popup/SuccessfullPopup";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { MoonLoader } from "react-spinners";
import { UploadDocumnet } from "Services/UploadDocumnet";
import MDInput from "components/MDInput";
import FormField from "components/Resuable/Patientsignup/FormField";

function NewAgentRegistration({ open ,onClose}) {
  const [files, setFiles] = useState([]);
  const [fileSelected, setFileSelected] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [uploadresponse, setUploadresponse] = useState({ docid: "", filename: "" });
  const [loading, setLoading] = useState(false);
  
  const formData = {

    formField: {
      name: {
        type: "text",
        label: "Name",
        placeholder: "Enter your name",
      },
      refferalcode: {
        type: "text",
        label: "Referral Code",
        placeholder: "Enter your referral code",
      },
      emailId: {
        type: "email",
        label: "Email",
        placeholder: "Enter your email",
      },
      phoneno: {
        type: "text",
        label: "Phone Number",
        placeholder: "Enter your phone number",
      },
      Idproof: {
        type: "text",
        label: "Identification Proof",
        placeholder: "Uplode your Identification Proof *",
      },
    },
  };
  const onFileChange = async (e,props) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const request = {
        resourcecode: "MEDHARVA",
        username: auth.name,
        code: auth.rolecode,
      };
      formData.append("body", JSON.stringify(request));
      setLoading(true);
      try {
        const data = await UploadDocumnet(formData);
        setUploadresponse({ docid: data?.docid, filename: data?.filename });
        props.setFieldValue('Idproof', data?.filename);
      } catch (error) {
        swal("Oops!", "An error occurred while uploading the file. Please try again later.", "error");
      }
      setLoading(false);
    }
  };
  const initialValues = {
    name: "",
    refferalcode: "",
    emailId: "",
    phoneno: "",
    Idproof: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    refferalcode: Yup.string().required("Referral code is required"),
    emailId: Yup.string().email("Invalid email address").required("Email is required"),
    phoneno: Yup.string()
      .required("Mobile number is required")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
      Idproof: Yup.string().required("Idproof is required"),
  
  });

 
  const handleSubmit = (values, { setSubmitting }) => {
    console.log('Form Data:', values);
   
    setSubmitting(false);
    
    onClose();
    setShowSuccessPopup(true);
  };
  
  return (

    <MDBox py={3} mb={3}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%", mt: 2 }}
      >
        <Grid item xs={12} lg={12}>


        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {(props) => (
            <Form>

              <MDBox mx={2} >
                <MDBox
                  variant="gradient"
                  bgColor="info"
                  borderRadius="lg"
                  coloredShadow="success"
                  mx={2}
                  mt={-3}
                  p={2}
                  mb={-2}
                  textAlign="center"
                >
                  <MDTypography
                    variant="h4"
                    fontWeight="small"
                    color="white"
                    mt={1}
                  >
                    Registration Form
                  </MDTypography>
                </MDBox>
              </MDBox>
              <MDBox p={3} mt={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={formData.formField.name.type}
                      label={<span>{formData.formField.name.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                      name="name"
                      placeholder={formData.formField.name.placeholder}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={formData.formField.refferalcode.type}
                      label={<span>{formData.formField.refferalcode.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                      name="refferalcode"
                      placeholder={formData.formField.refferalcode.placeholder}
                    />

                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={formData.formField.phoneno.type}
                      label={<span>{formData.formField.phoneno.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                      name="phoneno"
                      placeholder={formData.formField.phoneno.placeholder}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      type={formData.formField.emailId.type}
                      label={<span>{formData.formField.emailId.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                      name="emailId"
                      placeholder={formData.formField.emailId.placeholder}
                    />

                  </Grid>
                </Grid>
                <MDBox
                  mt={1}
                  width="100%"
                  display="flex"
                  justifyContent="center"
                >
                   
                   <Grid item xs={12} sm={12} sx={{ display: "flex" }}>
                


                    <Button
                      component="label"
                      variant="contained"
                      role={undefined}
                      tabIndex={-1}
                      startIcon={loading ? <MoonLoader color="#f2fefa" size={16} /> : <CloudUploadIcon />}
                      style={{ marginBottom: "10px", borderWidth: "1px", color: "white" }}
                    >
                      Upload

                      <input
                        type="file"
                        onChange={(e) => onFileChange(e, props)}
                        style={{ display: "none" }}
                        tabIndex={-1}
                      />
                    </Button>
                    <Grid item xs={12} sm={3} ml={1} mt={1}>
                      
                     <MDInput variant="standard"  name="Idproof"  type={formData.formField.Idproof.type}  value={uploadresponse?.filename} disabled />
                     <ErrorMessage name="Idproof" component="div" className="error" style={{ color: "red", fontSize: "small" ,textAlign:"center"}} />
                    </Grid>
                  </Grid>
                
                </MDBox>
              
                <MDBox
                  mt={5}
                  width="100%"
                  display="flex"
                  justifyContent="flex-end"
                >
                  <MDButton
                    type="submit"
                    variant="gradient"
                    color="info"
                    style={{ width: "100px" }}
                  >
                    Submit</MDButton>
                </MDBox>
              </MDBox>
            </Form>
             )}
          </Formik>
          {showSuccessPopup && <SuccessfullPopup message="Yay! You have successfully added a new agent to MIC. You can check the updated agent list." />}
        </Grid>
      </Grid>
    </MDBox>

  );
}

NewAgentRegistration.propTypes = {
  formData: PropTypes.object.isRequired,
};


export default NewAgentRegistration;
