import DashboardLayout from 'components/DashboardLayout'
import DashboardNavbar from 'components/Shared/DashboardNavbar'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import { useMaterialUIController } from 'context';
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";
import Sidenav from 'components/Sidenav';
import NewAddress from 'app/Doctor/DoctorWorkspace/AddressInformation/new-address';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CollectiveAgentPofile from './profile/CollectiveAgentPofile';
import { collectiveagentRoutes } from 'routes/collectiveagentRoutes';
import LabTestList from 'app/labTest/labTestList/labTestList';

import CollectiveAgentTestList from './collectiveAgentTestList/CollectiveAgentTestList';
import SearchLabPatient from 'app/lab/searchPatient/SearchLabPatient';




const CollectiveAgentLayout = () => {
    const dispatch = useDispatch();
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
    const [controller] = useMaterialUIController();
    const { slug } = useParams();
    const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode } = controller;
  

    return (
        <>
            <DashboardLayout>

                <div style={{ position: 'fixed', width: '100%', zIndex: '1000' }}>
                    <DashboardNavbar />
                </div>
                <Sidenav
                    color={sidenavColor}
                    brand={
                        (transparentSidenav && !darkMode) || whiteSidenav
                            ? brandDark
                            : brandWhite
                    }
                    brandName="Medharva"
                    routes={collectiveagentRoutes}
                />
    


                {slug === "profile" && <CollectiveAgentPofile />}
                <div style={{ marginTop: "70px" }}>
                {slug === "search-patient" && 
        <SearchLabPatient labProfileId={auth.createdby} />
      }
      </div>
                {slug === "booking-list" && <CollectiveAgentTestList />}
              



            </DashboardLayout>
        </>
    )
}

export default CollectiveAgentLayout;