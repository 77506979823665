import { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Box,
  FormHelperText,
  Grid,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { APIs } from "Services/APIs";
import { useSelector } from "react-redux";
import axios from "../../axiosinstance";

const ReferPatientPopup = (props) => {
  const {
    title,
    openRefer,
    handleCloseRefer,
    categories,
    doctors,
    patientData,
    passData,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [doctorList, setDoctorList] = useState([]);
  const [showInputs, setShowInputs] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const categoryOptions = {
    options: !categories ? [{ name: "Loading...", id: 0 }] : categories,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleSelectCatergory = (event, value, setFieldValue) => {
    setSelectedDoctor(null);
    setShowInputs(false);
    setFieldValue("doctorname", "");
    setFieldValue("address", "");
    setFieldValue("doctorid", "");
    if (!value || value.length === 0) {
      setFieldValue("categoryid", "");
      setFieldValue("category", "");
      setDoctorList([]);
      return;
    }
    console.log("value", value);
    setFieldValue("categoryid", value.code);
    setFieldValue("category", value.name);

    let tempData = doctors?.filter((item) =>
      item?.categorycodes?.includes(value?.code)
    );
    setDoctorList([...tempData, { id: -1, name: "Others" }]);
  };

  const doctorOptions = {
    options: !doctorList ? [{ name: "Loading...", id: 0 }] : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleSelectDoctor = (event, value, setFieldValue) => {
    if (!value) {
      setShowInputs(false);
      setSelectedDoctor(null);
      setFieldValue("doctorname", "");
      setFieldValue("address", "");
      setFieldValue("doctorid", "");
      return;
    }
    if (value.id === -1) {
      setShowInputs(true);
      setSelectedDoctor(null);
      setFieldValue("doctorid", "");
      setFieldValue("address", "");
    } else {
      setShowInputs(false);
      setSelectedDoctor(value);
      setFieldValue("doctorid", value.profileid);
      setFieldValue("doctorname", value.name);
      setFieldValue(
        "address",
        value?.address[0]?.firstline +
          " " +
          value?.address[0]?.secondline +
          " " +
          value?.address[0]?.city +
          " " +
          value?.address[0]?.state
      );
    }
  };

  const initialValues = {
    category: "",
    categoryid: "",
    doctorid: "",
    doctorname: "",
    address: "",
  };

  const validationSchema = Yup.object().shape({
    category: Yup.string().required("Category is required"),
    doctorname: Yup.string().when("doctorid", {
      is: (doctorid) => !doctorid,
      then: Yup.string().required("Doctor Name is required"),
      otherwise: Yup.string(),
    }),
    address: Yup.string().when("doctorid", {
      is: (doctorid) => !doctorid,
      then: Yup.string().required("Doctor Address is required"),
      otherwise: Yup.string(),
    }),
  });

  const handleSubmit = async (values) => {
    console.log(values, "Form values");
    setIsLoading(true);
    let body = {
      createdby: auth.profileid,
      createdbyname: auth.name,
      doctorid: values.doctorid,
      mmucode: values.doctorid,
      visitcategory: "OFFLINE",
      paymenttype: "UNPAID",
      patientid: patientData.patient.patientid,
      profileid: patientData.patient.profileid,
      recstatus: "REFERRED",
      status: "ACTIVE",
      visittype: "REFERAL",
      doctorname: values.doctorname,
      address: values.address,
      parentvisitid: patientData.visit.visitid,
      childvisitid: "",
    };

    await axios
      .post(`${APIs.visitServiceApi}/create`, body)
      .then((response) => {
        console.log(response, "create new refer visit response 118");
        // handleCloseRefer(response.data.data.dto.visitid)
        updateVisit(values, response.data.data.dto.visitid);
        // getData = {...getData, visit:response.data.data.dto, visitid:response.data.data.dto.visitid, recstatus: "SCHEDULED",}
        // bookingHandler(getData)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  const updateVisit = async (values, childId) => {
    const body = {
      visitid: patientData.visit.visitid,
      id: patientData.visit?.id,
      patientid: patientData.patient.patientid,
      profileid: patientData.patient.profileid,
      // patienttempid: patientData.patient.profileid,
      doctorid: patientData.visit.doctorid,
      mmucode: patientData.visit.doctorid,
      visittype: "REFERED",
      visitcategory: patientData.visit.visitcategory,
      paymenttype: "PAID",
      modifiedby: auth.profileid,
      recstatus: "COMPLETED",
      syncstatus: "NOT_SYNCED",
      status: "ACTIVE",
      parentvisitid: "",
      childvisitid: childId,
    };

    await axios
      .put(`${APIs.visitServiceApi}/update`, body)
      .then((response) => {
        console.log(response, "update visit response 150");
        handleCloseRefer();
        passData();
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  return (
    <Dialog open={openRefer} onClose={handleCloseRefer} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <DialogContent >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, errors, touched, values }) => (
            <Form>
              <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="tags-standard"
                    {...categoryOptions}
                    onChange={(event, newValue) =>
                      handleSelectCatergory(event, newValue, setFieldValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Category"
                        placeholder="Search..."
                      />
                    )}
                  />
                  {errors.category && touched.category && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.category}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                <Grid item xs={12} sm={12}>
                  <Autocomplete
                    id="tags-standard"
                    {...doctorOptions}
                    onChange={(event, newValue) =>
                      handleSelectDoctor(event, newValue, setFieldValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Search Doctor"
                        placeholder="Search..."
                      />
                    )}
                  />
                  {errors.doctorid && touched.doctorid && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.doctorid}
                    </FormHelperText>
                  )}
                  {selectedDoctor && (
                    <Grid>
                      <Box>Address: {values.address}</Box>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                {showInputs && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Doctor Name"
                        value={values.doctorname}
                        onChange={(e) =>
                          setFieldValue("doctorname", e.target.value)
                        }
                        fullWidth
                      />
                      {errors.doctorname && touched.doctorname && (
                        <FormHelperText error style={{ marginLeft: "0px" }}>
                          {errors.doctorname}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        label="Doctor Address"
                        value={values.address}
                        onChange={(e) =>
                          setFieldValue("address", e.target.value)
                        }
                        fullWidth
                      />
                      {errors.address && touched.address && (
                        <FormHelperText error style={{ marginLeft: "0px" }}>
                          {errors.address}
                        </FormHelperText>
                      )}
                    </Grid>
                  </>
                )}
              </Grid>

              {/* <DialogActions> */}
              <Grid container spacing={3} style={{ marginTop: ".1px" }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: "7px",
                    gap: "8px",
                    marginRight: "7px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    type="button"
                    onClick={handleCloseRefer}
                  >
                    Cancel
                  </MDButton>

                  <MDButton
                    variant="gradient"
                    color="info"
                    size="small"
                    type="submit"
                    // disabled={isLoading}
                  >
                    {isLoading ? (
                      <MoonLoader color="#f2fefa" size={16} />
                    ) : (
                      "Submit"
                    )}
                  </MDButton>
                </Grid>
              </Grid>
              {/* </DialogActions> */}
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default ReferPatientPopup;
