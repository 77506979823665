// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import axios from "../../../../../axiosinstance";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { micappCode } from "static/micappCode";
// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { getDepartment } from "../../../../Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "../../../../Admin/AdminWorkspace/designationMaster/service/Designation";
import { APIs } from "Services/APIs";
import { getRoleAction } from "redux/Actions/roleAction";

function UserInfo({ formData }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const { getRoles } = useSelector((state) => state);
  const [designationList, setDesignationList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [color] = useState("#344767");
  const { setFieldValue, setFieldError } = useFormikContext();
  const { formField, values, errors, touched } = formData;

  const { firstName, lastName, username, password, repeatPassword } = formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    username: usernameV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  console.log(values);

  async function getDesignationData(code) {
    try {
      const response = await axios.get(
        // `${APIs.designationServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
            `${APIs.designationServiceApi}/get`
      );
      setDesignation(response?.data?.data?.dtoList);
      const result = response?.data?.data?.dtoList.filter(
        (designationItem) => designationItem.deptcode === code
      );
      if (result?.length > 0) {
        setDesignationList(result);
      } else {
        setFieldValue("designationvalue", "");
        setDesignationList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  const getData = async () => {
    try {
      const response = await axios.get(
        // `${APIs.deparmentServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
            `${APIs.deparmentServiceApi}/get`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(async () => {
    dispatch(getRoleAction());
    getData();
    if (values?.departmentvalue !== "") {
      getDesignationData(values?.departmentvalue);
    }
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.rolecode && touched.rolecode}
              >
                <InputLabel
                  htmlFor="rolecode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(values.rolecode)}
                >
                  Role
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="rolecode"
                  id="rolecode"
                  value={values.rolecode}
                  onChange={(e) => {
                    const rolecode = e.target.value;
                    const selectedRoleObj =
                      getRoles?.list?.find(
                        (obj) => obj.rolecode === rolecode
                      ) || {};
                    setFieldValue("roleName", selectedRoleObj.name);
                    setFieldValue("rolecode", rolecode);
                  }}
                >
                  <option value=""></option>
                  {getRoles?.list?.map((obj) => (
                    <option key={obj.id} value={obj.rolecode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.rolecode && touched.rolecode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.rolecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                // error={errors.lastName && touched.lastName}
                // success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={username.type}
                label=<span>
                  {username.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.gender && touched.gender}>
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(values.gender)}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={(e) => {
                    setFieldValue("gender", e.target.value);
                  }}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
                {errors.gender && touched.gender && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.departmentvalue && touched.departmentvalue}
              >
                <InputLabel
                  htmlFor="departmentvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(values.departmentvalue)}
                >
                  Department
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="departmentvalue"
                  id="departmentvalue"
                  value={values.departmentvalue || ""}
                  onChange={(e) => {
                    const departmentvalue = e.target.value;
                    const selectedDepartmentObj =
                      department?.find((obj) => obj?.code === departmentvalue) ||
                      {};

                    getDesignationData(e.target.value);
                    setFieldValue("department", selectedDepartmentObj);

                    setFieldValue("departmentvalue", departmentvalue || "");
                  }}
                >
                  <option value=""></option>
                  {department.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.departmentvalue && touched.departmentvalue && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.departmentvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.designationvalue && touched.designationvalue}
              >
                <InputLabel
                  htmlFor="designationvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                  shrink={Boolean(values.designationvalue)}
                >
                  Designation
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="designationvalue"
                  id="designationvalue"
                  value={values.designationvalue}
                  onChange={(e) => {
                    const designationvalue = e.target.value;
                    //values.designation = newValue;
                    const designationobj =
                      designation.find(
                        (obj) => obj.code === designationvalue
                      ) || {};

                    setFieldValue("designation", designationobj);
                    setFieldValue("designationvalue", designationvalue);
                  }}
                >
                  <option value=""></option>
                  {designationList.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.designationvalue && touched.designationvalue && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.designationvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label=<span>
                  {password.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label=<span>
                  {repeatPassword.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for UserInfo
UserInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default UserInfo;
