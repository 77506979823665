/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "Association-form",
  formField: {
  
    rolecode: {
      name: "rolecode",
      label: "Role",
      type: "select",
      errorMsg: "Role is required.",
    },
   

    profile: {
      name: "profile",
      label: "profile",
      type: "select",
      errorMsg: "Profile is required.",
    },
    mmu: {
      name: "mmu",
      label: "mmu",
      type: "select",
      errorMsg: "Mmu is required.",
    }
   
   
   
   
  

   
  },
};

export default form;
