import { Button, Grid } from '@mui/material'
import React from 'react'
import '../MProvider/style.css'
const MobileButton = () => {
  return (
    <div>
      <Grid  className="medharwa-mob-button-container" >
          <Grid className="medharwa-mob-button">
          <Button >
          <span  className="medharwa-mob-button-text"
       
            > Get a Free Demo</span> 
          </Button>
       </Grid>
       </Grid>
    </div>
  )
}

export default MobileButton
