
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material'
import { ErrorMessage,Field, Form, Formik } from 'formik'
import pharmy from '../.././findDoctor/assets/images/Pharmycist.svg';
import * as Yup from 'yup';
import "./SignupSellersDoctor.css";
import { MoonLoader } from "react-spinners";
import axios from "../../../axiosinstance";


const SignupSellersDoctor = ({ onData }) => {

    const [isLoading, setIsLoading] = useState(false);



    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required(' Full Name is required !'),
             phoneNumber: Yup.number()

            .required('Phone Number is required !'),

            Specialization: Yup.string()
            .required('Specialization  is required !'),

            Experience: Yup.string()
            .required('Experience is required !'),

    });

    const handleRequestCallback = async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const formData = {
            name: values.fullName,
            isactive: "INACTIVE",
            rolename: "DOCTOR",
            rolecode: "DO001",
            description:values.Specialization,    
            mobileno: values.phoneNumber,
            experience:values.Experience,
            profileid: "string",
            recstatus: "APPROVED",
            resourcecode: "HEALTHCARE",
            status: "ACTIVE",
            syncstatus: "synced",
            createdby:"",
          };
          await axios
          .post("login-service-mongo/api/loginservice/v2/create", formData)
    
          .then((response) => {
            swal(
              "Registration Successful!",
              "Your account has been created successfully.",
              "success"
            );
            console.log(values);
            resetForm();
          })
          .catch((error) => {
            console.log(error);
            swal("Oops!", 
            (error.response && error.response.data && error.response.data.aceErrors) ?
              (error.response.data.aceErrors[0]?.errorCode === "K008" ?
                "User Already Registered with the same Email id !!" :
                error.response.data.aceErrors[0]?.errorMessage) :
              "Request failed with status code 404.",
            "warning"
          );
          
        
        resetForm();
        setIsLoading(false);
    });
  setSubmitting(false);
  setIsLoading(false);
    };
    return (
        <div className='agent-medharwa-singup-lap-a'>
            <Grid className='agent-medharwa-singup-lap-b' >


                <Formik
                    initialValues={{

                        fullName: '',
                        phoneNumber: '',
                        Specialization:"",
                        Experience: '',

                    }}

                    onSubmit={handleRequestCallback}
                    validationSchema={validationSchema}

                >
                    {({ errors, touched }) => (
                        <Form >




                            <Grid mt={2} htmlFor="fullName" className='agent-label-registraion'>Full Name</Grid>
                            <Field
                                type="text"
                                id="fullName"
                                name="fullName"
                                className='agent-input-text-area'
                            />
                            {errors.fullName && touched.fullName && <div className="agent-error">{errors.fullName}</div>}

                            <Grid mt={2} htmlFor="phoneNumber" className='agent-label-registraion'>Phone Number</Grid>
                            <Field
                                type="number"
                                id="phoneNumber"
                                name="phoneNumber"
                                className='agent-input-text-area'
                            />
                            {errors.phoneNumber && touched.phoneNumber && <div className="agent-error">{errors.phoneNumber}</div>}

                            <Grid mt={2} htmlFor="Specialization" className='label-registraion-phramyicist22'>Specialization</Grid>
                            <div className="input-with-logo">
                                <button className='pahrmycist-box-a-btn' ><img src={pharmy} alt="Pharmacy Logo" className="input-logo33" /></button>
                                <Field
                                    type="text"
                                    id="Specialization"
                                    name="Specialization"
                                    className='input-text-area-phramyicist-logo22'
                                />
                              
                                {errors.Specialization && touched.Specialization && <div className="agent-error">{errors.Specialization }</div>}
                                
                            </div>

                            <Grid mt={2} htmlFor="Experience" className='label-registraion-phramyicist112'>Experience</Grid>
                            <Field
                                as="textarea"
                                type="text"
                                id="Experience"
                                name="Experience"
                                className='input-text-area-phramyicist-about11'
                            />
                           
                            {errors.Experience && touched.Experience && <div className="agent-error">{errors.Experience}</div>}


                            <Grid container mb={5} justifyContent={"center"}>
                                {/* <button type="submit" className='agent-singup-aply-btn'>Apply Now </button> */}
                                <button type="submit" className="agent-singup-aply-btn-doctor" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#FFFFFF" size={20} /> : "Apply Now"}
        </button>
                            </Grid>
                        </Form>
                    )}
                </Formik>

            </Grid>
        </div>
    );
}

export default SignupSellersDoctor;