import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const SubDistrictEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [stateValue, setStateValue] = useState(null);
  const [districtValue, setDistrictValue] = useState(null);

  const validationSchema = Yup.object({
    subdistrictcode: Yup.string().required("District code is required."),
    shortname: Yup.string().required("Short name is required."),
    subdistrictname: Yup.string().required("District name is required."),
    district: Yup.object().nullable().required("District is required."),
    state: Yup.object().nullable().required("State is required."),
  });

  const initialValues = {
    subdistrictcode: selectedUser?.subdistrict?.code || "",
    shortname: selectedUser?.subdistrict?.shortname || "",
    subdistrictname: selectedUser?.subdistrict?.name || "",
    state: stateValue || "",
    district: districtValue || "",
  };

  const defaultPropsState = {
    options:
      stateList && stateList.length > 0
        ? stateList
        : [
            {
              state: {
                shortname: "- -",
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) =>
      option.state?.name + " (" + option?.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropsDistrict = {
    options:
      districtList && districtList.length > 0
        ? districtList
        : [
            {
              district: {
                shortname: "- -",
                name: "Loading...",
              },
              id: 0,
            },
          ],
    getOptionLabel: (option) =>
      option?.district?.name + " (" + option?.district?.shortname + ")",
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  async function getData(type) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIs.pincodeServiceApi}/get?type=${type}`
      );
      if (type === "STATE") {
        setStateList(response?.data?.data?.dtoList);
      } else if (type === "DISTRICT") {
        setDistrictList(response?.data?.data?.dtoList);
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  }

  async function getDistrictData(code) {
    // setLoading(true);
    await axios
      .get(`${APIs.pincodeServiceApi}/get?type=DISTRICT&state.code=${code}`)
      .then((response) => {
        if (code === undefined) {
          setDistrictList([]);
        }

        setDistrictList(response?.data?.data?.dtoList);

        // setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getData("STATE");
    getData("DISTRICT");
  }, []);

  useEffect(() => {
    if (selectedUser?.id) {
      const selectedState = stateList?.find(
        (state) => state?.state?.code === selectedUser?.state?.code
      );
      setStateValue(selectedState);
      if (selectedState) {
        getDistrictData(selectedState.state.code);
      }
    }
  }, [selectedUser, stateList]);

  useEffect(() => {
    if (selectedUser?.id && districtList.length > 0) {
      const selectedDistrict = districtList?.find(
        (district) => district?.district?.code === selectedUser?.district?.code
      );
      setDistrictValue(selectedDistrict);
    }
  }, [selectedUser, districtList]);

  const onSubmit = async (values) => {
    setLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,
        subdistrict: {
          code: values.subdistrictcode,
          shortname: values.shortname,
          name: values.subdistrictname,
        },
        district: values.district.district,
        state: values.state.state,
        type: "SUB-DISTRICT",
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.pincodeServiceApi}/update`, updatedUser);
      handleClose();
      handleEditreset();
      swal("Success!", "Sub District updated successfully.", "success");
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoading1(false);
    }
  };

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
              >
                {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsState}
                            value={values.state}
                            onChange={(event, newValue) => {
                              setFieldValue("stateobj", newValue);
                              setFieldValue("state", newValue);

                              getDistrictData(newValue?.state?.code);
                              setFieldValue("district", null);
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  label=<span>
                                    State
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                  variant="standard"
                                  error={errors.state && touched.state}
                                  success={
                                    values.state != null &&
                                    !errors.state &&
                                    touched.state
                                  }
                                />
                                <ErrorMessage
                                  name="state"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsDistrict}
                            value={values.district}
                            onChange={(event, newValue) => {
                              setFieldValue("districtobj", newValue);
                              setFieldValue("district", newValue);
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  label=<span>
                                    District
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                  variant="standard"
                                  error={errors.district && touched.district}
                                  success={
                                    values.district != null &&
                                    !errors.district &&
                                    touched.district
                                  }
                                />
                                <ErrorMessage
                                  name="district"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Sub District Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="subdistrictname"
                            value={values.subdistrictname}
                            onChange={handleChange}
                            error={
                              errors.subdistrictname && touched.subdistrictname
                            }
                            success={
                              values.subdistrictname != null &&
                              !errors.subdistrictname
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="subdistrictname"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Sub District Code
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="subdistrictcode"
                            value={values.subdistrictcode}
                            onChange={handleChange}
                            error={
                              errors.subdistrictcode && touched.subdistrictcode
                            }
                            success={
                              values.subdistrictcode != null &&
                              !errors.subdistrictcode
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="subdistrictcode"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Short Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="shortname"
                            value={values.shortname}
                            onChange={handleChange}
                            error={errors.shortname && touched.shortname}
                            success={
                              values.shortname != null && !errors.shortname
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="shortname"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loading1 ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default SubDistrictEdit;
