import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import  axios from "axios"
// @mui material components
import Grid from "@mui/material/Grid";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";


import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { updateProjectclinic } from "../service/Projectclinic";

// import { updateDesignation } from "../service/Designation";

const validationSchema = Yup.object().shape({
project: Yup.string().required("Project is required"),

  clinic: Yup.string().required("Clinic is required"),
 
});

const ProjectClinicEdit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedUser, openpopup, setopenpopup } = props;

  const {create} = useSelector((state) => state. create);
  const {clinic} = useSelector((state) => state. clinic);



  const [projectList, setProjectList] = useState([]);
  const [cliniclist, setCliniclist] = useState([]);


console.log("selectedUser",selectedUser)
  const onSubmit = (values) => {
    console.log("values", values);
    const updatedUser = {
      ...selectedUser,

      mmu:{
        name:values.clinicObj?.name?values.clinicObj?.name:selectedUser.mmu.name,
        code: values.clinicObj?values.clinicObj?.code : selectedUser.mmu.code
      },
      projectname:values.projectobj ? values.projectobj.name:selectedUser.projectname,
      projectcode:values.projectobj? values.projectobj.code :selectedUser.projectcode
    };
    
    dispatch(updateProjectclinic(updatedUser));
     setopenpopup(false);
  };

  const initialValues = {
  project:selectedUser?.projectcode,
  clinic:selectedUser?.mmu?.code
  };

  const handleClose = () => {
    setopenpopup(false);

  };
 



  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "green",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent >
            
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.project && touched.project}
              >
                <InputLabel
                  htmlFor="project"
                  style={{
                  
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Project selection
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="project"
                  id="project"
                  value={values.project}
                  onChange={(e) => {
                    const project = e.target.value;
                    const projectObj =
                      create.find((obj) => obj.code === project) || {};

                  
                    setFieldValue("projectobj", projectObj);
                    setFieldValue("project", project);
                  }}
                >
                  <option value=""></option>
                  {create.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.project && touched.project && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.project}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.clinic && touched.clinic}
              >
                <InputLabel
                  htmlFor="clinic"
                  style={{
                  
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Clinic
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="clinic"
                  id="clinic"
                  value={values.clinic}
                  onChange={(e) => {
                    
                   const cliniccode = e.target.value;
                    const clinicObj =
                    clinic.find((obj) => obj.code === cliniccode) || {};

                  console.log("clinicObj",clinicObj)
                    setFieldValue("clinicObj", clinicObj);
                    setFieldValue("clinic", cliniccode);
                  }}
                

                  
                >
                  <option value=""></option>
                  {clinic.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.clinic && touched.clinic && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.clinic}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
           
             
            
             
           
           
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <Button type="submit">Update</Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProjectClinicEdit;
