// Resources.js
import React, { useState, useEffect } from 'react';
import { Box, Card, Grid } from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import './resources.css';
import ResourcesCard from './ResourcesCard';

import MDButton from 'components/MDButton';
import { getResource } from 'Services/Resources';
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from 'components/Skeleton/Skeleton';
import { micappCode } from "static/micappCode";
import { APIs } from "Services/APIs";
import axios from "../../axiosinstance"
import { getRoleAction } from 'redux/Actions/roleAction';
import noData from "../../assets/images/products/No-data-found.png"

import MDBox from 'components/MDBox';
import CreateResourcepopup from 'app/agent/ResourceMaster/componets/CreateResourcepopup';


const Resources = () => {
  const dispatch = useDispatch();
  const [openpopup, setopenpopup] = useState(false);
  const {getRoles} = useSelector(state=>state);
  // const getRoles={
  //   list:[]
  // }
 

  const AddItemClick = () => {
    setopenpopup(true);
  };


  useEffect(() => {
    dispatch(getRoleAction())

  }, []);

  return (
    <DashboardLayout>
      <MDBox mt={3}>
      {getRoles?.list.length > 0 ? (
        <Card>


          {getRoles?.loading ? (

            <Grid container gap={3}>
              {[...Array(10)].map((_, index) => (
                <Card key={index}  >
                  <Skeleton width="240px" height="300px" />
                </Card>
              ))}
            </Grid>
          ) : (

            <Grid container gap={3}>
              {getRoles?.list?.map((item, index) => (
                <ResourcesCard key={index} data={item} />
              ))}
            </Grid>
          )}
          <CreateResourcepopup
            openpopup={openpopup}
            setopenpopup={setopenpopup}
          />
        </Card>
      
      ) : <Grid container xs={12} justifyContent="center" mt={5} style={{ padding: "10px" }}>
        <Card className="selectspecialitycard">
          <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>

            <img
              src={noData}
              alt="Brooklyn Alice"
              style={{
                width: '100%',
                maxWidth: '200px',
                height: 'auto',
                // maxHeight:'200px'

              }}
            />


          </MDBox>
        </Card>
      </Grid>
      }
</MDBox>
    </DashboardLayout>
  );
};

export default Resources;



