// ///////////////////////////////CUSTOM IMPORTS////////////////////////////////

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";

import OperationMaster from "components/Resuable/Operation-Master";
import CreateUom from "components/Resuable/UOM-Master/CreateUom";
import CreateGst from "components/Resuable/GstMaster/CreateGst";
import CreateItem from "components/Resuable/ItemMaster/CreateItem";

import AdminCreatePrice from "components/Resuable/Admin/Admin Price/AdminCreatePrice";

import AssociationList from "components/Resuable/Association/components/AssociationList";
import ProjectPincodeList from "components/Resuable/ProjectPincode/components/ProjectPincodeList";
import ProjectclinicList from "components/Resuable/Projectclinic/components/ProjectclinicList";
import ProjectTeamList from "components/Resuable/projectTeam/componets/ProjectTeamList";

import ProjectCreateList from "components/Resuable/Create Project/components/ProjectCreateList";
import InvestmentApproval from "components/Resuable/InvestmnetPanel/InvestmentApproval";
import WithDrawalapproval from "components/Resuable/InvestmnetPanel/WithDrawalapproval";
import FindDoctor from "app/findDoctor/FindDoctor";

const routesAdmin = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth")).name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",

        route: "/app/admin/profile",
      },

      {
        name: "Logout",
        key: "logout",
        route: "/app/home/find-doctors",
        component: <FindDoctor />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "collapse",
    name: "Admin Master",
    key: "adminmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Role",

        route: "/app/admin/role-list",
      },
      {
        name: "Create Department",

        route: "/app/admin/department-list",
      },

      {
        name: "Create Designation",

        route: "/app/admin/designation-list",
      },
      {
        name: "Create User",

        route: "/app/admin/create-user",
      },

      {
        name: "Approval Request",

        route: "/app/admin/approval-list",
      },

      // {
      //   name: "Add Doctor Detail",

      //   route: "/app/admin/doctor-detail",
      // },
      // {
      //   name: "Add Pharmacy Detail",

      //   route: "/app/admin/pharmacy-detail",
      // },
      // {
      //   name: "Pharmacy Address Detail",

      //   route: "/app/admin/pharmacy-address-detail",
      // },
      // {
      //   name: "Pharmacy Category Detail",

      //   route: "/app/admin/pharmacy-category-list",

      // },
    ],
  },

  {
    type: "collapse",
    name: "Price Management",
    key: "doctor master",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Operation Master",

        route: "/app/admin/operation-master",
      },
      {
        name: "Create UOM",

        route: "/app/admin/create-uom",
      },
      {
        name: "Create GST",

        route: "/app/admin/create-gst",
      },

      {
        name: "Create Item",

        route: "/app/admin/create-item",
      },

      {
        name: "Create Doctor Price",

        route: "/app/admin/admin-doctor-price",
      },
      // {
      //   name: "Create Pharmacy Price",

      //   route: "/app/admin/admin-pharmacy-price",

      // },
    ],
  },

  // {
  //   type: "collapse",
  //   name: "Clinic Master",
  //   key: "clinicmaster",
  //   icon: <Icon fontSize="medium">badge</Icon>,
  //   collapse: [
  //     {
  //       name: "Clinic Master",

  //       route: "/app/admin/clinic-master",
  //     },

  //     {
  //       name: "Create Association",

  //       route: "/app/admin/association-list",
  //     },

  //     {
  //       name: "Create MIC Clinic",

  //       route: "/app/admin/project-clinic-list",
  //     },
  //   ],
  // },
  {
    type: "collapse",
    name: "MIC Master",
    key: "micmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        // name: "Create Project",
        name: "Create MIC",
        key: "create-mic",
        route: "/app/admin/create-mic",
        component: <ProjectCreateList />,
      },
      // {
      //   name: "Create MIC Pincode",

      //   route: "/app/admin/project-pincode",
      // },
      {
        name: "Create MIC Team",

        route: "/app/admin/mic-team",
      },
    ],
  },
  {
    type: "collapse",
    name: "Lab Project Master",
    key: "labprojectmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        // name: "Create Project",
        name: "Lab Project List",
        key: "create-lab",
        route: "/app/admin/lab-project-list",
      
      },
      {
        name: "Lab Project Team",

        route: "/app/admin/lab-project-team",
      },
      //   {
      //   name: "Create Lab Pincode",

      //   route: "/app/admin/lab-pincode",
      // },
    ],
  },
  {
    type: "collapse",
    name: "Lab Master",
    key: "labmaster", 
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        type: "item",
        name: "Lab List",
        key: "lablist",
        route: "/app/admin/lab-list",
      },
      {
        type: "item",
        name: "Lab Association",
        key: "labassociation",
        route: "/app/admin/lab-association",
      },
    
      {
        type: "item",
        name: "Lab Team Association",
        key: "labteamassociation",
        route: "/app/admin/lab-team-association",
      },
    ],
  },
  {
    type: "collapse",
    name: "Address Master",
    key: "addressmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create State",

        route: "/app/admin/state-list",
      },

      {
        name: "Create District",

        route: "/app/admin/district-list",
      },

      {
        name: "Create Sub District",

        route: "/app/admin/subdistrict-list",
      },

      {
        name: "Create Village",

        route: "/app/admin/village-list",
      },
    ],
  },
  {
    type: "collapse",
    name: "Doctor Master",
    key: "doctormaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Add Doctor Detail",

        route: "/app/admin/doctor-detail",
      },
    ],
  },
  {
    type: "collapse",
    name: "MMU Scheduler",
    key: "mmuscheduler",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Create Schedule ",

        route: "/app/admin/create-scheduler",
      },
      {
        name: "Schedule-list",

        route: "/app/admin/schedule-list",
      },
      
      {
        name: "Approved Scheduler List",

        route: "/app/admin/approved-scheduler",
      },
      {
        name: "Bulk Approval",

        route: "/app/admin/bulk-approved-scheduler",
      },
      {
        name: "Upload Scheduler",

        route: "/app/admin/upload-schedule",
      },
    ],
  },
  {
    type: "collapse",
    name: "Stock Master",
    key: "stockmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Requsition List",

        route: "/app/admin/requsition-list",
      },
     
      {
        name: "Requisition Fulfillment",

        route: "/app/admin/requsition-fulfilment",
      },
      {
        name: "Requisition Approval",

        route: "/app/admin/requsition-approval",
      },
      {
        name: "Stock-List",

        route: "/app/admin/stock-list",
      },
    ],
  },
  {
    type: "collapse",
    name: "Masters",
    key: "master",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        name: "Category Master",

        route: "/app/admin/category-list",
      },

      {
        name: "Disease Master",

        route: "/app/admin/disease-list",
      },

      {
        name: "Frequency Master",

        route: "/app/admin/frequency-list",
      },

      {
        name: "Item Master",

        route: "/app/admin/item-list",
      },

      {
        name: "Test Master",

        route: "/app/admin/test-list",
      },

      {
        name: "Type Master",

        route: "/app/admin/type-list",
      },

      {
        name: "Vitals Master",

        route: "/app/admin/vitals-list",
      },
      {
        name: "WareHouse Master",

        route: "/app/admin/warehouse-list",
      },
    ],
  },
];

export default routesAdmin;
