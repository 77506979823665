import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const userCategoryAction = 
   createAsyncThunk("getUserCategory", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.profileid && data?.mmucode){
        url = APIs.userCatgoryServiceApi +`/get?profileid=${data.profileid}&mmucode=${data.mmucode}`
      }else if(data && data?.mmucode){
        url = APIs.userCatgoryServiceApi +`/get?mmucode=${data?.mmucode}`
      }else if(data && data.profileid){
        url = APIs.userCatgoryServiceApi +`/get?profileid=${data.profileid}`
      }
      if(url){
        let respData = await getAllUsers(url, "")
        return respData.dtoList;
      }else{
        return
      }

    } catch (error) {
      return rejectWithValue(error);
    }
  });