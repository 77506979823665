import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";

//edit icon
import { FaRegEdit } from "react-icons/fa";
import { deleteDepartment, getDepartment } from "../service/Department";
import Departmentedit from "./Departmentedit";
import Createdepartmnetpopup from "./Createdepartmnetpopup";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import DashboardLayout from "components/DashboardLayout";
import { Tooltip } from "@mui/material";

function DepartmentList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { department, loading } = useSelector((state) => state.department);
  // const [loading, setLoading] = useState(true);

  const [openpopup, setopenpopup] = useState(false);
  const [selectedDepartmnet, setSelectedDepartmnet] = useState(null);
  const [openpopupDepartment, setopenpopupDepartment] = useState(false);

  function editBrand(item) {
    setopenpopup(true);
    setSelectedDepartmnet(item);
  }

  function onDelete(item) {
    // Show a confirmation dialog using SweetAlert
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // User clicked "Delete," proceed with deletion
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });

        // Dispatch the deleteBrand action
        dispatch(deleteDepartment(item))
          .then(() => {
            // After successful deletion, close the Swal dialog
            swal("Deleted!", "Your item has been deleted.", "success");
            // Fetch the updated brand list
          })
          .catch((error) => {
            // Handle errors, display an error message, and close the Swal dialog
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        // User clicked "Cancel," do nothing
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  useEffect(() => {
    dispatch(getDepartment());
  }, []);

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "#039147",
    marginRight: "9px",
  };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Department List
                  </MDTypography>
                </span>

                <span
                  style={{
                    alignSelf: "start",
                    color: "#344767",
                  }}
                >
                  <MDButton
                    color="success"
                    onClick={() => setopenpopupDepartment(true)}
                  >
                    ADD
                  </MDButton>
                </span>
              </div>
            </MDBox>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "name", accessor: "name" },
                      { Header: "description", accessor: "description" },
                      { Header: "code", accessor: "code" },
                      {
                        Header: "hierarchical code",
                        accessor: "hierarchicalcode",
                      },
                      {
                        Header: "Created Date",
                        accessor: "createddate",
                        Cell: ({ value }) => {
                          const formattedDate = new Date(value).toDateString();
                          return formattedDate;
                        },
                      },

                      {
                        Header: "Action",
                        accessor: "action",

                        Cell: ({ row }) => (
                          <div>
                            <Tooltip title="Edit">
                              <Link to={{}}>
                                <FaRegEdit
                                  style={iconStyleedit}
                                  onClick={() => {
                                    editBrand(row.original);
                                  }}
                                />
                              </Link>
                            </Tooltip>
                            <Tooltip title="Delete">
                              <Link to={{}}>
                                <MdDelete
                                  style={iconStyle}
                                  onClick={() => {
                                    onDelete(row.original);
                                  }}
                                />
                              </Link>
                            </Tooltip>
                          </div>
                        ),
                      },
                    ],
                    rows: department || [],
                  }}
                />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
      <Departmentedit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Department Edit"}
        selectedDepartmnet={selectedDepartmnet}
      />
      <Createdepartmnetpopup
        openpopupDepartment={openpopupDepartment}
        setopenpopupDepartment={setopenpopupDepartment}
      />
    </DashboardLayout>
  );
}

export default DepartmentList;
