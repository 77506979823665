import { Button, Grid } from '@mui/material'
import React from 'react'
import '../MedicalStore/stylea.css'
const Btnstore = () => {
  return (
    <div>
        <div className="medibtn-container" >
        <Grid className="medibtn-grid"  >
        <Button>
        <span className="medibtn-text"> See all tests</span> 
        </Button>
      </Grid>
    </div>
    </div>
  )
}

export default Btnstore
