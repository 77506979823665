import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { shortname, subdistrictname, state,district, subdistricCode },
} = checkout;

const validations = [
  Yup.object().shape({
    [shortname.name]: Yup.string().required(shortname.errorMsg),

    [subdistrictname.name]: Yup.string().required(subdistrictname.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [subdistricCode.name]: Yup.string().required(subdistricCode.errorMsg),
  }),
];

export default validations;
