import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios from "../../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Footer from "examples/Footer";

import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { createUser } from "components/Resuable/UserMaster/service/User";
import { APIs } from "Services/APIs";

function CreatePatient({ roledata }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required."),
    name: Yup.string().required("First name is required."),
    age: Yup.string().required("Age is required."),
    Phonenumber: Yup.string()
      .required("Phone number is required.")
      .matches(/^\d{10}$/, "Phone number must be 10 digits."),
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email address."),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });

  // useEffect(() => {
  //   dispatch(getBrand());
  //   dispatch(getProduct());
  // }, [dispatch]);

  return (
    <Formik
      initialValues={{
        lastname: "",
        gender: "",
        age: "",
        name: "",
        Phonenumber: "",
        email: "",
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        setLoading(true);
        if (roledata === null || Object.keys(roledata).length === 0) {
          swal("Warning!", "Please select role first", "warning");
        } else {
          const UserData = {
            age: values.age,
            sex: values.gender,

            emailid: values.email,
            name: values.lastname
              ? values.name + " " + values.lastname
              : values.name,
            id: null,
            isactive: "ACTIVE",
            rolename: roledata.name,
            rolecode: roledata.rolecode,

            mobileno: values.Phonenumber,
            password: values.password,

            recstatus: "APPROVED",
            resourcecode: "HEALTHCARE",
            status: "ACTIVE",
            syncstatus: "synced",

            createdby: JSON.parse(localStorage.getItem("login-auth"))
              ?.profileid,
          };

          await axios.post(
            `${APIs.loginServiceApi}/create`,
            UserData
          )
            .then((response) => {
              setLoading(false);
              swal("Success!", "User has been created succefully.", "success");
              navigate("/app/admin/approval-list");
            })
            .catch((error) => {
              console.log(error);
              swal("Oops!", 
                (error?.response && error.response?.data && error?.response?.data?.aceErrors) ?
                  (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
                    "User Already Registered with the same Email id !!" :
                    error?.response?.data?.aceErrors?.[0]?.errorMessage) :
                  "Request failed with status code 404.",
                "warning"
              );
              setLoading(false);
            });
          }
        }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      First Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={(e) => {
                      const nameValue = e.target.value;
                      setFieldValue("name", nameValue);
                    }}
                    variant="standard"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    name="lastname"
                    id="lastname"
                    value={values.lastname}
                    onChange={(e) => {
                      const lastnameValue = e.target.value;
                      setFieldValue("lastname", lastnameValue);
                    }}
                    variant="standard"
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    InputLabelProps={{
                      style: { paddingBottom: "2px" },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="gender"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Gender{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    variant="standard"
                    style={{ width: "100%" }}
                    name="gender"
                    id="gender"
                    value={values.gender}
                    onChange={(e) => {
                      const selectedGender = e.target.value;
                      setFieldValue("gender", selectedGender);
                    }}
                    error={touched.gender && Boolean(errors.gender)}
                  >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Age
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="age"
               
                    id="age"
                    value={values.age}
                    onChange={(e) => {
                      const ageValue = e.target.value;
                      setFieldValue("age", ageValue);
                    }}
                    variant="standard"
                    error={touched.age && Boolean(errors.age)}
                    helperText={touched.age && errors.age}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Phone Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="Phonenumber"
               
                    id="Phonenumber"
                    value={values.Phonenumber}
                    onChange={(e) => {
                      const PhonenumberValue = e.target.value;
                      setFieldValue("Phonenumber", PhonenumberValue);
                    }}
                    variant="standard"
                    error={touched.Phonenumber && Boolean(errors.Phonenumber)}
                    helperText={touched.Phonenumber && errors.Phonenumber}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Email
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={(e) => {
                      const emailValue = e.target.value;
                      setFieldValue("email", emailValue);
                    }}
                    variant="standard"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="password"
                    type="password"
                    id="password"
                    value={values.password}
                    onChange={(e) => {
                      const passwordValue = e.target.value;
                      setFieldValue("password", passwordValue);
                    }}
                    variant="standard"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Confirm Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="confirmPassword"
                    type="password"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={(e) => {
                      const confirmPasswordValue = e.target.value;
                      setFieldValue("confirmPassword", confirmPasswordValue);
                    }}
                    variant="standard"
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="info">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
}

export default CreatePatient;

// import React, { useEffect } from "react";
// import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import FormControl from "@mui/material/FormControl";
// import InputLabel from "@mui/material/InputLabel";
// import Select from "@mui/material/Select";
// import TextField from "@mui/material/TextField";
// import { Grid } from "@mui/material";
// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";
// import { useDispatch } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { MoonLoader } from "react-spinners";
// import { createUser } from "components/Resuable/UserMaster/service/User";

// function CreatePatient({ roledata, commonFields, onCommonFieldChange }) {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(false);

//   const validationSchema = Yup.object().shape({
//     gender: Yup.string().required("Gender is required"),
//     name: Yup.string().required("Name is required"),
//     age: Yup.string().required("Age is required"),
//     Phonenumber: Yup.string()
//       .required("Phone number is required")
//       .matches(/^\d{10}$/, "Phone number must be 10 digits"),
//     email: Yup.string().required("Email is required").email("Invalid email address"),
//     password: Yup.string()
//       .required("Password is required")
//       .min(6, "Password must be at least 8 characters"),
//     confirmPassword: Yup.string()
//       .required("Confirm Password is required")
//       .oneOf([Yup.ref("password"), null], "Passwords must match"),
//   });

//   useEffect(() => {
//     // Update the form fields with the common fields when they change
//     setFieldValue("name", commonFields.firstName);
//     setFieldValue("lastname", commonFields.lastName);
//     setFieldValue("email", commonFields.email);
//     setFieldValue("Phonenumber", commonFields.phoneNumber);
//   }, [commonFields]);

//   return (
//     <Formik
//       initialValues={{
//         lastname: commonFields.lastName,
//         gender: "",
//         age: "",
//         name: commonFields.firstName,
//         Phonenumber: commonFields.phoneNumber,
//         email: commonFields.email,
//         password: "",
//         confirmPassword: "",
//       }}
//       validationSchema={validationSchema}
//       onSubmit={async (values, action) => {
//         setLoading(true);
//         if (roledata === null || Object.keys(roledata).length === 0) {
//           swal("Warning!", "Please select role first", "warning");
//         } else {
//           const UserData = {
//             age: values.age,
//             sex: values.gender,
//             emailid: values.email,
//             name: values.lastname ? values.name + " " + values.lastname : values.name,
//             id: null,
//             isactive: "ACTIVE",
//             rolename: roledata.name,
//             rolecode: roledata.rolecode,
//             mobileno: values.Phonenumber,
//             password: values.password,
//             recstatus: "APPROVED",
//             resourcecode: "HEALTHCARE",
//             status: "ACTIVE",
//             syncstatus: "synced",
//             createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
//           };

//           const response = await dispatch(createUser(UserData));
//           if (!response.error && !loading) {
//             setLoading(false);
//             navigate("/app/admin/approval-list");
//           } else {
//             swal({
//               icon: "error",
//               title: "Error",
//               text: response.error ? response.error?.message : "Something went wrong",
//             });
//             setLoading(false);
//           }
//         }
//       }}
//     >
//       {({ values, setFieldValue, errors, touched }) => (
//         <Form>
//           <MDBox p={3}>
//             <Grid container spacing={3}>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         First Name
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="name"
//                     id="name"
//                     value={values.name}
//                     onChange={(e) => {
//                       const nameValue = e.target.value;
//                       setFieldValue("name", nameValue);
//                       onCommonFieldChange("firstName", nameValue);
//                     }}
//                     variant="standard"
//                     error={touched.name && Boolean(errors.name)}
//                     helperText={touched.name && errors.name}
//                   />
//                 </FormControl>
//               </Grid>

//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label="Last Name"
//                     name="lastname"
//                     id="lastname"
//                     value={values.lastname}
//                     onChange={(e) => {
//                       const lastnameValue = e.target.value;
//                       setFieldValue("lastname", lastnameValue);
//                       onCommonFieldChange("lastName", lastnameValue);
//                     }}
//                     variant="standard"
//                     error={touched.lastname && Boolean(errors.lastname)}
//                     helperText={touched.lastname && errors.lastname}
//                     InputLabelProps={{
//                       style: { paddingBottom: "2px" },
//                     }}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <InputLabel
//                     htmlFor="gender"
//                     style={{
//                       marginLeft: "-14px",
//                       paddingBottom: "2px",
//                       marginTop: "2px",
//                     }}
//                   >
//                     Select Gender{" "}
//                     <sup
//                       style={{
//                         color: "red",
//                         fontSize: "small",
//                         fontWeight: "bolder",
//                         position: "relative",
//                         top: "2px",
//                       }}
//                     >
//                       {" "}
//                       *
//                     </sup>
//                   </InputLabel>
//                   <Select
//                     native
//                     fullWidth
//                     variant="standard"
//                     style={{ width: "100%" }}
//                     name="gender"
//                     id="gender"
//                     value={values.gender}
//                     onChange={(e) => {
//                       const selectedGender = e.target.value;
//                       setFieldValue("gender", selectedGender);
//                     }}
//                     error={touched.gender && Boolean(errors.gender)}
//                   >
//                     <option value=""></option>
//                     <option value="Male">Male</option>
//                     <option value="Female">Female</option>
//                     <option value="Other">Other</option>
//                   </Select>
//                   <ErrorMessage
//                     name="gender"
//                     component="div"
//                     style={{ color: "red", fontSize: "12px" }}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         Age
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="age"
//                     type="number"
//                     id="age"
//                     value={values.age}
//                     onChange={(e) => {
//                       const ageValue = e.target.value;
//                       setFieldValue("age", ageValue);
//                     }}
//                     variant="standard"
//                     error={touched.age && Boolean(errors.age)}
//                     helperText={touched.age && errors.age}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         Phonenumber
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="Phonenumber"
//                     type="number"
//                     id="Phonenumber"
//                     value={values.Phonenumber}
//                     onChange={(e) => {
//                       const PhonenumberValue = e.target.value;
//                       setFieldValue("Phonenumber", PhonenumberValue);
//                       onCommonFieldChange("phoneNumber", PhonenumberValue);
//                     }}
//                     variant="standard"
//                     error={touched.Phonenumber && Boolean(errors.Phonenumber)}
//                     helperText={touched.Phonenumber && errors.Phonenumber}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         Email
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="email"
//                     id="email"
//                     value={values.email}
//                     onChange={(e) => {
//                       const emailValue = e.target.value;
//                       setFieldValue("email", emailValue);
//                       onCommonFieldChange("email", emailValue);
//                     }}
//                     variant="standard"
//                     error={touched.email && Boolean(errors.email)}
//                     helperText={touched.email && errors.email}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         Password
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="password"
//                     id="password"
//                     value={values.password}
//                     onChange={(e) => {
//                       const passwordValue = e.target.value;
//                       setFieldValue("password", passwordValue);
//                     }}
//                     variant="standard"
//                     error={touched.password && Boolean(errors.password)}
//                     helperText={touched.password && errors.password}
//                   />
//                 </FormControl>
//               </Grid>
//               <Grid item xs={12} sm={6}>
//                 <FormControl fullWidth>
//                   <TextField
//                     label={
//                       <span>
//                         Confirm Password
//                         <sup
//                           style={{
//                             color: "red",
//                             fontSize: "small",
//                             fontWeight: "bolder",
//                             position: "relative",
//                             top: "2px",
//                           }}
//                         >
//                           {" "}
//                           *
//                         </sup>
//                       </span>
//                     }
//                     name="confirmPassword"
//                     id="confirmPassword"
//                     value={values.confirmPassword}
//                     onChange={(e) => {
//                       const confirmPasswordValue = e.target.value;
//                       setFieldValue("confirmPassword", confirmPasswordValue);
//                     }}
//                     variant="standard"
//                     error={touched.confirmPassword && Boolean(errors.confirmPassword)}
//                     helperText={touched.confirmPassword && errors.confirmPassword}
//                   />
//                 </FormControl>
//               </Grid>
//             </Grid>
//             <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
//               <MDButton type="submit" variant="gradient" color="dark">
//                 {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
//               </MDButton>
//             </MDBox>
//           </MDBox>
//         </Form>
//       )}
//     </Formik>
//   );
// }

// export default CreatePatient;
