import React, { useEffect, useState, lazy, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { testMasterListAction } from "redux/Actions/testMasterListAction";
import { frequenctMasterListAction } from "redux/Actions/frequencyMasterAction";
import { diseaseListAction } from "redux/Actions/diseaseListAction";
import HomeSkeleton from "components/Skeleton/HomeSkeleton";

const DoctorNavbar = lazy(() => import("./DoctorNavbar/DoctorNavBar"));
const DoctorProfile = lazy(() => import("./Profile/DoctorProfile"));
const Bookinglist = lazy(() => import("components/Shared/bookingList/BookingList"));
const PrescriptionList = lazy(() => import("components/Resuable/PrescriptionList/PrescriptionList"));
const Createnurse = lazy(() => import("components/Resuable/Create Nurse/Createnurse"));
const CreatePrice = lazy(() => import("components/Resuable/PriceMaster/CreatePrice"));
const Createslot = lazy(() => import("./DoctorWorkspace/slotcreation"));
const NewCategory = lazy(() => import("./DoctorWorkspace/category"));
const QualificationForm = lazy(() => import("./DoctorWorkspace/new-qualification"));
const Prescriptionview = lazy(() => import("components/Resuable/PrescriptionList/Prescriptionview"));
const NewAddress = lazy(() => import("./DoctorWorkspace/AddressInformation/new-address"));
const Myteam = lazy(() => import("./myteam/Myteam"));
const PatientQueue = lazy(() => import("./DoctorWorkspace/PatientQueue/Patientqueue"));
const SearchPatient = lazy(() => import("./DoctorWorkspace/searchpatient/SearchPatient"));
const VideoConsultation = lazy(() => import("./videoConsultation/VideoConsultation"));



const DoctorHome = () => {
  const { slug } = useParams();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(testMasterListAction());
    dispatch(frequenctMasterListAction());
    dispatch(diseaseListAction());
  }, [auth]);

  return (
    <Suspense fallback={<HomeSkeleton />}>
      {slug === "video-call" || slug === "video-consultation" ? <></> : <DoctorNavbar />}
      {slug === "profile" && <DoctorProfile />}
      {slug === "my-bookings" && <Bookinglist />}
      {slug === "my-prescription" && <PrescriptionList />}
      {slug === "create-nurse" && <Createnurse />}
      {slug === "my-team" && <Myteam />}
      {slug === "create-slot" && <Createslot />}
      {slug === "new-address" && <NewAddress />}
      {slug === "new-category" && <NewCategory />}
      {slug === "new-qualification" && <QualificationForm />}
      {slug === "prescription-view" && <Prescriptionview />}
      {slug === "video-consultation" && <VideoConsultation />}
      {slug === "search-patient" && (
        <SearchPatient doctorProfileId={auth.profileid} />
      )}
      {slug === "patient-queue" && (
        <PatientQueue doctorProfileId={auth.profileid} />
      )}
    </Suspense>
  );
};

export default DoctorHome;

























// import React, { useEffect, useState } from "react";
// import DoctorNavbar from "./DoctorNavbar/DoctorNavBar";
// import DoctorProfile from "./Profile/DoctorProfile";
// import { useParams } from "react-router-dom";
// import Bookinglist from "components/Shared/bookingList/BookingList";

// import PrescriptionList from "components/Resuable/PrescriptionList/PrescriptionList";
// import Searchpatient from "components/Resuable/Search Patient/SearchPatient";
// import Createnurse from "components/Resuable/Create Nurse/Createnurse";
// import CreatePrice from "components/Resuable/PriceMaster/CreatePrice";
// import Createslot from "./DoctorWorkspace/slotcreation";
// import NewCategory from "./DoctorWorkspace/category";
// import QualificationForm from "./DoctorWorkspace/new-qualification";
// import Prescriptionview from "components/Resuable/PrescriptionList/Prescriptionview";
// import NewAddress from "./DoctorWorkspace/AddressInformation/new-address";
// import { useSelector } from "react-redux";
// import Myteam from "./myteam/Myteam";

// import { useDispatch } from "react-redux";
// import { testMasterListAction } from "redux/Actions/testMasterListAction";
// import { frequenctMasterListAction } from "redux/Actions/frequencyMasterAction";
// import { diseaseListAction } from "redux/Actions/diseaseListAction";

// import PatientQueue from "./DoctorWorkspace/PatientQueue/Patientqueue";
// import SearchPatient from "./DoctorWorkspace/searchpatient/SearchPatient";
// import { Landing } from "app/videoCall/Landing";
// import VideoConsultation from "./videoConsultation/VideoConsultation";

// const DoctorHome = () => {
//   const { slug } = useParams();

//   const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(testMasterListAction());
//     dispatch(frequenctMasterListAction());
//     dispatch(diseaseListAction());
//   }, [auth]);

//   return (
//     <>
//       {/* {slug === "video-call" ? <></> : <DoctorNavbar />} */}
//       {slug === "video-call" || slug === "video-consultation" ? <></> : <DoctorNavbar />}
//       {slug === "profile" && <DoctorProfile />}
//       {slug === "my-bookings" && <Bookinglist />}
//       {slug === "my-prescription" && <PrescriptionList />}

//       {slug === "create-nurse" && <Createnurse />}
//       {slug === "my-team" && <Myteam />}
//       {slug === "create-slot" && <Createslot />}
//       {slug === "new-address" && <NewAddress />}
//       {slug === "new-category" && <NewCategory />}
//       {slug === "new-qualification" && <QualificationForm />}
//       {slug === "prescription-view" && <Prescriptionview />}
//       {slug === "video-consultation" && <VideoConsultation />}
//       {slug === "search-patient" && (
//         <SearchPatient doctorProfileId={auth.profileid} />
//       )}
//       {slug === "patient-queue" && (
//         <PatientQueue doctorProfileId={auth.profileid} />
//       )}
//     </>
//   );
// };

// export default DoctorHome;
