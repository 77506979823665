// ROLECODE.js

// import React from 'react';


// const Rolecode = () => {
  

  
//   const ROLE_CODE = {
//     Admincode:"AD001",
//     patientcode:"PA001",
//     Doctorcode:"DO001",
//     Nurse:"RE001"
//   };
 

//   return ROLE_CODE
   
// };

// export default Rolecode;



 
import React from 'react';
import { useSelector } from 'react-redux';



const Rolecode = () => {
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  
    const ROLE_CODE = {};
  
    if (auth.rolecode === "AD001") {
      ROLE_CODE.Admincode = "AD001";
    } else if (auth.rolecode === "PA001") {
      ROLE_CODE.patientcode = "PA001";
    } else if (auth.rolecode === "DO001") {
      ROLE_CODE.Doctorcode = "DO001";
    } else if (auth.rolecode === "RE001") {
      ROLE_CODE.Nurse = "RE001";
    }
    else if (auth.rolecode === "PH257") {
      ROLE_CODE.Pharmacy = "PH257";
    }
    else if (auth.rolecode === "AG2773") {
      ROLE_CODE.Agent = "RE001";
    }
    else if (auth.rolecode === "LB001") {
      ROLE_CODE.Lab = "LB001";
    }
    return ROLE_CODE;
  };
  
  export default Rolecode;
  


  