import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const PatientAddressEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, selectedUser, openpopup, setopenpopup, handleClose, getData } =
    props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
console.log(selectedUser)
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);

  const validationSchema = Yup.object({
    firstline: Yup.string().required("First line is required."),
    secondline: Yup.string().required("Second line is required."),
    country: Yup.string().required("Country is required."),
    state: Yup.string().required("State is required."),
    district: Yup.string().required("District is required."),
    subdistrict: Yup.string().required("Sub district is required."),
    city: Yup.string().required("City is required."),
    postcode: Yup.string().required("Pincode is required."),
    addresstype: Yup.string().required("Address type is required."),
  });

  const initialValues = {
    firstline: selectedUser?.firstline || "",
    secondline: selectedUser?.secondline || "",
    country: selectedUser?.country || "",
    state: selectedUser?.state || "",
    district: selectedUser?.district || "",
    subdistrict: selectedUser?.subdistrict || "",
    city: selectedUser?.city || "",
    postcode: selectedUser?.postcode || "",
    addresstype: selectedUser?.addresstype || "",
  };

  const onSubmit = async (values) => {
    setLoadings(true);
    try {
      const updatedUser = {
        ...selectedUser,
        firstline: values.firstline,
        secondline: values.secondline,
        addresstype: values.addresstype,
        country: values.country,
        state: values.state,
        district: values.district,
        subdistrict: values.subdistrict,
        city: values.city,
        postcode: values.postcode,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.addressServiceApi}/update`, updatedUser);
      handleClose();
      swal("Success!", "Address updated successfully.", "success");
      getData(auth?.profileid);
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoadings(false);
    }
  };

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="addresstype"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.addresstype)}
                      >
                       Address Type{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="addresstype"
                        name="addresstype"
                        value={values.addresstype}
                        onChange={(e) => {
                          const selectedType = e.target.value;

                          setFieldValue("addresstype", selectedType);
                        }}
                        error={touched.addresstype && Boolean(errors.addresstype)}
                      >
                        <option value=""></option>
                     
                          <option value="Default">Default</option>
                          <option value="Home">Home</option>
                          <option value="Office">Office</option>
                          <option value="Other">Other</option>
                      </Select>
                      <ErrorMessage
                        name="addresstype"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              First Line
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="firstline"
                            value={values.firstline}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="firstline"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              Second Line
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="secondline"
                            value={values.secondline}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="secondline"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              Country
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="country"
                            value={values.country}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="country"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              State
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="state"
                            value={values.state}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="state"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              District
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="district"
                            value={values.district}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="district"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            //  label="Sub District"
                            label=<span>
                              {" "}
                              Sub District
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="subdistrict"
                            value={values.subdistrict}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="subdistrict"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              {" "}
                              City/Village
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="city"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="number"
                            label=<span>
                              {" "}
                              Pincode
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="postcode"
                            value={values.postcode}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="postcode"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loadings ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default PatientAddressEdit;
