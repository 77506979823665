import checkout from "../schemas/form";


const {
  formField: {
    
    dateofbirth,
    gender,
    document,
    firstname,
    lastname,
    // password,
    // repeatPassword,
    secondline,
    emailId,
    phoneno,
    preferedlanguage,
    firstline,
  
    state,
    country,
    pincode,
    agentcomissiontype,
    agentcomission,
    resourcecomission,
    resourcecomissiontype,
    personalinformation,
    qualificationinformation,
    location,
    district,
    subDistrict
  },
} = checkout;

const initialValues = {
  [document.name]: [],
  [firstname.name]: "",
  [lastname.name]: "",
  // [password.name]: "",
  // [repeatPassword.name]: "",
  [gender.name]:"",
  [district.name]:  "",
  [dateofbirth.name]:  "",
  [firstline.name]:  "",
  [phoneno.name]:  "",
  [emailId.name]:  "",
  [preferedlanguage.name]:  "",
  [secondline.name]:  "",
  
  [state.name]: "",
  [subDistrict.name]: "",
  [country.name]: "",
  [pincode.name]: "",
  [agentcomissiontype.name]: "",
  [agentcomission.name]: "",
  [resourcecomission.name]: "",
[resourcecomissiontype.name]: "",
[personalinformation]:[],
[qualificationinformation]:[],
[location.name]:[0,0]
};

export default initialValues;
