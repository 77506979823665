import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getLoginServiceData } from "redux/Actions/getLoginServiceData";

const initialState = {
  rowData: {} ,
  tableData: [],
  filterArray:[],
  loading: false,
  error: null,
};

export const loginUserDataSlice = createSlice({
  name: "loginUserData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoginServiceData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getLoginServiceData.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload 22")
        state.loading = false;
        state.rowData = action.payload?.userList;
        state.tableData = action.payload?.userList.dtoList;
        state.filterArray = action.payload?.filterArray || [];
        state.error = null;
      })
      .addCase(getLoginServiceData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default loginUserDataSlice.reducer;
