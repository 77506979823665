import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { Grid } from "@mui/material";
// Material Dashboard 2 PRO React examples

import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import { BarLoader } from "react-spinners";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";

import { Autocomplete } from "@mui/material";
import PreviewIcon from "@mui/icons-material/Preview";
import axios from "../../../axiosinstance";
import UploadDocument from "./UploadDocument";
import TextField from "@mui/material/TextField";

import { useSelector } from "react-redux";
import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import Footer from "examples/Footer";
import { Link } from "react-router-dom";

function UploadPrescription() {
  const comp = "SM";

  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [uploadpopup, setUploadpopup] = useState(false);
  const [familydata, setFamilydata] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const defaultPropFamily = {
    options: !familydata ? [{ name: "Loading...", id: 0 }] : familydata,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const selfPlaceholder = "Self";

  defaultPropFamily.options = defaultPropFamily.options.map((option) => {
    if (
      option.name === localStorage.getItem("profile-Name")?.replace(/"/g, "")
    ) {
      return { ...option, name: selfPlaceholder };
    }
    return option;
  });

  const iconStyle = { color: "blue", marginRight: "9px", cursor: "pointer" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  async function getFamily(profileid) {
    await axios
      .get(`login-service-mongo/api/loginservice/v2/get?createdby=${profileid}`)
      .then((response) => {
        const newData = [...response.data?.data?.dtoList, auth];

        setFamilydata(newData);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  function getData(profileid, code) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "file name", accessor: "filename" },

        { Header: "type", accessor: "doctype" },

        { Header: "description", accessor: "description" },

        { Header: "view report", accessor: "view" },
      ],
      rows: [],
    };

    let apiEndpoint;

    if (code) {
      // If code is present, use the API endpoint with both profileid and code
      apiEndpoint = `new-upload-service-mongo/api/attachment/getAll/code/${profileid}/model/${code}`;
    } else {
      // If code is not present, use the API endpoint with only profileid
      apiEndpoint = `new-upload-service-mongo/api/attachment/getAll/code/${profileid}`;
    }

    axios
      .get(apiEndpoint)
      .then((response) => {
        console.log("response", response.data);
        response.data.map((item) => {
          item.view = (
            <span>
              <PreviewIcon
                fontSize="medium"
                style={iconStyle}
                onClick={() => {
                  window.open(item.downloadurl); // Open the file URL in a new window or tab
                }}
              />
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData(auth.profileid);
    getFamily(auth.profileid);
  }, []);
  const handleGetData = () => {
    getData(auth.profileid);
  };
  return (
    <>
      {/* {isMidScreen ? <></> : <Navbar />}
      {isMidScreen ? <MobileNavbar /> : <></>} */}
      <MDBox pt={isMidScreen ? 3 : 0}>
        <MDBox mt={23}>
          <Card
            style={{
              marginLeft: "22px",
              marginRight: "22px",
              marginTop: "170px",
            }}
          >
               <MDBox pl={3} pr={3} lineHeight={1}>
                 <div style={addButtonStyle}>
       
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Manage EMR
                  </MDTypography>
                </span>
    
                <Link
                    style={{
                      alignSelf: "flex-end",

                      marginRight: "10px",

                      marginTop: "-23px",

                      color: "#344767",
                    }}
                    to={""}
                    >
                  <MdOutlineLibraryAdd
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setUploadpopup(true);
                    }}
                  />
                   </Link>
          
              </div>
              </MDBox>
       
            <Grid container spacing={3}>
              <Grid item xs={12} sm={7}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={{ marginLeft: "20px" }}
                  sx={{ width: 300 }}
                  {...defaultPropFamily}
                  onChange={(event, newValue) => {
                    getData(auth.profileid, newValue?.profileid);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Family Member" />
                  )}
                />
              </Grid>
            </Grid>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar /> : <></>}
      {uploadpopup && (
        <UploadDocument
          tittle="Upload Document"
          uploadpopup={uploadpopup}
          getData={handleGetData}
          setUploadpopup={setUploadpopup}
        />
      )}
    </>
  );
}

export default UploadPrescription;
