import React, { useEffect, useState } from "react";

import { Dialog, DialogTitle, Grid } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Search from "../../../../../assets/svg/Search.svg";
import axios from "axios";

import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

const MapPopup = (props) => {
  const { title, openpopup, setopenpopup, onDataTransfer, data2 } = props;

  const customMarkerImageUrl =
    "https://i.pinimg.com/474x/67/c3/d6/67c3d63e215e034e01d45c8256d720d3.jpg";
  const navigate = useNavigate();
  const [mapCenter, setMapCenter] = useState({
    lat: 27.1766701,
    lng: 78.0080745,
  });
  const [searchAddress, setSearchAddress] = useState(
    [
      data2?.firstline,
      data2?.secondline,
      data2?.city,
      data2?.state,
      data2?.country,
    ]
      .filter(Boolean)
      .join(" ")
  );

  const [searchedLocation, setSearchedLocation] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [manualMarkerLocation, setManualMarkerLocation] = useState(null);
  const [selectedState, setSelectedState] = useState("");

  const data = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];
  const stateCoordinates = {
    "Andhra Pradesh": { lat: 15.9129, lng: 79.74 },
    "Arunachal Pradesh": { lat: 28.218, lng: 94.7278 },
    Assam: { lat: 26.2006, lng: 92.9376 },
    Bihar: { lat: 25.0961, lng: 85.3131 },
    Chhattisgarh: { lat: 21.2787, lng: 81.8661 },
    Goa: { lat: 15.2993, lng: 74.124 },
    Gujarat: { lat: 22.2587, lng: 71.1924 },
    Haryana: { lat: 29.0588, lng: 76.0856 },
    "Himachal Pradesh": { lat: 31.1048, lng: 77.1734 },
    Jharkhand: { lat: 23.6102, lng: 85.2799 },
    Karnataka: { lat: 15.3173, lng: 75.7139 },
    Kerala: { lat: 10.8505, lng: 76.2711 },
    "Madhya Pradesh": { lat: 22.9734, lng: 78.6569 },
    Maharashtra: { lat: 19.7515, lng: 75.7139 },
    Manipur: { lat: 24.6637, lng: 93.9063 },
    Meghalaya: { lat: 25.467, lng: 91.3662 },
    Mizoram: { lat: 23.1645, lng: 92.9376 },
    Nagaland: { lat: 26.1584, lng: 94.5624 },
    Odisha: { lat: 20.9517, lng: 85.0985 },
    Punjab: { lat: 31.1471, lng: 75.3412 },
    Rajasthan: { lat: 27.0238, lng: 74.2179 },
    Sikkim: { lat: 27.533, lng: 88.5122 },
    "Tamil Nadu": { lat: 11.1271, lng: 78.6569 },
    Telangana: { lat: 18.1124, lng: 79.0193 },
    Tripura: { lat: 23.9408, lng: 91.9882 },
    "Uttar Pradesh": { lat: 26.8467, lng: 80.9462 },
    Uttarakhand: { lat: 30.0668, lng: 79.0193 },
    "West Bengal": { lat: 22.9868, lng: 87.855 },
  };
  const handleStateChange = (selectedState) => {
    setSearchAddress("");
    setManualMarkerLocation(null);
    if (stateCoordinates[selectedState]) {
      const { lat, lng } = stateCoordinates[selectedState];
      console.log(lat);
      setMapCenter({ lat, lng });
      setSearchAddress("");
    }
  };

  useEffect(() => {
    const address = [
      data2?.firstline,
      data2?.secondline,
      data2?.pincodeobj?.state?.name,
      data2?.city,
      data2?.state,
      data2?.country,
    ]
      .filter(Boolean)
      .join(" ");

    setSearchAddress(address);
    handlesearchchange(address);
    // setSelectedState(address);
    // handleStateChange(address)
  }, [data2]);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPlaceChanged = () => {
    setManualMarkerLocation(null);
    if (window.google && window.google.maps) {
      const geocoder = new window.google.maps.Geocoder();
      console.log("object", searchAddress);
      geocoder.geocode({ address: searchAddress }, (results, status) => {
        if (status === "OK" && results[0]) {
          setLatitude(results[0].geometry.location.lat());
          setLongitude(results[0].geometry.location.lng());

          setMapCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });

          setSearchedLocation({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });

          const addressComponents = results[0].address_components;
          let stateName = "";
          for (let i = 0; i < addressComponents.length; i++) {
            const types = addressComponents[i].types;
            if (types.includes("administrative_area_level_1")) {
              stateName = addressComponents[i].long_name;
              break;
            }
          }

          console.log("Selected Place Coordinates:", {
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });
          setSelectedState(stateName);
        } else {
          // swal("Oops", status, "warning");
          console.log(status);
        }
      });
    } else {
      console.log("Google Maps API not available");
    }
  };

  const handleSearch = () => {
    onPlaceChanged();
  };

  const handlesearchchange = (e) => {
    setSearchAddress(e);
    setTimeout(() => {
      handleSearch();
    }, 3000);
  };
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);

  const validationSchema = Yup.object().shape({
    completeAddress: Yup.string().required("Complete Address is required"),
  });
  const fetchAreaInformation = async (lat, lng) => {
    const tokens = "AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0";
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${tokens}`
      );
      const result = response.data;
      console.log(result);
      if (result.status === "OK") {
        const addressComponents = result.results[0].address_components;

        // Concatenate all address components to form the full address
        let fullAddress = "";
        for (let component of addressComponents) {
          fullAddress += component.long_name + ", ";
        }

        // Trim any trailing comma and space
        fullAddress = fullAddress.replace(/,\s*$/, "");

        return fullAddress;
      } else {
        throw new Error(
          result.error_message || "Error fetching area information"
        );
      }
    } catch (error) {
      console.error("Error fetching area information:", error);
      return "";
    }
  };

  const handleMapClick = async (e) => {
    const clickedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setSearchedLocation(clickedLocation);

    setManualMarkerLocation(clickedLocation);
    // const areaInfo = await fetchAreaInformation(
    //   clickedLocation.lat,
    //   clickedLocation.lng
    // );

    // setManualMarkerLocation();

    // const areaInfoParts = areaInfo.split(", ");
    // const stateName = areaInfoParts[areaInfoParts.length - 3];

    // // Check if the state name exists in the data array
    // if (data.includes(stateName)) {
    //   setSelectedState(stateName);
    // } else {
    //   console.log("State not found in the data array.");
    // }

    // setSearchAddress(areaInfo);
  };

  const saveMapClick = () => {
    onDataTransfer(searchedLocation);
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      completeAddress: "",
      floor: "",
      landmark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log("values", values);
      const locationToSubmit = manualMarkerLocation || {
        lat: latitude,
        lng: longitude,
      };
      navigate("/app/pages/users/lab-test-cart", {
        state: {
          cordinates: locationToSubmit,
        },
      });
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let timeoutId;

    return () => {
      clearTimeout(timeoutId);
    };
  }, [formik.values.completeAddress]);
  const handleClose = () => {
    setopenpopup(false);
  };
  return (
    <Dialog open={openpopup} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <div>
        {/* {isMidScreen ? <></> : <Navbar pagelabel="Lab Test" />}
        {isMidScreen ? <MobileNavbar /> : <></>} */}
        <form className="specificlocationroot">
          <Grid container xs={12} className="specificlocationcontainer">
            <Grid
              item
              mt={2}
              xs={12}
              sm={4}
              mb={2}
              className="specificlocationitem"
            >
              <select
                className="specificlocationinput"
                id="selectedState"
                name="selectedState"
                value={selectedState}
                onChange={(e) => {
                  setSelectedState(e.target.value);
                  handleStateChange(e.target.value);
                }}
                style={{
                  background: "#FFFFFF",
                  border: "2px solid green",
                  borderRadius: "5px",
                  fontSize: "15px",
                }}
              >
                <option value="">Select any State</option>
                {data.map((item, index) => (
                  <option key={index} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Grid>
            <Grid
              item
              mt={2}
              mb={2}
              xs={12}
              sm={8}
              className="specificlocationitem2"
            >
              {/* <MDInput
                type="search"
                value={searchAddress}
                id="specificlocationinput"
                placeholder="Search for city, localities and more"
                // className="specificlocationinput"
                onChange={(e) => handlesearchchange(e.target.value)}
                style={{width:"90%"}}
                
              /> */}
              <div style={{ position: "relative", width: "95%" }}>
                <input
                  type="text"
                  value={searchAddress}
                  id="specificlocationinput"
                  placeholder="Search for city, localities and more"
                  style={{
                    width: "100%",
                    background: "#FFFFFF",
                    border: "2px solid green",
                  }}
                  onChange={(e) => handlesearchchange(e.target.value)}
                />
                <img
                  src={Search}
                  alt="Search Logo"
                  style={{
                    position: "absolute",
                    top: "50%",
                    right: "10px",
                    transform: "translateY(-50%)",
                    width: "20px", // Adjust the width as needed
                    height: "20px", // Adjust the height as needed
                  }}
                />
              </div>
              <button
                className="specificlocationbutton1"
                // onClick={handleSearch}
              >
                {/* <img
                  src={Search}
                  alt="Search Logo"
                  className="specificlocationimg1"
                /> */}
              </button>
            </Grid>
          </Grid>
          <Grid container xs={11} mb={5}>
            <LoadScript googleMapsApiKey="AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0">
              <GoogleMap
                center={manualMarkerLocation || mapCenter}
                zoom={14}
                mapContainerStyle={{ minHeight: "360px", width: "100%" }}
                options={{
                  fullscreenControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  zoomControl: false,
                }}
                onClick={(e) => {
                  handleMapClick(e);
                }}
              >
                {manualMarkerLocation && (
                  <Marker
                    position={manualMarkerLocation}
                    icon={{
                      url: customMarkerImageUrl,
                      scaledSize: new window.google.maps.Size(30, 30), // Adjust size as needed
                    }}
                  />
                )}
                {searchedLocation && <Marker position={searchedLocation} />}
              </GoogleMap>
            </LoadScript>
            <Grid container xs={isSmallScreen ? 12 : 10} mt={3}>
              {searchedLocation && (
                <MDButton
                  size="small"
                  color="info"
                  mt={2}
                  onClick={() => {
                    saveMapClick();
                  }}
                >
                  SaVE LOCATION
                </MDButton>
              )}
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
};

export default MapPopup;
