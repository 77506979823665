
import checkout from "../schemas/form";
const {
  formField: {sno, heading,type,description,schedules,condition,compensation,cgst,sgst,igst },
} = checkout;

const initialValues = {
  [sno.name]: "",
[heading.name]: "",
[type.name]: "",
[description.name]: "",
[schedules.name]: "",
[condition.name]: "",
[compensation.name]: "",
[cgst.name]: "",
[sgst.name]: "",
[igst.name]: "",
};

export default initialValues;
