import { Grid, Icon } from "@mui/material";
import React, { useEffect, useState } from "react";
import Profile from "../../../assets/svg/Profile.svg";
import "./navProfileInfo.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ROLE_CODE } from "privateComponents/codes";

const NavProfileInfo = ({ label }) => {
  const navigate = useNavigate();
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [dropdownItems, setDropdownItems] = useState([]);

  const patientItems = [
    { component: "My Profile", icon: "person" },
    { component: "Refer List", icon: "person_add" },
    { component: "My appointments", icon: "check_circle" },
    { component: "My Lab Tests", icon: "check_circle" },
    { component: "My Prescription", icon: "medical_services" },
    { component: "Manage EMR", icon: "medical_services" },
    { component: "Family List", icon: "person_add" },

    { component: "Log Out", icon: "logout" },
  ];

  const nurseItems = [
    { component: "My Profile", icon: "person" },
    { component: "Patient Form", icon: "person_add" },
    { component: "Patient List", icon: "check_circle" },
    { component: "Book Patient", icon: "person_add" },
    { component: "Patient Prescription", icon: "medical_services" },
    { component: "My Lab Test", icon: "check_circle" },
    { component: "Log Out", icon: "logout" },
  ];

  useEffect(() => {
    if (auth?.rolecode === ROLE_CODE.patientcode) {
      setDropdownItems(patientItems);
    } else if (auth?.rolecode === ROLE_CODE.nursecode) {
      setDropdownItems(nurseItems);
    }
  }, [auth]);

  const handleNavigation = (route) => {
    if (route === "Log Out") {
      reduxDispatch({ type: "LOGOUT_FULFILLED" });
    } else if (route === "My Profile") {
      if (auth?.rolecode === ROLE_CODE.nursecode) {
        navigate("/app/nurse/profile");
      } else {
        navigate("/app/patient/profile");
      }
    } else if (route === "My appointments") {
      navigate("/app/patient/my-booking");
    } else if (route === "Refer List") {
      navigate("/app/patient/refer-list");
    } else if (route === "My Lab Tests") {
      navigate("/app/patient/my-labtests");
    } else if (route === "My Prescription") {
      navigate("/app/patient/my-prescriptions");
    } else if (route === "Manage EMR") {
      navigate("/app/patient/manage-emr");
    } else if (route === "Family List") {
      navigate("/app/patient/family-list");
    } else if (route === "Patient Form") {
      navigate("/app/nurse/patient-form");
    } else if (route === "Patient List") {
      navigate("/app/nurse/patient-list");
    } else if (route === "Book Patient") {
      navigate("/app/nurse/book-patient");
    } else if (route === "Patient Prescription") {
      navigate("/app/nurse/patient-prescription");
    } else if (route === "My Lab Test") {
      navigate("/app/nurse/my-labtests");
    }
  };

  return (
    <div>
      <Grid container ml={1} xs={12}>
        <Grid
          item
          xs={12}
          ml={-1}
          className="profilenavbaritem"
          onClick={() => {
            auth?.rolecode === ROLE_CODE.patientcode
              ? navigate("/app/patient/profile")
              : navigate("/app/nurse/profile");
          }}
        >
          <Grid>
            <img src={Profile} alt="Logo" className="profilenavbarimg" />
          </Grid>
          <Grid className="profilenavbaritem3">
            <Grid className="authText">
              {auth?.name
                ? auth.name
                    .split(" ")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")
                : auth?.emailid || <></>}
            </Grid>
            <Grid item className="profilenavbaritem2">
              <div>{auth.mobileno ? "+91" + auth?.mobileno : <></>}</div>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          mt={3}
          sx={{ display: "grid", justifyContent: "start" }}
        >
          {/* Conditional rendering based on auth.logincount */}
          {auth.logincount > 1 ? (
            dropdownItems?.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                className="categorynavitem2"
                mb={3}
              >
                <button
                  className={
                    label == "sidebar"
                      ? "categorynavitem2text"
                      : "categorynavitem2texttype2"
                  }
                  onClick={() => handleNavigation(item?.component)}
                >
                  <Icon fontSize="medium" style={{ marginRight: "10px" }}>
                    {item?.icon}
                  </Icon>
                  {item?.component}
                </button>
              </Grid>
            ))
          ) : (
            // Render only logout button if logincount is not greater than 1
            <Grid item xs={12} className="categorynavitem2" mb={3}>
              <button
                className="categorynavitem2text"
                onClick={() => handleNavigation("Log Out")}
              >
                Log Out
              </button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default NavProfileInfo;
