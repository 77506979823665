
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create User

export const createProjectclinic = createAsyncThunk("createProjectclinic", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `project-service/api/ProjectMMU/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getProjectclinic = createAsyncThunk("getProjectclinic", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `project-service/api/ProjectMMU/v1/get`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const updateProjectclinic = createAsyncThunk("updateProjectclinic", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `project-service/api/ProjectMMU/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteProjectclinic = createAsyncThunk("deleteProjectclinic", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `project-service/api/ProjectMMU/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




