import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    // lastName,
    departmentvalue,
    designationvalue,
    email,
    password,
    age,
    repeatPassword,
    experience,
    gender,
    description,
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [experience.name]: Yup.string().required(experience.errorMsg),
   [gender.name]: Yup.string().required(gender.errorMsg),
    // [description.name]: Yup.string().required(description.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phonenumber.name]: Yup.string()
    .required(phonenumber.errorMsg)
    .min(10, phonenumber.invalidMsg)
    .max(10, phonenumber.invalidMsg),
    age: Yup.number()
    .min(23, 'Age must be at least 23 years')
    .required(age.errorMsg),

    // [age.name]: Yup.string().required(age.errorMsg),

    // [password.name]: Yup.string()
    //   .required(password.errorMsg)
    //   .min(6, password.invalidMsg),
    password: Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      {
        message:
          "Password must be at least 8 characters long and contain at least one letter, one number, and one special character",
        excludeEmptyString: true,
      }
    )
    .required("Password is required"),
    repeatPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
    // [repeatPassword.name]: Yup.string()
    //   .required(repeatPassword.errorMsg)
    //   .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
      [designationvalue.name]: Yup.string().required(designationvalue.errorMsg),
   [departmentvalue.name]: Yup.string().required(departmentvalue.errorMsg),
  }),
  
  //Yup.object().shape({
  
    // [phonenumber.name]: Yup.string()
    //   .required(phonenumber.errorMsg)
    //   .min(10, phonenumber.invalidMsg)
    //   .max(10, phonenumber.invalidMsg),
   // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
 
  // }),
  Yup.object().shape({
    [twitter.name]: Yup.string().required(twitter.errorMsg),
  }),
];

export default validations;
