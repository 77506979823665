import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const bookingListAction = 
   createAsyncThunk("getBookingList", async(data, { rejectWithValue }) => {
    console.log("data", data);
    let url;
    let recstatus = data?.recstatus ? data?.recstatus : 'SCHEDULED';

    try {
      if (data && data.visitId && data.mmucode) {
        if (data && data.name && data.date) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&visitid=${data?.visitId}&recstatus=${recstatus}&slot.startdate=${data?.date}&st=${data?.name}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else if (data && data?.name) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&visitid=${data?.visitId}&recstatus=${recstatus}&st=${data?.name}&ispageable=true&page=${data.page}&size=${data.size}`;
        } else if (data && data?.date) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&visitid=${data?.visitId}&recstatus=${recstatus}&slot.startdate=${data?.date}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&visitid=${data?.visitId}&recstatus=${recstatus}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        }
      }
      else if (data && data.mmucode){
        if (data && data.name && data.date) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&recstatus=${recstatus}&slot.startdate=${data?.date}&st=${data?.name}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else if (data && data?.name) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&recstatus=${recstatus}&st=${data?.name}&ispageable=true&page=${data.page}&size=${data.size}`;
        } else if (data && data?.date) {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&recstatus=${recstatus}&slot.startdate=${data?.date}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else {
          url = `${APIs.bookingServiceApi}/get?mmu.code=${data?.mmucode}&recstatus=${recstatus}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        }
      }
      else{

        if (data && data.name && data.date) {
          url = `${APIs.bookingServiceApi}/get?doctor.profileid=${data?.profileId}&recstatus=${recstatus}&slot.startdate=${data?.date}&st=${data?.name}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else if (data && data?.name) {
          url = `${APIs.bookingServiceApi}/get?doctor.profileid=${data?.profileId}&recstatus=${recstatus}&st=${data?.name}&ispageable=true&page=${data.page}&size=${data.size}`;
        } else if (data && data?.date) {
          url = `${APIs.bookingServiceApi}/get?doctor.profileid=${data?.profileId}&recstatus=${recstatus}&slot.startdate=${data?.date}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        } else {
          url = `${APIs.bookingServiceApi}/get?doctor.profileid=${data?.profileId}&recstatus=${recstatus}&ispageable=true&page=${data?.page}&size=${data?.size}`;
        }
      }


   

      let respData = await getAllUsers(url, "");

      return respData;

    } catch (error) {
      return rejectWithValue(error);
    }
  });
