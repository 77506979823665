import React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableCmpProps } from './TableCmpModel';
import nodatafound from '../../assets/images/products/No-data-found.png';
import { FormControl, Grid, InputLabel, MenuItem, Pagination, Select, Stack } from "@mui/material";

const TableCmp = ({ columns, rows, setCurrentPage, pageCount, setPerPage, perPage , currentPage ,maxWidth = '200px'}: TableCmpProps) => { 

  const handlePagination =(event:any, newPage:number)=>{
    setCurrentPage(newPage)
  }
      
  return (
    <>
        <Paper sx={{ width: '100%', overflow: 'hidden'}}>
    
        <TableContainer sx={{width: "100%", height:"57vh"}} >
            <Table aria-label="sticky table">
            <TableHead sx={{display:"contents"}}>
                <TableRow>
                {columns?.map((column) => (
                    <TableCell key={column.id} align="center" style={{ minWidth: 145 }}>
                      {column.label}
                    </TableCell>
                ))}
                </TableRow>
            </TableHead>
            {rows?.length === 0 ? (
             
             <TableBody>
             <TableRow>
               <TableCell colSpan={columns?.length} align="center">
                 <Grid container justifyContent="center" alignItems="center" style={{ height: '57vh' }}>
                   <img
                     src={nodatafound}
                     alt="No data found"
                     style={{
                      maxWidth: typeof maxWidth === 'string' ? maxWidth : `${maxWidth}px`,
                       height: 'auto',
                     }}
                   />
                 </Grid>
               </TableCell>
             </TableRow>
           </TableBody>
              ) : (
            <TableBody>
                {rows?.map((row) => (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code}
                    >
                        {columns?.map((column) => {
                        return (
                            <TableCell key={column.id} align="center">
                                {row[column.id]}
                            </TableCell>
                        );
                        })}
                    </TableRow>
                    )
                )}
            </TableBody>
                )}
            </Table>
        </TableContainer>
          
        <Stack direction="row" justifyContent="flex-end" alignItems="center" sx={{ padding: '1%', gap:2 }}>
        <FormControl variant="outlined"  sx={{width:"9%"}}>
              <InputLabel id={"per-page-label"}>Rows per page</InputLabel>
              <Select
                labelId="per=page-label"
                style={{height: "35px"}}
                id="perpage-select"
                name="per-page"
                onChange={(e)=> setPerPage(Number(e.target.value))}
                value={perPage}
                label="Rows per page"
                >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                </Select>
            </FormControl>
          <Pagination count={pageCount && pageCount} page={currentPage} onChange={handlePagination} color="primary" />
        </Stack>
        </Paper>
    </>
  )
}

export default TableCmp;