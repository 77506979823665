import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const VitalsEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [value, setValue] = useState("");
  const [uomValue, setUomValue] = useState("");
  const [vitalList, setVitalList] = useState([]);
  const [uomList, setUomList] = useState([]);

  const validationSchema = Yup.object({
    vitalsName: Yup.string().required("Vitals name is required."),
    vitalrange: Yup.string().required("Vital range is required."),
    vitalsType: Yup.object({
      name: Yup.string().required("Vitals type is required."),
    })
      .nullable()
      .required("Vitals type is required."),
    uom: Yup.object({
      name: Yup.string().required("UOM is required."),
    })
      .nullable()
      .required("UOM is required."),
  });

  const initialValues = {
    vitalsName: selectedUser?.name || "",
    vitalsType: selectedUser?.type ? { name: selectedUser.type } : "",
    vitalrange: selectedUser?.range || "",
    uom: selectedUser?.uom ? { name: selectedUser.uom } : "",
  };

  const defaultProps = {
    options:
      vitalList.length === 0 ? [{ name: "Loading...", id: 0 }] : vitalList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsUom = {
    options: uomList.length === 0 ? [{ name: "Loading...", id: 0 }] : uomList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const onSubmit = async (values) => {
    setLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,
        name: values.vitalsName,
        uom: values.uom?.name,
        type: values.vitalsType?.name,
        range: values.vitalrange,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.vitalsMasterServiceApi}/update`, updatedUser);
      handleClose();
      handleEditreset();
      swal("Success!", "Vitals updated successfully.", "success");
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoading1(false);
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const vitalResponse = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=VITAL`
      );
      setVitalList(vitalResponse.data.data.dtoList);

      const uomResponse = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=VITAL_UOM`
      );
      setUomList(uomResponse.data.data.dtoList);

      // Set initial values for Autocomplete based on selectedUser
      if (selectedUser) {
        setValue(
          vitalResponse.data.data.dtoList.find(
            (item) => item.name === selectedUser.type
          ) || ""
        );
        setUomValue(
          uomResponse.data.data.dtoList.find(
            (item) => item.name === selectedUser.uom
          ) || ""
        );
      }
      setLoading(false);
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Vitals Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="vitalsName"
                            value={values.vitalsName}
                            onChange={handleChange}
                            error={errors.vitalsName && touched.vitalsName}
                            success={
                              values.vitalsName != null && !errors.vitalsName
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="vitalsName"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultProps}
                            value={value}
                            onChange={(event, newValue) => {
                              setValue(newValue);
                              setFieldValue("vitalsType", newValue);
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={
                                    errors.vitalsType && touched.vitalsType
                                  }
                                  success={value !== null && !errors.vitalsType}
                                  label=<span>
                                    Vitals Type
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="vitalsType"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Vital Range
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="vitalrange"
                            value={values.vitalrange}
                            onChange={handleChange}
                            error={errors.vitalrange && touched.vitalrange}
                            success={
                              values.vitalrange != null && !errors.vitalrange
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="vitalrange"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsUom}
                            value={uomValue}
                            onChange={(event, newValue) => {
                              setUomValue(newValue);
                              setFieldValue("uom", newValue);
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  label=<span>
                                    UOM
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                  error={errors.uom && touched.uom}
                                  success={uomValue !== null && !errors.uom}
                                />
                                <ErrorMessage
                                  name="uom"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loading1 ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default VitalsEdit;
