

import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

import MDAvatar from "components/MDAvatar";

import MDBadge from "components/MDBadge";

import { useState } from "react";

import axios from "../../../../axiosinstance";

import { MoonLoader } from "react-spinners";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';

// Images

import productImage from "assets/images/products/product-11.jpg";

function OrderInfo(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [rolecode, setRolecode] = useState(
    JSON.parse(localStorage.getItem("login-auth")).rolecode
  );
  

  function isJoinButtonEnabled(props) {
    let startDate = new Date(props?.date?.startdate);

    startDate.setHours(startDate.getHours() - 5, startDate.getMinutes() - 30);

    let endDate = new Date(props?.date?.enddate);

    endDate.setHours(endDate.getHours() - 5, endDate.getMinutes());

    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  }

  function fetchRoomData(ordernumber) {
    setIsLoading(true);

    axios

      .get(
        `/room-id-generation-service/api/RoomIdGeneration/v1/getSingle?bookingid=${ordernumber}`
      )

      .then((response) => {
        const url = rolecode === "DO001" ? response?.data?.data?.dto?.link + '?rolecode=DO001' : response?.data?.data?.dto?.link;

        window.open(url, "_blank");

        console.log("meeting link", response?.data?.data?.dto?.link);

        setIsLoading(false);
      })

      .catch((error) => {
        console.error("Error fetching data from the API: ", error);

        setIsLoading(false);

        swal({
          text: "Error in fetching api response.",
          icon: "error",
          title: "Error",
        });
      });
  }

  

  const joinMeeting = (props) => {
    fetchRoomData(props?.ordernumber);
  };

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} >
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDAvatar size="xxl" src={productImage} alt="Gold Glasses" />
          </MDBox>

          <MDBox lineHeight={1}>
            <MDTypography variant="button" color="text">
              <b>Name: {props?.doctorData?.name === localStorage.getItem("profile-Name").replace(/"/g, "") ? "Self" : props?.doctorData?.name}</b>
            </MDTypography>

            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="text">
                <b>Experience: {props?.doctorData?.experience}</b>
              </MDTypography>
            </MDBox>

            <MDBox lineHeight={1}>
              <MDTypography variant="button" color="text">
                <b>Age: {props?.doctorData?.age}</b>
              </MDTypography>
            </MDBox>
            </MDBox>
            </MDBox>
        
        </Grid>
<Grid container ml={18} style={{display:"flex",justifyContent:"start",alignItems:"center"}}>
            <MDButton variant="gradient" color="warning" size="small">
              {props?.price}
            </MDButton>
        

         {props?.paymentmethod==="Pay on Counter" ? <MDBox>
          <></>
          </MDBox> :

          <MDBox ml={4} >
            <span
              style={{
                cursor:
                  !isJoinButtonEnabled(props) || isLoading
                    ? "not-allowed"
                    : "auto",
              }}
            >
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={() => joinMeeting(props)}
                size="small"
                disabled={!isJoinButtonEnabled(props) || isLoading}
              >
                {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Join"}
              </MDButton>{" "}
            </span>
          </MDBox>
          }
          </Grid>
       
     

      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}></Grid>
    </Grid>
  );
}

export default OrderInfo;
