import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Link, useLocation, useParams } from 'react-router-dom';

// OrderDetails page components
import Header from "components/Resuable/detailcomponet/components/Header";
import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "../../../app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import Appointment from "./components";


function FinalAppointment() {
  const location =useLocation();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 580);
const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);



useEffect(() => {
  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 580);
    setIsMidScreen(window.innerWidth <= 900);
  };
  window.addEventListener("resize", handleResize);
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);

console.log(location.state, "location.state 42 appointment")
 
  return (
  
    <div style={{ background: "#f3f5ea", overflowX: "hidden",marginTop:"140px" }}>
    {/* {isMidScreen ? <></> : <Navbar pagelabel={location?.state?.pagelabel}/>}
    {isMidScreen ? <MobileNavbar /> : <></>} */}
      <MDBox my={6}>
        <Grid container spacing={3} mb={isMidScreen ? 10 : undefined} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              {/* <MDBox pt={2} px={2}>
                <Header/>
              </MDBox> */}
              <Divider />
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                  <Appointment date={location.state} time={location.time} profileid={location.profileid} pagelabel={location?.state?.pagelabel} visit={location?.state?.visit} />
                </MDBox>
                <Divider />
               
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel={location?.state?.pagelabel}  /> : <></>}
    </div>
 
  );
}




export default FinalAppointment;