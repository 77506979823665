import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import swal from "sweetalert";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { tableDataAction } from "redux/Actions/tableDataAction";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import Button from "@mui/material/Button";
import {
  Autocomplete,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@mui/material";
import noData from "assets/images/products/No-data-found.png";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import MDBadge from "components/MDBadge";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { useDispatch } from "react-redux";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { capitalizeWords } from "static/methods/capitalizedWords";
import Skeleton from "components/Skeleton/Skeleton";
import AssignPopup from "components/Resuable/searchpatientcard/AssignPopup";
import VitalsPopup from "components/Resuable/searchpatientcard/VitalsPopup";

import { bookingListAction } from "redux/Actions/bookingListAction";
import { useNavigate } from "react-router-dom";
import PaginationFliter from "components/PaginationFliter/PaginationFliter";

import initialValues from "components/Resuable/Association/schemas/initialValues";
import LabQueueCard from "./LabQueueCard";
import DynamicformPopup from "../labReport/DynamicformPopup";



const LabQueue = ({ labProfileId }) => {
  const comp = "SM";
  const [Loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [color] = useState("#344767");
  const { getMMUDetails, getProjectDetails } = useSelector(state => state)
  const [filterData, setFilterData] = useState({ date: new Date().toISOString().split("T")[0] });
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(6);
  const [selecteddata, setSelectedData] = useState(6);
  const pageOption=[6,9,15]
  const dispatch = useDispatch();
  const {getBookingList } = useSelector((state) => state);
  const [opendynamicform, setOpenDynamicform] = useState(false);
  const filters = [
    {
      type: "date",
      // placeholder: "Enter name",
      variant: "outlined",
      // label: "Date",
      name: "date",
      page: "allList",
    },
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },

  ];
  const handlePopupClose = () => {
    setOpenDynamicform(false);
  };

 
 
  useEffect(() => {
    handleApi();
    dispatch(userCategoryAction({ profileid: auth.profileid }));
  }, []);

 
  const handleDoctor = (value) => {
    setSelectedData(value)
    setOpenDynamicform(true)
    // updateBooking(value)
  };

  const handleSearch = () => {
    setCurrentPage(1);
    handleApi();
  };

  const navigate = useNavigate();

  const handleClear = () => {
    setCurrentPage(1)
    setFilterData({});
    dispatch(bookingListAction({ mmucode: getProjectDetails?.details?.mmucreation?.code,page:currentPage-1,size:pageSize,recstatus:"SAMPLE COLLECTED" }));
    navigate(`/app/lab/lab-queue`);
  };

  const handleApi = () => {
    if (filterData?.date && filterData?.name) {
      dispatch(bookingListAction({ name: filterData?.name, date: filterData?.date, mmucode: getProjectDetails?.details?.mmucreation?.code,page:currentPage-1,size:pageSize,recstatus:"SAMPLE COLLECTED" }));
    }
    else if (filterData?.date) {
      dispatch(bookingListAction({ date: filterData?.date, mmucode: getProjectDetails?.details?.mmucreation?.code,page:currentPage-1,size:pageSize,recstatus:"SAMPLE COLLECTED" }));
    }
    else if (filterData?.name) {
      dispatch(bookingListAction({ name: filterData?.name, mmucode: getProjectDetails?.details?.mmucreation?.code,page:currentPage-1,size:pageSize,recstatus:"SAMPLE COLLECTED" }));
    }
    else {
      dispatch(bookingListAction({ mmucode: getProjectDetails?.details?.mmucreation?.code,page:currentPage-1,size:pageSize,recstatus:"SAMPLE COLLECTED" }));
    }
  }

  useEffect(()=>{
  handleApi()
  },[pageSize,currentPage])

  return (
    <>
     
      <MDBox pt={1.5}>
        <MDBox >
          <Card style={{display:"flex",justifyContent:"center"}}>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                   Lab Queue
                  </MDTypography>
                </Grid>
              
                <FilterCmp

                  filterArray={filters}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  handleSearch={handleSearch}
                  handleClear={handleClear}
                />
              </Grid>
            </MDBox>

            <div>
            
                {getBookingList?.loading ? (
                 <MDBox mt={2} sx={{ p: 2 }} className="patient-queue-grid">
                    {[...Array(9)].map((_, index) => (
                      <Card key={index}>
                        <Skeleton width="564px" height="300px" />
                      </Card>
                    ))}
                  </MDBox>
                ) : (
                  <>
                   
                      {getBookingList && getBookingList?.list?.length > 0 ? (
                                      <MDBox mt={2} sx={{ p: 2 }} className="patient-queue-grid">
                                        {getBookingList?.list?.map((obj) => (
                                          <Grid item key={obj.id}>
                                            <LabQueueCard
                                              data={obj}
                                              getpatient={handleApi}
                                              labProfileId={labProfileId}
                                              handleDoctor={handleDoctor}
                                           
                                            />
                                          </Grid>
                                        ))}
                                      </MDBox>
                    ) : (
                      <>
                     
                      <Grid
                        container
                        xs={11.5}
                        justifyContent="center"
                        mt={5}
                     
                      >

                        <MDBox
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          p={3}
                        >
                          <img
                            src={noData}
                            alt="Brooklyn Alice"
                            style={{
                              width: "100%",
                              maxWidth: "200px",
                              height: "auto",
                              // maxHeight:'200px'
                            }}
                          />
                        </MDBox>

                      </Grid>
                      </>
                    )}
                  </>
                )}
            
            </div>
            <PaginationFliter 
             
             setCurrentPage={setCurrentPage}
             pageCount={getBookingList?.totalPages}
             setPerPage={setPageSize}
             perPage={pageSize}
             currentPage={currentPage}
             option={pageOption}
            />
          </Card>
        </MDBox>
      </MDBox> 
      {opendynamicform && (
        <DynamicformPopup
          opendynamicform={opendynamicform}
          setOpenDynamicform={setOpenDynamicform} 
          onClose={handlePopupClose}
          selecteddata={selecteddata}

          handleApi={handleApi}
        />
      )}
    
    </>
  );
}; 


export default LabQueue;
