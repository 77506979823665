import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const micApprovalListAction = 
   createAsyncThunk("micApprovalListAction", async(data, {rejectWithValue}) => {
    let url;
  
    try {
        if(data && data.payload){
            let respData = await axios.post(`${APIs.loginServiceApi}/getByPage`, data?.payload)
            return respData?.data?.data?.page || {};
        }

    } catch (error) {
      return rejectWithValue(error);
    }
  });