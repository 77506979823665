import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import { BarLoader } from "react-spinners";
import { useFormikContext } from "formik";

import FormField from "./FormField";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";

import { useSelector } from "react-redux";

function FamilyInfo({ formData }) {
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context

  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFieldValue(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    // Validation logic...
    // For example, let's assume you want to ensure that the "brand" field is not empty onBlur.
    // if (!value) {
    //   setFieldError(name, "Brand is required");
    // } else {
    //   setFieldError(name, ""); // Clear the error if it's valid
    // }
  };

  const { firstName, lastName, age, email, mobilenumber, relation } = formField;

  const {
    firstName: firstNameV,
    lastName: lastNameV,
    gender: genderV,

    age: ageV,
    email: emailV,
    mobilenumber: mobilenumberV,
    relation: relationV,
  } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Add Family Member</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox>

      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstName.type}
              label=<span>
                {firstName.label}{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              success={lastNameV.length > 0 && !errors.lastName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={age.type}
              label=<span>
                {age.label}{" "}
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  {" "}
                  *
                </sup>
              </span>
              name={age.name}
              value={ageV}
              placeholder={age.placeholder}
              error={errors.age && touched.age}
              success={ageV.length > 0 && !errors.age}
              inputProps={{ min: 0 }}
              InputLabelProps={{ style: { paddingBottom: "2px" } }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.gender && touched.gender}>
              <InputLabel
                htmlFor="gender"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                <span>
                  Select Gender{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="gender"
                id="gender"
                value={genderV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
              {errors.gender && touched.gender && (
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors.gender}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              // type={mobilenumber.type}
              label={mobilenumber.label}
              name={mobilenumber.name}
              value={mobilenumberV}
              placeholder={mobilenumber.placeholder}
              onKeyDown={(e) => {
                if (
                  !/[0-9\b\t]/.test(e.key) &&
                  ![
                    "ArrowLeft",
                    "ArrowRight",
                    "ArrowUp",
                    "ArrowDown",
                    "Delete",
                    "Backspace",
                    "Tab",
                  ].includes(e.key)
                ) {
                  e.preventDefault();
                }
              }}
              error={errors.mobilenumber && touched.mobilenumber}
              success={mobilenumberV.length > 0 && !errors.mobilenumber}
            />
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={!!errors.relation && touched.relation}
            >
              <InputLabel
                htmlFor="gender"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                <span>
                  Select Relation{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="relation"
                id="realtion"
                value={relationV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Mother">Mother</option>
                <option value="Father">Father</option>
                <option value="Son">Son</option>
                <option value="Brother">Brother</option>
                <option value="Daughter">Daughter</option>
                <option value="Sister">Sister</option>
                <option value="Uncle">Uncle</option>
                <option value="Aunty">Aunty</option>
                <option value="GrandFather">GrandFather</option>
                <option value="GrandMother">GrandMother</option>
              </Select>
              {errors.relation && touched.relation && (
                <FormHelperText error sx={{ marginLeft: "0px" }}>
                  {errors.relation}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

FamilyInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FamilyInfo;
