import React from "react";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import BookingView from "./Bookingview/BookingView";
import { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import MDButton from "components/MDButton";

import PreviewIcon from "@mui/icons-material/Preview";

import swal from "sweetalert";

import { MoonLoader } from "react-spinners";

import DoDisturbIcon from "@mui/icons-material/DoDisturb";

import Rolecode from "../../../Rolecode";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { Grid, Stack } from "@mui/material";
import PrescriptionForm from "components/prescriptionForm";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { FaRegTimesCircle } from "react-icons/fa";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";

function Bookinglist() {
  const navigate = useNavigate();
  const comp = "SM";
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const code = Rolecode();

  const [loading, setLoading] = useState(true);

  const [color] = useState("#344767");

  const [tableDatas, setTableDatas] = useState({ rows: [], columns: [] });

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );

  const [rolecode, setRolecode] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.rolecode
  );

  const iconStyle = { color: "#344767", marginRight: "9px", cursor: "pointer" };

  const [loadingId, setLoadingId] = useState(0);

  const [openpopup, setopenpopup] = useState(false);
  const [selecteddata, setSelectedData] = useState({});
  const addButtonStyle = {
    display: "flex",

    fontSize: "2em",

    flexDirection: "column",

    marginRight: "10px",
  };

  function isJoinButtonEnabled(item) {
    let startDate = new Date(item?.slot?.startdate);

    startDate.setHours(startDate.getHours() - 5, startDate.getMinutes() - 30);

    let endDate = new Date(item?.slot?.enddate);

    endDate.setHours(endDate.getHours() - 5, endDate.getMinutes());

    const currentDate = new Date();

    return currentDate >= startDate && currentDate <= endDate;
  }

  function viewPopup(item) {
    setopenpopup(true);
    setSelectedData(item);
  }

  function getData(profileId) {
    setLoading(true);

    const dataObj = {
      columns: [
        { Header: "action", accessor: "action" },

        { Header: "order id", accessor: "ordernumber" },

        { Header: "status", accessor: "recstatus" },
      ],

      rows: [],
    };

    var api = "";

    if (code.Doctorcode) {
      dataObj.columns.push({
        Header: "patient name",

        accessor: "patient.name",
      });

      api = `booking-service/api/Booking/v1/get?doctor.profileid=${profileId}&type=booking`;
    } else {
      dataObj.columns.push({ Header: "doctor name", accessor: "doctor.name" });
      dataObj.columns.push({
        Header: "patient name",
        accessor: "patient.name",
      });

      api = `booking-service/api/Booking/v1/get?createdby=${profileId}&type=booking`;
    }

    dataObj.columns.push({
      Header: "Assigned date & time/ slot",
      accessor: "DateAndTime",
    });

    dataObj.columns.push({ Header: "Join", accessor: "button" });

    axios

      .get(api)

      .then((response) => {
        response?.data?.data?.dtoList?.sort(
          (a, b) => new Date(b.slot.startdate) - new Date(a.slot.startdate)
        );
        response?.data?.data?.dtoList?.map((item) => {
          if (
            item.patient.name ===
            localStorage.getItem("profile-Name").replace(/"/g, "")
          ) {
            item.patient.name = "Self";
          }
          item.action = (
            <span>
              <PreviewIcon
                fontSize="medium"
                style={iconStyle}
                onClick={() => viewPopup(item)}
              />
            </span>
          );
          if (item.paymentmethod === "Pay on Counter") {
            // item.button = <DoDisturbIcon fontSize="small" color="blue" />;

            item.button = (
              <MDButton
                size="small"
                sx={{ border: "1px solid black", cursor: "not-allowed" }}
              >
                <DoDisturbIcon
                  fontSize="large"
                  color="blue"
                  sx={{ width: 1, height: 18 }}
                />
              </MDButton>
            );
          } else {
            item.button = (
              <span
                style={{
                  cursor:
                    !isJoinButtonEnabled(item) || loadingId === item.ordernumber
                      ? "not-allowed"
                      : "auto",
                }}
              >
                <MDButton
                  id={item.ordernumber}
                  variant="gradient"
                  color="dark"
                  fullWidth
                  type="submit"
                  size="small"
                  onClick={() => joinMeeting(item)}
                  disabled={
                    !isJoinButtonEnabled(item) || loadingId === item.ordernumber
                  }
                >
                  {loadingId === item.ordernumber ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Join"
                  )}
                </MDButton>
              </span>
            );
          }

          item.date = new Date(item?.slot?.startdate)

            .toLocaleDateString("en-GB", {
              day: "2-digit",

              month: "2-digit",

              year: "numeric",
            })

            .replace(/\//g, "-");

          item.timing = (() => {
            const startDate = item?.slot?.startdate;

            const endDate = item?.slot?.enddate;

            if (startDate && endDate) {
              const startHour = startDate

                .split("T")[1]
                .split(".")[0]
                .split(":");

              const endHour = endDate.split("T")[1].split(".")[0].split(":");

              const getFormattedTime = (hour, minute) => {
                const meridian = hour >= 12 ? "PM" : "AM";

                const formattedHour = hour % 12 === 0 ? 12 : hour % 12;

                return `${formattedHour}:${minute} ${meridian}`;
              };

              const startTime = getFormattedTime(
                parseInt(startHour[0]),

                startHour[1]
              );

              const endTime = getFormattedTime(
                parseInt(endHour[0]),

                endHour[1]
              );

              return `  ${startTime} - ${endTime}`;
            }

            return "";
          })();
          item.DateAndTime = `${item.date}  ${item.timing}`;
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });

        // console.log("res70", dataObj);

        setTableDatas(dataObj);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
      });

    const joinMeeting = (item) => {
      console.log(item, "patient details 288")
      localStorage.setItem("patient-roomId", item?.ordernumber)
      navigate(`/app/patient/video-consultation`, {
        state: {
          roomId: item?.ordernumber,
          userdetails: item
        },
      });
      
      // navigate(
      //   `/app/patient/video-consultation`, {
      //   state: {
      //     roomId: item?.ordernumber,
      //     userdetails: item
      //   },
      // });

      // setAggregiatepatient(item);
      // setVisitid(item?.visit?.visitid);
      // fetchRoomData(item.ordernumber);
    };
  }

  const [showIframe, setShowIframe] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  async function fetchRoomData(ordernumber) {
    // setIsLoading(true);
    setLoadingId(ordernumber);
    await axios
      .get(
        `/room-id-generation-service/api/RoomIdGeneration/v1/getSingle?bookingid=${ordernumber}`
      )
      .then((response) => {
        const url =
          rolecode === "DO001"
            ? response?.data?.data?.dto?.link + "?rolecode=DO001"
            : response?.data?.data?.dto?.link;
        setVideoUrl(url);
        setLoadingId(0);
        window.open(url, "_blank");
        // if(ROLE_CODE.doctorcode === auth.rolecode){
        //   setOpen(true)
        // }else{
        //   window.open(url, "_blank");
        // }

        // setShowIframe(true)
      })
      .catch((error) => {
        swal({
          text: "Error in fetching api response.",
          icon: "error",
          title: "Error",
        });
        setLoadingId(0);
      });
  }
  useEffect(() => {
    getData(profileId);
  }, [loadingId]);

  const handleCloseIframe = () => {
    setShowIframe(false);
  };

//   // ------------------------------------ Prescription and video call ----------------------------------------------------
//   const [open, setOpen] = React.useState(false);
//   const [aggregiatepatient, setAggregiatepatient] = useState("");
//   const [selecteddisease, setSelectedDisease] = useState([]);
//   const [chiefcomplaint, setChiefcomplaint] = useState("");
//   const [remark, setRemark] = useState("");
//   const [data, setData] = useState([]);
//   const [frequencydata, setFrequencydata] = useState(null);
//   const [selectedTestlist, setSelectedTestlist] = useState([]);
//   const [showAppointments, setShowAppointments] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [idCounter, setIdCounter] = useState(0);
//   const [date, setDate] = useState();
//   const [finalLabtest, setFinalLabtest] = useState([]);
//   const [startDate, setStartDate] = useState();
//   const [slot, setSlot] = useState();
//   const [appointmentData, setAppointmentData] = useState();
//   const [visitid, setVisitid] = useState();

//   const handleClose = () => {
//     setData([]);
//     setOpen(false);
//   };

//   function getPrescriptionData(item) {
//     setAggregiatepatient(item);
//     setVisitid(item?.visit?.visitid);
//   }

//   const handleSubmit = (values, { resetForm }) => {
//     const newData = {
//       uid: idCounter,
//       countperday: frequencydata?.countperday,
//       createdby: auth.profileid,
//       //   documentofproof: "",
//       dosage: values?.medicine,
//       dose: values?.dose,
//       duration: values?.duration,
//       //   form: "",
//       frequency: values?.frequency,
//       id: null,
//       //   medicineid: medicineData?.id,
//       medicinename: values?.medicine,
//       mmucode: auth.profileid,
//       quantity: values?.dose * values?.duration * frequencydata?.countperday,
//       recstatus: "OPEN",
//       status: "ACTIVE",
//       syncstatus: "NOT_SYNCED",
//       tempid: "",
//       uom: values.uom,
//       visitid: aggregiatepatient?.visit?.visitid,
//     };
//     setData([...data, newData]);
//     setIdCounter(idCounter + 1);
//     resetForm();
//     setFrequencydata(null);
//   };

//   const initialValues = {
//     medicine: "",
//     uom: "",
//     duration: "",
//     dose: "",
//     frequency: "",
//   };
//   const validationSchema = Yup.object({
//     medicine: Yup.string().required("Medicine is required"),
//     uom: Yup.string().required("uom is required"),
//     frequency: Yup.string().required("Frequency is required"),
//     duration: Yup.string().required("Duration is required"),
//     dose: Yup.string().required("Dose is required"),
//   });

//   const removeData = (id) => {
//     const newData = data.filter((item) => item?.uid !== id);
//     setData(newData);
//   };

//   const columns = [
//     { Header: "medicine", accessor: "dosage" },
//     { Header: "Uom", accessor: "" },
//     { Header: "frequency", accessor: "frequency" },
//     { Header: "dose", accessor: "dose" },
//     { Header: "duration", accessor: "duration" },
//     { Header: "quantity", accessor: "quantity" },
//     {
//       Header: "Action",
//       accessor: "uid",
//       Cell: ({ row }) => (
//         <span>
//           <Link to={{}}>
//             <FaRegTimesCircle
//               style={{
//                 color: "#f44336",
//                 width: "25px",
//                 height: "25px",
//                 cursor: "pointer",
//               }}
//               onClick={() => removeData(row?.values?.uid)}
//             />
//           </Link>
//         </span>
//       ),
//     },
//   ];

//   const handleAssignFollowUp = (event) => {
//     setShowAppointments(event.target.checked);
//     console.log(event.target.checked, "handleAssignFollowUp event 597");
//   };

//   const updateData = (val) => {
//     setDate(val);
//   };

//   const handleAppointment = (dataFromGrandchild, value) => {
//     setSlot(value);
//     setAppointmentData(dataFromGrandchild);
//   };

//   const handleFollowUp = (visit) => {
//     setIsLoading(true);
//     const getData = {
//       approvedby: "",
//       approveddate: "",
//       approver: "string",
//       approvertype: "string",
//       classname: "string",
//       pricelist: null,
//       code: "string",
//       createdby: auth.profileid,
//       createddate: "",
//       doctor: auth,
//       profileid: auth.profileid,
//       documentofproof: "string",
//       id: null,
//       latlong: "string",
//       location: "",
//       modifiedby: "string",
//       modifieddate: "",
//       type: "booking",
//       ordernumber: "string",
//       paymentmethod: "Pay on Counter",
//       patient: aggregiatepatient.patient,
//       slot: slot,
//       recstatus: "SCHEDULED",
//       status: "ACTIVE",
//       syncstatus: "SYNCED",
//       visit: visit,
//       visitid: visit?.visitid,
//     };

//     axios
//       .post(`${APIS.bookingServiceApi}/create`, getData)
//       .then((response) => {
//         updateVisit(visit?.visitid);
//         const roomid = uuidv4().split("-").slice(0, 3).join("-");
//         const roominfoobj = {
//           bookingid: response?.data?.data?.dto?.ordernumber,
//           doctorid: response?.data?.data?.dto?.doctor?.profileid,
//           id: null,
//           link: `https://softalk.softwaremathematics.com/join/${roomid}`,
//           profileid: auth.profileid,
//           recstatus: "OPEN",
//           roomid: roomid,
//           status: "ACTIVE",
//           syncstatus: "SYNCED",
//         };
//         roomIdGeneration(roominfoobj);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.log(error);
//         setIsLoading(false);
//       });
//   };

//   const createVisit = async () => {
//     setIsLoading(true);
//     let body = {
//       createdby: auth.profileid,
//       createdbyname: auth.name,
//       doctorid: auth.profileid,
//       mmucode: auth.profileid,
//       visitcategory: "ONLINE",
//       paymenttype: "PAID",
//       patientid: aggregiatepatient.patient.patientid,
//       profileid: aggregiatepatient.patient.profileid,
//       recstatus: "SCHEDULED",
//       status: "ACTIVE",
//       visittype: "FOLLOW_UP",
//       doctorname: auth.doctorname,
//       address: "",
//       parentvisitid: aggregiatepatient.visit.visitid,
//       childvisitid: "",
//     };

//     await axios
//       .post(`${APIs.visitServiceApi}/create`, body)
//       .then((response) => {
//         handleFollowUp(response.data.data.dto);
//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.log(error, "Create visit error");
//         setIsLoading(false);
//       });
//   };

//   const updateVisit = async (childId) => {
//     const body = {
//       visitid: aggregiatepatient.visit.visitid,
//       id: aggregiatepatient.visit?.id,
//       patientid: aggregiatepatient.patient.patientid,
//       profileid: aggregiatepatient.patient.profileid,
//       doctorid: auth.profileid,
//       mmucode: auth.profileid,
//       visittype: "FOLLOW_UP",
//       visitcategory: aggregiatepatient.visit.visitcategory,
//       paymenttype: "PAID",
//       modifiedby: auth.profileid,
//       modifiedbyname: auth?.name,
//       recstatus: "COMPLETED",
//       syncstatus: "NOT_SYNCED",
//       status: "ACTIVE",
//       parentvisitid: "",
//       childvisitid: childId,
//     };

//     await axios
//       .put(`${APIs.visitServiceApi}/update`, body)
//       .then((response) => {
//         handleSubmitPrescription();

//         setIsLoading(false);
//       })
//       .catch((error) => {
//         console.log(error, "Create visit error");
//         setIsLoading(false);
//       });
//   };

//   async function bookingUpdatestatuschange(visitid) {
//     setIsLoading(true);
//     await axios
//       .put(`${APIs.bookingServiceApi}/updateV2?visitid=${visitid}`, {
//         recstatus: "PRESCRIBED",
//         // documentofproof:
//         //   uploadresponse.length === 0
//         //     ? null
//         //     : uploadresponse[uploadresponse.length - 1]?.docid,
//       })
//       .then((response) => {
//         swal("Sucess!", "Prescribed successfully.");
//         setIsLoading(false);
//         handleClose();
//         getpatient();
//       })
//       .catch((e) => {
//         console.log("error", e);
//         setIsLoading(false);
//       });
//   }

//   const completePrescrptionObject = {
//     additionalnotes: remark,
//     age: aggregiatepatient?.patient?.age,
//     chiefcomplaint: chiefcomplaint,
//     diagnosistext: remark,
//     createdby: auth.profileid,
//     diagnosiscategory: "",
//     doctor: auth?.name,
//     diseaselist: selecteddisease,
//     history: "",
//     id: null,
//     labtestlist: finalLabtest,
//     medicinelist: data,
//     mmucode: profileId,
//     name: aggregiatepatient?.patient?.name,
//     nextfollowupdate: new Date(startDate),
//     parentpresid: "",
//     patientid: aggregiatepatient?.patient?.patientid,
//     prestype: "",
//     reasontoreferal: "",
//     recstatus: "PRESCRIBED",
//     refereddoctor: "",
//     status: "ACTIVE",
//     syncstatus: "SYNCED",

//     visitData: aggregiatepatient?.visit,
//     visitid: aggregiatepatient?.visit?.visitid,
//   };

//   async function createPrescription(object) {
//     setIsLoading(true);

//     if (data.length === 0) {
//       swal("Oops", "Please add data in table first!", "error");
//       setIsLoading(false);
//     } else {
//       await axios
//         .post(`${APIs.aggregationServiceApi}/createPrescription`, object)
//         .then((response) => {
//           bookingUpdatestatuschange(visitid);
//         })
//         .catch((e) => {
//           console.log(e);
//           setIsLoading(false);
//         });
//     }
//   }

//   const handleSubmitPrescription = () => {
//     setFinalLabtest([]);
//     for (let i = 0; i < selectedTestlist?.length; i++) {
//       const labTestObj = {
//         createdby: auth.profileid,
//         detailedresult: null,
//         id: null,
//         investigationlist: selectedTestlist[i]?.investigationlist,

//         mmucode: profileId,
//         recstatus: "OPEN",
//         status: "ACTIVE",
//         syncstatus: "NOT_SYNC",
//         testid: selectedTestlist[i]?.id,
//         testname: selectedTestlist[i]?.testname,
//         testresult: "",
//         visitid: aggregiatepatient?.visit?.visitid,
//       };
//       finalLabtest.push(labTestObj);
//     }

//     for (let i = 0; i < completePrescrptionObject?.medicinelist?.length; i++) {
//       delete completePrescrptionObject?.medicinelist[i]?.uid;
//     }
//     completePrescrptionObject.visitData.recstatus = "PRESCRIBED";
//     createPrescription(completePrescrptionObject);
//   };

//   const handlePrescription = () => {
//     if (showAppointments) {
//       createVisit();
//     } else {
//       handleSubmitPrescription();
//     }
//   };

  return (
    <>
      <MDBox pt={3}>
        <MDBox mt={ROLE_CODE?.doctorcode == auth?.rolecode ? 15 : isMidScreen ? 23 : 20}>
          <Card style={{ marginLeft: "22px", marginRight: "22px" }}>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "25px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    My Booking 
                  </MDTypography>
                </span>
                {code?.patientcode ? (
                  <Link
                    style={{
                      alignSelf: "flex-end",
                      marginRight: "10px",
                      marginTop: "-23px",
                      color: "#344767",
                    }}
                    to={"/app/patient/speciality"}
                  >
                    <MdOutlineLibraryAdd />
                  </Link>
                ) : (
                  <></>
                )}
              </div>
            </MDBox>

            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {(ROLE_CODE?.patientcode == auth?.rolecode && isMidScreen) || ROLE_CODE?.doctorcode == auth?.rolecode ? (
        <></>
      ) : (
        <MedharvaFooter />
      )}
      {ROLE_CODE?.patientcode == auth?.rolecode && isMidScreen && <FooterNavbar />}

      {selecteddata && Object.keys(selecteddata).length > 0 && (
        <BookingView
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Booking View"}
          selecteddata={selecteddata}
        />
      )}
      {/* <Dialog open={showIframe} onClose={handleCloseIframe} fullWidth maxWidth="md">

        <Stack zIndex={9999}>
          <iframe
            src={videoUrl}
            title="Video Call"
            width="600"
            height="400"
            allow="camera; microphone"
          ></iframe>
        </Stack>
      </Dialog> */}

      {/* Prescription and Video  */}
      {/* <PrescriptionForm
        open={open}
        handleClose={handleClose}
        aggregiatepatient={aggregiatepatient}
        setSelectedDisease={setSelectedDisease}
        setChiefcomplaint={setChiefcomplaint}
        chiefcomplaint={chiefcomplaint}
        setRemark={setRemark}
        remark={remark}
        handleSubmit={handleSubmit}
        validationSchema={validationSchema}
        initialValues={initialValues}
        setFrequencydata={setFrequencydata}
        columns={columns}
        data={data}
        setSelectedTestlist={setSelectedTestlist}
        handleAssignFollowUp={handleAssignFollowUp}
        showAppointments={showAppointments}
        updateData={updateData}
        handleAppointment={handleAppointment}
        isLoading={isLoading}
        handlePrescription={handlePrescription}
        videoUrl={videoUrl}
      /> */}
    </>
  );
}

export default Bookinglist;
