import React from "react";
import MobileSection1 from "./MobileSection1";
import { Grid } from "@mui/material";
import "./mobileNavbar.css"
import MobileSection2 from "./MobileSection2";
import MobileSection3 from "./MobileSection3";

const mobileNavbar = () => {
  return (
    <div className="mobilenavbarroot">
      <Grid container className="mobilenavbarcontainer1">
        <MobileSection1 />
      </Grid>
      <Grid container mt={2}>
        <MobileSection2 />
      </Grid>
      <Grid container mt={2} mb={1} className="mobilesection2container3">
        <MobileSection3 />
      </Grid>
    </div>
  );
};

export default mobileNavbar;
