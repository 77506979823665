import React from "react";
import "./hover.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Adninnavbar = () => {
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  return (
    <div className="popover__content">
      {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
  
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   </Grid>
   <Grid item xs={12} sm={6}>
   {auth?.name}
 
   </Grid>
   </Grid> */}
   <div className="modal-area">
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   <p style={{ marginLeft: "10px",fontSize:"16px" }}>{auth?.name}</p>
   </div>


    
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person
        </Icon>

        <Link to="/app/pages/profile/profile-overview">My Profile</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          approval
        </Icon>
        <Link to="/app/page/adminrole-list">Appproval Request</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
        vaccines
        </Icon>

        <Link to="/app/page/admin-doctordetail">Add Doctor Detail</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
        personal_injury
        </Icon>
        <Link to="/app/page/admin-patientregistration">Patient Registartion</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
        person_add
        </Icon>
        <Link to="/app/page/admin-registration">Registartion</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/operation-master">Operation Master</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/create-uom">Create Uom</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/create-gst">Create Gst</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/create-item">Create Item</Link>
      </div>
      
     
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/create-price">Create Price</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/role">Role</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/departmentlist">Departmnet</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          price_change
        </Icon>
        <Link to="/app/page/designationlist">Designation</Link>
      </div>
      
     
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          logout
        </Icon>
        <a href="#" onClick={() => reduxDispatch({ type: "LOGOUT_FULFILLED" })}>
  Log Out
</a>

      </div>
    </div>
  );
};




export default Adninnavbar;


