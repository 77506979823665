/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    state: {
      name: "state",
      label: "State",
      type: "select",
      errorMsg: "State is required.",
    }, 
   
    
    project: {
      name: "project",
      label: "MIC Name",
      type: "select",
      errorMsg: "MIC name is required.",
    },
   
   
   
    district: {
      name: "district",
      label: "District",
      type: "select",
      errorMsg: "District is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub District",
      type: "select",
      errorMsg: "Sub district is required.",
    },

    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "select",
      errorMsg: "Pincode is required.",
    },
  },
};

export default form;
