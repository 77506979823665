import {
  Grid,
  CircularProgress,
  Autocomplete,
  Checkbox,
  TextField,
  Card,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./avialableDoctors.css";
import Search from "../../../assets/svg/Search.svg";
import Avialabledoctorinfo from "./avialableDoctorInfo/avialabledoctorinfo";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import profilePicture from "../../../assets/images/products/No-data-found.png";
import MDBox from "components/MDBox";
import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";
import { APIs } from "Services/APIs";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import { variables } from "static/Variables";
 
const DoctorsPage = () => {
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 580);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [doctorlist, setDoctorList] = useState([]);
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState([]);
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [selectedSpecialties, setSelectedSpecialties] = useState([]);
  const [doctorsWithPrice, setDoctorWithPrice] = useState([]);
  const [filterDoctorList, setFilterDoctorList] = useState([]);
  const storedFilterValue = localStorage.getItem("filterValue");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [selectedValue, setSelectedValue] = useState(
    storedFilterValue ? storedFilterValue : ""
  );
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 580);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
 
  const getDoctorList = async (code) => {
    setLoading(true);
 
    try {
      let apiUrl = `${APIs.summaryServiceApi}/get?type=doctor&isactive=ONLINE`;
 
      if (auth && auth?.rolecode === ROLE_CODE.nursecode) {
        apiUrl = `${APIs.summaryServiceApi}/get?type=doctor&isactive=ONLINE&profileid=${auth.createdby}`;
      }
 
      const response = await axios.get(apiUrl);
 
      if (response?.data?.data?.listSize === 0) {
        swal("Warning!", "No doctors available.", "warning");
      } else {
        const allDoctors = response?.data?.data?.dtoList;
 
        // const doctorsWithPrice = allDoctors.filter(
        //   (doctor) => doctor?.price && doctor?.price[0]?.value
        // );
        const doctorsWithPrice = allDoctors.filter((doctor) =>
          doctor?.price?.some(
            (price) => price?.value && price?.type === variables?.showFeeType && price?.status === "ACTIVE"
          )
        );
 
        setDoctorWithPrice(doctorsWithPrice);
 
        const catcodes = localStorage?.getItem("doctor-code")?.split(",");
        if (catcodes) {
          setDoctorList(
            doctorsWithPrice.filter((doctor) =>
              catcodes.some((code) => doctor?.categorycodes?.includes(code))
            )
          );
          setFilterDoctorList(
            doctorsWithPrice.filter((doctor) =>
              catcodes.some((code) => doctor?.categorycodes?.includes(code))
            )
          );
        } else {
          setDoctorList(doctorsWithPrice);
          setFilterDoctorList(doctorsWithPrice);
        }
      }
 
      console.log("setFilterDoctorList",doctorsWithPrice)
 
      setLoading(false);
    } catch (error) {
      // console.error("Error fetching data:", error);
      swal("Warning!", "Error fetching data. Please try again.", "warning");
      setLoading(false);
    }
  };
 
  const [searchValue, setSearchValue] = useState("");
 
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
 
  useEffect(() => {
    const filteredDoctors = doctorlist.filter((doctor) => {
      const lowerCaseSearch = searchValue.toLowerCase();
      return (
        doctor.name.toLowerCase().includes(lowerCaseSearch) ||
        doctor.age.toString().includes(lowerCaseSearch) ||
        doctor.price[0]?.value.toString().includes(lowerCaseSearch)
      );
    });
 
    setFilterDoctorList(filteredDoctors);
  }, [searchValue, doctorlist]);
 
  useEffect(() => {
    const storedCode = localStorage.getItem("doctor-code");
    const storedCodesArray = storedCode ? storedCode.split(",") : [];
    const selectedCategories = category.filter((cat) =>
      storedCodesArray.includes(cat?.code)
    );
    setSelectedSpecialties(selectedCategories);
    getDoctorList(storedCodesArray.join(","));
  }, [category]);
 
  async function getSubCategory() {
    setLoading(true);
    await axios
      .get(
        `category-service/api/Category/v1/getWithHierarchy?depth=2&hierarchicalcode=`
      )
      .then((response) => {
        setCategory(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
     
        console.log("error", e);
        setLoading(false);
      });
  }
 
  useEffect(() => {
    getSubCategory();
    getDoctorList();
  }, []);
 
  // const handleSelectChange = (event) => {
  //   const selectedValue2 = event.target.value;
   
  //   localStorage.setItem("filterValue", selectedValue2);
 
  //   setSelectedValue(selectedValue2);
  //   console.log("166",doctorlist)
  //   if (selectedValue2 === "") {
  //     setFilterDoctorList(doctorlist);
  //   } else {
  //     let filteredDoctorList;
 
  //     switch (selectedValue2) {
  //       case "<1000":
  //         filteredDoctorList = doctorlist.filter(
  //           (doctor) => parseInt(doctor?.price?.[0]?.value) < 1000
  //         );
  //         break;
  //       case "1000-1500":
  //         filteredDoctorList = doctorlist.filter(
  //           (doctor) =>
  //             parseInt(doctor?.price?.[0]?.value) >= 1000 &&
  //             parseInt(doctor?.price?.[0]?.value) <= 1500
  //         );
  //         break;
  //       case ">1500":
  //         filteredDoctorList = doctorlist.filter(
  //           (doctor) => parseInt(doctor?.price?.[0]?.value) > 1500
  //         );
  //         break;
  //       default:
  //         break;
  //     }
 
  //     setFilterDoctorList(filteredDoctorList || []);
  //   }
  // };
  const handleSelectChange = (event) => {
    const selectedValue2 = event.target.value;
 
    localStorage.setItem("filterValue", selectedValue2);
 
    setSelectedValue(selectedValue2);
    console.log("Doctor list:", doctorlist);
 
    if (selectedValue2 === "") {
      setFilterDoctorList([...doctorlist]);
    } else {
      let filteredDoctorList;
 
      switch (selectedValue2) {
        case "<1000":
          filteredDoctorList = doctorlist.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) < 1000 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for <1000:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        case "1000-1500":
          filteredDoctorList = doctorlist.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) >= 1000 &&
                parseInt(price?.value) <= 1500 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for 1000-1500:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        case ">1500":
          filteredDoctorList = doctorlist.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) > 1500 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for >1500:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        default:
          filteredDoctorList = [];
          break;
      }
 
      setFilterDoctorList(filteredDoctorList || []);
    }
  };
 
  useEffect(() => {
    if (!localStorage.getItem("doctor-code")) {
      setFilterDoctorList(doctorsWithPrice);
      setDoctorList(doctorsWithPrice);
    } else {
      const catcodes = localStorage?.getItem("doctor-code")?.split(",");
      setFilterDoctorList(
        doctorsWithPrice?.filter((doctor) =>
          catcodes?.some((code) => doctor?.categorycodes?.includes(code))
        )
      );
    }
  }, [localStorage.getItem("doctor-code")]);
 
  useEffect(() => {
    let filteredDoctors = doctorsWithPrice;
    if (selectedSpecialties.length > 0) {
      const catcodes = selectedSpecialties?.map((i) => i?.code);
      filteredDoctors = filteredDoctors?.filter((doctor) =>
        catcodes?.some((code) => doctor?.categorycodes?.includes(code))
      );
    }
    if (selectedValue !== "") {
      switch (selectedValue) {
        case "<1000":
          filteredDoctors = filteredDoctors.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) < 1000 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for <1000effect:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        case "1000-1500":
          filteredDoctors = filteredDoctors.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) >= 1000 &&
                parseInt(price?.value) <= 1500 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for >1500effect:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        case ">1500":
          filteredDoctors = filteredDoctors.filter((doctor) => {
            const matchingPrices = doctor?.price?.filter(
              (price) =>
                parseInt(price?.value) > 1500 &&
                price?.type === "fees" &&
                price?.status === "ACTIVE"
            );
            console.log("Matching prices for >1500effect:", matchingPrices);
            return matchingPrices.length > 0;
          });
          break;
        default:
          break;
      }
    }
 
    setFilterDoctorList(filteredDoctors);
  }, [selectedSpecialties, selectedValue, doctorsWithPrice]);
 
  return (
    <div
      style={{ background: "#f3f5ea", overflowX: "hidden", marginTop: "120px" }}
    >
      <div className="avialablecenter">
     
          <>
            <Grid container xs={12} className="avialablecenter">
              {isSmallScreen ? (
                <>
                  <Grid
                    item
                    xs={12}
                    mt={10}
                    className="avialabledoctorsitemsearch"
                  >
                    Search
                  </Grid>
                  <Grid container mt={1} className="avialabledoctorscontainer">
                    <Grid
                      item
                      mb={isSmallScreen ? 1 : undefined}
                      className="avialabledoctorsitem2"
                    >
                      <input
                        type="text"
                        id="avialabledoctorsinput"
                        placeholder="Search for doctors"
                        className="avialabledoctorsinput"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                      <button className="avialabledoctorsbutton1">
                        <img
                          src={Search}
                          alt="Search Logo"
                          className="avialabledoctorsimg1"
                        />
                      </button>
                    </Grid>
 
                    <Grid container xs={6}>
                      <span className="autocompletespan">
                        <Autocomplete
                          multiple
                          fullWidth
                          style={{ width: "50vw", background: "white" }}
                          id="machinelist-tags-demo"
                          options={category}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option?.name}
                          value={selectedSpecialties}
                          onChange={(event, value) => {
                            setSelectedSpecialties(value);
                            if (value.length > 0) {
                              const catcodes = value.map((i) => i?.code);
                              localStorage.setItem("doctor-code", catcodes);
                              setFilterDoctorList(
                                doctorlist.filter((doctor) =>
                                  catcodes.some((code) =>
                                    doctor?.categorycodes.includes(code)
                                  )
                                )
                              );
                            } else {
                              localStorage.removeItem("doctor-code");
                              setFilterDoctorList(doctorsWithPrice);
                              setDoctorList(doctorsWithPrice);
                            }
                          }}
                          renderOption={(props, option, { selected }) => (
                            <div {...props} className="autocompletesdiv1">
                              <div className="autocompletesdiv2">
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  checked={selected}
                                />
                                <span>{option?.name}</span>
                              </div>
                            </div>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Filter by Speciality"
                              variant="outlined"
                            />
                          )}
                        />
                      </span>
                    </Grid>
                    <Grid item className="avialabledoctorsitem3">
                      <select
                        className="avialabledoctorsinput2"
                        value={selectedValue}
                        onChange={handleSelectChange}
                      >
                        <option value="">Filter by Price</option>
                        <option value="<1000">Below - 1000</option>
                        <option value="1000-1500">1000 - 1500</option>
                        <option value=">1500">1500 - Above</option>
                      </select>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    ml={isSmallScreen ? 3 : 7}
                    mt={isSmallScreen ? 3 : 10}
                    className="avialabledoctorsroot"
                  >
                    <Grid item className="avialabledoctorsitemmob">
                      Available Doctors
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    ml={isSmallScreen ? 3 : 7}
                    mt={10}
                    className="avialabledoctorsroot"
                  >
                    <Grid item className="avialabledoctorsitem">
                      Available Doctors
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    ml={isSmallScreen ? 6 : 7}
                    mt={3}
                    className="avialabledoctorscontainer"
                  >
                    <Grid item mr={9} className="avialabledoctorsitem2">
                      <input
                        type="text"
                        id="avialabledoctorsinput"
                        placeholder="Search for doctors"
                        className="avialabledoctorsinput"
                        value={searchValue}
                        onChange={handleSearchChange}
                      />
                      <button className="avialabledoctorsbutton1">
                        <img
                          src={Search}
                          alt="Search Logo"
                          className="avialabledoctorsimg1"
                        />
                      </button>
                    </Grid>
                    <Grid item xs={4} className="filtergriditems">
                      <Grid item mb={3}>
                        <span className="autocompletespan">
                          <Autocomplete
                            multiple
                            // fullWidth
                            style={{ width: "200px", background: "white" }}
                            id="category-tags-demo"
                            options={category}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option?.name}
                            value={selectedSpecialties}
                            onChange={(event, value) => {
                              setSelectedSpecialties(value);
                              if (value.length > 0) {
                                const catcodes = value.map((i) => i?.code);
                                localStorage.setItem("doctor-code", catcodes);
                                localStorage.removeItem("filterValue");
                                setSelectedValue("");
                                setFilterDoctorList(
                                  doctorlist?.filter((doctor) =>
                                    catcodes.some((code) =>
                                      doctor?.categorycodes?.includes(code)
                                    )
                                  )
                                );
                              } else {
                                localStorage.removeItem("doctor-code");
 
                                setFilterDoctorList(doctorsWithPrice);
                                setDoctorList(doctorsWithPrice);
                              }
                            }}
                            renderOption={(props, option, { selected }) => (
                              <div {...props} className="autocompletesdiv1">
                                <div className="autocompletesdiv2">
                                  <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    checked={selected}
                                  />
                                  <span>{option?.name}</span>
                                </div>
                              </div>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Filter by Speciality"
                                variant="outlined"
                              />
                            )}
                          />
                        </span>
                      </Grid>
                      <Grid item mb={3} className="avialabledoctorsitem3">
                        <select
                          className="avialabledoctorsinput2"
                          value={selectedValue}
                          onChange={handleSelectChange}
                        >
                          <option value="">Filter by Price</option>
                          <option value="<1000">Below - 1000</option>
                          <option value="1000-1500">1000 - 1500</option>
                          <option value=">1500">1500 - Above</option>
                        </select>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
                 {loading ? (
       
          <Grid container xs={12} justifyContent={"center"} mb={2}>
          <Grid container xs={11} justifyContent={"center"}  mt={1} spacing={3} >
          {[...Array(10)].map((_, index) => (
            <Grid item key={index}   xs={12} sm={6} md={6} lg={4}>
             <Card>
             <Grid mt={2} style={{display:"flex",gap:"50px",marginLeft:"10px"}}>
                <ProfileSkeleton variant='circular'  width={70} height={70} borderRadius="10%"/>
                <ProfileSkeleton width="50%" />
                <ProfileSkeleton width="5%" />
                </Grid>
                <ProfileSkeleton width="85%" />
                <ProfileSkeleton width="85%" />
                <Grid mb={3} style={{display:"flex",gap:"5px",marginLeft:"50px"}}>
                <ProfileSkeleton width="30%" />
                <ProfileSkeleton width="30%" />
                <ProfileSkeleton width="30%" />
               
               
                </Grid>
               
                <Grid mt={3} mb={2} style={{display:"flex",justifyContent:"center",gap:"40px"}}>
                <ProfileSkeleton width="40%" />
                <ProfileSkeleton width="40%" />
               
                </Grid>
                </Card>
            </Grid>
          ))}
        </Grid>
        </Grid>
        ) : (
              <Grid
                container
                mt={3}
                xs={12}
                className="avialabledoctorrootcontainer"
              >
                {filterDoctorList.length === 0 ? (
                  <Grid
                    container
                    xs={11.3}
                    justifyContent="center"
                    mt={5}
                    style={{ padding: "10px" }}
                  >
                    <Card className="selectspecialitycard">
                      <MDBox
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        p={3}
                      >
                        <img
                          src={profilePicture}
                          alt="Brooklyn Alice"
                          style={{
                            width: "100%",
                            maxWidth: "200px",
                            height: "auto",
                            // maxHeight:'200px'
                          }}
                        />
                      </MDBox>
                    </Card>
                  </Grid>
                ) : (
                  <>
                    <Grid
                      container
                      xs={11.3}
                      className="avialabledoctorscontainer2"
                    >
                      {filterDoctorList?.map((item, index) => (
                        <Avialabledoctorinfo
                          key={index}
                          doctor={item}
                          code={location?.state?.code}
                          pagelabel={location?.state?.pagelabel}
                        />
                      ))}
                    </Grid>
                  </>
                )}
              </Grid>
        )}
            </Grid>
            <Grid
              container
              xs={10}
              mt={10}
              mb={isMidScreen ? 10 : 5}
              className="avialbledoctornavigate"
            >
              <div className="spacebutton">
                <div>
                  <button
                    className="previous"
                    onClick={() => {
                      navigate("/app/home/speciality");
                      // localStorage.removeItem("doctor-code");
                      localStorage.removeItem("filterValue");
                    }}
                  >
                    Previous
                  </button>
                </div>
              </div>
            </Grid>
          </>
       
      </div>
    </div>
  );
};
 
export default DoctorsPage;
 