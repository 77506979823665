import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const micDetailsAction = 
   createAsyncThunk("micDetailsAction", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.profileId){
        url = APIs.projectServiceApi +`/getProjectByProfileId?profileId=${data.profileId}`
      } else {
        url = APIs.projectServiceApi +`/get`
      }
        let respData = await getAllUsers(url, "")
        console.log(respData, "respData 17")
        return respData?.dto || {};

    } catch (error) {
      return rejectWithValue(error);
    }
  });