import React from "react";
import "./centerstyle.css";
import Slider from "@mui/material/Slider";
import Button from "@mui/material/Button";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend} from 'recharts';
import TextField from "@mui/material/TextField";


export const PriceDropdown = () => {
    const [value, setValue] = React.useState([150, 250]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const slotdata = [
        { slot: 1 },
        { slot: 2 },
        { slot: 3 },
        { slot: 4 },
        { slot: 5 },
        { slot: 6 }
    ];
    const data = [
        { name: 'A', value: 10 },
        { name: 'B', value: 20 },
        { name: 'C', value: 15 },
        // Add more data as needed
    ];
    return (
        <div className="pricedropdownmaindiv">
            <p className="pricedropdownheading">Price range</p>

            <span className="pricedropdown-span">The average price range is 400</span>

            <div className="pricedropdownchart-container">
                <div className="pricedropdownslider-container">

                    <BarChart
                        data={data}
                        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                        fill="#039147"

                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>

                    {/* dummy barchart bar for ui*/}

                    <div style={{ width: 3.14, height: 14.66, left: 0, top: 16.05, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 16.75, left: 4.19, top: 13.96, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 18.50, left: 8.38, top: 12.21, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 17.45, left: 12.56, top: 13.26, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 23.03, left: 16.75, top: 7.68, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 17.45, left: 20.94, top: 13.26, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 17.45, left: 25.13, top: 13.26, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 17.45, left: 29.32, top: 13.26, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 19.54, left: 33.50, top: 11.17, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 18.50, left: 37.69, top: 12.21, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 18.50, left: 50.25, top: 12.21, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 18.50, left: 54.44, top: 12.21, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 18.50, left: 58.63, top: 12.21, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 13.61, left: 62.82, top: 17.10, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 10.47, left: 67.01, top: 20.24, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 7.68, left: 71.19, top: 23.03, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 10.47, left: 75.38, top: 20.24, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 4.89, left: 79.57, top: 25.82, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 30.71, left: 41.88, top: 0, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                    <div style={{ width: 3.14, height: 27.92, left: 46.07, top: 2.79, position: 'absolute', background: '#D9D9D9', borderRadius: 1 }} />
                  {/* dummy barchart bar for ui*/}
                
                </div>

                <div style={{ width: 197 , marginTop:"8px"}}>


                    <Slider
                        getAriaLabel={() => 'Temperature range'}
                        value={value}
                        onChange={handleChange}
                        color="success"
                        min={100}
                        max={300}
                        valueLabelDisplay="auto"
                        aria-labelledby="price-range-slider"
                    />

                </div>

            </div>
            <div className="pricwdropdowninputdiv">

                <div className="pricedropdownprice-input">
                    <TextField
                        label="Min price"
                        variant="standard"
                        defaultValue="100"
                
                    />

                </div>
                <div className="pricedropdownprice-input">
                    <TextField
                        label="Max price"
                        variant="standard"
                        defaultValue="300"
                
                    />

                </div>
            </div>
            <p className="pricedropdownheading1">Number of appointments</p>
            <div className="pricedropdownappointments-container">
                {slotdata.map((slot, index) => (
                    <div key={index} className="pricedropdownappointment-item">
                        <p className="slotpricepar">{slot.slot}</p>
                    </div>
                ))}

            </div>
            <div className="pricedropdownresult-buttons-container">

            <button className="centerrangebutton1">
                    Clear all 
                    </button>
                   
                      <button className="centerrangebutton12">
                      Show 40 results
                    </button>
               


            </div>
        </div>
    );
};
