
import checkout from "../schemas/form";
const {
  formField: { DiseaseName, DiseaseDescrption, DiseaseType  },
} = checkout;

const initialValues = {
  [DiseaseName.name]: "",
  [DiseaseDescrption.name]: "",
  [DiseaseType.name]: "",
};

export default initialValues;
