import { Grid } from "@mui/material";
import React from "react";
import "./navbar.css";
import { Link } from "react-router-dom";

const SectionOne = () => {
  return (
    <>
      <Grid item className="navbaritem1">
        Get your first doctor visit for just ₹799.
      </Grid>

      <Link to="/app/home/speciality" className="navbarbooknowbtn">
        Book now
      </Link>
    </>
  );
};

export default SectionOne;
