/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// OrderDetails page components
import Header from "components/Resuable/detailcomponet/components/Header";
import MDTypography from "components/MDTypography";

import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";

import OrderInfo from "components/Resuable/detailcomponet/components/OrderInfo";
import PaymentDetails from "components/Resuable/detailcomponet/components/PaymentDetails";
import OrderSummary from "components/Resuable/detailcomponet/components/OrderSummary";
import { Link, useLocation, useParams } from "react-router-dom";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import Rolecode from "../../../Rolecode";
import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";


function Detailpage() {
  const code = Rolecode();
  const location = useLocation();
  const [doctorfess, setDoctorfees] = useState(location.state?.doctorfess);
  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* {code?.patientcode && (
        <Navbar pagelabel={location?.state?.pagelabel} />
      )}
      {code?.patientcode && isMidScreen && <MobileNavbar />}
      {code?.Doctorcode && <DashboardLayout />}
      {code?.Doctorcode && <UpaarNavbar />} */}
      <MDBox my={6} mt={code?.Doctorcode ? 18 : 25}>
        <Grid container spacing={3} mb={isMidScreen ? 10 : undefined} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox pt={2} px={2}>
                <Header />
              </MDBox>
              <Divider />
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mb={3}>
                  <OrderInfo data={location.state} />
                </MDBox>
                <Divider />
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={5}>
                      <PaymentDetails data={location.state} />
                      {/* <MDBox mt={3}>
                        <BillingInformation />
                      </MDBox> */}
                    </Grid>

                    {/* <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>
                      <OrderSummary />
                    </Grid> */}
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <MDBox p={3}>
                        <MDBox width="100%" overflow="auto">
                          <Table sx={{ minWidth: "32rem" }}>
                            <MDBox component="thead">
                              <TableRow style={{ backgroundColor: "#039147" }}>
                                <MDBox
                                  component="th"
                                  width={{ xs: "45%", md: "50%" }}
                                  py={1.5}
                                  px={1}
                                  textAlign="left"
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                  >
                                    Item
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                  >
                                    Qty
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                  >
                                    Rate
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="h6"
                                    color="white"
                                    fontWeight="medium"
                                  >
                                    Amount
                                  </MDTypography>
                                </MDBox>
                              </TableRow>
                            </MDBox>
                            <TableBody>
                              {/* {response?.map((values) => ( */}
                              <TableRow>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  p={1}
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    {doctorfess[0].name}
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    1
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    ₹ {doctorfess[0].value}
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                >
                                  <MDTypography
                                    variant="body2"
                                    color="text"
                                    fontWeight="regular"
                                  >
                                    ₹ {doctorfess[0].value}
                                  </MDTypography>
                                </MDBox>
                              </TableRow>

                              {/* ))} */}

                              <TableRow>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  p={1}
                                  sx={borderBottom}
                                />
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                />
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                >
                                  <MDTypography variant="h5">
                                    Total
                                  </MDTypography>
                                </MDBox>
                                <MDBox
                                  component="td"
                                  textAlign="left"
                                  py={1}
                                  pr={1}
                                  pl={3}
                                  sx={borderBottom}
                                >
                                  <MDTypography variant="h5">
                                    ₹ {doctorfess[0].value}
                                  </MDTypography>
                                </MDBox>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
      {code?.patientcode && isMidScreen ? <></> : <MedharvaFooter />}
      {code?.patientcode && isMidScreen && (
        <FooterNavbar pagelabel={location?.state?.pagelabel} />
      )}
    </>
  );
}

export default Detailpage;
