/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDEditor from "components/MDEditor";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
// Images
import heartImage from "assets/images/heart.png";

function Patientdetail() {
  const [editorValue, setEditorValue] = useState(
    `<p>
      Long sleeves black denim jacket with a twisted design. Contrast stitching. Button up closure. White arrow prints on the back.
    </p>`
  );

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography style={{color:"blue"}} variant="h5">Patient Detail</MDTypography>
        <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
        <MDBox mt={7}>
          <Grid container spacing={-12}>
          <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Patient Name : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
            Deepak
            </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>

          <Grid container spacing={-12}>
            <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Blood Pressure : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
             120/62
            </MDTypography>
            </Grid>
           
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={-12}>
          <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Diabetes : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
            6.5% or above
            </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
        <Grid container spacing={-12}>
          <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Prediabetes : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
            5.7 – 6.4%
            </MDTypography>
            </Grid>
          </Grid>
          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description&nbsp;&nbsp;
                  <MDTypography variant="caption" fontWeight="regular" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDEditor value={editorValue} onChange={setEditorValue} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDBox mb={3}>
                <MDBox mb={1.625} display="inline-block">
                  <MDTypography
                    component="label"
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    textTransform="capitalize"
                  >
                    Category
                  </MDTypography>
                </MDBox>
                <Autocomplete
                  defaultValue="Clothing"
                  options={["Clothing", "Electronics", "Furniture", "Others", "Real Estate"]}
                  renderInput={(params) => <MDInput {...params} variant="standard" />}
                />
              </MDBox>
              <MDBox mb={1.625} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Color
                </MDTypography>
              </MDBox>
              <Autocomplete
                defaultValue="Black"
                options={["Black", "Blue", "Green", "Orange", "White"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </Grid>
          </Grid> */}
          <MDBox mt={1}>
          <Grid container spacing={-12}>
          <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Albumin : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
            3.5 to 5.0 grams per deciliter (g/dL)
            </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox mt={1}>
          <Grid container spacing={-12}>
          <Grid item xs={12} sm={3}>
            <MDTypography variant="h6">Bilirubin : </MDTypography>
            </Grid>
            <Grid item xs={12} sm={3}>
            <MDTypography component="p" variant="h6" color="text">
            0.1 to 1.2 milligrams per deciliter (mg/dL)
            </MDTypography>
            </Grid>
          </Grid>
        </MDBox>
        </MDBox>
        </Grid>
        <Grid item xs={12} sm={4}>
       
        <img src={heartImage}  width="100%"
          height="100%"
         alt="Logo" />;
    
        </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}




export default Patientdetail;