

import React, { useEffect, useState } from 'react';
import axios from "../../../../axiosinstance";
import { Autocomplete, Card, Grid, TextField } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from 'sweetalert';

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import PriceInfo from './PriceInfo';





const AdminCreatePrice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [selectedItem, setSelectedItem] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState([]);
  const [pricePopup, setPricePopup] = useState(false);
  const [profileId, setProfileId] = useState(JSON.parse(localStorage.getItem("login-auth"))?.profileid)

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px",cursor:"pointer" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  const dataObj = {
    columns: [
      { Header: "Name", accessor: "name" },
        { Header: "Test name", accessor: "pricename" },
        { Header: "type", accessor: "type" },  

        { Header: "Price type", accessor: "valuetype" },  
        { Header: "currency", accessor: "currency" },
        { Header: "Value", accessor: "value" },  
        { Header: "uom", accessor: "uom" },
       { Header: "Tax Preference", accessor: "taxinclusiveflag"},

        { Header: "Action", accessor: "action"},
       
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsDoctor = {
    options: !doctorOptions ? [{ name: "Loading...", id: 0 }] : doctorOptions,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
};
  
  async function onDelete(item) {
   
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      console.log(willDelete)
      if (willDelete) {
        
            axios
        .put(
          "price-service/api/price/v1/delete",
          item
        )
            .then((response) => {
          console.log(response);
          getApiResponse();
        })
        swal("Your price record has been deleted!", {
          icon: "success",
          
        });
      } else {
        swal("Your price record is safe!");
      }
    });
  }
  function handleAddButton(){
    setPricePopup(true)
    setSelectedItem(null)
   

  }

  function getApiResponse(code) {
    setLoading(true);
    
    axios
      .get(`price-service/api/price/v1/get?profileid=${code}`)
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <FaRegEdit
                style={iconStyle}
                onClick={() => {
                  setSelectedItem(item);
                  setPricePopup(true);
                }}
              />
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  onDelete(item);
                }}
              />
            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const getData = async () => {
    try {
      const response = await axios.get(
        `login-service-mongo/api/loginservice/v2/get?rolecode=PH257&recstatus=APPROVED`
      );
      setDoctorOptions(response.data.data.dtoList);
    } catch (error) {
      console.log('error', error);
    }
  }
  useEffect(() => {
    getData()
   
  }, []);

 
  return (
    <DashboardLayout>
    
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span style={{ alignSelf: "flex-start", marginTop: "20px" }}>
                  <MDTypography variant="h5" fontWeight="medium">
                  Pharmacy Price List
                  </MDTypography>
                </span>
                <MdOutlineLibraryAdd
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                    cursor: "pointer"
                  }}
                  onClick={handleAddButton}
                
                />
              </div>
            </MDBox>
            <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
              {...defaultPropsDoctor}
              onChange={(event, newValue) => {
                console.log(newValue)
            
                getApiResponse(newValue?.profileid);
             
                
              }}
              renderInput={(params) => <TextField {...params} label="Select Pharmacy" />}
            />
          </Grid>
        </Grid>
            <BarLoader
              color="#344767"
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />
          {pricePopup && (
     <PriceInfo

       pricePopup={pricePopup}
      setPricePopup={setPricePopup}
       data={selectedItem}
      getApiResponse={getApiResponse}
     />
          )}
    </DashboardLayout>
  );
};











export default AdminCreatePrice