import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import Search from "../../findDoctor/assets/images/Search.svg";
import photo1 from "../../findDoctor/assets/images/supplimentsimg.jpg";
import photo2 from "../../findDoctor/assets/images/sexualwellnessimg.jpg";
import photo3 from "../../findDoctor/assets/images/wellnessimg.jpg";
import photo4 from "../../findDoctor/assets/images/skinncareimg.jpg";
import photo5 from "../../findDoctor/assets/images/babyfoodncareimg.jpg";
import "./selectMedicineCategory.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import category from '../../../static/category';

const SelectMedicineCategory = () => {
  console.log(category,"18category")
  const navigate = useNavigate();
  const [selectedMedicineCategory, setSelectedMedicineCategory] =
    useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 560);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 560);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    { medicinecategory: "Vitamins and supplements", image: photo1 },
    { medicinecategory: "Sexual wellness", image: photo2 },
    { medicinecategory: "Women Welness", image: photo3 },
    { medicinecategory: "Skin and Hair care", image: photo4 },
    { medicinecategory: "Baby Food and Care", image: photo5 },
    { medicinecategory: "Vitamins and supplements", image: photo1 },
    { medicinecategory: "Sexual wellness", image: photo2 },
    { medicinecategory: "Women Welness", image: photo3 },
    { medicinecategory: "Skin and Hair care", image: photo4 },
    { medicinecategory: "Baby Food and Care", image: photo5 },
    { medicinecategory: "Vitamins and supplements", image: photo1 },
    { medicinecategory: "Sexual wellness", image: photo2 },
    { medicinecategory: "Women Welness", image: photo3 },
    { medicinecategory: "Skin and Hair care", image: photo4 },
    { medicinecategory: "Baby Food and Care", image: photo5 },
  ];

  const handleButtonClick = () => {
    if (selectedMedicineCategory !== null) {
      console.log(selectedMedicineCategory);
      navigate("/app/pages/users/medicine-cart")
    } else {
      alert("No Medicine Category selected.");
    }
  };

  const filteredData = data.filter((item) =>
    item.medicinecategory.toLowerCase().includes(searchInput.toLowerCase())
  );

  return (
    <div>
            {isSmallScreen ? <></> : <Navbar pagelabel="Medicines" />}
      {isSmallScreen ? <MobileNavbar /> : <></>}
      <Grid
        item
        pt={23}
        ml={isSmallScreen ? 0 : 9}
        className="medicinecategoryitem"
      >
        Select Category
      </Grid>
      <Grid item className="medicinecategorycontainer">
        <Grid item mt={3} className="medicinecategoryitem2">
          <input
            type="text"
            id="medicinecategoryinput"
            placeholder="Search for medicines"
            className="medicinecategoryinput"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button
            className="medicinecategorybutton1"
            onClick={handleButtonClick}
          >
            <img
              src={Search}
              alt="Search Logo"
              className="medicinecategoryimg1"
            />
          </button>
        </Grid>
      </Grid>
      <div className="medicinecategorycontainer2">
        <Grid container xs={11} mt={5} className="medicinecategoryitem3">
          {filteredData.map((item, index) => {
            const words = item.medicinecategory.split(" ");
            const shouldBreakWords = words.length > 2;

            return (
              <Grid
                item
                key={index}
                mt={3}
                mr={3}
                ml={3}
                className={`medicinecategorycontainer3 ${
                  selectedMedicineCategory === item.medicinecategory
                    ? "selected"
                    : ""
                }`}
                onClick={() =>
                  setSelectedMedicineCategory(item.medicinecategory)
                }
              >
                <img
                  src={item?.image}
                  alt="Image"
                  className={`medicinecategoryimg ${
                    selectedMedicineCategory === item.medicinecategory
                      ? "selected"
                      : ""
                  }`}
                />
                <div className="greenTick">&#10004;</div>
                <br />
                {shouldBreakWords ? (
                  <>
                    <div className="medicinecategorytext">
                      {words[0]} {words[1]}
                    </div>
                    <div className="medicinecategorytext">
                      {words[2]} {words[3]}
                    </div>
                  </>
                ) : (
                  <div className="medicinecategorytext">
                    {item.medicinecategory}
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
        <Grid container xs={12} mt={10} mb={isMidScreen ? 10 : 5}>
          <div className="spacebutton">
            <div>
              <button className="previous" onClick={() => navigate("/app/pages/users/medicine")}>Previous</button>
            </div>
            <div>
              <button className="next" onClick={handleButtonClick}>
                Next
              </button>
            </div>
          </div>
        </Grid>
      </div>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Medicines" /> : <></>}
    </div>
  );
};

export default SelectMedicineCategory;
