import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples

import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import swal from "sweetalert";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Formik, Form, Field, ErrorMessage } from "formik";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDButton from "components/MDButton";

import Button from "@mui/material/Button";
import { MoonLoader } from "react-spinners";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from "@mui/material";

import { FaRegTimesCircle } from "react-icons/fa";
import Select from "@mui/material/Select";

import CancelIcon from "@mui/icons-material/Cancel";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

import MDDatePicker from "components/MDDatePicker";
import Prescription from "./Prescription";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import OfflinePrescription from "./OfflinePrescription";
import ReferPatientPopup from "components/ReferPatientPopup";
import Appointment from "../PatientNurseshared/doctorsExpanded/appointment/appointment";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import VitalsPopup from "../Search Patient/VitalsPopup";
import { useDispatch } from "react-redux";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import profilePicture from "../../../assets/images/products/No-data-found.png";
import PrescriptionForm from "components/prescriptionForm";
import { capitalizeWords } from "static/methods/capitalizedWords";

const PatientQueue = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [openline, setOpenLine] = React.useState(false);
  const [updatestatus, setUpdatestatus] = React.useState(false);

  const [searched, setSearched] = useState(null);
  const [doctorList, setDoctorList] = useState("");
  const [aggregiatepatient, setAggregiatepatient] = useState("");
  const [idCounter, setIdCounter] = useState(0);
  const [uploadresponse, setUploadresponse] = useState([]);

  const [treatedlist, setTreatedlist] = useState("");
  const [diseaseList, setDiseaseList] = useState("");
  const [medList, setmedList] = useState([]);
  const [prescriptionvalue, setPrescriptionvalue] = useState({});
  const [frequencyList, setFrequencyList] = useState([]);
  const [chiefcomplaint, setChiefcomplaint] = useState("");
  const [remark, setRemark] = useState("");

  const [openprescrption, setOpenprescrption] = useState(false);
  const [selecttreated, setSelecttreated] = useState(null);
  const [patientId, setPatientId] = useState();

  const [visitid, setVisitid] = useState("");
  const [loginUser, setLoginUser] = useState(
    JSON.parse(localStorage.getItem("login-auth"))
  );
  const [profileId, setProfileId] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );
  const [data, setData] = useState([]);
  const [mmucode, setMmucode] = useState("hello");
  const [color] = useState("#344767");

  const [item, setItem] = useState({});
  const [patientresponse, setPatientresponse] = useState([]);
  const [frequencydata, setFrequencydata] = useState(null);
  const [medicineData, setMedicineData] = useState(null);
  const [openoflinepres, setOpenoflinepres] = useState(false);
  const [getApiResponse, setApiResponse] = useState([]);
  const [selecteddisease, setSelectedDisease] = useState([]);
  const [startDate, setStartDate] = useState();
  const [finalLabtest, setFinalLabtest] = useState([]);

  const [createdBy, setCreatedBy] = useState(
    JSON.parse(localStorage.getItem("login-auth"))?.profileid
  );
  const [openRefer, setOpenRefer] = useState(false);
  const [patientData, setPatientData] = useState();

  const [uom, setUom] = useState("");
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getUserCategory } = useSelector(state => state);

  const handleChildData = (data) => {
    setUploadresponse(data);
  };
  const dispatch = useDispatch()

  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const initialValues = {
    medicine: "",
    uom: "",
    duration: "",
    dose: "",
    frequency: "",
  };
  const validationSchema = Yup.object({
    medicine: Yup.string().required("Medicine is required"),
    uom: Yup.string().required("uom is required"),
    frequency: Yup.string().required("Frequency is required"),
    duration: Yup.string().required("Duration is required"),
    dose: Yup.string().required("Dose is required"),
  });

  const completePrescrptionObject = {
    additionalnotes: remark,
    age: aggregiatepatient?.patient?.age,
    chiefcomplaint: chiefcomplaint,
    diagnosistext: remark,
    createdby: createdBy,
    diagnosiscategory: "",
    doctor: loginUser?.name,
    diseaselist: selecteddisease,
    history: "",
    id: null,
    labtestlist: finalLabtest,
    medicinelist: data,
    mmucode: profileId,
    name: aggregiatepatient?.patient?.name,
    nextfollowupdate: new Date(startDate),
    parentpresid: "",
    patientid: aggregiatepatient?.patient?.patientid,
    prestype: "",
    reasontoreferal: "",
    recstatus: "PRESCRIBED",
    refereddoctor: "",
    status: "ACTIVE",
    syncstatus: "SYNCED",

    visitData: aggregiatepatient?.visit,
    visitid: aggregiatepatient?.visit?.visitid,
  };

  const handleSubmit = (values, { resetForm }) => {

    const newData = {
      uid: idCounter,
      countperday: frequencydata?.countperday,
      createdby: createdBy,
      //   documentofproof: "",
      dosage: values?.medicine,
      dose: values?.dose,
      duration: values?.duration,
      //   form: "",
      frequency: values?.frequency,
      id: null,
      //   medicineid: medicineData?.id,
      medicinename: values?.medicine,
      mmucode: mmucode,
      quantity: values?.dose * values?.duration * frequencydata?.countperday,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "NOT_SYNCED",
      tempid: "",
      uom: values.uom,
      visitid: aggregiatepatient?.visit?.visitid,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    resetForm();
    setFrequencydata(null);

  };
  console.log("data", data)
  const removeData = (id) => {
    const newData = data.filter((item) => item?.uid !== id);
    setData(newData);
  };
  const columns = [
    { Header: "medicine", accessor: "dosage" },
    { Header: "Uom", accessor: "" },
    { Header: "frequency", accessor: "frequency" },
    { Header: "dose", accessor: "dose" },
    { Header: "duration", accessor: "duration" },
    { Header: "quantity", accessor: "quantity" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        // <button onClick={() => removeData(row.values.id)}>Remove</button>
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row?.values?.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];

  // async function bookingUpdatestatuschange(visitid) {
  //   await axios
  //     .put(`booking-service/api/Booking/v1/updateV2?visitid=${visitid}`, {
  //       recstatus: "PRESCRIBED",
  //     })
  //     .then((response) => {

  //     })
  //     .catch((e) => {
  //       console.log("error", e);
  //       setIsLoading(false);
  //     });
  // }

  async function createPrescription(object) {
    setIsLoading(true);

    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setIsLoading(false);
    }
    // else if (
    //   object &&
    //   object?.nextfollowupdate instanceof Date &&
    //   !isNaN(object?.nextfollowupdate)
    // )
    else {
      await axios
        .post(
          `aggregation-service/api/Aggregation/v1/createPrescription`,
          object
        )
        .then((response) => {
          bookingUpdatestatuschange(visitid);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
    // else {
    //   swal("Oops", "Please select followup Date first!", "error");
    //   setIsLoading(false);
    // }
  }
  const handleSubmitPrescription = () => {
    setFinalLabtest([]);
    for (let i = 0; i < selectedTestlist?.length; i++) {
      const labTestObj = {
        createdby: createdBy,
        detailedresult: null,
        id: null,
        investigationlist: selectedTestlist[i]?.investigationlist,

        mmucode: profileId,
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "NOT_SYNC",
        testid: selectedTestlist[i]?.id,
        testname: selectedTestlist[i]?.testname,
        testresult: "",
        visitid: aggregiatepatient?.visit?.visitid,
      };
      finalLabtest.push(labTestObj);
    }

    for (let i = 0; i < completePrescrptionObject?.medicinelist?.length; i++) {
      delete completePrescrptionObject?.medicinelist[i]?.uid;
    }
    completePrescrptionObject.visitData.recstatus = "PRESCRIBED";
    createPrescription(completePrescrptionObject);
  };

  // const defaultPropsdisease = {
  //   options: !diseaseList ? [{ name: "Loading...", id: 0 }] : diseaseList,
  //   getOptionLabel: (option) => option.name,
  //   isOptionEqualToValue: (option, value) => option.id === value.id,
  // };
  // const defaultPropsmediciene = {
  //   options: !medList ? [{ name: "Loading...", id: 0 }] : medList,
  //   getOptionLabel: (option) => option.name,
  //   isOptionEqualToValue: (option, value) => option.id === value.id,
  // };
  // const defaultPropsfrequency = {
  //   options: !frequencyList ? [{ name: "Loading...", id: 0 }] : frequencyList,
  //   getOptionLabel: (option) => option.description,
  //   isOptionEqualToValue: (option, value) => option.id === value.id,
  // };
  async function createPrescription2(data) {
    console.log("createpre", data);
    setIsLoading(true);
    await axios
      .post(`aggregation-service/api/Aggregation/v1/createPrescription`, data)
      .then((response) => {
        setIsLoading(false);
        bookingUpdatestatuschange(data?.visitData?.visitid);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
      });
  }

  function offlineprescription(aggregiatepatient) {
    // Set loading to true initially
    setIsLoading(true);

    swal({
      title: "The prescription status is going to be updated.",
      text: "Are you sure you want to continue?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        // Function to execute when user confirms deletion
        swal({
          title: "Processing",
          text: "Please wait while we process your request...",
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
          closeOnEsc: false,
        });

        const data = {
          additionalnotes: remark,
          age: aggregiatepatient?.patient?.age,
          chiefcomplaint: chiefcomplaint,
          diagnosistext: remark,
          createdby: createdBy,
          diagnosiscategory: "",
          doctor: loginUser?.name,
          history: "",
          id: null,
          labtestlist: finalLabtest,
          medicinelist: [],
          mmucode: profileId,
          name: aggregiatepatient?.patient?.name,
          nextfollowupdate: new Date(startDate),
          parentpresid: "",
          patientid: aggregiatepatient?.patient?.patientid,
          prestype: "",
          reasontoreferal: "",
          recstatus: "PRESCRIBED",
          refereddoctor: "",
          status: "ACTIVE",
          syncstatus: "SYNCED",

          visitData: aggregiatepatient?.visit,
          visitid: aggregiatepatient?.visit?.visitid,
        };
        data.visitData.recstatus = "PRESCRIBED";
        (data.documentofproof =
          uploadresponse.length === 0
            ? null
            : uploadresponse[uploadresponse.length - 1]?.docid),
          createPrescription2(data)
            .then(() => {
              setIsLoading(false); // Reset loading state after successful prescription creation
              swal({
                title: "Success",
                text: "Prescription Refill Processed Successfully!",
                icon: "success",
              });
            })
            .catch((error) => {
              console.error("Error creating prescription:", error);
              setIsLoading(false); // Reset loading state in case of error
              swal({
                title: "Error",
                text: error.response.data.message, // Show the API error message
                icon: "error",
              });
            });
      } else {
        swal("Your Prescription file is safe!");
        setIsLoading(false); // Reset loading state if user cancels action
      }
    });
  }



  //search button=======
  const requestSearch = (keyData) => {
    //setSearched(key);
    const searchdata = patientresponse.filter((key) => {
      if (
        key?.patient?.name?.toLowerCase().includes(keyData.toLowerCase()) ||
        key?.patient?.mobileno?.includes(keyData) ||
        key?.patient?.patientid?.toLowerCase().includes(keyData.toLowerCase())
      ) {
        return key;
      }
    });
    if (keyData === null || keyData?.length === 0) {
      setPatientresponse(getApiResponse);
    } else setPatientresponse(searchdata);
  };
  async function getpatient() {
    setLoading(true);

    await axios
      .get(
        `aggregation-service/api/Aggregation/v1/get?mmucode=${profileId}&recstatus=ASSIGNED`

        // `http://45.130.104.65:9137/api/Aggregation/v1/get?mmucode=${profileId}&recstatus=OPEN`
      )
      .then((response) => {
        response?.data?.data?.visitAggregationDTOList?.map((item) => { });
        setPatientresponse(response.data?.data?.visitAggregationDTOList);
        setApiResponse(response.data?.data?.visitAggregationDTOList);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  const defaultPropsTreated = {
    options: !treatedlist
      ? [
        { name: "TREATED", code: "X" },
        { name: "TREATMENT_IN_PROGRESS", code: "y" },
      ]
      : treatedlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };
  const [testlist, setTestlist] = useState();
  const [selectedTestlist, setSelectedTestlist] = useState([]);
  // const defaultPropsLabTest = {
  //   options: !testlist ? [{ name: "Loading", id: "X" }] : testlist,
  //   getOptionLabel: (option) => option.name,
  //   isOptionEqualToValue: (option, value) => option.id === value.id,
  // };
  async function bookingUpdatestatuschange(visitid) {
    setIsLoading(true);
    await axios
      .put(`booking-service/api/Booking/v1/updateV2?visitid=${visitid}`, {
        recstatus: "PRESCRIBED",
        documentofproof:
          uploadresponse.length === 0
            ? null
            : uploadresponse[uploadresponse.length - 1]?.docid,
      })
      .then((response) => {
        swal("Sucess!", "Prescribed successfully.");
        setIsLoading(false);
        handleClose();
        getpatient();
      })
      .catch((e) => {
        console.log("error", e);
        setIsLoading(false);
      });
  }
  function getPrescriptionData(item) {
    setAggregiatepatient(item);
    // getDiseaseData();
    setVisitid(item?.visit?.visitid);
  }

  const handleClose = () => {
    setData([]);
    setOpen(false);
  };
  // setUpdatestatus handle close====================

  const handleCloseupdatestatus = () => {
    setUpdatestatus(false);
  };
  const handleCloseline = () => {
    setOpenLine(false);
  };
  const handleSubmitwithdoc = (prescriptionvalue) => {
    setOpenoflinepres(false);
    offlineprescription(prescriptionvalue);
  };
  const handleSubmitwithoutdoc = (prescriptionvalue) => {
    setOpenoflinepres(false);
    offlineprescription(prescriptionvalue);
  };

  const handleClosepres = () => {
    setOpenoflinepres(false);
    setUploadresponse("");
  };

  useEffect(() => {
    getpatient();
    dispatch(userCategoryAction({ profileid:auth.profileid }))
  }, []);

  // ---------------------------------------- refer patient ------------------------------------------------------------
  const [categories, setCategories] = useState([]);
  const [doctors, setDoctors] = useState([]);

  const handleOpenRefer = (values) => {
    setPatientData(values);
    getDoctors();
    getCategories();
    setOpenRefer(true);
  };

  const handleCloseRefer = () => {
    setOpenRefer(false);
  };

  const passData = () => {
    getpatient();
  }

  const getCategories = async () => {
    await axios
      .get(
        "/category-service/api/Category/v1/getWithHierarchy?depth=2&hierarchicalcode="
      )
      .then((response) => {
        setCategories(response.data.data.dtoList);
      })
      .catch((error) => console.log(error, "Category error."));
  };

  const getDoctors = async () => {
    await axios
      .get("/summary-service-mongo/api/summary/v2/get?type=doctor")
      .then((response) => {
        setDoctors(response.data.data.dtoList);
      })
      .catch((error) => console.log(error, "Get doctors error."));
  };

  // ------------------------------------- Create follow up ---------------------------------------------------------

  const [showAppointments, setShowAppointments] = useState(false);
  const [date, setDate] = useState("");
  const [appointmentData, setAppointmentData] = useState();
  const [slot, setSlot] = useState();

  const handleAssignFollowUp = (event) => {
    setShowAppointments(event.target.checked);
    console.log(event.target.checked, "handleAssignFollowUp event 597");
  };

  const updateData = (val) => {
    setDate(val);
  };

  const handleAppointment = (dataFromGrandchild, value) => {
    setSlot(value);
    setAppointmentData(dataFromGrandchild);
  };

  const handleFollowUp = (visit) => {
    setIsLoading(true);
    const getData = {
      approvedby: "",
      approveddate: "",
      approver: "string",
      approvertype: "string",
      classname: "string",
      pricelist: null,
      code: "string",
      createdby: auth.profileid,
      createddate: "",
      doctor: auth,
      profileid: auth.profileid,
      documentofproof: "string",
      id: null,
      latlong: "string",
      location: "",
      modifiedby: "string",
      modifieddate: "",
      type: "booking",
      ordernumber: "string",
      paymentmethod: "Pay on Counter",
      patient: aggregiatepatient.patient,
      slot: slot,
      recstatus: "SCHEDULED",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      visit: visit,
      visitid: visit?.visitid

    };

    axios
      .post("booking-service/api/Booking/v1/create", getData)
      .then((response) => {
        updateVisit(visit?.visitid)
        const roomid = uuidv4().split("-").slice(0, 3).join("-");
        const roominfoobj = {
          bookingid: response?.data?.data?.dto?.ordernumber,
          doctorid: response?.data?.data?.dto?.doctor?.profileid,
          id: null,
          link: `https://softalk.softwaremathematics.com/join/${roomid}`,
          profileid: auth.profileid,
          recstatus: "OPEN",
          roomid: roomid,
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        roomIdGeneration(roominfoobj);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };
  const handlePrescription = () => {
    if (showAppointments) {
      createVisit();
    } else {
      handleSubmitPrescription();
    }
  };

  const createVisit = async () => {
    setIsLoading(true);
    let body = {
      createdby: auth.profileid,
      createdbyname: auth.name,
      doctorid: auth.profileid,
      mmucode: auth.profileid,
      visitcategory: "ONLINE",
      paymenttype: "PAID",
      patientid: aggregiatepatient.patient.patientid,
      profileid: aggregiatepatient.patient.profileid,
      recstatus: "SCHEDULED",
      status: "ACTIVE",
      visittype: "FOLLOW_UP",
      doctorname: auth.doctorname,
      address: "",
      parentvisitid: aggregiatepatient.visit.visitid,
      childvisitid: "",
    };

    await axios.post(`${APIs.visitServiceApi}/create`, body)
      .then(response => {
        console.log(response, "create new refer visit response 118")
        handleFollowUp(response.data.data.dto)
        // updateVisit( response.data.data.dto.visitid)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  const updateVisit = async (childId) => {
    const body = {
      visitid: aggregiatepatient.visit.visitid,
      id: aggregiatepatient.visit?.id,
      patientid: aggregiatepatient.patient.patientid,
      profileid: aggregiatepatient.patient.profileid,
      doctorid: auth.profileid,
      mmucode: auth.profileid,
      visittype: "FOLLOW_UP",
      visitcategory: aggregiatepatient.visit.visitcategory,
      paymenttype: "PAID",
      modifiedby: auth.profileid,
      modifiedbyname: auth?.name,
      recstatus: "COMPLETED",
      syncstatus: "NOT_SYNCED",
      status: "ACTIVE",
      parentvisitid: "",
      childvisitid: childId,
    };

    await axios
      .put(`${APIs.visitServiceApi}/update`, body)
      .then((response) => {
        handleSubmitPrescription();
        console.log(response, "update visit response 150");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  async function roomIdGeneration(roominfo) {
    try {
      const response = await axios.post(
        "room-id-generation-service/api/RoomIdGeneration/v1/create",
        roominfo
      );

      // return response;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  }

  // -------------------------------------------- create or update vitals ------------------------------------------------

  const [patientVitals, setPatientVitals] = useState();
  const [vitalsMasterList, setVitalsMasterList] = useState([]);
  const [openvitals, setOpenvitals] = useState(false);

  const handleCreateVitals = (values) => {
    setOpenvitals(true);
    setIsLoading(true)
    // getPatientVitals(values.visitid)
    getVitalMaster(values.visit.visitid);
    setPatientData(values.visit);
  };

  async function getVitalMaster(visitId) {
    await axios
      .get(`${APIs.vitalsMasterServiceApi}/get`)
      .then((response) => {
        // setVitalsMasterList(response?.data?.data?.dtoList);
        getPatientVitals(visitId, response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  const getPatientVitals = async (visitId, masterList) => {
    await axios
      .get(`${APIs.vitalsServiceApi}/get?visitid=${visitId}`)
      .then((response) => {
        let temp = response.data.data.dtoList;
        if (temp.length > 0) {
          const updatedList = masterList.map((item, index) => ({
            ...item,
            value: temp[index]?.value,
            id: temp[index]?.id,
            createdby: temp[index]?.createdby,
          }));
          setVitalsMasterList(updatedList);
          // setVitalsMasterList(temp);
          // setOpenvitals(true);
          setIsLoading(false)
        } else {
          setVitalsMasterList(masterList);
          setIsLoading(false)
          // setOpenvitals(true);
        }
        setPatientVitals(response.data.data.dtoList)
      }).catch(error => {
        setIsLoading(false)
        // setOpenvitals(true);
      })
  }

  const getUserCategoryNameByValue = (value) =>{
    for(let i=0; i<getUserCategory.visitCategory.length; i++){
      if(getUserCategory.visitCategory[i]?.category?.value === value){
        return capitalizeWords(getUserCategory.visitCategory[i]?.category?.name)
      }
    }
    return "";
  }

  return (
    <>
      {/* <UpaarNavbar /> */}
      <MDBox pt={3}>
        <MDBox mt={16}>
          <Card style={{ marginLeft: "25px", marginRight: "25px" }}>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Patient Queue
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                    <MDInput
                      placeholder="Search..."
                      value={searched}
                      onChange={(e) => {
                        requestSearch(e.target.value);
                        setSearched(e.target.value);
                      }}
                    />
                  </MDBox>
                  <></>
                </Grid>
              </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            /> 

            <div>
              {patientresponse?.length === 0 && !loading ? (
                  <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
                  <img
              src={profilePicture}
              alt="Brooklyn Alice"
              style={{ 
                width: '100%', 
                maxWidth: '200px', 
                height: 'auto', 
               
              }} 
            />
          
                 
                       </MDBox>
              ) : (
                <>
                  {patientresponse?.map((values) => (
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          <div style={{ fontSize: "15px", color: "#7b809a" }}>
                            <div>
                              <text style={{ fontWeight: "bold" }}>Name :</text>
                              {" " + values?.patient?.name}
                            </div>
                            <div>
                              <text style={{ fontWeight: "bold" }}>Age :</text>
                              {" " + values?.patient?.age}
                            </div>
                            <div>
                              <text style={{ fontWeight: "bold" }}>
                                Mobile no :
                              </text>
                              {" " + values?.patient?.mobileno}
                            </div>

                            <div>
                              <text style={{ fontWeight: "bold" }}>
                                Patient Id. :
                              </text>
                              {" " + values?.patient?.patientid}
                            </div>

                            <div>
                          <text style={{ fontWeight: "bold" }}>
                            Category :
                          </text>
                          {" " + values?.visit?.category===null?"" : values?.visit?.category}
                        </div>

                            <div>
                          <text style={{ fontWeight: "bold" }}>
                            Visit Type :
                          </text>
                          {" " + getUserCategoryNameByValue(values?.visit?.visitcategory)}
                        </div>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={6} md={2}>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setOpen(true);
                                getPrescriptionData(values);
                              }}
                            >
                              Prescription
                            </MDButton>
                          </Grid>
                          <Grid item xs={12} sm={6} md={2}>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => {
                                setPrescriptionvalue(values);
                                // offlineprescription(values);
                                setOpenoflinepres(true);
                              }}
                            >
                              Upload Prescription
                            </MDButton>
                          </Grid>

                          <Grid item xs={12} sm={6} md={2}>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => handleOpenRefer(values)}
                            >
                              Refer
                            </MDButton>
                          </Grid>

                          <Grid item xs={12} sm={6} md={2}>
                            <MDButton
                              variant="gradient"
                              color="info"
                              size="small"
                              type="submit"
                              onClick={() => handleCreateVitals(values)}
                            >
                              Vitals
                            </MDButton>
                          </Grid>


                          {values?.prescriptionlist.length !== 0 && (
                            <Grid item xs={12} sm={6} md={2}>
                              <MDButton
                                variant="gradient"
                                color="info"
                                size="small"
                                type="submit"
                                onClick={() => {
                                  setOpenprescrption(true);
                                  // console.log("hello")
                                  setPatientData(values)

                                  // historyData(values?.patient);
                                  // setOpenhistory(true);
                                }}
                              >
                                View History
                              </MDButton>
                            </Grid>
                          )

                          }

                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </>
              )}

            </div>
          </Card>
        </MDBox>
      </MDBox>

      {/* Prescription popup================================================= */}
      {aggregiatepatient && (
        <>
        <PrescriptionForm 
          open={open} handleClose={handleClose} aggregiatepatient={aggregiatepatient}
          setSelectedDisease={setSelectedDisease} setChiefcomplaint={setChiefcomplaint} chiefcomplaint={chiefcomplaint}
          setRemark={setRemark} remark={remark} handleSubmit={handleSubmit} validationSchema={validationSchema} initialValues={initialValues}
          frequencyList={frequencyList} setFrequencydata={setFrequencydata} columns={columns} data={data}
          setSelectedTestlist={setSelectedTestlist} handleAssignFollowUp={handleAssignFollowUp} showAppointments={showAppointments}
          updateData={updateData} handleAppointment={handleAppointment} isLoading={isLoading} handlePrescription={handlePrescription}
         />
        {/* <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
          <DialogTitle
            style={{
              backgroundColor: "#039147",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Prescription
          </DialogTitle>
          <DialogContent>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                border: "1px solid #727375",
                borderRadius: "5px",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Grid item container spacing={1} style={{ margin: "2px" }}>
                <Grid
                  item
                  xs={12}
                  sm={11}
                  sx={{ display: "grid", placeItems: "center" }}
                >
                  <MDTypography variant="h6" fontWeight="medium">
                    Patient ID : {aggregiatepatient?.patient?.patientid}
                  </MDTypography>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Name"
                  size="large"
                  value={aggregiatepatient?.patient?.name}
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Gender"
                  size="large"
                  value={aggregiatepatient?.patient?.gender}

                // multiline
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <MDInput
                  disabled="true"
                  label="Age"
                  size="large"
                  value={aggregiatepatient?.patient?.age}
                />
              </Grid>
            </Grid>

            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  defaultValue={[]}
                  onChange={(event, newValue) => {
                    const selectedNames = newValue?.map(item => item?.name);


                    setSelectedDisease(selectedNames);
                  }}
                  {...defaultPropsdisease}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search Disease"
                      placeholder="Search..."
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  style={{ width: "100%" }}
                  label="chief complaint"
                  size="medium"
                  type="text"
                  value={chiefcomplaint}
                  onChange={(e) => setChiefcomplaint(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MDInput
                  style={{ width: "100%" }}
                  label="Remark"
                  size="medium"
                  type="text"
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={12}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => (
                  <Form>
                    <Grid container spacing={3} mt={1}>
                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Medicine
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="text"
                            name="medicine"
                            variant="standard"
                            value={values.medicine}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("medicine", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="medicine"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              UOM
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="text"
                            name="medicine"
                            variant="standard"
                            value={values.uom}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("uom", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="uom"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <InputLabel
                            htmlFor="frequency"
                            style={{
                              marginLeft: "-14px",
                              paddingBottom: "2px",
                              marginTop: "2px",
                            }}
                          >
                            Frequency:-times/day{" "}
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </InputLabel>
                          <Select
                            native
                            variant="standard"
                            style={{ width: "100%" }}
                            name="frequency"
                            id="frequency"
                            value={values.frequency}
                            onChange={(e) => {
                              const selectedType = e.target.value;
                              const selectedFrequency = frequencyList.find(
                                (obj) => obj.description === e.target.value
                              );
                              setFrequencydata(selectedFrequency);
                              setFieldValue("frequency", selectedType);
                            }}
                            error={
                              touched.frequency && Boolean(errors.frequency)
                            }
                          >
                            <option value=""></option>

                            {frequencyList.map((obj) => (
                              <option key={obj.id} value={obj.description}>
                                {obj.description}
                              </option>
                            ))}
                          </Select>
                          <ErrorMessage
                            name="frequency"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Dose:quantity per time
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="number"
                            name="dose"
                            variant="standard"
                            value={values.dose}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("dose", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="dose"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={4}>
                        <FormControl fullWidth>
                          <TextField
                            label=<span>
                              {" "}
                              Duration
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            type="number"
                            name="duration"
                            variant="standard"
                            value={values.duration}
                            onChange={(e) => {
                              const nameValue = e.target.value;
                              setFieldValue("duration", nameValue);
                            }}
                          />

                          <ErrorMessage
                            name="duration"
                            component="div"
                            style={{ color: "red", fontSize: "12px" }}
                          />
                        </FormControl>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sm={12}
                        display="flex"
                        justifyContent="flex-end"
                      >
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          size="small"
                        >
                          Add
                        </MDButton>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                      />
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  multiple
                  id="tags-outlined"
                  defaultValue={[]}
                  onChange={(event, newValue) => {
                    console.log("909", newValue);
                    setSelectedTestlist(newValue);
                  }}
                  {...defaultPropsLabTest}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Lab-Test"
                      placeholder="Select"
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showAppointments}
                      onChange={handleAssignFollowUp}
                    />
                  }
                  label="Assign Follow-Up"
                />
              </FormGroup>
              <Grid px={8} alignItems="center">
                {showAppointments && (
                  <Appointment
                    onGrandchildData={handleAppointment}
                    doctorprofileid={auth?.profileid}
                    updateData={updateData}
                  />
                )}
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Grid container spacing={3} style={{ marginTop: ".1px" }}>
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  marginTop: "7px",
                  marginRight: "7px",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="submit"
                  disabled={isLoading}
                  onClick={() => {
                    handlePrescription();
                  }}
                >
                  {isLoading ? (
                    <MoonLoader color="#f2fefa" size={16} />
                  ) : (
                    "Submit Prescription"
                  )}
                </MDButton>
              </Grid>

            </Grid>
          </DialogActions>
        </Dialog> */}
        </>
      )}

      {/* create vitlas popup================================================= */}

      <Dialog open={updatestatus} onClose={handleCloseupdatestatus}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <DialogTitle style={{ textAlign: "centre" }}>
              Update status
            </DialogTitle>
          </Grid>
          <Grid item xs={12} sm={2} style={{ marginTop: "12px" }}>
            <span>
              <Link to={{}}>
                <CancelIcon
                  style={iconStyle}
                  onClick={handleCloseupdatestatus}
                />
              </Link>
            </span>
          </Grid>
        </Grid>
        <DialogContent style={{ width: 350, height: 150 }}>
          <Grid item xs={12} sm={9} style={{ marginTop: "2px" }}>
            <Autocomplete
              {...defaultPropsTreated}
              value={selecttreated}
              onChange={(event, newValue) => {
                // handleChange(event);
                item.type = newValue.name;

                setSelecttreated(newValue);
                console.log("515", selecttreated);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Treated" />
              )}
            />
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "2px" }}>
            <Grid item xs={12} sm={6}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                type="submit"
              >
                Update
              </MDButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MDButton
                style={{ marginLeft: "-47px" }}
                variant="gradient"
                color="info"
                size="small"
                type="submit"
              >
                Refer
              </MDButton>
              {/* popup view history============================= */}
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Prescription
        tittle="View History"
        openprescrption={openprescrption}
        setOpenprescrption={setOpenprescrption}
        data={patientData}
      />


      <Footer />
      <Dialog
        open={openoflinepres}
        onClose={handleClosepres}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          Upload Prescription
        </DialogTitle>

        <DialogContent>
          <OfflinePrescription onData={handleChildData} />
        </DialogContent>

        <DialogActions>
          <MDButton
            size="small"
            variant="outlined"
            color="info"
            onClick={handleClosepres}
          >
            Cancel
          </MDButton>
          {uploadresponse.length > 0 ? (
            <MDButton
              size="small"
              variant="outlined"
              color="info"
              onClick={() => handleSubmitwithdoc(prescriptionvalue)}
            >
              Save With Document
            </MDButton>
          ) : (
            <MDButton
              size="small"
              variant="outlined"
              color="info"
              onClick={() => handleSubmitwithoutdoc(prescriptionvalue)}
            >
              Save Without Document
            </MDButton>
          )}
        </DialogActions>
      </Dialog>

      {/* ------------------------------ Refer Patient popup ------------------------------------------- */}
      <ReferPatientPopup
        title="Refer Patient"
        openRefer={openRefer}
        categories={categories}
        doctors={doctors}
        patientData={patientData}
        handleCloseRefer={handleCloseRefer}
        passData={passData}
      />

      {/* ---------------------------------- vitals popup --------------------------------------------------- */}
      <VitalsPopup
        openvitals={openvitals}
        setOpenvitals={setOpenvitals}
        patientVitals={patientVitals}
        vitalsMasterList={vitalsMasterList}
        setVitalsMasterList={setVitalsMasterList}
        bookingPatient={patientData}
        isLoading={isLoading}
      />
    </>
  );
};

export default PatientQueue;
