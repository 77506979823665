/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

function Header(props) {
 

  const dateTimeString = props?.datetime;

// Extracting date
const date = dateTimeString?.slice(0, 10);

// Extracting time
const time = dateTimeString?.slice(11, 19);

// Converting time to AM/PM format
const formattedTime = new Date(dateTimeString)?.toLocaleString('en-US', {
  hour: 'numeric',
  minute: 'numeric',
  hour12: true,
  timeZone: 'UTC'
});


  
  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="center">
      <MDBox>
        <MDBox mb={1}>
          <MDTypography variant="h6" fontWeight="medium">
            Order Details
          </MDTypography>
        </MDBox>
        <MDTypography component="p" variant="button" color="text">
          Order no.&nbsp;<b>{props?.orderNo}&nbsp;</b>from&nbsp;
          <b>{date}</b>
        </MDTypography>
        <MDTypography component="p" variant="button" fontWeight="regular" color="text">
          Time: {formattedTime}
        </MDTypography>
      </MDBox>
      <MDButton variant="gradient" color="info">
        invoice
      </MDButton>
    </MDBox>
  );
}

export default Header;
