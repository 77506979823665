import React from "react";
import doctor from "../../../../../assets/svg/Double-end.svg";
import { Grid } from "@mui/material";
import "./whatUserSay.css";
const WhatUserSay = () => {
  return (
    <div className="WhatUserSaycontainer">
      <Grid item>
        <img className="WhatUserSayimg" src={doctor} />
      </Grid>
      <Grid className="WhatUserSayuser">What our users say?</Grid>
      <Grid item xs={6} className="WhatUserSaydes">
      This app is really flawless. Good for eldery
      people. The best part of the app is the
      home delivery of discounted price.
  </Grid>
      <Grid className="WhatUserSayVimdhayak">
      Vimdhayak.J
      </Grid>
      <Grid className="WhatUserSaygggg">
      Ordered medicine through medharva site
      </Grid>
    </div>
   
  );
};
 
export default WhatUserSay;