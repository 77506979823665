import { Grid } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./uploadPrescription.css";
import BiotechIcon from "@mui/icons-material/Biotech";
import PeopleIcon from "@mui/icons-material/People";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonNearTruck from "../../findDoctor/assets/images/personneartruck.png";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import MedharvaIcon from "../../findDoctor/assets/images/Medharva-logo.svg";
import { useNavigate } from "react-router-dom";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";

const UploadPrescription = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 1286);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 700);
      setIsMidScreen(window.innerWidth <= 1286);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const data = [
    {
      icon: BiotechIcon,
      about: "NABL- accredited labs for aaccurate results",
    },
    {
      icon: PeopleIcon,
      about: "Lab tests at home in 60 seconds",
    },
    {
      icon: AccessTimeIcon,
      about: "Online lab reports in 6 hours*",
    },
  ];

  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      if (
        selectedFile.type === "image/jpeg" ||
        selectedFile.type === "image/png"
      ) {
        if (selectedFile.size <= 20 * 1024 * 1024) {
          console.log("Selected File:", selectedFile.name);
        } else {
          console.error("File size exceeds the limit. Max size: 20MB");
          alert("File size exceeds the limit. Max size: 20MB");
        }
      } else {
        console.error("Invalid file type. Only JPG and PNG are allowed.");
        alert("Invalid file type. Only JPG and PNG are allowed.");
      }
    }
  };

  return (
    <>
      {isMidScreen ? <></> : <Navbar pagelabel="Medicines" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <div className="uploadPrescriptionroot">
        <Grid item pt={23} ml={7} className="uploadPrescriptiontitle">
          Upload Prescription
        </Grid>
        <Grid
          container
          mt={isSmallScreen ? 3 : 5}
          xs={11}
          mb={1}
          ml={isSmallScreen ? 3 : 7}
          mr={isSmallScreen ? 3 : undefined}
          className="uploadPrescriptioncontainer"
        >
          {isMidScreen ? (
            <>
              <Grid item className="uploadPrescriptioncontainer2">
                <Grid item className="uploadPrescriptionitem2">
                  Order Medicines
                  <Grid item className="uploadPrescriptionitem1">
                    Private online consultations with verified doctors in all
                    specialists
                  </Grid>
                </Grid>
              </Grid>
              <Grid container mt={2} className="truckimgarea">
                <img
                  src={PersonNearTruck}
                  alt="person logo"
                  className="uploadPrescriptionitemimg"
                />
                <Grid item ml={3} mt={isSmallScreen ? 3 : undefined}>
                  {data.map((item, index) => (
                    <Grid item xs={10} className="uploadPrescriptionitem4">
                      <Grid item mr={1}>
                        <item.icon fontSize="large" />
                      </Grid>
                      <Grid item className="uploadPrescriptionitemtext">
                        {item.about}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item className="uploadPrescriptioncontainer2">
                <Grid item className="uploadPrescriptionitem2">
                  Order Medicines
                  <Grid item className="uploadPrescriptionitem1">
                    Private online consultations with verified doctors in all
                    specialists
                  </Grid>
                </Grid>
                <Grid container mt={isSmallScreen ? 3 : undefined}>
                  {data.map((item, index) => (
                    <Grid
                      item
                      xs={3}
                      mr={2}
                      className="uploadPrescriptionitem4"
                    >
                      <Grid item mr={1}>
                        <item.icon fontSize="large" />
                      </Grid>
                      <Grid item className="uploadPrescriptionitemtext">
                        {item.about}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              <Grid item>
                <img
                  src={PersonNearTruck}
                  alt="person logo"
                  className="uploadPrescriptionitemimg"
                />
              </Grid>
            </>
          )}
        </Grid>
        <Grid container className="uploadcontainerparent">
          {isSmallScreen ? (
            <>
              <Grid
                item
                mt={5}
                ml={isSmallScreen ? 3 : undefined}
                mr={isSmallScreen ? 3 : undefined}
                className="uploadcontainer2"
              >
                <Grid item>
                  <img
                    src={MedharvaIcon}
                    alt="med icon"
                    className="uploadcontainer2img"
                  />
                </Grid>
                <Grid item className="uploadtext6">
                  Medharva Prescription
                </Grid>
              </Grid>
              <Grid
                item
                mt={5}
                ml={isSmallScreen ? 3 : undefined}
                mr={isSmallScreen ? 3 : undefined}
                className="uploadcontainer"
                onClick={handleFileUpload}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                />
                <Grid item>
                  <FileUploadIcon className="uploadicon" />
                </Grid>
                <Grid item mt={2} className="uploadtext">
                  Drag files to upload
                </Grid>
                <Grid item className="uploadtext2">
                  OR
                </Grid>
                <Grid item className="uploadtext">
                  Browse Files
                </Grid>
                <Grid item className="uploaditem">
                  <span className="uploadtext4">Max file size</span>
                  <span className="uploadtext3"> : 20 Mb</span> <br />
                  <span className="uploadtext4">Supported files:</span>
                  <span className="uploadtext5"> JPG and PNG</span>
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                item
                mt={5}
                className="uploadcontainer"
                onClick={handleFileUpload}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  accept=".jpg, .jpeg, .png"
                  onChange={handleFileChange}
                />
                <Grid item>
                  <FileUploadIcon className="uploadicon" />
                </Grid>
                <Grid item mt={2} className="uploadtext">
                  Drag files to upload
                </Grid>
                <Grid item className="uploadtext2">
                  OR
                </Grid>
                <Grid item className="uploadtext">
                  Browse Files
                </Grid>
                <Grid item className="uploaditem">
                  <span className="uploadtext4">Max file size</span>
                  <span className="uploadtext3"> : 20 Mb</span> <br />
                  <span className="uploadtext4">Supported files:</span>
                  <span className="uploadtext5"> JPG and PNG</span>
                </Grid>
              </Grid>
              <Grid item mt={5} className="uploadcontainer2">
                <Grid item>
                  <img
                    src={MedharvaIcon}
                    alt="med icon"
                    className="uploadcontainer2img"
                  />
                </Grid>
                <Grid item className="uploadtext6">
                  Medharva Prescription
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <div className="navupload">
          <Grid container mt={3} xs={12} mb={isMidScreen ? 10 : 5}>
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() => navigate("/app/pages/users/medicine")}
                >
                  Previous
                </button>
              </div>
              <div>
                <button
                  className="next"
                  onClick={() => navigate("/app/pages/users/get-medicine")}
                >
                  Next
                </button>
              </div>
            </div>
          </Grid>
        </div>
      </div>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Medicines" /> : <></>}
    </>
  );
};

export default UploadPrescription;
