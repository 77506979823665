import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { labAddCartAction } from "redux/Actions/labAddCart";
import { variables } from "static/Variables";

    const newSelectedDate = new Date();
    const formattedDate = newSelectedDate.toLocaleDateString("en-CA");

const initialState = {
  labs: [],
  selectedPrices: [],
  selectedTestType: [],
  date: formattedDate,
  dateNumber: 1,
  homeVisit: false,
  loading: false,
  error: null,
  areaRange: variables.address[2],
  userCoordinate: [],
  searchInput: "",
  selectedPerson: null,
  paymentMethod:"",
  address:null,
  count:0,
  totalPrice:0,
  labtestCode:[]
};

export const getAddLabCartSlice = createSlice({ 
  name: "getAddLabCart",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(labAddCartAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(labAddCartAction.fulfilled, (state, action) => {
        state.loading = false;
        state.labs = action.payload.labs;
        state.selectedPrices = action.payload.selectedPrices;
        state.selectedTestType = action.payload.selectedTestType;
        state.homeVisit = action.payload.homeVisit;
        state.date = action.payload.date;
        state.dateNumber = action.payload.dateNumber;
        state.areaRange = action.payload.areaRange;
        state.userCoordinate = action.payload.userCoordinate;
        state.searchInput = action.payload.searchInput;
        state.selectedPerson = action.payload.selectedPerson;
        state.paymentMethod = action.payload.paymentMethod;
        state.address = action.payload.address;
        state.labtestCode= action.payload.labtestCode;
        state.count= action.payload.count;
        state.totalPrice= action.payload.totalPrice;
        state.error = null; 
      })
      .addCase(labAddCartAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getAddLabCartSlice.reducer; 