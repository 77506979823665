import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { MMUDetailsAction } from "redux/Actions/mmuDetailsAction";

const initialState = {
  data: {},
  loading: false,
  error: null,
};

export const getMMUDetailsSlice = createSlice({
  name: "getMMUDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(MMUDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(MMUDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(MMUDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getMMUDetailsSlice.reducer;