


import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { departmentName, departmentDescrption, department  },
} = checkout;

const validations = [
  Yup.object().shape({
    [departmentName.name]: Yup.string().required(departmentName.errorMsg),
    // [departmentDescrption.name]: Yup.string().required(departmentDescrption.errorMsg),
 
    // [department.name]: Yup.string().required(department.errorMsg),
 
  }),
];

export default validations;
