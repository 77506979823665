/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-role-form",
  formField: {
    
    commissiontype: {
      name: "commissiontype",
      label: "Commission Type",
      type: "select",
      errorMsg: "Commission Type is required.",
    },
    comisssion: {
      name: "commission",
      label: "Commission",   
      type: "number",
      errorMsg: "Commission is required.",
    },
    investmentterm: {
      name: "investmentterm",
      label: "Investment Term(Months)",   
      type: "number",
      errorMsg: "Investment term is required.",
    },
    amount: {
      name: "amount",
      label: "Amount",   
      type: "number",
      errorMsg: "Amount is required.",
    },
    transationNo: {
      name: "transationNo",
      label: "Transation Number",   
      type: "text",
      errorMsg: "Transation Number is required.",
    },
   
    enddate: {
      name: "enddate",
      label: "End Date",
      type: "date",
    
      errorMsg: "End Date is required.",
    },
    startdate: {
      name: "startdate",
      label: "Start Date",
      type: "date",
    
      errorMsg: "Start Date is required.",
    },
  },
};

export default form;
