import LabReport from 'app/lab/labReport/LabReport';
import BookingView from 'app/labTest/labTestList/Bookingview/BookingView';
import HomeSkeleton from 'components/Skeleton/HomeSkeleton';
import React, { useEffect, useState, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';

const Bookinglist = lazy(() => import('components/Shared/bookingList/BookingList'));
const MobileNavbar = lazy(() => import('../../components/navbar/mobileNavbar/MobileNavbar'));
const Navbar = lazy(() => import('../../components/navbar/desktopNavbar/Navbar'));
const LabTestList = lazy(() => import('app/labTest/labTestList/labTestList'));
const PatientPrescriptionlist = lazy(() => import('components/Resuable/PrescriptionList/PatientPrescriptionlist'));
const UploadPrescription = lazy(() => import('components/Resuable/Upload Prescription/UploadPrescription'));
const Familylist = lazy(() => import('components/Resuable/Add Family member/Familylist'));
const AddFamilymember = lazy(() => import('components/Resuable/Add Family member/AddFamilymember'));
const Speciality = lazy(() => import('components/Shared/specialities/Speciality'));
const FinalAppointment = lazy(() => import('components/Resuable/appointment'));
const Detailpage = lazy(() => import('layouts/pages/Details'));
const Prescriptionview = lazy(() => import('components/Resuable/PrescriptionList/Prescriptionview'));
const UserInformation = lazy(() => import('components/UserInformation/userInformation'));
const PatientInfo = lazy(() => import('./PatientInformation/PatientInfo'));
const DoctorsExpanded = lazy(() => import('components/Resuable/PatientNurseshared/doctorsExpanded/doctorsExpanded'));
const SpecificLocation = lazy(() => import('app/labTest/specificLocation/specificLocation'));
const SelectPerson = lazy(() => import('app/labTest/Select Person/selectperson'));
const ReferList = lazy(() => import('./rafer-list'));
const SelectLabTest = lazy(() => import('app/labTest/Select Lab Test/selectlabTest'));
const SelectLab = lazy(() => import('app/labTest/selectLab/selectLab'));
const SelectAddres = lazy(() => import('app/labTest/BookLabTest/SelectAddres'));
const DoctorsPage = lazy(() => import('components/Shared/doctor-bookings/DoctorsPage'));
const HomeMobFooter = lazy(() => import("layouts/pages/home/footer/HomeMobFooter"));
const MedharvaFooter = lazy(() => import("app/findDoctor/medharvaFooter/medharvaFooter"));
const PatientVideoConsultation = lazy(() => import('./videoConsultation/VideoConsultation'));


const PatientHome = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const { slug } = useParams();
 
  useEffect(() => {
      const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 500);
        setIsMidScreen(window.innerWidth <= 900);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

return (
  <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      <Suspense fallback={<HomeSkeleton />}>
          { slug === "video-consultation" ? <></> : (isMidScreen ? <MobileNavbar /> : <Navbar />)}
          { slug === "video-consultation" && <PatientVideoConsultation /> }
          {slug ==="my-booking" && <Bookinglist />}
          {slug ==="my-labtests" && <LabTestList />}
          {slug ==="lab-booking-information" && <BookingView />}
          {slug ==="my-prescriptions" && <PatientPrescriptionlist />}
          {slug ==="refer-list" && <ReferList />}
          {slug ==="prescription-view" && <Prescriptionview />}
          {slug ==="manage-emr" && <UploadPrescription />}
          {slug ==="family-list" && <Familylist />}
          {slug ==="addFamilymember" && <AddFamilymember />}
          {slug ==="doctors-Expanded" && <DoctorsExpanded />}
          {slug ==="profile" && <PatientInfo />}
          {slug ==="doctor-booking" && <DoctorsPage />}
          {slug ==="speciality" && <Speciality />}
          {slug ==="patient-information" && <UserInformation />}
          {slug ==="lab-test-cart" && <SelectLabTest />}
          {slug ==="select-lab" && <SelectLab />}
          {slug ==="final-appointment" && <FinalAppointment />}
          {slug ==="detail-page" && <Detailpage />}
          {slug ==="select-person" && <SelectPerson />}
          {slug ==="specific-location" && <SpecificLocation />}
          {slug ==="selected-address" && <SelectAddres />}
          {slug === "test-report-View" && <LabReport />}
          {isSmallScreen && <HomeMobFooter />}
      </Suspense>
  </div>
)
}

export default PatientHome;






















// import Bookinglist from 'components/Shared/bookingList/BookingList';
// import MobileNavbar from '../../components/navbar/mobileNavbar/MobileNavbar';
// import Navbar from '../../components/navbar/desktopNavbar/Navbar';
// import React, { useEffect, useState } from 'react'
// import { useParams } from 'react-router-dom';
// import LabTestList from 'app/labTest/labTestList/labTestList';
// import PatientPrescriptionlist from 'components/Resuable/PrescriptionList/PatientPrescriptionlist';
// import UploadPrescription from 'components/Resuable/Upload Prescription/UploadPrescription';
// import Familylist from 'components/Resuable/Add Family member/Familylist';
// import AddFamilymember from 'components/Resuable/Add Family member/AddFamilymember';
// import Speciality from 'components/Shared/specialities/Speciality';
// import FinalAppointment from 'components/Resuable/appointment';
// import Detailpage from 'layouts/pages/Details';
// import Prescriptionview from 'components/Resuable/PrescriptionList/Prescriptionview';
// import UserInformation from 'components/UserInformation/userInformation';
// import PatientInfo from './PatientInformation/PatientInfo';
// import DoctorsExpanded from 'components/Resuable/PatientNurseshared/doctorsExpanded/doctorsExpanded';
// import SpecificLocation from 'app/labTest/specificLocation/specificLocation';

// import SelectPerson from 'app/labTest/Select Person/selectperson';
// import ReferList from './rafer-list';
// import SelectLabTest from 'app/labTest/Select Lab Test/selectlabTest';
// import SelectLab from 'app/labTest/selectLab/selectLab';
// import SelectAddres from 'app/labTest/BookLabTest/SelectAddres';
// import DoctorsPage from 'components/Shared/doctor-bookings/DoctorsPage';
// import HomeMobFooter from "layouts/pages/home/footer/HomeMobFooter";
// import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
// import PatientVideoConsultation from './videoConsultation/VideoConsultation';


// const PatientHome = () => {
//     const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
//     const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
//     const { slug } = useParams();
   
//     useEffect(() => {
//         const handleResize = () => {
//           setIsSmallScreen(window.innerWidth <= 500);
//           setIsMidScreen(window.innerWidth <= 900);
//         };
//         window.addEventListener("resize", handleResize);
//         return () => {
//           window.removeEventListener("resize", handleResize);
//         };
//       }, []);


//   return (
//     <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
//         {/* {isMidScreen ? <MobileNavbar /> : <Navbar />} */}
//         { slug === "video-consultation" ? <></> : (isMidScreen ? <MobileNavbar /> : <Navbar />)}
//         { slug === "video-consultation" && <PatientVideoConsultation /> }
//         {slug ==="my-booking" && <Bookinglist />}
//         {slug ==="my-labtests" && <LabTestList />}
//         {slug ==="my-prescriptions" && <PatientPrescriptionlist />}
//         {slug ==="refer-list" && <ReferList />}
//         {slug ==="prescription-view" && <Prescriptionview />}
//         {slug ==="manage-emr" && <UploadPrescription />}
//         {slug ==="family-list" && <Familylist />}
//         {slug ==="addFamilymember" && <AddFamilymember />}
//         {slug ==="doctors-Expanded" && <DoctorsExpanded />}
//         {slug ==="profile" && <PatientInfo />}
//         {slug ==="doctor-booking" && <DoctorsPage />}
//         {slug ==="speciality" && <Speciality />}
//         {slug ==="patient-information" && <UserInformation />}
//         {slug ==="lab-test-cart" && <SelectLabTest />}
//         {slug ==="select-lab" && <SelectLab />}
//         {slug ==="final-appointment" && <FinalAppointment />}
//         {slug ==="detail-page" && <Detailpage />}
//         {slug ==="select-person" && <SelectPerson />}
//         {slug ==="specific-location" && <SpecificLocation />}
//         {slug ==="selected-address" && <SelectAddres />}
//         {/* {isMidScreen ? <></> : <MedharvaFooter />} */}
//         {isSmallScreen && <HomeMobFooter />}
//     </div>
//   )
// }

// export default PatientHome