import { createSlice } from "@reduxjs/toolkit";
import { createProjectTeam, deleteProjectTeam, getProjectTeam, updateProjectTeam } from "../service/ProjectTeam";





const initialState = {
  projectTeam: [],
  loading: false,
  error: null,
};

export const projectTeamDetail = createSlice({
  name: "projectTeamDetail",
  initialState,
  extraReducers: {
    [createProjectTeam.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProjectTeam.fulfilled]: (state, action) => {
      console.log("state",state.projectTeam)
      state.loading = false;
      state.projectTeam.push(action.payload.data.dto);
    },
    [createProjectTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getProjectTeam.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProjectTeam.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectTeam = action?.payload?.data?.dtoList || [];

    },
    [getProjectTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProjectTeam.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProjectTeam.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.projectTeam = state.projectTeam.filter((projectTeam) => projectTeam.id !== id);
      }
    },
    [deleteProjectTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProjectTeam.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProjectTeam.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.projectTeam.findIndex(projectTeam => projectTeam.id === action.payload.data.dto.id)
      state.projectTeam[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProjectTeam.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default projectTeamDetail.reducer;
