import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

import MDButton from "components/MDButton";

import MDAvatar from "components/MDAvatar";

import MDBadge from "components/MDBadge";

import { useState } from "react";

import axios from "../../../../axiosinstance";

import { MoonLoader } from "react-spinners";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";

// Images

import productImage from "assets/images/products/product-11.jpg";

function OrderInfo(props) {
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={12} md={12}>
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDAvatar size="xxl" src={productImage} alt="Gold Glasses" />
          </MDBox>

          <MDBox lineHeight={1}>
            <MDTypography variant="button" color="text">
              <b>
                Name:{" "}
                {props?.doctorData?.name ===
                localStorage.getItem("profile-Name")?.replace(/"/g, "")
                  ? "Self"
                  : props?.doctorData?.name}
              </b>
            </MDTypography>

            {/* <MDBox mb={2}>
              <MDTypography variant="button" color="text">
                Experience: {props?.doctorData?.experience}
              </MDTypography>
            </MDBox> */}

            {/* <MDBox mb={2}>
              <MDTypography variant="button" color="text">
                Age: {props?.doctorData?.age}
              </MDTypography>
            </MDBox> */}
            <br />
            <br />

            <MDButton variant="gradient" color="warning" size="small">
              ₹{" "}
              {props?.price?.reduce(
                (acc, item) => acc + parseInt(item?.value),
                0
              )}
            </MDButton>
          </MDBox>
        </MDBox>
      </Grid>

      <Grid item xs={12} md={6} sx={{ textAlign: "right" }}></Grid>
    </Grid>
  );
}

export default OrderInfo;
