import React from "react";
import Swal from 'sweetalert2';
import PropTypes from "prop-types";
import SucessLogo from '../../../assets/images/Sucessfull.png';


const SuccessfullPopup = ({ message }) => {
  const handleClick = () => {
    Swal.fire({
      title: `
        <div style="height: 300px;">
            <div style="display: flex; justify-content: center;">
                <img src="${SucessLogo}" alt="Success Logo" style="width: 200px; height: 200px; margin-top:-10%">
            </div>
            <div style="margin-top:-10%;height:84px; ">
            <span style="color: #039147;  width:1056px; font-size:16px; font-weight:500px; font-family:Poppins; text-align:center; line-height:42px; margin-bottom:25px ">${message}</span>
            </div>
        </div>
        `,
      showConfirmButton: false,
      height: 'auto',
      width: '800px', 
    });
  };

  
  React.useEffect(() => {
    handleClick();
  }, []);

  return null; 
};

SuccessfullPopup.propTypes = {
  message: PropTypes.string.isRequired,
};

export default SuccessfullPopup;
