import React, { useEffect, useState } from "react";
import mrdicine from "../../../../assets/images/lab-main.png";
import "./labTest.css";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Cards from "../components/Cards";
import TopBookedlab from "../components/TopBooked/Topbookedlab";
import HealthChecks from "../components/Healthcaretest/HealthChecks";
import CallbackForm from "../components/find-right-test/Callback";
import ConditionBasedTest from "../components/conditionBasedTest/conditionBasedTest";
import MedharvaPlus from "../components/medharvaPlus/medharvaPlus";
import WorkProcessAtMedharva from "../components/workProcessAtMedharva/workProcessAtMedharva";
import WhatUserSay from "../components/whatUserSay/whatUserSay";
import EmailSubscribe from "../components/emailSubscribe/emailSubscribe";
const LabTest = () => {
  const cards = [{ image: mrdicine }, { image: mrdicine }, { image: mrdicine }];
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div>
      <Slider {...sliderSettings}>
        {cards.map((card, index) => (
          <div key={index}>
            <Grid mt={23} className="medicine-card-A">
              <Grid className="medicine-card-B">
                <img
                  className="medicine-card-imge"
                  src={card.image}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div className="medicine-card-C">
                <Grid className="medicine-car-D">Book lab tests</Grid>
                <Grid className="medicine-car-E">Directly from your home </Grid>
                <div className="consult-car-AL">
                  <Grid className="consult-car-AX">Flat 80% OFF</Grid>

                  <Grid className="consult-card-y">
                    <Button>
                      <span className="consult-card-Az">NEW60</span>
                    </Button>
                  </Grid>
                </div>
              </div> 
           <button
                className="medicine-button-container"
                onClick={() => {
                  if (auth?.rolecode) {
                    navigate(`/app/${auth?.rolename?.toLowerCase()}/lab-test-cart`);
                    localStorage.removeItem("labtest-code");
                    localStorage.removeItem("selectedLabTests");
                    localStorage.removeItem("selectedLocation");
                    localStorage.removeItem("labformData");
                    localStorage.removeItem("selectedTiming");
                    localStorage.removeItem("selectedPharmacy");
                    localStorage.removeItem("selectedDate");
                    localStorage.removeItem("selectedPerson");
                    localStorage.removeItem("dayNumber");
                    localStorage.removeItem("paymentmethod");
                    localStorage.removeItem("selectedAddressIndex");

                  } else {
                    navigate("/app/login");
                    swal(
                      "Attention",
                      "Please log in before booking an appointment for a secure and personalized experience||.",
                      "warning"
                    );
                  }
                }}
              >
                Book now
              </button>
             
            </Grid>
          </div>
        ))}
      </Slider>

      {isSmallScreen ? <></> : <Cards />}
      <TopBookedlab />
      <HealthChecks />
      <CallbackForm />
      {isMidScreen ? <></> : <ConditionBasedTest /> }
      <MedharvaPlus />
      <WorkProcessAtMedharva />
      <WhatUserSay />
      <EmailSubscribe />
    </div>
  );
};

export default LabTest;
