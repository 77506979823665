const form = {
  formId: "create-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
    },
    parenthierachical: {
      name: "parenthierachical",
      label: "Parent hiearachical",
      type: "text",
      errorMsg: "Parent hierachical is required.",
    },
    firstline: {
      name: "firstline",
      label: "First Line",
      type: "text",
      errorMsg: "First line is required.",
    },
    secondline: {
      name: "secondline",
      label: "Second Line ",
      type: "text",
      errorMsg: "Second line is required.",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
      errorMsg: "Country is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "select",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "select",
      errorMsg: "District is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub District",
      type: "select",
      errorMsg: "Sub district is required.",
    },
    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "select",
      errorMsg: "Pincode is required.",
    },
    latitude: {
      name: "latitude",
      label: "Latitude",
    },
    longitude: {
      name: "longitude",
      label: "longitude",
    },
  },
};

export default form;
