



const form = {
  formId: "new-user-form",
  formField: {
   
    
   


firstName: {
  name: "firstName",
  label: "First Name",
  type: "text",
  errorMsg: "firstname is required.",
  
},

age: {
  name: "age",
  label: "Age",
  type: "number",
  errorMsg: "age is required.",
  
},




lastName: {
  name: "lastName",
  label: "Last Name",
  type: "text",
  errorMsg: "lastname is required.",
  
},
experience: {
  name: "experience",
  label: "Experience",
  type: "number",
  errorMsg: "experience is required.",
  
},




description: {
  name: "description",
  label: "Description",
  type: "text",
  errorMsg: "description is required.",
  
},
role: {
  name: "role",
  label: "Role",
  type: "select",
  errorMsg: "role is required.",
  
},
    gender: {
      name: "gender",
      label: "Gender",
      type: "select",
      errorMsg: "gender is required.",
    },
   
  
    doctor:{
      name: "doctor",
      label: "doctor",
      type: "select", // Set the type appropriately
      errorMsg: "doctor is required."
    },

email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    mobilenumber: {
      name: "mobilenumber",
      label: "Mobile number",
      type: "number",
      errorMsg: "number is required.",
      
    },
   
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
   
  },
};

export default form;
