import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { APIs } from "Services/APIs";
// Import form validation, schema, and initial values
import initialValues from "./createTestInfo/schemas/initialValues";
import validations from "./createTestInfo/schemas/validation";
import form from "./createTestInfo/schemas/form";

import { micappCode } from "static/micappCode";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import axios from "axiosinstance";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";

import { createRegistrationResource } from "Services/RegistrationResourceservice";
import { getLoginServiceData } from "redux/Actions/getLoginServiceData";
import TestInfo from "./createTestInfo/Index";

// Function to define form steps
function getSteps() {
  return ["Test Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <TestInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function CreateTestPopup(props) {
  const {
    openpopupTestCreate,
    setopenpopupTestCreate,
    handleClose,
    handleCreatereset,
  } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { role } = useSelector((state) => state.role);
  const { error, loading } = useSelector((state) => state.role);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    values?.investigationList?.forEach((item) => {
      delete item?.id;
      delete item?.action;
    });
    setIsLoading(true);

    const userData = {
      investigationlist: values.investigationList,
      name: values.name,
      testname: values.testname,
      recstatus: "OPEN",
      status: "Active",
      syncstatus: "SYNCED",
      createdby: auth.profileid,
      createdbyname: auth.name,
    };

    try {
      const response = await axios.post(
        `${APIs.testServiceApi}/create`,
        userData
      );

      if (response.status === 201) {
        setIsLoading(false);

        handleClose();
        swal("Success!", "Your data has been successfully created.", "success");
        handleCreatereset();

        setActiveStep(activeStep - 1);
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      // Handle API error
      setIsLoading(false);

      swal(
        "Oops!",
        error?.response?.data?.aceErrors?.[0]?.errorMessage ||
          "Something wents wrong.",
        "error"
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (!values.investigationList || values.investigationList.length === 0) {
      swal({
        title: "Validation Error",
        text: "Investigation list cannot be empty.",
        icon: "error",
      });
      return;
    }
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <Dialog
      open={openpopupTestCreate}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Test Master
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={5}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
