import React, { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import ScheduleaDemo from "./scheduleDemo/ScheduleaDemo";
import CorpoeatesPremium from "./corporatesPremium/CorpoeatesPremium";
import WellnessPlans from "./Medharva  Wellness Plans/WellnessPlans";
import MedharvaPlus from "app/findDoctor/medharvaPlus/medharvaPlus";
import EmailSubscribe from "app/findDoctor/emailSubscribe/emailSubscribe";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";

const ForCorporates = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="For Corporates" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <ScheduleaDemo />
      <CorpoeatesPremium />
      <WellnessPlans />
      <MedharvaPlus />
      <EmailSubscribe />
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Corporates"/> : <></>}
    </div>
  );
};

export default ForCorporates;
