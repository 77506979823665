import { Grid } from '@mui/material'
import MDTypography from 'components/MDTypography'
import React from 'react'

const PersonalDetails = (props) => {
  return (
    <div>
         <Grid container ml={2} mt={2} sx={{ maxWidth: "100%" }}>
  {props?.personalDetails.map((item, index) => (
    <Grid key={index} item  xs={12}  sm={6} sx={{ display: "flex", gap: "5%", flexWrap: "wrap" }}>
      <Grid item   sx={{ wordWrap: "break-word" , width:"25%" }}> 
        <MDTypography   variant="body2" color="text" fontWeight="medium">
          {item.action}
        </MDTypography>
      </Grid>
      <Grid item  sx={{ textAlign: 'center', wordWrap: "break-word" }}>
        <MDTypography  variant="body2" color="text" fontWeight="medium" >
          :
        </MDTypography>
      </Grid>
      <Grid item  sx={{ wordWrap: "break-word" }}>
        <MDTypography variant="body2"  fontWeight="regular" >
          {item.accept}
        </MDTypography>
      </Grid>
    </Grid>
  ))}
</Grid>
    </div>
  )
}

export default PersonalDetails

