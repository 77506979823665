import React from 'react';
import { Grid, Skeleton, Stack, Button } from '@mui/material';
import "./Skeleton.css";
const SkeletonFilterCmp = ({ filterArray }) => {
  return (
    <Grid container py={2} px={3} spacing={2} rowSpacing={3}>
      {filterArray?.map((item, ind) => (
        <Grid key={ind} item md={3} lg={3} sm={3} xs={6}>
          <Skeleton variant="rectangular" width="100%" height={56} />
        </Grid>
      ))}
      <Grid item md={3} lg={3} sm={6} xs={12}>
        <Stack display="flex" flexDirection="row" gap={2} justifyContent="center">
          <Skeleton variant="rectangular" width={100} height={40} />
          <Skeleton variant="rectangular" width={100} height={40} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default SkeletonFilterCmp;
