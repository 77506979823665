import React, { useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/resetpasswordimg2.png";
// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import axios from "../../../axiosinstance";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address.")
      .required("Email is required."),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      axios
        .get(
          `${
            APIs?.otpServiceApiv2
          }/forget/password?msgCode=EDT&otpOn=EMAIL&resourceCode=HEALTHCARE&userid=${values?.email.trim()}`
        )
        .then((response) => {
          localStorage.setItem(
            "user-code",
            JSON.stringify({
              code: response?.data?.data?.dto?.code,
              emailid: response?.data?.data?.dto?.emailid,
            })
          );
          setIsLoading(false);
          swal(
            "Success!",
            "OTP has been sent to your registered email address.",
            "success"
          );
          navigate("/app/home/forgot-paasword-otp", {
            state: {
              email: response?.data?.data?.dto?.emailid,
            },
          });
          // navigate("/app/pages/otp/new-otp");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          swal(
            "Oops!",
            e?.response?.data?.aceErrors?.[0]?.errorMessage,
            "warning"
          );
        });
    },
  });

  return (
    <CoverLayout coverHeight="50vh" image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          py={2}
          mb={1}
          mt={-3}
          textAlign="center"
        >
          <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You will receive an email within 60 seconds.
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <form onSubmit={formik.handleSubmit}>
            <MDBox mb={4}>
              <MDInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <p style={{ color: "red", fontSize: "12px" }}>
                  {formik.errors.email}
                </p>
              )}
            </MDBox>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type="submit">
                {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Reset"}
              </MDButton>
            </MDBox>
          </form>
        </MDBox>
      </Card>
    </CoverLayout>
  );
};

export default ForgotPassword;
