import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import MDButton from "components/MDButton";
import { Link, useLocation } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import {  FormHelperText } from "@mui/material";
import swal from "sweetalert";

function ProfessionalInformation({ formData }) {
  
  const [idCounter, setIdCounter] = useState(0);
  const currentDate = new Date();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const location = useLocation();
  const { formField, values, errors, touched } = formData;

  const [data, setData] = useState(formData.values.personalinformation ||[]);
  const initialValues = {
    orgname: "",
    jobtitle: "",
    dateofjoin: currentDate.toISOString().split("T")[0],
    dateofleave: currentDate.toISOString().split("T")[0],
  };

  const handleSubmit = (values) => {
    if (
      !values.jobtitle.trim() ||
      !values.orgname.trim() ||
      values.dateofjoin > values.dateofleave
    ) {
      if (!values.jobtitle.trim() || !values.orgname.trim()) {
        swal("oops!", "Please fill in all fields.", "error");
   
      }
      // if (!values.orgname.trim()) {
      //   alert("Please enter an orgname");
      // }
      if (values.dateofjoin > values.dateofleave) {
       
        swal("oops!", "Start date cannot be greater then end date!", "error");
      }
      return; // Exit the function if validation fails
    } else {
      const newData = {
        uid: idCounter,
        ...values,
        id: null,
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "synced",
        
      };
      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      formData.errors.personalinformation = "";
      // resetForm();
      values.jobtitle = "";
      values.orgname = "";
      values.dateofjoin = currentDate.toISOString().split("T")[0];
      values.dateofleave = currentDate.toISOString().split("T")[0];
   
    }
  };
  formData.values.personalinformation=data
  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };


 

  const columns = [
    { Header: "Job title", accessor: "jobtitle" },
    { Header: "Organisation name", accessor: "orgname" },
    { Header: "date of join", accessor: "dateofjoin" },
    { Header: "date of leave", accessor: "dateofleave" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <FaRegTimesCircle
            style={{
              color: "#f44336",
              width: "25px",
              height: "25px",
              cursor: "pointer",
            }}
            onClick={() => removeData(row.values.uid)}
          />
        </span>
      ),
      width: "12%",
    },
  ];

  return (
    <Grid>
    
    {errors.personalinformation && (
          <FormHelperText error>{errors.personalinformation}</FormHelperText>
        )}
    
      <Formik initialValues={initialValues}>
        {({ values, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>Job title<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    type="text"
                    name="jobtitle"
                    variant="standard"
                    value={values.jobtitle}
                    onChange={(e) => {
                      const nameValue = e.target.value;
                      setFieldValue("jobtitle", nameValue);
                    }}
                  />
                  <ErrorMessage
                    name="jobtitle"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                   
                    label=<span>Organisation name<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    type="text"
                    name="orgname"
                    variant="standard"
                    value={values.orgname}
                    onChange={(e) => {
                      const nameValue = e.target.value;
                      setFieldValue("orgname", nameValue);
                    }}
                  />
                  <ErrorMessage
                    name="orgname"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>Start Date<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    type="date"
                    name="dateofjoin"
                    variant="standard"
                    value={values.dateofjoin}
                    onChange={(e) => {
                      const dateofjoinValue = e.target.value;
                      setFieldValue("dateofjoin", dateofjoinValue);
                    }}
                    error={touched.dateofjoin && Boolean(errors.dateofjoin)}
                  />
                  <ErrorMessage
                    name="dateofjoin"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>End Date<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    type="date"
                    name="dateofleave"
                    variant="standard"
                    value={values.dateofleave}
                    onChange={(e) => {
                      const dateofleaveValue = e.target.value;
                      setFieldValue("dateofleave", dateofleaveValue);
                    }}
                    error={touched.dateofleave && Boolean(errors.dateofleave)}
                  />
                  <ErrorMessage
                    name="dateofleave"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                {/* <MDButton variant="gradient" color="info" size="small" type="submit">
                                    Add +
                                </MDButton> */}
                <MDButton
                  variant="gradient"
                  color="info"
                  size="small"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit(values);
                  }}
                >
                  Add +
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          border: "1px solid #727375",
          borderRadius: "5px",
          marginTop: "15px",
          marginBottom: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <DataTable
          showTotalEntries={false}
          entriesPerPage={false}
          table={{ columns, rows: data }}
        />
      </Grid>
    </Grid>
  );
}

ProfessionalInformation.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProfessionalInformation;
