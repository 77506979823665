
// import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Stack, TextField } from '@mui/material';
// import React from 'react';
// import { FilterCmpModel } from './FilterCmdModel';
// import { useLocation } from 'react-router-dom';

// const FilterCmp = ({ filterArray, filterData, setFilterData, handleSearch, handleClear }:
//     { filterArray: FilterCmpModel, filterData: any, setFilterData: (prevState: any) => void, handleSearch: () => void, handleClear: () => void }) => {

//     const location = useLocation();
   

//     const updateURLParams = (params: any) => {
//         const searchParams = new URLSearchParams(location.search);
//         Object.entries(params).forEach(([key, value]) => {
//             if (value === undefined || value === null || value === '') {
//                 searchParams.delete(key);
//             } else {
//                 const transformedValue = Array.isArray(value) 
//                     ? value.join('~2C')
//                     : String(value).replace(/,/g, '~2C');
//                 searchParams.set(key, transformedValue);
//             }
//         });
//         window.history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`);
//     };

//     const handleFilterData = (e: any) => {
//         const { name, value, multiple, selectedOptions } = e.target;
//         if (multiple) {
//             const selectedValues = Array.from(selectedOptions, (option: any) => option.value);
//             setFilterData((prevState: any) => ({
//                 ...prevState,
//                 [name]: selectedValues
//             }));
//             updateURLParams({ [name]: selectedValues });
//         } else {
//             setFilterData((prevState: any) => ({
//                 ...prevState,
//                 [name]: value
//             }));
//             updateURLParams({ [name]: value });
//         }
//     };

    

//     return (
//         <Grid container py={2} px={3} spacing={2} rowSpacing={3}>
//             {filterArray?.map((item, ind) => (
//                 <Grid key={ind} item md={3} lg={3} sm={3} xs={6}>
//                     {item.type === "select" && (
//                         <FormControl variant="outlined" fullWidth>
//                             <InputLabel id={`demo-${item.name}-label`}>{item.label}</InputLabel>
//                             <Select
//                                 labelId={`demo-${item.name}-label`}
//                                 style={{ height: "43px" }}
//                                 id={`demo-${item.name}-select`}
//                                 name={item.name}
//                                 onChange={handleFilterData}
//                                 value={filterData[item.name] || ""}
//                                 label={item.label}
//                             >
//                                 <MenuItem>
//                                     <em>None</em>
//                                 </MenuItem>
//                                 {item.options?.map((op, index) => (
//                                     <MenuItem key={index} value={op.value}>
//                                         {op.label}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     )}
//                     {item.type === "multiselect" && (
//                         <FormControl variant="outlined" fullWidth>
//                             <InputLabel id={`demo-${item.name}-label`}>{item.label}</InputLabel>
//                             <Select
//                                 labelId={`demo-${item.name}-label`}
//                                 multiple
//                                 style={{ height: "43px" }}
//                                 id={`demo-${item.name}-select`}
//                                 name={item.name}
//                                 onChange={handleFilterData}
//                                 value={filterData[item.name] || []}
//                                 renderValue={(selected) => selected.join(', ')}
//                                 label={item.label}
//                             >
//                                 <MenuItem value="">
//                                     <em>None</em>
//                                 </MenuItem>
//                                 {item.options?.map((op, index) => (
//                                     <MenuItem key={index} value={op.value}>
//                                         <Checkbox checked={filterData[item.name]?.indexOf(op.value) > -1} />
//                                         {op.label}
//                                     </MenuItem>
//                                 ))}
//                             </Select>
//                         </FormControl>
//                     )}
//                     {item.type === "radio" && (
//                         <FormControl component="fieldset">
//                             <FormLabel component="legend" style={{ fontSize: "0.9rem", fontWeight: 700 }}>{item.label}</FormLabel>
//                             <RadioGroup
//                                 aria-label={item.label}
//                                 row
//                                 name={item.name}
//                                 value={filterData[item.name] || ""}
//                                 onChange={handleFilterData}
//                             >
//                                 {item.options?.map((op, index) => (
//                                     <FormControlLabel
//                                         style={{ fontSize: "0.9rem" }}
//                                         key={index}
//                                         value={op.value}
//                                         name={op.value}
//                                         control={<Radio />}
//                                         label={op.label}
//                                         onChange={handleFilterData}
//                                     />
//                                 ))}
//                             </RadioGroup>
//                         </FormControl>
//                     )}
//                     {item.type === "checkbox" && (
//                         <FormControl component="fieldset">
//                             <FormLabel component="legend" style={{ fontSize: "0.9rem", fontWeight: 700 }}>{item.label}</FormLabel>
//                             <FormGroup row>
//                                 {item?.options?.map((op, index) => (
//                                     <FormControlLabel
//                                         key={index}
//                                         control={
//                                             <Checkbox
//                                                 name={item.name}
//                                                 checked={filterData[item.name]?.includes(op.value) || false}
//                                                 onChange={(e) => {
//                                                     const newValue = e.target.checked
//                                                         ? [...(filterData[item.name] || []), op.value]
//                                                         : filterData[item.name].filter((val: any) => val !== op.value);
//                                                     setFilterData((prevState: any) => ({
//                                                         ...prevState,
//                                                         [item.name]: newValue
//                                                     }));
//                                                     updateURLParams({ [item.name]: newValue });
//                                                 }}
//                                             />
//                                         }
//                                         label={op.label}
//                                     />
//                                 ))}
//                             </FormGroup>
//                         </FormControl>
//                     )}
//                     {item.type === "text" && (
//                         <TextField
//                             {...item}
//                             type={item.type}
//                             fullWidth
//                             variant={item.variant}
//                             placeholder={item.placeholder}
//                             label={item.label}
//                             name={item.name}
//                             value={filterData[item.name] || ""}
//                             onChange={handleFilterData}
//                         />
//                     )}
//                     {item.type === "date" && (
//                         <TextField
//                             {...item}
//                             type={item.type}
//                             fullWidth
//                             variant={item.variant}
//                             placeholder={item.placeholder}
//                             label={item.label}
//                             name={item.name}
//                             value={filterData[item.name] || ""}
//                             onChange={handleFilterData}
//                         />
//                     )}
//                 </Grid>
//             ))}
//             <Grid item md={3} lg={3} sm={6} xs={12}>
//                 <Stack display="flex" flexDirection="row" gap={2} justifyContent="center">
//                     <Button variant="contained" onClick={handleSearch} style={{ color: "white" }}>Search</Button>
//                     <Button variant="outlined" style={{ color: "black" }} onClick={handleClear}>Clear</Button>
//                 </Stack>
//             </Grid>
//         </Grid>
//     )
// }

// export default FilterCmp;


import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    TextField,
  } from "@mui/material";
  import React from "react";
  import { FilterCmpModel } from "./FilterCmdModel";
  import { useLocation } from "react-router-dom";
  
  const FilterCmp = ({
    filterArray,
    filterData,
    setFilterData,
    handleSearch,
    handleClear,
  }: {
    filterArray: FilterCmpModel;
    filterData: any;
    setFilterData: (prevState: any) => void;
    handleSearch: () => void;
    handleClear: () => void;
  }) => {
    const location = useLocation();
  
    const updateURLParams = (params: any) => {
      const searchParams = new URLSearchParams(location.search);
      Object.entries(params).forEach(([key, value]) => {
        if (value === undefined || value === null || value === "") {
          searchParams.delete(key);
        } else {
          const transformedValue = Array.isArray(value)
            ? value.join("~2C")
            : String(value).replace(/,/g, "~2C");
          searchParams.set(key, transformedValue);
        }
      });
      window.history.replaceState(
        {},
        "",
        `${location.pathname}?${searchParams.toString()}`
      );
    };
  
    const handleFilterData = (e: any) => {
      const { name, value, multiple, selectedOptions } = e.target;
      if (multiple) {
        const selectedValues = Array.from(
          selectedOptions,
          (option: any) => option.value
        );
        setFilterData((prevState: any) => ({
          ...prevState,
          [name]: selectedValues,
        }));
        updateURLParams({ [name]: selectedValues });
      } else {
        setFilterData((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
        updateURLParams({ [name]: value });
      }
    };
  
    return (
      <Grid
        container
        py={2}
        px={3}
        spacing={2}
        rowSpacing={3}
        alignItems="center"
      >
        {filterArray?.map((item, ind) => (
          <Grid
            key={ind}
            item
            md={filterArray?.length > 1 ? 3 : 5}
            lg={filterArray?.length > 1 ? 3 : 4}
            sm={filterArray?.length > 1 ? 3 : 5}
            xl={filterArray?.length > 1 && 3}
            xs={filterArray?.length > 1 ? 6 : 12}
          >
            {item.type === "select" && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={`demo-${item.name}-label`}>
                  {item.label}
                </InputLabel>
                <Select
                  labelId={`demo-${item.name}-label`}
                  style={{ height: "43px" }}
                  id={`demo-${item.name}-select`}
                  name={item.name}
                  onChange={handleFilterData}
                  value={filterData[item.name] || ""}
                  label={item.label}
                >
                  <MenuItem>
                    <em>None</em>
                  </MenuItem>
                  {item.options?.map((op, index) => (
                    <MenuItem key={index} value={op.value}>
                      {op.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {item.type === "multiselect" && (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id={`demo-${item.name}-label`}>
                  {item.label}
                </InputLabel>
                <Select
                  labelId={`demo-${item.name}-label`}
                  multiple
                  style={{ height: "43px" }}
                  id={`demo-${item.name}-select`}
                  name={item.name}
                  onChange={handleFilterData}
                  value={filterData[item.name] || []}
                  renderValue={(selected) => selected.join(", ")}
                  label={item.label}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {item.options?.map((op, index) => (
                    <MenuItem key={index} value={op.value}>
                      <Checkbox
                        checked={filterData[item.name]?.indexOf(op.value) > -1}
                      />
                      {op.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {item.type === "radio" && (
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{ fontSize: "0.9rem", fontWeight: 700 }}
                >
                  {item.label}
                </FormLabel>
                <RadioGroup
                  aria-label={item.label}
                  row
                  name={item.name}
                  value={filterData[item.name] || ""}
                  onChange={handleFilterData}
                >
                  {item.options?.map((op, index) => (
                    <FormControlLabel
                      style={{ fontSize: "0.9rem" }}
                      key={index}
                      value={op.value}
                      name={op.value}
                      control={<Radio />}
                      label={op.label}
                      onChange={handleFilterData}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {item.type === "checkbox" && (
              <FormControl component="fieldset">
                <FormLabel
                  component="legend"
                  style={{ fontSize: "0.9rem", fontWeight: 700 }}
                >
                  {item.label}
                </FormLabel>
                <FormGroup row>
                  {item?.options?.map((op, index) => (
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          name={item.name}
                          checked={
                            filterData[item.name]?.includes(op.value) || false
                          }
                          onChange={(e) => {
                            const newValue = e.target.checked
                              ? [...(filterData[item.name] || []), op.value]
                              : filterData[item.name].filter(
                                  (val: any) => val !== op.value
                                );
                            setFilterData((prevState: any) => ({
                              ...prevState,
                              [item.name]: newValue,
                            }));
                            updateURLParams({ [item.name]: newValue });
                          }}
                        />
                      }
                      label={op.label}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            )}
            {item.type === "text" && (
              <TextField
                {...item}
                type={item.type}
                fullWidth
                variant={item.variant}
                placeholder={item.placeholder}
                label={item.label}
                name={item.name}
                value={filterData[item.name] || ""}
                onChange={handleFilterData}
              />
            )}
            {item.type === "date" && (
              <TextField
                {...item}
                type={item.type}
                fullWidth
                variant={item.variant}
                placeholder={item.placeholder}
                label={item.label}
                name={item.name}
                value={filterData[item.name] || ""}
                onChange={handleFilterData}
              />
            )}
          </Grid>
        ))}
        <Grid item md={3} lg={3} sm={6} xs={12} xl={filterArray?.length > 1 && 3}>
          <Stack
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="center"
          >
            <Button
              variant="contained"
              onClick={handleSearch}
              style={{ color: "white" }}
            >
              Search
            </Button>
            <Button
              variant="outlined"
              style={{ color: "black" }}
              onClick={handleClear}
            >
              Clear
            </Button>
          </Stack>
        </Grid>
      </Grid>
    );
  };
  
  export default FilterCmp;
  