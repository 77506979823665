import * as React from "react";
import { useEffect, useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import LightModeIcon from "@mui/icons-material/LightMode";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import MDDatePicker from "components/MDDatePicker";
import { useNavigate } from "react-router-dom";
import axios from "../../../../axiosinstance";
//Material UI

import { getData } from "Services/Slot";
import moment from "moment-timezone";
import { Autocomplete, TextField, Tooltip } from "@mui/material";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// import "./style.css";

function DoctorSlot(props) {
  
  const [response, setResponse] = useState();
  const [profileid, setProfileId] = useState();

  const [date, setDate] = useState(moment(new Date()).format("yyyy-MM-DD"));

  const [slotlist, setSlotlist] = useState([]);
  const [doctorList, setDoctorlist] = useState("")

  const [disabled, setDisabled] = useState(props.disabled);

  const [accordianValue, setAccordian] = useState(props.value);

  var finalTimeArray = [];
  const [time, setTime] = useState(finalTimeArray);
  const defaultPropsDoctor = {
    options: !doctorList ? [{ name: "Loading...", id: 0 }] : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
};
  
  

  var intialCheckBox = {};
  const [checkboxstatus, setCheckboxstatus] = useState({});
  // const [timeArray, setTimeArray] = useState(Object.keys(checkboxstatus)
  //   .filter(key => checkboxstatus[key] != false)
  //   .reduce((acc, key) => {
  //     acc[key] = checkboxstatus[key];
  //     return key;
  //   }, []))

  const navigate = useNavigate();

  var obj = checkboxstatus;
  var keys = Object.keys(obj).filter((e) => obj[e] === true);

  async function getSlotData(id, date) {
    setSlotlist([]);

    var checkbox = {};
    await axios
      .get(`event-service/api/event/v1/get?profileid=${id}&date=${date}`)
      .then((response) => {
        response.data?.data?.dtoList.map((values) => {
          checkbox[values.startdate] = false;
          startDate(response.data?.data?.dtoList);
          var date = values.startdate;
          var timeonly = date.substring(11, 19);
          var hms = timeonly; // your input string
          var a = hms.split(":"); // split it at the colons
          var correctTime = ("a", a[0] + ":" + a[1]);

          values.slottime = correctTime;
          // minutes are worth 60 seconds. Hours are worth 60 minutes.

          values.averageusercount = values.averageusercount
            ? values.averageusercount
            : 0;
          if (values.averageusercount * 1 == 0) {
            values.button = true;
          } else {
            values.button = false;
          }
        });
        setCheckboxstatus(checkbox);
        setSlotlist(response.data?.data?.dtoList);

        props.getData(response.data?.data?.dtoList);
      })

      .catch((e) => {});
  }
  function startDate(slotvalue) {
    for (let i = 0; i < slotvalue.length; i++) {
      setTime(slotvalue[i].startdate);
    }
  }

  const handleChange = (event, object) => {
    if (event.target.name === "all") {
      // const newObj: any = objectMap(state, (value) => event.target.checked);
      Object.keys(checkboxstatus).forEach(
        (key) => (checkboxstatus[key] = event.target.checked)
      );
      setCheckboxstatus({ ...checkboxstatus });
    } else {
      // object[event.target.name] = event.target.checked
      // setCheckboxstatus(checkboxstatus)
      setCheckboxstatus({
        ...object,
        [event.target.name]: event.target.checked,
      });
    }
  };

  function getResponse(e) {
    intialCheckBox = {};

    var date = dateFormat(e.target.value);
    getSlotData(profileid, date);

    // {
    //   accordianValue == "accordianData" && props.getValue([])
    // }

    setDate(e.target.value);
    var y = getData(" ");
    setResponse(y);
  }
  function dateFormat(date) {
    return moment(date).format("yyyy/MM/DD");
  }
  // format date and time in am pm
  function formatTime(timeString) {
    const formattedTime = moment(timeString, "HH:mm").format("h:mm A");
    return formattedTime;
  }

//   useEffect(() => {
//     getSlotData(profileid, dateFormat(new Date()));
//   }, []);
async function getDoctorData(code) {
    await axios
    .get(
      `login-service-mongo/api/loginservice/v2/get?rolecode=${code}&recstatus=APPROVED`
    )
      .then((response) => {
      

        
      
    setDoctorlist(response.data.data.dtoList)
       
        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }
    useEffect(() => {
    getDoctorData("DO001");
  }, []);

  return (
    <>
    <Card>
      <MDBox p={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={10}>
            <MDBox>
              {disabled ? (
                <MDTypography variant="h5" fontWeight="bold">
                  Slot List
                </MDTypography>
              ) : (
                <MDTypography variant="h5" fontWeight="bold">
                  Slot Booking
                </MDTypography>
              )}
            </MDBox>
          </Grid>
         
        </Grid>
        <MDBox>
          <Grid container spacing={3} m={3}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
               {...defaultPropsDoctor}
              onChange={(event, newValue) => {
                setProfileId(newValue?.profileid)
                getSlotData(newValue?.profileid, dateFormat(new Date()));
            
     
             
                
              }}
              renderInput={(params) => <TextField {...params} label="Select Doctor" />}
            />
          </Grid>
            <Grid item xs={12} sm={6}>
              <MDInput
                value={date}
                type="date"
                onChange={(e) => getResponse(e)}
              />
            </Grid>
           
          </Grid>

          <MDBox p={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <LightModeIcon fontSize="large" />
              </Grid>

              <Grid item xs={12} sm={10}>
                <Grid container spacing={3}>
                  {slotlist.length > 0 &&
                    slotlist.map((obj) => {
                      return (
                        <Grid item xs={12} sm={2}>
                          {disabled === true ? (
                            <Tooltip title={obj.averageusercount} arrow>
                              <MDButton
                                name={obj.slottime}
                                disabled={obj.button}
                                variant="outlined"
                                color="info"
                                size="small"
                              >
                                {obj.slottime}
                              </MDButton>
                            </Tooltip>
                          ) : disabled === false ? (
                            <Tooltip title={obj.averageusercount} arrow>
                              <MDButton
                                name={obj.slottime}
                                disabled={obj.button}
                                onClick={() => {
                                  navigate("/app/page/final-appointment", {
                                    state: {
                                      date: date,
                                      time: formatTime(obj.slottime),
                                      profileid: profileid,
                                      slotobject: obj,
                                      doctorobject: props?.doctorobject,
                                      doctorfess: props?.doctorfess,
                                      patientrecpanist:props?.patientrecpanist
                                    },
                                  });
                                }}
                                variant="outlined"
                                color="info"
                                size="small"
                              >
                               
                                {formatTime(obj.slottime)}{" "}
                                {/* Call the formatTime function */}
                              </MDButton>
                            </Tooltip>
                          ) : (
                            <></>
                          )}
                          <Grid container spacing={3}>
                            {accordianValue == "accordianData" && (
                              <Tooltip title={obj.averageusercount} arrow>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={checkboxstatus[obj.startdate]}
                                      onChange={(e) =>
                                        handleChange(e, checkboxstatus)
                                      }
                                      name={obj.startdate}
                                    />
                                  }
                                  label=<button
                                    name={obj.slottime}
                                    disabled={obj.button}
                                    className="btn"
                                  >
                                    {obj.slottime}
                                  </button>
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
     <Footer />
     </>
  
  );
}

export default DoctorSlot