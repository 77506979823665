/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "create-form",
  formField: {
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is required.",
    },
    description: {
      name: "description",
      label: "Description",
      type: "text",
    },
    firstline: {
      name: "firstline",
      label: "First Line",
      type: "text",
      errorMsg: "First line is required.",
    },
    secondline: {
      name: "secondline",
      label: "Second Line ",
      type: "text",
      errorMsg: "Second line is required.",
    },
    country: {
      name: "country",
      label: "Country",
      type: "text",
      errorMsg: "Country is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "select",
      errorMsg: "State is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "select",
      errorMsg: "District is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub District",
      type: "select",
      errorMsg: "Sub district is required.",
    },
    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "select",
      errorMsg: "Pincode is required.",
    },
    mapware: {
      name: "mapware",
      label: "Map ware-house",
      type: "text",
      errorMsg: " mapware is required.",
    },

    RaspberryAssign: {
      name: "RaspberryAssign",
      label: "Raspberry Assign",
      type: "text",
      errorMsg: "RaspberryAssign is required.",
    },

    VehicleAssign: {
      name: "VehicleAssign",
      label: "Vehicle Assign",
      type: "text",
      errorMsg: "VehicleAssign is required.",
    },
    latitude: {
      name: "latitude",
      label: "Latitude",
    },
    longitude: {
      name: "longitude",
      label: "longitude",
    },
  },
};

export default form;
