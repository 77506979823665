import React from "react";
import "./services2.css";
import { Button, Grid, useMediaQuery } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imag5 from "../../findDoctor/assets/images/cardsliderimg.jpg";

const Ourservice1 = () => {
    const cardsData2 = [
        {
            logo: imag5,
            name: "BP Monitor",
            btn: "Starting from ₹150",
            description: "Check BP under 30 sec",
        },
        {
            logo: imag5,
            name: "BP Monitor",
            btn: "Starting from ₹150",
            description: "Check BP under 30 sec",
        },
        {
            logo: imag5,
            name: "BP Monitor",
            btn: "Starting from ₹150",
            description: "Check BP under 30 sec",
        },
        {
            logo: imag5,
            name: "BP Monitor",
            btn: "Starting from ₹150",
            description: "Check BP under 30 sec",
        },
    ];
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: Math.floor(window.innerWidth / 345), // Adjust the value as needed
        slidesToScroll: 1,
        centerPadding: "20px",
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 808,
                settings: {
                    slidesToShow: 2.5,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 710,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1.5,
                    centerPadding: "10px",
                },
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 1.3,
                    centerPadding: "10px",

                },
            },
            {
                breakpoint: 400,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "10px",
                },
            },
        ],
    };

    return (
        <div>
            <Grid container className="main-container">
                <Grid container spacing={2} className="container2">
                    <Grid item xs={12}>
                        <h2 className="mainheading">
                            Our Service Extends Beyond Consultation, Scan & Lab Test
                        </h2>
                    </Grid>

                    <Grid item xs={12} sm={12} mt={2}>
                        <Slider {...sliderSettings}>
                            {cardsData2.map((card, index) => (
                                <div key={index}>
                                    <div className="card3">
                                        <div className="image-container1">
                                            <img
                                                src={card.logo}
                                                alt="Service Logo"
                                                className="imageour"
                                            />
                                        </div>
                                    
                                        <Grid container justify="flex-start" ml={2}>
                                            <Grid item xs={12} sm={12}>
                                                <Grid className="cardheading4">{card.name}</Grid>
                                                <Grid item xs={12} sm={12} className="description10">{card.description}</Grid>
                                            </Grid>

                                            <Grid item xs={12} mt={1}>
                                                <Button
                                                    variant="text"
                                                    className="button11"
                                                    sx={{
                                                        width: "99px",
                                                        height: "23px",
                                                        borderRadius: "100px",
                                                        fontSize: "8px",
                                                        fontFamily: "Poppins",
                                                        color: "#000000",
                                                        padding: "10px",
                                                        background: "#f2f4ea",

                                                        textTransform: "none",
                                                    }}
                                                >
                                                    {card.btn}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="flex-start" ml={2} mt={3} sx={{justifyContent:"space-around"}}>
                                            <Button
                                                variant="outlined"
                                                className="button11"
                                                sx={{
                                                    height: "29px",
                                                    // width: "auto",
                                                    borderRadius: "8px",

                                                    color: "#039147",
                                                    background: "#ffffff",
                                                    border: "2px solid #039147",
                                                    fontSize: "10px",
                                                    fontFamily: "Poppins",
                                                    borderColor: "#039147",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                        background: "#039147",
                                                        color: "#ffffff",
                                                        border: "2px solid #039147",
                                                    },
                                                }}
                                            >
                                                Add to Cart
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className="button11"
                                                sx={{
                                                    height: "29px",
                                                    // width: "auto",
                                                    borderRadius: "8px",
                                                    fontSize: "10px",
                                                    fontFamily: "Poppins",
                                                    color: "#ffffff",
                                                    background: "#039147",
                                                    border: "2px solid #039147",
                                                    borderColor: "#039147",
                                                    textTransform: "none",
                                                    "&:hover": {
                                                        background: "#FFFFFF",
                                                        color: "#039147",
                                                        border: "2px solid #039147",
                                                    },
                                                }}
                                            >
                                                Buy Now
                                            </Button>
                                        </Grid>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default Ourservice1;
