import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../axiosinstance";
 
 
export const getAggregated = async (data) => {

 
    try {
      const response = await axios.get(
        `profile-service-mongo/api/ProfileEntity/v2/getAggregated?profileid=${data}`
    );
      return await response.data;
    } catch (error) {
        console.log(error)
    //   return rejectWithValue(error);
    }
  };

