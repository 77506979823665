import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { ErrorMessage, useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import { createProject } from "components/Resuable/Create Project/service/Projectcreate";
import { labappCode } from "static/labappCode";
import { MMUListAction } from "redux/Actions/MMUListAction";
import { labListAction } from "redux/Actions/labListAction";
import { APIs } from "Services/APIs";
import { ROLE_CODE } from "privateComponents/codes";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { Link } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import { tableAlreadyExistData } from "static/methods/tableAlreadyExistData";

function AssociationTeamInfo({ formData }) {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();
  const { create } = useSelector((state) => state.create);
  const [color] = useState("#344767");
  const { getLabList, getMMUList } = useSelector((state) => state);
  const [profiledata, setProfileData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [department, setDepartment] = useState([]);
  const [data, setData] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [idCounter, setIdCounter] = useState(0);
  const { getProjectDetails } = useSelector((state) => state);

  const { formField, values, touched } = formData;

  const [errors, setErrors] = useState({});

  const { mmu, departmentname, designationname, profilename } = formField;
  var {
    mmu: mmuV,
    departmentname: departmentnameV,
    designationname: designationnameV,
    profilename: profilenameV,
  } = values;

  // async function getProfileDataByDeptCode(departmentcode) {
  //   let url =
  //     auth?.rolecode === ROLE_CODE?.admincode
  //       ? `${APIs?.loginServiceApi}/get?departmentcode=${departmentcode}&recstatus=APPROVED`
  //       : `${APIs?.loginServiceApi}/getByMappingcode?projectcode=${getProjectDetails?.details?.projecthirarichalcode}&designationcode=${departmentcode}&recstatus=APPROVED`;
  //   await axios
  //     .get(url)
  //     .then((response) => {
  //       if (response?.data?.data?.listSize > 0) {
  //         setUserList(response?.data?.data?.dtoList);
  //       } else {
  //         swal(
  //           "",
  //           "No profile data found for the selected department!",
  //           "warning"
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       swal(
  //         "Error retrieving profile data.",
  //         `${error?.response?.data}.` || "Something wents wrong.",
  //         "warning"
  //       );
  //       console.log("error", e);
  //     });
  // }

  async function getprofileData(designationcode) {
    let url =
      auth?.rolecode === ROLE_CODE?.admincode
        ? `${APIs?.loginServiceApi}/get?designationcode=${designationcode}&recstatus=APPROVED`
        : `${APIs?.loginServiceApi}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&designationcode=${designationcode}&recstatus=APPROVED`;
    await axios
      .get(url)
      .then((response) => {
        if (response?.data?.data?.listSize > 0) {
          setUserList(response?.data?.data?.dtoList);
        } else {
          swal(
            "",
            "No profile data found for the selected designation!",
            "warning"
          );
        }
      })
      .catch((error) => {
        swal(
          "Error retrieving profile data.",
          `${error?.response?.data}.` || "Something wents wrong.",
          "warning"
        );
        console.log("error", e);
      });
  }

  async function getDesignationData(code) {
    let url = `${APIs?.designationServiceApi}/get?deptcode=${code}`;

    try {
      const response = await axios.get(url);
      if (response?.data?.data?.listSize > 0) {
        setDesignationList(response?.data?.data?.dtoList);
        // getProfileDataByDeptCode(code);
      } else {
        swal(
          "",
          "No designation data found for the selected department!",
          "warning"
        );
      }
    } catch (error) {
      swal(
        "Error retrieving designation data.",
        `${error?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
      console.log("error", error);
      return;
    }
  }

  async function getData() {
    let url = `${APIs?.deparmentServiceApi}/get`;
    try {
      const response = await axios.get(url);
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      swal(
        "Error retrieving department data.",
        `${error?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
      console.log("error", error);
    }
  }
  useEffect(() => {
    getData();
  }, [dispatch]);

  useEffect(() => {
    if (auth.rolecode === ROLE_CODE.admincode) {
      dispatch(MMUListAction());
    } else {
      dispatch(
        MMUListAction({
          mappingcode: getProjectDetails?.details?.projecthirarichalcode,
        })
      );
    }
  }, []);

  const handleSubmit = async () => {
    let hasError = false;

    let newErrors = {};

    if (!values.mmu) {
      newErrors.mmu = "Lab is required.";
      hasError = true;
    }

    if (!values.departmentname) {
      newErrors.departmentname = "Department is required.";
      hasError = true;
    }

    if (!values.designationname) {
      newErrors.designationname = "Designation is required.";
      hasError = true;
    }

    if (!values.profilename) {
      newErrors.profilename = "Profile is required.";
      hasError = true;
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }

    if (!hasError) {
      if (await tableAlreadyExistData(data, values?.profilenameobject?.id))
        return;

      const newData = {
        uid: idCounter,
        mmu: values?.mmuname,
        mmuname: values?.mmuname?.name,
        department: values?.departmentname,
        departmentname: values?.profilenameobject?.departmentname,
        designation: values?.designationname,
        designationname: values?.profilenameobject?.designationname,
        projectteams: values?.profilenameobject,
        name: values?.profilenameobject?.name,
      };

      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      setFieldValue("mmu", "");
      setFieldValue("departmentname", "");
      setFieldValue("designationname", "");
      setFieldValue("profilename", "");
      values.teamList = [...data, newData];
    }
  };

  useEffect(() => {
    values.teamList = data;
  }, [data]);

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "Lab", accessor: "mmuname" },
    { Header: "Department", accessor: "departmentname" },
    { Header: "Designation", accessor: "designationname" },
    { Header: "Profile", accessor: "name" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];

  return (
    <MDBox>
      <BarLoader
        color={color}
        loading={getMMUList?.loading}
        aria-label="Loading Spinner"
      />
      {!getMMUList?.loading && (
        <>
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="mmu"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Lab
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      &nbsp;*
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="mmu"
                    id="mmu"
                    value={values.mmu}
                    onChange={(e) => {
                      const mmu = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }

                      const mmuname =
                        getMMUList?.data?.dtoList?.find(
                          (obj) => obj.code === mmu
                        ) || {};

                      setFieldValue("mmuname", mmuname);
                      setFieldValue("mmu", mmu);
                    }}
                  >
                    <option value=""></option>
                    {getMMUList?.data?.dtoList?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {errors.mmu && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: "400" }}
                    >
                      {errors.mmu}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="departmentname"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Department
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentname"
                    id="departmentname"
                    value={values?.departmentname}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      setFieldValue("designationname", "");
                      setFieldValue("profilename", "");
                      setDesignationList([]);
                      setUserList([]);
                      if (departmentvalue) {
                        getDesignationData(e.target.value);
                        // getProfileDataByDeptCode(e.target.value);
                      }
                      setFieldValue("departmentname", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {/* <ErrorMessage
                    name="departmentname"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  /> */}
                  {errors.departmentname && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: "400" }}
                    >
                      {errors.departmentname}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="designationname"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationname"
                    id="designationname"
                    value={values?.designationname}
                    onChange={(e) => {
                      const designationvalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      const departmentvalue = values?.departmentname;
                      setFieldValue("profilename", "");
                      setUserList([]);
                      // if (!designationvalue && departmentvalue) {
                      //   getProfileDataByDeptCode(departmentvalue);
                      // } else {
                      getprofileData(designationvalue);
                      // }
                      if (designationvalue) {
                        const data = {
                          departmentvalue: departmentvalue,
                          designationvalue: designationvalue,
                        };
                      }
                      setFieldValue("designationname", designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designationList?.map((obj) => (
                      <option key={obj?.id} value={obj?.code}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {/* <ErrorMessage
                    name="designationname"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  /> */}
                  {errors.designationname && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: "400" }}
                    >
                      {errors.designationname}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="profilename"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Profile Name
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        marginLeft: "4px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="profilename"
                    id="profilename"
                    value={values?.profilename}
                    onChange={(e) => {
                      const profilenamevalue = e.target.value;
                      if (e.target.value) {
                        setErrors({ ...errors, [e.target.name]: "" });
                      }
                      const profilenameobject =
                        userList?.find((obj) => obj?.id == e.target.value) ||
                        {};

                      setFieldValue("profilenameobject", profilenameobject);
                      setFieldValue("profilename", profilenamevalue);
                    }}
                  >
                    <option value=""></option>
                    {userList?.map((obj) => (
                      <option key={obj?.id} value={obj?.id}>
                        {obj?.name}
                      </option>
                    ))}
                  </Select>
                  {/* <ErrorMessage
                    name="profilename"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  /> */}
                  {errors.profilename && (
                    <FormHelperText
                      error
                      style={{ marginLeft: "0px", fontWeight: "400" }}
                    >
                      {errors.profilename}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                display="flex"
                justifyContent="flex-end"
              >
                <MDButton
                  onClick={() => handleSubmit()}
                  variant="gradient"
                  color="info"
                  size="small"
                >
                  Add
                </MDButton>
              </Grid>
            </Grid>
          </MDBox>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={{ columns, rows: data }}
            />
          </Grid>
        </>
      )}
    </MDBox>
  );
}

AssociationTeamInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AssociationTeamInfo;
