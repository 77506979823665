import { Grid } from "@mui/material";
import React from "react";
import "./mobileNavbar.css";
import Phone from "../../../assets/svg/Phone.svg";
import Email from "../../../assets/svg/Email.svg";

const MobileSection1 = () => {
  return (
    <>
      <Grid item className="mobilenavbaritem1">
        <a href="tel:+919319896978">
          <img src={Phone} alt="Phone" className="mobilenavbarimg" />
          &nbsp;&nbsp; +91 931989 6978
        </a>
      </Grid>
      <Grid item className="mobilenavbaritem1">
        <a href="mailto:info@medharva.com">
          <img src={Email} alt="Phone" className="mobilenavbarimg" />
          &nbsp;&nbsp; info@medharva.com
        </a>
      </Grid>
    </>
  );
};

export default MobileSection1;
