
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import productImage from "assets/images/products/product-11.jpg";
import Grid from "@mui/material/Grid";
import MDBadge from "components/MDBadge";
import axios from "../../../../../axiosinstance"
import MDButton from "components/MDButton";


function DoctorImage(props) {
  console.log("nbbcsbccbcb",props.doctorfess)
  const [logininformation, setLogininformation] = useState(props.data.login)
  const [personalinformation, setPersonalinformation] = useState(props.data.personalinformation)
  const [addressinformation, setAddressinformation] = useState(props.data.addresslist[0])
  const [qualificationinformation, setQualificationinformation] = useState(props.data.qualificationlist)
  const [address, setAddress] = useState(JSON.parse(localStorage.getItem("addressdata"))?.address)
  const [slotaddress, setSlotAddress] = useState(props.addressdata[0]?.addressobject)
  const [doctorprofileId, setDoctorProfileId] = useState(JSON.parse(localStorage.getItem("doctorprofileid"))?.profileid)
  


  
  let experience = 0
  for (let i = 0; i < props.data.experiencelist.length; i++) {

    experience = experience + (props.data.experiencelist[i].experienceinyear * 1)

  }
  var oldName = logininformation?.name

  var firstName = oldName?.split(' ').slice(0, -1)?.join(' ');
  var lastName = oldName?.split(' ').slice(-1)?.join(' ');
  var firstname = firstName;
  var lastname = lastName;
  var firstname2 = firstname?.charAt(0)?.toUpperCase() + firstname?.slice(1);
  var lastname2 = lastname?.charAt(0)?.toUpperCase() + lastname?.slice(1);

  
  

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={productImage}
          alt="Product Image"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          zIndex={10}
          // mb={2}
        />
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-10}
          position="relative"
          zIndex={1}
        >
         

        </MDBox>
        <Grid container spacing={3}>
          <Grid container spacing={3} mt={7} >
            <Grid alignItems="right" item xs={12} sm={12} mr={16} >
            <MDTypography component="p" variant="h6" color="text" textAlign="left" ml={2}>
                Name : {firstname2 + " " + lastname2}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} mt={-2} mr={22}>
            <MDTypography component="p" variant="h6" color="text" textAlign="left" ml={2}>
                Experience : {logininformation?.experience + " " + "yr"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} mt={-2}>
             <MDTypography component="p" variant="h6" color="text" textAlign="left" ml={2}>
             
                {/* Address: {props.addressdata.length > 0 ? props.addressdata[0]?.addressobject?.firstline +" "+props.addressdata[0]?.addressobject?.secondline +" "+props.addressdata[0]?.addressobject?.city + " " + props.addressdata[0]?.addressobject?.state  : addressinformation?.firstline + " " + addressinformation?.secondline + " " + addressinformation?.city + " " + addressinformation?.state} */}
                Address: {props.addressdata.length > 0 ? props.addressdata[0]?.addressobject?.firstline +" "+props.addressdata[0]?.addressobject?.secondline +" "+props.addressdata[0]?.addressobject?.city + " " + props.addressdata[0]?.addressobject?.state  :  "N/A"}
              </MDTypography>
            </Grid>
            <Grid item xs={12} sm={12} mt={-2}>
              <MDTypography component="p" variant="h6" color="text" textAlign="left" ml={2}>
                {qualificationinformation?.map((values) => (
                  <Grid item xs={12} md={6}>
                    <MDBadge

                      variant="gradient"
                      color="warning"
                      size="xs"
                      badgeContent={values.qualificationname}
                      container
                    />
                  </Grid>
                ))}
              </MDTypography>
            </Grid>

            <Grid item xs={12} sm={12} mt={-2}>
            <MDTypography component="p" variant="h6" color="text" textAlign="right" ml={2}>
               
               
                <MDButton

                  variant="gradient"
                  color="success"
                  size="small"
                  
                
                > {props.doctorfess[0]?.currency} {props.doctorfess[0]?.value}</MDButton>

              </MDTypography>
            </Grid>
           

          </Grid>
        </Grid>
       

      </MDBox>
    </Card>
  );
}

export default DoctorImage;


