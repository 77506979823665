import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";


  export const deleteProjectTeamData = createAsyncThunk("deleteProjectTeamData", async (data, {rejectWithValue}) => {
    try {
         const response = await axios.put(
           `${APIs.projectTeamService}/delete`,
           data
          
         );
         return await response.data;
       } catch (error) {
         return rejectWithValue(error);
       }
     });