import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const getRoleAction = createAsyncThunk("getRoles", async (data, { rejectWithValue }) => {
  let url;
  if (data && data?.profileid) {
    url = `${APIs.roleService}/get?createdBy=${data?.profileid}`;
  } else {
    url = `${APIs.roleService}/get`
  }
  try {
    const response = await axios.get(url)
    console.log(response, "roles response 15")
    return response?.data?.data?.dtoList;
  } catch (error) {
    return rejectWithValue(error);
  }
});