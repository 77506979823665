import { Button, Grid } from '@mui/material'
import React from 'react'
import '../MProvider/style.css'

const ProviderCard = () => {
  return (
    <div>
      <Grid>
        <Grid className='provedercard-1'>
            <Grid className='provedercard-2'>
                <Grid className='provedercard-3'>Medharva Provider</Grid>
            </Grid>
            <Grid className='provedercard-4'>
                <Grid className='provedercard-5'>For Clinics and Hospitals</Grid>
            </Grid>
            <Grid className='provedercard-6'>
                <Grid className='provedercard-7'>
                A technology product designed for clinics and hospitals that assists in growing your practice and enhancing the overall patient visit experience.
                    </Grid>
            </Grid>
            <Grid  className="provedercard-button-container" >
          <Grid className="provedercard-button">
          <Button>
          <span  className="provedercard-button-text"
       
            > Get a free Demo</span> 
          </Button>
       </Grid>
       </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default ProviderCard
