import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import axios from "../../../../axiosinstance";
import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import { Grid } from "@mui/material";

import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { createUser } from "components/Resuable/UserMaster/service/User";

import { APIs } from "Services/APIs";

function CreateMic({ roledata }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { department } = useSelector((state) => state.department);
  const [designationList, setDesignationList] = useState([]);
  const { designation } = useSelector((state) => state.designation);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required."),
    name: Yup.string().required("First name is required."),
    age: Yup.string().required("Age is required."),
    Phonenumber: Yup.string()
      .required("Phone number is required.")
      .matches(/^\d{10}$/, "Phone number must be 10 digits."),
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email address."),
    departmentvalue: Yup.string().required("Department is required."),
    designationvalue: Yup.string().required("Designation is required."),
    // experience: Yup.string().required("Experience is required."),

    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });
  function getDesignationData(code) {
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getDesignation());
  }, [dispatch]);

  return (
    <Formik
      initialValues={{
        lastname: "",
        gender: "",
        age: "",
        name: "",
        Phonenumber: "",
        email: "",
        password: "",
        confirmPassword: "",
        experience: "",
        description: "",
        departmentvalue: "",
        designationvalue: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        setLoading(true);
        if (roledata === null || Object.keys(roledata).length === 0) {
          swal("Warning!", "Please select role first!", "warning");
        } else {
          const UserData = {
            age: values.age,
            sex: values.gender,
            documentofproof: "",
            emailid: values.email,
            name: values.lastname
              ? values.name + " " + values.lastname
              : values.name,

            isactive: "INACTIVE",
            description: values.description,

            emailid: values.email,
            rolename: roledata.name,
            rolecode: roledata.rolecode,
            description: values.description,
            experience: values.experience,
            departmentcode: values.department.code,
            departmentname: values.department.name,
            designationcode: values.designation.code,
            designationname: values.designation.name,

            mobileno: values.Phonenumber,

            password: values.password,

            recstatus: "APPROVED",
            resourcecode: "HEALTHCARE",
            status: "ACTIVE",
            syncstatus: "synced",

            createdby: JSON.parse(localStorage.getItem("login-auth"))
              ?.profileid,
          };
  
      await axios.post(
        `${APIs.loginServiceApi}/create`,
        UserData
      )
        .then((response) => {
          setLoading(false);
          swal("Success!", "User has been created succefully.", "success");
          navigate("/app/admin/approval-list");
        })
        .catch((error) => {
          console.log(error);
          swal("Oops!", 
            (error?.response && error.response?.data && error?.response?.data?.aceErrors) ?
              (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
                "User Already Registered with the same Email id !!" :
                error?.response?.data?.aceErrors?.[0]?.errorMessage) :
              "Request failed with status code 404.",
            "warning"
          );
          setLoading(false);
        });
      }
    }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      First Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={(e) => {
                      const nameValue = e.target.value;
                      setFieldValue("name", nameValue);
                    }}
                    variant="standard"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    name="lastname"
                    id="lastname"
                    value={values.lastname}
                    onChange={(e) => {
                      const lastnameValue = e.target.value;
                      setFieldValue("lastname", lastnameValue);
                    }}
                    variant="standard"
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    InputLabelProps={{
                      style: { paddingBottom: "2px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="gender"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Gender{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    variant="standard"
                    style={{ width: "100%" }}
                    name="gender"
                    id="gender"
                    value={values.gender}
                    onChange={(e) => {
                      const selectedGender = e.target.value;
                      setFieldValue("gender", selectedGender);
                    }}
                    error={touched.gender && Boolean(errors.gender)}
                  >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Age
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="age"
                  
                    id="age"
                    value={values.age}
                    onChange={(e) => {
                      const ageValue = e.target.value;
                      setFieldValue("age", ageValue);
                    }}
                    variant="standard"
                    error={touched.age && Boolean(errors.age)}
                    helperText={touched.age && errors.age}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmnet"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Department{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      const selectedDepartmentObj =
                        department.find(
                          (obj) => obj.code === departmentvalue
                        ) || {};

                      getDesignationData(e.target.value);
                      setFieldValue("department", selectedDepartmentObj);

                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>

                  <ErrorMessage
                    name="departmentvalue"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.designationvalue && touched.designationvalue}
                >
                  <InputLabel
                    htmlFor="designationvalue"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={values.designationvalue}
                    onChange={(e) => {
                      const designationvalue = e.target.value;
                      //values.designation = newValue;
                      const designationobj =
                        designation.find(
                          (obj) => obj.code === designationvalue
                        ) || {};

                      setFieldValue("designation", designationobj);
                      setFieldValue("designationvalue", designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designationList.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>

                  <ErrorMessage
                    name="designationvalue"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>Experience(Optional)</span>
                    name="experience"
                 
                    id="experience"
                    value={values.experience}
                    onChange={(e) => {
                      const experienceValue = e.target.value;
                      setFieldValue("experience", experienceValue);
                    }}
                    variant="standard"
                    error={touched.experience && Boolean(errors.experience)}
                    helperText={touched.experience && errors.experience}
                    onKeyDown={(e) => {
                      if (
                        !/[0-9\b\t]/.test(e.key) &&
                        ![
                          "ArrowLeft",
                          "ArrowRight",
                          "ArrowUp",
                          "ArrowDown",
                          "Delete",
                          "Backspace",
                          "Tab",
                        ].includes(e.key)
                      ) {
                        e.preventDefault();
                      }
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>Description</span>
                    name="description"
                    type="text"
                    id="description"
                    value={values.description}
                    onChange={(e) => {
                      const descriptionValue = e.target.value;
                      setFieldValue("description", descriptionValue);
                    }}
                    variant="standard"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Phone Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="Phonenumber"
                 
                    id="Phonenumber"
                    value={values.Phonenumber}
                    onChange={(e) => {
                      const PhonenumberValue = e.target.value;
                      setFieldValue("Phonenumber", PhonenumberValue);
                    }}
                    variant="standard"
                    error={touched.Phonenumber && Boolean(errors.Phonenumber)}
                    helperText={touched.Phonenumber && errors.Phonenumber}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Email
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={(e) => {
                      const emailValue = e.target.value;
                      setFieldValue("email", emailValue);
                    }}
                    variant="standard"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label=<span>
                      Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={(e) => {
                      const passwordValue = e.target.value;
                      setFieldValue("password", passwordValue);
                    }}
                    variant="standard"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label=<span>
                      Confirm Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={(e) => {
                      const confirmPasswordValue = e.target.value;
                      setFieldValue("confirmPassword", confirmPasswordValue);
                    }}
                    variant="standard"
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
            <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="info">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
}

export default CreateMic;
