import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./footerNavbar.css";
import Home from "../assets/images/Home.svg";
import Category from "../assets/images/category.svg";
import M from "../assets/images/M.svg";
import Profile from "../assets/images/Profile.svg";
import Footernavbarimage from "./footernavbarimage";
import CategoryNavbar from "./Sidebar/CategoryNavbar";

const FooterNavbar = ({pagelabel}) => {
  const [showSideNav, setShowSideNav] = useState(false);
  const [contentType, setContentType] = useState(null);

  const handleHomeClick = () => {
    console.log("Home image clicked");
  };

  const handleCategoryClick = () => {
    console.log("Category image clicked");
    toggleDrawer("left", true, "category");
  };

  const handleMClick = () => {
    console.log("M image clicked");
  };

  const handleProfileClick = () => {
    console.log("Profile image clicked");
    toggleDrawer("left", true, "profile");
  };

  const toggleDrawer = (anchor, open, contentType) => {
    setShowSideNav(open);
    setContentType(contentType);
  };

  const imageList = [
    { image: Home, onClick: handleHomeClick },
    { image: Category, onClick: handleCategoryClick },
    { image: M, onClick: handleMClick },
    { image: Profile, onClick: handleProfileClick },
  ];

  return (
    <div>
      <Grid container className="footernavgridcontainer" xs={12}>
        {imageList.map((item, index) => (
          <Footernavbarimage
            key={index}
            image={item.image}
            onClick={item.onClick}
          />
        ))}
      </Grid>
      {showSideNav && (
        <CategoryNavbar
          open={showSideNav}
          onClose={() => setShowSideNav(false)}
          contentType={contentType}
          pagelabel={pagelabel}
        />
      )}
    </div>
  );
};

export default FooterNavbar;
