import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import axios from "../../../../axiosinstance";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import CircularProgress from "@mui/material/CircularProgress";
import { updateProject } from "../service/Projectcreate";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import { micappCode } from "static/micappCode";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { FaRegTimesCircle } from "react-icons/fa";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("MIC name is required."),
  state: Yup.string().required("State is required."),
  district: Yup.string().required("District is required."),
  subDistrict: Yup.string().required("Sub district is required."),
  pincode: Yup.string().required("Pincode is required."),
});

const ProjectCreateedit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, selectedUser, openpopup, setopenpopup, handleClose } = props;
  // const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);
  const { department } = useSelector((state) => state.department);
  const [loadingfilter, setLoadingfilter] = useState(true);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const { statelist } = useSelector((state) => state.statelist);
  const [loading, setIsLoading] = useState(false);
  // const { district } = useSelector((state) => state.district);
  const [district, setDistric] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [data, setData] = useState([]);
  const [lattitude, setlattitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [list, setList] = useState([]);

  const initialValues = {
    name: selectedUser?.name,
    description: selectedUser?.desc,
    projecthirarichalcode: selectedUser?.parentproject,
    // project: selectedUser?.projecthirarichalcode,
    state: selectedUser?.pinCode?.state?.code,
    district: selectedUser?.pinCode?.district?.code,
    subDistrict: selectedUser?.pinCode?.subdistrict?.code,
    pincode: selectedUser?.pinCode?.village?.code,
  };

  const onSubmit = async (values) => {
    setIsLoading(true);
    const locationArray = list?.map((item) => [
      parseFloat(item.lattitude),
      parseFloat(item.longitude),
    ]);
 
    const updatedUser = {
      ...selectedUser,
      name: values?.name,
      polygoncoordinates: {
        type: "Polygon",
        coordinates: locationArray,
      },
      desc: values?.description,
      pinCode: values?.pincodeobj ? values?.pincodeobj : selectedUser?.pinCode,
      // projectcode: values.projectobj ? values.projectobj.code : selectedUser.projectcode,
      modifiedby: auth?.profileid,
      modifiedbyname: auth?.name,
      parentproject: values?.projecthirarichalcode,
      projecthirarichalcode: values?.projecthirarichalcode,
    };
  
   const response = await dispatch(updateProject(updatedUser));

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been updated successfully!", {
        icon: "success",
      });
    } else {
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      // handleClose()
    }
  };

  async function getData() {
    try {
      const response = await axios.get(`${APIs.projectServiceApi}/get`);
      setProjectList(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  async function getDistrictData(statecode) {
    const response = await dispatch(getDistrict(statecode));

    setDistric(response?.payload?.data?.dtoList);
    setSubDistrict([]);
    setPincodeList([]);
  }

  async function getSubDistrictData(statecode, districtCode) {
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };

    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList);
    setPincodeList([]);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    setPincodeList(response.payload?.data?.dtoList);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoadingfilter(true);

      try {
        getData();
        dispatch(getState());
        // dispatch(getDepartment());
        const response = await dispatch(
          getDistrict(selectedUser?.pinCode?.state?.code)
        );
        setDistric(response.payload.data.dtoList);

        const data = {
          statecode: selectedUser?.pinCode?.state?.code,
          districtCode: selectedUser?.pinCode?.district?.code,
        };

        const responseDistric = await dispatch(getSubDistrict(data));
        setSubDistrict(responseDistric.payload?.data?.dtoList);

        const datadistric = {
          statecode: selectedUser?.pinCode?.state?.code,
          districtCode: selectedUser?.pinCode?.district?.code,
          subDistrictcode: selectedUser?.pinCode?.subdistrict?.code,
        };

        const responsepincode = await dispatch(getPincode(datadistric));
        setPincodeList(responsepincode.payload?.data?.dtoList);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      } finally {
        setLoadingfilter(false);
      }
    };

    fetchData();

    const formattedCoordinates =
      selectedUser?.polygoncoordinates.coordinates?.map((coord) => ({
        lattitude: coord[0],
        longitude: coord[1],
      }));
    setList(formattedCoordinates || []);
  }, [selectedUser]);

  const addData = () => {
    if (lattitude && longitude) {
      const newEntry = {
        lattitude: lattitude,
        longitude: longitude,
      };

      setList([...list, newEntry]);

      setLongitude("");
      setlattitude("");

      // Update the form values with the new list
      // setData([...list, newEntry] ) ;
    } else {
      swal({
        icon: "warning",
        title: "Incomplete Data",
        text: "Please fill out all fields before adding.",
      });
    }
  };
 
  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };
  const handleDelete = (index) => {
    const newList = [...list];
    newList.splice(index, 1);
    setList(newList);
  };
 return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              {loadingfilter ? (
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : (
                <>
                  <Grid container spacing={3} padding={1}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        type="text"
                        label=<span>
                          MIC Name{" "}
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        variant="standard"
                        fullWidth
                        id="name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        error={touched.name && Boolean(errors.name)}
                        helperText={
                          touched.name && errors.name ? (
                            <p
                              style={{
                                fontWeight: "400",
                                color: "red",
                                fontSize: "12px",
                              }}
                            >
                              {errors.name}
                            </p>
                          ) : null
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDBox>
                        <MDInput
                          type="text"
                          label="Description"
                          variant="standard"
                          fullWidth
                          id="description"
                          name="description"
                          value={values.description}
                          onChange={handleChange}
                          InputLabelProps={{
                            style: { paddingBottom: "2px" },
                          }}
                        />
                      </MDBox>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} padding={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={
                          !!errors.projecthirarichalcode &&
                          touched.projecthirarichalcode
                        }
                      >
                        <InputLabel
                          htmlFor="projecthirarichalcode"
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Parent Hierarchical
                          {/* <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup> */}
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="projecthirarichalcode"
                          id="projecthirarichalcode"
                          value={values.projecthirarichalcode}
                          //onChange={(e) => {
                          //   const project = e.target.value;
                          //   const projectObj =
                          //     projectList.find((obj) => obj.code === project) || {};

                          // console.log("projectObj",projectObj)
                          //   setFieldValue("projectobj", projectObj);
                          //   setFieldValue("project", project);
                          // }}
                          onChange={(e) => {
                            const projecthirarichalcode = e.target.value;
                            setFieldValue(
                              "projecthirarichalcode",
                              projecthirarichalcode
                            );
                          }}
                        >
                          <option value=""></option>
                          {projectList?.map((obj) => (
                            <option key={obj?.id} value={obj?.code}>
                              {obj?.name}
                            </option>
                          ))}
                        </Select>
                        {/* {errors.project && touched.project && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.project}
                  </FormHelperText>
                )} */}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.state && touched.state}
                      >
                        <InputLabel
                          htmlFor="state"
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          State
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="state"
                          id="state"
                          value={values.state}
                          onChange={(e) => {
                            const state = e.target.value;

                            getDistrictData(e.target.value);
                            setFieldValue("state", state);
                            setFieldValue("district", "");
                            setFieldValue("subDistrict", "");
                            setFieldValue("pincode", "");
                          }}
                        >
                          <option value=""></option>
                          {statelist?.map((obj) => (
                            <option key={obj?.id} value={obj?.state?.code}>
                              {obj?.state?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.state && touched.state && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3} padding={1}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.district && touched.district}
                      >
                        <InputLabel
                          htmlFor="district"
                          shrink={Boolean(values.district)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="district"
                          id="district"
                          value={values.district}
                          onChange={(e) => {
                            const districtvalue = e.target.value;
                            setFieldValue("subDistrict", "");
                            setFieldValue("pincode", "");
                            const selectedDistrictobj =
                              district?.find(
                                (obj) => obj.district.code === e.target.value
                              ) || {};
                            getSubDistrictData(
                              selectedDistrictobj.state?.code,
                              selectedDistrictobj.district?.code
                            );
                            setFieldValue("district", districtvalue);
                          }}
                        >
                          <option value=""></option>
                          {district?.map((obj) => (
                            <option key={obj?.id} value={obj?.district?.code}>
                              {obj?.district?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.district && touched.district && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.district}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.subDistrict && touched.subDistrict}
                      >
                        <InputLabel
                          htmlFor="subDistrict"
                          shrink={Boolean(values.subDistrict)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Sub District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="subDistrict"
                          id="subDistrict"
                          value={values.subDistrict}
                          onChange={(e) => {
                            const subDistrictvalue = e.target.value;
                            setFieldValue("pincode", "");
                            const selectedPincodeobj =
                              subdistrict?.find(
                                (obj) =>
                                  obj.subdistrict?.code === e.target.value
                              ) || {};

                            getVillageData(
                              selectedPincodeobj.state?.code,
                              selectedPincodeobj.district?.code,
                              selectedPincodeobj.subdistrict?.code
                            );

                            setFieldValue("subDistrict", subDistrictvalue);
                          }}
                        >
                          <option value=""></option>
                          {subdistrict?.map((obj) => (
                            <option
                              key={obj?.id}
                              value={obj?.subdistrict?.code}
                            >
                              {obj?.subdistrict?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.subDistrict && touched.subDistrict && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.subDistrict}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} padding={1}>
                    <Grid item xs={12} sm={6}>
                      {/* Department Dropdown */}
                      <FormControl
                        fullWidth
                        error={!!errors.pincode && touched.pincode}
                      >
                        <InputLabel
                          htmlFor="pincode"
                          shrink={Boolean(values.pincode)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Pincode
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="pincode"
                          id="pincode"
                          value={values.pincode}
                          onChange={(e) => {
                            const pincode = e.target.value;
                            const selectedPincodeObj =
                              pincodelist.find(
                                (obj) => obj.village.code === e.target.value
                              ) || {};

                            setFieldValue("pincodeobj", selectedPincodeObj);
                            setFieldValue("pincode", pincode);
                          }}
                        >
                          <option value=""></option>
                          {pincodelist?.map((obj) => (
                            <option key={obj?.id} value={obj?.village?.code}>
                              {obj?.village?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.pincode && touched.pincode && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.pincode}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <MDTypography
                    variant="h6"
                    fontWeight="small"
                    color="black"
                    mt={1}
                  >
                    Add Location
                  </MDTypography>
                  <Grid container spacing={3} padding={1}>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label=<span>
                          Latitude
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="latitude"
                        value={lattitude}
                        placeholder="Enter Latitude"
                        onChange={(e) => setlattitude(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <MDInput
                        variant="standard"
                        type="text"
                        label=<span>
                          Longtitude
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="longtitude"
                        value={longitude}
                        placeholder="Enter Longitude"
                        onChange={(e) => setLongitude(e.target.value)}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      display="flex"
                      justifyContent="flex-end"
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={addData}
                      >
                        Add
                      </MDButton>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "5px",
                        marginRight: "5px",
                      }}
                    >
                      <DataTable
                        imageMaxWidth={"100px"}
                        canSearch={false}
                        showTotalEntries={false}
                        pagination={false}
                        entriesPerPage={false}
                        table={{
                          columns: [
                            { Header: "lattitude", accessor: "lattitude" },
                            { Header: "longitude", accessor: "longitude" },
                            {
                              Header: "Actions",
                              accessor: "actions",
                              Cell: ({ row }) => (
                                <span>
                                  <FaRegTimesCircle
                                    style={{
                                      color: "#f44336",
                                      width: "25px",
                                      height: "25px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleDelete(row.index)}
                                  />
                                </span>
                              ),
                              width: "15%",
                            },
                          ],
                          rows: list?.map((item, index) => ({
                            ...item,
                          })),
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ProjectCreateedit;
