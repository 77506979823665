import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import SectionTwo from "./SectionTwo";
import "./navbar.css";
import SectionOne from "./SectionOne";
import { Link } from "react-router-dom";

const FirstPage = ({ pagelabel }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [indicatorPosition, setIndicatorPosition] = useState({
    left: 0,
    width: 0,
  });

  let data2 =[]

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1300);
    };
    window.addEventListener("resize", handleResize);
    const initialSelectedItem =
      data.find((item) => item.label === pagelabel) ||
      data2.find((item) => item.label === pagelabel);
    if (initialSelectedItem) {
      const targetElement = document.querySelector(
        `.section2item4[data-label="${initialSelectedItem.label}"]`
      );
      if (targetElement) {
        setIndicatorPosition({
          left: targetElement.offsetLeft,
          width: targetElement.offsetWidth,
        });
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pagelabel]);


  const data = [
    {
      label: "Find Doctors",
      navigate: "/app/home/find-doctors",
    },
    {
      label: "Online Consultation",
      navigate: "/app/home/online-consultation",
    },
    {
      label: "Lab Test",
      navigate: "/app/home/lab-test",
    },
    {
      label: "Medicines",
      navigate: "/app/home/medicine",
    },
    {
      label: "Medharva Care",
      navigate: "/app/home/medharva-care",
    },
    {
      label: "For Corporates",
      navigate: "/app/home/for-corporates",
    },
    {
      label: "For Providers",
      navigate: "/app/home/for-providers",
    },
  ];

  return (
    <div className="rootnavbar">
      <Grid container className="navbarcontainer1">
        <SectionOne />
      </Grid>
      <Grid container mt={2}>
        <SectionTwo />
      </Grid>
      <Grid container className="navbarcontainer3">
        <Grid item xs={11}>
          <hr className="navbarseperator" />
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : 10}
          mt={0} 
          mb={1.5}
          className="section2item3"
        >
          {data.map((item, index) => (
            <Link
              to={item.navigate}
              key={index}
              data-label={item.label}
              className="section2item4"
            >
              {item.label}
            </Link>
          ))}
          {data2.map((item, index) => (
            <a
              href={item.navigate}
              key={index}
              data-label={item.label}
              className="section2item4"
            >
              {item.label}
            </a>
          ))}
          {pagelabel && (
            <div
              className="indicator"
              style={{
                left: indicatorPosition.left,
                width: indicatorPosition.width,
              }}
            ></div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FirstPage;