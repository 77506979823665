// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { MdDelete } from "react-icons/md";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import { useDispatch, useSelector } from "react-redux";
// //import { brandList, deleteBrand } from "Services/Brand Service/Brand";
// import CircularProgress from "@mui/material/CircularProgress";
// import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";

// //edit icon
// import { FaRegEdit } from "react-icons/fa";
// // import { deleteProjectTeam, getProjectTeam } from "../service/ProjectTeam";
// // import CreateProjectTeamPopup from "./CreateProjectTeamPopup";
// import MDButton from "components/MDButton";
// import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
// import CreateProjectTeamPopup from "./CreateProjectTeamPopup";
// import { deleteProjectTeam, getProjectTeam } from "../service/ProjectTeam";
// import ProjectTeamedit from "./ProjectTeamedit";
// import { MdOutlineLibraryAdd } from "react-icons/md";
// import {
//   Autocomplete,
//   FormControl,
//   Grid,
//   Select,
//   TextField,
// } from "@mui/material";
// import { getMicListAction } from "redux/Actions/micListAction";
// import { APIs } from "Services/APIs";
// import { micappCode } from "static/micappCode";
// import { tableDataAction } from "redux/Actions/tableDataAction";

// function ProjectTeamList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { projectTeam, loading } = useSelector((state) => state.projectTeam);
//   const { tableData, AuthReducer, tableActionRow } = useSelector(
//     (state) => state
//   );
//   // const [loading, setLoading] = useState(true);
//   const { getMicList } = useSelector((state) => state);

//   const [openpopup, setopenpopup] = useState(false);
//   const [openpopupProjectTeam, setopenpopupProjectTeam] = useState(false);

//   const [selectedProjectTeam, setSelectedProjectTeam] = useState(null);

//   const [selectedProject, setSelectedProject] = useState(null);

//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     justifyContent: "space-between",
//     alignItems: "center",
//   };

//   const defaultPropProject = {
//     options: !getMicList?.list
//       ? [{ name: "Loading...", id: 0 }]
//       : getMicList?.list,

//     getOptionLabel: (option) => option?.name,

//     isOptionEqualToValue: (option, value) => option.id === value.id,
//   };

//   function editProjectTeam(item) {
//     setopenpopup(true);
//     setSelectedProjectTeam(item);
//   }

//   function onDelete(item) {
//     swal({
//       title: "Are you sure?",
//       text: "You will not be able to recover this item!",
//       icon: "warning",
//       buttons: ["Cancel", "Delete"],
//       dangerMode: true,
//     }).then((willDelete) => {
//       if (willDelete) {
//         swal("Deleting...", {
//           icon: "info",
//           buttons: false,
//           closeOnClickOutside: false,
//         });
//         dispatch(deleteProjectTeam(item))
//           .then(() => {
//             swal("Deleted!", "Your item has been deleted.", "success");
//             if (
//               selectedProject === null ||
//               selectedProject === undefined
//             ) {
//               dispatch(
//                 tableDataAction({
//                   url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}`,
//                 })
//               );
//             } else {
//               dispatch(
//                 tableDataAction({
//                   url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}&projectcode=${selectedProject}`,
//                 })
//               );
//             }
//           })
//           .catch((error) => {
//             swal(
//               "Error",
//               "An error occurred while deleting the item.",
//               "error"
//             );
//           });
//       } else {
//         swal("Cancelled", "Your item is safe.", "info");
//       }
//     });
//   }

//   useEffect(() => {
//     dispatch(
//       getMicListAction({
//         url: `${APIs?.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}`,
//       })
//     );
//     dispatch(
//       tableDataAction({
//         url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}`,
//       })
//     );
//   }, [dispatch]);

//   const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
//   const iconStyleedit = {
//     fontSize: "1.5em",
//     color: "#039147",
//     marginRight: "9px",
//   };

//   const handleEdit = () => {
//     setopenpopup(false);
//   };

//   return (
//     <DashboardLayout>
//       <MDBox>
//         <MDBox>
//           <Card>
//             <MDBox pl={3} pr={3} mt={3} mb={3} lineHeight={1}>
//               <div style={addButtonStyle}>
//                 <span
//                   style={{
//                     alignSelf: "center",
//                   }}
//                 >
//                   <MDTypography variant="h5" fontWeight="medium">
//                     MIC Team Master
//                   </MDTypography>
//                 </span>

//                 <span
//                   style={{
//                     alignSelf: "start",
//                     color: "#344767",
//                   }}
//                 >
//                   <MDButton
//                     color="success"
//                     onClick={() => setopenpopupProjectTeam(true)}
//                   >
//                     ADD
//                   </MDButton>
//                 </span>
//               </div>
//             </MDBox>
//             <Grid item xs={12} sm={2.5} ml={3} mr={3}>
//               <Autocomplete
//                 disablePortal
//                 style={{ width: "100%" }}
//                 {...defaultPropProject}
//                 onChange={(_, newValue) => {
//                   const selectedproj = newValue?.code;
//                   setSelectedProject(selectedproj);
//                   if (
//                     selectedproj === null ||
//                     selectedproj === undefined
//                   ) {
//                     dispatch(
//                       tableDataAction({
//                         url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}`,
//                       })
//                     );
//                   } else {
//                     dispatch(
//                       tableDataAction({
//                         url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}&projectcode=${selectedproj}`,
//                       })
//                     );
//                   }
//                 }}
//                 renderInput={(params) => (
//                   <TextField {...params} label="Select MIC Team" />
//                 )}
//               />
//             </Grid>
//             {loading ? (
//               <div
//                 style={{
//                   display: "flex",
//                   justifyContent: "center",
//                   alignItems: "center",
//                   height: "100vh",
//                 }}
//               >
//                 <CircularProgress />
//               </div>
//             ) : (
//               <>
//                 <DataTable
//                   canSearch={true}
//                   table={{
//                     columns: [
//                       { Header: "mic name", accessor: "projectname" },
//                       {
//                         Header: "department",
//                         accessor: "projectteams.login.departmentname",
//                       },
//                       {
//                         Header: "designation",
//                         accessor: "projectteams.login.designationname",
//                       },
//                       { Header: "name", accessor: "projectteams.login.name" },
//                       // { Header: "name", accessor: (row) => `${row.projectteams.login.firstname} ${row.projectteams.login.lastname}` },
//                       {
//                         Header: "role",
//                         accessor: "projectteams.login.rolename",
//                       },
//                       {
//                         Header: "Action",
//                         accessor: "action",

//                         Cell: ({ row }) => (
//                           <div>
//                             <Link to={{}}>
//                               <FaRegEdit
//                                 style={iconStyleedit}
//                                 onClick={() => {
//                                   editProjectTeam(row.original);
//                                 }}
//                               />
//                             </Link>

//                             <Link to={{}}>
//                               <MdDelete
//                                 style={iconStyle}
//                                 onClick={() => {
//                                   onDelete(row.original);
//                                 }}
//                               />
//                             </Link>
//                           </div>
//                         ),
//                       },
//                     ],
//                     rows: tableData?.rowData?.dtoList || [],
//                   }}
//                 />
//               </>
//             )}
//           </Card>
//         </MDBox>
//       </MDBox>
//       {openpopup && (
//         <ProjectTeamedit
//           openpopup={openpopup}
//           setopenpopup={setopenpopup}
//           title={"MIC Team Edit"}
//           selectedProjectTeam={selectedProjectTeam}
//           handleClose={handleEdit}
//           selectedProject={selectedProject}
//         />
//       )}

//       {openpopupProjectTeam && (
//         <CreateProjectTeamPopup
//           openpopupProjectTeam={openpopupProjectTeam}
//           setopenpopupProjectTeam={setopenpopupProjectTeam}
//           selectedProject={selectedProject}
//         />
//       )}
//     </DashboardLayout>
//   );
// }

// export default ProjectTeamList;

import {
  Button,
  Card,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../../axiosinstance";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { deleteDepartmentData } from "redux/Actions/departmentAction";
import { deleteDesignationData } from "redux/Actions/designationAction";
import { micappCode } from "static/micappCode";
import { deleteProjectTeamData } from "redux/Actions/projectTeamAction";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import CreateProjectTeamPopup from "./CreateProjectTeamPopup";
import ProjectTeamedit from "./ProjectTeamedit";

const TeamAssociationList = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(5);
  const location = useLocation();
  const [selectedUser, setselectedUser] = useState(null);
  const [openpopup, setopenpopup] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [openpopupProjectTeam, setopenpopupProjectTeam] = useState(false);
  const [selectedProjectTeam, setselectedProjectTeam] = useState(null);
  const nameFilter = new URLSearchParams(location.search).get("name");
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
    {
      type: "select",
      placeholder: "Select MIC",
      variant: "outlined",
      label: "Select MIC",
      name: "selectedProject",
      page: "micteamAssociationadmin",
      options: projectList || [],
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        {/* <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip> */}
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };

  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.projectTeamService}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.projectTeamService}/get?type=${
            micappCode?.mappingcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    // if (filterData?.name || nameFilter) {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/get?type=${
    //         micappCode?.mappingcode
    //       }&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${
    //         nameFilter || filterData.name
    //       }`,
    //     })
    //   );
    // } else {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/get?type=${
    //         micappCode?.mappingcode
    //       }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
    //     })
    //   );
    // }
    handleTeamListData();
  }, [currentPage, perPage]);

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
    return {
      ...row,
      department: row?.projectteams?.login?.departmentname
        ? row?.projectteams?.login?.departmentname
        : "",
      designation: row?.projectteams?.login?.designationname
        ? row?.projectteams?.login?.designationname
        : "",
      role: row?.projectteams?.login?.rolename
        ? row?.projectteams?.login?.rolename
        : "",
      // name: row?.projectteams?.login?.name ? row?.projectteams?.login?.name : "",
      name: row?.projectteams?.login?.name,
      action: actionButtons(row),
    };
  });

  const columns = [
    { id: "name", label: "Name" },
    { id: "projectname", label: "MIC Name" },
    { id: "department", label: "Department" },
    { id: "designation", label: "Designation" },
    { id: "role", label: "Role" },
    { id: "createdbyname", label: "Created By" },
    { id: "action", label: "Action" },
  ];

  const handleSearch = () => {
    setCurrentPage(1);
    handleTeamListData();
    // if (filterData?.name) {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/get?type=${
    //         micappCode?.mappingcode
    //       }&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
    //     })
    //   );
    // }
  };

  const handleClear = () => {
    setFilterData({});
    dispatch(
      tableDataAction({
        url: `${APIs.projectTeamService}/get?type=${
          micappCode?.mappingcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };

  const passData = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.projectTeamService}/get?type=${
          micappCode?.mappingcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };

  const handleCreate = () => {
    setopenpopupProjectTeam(false);
  };

  const handleEdit = () => {
    setopenpopup(false);
  };

  const handleOpenEditDialog = (row) => {
    setselectedProjectTeam(row);
    setopenpopup(true);
  };

  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: `You will not be able to recover this ${row.projectname}!`,
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteProjectTeamData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            handleClear();
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  const handleTeamListData = () => {
    let url = "";

    if (filterData && filterData.selectedProject && filterData.name) {
      url = `${APIs.projectTeamService}/get?type=${
        micappCode?.mappingcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&projectcode=${
        filterData.selectedProject
      }&st=${filterData?.name}`;
    } else if (filterData && filterData.selectedProject) {
      url = `${APIs.projectTeamService}/get?type=${
        micappCode?.mappingcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&projectcode=${
        filterData.selectedProject
      }`;
    } else if (filterData && filterData.name) {
      url = `${APIs.projectTeamService}/get?type=${
        micappCode?.mappingcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${
        filterData?.name
      }`;
    } else {
      url = `${APIs.projectTeamService}/get?type=${
        micappCode?.mappingcode
      }&ispageable=true&page=${currentPage - 1}&size=${perPage}`;
    }
    dispatch(tableDataAction({ url: url }));
  };

  const getProjectList = async () => {
    await axios
      .get(`${APIs?.projectServiceApi}/get?type=${micappCode?.mappingcode}`)
      .then((response) => {
        const data =
          response?.data?.data?.dtoList?.map((item) => ({
            ...item,
            value: item.code,
            label: item.name,
          })) || [];
        setProjectList(data);
      })
      .catch((error) => {
        setProjectList([]);
      });
  };

  useEffect(() => {
    getProjectList();
  }, []);

  return (
    <DashboardLayout>
      {loading ? (
        <Card>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Team Association List
              </MDTypography>
              <MDButton
                color="info"
                onClick={() => setopenpopupProjectTeam(true)}
              >
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />
          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopupProjectTeam && (
        <CreateProjectTeamPopup
          openpopupProjectTeam={openpopupProjectTeam}
          setopenpopupProjectTeam={setopenpopupProjectTeam}
          handleClose={handleCreate}
          passData={passData}
        />
      )}
      {openpopup && (
        <ProjectTeamedit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"MIC Team Edit"}
          selectedProjectTeam={selectedProjectTeam}
          handleClose={handleEdit}
          passData={passData}
        />
      )}
    </DashboardLayout>
  );
};

export default TeamAssociationList;
