import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getProjectTeamAction } from "redux/Actions/getProjectTeamAction";

const initialState = {
  team: [],
  loading: false,
  error: null,
};

export const getProjectTeamSlice = createSlice({
  name: "getProjectTeam",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProjectTeamAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProjectTeamAction.fulfilled, (state, action) => {
        state.loading = false;
        state.team = action.payload || [];
        state.error = null;
      })
      .addCase(getProjectTeamAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getProjectTeamSlice.reducer;