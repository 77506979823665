import { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { labappCode } from "static/labappCode";
// import validations from "components/Resuable/Create Project/schemas/validations";
import validations from "components/LabMaster/LabProjectMaster/CreateLabProject/schemas/validations";
// import form from "components/Resuable/Create Project/schemas/form";
import form from "components/LabMaster/LabProjectMaster/CreateLabProject/schemas/form";
// import initialValues from "components/Resuable/Create Project/schemas/initialValues";
import initialValues from "components/LabMaster/LabProjectMaster/CreateLabProject/schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";

import { createProject } from "components/Resuable/Create Project/service/Projectcreate";
import LabprojectcreateInfo from "./labprojectcreateInfo";
import { ROLE_CODE } from "privateComponents/codes";

function getSteps() {
  return ["Lab Create Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <LabprojectcreateInfo formData={formData} />;
    default:
      return null;
  }
}

export default function CreateLabprojectpopup(props) {
  const { openpopupLabCreate, setopenpopupLabCreate, handleClose, passData } =
    props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { error, loading } = useSelector((state) => state.create);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectTeam, getMicDetails, getProjectDetails } = useSelector((state) => state);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  const submitForm = async (values, actions) => {
    if (!values.latitude || !values.longitude) {
      swal("", "Save your location via add location first!", "warning");
      return;
    }
    setIsLoading(true);

    let pincodeobj = {
      ...values.pincodeobj,
      pincode: values.pincodeobj?.village?.name.split("{")[1].split("}")[0],
    };

    const isAdmin = auth?.rolecode === ROLE_CODE?.admincode;

    const requestData = {
      address: `${[
        values.firstline,
        values.secondline,
        values.pincodeobj?.village?.name?.split(" {")[0],
        values.pincodeobj?.subdistrict?.name,
        values.pincodeobj?.district?.name,
        values.pincodeobj?.state?.name,
      ]
        .filter(Boolean)
        .join(", ")}, ${values.country}, ${pincodeobj?.pincode || ""}`,
      name: values.name,
      desc: values.description,
      parentproject: isAdmin 
        ? values.projecthirarichalcode
        : values.projecthirarichalcode
        ? values.projecthirarichalcode
        : getProjectDetails?.details?.code,
      projecthirarichalcode: isAdmin
        ? values?.projecthierarchy
        : values?.projecthierarchy
        ? values?.projecthierarchy
        : getProjectDetails?.details?.projecthirarichalcode,
      pinCode: pincodeobj,
      // mappingcode: isAdmin? "" : getProjectDetails?.details?.projecthirarichalcode,
      mappingcode: isAdmin
      ? values?.projecthierarchy
      : values?.projecthierarchy
      ? values?.projecthierarchy
      : getProjectDetails?.details?.projecthirarichalcode,
      type:  labappCode?.mappingcode,
      location: {
        coordinates: [values.latitude, values.longitude],
        type: "string",
        x: values.latitude,
        y: values.longitude,
      },
      createdby: auth?.profileid,
      createdbyname: auth?.name,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "synced",
    };

    console.log(requestData, "requestData")

    const response = await dispatch(createProject(requestData));
    if (!response.error && !loading) {
      actions.resetForm();

      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });
      passData();
    } else {
      actions.resetForm();
      swal({
        icon: "error",
        title: "Error",
        text: `${error?.message}.`,
      });
      setIsLoading(false);
      handleClose();
    }
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <Dialog
      open={openpopupLabCreate}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 6 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-2}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Create Lab Project
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={5}>
                    <MDBox padding={2}>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={8}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            Back
                          </MDButton>
                        )}
                        <MDButton type="submit" variant="gradient" color="info">
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
