import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import { APIs } from "Services/APIs";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Autocomplete, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import CategoryEdit from "app/Doctor/DoctorWorkspace/category/CategoryEdit";
// import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { FaRegEdit } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";

const DoctorCategoryList = () => {
  const dispatch = useDispatch();
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [code, setCode] = useState(null);
  const [doctorList, setDoctorlist] = useState("");
  const [color] = useState("#344767");
  const [openpopupcategory, setopenpopupcategory] = useState(false);
  const [profileid ,setProfileid] = useState('');
    const [refresh, setRefresh] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dataObj = {
    columns: [
      { Header: "Categoryname", accessor: "category.name" },
      // { Header: "Categoryname", accessor: "category.subcategory" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "#039147",
    marginRight: "9px",
  };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const defaultPropsDoctor = {
    options: !doctorList ? [{ name: "Loading...", id: 0 }] : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  async function onUpdatedata(object) {
    setSelectedUser(object);
    setopenpopupcategory(true);
  }
  const handleClose = () => {
    setopenpopupcategory(false);
  };
  // useEffect(() => {
  //   dispatch(userCategoryAction({ profileid:auth.rolecode === ROLE_CODE.admincode ? profileid :  auth.profileid }));
  // }, [dispatch, auth.profileid, refresh]);

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axios.put(`${APIs.userCatgoryServiceApi}/delete`, item);

          // Update the state by filtering out the deleted item
          setTableDatas((prevData) => {
            const newRows = prevData.rows.filter((row) => row.id !== item.id);
            return { ...prevData, rows: newRows };
          });

          swal("Your Qualification record has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting item:", error);
          swal("Error deleting record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your Qualification is safe!");
      }
    });
  }

  const getRole = async (id) => {
    setLoading(true);

    await axios
      .get(
        `${APIs.userCatgoryServiceApi}/get?profileid=${id}&category.type=CAT`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Tooltip title="Edit">
                <Link to={{}}>
                  <FaRegEdit
                    style={iconStyleedit}
                    onClick={() => {
                      onUpdatedata(item);
                      setopenpopupcategory(true);
                    }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Delete">
                <Link to={{}}>
                  <MdDelete
                    style={iconStyle}
                    onClick={() => {
                      onDelete(item);
                    }}
                  />
                </Link>
              </Tooltip>
            </span>
          );
          dataObj?.rows?.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  async function getDoctorData(code) {
    await axios
      .get(`${APIs.loginServiceApi}/get?rolecode=${code}&recstatus=APPROVED`)
      .then((response) => {
        setDoctorlist(response?.data?.data?.dtoList);

        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }
  useEffect(() => {
    getDoctorData("DO001");
  }, []);
  return (
    <>
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Category List
        </MDTypography>
        <MDBox pl={3} pr={3} lineHeight={1}>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            ></span>
          </div>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
              {...defaultPropsDoctor}
              onChange={(event, newValue) => {
                // console.log(newValue);
                setProfileid(newValue?.profileid)
                getRole(newValue?.profileid);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Doctor" />
              )}
            />
          </Grid>
        </Grid>
        <BarLoader
          color={color}
          loading={loading}
          aria-label="Loading Spinner"
        />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>

      {/* <Footer /> */}
      <br />
      {openpopupcategory && (
        <CategoryEdit
          openpopupcategory={openpopupcategory}
          setopenpopupcategory={setopenpopupcategory}
          title={"Category Edit"}
          selectedUser={selectedUser}
          handleClose={handleClose}
          onUpdate={getRole}
        />
      )}
    </>
  );
};

export default DoctorCategoryList;
