

import React, { useState } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";

import { useFormikContext } from "formik";

import FormField from "../FormField";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  Input,
} from "@mui/material";

function PersonalDetail({ formData }) {
  const { setFieldValue, setFieldError } = useFormikContext(); // Access Formik context

  const { formField, values, errors, touched } = formData;

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFieldValue(name, value);
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;
  };

  const { firstname, lastname,password,repeatPassword, dateofbirth, emailId, phoneno, preferedlanguage } = formField;

  const {
    firstname: firstnameV,
    lastname: lastnameV,
    password:passwordV,
    repeatPassword:repeatPasswordV,
    gender: genderV,
    emailId: emailIdV,
    phoneno: phonenoV,  
    preferedlanguage: preferedlanguageV,
    dateofbirth: dateofbirthV,
  } = values;
  const currentdate = new Date().toISOString().split('T')[0];
  const defaultdate = currentdate

  values.dateofbirth = dateofbirthV || defaultdate;
  return (
    <MDBox>
      {/* <MDBox lineHeight={0}>
        <MDTypography variant="h5">Registration Form</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory information
        </MDTypography>
      </MDBox> */}

      <MDBox >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={firstname.type}
              label=<span>{firstname.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
              name={firstname.name}
              value={firstnameV}
              placeholder={firstname.placeholder}
              error={errors.firstname && touched.firstname}
              success={firstnameV.length > 0 && !errors.firstname}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              type={lastname.type}
              label={lastname.label}
              name={lastname.name}
              value={lastnameV}
              placeholder={lastname.placeholder}
              error={errors.lastname && touched.lastname}
              success={lastnameV.length > 0 && !errors.lastname}
            />
          </Grid>
        </Grid>

    

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type={dateofbirth.type}
              label=<span>{dateofbirth.label} <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
              name={dateofbirth.name}
              value={dateofbirthV}
              placeholder={dateofbirth.placeholder}
              error={errors.dateofbirth && touched.dateofbirth}
              success={dateofbirthV.length > 0 && !errors.dateofbirth}
              InputLabelProps={{
                style: { paddingBottom: "2px", },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth error={!!errors.gender && touched.gender}>
              <InputLabel
                htmlFor="gender"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                <span>Gender <sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="gender"
                id="gender"
                value={genderV}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value=""></option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Select>
              {errors.gender && touched.gender && (
                <FormHelperText error sx={{marginLeft: "0px", fontWeight:"400"}}>{errors.gender}</FormHelperText>
              )}
            </FormControl>
          </Grid>


        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <div style={{ marginTop: window.innerWidth <= 700 ? "-30px" : "auto" }}>
              <FormField
                type={emailId.type}
                label=<span>{emailId.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                name={emailId.name}
                value={emailIdV}
                placeholder={emailId.placeholder}
                error={errors.emailId && touched.emailId}
                success={emailIdV.length > 0 && !errors.emailId}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div style={{ width: window.innerWidth <= 700 ? "65%" : "auto" }}>
              <FormField
                type={phoneno.type}
                label={<span>{phoneno.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>}
                name={phoneno.name}
                value={phonenoV}
                placeholder={phoneno.placeholder}
                error={errors.phoneno && touched.phoneno}
                success={phonenoV.length > 0 && !errors.phoneno}
                onKeyDown={(e) => {
                  if (!/[0-9\b\t]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </div>
          </Grid>
        </Grid>

        
        <Grid container spacing={3}>
      
        <Grid item xs={12} sm={6}>
        <FormField
                type={preferedlanguage.type}
                label=<span>{preferedlanguage.label}<sup style={{ color: "red", fontSize: "small", fontWeight: "bolder", position: "relative", top: "2px" }}> *</sup></span>
                name={preferedlanguage.name}
                value={preferedlanguageV}
                placeholder={preferedlanguage.placeholder}
                error={errors.preferedlanguage && touched.preferedlanguage}
                success={preferedlanguageV.length > 0 && !errors.preferedlanguage}

              />
        </Grid>
      </Grid>
      </MDBox>
    </MDBox>
  );
}

PersonalDetail.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
export default PersonalDetail;
