import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import axios from "../../../../axiosinstance";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import PreviewIcon from "@mui/icons-material/Preview";
import qs from "qs";
// import CreateUserpopop from './components/CreateUserpopop'

import { micappCode } from "static/micappCode";
import MDInput from "components/MDInput";
import {
  deleteLoginServiceData,
  getLoginServiceData,
} from "redux/Actions/getLoginServiceData";
// import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
// import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";

import MDBadgeDot from "components/MDBadgeDot";
// import UserEdit from './UserEdit';
import { pincodeAssociationAction } from "redux/Actions/getPincodeAssociationAction";
import { getProjectTeamAction } from "redux/Actions/getProjectTeamAction";
import { micApprovalListAction } from "redux/Actions/micApprovalListAction";

const ApprovalList = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { tableData, AuthReducer, tableActionRow, getMicDetails, getMicApprovalList } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [openpopupRole, setopenpopupRole] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectdesi, setSelectDesi] = useState("");
  const [item, setItem] = useState({});
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [roleList, setRoleList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const [selectedRole, setSelectRole] = useState("");
  const [selectdep, setSelectdep] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const { department } = useSelector((state) => state.department);
  const { designation } = useSelector((state) => state.designation);
  const { getProjectTeam, getPincodeAssociation } = useSelector(
    (state) => state
  );
  const iconStyleedit = { cursor: "pointer", color: "green" };

  const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);
  // const PER_PAGE = 5;
  const location = useLocation();

  const nameFilter = new URLSearchParams(location.search).get("name");
  const getRole = async () => {
    try {
      const response = await axios.get(
        `${APIs.roleService}/get?mappingcode=${micappCode?.mappingcode}`
      );
      setRoleList(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  // useEffect(async () => {
  //   await dispatch(getProjectTeamAction({ emailId: auth?.emailid }));
  //   await dispatch(
  //     pincodeAssociationAction({
  //       projectCode: getProjectTeam?.team[0]?.projectcode,
  //     })
  //   );
  // }, []);

  // useEffect(() => {
  //   let selectedMic = getPincodeAssociation?.optionList
  //     ?.map((i) => i?.value)
  //     .join("~");
  //   if (selectedMic) {
  //     setFilterData({
  //       ...filterData,
  //       name: nameFilter,
  //       selectedMic: getPincodeAssociation?.optionList?.map((i) => i?.value),
  //     });
  //     if (nameFilter) {
  //       dispatch(
  //         tableDataAction({
  //           url: `${APIs.loginServiceApi}/getByPinCode?ispageable=true&page=${currentPage - 1}&size=${perPage}&pincode.village.shortname=${selectedMic}&st=${nameFilter}`,
  //         })
  //       );
  //     } else {
  //       dispatch(
  //         tableDataAction({
  //           url: `${APIs.loginServiceApi}/getByPinCode?ispageable=true&page=${
  //             currentPage - 1
  //           }&size=${perPage}&pincode.village.shortname=${selectedMic}`,
  //         })
  //       );
  //     }
  //   }
  // }, [getPincodeAssociation]);

  useEffect(() => {
      // if (nameFilter) {
      //   dispatch(
      //     tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) })
      //   );
      // } else {
      //   dispatch(
      //     tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) })
      //   );
      // }
      dispatch(micApprovalListAction({ payload: getPayLoad(filterData?.name) }))
  }, []);

  const generateQueryString = (filters, currentPage, perPage, searchText) => {
    const queryParams = {
      nearLocation: null,
      pageable: {
        page: currentPage - 1,
        size: perPage,
        sortBy: "city",
        sortDirection: "DESC",
      },
      polygonCoordinates: [
        [-73.95, 40.8],
        [-73.94, 40.79],
        [-73.97, 40.76],
        [-73.98, 40.76],
        [-73.95, 40.8],
      ],
      // searchItems: {
      //   city: "Brooklyn Bridge",
      // },
      // searchOnFields: ["string", ""],
      searchText: searchText || "",
    };
    

    // if (filters.selectedMic) {
    //   queryParams.pincode = {
    //     village: {
    //       shortname: filters.selectedMic.join("~"),
    //     },
    //   };
    // }

    return qs.stringify(queryParams, {
      encode: true,
      indices: true,
    });
  };

  useEffect(() => {
    if (nameFilter) {
      dispatch(micApprovalListAction({ payload: getPayLoad(nameFilter) }))
      // dispatch(tableDataAction({url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) }));
    } else {
      dispatch(micApprovalListAction({ payload: getPayLoad(filterData?.name) }))
      // dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) }));
    }
}, [currentPage, perPage]);

  // const handlePincodeurl = () => {
  //   return filterData?.selectedMic?.map((i) => i).join("~");
  // };

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
    // {
    //   type: "multiselect",
    //   placeholder: "Select MIC",
    //   variant: "outlined",
    //   label: "Select Pincode",
    //   name: "selectedMic",
    //   options: getPincodeAssociation?.optionList,
    //   page: "allList",
    // },
  ];

  const actionButtons2 = (row) => {
    return (
      <Stack direction="row" sx={{ display: "flex", justifyContent: "center" }}>
        <FormControlLabel
          name={row.id}
          control={
            <Switch
              onClick={(e) =>
                toggler(e.target.checked, row, roleList, departmentList)
              }
              defaultChecked={row.recstatus === "APPROVED"}
            />
          }
          label="Approved"
        />
      </Stack>
    );
  };
  const viewButton = (row) => {
    return (
      <Stack direction="row" sx={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="View">
          <PreviewIcon
            fontSize="large"
            style={iconStyleedit}
            onClick={() =>
              dispatch(setActionTypeRow({ type: "view", data: row }))
            }
          />
        </Tooltip>
      </Stack>
    );
  };

  // const modifiedRows = tableData?.rowData?.dtoList?.map((row) => ({
  const modifiedRows = getMicApprovalList?.tableData?.content?.map((row) => ({
    ...row,
    name: row?.name ?  `${row.name}`: `${row.firstname} ${row.lastname}`,
    action2: actionButtons2(row),
    viewButton: viewButton(row),
  }));

  const columns = [
    { id: "name", label: "Name" },
    { id: "rolename", label: "Role" },
    { id: "mobileno", label: "Phone No" },
    { id: "emailid", label: "Email Id" },
    { id: "recstatus", label: "Status" },
    { id: "action2", label: "Change Status" },
    { id: "viewButton", label: "View" },
  ];

  if (tableActionRow.actionType === "view") {
    const profileid = tableActionRow?.rowData?.profileid;
    dispatch(clearActionOfRow());
    navigate(`/app/mic/view-profile?id=${profileid}`);
  }

  if (tableActionRow.actionType === "edit") {
  }

  const getPayLoad = (filterName) =>{
    return {
      "nearLocation": null,
      "pageable": {
        "page": currentPage -1,
        "size": perPage,
        "sortBy": "city",
        "sortDirection": "DESC"
      },
      "polygonCoordinates": getMicDetails?.details?.polygoncoordinates?.coordinates,
      // "searchItems": {
      //   "city":"Brooklyn Bridge"
      // },
      "searchOnFields": [
        "firstname","lastname"
      ],
      "searchText": filterName
    }
  }

  const handleSearch = () => {
    setCurrentPage(1);
    dispatch(micApprovalListAction({ payload: getPayLoad(filterData?.name) }))
    // if (filterData?.name) {
    //   dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) }));
    // } else {
    //   dispatch(
    //     tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name) })
    //   );
    // }
  };

  const defaultProps = {
    options: roleList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsDepartment = {
    options: departmentList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsDesignation = {
    options: designationList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleClear = () => {
    setFilterData({});
    navigate("/app/mic/approval-list");
    dispatch(micApprovalListAction({ payload: getPayLoad("") }))
    // dispatch(tableDataAction({url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad("") }));
  };

  const handleCloseEditDialog = () => {
    setOpen(false);
  };
  const toggler = async (checked, item, roleIdList, DeptIdList) => {
    try {
      const updatedUser = {
        ...item,
        isactive: "ACTIVE",
        password: Math.random().toString(36).slice(-8),
        recstatus: checked ? "APPROVED" : "NOT-APPROVED",
        resourcecode: "HEALTHCARE",
      };

      await axios.put(
        `${APIs.loginServiceApi}/update`,
        updatedUser
      );

      dispatch(micApprovalListAction({ payload: getPayLoad(filterData?.name) }))
      // dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/getByPinCode`, payload: getPayLoad(filterData.name)}));
    } catch (error) {
      swal({
        icon: "error",
        title: "Error",
        text: "Something went wrong!",
      });
      console.error(error);
    }
  };
  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "5%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "2%" }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                Approval List
              </MDTypography>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />

          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={getMicApprovalList?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
    </DashboardLayout>
  );
};
export default ApprovalList;
