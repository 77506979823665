import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const aggregationListAction = 
   createAsyncThunk("getAggregationList", async(data, {rejectWithValue}) => {
   
    let url;
  
    

  try {
    if(data && data?.date){
      url = `${APIs.aggregationServiceApi}/get?mmucode=${data?.profileId}&recstatus=ASSIGNED&modifieddate=${data?.date}&ispageable=true&page=${data?.page}&size=${data?.size}`
  } else{
        url = `${APIs.aggregationServiceApi}/get?mmucode=${data?.profileId}&recstatus=ASSIGNED&ispageable=true&page=${data?.page}&size=${data?.size}`
    }
      let respData = await getAllUsers(url, "")
      return respData;

  } catch (error) {
    return rejectWithValue(error);
  }
});





