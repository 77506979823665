import React, { useEffect } from 'react'


import MDBox from 'components/MDBox';
import BankDetail from 'components/Resuable/InvestmnetPanel/BankDetail/BankDetail';
import ProfileInformation from 'components/Shared/profileInformation/ProfileInformation';
import DashboardLayout from 'components/DashboardLayout';
import { useDispatch } from 'react-redux';
import { getProjectTeamAction } from 'redux/Actions/getProjectTeamAction';
import { useSelector } from 'react-redux';
import { micDetailsAction } from 'redux/Actions/micDetailsAction';
import { MMUDetailsAction } from 'redux/Actions/mmuDetailsAction';

const MicProfile = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectTeam, getMicDetails } = useSelector(state => state);
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getProjectTeamAction({ emailId:auth.emailid }))
    dispatch(micDetailsAction({ profileId: auth?.profileid }))
  },[]);

  useEffect(()=>{
    dispatch(MMUDetailsAction({ createdby: auth.createdby }));
  },[auth]);

  console.log(getMicDetails, "getMicDetails 23");

  return (
    <>
    <DashboardLayout>
        <ProfileInformation />
       <MDBox px={2.5}>
       <BankDetail/>
       </MDBox>
       </DashboardLayout>
    </>
  )
}

export default MicProfile;