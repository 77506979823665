import { Grid } from "@mui/material";
import React from "react";
import "./conditionBasedTest.css";
import DiabetiesScreening from "../../../../../assets/images/diabetiesscreening.jpg";
import LiverFunction from "../../../../../assets/images/liverfunction01.jpg";
import KidneyFunction from "../../../../../assets/images/kidneyfunction.jpg";

const ConditionBasedTest = () => {
  const conditionBasedTestdata = [
    {
      image: DiabetiesScreening,
      title: "Diabetes Screening",
      startingprice: "Starting at ₹150",
    },
    {
      image: LiverFunction,
      title: "Liver Function",
      startingprice: "Starting at ₹150",
    },
    {
      image: KidneyFunction,
      title: "Kidney Function",
      startingprice: "Starting at ₹150",
    },
  ];
  return (
    <div className="conditionBasedTestroot">
      <Grid container pt={3} ml={7} className="conditionBasedTest">
        Condition based test :
      </Grid>
      <Grid
        container
        xs={11}
        ml={7}
        mt={7}
        className="conditionBasedTestcontainerparent"
      >
        {conditionBasedTestdata.map((item, index) => (
          <Grid key={index} container xs={3} className="conditionBasedTestcontainer">
            <Grid item xs={12} className="conditionBasedTestcontainer2">
              <img
                src={item.image}
                alt="diabeties logo"
                className="conditionBasedTestimg"
              />
            </Grid>
            <Grid item xs={12} ml={3} className="conditionBasedTestitem">
              {item.title}
            </Grid>
            <Grid item xs={6} ml={3} className="conditionBasedTestitem1">
              {item.startingprice}
            </Grid>
            <Grid container xs={12} className="conditionBasedTestitem2">
              <button className="conditionBasedTestbutton">Know More</button>
              <button className="conditionBasedTestbutton">Book now</button>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid item mt={7} className="conditionBasedTestcontainer2">
      <button className="conditionBasedTestcontainer2button">View all</button>
      </Grid>
    </div>
  );
};

export default ConditionBasedTest;
