import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import pharmy from '../../.././findDoctor/assets/images/Pharmycist.svg'
import './pharmycist.css'
import * as Yup from 'yup';
import { MoonLoader } from "react-spinners";
import axios from "../../../../axiosinstance";



const Pharmycist = () => {
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    PharmacyName: Yup.string().required('Pharmacy Name is required'),
    PhoneNumber: Yup.string().required('Phone Number is required'),
    Pharmacylicense: Yup.string().required('Pharmacy License is required'),
    NumberOfStores: Yup.number().required('Number of Stores is required'),
    PrimaryAddressLocations: Yup.string().required('Primary Address / Locations is required'),
    AboutPharmacy: Yup.string().required('About Pharmacy is required'),
  });

  const handleRequestCallback = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    const formData = {
        name: values.PharmacyName,
        age: values.Pharmacylicense,
        emailid: values.NumberOfStores,
        isactive: "INACTIVE",
        rolename: "PHARMACY",
        rolecode: "PH257",
        description:values.PrimaryAddressLocations,    
        mobileno: values.PhoneNumber,
        experience:values.AboutPharmacy,
        profileid: "string",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
        createdby:"",
      };
      await axios
      .post("login-service-mongo/api/loginservice/v2/create", formData)

      .then((response) => {
        swal(
          "Registration Successful!",
          "Your account has been created successfully.",
          "success"
        );
        console.log(values);
        resetForm();
      })
      .catch((error) => {
        console.log(error);
        swal("Oops!", 
        (error.response && error.response.data && error.response.data.aceErrors) ?
          (error.response.data.aceErrors[0]?.errorCode === "K008" ?
            "User Already Registered with the same Email id !!" :
            error.response.data.aceErrors[0]?.errorMessage) :
          "Request failed with status code 404.",
        "warning"
      );
      
    
    resetForm();
    setIsLoading(false);
});
setSubmitting(false);
setIsLoading(false);
};
  return (
    <div className='medharwa-singup-phramyicist-lap-a'>
      <Grid className='medharwa-singup-phramyicist-lap-b' >
      
        <Formik
          initialValues={{
            PharmacyName: '',
            PhoneNumber: '',
            Pharmacylicense: '',
            NumberOfStores: '',
            PrimaryAddressLocations: '',
            AboutPharmacy: ''
          }}
          validationSchema={validationSchema}
          onSubmit={handleRequestCallback}
        >
          <Form >

            <Grid mt={2} htmlFor="PharmacyName" className='label-registraion-phramyicist'>Pharmacy Name</Grid>
            <Field
              type="text"
              id="PharmacyName"
              name="PharmacyName"
              className='input-text-area-phramyicist'
            />
            <ErrorMessage name="PharmacyName" component="div" className="error-message-pharmacy" />
            <Grid mt={2} htmlFor="PhoneNumber" className='label-registraion-phramyicist'>Phone Number</Grid>
            <Field
              type="number"
              id="PhoneNumber"
              name="PhoneNumber"
              className='input-text-area-phramyicist'
            />
            <ErrorMessage name="PhoneNumber" component="div" className="error-message-pharmacy" />
            <Grid mt={2} htmlFor="Pharmacylicense " className='label-registraion-phramyicist'>Pharmacy license </Grid>

            <label className='input-text-area-phramyicist-Upload-documents-11' htmlFor="idCard"><input className='input-text-area-phramyicist-Upload-documents1-11' type="file" name="idCard" id="idCard" accept="image/*,.pdf" />Upload documents</label>
            {/* <ErrorMessage name="Pharmacylicense" component="div" className="error-message-pharmacy" /> */}
            <Grid mt={2} htmlFor="NumberOfStores" className='label-registraion-phramyicist'>Number of stores</Grid>
            <Field
              type="number"
              id="NumberOfStores"
              name="NumberOfStores"
              className='input-text-area-phramyicist'
            />
            <ErrorMessage name="NumberOfStores" component="div" className="error-message-pharmacy" />
            <Grid mt={2} htmlFor="PrimaryAddressLocations" className='label-registraion-phramyicist'>Primary Address / Locations</Grid>
            <div className="input-with-logo">
              <button className='pahrmycist-box-a-btn' ><img src={pharmy} alt="Pharmacy Logo" className="input-logo" /></button>
              <Field
                type="text"
                id="PrimaryAddressLocations"
                name="PrimaryAddressLocations"
                className='input-text-area-phramyicist-logo'
              />
              <ErrorMessage name="PrimaryAddressLocations" component="div" className="error-message-pharmacy" />
            </div>

            <Grid mt={2} htmlFor="AboutPharmacy" className='label-registraion-phramyicist'>About Pharmacy</Grid>
            <Field
              as="textarea"
              type="text"
              id="AboutPharmacy"
              name="AboutPharmacy"
              className='input-text-area-phramyicist-about'
            />
            <ErrorMessage name="AboutPharmacy" component="div" className="error-message-pharmacy" />
            {/* Submit Button */}
            <Grid container justifyContent={'center'}>
              {/* <button type="submit" className='singup-aply-btn-phramyicist'>Apply Now </button> */}
              <button type="submit" className="singup-aply-btn-phramyicist" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#FFFFFF" size={20} /> : "Apply Now"}
        </button>
            </Grid>

          </Form>
        </Formik>

      </Grid>
    </div>
  )
}

export default Pharmycist
