import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { getMicListAction } from "redux/Actions/micListAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getMicListSlice = createSlice({
  name: "getMicList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMicListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMicListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        state.error = null;
      })
      .addCase(getMicListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getMicListSlice.reducer;