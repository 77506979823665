import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  CircularProgress,
} from "@mui/material";
import { MoonLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import axios from "../../../.././../../axiosinstance";
import * as Yup from "yup";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import { micappCode } from "static/micappCode";
import { updateProjectTeam } from "../service/ProjectTeam";
import MDButton from "components/MDButton";

const ProjectTeamedit = (props) => {
  const {
    title,
    selectedProjectTeam,
    openpopup,
    setopenpopup,
    handleClose,
    passData,
  } = props;
  const [project, setProject] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Isloading, setIsLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  // const { user, loading4 } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.projectTeam);
  const { getProjectTeam, getMicDetails } = useSelector((state) => state);

  const validationSchema = Yup.object({
    projectname: Yup.string().required("MIC name is required"),
    departmentname: Yup.string().required("Department is required"),
    designationname: Yup.string().required("Designation is required"),
    profilename: Yup.string().required("Profile name is required"),
  });
  console.log("selectedProjectTeam", selectedProjectTeam);

  const formik = useFormik({
    initialValues: {
      projectname: selectedProjectTeam?.projectcode || "",
      departmentname:
        selectedProjectTeam?.projectteams.login.departmentcode || "",
      designationname:
        selectedProjectTeam?.projectteams.login.designationcode || "",
      profilename: selectedProjectTeam?.projectteams.login.id || "",
      projectnamevalue: selectedProjectTeam?.projectcode,
      profilenameobject: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const updatedProject = {
       ...selectedProjectTeam,
        projectcode: values.projectnamevalue
          ? values.projectnamevalue
          : selectedProjectTeam?.projectcode,
        projectname: values.projectnamevalue
          ? values?.projectname
          : selectedProjectTeam.projectname,
        projectteams: values?.profilenameobject
          ? { login: values?.profilenameobject }
          : selectedProjectTeam?.projectteams,
        modifiedby: auth?.profileid,
        modifiedbyname: auth?.name,
      };
    
      const response = await dispatch(updateProjectTeam(updatedProject));
      if (!response.error && !loading) {
        setIsLoading(false);
        handleClose();
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
        passData();
      } else {
        formik.resetForm();
        setIsLoading(false);

        swal({
          icon: "error",
          title: "Error",
          text:
            response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
            "Something wents wrong.",
        });
      }
    
    },
  });

  async function getprofileData(designationcode) {
    await axios
      .get(
        `${APIs.loginServiceApi}/get?designationcode=${designationcode}&mappingcode=${getMicDetails?.details?.projecthirarichalcode}&isactive=ACTIVE`
      )
      .then((response) => {
        setUserList(response?.data?.data?.dtoList);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDesignationData(code) {
    try {
      const response = await axios.get(
        // `${APIs.designationServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
          `${APIs.designationServiceApi}/get`
      );
      setDesignation(response?.data?.data?.dtoList);
      const result = response?.data?.data?.dtoList.filter(
        (designationItem) => designationItem.deptcode === code
      );
 
      if (result?.length > 0) {
        setDesignationList(result);
      } else {
        formik.setFieldValue("designationname", "");
        formik.setFieldValue("profilename", "");
        setDesignationList([]);
        setUserList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    if (selectedProjectTeam) {
      formik.setValues({
        projectname: selectedProjectTeam?.projectcode || "",
        departmentname:
          selectedProjectTeam?.projectteams.login.departmentcode || "",
        designationname:
          selectedProjectTeam?.projectteams.login.designationcode || "",
        profilename: selectedProjectTeam?.projectteams.login.id || "",
        projectnamevalue:
          project.find((obj) => obj?.code === selectedProjectTeam?.projectcode)
            ?.name || "",
        // profilenameobject: userList.find((obj) => obj?.departmentcode === selectedProjectTeam?.projectteams.login.departmentcode) || {},
      });
      getprofileData(selectedProjectTeam?.projectteams.login.designationcode);
      getDesignationData(
        selectedProjectTeam?.projectteams.login.departmentcode
      );
    }
  }, [selectedProjectTeam]);

  async function getData() {
    setLoading(true);
    try {
      let url = `${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}&createdby=${auth?.profileid}`;

      const response = await axios.get(url);

      setProject(response?.data?.data?.dtoList);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }

    try {
      const response = await axios.get(
        // `${APIs.deparmentServiceApi}/get?mappingcode=${micappCode?.mappingcode}`
          `${APIs.deparmentServiceApi}/get`
      );
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    getData();
  }, [dispatch]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {loading ? (
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          ) : (
            <>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="projectname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                      shrink={Boolean(formik.values.projectname)}
                    >
                      MIC Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="projectname"
                      id="projectname"
                      value={formik.values.projectname}
                      onChange={(e) => {
                        const projectname = e.target.value;
                        const projectnamevalue =
                          project.find((obj) => obj?.code === e.target.value) ||
                          {};

                        formik.setFieldValue(
                          "projectnamevalue",
                          projectnamevalue?.name
                        );
                        formik.setFieldValue("projectname", projectname);
                      }}
                    >
                      <option value=""></option>
                      {project?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.projectname &&
                      formik.touched.projectname && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.projectname}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="departmentname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                      shrink={Boolean(formik.values.departmentname)}
                    >
                      Department Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="departmentname"
                      id="departmentname"
                      value={formik.values.departmentname}
                      onChange={(e) => {
                        const departmentvalue = e.target.value;
                        formik.setFieldValue("departmentname", departmentvalue);
                        getDesignationData(departmentvalue);
                      }}
                    >
                      <option value=""></option>
                      {department?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.departmentname &&
                      formik.touched.departmentname && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.departmentname}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="designationname"
                      shrink={Boolean(formik.values.designationname)}
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Designation Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="designationname"
                      id="designationname"
                      value={formik.values.designationname}
                      onChange={(e) => {
                        const designationvalue = e.target.value;
                        formik.setFieldValue("profilenameobject", "");
                        formik.setFieldValue("profilename", "");
                        formik.setFieldValue(
                          "designationname",
                          designationvalue
                        );
                        getprofileData(designationvalue);
                      }}
                    >
                      <option value=""></option>
                      {designationList?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.designationname &&
                    formik.touched.designationname && (
                      <FormHelperText
                        error
                        style={{
                          marginLeft: "0px",
                          fontWeight: 400,
                          marginTop: 5,
                        }}
                      >
                        {formik.errors.designationname}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="profilename"
                      shrink={Boolean(formik.values.profilename)}
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Profile Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="profilename"
                      id="profilename"
                      value={formik.values.profilename}
                      onChange={(e) => {
                        const profilenamevalue = e.target.value;
                        const profilenameobject =
                          userList.find(
                            (obj) => obj?.id === parseInt(profilenamevalue)
                          ) || {};
                        formik.setFieldValue(
                          "profilenameobject",
                          profilenameobject
                        );
                        formik.setFieldValue("profilename", profilenamevalue);
                      }}
                    >
                      <option value=""></option>
                      {userList?.map((obj) => (
                        <option key={obj?.id} value={obj?.id}>
                          {obj?.firstname}&nbsp;{obj?.lastname}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.profilename &&
                      formik.touched.profilename && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.profilename}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <MDButton type="submit" color="info" size="small">
            {Isloading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProjectTeamedit;
