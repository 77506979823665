import Preview from "components/Preview/Preview";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DashboardLayout from "components/DashboardLayout";
import { getAggregated } from "Services/ProfileAggregated";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation} from "react-router-dom";
const ViewProfile = () => {
 
  const [previewData, setPreviewData] = useState({});
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
console.log(data)
  const location = useLocation()
const profileid = location.search?.split("?")[1]?.split("=")[1]

  useEffect(() => {
    // Fetch data from API
    const fetchData = async () => {
      try {
        const response = await getAggregated(
          profileid
        );
        if (!response) {
          throw new Error("No response from API");
        } else {
          setData(response?.data);
        }
      } catch (error) {
        swal("Oops!", "An error occurred while fetching data.", "error");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [profileid]);
  useEffect(() => {
    const newData = {
      agentcomission: data?.profileaggregation?.commission?.agentcomission,
      agentcomissiontype:
        data?.profileaggregation?.commission?.agentcomissiontype,
      // city: data?.profileaggregation?.addresslist[0]?.city,
      country: data?.profileaggregation?.addresslist[0]?.country,
      dateofbirth: data?.profileaggregation?.personalinformation?.dateofbirth,
      // district: data?.profileaggregation?.addresslist[0]?.district,
      document: data?.profileaggregation?.documents,
      emailId: data?.profileaggregation?.personalinformation?.emailid,
      firstline: data?.profileaggregation?.addresslist[0]?.firstline,
      firstname: data?.profileaggregation?.personalinformation?.firstname,
      gender: data?.profileaggregation?.personalinformation?.sex,
      lastname: data?.profileaggregation?.personalinformation?.lastname,
      location: data?.profileaggregation?.addresslist[0]?.location,
      personalinformation: data?.profileaggregation?.professionaldetailslist,
      phoneno: data?.profileaggregation?.personalinformation?.phoneno,
      preferedlanguage: data?.profileaggregation?.personalinformation?.languages,
      qualificationinformation: data?.profileaggregation?.qualificationlist,
      resourcecomission:
        data?.profileaggregation?.commission?.resourcecomission,
      resourcecomissiontype:
        data?.profileaggregation?.commission?.resourcecomissiontype,
      secondline: data?.profileaggregation?.addresslist[0]?.secondline,
      // state: data?.profileaggregation?.addresslist[0]?.city,
      // zip: data?.profileaggregation?.addresslist[0]?.postcode,
      pincodeobj:data?.profileaggregation?.addresslist[0]?.pincode,
    };

    setPreviewData(newData);
  }, [data]);
 

  return (
    <DashboardLayout>
      <Card style={{marginTop:"30px",paddingBottom:"20px"}}>
      
          {loading ? (
            <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
       <CircularProgress />
       
      </div>
          ) : (
            
            <Preview data={previewData} rolename={data?.profileaggregation?.personalinformation?.rolename} />
          )}
     
      </Card>
    </DashboardLayout>
  );
};

export default ViewProfile;
