import { Grid } from "@mui/material";
import React from "react";
import Search from "../../../assets/svg/Search.svg";
import "./mobileNavbar.css"
import MicIcon from "../../../assets/svg/micicon.svg"

const MobileSection3 = () => {
  return (
    <>
      <Grid item className="mobilesection2item3">
      <button className="mobilesection2button1">
          <img src={Search} alt="Search Logo" className="mobilesection2img1" />
        </button>
        <input
          type="text"
          id="mobilesection2input"
          placeholder="Search on Medharva"
          className="mobilesection2input"
        />
        <button className="mobilesection2button1">
          <img src={MicIcon} alt="MicIcon Logo" className="mobilesection2img2" />
        </button>
      </Grid>
    </>
  );
};

export default MobileSection3;
