import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { frequenctMasterListAction } from "redux/Actions/frequencyMasterAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getFrequenctMasterListSlice = createSlice({
  name: "getDiseaseList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(frequenctMasterListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(frequenctMasterListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
        state.error = null;
      })
      .addCase(frequenctMasterListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getFrequenctMasterListSlice.reducer;