import React,{useEffect,useRef} from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";




// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";


// Overview page components
import Header from "layouts/pages/profile/components/Header";


// Images

import lottie from "lottie-web";


import { Card } from "reactstrap";

import Slotbooking from "components/Resuable/doctor-detail/components/Slot";
import CategoryList from "./CategoryList";
import AddressList from "./AddressList";
import DoctorQualification from "components/Resuable/Admin/Doctor Qualification/DoctorQualification";
import DoctorCategoryList from "components/Resuable/Admin/Doctor Qualification/DoctorCategoryList";
import DoctorAddressList from "components/Resuable/Admin/Doctor Qualification/DoctorAddressList";
import DoctorSlot from "components/Resuable/Admin/Doctor Qualification/DoctorSlot";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import Rolecode from "../../../../Rolecode";



function OtherProfile() {
  
  const [profileId] = useState(JSON.parse(localStorage.getItem("login-auth"))?.profileid)
  const [rolecode] = useState(JSON.parse(localStorage.getItem("login-auth"))?.rolecode)
  const code = Rolecode();

 
  
 
  const container = useRef(null);
  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: "svg",
      autoplay: true,
      animationData: require('./data/ambulance.json'),
    });
  }, []);
  
  var oldName = localStorage.getItem("login-auth")
  ? JSON.parse(localStorage.getItem("login-auth")).name : ""

var firstName = oldName.split(' ').slice(0, -1).join(' ');
var lastName = oldName.split(' ').slice(-1).join(' ');
var firstname = firstName;
var lastname = lastName;
var firstname2 = firstname.charAt(0).toUpperCase() + firstname.slice(1);
var lastname2 = lastname.charAt(0).toUpperCase() + lastname.slice(1);


  
  return (
    <DashboardLayout>
    
     { code?.Admincode ||code?.Agent ?  <DashboardNavbar /> : <UpaarNavbar/> }
    
     
      {/* <UpaarNavbar/> */}
      <MDBox mb={2} />
      <Header>
        <MDBox mt={5} mb={2}>
          <Grid container spacing={1}>
           
            <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              <ProfileInfoCard
                title="profile information"
            
                info={{
                  name:  firstname2 + " " + lastname2,
      Role: localStorage.getItem("login-auth")?JSON.parse(localStorage.getItem("login-auth"))?.rolename:"",

                  mobile: localStorage.getItem("login-auth")?JSON.parse(localStorage.getItem("login-auth"))?.mobileno:"",
                  email: localStorage.getItem("login-auth")?JSON.parse(localStorage.getItem("login-auth"))?.emailid:"",
                  location: "INDIA",
                }}
                social={[
                 
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}
              />
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            <Grid item xs={10} xl={7} mt={-7} >
        
            {rolecode==="PA001" ?  <MDBox
                  ref={container}>
                </MDBox>
   : <></>
   }
              
          </Grid>
          </Grid>
        </MDBox>
   
       
      </Header>
      
          
     
    {code?.Doctorcode ? <Qualificationlist />
   : <></>
   }
   {code?.Doctorcode ||code.Pharmacy?  <CategoryList/>
   : <></>
   }
   {code?.Doctorcode ||code.Pharmacy ? <AddressList/>
   : <></>
   }
   {code?.Doctorcode ||code.Pharmacy ? 
    <MDBox mt={5}>
   
    
  <Slotbooking  data={profileId} disabled={true}/>
    
    
     </MDBox>
    :<></>}
       
           
    
    {code?.Admincode? <DoctorQualification />
   : <></>
   }
   {code?.Admincode? <DoctorCategoryList/>
   : <></>
   }
   {code?.Admincode? <DoctorAddressList/>
   : <></>
   }
   {code?.Admincode? 
    <MDBox mt={5}>
 
    
  <DoctorSlot  data={profileId} disabled={true}/>
    
  
     </MDBox>
    :<></>}
                
     

     
    </DashboardLayout>
  );
}

export default OtherProfile;