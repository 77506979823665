import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { micDetailsAction } from "redux/Actions/micDetailsAction";

const initialState = {
  details: {},
  loading: false,
  error: null,
};

export const getMicDetilsSlice = createSlice({
  name: "getMicDetils",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(micDetailsAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(micDetailsAction.fulfilled, (state, action) => {
        state.loading = false;
        state.details = action.payload;
        state.error = null;
      })
      .addCase(micDetailsAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getMicDetilsSlice.reducer;