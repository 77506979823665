import { useState } from "react";

// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Newtype page components
import NurseInfo from "./components/NurseInfo";

// Newlab layout schemas for form and form feilds

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";

import axios from "../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { APIs } from "Services/APIs";


function getSteps() {
  return ["Nurse Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <NurseInfo formData={formData} />;

    default:
      return null;
  }
}

function Createnurse() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    setLoading(true)

    const getData = {
 age: values?.age,
      sex: values?.gender,
emailid: values.email,
      name: values?.lastName ? values.firstName + " " + values.lastName : values.firstName,
      id: null,
      isactive: "INACTIVE",
 rolename: "Nurse",
      rolecode: ROLE_CODE.nursecode,
      logincount: "7",
      mobileno: values.phonenumber,
password: values.password,
 recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",

      createdby: auth.profileid
    };

    await axios
      .post(
        `${APIs?.loginServiceApi}/create`, getData
      )

      .then((response) => {
        setLoading(false)
        swal(
          "Registration Successful!",
          "Your team member created successfully.",
          "success"
        );
        navigate(`/app/${auth.rolename?.toLowerCase()}/my-team`);
       
      })
      .catch((error) => {
        swal("Oops!", 
          (error?.response && error.response?.data && error?.response?.data?.aceErrors) ?
            (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
              "User Already Registered with the same Email id !!" :
              error?.response?.data?.aceErrors?.[0]?.errorMessage) :
            "Request failed with status code 404.",
          "warning"
        );
        setLoading(false)
      });
 

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <>
      <DashboardLayout>
        {/* <UpaarNavbar /> */}
        <MDBox py={3} mb={20} height="65vh">
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", mt: 20 }}
          >
            <Grid item xs={12} lg={6}>
              <Formik
                initialValues={initialValues}
                validationSchema={currentValidation}
                onSubmit={handleSubmit}
              >
                {({ values, errors, touched, isSubmitting }) => (
                  <Form id={formId} autoComplete="off">
                    <Card sx={{ height: "100%" }}>
                      <MDBox mx={2} mt={-3}>
                        <MDBox
                          variant="gradient"
                          bgColor="info"
                          borderRadius="lg"
                          coloredShadow="success"
                          mx={2}
                          mt={-1}
                          p={1}
                          mb={1}
                          textAlign="center"
                        >
                          <MDTypography
                            variant="h4"
                            fontWeight="medium"
                            color="white"
                            mt={2}
                          >
                            Create Nurse
                          </MDTypography>
                          <MDTypography
                            display="block"
                            variant="button"
                            color="white"
                            my={1}
                          >
                            Enter your detail
                          </MDTypography>
                        </MDBox>
                      </MDBox>
                      <MDBox p={3}>
                        <MDBox>
                          {getStepContent(activeStep, {
                            values,
                            touched,
                            formField,
                            errors,
                          })}
                          <MDBox
                            mt={2}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            {activeStep === 0 ? (
                              <MDBox />
                            ) : (
                              <MDButton
                                variant="gradient"
                                color="light"
                                onClick={handleBack}
                              >
                                back
                              </MDButton>
                            )}
                            <MDButton
                              // disabled={isSubmitting}
                              type="submit"
                              variant="gradient"
                              color="info"
                             
                            >
                             {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                            </MDButton>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Card>
                  </Form>
                )}
              </Formik>
            </Grid>
          </Grid>
        </MDBox>

      </DashboardLayout>
      {/* <Grid mt={10} mb={1}>
        <Footer />
      </Grid> */}
    </>
  );
}





export default Createnurse;