import { Button, Grid } from "@mui/material";
import React from "react";
import "./card.css";
import { useNavigate } from "react-router-dom";
const ButtonSlider = () => {
  const navigate = useNavigate();
  return (
    
      <div className="responsive-container">
        <Grid className="responsive-grid">
          <Button onClick={()=>navigate('/app/home/find-doctor/speciality')}>
            <span className="responsive-text" > See all specialties</span>
          </Button>
        </Grid>
      </div>
    
  );
};

export default ButtonSlider;
