
import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: { sno, heading,type,description,schedules,condition,compensation,cgst,sgst,igst },
} = checkout;

const validations = [
  Yup.object().shape({
    [sno.name]: Yup.string().required(sno.errorMsg),
    
  [heading.name]: Yup.string().required(heading.errorMsg),
  [type.name]: Yup.string().required(type.errorMsg),
  [description.name]: Yup.string().required(description.errorMsg),
  [schedules.name]: Yup.string().required(schedules.errorMsg),
  [condition.name]: Yup.string().required(condition.errorMsg),
  [compensation.name]: Yup.string().required(compensation.errorMsg),
  [cgst.name]: Yup.string().required(cgst.errorMsg),
  [sgst.name]: Yup.string().required(sgst.errorMsg),
  [igst.name]: Yup.string().required(igst.errorMsg),


 
  }),
];

export default validations;
