import {
  Box,
  Card,
  Dialog,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import medharvaImage from "assets/images/Medharva-logo.svg";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import React, { useEffect, useRef, useState } from "react";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import axios from "axiosinstance";
import Skeleton from "components/Skeleton/Skeleton";
import { ROLE_CODE } from "privateComponents/codes";
const LabReport = (props) => {
  const { openpopup, setopenpopup, testName, orderNumber, mmucode } = props;
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const { getMMUDetails, getProjectDetails } = useSelector((state) => state);
  const reportRef = useRef(null);

  const [data, setData] = useState([]);
  const [response, setResponse] = useState([]);

  const testname = openpopup ? testName : location?.state?.testname;
  const ordernumber = openpopup ? orderNumber : location?.state?.ordernum;

  const handleClose = () => {
    setopenpopup(false);
  };

  async function get(id, ordernum) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIs.bookingServiceApi}/get?mmu.code=${id}&ordernumber=${ordernum}&type=labtest`
      );

      const dtoList = response?.data?.data?.dtoList;
      setResponse(response?.data?.data?.dtoList);

      if (dtoList && dtoList.length > 0) {
        for (let dto of dtoList) {
          const pricelist = dto?.pricelist;

          if (pricelist && pricelist.length > 0) {
            const pricelistCode = pricelist?.find(
              (obj) => obj?.pricename === testname
            )?.code;

            if (pricelistCode) {
              const finalData = dto.categorylist
                .filter((i) => i.parentdepcode === pricelistCode)
                .map((item) => {
                  const range = item.parametervalue.split(" - ");
                  const minRange = parseFloat(range[0]);
                  const maxRange = parseFloat(range[1]);
                  const value = parseFloat(item.value);
                  if (value < minRange || value > maxRange) {
                    return {
                      ...item,
                      value: (
                        <span style={{ fontWeight: "bold" }}>{item.value}</span>
                      ),
                    };
                  }
                  return item;
                });
              setData(finalData);
            }
          }
        }
      } else {
        console.log("dtoList is empty or undefined.");
      }
    } catch (e) {
      console.log("error", e);
    }
    setLoading(false);
  }

  const handleDownload = () => {
    swal({
      title: "Are you sure?",
      text: `Do you want to download this ${testname} report?`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDownload) => {
      if (willDownload) {
        const element = reportRef.current;

        if (element) {
          const originalOverflow = element.style.overflow;
          const originalWhiteSpace = element.style.whiteSpace;

          element.style.overflow = "visible";
          element.style.whiteSpace = "nowrap";

          const dataTableElements = element.querySelectorAll(
            ".MuiTableContainer-root"
          );
          const originalStyles = [];

          dataTableElements.forEach((dataTableElement) => {
            originalStyles.push({
              overflowX: dataTableElement.style.overflowX,
              overflowY: dataTableElement.style.overflowY,
              maxHeight: dataTableElement.style.maxHeight,
            });

            dataTableElement.style.overflowX = "hidden";
            dataTableElement.style.overflowY = "hidden";
            dataTableElement.style.maxHeight = "none";
          });

          domtoimage
            .toPng(element, { useCORS: true })
            .then((imgData) => {
              const pdf = new jsPDF("p", "mm", "a4");

              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = pdf.internal.pageSize.getHeight();
              const margin = 10;

              const img = new Image();
              img.src = imgData;
              img.onload = () => {
                const imgWidth = pdfWidth - 2 * margin;
                const imgHeight = (img.height * imgWidth) / img.width;

                if (imgHeight > pdfHeight - 2 * margin) {
                  const scaleFactor = (pdfHeight - 2 * margin) / imgHeight;
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth * scaleFactor,
                    imgHeight * scaleFactor
                  );
                } else {
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth,
                    imgHeight
                  );
                }

                pdf.save(`${response?.[0]?.patientobj?.name}_${testname}.pdf`);
              };

              dataTableElements.forEach((dataTableElement, index) => {
                dataTableElement.style.overflowX =
                  originalStyles[index].overflowX;
                dataTableElement.style.overflowY =
                  originalStyles[index].overflowY;
                dataTableElement.style.maxHeight =
                  originalStyles[index].maxHeight;
              });

              element.style.overflow = originalOverflow;
              element.style.whiteSpace = originalWhiteSpace;
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
            });
        } else {
          console.error("Report reference is null.");
        }
      }
    });
  };

  useEffect(() => {
    if (getProjectDetails?.details?.mmucreation?.code) {
      get(getProjectDetails?.details?.mmucreation?.code, ordernumber);
    }
  }, [getProjectDetails?.details?.mmucreation?.code]);

  useEffect(() => {
    if(mmucode){
      get(mmucode, ordernumber);
    }
  },[mmucode])

  const ReportUI = () => {
    return (
      <>
        <div ref={reportRef} id="reportContent">
          <MDBox pl={3} pr={3} lineHeight={1} sx={{ mb: "30px", mt: "20px" }}>
            <img
              src={medharvaImage}
              alt="Watermark"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                opacity: 0.12,
                objectFit: "contain",
                zIndex: 0,
              }}
            />
            <Box mb={1} textAlign="center">
              <Grid
                item
                xs={12}
                sm={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={medharvaImage}
                  alt="Lab Logo"
                  style={{ width: "70px" }}
                />
                <MDTypography variant="h4">
                  {response?.[0]?.doctor?.name}
                </MDTypography>
              </Grid>
            </Box>

            <Divider
              style={{
                margin: "15px 0px",
                width: "100%",
                backgroundColor: "#009147",
                height: "2px",
              }}
            />
            <Box mb={2}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} ml={2}>
                  <MDTypography variant="body2">
                    <strong>Name :</strong>&nbsp;
                    {response?.[0]?.patient?.name}
                  </MDTypography>
                  <MDTypography variant="body2">
                    <strong>Ordernumber :</strong>&nbsp;
                    {response?.[0]?.ordernumber}
                  </MDTypography>
                  {/* <MDTypography variant="body2"><strong>Ref By:</strong> {orderData?.refBy}</MDTypography> */}
                  <MDTypography variant="body2">
                    <strong>Collected :</strong>&nbsp;
                    {new Date(response?.[0]?.modifieddate).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      }
                    )}
                  </MDTypography>
                  <MDTypography variant="body2">
                    <strong>Payment Type :</strong>&nbsp;
                    {response?.[0]?.paymentmethod}
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={5} ml={2}>
                  <MDTypography variant="body2">
                    <strong>Age :</strong>&nbsp;{response?.[0]?.patient?.age}
                  </MDTypography>
                  <MDTypography variant="body2">
                    <strong>Gender :</strong>&nbsp;
                    {response?.[0]?.patient?.sex}
                  </MDTypography>
                  {/* <MDTypography variant="body2"><strong>Reported:</strong> {orderData?.reportedDate}</MDTypography> */}
                  <MDTypography variant="body2">
                    <strong> Status :</strong>&nbsp;{response?.[0]?.recstatus}
                  </MDTypography>
                  <MDTypography variant="body2">
                    <strong>Test Name :</strong>&nbsp;
                    {testname}
                  </MDTypography>
                </Grid>
              </Grid>
            </Box>
            <MDBox mt={4}>
              <Grid sx={{ display: "flex", justifyContent: "center" }}>
                <MDTypography variant="h5" gutterBottom>
                  Test Report
                </MDTypography>
              </Grid>

              <Divider
                style={{
                  margin: "15px 0px",
                  width: "100%",
                  backgroundColor: "#009147",
                  height: "2px",
                }}
              />
              {loading ? (
                <Grid item xs={12} sm={6} ml={2} className="patient-queue-grid">
                  {[...Array(9)].map((_, index) => (
                    <Card key={index}>
                      <Skeleton width="620px" height="20px" />
                    </Card>
                  ))}
                </Grid>
              ) : (
                <DataTable
                  isSorted={false}
                  entriesPerPage={false}
                  canSearch={false}
                  showTotalEntries={false}
                  pagination={false}
                  table={{
                    columns: [
                      { Header: "Name", accessor: "name" },
                      { Header: "Result", accessor: "value" },
                      { Header: "Units", accessor: "parametername" },
                      { Header: "Range", accessor: "parametervalue" },
                      // { Header: "bio. Ref. interval", accessor: "bioRef" },
                    ],
                    rows: data || [],
                  }}
                />
              )}
            </MDBox>
            <Divider
              style={{
                margin: "15px 0px",
                width: "100%",
                backgroundColor: "#009147",
                height: "2px",
              }}
            />
          </MDBox>
        </div>
        <Grid
          mr={2}
          mb={1}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleDownload}
          >
            Download
          </MDButton>
        </Grid>
      </>
    );
  };

  return (
    <>
      {openpopup ? (
        <Dialog open={openpopup} onClose={handleClose} maxWidth="md" fullWidth>
          <ReportUI />
        </Dialog>
      ) : (
        <>
          <MDBox
            mt={
              isSmallScreen
                ? 15
                : auth?.rolecode === ROLE_CODE?.patientcode
                ? 24
                : 10
            }
          >
            <Paper
              elevation={3}
              style={{
                padding: 0,
                margin: "20px auto",
                maxWidth: 900,
                borderRadius: "10px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <ReportUI />
            </Paper>
          </MDBox>
        </>
      )}
    </>
  );
};

export default LabReport;
