import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Footer from "examples/Footer";
import swal from "sweetalert";
import { useLocation } from "react-router-dom";

// import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
import MDTypography from "components/MDTypography";
import axios from "../../../axiosinstance";
// NewUser layout schemas for form and form feilds

import validations from "../components/otp/new-otp/schemas/validations";
import form from "../components/otp/new-otp/schemas/form";
import initialValues from "../components/otp/new-otp/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/otpimg3.png";
import OtpInfo from "../components/otp/new-otp/components/OtpInfo";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";

function getSteps() {
  return ["Otp Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <OtpInfo formData={formData} resettype="FORGET" />;

    default:
      return null;
  }
}

function ForgotPasswordOtp() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();

  // const [code, setCode] = useState(
  //   JSON.parse(localStorage.getItem("user-code")).code
  // );
  const [emailid, setEmailId] = useState(
    JSON.parse(localStorage.getItem("user-code")).emailid
  );
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [isLoading, setIsLoading] = useState(false);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    setIsLoading(true);

    await axios
      .post(
        `${APIs?.otpServiceApiv1}/confirm?otp=${values?.otp}&userid=${emailid}&msgType=FORGET`
        // `http://194.163.159.164:9163/api/Otps/v1/confirm?otp=${values.otp}&userid=${code}`
      )

      .then((response) => {
        setIsLoading(false);
        swal({
          icon: "success",
          title: "OTP Verified!",
          content: {
            element: "div",
            attributes: {
              innerHTML: `
                <p style="text-align: center;">
                  Your OTP has been successfully verified. You can now change or reset your password.
                </p>
              `,
            },
          },
        });
        navigate("/app/home/reset-password", {
          state: {
            email: location,
          },
        });
      })
      .catch((e) => {
        setIsLoading(false);
        swal(
          "Oops!",
          e?.response?.data?.aceErrors?.[0]?.errorMessage,
          "warning"
        );
      });

    actions.setSubmitting(false);
    actions.resetForm();

    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <CoverLayout image={bgImage}>
      <MDBox borderRadius="lg" mx={2} py={2} mb={1} mt={-3}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Grid
            item
            xs={12}
            lg={12}
            style={{ display: "grid", justifyContent: "center" }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ marginTop: "50px", marginLeft: "-30px" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          OTP
                        </MDTypography>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="white"
                          my={1}
                        >
                          Enter your OTP to register
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            fullwidth
                            variant="gradient"
                            color="info"
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Verify"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </CoverLayout>
  );
}

export default ForgotPasswordOtp;
