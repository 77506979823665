import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { APIs } from "Services/APIs";
import MDBox from "components/MDBox";
import { Dialog, FormControlLabel, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";

import MDTypography from "components/MDTypography";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FaRegTimesCircle } from "react-icons/fa";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import axios from "../../../../../axiosinstance";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import axios2 from "axios";
import Checkbox from "@mui/material/Checkbox";
import { ROLE_CODE } from "privateComponents/codes";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MapPopup from "app/agent/registrationResource/components/Map/MapPopup";
import DashboardLayout from "components/DashboardLayout";

function NewAddress(props) {
  const { popup, setPopup,getData} = props;
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("OPEN");

  const [idCounter, setIdCounter] = useState(0);
  const [values, setValues] = useState({
    firstline: "",
    secondline: "",
    district: "",
    country: "",
    city: "",
    postcode: "",
    subdistrict: "",
    state: "",
  });

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  
const {getMMUDetails} = useSelector(state => state)
  const location = useLocation();
  const [openpopup, setopenpopup] = useState(false);
  const [maplocation, setMaplocation] = useState(null);
  const [checked, setChecked] = useState(false);

  const initialValues = {
    firstline: "",
    secondline: "",

    country: "",
    state: "",
    district: "",
    subdistrict: "",
    city: "",
    postcode: "",
    recstatus: "",
  };
  const validationSchema = Yup.object({
    firstline: Yup.string().required("First line is required."),
    secondline: Yup.string().required("Second line is required."),
    country: Yup.string().required("Country is required."),
    state: Yup.string().required("State name is required."),
    district: Yup.string().required("District is required."),
    subdistrict: Yup.string().required("Sub district is required."),
    city: Yup.string().required("City is required."),
    postcode: Yup.string().required("Pincode is required."),
  });
  const handleDataTransfer = (data) => {
    // This function is passed as a prop to the ChildComponent
    // and is called by the child with the data it wants to transfer.
    setMaplocation(data);
  };

  const handleSubmit = async (values, { resetForm }) => {
    // const response = await axios2.get(
    //   `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    //     `${values?.firstline} ${values?.secondline}, ${values?.city}, ${values?.state}, ${values?.country}`
    //   )}&key=AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0`
    // );

    // Extract latitude and longitude from the API response
    // const location = response?.data?.results[0]?.geometry?.location;
    // const { lat, lng } = location;
    if (maplocation === null) {
      swal("Oops", "Please add location first.", "error");
    } else {
      const newData = {
        uid: idCounter,
        ...values,
        id: null,

        recstatus: status,
        status: "ACTIVE",
        syncstatus: "synced",
        latitude: maplocation?.lat,
        longitude: maplocation?.lng,

        profileid:
          auth.rolecode === "AD001"
            ? location?.state?.profileid
            : auth?.profileid,
      };
      setData([...data, newData]);
      setIdCounter(idCounter + 1);
      resetForm();
      setChecked(false);
      setMaplocation(null);
    }
  };

  const removeData = (id) => {
    const newData = data.filter((item) => item.uid !== id);
    setData(newData);
  };

  const columns = [
    { Header: "first line", accessor: "firstline" },
    { Header: "second line", accessor: "secondline" },
    { Header: "village", accessor: "city" },
    { Header: "district", accessor: "district" },
    { Header: "sub district", accessor: "subdistrict" },
    { Header: "state", accessor: "state" },
    { Header: "country", accessor: "country" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        // <button onClick={() => removeData(row.values.id)}>Remove</button>
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row.values.uid)}
            />
          </Link>
        </span>
      ),
      width: "12%",
    },
  ];

  async function finasubmit() {
    setLoading(true);
    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setLoading(false);
    } else {
      const datalist = [];
      for (let i = 0; i < data.length; i++) {
        // delete data[i].uid;
        const dataval = {
          classname: "Address",
          profileid:
            auth.rolecode === ROLE_CODE.admincode
              ? location?.state?.profileid
              : auth?.profileid,
          firstline: data[i]?.firstline,
          secondline: data[i]?.secondline,
          postcode: data[i]?.postcode,
          country: data[i]?.country,
          state: data[i]?.state,
          district: data[i]?.district,
          subdistrict: data[i]?.subdistrict,
          city: data[i]?.city,
          location: {
            coordinates: [data[i]?.latitude, data[i]?.longitude],
            type: "string",
            x: data[i]?.latitude,
            y: data[i]?.longitude,
          },
          id: null,
          mmucode:auth?.rolecode === ROLE_CODE?.labcode || auth?.rolecode === ROLE_CODE?.childlabcode ? getMMUDetails?.data?.mmucode : "",
          recstatus: data[i]?.recstatus,
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        datalist.push(dataval);
      }

      await axios
        .post(`${APIs.addressServiceApi}/createList`, datalist)
        .then((response) => {
          setData([]);
          swal("Good job!", "Address has been Added!", "success");
          handleClose();
          getData(auth?.profileid);
          // navigate(`/app/${auth.rolename?.toLowerCase()}/profile`);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          swal("oops!", "Something wents wrong.");
          setLoading(false);
        });
    }
  } 
  
  const handleClose = () => {
    setPopup(false);
  };
  return (  
    <Dialog open={popup} onClose={handleClose} fullWidth maxWidth="md">
    <>
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100%",
            mt: 3,
          }}
        >
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, setFieldValue, errors, touched }) => (
                <Form>
                 <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={1}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="medium"
                          color="white"
                          mt={2}
                        >
                          Address Information 
                        </MDTypography>
                        <MDTypography
                          display="block"
                          variant="button"
                          color="white"
                          my={1}
                        >
                          Enter your detail
                        </MDTypography>
                      </MDBox>
                      {auth.rolecode === "AD001" ? (
                        <MDTypography variant="h5">
                          Selected Person: {location?.state?.name}
                        </MDTypography>
                      ) : null}
                    </MDBox>
                    <MDBox p={2} mx={2}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                First Line
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="firstline"
                              variant="standard"
                              value={values.firstline}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("firstline", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  firstline: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="firstline"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Second Line
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="secondline"
                              variant="standard"
                              value={values.secondline}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("secondline", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  secondline: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="secondline"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Country
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="country"
                              variant="standard"
                              value={values.country}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("country", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  country: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="country"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                State
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="state"
                              variant="standard"
                              value={values.state}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("state", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  state: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="state"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                District
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="district"
                              variant="standard"
                              value={values.district}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("district", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  district: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="district"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Sub District
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="subdistrict"
                              variant="standard"
                              value={values.subdistrict}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("subdistrict", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  subdistrict: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="subdistrict"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                City/Village
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="text"
                              name="city"
                              variant="standard"
                              value={values.city}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("city", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  city: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="city"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControl fullWidth>
                            <TextField
                              label=<span>
                                {" "}
                                Pincode
                                <sup
                                  style={{
                                    color: "red",
                                    fontSize: "small",
                                    fontWeight: "bolder",
                                    position: "relative",
                                    top: "2px",
                                  }}
                                >
                                  {" "}
                                  *
                                </sup>
                              </span>
                              type="number"
                              name="postcode"
                              variant="standard"
                              value={values.postcode}
                              onChange={(e) => {
                                const nameValue = e.target.value;
                                setFieldValue("postcode", nameValue);
                                setValues((prevState) => ({
                                  ...prevState,
                                  postcode: e.target.value,
                                }));
                              }}
                            />

                            <ErrorMessage
                              name="postcode"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <FormControlLabel
                            label="Select as primary"
                            control={
                              <Checkbox
                                checked={checked}
                                onChange={(event) => {
                                  if (event.target.checked === true) {
                                    setStatus("PRIMARY");
                                    setChecked(event.target.checked);
                                  } else {
                                    setStatus("OPEN");
                                    setChecked(event.target.checked);
                                  }
                                }}
                              />
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ display: "flex", gap: "20px" }}
                        >
                          <MDButton
                            variant="outlined"
                            color="success"
                            size="small"
                            onClick={() => setopenpopup(true)}
                            style={{
                              marginTop: 7,
                              marginLeft: "10px",
                            }}
                          >
                            <RemoveRedEyeIcon />
                            &nbsp; Add Location
                          </MDButton>
                          {maplocation && (
                            <Grid item xs={12} sm={1} mt={1.5}>
                              <CheckCircleIcon fontSize="medium" />
                            </Grid>
                          )}
                        </Grid>

                        <Grid item xs={12} sm={4} mt={1}>
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            size="small"
                          >
                            Add
                          </MDButton>
                        </Grid>
                      </Grid>
                    </MDBox>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      style={{
                        border: "1px solid #727375",
                        borderRadius: "5px",
                        marginTop: "15px",
                        marginBottom: "10px",
                        marginLeft: "10px",
                        marginRight: "10px",
                      }}
                    >
                      <DataTable
                        showTotalEntries={false}
                        entriesPerPage={false}
                        table={{ columns, rows: data }}
                        imageMaxWidth="110px"
                      />
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="flex-end"
                      marginRight="10px"
                      marginBottom="8px"
                    >
                      <MDButton
                        variant="gradient"
                        color="info"
                        onClick={() => finasubmit()}
                      >
                        {loading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "submit"
                        )}
                      </MDButton>
                    </MDBox>
               
               </Card>
               </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      {openpopup ? (
        <MapPopup
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Select Location"}
          onDataTransfer={handleDataTransfer}
          data2={values}
        />
      ) : (
        <></>
      )}
    </>
    </Dialog>
  );
}

export default NewAddress;
