import React, { useEffect, useState } from "react";
import "./specificLocation.css";
import { Grid } from "@mui/material";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Search from "../../findDoctor/assets/images/Search.svg";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { useSelector } from "react-redux";
import axios from "axios";

const SpecificLocation = () => {
  const navigate = useNavigate();
  const Location = JSON.parse(localStorage.getItem("userLocation"));

  const [mapCenter, setMapCenter] = useState({
    lat: Location?.latitude || 28.6139,
    lng: Location?.longitude || 77.209,
  });

  const [searchAddress, setSearchAddress] = useState("");
  const [searchedLocation, setSearchedLocation] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [manualMarkerLocation, setManualMarkerLocation] = useState(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [selectedState, setSelectedState] = useState("");
  const customMarkerImageUrl =
    "https://i.pinimg.com/474x/67/c3/d6/67c3d63e215e034e01d45c8256d720d3.jpg";

  const data = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
  ];

  const stateCoordinates = {
    "Andhra Pradesh": { lat: 15.9129, lng: 79.74 },
    "Arunachal Pradesh": { lat: 28.218, lng: 94.7278 },
    Assam: { lat: 26.2006, lng: 92.9376 },
    Bihar: { lat: 25.0961, lng: 85.3131 },
    Chhattisgarh: { lat: 21.2787, lng: 81.8661 },
    Goa: { lat: 15.2993, lng: 74.124 },
    Gujarat: { lat: 22.2587, lng: 71.1924 },
    Haryana: { lat: 29.0588, lng: 76.0856 },
    "Himachal Pradesh": { lat: 31.1048, lng: 77.1734 },
    Jharkhand: { lat: 23.6102, lng: 85.2799 },
    Karnataka: { lat: 15.3173, lng: 75.7139 },
    Kerala: { lat: 10.8505, lng: 76.2711 },
    "Madhya Pradesh": { lat: 22.9734, lng: 78.6569 },
    Maharashtra: { lat: 19.7515, lng: 75.7139 },
    Manipur: { lat: 24.6637, lng: 93.9063 },
    Meghalaya: { lat: 25.467, lng: 91.3662 },
    Mizoram: { lat: 23.1645, lng: 92.9376 },
    Nagaland: { lat: 26.1584, lng: 94.5624 },
    Odisha: { lat: 20.9517, lng: 85.0985 },
    Punjab: { lat: 31.1471, lng: 75.3412 },
    Rajasthan: { lat: 27.0238, lng: 74.2179 },
    Sikkim: { lat: 27.533, lng: 88.5122 },
    "Tamil Nadu": { lat: 11.1271, lng: 78.6569 },
    Telangana: { lat: 18.1124, lng: 79.0193 },
    Tripura: { lat: 23.9408, lng: 91.9882 },
    "Uttar Pradesh": { lat: 26.8467, lng: 80.9462 },
    Uttarakhand: { lat: 30.0668, lng: 79.0193 },
    "West Bengal": { lat: 22.9868, lng: 87.855 },
  };
  const handleStateChange = (selectedState) => {
    setSearchAddress("");
    formik.setFieldValue("completeAddress", "");

    if (stateCoordinates[selectedState]) {
      const { lat, lng } = stateCoordinates[selectedState];
      setMapCenter({ lat, lng });
    }
  };

  const data2 = [
    {
      heading: "Complete Address",
      placeholder: "Search for city, localities and more",
    },
    {
      heading: "Floor (Optional)*",
      placeholder: "Search for city, localities and more",
    },
    {
      heading: "Landmark (Optional)*",
      placeholder: "Search for city, localities and more",
    },
  ];

  const data3 = ["Home", "Work", "Office", "Other"];

  const [selectedButton, setSelectedButton] = useState("Home");

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onPlaceChanged = () => {
    setIsLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: searchAddress }, (results, status) => {
      if (status === "OK" && results[0]) {
        setLatitude(results[0].geometry.location.lat());
        setLongitude(results[0].geometry.location.lng());

        setMapCenter({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });

        setSearchedLocation({
          lat: results[0].geometry.location.lat(),
          lng: results[0].geometry.location.lng(),
        });

        const addressComponents = results[0].address_components;
        let stateName = "";
        for (let i = 0; i < addressComponents.length; i++) {
          const types = addressComponents[i].types;
          if (types.includes("administrative_area_level_1")) {
            stateName = addressComponents[i].long_name;
            break;
          }
        }

        setSelectedState(stateName);
      } else {
        console.error("Geocoding failed:", status);
      }
      setIsLoading(false);
    });
  };

  const handleSearch = () => {
    onPlaceChanged();
  };

  const handlesearchchange = (e) => {
    formik.setFieldValue("completeAddress", "");
    setSearchAddress(e.target.value);
    setTimeout(() => {
      handleSearch();
    }, 3000);
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);

  const validationSchema = Yup.object().shape({
    completeAddress: Yup.string().required("Complete Address is required"),
  });

  const fetchAreaInformation = async (lat, lng) => {
    setIsLoading(true);
    const tokens = "AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0";
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${tokens}`
      );
      const result = response.data;

      if (result.status === "OK") {
        const addressComponents = result.results[0].address_components;

        // Concatenate all address components to form the full address
        let fullAddress = "";
        for (let component of addressComponents) {
          fullAddress += component.long_name + ", ";
        }

        // Trim any trailing comma and space
        fullAddress = fullAddress.replace(/,\s*$/, "");

        return fullAddress;
      } else {
        throw new Error(
          result.error_message || "Error fetching area information"
        );
      }
    } catch (error) {
      console.error("Error fetching area information:", error);
      return "";
    } finally {
      setIsLoading(false);
    }
  };

  const handleMapClick = async (e) => {
    setIsLoading(true);
    localStorage.removeItem("selectedLocation");
    const clickedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };

    setSearchedLocation(clickedLocation);

    setManualMarkerLocation(clickedLocation);
    const areaInfo = await fetchAreaInformation(
      clickedLocation.lat,
      clickedLocation.lng
    );

    setManualMarkerLocation();

    const areaInfoParts = areaInfo.split(", ");
    const stateName = areaInfoParts[areaInfoParts.length - 3];

    // Check if the state name exists in the data array
    if (data.includes(stateName)) {
      setSelectedState(stateName);
    } else {
      console.log("State not found in the data array.");
    }

    formik.setFieldValue("completeAddress", areaInfo);
    setSearchAddress(areaInfo);
    setIsLoading(false);
  };
  const handleChangecomplete = (event) => {
    formik.handleChange(event);
    setSearchAddress(event.target.value);
  };

  const formik = useFormik({
    initialValues: {
      completeAddress: "",
      floor: "",
      landmark: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const locationToSubmit = manualMarkerLocation || {
        lat: latitude,
        lng: longitude,
      };

      if (!latitude && !longitude) {
        return swal({
          icon: "error",
          title: "Error",
          text: "Please enter full address",
        });
      }
      localStorage.setItem(
        "selectedLocation",
        JSON.stringify(locationToSubmit)
      );
      navigate(`/app/${auth?.rolename?.toLowerCase()}/lab-test-cart`, {
        state: {
          cordinates: locationToSubmit,
        },
      });
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 700);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleChangeconplete = (event) => {
    formik.handleChange(event); // Call formik's handleChange
    setAddress(null); // Call your custom function
  };

  useEffect(() => {
    let timeoutId;

    const handleAddressChange = (address) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: address }, (results, status) => {
        if (status === "OK" && results[0]) {
          setLatitude(results[0].geometry.location.lat());
          setLongitude(results[0].geometry.location.lng());

          setMapCenter({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });

          setSearchedLocation({
            lat: results[0].geometry.location.lat(),
            lng: results[0].geometry.location.lng(),
          });

          const addressComponents = results[0].address_components;
          let stateName = "";
          for (let i = 0; i < addressComponents.length; i++) {
            const types = addressComponents[i].types;
            if (types.includes("administrative_area_level_1")) {
              stateName = addressComponents[i].long_name;
              break;
            }
          }

          setSelectedState(stateName);
        } else {
          console.error("Geocoding failed:", status);
        }
        setIsLoading(false);
      });
    };

    const handleDelayedAddressChange = (address) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      setIsLoading(true);
      timeoutId = setTimeout(() => {
        handleAddressChange(address);
      }, 3000);
    };

    if (formik.values.completeAddress) {
      handleDelayedAddressChange(formik.values.completeAddress);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [formik.values.completeAddress]);

  return (
    <div>
      {isLoading && <div className="loader">Loading...</div>}

      <form onSubmit={formik.handleSubmit} className="specificlocationroot">
        <Grid
          container
          xs={isSmallScreen ? 12 : 10}
          mt={23}
          className="specificlocationcontainer"
        >
          <Grid item mt={5} mb={5} className="specificlocationitem">
            <select
              className="specificlocationinput"
              id="selectedState"
              name="selectedState"
              value={selectedState}
              onChange={(e) => {
                setSelectedState(e.target.value);
                handleStateChange(e.target.value);
              }}
            >
              <option value="">Select any State</option>
              {data?.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </Grid>
          <Grid item className="specificlocationitem2">
            <input
              type="text"
              id="specificlocationinput"
              value={searchAddress}
              placeholder="Search for city, localities and more"
              className="specificlocationinput"
              onChange={(e) => handlesearchchange(e)}
            />
            {/* <button className="specificlocationbutton1" onClick={handleSearch}>
              <img
                src={Search}
                alt="Search Logo"
                className="specificlocationimg1"
              />
            </button> */}
          </Grid>
        </Grid>
        <Grid container xs={isSmallScreen ? 12 : 10}>
          <LoadScript googleMapsApiKey="AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0">
            <GoogleMap
              center={manualMarkerLocation || mapCenter}
              zoom={14}
              mapContainerStyle={{ width: "100%", height: "400px" }}
              // options={{
              //   fullscreenControl: false,
              //   streetViewControl: false,
              //   mapTypeControl: false,
              //   zoomControl: false,
              // }}
              onClick={(e) => {
                handleMapClick(e);
              }}
            >
              {manualMarkerLocation && window.google && window.google.maps && (
                <Marker
                  position={manualMarkerLocation}
                  icon={{
                    url: customMarkerImageUrl,
                    scaledSize: new window.google.maps.Size(30, 30),
                  }}
                />
              )}
              {searchedLocation && <Marker position={searchedLocation} />}
            </GoogleMap>
          </LoadScript>
        </Grid>
        {isSmallScreen ? (
          <Grid container xs={10} mt={2} className="selectyourloc">
            Select your Location
          </Grid>
        ) : (
          <></>
        )}
        <Grid
          container
          xs={isSmallScreen ? 10 : 8}
          mt={2}
          className="specificlocationlabel"
        >
          <div className="specificlocationheading">Complete Address</div>
          <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
            <input
              type="text"
              id="completeAddress"
              name="completeAddress"
              className={`specificlocationlabelinput ${
                formik.touched.completeAddress && formik.errors.completeAddress
                  ? "error"
                  : ""
              }`}
              placeholder="Search for city, localities and more"
              onChange={handleChangecomplete}
              onBlur={formik.handleBlur}
              value={formik.values.completeAddress}
            />
          </Grid>
          {formik.touched.completeAddress && formik.errors.completeAddress ? (
            <div
              className="error-message"
              style={{
                fontWeight: "400",
                color: "red",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              {formik.errors.completeAddress}
            </div>
          ) : null}
        </Grid>
        <Grid
          container
          xs={isSmallScreen ? 10 : 8}
          mt={2}
          className="specificlocationlabel"
        >
          <div className="specificlocationheading">Floor (Optional)*</div>
          <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
            <input
              type="text"
              id="floor"
              name="floor"
              className="specificlocationlabelinput"
              placeholder="Search for city, localities and more"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.floor}
            />
          </Grid>
        </Grid>
        <Grid
          container
          xs={isSmallScreen ? 10 : 8}
          mt={2}
          className="specificlocationlabel"
        >
          <div className="specificlocationheading">Landmark (Optional)*</div>
          <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
            <input
              type="text"
              id="landmark"
              name="landmark"
              className="specificlocationlabelinput"
              placeholder="Search for city, localities and more"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.landmark}
            />
          </Grid>
        </Grid>
        <Grid container xs={isSmallScreen ? 10 : 9} mt={isSmallScreen ? 3 : 10}>
          <Grid item xs={12} mb={1} className="specificlocationtext">
            Tag this location for later
          </Grid>
          {data3.map((item, index) => (
            <button
              key={index}
              className={`specificlocationlabelbutton ${
                selectedButton === item ? "selected" : ""
              }`}
              onClick={() => setSelectedButton(item)}
            >
              {item}
            </button>
          ))}
        </Grid>
        <Grid container xs={10} mt={7} mb={isMidScreen ? 10 : 5}>
          {!isLoading && (
            <div className="specificlocationspacebutton">
              {isSmallScreen ? (
                <div>
                  <button className="specificlocationprevious" type="submit">
                    Confirm Location & Proceed
                  </button>
                </div>
              ) : (
                <>
                  <div>
                    <button
                      className="specificlocationprevious"
                      onClick={() =>
                        navigate(
                          `/app/${auth?.rolename?.toLowerCase()}/my-labtests`
                        )
                      }
                    >
                      Previous
                    </button>
                  </div>
                  <div>
                    <button className="specificlocationnext" type="submit">
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          )}
        </Grid>
      </form>
    </div>
  );
};

export default SpecificLocation;

// import React, { useEffect, useState } from "react";
// import "./specificLocation.css";
// import { Grid } from "@mui/material";
// import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
// import Search from "../../findDoctor/assets/images/Search.svg";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import { useNavigate } from "react-router-dom";
// import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
// import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
// import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
// import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
// import { useSelector } from "react-redux";
// import axios from "axios";

// const SpecificLocation = () => {
//   const navigate = useNavigate();
//   const Location = JSON.parse(localStorage.getItem("userLocation"));

//   const [mapCenter, setMapCenter] = useState({
//     lat: Location?.latitude || 28.6139,
//     lng: Location?.longitude || 77.209,
//   });

//   const [searchAddress, setSearchAddress] = useState("");
//   const [searchedLocation, setSearchedLocation] = useState(null);
//   const [latitude, setLatitude] = useState(null);
//   const [longitude, setLongitude] = useState(null);
//   const [manualMarkerLocation, setManualMarkerLocation] = useState({
//     lat: Location?.latitude,
//     lng: Location?.longitude,
//   });
//   const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

//   const [selectedState, setSelectedState] = useState("");
//   const customMarkerImageUrl =
//     "https://i.pinimg.com/474x/67/c3/d6/67c3d63e215e034e01d45c8256d720d3.jpg";

//   const data = [
//     "Andhra Pradesh",
//     "Arunachal Pradesh",
//     "Assam",
//     "Bihar",
//     "Chhattisgarh",
//     "Goa",
//     "Gujarat",
//     "Haryana",
//     "Himachal Pradesh",
//     "Jharkhand",
//     "Karnataka",
//     "Kerala",
//     "Madhya Pradesh",
//     "Maharashtra",
//     "Manipur",
//     "Meghalaya",
//     "Mizoram",
//     "Nagaland",
//     "Odisha",
//     "Punjab",
//     "Rajasthan",
//     "Sikkim",
//     "Tamil Nadu",
//     "Telangana",
//     "Tripura",
//     "Uttar Pradesh",
//     "Uttarakhand",
//     "West Bengal",
//   ];

//   const stateCoordinates = {
//     "Andhra Pradesh": { lat: 15.9129, lng: 79.74 },
//     "Arunachal Pradesh": { lat: 28.218, lng: 94.7278 },
//     Assam: { lat: 26.2006, lng: 92.9376 },
//     Bihar: { lat: 25.0961, lng: 85.3131 },
//     Chhattisgarh: { lat: 21.2787, lng: 81.8661 },
//     Goa: { lat: 15.2993, lng: 74.124 },
//     Gujarat: { lat: 22.2587, lng: 71.1924 },
//     Haryana: { lat: 29.0588, lng: 76.0856 },
//     "Himachal Pradesh": { lat: 31.1048, lng: 77.1734 },
//     Jharkhand: { lat: 23.6102, lng: 85.2799 },
//     Karnataka: { lat: 15.3173, lng: 75.7139 },
//     Kerala: { lat: 10.8505, lng: 76.2711 },
//     "Madhya Pradesh": { lat: 22.9734, lng: 78.6569 },
//     Maharashtra: { lat: 19.7515, lng: 75.7139 },
//     Manipur: { lat: 24.6637, lng: 93.9063 },
//     Meghalaya: { lat: 25.467, lng: 91.3662 },
//     Mizoram: { lat: 23.1645, lng: 92.9376 },
//     Nagaland: { lat: 26.1584, lng: 94.5624 },
//     Odisha: { lat: 20.9517, lng: 85.0985 },
//     Punjab: { lat: 31.1471, lng: 75.3412 },
//     Rajasthan: { lat: 27.0238, lng: 74.2179 },
//     Sikkim: { lat: 27.533, lng: 88.5122 },
//     "Tamil Nadu": { lat: 11.1271, lng: 78.6569 },
//     Telangana: { lat: 18.1124, lng: 79.0193 },
//     Tripura: { lat: 23.9408, lng: 91.9882 },
//     "Uttar Pradesh": { lat: 26.8467, lng: 80.9462 },
//     Uttarakhand: { lat: 30.0668, lng: 79.0193 },
//     "West Bengal": { lat: 22.9868, lng: 87.855 },
//   };
//   const handleStateChange = (selectedState) => {
//     setSearchAddress("");
//     formik.setFieldValue("completeAddress", "");

//     if (stateCoordinates[selectedState]) {
//       const { lat, lng } = stateCoordinates[selectedState];
//       setMapCenter({ lat, lng });
//     }
//   };

//   const data2 = [
//     {
//       heading: "Complete Address",
//       placeholder: "Search for city, localities and more",
//     },
//     {
//       heading: "Floor (Optional)*",
//       placeholder: "Search for city, localities and more",
//     },
//     {
//       heading: "Landmark (Optional)*",
//       placeholder: "Search for city, localities and more",
//     },
//   ];

//   const data3 = ["Home", "Work", "Office", "Other"];

//   const [selectedButton, setSelectedButton] = useState("Home");

//   const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);

//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsMidScreen(window.innerWidth <= 990);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const onPlaceChanged = () => {
//     setIsLoading(true);
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ address: searchAddress }, (results, status) => {
//       if (status === "OK" && results[0]) {
//         setLatitude(results[0].geometry.location.lat());
//         setLongitude(results[0].geometry.location.lng());

//         setMapCenter({
//           lat: results[0].geometry.location.lat(),
//           lng: results[0].geometry.location.lng(),
//         });

//         setSearchedLocation({
//           lat: results[0].geometry.location.lat(),
//           lng: results[0].geometry.location.lng(),
//         });

//         console.log("results", results);
//         const addressComponents = results[0].address_components;
//         let stateName = "";
//         for (let i = 0; i < addressComponents.length; i++) {
//           const types = addressComponents[i].types;
//           if (types.includes("administrative_area_level_1")) {
//             stateName = addressComponents[i].long_name;
//             break;
//           }
//         }

//         setSelectedState(stateName);
//       } else {
//         console.error("Geocoding failed:", status);
//       }
//       setIsLoading(false);
//     });
//   };

//   const handleSearch = () => {
//     onPlaceChanged();
//   };

//   const handlesearchchange = (e) => {
//     formik.setFieldValue("completeAddress", "");
//     setSearchAddress(e.target.value);
//     setTimeout(() => {
//       handleSearch();
//     }, 3000);
//   };

//   const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 700);

//   const validationSchema = Yup.object().shape({
//     completeAddress: Yup.string().required("Complete Address is required"),
//   });

//   const fetchAreaInformation = async (lat, lng) => {
//     setIsLoading(true);
//     const tokens = "AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0";
//     try {
//       const response = await axios.get(
//         `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${tokens}`
//       );
//       const result = response.data;

//       if (result.status === "OK") {
//         const addressComponents = result.results[0].address_components;

//         // Concatenate all address components to form the full address
//         let fullAddress = "";
//         for (let component of addressComponents) {
//           fullAddress += component.long_name + ", ";
//         }

//         // Trim any trailing comma and space
//         fullAddress = fullAddress.replace(/,\s*$/, "");
//         console.log("fullAddress",fullAddress)
//         formik.setFieldValue("completeAddress", fullAddress);
//         return fullAddress;

//       } else {
//         throw new Error(
//           result.error_message || "Error fetching area information"
//         );
//       }
//     } catch (error) {
//       console.error("Error fetching area information:", error);
//       return "";
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchAreaInformation(
//       manualMarkerLocation?.lat,
//       manualMarkerLocation?.lng
//     )
//   },[manualMarkerLocation])

//   const handleMapClick = async (e) => {
//     setIsLoading(true);
//     localStorage.removeItem("selectedLocation");
//     const clickedLocation = {
//       lat: e.latLng.lat(),
//       lng: e.latLng.lng(),
//     };

//     setSearchedLocation(clickedLocation);

//     setManualMarkerLocation(clickedLocation);
//     const areaInfo = await fetchAreaInformation(
//       clickedLocation?.lat,
//       clickedLocation?.lng
//     );

//     setManualMarkerLocation();

//     const areaInfoParts = areaInfo.split(", ");
//     const stateName = areaInfoParts[areaInfoParts.length - 3];

//     // Check if the state name exists in the data array
//     if (data.includes(stateName)) {
//       setSelectedState(stateName);
//     } else {
//       console.log("State not found in the data array.");
//     }

//     formik.setFieldValue("completeAddress", areaInfo);
//     setSearchAddress(areaInfo);
//     setIsLoading(false);
//   };
//   const handleChangecomplete = (event) => {
//     formik.handleChange(event);
//     setSearchAddress(event.target.value);
//   };

//   const formik = useFormik({
//     initialValues: {
//       completeAddress: "",
//       floor: "",
//       landmark: "",
//     },
//     validationSchema: validationSchema,
//     onSubmit: (values) => {
//       setIsLoading(true);
//       const locationToSubmit = manualMarkerLocation || {
//         lat: latitude,
//         lng: longitude,
//       };

//       if (!latitude && !longitude) {
//         return swal({
//           icon: "error",
//           title: "Error",
//           text: "Please enter full address",
//         });
//       }
//       localStorage.setItem(
//         "selectedLocation",
//         JSON.stringify(locationToSubmit)
//       );
//       navigate(`/app/${auth?.rolename?.toLowerCase()}/lab-test-cart`, {
//         state: {
//           cordinates: locationToSubmit,
//         },
//       });
//       setIsLoading(false);
//     },
//   });

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth <= 700);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);
//   const handleChangeconplete = (event) => {
//     formik.handleChange(event); // Call formik's handleChange
//     setAddress(null); // Call your custom function
//   };

//   useEffect(() => {
//     let timeoutId;

//     const handleAddressChange = (address) => {
//       const geocoder = new window.google.maps.Geocoder();
//       geocoder.geocode({ address: address }, (results, status) => {
//         if (status === "OK" && results[0]) {
//           setLatitude(results[0].geometry.location.lat());
//           setLongitude(results[0].geometry.location.lng());

//           setMapCenter({
//             lat: results[0].geometry.location.lat(),
//             lng: results[0].geometry.location.lng(),
//           });

//           setSearchedLocation({
//             lat: results[0].geometry.location.lat(),
//             lng: results[0].geometry.location.lng(),
//           });

//           const addressComponents = results[0].address_components;
//           let stateName = "";
//           for (let i = 0; i < addressComponents.length; i++) {
//             const types = addressComponents[i].types;
//             if (types.includes("administrative_area_level_1")) {
//               stateName = addressComponents[i].long_name;
//               break;
//             }
//           }

//           setSelectedState(stateName);
//         } else {
//           console.error("Geocoding failed:", status);
//         }
//         setIsLoading(false);
//       });
//     };

//     const handleDelayedAddressChange = (address) => {
//       if (timeoutId) {
//         clearTimeout(timeoutId);
//       }
//       setIsLoading(true);
//       timeoutId = setTimeout(() => {
//         handleAddressChange(address);
//       }, 3000);
//     };

//     if (formik.values.completeAddress) {
//       handleDelayedAddressChange(formik.values.completeAddress);
//     }

//     return () => {
//       clearTimeout(timeoutId);
//     };
//   }, [formik.values.completeAddress]);

//   return (
//     <div>
//       {isLoading && <div className="loader">Loading...</div>}

//       <form onSubmit={formik.handleSubmit} className="specificlocationroot">
//         <Grid
//           container
//           xs={isSmallScreen ? 12 : 10}
//           mt={23}
//           className="specificlocationcontainer"
//         >
//           <Grid item mt={5} mb={5} className="specificlocationitem">
//             <select
//               className="specificlocationinput"
//               id="selectedState"
//               name="selectedState"
//               value={selectedState}
//               onChange={(e) => {
//                 setSelectedState(e.target.value);
//                 handleStateChange(e.target.value);
//               }}
//             >
//               <option value="">Select any State</option>
//               {data?.map((item, index) => (
//                 <option key={index} value={item}>
//                   {item}
//                 </option>
//               ))}
//             </select>
//           </Grid>
//           <Grid item className="specificlocationitem2">
//             <input
//               type="text"
//               id="specificlocationinput"
//               value={searchAddress}
//               placeholder="Search for city, localities and more"
//               className="specificlocationinput"
//               onChange={(e) => handlesearchchange(e)}
//             />
//             {/* <button className="specificlocationbutton1" onClick={handleSearch}>
//               <img
//                 src={Search}
//                 alt="Search Logo"
//                 className="specificlocationimg1"
//               />
//             </button> */}
//           </Grid>
//         </Grid>
//         <Grid container xs={isSmallScreen ? 12 : 10}>
//           <LoadScript googleMapsApiKey="AIzaSyDA7CtE4tjaOSOCuGISc_pj0GMqFwfaDN0">
//             <GoogleMap
//               center={manualMarkerLocation || mapCenter}
//               zoom={14}
//               mapContainerStyle={{ width: "100%", height: "400px" }}
//               // options={{
//               //   fullscreenControl: false,
//               //   streetViewControl: false,
//               //   mapTypeControl: false,
//               //   zoomControl: false,
//               // }}
//               onClick={(e) => {
//                 handleMapClick(e);
//               }}
//             >
//               {manualMarkerLocation && window.google && window.google.maps && (
//                 <Marker
//                   position={manualMarkerLocation}
//                   icon={{
//                     url: customMarkerImageUrl,
//                     scaledSize: new window.google.maps.Size(30, 30),
//                   }}
//                 />
//               )}

//               {searchedLocation && <Marker position={searchedLocation} />}
//             </GoogleMap>
//           </LoadScript>
//         </Grid>
//         {isSmallScreen ? (
//           <Grid container xs={10} mt={2} className="selectyourloc">
//             Select your Location
//           </Grid>
//         ) : (
//           <></>
//         )}
//         <Grid
//           container
//           xs={isSmallScreen ? 10 : 8}
//           mt={2}
//           className="specificlocationlabel"
//         >
//           <div className="specificlocationheading">Complete Address</div>
//           <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
//             <input
//               type="text"
//               id="completeAddress"
//               name="completeAddress"
//               className={`specificlocationlabelinput ${
//                 formik.touched.completeAddress && formik.errors.completeAddress
//                   ? "error"
//                   : ""
//               }`}
//               placeholder="Search for city, localities and more"
//               onChange={handleChangecomplete}
//               onBlur={formik.handleBlur}
//               value={formik.values.completeAddress}
//             />
//           </Grid>
//           {formik.touched.completeAddress && formik.errors.completeAddress ? (
//             <div
//               className="error-message"
//               style={{
//                 fontWeight: "400",
//                 color: "red",
//                 fontSize: "12px",
//                 textAlign: "left",
//               }}
//             >
//               {formik.errors.completeAddress}
//             </div>
//           ) : null}
//         </Grid>
//         <Grid
//           container
//           xs={isSmallScreen ? 10 : 8}
//           mt={2}
//           className="specificlocationlabel"
//         >
//           <div className="specificlocationheading">Floor (Optional)*</div>
//           <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
//             <input
//               type="text"
//               id="floor"
//               name="floor"
//               className="specificlocationlabelinput"
//               placeholder="Search for city, localities and more"
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.floor}
//             />
//           </Grid>
//         </Grid>
//         <Grid
//           container
//           xs={isSmallScreen ? 10 : 8}
//           mt={2}
//           className="specificlocationlabel"
//         >
//           <div className="specificlocationheading">Landmark (Optional)*</div>
//           <Grid item xs={12} mt={2} className="specificlocationlabelitem2">
//             <input
//               type="text"
//               id="landmark"
//               name="landmark"
//               className="specificlocationlabelinput"
//               placeholder="Search for city, localities and more"
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.landmark}
//             />
//           </Grid>
//         </Grid>
//         <Grid container xs={isSmallScreen ? 10 : 9} mt={isSmallScreen ? 3 : 10}>
//           <Grid item xs={12} mb={1} className="specificlocationtext">
//             Tag this location for later
//           </Grid>
//           {data3.map((item, index) => (
//             <button
//               key={index}
//               className={`specificlocationlabelbutton ${
//                 selectedButton === item ? "selected" : ""
//               }`}
//               onClick={() => setSelectedButton(item)}
//             >
//               {item}
//             </button>
//           ))}
//         </Grid>
//         <Grid container xs={10} mt={7} mb={isMidScreen ? 10 : 5}>
//           {!isLoading && (
//             <div className="specificlocationspacebutton">
//               {isSmallScreen ? (
//                 <div>
//                   <button className="specificlocationprevious" type="submit">
//                     Confirm Location & Proceed
//                   </button>
//                 </div>
//               ) : (
//                 <>
//                   <div>
//                     <button
//                       className="specificlocationprevious"
//                       onClick={() =>
//                         navigate(
//                           `/app/${auth?.rolename?.toLowerCase()}/my-labtests`
//                         )
//                       }
//                     >
//                       Previous
//                     </button>
//                   </div>
//                   <div>
//                     <button className="specificlocationnext" type="submit">
//                       Next
//                     </button>
//                   </div>
//                 </>
//               )}
//             </div>
//           )}
//         </Grid>
//       </form>
//     </div>
//   );
// };

// export default SpecificLocation;
