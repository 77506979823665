import React, { useEffect, useRef, useState } from "react";
import medharvaImage from "../../../assets/images/Medharva-logo.svg";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

import MDInput from "components/MDInput";
import BaseLayout from "layouts/pages/account/components/BaseLayout";

import Divider from "@mui/material/Divider";

import { IoCall } from "react-icons/io5";
import DataTable from "examples/Tables/DataTable";
import { FaUserDoctor } from "react-icons/fa6";
import { Link, useLocation, useParams } from "react-router-dom";
import axios from "../../../axiosinstance";
import { CardMedia, CircularProgress, useMediaQuery } from "@mui/material";

import {
  Container,
  Grid,
  Typography,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TextField,
} from "@mui/material";
import MDBoxRoot from "components/MDBox/MDBoxRoot";
import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";

const Prescriptionview = () => {
  const location = useLocation();
  const reportRef = useRef(null);
  const [presId, setPresId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [bookingStartTime, setBookingStartTime] = useState("");
  const [bookingEndTime, setBookingEndTime] = useState("");
  const [labData, setLabData] = useState([]);
  const isMobile = useMediaQuery("(max-width: 600px)");
  const [medicineData, setMedicineData] = useState([]);
  let patient = location?.state?.patient;
  const [presdate, setPresDate] = useState("");
  const [doctor, setDoctor] = useState("");
  const [patname, setPatname] = useState("");
  const [patage, setPatage] = useState("");
  const [patmobno, setPatmobno] = useState("");
  // const [visitid, setVisitID ] = useState(location?.state?.visitid);

  const [orderId, setOrderId] = useState(location?.state?.orderId);
  const [responseFound, setResponseFound] = useState(false);
  const [response, setResponse] = useState([]);
  const [Deseses, setDeseses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const visitID = new URLSearchParams(location.search).get("visitid");
  const presID = new URLSearchParams(location.search).get("id");
  const videourl = `https://smapi.softwaremathematics.com/new-upload-service-mongo/api/play/${orderId}`;

  const patientDetails = [
    {
      action: "Name",
      accept: patname,
    },

    {
      action: "Patient Id",
      accept: patientId,
    },

    {
      action: "Age",
      accept: patage,
    },
    {
      action: "Mobile No",
      accept: patmobno,
    },

    {
      action: "Prescription Id",
      accept: presID ? presID : presId,
    },

    {
      action: "Booking Time",
      accept: `${new Date(bookingStartTime)
        .toLocaleString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .replace(" at", ",")} - ${new Date(bookingEndTime).toLocaleString(
        "en-US",
        {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        }
      )}`,
    },
  ];
  const search = location.search;

  function getQueryParameter(name) {
    const urlParams = new URLSearchParams(search);
    return urlParams.get(name);
  }

  const newtabpresId = getQueryParameter("id");

  async function getUrl(orderId) {
    setIsLoading(true);
    await axios

      .get(`new-upload-service-mongo/api/play/${orderId}`)

      .then(() => {
        setIsLoading(false);
        setResponseFound(true);
      })

      .catch((e) => {
        setIsLoading(false);
        console.log("error", e);
        swal("","Recording not found!","warning");
      });
  }

  async function doseList(presID) {
    setLoading(true);
    await axios

      .get(`${APIs?.dosesService}/get?presid=${presID}`)

      .then((response) => {
        const fetchedPresId = response?.data?.data?.dtoList?.[0]?.presid;

        const fetchedPresDate =
          response?.data?.data?.dtoList?.[0]?.createddate.split("T")[0];
        setPresDate(fetchedPresDate);

        setMedicineData(response?.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
        setLoading(true);
      });
  }

  async function getDeseses(presID) {
    setLoading(true);
    await axios

      .get(`${APIs?.aggregationServiceApi}/get?presid=${presID}`)

      .then((response) => {
        setDeseses(
          response?.data?.data?.visitAggregationDTOList?.[0]
            ?.prescriptionlist?.[0]?.diseaselist
        );
        setDoctor(
          response?.data?.data?.visitAggregationDTOList?.[0]
            ?.prescriptionlist?.[0]?.doctor
        );
        setPatname(
          response?.data?.data?.visitAggregationDTOList?.[0]?.patient?.name
        );
        setPatage(
          response?.data?.data?.visitAggregationDTOList?.[0]?.patient?.age
        );
        setPatmobno(
          response?.data?.data?.visitAggregationDTOList?.[0]?.patient?.mobileno
        );
        setBookingStartTime(
          response?.data?.data?.visitAggregationDTOList?.[0]?.slot?.startdate
        );
        setBookingEndTime(
          response?.data?.data?.visitAggregationDTOList?.[0]?.slot?.enddate
        );
        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  async function labList(presID) {
    setLoading(true);
    await axios

      .get(`${APIs?.labtestService}/get?presid=${presID}`)

      .then((response) => {
        setLabData(response?.data?.data?.dtoList);
      })

      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  const handlePrint = () => {
    const element = reportRef.current;
    if (element) {
      const iframe = document.createElement("iframe");
      iframe.style.position = "absolute";
      iframe.style.width = "0";
      iframe.style.height = "0";
      iframe.style.border = "none";
      document.body.appendChild(iframe);

      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(`
      <html>
        <head>
          <title>Print</title>
          <style>
            ${Array.from(document.styleSheets)
              .map((sheet) => {
                try {
                  return Array.from(sheet.cssRules)
                    .map((rule) => rule.cssText)
                    .join("\n");
                } catch (e) {
                  return "";
                }
              })
              .join("\n")}
            @media print {
              body { margin: 0; }
              .no-print { display: none; }
            }
          </style>
        </head>
        <body>
          ${element.outerHTML}
        </body>
      </html>
    `);
      doc.close();

      iframe.onload = () => {
        iframe.contentWindow.focus();
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      };
    }
  };

  const handleDownload = () => {
    swal({
      title: "Are you sure?",
      text: "Do you want to download this report?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDownload) => {
      if (willDownload) {
        const element = reportRef.current;

        if (element) {
          const originalOverflow = element.style.overflow;
          const originalWhiteSpace = element.style.whiteSpace;

          element.style.overflow = "visible";
          element.style.whiteSpace = "nowrap";

          const dataTableElements = element.querySelectorAll(
            ".MuiTableContainer-root"
          );
          const originalStyles = [];

          dataTableElements.forEach((dataTableElement) => {
            originalStyles.push({
              overflowX: dataTableElement.style.overflowX,
              overflowY: dataTableElement.style.overflowY,
              maxHeight: dataTableElement.style.maxHeight,
            });

            dataTableElement.style.overflowX = "hidden";
            dataTableElement.style.overflowY = "hidden";
            dataTableElement.style.maxHeight = "none";
          });

          domtoimage
            .toPng(element, { useCORS: true })
            .then((imgData) => {
              const pdf = new jsPDF("p", "mm", "a4");

              const pdfWidth = pdf.internal.pageSize.getWidth();
              const pdfHeight = pdf.internal.pageSize.getHeight();
              const margin = 10;

              const img = new Image();
              img.src = imgData;
              img.onload = () => {
                const imgWidth = pdfWidth - 2 * margin;
                const imgHeight = (img.height * imgWidth) / img.width;

                if (imgHeight > pdfHeight - 2 * margin) {
                  const scaleFactor = (pdfHeight - 2 * margin) / imgHeight;
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth * scaleFactor,
                    imgHeight * scaleFactor
                  );
                } else {
                  pdf.addImage(
                    imgData,
                    "PNG",
                    margin,
                    margin,
                    imgWidth,
                    imgHeight
                  );
                }

                pdf.save("prescription-report.pdf");
              };

              dataTableElements.forEach((dataTableElement, index) => {
                dataTableElement.style.overflowX =
                  originalStyles[index].overflowX;
                dataTableElement.style.overflowY =
                  originalStyles[index].overflowY;
                dataTableElement.style.maxHeight =
                  originalStyles[index].maxHeight;
              });

              element.style.overflow = originalOverflow;
              element.style.whiteSpace = originalWhiteSpace;
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
            });
        } else {
          console.error("Report reference is null.");
        }
      }
    });
  };

  function getData(visitid) {
    setLoading(true);
    axios

      .get(`${APIs?.visitServiceApi}/get?visitid=${visitid}`)

      .then((response) => {
        labList(response.data?.data?.dtoList?.[0]?.presid);

        setPresId(response?.data?.data?.dtoList?.[0]?.presid);
        setPatientId(response?.data?.data?.dtoList?.[0]?.patientid);
        doseList(response?.data?.data?.dtoList?.[0]?.presid);
        getDeseses(response?.data?.data?.dtoList?.[0]?.presid);
      })

      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  useEffect(async () => {
    if (newtabpresId !== null) {
      doseList(newtabpresId);
      labList(newtabpresId);
      getDeseses(newtabpresId);
    } else if (visitID) {
      getData(visitID);
    }
    // if (orderId) {
    //   getUrl(orderId);
    // }
  }, [newtabpresId, visitID, orderId]);

  return (
    <MDBox p={3}>
      <MDBox mt={15}>
        <Card
          style={{
            margin: isMobile ? "0% 0%" : "0px 15%",
            padding: "10px",
            marginBottom: "20px",
            marginTop: "160px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div ref={reportRef}>
            <MDBox pl={3} pr={3} lineHeight={1} sx={{ mb: "40px" }}>
              <img
                src={medharvaImage}
                alt="Watermark"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  opacity: 0.12,
                  objectFit: "contain",
                  zIndex: 0,
                  pointerEvents: "none",
                }}
              />
              {loading ? (
                <Grid container xs={12} justifyContent={"center"} mb={2}>
                  <Grid
                    container
                    xs={11}
                    justifyContent={"center"}
                    mt={1}
                    spacing={3}
                  >
                    {[...Array(20)].map((_, index) => (
                      <Grid item key={index} xs={12} sm={6} md={6} lg={4}>
                        <ProfileSkeleton width="100%" />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12} md={12}>
                    <MDBox
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      style={{
                        marginTop: "0.4rem",
                        position: "relative",
                        zIndex: 1,
                      }}
                    >
                      <Grid
                        item
                        xs={12}
                        md={3}
                        display={"flex"}
                        flexDirection={"column"}
                      >
                        <img
                          src={medharvaImage}
                          width="60%"
                          p={1}
                          mb={1}
                          style={{ marginRight: "16px", pointerEvents: "none" }}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={3}
                        display={"flex"}
                        flexDirection={"column"}
                        marginTop={"1rem"}
                      >
                        <MDBox
                          sx={{
                            width: "100%",

                            display: "flex",
                            justifyItems: "center",
                            alignItems: "center",
                            marginLeft: "2rem",
                          }}
                        >
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyItems: "center",
                              alignItems: "center",
                            }}
                          >
                            <FaUserDoctor />
                          </MDBox>
                          <MDBox
                            sx={{
                              display: "flex",
                              justifyItems: "center",
                              alignItems: "center",
                              px: 2,
                            }}
                          >
                            <MDTypography
                              variant="body2"
                              color="text"
                              fontWeight="medium"
                              style={{ textAlign: "center" }}
                            >
                              {/* {doctor ===
                              localStorage
                                ?.getItem("profile-Name")
                                ?.replace(/"/g, "")
                                ? "Self"
                                : doctor} */}
                              {doctor}
                            </MDTypography>
                          </MDBox>
                        </MDBox>

                        {/* <MDBox
                  sx={{
                    width: "100%",
                    marginLeft: "2rem",
                    display: "flex",
                    justifyItems: "center",
                    alignItems: "center",
                  }}
                >
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                    }}
                  >
                    <IoCall />
                  </MDBox>
                  <MDBox
                    sx={{
                      display: "flex",
                      justifyItems: "center",
                      alignItems: "center",
                      color: "white",
                      px: 2,
                    }}
                  >
                    8651659585
                  </MDBox>
                </MDBox> */}
                      </Grid>
                    </MDBox>
                  </Grid>

                  <Divider
                    style={{
                      margin: "5px 0px",
                      width: "100%",
                      backgroundColor: "#009147",
                      height: "2px",
                    }}
                  />
                  <MDBox
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                      margin: "16px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="medium">
                      Patient's Details
                    </MDTypography>
                    <MDTypography variant="body2" fontWeight="regular">
                      {" "}
                      Date: {presdate}
                    </MDTypography>
                  </MDBox>
                  <Grid mt={2}>
                    {patientDetails?.map((item, index) => (
                      <Grid
                        key={index}
                        item
                        ml={2}
                        xs={12}
                        // sm={6}
                        sx={{
                          display: "flex",
                          gap: "5%",
                          position: "relative",
                          zIndex: 1,
                        }}
                      >
                        <Grid
                          item
                          sx={{ wordWrap: "break-word", width: "25%" }}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="medium"
                          >
                            {item?.action}
                          </MDTypography>
                        </Grid>
                        <Grid item>
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="medium"
                            style={{ textAlign: "center" }}
                          >
                            :
                          </MDTypography>
                        </Grid>
                        <Grid
                          item
                          sx={{ wordWrap: "break-word", width: "60%" }}
                        >
                          <MDTypography variant="body2" fontWeight="regular">
                            {" "}
                            {item?.accept}{" "}
                          </MDTypography>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>

                  <Divider
                    style={{
                      margin: "5px 0",
                      width: "100%",
                      backgroundColor: "#009147",
                      height: "2px",
                    }}
                  />
                  <MDBox
                    sx={{
                      margin: "16px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="medium">
                      Disease
                    </MDTypography>
                  </MDBox>
                  <Grid
                    container
                    spacing={2}
                    display={"flex"}
                    flexDirection={"column"}
                    sx={{
                      margin: "16px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    {Deseses?.map((disease, index) => (
                      <Grid item key={index} xs={12} sm={6} md={4} lg={2}>
                        <MDTypography variant="body2">
                          {index + 1}
                          {"  :  "}
                          {disease}
                        </MDTypography>
                      </Grid>
                    ))}
                  </Grid>

                  <Divider
                    style={{
                      margin: "5px 0",
                      width: "100%",
                      backgroundColor: "#009147",
                      height: "2px",
                    }}
                  />

                  <MDBox
                    sx={{
                      margin: "16px",
                      position: "relative",
                      zIndex: 1,
                    }}
                  >
                    <MDTypography variant="body1" fontWeight="medium">
                      Medicines
                    </MDTypography>
                  </MDBox>

                  {/* <Divider
                style={{
                  margin: "20px 0",
                  width: "100%",
                  backgroundColor: "#009147",
                  height: "2px",
                }}
              /> */}
                  <Grid
                    container
                    sx={{ overflowY: "auto", maxHeight: "400px" }}
                  >
                    <DataTable
                      isSorted={false}
                      entriesPerPage={false}
                      canSearch={false}
                      showTotalEntries={false}
                      pagination={false}
                      table={{
                        columns: [
                          {
                            Header: "Sr. No.",
                            accessor: (row, index) => index + 1,
                          },
                          { Header: "Name", accessor: "medicinename" },
                          { Header: " Frequency", accessor: "frequency" },
                          { Header: " Quantity", accessor: "quantity" },
                          { Header: "Duration", accessor: "duration" },
                          { Header: "Doses", accessor: "dose" },
                        ],
                        rows: medicineData,
                      }}
                    />
                  </Grid>

                  <Divider
                    style={{
                      margin: "5px 0",
                      width: "100%",
                      backgroundColor: "#009147",
                      height: "2px",
                    }}
                  />
                  <MDBox sx={{ marginRight: "16px", marginLeft: "16px" }}>
                    <MDTypography variant="body1" fontWeight="medium">
                      Tests
                    </MDTypography>
                  </MDBox>
                  <DataTable
                    isSorted={false}
                    entriesPerPage={false}
                    canSearch={false}
                    showTotalEntries={false}
                    pagination={false}
                    table={{
                      columns: [
                        {
                          Header: "Sr. No.",
                          accessor: (row, index) => index + 1,
                        },
                        { Header: "Test Name", accessor: "testname" },
                        { Header: "Result", accessor: "testresult" },
                      ],
                      rows: labData,
                    }}
                  />
                </>
              )}
            </MDBox>
          </div>
          {!loading && (
            <Grid mr={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handlePrint}
                sx={{ marginRight: 2 }}
              >
                Print
              </MDButton>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                onClick={handleDownload}
              >
                Download
              </MDButton>
            </Grid>
          )}
        </Card>
        <Card
          style={{
            margin: isMobile ? "10px 0%" : "10px 15%",
            padding: "10px",
            display: responseFound && "none"
          }}
        >
          <Grid ml={2} sx={{ display: "flex", justifyContent: "flex-start" }}>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={() => getUrl(orderId)}
            >
              {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "View Recording"}
            </MDButton>
          </Grid>
        </Card>
        {!responseFound ? (
          <></>
        ) : (
          // <Card
          //   style={{
          //     marginTop: "10px",
          //     padding: "10px",
          //     marginBottom: "20px",
          //     zIndex: 1,
          //   }}
          // >
          <>
            <Card
              style={{
                margin: isMobile ? "10px 0%" : "10px 15%",
                padding: "10px",
                overflow: "hidden",
              }}
            >
              <MDBox>
                <MDBox ml={2} mt={2}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Recorded Session
                  </MDTypography>
                </MDBox>

                <span
                  style={{
                    display: "grid",
                    placeItems: "start",
                    marginBottom: "20px",
                  }}
                >
                  {/* <video width="320" height="240" controls>
                    <source src={videourl} type="video/mp4" />
                  </video> */}
                  <CardMedia
                    component="video"
                    controls
                    src={videourl}
                    title="Video Title"
                    style={{
                      width: "80%", // Ensure the image scales with the card width
                      height: "auto", // Maintain aspect ratio
                    }}
                  />
                </span>
              </MDBox>
            </Card>
          </>
        )}
      </MDBox>
    </MDBox>
  );
};

export default Prescriptionview;
