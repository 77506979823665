import React, { useState } from 'react';
import { Dialog, Grid, TextField } from '@mui/material';
import MDButton from 'components/MDButton';

const Negotiate = ({ onClose, open, negotiateValue, setNegotiateValue }) => {
  const [showOverride, setShowOverride] = useState(false);
  const [overrideValue, setOverrideValue] = useState('');

  const handleOverrideClick = () => {
    setShowOverride(true);
  };

  const handleSettleClick = () => {
    
  };

  const handleSubmitOverride = () => {
    console.log("Override value:", overrideValue); 
    onClose();
  };

  const handleOverrideChange = (event) => {
    setOverrideValue(event.target.value); // Update overrideValue state separately
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={true} onClose={onClose} disableEnforceFocus>
      <Grid sx={{ height: '250px' }}>
        {showOverride ? (
          <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '15%', gap: '2%' }}>
            <Grid sx={{ fontSize: '20px', lineHeight: '42px', color: '#505452' }}>Enter your override offer</Grid>
            <Grid>
              <TextField
                name="overrideValue"
                variant="outlined"
                type='text'
                sx={{ width: '250px' }}
                value={overrideValue} 
                onChange={handleOverrideChange}   
              />
            </Grid>
          </Grid>
        ) : (
          <Grid sx={{ display: 'flex', justifyContent: 'center', marginTop: '15%' }}>
            <Grid>Settle for the commission agent demanded?</Grid>
          </Grid>
        )}
        <Grid sx={{ display: 'flex', justifyContent: 'center', gap: '5%', marginTop: '2%' }}>
          {showOverride ? (
            <MDButton type="submit" sx={{ width: '150px' }} onClick={handleSubmitOverride} color="info">submit</MDButton>
          ) : (
            <>
              <MDButton type="button" sx={{ width: '150px' }} color="info" onClick={handleSettleClick}>settle</MDButton>
              <MDButton variant="outlined" type="button" sx={{ width: '150px' }} color="info" onClick={handleOverrideClick}>override</MDButton>
            </>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Negotiate;
