import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { APIs } from "Services/APIs";
// Import form validation, schema, and initial values
import initialValues from "../components/schemas/initialValues";
import validations from "../components/schemas/validation";
import form from "../components/schemas/form";

import { micappCode } from "static/micappCode";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import axios from "axiosinstance";
import { useSelector } from "react-redux";
import swal from "sweetalert";

import Dialog from "@mui/material/Dialog";
import UserInfo from "./UserInfo";
import AdditionalInfo from "./AdditionalInfo";
import { createRegistrationResource } from "Services/RegistrationResourceservice";
import { getLoginServiceData } from "redux/Actions/getLoginServiceData";

// Function to define form steps
function getSteps() {
  return ["User Info", "Additional Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <UserInfo formData={formData} />;
    case 1:
      return <AdditionalInfo formData={formData} />;

    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function CreateUserpopop(props) {
  const {
    openpopupRole,
    setopenpopupRole,
    handleClose,
    updateTableDataAction,
  } = props;
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { role } = useSelector((state) => state.role);
  const { error, loading } = useSelector((state) => state.role);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectTeam, getMicDetails } = useSelector((state) => state);
  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const userData = {
      departmentcode: values.department.code,
      departmentname: values.department.name,
      designationcode: values.designation.code,
      designationname: values.designation.name,
      createddate: null,
      sex: values?.gender,
      emailid: values.email,
      firstname: values.firstName,
      lastname: values.lastName,
      name: values.firstName + " " + values.lastName,
      password: values.password,
      mobileno: values.phonenumber,
      recstatus: "APPROVED",
      resetflag: null,
      resourcecode: "HEALTHCARE",
      rolecode: values.rolecode,
      rolename: values.roleName,
      mappingcode: getMicDetails?.details?.projecthirarichalcode,
      type: micappCode?.mappingcode,
      isactive: "ACTIVE",
      status: "ACTIVE",
      syncstatus: "synced",
      username: values.username,
      createdby: auth.profileid,
      createdbyname: auth.name,
    };
    try {
      const response = await axios.post(
        `${APIs.loginServiceApi}/create`,
        userData
      );

      if (response.status === 201) {
        setIsLoading(false);
        // setopenpopupRole(false);
        handleClose();
        swal("Success!", "Your data has been successfully created.", "success");
        updateTableDataAction();
        navigate("/app/mic/user");
        setActiveStep(activeStep - 1);
      } else {
        throw new Error("Unexpected response status: " + response.status);
      }
    } catch (error) {
      // Handle API error
      setIsLoading(false);

      swal(
        "Oops!",
        error?.response?.data?.aceErrors?.[0]?.errorMessage ||
          "Something wents wrong.",
        "error"
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <Dialog open={openpopupRole} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card sx={{ height: "100%" }}>
                  <MDBox mx={2} mt={-3}>
                    <Stepper activeStep={activeStep} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel>{label}</StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            back
                          </MDButton>
                        )}
                        <MDButton
                          // disabled={isSubmitting}
                          type="submit"
                          variant="gradient"
                          color="info"
                        >
                          {isLastStep ? isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "submit" : "next"}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
