import { Grid } from "@mui/material";
import React from "react";
import Search from "../../findDoctor/assets/images/Search.svg";
import "./medharvaCareCentres.css";
import MedharvaCareCentre from "../../findDoctor/assets/images/medharvacarecentres.jpg";
import Center from "../Search For Center/center";
import photo3 from "../../findDoctor/assets/images/Vector.svg";
import photo4 from "../../findDoctor/assets/images/Vector (1).svg";
import photo2 from "../../findDoctor/assets/images/Group.svg";


const MedharvaCareCentres = () => {
  const data = [
    "All locations",
    "Near me",
    "Kolkata",
    "Barasat",
    "Kalyani",
    "Naihati",
    "Delhi",
    "Mumbai",
    "Uttar Pradesh",
  ];

  const data2 = [
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
    {
      image: MedharvaCareCentre,
      location: "Medharva (location), West Bengal",
      rating: "4.5",
      src1: photo2,
    },
  ];
  return (
    <div>
      <Grid item pt={5} className="medharvacarecentrescontainer">
        <Grid item className="medharvacarecentresitem">
          <input
            type="text"
            id="medharvacarecentresinput"
            placeholder="Search for centres"
            className="medharvacarecentresinput"
          />
          <button className="medharvacarecentresbutton">
            <img
              src={Search}
              alt="Search Logo"
              className="medharvacarecentresimg1"
            />
          </button>
        </Grid>
        <Grid item mt={3} className="medharvacarecentresitem2">
          {data.map((item, index) => (
            <Grid item key={index} mr={1.5}>
              <button className="medharvacarecentresbutton101">{item}</button>
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item mt={5} className="medharvacarecentrescontainer2">
        <Grid container xs={3} ml={5} className="medharvacarecentrescontainer2">
          <Grid item>
            <Center />
          </Grid>
        </Grid>
        <Grid container xs={9} className="medharvacarecentrescontainer2main">
         
          {data2.map((item, index) => (
            
            <Grid
              item
              xs={5}
              pt={1}
              mr={5}
              mb={3}
              key={index}
              className="medharvacarecentrescontainer3"
            >
              <Grid item className="medharvacarecentrescontainer2">
                <img
                  src={item.image}
                  alt="med centres"
                  className="medharvacarecentresimg2"
                />
              </Grid>
              <Grid item mt={1} className="medharvacarecentreslocation">
                {item.location}
              </Grid>
              <Grid item mt={1} className="medharvacarecentrescontainer4">
                <Grid item className="medharvacarecentresrating">
                  {item.rating}
                  <div className="medharvacarecentresratingimgdiv">
                    {Array.from({ length: 5 }, (_, index) => (
                      <img key={index} className="medharvacarecentresratingimg" src={item.src1} />
                    ))}

                  </div>
                </Grid>
                <Grid item className="medharvacarecentrescontainer5">
                  <Grid item mr={2}>

                    <img className="medharvacarecentresicons" src={photo3} />
                  </Grid>
                  <Grid item>

                    <img className="medharvacarecentresicons" src={photo4} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container xs={12} mt={2}>
                <Grid container className="medharvacarecentresgriditem4">
                  <Grid item>
                    <button className="medharvacarecentresbutton1">
                      Know more
                    </button>
                  </Grid>
                  <Grid item>
                    <button className="medharvacarecentresbutton2">
                      Book An Appointment
                    </button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            
          ))}
          
        </Grid>
      </Grid>
    </div>
  );
};

export default MedharvaCareCentres;
