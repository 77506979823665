import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
// import { userList } from "static/data/tableData"
import { directoryFilter } from "static/filters/directoryFilter";


export const tableDataAction = 
  createAsyncThunk("getTableData", async(data: any, {rejectWithValue}:any) => {

  
    try {
  
      if(!data.url){
        return
      }
      if(data.payload){
        let userData = await getAllUsers(data.url, data.payload)
        return { userList: userData , filterArray: directoryFilter } ;
      }
      let userData = await getAllUsers(data.url, "")
        return { userList: userData , filterArray: directoryFilter } ;
    // if( data.page === "directory" ){
    //   console.log(userList, "user list 14")
    //   return { userList:  userList, filterArray: directoryFilter } ;
    //     // let userData = await getAllUsers(`login-service-mongo/api/loginservice/v2/get?createdby=${data.profileId}`)
    //     // return { userList: userData ||  userList, filterArray: directoryFilter } ;
    //     // return await { userList: userList, filterArray: directoryFilter } ;
    // }
    } catch (error) {
      return rejectWithValue(error);
    }
  });

   // if(data.name ==="office" && data.value !== null){
        //     let list = userList.filter((item)=> item[data?.name] === data.value)
        //     return list
        // }
        // else if(data.name ==="name" && data.value !== null){
        //     let filteredNameList = userList.filter(item => {
        //         const lowerCaseName = item.name.toLowerCase();
        //         const lowerCaseCharacter = data.value.toLowerCase();
        //         return lowerCaseName.includes(lowerCaseCharacter);
        //     });
        //     return filteredNameList;
        // }
        // else if(data.name ==="currentPage" && data.value !== null){
        //     let filteredList = userList.filter((item, index )=> (
        //         index > data.value *10 && index <= data.value *10 + 10
        //     ));
        //     return filteredList;
        // }
        // else if(data.name ==="age" && data.value !== null){
        //     let filteredList = userList.filter((item )=> (
        //         item.age == data.value
        //     ));
        //     return filteredList;
        // }
        // return await userList;
