import React, { useState } from "react";

import MDInput from "components/MDInput";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import swal from "sweetalert";
import Grid from "@mui/material/Grid";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { Formik, useFormik } from "formik";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { validationdata } from "./schemas";
import bgImage from "assets/images/newpasswordimg.png";
import axios from "../../../axiosinstance";
import { useLocation } from "react-router-dom";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";
const initialValues = {
  new_password: "",
  confirm_password: "",
};

const ResetPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: validationdata,
      onSubmit: (values, action) => {
        setIsLoading(true);
        const getData = {
          newpassword: values.new_password,

          resourcecode: "HEALTHCARE",
          username: JSON.parse(localStorage.getItem("user-code"))?.emailid,
        };

        axios
          .post(`${APIs?.loginServiceApi}/ForgetPassword`, getData)

          .then((response) => {
            setIsLoading(false);
            swal(
              "Alert!",
              "Your password has been sucessfully update!",
              "success"
            ).then((value) => {
              // Remove the user code from local storage
              localStorage.removeItem("userCode");
              localStorage.removeItem("user-code");
              // window.location.reload(true)
              action.resetForm();
              navigate("/authentication/sign-in/basic");
            });
          })
          .catch((e) => {
            setIsLoading(false);
            swal("Oops!", "Something went wrong.");
          });
      },
    });
  return (
    <CoverLayout mt={20} image={bgImage}>
      <Card style={{ marginTop: "50px" }}>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          p={3}
          mb={1}
          mt={-3}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white">
            Reset Password
          </MDTypography>
          <MDTypography display="block" variant="button" color="white">
            Enter your new password
          </MDTypography>
        </MDBox>

        <form onSubmit={handleSubmit}>
          <MDBox pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={1}>
                <MDInput
                  fullWidth
                  name="new_password"
                  id="new_password"
                  type="password"
                  label="New Password"
                  autoComplete="off"
                  value={values.new_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                {errors.new_password && touched.new_password && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.new_password}
                  </p>
                )}
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  fullWidth
                  name="confirm_password"
                  id="confirm_password"
                  type="password"
                  autoComplete="off"
                  label="Confirm New Password"
                  value={values.confirm_password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />

                {errors.confirm_password && touched.confirm_password && (
                  <p
                    style={{
                      fontWeight: "400",
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    {errors.confirm_password}
                  </p>
                )}
              </Grid>
            </Grid>
            <MDBox mt={6} mb={1}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                {" "}
                {isLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Update"
                )}
              </MDButton>
            </MDBox>
          </MDBox>
        </form>
      </Card>
    </CoverLayout>
  );
};

export default ResetPassword;
