// import React, { useEffect, useState } from "react";
// import { Link, useNavigate } from "react-router-dom";
// import { MdDelete } from "react-icons/md";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import DataTable from "examples/Tables/DataTable";
// import { useDispatch, useSelector } from "react-redux"; 
// //import { brandList, deleteBrand } from "Services/Brand Service/Brand";
// import CircularProgress from "@mui/material/CircularProgress";

// //edit icon
// import { FaRegEdit } from "react-icons/fa";


// import MDButton from "components/MDButton";
// import MDBox from "components/MDBox";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";

// import CreateAssociationPopup from "./CreateAssociationPopup";
// import { deleteAssociation, getAssociation } from "../service/Association";
// import AssociationEdit from "./AssociationEdit";


// function AssociationList() {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { association, loading } = useSelector((state) => state.association);
 
//   // const [loading, setLoading] = useState(true);
 
//   const [openpopup, setopenpopup] = useState(false);
//   const [selectedUser, setSelectedUser] = useState(null);
//   const [openpopupUser, setopenpopupUser] = useState(false);


// function editBrand(item){
//       setopenpopup(true);
//      setSelectedUser(item)
// }


// function onDelete(item) {
//   // Show a confirmation dialog using SweetAlert
//   swal({
//     title: "Are you sure?",
//     text: "You will not be able to recover this item!",
//     icon: "warning",
//     buttons: ["Cancel", "Delete"],
//     dangerMode: true,
//   }).then((willDelete) => {
//     if (willDelete) {
//       // User clicked "Delete," proceed with deletion
//       swal("Deleting...", {
//         icon: "info",
//         buttons: false,
//         closeOnClickOutside: false,
//       });

//       // Dispatch the deleteBrand action
//       dispatch(deleteAssociation(item))
//         .then(() => {
//           // After successful deletion, close the Swal dialog
//           swal("Deleted!", "Your item has been deleted.", "success");
//           // Fetch the updated brand list
        
//         })
//         .catch((error) => {
//           // Handle errors, display an error message, and close the Swal dialog
//           swal("Error", "An error occurred while deleting the item.", "error");
//         });
//     } else {
//       // User clicked "Cancel," do nothing
//       swal("Cancelled", "Your item is safe.", "info");
//     }
//   });
// }




// useEffect(() => {
//   dispatch(getAssociation());
// }, [])

//   const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
//   const iconStyleedit = {
//     fontSize: "1.5em",
//     color: "blue",
//     marginRight: "9px",
//   };
//   const addButtonStyle = {
//     display: "flex",
//     fontSize: "2em",
//     flexDirection: "column",
//     marginRight: "10px",
//   };

  
  

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
//       <MDBox>
//         <MDBox>
//           <Card>
//           {loading &&
//             <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//        <CircularProgress />
//       </div>
//           }
//             <MDBox pl={3} pr={3} lineHeight={1}>
//               <div style={addButtonStyle}>
//                 <span
//                   style={{
//                     alignSelf: "flex-start",
//                     marginTop: "20px",
//                   }}
//                 >
//                   <MDTypography variant="h5" fontWeight="medium">
//                 Association Master
//                   </MDTypography>
//                 </span>

//                 <span
//                   style={{
//                     alignSelf: "flex-end",
//                     marginRight: "10px",
//                     marginTop: "-23px",
//                     color: "#344767",
//                   }}
//                 >
//                   <MDButton color='success' onClick={() => setopenpopupUser(true)}>ADD</MDButton>
//                 </span>
//               </div>
//             </MDBox>
//       <DataTable
//         canSearch={true}
//         table={{
//           columns: [
      //       { Header: "MMU", accessor: "mmuname"},
      // {
      //   Header: "Role",
      //   accessor: "rolename"
      //     },
      //  { Header: "Username", accessor: "fullname"},
      //       // {
      //       //     Header: "Name",
      //       //     accessor: "fullname",
      //       //     Cell: ({ row }) => (
      //       //       <span>
      //       //         {row?.original?.lastname
      //       //           ? row?.original?.firstname + " " + row?.original?.lastname
      //       //           : row?.original?.firstname}
      //       //       </span>
      //       //     ),
      //       //   },
      //         {
      //         Header: "Action",
      //         accessor: "action",

      //         Cell: ({ row }) => (
      //           <div>
      //             <Link to={{}}>
      //               <FaRegEdit
      //                 style={iconStyleedit}
      //                 onClick={() => {
      //                  editBrand(row.original)
      //                 }}
      //               />
      //             </Link>

      //             <Link to={{}}>
      //               <MdDelete
      //                 style={iconStyle}
      //                 onClick={() => {
      //                   onDelete(row.original);
      //                 }}
      //               />
      //             </Link>
      //           </div>
      //         ),
      //       },
      //     ],
      //     rows: association || [],
      //   }}
      // />
//       </Card>
//       </MDBox>
//       </MDBox>
//       <AssociationEdit
//         openpopup={openpopup}
//         setopenpopup={setopenpopup}
//        title={"Association Edit"}
//        selectedUser={selectedUser}
//       />
//       <CreateAssociationPopup
//         openpopupUser={openpopupUser}
//         setopenpopupUser={setopenpopupUser}
//       />
//     </DashboardLayout>
//   );
// }

// export default AssociationList;




import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import { useDispatch, useSelector } from "react-redux";
//import { brandList, deleteBrand } from "Services/Brand Service/Brand";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "components/MDBox";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";

//edit icon
import { FaRegEdit } from "react-icons/fa";

import MDButton from "components/MDButton";
import CreateAssociationPopup from "./CreateAssociationPopup";
import { deleteAssociation, getAssociation } from "../service/Association";
import AssociationEdit from "./AssociationEdit";

function AssociationList() {
    const navigate = useNavigate();
  const dispatch = useDispatch();
  const { association, loading } = useSelector((state) => state.association);
 
  // const [loading, setLoading] = useState(true);
 
  const [openpopup, setopenpopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [openpopupUser, setopenpopupUser] = useState(false);


  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

 function editBrand(item){
      setopenpopup(true);
     setSelectedUser(item)
}

  async function onDelete(item) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    });

    if (willDelete) {
      swal("Deleting...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      // Dispatch the deleteBrand action
      const response = await dispatch(deleteAssociation(item));

      if (!response.error && !loading) {
        swal("Deleted!", "Your item has been deleted.", "success");
      } else {
        swal({
          icon: "error",
          title: "Error",
          text: error?.message
            ? error.message
            : "An error occurred while deleting the item.",
        });
      }
    } else {
      swal("Cancelled", "Your item is safe.", "info");
    }
  }

 useEffect(() => {
  dispatch(getAssociation());
}, [])

  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyleedit = {
    fontSize: "1.5em",
    color: "blue",
    marginRight: "9px",
  };

  return (
    <DashboardLayout>
  
      <MDBox>
        <MDBox>
          <Card>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <MDBox pl={3} pr={3} lineHeight={1}>
                  <div style={addButtonStyle}>
                    <span
                      style={{
                        alignSelf: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <MDTypography variant="h5" fontWeight="medium">
                        Association Master
                      </MDTypography>
                    </span>

                    <span
                      style={{
                        alignSelf: "flex-end",
                        marginRight: "10px",
                        marginTop: "-23px",
                        color: "#344767",
                      }}
                    >
                      <MDButton color='success' onClick={() => setopenpopupUser(true)}>ADD</MDButton>
                    </span>
                  </div>
                </MDBox>
                <DataTable
                  canSearch={true}
                  table={{
                    columns: [
                      { Header: "MMU", accessor: "mmuname"},
      {
        Header: "Role",
        accessor: "rolename"
          },
       { Header: "Username", accessor: "fullname"},
            // {
            //     Header: "Name",
            //     accessor: "fullname",
            //     Cell: ({ row }) => (
            //       <span>
            //         {row?.original?.lastname
            //           ? row?.original?.firstname + " " + row?.original?.lastname
            //           : row?.original?.firstname}
            //       </span>
            //     ),
            //   },
              {
              Header: "Action",
              accessor: "action",

              Cell: ({ row }) => (
                <div>
                  <Link to={{}}>
                    <FaRegEdit
                      style={iconStyleedit}
                      onClick={() => {
                       editBrand(row.original)
                      }}
                    />
                  </Link>

                  <Link to={{}}>
                    <MdDelete
                      style={iconStyle}
                      onClick={() => {
                        onDelete(row.original);
                      }}
                    />
                  </Link>
                </div>
              ),
            },
          ],
          rows: association || [],
        }}
      />
              </>
            )}
          </Card>
        </MDBox>
      </MDBox>
            <AssociationEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
       title={"Association Edit"}
       selectedUser={selectedUser}
      />
      <CreateAssociationPopup
        openpopupUser={openpopupUser}
        setopenpopupUser={setopenpopupUser}
      />
    </DashboardLayout>
  );
}

export default AssociationList;
