import { createSlice } from "@reduxjs/toolkit";
import { createProjectpincode, deleteProjectpincode, getProjectpincode, updateProjectpincode } from "../service/ProjectPincode";





const initialState = {
  projectpincode: [],
  loading: false,
  error: null,
};

export const projectPincodeDetail = createSlice({
  name: "projectPincodeDetail",
  initialState,
  extraReducers: {
    [createProjectpincode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProjectpincode.fulfilled]: (state, action) => {
      console.log("state",state.projectpincode)
      state.loading = false;
      state.projectpincode.push(action.payload.data.dto);
    },
    [createProjectpincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getProjectpincode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProjectpincode.fulfilled]: (state, action) => {
      state.loading = false;
      state.projectpincode = action.payload.data.dtoList;

    },
    [getProjectpincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProjectpincode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProjectpincode.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.projectpincode = state.projectpincode.filter((projectpincode) => projectpincode.id !== id);
      }
    },
    [deleteProjectpincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProjectpincode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProjectpincode.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.projectpincode.findIndex(projectpincode => projectpincode.id === action.payload.data.dto.id)
      state.projectpincode[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProjectpincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default projectPincodeDetail.reducer;
