import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    // lastName,
    username,
    email,
    password,
    rolecode,
    repeatPassword,
    departmentvalue,
    designationvalue,
   
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
     [designationvalue.name]: Yup.string().required(designationvalue.errorMsg),
      [departmentvalue.name]: Yup.string().required(departmentvalue.errorMsg),
    [username.name]: Yup.string().required(username.errorMsg),
    [rolecode.name]: Yup.string().required(rolecode.errorMsg),

      [phonenumber.name]: Yup.string()
      .required(phonenumber.errorMsg)
      .min(10, phonenumber.invalidMsg)
      .max(10, phonenumber.invalidMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [password.name]: Yup.string()
      .required(password.errorMsg)
      .min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
  }),
  // Yup.object().shape({
  
  //   [phonenumber.name]: Yup.string()
  //     .required(phonenumber.errorMsg)
  //     .min(10, phonenumber.invalidMsg)
  //     .max(10, phonenumber.invalidMsg),
  //   [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
 
  // }),
  // Yup.object().shape({
  //   [twitter.name]: Yup.string().required(twitter.errorMsg),
  // }),
];

export default validations;
