import { createSlice } from "@reduxjs/toolkit";
import { createDisease, deleteDisease, getDisease, updateDisease } from "../service/Disease";






const initialState = {
  disease: [],
  loading: false,
  error: null,
};

export const diseaseDetail = createSlice({
  name: "diseaseDetail",
  initialState,
  extraReducers: {
    [createDisease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createDisease.fulfilled]: (state, action) => {
      console.log("state",state.disease)
      state.loading = false;
      state.disease.push(action.payload.data.dto);
    },
    [createDisease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getDisease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getDisease.fulfilled]: (state, action) => {
      state.loading = false;
      state.disease = action.payload.data.dtoList;

    },
    [getDisease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteDisease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteDisease.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.disease = state.disease.filter((disease) => disease.id !== id);
      }
    },
    [deleteDisease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateDisease.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateDisease.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.disease.findIndex(disease => disease.id === action.payload.data.dto.id)
      state.disease[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateDisease.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default diseaseDetail.reducer;
