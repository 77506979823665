import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { getRoleAction } from "redux/Actions/roleAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getRolesSlice = createSlice({
  name: "getRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRoleAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getRoleAction.fulfilled, (state, action) => {
        console.log(action.payload, "data of roles 21")
        state.loading = false;
        state.list = action.payload || [];
        state.error = null;
      })
      .addCase(getRoleAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getRolesSlice.reducer;