import React, { useState } from 'react'
import "./WellnessPlans.css";
import { Grid, Card, Button, useMediaQuery  } from '@mui/material';
import imag1 from "../../../assets/images/corporate logo.png";
import { FaCheck } from "react-icons/fa6";

const WellnessPlans = () => {
  
  const isMobile = useMediaQuery('(max-width:880px)');
  const [plan, setPlan] = useState('Yearly');
  const corporatesData = [
    { logo: imag1 }, { logo: imag1 }, { logo: imag1 }, { logo: imag1 }, { logo: imag1 },
  ];
  const handlePlanChange = (e) => {
    setPlan(e.target.value);
  };

  return (
    <div className='wellnessdiv'>

      <div className="containerwellness">
        <div className="heading-container">

          <h2 className='switchplanheading1'>Medharva</h2>

        </div>
        <div className="heading-container">

          <h2 className='switchplanheading2'>Wellness Plans</h2>

        </div>

        <div className="heading-containerpara">

          <p className='switchplanpara1'>Top companies use Medharva’s welness plans to provide their employees the care they deserve.</p>

        </div>
        <div className="heading-containerlogo">

        {corporatesData.slice(0, isMobile ? 4 : corporatesData.length).map((card, index) => (
            <Grid item xs={12} sm={12} key={index} className='corporateslogoimgcontainer'>

              <img src={card.logo} alt="Service Logo" className="corporateslogoimg" />


            </Grid>
          ))}

        </div>
        <div className="switches-containerwellness">
          <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" checked={plan === 'Yearly'} onChange={handlePlanChange} />
          <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked={plan === 'Monthly'} onChange={handlePlanChange} />
          <label htmlFor="switchYearly" className='wellnessswitchbtn'>Annualy</label>
          <label htmlFor="switchMonthly" className='wellnessswitchbtn'>Monthly</label>

          <div className="switch-wrapperwellness">
            <div className="switchwellnessplan">
              <div className='wellnessswitchbtn'>Annualy</div>
              <div className='wellnessswitchbtn'>Monthly</div>


            </div>
          </div>
        </div>

{/* stater */}

        <div className='switchcontainermain'>
          <div className='switchcontainer1'>
            <div className="starter-container">

              <h2 className='starter-heading1'>Starter</h2>

            </div>
            <div className="starter-container">

              <h2 className='starter-prise1'> {plan === 'Monthly' ? '$100/' : '$80/'}<span className='starter-prise1span'>mo</span></h2>

            </div>
            <div className="starter-containerpara">

              <p className='switchplanpara'>Billed Annualy</p>

            </div>
            <div className="starter-containerpara2">

              <p className='switchplanpara2'>Let’s get started</p>

            </div>
            <div className="starter-containerpara2">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> seat</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>10</span> sessions</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>23</span> members</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> monthly get-together</p>

            </div>
            <div className="starter-containerpara22">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'><span className='switchplanpara3span1'>Pro includes</span></p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Basic amenities</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Discounts upto 60%</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> SOS Ambulance Service</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 24/7 Online Pharmacy</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Personal Whatsapp support</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> VR training</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Get discounts at every </p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Medharva Care centre</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Mediclaim</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 1 on 1 physchotherapy sessions</p>

            </div>
            <div className="starter-containerpara3btn">

              <Button variant="contained" color="primary" className='getstaartedbtn'>get started</Button>

            </div>

          </div>


{/* Premium */}

          <div className='switchcontainer2'>
            <div className="starter-container">

              <h2 className='starter-heading1'>Premium</h2>

            </div>
            <div className="starter-container">

              <h2 className='starter-prise1'>{plan === 'Monthly' ? '$250/' : '$200/'}<span className='starter-prise1span'>mo</span></h2>

            </div>
            <div className="starter-containerpara">

              <p className='switchplanpara'>Billed Annualy</p>

            </div>
            <div className="starter-containerpara2">

              <p className='switchplanpara2'>Let’s get started</p>

            </div>
            <div className="starter-containerpara2">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> seat</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>10</span> sessions</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>23</span> members</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> monthly get-together</p>

            </div>
            <div className="starter-containerpara22">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'><span className='switchplanpara3span1'>Pro includes</span></p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Basic amenities</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Discounts upto 60%</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> SOS Ambulance Service</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 24/7 Online Pharmacy</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Personal Whatsapp support</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> VR training</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Get discounts at every </p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Medharva Care centre</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Mediclaim</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 1 on 1 physchotherapy sessions</p>

            </div>
            <div className="starter-containerpara3btn">

              <Button variant="contained" color="primary" className='getstaartedbtn'>get started</Button>

            </div>
          </div>


{/* starter */}



          <div className='switchcontainer3'>
            <div className="starter-container">

              <h2 className='starter-heading1'>Starter</h2>

            </div>
            <div className="starter-container">

              <h2 className='starter-prise1'>{plan === 'Monthly' ? '$100/' : '$80/'}<span className='starter-prise1span'>mo</span></h2>

            </div>
            <div className="starter-containerpara">

              <p className='switchplanpara'>Billed Annualy</p>

            </div>
            <div className="starter-containerpara2">

              <p className='switchplanpara2'>Let’s get started</p>

            </div>
            <div className="starter-containerpara2">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> seat</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>10</span> sessions</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>23</span> members</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='switchplanpara3span'>1</span> monthly get-together</p>

            </div>
            <div className="starter-containerpara22">
              <div className='switchpageline'></div>
            </div>
            <div className="starter-containerpara3h">

              <p className='switchplanpara3'><span className='switchplanpara3span1'>Pro includes</span></p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Basic amenities</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Discounts upto 60%</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> SOS Ambulance Service</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 24/7 Online Pharmacy</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Personal Whatsapp support</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> VR training</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Get discounts at every </p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Medharva Care centre</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> Mediclaim</p>

            </div>
            <div className="starter-containerpara3">

              <p className='switchplanpara3'> <span className='planpara3span'><FaCheck /></span> 1 on 1 physchotherapy sessions</p>

            </div>
            <div className="starter-containerpara3btn">

              <Button variant="contained" color="primary" className='getstaartedbtn'>get started</Button>

            </div>
          </div>
        </div>
      </div>


    </div>
  )
}

export default WellnessPlans;
