import { Card } from '@material-ui/core'
import DashboardLayout from 'components/DashboardLayout'
import FilterCmp from 'components/Filters/FilterCmp'
import TableCmp from 'components/Tables/TableCmp'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { tableDataAction } from 'redux/Actions/tableDataAction';
import { clearActionRowData } from 'redux/slices/tableActionRow'
import Swal, {SweetAlertOptions} from 'sweetalert2';

export default function Transactions() {

  const { tableData, AuthReducer, tableActionRow } = useSelector(state => state) as { tableData: any; AuthReducer: any, tableActionRow:any };
    const dispatch = useDispatch()
    const [currentPage, setCurrentPage] = useState(1);
    const [filterData, setFilterData] = useState<any>({})
    const [perPage, setPerPage] = useState(5)

    useEffect(()=>{
        dispatch(tableDataAction({ page:"directory", profileId:AuthReducer?.auth?.profileid}) as any)
    },[])

    useEffect(()=>{
        dispatch(tableDataAction({name:"currentPage", value:currentPage, page:"allList"}) as any)
    },[currentPage])

  const columns:any[] = [
    { id:"name", label: "name", accessor: "name" },
    { id:"code",  label: "code", accessor: "rolename" },
    { id:"gender",  label: "Gender", accessor: "sex" },
    { id:"description",  label: "description", accessor: "description" },
    { id:"createdby",  label: "created By", accessor: "createdby" },
    { id:"createddate",  label: "Created date", accessor: "Screateddate" },
    // { id:"action",  label: "action", accessor: "action" }
  ]

  // const actionButtons = ["edit", "delete"]

  if(tableActionRow.actionType === "delete"){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    } as SweetAlertOptions).then((result) => {
      if (result.value) {
         dispatch(clearActionRowData())
        }else{
        dispatch(clearActionRowData())
      }
    });
  }

  // filter handler

  const handleSearch =()=>{
    console.log(filterData, "Filter data")
  }

  const handleClear =()=>{
    setFilterData({})
  }

  return (
    <DashboardLayout>
        <Card style={{marginTop:"5%"}}>
            <FilterCmp filterArray={tableData?.filterArray} filterData={filterData} setFilterData={setFilterData}
              handleSearch={handleSearch} handleClear={handleClear} />
            {tableData.rowData && <TableCmp columns={columns} rows={tableData.rowData} setCurrentPage={setCurrentPage}
                  setPerPage={setPerPage} perPage={perPage} currentPage={currentPage}
               />}
        </Card>
    </DashboardLayout>
  )
}
