import React, { useState } from "react";
import { Card, Box, Dialog, Grid, CardContent } from "@mui/material";
import DashboardLayout from "components/DashboardLayout";
import MDButton from "components/MDButton";
import NewAgeantRrgistration from "app/MIC/agent/newAgentRegistration";
import { Link } from "react-router-dom";
import ProfileCard from "components/profileCard";
import profileImage from 'assets/images/consultindia.png';

const AgentList = () => {
  const [showonpopup, setshowonpopup] = useState(false);
  const showNewAgeantRrgistration = () => {
    setshowonpopup(true);
  };
  const handleClose = () => {
    setshowonpopup(false);
  };
  const data = [
    {
      name: "Jay Sharma",
      referralCode: "XYZ123",
      resourcesAdded: "25",
      image:profileImage,
      path:"/app/mic/agents/onboard-list"
    },
    {
      name: "Jay Sharma",
      referralCode: "XYZ123",
      resourcesAdded: "25",
      image:profileImage,
      path:"/app/mic/agents/onboard-list"
    },
    {
      name: "Jay Sharma",
      referralCode: "XYZ123",
      resourcesAdded: "25",
      image:profileImage,
      path:"/app/mic/agents/onboard-list"
    },
 
  ];
  return (
    <DashboardLayout>
  <Grid mt={3}>
      <Card>
      <Grid mt={2} mb={2} mr={3}
        sx={{ display: "flex", justifyContent: "flex-end" }}
      >
        <MDButton color="info" onClick={showNewAgeantRrgistration}>
          Add New Agent
        </MDButton>
      </Grid>
      <CardContent>
        <Grid container gap={3} mt={1}>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={3} >
              <ProfileCard data={item} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
      <Dialog open={showonpopup} onClose={handleClose} fullWidth maxWidth="sm">
        <NewAgeantRrgistration open={showonpopup} onClose={handleClose} />
      </Dialog>
      </Grid>
    </DashboardLayout>
  );
};

export default AgentList;
