import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{projectname, departmentname, profilename, designationname},
} = checkout;

const validations = [
  // Yup.object().shape({
  //   [projectname.name]: Yup.string().required(projectname.errorMsg),
  //   [departmentname.name]: Yup.string().required(departmentname.errorMsg),
  //   [profilename.name]: Yup.string().required(profilename.errorMsg),
  //   [designationname.name]: Yup.string().required(designationname.errorMsg),
  // }),
];

export default validations;
