// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getUser } from "components/Resuable/UserMaster/service/User";
import { getClinic } from "app/Admin/AdminWorkspace/Clinic Master/service/Clinic";

 



function AssociationInfo({ formData }) {
  const dispatch = useDispatch();

  const { role, loading } = useSelector((state) => state.role);
  const { user } = useSelector((state) => state.user);
  const { clinic } = useSelector((state) => state.clinic);
 console.log("dhdhdh",clinic)

  const [color] = useState("#344767");

  const [mmu, setMMUList] = useState([]);
  const [userlist, setUserList] = useState([]);

  const { setFieldValue, setFieldError } = useFormikContext();


  console.log("userlist",userlist)

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    
  } = formField;
  var {
    firstName: firstNameV,
    
  } = values;

  function getProfileByRoleCode(code) {
    const result = user.filter(
      (userItem) => userItem.rolecode === code
    );
    setUserList(result);
  }
 
  
    useEffect(() => {
      dispatch(getClinic());
      
      dispatch(getRole());
       dispatch(getUser());
    }, []);
  
  

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Association</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
         
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.mmu && touched.mmu}
              >
                <InputLabel
                  htmlFor="mmu"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Clinic
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="mmu"
                  id="mmu"
                  value={values.mmu}
                  onChange={(e) => {
                    const mmucode = e.target.value;
                
                    const selectedmmuObj =
                      clinic.find((obj) => obj.code === mmucode) || {};
                   
                   
                    setFieldValue("selectedmmuObj", selectedmmuObj);  
                    setFieldValue("mmu", mmucode);
                   
                  }}
                >
                  <option value=""></option>
                  {clinic.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.mmu && touched.mmu && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.mmu}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.rolecode && touched.rolecode}
              >
                <InputLabel
                  htmlFor="rolecode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Role
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="rolecode"
                  id="rolecode"
                  value={values.rolecode}
                  onChange={(e) => {
                    const rolecode = e.target.value;
                    getProfileByRoleCode(e.target.value)
                    const selectedRoleObj =
                      role.find((obj) => obj.rolecode === rolecode) || {};
                   
                    // const roleName =
                    //   e.target.options[e.target.selectedIndex].text;
                    setFieldValue("roleName", selectedRoleObj.name);  
                    setFieldValue("rolecode", rolecode);
                   
                  }}
                >
                  <option value=""></option>
                  {role.map((obj) => (
                    <option key={obj.id} value={obj.rolecode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.rolecode && touched.rolecode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.rolecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={.1}>
          <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.profile && touched.profile}
              >
                <InputLabel
                  htmlFor="profile"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Profile
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="profile"
                  id="profile"
                  value={values.profile}
                  onChange={(e) => {
                    const profile = e.target.value;
                    const selectedProfileObj =
                      userlist.find((obj) => obj.profileid === profile) || {};
                     
                   
                    setFieldValue("selectedProfileObj", selectedProfileObj);  
                    setFieldValue("profile", profile);
                   
                  }}
                >
                  <option value=""></option>
                  {userlist.map((obj) => (
                    <option key={obj.id} value={obj.profileid}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.profile && touched.profile && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.profile}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          
            </Grid>
         
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for AssociationInfo
AssociationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default AssociationInfo;
