const form = {
  formId: "new-user-form",
  formField: {

    //personal Detail


    firstname: {
      name: "firstname",
      label: "First Name",
      type: "text",
      errorMsg: "Firstname is required.",

    },
    dateofbirth: {
      name: "dateofbirth",
      label: "Date of Birth",
      type: "date",
      errorMsg: "Date of Birth is required.",
    },
    lastname: {
      name: "lastname",
      label: "Last Name",
      type: "text",
      errorMsg: "Lastname is required.",

    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },

    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "District is required.",

    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "select",
      errorMsg: "Gender is required.",
    },
    emailId: {
      name: "emailId",
      label: "Email Id",
      type: "email",
      errorMsg: "Email Id is required.",
      invalidMsg: "Your Email Id is invalid",
    },
    phoneno: {
      name: "phoneno",
      label: "Phone No",
      type: "tel",
      errorMsg: "Phone Number is required.",

    },
    preferedlanguage: {
      name: "preferedlanguage",
      label: "Prefered Language",
      type: "text",
      errorMsg: "Prefered Language is required.",

    },

    personalinformation: {
      name: "personalinformation",

      errorMsg: "Personal Information is required.",

    },


    qualificationinformation: {
      name: "qualificationinformation ",

      errorMsg: "Qualification Information  is required.",

    },
    location: {
      name: "location",
      label: "Location",
      type: "text",
      errorMsg: "Location is required.",

    },

    // upload Document
    document: {
      name: "document",

      errorMsg: "Document is required.",

    },

    //contact Detail
    secondline: {
      name: "secondline",
      label: "Second Line",
      type: "text",
      errorMsg: "Second Line is required.",

    },
    firstline: {
      name: "firstline",
      label: "First Line",
      type: "text",
      errorMsg: "First Line is required.",

    },


    pincode: {
      name: "pincode",
      label: "Pincode",
      type: "select",
      errorMsg: "Pincode is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub District",
      type: "select",
      errorMsg: "Sub District is required.",
    },
    state: {
      name: "state",
      label: "State",
      type: "select",
      errorMsg: "State is required.",
    }, 
    country: {
      name: "country",
      label: "Country",
      type: "text",
      errorMsg: "Country is required.",

    },
   
    agentcomissiontype: {
      name: "agentcomissiontype",
      label: "Agent Commission Type",
      type: "select",
      errorMsg: "Agent Commission Type is required.",
    },
    agentcomission: {
      name: "agentcomission",
      label: "Agent Commission",
      type: "number",
      errorMsg: "Agent Commission is required.",
    },
    resourcecomission: {
      name: "resourcecomission",
      label: "Resource Commission",
      type: "number",
      errorMsg: "Resource Commission is required.",
    },
    resourcecomissiontype: {
      name: "resourcecomissiontype",
      label: "Resource Commission Type",
      type: "select",
      errorMsg: "Resource Commission Type is required.",
    },
  },
};

export default form;
