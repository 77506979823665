import DashboardLayout from 'components/DashboardLayout'
import React from 'react'
import { useParams } from 'react-router-dom';
import Sidenav from 'components/Sidenav';
import { useMaterialUIController } from 'context';
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";
import { agentRoutes } from 'routes/agentRoutes';
import InvestmentRedeem from 'components/Resuable/InvestmnetPanel/InvestmentRedeem';
import FamilyTree from 'components/Resuable/InvestmnetPanel/familytree';
import AgentDoctorlist from './doctorList/AgentDoctorlist';
import ProductRegistration from "../agent/registrationResource/RegistrationResource"
import Analytics from './analytics/Analytics';

import MDBox from 'components/MDBox';
import { AppBar, Stack } from '@mui/material';

import Directory from './directory/Directory';
import AgentProfile from './profile/AgentProfile';
import Transactions from './transactions';


import DashboardNavbar from 'components/Shared/DashboardNavbar';
import Resources from 'components/resources/Resources';
import ViewProfile from 'components/viewProfile';
// import Preview from 'components/Preview/Preview';


const AgentLayout = () => {

    const [controller, dispatch] = useMaterialUIController();
    const { slug } = useParams();

    const { miniSidenav, direction, layout, openConfigurator, sidenavColor, transparentSidenav, whiteSidenav, darkMode,
      } = controller;

  return (
    <>
    <DashboardLayout> 
  
        <Stack style={{  position: 'fixed', width: '95%', zIndex: '1000' }}>
          <DashboardNavbar />
        </Stack>       
        <Sidenav color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Medharva"
            routes={agentRoutes}
         />
    </DashboardLayout>
    {slug==="profile" && <AgentProfile />}
    {slug==="analytics" && <Analytics />}
    {slug==="resources" && <Resources />}
    {slug==="transactions" && <Transactions />}
    {slug==="registration" && <ProductRegistration/>}
    {/* {slug==="directory" && <AgentDoctorlist />} */}
    {slug==="directory" && <Directory />}
    {slug==="profile-list" && <FamilyTree />}
    {slug==="view-profile" && <ViewProfile />}
   
    {/* {slug==="transactions" && <InvestmnetList />} */}
    {/* {slug==="add-investment" && <Investment/>} */}
    {/* {slug==="transactions" && <InvestmentRedeem />} */}
    {/* {slug==="bank-details" && <BankDetail />} */}
    {/* {slug==="agent-doctor" && <AgentDoctor/>} */}
 
    </>
  )
}

export default AgentLayout;