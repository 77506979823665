import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    // lastName,
 
    email,
    password,
    age,
    repeatPassword,
    experience,
    gender,
    description,
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [experience.name]: Yup.string().required(experience.errorMsg),
   [gender.name]: Yup.string().required(gender.errorMsg),
   // [description.name]: Yup.string().required(description.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [phonenumber.name]: Yup.string()
    .required(phonenumber.errorMsg)
    .min(10, phonenumber.invalidMsg)
    .max(10, phonenumber.invalidMsg),
    // [age.name]: Yup.number().required(age.errorMsg) .min(22, "must should be age 22 "),
    age: Yup.number()
    .min(22, 'Age must be at least 22 years.')
    .required(age.errorMsg),

    password: Yup.string()
    .required('Password is required.')
    .min(8, 'Password must be at least 8 characters.')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character.'
    ),
  repeatPassword: Yup.string()
    .required('Repeat password is required.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
  }),
  
];

export default validations;
