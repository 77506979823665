// import { Card, Dialog } from '@material-ui/core'
// import DashboardLayout from 'components/DashboardLayout'
// import FilterCmp from 'components/Filters/FilterCmp'
// import Negotiate from 'components/Popup/Negotiate'
// import TableCmp from 'components/Tables/TableCmp'
// import React, { useEffect, useState } from 'react'
// import { useDispatch } from 'react-redux'
// import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
// import { tableDataAction } from 'redux/Actions/tableDataAction';
// import Swal, {SweetAlertOptions} from 'sweetalert2';
// import { APIs } from 'Services/APIs'
// import { directoryFilter } from 'static/filters/directoryFilter';
// import tableActionRow, { clearActionOfRow, clearActionRowData } from 'redux/slices/tableActionRow'
// const Transactions = () => {

//     const { tableData, AuthReducer, tableActionRow } = useSelector(state => state);
//     const dispatch = useDispatch()
//     const [currentPage, setCurrentPage] = useState(1);
//     const [rowData, setRowData]= useState()
//     const [filterData, setFilterData] = useState({})
//     const [openNegotiate, setOpenNegotiate] = useState(false);
//     const [negotiateValue, setNegotiateValue] = useState()
//     const navigate = useNavigate();
//     const PER_PAGE = 5;
//     // useEffect(()=>{
//     //     dispatch(tableDataAction({ page:"directory", profileId:AuthReducer?.auth?.profileid}))
//     // },[])

//     // useEffect(()=>{
//     //     dispatch(tableDataAction({name:"currentPage", value:currentPage, page:"allList"}))
//     // },[currentPage])
//     useEffect(async()=>{
//       dispatch(tableDataAction({ url:`${APIs.getProfileList+AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${PER_PAGE}`}))
//     },[])
    
//     useEffect(()=>{
//       dispatch(tableDataAction({ url:`${APIs.getProfileList+AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage-1}&size=${PER_PAGE}`}))
//   },[currentPage])

//   useEffect(() => {
//     const rowData = [
//       {
//         resourceName: "John Doe",
//         role: "Manager",
//         resourceComission: "$500",
//         agentComission: "$200",
//         resourceDetails: "Experienced in project management",
//         view: "View",
//         negotiate: "Negotiate"
//       },
//       {
//         resourceName: "Jane Smith",
//         role: "Developer",
//         resourceComission: "$400",
//         agentComission: "$150",
//         resourceDetails: "Expert in full-stack development",
//         view: "View",
//         negotiate: "Negotiate"
//       }
//       // Add more rows as needed
//     ];
//     setRowData(rowData);
//   }, []);
//   const columns = [
//     { id:"resourceName", label: "Resource Name", accessor: "resourceName" },
//     { id:"role",  label: "Role", accessor: "role" },
//     { id:"resourceComission",  label: "Resource Comission", accessor: "resourceComission" },
//     { id:"agentComission",  label: "Agent Comission", accessor: "agentComission" },
//     { id:"resourceDetails",  label: "Resource Details", accessor: "resourceDetails" },
//     {
//       id: "view",
//       label: "Resource Details",
//       accessor: "view",
//       Cell: ({ row }) => (
//         <button onClick={() => handleRowAction("view", row)}>View</button>
//       )
//     },
//     {
//       id: "negotiate",
//       label: "Negotiate Comission",
//       accessor: "negotiate",
//       Cell: ({ row }) => (
//         <button onClick={() => handleRowAction("negotiate", row)}>Negotiate</button>
//       )
//     }


//   ]

//   const actionButtons = ["view", "negotiate"]

//   useEffect(()=>{
//     if(tableActionRow.actionType === "negotiate"){
//       setOpenNegotiate(true);
//       return 
//   }else if(tableActionRow.actionType === "view"){
//     navigate('/app/mic/agents/view-profile');
//   }
//   },[tableActionRow.actionType])

// const closeNegotiate =()=> {
//       setOpenNegotiate(false);
//       dispatch(clearActionRowData())
//   }

//   // filter handler

//   const handleSearch =()=>{
//     console.log(filterData, "Filter data")
//   }

//   const handleClear =()=>{
//     setFilterData({})
//   }

//   return (
//     <DashboardLayout>
//     <Card style={{marginTop:"5%"}}>
//         <FilterCmp filterArray={tableData?.filterArray} filterData={filterData} setFilterData={setFilterData}
//           handleSearch={handleSearch} handleClear={handleClear} />
//         <TableCmp columns={columns} rows={rowData} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages}  />
//     </Card>
// </DashboardLayout>
//   )
// }

// export default Transactions;
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Dialog } from '@material-ui/core';
import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DashboardLayout from 'components/DashboardLayout';
import FilterCmp from 'components/Filters/FilterCmp';
import Negotiate from 'components/Popup/Negotiate';
import TableCmp from 'components/Tables/TableCmp';
import { tableDataAction } from 'redux/Actions/tableDataAction';
import { APIs } from 'Services/APIs';
import { tableActionRow, clearActionOfRow, clearActionRowData } from 'redux/slices/tableActionRow';

const Transactions = () => {
  const { tableData, AuthReducer, tableActionRow } = useSelector(state => state);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [openNegotiate, setOpenNegotiate] = useState(false);
  const navigate = useNavigate();
  const PER_PAGE = 5;

  useEffect(() => {
    dispatch(tableDataAction({
      url: `${APIs.getProfileList + AuthReducer?.auth?.profileid}&ispageable=true&page=${currentPage - 1}&size=${PER_PAGE}`
    }));
  }, [currentPage, AuthReducer?.auth?.profileid, dispatch]);

  useEffect(() => {
    const initialRowData = [
      {
        resourceName: "John Doe",
        role: "Manager",
        resourceComission: "$500",
        agentComission: "$200",
        resourceDetails: "Experienced in project management",
        view: "View",
        negotiate: "Negotiate"
      },
      {
        resourceName: "Jane Smith",
        role: "Developer",
        resourceComission: "$400",
        agentComission: "$150",
        resourceDetails: "Expert in full-stack development",
        view: "View",
        negotiate: "Negotiate"
      }
    ];
    setRowData(initialRowData);
  }, []);

  const columns = [
    { id: "resourceName", label: "Resource Name", accessor: "resourceName" },
    { id: "role", label: "Role", accessor: "role" },
    { id: "resourceComission", label: "Resource Comission", accessor: "resourceComission" },
    { id: "agentComission", label: "Agent Comission", accessor: "agentComission" },
    { id: "resourceDetails", label: "Resource Details", accessor: "resourceDetails" },
    {
      id: "view",
      label: "Resource Details",
      accessor: "view",
      Cell: ({ row }) => (
        <button onClick={() => handleRowAction("view", row)}>View</button>
      )
    },
    {
      id: "negotiate",
      label: "Negotiate Comission",
      accessor: "negotiate",
      Cell: ({ row }) => (
        <button onClick={() => handleRowAction("negotiate", row)}>Negotiate</button>
      )
    }
  ];

  const handleRowAction = (action, row) => {
    dispatch(tableActionRow({ actionType: action, rowData: row }));
  };

  useEffect(() => {
    if (tableActionRow.actionType === "negotiate") {
      setOpenNegotiate(true);
    } else if (tableActionRow.actionType === "view") {
      navigate('/app/mic/agents/view-profile');
      
    }

    dispatch(clearActionOfRow());
  }, [tableActionRow.actionType, dispatch, navigate]);

  const closeNegotiate = () => {
    setOpenNegotiate(false);
    dispatch(clearActionRowData());
  };

  const handleSearch = () => {
    console.log(filterData, "Filter data");
  };

  const handleClear = () => {
    setFilterData({});
  };

  return (
    <DashboardLayout>
      <Card style={{ marginTop: "5%" }}>
        <FilterCmp filterArray={tableData?.filterArray} filterData={filterData} setFilterData={setFilterData}
          handleSearch={handleSearch} handleClear={handleClear} />
        <TableCmp columns={columns} rows={rowData} setCurrentPage={setCurrentPage} pageCount={tableData?.rowData?.page?.totalPages} />
      </Card>
      <Dialog open={openNegotiate} onClose={closeNegotiate} fullWidth maxWidth="md">
        <DialogTitle>Negotiate Comission</DialogTitle>
        <DialogContent>
          <Negotiate />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNegotiate} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
};

export default Transactions;
