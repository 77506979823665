import { Card, CardContent, Grid, Typography } from '@mui/material'
import React from 'react'

// import { makeStyles } from '@mui/styles';
import './AgentProcess.css'




const AgentProcess = () => {

    return (

        <Grid mt={8}>
            <Card>
                <Grid className='card-main'>
                    <Grid className='card-text' >What is the Process ?</Grid>

                    <Grid className='card-process'>
                        <Grid>
                            <Grid className='text-name' >Registration</Grid>
                            <Grid className='registration-text' >Fill the Registration form and upload All the mentioned documents</Grid>
                        </Grid>
                        <Grid>
                            <Grid className='text-name' >Verification</Grid>
                            <Grid className='registration-text'>Medharva team verifies the all documents and  credentials within 7 working days.</Grid>
                        </Grid>
                        <Grid>
                                <Grid className='text-name' sx={{lineHeight:'20px'}}>Compliance Check</Grid>
                            <Grid className='registration-text'>Outsite Verification of mentioed information and products</Grid>
                        </Grid>
                        <Grid>
                            <Grid className='text-name'> Approval</Grid>

                            <Grid className='registration-text'>After successful verfification, Registration is approved and Training</Grid>
                        </Grid>
                    </Grid>

                </Grid>

            </Card>
        </Grid>

    )
}

export default AgentProcess
