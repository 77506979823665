
// import React, { useState, useEffect, useRef } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import MDBox from "components/MDBox";
// import { CircularProgress, Grid } from "@mui/material";
// import FileUpload from "react-material-file-upload";
// import { MoonLoader } from "react-spinners";
// import { useSelector } from "react-redux";
// import { UploadDocumnet } from "Services/UploadDocumnet";
// import MDButton from "components/MDButton";
// import DataTable from "examples/Tables/DataTable";
// import { FaRegTimesCircle } from "react-icons/fa";


// const OfflinePrescription = (props) => {

//     const fileInputRef = useRef(null);


//     const [uploadresponse, setUploadresponse] = useState("");

//     const [files, setFiles] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
//     console.log(uploadresponse);
  
//     useEffect(()=>{
// props.onData(uploadresponse)
//     },[uploadresponse])
//     function onfileChange(e) {
//         setFiles(e);
//         finalupload(e);
//     }

//     async function finalupload(e) {
//         // Check if 'e' is not empty and has at least one element
//         if (e && e.length > 0) {
//             const formData = new FormData();

//             // Update the formData object
//             formData.append("file", e[0]);
//             const request = {
//                 resourcecode: "MEDHARVA",
//                 username: auth.name,
//                 code: auth.rolecode,
//             };
//             const myJSON = JSON.stringify(request);
//             formData.append("body", myJSON);
//             setLoading(true);
//             const data = await UploadDocumnet(formData);
//             // values.document = data.docid;

//             setUploadresponse(data?.docid);

//             setFiles([]);
//             setLoading(false);
//         } else {
//             swal("oops!", "Please enter a all field!", "error");
//             setLoading(false);

//         }
//     }

//     const handleSubmitwithdoc = () => {
//         setOpenoflinepres(false);

//     };
//     const handleSubmitwithoutdoc = () => {
//         setOpenoflinepres(false);

//     };


//     const handleClose = () => {
//         setOpenoflinepres(false);
//         setUploadresponse("")
//     };
//     const removeData = (id) => {
//         const newData = uploadresponse.filter((item) => item.docid !== id);
       
//           setUploadresponse(newData);
         
//         };
//     return (


//         <Grid item xs={12} sm={12} mt={3}>


//             <FileUpload
//                 buttonText={<span style={{ color: "white", display: "flex", alignItems: "center" }}>
//                     {loading ? (
//                         <MoonLoader color="#f2fefa" size={16} />
//                     ) : (
//                         "Upload Document"
//                     )}
//                 </span>}



//                 value={files}
//                 onChange={onfileChange}

//             />
//   <Grid
//             item
//             xs={12}
//             sm={12}
//             style={{
//               border: "1px solid #727375",
//               borderRadius: "5px",
//               marginTop: "15px",
//               marginBottom: "10px",
//               marginLeft: "5px",
//               marginRight: "5px",
//             }}
//           >
         

//             <DataTable
//               showTotalEntries={false}
//               entriesPerPage={false}
//               table={{
//                 columns: [
//                   { Header: "name", accessor: "filename" },
//                   { Header: "documentid", accessor: "docid" },
//                   {
//       Header: "Action",
//       accessor: "uid",
//       Cell: ({ row }) => (
//         <span>
//           <FaRegTimesCircle
//             style={{
//               color: "#f44336",
//               width: "25px",
//               height: "25px",
//               cursor: "pointer",
//             }}
//             onClick={() => removeData(row.values.uid)}
//           />
//         </span>
//       ),
//       width: "12%",
//     },
//                 ],
//                 rows: uploadresponse,
//               }}
//             />
//           </Grid>
//         </Grid>

//     );
// };

// export default OfflinePrescription;
import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDBox from "components/MDBox";
import { CircularProgress, Grid } from "@mui/material";
import FileUpload from "react-material-file-upload";
import { MoonLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { UploadDocumnet } from "Services/UploadDocumnet";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { FaRegTimesCircle } from "react-icons/fa";
import swal from "sweetalert";
import axios from "../../../axiosinstance";

const OfflinePrescription = (props) => {
  const fileInputRef = useRef(null);
  const [uploadresponse, setUploadresponse] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  useEffect(() => {
    props.onData(uploadresponse);
  }, [uploadresponse, props]);

  const onfileChange = (e) => {
    setFiles(e);
    finalupload(e);
  };

  const finalupload = async (e) => {
    if (e && e.length > 0) {
      const formData = new FormData();
      formData.append("file", e[0]);

      const request = {
        resourcecode: "MEDHARVA",
        username: auth.name,
        code: auth.rolecode,
      };
      const myJSON = JSON.stringify(request);
      formData.append("body", myJSON);

      setLoading(true);
      try {
        const data = await UploadDocumnet(formData);
        setUploadresponse((prev) => [...prev, data]);
        setFiles([]);
      } catch (error) {
        swal("Oops!", "File upload failed!", "error");
      } finally {
        setLoading(false);
      }
    } else {
      swal("Oops!", "Please select a file!", "error");
    }
  };

  const handleClose = () => {
    setOpenoflinepres(false);
    setUploadresponse([]);
  };
console.log("upload",uploadresponse)
  // const removeData = (docid) => {
  //   const newData = uploadresponse.filter((item) => item.docid !== docid);
  //   setUploadresponse(newData);
  // };
  const removeData = async (id) => {
    try {
      const response = await axios.delete(`/new-upload-service-mongo/api/attachment/delete/${id}`);
      console.log(response);
 
      setUploadresponse(prevResponse => prevResponse.filter((item) => item.docid !== id));
    } catch (e) {
      console.error("Error removing data:", e);
    }
  };
 

  return (
    <Grid item xs={12} sm={12} mt={3}>
      <FileUpload
        buttonText={
          <span style={{ color: "white", display: "flex", alignItems: "center" }}>
            {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Upload Document"}
          </span>
        }
        value={files}
        onChange={onfileChange}
      />
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          border: "1px solid #727375",
          borderRadius: "5px",
          marginTop: "15px",
          marginBottom: "10px",
          marginLeft: "5px",
          marginRight: "5px",
        }}
      >
        <DataTable
          showTotalEntries={false}
          entriesPerPage={false}
          table={{
            columns: [
              { Header: "Name", accessor: "filename" },
              { Header: "Document ID", accessor: "docid" },
              {
                Header: "Action",
                accessor: "uid",
                Cell: ({ row }) => (
                  <span>
                    <FaRegTimesCircle
                      style={{
                        color: "#f44336",
                        width: "25px",
                        height: "25px",
                        cursor: "pointer",
                      }}
                      onClick={() => removeData(row.values.docid)}
                    />
                  </span>
                ),
                width: "12%",
              },
            ],
            rows: uploadresponse.map((doc, index) => ({
              filename: doc.filename,
              docid: doc.docid,
              uid: index,
            })),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default OfflinePrescription;

