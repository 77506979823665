import { Grid } from "@mui/material";
import React from "react";
import "./userInformation.css"
import { useSelector } from "react-redux";

const photoSection = ({ image }) => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  return (
    <>
      <Grid item className="userinformationitem1">
        <Grid item>
          <img src={image} alt="image" className="userinformationimg" />
        </Grid>
        <Grid item>
        <button className="userinformationbrowse">{auth?.name && auth?.name?.replace(/\b\w/g, l => l?.toUpperCase())}</button>

          <br />
          <br />
          {/* <button className="userinformationaddphoto">Add Photo </button> */}
        </Grid>
      </Grid>
    </>
  );
};

export default photoSection;
