import * as React from "react";

// @mui material components

import Grid from "@mui/material/Grid";

import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

// @mui material component

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";

// import { getRoles } from "@testing-library/react";

import axios from "../../../axiosinstance";

import { BarLoader, MoonLoader } from "react-spinners";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";

import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";

import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";

import TextField from "@mui/material/TextField";

import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import PreviewIcon from "@mui/icons-material/Preview";

import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { downloadurl } from "../../../Services/UploadDocumnet";
import DownloadIcon from "@mui/icons-material/Download";
import MDButton from "components/MDButton";
import { APIs } from "Services/APIs";
const PatientPrescriptionlist = () => {
  const navigate = useNavigate();

  const comp = "SM";

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [fileurl, setFileurl] = useState(false);
  const [color] = useState("#344767");
  const [isloading, setIsLoading] = useState(true);
  const handleLoad = () => {
    setIsLoading(false);
  };
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const [familydata, setFamilydata] = useState([]);
  const label =
    auth?.rolecode === ROLE_CODE.patientcode
      ? "Select Family Member"
      : "Select Patient";
  let selectedName = "";

  const dataObj = {
    columns: [
      { Header: "patient name", accessor: "patient.name" },

      { Header: "order id", accessor: "ordernumber" },

      { Header: "booking date", accessor: "fcreateddate" },

      { Header: "STATUS", accessor: "recstatus" },

      { Header: "Action", accessor: "action" },
    ],

    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);

  const iconStyle = {
    fontSize: "1.5em",
    color: "#344767",
    marginRight: "9px",
    cursor: "pointer",
  };

  const addButtonStyle = {
    display: "flex",

    fontSize: "2em",

    flexDirection: "column",

    marginRight: "10px",
  };

  const defaultPropFamily = {
    options: !familydata ? [{ name: "Loading...", id: 0 }] : familydata,

    getOptionLabel: (option) => option.name,

    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const selfPlaceholder = "Self";

  defaultPropFamily.options = defaultPropFamily.options.map((option) => {
    if (
      option?.name === localStorage.getItem("profile-Name")?.replace(/"/g, "")
    ) {
      return { ...option, name: selfPlaceholder };
    }
    return option;
  });
  const handleDownload = (filename) => {
    swal({
      title: "Confirm Download",
      text: `Do you want to download report?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        confirm: "Download",
      },
    }).then((willDownload) => {
      if (willDownload) {
        fetch(`${downloadurl}/${filename}`)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              URL.revokeObjectURL(blobUrl);
            }, 100);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            swal(
              "Error",
              "An error occurred while downloading the file.",
              "error"
            );
          });
      } else {
        swal("Download Cancelled", "You have cancelled the download.", "info");
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  function viewData(item, rolecode) {
    if (
      item.documentofproof === "" ||
      item.documentofproof == "string" ||
      item.documentofproof === null ||
      item.documentofproof === undefined
    ) {
      // const path =
      //   rolecode === "RE001"
      //     ? "/app/nurse/prescription-view"
      //     : "/app/patient/prescription-view";
      // navigate(path, {
      //   state: {
      //     visitid: item?.visitid,
      //     doctor: item?.doctor?.name,
      //     patient: item?.patient,
      //     orderId: item?.ordernumber,
      //     status: item?.recstatus,
      //     modifiedate: item.modifieddate,
      //   },
      // });
      navigate(
        `/app/${auth.rolename?.toLowerCase()}/prescription-view?visitid=${
          item?.visitid
        }`,
        {
          state: {
            visitid: item?.visitid,
            doctor: item?.doctor?.name,
            patient: item?.patient,
            orderId: item?.ordernumber,
            status: item?.recstatus,
            modifiedate: item?.modifieddate
          },
        }
      );
    } else {
      handleDownload(item.documentofproof);
    }
  }
  function viewdocData(item, rolecode) {
    setOpen(true);
    setFileurl(item.documentofproof);
  }

  async function get(profileid) {
    await axios

      .get(`${APIs.loginServiceApi}/get?createdby=${profileid}`)

      .then((response) => {
        const newData = [...response.data?.data?.dtoList, auth];

        setFamilydata(newData);

        // setLoader(false);
      })

      .catch((e) => {
        // setLoader(false);

        console.log("error", e);
      });
  }

  useEffect(() => {
    get(auth.profileid);
  }, []);

  const getsinglepersondata = (id) => {
    setLoading(true);

    axios

      .get(
        `${APIs.bookingServiceApi}/get?createdby=${auth.profileid}&patientobj.profileid=${id}&recstatus=PRESCRIBED`
      )

      .then((response) => {
        response.data.data.dtoList.sort((a, b) => {
          const dateA = new Date(a.slot.startdate);
          const dateB = new Date(b.slot.startdate);

          if (dateA > dateB) return -1;
          if (dateA < dateB) return 1;
          return 0;
        });
        response.data.data.dtoList.map((item) => {
          if (
            item.patient.name ===
            localStorage.getItem("profile-Name")?.replace(/"/g, "")
          ) {
            item.patient.name = selfPlaceholder;
          }
          item.action = (
            <span>
              {item.documentofproof !== "string" ? (
                <span>
                  <PreviewIcon
                    fontSize="medium"
                    style={iconStyle}
                    onClick={() => {
                      viewdocData(item);
                    }}
                  />
                  <DownloadIcon
                    fontSize="medium"
                    style={iconStyle}
                    onClick={() => {
                      viewData(item);
                    }}
                  />
                </span>
              ) : (
                <PreviewIcon
                  fontSize="medium"
                  style={iconStyle}
                  onClick={() => {
                    viewData(item);
                  }}
                />
              )}
            </span>
          );

          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);

        // setTableDatas(dataObj);

        setLoading(false);
      })

      .catch((e) => {
        console.log("error", e);

        setLoading(false);
      });
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      {/* {isMidScreen ? <></> : <Navbar />} */}
      {/* {isMidScreen ? <MobileNavbar /> : <></>} */}
      <MDBox pt={isMidScreen ? 3 : 0}>
        <MDBox mt={isMidScreen ? 23 : 20}>
          <Card
            style={{
              marginTop: "180px",
              marginLeft: "22px",
              marginRight: "22px",
            }}
          >
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",

                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    My Prescription
                  </MDTypography>
                </span>
                {/* <Link
              style={{
                alignSelf: "flex-end",

                marginRight: "10px",

                marginTop: "-23px",

                color: "#344767",
              }}
              to={"/page/search-doctor"}
            >
              <MdOutlineLibraryAdd />
            </Link> */}
              </div>
            </MDBox>

            <Grid container spacing={3}>
              <Grid item xs={12} sm={7} mt={2}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  style={{ marginLeft: "20px" }}
                  sx={{ width: 300 }}
                  {...defaultPropFamily}
                  onChange={(_, newValue) => {
                    getsinglepersondata(newValue?.profileid);

                    if (newValue && newValue.name === selfPlaceholder) {
                      selectedName = localStorage
                        .getItem("profile-Name")
                        .replace(/"/g, "");
                    } else {
                      selectedName = newValue?.name;
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label={label} />
                  )}
                />
              </Grid>
            </Grid>

            <MDBox mt={1}>
              <BarLoader
                color={color}
                loading={loading}
                aria-label="Loading Spinner"
              />
            </MDBox>

            {!loading && (
              <MDBox pt={3}>
                <MDBox>
                  <Card>
                    <DataTable table={tableDatas} canSearch={true} />
                  </Card>
                </MDBox>
              </MDBox>
            )}
          </Card>
        </MDBox>
        {open && (
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
              style={{
                backgroundColor: "#039147",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Prescription View
            </DialogTitle>
            <DialogContent>
              <MDBox mt={1}>
                <BarLoader
                  color={color}
                  loading={isloading}
                  aria-label="Loading Spinner"
                />
              </MDBox>

              <iframe
                src={`${downloadurl}/${fileurl}`}
                style={{
                  width: "100%",
                  height: "500px",
                  display: isloading ? "none" : "block",
                }}
                title="PDF Document"
                onLoad={handleLoad}
              />
            </DialogContent>
          </Dialog>
        )}
      </MDBox>

      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar /> : <></>}
    </div>
  );
};

export default PatientPrescriptionlist;
