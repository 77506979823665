import React, { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import "./profileinfo.css";
import Calendar from "../../../../../assets/images/Calendar.svg";
import Simplification from "../../../../../assets/images/Simplification.svg";
import Rupee from "../../../../../assets/images/rupee.svg";
import doctorprofile from "static/doctorprofile";
import Tooltip from '@material-ui/core/Tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

const Profileinfo = ({doctor,response ,doctorfess}) => {

 
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1000);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
 
  const handleTooltipOpen = () => {
    setIsTooltipOpen(true);
  };
 
  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1000);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let categorys = response?.usercategories?.filter(item => item?.category?.type === "CAT");

  return (
    <div>
      <Grid
        container
        mt={isSmallScreen ? 12 : 10}
        className="parentcontainer"
        xs={12}
      >
        <Grid item className="gridcontainer">
          <Grid item className="griditem">
     
            <img  alt="AboutDummy Logo" src={doctorprofile(doctor?.profileid)} className="imagesize" />
          </Grid>
        </Grid>
        <Grid item className="gridcontainer" xs={isSmallScreen ? 12 : 10}>
          <Grid
            item
            className="griditem"
            xs={12}
            ml={isSmallScreen ? 0 : 3}
            style={{
              display: "flex",
              justifyContent: isSmallScreen ? "center" : "space-between",
              gap: isSmallScreen ? 10 : 0,
            }}
          >
            <div className="name">{response?.login?.name}</div>
            <img
              src={Simplification}
              alt="Simplification Logo"
              className="imagesize2"
            />
          </Grid>

          <Grid item className="griditem" xs={12} ml={3}>
            <div className="about">
              {/* <span>{doctor.specialization}</span> */}
             
              {response?.addresslist?.map(
                  (item, index) =>
                    item?.recstatus === "PRIMARY" && (
                      <span>
                     
                        | 
                        {item?.firstline} {item?.secondline} {item?.city}{" "}
                        {item?.state}
                      </span>
                    )
                )}
            </div>
          </Grid>

          <Grid item className="gridcontainer2" xs={12} ml={3}>
            <Grid item className="griditem3">
            {categorys?.map((item, index) => (
                // <div key={index} className="specialization">
                // {item?.category?.name}
                // </div>
                <React.Fragment key={index}>
               {index === 0 ? (
  <div className="avialabledoctorspecialization">
    {item?.category?.name}
  </div>
) : (
  index === 1 ? (
    <div className="avialabledoctorspecialization">
      {item?.category?.name}
    </div>
  ) : null
)}
                {index === 0 && "" || index === 1 && categorys?.length > 2 ? (
                  <Tooltip
                    PopperProps={{
                      disablePortal: true,
                    }}
                    onClose={handleTooltipClose}
                    open={isTooltipOpen}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={
                      <React.Fragment>
                        {categorys?.map((category, index) => (
                          <div key={index}>{category?.category?.name}</div>
                        ))}
                      </React.Fragment>
                    }
                  >
                    <div
                      className="avialabledoctorspecialization"
                      onMouseEnter={handleTooltipOpen}
                      onMouseLeave={handleTooltipClose}
                    >
                      +{categorys?.length - 2} more
                    </div>
                  </Tooltip>
                ) : null}
              </React.Fragment>
              ))}


              {/* {response?.usercategories?.map((item, index) => (
                <div key={index} className="avialabledoctorspecialization">
                  {item?.category?.name}
                </div>
              ))} */}
            </Grid>
            <Grid item className="griditem2">
              <img src={Calendar} alt="Calendar Logo" className="imagesize2" />
              &nbsp;
              <div className="textdetail">{response?.login?.age} years</div>
            </Grid>
            <Grid item className="griditem2">
              <img src={Rupee} alt="Rupee Logo" className="imagesize2" />
              &nbsp;
              <div className="textdetail">{doctorfess?.length>0 && doctorfess[0]?.value }</div>
              {/* <div className="textdetail">{doctorfess[0]? doctorfess[0]?.value:null}</div> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Profileinfo;
