import { Grid } from "@mui/material";
import React from "react";
import "./numberUpdates.css";

const numberUpdatesInfo = ({ image, text }) => {
  return (
    <>
      <Grid item className="numberupdatesinneritem">
        <Grid item className="imagestyle">
          <img src={image} alt="Logo" className="numberimagesize" />
        </Grid>
        <Grid item className="numbertext">
          {text}
        </Grid>
      </Grid>
    </>
  );
};

export default numberUpdatesInfo;
