import React from "react";
import "../TechinicalProvider/style.css";
import technical from "../../findDoctor/assets/images/technical.svg";
import { Button, Grid, Typography } from "@mui/material";
const TechnicalMP = () => {
  return (
    <div>
      <Grid className="tech-provider-1" sx={{}}>
        <Grid className="tech-provider-2">
          <Grid className="tech-provider-3">24x7 Technical Support</Grid>
          <Grid className="tech-provider-4">
            <Grid className="tech-provider-5">
              <img className="tech-provider-6" src={technical} />
            </Grid>
            <Grid className="tech-provider-7">
              Interactive VR service provided by Medharva is best in class.
              Helps visualize situations better be it any. Now you can train
              your employees without the hassle of actually manifesting it.
              <Grid className="tech-provider-8">
                (VR service included only with Premium services)
              </Grid>
            </Grid>
          </Grid>

          <Grid className="tech-provider-9">
            <button className="tech-provider-10">Get a free Demo</button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default TechnicalMP;
