import React from "react";
import "./card.css";
const Text = () => {
  return (
    <div>
      <div className="text-slider-a">
        <div className="text-slider-B">
          <span className="text-slider-C">30+ Specialities</span>
          <span className="text-slider-D">
            Consult with top doctors across specialities{" "}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Text;
