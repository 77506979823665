
const form = {
  formId: "new-Disease-form",
  formField: {
    DiseaseName: {
      name: "DiseaseName",
      label: "Disease Name",
      type: "text",
      errorMsg: "Disease Name is required.",
    },
    DiseaseDescrption: {
      name: "DiseaseDescrption",
      label: "Disease Description",
      type: "text",
      errorMsg: "Disease Description is required.",
    },
   
    DiseaseType: {
      name: "DiseaseType",
      label: "Disease Type",
      type: "text",
      errorMsg: "Disease Type is required.",
    },
  
  },
};

export default form;

