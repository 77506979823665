import {
  Autocomplete,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
  Tooltip,
} from "@mui/material";
import FilterCmp from "components/Filters/FilterCmp.tsx";
import DataTable from "components/Tables/DataTable";
import axios from "../../../../axiosinstance";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { directoryFilter } from "static/filters/directoryFilter";
import TableCmp from "components/Tables/TableCmp";
import { APIs } from "Services/APIs";
import Swal, { SweetAlertOptions } from "sweetalert2";
import tableActionRow, {
  clearActionOfRow,
  clearActionRowData,
  setActionTypeRow,
} from "redux/slices/tableActionRow";
import { useLocation, useNavigate } from "react-router-dom";
import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
import DashboardLayout from "components/DashboardLayout";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import CreateUserpopop from "./components/CreateUserpopop";

import { micappCode } from "static/micappCode";
import MDInput from "components/MDInput";
import {
  deleteLoginServiceData,
  getLoginServiceData,
} from "redux/Actions/getLoginServiceData";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";

import MDBadgeDot from "components/MDBadgeDot";
import UserEdit from "./UserEdit";
import { pincodeAssociationAction } from "redux/Actions/getPincodeAssociationAction";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const Userlist = () => {
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { tableData, AuthReducer, tableActionRow } = useSelector(
    (state) => state
  );
  const { loading } = useSelector((state) => state.tableData);
  //  const [ loading , setLoading] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState();
  const [filterData, setFilterData] = useState({});
  const [perPage, setPerPage] = useState(10);
  const [openpopupRole, setopenpopupRole] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [selectdesi, setSelectDesi] = useState("");
  const [item, setItem] = useState({});
  const [username, setUsername] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [roleList, setRoleList] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [designationList, setDesignationList] = useState("");
  const [selectedRole, setSelectRole] = useState("");
  const [selectdep, setSelectdep] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const { department } = useSelector((state) => state.department);
  const { designation } = useSelector((state) => state.designation);
  const { getPincodeAssociation } = useSelector((state) => state);
  const { getProjectTeam, getMicDetails } = useSelector((state) => state);
  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);
  // const PER_PAGE = 5;
  const location = useLocation();

  const nameFilter = new URLSearchParams(location.search).get("name");
  const getRole = async () => {
    try {
      const response = await axios.get(
        `${APIs.roleService}/getByPageV2?mappingcode=${micappCode?.mappingcode}`
      );
      setRoleList(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(async () => {
    setFilterData({ ...filterData, name: nameFilter });
    getUsers();
  }, []);

  const getUsers = () => {
    if (nameFilter) {
      dispatch(
        tableDataAction({
          url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
            getMicDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}&st=${nameFilter}`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
            getMicDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  };

  useEffect(() => {
    getUsers();
  }, [currentPage, perPage]);

  const filters = [
    {
      type: "text",
      placeholder: "Enter name",
      variant: "outlined",
      label: "Name",
      name: "name",
      page: "allList",
    },
  ];

  const actionButtons = (row) => {
    return (
      <Stack
        direction="row"
        sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
      >
        {/* <Tooltip title="Edit">
          <div>
            <FaRegEdit
              style={iconStyleedit}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                handleOpenEditDialog(row);
              }}
            />
          </div>
        </Tooltip> */}
        <Tooltip title="Delete">
          <div>
            <MdDelete
              style={iconStyle}
              onClick={() => {
                dispatch(setActionTypeRow({ type: "edit", data: row }));
                onDelete(row);
              }}
            />
          </div>
        </Tooltip>
      </Stack>
    );
  };
  const actionButtons2 = (row) => {
    return (
      <Stack direction="row" sx={{ display: "flex", justifyContent: "center" }}>
        <FormControlLabel
          name={row.id}
          control={
            <Switch
              onClick={(e) =>
                toggler(e.target.checked, row, roleList, departmentList)
              }
              defaultChecked={row.isactive === "ACTIVE"}
            />
          }
          label="Approved"
        />
      </Stack>
    );
  };

  const modifiedRows = tableData?.rowData?.dtoList?.map((row) => ({
    ...row,
    name: row.name,
    action: actionButtons(row),
    action2: actionButtons2(row),
  }));

  const columns = [
    { id: "name", label: "Name" },
    { id: "username", label: "User Name" },
    { id: "rolename", label: "Role" },
    { id: "departmentname", label: "Department" },
    { id: "designationname", label: "Designation" },
    { id: "mobileno", label: "Phone No" },
    { id: "recstatus", label: "Status" },
    { id: "emailid", label: "Email Id" },
    { id: "action2", label: "Change Status" },
    { id: "action", label: "Action" },
  ];

  if (tableActionRow.actionType === "edit") {
  }

  const handleSearch = () => {
    setCurrentPage(1);
    if (filterData?.name) {
      dispatch(
        tableDataAction({
          url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
            getMicDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}&st=${
            nameFilter || filterData.name
          }`,
        })
      );
    } else {
      dispatch(
        tableDataAction({
          url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
            getMicDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    }
  };

  const handleOpenEditDialog = (row) => {
    setSelectRole(row);
    setopenpopup(true);
  };
  function onDelete(row) {
    swal({
      title: "Are you sure?",
      text: "You will not be able to recover this item!",
      icon: "warning",
      buttons: ["Cancel", "Delete"],
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Deleting...", {
          icon: "info",
          buttons: false,
          closeOnClickOutside: false,
        });
        dispatch(deleteLoginServiceData(row))
          .then(() => {
            swal("Deleted!", "Your item has been deleted.", "success");

            navigate("/app/mic/user");
            dispatch(
              tableDataAction({
                url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
                  getMicDetails?.details?.projecthirarichalcode
                }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
              })
            );
          })
          .catch((error) => {
            swal(
              "Error",
              "An error occurred while deleting the item.",
              "error"
            );
          });
      } else {
        swal("Cancelled", "Your item is safe.", "info");
      }
    });
  }

  const defaultProps = {
    options: roleList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsDepartment = {
    options: departmentList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const defaultPropsDesignation = {
    options: designationList || [],
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const handleClear = () => {
    setFilterData({});
    dispatch(
      tableDataAction({
        url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
          getMicDetails?.details?.projecthirarichalcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };
  const handleCloseCreate = () => {
    setopenpopupRole(false);
  };
  const handleClose = () => {
    setopenpopup(false);
    // dispatch(tableDataAction({ url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${micappCode?.mappingcode}&createdby=${auth.profileid}&ispageable=true&page=${currentPage - 1}&size=${perPage}` }))
  };

  const updateTableDataAction = () => {
    dispatch(
      tableDataAction({
        url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
          getMicDetails?.details?.projecthirarichalcode
        }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
      })
    );
  };
  const toggler = async (checked, item, roleIdList, DeptIdList) => {
    try {
      const updatedUser = {
        ...item,
        isactive: checked ? "ACTIVE" : "INACTIVE",
      };

      await axios.put(`${APIs.loginServiceApi}/update`, updatedUser);

      dispatch(
        tableDataAction({
          url: `${APIs.loginServiceApi}/getByPageV2?mappingcode=${
            getMicDetails?.details?.projecthirarichalcode
          }&ispageable=true&page=${currentPage - 1}&size=${perPage}`,
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <DashboardLayout>
      {loading ? (
        <Card style={{ marginTop: "5%" }}>
          <>
            <SkeletonFilterCmp filterArray={filters} />
            <SkeletonTableCmp columns={columns} perPage={perPage} />
          </>
        </Card>
      ) : (
        <Card style={{ marginTop: "2%" }}>
          <MDBox p={3} lineHeight={1}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <MDTypography variant="h5" fontWeight="medium">
                User List
              </MDTypography>
              <MDButton color="info" onClick={() => setopenpopupRole(true)}>
                ADD
              </MDButton>
            </div>
          </MDBox>
          <FilterCmp
            filterArray={filters}
            filterData={filterData}
            setFilterData={setFilterData}
            handleSearch={handleSearch}
            handleClear={handleClear}
          />

          <TableCmp
            columns={columns}
            rows={modifiedRows}
            setCurrentPage={setCurrentPage}
            pageCount={tableData?.rowData?.page?.totalPages}
            setPerPage={setPerPage}
            perPage={perPage}
            currentPage={currentPage}
          />
        </Card>
      )}
      {openpopupRole && (
        <CreateUserpopop
          openpopupRole={openpopupRole}
          setopenpopupRole={setopenpopupRole}
          handleClose={handleCloseCreate}
          updateTableDataAction={updateTableDataAction}
        />
      )}
      {openpopup && (
        <UserEdit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          selectedRole={selectedRole}
          title={"EDIT USER"}
          handleClose={handleClose}
          updateTableDataAction={updateTableDataAction}
        />
      )}
    </DashboardLayout>
  );
};
export default Userlist;
