import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    firstName,
    lastName,

    email,

    phonenumber,

    age,
    gender,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
    
   
    
    [phonenumber.name]: Yup.string()
      .required(phonenumber.errorMsg)
      .min(10, phonenumber.invalidMsg)
      .max(10, phonenumber.invalidMsg),
  }),
];

export default validations;
