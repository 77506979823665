import { Grid } from "@mui/material";
import React from "react";
import "./homeFooter.css";

const NavbarImage = ({ image, onClick }) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };
  
  return (
    <div>
      <Grid item className="footernavgriditem">
        <button className="footernavbutton" onClick={handleClick}>
          <img src={image} alt="Logo" className="footernavbarimg" />
        </button>
      </Grid>
    </div>
  );
};

export default NavbarImage;
