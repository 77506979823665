import React, { useEffect, useState } from 'react'
import Mproviders from '../../findDoctor/assets/images/Mprovider.png';
import Slider from 'react-slick';
import '../MProvider/style.css'
import { Button, Grid } from '@mui/material';
import ProviderCard from './ProviderCard';
import MobileButton from './MobileButton';
const MedharvaProvider = () => {
    const providerCard = [
        { image: Mproviders },
        { image: Mproviders },
        { image: Mproviders },
      ];
      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
      const maxWidth = 600; 
  
      useEffect(() => {
          const handleResize = () => {
              setWindowWidth(window.innerWidth);
          };
  
          window.addEventListener('resize', handleResize);
  
          return () => {
              window.removeEventListener('resize', handleResize);
          };
      }, []);
      const sliderSetting = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
       
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: true,
              slidesToShow: 1,
               
            },
          },
        ],
      };
  return (
    <div>
      <Slider {...sliderSetting} >
        {providerCard .map((card, index) => (
          <div key={index}>
            <Grid mt={23} className='Medhrwaprovider-card-A' >
              <Grid className='Medhrwaprovider-card-B' >
                <img className='Medhrwaprovider-card-imge' src={card.image} alt={`Card ${index + 1}`} />            
              </Grid>
              {windowWidth <= maxWidth && (
                <div style={{display:'flex', flexDirection:'column-reverse', justifyContent:'space-around'}}>
                <Grid className='Mobile-view-card-a' >
                     <span className='Mobile-view-card-b'>Medharva</span>
                    <span className='Mobile-view-card-b'>For</span>
                   <span className='Mobile-view-card-b'>Providers</span>
                      
                </Grid>
                        <div >
                      {<MobileButton/>}
                       </div>
                </div>
            )}
              {windowWidth > maxWidth && (
                <div className='Main-Card-Container'>
                    <ProviderCard />
                </div>

            )}
            </Grid>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default MedharvaProvider
