export const capitalizeWords = (string) => {
  if(string === undefined) return; 
  if (typeof string !== 'string') {
    console.error('Expected a string but got:', typeof string);
    return '';
  }

  return string
    .toLowerCase()
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
