import PropTypes from "prop-types";
import * as React from "react";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { APIs } from "Services/APIs";
import FormField from "../createTestInfo/FormField/index";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { FaRegTimesCircle } from "react-icons/fa";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import { ErrorMessage } from "formik";

function TestInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [uomvalue, setUomvalue] = React.useState(null);
  const [QuantityId, setQuantityId] = useState("0");
  const [QTY, setQTY] = useState("");
  const [asset, setAsset] = useState("");
  const [uomList, setUomList] = useState("");
  const [tableData, setTableData] = useState({
    columns: [
      { Header: "investigation name", accessor: "name" },
      { Header: "Uom", accessor: "uom" },
      { Header: "Range", accessor: "range" },
      { Header: "Action", accessor: "action" },
    ],
    rows: [],
  });
  const [givenQty, setGivenQty] = useState();
  const [select, setSelect] = useState("");
  const [refreshKey, setRefreshKey] = useState(0);
  const [errorMessages, setErrorMessages] = useState({
    investigationname: "",
    range: "",
    uom: "",
  });
  const { formField, values, errors, touched } = formData;

  // uom
  const defaultPropssUOM = {
    options: !uomList ? [{ name: "Loading", id: "x" }] : uomList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const { name, testname, investigationname, uom, range } = formField;
  const {
    name: nameV,
    testname: testnameV,
    investigationname: investigationnameV,
    range: rangeV,
    EquipmentList: EquipmentListV,
  } = values;

  const removeItem = (id) => {
    setTableData((prevTableData) => {
      const updatedRows = prevTableData.rows.filter((item) => item.id !== id);
      return {
        ...prevTableData,
        rows: updatedRows,
      };
    });
  };

  const handleClose = () => {
    resetForm();
    setOpen(false);
  };
  const handleInputChange = (e, field) => {
    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [field]: "",
    }));
    values[field] = e.target.value;
  };
  const addData = () => {
    let localErrorMessages = {
      investigationname: "",
      range: "",
      uom: "",
    };
    if (!values.investigationname) {
      localErrorMessages.investigationname = "Investigation name is required.";
    }

    if (!values.range) {
      localErrorMessages.range = "Range is required.";
    }

    if (!values.uom) {
      localErrorMessages.uom = "UOM is required.";
    }

    if (
      localErrorMessages.investigationname ||
      localErrorMessages.range ||
      localErrorMessages.uom
    ) {
      setErrorMessages(localErrorMessages);
      swal("", "Investigation name, Range, UOM cannot be empty!", "warning");
      return;
    }

    const investigationObject = {
      name: values.investigationname,
      range: values.range,
      uom: values.uom,
    };
    const filter = tableData.rows.some(
      (a) => a.name === investigationObject.name
    );
    if (!filter) {
      setTableData((prevTableData) => {
        const newId = new Date().getTime(); // Generate a unique ID or use a better method
        const updatedRows = [
          ...prevTableData.rows,
          { ...investigationObject, id: newId },
        ];

        return {
          ...prevTableData,
          rows: updatedRows.map((item) => ({
            ...item,
            action: (
              <span>
                <FaRegTimesCircle
                  style={{
                    color: "#f44336",
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                  }}
                  onClick={() => removeItem(item.id)}
                />
              </span>
            ),
          })),
        };
      });
      values.investigationname = "";
      values.range = "";
      values.uom = "";
      setUomvalue(null);
    }
  };

  async function getData() {
    setLoading(true);
    await axios
      .get(`${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS`)
      .then((response) => {
        setUomList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    values.investigationList = tableData.rows;
  }, [tableData.rows]);
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Test Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label={
                  <span>
                    {name.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                }
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={testname.type}
                label={
                  <span>
                    {testname.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                }
                name={testname.name}
                value={testnameV}
                placeholder={testname.placeholder}
                error={errors.testname && touched.testname}
                success={testnameV.length > 0 && !errors.testname}
              />
            </Grid>
          </Grid>
          <MDTypography variant="h4" fontWeight="small" color="black" mt={1}>
            Investigation list
          </MDTypography>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={investigationname.type}
                label={
                  <span>
                    {investigationname.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                }
                name={investigationname.name}
                value={investigationnameV}
                placeholder={investigationname.placeholder}
                error={errors.investigationname && touched.investigationname}
                success={
                  investigationnameV.length > 0 && !errors.investigationname
                }
                onChange={(e) => handleInputChange(e, "investigationname")}
              />
              {errorMessages.investigationname && (
                <MDTypography
                  variant="caption"
                  color="error"
                  mt={0}
                  style={{
                    display: "block",
                    marginTop: "-5px",
                    fontWeight: "400"
                  }}
                >
                  {errorMessages.investigationname}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropssUOM}
                value={uomvalue}
                onChange={(event, newValue) => {
                  setUomvalue(newValue);
                  values.uom = newValue?.name;
                  setErrorMessages((prevMessages) => ({
                    ...prevMessages,
                    uom: "",
                  }));
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label={
                      <span>
                        {uom.label}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </span>
                    }
                    error={errors.uom && touched.uom}
                    success={uom.length > 0 && !errors.uom}
                  />
                )}
              />
              {errorMessages.uom && (
                <MDTypography
                  variant="caption"
                  color="error"
                  mt={1}
                  style={{
                    display: "block",
                    marginTop: "5px",
                    fontWeight: "400"
                  }}
                >
                  {errorMessages.uom}
                </MDTypography>
              )}
              <ErrorMessage
                name="uom"
                component="div"
                style={{ color: "red", fontSize: "12px" }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={range.type}
                label={
                  <span>
                    {range.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                }
                name={range.name}
                value={rangeV}
                placeholder={range.placeholder}
                error={errors.range && touched.range}
                success={rangeV.length > 0 && !errors.range}
                onChange={(e) => handleInputChange(e, "range")}
              />
              {errorMessages.range && (
                <MDTypography
                  variant="caption"
                  color="error"
                  mt={0}
                  style={{
                    display: "block",
                    marginTop: "-5px",
                    fontWeight: "400"
                  }}
                >
                  {errorMessages.range}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={6} sm={6}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={addData}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={12} mt={1}>
            {/* {tableData?.rows?.length === 0 && (
              <MDTypography variant="caption" color="error" mt={-3}>
                {errors.investigationList ||
                  "Investigation list cannot be empty."}
              </MDTypography>
            )} */}
            <DataTable
              table={tableData}
              entriesPerPage={false}
              canSearch={false}
              showTotalEntries={false}
              isSorted={false}
              noEndBorder
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

TestInfo.propTypes = {
  formData: PropTypes.object.isRequired,
};

export default TestInfo;
