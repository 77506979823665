import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";

import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";

import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import CircularProgress from "@mui/material/CircularProgress";
import { updateProjectpincode } from "../../../../components/Resuable/ProjectPincode/service/ProjectPincode";
import { APIs } from "Services/APIs";
import { micappCode } from "static/micappCode";
import axios from "../../../../axiosinstance";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

// import { updateDesignation } from "../service/Designation";

const validationSchema = Yup.object().shape({
  project: Yup.string().required("Project is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  subDistrict: Yup.string().required("Sub district is required"),
  pincode: Yup.string().required("Pincode is required"),
});

const PincodeAssociationEdit = (props) => {
  const dispatch = useDispatch();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    passData,
  } = props;
  // const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);
  const { department } = useSelector((state) => state.department);
  const [loadingfilter, setLoadingfilter] = useState(true);

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { statelist } = useSelector((state) => state.statelist);
  const [loading, setIsLoading] = useState(false);

  // const { district } = useSelector((state) => state.district);
  const [district, setDistric] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [create, setProjectList] = useState([]);

  const onSubmit = async (values) => {
    setIsLoading(true);
    const updatedUser = {
      ...selectedUser,
      pincode: values?.pincodeobj ? values?.pincodeobj : selectedUser?.pincode,
      projectcode: values?.projectobj
        ? values?.projectobj?.code
        : selectedUser?.projectcode,
      projectname: values?.projectobj
        ? values?.projectobj?.name
        : selectedUser?.projectname,
      modifiedby: auth?.profileid,
      modifiedbyname: auth?.name,
    };

    // navigate("/app/mic/pincode-association-list")
    const response = await dispatch(updateProjectpincode(updatedUser));

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been updated successfully!", {
        icon: "success",
      });
      passData();
    } else {
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      // handleClose()
    }
  };

  const initialValues = {
    // name: selectedUser?.firstname,

    project: selectedUser?.projectcode,
    state: selectedUser?.pincode.state?.code,
    district: selectedUser?.pincode?.district?.code,
    subDistrict: selectedUser?.pincode?.subdistrict?.code,
    pincode: selectedUser?.pincode?.village?.code,
  };

  async function getData() {
    try {
      let url = `${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}&createdby=${auth?.profileid}`;

      const response = await axios.get(url);

      // Update state with the fetched data
      setProjectList(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  async function getDistrictData(statecode) {
    const response = await dispatch(getDistrict(statecode));

    setDistric(response.payload.data.dtoList);
    setSubDistrict([]);
    setPincodeList([]);
  }

  async function getSubDistrictData(statecode, districtCode) {
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };

    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList);
    setPincodeList([]);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    setPincodeList(response.payload?.data?.dtoList);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoadingfilter(true);

      try {
        getData();
        dispatch(getState());
        // dispatch(getDepartment());
        const response = await dispatch(
          getDistrict(selectedUser?.pincode?.state?.code)
        );
        setDistric(response.payload.data.dtoList);

        const data = {
          statecode: selectedUser?.pincode?.state?.code,
          districtCode: selectedUser?.pincode?.district?.code,
        };

        const responseDistric = await dispatch(getSubDistrict(data));
        setSubDistrict(responseDistric.payload?.data?.dtoList);

        const datadistric = {
          statecode: selectedUser?.pincode?.state?.code,
          districtCode: selectedUser?.pincode?.district?.code,
          subDistrictcode: selectedUser?.pincode?.subdistrict?.code,
        };

        const responsepincode = await dispatch(getPincode(datadistric));
        setPincodeList(responsepincode.payload?.data?.dtoList);
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      } finally {
        setLoadingfilter(false); // Move setLoadingfilter inside the finally block
      }
    };

    fetchData();
  }, [selectedUser]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              {loadingfilter ? (
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.project && touched.project}
                      >
                        <InputLabel
                          htmlFor="project"
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          MIC Selection
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="state"
                          id="state"
                          value={values.project}
                          onChange={(e) => {
                            const project = e.target.value;
                            const projectObj =
                              create.find((obj) => obj.code === project) || {};

                            setFieldValue("projectobj", projectObj);
                            setFieldValue("project", project);
                          }}
                        >
                          <option value=""></option>
                          {create?.map((obj) => (
                            <option key={obj?.id} value={obj?.code}>
                              {obj?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.project && touched.project && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.project}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.state && touched.state}
                      >
                        <InputLabel
                          htmlFor="state"
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          State
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="state"
                          id="state"
                          value={values.state}
                          onChange={(e) => {
                            const state = e.target.value;

                            getDistrictData(e.target.value);
                            setFieldValue("district", "");
                            setFieldValue("subDistrict", "");
                            setFieldValue("pincode", "");
                            setFieldValue("state", state);
                          }}
                        >
                          <option value=""></option>
                          {statelist?.map((obj) => (
                            <option key={obj?.id} value={obj?.state?.code}>
                              {obj?.state?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.state && touched.state && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.district && touched.district}
                      >
                        <InputLabel
                          htmlFor="district"
                          shrink={Boolean(values.district)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="district"
                          id="district"
                          value={values.district}
                          onChange={(e) => {
                            const districtvalue = e.target.value;
                            setFieldValue("subDistrict", "");
                            setFieldValue("pincode", "");
                            const selectedDistrictobj =
                              district?.find(
                                (obj) => obj.district.code === e.target.value
                              ) || {};
                            getSubDistrictData(
                              selectedDistrictobj.state?.code,
                              selectedDistrictobj.district?.code
                            );
                            setFieldValue("district", districtvalue);
                          }}
                        >
                          <option value=""></option>
                          {district?.map((obj) => (
                            <option key={obj?.id} value={obj?.district?.code}>
                              {obj?.district?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.district && touched.district && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.district}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.subDistrict && touched.subDistrict}
                      >
                        <InputLabel
                          htmlFor="subDistrict"
                          shrink={Boolean(values.subDistrict)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Sub District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="subDistrict"
                          id="subDistrict"
                          value={values.subDistrict}
                          onChange={(e) => {
                            const subDistrictvalue = e.target.value;
                            setFieldValue("pincode", "");
                            const selectedPincodeobj =
                              subdistrict?.find(
                                (obj) =>
                                  obj.subdistrict?.code === e.target.value
                              ) || {};

                            getVillageData(
                              selectedPincodeobj.state?.code,
                              selectedPincodeobj.district?.code,
                              selectedPincodeobj.subdistrict?.code
                            );

                            setFieldValue("subDistrict", subDistrictvalue);
                          }}
                        >
                          <option value=""></option>
                          {subdistrict?.map((obj) => (
                            <option
                              key={obj?.id}
                              value={obj?.subdistrict?.code}
                            >
                              {obj?.subdistrict?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.subDistrict && touched.subDistrict && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.subDistrict}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {/* Department Dropdown */}
                    <FormControl
                      fullWidth
                      error={!!errors.pincode && touched.pincode}
                    >
                      <InputLabel
                        htmlFor="pincode"
                        shrink={Boolean(values.pincode)}
                        style={{
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                      >
                        Pincode
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="pincode"
                        id="pincode"
                        value={values.pincode}
                        onChange={(e) => {
                          const pincode = e.target.value;
                          const selectedPincodeObj =
                            pincodelist.find(
                              (obj) => obj.village.code === e.target.value
                            ) || {};

                          setFieldValue("pincodeobj", selectedPincodeObj);
                          setFieldValue("pincode", pincode);
                        }}
                      >
                        <option value=""></option>
                        {pincodelist?.map((obj) => (
                          <option key={obj?.id} value={obj?.village?.code}>
                            {obj?.village?.name}
                          </option>
                        ))}
                      </Select>
                      {errors.pincode && touched.pincode && (
                        <FormHelperText error style={{ marginLeft: "0px" }}>
                          {errors.pincode}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default PincodeAssociationEdit;
