import AnalyticsGraph from 'components/Analytics'
import React from 'react'

import { useParams } from 'react-router-dom';
import ProfileCard from 'components/profileCard';
import Analytics from './Analytics';
import TotalAgentView from 'components/TotalAgentView';
import OnboardedList from './OnboardedList';
import Transactions from './Transaction';


const MicNurse = () => {
  const { slug2 } = useParams();

  return (
    <div>
      {slug2 ==="analytics" && <Analytics />}
      {slug2 ==="total-nurse" && <OnboardedList />}
      {slug2 ==="transaction" && <Transactions />}
    </div>
  )
}

export default MicNurse
