import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { shortname, villagename, state,district,subdistrict, villageCode ,pincode},
} = checkout;

const validations = [
  Yup.object().shape({
    [shortname.name]: Yup.string().required(shortname.errorMsg),
    [pincode.name]: Yup.string().required(pincode.errorMsg),
    [villagename.name]: Yup.string().required(villagename.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [subdistrict.name]: Yup.string().required(subdistrict.errorMsg),
    [villageCode.name]: Yup.string().required(villageCode.errorMsg),
  }),
];

export default validations;
