import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import MDBox from "components/MDBox";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import axios from "axiosinstance";
import { Button } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import Card from "@mui/material/Card";
import profilePicture from "../../../assets/images/products/No-data-found.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MDTypography from "components/MDTypography";
import { Grid } from "@mui/material";
import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import MDInput from "components/MDInput";

export default function TestReportList() {
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getMMUDetails, getProjectDetails } = useSelector(state => state)
  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [color] = useState("#344767");
  const [searched, setSearched] = useState(null);
  const [getResponse, setResponse] = useState([]);
  async function get(id) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIs.bookingServiceApi}/get?mmu.code=${id}&type=labtest`
      );
      setData( response?.data?.data?.dtoList?.filter(item => item?.categorylist) || []);
      setResponse( response?.data?.data?.dtoList?.filter(item => item?.categorylist) || []);
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }
  }
  const handleButtonClick = (name, ordernum) => {
    navigate("/app/lab/test-report-View", {
      state: {
        testname: name,
        ordernum: ordernum,
      },
    });
  };

const requestSearch = (keyData) => {

      //setSearched(key);
      const searchdata = data.filter((key) => {
       

          if (key?.patient?.name.toLowerCase().includes(keyData.toLowerCase())) {
              return key;
          }

      });
      if (keyData === null || keyData.length === 0) {
          setData(getResponse);
      }
      else
          setData(searchdata);
  };


  useEffect(() => {
    if (getProjectDetails?.details?.mmucreation?.code) {
      get(getProjectDetails?.details?.mmucreation?.code);
    }
  }, [getProjectDetails?.details?.mmucreation?.code]);

  return (
    <MDBox pt={2}>
      <MDBox mt={8}>
        <Card>
        <MDBox pl={3} pr={3} lineHeight={1}>
              <Grid container spacing={3} mt={2}>
                <Grid item xs={12} sm={6}>
                  <MDTypography variant="h5" fontWeight="medium">
                   Test Report
                  </MDTypography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDBox width="12rem" ml="auto">
                                        <MDInput
                                            placeholder="Search..."
                                            value={searched}

                                            onChange={(e) => {
                                                requestSearch(e.target.value)
                                                setSearched(e.target.value)
                                            }}


                                        />
                                    </MDBox>
                  <></>
                </Grid>
              </Grid>
            </MDBox>
          <BarLoader
            color={color}
            loading={Loading}
            aria-label="Loading Spinner"
          />

          <div>
            {data?.length === 0 && !Loading ? (
              <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={3}
              >
                <img
                  src={profilePicture}
                  alt="Brooklyn Alice"
                  style={{
                    width: "100%",
                    maxWidth: "200px",
                    height: "auto",
                  }}
                />
              </MDBox>
            ) : (
              <>
                {data?.map((values) => {
                  const categoryCodes = values?.categorylist?.map(
                    (category) => category.parentdepcode
                  );
                  const filteredPricelistNames = values?.pricelist
                    ?.filter((price) => categoryCodes?.includes(price?.code))
                    ?.map((price) => price?.pricename);
                  return (
                    <Accordion key={values?.ordernumber}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          <div style={{ fontSize: "15px", color: "#7b809a" }}>
                            <div>
                              <text style={{ fontWeight: "bold" }}>Name :</text>
                              {" " + values?.patientobj?.name}
                            </div>

                            <div>
                              <text style={{ fontWeight: "bold" }}>
                                Patient ID :
                              </text>
                              {" " + values?.patientobj?.patientid}
                            </div>

                            <div>
                              <text style={{ fontWeight: "bold" }}>Age :</text>
                              {" " + values?.patientobj?.age}
                            </div>
                            <div>
                              <text style={{ fontWeight: "bold" }}>
                                Mobile No :
                              </text>
                              {" " + values?.patientobj?.mobileno}
                            </div>
                          </div>
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <DataTable
                          canSearch={false}
                          entriesPerPage={false}
                          showTotalEntries={false}
                          table={{
                            columns: [
                              { Header: "Test name", accessor: "name" },
                              {
                                Header: "Action",
                                accessor: "action",
                                Cell: ({ row }) => (
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <MDButton
                                      variant="contained"
                                      size="small"
                                      color="info"
                                      onClick={() =>
                                        handleButtonClick(
                                          row.original.name,
                                          values?.ordernumber
                                        )
                                      }
                                    >
                                      View Report
                                    </MDButton>
                                  </div>
                                ),
                              },
                            ],
                            rows: filteredPricelistNames.map((name) => ({
                              name,
                            })),
                          }}
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
              </>
            )}
          </div>
        </Card>
      </MDBox>
    </MDBox>
  );
}
