import { Grid } from "@mui/material";
import React from "react";
import "./navbar.css";

const Section1 = () => {
  return (
    <>
      <Grid item className="navbaritem1">
        Get your first doctor visit for just ₹399.
      </Grid>
    </>
  );
};

export default Section1;
