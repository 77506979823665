// UnderConstruction.js
import React from 'react';

import './Undercons.css'
import img from '../../assets/svg/bower-logo (1).svg'
import { Card, Grid } from '@mui/material';
import MDBox from 'components/MDBox';
const UnderConstruction = () => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minheight: '100vh',
      textAlign: 'center',
      backgroundColor: '#f0f0f0',
      padding: '20px',
    },
    heading: {
      fontSize: '2rem',
      marginBottom: '1rem',
      color: '#2c3e50',
      marginTop:'20px'
    },
    paragraph: {
      fontSize: '1rem',
      color: '#34495e',
    },
    image: {
      width: '150px',
      height: '150px',
      marginTop: '20px',
      animation: 'heartbeat 1.5s infinite',
    },
  };

  return (
    <MDBox>
    <Grid mt={2} sx={styles.container}>
       
           <img 
        style={styles.image}
        src={img}
        alt="Under Construction"
      />
      
      <h1 style={styles.heading}>Site Under Construction!</h1>
      <p style={styles.paragraph}>Our healthcare website is currently being upgraded. </p>
      <p style={styles.paragraph}>We apologize for any inconvenience and appreciate your patience.</p>
  
    </Grid>
    </MDBox>
  );
};

export default UnderConstruction;
