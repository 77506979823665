import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import axios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import { MoonLoader } from "react-spinners";
import { APIs } from "Services/APIs";

const ChangePassword = (props) => {
  const { openpopupPassword, setopenpopupPassword, title } = props;
  const [loading, setIsLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const validationSchema = Yup.object({
    oldpassword: Yup.string().required("Old password is required."),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    confirmpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  });

  const formik = useFormik({
    initialValues: {
      oldpassword: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      const passworddata = {
        newpassword: values.password,
        oldpassword: values.oldpassword,
        resourcecode: "HEALTHCARE",
        username: auth?.emailid,
      };

      await axios
        .post(
          `${APIs?.loginServiceApi}/setPassword`,
          passworddata
        )

        .then((response) => {
          swal(
            "Change Password Successful!",
            "Your password has been changed successfully.",
            "success"
          );
          handleClose();
          setIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
          swal(
            "Oops!",
            error?.response?.data?.aceErrors?.[0]?.errorMessage ||
              "Something wents wrong.",
            "warning"
          );
        });
    },
  });
  const handleClose = () => {
    setopenpopupPassword(false);
    formik.resetForm();
  };
  return (
    <Dialog
      open={openpopupPassword}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          <Grid item xs={12} lg={12}>
            <MDBox mt={1.625}>
              <MDBox mb={2} mt={1}>
                <MDInput
                  type="password"
                  label="Old Password"
                  variant="standard"
                  fullWidth
                  id="oldpassword"
                  name="oldpassword"
                  value={formik.values.oldpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.oldpassword &&
                    Boolean(formik.errors.oldpassword)
                  }
                  helperText={
                    formik.touched.oldpassword && formik.errors.oldpassword ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.oldpassword}
                      </p>
                    ) : null
                  }
                />
              </MDBox>
            </MDBox>
          </Grid>

          <Grid item xs={12} lg={12}>
            <MDBox mt={1.625}>
              <MDBox mb={2} mt={1}>
                <MDInput
                  type="password"
                  label="Password"
                  variant="standard"
                  fullWidth
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={
                    formik.touched.password && formik.errors.password ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.password}
                      </p>
                    ) : null
                  }
                />
              </MDBox>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={12}>
            <MDBox mt={1.625}>
              <MDBox mb={2} mt={1}>
                <MDInput
                  type="password"
                  label="Confirm Password"
                  variant="standard"
                  fullWidth
                  id="confirmpassword"
                  name="confirmpassword"
                  value={formik.values.confirmpassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmpassword &&
                    Boolean(formik.errors.confirmpassword)
                  }
                  helperText={
                    formik.touched.confirmpassword &&
                    formik.errors.confirmpassword ? (
                      <p
                        style={{
                          fontWeight: "400",
                          color: "red",
                          fontSize: "12px",
                        }}
                      >
                        {formik.errors.confirmpassword}
                      </p>
                    ) : null
                  }
                />
              </MDBox>
            </MDBox>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="gradient"
            color="info"
            size="small"
            onClick={handleClose}
          >
            Cancel
          </MDButton>
          <MDButton type="submit" size="small" variant="gradient" color="info">
            {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ChangePassword;
