
const form = {
  formId: "new-department-form",
  formField: {
    departmentName: {
      name: "departmentName",
      label: "Department Name",
      type: "text",
      errorMsg: "Department Name is required.",
    },
    departmentDescrption: {
      name: "departmentDescrption",
      label: "Department Description",
      type: "text",
      errorMsg: "Department Description is required.",
    },
   
    department: {
      name: "department",
      label: "Parent Department",
      type: "text",
      errorMsg: "Parent Department is required.",
    },
  
  },
};

export default form;

