
import checkout from "../schemas/form";
const {
  formField: {
    firstName,
    lastName,
   
    email,
    password,
    repeatPassword,
    experience,
    phonenumber,
    age,
    gender,
    description,
    departmentvalue,
    designationvalue,
    state,
    district,
    subDistrict,
    pincode
   
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [gender.name]: "",
  [description.name]: "",
 
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
  [state.name]: "",
  [district.name]: "",
  [subDistrict.name]: "",
  [pincode.name]: "",
  [experience.name]:"",
  [phonenumber.name]: "",
  [age.name]: "",
  [designationvalue.name]: "",
  [departmentvalue.name]: "",

};

export default initialValues;
