// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField/index";

import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

function FrequencyInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

 
  const { formField, values, errors, touched } = formData;

  const {frequencyDescription,countperday} = formField;
  var {
    frequencyDescription: frequencyDescriptionV,
    countperday:countperdayV
  
   
    
  } = values;



  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Create Frequency </MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
           <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
            <FormField
                type={frequencyDescription.type}
                label=<span>{frequencyDescription.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={frequencyDescription.name}
                value={frequencyDescriptionV}
                placeholder={frequencyDescription.placeholder}
                error={errors.frequencyDescription && touched.frequencyDescription}
                success={frequencyDescriptionV.length > 0 && !errors.frequencyDescription}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={countperday.type}
                label=<span>{countperday.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={countperday.name}
                value={countperdayV}
                placeholder={countperday.placeholder}
                error={errors.countperday && touched.countperday}
                success={countperdayV.length > 0 && !errors.countperday}
              />
             
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for FrequencyInfo
FrequencyInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default FrequencyInfo;




