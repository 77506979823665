// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";

function PatientInfo({ formData }) {
 
 
  const { role, loading } = useSelector((state) => state.role);
 

  const [color] = useState("#344767");

 

  const { setFieldValue, setFieldError } = useFormikContext();

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
   
    phonenumber,
   
    email,
    age,
    
  } = formField;
  var {
    firstName: firstNameV,
   

    lastName: lastNameV,
    email: emailV,
  
  
    phonenumber: phonenumberV,
   
   
    age: ageV,
  } = values;

 

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
        
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                // error={errors.lastName && touched.lastName}
                // success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.gender && touched.gender}>
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={(e) => {
                    const gender = e.target.value;
                    

                    setFieldValue("gender", gender);
                  }}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Select>
                {errors.gender && touched.gender && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={age.type}
                label=<span>{age.label} <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
                InputLabelProps={{
                style: { paddingBottom: "2px" }}}
                onKeyDown={(e) => {
                  if (!/[0-9\b\t]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
          </Grid>

         

        
         
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={phonenumber.type}
                label=<span>{phonenumber.label} <sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
             
                name={phonenumber.name}
                value={phonenumberV}
                placeholder={phonenumber.placeholder}
                error={errors.phonenumber && touched.phonenumber}
                success={phonenumberV.length > 0 && !errors.phonenumber}
                InputLabelProps={{
                style: { paddingBottom: "2px" }}}
                onKeyDown={(e) => {
                  if (!/[0-9\b\t]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label=<span>
                  {email.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>
          </Grid>
        
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for PatientInfo
PatientInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PatientInfo;
