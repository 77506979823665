import React, { useState } from "react";
import "./signin.css";
// import google from "../../findDoctor/assets/images/Google.svg";
// import facebook from "../../findDoctor/assets/images/Facebook2.svg";
// import twitter from "../../findDoctor/assets/images/twitter.svg";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Grid} from "@mui/material";
import { MoonLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { logIn } from "redux/Actions/authActions";

const Signin = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is Required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is Required"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setIsLoading(true);
    const getData = {
      username: values.email,
      password: values.password,
      resourcecode: "HEALTHCARE",
    };

    await dispatch(logIn(getData));

    setIsLoading(false);
    setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <Form>
        <div className="input-block">
          <label htmlFor="email">Email:</label>
          <Field type="text" id="email" name="email" />
          {
            <p
              style={{
                fontWeight: "400",
                color: "red",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <ErrorMessage name="email" component="div" />
            </p>
          }
        </div>
        <div className="input-block">
          <label htmlFor="password">Password:</label>
          <Field type="password" id="password" name="password" />

          {
            <p
              style={{
                fontWeight: "400",
                color: "red",
                fontSize: "12px",
                textAlign: "left",
              }}
            >
              <ErrorMessage name="password" component="div" />
            </p>
          }
          <Link
            to={"/app/home/forgot-password"}
            className="forgot-password"
          >
            Forgot Password?
          </Link>
        </div>
        <Grid mt={5} mb={5}>
        <button type="submit"  className="btn-login" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#f2fefa" size={16} /> : "Log In"}
        </button>
        </Grid>
        {/* <div className="loginwith">Or login with</div> */}
        {/* <div className="logoimagecontainer">
          <a href="https://www.google.com/">
            <img className="loginimageitem" src={google} alt="Logo" />
          </a>
          <a href="https://www.google.com/">
            <img className="loginimageitem" src={facebook} alt="Logo" />
          </a>
          <a href="https://www.google.com/">
            <img className="loginimageitem" src={twitter} alt="Logo" />
          </a>

        
        </div> */}
      </Form>
    </Formik>
  );
};
export default Signin;
