import { Grid } from "@mui/material";
import React from "react";
import "./whyConsultOnline.css";
import Doctor2 from "../../../../../assets/images/doctor2.png";
import Rupee1 from "../../../../../assets/images/rupee1.png"
import AccessControl from "../../../../../assets/images/accesscontrol.png"
import HealthInsurance from "../../../../../assets/images/healthinsurance1.png"
import Success from "../../../../../assets/images/success1.png"
import SecureData from "../../../../../assets/images/securedata.png"
import WhyConsultOnlineInfo from "./whyConsultOnlineInfo";

const WhyConsultOnline = () => {
  const data = [
    {
      image: Doctor2,
      heading: "Online doctor",
      text: "Virtual healthcare experts offering remote consultations and medical advice through online platforms.",
    },
    {
      image: Rupee1,
      heading: "Affordability",
      text: "Affordability refers to the accessibility and feasibility of acquiring goods, services, or resources without causing financial strain or hardship",
    },
    {
      image: AccessControl,
      heading: "Data Privacy",
      text: "Data privacy involves safeguarding individuals' personal information and ensuring responsible and secure handling of data to protect against unauthorized access or misuse.",
    },
    {
      image: HealthInsurance,
      heading: "Health Security",
      text: "Health security encompasses measures and strategies aimed at safeguarding and protecting public health from threats, including infectious diseases, bioterrorism, and other health emergencies.",
    },
    {
      image: Success,
      heading: "Convenience",
      text: "Health convenience is the seamless integration of health-conscious choices into daily life, promoting well-being through accessible and time-efficient solutions for nutrition, fitness, and self-care.",
    },
    {
      image: SecureData,
      heading: "Accessibility",
      text: "Accessibility is the design and implementation of products, services, or environments to ensure they can be accessed and used by individuals with diverse abilities, including those with disabilities.",
    },
  ];
  return (
    <div className="whyconsultonlinecontainer">
      <Grid container xs={11} ml={7} mt={5} className="whyconsultonlinecontainer">
        <Grid item className="whyconsultonlineitem">
          Why consult online on Medharva
        </Grid>
        <Grid container mt={7}>
          {data.map((item, index) => (
            <Grid item key={index} xs={6}>
            <WhyConsultOnlineInfo key={index} image={item.image} heading={item.heading} text={item.text} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default WhyConsultOnline;
