import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{categoryName, categoryDescrption,categoryType,parentcategory,categoryvalue},
} = checkout;

const validations = [
  Yup.object().shape({
    [categoryName.name]: Yup.string().required(categoryName.errorMsg),
    // [categoryDescrption.name]: Yup.string().required(categoryDescrption.errorMsg),
    [categoryType.name]: Yup.string().required(categoryType.errorMsg),
    // [categoryvalue.name]: Yup.string().required(categoryvalue.errorMsg),
    // [parentcategory.name]: Yup.string().required(parentcategory.errorMsg),
  }),
];

export default validations
