// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
// import AddBoxIcon from '@mui/icons-material/AddBox';
import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import { createProject, getProject } from "components/Resuable/Create Project/service/Projectcreate";
import MDButton from "components/MDButton";
import DataTable from "components/Tables/DataTable";
import { APIs } from 'Services/APIs';
import { micappCode } from "static/micappCode";
import axios from "../../../../../axiosinstance"

// import { Link } from "react-router-dom";
// import CreateMicpopup from "../../createMic/CreateMicpopup";

function ProjectPincodeInfo({ formData, data, addDataHandler }) {
  const dispatch = useDispatch();
 
 
 
  const { statelist,loading } = useSelector((state) => state.statelist);
  // const { district } = useSelector((state) => state.district);
  const[district,setDistric] =useState([])
  const[subdistrict,setSubDistrict] =useState([])
  const [pincodelist, setPincodeList] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  
  const [create, setProjectList] = useState([]);
 const [color] = useState("#344767");
  // const [openpopup, setopenpopup] = useState(false);
  // const [openpopupProjectCreate, setopenpopupProjectCreate] = useState(false);

  const { setFieldValue, setFieldError } = useFormikContext();

  const { formField, values, errors, touched } = formData;

  const { firstName } = formField;
  var { firstName: firstNameV } = values;

async function getDistrictData(statecode) {
const response = await dispatch(getDistrict(statecode));

setDistric(response.payload.data.dtoList)
  }

  
   async function getSubDistrictData(statecode, districtCode) {
   const data={
      statecode:statecode,
      districtCode:districtCode
    }

   
    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList)
  }


  async function getVillageData(statecode, districtcode, subDistrictcode) {
    const data={
      statecode:statecode,
      districtCode:districtcode,
      subDistrictcode:subDistrictcode
    }
    const response = await dispatch(getPincode(data));
    setPincodeList(response.payload?.data?.dtoList)
}

async function getData() {
  try {

    let url = `${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}&createdby=${auth?.profileid}`;
   

    const response = await axios.get(url);
  

    // Update state with the fetched data
    setProjectList(response?.data?.data?.dtoList);
  
  } catch (error) {
    console.log("error", error);
  }
}



  useEffect(() => {
     getData()
    // dispatch(getProject());
    // dispatch(createProject());
    dispatch(getState());
   
  }, []);

  return (
    <MDBox>
      {/* <MDBox lineHeight={0}>
        <MDTypography variant="h5">MIC</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox> */}
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.project && touched.project}
              >
                <InputLabel
                  htmlFor="project"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  MIC Selection
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="state"
                  id="state"
                  value={values.project}
                  onChange={(e) => {
                    const project = e.target.value;
                    const projectObj =
                      create.find((obj) => obj.code === project) || {};

                  console.log("projectObj",projectObj)
                    setFieldValue("projectobj", projectObj);
                    setFieldValue("project", project);
                  }}
                >
                  <option value=""></option>
                  {create?.map((obj) => (
                    <option key={obj?.id} value={obj?.code}>
                      {obj?.name}
                    </option>
                  ))}
                </Select>
                {errors.project && touched.project && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.project}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} sm={1}>

<span>
  <Link to={{}}>
    <AddBoxIcon
      fontSize="large"
      onClick={() => {
        setopenpopupProjectCreate(true);
      }}
    />
  </Link>
</span>


</Grid> */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.state && touched.state}>
                <InputLabel
                  htmlFor="state"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  State
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="state"
                  id="state"
                  value={values.state}
                  onChange={(e) => {
                    const state = e.target.value;

                    getDistrictData(e.target.value);
                    setFieldValue("state", state);
                  }}
                >
                  <option value=""></option>
                  {statelist?.map((obj) => (
                    <option key={obj?.id} value={obj?.state?.code}>
                      {obj?.state?.name}
                    </option>
                  ))}
                </Select>
                {errors.state && touched.state && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.state}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.district && touched.district}
              >
                <InputLabel
                  htmlFor="district"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  District
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="district"
                  id="district"
                  value={values.district}
                  onChange={(e) => {
                    const districtvalue = e.target.value;
                    const selectedDistrictobj =
                      district?.find((obj) => obj.district.code === e.target.value) ||
                      {};
                   getSubDistrictData(selectedDistrictobj.state?.code,selectedDistrictobj.district?.code
                  );
                    setFieldValue("district", districtvalue);
                  }}
                >
                  <option value=""></option>
                  {district?.map((obj) => (
                    <option key={obj?.id} value={obj?.district?.code}>
                      {obj?.district?.name}
                    </option>
                  ))}
                </Select>
                {errors.district && touched.district && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.district}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.subDistrict && touched.subDistrict}
              >
                <InputLabel
                  htmlFor="subDistrict"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Sub District
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="subDistrict"
                  id="subDistrict"
                  value={values.subDistrict}
                  onChange={(e) => {
                    const subDistrictvalue = e.target.value;
                    const selectedPincodeobj =
                    subdistrict?.find((obj) => obj.subdistrict?.code === e.target.value) ||
                      {};
                   
                    getVillageData(
                    selectedPincodeobj.state?.code,
                    selectedPincodeobj.district?.code,
                    selectedPincodeobj.subdistrict?.code
                  );
                    
                    setFieldValue("subDistrict", subDistrictvalue);
                  }}
                >
                  <option value=""></option>
                  {subdistrict?.map((obj) => (
                    <option key={obj?.id} value={obj?.subdistrict?.code}>
                      {obj?.subdistrict?.name}
                    </option>
                  ))}
                </Select>
                {errors.subDistrict && touched.subDistrict && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.subDistrict}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={0.1}>
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.pincode && touched.pincode}
              >
                <InputLabel
                  htmlFor="pincode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Pincode
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="pincode"
                  id="pincode"
                  value={values.pincode}
                  onChange={(e) => {
                    const pincode = e.target.value;
                    const selectedPincodeObj =
                      pincodelist.find((obj) => obj.village.code === e.target.value) || {};

                   
                      setFieldValue("pincodeobj", selectedPincodeObj);
                    setFieldValue("pincode", pincode);
                  }}
                >
                  <option value=""></option>
                  {pincodelist?.map((obj) => (
                    <option key={obj?.id} value={obj?.village?.code}>
                      {obj?.village?.name}
                    </option>
                  ))}
                </Select>
                {errors.pincode && touched.pincode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.pincode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          {/* <MDButton variant="gradient" color="info" size="small" type="button" onClick={() => handleSubmit(values)}>
            Add +
          </MDButton>
          <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "15px",
              marginBottom: "10px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
          >
            <DataTable showTotalEntries={false} entriesPerPage={false} table={{ columns, rows: data }} />
          </Grid> */}
        </MDBox>
      )}
{/*       
       <CreateMicpopup
        openpopupProjectCreate={openpopupProjectCreate}
        setopenpopupProjectCreate={setopenpopupProjectCreate}
      />  */}
    </MDBox>
  );
}

// typechecking props for ProjectPincodeInfo
ProjectPincodeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ProjectPincodeInfo;
