

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";
 
// @mui material components
import Grid from "@mui/material/Grid";
 
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
 
// vitals page components
import FormField from "../FormField";
 
import { useEffect, useState } from "react";
import FileUpload from "react-material-file-upload";
import { BarLoader } from "react-spinners";
import { useSelector } from "react-redux";
import { UploadDocumnet } from "Services/UploadDocumnet";
import swal from "sweetalert";
 
function ResourceInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [files, setFiles] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
 
  const { formField, values, errors, touched } = formData;
  const [uploadresponse,setUploadresponse] = useState("");
  const { resourcename, resourceDescrption } = formField;
  var { resourcename: resourcenameV, resourceDescrption: resourceDescrptionV } =
    values;
    
  function onfileChange(e) {
    setUploadresponse("")
    setFiles(e);
    finalupload(e);
  
  }
 
 
async function finalupload(e) {
  // Check if 'e' is not empty and has at least one element
  if (e && e.length > 0) {
    const formData = new FormData();
 
    // Update the formData object
    formData.append("file", e[0]);
    const request = {
      resourcecode: "MEDHARVA",
      username: auth.name,
      code: auth.rolecode,
    };
    const myJSON = JSON.stringify(request);
    formData.append("body", myJSON);
 
    const data = await UploadDocumnet(formData);
    values.document = data.docid;
    setUploadresponse(data.docid)
  } else {
  
    // swal("oops!", "somethings wents wrong!", "error")
    setUploadresponse("")
 
  }
}
 
 
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Resource Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
 
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
 
      {!loading && (
        <MDBox mt={1.625}>
        
          <FileUpload
         
            buttonText={<span style={{ color: "white" }}>Upload</span>}
            value={files}
            onChange={onfileChange}
            style={{ marginBottom: '10px', borderWidth: '1px' }}
          />
          
          {/* <Grid container spacing={3} mt={2}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={resourcename.type}
                label=<span>
                  {resourcename.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={resourcename.name}
                value={resourcenameV}
                placeholder={resourcename.placeholder}
                error={errors.resourcename && touched.resourcename}
                success={resourcenameV.length > 0 && !errors.resourcename}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormField
                type={resourceDescrption.type}
                multiline
                rows={2}
                label=<span>
                  {resourceDescrption.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                  </sup>
                </span>
                name={resourceDescrption.name}
                value={resourceDescrptionV}
                placeholder={resourceDescrption.placeholder}
                error={errors.resourceDescrption && touched.resourceDescrption}
                success={
                  resourceDescrptionV.length > 0 && !errors.resourceDescrption
                }
              />
            </Grid>
          </Grid> */}


          {uploadresponse && (
  <Grid container spacing={3} mt={2}>
    <Grid item xs={12} sm={6}>
      <FormField
        type={resourcename.type}
        label={
          <span>
            {resourcename.label}
            <sup
              style={{
                color: "red",
                fontSize: "small",
                fontWeight: "bolder",
                position: "relative",
                top: "2px",
              }}
            >
              {" "}
              *
            </sup>
          </span>
        }
        name={resourcename.name}
        value={resourcenameV}
        placeholder={resourcename.placeholder}
        error={errors.resourcename && touched.resourcename}
        success={resourcenameV.length > 0 && !errors.resourcename}
      />
    </Grid>
    <Grid item xs={12} sm={12}>
      <FormField
        type={resourceDescrption.type}
        multiline
        rows={2}
        label={
          <span>
            {resourceDescrption.label}
            <sup
              style={{
                color: "red",
                fontSize: "small",
                fontWeight: "bolder",
                position: "relative",
                top: "2px",
              }}
            >
              {" "}
            </sup>
          </span>
        }
        name={resourceDescrption.name}
        value={resourceDescrptionV}
        placeholder={resourceDescrption.placeholder}
        error={errors.resourceDescrption && touched.resourceDescrption}
        success={
          resourceDescrptionV.length > 0 && !errors.resourceDescrption
        }
      />
    </Grid>
  </Grid>
)}
        
        </MDBox>
      )}
    </MDBox>
  );
}
 
// typechecking props for ResourceInfo
ResourceInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};
 
export default ResourceInfo;