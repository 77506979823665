// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useFormikContext } from "formik";

// NewUser page components

import FormField from "../../components/FormField";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
function DoctorInfo({ formData }) {
  const dispatch = useDispatch();
  const { department } = useSelector((state) => state.department);

  const { designation } = useSelector((state) => state.designation);
  const [loading, setLoading] = useState(false);
  const [genderValue, setGenderValue] = useState(null);
  const [genderList, setGenderList] = useState("");
  const [color] = useState("#344767");
  const [isMobileView, setIsMobileView] = useState(false);

  const [designationList, setDesignationList] = useState([]);
  const { setFieldValue, setFieldError } = useFormikContext();

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 575);
    }
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    age,
    gender,
    email,
    phonenumber,
    description,
    experience,
    password,
    repeatPassword,
  } = formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phonenumber: phonenumberV,
    username: usernameV,

    description: descriptionV,
    age: ageV,
    experience: experienceV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  function getDesignationData(code) {
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }
  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getDesignation());
  }, []);

  return (
    <MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                //success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                // type={age.type}
                label=<span>
                  {age.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.gender && touched.gender}>
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={(e) => {
                    const gender = e.target.value;

                    setFieldValue("gender", gender);
                  }}
                >
                  <option value=""></option>

                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
                {errors.gender && touched.gender && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: "400" }}
                  >
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label=<span>
                  {email.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={phonenumber.type}
                label=<span>
                  {phonenumber.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={phonenumber.name}
                value={phonenumberV}
                placeholder={phonenumber.placeholder}
                error={errors.phonenumber && touched.phonenumber}
                success={phonenumberV.length > 0 && !errors.phonenumber}
                onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} mb={1.5}>
              <FormControl
                fullWidth
                error={!!errors.departmentvalue && touched.departmentvalue}
              >
                <InputLabel
                  htmlFor="departmentvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Department
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="departmentvalue"
                  id="departmentvalue"
                  value={values.departmentvalue}
                  onChange={(e) => {
                    const departmentvalue = e.target.value;
                    const selectedDepartmentObj =
                      department.find((obj) => obj.code === departmentvalue) ||
                      {};

                    getDesignationData(e.target.value);
                    setFieldValue("department", selectedDepartmentObj);

                    setFieldValue("departmentvalue", departmentvalue);
                  }}
                >
                  <option value=""></option>
                  {department.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.departmentvalue && touched.departmentvalue && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: "400" }}
                  >
                    {errors.departmentvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} mb={1.5}>
              <FormControl
                fullWidth
                error={!!errors.designationvalue && touched.designationvalue}
              >
                <InputLabel
                  htmlFor="designationvalue"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Designation
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="designationvalue"
                  id="designationvalue"
                  value={values.designationvalue}
                  onChange={(e) => {
                    const designationvalue = e.target.value;
                    //values.designation = newValue;
                    const designationobj =
                      designation.find(
                        (obj) => obj.code === designationvalue
                      ) || {};

                    setFieldValue("designation", designationobj);
                    setFieldValue("designationvalue", designationvalue);
                  }}
                >
                  <option value=""></option>
                  {designationList.map((obj) => (
                    <option key={obj.id} value={obj.code}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.designationvalue && touched.designationvalue && (
                  <FormHelperText
                    error
                    style={{ marginLeft: "0px", fontWeight: "400" }}
                  >
                    {errors.designationvalue}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={experience.type}
                label=<span>
                  {experience.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={experience.name}
                value={experienceV}
                placeholder={experience.placeholder}
                error={errors.experience && touched.experience}
                success={experienceV.length > 0 && !errors.experience}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                multiline
                rows={1}
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label=<span>
                  {password.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label=<span>
                  {repeatPassword.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DoctorInfo
DoctorInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DoctorInfo;
