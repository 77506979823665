// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

function ClinicInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const {  clinicname,clinicdescription } = formField;
  var { clinicname: clinicnameV,clinicdescription:clinicdescriptionV} = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Clinic Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
           
            <Grid item xs={12} sm={6}>
              <FormField
                type={clinicname.type}
                label=<span>{clinicname.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={clinicname.name}
                value={clinicnameV}
                placeholder={clinicname.placeholder}
                error={errors.clinicname && touched.clinicname}
                success={clinicnameV.length > 0 && !errors.clinicname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={clinicdescription.type}
                label=<span>{clinicdescription.label}</span>
                name={clinicdescription.name}
                value={clinicdescriptionV}
                placeholder={clinicdescription.placeholder}
                // error={errors.machineDescription && touched.machineDescription}
                // success={machineDescriptionV.length > 0 && !errors.machineDescription}
              />
            </Grid>
          </Grid>


          <Grid container spacing={3}>


          {/* <Grid item xs={12} sm={6}>
              <FormField
                type={clinicdescription.type}
                label=<span>{clinicdescription.label}</span>
                name={clinicdescription.name}
                value={clinicdescriptionV}
                placeholder={clinicdescription.placeholder}
                // error={errors.machineDescription && touched.machineDescription}
                // success={machineDescriptionV.length > 0 && !errors.machineDescription}
              />
            </Grid>
            */}
           
          </Grid>
          <Grid container spacing={3}></Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for MachineInfo
ClinicInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default ClinicInfo;
