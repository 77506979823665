import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ROLE_CODE } from "./codes";
import { useSelector } from "react-redux";

function PrivatePharmacy() {

    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);


    return auth?.rolecode === ROLE_CODE.labcode ||auth?.rolecode === ROLE_CODE.childlabcode ?<Outlet />:<Navigate to="/app/login"  />
    
}

export default PrivatePharmacy;