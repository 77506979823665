import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
   mmu,
    lab,
   
   
   
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [mmu.name]: Yup.string().required(mmu.errorMsg),
   
     
   
     [lab.name]: Yup.string().required(lab.errorMsg),

      
    
    
  }),
  
];

export default validations;
