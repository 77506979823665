import checkout from "./form";

const {
  formField: { itemtype, sapcode, uom,name,packagetype },
} = checkout;

const initialValues = {
  [packagetype.name]: "",
  [name.name]:"",
  [itemtype.name]: "",
  [sapcode.name]: "",
  [uom.name]: "",
};

export default initialValues;
