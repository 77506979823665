
import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { useSelector } from "react-redux";
import { ROLE_CODE } from "privateComponents/codes";
import PatientEdit from "./PatientEdit/PatientEdit";
import { APIs } from "Services/APIs";
import { Tooltip } from "@mui/material";
function PatientList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [selectedPatient, setSelectedPatient] = useState({});
  const [openpopup, setopenpopup] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [item, setItem] = useState({});

  const [open, setOpen] = React.useState(false);
  const iconStyle = { color: "blue", marginRight: "9px", cursor: "pointer", fontSize: "1.5rem" };
  const iconStyledelte = { color: "red", marginRight: "9px", cursor: "pointer", fontSize: "1.5rem" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function navigateSearchDoctor(item) {

    delete item.action
    navigate(`/app/nurse/doctor-booking`, {
      state: {
        patientdata: item,

      },
    });

  }
  const onEdit = (item) => {
    setSelectedPatient(item)
    setopenpopup(true);

  };

  async function onDelete(item) {

    item.modifieddate = new Date()
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Patient list.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then((willDelete) => {
        console.log(willDelete)
        if (willDelete) {

          axios
            .put(
              `${APIs.loginServiceApi}/delete`,
              item
            )
            .then((response) => {
              console.log(response);
              getPatient(ROLE_CODE.patientcode);
            })
          swal("Your role record has been deleted!", {
            icon: "success",

          });
        } else {
          swal("Your list record is safe!");
        }
      });
  }

  function getPatient(code) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },
        { Header: "age", accessor: "age" },
        { Header: "gender", accessor: "sex" },
        { Header: "email", accessor: "emailid" },
        { Header: "mobile number", accessor: "mobileno" },
        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(
        `${APIs.loginServiceApi}/get?createdby=${auth.profileid}&rolecode=${code}&isactive=ACTIVE`
      )
      .then((response) => {
        response?.data?.data?.dtoList.map((item) => {
          item.action = (
            <span>
              {/* <Link to={{}}> */}
              <Tooltip title="Book Patient">
              <ArrowCircleRightIcon
                fontSize="medium"
                style={iconStyle}

                onClick={() => {
                  navigateSearchDoctor(item);
                }}
              />
              </Tooltip> 
              
              <Tooltip title="Edit">
               <Link to={{}}>
                <FaRegEdit
                   style={iconStyle}
                  onClick={() => {
                    onEdit(item);
                  }}
                />
</Link> 
</Tooltip>
              <Tooltip title="Delete">
                <Link to={{}}>
                  <MdDelete
                    style={iconStyledelte}
                    onClick={() => {
                      onDelete(item);
                    }}
                  />
                </Link>
              </Tooltip>

            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getPatient(ROLE_CODE.patientcode);
  }, []);
  return (
    <DashboardLayout>
      {/* <UpaarNavbar /> */}
      <MDBox pt={3} mt={5}>
        <MDBox mt={14}>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Patient List
                  </MDTypography>
                </span>
                <Tooltip title="Add Patient">
                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/app/nurse/patient-form"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
                </Tooltip>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      {openpopup && (
        <PatientEdit
        openpopup={openpopup}
        setopenpopup={setopenpopup}
        title={"Patient Edit"}
        selectePatient={selectedPatient}
       getPatient={getPatient}
      />)}
    </DashboardLayout>
  );
}


export default PatientList