import { Landing } from "app/videoCall/Landing";
import React, { useState } from "react";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { aggregationListAction } from "redux/Actions/aggregationListAction";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import axios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import PrescriptionForm from "components/prescriptionForm";
import { swalMessage } from "static/swalMessage/swalMessage";

const VideoConsultation = () => {
  const location = useLocation();
  const [aggregiatepatient, setAggregiatepatient] = useState(
    location?.state?.userdetails
  );
  const [selecteddisease, setSelectedDisease] = useState([]);
  const [chiefcomplaint, setChiefcomplaint] = useState("");
  const [remark, setRemark] = useState("");
  const [orderNumber, setOrderNumber] = useState(location?.state?.roomId);
  const [frequencydata, setFrequencydata] = useState(null);
  const [data, setData] = useState([]);
  const [selectedTestlist, setSelectedTestlist] = useState([]);
  const [showAppointments, setShowAppointments] = useState(false);
  const [date, setDate] = useState("");
  const [appointmentData, setAppointmentData] = useState();
  const [slot, setSlot] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [finalLabtest, setFinalLabtest] = useState([]);
  const { getUserCategory, getAggregationList } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAssignFollowUp = (event) => {
    setShowAppointments(event.target.checked);
  };
  const [idCounter, setIdCounter] = useState(0);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [startDate, setStartDate] = useState();
  const [visitid, setVisitid] = useState(location?.state?.userdetails?.visit?.visitid);

  const removeData = (id) => {
    const newData = data.filter((item) => item?.uid !== id);
    setData(newData);
  };

  const updateData = (val) => {
    setDate(val);
  };

  const handleAppointment = (dataFromGrandchild, value) => {
    setSlot(value);
    setAppointmentData(dataFromGrandchild);
  };

  const completePrescrptionObject = {
    additionalnotes: remark,
    age: aggregiatepatient?.patient?.age,
    chiefcomplaint: chiefcomplaint,
    diagnosistext: remark,
    createdby: auth.profileid,
    diagnosiscategory: "",
    doctor: auth?.name,
    diseaselist: selecteddisease,
    history: "",
    id: null,
    labtestlist: finalLabtest,
    medicinelist: data,
    mmucode: auth?.profileid,
    name: aggregiatepatient?.patient?.name,
    nextfollowupdate: new Date(startDate),
    parentpresid: "",
    patientid: aggregiatepatient?.patient?.patientid,
    prestype: "",
    reasontoreferal: "",
    recstatus: "PRESCRIBED",
    refereddoctor: "",
    status: "ACTIVE",
    syncstatus: "SYNCED",

    visitData: aggregiatepatient?.visit,
    visitid: aggregiatepatient?.visit?.visitid,
  };

  const columns = [
    { Header: "medicine", accessor: "dosage" },
    { Header: "Uom", accessor: "uom" },
    { Header: "frequency", accessor: "frequency" },
    { Header: "dose", accessor: "dose" },
    { Header: "duration", accessor: "duration" },
    { Header: "quantity", accessor: "quantity" },
    {
      Header: "Action",
      accessor: "uid",
      Cell: ({ row }) => (
        // <button onClick={() => removeData(row.values.id)}>Remove</button>
        <span>
          <Link to={{}}>
            <FaRegTimesCircle
              style={{
                color: "#f44336",
                width: "25px",
                height: "25px",
                cursor: "pointer",
              }}
              onClick={() => removeData(row?.values?.uid)}
            />
          </Link>
        </span>
      ),
    },
  ];

  const handlePrescription = () => {
    if(orderNumber !== null){
      swalMessage('error', "Please hang up the call before submitting the prescription.");
      return
    }
    if (showAppointments) {
      createVisit();
    } else {
      handleSubmitPrescription();
    }
  };

  async function createPrescription(object) {
    setIsLoading(true);

    if (data.length === 0) {
      swal("Oops", "Please add data in table first!", "error");
      setIsLoading(false);
    } else {
      await axios
        .post(`${APIs.aggregationServiceApi}/createPrescription`, object)
        .then((response) => {
          bookingUpdatestatuschange(location?.state?.userdetails?.visit?.visitid, response?.data?.data?.dto?.presid);
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  }

  async function bookingUpdatestatuschange(visitid, presId) {
    console.log("bookingUpdatestatuschange function is called 146")
    try {
      setIsLoading(true);

      const response = await axios.put(
        `${APIs.bookingServiceApi}/updateV2?visitid=${visitid}`,
        {
          recstatus: "PRESCRIBED",
          presid: presId,
        }
      );

      swal("Success!", "Prescribed successfully.");
      navigate(-1);
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  }

  const createVisit = async () => {
    setIsLoading(true);
    setOrderNumber("");
    let body = {
      createdby: auth.profileid,
      createdbyname: auth.name,
      doctorid: auth.profileid,
      mmucode: auth.profileid,
      visitcategory: "ONLINE",
      paymenttype: "PAID",
      patientid: aggregiatepatient.patient.patientid,
      profileid: aggregiatepatient.patient.profileid,
      recstatus: "SCHEDULED",
      status: "ACTIVE",
      visittype: "FOLLOW_UP",
      doctorname: auth.doctorname,
      address: "",
      parentvisitid: aggregiatepatient.visit.visitid,
      childvisitid: "",
    };

    await axios
      .post(`${APIs.visitServiceApi}/create`, body)
      .then((response) => {
        handleFollowUp(response.data.data.dto);
        // updateVisit( response.data.data.dto.visitid)
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  const handleFollowUp = (visit) => {
    setIsLoading(true);
    const getData = {
      approvedby: "",
      approveddate: "",
      approver: "string",
      approvertype: "string",
      classname: "string",
      pricelist: null,
      code: "string",
      createdby: auth.profileid,
      createddate: "",
      doctor: auth,
      profileid: auth.profileid,
      documentofproof: "string",
      id: null,
      latlong: "string",
      location: "",
      modifiedby: "string",
      modifieddate: "",
      type: "booking",
      ordernumber: "string",
      paymentmethod: "Pay on Counter",
      patient: aggregiatepatient.patient,
      slot: slot,
      recstatus: "SCHEDULED",
      status: "ACTIVE",
      syncstatus: "SYNCED",
      visit: visit,
      visitid: visit?.visitid,
    };

    axios
      .post(`${APIs.bookingServiceApi}/create`, getData)
      .then((response) => {
        updateVisit(visit?.visitid);
        const roomid = uuidv4().split("-").slice(0, 3).join("-");
        const roominfoobj = {
          bookingid: response?.data?.data?.dto?.ordernumber,
          doctorid: response?.data?.data?.dto?.doctor?.profileid,
          id: null,
          link: `https://softalk.softwaremathematics.com/join/${roomid}`,
          profileid: auth.profileid,
          recstatus: "OPEN",
          roomid: roomid,
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };
        roomIdGeneration(roominfoobj);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  };

  const updateVisit = async (childId) => {
    const body = {
      visitid: aggregiatepatient.visit.visitid,
      id: aggregiatepatient.visit?.id,
      patientid: aggregiatepatient.patient.patientid,
      profileid: aggregiatepatient.patient.profileid,
      doctorid: auth.profileid,
      mmucode: auth.profileid,
      visittype: "FOLLOW_UP",
      visitcategory: aggregiatepatient.visit.visitcategory,
      paymenttype: "PAID",
      modifiedby: auth.profileid,
      modifiedbyname: auth?.name,
      recstatus: "COMPLETED",
      syncstatus: "NOT_SYNCED",
      status: "ACTIVE",
      parentvisitid: "",
      childvisitid: childId,
    };

    await axios
      .put(`${APIs.visitServiceApi}/update`, body)
      .then((response) => {
        handleSubmitPrescription();
        console.log(response, "update visit response 150");
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error, "Create visit error");
        setIsLoading(false);
      });
  };

  async function roomIdGeneration(roominfo) {
    try {
      const response = await axios.post(
        "room-id-generation-service/api/RoomIdGeneration/v1/create",
        roominfo
      );

      // return response;
    } catch (error) {
      console.error("API error:", error);
      throw error;
    }
  }

  const handleSubmitPrescription = () => {
    setFinalLabtest([]);
    for (let i = 0; i < selectedTestlist?.length; i++) {
      const labTestObj = {
        createdby: auth.profileid,
        detailedresult: null,
        id: null,
        investigationlist: selectedTestlist[i]?.investigationlist,

        mmucode: auth.profileid,
        recstatus: "OPEN",
        status: "ACTIVE",
        syncstatus: "NOT_SYNC",
        testid: selectedTestlist[i]?.id,
        testname: selectedTestlist[i]?.testname,
        testresult: "",
        visitid: aggregiatepatient?.visit?.visitid,
      };
      finalLabtest.push(labTestObj);
    }

    for (let i = 0; i < completePrescrptionObject?.medicinelist?.length; i++) {
      delete completePrescrptionObject?.medicinelist[i]?.uid;
    }
    // completePrescrptionObject.visitData.recstatus = "PRESCRIBED";
    const data = {
      ...completePrescrptionObject,
      visitData: {
        ...completePrescrptionObject.visitData,
        recstatus: "PRESCRIBED",
      },
    };

    createPrescription(data);
  };

  const handleSubmit = (values, { resetForm }) => {
    console.log(values, "values 327")
    const newData = {
      uid: idCounter,
      countperday: frequencydata?.countperday,
      createdby: auth?.profileid,
      //   documentofproof: "",
      dosage: values?.medicine,
      dose: values?.dose,
      duration: values?.duration,
      //   form: "",
      frequency: values?.frequency,
      id: null,
      //   medicineid: medicineData?.id,
      medicinename: values?.medicine,
      // mmucode: mmucode,
      quantity: values?.dose * values?.duration * frequencydata?.countperday,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "NOT_SYNCED",
      tempid: "",
      uom: values.uom,
      visitid: aggregiatepatient?.visit?.visitid,
    };
    setData([...data, newData]);
    setIdCounter(idCounter + 1);
    resetForm();
    setFrequencydata(null);
  };

  console.log("Saterea", location?.state);

  return (
    <div>
      {orderNumber && <Landing roomId={location?.state?.roomId} setOrderNumber={setOrderNumber} />}
      <PrescriptionForm
        aggregiatepatient={aggregiatepatient}
        setSelectedDisease={setSelectedDisease}
        setChiefcomplaint={setChiefcomplaint}
        chiefcomplaint={chiefcomplaint}
        setRemark={setRemark}
        remark={remark}
        handleSubmit={handleSubmit}
        orderNumber={orderNumber}
        setFrequencydata={setFrequencydata}
        columns={columns}
        data={data}
        setSelectedTestlist={setSelectedTestlist}
        handleAssignFollowUp={handleAssignFollowUp}
        showAppointments={showAppointments}
        updateData={updateData}
        handleAppointment={handleAppointment}
        isLoading={isLoading}
        handlePrescription={handlePrescription}
      />
    </div>
  );
};

export default VideoConsultation;
