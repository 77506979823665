
  import checkout from "../schemas/form";
 
  const {
    formField: {
   
     //puchasedetail
    
   age,
     firstName,
     lastName,
   gender,
email,
mobilenumber,
relation
 
    },
  } = checkout;

  const initialValues = {
  
  
  

[age.name]:"",


 [firstName.name]:"",
 [lastName.name]:"",
   [gender.name]: "",
   [email.name]:"",
   [mobilenumber.name]:"",
   [relation.name]:""
  
   
  };

  export default initialValues;
