/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "role-form",
  formField: {
    projectname: {
      name: "projectname",
      label: "MIC",
      type: "text",
      errorMsg: "MIC is required.",
    },
    departmentname: {
      name: "departmentname",
      label: "Department",
      type: "text",
      errorMsg: "Department is required.",
    },
    designationname: {
      name: "designationname",
      label: "Designation",
      type: "text",
      errorMsg: "Designation is required.",
    },
    profilename: {
      name: "profilename",
      label: "Profile",
      type: "text",
      errorMsg: "Profile is required.",
    },
  },
};

export default form;
