
import { useEffect, useState } from "react";
import {Card, Grid} from "@mui/material";
import MDBox from "components/MDBox";

// Images
import Capsule from "assets/images/Capsule.png";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 React Examples
import DataTable from "examples/Tables/DataTable";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import { useLocation } from "react-router-dom";

const PrescrpitionHistory = () => {
    const location =useLocation();

  return (
        <DashboardLayout>
        {/* <DashboardNavbar /> */}
      
<Card>

                <MDBox p={3}>
                    <MDTypography style={{ color: "blue" }} variant="h5">Full Detail</MDTypography>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={8}>
                            <MDBox mt={7}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography variant="h6">Patient Name : </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography component="p" variant="h6" color="text">
                                        {location?.state?.patient?.name}
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox mt={1}>

                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography variant="h6">Patient Id:</MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography component="p" variant="h6" color="text">
                                        {location?.state?.patient?.patientid}
                                        </MDTypography>
                                    </Grid>

                                </Grid>
                            </MDBox>
                            <MDBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography variant="h6">Age : </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography component="p" variant="h6" color="text">
                                        {location?.state?.patient?.age}
                                        </MDTypography>
                                    </Grid>
                                </Grid>
                            </MDBox>
                            <MDBox mt={1}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography variant="h6">Gender : </MDTypography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <MDTypography component="p" variant="h6" color="text">
                                        {location?.state?.patient?.gender}
                                        </MDTypography>
                                    </Grid>
                                </Grid>

                                {/* <MDBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <MDTypography variant="h6">Albumin : </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDTypography component="p" variant="h6" color="text">
                                                3.5 to 5.0 grams per deciliter (g/dL)
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MDBox> */}
                                {/* <MDBox mt={1}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <MDTypography variant="h6">Bilirubin : </MDTypography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <MDTypography component="p" variant="h6" color="text">
                                                milligrams per
                                            </MDTypography>
                                        </Grid>
                                    </Grid>
                                </MDBox> */}
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} sm={4}>

                            <img src={Capsule} width="90%"
                                height="80%"
                                alt="Logo" />

                        </Grid>
                    </Grid>
                </MDBox>

</Card>
 <h5 style={{ margin: "1px", textAlign: "center" }}>Medicine list</h5>
                <Grid container spacing={3} mt={3}>
               
                    <Grid item xs={12} sm={12}>
                        <DataTable entriesPerPage={false}
                        showTotalEntries={false}
                            table={{
                                columns: [
                                    { Header: "medicine name", accessor: "medicinename" },
                                    { Header: "duration", accessor: "duration"},
                                    { Header: "countperday", accessor: "countperday" },
                                    
                                ],
                                rows: location?.state?.doseslist 
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={3} mt={1}>
               
               
                    <Grid item xs={12} sm={12}>
                    <h5 style={{ margin: "1px", textAlign: "center" }}>Download Link</h5>
                        <DataTable entriesPerPage={false}
                        showTotalEntries={false}
                            table={{
                                columns: [
                                    { Header: "name", accessor: "link" },
                                    
                                ],
                                rows: [
                                    {
                                        name: "Hanny Baniard",
                                        position: "Data Coordiator",
                                        office: "Baorixile",
                                        age: 42,
                                        startDate: "4/11/2021",
                                        salary: "$474,978",
                                        link:"https://www.google.com/"
                                    },
                                  


                                ]
                            }}
                        />
                    </Grid>
                </Grid>



          
        </DashboardLayout>
    )
}





export default PrescrpitionHistory;

