import React, { useEffect, useState } from "react";

import Dialog from "@mui/material/Dialog";



import DialogTitle from "@mui/material/DialogTitle";
import "./viewprofile.css"
import doctorprofile from "static/doctorprofile";



function ViewfullProfile(props) {
  const { title, openpopup, setopenpopup, selecteddata } = props;
  console.log(selecteddata);


  const handleClose = () => {
    setopenpopup(false);
  };

  return (
  
    <Dialog open={openpopup} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <div className="profile-card">
      <header>
        {/* Profile */}
        <img 
        src={doctorprofile(selecteddata?.profileid)} 
        
        alt="Profile" />

        <h1>
         {selecteddata?.name} 
        </h1>
        <h2>
          Age: {selecteddata?.age}yr 
        </h2>
        
      </header>
      {/* Bio */}
      <div className="profile-bio">
      {selecteddata?.qualificationDTOList
  ?.filter(item => item.status === 'ACTIVE') 
  .map((item, index) => (
    <p key={index}>{item?.qualificationname} {item?.qualificationmode} {item?.qualificationboard}</p>
  ))}


       

        
      </div>
      
    </div>
    
      
    </Dialog>
  );
}




export default ViewfullProfile;