import { Grid } from "@mui/material";
import React from "react";
import "../Providercard/style.css";

import visibility from "../../findDoctor/assets/images/svgimage.svg";
import Instant from "../../findDoctor/assets/images/instant.svg";
import smartcalling from "../../findDoctor/assets/images/smartcalling.svg";
import intractive from "../../findDoctor/assets/images/intractive.svg";

const Pcards = () => {
  // const cardsDatas1 = [
  //     {
  //       logo: visibility,
  //       description: "Better visibility in our listings",
  //     },
  //     {
  //       logo: Instant,
  //       description: "24X7 Instant Booking",
  //     },
  //     {
  //       logo: smartcalling,
  //       description: "Smart Calling",
  //     },
  //     {
  //       logo: intractive,
  //       description: "Interactive VR Service",
  //     },

  //   ];
  return (
    <div>
      <Grid container className="provider-container">
        <Grid container spacing={2} className="container-a">
          <Grid item xs={12}>
            <h2 className="provider-heading">Here’s what you get with us</h2>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className="card-a">
              <div className="image-container-1">
                <Grid item className="providerimage">
                  <img
                    src={visibility}
                    alt="Service Logo"
                    className="image-a1"
                  />
                </Grid>

                {/* <div className="pentagon-1"></div> */}
              </div>
              <Grid item>
                <div className="description-a1">
                  Better visibility in our listings
                </div>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className="card-a">
              <div className="image-container-1a">
                <Grid item className="providerimage">
                  <img src={Instant} alt="Service Logo" className="image-a1" />
                </Grid>
              </div>
              <Grid item>
                <div className="description-a1">24X7 Instant Booking</div>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className="card-a">
              <div className="image-container-1b">
                {/* <Grid item className="providerimage">
             <img src={smartcalling} alt="Service Logo" className="image-a1" />
           </Grid> */}
                <div class="hexagon"></div>
                <img
                  src={smartcalling}
                  alt="Service Logo"
                  className="image-a2"
                />
              </div>
              <Grid item>
                <div className="description-a1">Smart Calling</div>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <div className="card-a">
              <div className="image-container-1c">
                <Grid item className="providerimage">
                  <img
                    src={intractive}
                    alt="Service Logo"
                    className="image-a1"
                  />
                </Grid>
              </div>
              <Grid item>
                <div className="description-a1">Interactive VR Service</div>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Pcards;
