import Bookinglist from 'components/Shared/bookingList/BookingList';
import MobileNavbar from '../../components/navbar/mobileNavbar/MobileNavbar';
import Navbar from '../../components/navbar/desktopNavbar/Navbar';
import React, { Suspense, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Patientform from 'components/Resuable/Patient Form/Patientform';
import PatientList from 'components/Resuable/Patient Form/PatientList';

import PatientPrescriptionlist from 'components/Resuable/PrescriptionList/PatientPrescriptionlist';
import Speciality from 'components/Shared/specialities/Speciality';



import FinalAppointment from 'components/Resuable/appointment';
import Detailpage from 'layouts/pages/Details';
import DoctorsExpanded from 'components/Resuable/PatientNurseshared/doctorsExpanded/doctorsExpanded';
import PatientInfo from 'app/patient/PatientInformation/PatientInfo';
import Prescriptionview from 'components/Resuable/PrescriptionList/Prescriptionview';


import { useSelector } from 'react-redux';
import SpecificLocation from 'app/labTest/specificLocation/specificLocation';

import SelectPerson from 'app/labTest/Select Person/selectperson';
import SelectLabTest from 'app/labTest/Select Lab Test/selectlabTest';
import SelectLab from 'app/labTest/selectLab/selectLab';
import SelectAddres from 'app/labTest/BookLabTest/SelectAddres';
import LabTestList from 'app/labTest/labTestList/labTestList';
import UserInformation from 'components/UserInformation/userInformation';
import SearchPatient from './searchpatient/SearchPatient';
import DoctorsPage from 'components/Shared/doctor-bookings/DoctorsPage';
import HomeMobFooter from "layouts/pages/home/footer/HomeMobFooter";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import HomeSkeleton from 'components/Skeleton/HomeSkeleton';

const NurseHome = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
    const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
    const { slug } = useParams();
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

    useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth <= 500);
          setIsMidScreen(window.innerWidth <= 900);
        };
        window.addEventListener("resize", handleResize);
        return () => {
          window.removeEventListener("resize", handleResize);
        };
      }, []);
 

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
        <Suspense fallback={<HomeSkeleton />}>
        {isMidScreen ? <MobileNavbar /> : <Navbar />}
        
        {slug ==="patient-form" && <Patientform />}
        {slug ==="patient-list" && <PatientList />}
     
        {slug ==="book-patient" && <SearchPatient doctorProfileId={auth.createdby} />}
        {slug ==="patient-prescription" && <PatientPrescriptionlist />}
        {slug ==="speciality" && <Speciality />}
        {slug ==="profile" && <PatientInfo />}
        {slug ==="doctors-Expanded" && <DoctorsExpanded />}
        {slug ==="doctor-booking" && <DoctorsPage />}
        {slug ==="final-appointment" && <FinalAppointment />}
        {slug ==="detail-page" && <Detailpage />}
        {slug ==="prescription-view" && <Prescriptionview />}
        {slug ==="nurse-information" && <UserInformation />}
        {slug ==="specific-location" && <SpecificLocation />}
        {slug ==="lab-test-cart" && <SelectLabTest />}
        {slug ==="select-person" && <SelectPerson />}
        {slug ==="select-lab" && <SelectLab />}
        {slug ==="selected-address" && <SelectAddres />}
        {slug ==="my-labtests" && <LabTestList />}
        {/* 
        {slug ==="family-list" && <Familylist />}
        {slug ==="addFamilymember" && <AddFamilymember />}
        {slug ==="speciality" && <Speciality />} */}

{isMidScreen ? <></> : <MedharvaFooter />}
{isSmallScreen && <HomeMobFooter />}
</Suspense>
    </div>
  )
}


export default NurseHome;