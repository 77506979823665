// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// vitals page components
import FormField from "../FormField";

import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";
import { FormControl, InputLabel, Select } from "@mui/material";
import { ErrorMessage } from "formik";

function PatientInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");

  const { formField, values, errors, touched ,setFieldValue} = formData;

  const { firstLine,secondLine,country,state,district,subdistrict,cityVillage,pincode } = formField;
  var { firstLine: firstLineV, secondLine: secondLineV,country: countryV,state: stateV,district: districtV,subdistrict: subdistrictV,cityVillage: cityVillageV,pincode: pincodeV } = values;

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Address Master</MDTypography>
     
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />

      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="addresstype"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.addresstype)}
                      >
                       Address Type{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="addresstype"
                        name="addresstype"
                        value={values.addresstype}
                        onChange={(e) => {
                          const selectedType = e.target.value;

                          setFieldValue("addresstype", selectedType);
                        }}
                        error={touched.addresstype && Boolean(errors.addresstype)}
                      >
                        <option value=""></option>
                     
                          <option value="Default">Default</option>
                          <option value="Home">Home</option>
                          <option value="Office">Office</option>
                          <option value="Other">Other</option>
                      </Select>
                      <ErrorMessage
                        name="addresstype"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstLine.type}
                label=<span>{firstLine.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={firstLine.name}
                value={firstLineV}
                placeholder={firstLine.placeholder}
                error={errors.firstLine && touched.firstLine}
                success={firstLineV.length > 0 && !errors.firstLine}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={secondLine.type}
                label=<span>{secondLine.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={secondLine.name}
                value={secondLineV}
                placeholder={secondLine.placeholder}
                error={errors.secondLine && touched.secondLine}
                success={secondLineV.length > 0 && !errors.secondLine}
              />
            </Grid>
              <Grid item xs={12} sm={6}>
              <FormField
                type={country.type}
                label=<span>{country.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={country.name}
                value={countryV}
                placeholder={country.placeholder}
                error={errors.country && touched.country}
                success={countryV.length > 0 && !errors.country}
              />
            </Grid>
        
              <Grid item xs={12} sm={6}>
              <FormField
                type={state.type}
                label=<span>{state.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={state.name}
                value={stateV}
                placeholder={state.placeholder}
                error={errors.state && touched.state}
                success={stateV.length > 0 && !errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={district.type}
                label=<span>{district.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={district.name}
                value={districtV}
                placeholder={district.placeholder}
                error={errors.district && touched.district}
                success={districtV.length > 0 && !errors.district}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={subdistrict.type}
                label=<span>{subdistrict.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={subdistrict.name}
                value={subdistrictV}
                placeholder={subdistrict.placeholder}
                error={errors.subdistrict && touched.subdistrict}
                success={subdistrictV.length > 0 && !errors.subdistrict}
              />
            </Grid>
         
            <Grid item xs={12} sm={6}>
              <FormField
                type={cityVillage.type}
                label=<span>{cityVillage.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={cityVillage.name}
                value={cityVillageV}
                placeholder={cityVillage.placeholder}
                error={errors.cityVillage && touched.cityVillage}
                success={cityVillageV.length > 0 && !errors.cityVillage}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={pincode.type}
                label=<span>{pincode.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={pincode.name}
                value={pincodeV}
                placeholder={pincode.placeholder}
                error={errors.pincode && touched.pincode}
                success={pincodeV.length > 0 && !errors.pincode}
              />
            </Grid>
          </Grid>
     
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RoleInfo
PatientInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default PatientInfo;
