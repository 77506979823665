// import React from 'react'
// import "./medharva.css";
// import medharvaImage from "assets/images/Medharva-logo.svg";
// import MDBox from 'components/MDBox';
// import { Grid } from '@mui/material';
// import MDTypography from 'components/MDTypography';
// import MuiLink from "@mui/material/Link";
// // @mui icons
// import YouTubeIcon from "@mui/icons-material/YouTube";
// import GitHubIcon from "@mui/icons-material/GitHub";
// import GoogleIcon from "@mui/icons-material/Google";
// import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import InstagramIcon from "@mui/icons-material/Instagram";
// const MedharvaFooter = () => {
//   return (

//     <div className='footer'>
//       <div className='sb_footer section__padding'>

//         <MDBox style={{ margin: "auto" }}>
//           <img src={medharvaImage} alt='logo' height={200} />

//         </MDBox>
//         <Grid container spacing={3} justifyContent="center" sx={{ mb: 2 }}>

//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://www.facebook.com/" variant="body1" >
//               <FacebookOutlinedIcon color="primary" fontSize="large" />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://www.instagram.com/" variant="body1" >
//               <InstagramIcon fontSize="large" sx={{ color: "#E1306C" }} />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://github.com/" variant="body1" >
//               <GitHubIcon color="#171515" fontSize='large' />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://www.google.com/" variant="body1" color="white">
//               <GoogleIcon color="inherit" fontSize='large' />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://www.youtube.com/" variant="body1" color="white">
//               <YouTubeIcon fontSize="large" sx={{ color: "#FF0000" }} />
//             </MDTypography>
//           </Grid>
//           <Grid item xs={-1}>
//             <MDTypography component={MuiLink} href="https://twitter.com/" variant="body1" color="white">
//               <TwitterIcon fontSize="large" sx={{ color: "#1DA1F2" }} />
//             </MDTypography>
//           </Grid>

//         </Grid>
//         <hr></hr>
//         <Grid item xs={12} sm={10} style={{ margin: "auto" }}>
//           <Grid container spacing={3} ml={2} mr={2}>
//             <Grid item xs={12} sm={2}>
//               <a href='https://medharva.com/'>
//                 <b style={{ color: "white", fontSize: "17px", fontWeight: "bold" }}>Home</b>
//               </a>
//             </Grid>
//             <Grid item xs={12} sm={2}>
//               <a href='https://medharva.com/about/'>
//                 <b style={{ color: "white", fontSize: "16px", fontWeight: "bold" }}>About</b>
//               </a>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <a href='https://medharva.com/our-services/'>
//                 <b style={{ color: "white", fontSize: "17px", fontWeight: "bold" }}>Our services</b>
//               </a>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               <a href='https://medharva.com/contact-us/'>
//                 <b style={{ color: "white", fontSize: "17px", fontWeight: "bold" }}>Contact Us</b>
//               </a>
//             </Grid>
//             <Grid item xs={12} sm={4}>
//               {<></>} </Grid>
//           </Grid>
//         </Grid>

//         <hr></hr>
//         <Grid item xs={12} sm={10} style={{ margin: "auto" }}>
//           <Grid container spacing={3}>
//             <Grid item xs={12} sm={12} ml={2} mr={2}>
//               <MDTypography sx={{ mb: 2 }} fontSize="18px" fontWeight="bold" color="white" justifyContent="center" textaligin="center" >
//                 Copyright 2023 Medharva Platform. All rights reserved. Proudly Hosted by Software Mathematics.
//               </MDTypography>
//             </Grid>
//             <Grid item xs={12} sm={2}>

//             </Grid>
//           </Grid>
//         </Grid>
//       </div>
//     </div>

//   )
// }

// export default MedharvaFooter;

import React, {useState, useEffect} from "react";
import "./medharva.css";
import medharvaImage from "assets/images/Medharva-logo.svg";
import { Grid } from "@mui/material";
import MuiLink from "@mui/material/Link";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import GoogleIcon from "@mui/icons-material/Google";
import InstagramIcon from "@mui/icons-material/Instagram";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const MedharvaFooter = () => {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isTabView, setIsTabView] = useState(false);
  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 655);
      setIsTabView(window.innerWidth >= 656 && window.innerWidth <=1246);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Grid container spacing={3} pb={10} className="footer">
        <Grid item xs={isMobileView ? 12 : (isTabView ? 6 : 3)} ml={isMobileView ? 3 : (isTabView ? 3 : 15)} mr={(isTabView ? 0 : 10)} mt={5} className="part1">
          <a href="https://medharva.com/">
            <img src={medharvaImage} alt="logo" height={130} />
          </a>
          Our comprehensive healthcare center offers a convenient one-stop
          solution, catering to all your medical needs under one roof.
          <Grid container spacing={1} mt={4} ml={0}>
            <Grid item xs={isMobileView ? 1.5 : (isTabView ? 2 : 1.5)} mr={1} pr={1} className="mdicon">
              <a href="https://www.facebook.com/people/Medharva/100094882181344/">
                <FacebookOutlinedIcon fontSize="small" color="white" />
              </a>
            </Grid>
            <Grid item xs={isMobileView ? 1.5 : (isTabView ? 2 : 1.5)} mr={1} pr={1} className="mdicon">
              <a href="https://www.instagram.com/_medharva_/">
                <InstagramIcon fontSize="small" color="white" />
              </a>
            </Grid>
            <Grid item xs={isMobileView ? 1.5 : (isTabView ? 2 : 1.5)} mr={1} pr={1} className="mdicon">
              <a href="https://www.linkedin.com/company/medharva/">
                <LinkedInIcon fontSize="small" color="white" />
              </a>
            </Grid>
            <Grid item xs={isMobileView ? 1.5 : (isTabView ? 2 : 1.5)} mr={1} pr={1} className="mdicon">
              <a href="https://www.google.com/search?q=Medharva">
                <GoogleIcon fontSize="small" color="white" />
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobileView ? 12 : (isTabView ? 4 : 3)} ml={isMobileView ? 3 : (isTabView ? 10 : 0)} mt={9} mr={(isTabView ? 0 : 0)} className="part2">
          <h2>Quick Link</h2>
          <Grid container spacing={1} mt={0}>
            <Grid item xs={12}>
              <a href="https://medharva.com/">
                <KeyboardArrowRightIcon
                  fontSize="medium"
                  sx={{ marginBottom: "-5px" }}
                />{" "}
                Home
              </a>
            </Grid>
            <Grid item xs={12}>
              <a href="/app">
                <KeyboardArrowRightIcon
                  fontSize="medium"
                  sx={{ marginBottom: "-5px" }}
                />{" "}
                Medharva
              </a>
            </Grid>
            <Grid item xs={12}>
              <a href="https://medharva.com/about/">
                <KeyboardArrowRightIcon
                  fontSize="medium"
                  sx={{ marginBottom: "-5px" }}
                />{" "}
                About Us
              </a>
            </Grid>
            <Grid item xs={12}>
              <a href="https://medharva.com/our-services/">
                <KeyboardArrowRightIcon
                  fontSize="medium"
                  sx={{ marginBottom: "-5px" }}
                />{" "}
                Our Services
              </a>
            </Grid>
            <Grid item xs={12}>
              <a href="https://medharva.com/contact-us/">
                <KeyboardArrowRightIcon
                  fontSize="medium"
                  sx={{ marginBottom: "-5px" }}
                />{" "}
                Contact Us
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={isMobileView ? 8 : (isTabView ? 4 : 3)} mt={10} ml={isMobileView ? 3 : (isTabView ? 3 : 0)} className="part3">
          <h2>Contact Us</h2>
          <br />
          <div className="telicon">
            <a href="tel:+919313891689">
              <PhoneEnabledIcon className="phoneenabledicon" /> &nbsp;&nbsp;+91
              9313891689
            </a>
          </div>
          <div className="emailicon">
            <a href="mailto:info@medharva.com">
              <EmailIcon className="phoneenabledicon" />{" "}
              &nbsp;&nbsp;Info@Medharva.Com
            </a>
          </div>
          <div className="location">
            <div>
              <LocationOnIcon className="locationicon" />
            </div>
            <div>
              RDB Boulevard, 8th Floor, Plot K-1, Sector V, Block EP & GP, Salt
              Lake City, Kolkata, West Bengal
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={1} className="footer2">
        <Grid item xs={12} mt={1} mb={1} className="copyright">
          Copyright 2023 Medharva All Rights Reserved
        </Grid>
      </Grid>
    </>
  );
};

export default MedharvaFooter;
