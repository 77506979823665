import React, { useEffect, useState } from "react";
import { Grid, Card, useMediaQuery, Avatar } from "@mui/material";
import "./selectcategory.css";
import Button from "@mui/material/Button";
import image1 from "../../findDoctor/assets/images/medicine00000.png";
import image2 from "../../findDoctor/assets/images/Search.svg";
import image3 from "../../findDoctor/assets/images/mg.svg";
import image4 from "../../findDoctor/assets/images/filtersort.svg";
import GreenDropdown from "./greendropdown";
import { useNavigate } from "react-router-dom";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";

const personData = [
  {
    id: 1,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 500,
    prise: 650,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
  {
    id: 2,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 500,
    prise: 650,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
  {
    id: 3,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 500,
    prise: 650,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
  {
    id: 4,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 500,
    prise: 650,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
  {
    id: 5,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 500,
    prise: 650,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
  {
    id: 6,
    name: "Medicine one full nameai BG3 Blood Glucose strip (Only strip)",
    addcart: "Add to cart",
    weight: 600,
    prise: 700,
    discount: "27%off",
    time: " Box of 50 strips",
    profileUrl1: image1,
    profileUrl2: image3,
  },
];
const options = ["Option 1", "Option 2", "Option 3"];
const Selectcategory = () => {
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState("");
  const [totalWeight, setTotalWeight] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleAddToCart = (item) => {
    setSelectedItems([...selectedItems, item]);
    setCount(count + 1);
    setTotalWeight(totalWeight + item.weight);
  };
  const handleSubmit = () => {
    if (selectedItems.length === 0) {
      alert("add object in cart");
      return;
    }
    console.log("Selected Items:", selectedItems);
    alert([{ selectedItems }]);
    navigate("/app/pages/users/medicine");
  };
  const filteredData = personData.filter((person) => {
    return Object.values(person).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const Product = ({ price, discount }) => {
    return (
      <div className="spandiscount11">
        <span className="spandiscount">{discount}</span>
      </div>
    );
  };
  return (
    <>
      {isSmallScreen ? <></> : <Navbar pagelabel="Medicines" />}
      {isSmallScreen ? <MobileNavbar /> : <></>}
      <div className="personclass">
        <Grid container spacing={1} mt={23} className="select4">
          <Grid
            container
            item
            xs={12}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
            ml={isSmallScreen ? 0 : 6}
          >
            {isSmallScreen ? (
              <h2 className="selectlabh2">Add Medicines</h2>
            ) : (
              <h2 className="selectlabh2">Select Category</h2>
            )}
          </Grid>
          <Grid item mt={3} className="searchlabtest1">
            <input
              type="text"
              id="searchlabtestinput"
              placeholder="Search for lab tests"
              className="searchlabtestinput"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="searchlabtestbutton1">
              <img
                src={image2}
                alt="Search Logo"
                className="searchlabtestimg1"
              />
            </button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            className="sortAndFilterContainer"
            sx={{
              justifyContent: "left",
              alignItems: "center",
              gap: "15px",
              marginTop: "30px",
            }}
          >
            <div>
              <GreenDropdown
                options={options}
                value={value}
                onChange={handleChange}
              />
            </div>
            <div>
              <Button
                variant="outlined"
                className="button11"
                endIcon={<Avatar src={image4} sx={{ width: 14, height: 14 }} />}
                sx={{
                  width: "auto",
                  height: "38px",
                  borderRadius: "4px",
                  fontSize: "12px",
                  color: "#000000",
                  background: "#ffffff",
                  border: "2px solid #039147",
                  borderColor: "#039147",
                  textTransform: "none",
                  "&:hover": {
                    background: "#039147",
                    color: "#ffffff",
                    border: "2px solid #039147",
                  },
                }}
              >
                Filter
              </Button>
            </div>
          </Grid>
          {filteredData.map((person, index) => (
            <Card
              key={index}
              className="selectcard"
              sx={{ borderRadius: "8px" }}
            >
              <Grid container spacing={1}>
                <Grid container item xs={12} className="grid11">
                  <Grid item xs={7} className="grid11">
                    <h2 className="selecth3">
                      {" "}
                      {person.name}
                      <br />
                      <span>{person.time} old </span>{" "}
                    </h2>
                  </Grid>
                  <Grid item xs={5} mt={1} className="grid38">
                    <img
                      className="profileimg"
                      alt={person.image1}
                      src={person.profileUrl1}
                    />
                  </Grid>
                </Grid>
                <Grid container item xs={12} className="grid14">
                  <Grid item xs={isSmallScreen ? 3.5 : 5} className="grid18">
                    <h2 className="selecth4">
                      <span className="spantext">{person.prise}</span>{" "}
                      {person.weight}
                    </h2>
                    <Grid
                      item
                      xs={isSmallScreen ? 4.7 : 8}
                      mt={isSmallScreen ? 0 : -1}
                      className="grid18"
                    >
                      <Product discount="27% off" />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={isSmallScreen ? 4 : 2.5}
                    mt={2}
                    className="grid388"
                  >
                    <h2 className="selecth44">
                      Provided by{" "}
                      <img
                        className="profileimg223"
                        alt={person.image3}
                        src={person.profileUrl2}
                      />
                    </h2>
                  </Grid>
                  <Grid item xs={isSmallScreen ? 4.5 : 3} className="grid17">
                    <Button
                      variant="outlined"
                      onClick={() => handleAddToCart(person)}
                      className="button11"
                      sx={{
                        width: { xs: 110, sm: 138, md: 140, lg: 145, xl: 145 },
                        height: { xs: 29, sm: 34, md: 36, lg: 41, xl: 41 },
                        borderRadius: "100px",
                        fontSize: { xs: 11, sm: 12, md: 13, lg: 15, xl: 15 },
                        color: "#039147",
                        border: "2px solid #039147",
                        borderColor: "#039147",
                        textTransform: "none",
                        "&:hover": {
                          background: "#039147",
                          color: "#ffffff",
                          border: "2px solid #039147",
                        },
                      }}
                    >
                      {selectedItems.filter((item) => item.id === person.id)
                        .length > 0
                        ? `${person.addcart} (${
                            selectedItems.filter(
                              (item) => item.id === person.id
                            ).length
                          })`
                        : person.addcart}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          ))}
          <Grid
            container
            item
            xs={12}
            sm={12}
            mt={1}
            mb={10}
            sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              variant="text"
              className="button11"
              sx={{
                // width: "151px",
                width: { xs: 100, sm: 120, md: 120, lg: 130, xl: 151 },
                height: { xs: 29, sm: 34, md: 36, lg: 41, xl: 41 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                color: "#000000",
                background: "#fdfdfd",

                textTransform: "none",
              }}
            >
              {count} items
            </Button>
            <Button
              variant="text"
              className="button11"
              sx={{
                // width: "151px",
                width: { xs: 100, sm: 120, md: 120, lg: 130, xl: 151 },
                height: { xs: 29, sm: 34, md: 36, lg: 41, xl: 41 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                lineHeight: "20",
                color: "#000000",
                background: "#fdfdfd",

                textTransform: "none",
              }}
            >
              {totalWeight}
            </Button>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              className="button11"
              sx={{
                // width: "151px",
                width: { xs: 100, sm: 315, md: 400, lg: 700, xl: 1010 },
                height: { xs: 29, sm: 34, md: 36, lg: 41, xl: 41 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                color: "#ffffff",
                background: "#039147",
                border: "2px solid #039147",
                borderColor: "#039147",
                textTransform: "none",
                "&:hover": {
                  background: "#FFFFFF",
                  color: "#039147",
                  border: "2px solid #039147",
                },
              }}
            >
              Select Lab
            </Button>
          </Grid>
        </Grid>
      </div>
      {isSmallScreen ? <></> : <MedharvaFooter />}
      {isSmallScreen ? <FooterNavbar pagelabel="Medicines" /> : <></>}
    </>
  );
};
export default Selectcategory;
