import { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "../findDoctor/navbar/mobileNavbar/mobileNavbar";

import MedharvaPlus from "app/findDoctor/medharvaPlus/medharvaPlus";
import EmailSubscribe from "app/findDoctor/emailSubscribe/emailSubscribe";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
// import Home from "app/findDoctor/akash/Home";
// import Cards from "app/findDoctor/akash/Cards";
// import Card from "app/findDoctor/akash/Card";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";

import WhyConsultOnline from "./whyConsultOnline/whyConsultOnline";
import WhatUserSay from "app/findDoctor/whatUserSay/whatUserSay";
import NumberUpdates from "app/findDoctor/numberUpdates/numberUpdates";
import SurgicalSpecalist from "./surgical specialist/SurgicalSpecalist";
import OnlineHome from "./OnlineHome/OnlineHome";
import Choosespeciality from "./Choose a speciality/Choosespeciality";

function OnlineConsult() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="Online Consultation" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <OnlineHome />
      {/* {isSmallScreen ? <></> : <Cards />} */}
      <NumberUpdates />
      {/* <Card pagelabel="Online Consultation" /> */}
      <SurgicalSpecalist />
      {isMidScreen ? <></> : <WhyConsultOnline />}
      {isSmallScreen ? <Choosespeciality /> : <></>}
      <MedharvaPlus />

      <WhatUserSay />
      <EmailSubscribe />

      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Video Consultation" /> : <></>}
    </div>
  );
}

export default OnlineConsult;
