import React from "react";
import mrdicine from "../../findDoctor/assets/images/labbbb.png";
import "./style1.css";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const LabTestHome = () => {
  const cards = [{ image: mrdicine }, { image: mrdicine }, { image: mrdicine }];
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...sliderSettings}>
        {cards.map((card, index) => (
          <div key={index}>
            <Grid mt={23} className="medicine-card-A">
              <Grid className="medicine-card-B">
                <img
                  className="medicine-card-imge"
                  src={card.image}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div className="medicine-card-C">
                <Grid className="medicine-car-D">Book lab tests</Grid>
                <Grid className="medicine-car-E">Directly from your home </Grid>
                <div className="consult-car-AL">
                  <Grid className="consult-car-AX">Flat 80% OFF</Grid>

                  <Grid className="consult-card-y">
                    <Button>
                      <span className="consult-card-Az">NEW60</span>
                    </Button>
                  </Grid>
                </div>
              </div>

              <button
                className="medicine-button-container"
                onClick={() => {
                  if (auth.rolecode) {
                    navigate("/app/pages/users/specific-location");
                  } else {
                    navigate("/app/authentication/sign-in/basic");
                    swal(
                      "Attention",
                      "Please log in before booking an appointment for a secure and personalized experience||.",
                      "warning"
                    );
                  }
                }}
              >
                Book now
              </button>
            </Grid>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default LabTestHome;
