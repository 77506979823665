// import React, { useEffect, useState } from "react";
// import axios from "../../../axiosinstance";
// import { Card, Tooltip } from "@mui/material";
// import { BarLoader } from "react-spinners";
// import { FaRegEdit } from "react-icons/fa";
// import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
// import { Link, useNavigate } from "react-router-dom";
// import DataTable from "examples/Tables/DataTable";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import Footer from "examples/Footer";
// import swal from "sweetalert";

// import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";

// import UomInfo from "./Uomifo.js";
// import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
// import CreateuomPopup from "./components/CreateuomPopup";
// import UomEdit from "./components/UomEdit";
// import MDButton from "components/MDButton";
// import { APIs } from 'Services/APIs';

// const CreateUom = () => {
//   const navigate = useNavigate();
//   const [loading, setLoading] = useState(true);
//   const [tableDatas, setTableDatas] = useState({});
//   const [selectedUom, setSelectedUom] = useState({});
//   const [uomPopup, setUomPopup] = useState(false);
//   const [uomedit, setUomedit] = useState(false);

// const iconStyleedit = {
//   fontSize: "1.5em",
//   cursor: "pointer",
//   color: "#039147",
// };
// const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

//   async function onDelete(item) {
//     swal({
//       title: "Are you sure?",
//       text: "Once deleted, you will not be able to recover this record",
//       icon: "warning",
//       buttons: true,
//       dangerMode: true,
//     }).then((willDelete) => {
//       console.log(willDelete);
//       if (willDelete) {
//         axios
//           .put(`${APIs.typeMasterServiceApi}/delete`, item)
//           .then((response) => {

//             getApiResponse();
//           });
//           swal("Your UOM record has been deleted!", {
//             icon: "success",

//           });
//       } else {
//         swal("Your UOM record is safe!");
//       }
//     });
//   }
//   function handleAddButton() {
//     setUomPopup(true);

//   }

//   function getApiResponse() {
//     setLoading(true);
//     const dataObj = {
//       columns: [
//         { Header: "Name", accessor: "name" },
//         { Header: "Description", accessor: "description" },
//         { Header: "base uom", accessor: "basename" },
//         { Header: "conversion factor", accessor: "conversionfactor" },

//         { Header: "display Text", accessor: "displaytext" },
//         { Header: "category", accessor: "category" },

//         { Header: "Action", accessor: "action" },
//       ],
//       rows: [],
//     };
//     axios
//       .get(
//         `${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS`
//       )
//       .then((response) => {
//         response?.data?.data?.dtoList?.map((item) => {
//           item.action = (

//             <span>
//             <Tooltip title="Edit">

//          <FaRegEdit
//            style={iconStyleedit}
//            onClick={() => {
//                    setSelectedUom(item);
//                   setUomedit(true);
//            }}
//          />

//         </Tooltip>

//           <Tooltip title="Delete">

//        <MdDelete
//          style={iconStyle}
//          onClick={() => {

//            onDelete(item);
//          }}
//        />

//    </Tooltip>
//        </span>
//           );
//           dataObj.rows.push(item);
//         });

//         setTableDatas(dataObj);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }

//   useEffect(() => {
//     getApiResponse();
//   }, []);

//   return (
//     <DashboardLayout>
//       <MDBox>
//         <MDBox>
//           <Card>
// <MDBox p={3} lineHeight={1}>
//   <div style={{ display: "flex", justifyContent: "space-between" }}>
//     <MDTypography variant="h5" fontWeight="medium">
//     UOM List
//     </MDTypography>
//     <MDButton
//       color="info"
//       onClick={handleAddButton}
//     >
//       ADD
//     </MDButton>
//   </div>
// </MDBox>
//             <BarLoader
//               color="#344767"
//               loading={loading}
//               aria-label="Loading Spinner"
//             />
//             {!loading && <DataTable table={tableDatas} canSearch={true} />}
//           </Card>
//         </MDBox>
//       </MDBox>
//       <Footer />

//       {uomedit && (
//         <UomEdit
//         tittle={"Edit UOM"}
//         uomedit={uomedit}
//         setUomedit={setUomedit}
//         selectedUom={selectedUom}
//         getApiResponse={getApiResponse}
//       />
//       )}
//       {uomPopup && (
//         <CreateuomPopup
//           tittle={"Create UOM"}
//           uomPopup={uomPopup}
//           setUomPopup={setUomPopup}
//           getApiResponse={getApiResponse}
//         />
//       )}
//     </DashboardLayout>
//   );
// };

// export default CreateUom;

import React, { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { Card, Tooltip } from "@mui/material";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "examples/Tables/DataTable";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from "sweetalert";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import UomInfo from "./Uomifo.js";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { APIs } from "Services/APIs";
import MDButton from "components/MDButton";

const CreateUom = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [tableDatas, setTableDatas] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [uomPopup, setUomPopup] = useState(false);

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const iconStyleedit = {
    fontSize: "1.5em",
    cursor: "pointer",
    color: "#039147",
  };
  const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };

  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios
          .put(`${APIs.typeMasterServiceApi}/delete`, item)
          .then((response) => {
            console.log(response);
            getApiResponse();
          });
        swal("Your role record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your operation record is safe!");
      }
    });
  }
  function handleAddButton() {
    setUomPopup(true);
    setSelectedItem(null);
  }

  function getApiResponse() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "Name", accessor: "name" },
        { Header: "Description", accessor: "description" },
        { Header: "base uom", accessor: "basename" },
        { Header: "conversion factor", accessor: "conversionfactor" },

        { Header: "display Text", accessor: "displaytext" },
        { Header: "category", accessor: "category" },

        { Header: "Action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(`${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS`)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Tooltip title="Edit">
                <FaRegEdit
                  style={iconStyleedit}
                  onClick={() => {
                    setSelectedItem(item);
                    setUomPopup(true);
                  }}
                />
              </Tooltip>

              <Tooltip title="Delete">
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Tooltip>
            </span>
          );
          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getApiResponse();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} mt={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    UOM List
                  </MDTypography>
                </span>
                <span
                  style={{
                    alignSelf: "start",
                    color: "#344767",
                  }}
                >
                  <MDButton color="info" onClick={handleAddButton}>
                    ADD
                  </MDButton>
                </span>
              </div>
            </MDBox>
            <BarLoader
              color="#344767"
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Footer />

      <UomInfo
        uomPopup={uomPopup}
        setUomPopup={setUomPopup}
        data={selectedItem}
        getApiResponse={getApiResponse}
      />
    </DashboardLayout>
  );
};

export default CreateUom;
