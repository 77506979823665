

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getClinic, updateClinic } from "../service/Clinic";
import MDButton from "components/MDButton";

const Clinicedit = (props) => {
  const dispatch = useDispatch();
  const { title, selectedClinic, openpopup, setopenpopup } = props;

  // Validation schema using Yup (only for "Brand Name")
  const validationSchema = Yup.object({
    name: Yup.string().required(" Name is required"),
    
  });

  // Formik form setup
  const formik = useFormik({
    initialValues: {
     
      name: selectedClinic?.name || "",
      description: selectedClinic?.description || "",
    
    },
    validationSchema,
    onSubmit: async(values) => {
      
    // Create a copy of selectedmachine with updated values
      const updatedBrand = {
        ...selectedClinic,
       
       
        name: values.name,
        description: values.description,
       
      };
     

      // Dispatch the action to update the brand in the Redux store
      const response = await  dispatch(updateClinic(updatedBrand));

      // Close the dialog
     await dispatch(getClinic());
     
      setopenpopup(false);
    },
  });

  useEffect(() => {
    // Update the form values when selectedRole changes
    formik.setValues({
     
      name: selectedClinic?.name || "",
      description: selectedClinic?.description || "",
    
    });
  }, [selectedClinic]);

  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth >
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent fullWidth >
        <Grid item xs={12} lg={12}>
          <MDBox mt={1.625}>
            
               
             
            
                <MDBox mb={2} mt={1}>
                  <MDInput
                    type="text"
                    label=<span>Clinic Name<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={
                      formik.touched.name && formik.errors.name ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {formik.errors.name}
                        </p>
                      ) : null
                    }
                  />
                </MDBox>
            
           
            <MDBox mb={2} mt={1}>
              <MDInput
                type="text"
                label="Clinic Description"
                variant="standard"
                fullWidth
                id="description"
                name="description"
                value={formik.values.description}
                onChange={formik.handleChange}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
              />
            </MDBox>
          
          
            
          

          </MDBox>
          </Grid>
        </DialogContent>
        <DialogActions>
          <MDButton onClick={handleClose}>Cancel</MDButton>
          <MDButton type="submit" color="success" size="small">Update</MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Clinicedit;


