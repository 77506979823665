

import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Import form validation, schema, and initial values
import validations from "./components/schemas/validations";
import form from "./components/schemas/form";
import initialValues from "./components/schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import swal from "sweetalert";
import { APIs } from "Services/APIs";


 

import axios from "../../../axiosinstance";
import PatientInfo from "./components/PatientInfo";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { createUser } from "../UserMaster/service/User";


// Function to define form steps
function getSteps() {
  return ["Patient Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <PatientInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
function Patientform() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const {auth}=useSelector(({AuthReducer}) => AuthReducer);


  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
 
  
  async function otpData(code) {
    const otp = {
      createdby: "HEALTHCARE WEB UI",
      id: null,

      otptype: "EMAIL",
      recstatus: "OPEN",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",

      userid: code,
    };
    // setIsLoading(true);
    await axios
      .post(
      
        "otps-service/api/Otps/v1/create",
        otp
      )

      .then((response) => {
        navigate("/app/nurse/patient-list");

        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage,
          "warning"
        );
        // setIsLoading(false);
      });
  }
 

  const submitForm = async (values, actions) => {
    setIsLoading(true);
  
    
      const UserData = {
        age: values.age,
        sex: values.gender,
        emailid: values.email,
        name: values.lastName
          ? values.firstName + " " + values.lastName
          : values.firstName,
        id: null,
        isactive: "ACTIVE",

        rolename: "PATIENT",
      rolecode: "PA001",
      password: "123456",

        mobileno: values.phonenumber,
 recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
      
        createdby: auth.profileid,
      };


    

      await axios
      .post(
      `${APIs.loginServiceApi}/create`, UserData
      )

      .then((response) => {
        setIsLoading(false)
        swal(
          "Registration Successful!",
          "Patient created successfully.",
          "success"
        );
        navigate(`/app/nurse/patient-list`);
       
      }) 
      .catch((error) => {
        swal("Oops!", 
          (error?.response && error.response?.data && error?.response?.data?.aceErrors) ?
            (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
              "User Already Registered with the same Email id !!" :
              error?.response?.data?.aceErrors?.[0]?.errorMessage) :
            "Request failed with status code 404.",
          "warning"
        );
        setIsLoading(false)
      });
 
    
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <DashboardLayout>
      {/* <UpaarNavbar/> */}

      <MDBox py={3} mb={20} mt={8} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 18 }}
        >
          <Grid item xs={12} lg={6}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Create Patient
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Patientform;
