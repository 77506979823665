


import { useState,useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";


import validations from "../Patientsignup/schemas/validations";
import form from "../Patientsignup/schemas/form";
import initialValues from "../Patientsignup/schemas/initialValues";

;
import { useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance"

import { MoonLoader } from "react-spinners";



import Patientsignup from "components/Resuable/Patientsignup";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

function getSteps() {
  return ["Family Info"];
}


function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
    return <Patientsignup formData={formData} />;
    default:
      return null;
  }
}


function AdminPatientregistratin() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);


  // Function to submit the form


  async function otpData(code) {
    const otp = {
      createdby: "HEALTHCARE WEB UI",
      id: null,

      otptype: "EMAIL",
      recstatus: "OPEN",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",

      userid: code,
    };
    // setIsLoading(true);
    await axios
      .post(
        // 'http://194.163.159.164:9163/api/Otps/v1/create',
        // otp
        // 'http://194.163.159.164:9163/api/Otps/v1/create',otp
        "otps-service/api/Otps/v1/create",
        otp
      )

      .then((response) => {
        navigate("/app/page/admin-otp");

        // setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage,
          "warning"
        );
        // setIsLoading(false);
      });
  }
  const submitForm = async (values, actions) => {
    setIsLoading(true)
    console.log("Form values:", values);

    const formData = {
       
        approvedby: "",
      approveddate: "",
      approver: "",
      approvertype: "",
      createddate: null,
      age: values.age,
      sex: values.gender,
      documentofproof: "",
      emailid: values.email,
      name: values.lastName
        ? values.firstName + " " + values.lastName
        : values.firstName,
      id: null,
      isactive: "ACTIVE",
      isloggedin: "",
      latlong: "",
      rolename: "PATIENT",
      rolecode: "PA001",
      logincount: "",
       mobileno: values.mobilenumber,
      modifiedby: "",
      modifieddate: "",
      password: "123456",
      profileid: "",
      recstatus: "APPROVED",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",
      tempid: "",
      username: "",
      createdby: JSON.parse(localStorage.getItem("login-auth")).profileid,
        };
  axios
  .post('login-service-mongo/api/loginservice/v2/create',formData)
  .then((response) => {
    console.log("login", response?.data?.data?.dto);
        var code = response.data?.data?.dto.code;

        // createPatient(response?.data?.data?.dto);
        localStorage.setItem("user-code", JSON.stringify({ code: code }));

        otpData(code);
   
  })
  .catch((error) => {
    // ... (other code)
    console.log(error);
    setIsLoading(false)
  });
     
   
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    console.log("Form values:", values);

    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <UpaarNavbar />
    
   
    
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 15 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                         Patient Registration
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                         
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
   
    </DashboardLayout>
  );
}






export default AdminPatientregistratin;