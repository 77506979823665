import { Grid } from "@mui/material";
import React from "react";
import "./medharvaFooter.css";
import MedharvaLogo from "../assets/images/medharva-logo.png";
import Instagram from "../assets/images/instagram.svg";
import XLogo from "../assets/images/X Logo.svg";
import Facebook from "../assets/images/facebook.svg";
import Youtube from "../assets/images/youtube.svg";

const medharvaFooter = () => {
  const data = [
    { image: Instagram, width: 32, height: 32, navigate: "#" },
    { image: XLogo, width: 30, height: 29, navigate: "#" },
    { image: Facebook, width: 29, height: 29, navigate: "#" },
    { image: Youtube, width: 35, height: 35, navigate: "#" },
  ];

  const data2 = [
    {
      Medharva: [
        { title: "About", navigate: "#" },
        { title: "Blog", navigate: "#" },
        { title: "Careers", navigate: "#" },
        { title: "Press", navigate: "#" },
        { title: "Contact Us", navigate: "#" },
        { title: "Privacy Policy", navigate: "#" },
        { title: "Terms of Use", navigate: "#" },
      ],
    },
    {
      "Our Services": [
        { title: "Consultation", navigate: "#" },
        { title: "Lab tests", navigate: "#" },
        { title: "Health Packages", navigate: "#" },
        { title: "Scan & X-Rays", navigate: "#" },
        { title: "Long term core plans", navigate: "#" },
      ],
    },
    {
      "Health Tools": [
        { title: "Blood Pressure Monitor", navigate: "#" },
        { title: "SPO2 tracker", navigate: "#" },
        { title: "Heart Rate Monitor", navigate: "#" },
        { title: "Period Tracker", navigate: "#" },
        { title: "Self Checks", navigate: "#" },
      ],
    },
  ];
  return (
    <div className="medharvaFootercontainer">
      <Grid container mt={2} xs={12} ml={5}>
        <Grid item xs={12} mt={5} className="medharvaFooteritem">
          <img src={MedharvaLogo} alt="Logo" className="medharvaFooterimg" />
          <div className="medharvaFootertitle">Medharva</div>
        </Grid>
        <Grid item xs={3} className="medharvaFooteritem">
          {data.map((item, index) => (
            <a href={item.navigate} key={index} className="medharvaFooteranchor">
              <img
                src={item.image}
                key={index}
                alt="Logo"
                style={{ width: item.width, height: item.height }}
              />
            </a>
          ))}
        </Grid>
        {data2.map((item, index) =>
          Object.keys(item).map((key) => (
            <Grid
              item
              xs={3}
              className="medharvaFooteritem2"
              key={index}
              mb={index > 0 ? 4 : 0}
            >
              <div className="medharvaFooteritemheading">{key}</div>
              {item[key].map((subItem, subIndex) => (
                <a href={subItem.navigate} key={subIndex}>
                  <div className="medharvaFooteranchor2">{subItem.title}</div>
                </a>
              ))}
            </Grid>
          ))
        )}
      </Grid>
    </div>
  );
};

export default medharvaFooter;
