import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { ROLE_CODE } from "privateComponents/codes";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";

const CategoryEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopupcategory,
    setopenpopupcategory,
    handleClose,
    onUpdate,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [categoryList, setCategoryList] = useState([]);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [categoryname, setCategoryname] = useState(0);

  const { getUserCategory } = useSelector((state) => state);
  const [subcategoryresponse, setSubcategoryresponse] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const validationSchema = Yup.object({
    subcategory: Yup.string().required("Sub Category is required"),
    category: Yup.string().required("Category  is required"),
  });

  const initialValues = {
    subcategory: selectedUser?.category?.name || "",
    category: selectedUser?.category?.parenthierarchicalcode || "",
  };

  const onSubmit = async (values) => {
    setLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,

        category: subcategoryresponse
          ? subcategoryresponse
          : selectedUser?.category,

        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      const userCategoryIds =
        getUserCategory?.category?.map((i) => i?.category?.id) || [];
      const isSpecializationIdInCategory =
        userCategoryIds.includes(updatedUser?.category?.id) &&
        updatedUser?.category?.id != selectedUser?.category?.id;
      if (!isSpecializationIdInCategory) {
        await axios.put(`${APIs.userCatgoryServiceApi}/update`, updatedUser);
        handleClose();
        swal("Success!", "Category updated successfully.", "success");
        onUpdate(auth.rolecode === ROLE_CODE.admincode ? selectedUser?.profileid : auth?.profileid);
      } else {
        swal(
          "",
          "This category has already been registered to you.",
          "warning"
        );
      }
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoading1(false);
    }
  };
  async function getSubCategory(depth, hierarchicalcode) {
    if (hierarchicalcode === "") {
      setSubcategoryList([]);

      return;
    }
    setLoadings(true);
    try {
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/getWithHierarchy?depth=${depth}&hierarchicalcode=${hierarchicalcode}`
      );
      setSubcategoryList(response?.data?.data?.dtoList);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    } finally {
      setLoadings(false);
    }
  }

  async function getCategory() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/getWithHierarchy?depth=1`
      );
      setCategoryList(response?.data?.data?.dtoList);
      getSubCategory(
        2,
        selectedUser ? selectedUser?.category?.parenthierarchicalcode : ""
      );
    } catch (error) {
      console.error("Error fetching categories", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCategory();
    dispatch(userCategoryAction({ profileid: auth.rolecode === ROLE_CODE.admincode ? selectedUser?.profileid : auth?.profileid }));
  }, []);

  return (
    <DashboardLayout>
      <Dialog
        open={openpopupcategory}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, setFieldValue, handleChange, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="category"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                              shrink={Boolean(values.category)}
                            >
                              Category{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="category"
                              id="category"
                              value={values.category}
                              onChange={(e) => {
                                const selectedMode = e.target.value;
                                setFieldValue("category", selectedMode);

                                // Find the selected object from categoryList
                                const selectedObject = categoryList.find(
                                  (obj) => obj.hierarchicalcode === selectedMode
                                );

                                setCategoryname(selectedObject?.name);
                                getSubCategory(2, selectedMode);
                                setFieldValue("subcategory", "");
                              }}
                              error={
                                touched.category && Boolean(errors.category)
                              }
                            >
                              <option value=""></option>
                              {categoryList?.map((obj) => (
                                <option
                                  key={obj.id}
                                  value={obj.hierarchicalcode}
                                >
                                  {obj.name}
                                </option>
                              ))}
                            </Select>

                            <ErrorMessage
                              name="category"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              htmlFor="subcategory"
                              style={{
                                marginLeft: "-14px",
                                paddingBottom: "2px",
                                marginTop: "2px",
                              }}
                              shrink={Boolean(values.subcategory)}
                            >
                              Sub Category{" "}
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </InputLabel>
                            <Select
                              native
                              variant="standard"
                              style={{ width: "100%" }}
                              name="subcategory"
                              id="subcategory"
                              value={values.subcategory}
                              onChange={(e) => {
                                const selectedType = e.target.value;
                                setFieldValue("subcategory", selectedType);
                                const selectedObject = subcategoryList.find(
                                  (obj) => obj.name === selectedType
                                );

                                setSubcategoryresponse(selectedObject);
                              }}
                              error={
                                touched.subcategory &&
                                Boolean(errors.subcategory)
                              }
                            >
                              {loadings ? (
                                <option value="">loading...</option>
                              ) : (
                                <></>
                              )}
                              <option value=""></option>
                              {subcategoryList.map((obj) => (
                                <option key={obj.id} value={obj.name}>
                                  {obj.name}
                                </option>
                              ))}
                            </Select>
                            <ErrorMessage
                              name="subcategory"
                              component="div"
                              style={{ color: "red", fontSize: "12px" }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loading1 ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default CategoryEdit;
