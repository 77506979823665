import { useState } from "react";

import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";

import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

// OrderDetails page components

import MDTypography from "components/MDTypography";

import BillingInformation from "layouts/ecommerce/orders/order-details/components/BillingInformation";

import PaymentDetails from "components/Resuable/detailcomponet/components/PaymentDetails";

import OrderSummary from "components/Resuable/detailcomponet/components/OrderSummary";

import { Link, useLocation, useParams } from "react-router-dom";

import Table from "@mui/material/Table";

import TableRow from "@mui/material/TableRow";

import TableBody from "@mui/material/TableBody";

import Header from "./Header";

import OrderInfo from "./OrderInfo";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import PaymentDetail from "./PaymentDetail";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import MDButton from "components/MDButton";

function BookingView(props) {
  const { title, openpopup, setopenpopup, selecteddata } = props;
  console.log(selecteddata);
  const location = useLocation();

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };
  const handleClose = () => {
    setopenpopup(false);
  };

  return (
    // <DashboardLayout>
    // <UpaarNavbar/>

    <Dialog open={openpopup} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <MDBox pt={2} px={2}>
        <Header
          orderNo={selecteddata?.ordernumber}
          datetime={selecteddata?.slot?.startdate}
        />
      </MDBox>

      <Divider />

      <MDBox pt={1} pb={3} px={2}>
        <MDBox mb={3}>
          <OrderInfo
            doctorData={selecteddata?.doctor}
            price={selecteddata?.pricelist?.[0]?.value}
            ordernumber={selecteddata?.ordernumber}
            date={selecteddata?.slotdate}
            paymentmethod={selecteddata?.paymentmethod}
          />
        </MDBox>

        <Divider />

        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={6} >
              <PaymentDetail paymentmethod={selecteddata?.paymentmethod} />

              {/* <MDBox mt={3}>

                        <BillingInformation />

                      </MDBox> */}
            </Grid>

            {/* <Grid item xs={12} lg={3} sx={{ ml: "auto" }}>

                      <OrderSummary />

                    </Grid> */}
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MDBox p={3}>
                <MDBox width="100%" overflow="auto">
                  <Table sx={{ minWidth: "32rem" }}>
                    <MDBox component="thead">
                      <TableRow style={{ backgroundColor: "#039147" }}>
                        <MDBox
                          component="th"
                          width={{ xs: "45%", md: "50%" }}
                          py={1.5}
                          px={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Item
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Qty
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Rate
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="th"
                          py={1.5}
                          pl={3}
                          pr={1}
                          textAlign="left"
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="h6"
                            color="white"
                            fontWeight="medium"
                          >
                            Amount
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </MDBox>

                    <TableBody>
                      <TableRow>
                        <MDBox
                          component="td"
                          textAlign="left"
                          p={1}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {selecteddata?.pricelist?.[0]?.name}
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            1
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            {selecteddata?.pricelist?.[0]?.value}
                          </MDTypography>
                        </MDBox>

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography
                            variant="body2"
                            color="text"
                            fontWeight="regular"
                          >
                            ₹ {selecteddata?.pricelist?.[0]?.value}
                          </MDTypography>
                        </MDBox>
                      </TableRow>

                      {/* ))} */}

                      <TableRow>
                        <MDBox
                          component="td"
                          textAlign="left"
                          p={1}
                          sx={borderBottom}
                        />

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        />

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="h5">Total</MDTypography>
                        </MDBox>

                        <MDBox
                          component="td"
                          textAlign="left"
                          py={1}
                          pr={1}
                          pl={3}
                          sx={borderBottom}
                        >
                          <MDTypography variant="h5">
                            ₹ {selecteddata?.pricelist?.[0]?.value}{" "}
                          </MDTypography>
                        </MDBox>
                      </TableRow>
                    </TableBody>
                  </Table>
                </MDBox>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <DialogActions>
        <MDButton
          variant="gradient"
          color="success"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}

export default BookingView;
