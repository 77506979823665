const baseUrl = "https://sm.softwaremathematics.com";

export const APIs = {
  createProfile: "profile-service-mongo/api/ProfileEntity/v3/create",
  // getProfileList :"http://localhost:5001/api/ProfileEntity/v2/get?createdby=",
  getProfileList: `${baseUrl}/profile-service-mongo/api/ProfileEntity/v3/get?createdby=`,
  getProfileDetails: `${baseUrl}/profile-service-mongo/api/ProfileEntity/v2/getAggregated?profileid=`,
  updateProfile: `${baseUrl}/profile-service-mongo/api/ProfileEntity/v2/update`,
  getLoginUser: `${baseUrl}/login-service-mongo/api/loginservice/v2/get?`,
  loginServiceApi: `${baseUrl}/login-service-mongo/api/loginservice/v2`,
  deparmentServiceApi: `${baseUrl}/department-service-mongo/api/department/v2`,
  designationServiceApi: `${baseUrl}/designation-service-mongo/api/designation/v2`,
  visitServiceApi: `${baseUrl}/visit-service/api/visit/v1`,
  vitalsMasterServiceApi: `${baseUrl}/vitalsmaster-service/api/VitalsMaster/v1`,
  vitalsServiceApi: `${baseUrl}/vitals-service/api/Vitals/v1`,
  aggregationServiceApi: `${baseUrl}/aggregation-service/api/Aggregation/v1`,
  userCatgoryServiceApi: `${baseUrl}/usercategory-service/api/Usercategory/v1`,
  catgoryServiceApi: `${baseUrl}/category-service/api/Category/v1`,
  projectServiceApi: `${baseUrl}/project-service/api/Project/v1`,
  pincodeAssociationService: `${baseUrl}/project-service/api/ProjectPincode/v1`,
  projectTeamService: `${baseUrl}/project-service/api/ProjectTeam/v1`,
  projectServicePincodeApi: `${baseUrl}/project-service/api/ProjectTeam/v1`,
  roleService: `${baseUrl}/role-service-mongo/api/role/v2`,
  categoryService: `${baseUrl}/category-service/api/Category/v1`,
  personalInformationServiceApi: `${baseUrl}/personal-information-service-mongo/api/personalInformation/v2`,
  projectServiceApi: `${baseUrl}/project-service/api/Project/v1`,
  testServiceApi: `${baseUrl}/testmaster-service/api/testMaster/v1`,
  typeMasterServiceApi: `${baseUrl}/typemaster-service/api/TypeMaster/v1`,
  qualificationServiceApi: `${baseUrl}/qualification-service-mongo/api/qualification/v2`,
  priceServiceApi: `${baseUrl}/price-service/api/price/v1/`,
  summaryServiceApi: `${baseUrl}/summary-service-mongo/api/summary/v2`,
  itemmasterServiceApi: `${baseUrl}/itemmaster-service/api/itemMaster/v1`,
  frequencymasterServiceApi: `${baseUrl}/frequencymaster-service/api/frequencyMaster/v1`,
  diseaseServiceApi: `${baseUrl}/disease-service/api/Disease/v1`,
  testMasterServiceApi: `${baseUrl}/testmaster-service/api/testMaster/v1`,
  frequencyMasterServiceApi: `${baseUrl}/frequencymaster-service/api/frequencyMaster/v1`,
  bookingServiceApi: `${baseUrl}/booking-service/api/Booking/v1`,
  pincodeServiceApi: `${baseUrl}/pincode-service/api/pincode/v1`,
  addressServiceApi: `${baseUrl}/address-service-mongo/api/address/v2`,
  roomIdGenerationServiceApi: `${baseUrl}/room-id-generation-service/api/RoomIdGeneration/v1`,
  gstServiceApi: `${baseUrl}/gstmaster/api/GSTMaster/v2/`,
  otpServiceApiv1: `${baseUrl}/otps-service/api/Otps/v1`,
  otpServiceApiv2: `${baseUrl}/otps-service/api/Otps/v2`,
  newUploadServiceMongoApi: `${baseUrl}/new-upload-service-mongo/api/post`,
  dosesService: `${baseUrl}/doses-service/api/doses/v1`,
  labtestService: `${baseUrl}/labtests-serv/api/labTests/v1`,
  mmuCreationService: `${baseUrl}/mmucreation-service/api/MMUCreation/v1`,
  mmuAssociation: `${baseUrl}/project-service/api/ProjectMMU/v1`,
  mmuteamAssociation: `${baseUrl}/mmuassociation-service/api/MMUAssociation/v1`,
  eventServiceApi: `${baseUrl}/event-service/api/event/v1`,
  warehouseServiceApi: `${baseUrl}/warehousemaster-service/api/WareHouseMaster/v1`,
  medrequisitiongenServiceApi: `${baseUrl}/medrequisitiongen-service/api/MedRequisitionGen/v1`,
  stockServiceApi: `${baseUrl}/stock-service/api/Stock/v1`,
  bulkImportApi: `${baseUrl}/bulk-import/api/bulk_import/v1`,
  medrequisitiongenServiceApi: `${baseUrl}/medrequisitiongen-service/api/MedRequisitionGen/v1`,

};
