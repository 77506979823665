import { createSlice } from "@reduxjs/toolkit";
import { createProject, deleteProject, getProject, updateProject } from "../service/Project";





const initialState = {
  project: [],
  loading: false,
  error: null,
};

export const projectDetail = createSlice({
  name: "projectDetail",
  initialState,
  extraReducers: {
    [createProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createProject.fulfilled]: (state, action) => {
      console.log("state",state.project)
      state.loading = false;
      state.project.push(action.payload.data.dto);
    },
    [createProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getProject.fulfilled]: (state, action) => {
      state.loading = false;
      state.project = action.payload.data.dtoList;

    },
    [getProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteProject.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.project = state.project.filter((project) => project.id !== id);
      }
    },
    [deleteProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateProject.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateProject.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.project.findIndex(project => project.id === action.payload.data.dto.id)
      state.project[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateProject.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default projectDetail.reducer;
