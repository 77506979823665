import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {

    
   
   //puchase detail
  gender,
  firstName,
  age,
  email,
  mobilenumber,
  role,
  doctor,
  password,
  experience,
  description,
  repeatPassword
  
  },
} = checkout;

const validations = [
  
  
 

  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [experience.name]: Yup.string().required(experience.errorMsg),
   
     [description.name]: Yup.string().required(description.errorMsg),
    [password.name]: Yup.string()
    .required(password.errorMsg)
    .min(6, password.invalidMsg),
  [password.name]: Yup.string()
    .required(password.errorMsg)
    .min(6, password.invalidMsg),
  [repeatPassword.name]: Yup.string()
    .required(repeatPassword.errorMsg)
    .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
     [role.name]: Yup.string().required(role.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [doctor.name]: Yup.string().when(role.name, {
      is: "RE001", // Only apply the validation when purchasesource is "Online"
      then: Yup.string().required("Doctor is required"),
    }),
    [mobilenumber.name]: Yup.string()
    .required("Mobile number is required")
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    
    }),
 
  

   
     
      
         
  
  
  
];

export default validations;
   
      
  
   
   
