import React from "react";
import { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import * as Yup from "yup";
import {
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./uom.css"; // Import the CSS file
import axios from "../../../../axiosinstance";
import swal from "sweetalert";
import hand from "../../../../assets/images/hand.png"; //image
import smily from "../../../../assets/images/smily.png"; //image
import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { MoonLoader } from "react-spinners";

const UomInfo = (props) => {
  const { uomPopup, setUomPopup, data, getApiResponse } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [uomoptions, setUomOptions] = useState([]);
  const [categoryoptions, setCategoryoptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const prevData = useRef(null);
  const [initialValues, setInitialValues] = useState({
    name: "",
    displaytext: "",
    baseuom: "",
    conversionfactor: "",
    category: "",
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required."),
    displaytext: Yup.string().required("Display text is required."),
    category: Yup.string().required("Category is required."),
    conversionfactor: Yup.number().when("baseuom", {
      is: (value) => value && value.length > 0,
      then: Yup.number().required("Conversion factor is required."),
    }),
  });

  const handleClose = () => {
    setUomPopup(false);
  };

  const handleSubmit = async (values) => {
    setIsLoading(true);
    try {
      if (data) {
        const updatedData = {
          ...data,
          category: values.category,
          description: values.description,
          basename: values.baseuom,
          conversionfactor: values.conversionfactor,
          displaytext: values.displaytext,
          modifiedby: auth.name,
          name: values.name,
        };

        const response = await axios.put(
          `${APIs.typeMasterServiceApi}/update`,
          updatedData
        );
        setIsLoading(false);
        swal("", "UOM has been updated successfully.", "success");
        handleClose();
        getData();
      } else {
        const newData = {
          category: values.category,
          categorytype: "M_UOM_OPTIONS",

          createdby: auth?.name,

          description: values.description,
          basename: values.baseuom,
          conversionfactor: values.conversionfactor,
          displaytext: values.displaytext,

          name: values.name,

          recstatus: "OPEN",
          resourcecode: "",
          rolecode: "",
          status: "ACTIVE",
          syncstatus: "SYNCED",
        };

        const response = await axios.post(
          `${APIs.typeMasterServiceApi}/create`,
          newData
        );
        setIsLoading(false);

        // swal({
        //   content: {
        //     element: "img",
        //     attributes: {
        //       src: hand,
        //       width: 165,
        //       height: 150,
        //     },
        //   },
        //   title: "Create Successful",
        // });
        swal("", "UOM has been created successfully.", "success");
      }

      setUomPopup(false); // Close the dialog
      getApiResponse(); // Refresh the uom list
      getData();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (error.response?.data == null) {
        alert("Something went wrong!!");
      } else {
        alert(error.response?.data?.aceErrors?.[0].errorMessage);
      }
    }
  };

  useEffect(() => {
    // Update the initial values of the form when data prop changes (for editing)
    if (data && JSON.stringify(data) !== JSON.stringify(prevData.current)) {
      setInitialValues({
        name: data?.name,
        description: data?.description,
        displaytext: data.displaytext,
        baseuom: data?.basename,
        conversionfactor: data?.conversionfactor,
        category: data.category,
      });
    } else if (!data) {
      setInitialValues({
        // Reset the form values and data prop
        name: "",
        displaytext: "",
        baseuom: "",
        conversionfactor: "",
        category: "",
      });
    }

    prevData.current = data;
  }, [data]);

  // get dropdown list data
  const getData = async () => {
    try {
      const response = await axios.get(
        `${APIs.typeMasterServiceApi}/get?categorytype=M_UOM_OPTIONS`
      );
      setUomOptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
    try {
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/get?type=M_UOM`
      );

      setCategoryoptions(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Dialog open={uomPopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "white",
          textAlign: "center",
        }}
      >
        {data ? "Edit UOM" : "Create UOM"}
      </DialogTitle>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <MDBox p={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Name
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="name"
                        id="name"
                        value={values.name}
                        onChange={(e) => {
                          const name = e.target.value;
                          setFieldValue("name", name);
                        }}
                        variant="standard"
                      />
                      <ErrorMessage
                        name="name"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label="Description"
                        name="description"
                        id="description"
                        value={values.description}
                        onChange={(e) => {
                          const description = e.target.value;
                          setFieldValue("description", description);
                        }}
                        variant="standard"
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <TextField
                        label=<span>
                          Display Text
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            {" "}
                            *
                          </sup>
                        </span>
                        name="displaytext"
                        id="displaytext"
                        value={values.displaytext}
                        onChange={(e) => {
                          const displaytext = e.target.value;
                          setFieldValue("displaytext", displaytext);
                        }}
                        variant="standard"
                      />
                      <ErrorMessage
                        name="displaytext"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="baseuom"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.baseuom)}
                      >
                        Select Base UOM{" "}
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="baseuom"
                        name="baseuom"
                        value={values.baseuom}
                        onChange={(e) => {
                          const baseuom = e.target.value;

                          setFieldValue("baseuom", baseuom);
                        }}
                      >
                        <option value=""></option>
                        {uomoptions?.map((option) => (
                          <option key={option.id} value={option.name}>
                            {option.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  {values.baseuom && (
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <TextField
                          label=<span>
                            Conversion Factor
                            <sup
                              style={{
                                color: "red",
                                fontSize: "small",
                                fontWeight: "bolder",
                                position: "relative",
                                top: "2px",
                              }}
                            >
                              {" "}
                              *
                            </sup>
                          </span>
                          name="conversionfactor"
                          id="conversionfactor"
                          value={values.conversionfactor}
                          type="number"
                          onChange={(e) => {
                            const conversionfactor = e.target.value;
                            setFieldValue("conversionfactor", conversionfactor);
                          }}
                          variant="standard"
                        />
                        <ErrorMessage
                          name="conversionfactor"
                          component="div"
                          style={{ color: "red", fontSize: "12px" }}
                        />
                      </FormControl>
                    </Grid>
                  )}

                  <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                      <InputLabel
                        htmlFor="category"
                        style={{
                          marginLeft: "-14px",
                          paddingBottom: "2px",
                          marginTop: "2px",
                        }}
                        shrink={Boolean(values.category)}
                      >
                        Select Category{" "}
                        <sup
                          style={{
                            color: "red",
                            fontSize: "small",
                            fontWeight: "bolder",
                            position: "relative",
                            top: "2px",
                          }}
                        >
                          {" "}
                          *
                        </sup>
                      </InputLabel>
                      <Select
                        native
                        fullWidth
                        variant="standard"
                        style={{ width: "100%" }}
                        id="category"
                        name="category"
                        value={values.category}
                        onChange={(e) => {
                          const category = e.target.value;

                          setFieldValue("category", category);
                        }}
                        error={touched.category && Boolean(errors.category)}
                      >
                        <option value=""></option>
                        {categoryoptions.map((option) => (
                          <option key={option?.id} value={option?.name}>
                            {option?.name}
                          </option>
                        ))}
                      </Select>
                      <ErrorMessage
                        name="category"
                        component="div"
                        style={{ color: "red", fontSize: "12px" }}
                      />
                    </FormControl>
                  </Grid>

                  <Grid container spacing={3} mt={1} justifyContent="end">
                    {/* <Grid item>
                      <MDButton
                        variant="gradient"
                        color="info"
                        size="small"
                        onClick={handleClose}
                      >
                        Cancel
                      </MDButton>
                    </Grid> */}
                    <Grid item>
                      <MDButton
                        type="submit"
                        variant="gradient"
                        color="info"
                        size="small"
                      >
                        {isLoading ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : data ? (
                          "Update"
                        ) : (
                          "Submit"
                        )}
                      </MDButton>
                    </Grid>
                  </Grid>
                </Grid>
              </MDBox>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default UomInfo;
