import { createSlice } from "@reduxjs/toolkit";
import { createCategory, deleteCategory, getCategory, updateCategory } from "../service/Category";





const initialState = {
  category: [],
  loading: false,
  error: null,
};

export const categoryDetail = createSlice({
  name: "categoryDetail",
  initialState,
  extraReducers: {
  [createCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createCategory.fulfilled]: (state, action) => {
      console.log("state",state.category)
      state.loading = false;
      state.category.push(action.payload.data.dto);
    },
    [createCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getCategory.fulfilled]: (state, action) => {
      state.loading = false;
      state.category = action.payload.data.dtoList;

    },
    [getCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [deleteCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteCategory.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.category = state.category.filter((category) => category.id !== id);
      }
    },
    [deleteCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    [updateCategory.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [updateCategory.fulfilled]: (state, action) => {
      state.loading = false;
      
    
      const index = state.category.findIndex(category => category.id === action.payload.data.dto.id)
      state.category[index] = action.payload.data.dto;
    //   state.users.push(action.payload);
    },
    [updateCategory.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default categoryDetail.reducer;
