import * as Yup from "yup";
import checkout from "..//schemas/form";

const {
  formField: {
    firstName,
    // lastName,
    username,
    email,
    gender,
    password,
    role,
    repeatPassword,
    designationvalue,
    departmentvalue,
    rolecode,
    phonenumber,
    zip,
    twitter,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [designationvalue.name]: Yup.string().required(designationvalue.errorMsg),
    [rolecode.name]: Yup.string().required(rolecode.errorMsg),
    [departmentvalue.name]: Yup.string().required(departmentvalue.errorMsg),
    // [lastName.name]: Yup.string().required(lastName.errorMsg),
    [username.name]: Yup.string().required(username.errorMsg),
    // [role.name]: Yup.string().required(role.errorMsg),
    [password.name]: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    [repeatPassword.name]: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
  }),
  Yup.object().shape({
    [phonenumber.name]: Yup.string()
      .required(phonenumber.errorMsg)
      .min(10, phonenumber.invalidMsg)
      .max(10, phonenumber.invalidMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
  }),
];

export default validations;
