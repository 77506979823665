
import Analytics from "layouts/dashboards/analytics";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import SignInBasic from "layouts/authentication/sign-in/basic";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";
// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import ProfileSummary from "layouts/pages/Profilesummary";
import FindDoctor from "app/findDoctor/FindDoctor";

const routesCollectiveAgent = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth"))?.name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/app/collectiveagent/profile",
        component: <ProfileOverview />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/app/home/find-doctors",
        component: <FindDoctor />,
      },
    
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },

  {
    type: "item",
    name: "Booking List",
    key: "booking-list",
    route: "/app/collectiveagent/booking-list",
  },
  {
    type:"item",
    name:"Search Patient",
    key:"searchpatient",
    route:"/app/collectiveagent/search-patient"
  },






];


export default routesCollectiveAgent;

