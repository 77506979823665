import * as Yup from "yup";
import checkout from "./form";
const {
  formField:{ typeName, typeDescrption,Category,Categorytype},
} = checkout;

const validations = [
  Yup.object().shape({
    [typeName.name]: Yup.string().required(typeName.errorMsg),
    [typeDescrption.name]: Yup.string().required(typeDescrption.errorMsg),
    [Category.name]: Yup.string().required(Category.errorMsg),
    [Categorytype.name]: Yup.string().required(Categorytype.errorMsg),
 
  }),
];

export default validations;
