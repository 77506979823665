import swal from "sweetalert";

export const tableAlreadyExistData = (data, matchingValue) => {
  const isDuplicate = data?.find((i) => i?.projectteams?.id === matchingValue);

  if (isDuplicate) {
    return swal({
      icon: "warning",
      title: "Duplicate Profile",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
              <p style="text-align: center;">
                A profile with this name already exists. Please choose a different profile.
              </p>
            `,
        },
      },
    }).then(() => true);
  }

  return false;
};
