// import { useState, useEffect } from "react";

// // Import form-related components and dependencies
// import { Formik, Form } from "formik";
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import MDTypography from "components/MDTypography";
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// // Import form validation, schema, and initial values
// import validations from "../schemas/validations";
// import form from "../schemas/form";
// import initialValues from "../schemas/initialValues";
// import { useNavigate } from "react-router-dom";

// // Import loader component and Redux-related dependencies
// import { MoonLoader } from "react-spinners";
// import { useDispatch } from "react-redux";

// import { useSelector } from "react-redux";
// import swal from "sweetalert";

// import RegistrationInfo from "./RegistrationInfo";
// import { createUser } from "components/Resuable/UserMaster/service/User";
// import { createOtp } from "../service/Otp";
// import axios from "../../../../../axiosinstance";

// // Function to define form steps
// function getSteps() {
//   return ["Registration Info"];
// }

// // Function to get content for each form step
// function getStepContent(stepIndex, formData) {
//   switch (stepIndex) {
//     case 0:
//       return <RegistrationInfo formData={formData} />;
//     default:
//       return null;
//   }
// }

// // Create the CreateBrand functional component
// function CreateUser() {
//   const navigate = useNavigate();
//   const [activeStep, setActiveStep] = useState(0);
//   const [isLoading, setIsLoading] = useState(false); // Loader state
//   const steps = getSteps();
//   const { formId, formField } = form;
//   const currentValidation = validations[activeStep];
//   const isLastStep = activeStep === steps.length - 1;
//   const { user, loading, error } = useSelector((state) => state.user);

//   // Function to simulate a delay
//   const sleep = (ms) =>
//     new Promise((resolve) => {
//       setTimeout(resolve, ms);
//     });

//   // Function to handle going back to the previous step
//   const handleBack = () => setActiveStep(activeStep - 1);
//   const dispatch = useDispatch();

//   // Function to submit the form
//   async function getInvestmentprofile(
//     referalcode,
//     profileEntityObject,
//     investmentProfileObject,
//     bankObj,
//     actions
//   ) {
//     await axios
//       .get(
//         `/investmentprofile-service/api/investmentprofile/v1/get?referalcode=${referalcode}`
//       )

//       .then((response) => {
//         if (response.data.data.dtoList.length == 0) {
//           setIsLoading(false);
//           swal("Alert!", "...Invalid Referal Code!");
//         } else {
//           investmentProfileObject.hierarchicalcode =
//             response?.data?.data?.dtoList[0]?.hierarchicalcode;
//           createprofileentity(
//             profileEntityObject,
//             investmentProfileObject,
//             bankObj,
//             actions
//           );

//           swal("Good job!", "your registration has been done!", "success");
//         }
//       })
//       .catch((error) => {
//         swal("Oops!", "...Something Went Wrong!");
//       });
//   }

//   async function createInvestmentprofile(object) {
//     await axios
//       .post(
//         "/investmentprofile-service/api/investmentprofile/v1/create",
//         // 'http://localhost:6012/api/investmentprofile/v1/create',
//         object
//       )

//       .then((response) => {
//         console.log("gfgfgf", response);
//       })
//       .catch((error) => {
//         setIsLoading(false);
//         console.log(error);
//       });
//   }

//   function addBankAccount(object, actions) {
//     axios
//       .post(
//         `/bankaccounts1-service/api/Bankaccounts1/v1/create`,
//         // `http://localhost:6001/api/Bankaccounts1/v1/create`,
//         object
//       )
//       .then((response) => {
//         console.log("data", response.data.data);
//         actions.resetForm();
//         // getData(profile?.profileid);
//       })
//       .catch((e) => {
//         actions.resetForm();
//         setIsLoading(false);
//         console.log("error", e);
//       });
//   }
//   async function createprofileentity(
//     object,
//     investmentProfileObject,
//     bankObj,
//     actions
//   ) {
//     const response = await dispatch(createUser(object));

//     if (!response.error && !loading) {
//       investmentProfileObject.profileid =
//         response?.payload?.data?.dto?.profileid;
//       bankObj.profileid = response?.payload?.data?.dto?.profileid;
//       createInvestmentprofile(investmentProfileObject);
//       addBankAccount(bankObj, actions);

//       setIsLoading(false);
//     } else {
//       swal({
//         icon: "error",
//         title: "Error",

//         text: error ? error?.message : "Something went wrong",
//       });
//       setIsLoading(false);
//     }
//   }

//   const submitForm = async (values, actions) => {
//     setIsLoading(true);

//     if (values?.rolecode === "PA001" || values?.rolecode === "PH257" || values?.rolecode ==="MI8236") {
//       const UserData = {
//         age: values.age,
//         sex: values.gender,

//         emailid: values.email,
//         name: values.lastName
//           ? values.firstName + " " + values.lastName
//           : values.firstName,
//         id: null,
//         isactive: values?.rolecode === "PH257" ? "OPEN" : "INACTIVE",
//         rolename: values.roleName,
//         rolecode: values.rolecode,

//         mobileno: values.phonenumber,
//         password: values.password,
//         profileid: "",
//         recstatus: "APPROVED",
//         resourcecode: "HEALTHCARE",
//         status: "ACTIVE",
//         syncstatus: "synced",
//         tempid: "",
//         username: "",
//         createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
//       };

//       const response = await dispatch(createUser(UserData));

//       if (!response.error && !loading) {
//         actions.resetForm();
//         setIsLoading(false);
//       } else {
//         actions.resetForm();
//         swal({
//           icon: "error",
//           title: "Error",

//           text: error ? error?.message : "Something went wrong",
//         });
//         setIsLoading(false);
//       }
//     } else if (values?.rolecode === "RE001" || values?.rolecode === "DO001") {
//       const UserData = {
//         age: values.age,
//         sex: values.gender,
//         documentofproof: "",
//         emailid: values.email,
//         name: values.lastName
//           ? (values.rolecode === "DO001" ? "Dr. " : "") +
//             values.firstName.replace(/^(Dr|DR)\.?/i, "").trim() +
//             " " +
//             values.lastName
//           : (values.rolecode === "DO001" ? "Dr. " : "") +
//             values.firstName.replace(/^(Dr|DR)\.?/i, "").trim(),

//         isactive: values?.rolecode === "RE001" ? "INACTIVE" : "OPEN",
//         description: values.description,

//         emailid: values.email,
//         rolename: values.roleName,
//         rolecode: values.rolecode,
//         description: values.description,
//         experience: values.experience,
//         departmentcode: values.department.code,
//         departmentname: values.department.name,
//         designationcode: values.designation.code,
//         designationname: values.designation.name,

//         mobileno: values.phonenumber,
//         modifiedby: "",
//         modifieddate: "",
//         password: values.password,
//         profileid: "string",
//         recstatus: "APPROVED",
//         resourcecode: "HEALTHCARE",
//         status: "ACTIVE",
//         syncstatus: "synced",

//         createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
//       };

//       const response = await dispatch(createUser(UserData));

//       if (!response.error && !loading) {
//         actions.resetForm();

//         swal("Good job!", "User has been succesfully created!", "success");
//         setIsLoading(false);
//       } else {
//         actions.resetForm();
//         swal({
//           icon: "error",
//           title: "Error",

//           text: error ? error?.message : "Something went wrong",
//         });
//         setIsLoading(false);
//       }
//     } else if (values?.rolecode === "AG2773") {
//       const profileEntityObject = {
//         age: values.age,
//         sex: values.gender,
//         documentofproof: "",
//         emailid: values.email,
//         name: values.lastName
//           ? values.firstName + " " + values.lastName
//           : values.firstName,

//         isactive: "INACTIVE",
//         description: values.description,

//         emailid: values.email,
//         rolename: values.roleName,
//         rolecode: values.rolecode,
//         description: values.description,
//         experience: values.experience,
//         departmentcode: values.department.code,
//         departmentname: values.department.name,
//         designationcode: values.designation.code,
//         designationname: values.designation.name,

//         mobileno: values.phonenumber,
//         modifiedby: "",
//         modifieddate: "",
//         password: values.password,
//         profileid: "string",
//         recstatus: "APPROVED",
//         resourcecode: "HEALTHCARE",
//         status: "ACTIVE",
//         syncstatus: "synced",
//       };
//       const investmentprofileObeject = {
//         age: values.age,
//         approvedby: null,
//         approveddate: null,
//         approver: "string",
//         approvertype: "string",
//         closingbalance: 0,
//         createdby: "string",
//         createddate: "",
//         currency: 0,
//         documentnumber: values.documentnumber,
//         documentofproof: " ",
//         documenttype: values.documenttype,
//         hierarchicalcode: "",
//         id: null,
//         latlong: "",
//         location: null,
//         modifiedby: "",
//         modifieddate: "",
//         name: values.lastName
//           ? values.firstName + " " + values.lastName
//           : values.firstName,
//         openingbalance: 0,
//         profileid: "",
//         recstatus: "APPROVED",
//         referalcode: values.referalcode,
//         status: "Active",
//         syncstatus: "SYNCED",
//         bankdetail: {
//           adhar: values.adhar,
//           pan: values.pan,
//           bankname: values.bankname,
//           ifsc: values.ifsccode,
//           accountno: values.accountnumber,
//           holdername: values.accountholdername,
//         },
//         tempid: "",
//       };
//       const bankObj = {
//         bankname: values.bankname,

//         ifsc: values.ifsccode,

//         accountno: values.accountnumber,

//         holdername: values.accountholdername,

//         profileid: "",

//         recstatus: "PRIMARY",

//         status: "Active",

//         syncstatus: "SYNCED",
//       };

//       getInvestmentprofile(
//         values.referalcode,
//         profileEntityObject,
//         investmentprofileObeject,
//         bankObj,
//         actions
//       );
//     }
//   };

//   // Function to handle form submission
//   const handleSubmit = (values, actions) => {
//     if (isLastStep) {
//       // If it's the last step, submit the form
//       submitForm(values, actions);
//     } else {
//       // If not the last step, move to the next step
//       setActiveStep(activeStep + 1);
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   };

//   // Render the component
//   return (
//     <DashboardLayout>
 

//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%", mt: 8 }}
//         >
//           <Grid item xs={12} lg={8}>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={currentValidation}
//               onSubmit={handleSubmit}
//             >
//               {({ values, errors, touched, isSubmitting }) => (
//                 <Form id={formId} autoComplete="off">
//                   <Card sx={{ height: "100%" }}>
//                     <MDBox mx={2} mt={-3}>
//                       <MDBox
//                         variant="gradient"
//                         bgColor="info"
//                         borderRadius="lg"
//                         coloredShadow="success"
//                         mx={2}
//                         mt={-3}
//                         p={2}
//                         mb={-2}
//                         textAlign="center"
//                       >
//                         <MDTypography
//                           variant="h4"
//                           fontWeight="small"
//                           color="white"
//                           mt={1}
//                         >
//                           New User 
//                         </MDTypography>
//                       </MDBox>
//                     </MDBox>
//                     <MDBox p={3}>
//                       <MDBox>
//                         {getStepContent(activeStep, {
//                           values,
//                           touched,
//                           formField,
//                           errors,
//                         })}
//                         <MDBox
//                           mt={5}
//                           width="100%"
//                           display="flex"
//                           justifyContent="space-between"
//                         >
//                           {activeStep === 0 ? (
//                             <MDBox />
//                           ) : (
//                             <MDButton
//                               variant="gradient"
//                               color="light"
//                               onClick={handleBack}
//                             >
//                               Back
//                             </MDButton>
//                           )}
//                           <MDButton
//                             type="submit"
//                             variant="gradient"
//                             color="dark"
//                             disabled={isLoading}
//                           >
//                             {loading ? (
//                               <MoonLoader color="#f2fefa" size={16} />
//                             ) : (
//                               "Submit"
//                             )}
//                           </MDButton>
//                         </MDBox>
//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Form>
//               )}
//             </Formik>
//           </Grid>
//         </Grid>
//       </MDBox>
//     </DashboardLayout>
//   );
// }

// export default CreateUser;

import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Import form validation, schema, and initial values
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import swal from "sweetalert";

import RegistrationInfo from "./RegistrationInfo";
import { createUser } from "components/Resuable/UserMaster/service/User";
import { createOtp } from "../service/Otp";
import axios from "../../../../../axiosinstance";

// Function to define form steps
function getSteps() {
  return ["Registration Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <RegistrationInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
function CreateUser() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const { user, loading, error } = useSelector((state) => state.user);

  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  async function getInvestmentprofile(
    referalcode,
    profileEntityObject,
    investmentProfileObject,
    bankObj,
    actions
  ) {
    await axios
      .get(
        `/investmentprofile-service/api/investmentprofile/v1/get?referalcode=${referalcode}`
      )

      .then((response) => {
        if (response.data.data.dtoList.length == 0) {
          setIsLoading(false);
          swal("Alert!", "...Invalid Referal Code!");
        } else {
          investmentProfileObject.hierarchicalcode =
            response?.data?.data?.dtoList[0]?.hierarchicalcode;
          createprofileentity(
            profileEntityObject,
            investmentProfileObject,
            bankObj,
            actions
          );

          swal("Good job!", "your registration has been done!", "success");
        }
      })
      .catch((error) => {
        swal("Oops!", "...Something Went Wrong!");
      });
  }

  async function createInvestmentprofile(object) {
    await axios
      .post(
        "/investmentprofile-service/api/investmentprofile/v1/create",
        // 'http://localhost:6012/api/investmentprofile/v1/create',
        object
      )

      .then((response) => {
        console.log("gfgfgf", response);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  }

  function addBankAccount(object, actions) {
    axios
      .post(
        `/bankaccounts1-service/api/Bankaccounts1/v1/create`,
        // `http://localhost:6001/api/Bankaccounts1/v1/create`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        actions.resetForm();
        // getData(profile?.profileid);
      })
      .catch((e) => {
        actions.resetForm();
        setIsLoading(false);
        console.log("error", e);
      });
  } 
  async function createprofileentity(
    object,
    investmentProfileObject,
    bankObj,
    actions
  ) {
    const response = await dispatch(createUser(object));

    if (!response.error && !loading) {
      investmentProfileObject.profileid =
        response?.payload?.data?.dto?.profileid;
      bankObj.profileid = response?.payload?.data?.dto?.profileid;
      createInvestmentprofile(investmentProfileObject);
      addBankAccount(bankObj, actions);

      setIsLoading(false);
    } else {
      swal({
        icon: "error",
        title: "Error",

        text: error ? error?.message : "Something went wrong.",
      });
      setIsLoading(false);
    }
  }

  const submitForm = async (values, actions) => {
    setIsLoading(true);

    if (values?.rolecode === "PA001" || values?.rolecode === "PH257" ) {
      const UserData = {
        age: values.age,
        sex: values.gender,

        emailid: values.email,
        name: values.lastName
          ? values.firstName + " " + values.lastName
          : values.firstName,
        id: null,
        isactive: values?.rolecode === "PH257" ? "OPEN" : "INACTIVE",
        rolename: values.roleName,
        rolecode: values.rolecode,

        mobileno: values.phonenumber,
        password: values.password,
        profileid: "",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
        tempid: "",
        username: "",
        createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
      };

      const response = await dispatch(createUser(UserData));
   
      if (!response.error && !loading) {
        actions.resetForm();
        navigate("/app/admin/approval-list");
        swal("Good job!", "User has been succesfully created!", "success");
        setIsLoading(false);
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",

          text: error ? error?.message : "Something went wrong.",
        });
        setIsLoading(false);
      }
    } else if (values?.rolecode ==="MI8236") {
      const UserData = {
        age: values.age,
        sex: values.gender,

        emailid: values.email,
        name: values.lastName
          ? values.firstName + " " + values.lastName
          : values.firstName,
        id: null,
        isactive: values?.rolecode === "PH257" ? "OPEN" : "INACTIVE",
        rolename: values.roleName,
        rolecode: values.rolecode,
        departmentcode: values.department.code,
        departmentname: values.department.name,
        designationcode: values.designation.code,
        designationname: values.designation.name,
        mobileno: values.phonenumber,
        password: values.password,
        profileid: "",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
        tempid: "",
        username: "",
        createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
      };

      const response = await dispatch(createUser(UserData));

      if (!response.error && !loading) {
        actions.resetForm();
        navigate("/app/admin/approval-list");
        swal("Good job!", "User has been succesfully created!", "success");
        setIsLoading(false);
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",

          text: error ? error?.message : "Something went wrong.",
        });
        setIsLoading(false);
      }
    }  else if (values?.rolecode === "RE001" || values?.rolecode === "DO001") {
      const UserData = {
        age: values.age,
        sex: values.gender,
        documentofproof: "",
        emailid: values.email,
        name: values.lastName
          ? (values.rolecode === "DO001" ? "Dr. " : "") +
            values.firstName.replace(/^(Dr|DR)\.?/i, "").trim() +
            " " +
            values.lastName
          : (values.rolecode === "DO001" ? "Dr. " : "") +
            values.firstName.replace(/^(Dr|DR)\.?/i, "").trim(),

        isactive: values?.rolecode === "RE001" ? "INACTIVE" : "OPEN",
        description: values.description,

        emailid: values.email,
        rolename: values.roleName,
        rolecode: values.rolecode,
        description: values.description,
        experience: values.experience,
        departmentcode: values.department.code,
        departmentname: values.department.name,
        designationcode: values.designation.code,
        designationname: values.designation.name,

        mobileno: values.phonenumber,
        modifiedby: "",
        modifieddate: "",
        password: values.password,
        profileid: "string",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",

        createdby: JSON.parse(localStorage.getItem("login-auth"))?.profileid,
      };

      const response = await dispatch(createUser(UserData));

      if (!response.error && !loading) {
        actions.resetForm();
        navigate("/app/admin/approval-list");
        swal("Good job!", "User has been succesfully created!", "success");
        setIsLoading(false);
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",
          text: error ? error?.message : "Something went wrong.",
        });
        setIsLoading(false);
      }
    } else if (values?.rolecode === "AG2773") {
      const profileEntityObject = {
        age: values.age,
        sex: values.gender,
        documentofproof: "",
        emailid: values.email,
        name: values.lastName
          ? values.firstName + " " + values.lastName
          : values.firstName,

        isactive: "INACTIVE",
        description: values.description,

        emailid: values.email,
        rolename: values.roleName,
        rolecode: values.rolecode,
        description: values.description,
        experience: values.experience,
        departmentcode: values.department.code,
        departmentname: values.department.name,
        designationcode: values.designation.code,
        designationname: values.designation.name,

        mobileno: values.phonenumber,
        modifiedby: "",
        modifieddate: "",
        password: values.password,
        profileid: "string",
        recstatus: "APPROVED",
        resourcecode: "HEALTHCARE",
        status: "ACTIVE",
        syncstatus: "synced",
      };
      const investmentprofileObeject = {
        age: values.age,
        approvedby: null,
        approveddate: null,
        approver: "string",
        approvertype: "string",
        closingbalance: 0,
        createdby: "string",
        createddate: "",
        currency: 0,
        documentnumber: values.documentnumber,
        documentofproof: " ",
        documenttype: values.documenttype,
        hierarchicalcode: "",
        id: null,
        latlong: "",
        location: null,
        modifiedby: "",
        modifieddate: "",
        name: values.lastName
          ? values.firstName + " " + values.lastName
          : values.firstName,
        openingbalance: 0,
        profileid: "",
        recstatus: "APPROVED",
        referalcode: values.referalcode,
        status: "Active",
        syncstatus: "SYNCED",
        bankdetail: {
          adhar: values.adhar,
          pan: values.pan,
          bankname: values.bankname,
          ifsc: values.ifsccode,
          accountno: values.accountnumber,
          holdername: values.accountholdername,
        },
        tempid: "",
      };
      const bankObj = {
        bankname: values.bankname,

        ifsc: values.ifsccode,

        accountno: values.accountnumber,

        holdername: values.accountholdername,

        profileid: "",

        recstatus: "PRIMARY",

        status: "Active",

        syncstatus: "SYNCED",
      };

      getInvestmentprofile(
        values.referalcode,
        profileEntityObject,
        investmentprofileObeject,
        bankObj,
        actions
      );
    }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <DashboardLayout>
 

      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          New User 
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            disabled={isLoading}
                          >
                            {loading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default CreateUser;

