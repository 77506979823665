// import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import MDInput from "components/MDInput";
// import MDBox from "components/MDBox";
// import Grid from "@mui/material/Grid";
// import MDTypography from "components/MDTypography";
// import {
//   FormControl,
//   FormControlLabel,
//   FormHelperText,
//   InputLabel,
//   MenuItem,
//   Switch,s
// } from "@mui/material";
// import Select from "@mui/material/Select";
// import { MoonLoader } from "react-spinners";
// import { useDispatch } from "react-redux";
// import { useSelector } from "react-redux";
// import { useFormik, useFormikContext } from "formik";
// import axios from "../../../.././axiosinstance";
// import { getProject } from "components/Resuable/Create Project/service/Projectcreate";

// import * as Yup from "yup";
// import { getProjectTeam, updateProjectTeam } from "../service/ProjectTeam";
// import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
// import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
// import { getUser } from "components/Resuable/UserMaster/service/User";

// import MDButton from "components/MDButton";
// import swal from "sweetalert";

// const ProjectTeamedit = (props) => {
//   const { title, selectedProjectTeam, openpopup, setopenpopup } = props;

//   const [color] = useState("#344767");
//   // const { setFieldValue } = useFormikContext();
//   const { project, loading1 } = useSelector((state) => state.project);
//   const { department, loading2 } = useSelector((state) => state.department);
//   const { designation, loading3 } = useSelector((state) => state.designation);
//   const { user, loading4 } = useSelector((state) => state.user);
//   const dispatch = useDispatch();
//   const [designationList, setDesignationList] = useState([]);
//   const [userList, setUserList] = useState([]);

//   const {error,loading} = useSelector((state) => state.projectTeam);

//   useEffect(() => {
//     dispatch(getProject());
//     dispatch(getDepartment());
//     dispatch(getDesignation());
//     dispatch(getUser());
//   }, [dispatch]);

//   // Validation schema using Yup (only for "Brand Name")
//   const validationSchema = Yup.object({
//     projectname: Yup.string().required("Project Team Name is required"),
//     designationname: Yup.string().required("Designation Name is required"),
//     profilename: Yup.string().required("Profile Name is required"),
//   });

//   console.log("selected",selectedProjectTeam);

//   async function getProfileDataByDeptCode(departmentcode) {
//     await axios
//       .get(
//         `login-service-mongo/api/loginservice/v2/get?departmentcode=${departmentcode}`
//       )
//       .then((response) => {
//         setUserList(response.data.data.dtoList);
//         // setDesignationList(response.data.data.dtoList);
//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   async function getprofileData(designationcode) {

//     await axios
//       .get(
//         `login-service-mongo/api/loginservice/v2/get?designationcode=${designationcode}`
//       )
//       .then((response) => {
//         setUserList(response.data.data.dtoList);

//         setLoading(false);
//       })
//       .catch((e) => {
//         console.log("error", e);
//       });
//   }
//   function getuserData(descode,depcode){
//     console.log(descode,depcode)
//     const userResult = user.filter(
//       (userItem) => userItem.departmentcode === depcode && userItem.designationcode === descode
//     );
//     setUserList(userResult);
//     console.log("userlist",userResult);
//   }

//   function getDesignationData(code){
//     const result = designation.filter(
//       (designationItem) => designationItem.deptcode === code
//     );
//     setDesignationList(result);
//   }
//   // Formik form setup
//   const formik = useFormik({
//     initialValues: {
//       projectname: selectedProjectTeam?.projectcode || "",
//       departmentname: selectedProjectTeam?.projectteams.login.departmentcode || "",
//       designationname: selectedProjectTeam?.projectteams.login.designationcode || "",
//       profilename: selectedProjectTeam?.projectteams.login.id || "",
//       projectnamevalue: project.find((obj) => obj?.code === selectedProjectTeam?.projectcode)?.name,
//       profilenameobject: user.find((obj) => obj?.departmentcode === selectedProjectTeam?.projectteams.login.departmentcode)
//     },
//     validationSchema,

//     onSubmit: async(values) => {

//       console.log("values",values);
//       const updatedProject = {
//         ...selectedProjectTeam,
//         projectcode: formik?.values?.projectname,
//         projectname: formik?.values?.projectnamevalue,
//         projectteams: { login: formik?.values?.profilenameobject },
//         syncstatus: "SYNCED",
//       };
//       const response = await dispatch(updateProjectTeam(updatedProject));
//       if (!response.error && !loading) {
//         handleClose()

//        }
//         else {
//         actions.resetForm();
//         swal({
//           icon: "error",
//           title: "Error",
//           text: error?.message,
//         });

//       }

//       setopenpopup(false);

//     },
//   });

//   useEffect(() => {
//     if (selectedProjectTeam) {
//       formik.setValues({
//         projectname: selectedProjectTeam?.projectcode || "",
//         departmentname: selectedProjectTeam?.projectteams.login.departmentcode || "",
//         designationname: selectedProjectTeam?.projectteams.login.designationcode || "",
//         profilename: selectedProjectTeam?.projectteams.login.id || "",
//       });
//       const setFormValues = async () => {
//         await dispatch(getDesignation(selectedProjectTeam?.projectteams.login.departmentcode));

//         // await dispatch(getProfile({
//         //   departmentvalue: selectedProjectTeam?.projectteams.login.departmentcode,
//         //   designationvalue: selectedProjectTeam?.projectteams.login.designationcode,
//         // }));

//         formik.setValues((values) => ({
//           ...values,
//           designationname: selectedProjectTeam?.projectteams.login.designationcode || "",
//           profilename: selectedProjectTeam?.projectteams.login.id || "",
//           projectnamevalue: project.find((obj) => obj?.code === selectedProjectTeam?.projectcode)?.name,
//           profilenameobject: user.find((obj) => obj?.departmentcode === selectedProjectTeam?.projectteams.login.departmentcode)
//         }));
//       };
//       getDesignationData(selectedProjectTeam?.projectteams.login.departmentcode);
//       getuserData(selectedProjectTeam?.projectteams.login.designationcode,selectedProjectTeam?.projectteams.login.departmentcode);

//       setFormValues();
//     }
//   }, [selectedProjectTeam]);

//   const handleClose = () => {
//     setopenpopup(false);
//     formik.setValues({
//       projectname: selectedProjectTeam?.projectcode || "",
//       departmentname: selectedProjectTeam?.projectteams.login.departmentcode || "",
//       designationname: selectedProjectTeam?.projectteams.login.designationcode || "",
//       profilename: selectedProjectTeam?.projectteams.login.id || "",
//       projectnamevalue: project.find((obj) => obj?.code === selectedProjectTeam?.projectcode)?.name,
//       profilenameobject: user.find((obj) => obj?.departmentcode === selectedProjectTeam?.projectteams.login.departmentcode)
//     });
//     getDesignationData(selectedProjectTeam?.projectteams.login.departmentcode);
//     getuserData(selectedProjectTeam?.projectteams.login.designationcode,selectedProjectTeam?.projectteams.login.departmentcode);
//   };

//   return (
//     <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth='md'>
//       <DialogTitle
//         style={{
//           backgroundColor: "blue",
//           color: "#fff",
//           textAlign: "center",
//         }}
//       >
//         {title}
//       </DialogTitle>
//       <form onSubmit={formik.handleSubmit}>
//         <DialogContent>
//         <Grid container spacing={3} mt={1}>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel
//                   htmlFor='projectname'
//                   style={{
//                     marginLeft: "-14px",
//                     paddingBottom: "2px",
//                     marginTop: "2px",
//                   }}
//                 >
//                   Project Name
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       marginLeft: "4px",
//                     }}
//                   >
//                     *
//                   </sup>
//                 </InputLabel>
//                 <Select
//                   native
//                   variant="standard"
//                   style={{ width: "100%" }}
//                   name='projectname'
//                   id='projectname'
//                   value={formik.values.projectname}
//                   onChange={(e) => {
//                     const projectvalue = e.target.value;
//                     const projectnamevalue =
//                       project.find((obj) => obj?.code === projectvalue) ||
//                       {};
//                     formik.setFieldValue("projectnamevalue",projectnamevalue?.name);
//                     formik.setFieldValue("projectname", projectvalue);
//                   }}
//                 >
//                   <option value=""></option>
//                   {project.map((obj) => (
//                     <option key={obj?.id} value={obj?.code}>
//                       {obj?.name}
//                     </option>
//                   ))}
//                 </Select>
//                 {formik.errors.projectname && formik.touched.projectname && (
//                   <FormHelperText
//                     error
//                     style={{ marginLeft: "0px", fontWeight: 400, marginTop: 5 }}
//                   >
//                     {formik.errors.projectname}
//                   </FormHelperText>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel
//                   htmlFor='departmentname'
//                   style={{
//                     marginLeft: "-14px",
//                     paddingBottom: "2px",
//                     marginTop: "2px",
//                   }}
//                 >
//                   Department Name
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       marginLeft: "4px",
//                     }}
//                   >
//                     *
//                   </sup>
//                 </InputLabel>
//                 <Select
//                   native
//                   variant="standard"
//                   style={{ width: "100%" }}
//                   name='departmentname'
//                   id='departmentname'
//                   value={formik.values.departmentname}
//                   onChange={(e) => {
//                     const departmentvalue = e.target.value;
//                     formik.setFieldValue("departmentname", departmentvalue);
//                     getDesignationData(e.target.value);
//                     getProfileDataByDeptCode(e.target.value)
//                     // setUserList([]);
//                     // dispatch(getDesignation(departmentvalue));
//                     // dispatch(getProfile(departmentvalue));
//                   }}
//                 >
//                   <option value=""></option>
//                   {department.map((obj) => (
//                     <option key={obj?.id} value={obj?.code}>
//                       {obj?.name}
//                     </option>
//                   ))}
//                 </Select>
//                 {formik.errors.departmentname && formik.touched.departmentname && (
//                   <FormHelperText
//                     error
//                     style={{ marginLeft: "0px", fontWeight: 400, marginTop: 5 }}
//                   >
//                     {formik.errors.departmentname}
//                   </FormHelperText>
//                 )}
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel
//                   htmlFor='designationname'
//                   shrink={Boolean(formik.values.designationname)}
//                   style={{
//                     marginLeft: "-14px",
//                     paddingBottom: "2px",
//                     marginTop: "2px",
//                   }}
//                 >
//                   Designation Name
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       marginLeft: "4px",
//                     }}
//                   >

//                   </sup>
//                 </InputLabel>
//                 <Select
//                   native
//                   variant="standard"
//                   style={{ width: "100%" }}
//                   name='designationname'
//                   id='designationname'
//                   value={formik.values.designationname}
//                   onChange={(e) => {
//                     const designationvalue = e.target.value;
//                     const departmentvalue  = formik?.values?.departmentname;
//                     formik.setFieldValue("designationname", designationvalue);
//                     // setUserList([]);
//                     // getuserData(e.target.value,departmentvalue);
//                     if (!designationvalue) {

//                       getProfileDataByDeptCode(departmentvalue);
//                     } else {

//                       getprofileData(designationvalue);
//                     }
//                     if (designationvalue) {
//                       const data = {
//                         departmentvalue: departmentvalue,
//                         designationvalue: designationvalue
//                       }
//                       // dispatch(getProfile(data));
//                     }
//                   }}
//                 >
//                   <option value=""></option>
//                   {designationList.map((obj) => (
//                     <option key={obj?.id} value={obj?.code}>
//                       {obj?.name}
//                     </option>
//                   ))}
//                 </Select>
//               </FormControl>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <FormControl fullWidth>
//                 <InputLabel
//                   htmlFor='profilename'
//                   shrink={Boolean(formik.values.profilename)}
//                   style={{
//                     marginLeft: "-14px",
//                     paddingBottom: "2px",
//                     marginTop: "2px",
//                   }}
//                 >
//                   Profile Name
//                   <sup
//                     style={{
//                       color: "red",
//                       fontSize: "small",
//                       fontWeight: "bolder",
//                       position: "relative",
//                       marginLeft: "4px",
//                     }}
//                   >
//                     *
//                   </sup>
//                 </InputLabel>
//                 <Select
//                   native
//                   variant="standard"
//                   style={{ width: "100%" }}
//                   name='profilename'
//                   id='profilename'
//                   value={formik.values.profilename}
//                   onChange={(e) => {
//                     const profilenamevalue = e.target.value;
//                     const profilenameobject =
//                     userList.find((obj) => obj?.id === parseInt(profilenamevalue)) ||
//                       {};

//                     console.log("Obj",profilenameobject,e.target.value);

//                     formik.setFieldValue("profilenameobject",profilenameobject);
//                     formik.setFieldValue("profilename", profilenamevalue);
//                   }}
//                 >
//                   <option value=""></option>
//                   {userList.map((obj) => (
//                     <option key={obj?.id} value={obj?.id}>
//                       {/* {obj?.firstname}&nbsp;{obj?.lastname} */}
//                       {obj?.name}
//                     </option>
//                   ))}
//                 </Select>
//                 {formik.errors.profilename && formik.touched.profilename && (
//                   <FormHelperText
//                     error
//                     style={{ marginLeft: "0px", fontWeight: 400, marginTop: 5 }}
//                   >
//                     {formik.errors.profilename}
//                   </FormHelperText>
//                 )}
//               </FormControl>
//             </Grid>
//           </Grid>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <MDButton type="submit" color="info" size="small">
//           {loading ? (
//                               <MoonLoader color="#f2fefa" size={16} />
//                             ) : (
//                               "Update"
//                             )}
//           </MDButton>
//         </DialogActions>
//       </form>
//     </Dialog>
//   );
// };

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import {
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Switch,
  s,
} from "@mui/material";
import Select from "@mui/material/Select";
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useFormik, useFormikContext } from "formik";
import axios from "../../../../axiosinstance";
import { getProject } from "components/Resuable/Create Project/service/Projectcreate";

import * as Yup from "yup";
import { getProjectTeam, updateProjectTeam } from "../service/ProjectTeam";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";
import { getUser } from "components/Resuable/UserMaster/service/User";

import MDButton from "components/MDButton";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import { tableDataAction } from "redux/Actions/tableDataAction";
import { micappCode } from "static/micappCode";

const ProjectTeamedit = (props) => {
  const {
    title,
    selectedProjectTeam,
    openpopup,
    setopenpopup,
    handleClose,
    selectedProject,
  } = props;
  const [project, setProject] = useState([]);
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [designationList, setDesignationList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [Isloading, setIsLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const dispatch = useDispatch();
  // const { user, loading4 } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.projectTeam);

  const validationSchema = Yup.object({
    projectname: Yup.string().required("MIC name is required."),
    departmentname: Yup.string().required("Department is required."),
    designationname: Yup.string().required("Designation is required."),
    profilename: Yup.string().required("Profile name is required."),
  });

  const formik = useFormik({
    initialValues: {
      projectname: selectedProjectTeam?.projectname || "",
      departmentname:
        selectedProjectTeam?.projectteams.login.departmentcode || "",
      designationname:
        selectedProjectTeam?.projectteams.login.designationcode || "",
      profilename: selectedProjectTeam?.projectteams.login.id || "",
      projectnamevalue: selectedProjectTeam?.projectcode,
      profilenameobject: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);

      const updatedProject = {
        ...selectedProjectTeam,
        projectcode: values.projectnamevalue
          ? values.projectnamevalue
          : selectedProjectTeam?.projectcode,
        projectname: values.projectnamevalue
          ? values?.projectname
          : selectedProjectTeam.projectname,
        projectteams: values?.profilenameobject
          ? { login: values?.profilenameobject }
          : selectedProjectTeam?.projectteams,
        modifiedby: auth?.profileid,
        modifiedbyname: auth?.name,
      };

      const response = await dispatch(updateProjectTeam(updatedProject));
      if (!response.error && !loading) {
        setIsLoading(false);
        handleClose();
        if (selectedProject === null || selectedProject === undefined) {
          dispatch(
            tableDataAction({
              url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}`,
            })
          );
        } else {
          dispatch(
            tableDataAction({
              url: `${APIs.projectTeamService}/get?mappingcode=${micappCode?.mappingcode}&projectcode=${selectedProject}`,
            })
          );
        }
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
      } else {
        formik.resetForm();
        setIsLoading(false);

        swal({
          icon: "error",
          title: "Error",
          text:
            response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
            "Something wents wrong.",
        });
      }
    },
  });

  async function getprofileData(designationcode) {
    await axios
      .get(
        `${APIs.loginServiceApi}/get?designationcode=${designationcode}&isactive=ACTIVE`
      )
      .then((response) => {
        setUserList(response?.data?.data?.dtoList);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDesignationData(code) {
    try {
      const response = await axios.get(`${APIs.designationServiceApi}/get`);
      setDesignation(response?.data?.data?.dtoList);
      const result = response?.data?.data?.dtoList.filter(
        (designationItem) => designationItem.deptcode === code
      );

      if (result?.length > 0) {
        setDesignationList(result);
      } else {
        formik.setFieldValue("designationname", "");
        formik.setFieldValue("profilename", "");
        setDesignationList([]);
        setUserList([]);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  async function getData() {
    try {
      let url = `${APIs.projectServiceApi}/get?mappingcode=${micappCode?.mappingcode}`;

      const response = await axios.get(url);

      setProject(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }

    try {
      const response = await axios.get(`${APIs.deparmentServiceApi}/get`);
      setDepartment(response?.data?.data?.dtoList);
    } catch (error) {
      console.log("error", error);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (selectedProjectTeam) {
        await getData();
        formik.setValues({
          projectname: selectedProjectTeam?.projectcode || "",
          departmentname:
            selectedProjectTeam?.projectteams.login.departmentcode || "",
          designationname:
            selectedProjectTeam?.projectteams.login.designationcode || "",
          profilename: selectedProjectTeam?.projectteams.login.id || "",
          projectnamevalue:
            project.find(
              (obj) => obj?.code === selectedProjectTeam?.projectcode
            )?.name || "",
        });
        await getprofileData(
          selectedProjectTeam?.projectteams.login.designationcode
        );
        await getDesignationData(
          selectedProjectTeam?.projectteams?.login.departmentcode
        );
      }
      setLoading(false);
    };

    fetchData();
  }, [selectedProjectTeam]);

  // useEffect(() => {
  //   getData();
  // }, [dispatch]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent>
          {loading ? (
            <CircularProgress
              style={{ position: "absolute", top: "50%", left: "50%" }}
            />
          ) : (
            <>
              <Grid container spacing={3} mt={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="projectname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                      shrink={Boolean(formik.values.projectname)}
                    >
                      MIC Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="projectname"
                      id="projectname"
                      value={formik.values.projectname}
                      onChange={(e) => {
                        const projectname = e.target.value;
                        const projectnamevalue =
                          project.find((obj) => obj?.code === e.target.value) ||
                          {};

                        formik.setFieldValue(
                          "projectnamevalue",
                          projectnamevalue?.name
                        );
                        formik.setFieldValue("projectname", projectname);
                      }}
                    >
                      <option value=""></option>
                      {project?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.projectname &&
                      formik.touched.projectname && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.projectname}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="departmentname"
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                      shrink={Boolean(formik.values.departmentname)}
                    >
                      Department Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="departmentname"
                      id="departmentname"
                      value={formik.values.departmentname}
                      onChange={(e) => {
                        const departmentvalue = e.target.value;
                        formik.setFieldValue("departmentname", departmentvalue);
                        getDesignationData(departmentvalue);
                      }}
                    >
                      <option value=""></option>
                      {department?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.departmentname &&
                      formik.touched.departmentname && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.departmentname}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="designationname"
                      shrink={Boolean(formik.values.designationname)}
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Designation Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="designationname"
                      id="designationname"
                      value={formik.values.designationname}
                      onChange={(e) => {
                        const designationvalue = e.target.value;
                        formik.setFieldValue("profilenameobject", "");
                        formik.setFieldValue("profilename", "");
                        formik.setFieldValue(
                          "designationname",
                          designationvalue
                        );
                        getprofileData(designationvalue);
                      }}
                    >
                      <option value=""></option>
                      {designationList?.map((obj) => (
                        <option key={obj?.id} value={obj?.code}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.errors.designationname &&
                    formik.touched.designationname && (
                      <FormHelperText
                        error
                        style={{
                          marginLeft: "0px",
                          fontWeight: 400,
                          marginTop: 5,
                        }}
                      >
                        {formik.errors.designationname}
                      </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel
                      htmlFor="profilename"
                      shrink={Boolean(formik.values.profilename)}
                      style={{
                        marginLeft: "-14px",
                        paddingBottom: "2px",
                        marginTop: "2px",
                      }}
                    >
                      Profile Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          marginLeft: "4px",
                        }}
                      >
                        *
                      </sup>
                    </InputLabel>
                    <Select
                      native
                      variant="standard"
                      style={{ width: "100%" }}
                      name="profilename"
                      id="profilename"
                      value={formik.values.profilename}
                      onChange={(e) => {
                        const profilenamevalue = e.target.value;
                        const profilenameobject =
                          userList.find(
                            (obj) => obj?.id === parseInt(profilenamevalue)
                          ) || {};
                        formik.setFieldValue(
                          "profilenameobject",
                          profilenameobject
                        );
                        formik.setFieldValue("profilename", profilenamevalue);
                      }}
                    >
                      <option value=""></option>
                      {userList?.map((obj) => (
                        <option key={obj?.id} value={obj?.id}>
                          {obj?.name}
                        </option>
                      ))}
                    </Select>
                    {formik.errors.profilename &&
                      formik.touched.profilename && (
                        <FormHelperText
                          error
                          style={{
                            marginLeft: "0px",
                            fontWeight: 400,
                            marginTop: 5,
                          }}
                        >
                          {formik.errors.profilename}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <MDButton type="submit" color="info" size="small">
            {Isloading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
          </MDButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ProjectTeamedit;
