import { createAsyncThunk } from "@reduxjs/toolkit";

export const labAddCartAction = createAsyncThunk(
  "labAddCart",
  async (data, { rejectWithValue }) => {
    console.log(data, "Add cart data");
    try {
      let currDate = new Date();
      const formattedDate = currDate.toLocaleDateString("en-CA");
      if (data.clear) {
        return {
          labs: [],
          selectedPrices: [],
          selectedTestType: [],
          date: formattedDate,
          dateNumber: 1,
          homeVisit: false,
          loading: false,
          error: null,
          areaRange: null,
          userCoordinate: [],
          searchInput: "",
          selectedPerson: null,
          paymentMethod: "",
          labtestCode: [],
          count:0,
          totalPrice:0,
          address: null,
        };
      } else if (data && data.labs?.length <= 0) {
        return {
          homeVisit: data?.homeVisit,
          labs: [],
          selectedPrices: data?.selectedPrices,
          date: data?.date,
          dateNumber: data?.dateNumber,
          selectedTestType: data?.selectedTestType,
          searchInput: data?.searchInput,
          areaRange: data?.areaRange,
          userCoordinate: data?.userCoordinate,
          selectedPerson: data?.selectedPerson,
          paymentMethod: data?.paymentMethod,
          address: data?.address,
          labtestCode: data?.labtestCode,
          count:data?.count,
          totalPrice:data?.totalPrice
        };
      } else if (data && data.labs?.length <= 0 && data.labs?.length <= 0) {
        return {
          homeVisit: data?.homeVisit,
          labs: [],
          selectedPrices: [],
          date: data?.date,
          dateNumber: data?.dateNumber,
          selectedTestType: data?.selectedTestType,
          searchInput: data?.searchInput,
          areaRange: data?.areaRange,
          userCoordinate: data?.userCoordinate,
          selectedPerson: data?.selectedPerson,
          paymentMethod: data?.paymentMethod,
          address: data?.address,
          labtestCode:data?.labtestCode,
          count:data?.count,
          totalPrice:data?.totalPrice
        };
      } else {
        console.log(data, "data in action 14");
        return (
          {
            homeVisit: data?.homeVisit,
            labs: data?.labs,
            selectedPrices: data?.selectedPrices,
            date: data?.date,
            dateNumber: data?.dateNumber,
            selectedTestType: data?.selectedTestType,
            searchInput: data?.searchInput,
            areaRange: data?.areaRange,
            userCoordinate: data?.userCoordinate,
            selectedPerson: data?.selectedPerson,
            paymentMethod: data?.paymentMethod,
            address: data?.address,
            labtestCode:data?.labtestCode,
            count:data?.count,
            totalPrice:data?.totalPrice
          } || {
            homeVisit: false,
            labs: [],
            date: currDate,
            dateNumber: 1,
            selectedTestType: data?.selectedTestType,
          }
        );
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
