import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import axios from "../../../../axiosinstance";
import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import CircularProgress from "@mui/material/CircularProgress";
import { updateProject } from "components/Resuable/Create Project/service/Projectcreate";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import { labappCode } from "static/labappCode";
import { MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";
import MapPopup from "app/agent/registrationResource/components/Map/MapPopup";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MDTypography from "components/MDTypography";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Lab name is required."),
  state: Yup.string().required("State is required."),
  district: Yup.string().required("District is required."),
  subDistrict: Yup.string().required("Sub district is required."),
  pincode: Yup.string().required("Pincode is required."),
  firstline: Yup.string().required("First line is required."),
  sapcode: Yup.string().required("Sapcode is required."),
  type: Yup.string().required("Type is required."),
});

const WareHouseEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, open,selectedUser, setopen, handleClose, passData } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingfilter, setLoadingfilter] = useState(false);
  const [typeloading, setTypeLoading] = useState(false);
  const [district, setDistrict] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [maplocation, setMaplocation] = useState(null);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [subDistrictLoading, setSubDistrictLoading] = useState(false);
  const [pincodeLoading, setPincodeLoading] = useState(false);
  const [statelist, setStateList] = useState([]);
  const [typelist, setTypeList] = useState([]);
console.log(selectedUser)
  const initialValues = {
    name: selectedUser?.name,
    sapcode: selectedUser?.whsapcode,
    state: selectedUser?.pincodeaddress?.state?.code,
    district: selectedUser?.pincodeaddress?.district?.code,
    subDistrict: selectedUser?.pincodeaddress?.subdistrict?.code,
    pincode: selectedUser?.pincodeaddress?.village?.code,
    firstline: selectedUser?.address
      ? selectedUser?.address?.split(",")[0]
      : "",
    type: selectedUser?.type,
  };

  const [pincodeobj, setPincodeobj] = useState(selectedUser?.pincodeaddress || {});

  const onSubmit = async (values) => {
    setIsLoading(true);
    let pincodeobject = {
      ...pincodeobj,
      pincode: pincodeobj?.village?.name.split("{")[1].split("}")[0],
    };
    const updatedUser = {
        ...selectedUser,
      name: values?.name,
      whsapcode: values?.sapcode,
      address: `${[
        values.firstline,
        pincodeobj?.village?.name?.split(" {")[0],
        pincodeobj?.subdistrict?.name,
        pincodeobj?.district?.name,
        pincodeobj?.state?.name,
      ]
        .filter(Boolean)
        .join(", ")}, ${pincodeobject?.pincode || ""}`,
      pincodeaddress: pincodeobject,
      type: values?.type,

      createdby: auth.profileid,
      createdbyname: auth.name,
      recstatus: "OPEN",
      status: "ACTIVE",
      syncstatus: "SYNCED",
    };

    const response = await axios.put(
      `${APIs.warehouseServiceApi}/update`,
      updatedUser
    );

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      swal("Good job!", "Data has been updated successfully!", {
        icon: "success",
      });
      passData();
    } else {
        swal("Opps!",response?.aceErrors?.[0]?.errorMessage ||
            "Something wents wrong.", {
            icon: "error",
          });
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.aceErrors?.[0]?.errorMessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      handleClose();
    }
  };
//   useEffect(() => {
//     getTypeData();
//     getStateData();
//   }, []);
  async function getStateData() {
    setLoading(true);
    const response = await dispatch(getState());
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setStateList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No State data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setLoading(false);
  }
  async function getTypeData() {
    setTypeLoading(true);
    const response = await axios.get(
      `${APIs.typeMasterServiceApi}/get?category=WAREHOUSE`
    );
    console.log(response);
    if (!response.error) {
      if (response?.data?.data?.dtoList && response?.data?.data?.listSize > 0) {
        setTypeList(response?.data?.data?.dtoList);
      } else {
        swal("", "No Type data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.data?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setTypeLoading(false);
  }

  async function getDistrictData(statecode) {
    setDistrictLoading(true);
    const response = await dispatch(getDistrict(statecode));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setDistrict(response?.payload?.data?.dtoList);
      } else {
        swal("", "No District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setDistrictLoading(false);
  }

  async function getSubDistrictData(statecode, districtCode) {
    setSubDistrictLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };
    const response = await dispatch(getSubDistrict(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setSubDistrict(response.payload.data.dtoList);
      } else {
        swal("", "No Sub District data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setSubDistrictLoading(false);
  }

  async function getVillageData(statecode, districtcode, subDistrictcode) {
    setPincodeLoading(true);
    const data = {
      statecode: statecode,
      districtCode: districtcode,
      subDistrictcode: subDistrictcode,
    };
    const response = await dispatch(getPincode(data));
    if (!response.error) {
      if (
        response?.payload?.data?.dtoList &&
        response?.payload?.data?.listSize > 0
      ) {
        setPincodeList(response?.payload?.data?.dtoList);
      } else {
        swal("", "No Pincode data found.", "warning");
      }
    } else {
      swal(
        "Oops!",
        `${response?.payload?.response?.data}.` || "Something wents wrong.",
        "warning"
      );
    }
    setPincodeLoading(false);
  }
  useEffect(() => {
    const fetchData = async () => {
      setLoadingfilter(true);

      try {
        getTypeData();
        getStateData();

        const response = await dispatch(
          getDistrict(selectedUser?.pincodeaddress?.state?.code)
        );
        setDistrict(response.payload.data.dtoList);

        const data = {
          statecode: selectedUser?.pincodeaddress?.state?.code,
          districtCode: selectedUser?.pincodeaddress?.district?.code,
        };

        const responseDistric = await dispatch(getSubDistrict(data));
        setSubDistrict(responseDistric.payload?.data?.dtoList);

        const datadistric = {
          statecode: selectedUser?.pincodeaddress?.state?.code,
          districtCode: selectedUser?.pincodeaddress?.district?.code,
          subDistrictcode: selectedUser?.pincodeaddress?.subdistrict?.code,
        };

        const responsepincode = await dispatch(getPincode(datadistric));
        setPincodeList(responsepincode.payload?.data?.dtoList);

        
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingfilter(false);
      }
    };

    fetchData();
  }, [selectedUser]);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
            {loadingfilter ? (
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : (
                <>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Lab Name{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={
                      touched.name && errors.name ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.name}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      Sap Code{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="sapcode"
                    name="sapcode"
                    value={values.sapcode}
                    onChange={handleChange}
                    error={touched.sapcode && Boolean(errors.sapcode)}
                    helperText={
                      touched.sapcode && errors.sapcode ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.sapcode}
                        </p>
                      ) : null
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} marginTop={1}>
                  <FormControl fullWidth error={!!errors.type && touched.type}>
                    <InputLabel
                      htmlFor="type"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Type
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="type"
                        id="type"
                        value={values.type}
                        onChange={(e) => {
                          const type = e.target.value;
                          setFieldValue("type", type);
                        }}
                      >
                        <option value=""></option>
                        {typelist?.map((obj) => (
                          <option key={obj?.id} value={obj?.code}>
                            {obj?.name}
                          </option>
                        ))}
                      </Select>
                      {typeloading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.type && touched.type && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.type}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <MDInput
                    type="text"
                    label=<span>
                      First Line{" "}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    variant="standard"
                    fullWidth
                    id="firstline"
                    name="firstline"
                    value={values.firstline}
                    onChange={(e) => {
                      handleChange(e);
                      setMaplocation(null);
                    }}
                    error={touched.firstline && Boolean(errors.firstline)}
                    helperText={
                      touched.firstline && errors.firstline ? (
                        <p
                          style={{
                            fontWeight: "400",
                            color: "red",
                            fontSize: "12px",
                          }}
                        >
                          {errors.firstline}
                        </p>
                      ) : null
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.state && touched.state}
                  >
                    <InputLabel
                      htmlFor="state"
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      State
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="state"
                        id="state"
                        value={values.state}
                        onChange={(e) => {
                          const state = e.target.value;
                          setDistrict([]);
                          setSubDistrict([]);
                          setPincodeList([]);
                          if (state) {
                            getDistrictData(e.target.value);
                          }
                          setFieldValue(
                            "statename",
                            statelist?.find((i) => i.state?.code == state)
                              ?.state?.name
                          );
                          setFieldValue("district", "");
                          setFieldValue("subDistrict", "");
                          setFieldValue("pincode", "");
                          setFieldValue("districtname", "");
                          setFieldValue("subdistrictname", "");

                          setFieldValue("state", state);
                        }}
                      >
                        <option value=""></option>
                        {statelist?.map((obj) => (
                          <option key={obj?.id} value={obj?.state?.code}>
                            {obj?.state?.name}
                          </option>
                        ))}
                      </Select>
                      {loading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.state && touched.state && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.state}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.district && touched.district}
                  >
                    <InputLabel
                      htmlFor="district"
                      shrink={Boolean(values.district)}
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      District
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="district"
                        id="district"
                        value={values.district}
                        onChange={(e) => {
                          const districtvalue = e.target.value;
                          setFieldValue("subDistrict", "");
                          setFieldValue("pincode", "");
                          setSubDistrict([]);
                          setPincodeList([]);
                          const selectedDistrictobj =
                            district?.find(
                              (obj) => obj.district.code === e.target.value
                            ) || {};
                          if (districtvalue) {
                            getSubDistrictData(
                              selectedDistrictobj.state?.code,
                              selectedDistrictobj.district?.code
                            );
                          }
                          setFieldValue("subdistrictname", "");
                          setFieldValue(
                            "districtname",
                            selectedDistrictobj?.district?.name
                          );

                          setFieldValue("district", districtvalue);
                        }}
                      >
                        <option value=""></option>
                        {district?.map((obj) => (
                          <option key={obj?.id} value={obj?.district?.code}>
                            {obj?.district?.name}
                          </option>
                        ))}
                      </Select>
                      {districtLoading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.district && touched.district && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.district}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.subDistrict && touched.subDistrict}
                  >
                    <InputLabel
                      htmlFor="subDistrict"
                      shrink={Boolean(values.subDistrict)}
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Sub District
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="subDistrict"
                        id="subDistrict"
                        value={values.subDistrict}
                        onChange={(e) => {
                          const subDistrictvalue = e.target.value;
                          setFieldValue("pincode", "");
                          setPincodeList([]);
                          const selectedPincodeobj =
                            subdistrict?.find(
                              (obj) => obj.subdistrict?.code === e.target.value
                            ) || {};
                          if (subDistrictvalue) {
                            getVillageData(
                              selectedPincodeobj.state?.code,
                              selectedPincodeobj.district?.code,
                              selectedPincodeobj.subdistrict?.code
                            );
                          }
                          setFieldValue(
                            "subdistrictname",
                            selectedPincodeobj?.subdistrict?.name
                          );

                          setFieldValue("subDistrict", subDistrictvalue);
                        }}
                      >
                        <option value=""></option>
                        {subdistrict?.map((obj) => (
                          <option key={obj?.id} value={obj?.subdistrict?.code}>
                            {obj?.subdistrict?.name}
                          </option>
                        ))}
                      </Select>
                      {subDistrictLoading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.subDistrict && touched.subDistrict && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.subDistrict}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl
                    fullWidth
                    error={!!errors.pincode && touched.pincode}
                  >
                    <InputLabel
                      htmlFor="pincode"
                      shrink={Boolean(values.pincode)}
                      style={{
                        paddingBottom: "2px",
                        marginTop: "-5px",
                      }}
                    >
                      Pincode
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        &nbsp;*
                      </sup>
                    </InputLabel>
                    <div style={{ position: "relative", marginTop: "10px" }}>
                      <Select
                        native
                        variant="standard"
                        style={{ width: "100%" }}
                        name="pincode"
                        id="pincode"
                        value={values.pincode}
                        onChange={(e) => {
                          const pincode = e.target.value;
                          const selectedPincodeObj =
                            pincodelist?.find(
                              (obj) => obj.village?.code === e.target.value
                            ) || {};

                          setPincodeobj(selectedPincodeObj);

                          setFieldValue("pincode", pincode);
                        }}
                      >
                        <option value=""></option>
                        {pincodelist?.map((obj) => (
                          <option key={obj?.id} value={obj?.village?.code}>
                            {obj?.village?.name}
                          </option>
                        ))}
                      </Select>
                      {pincodeLoading && (
                        <CircularProgress
                          color="inherit"
                          size={20}
                          style={{
                            position: "absolute",
                            right: 0,
                            top: "30%",
                            transform: "translateY(-50%)",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </div>
                    {errors.pincode && touched.pincode && (
                      <FormHelperText error style={{ marginLeft: "0px" }}>
                        {errors.pincode}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              </>
              )}
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {isLoading ? (
                  <MoonLoader color="#f2fefa" size={16} />
                ) : (
                  "Update"
                )}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default WareHouseEdit;
