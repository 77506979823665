import { createSlice } from "@reduxjs/toolkit";
import { getPincode } from "../service/Pincode";







const initialState = {
  pincode: [],
  loading: false,
  error: null,
};

export const pincodeDetail = createSlice({
  name: "pincodeDetail",
  initialState,
  extraReducers: {
    // [createDistrict.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [createDistrict.fulfilled]: (state, action) => {
    //   console.log("state",state.district)
    //   state.loading = false;
    //   state.district.push(action.payload.data.dto);
    // },
    // [createDistrict.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    //   // console.log("API Error slice:", action.payload.message);
    // },
    [getPincode.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getPincode.fulfilled]: (state, action) => {
      state.loading = false;
      state.pincode = action.payload.data.dtoList;

    },
    [getPincode.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // [deleteDistrict.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [deleteDistrict.fulfilled]: (state, action) => {
    //   state.loading = false;
    //   console.log(action.payload)
    //   const { id } = action.payload.data.dto;
    //   if (id) {
    //     state.district = state.district.filter((district) => district.id !== id);
    //   }
    // },
    // [deleteDistrict.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.error;
    // },
    // [updateDistrict.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [updateDistrict.fulfilled]: (state, action) => {
    //   state.loading = false;
      
    
    //   const index = state.district.findIndex(district => district.id === action.payload.data.dto.id)
    //   state.district[index] = action.payload.data.dto;
    // //   state.users.push(action.payload);
    // },
    // [updateDistrict.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});

export default pincodeDetail.reducer;
