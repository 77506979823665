

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";
import swal from "sweetalert";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { tableDataAction } from "redux/Actions/tableDataAction";

import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import Button from "@mui/material/Button";

import {
    Autocomplete,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from "@mui/material";
import noData from "../../../assets/images/products/No-data-found.png";


import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import MDBadge from "components/MDBadge";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { useDispatch } from "react-redux";
import { userCategoryAction } from "redux/Actions/getUserCategoryAction";
import { capitalizeWords } from "static/methods/capitalizedWords";

import Skeleton from 'components/Skeleton/Skeleton';
import AssignPopup from "components/Resuable/searchpatientcard/AssignPopup";
import VitalsPopup from "components/Resuable/searchpatientcard/VitalsPopup";
import SearchPatientCard from "components/Resuable/searchpatientcard/SerachPatientCard";
import FilterCmp from "components/Filters/FilterCmp";
import { bookingListAction } from "redux/Actions/bookingListAction";
import { useNavigate } from "react-router-dom";
import PaginationFliter from "components/PaginationFliter/PaginationFliter";




// import FormField from "../FormField";
const SearchPatient = ({ doctorProfileId }) => {
    const comp = "SM";

    let selectedName = "";
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [openedit, setOpenedit] = React.useState(false);
    const [selectid, setSelectid] = useState(null);
    const [selectdoctor, setSelectdoctor] = useState(null);
    const [idcardList, setIdcardList] = useState("");
    const [doctorList, setDoctorList] = useState("");
    const [visittypeList, setvisittypelist] = useState("");
    const [mobileno, setMobileno] = useState("");
    const [docno, setDocno] = useState("");
    const [docType, setDocType] = useState("");
    const [selectvisittype, setSelectvisittype] = useState(null);
    const [selectvisitcategory, setSelectvisitcategory] = useState(null);
    const [openhistory, setOpenhistory] = React.useState(false);
    const [Loading, setLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [color] = useState("#344767");
    const [openassign, setAssign] = useState(false);
    const [assigndata, setAssignData] = useState({});

    const [item, setItem] = useState({});
    const [editobj, setEditobj] = useState({});
    const [errors, setErrors] = useState({
        doctorError: false,
        visitTypeError: false,
        visitCategoryError: false,
        paymentError: false,
    });
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

    const { getUserCategory, getBookingList } = useSelector((state) => state);

    const [mmuvalue, setMmuvalue] = useState(null);
    const [openvitals, setOpenvitals] = useState(false);
    const [searched, setSearched] = useState(null);
    const [getResponse, setResponse] = useState([]);
    const [mmucode, setMmucode] = useState(null);
    const [givenQty, setGivenQty] = useState("");
    const [selectedPatient, setSelectedPatient] = useState();

    const [getApiResponse, setApiResponse] = useState([]);
    const [idList, setidList] = useState("");
    const [patientId, setPatientId] = useState();
    const [bookingPatient, setBookingPatient] = useState();
    const [patientVisitRec, setPatientVisitRec] = useState();
    const [payment, setPayment] = useState();
    const dispatch = useDispatch();
    const [filterData, setFilterData] = useState({ date: new Date().toISOString().split("T")[0] });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(6);

    const pageOption = [6, 9, 15]

    async function updatePatientbookinglist(bookingobj) {
        setLoading(true);

        await axios
            .put(`${APIs.bookingServiceApi}/update`, bookingobj)
            .then((response) => {
                // getpatient(doctorProfileId);
                handleApi()
                setIsLoading(false);
                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }


    // const getpatient = async (profileId) => {
    //     setLoading(true);
    //     await axios.get(`${APIs.bookingServiceApi}/get?doctor.profileid=${profileId}&recstatus=SCHEDULED`)
    //         .then((response) => {
    //             setResponse(response?.data?.data?.dtoList);
    //             setDoctorList([response.data?.data?.dtoList[0]?.doctor]);
    //             setApiResponse(response?.data?.data?.dtoList);
    //             setLoading(false);
    //             setIsLoading(false);
    //         })
    //         .catch((e) => {
    //             console.log("error", e);
    //             setLoading(false);
    //             setIsLoading(false);
    //         });
    // }


    const filters = [
        {
            type: "date",
            // placeholder: "Enter name",
            variant: "outlined",
            // label: "Date",
            name: "date",
            page: "allList",
        },
        {
            type: "text",
            placeholder: "Enter name",
            variant: "outlined",
            label: "Name",
            name: "name",
            page: "allList",
        },

    ];

    //view history data ============================
    const [historyDataList, setHistoryDataList] = useState();
    async function historyData(patientObject) {
        // var mmucode = JSON.parse(localStorage.getItem("association"))[0].mmucode;
        setPatientId(patientObject?.patientobj?.patientid);
        // var patientId = patientObject.patientid;
        setLoading(true);

        await axios
            .get(
                `${APIs.aggregationServiceApi}/get?mmucode=${doctorProfileId}&patientid=${patientObject?.patientobj?.patientid}&recstatus=PRESCRIBED`
            )
            .then((response) => {
                setHistoryDataList(response?.data?.data?.visitAggregationDTOList);
                setLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
                setLoading(false);
            });
    }

    async function createVisit(object) {
        setIsLoading(true);

        await axios
            .put(`${APIs.visitServiceApi}/update`, object)
            .then((response) => {
                bookingPatient.visitid = response.data?.data?.dto?.visitid;
                bookingPatient.recstatus = "ASSIGNED";
                updatePatientbookinglist(bookingPatient);
                setLoading(false);
                setIsLoading(false);
            })
            .catch((e) => {
                console.log("error", e);
                setIsLoading(false);
            });
    }
    const [finalPatientObject, setFinalPatientObject] = useState();
    async function editPatient(object, itemIdList) {
        for (let i = 0; i < itemIdList.length; i++) {
            if (itemIdList[i].name == object.documenttype) {
                setSelectid(itemIdList[i]);
            }
        }

        setEditobj(object);

        setMobileno(object.mobileno);
        setDocno(object.documentno);

    }

    //search button
    const requestSearch = (keyData) => {
        //setSearched(key);
        const searchdata = getResponse.filter((key) => {
            if (
                key?.patientobj?.name.toLowerCase().includes(keyData.toLowerCase()) ||
                key?.patientobj?.mobileno.includes(keyData) ||
                key?.patientobj?.patientid.toLowerCase().includes(keyData.toLowerCase())
            ) {
                return key;
            }
        });
        if (keyData === null || keyData.length === 0) {
            setResponse(getApiResponse);
        } else setResponse(searchdata);
    };



    useEffect(() => {
        handleApi();
        // getpatient(auth.profileid, filterData?.date);
        dispatch(userCategoryAction({ profileid: auth.profileid }));
    }, []);

    const handleClear = () => {
        setCurrentPage(1)
        setFilterData({});
        dispatch(bookingListAction({ profileId: doctorProfileId, page: currentPage - 1, size: pageSize }));
        navigate('/app/nurse/book-patient');
    };

    const handleSearch = () => {
        handleApi();
    };




    const handleApi = () => {
        if (filterData?.date && filterData?.name) {
            dispatch(bookingListAction({ name: filterData?.name, date: filterData?.date, profileId: doctorProfileId, page: currentPage - 1, size: pageSize }));
        }
        else if (filterData?.date) {
            dispatch(bookingListAction({ date: filterData?.date, profileId: doctorProfileId, page: currentPage - 1, size: pageSize }));
        }
        else if (filterData?.name) {
            dispatch(bookingListAction({ name: filterData?.name, profileId: doctorProfileId, page: currentPage - 1, size: pageSize }));
        }
        else {
            dispatch(bookingListAction({ profileId: doctorProfileId, page: currentPage - 1, size: pageSize }));
        }
    }

    useEffect(() => {
        handleApi()
    }, [pageSize, currentPage])


    //------------------------------------- vitals handler ------------------------------------------------

    const [patientVitals, setPatientVitals] = useState();
    const [vitalsMasterList, setVitalsMasterList] = useState([]);

    const handleCreateVitals = (values) => {
        setOpenvitals(true);
        setIsLoading(true)
        // getPatientVitals(values.visitid)
        getVitalMaster(values.visitid);
        setBookingPatient(values);
    };

    async function getVitalMaster(visitId) {
        await axios
            .get(`${APIs.vitalsMasterServiceApi}/get`)
            .then((response) => {
                // setVitalsMasterList(response?.data?.data?.dtoList);
                getPatientVitals(visitId, response?.data?.data?.dtoList);
            })
            .catch((e) => {
                console.log("error", e);
            });
    }

    const getPatientVitals = async (visitId, masterList) => {
        await axios
            .get(`${APIs.vitalsServiceApi}/get?visitid=${visitId}`)
            .then((response) => {
                let temp = response.data.data.dtoList;
                if (temp.length > 0) {
                    const updatedList = masterList.map((item, index) => ({
                        ...item,
                        value: temp[index]?.value,
                        id: temp[index]?.id,
                        createdby: temp[index]?.createdby,
                    }));
                    setVitalsMasterList(updatedList);

                    setIsLoading(false)
                } else {
                    setVitalsMasterList(masterList);

                    setIsLoading(false)
                }
                setPatientVitals(response.data.data.dtoList);
            })
            .catch((error) => {

                setIsLoading(false)
            });
    };


    const handleDoctor = (value) => {
        setAssignData(value)
        setAssign(true)
    }
    return (
        <DashboardLayout>
            {/* <UpaarNavbar/> */}
            <MDBox pt={3}>
                <MDBox mt={14}>
                    <Card >
                        <MDBox pl={3} pr={3} lineHeight={1}>
                            <Grid container spacing={3} mt={2}>
                                <Grid item xs={12} sm={6}>
                                    <MDTypography variant="h5" fontWeight="medium">
                                        Search Patient
                                    </MDTypography>
                                </Grid>
                                <FilterCmp

                                    filterArray={filters}
                                    filterData={filterData}
                                    setFilterData={setFilterData}
                                    handleSearch={handleSearch}
                                    handleClear={handleClear}
                                />
                                {/* <Grid item xs={12} sm={6}>
                                    <MDBox width="12rem" ml="auto">
                                        <MDInput
                                            placeholder="Search..."
                                            value={searched}
                                            onChange={(e) => {
                                                requestSearch(e.target.value);
                                                setSearched(e.target.value);
                                            }}
                                        />
                                    </MDBox>
                                </Grid> */}
                            </Grid>
                        </MDBox>


                    


                            <div >
                                {getBookingList?.loading ? (
                                    <MDBox mt={2} sx={{ p: 2 }} className="patient-queue-grid">
                                        {[...Array(9)].map((_, index) => (
                                            <Card key={index}>
                                                <Skeleton width="564px" height="300px" />
                                            </Card>
                                        ))}
                                    </MDBox>
                                ) : (
                                    <>
                                    {getBookingList && getBookingList?.list?.length > 0 ? (
                                      <MDBox mt={2} sx={{ p: 2 }} className="patient-queue-grid">
                                        {getBookingList?.list?.map((obj) => (
                                          <Grid item key={obj.id}>
                                            <SearchPatientCard
                                              data={obj}
                                              getpatient={handleApi}
                                              doctorProfileId={doctorProfileId}
                                              handleDoctor={handleDoctor}
                                              handleCreateVitals={handleCreateVitals}
                                            />
                                          </Grid>
                                        ))}
                                      </MDBox>
                                    ) : (
                                      <Grid
                                        item
                                        xs={12}
                                        justifyContent="center"
                                        mt={5}
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                         
                                        }}
                                      >
                                        <img
                                          src={noData}
                                          alt="No Data Available"
                                          style={{
                                            width: '100%',
                                            maxWidth: '200px',
                                            height: 'auto'
                                          }}
                                        />
                                      </Grid>
                                    )}
                                  </>
                                  
                                )}
                            </div>

                        <PaginationFliter
                            setCurrentPage={setCurrentPage}
                            pageCount={getBookingList?.totalPages}
                            setPerPage={setPageSize}
                            perPage={pageSize}
                            currentPage={currentPage}
                            option={pageOption}
                        />
                    </Card>
                </MDBox>
            </MDBox>

            {/* assign Doctor popup================================================= */}

            {openassign && (
                <AssignPopup
                    openassign={openassign}
                    setAssign={setAssign}
                    doctorList={getBookingList?.doctorList}
                    values={assigndata}
                    getpatient={handleApi}
                    doctorProfileId={doctorProfileId}

                />

            )}
            {
                openvitals && (
                    <VitalsPopup
                        openvitals={openvitals}
                        setOpenvitals={setOpenvitals}
                        patientVitals={patientVitals}
                        vitalsMasterList={vitalsMasterList}
                        setVitalsMasterList={setVitalsMasterList}
                        bookingPatient={bookingPatient}
                        isLoading={isLoading}
                    />

                )
            }

        </DashboardLayout>
    );
};




export default SearchPatient;

