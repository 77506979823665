import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../../../app/findDoctor/navbar/desktopNavbar/navbar";
import { Grid } from "@mui/material";
import MedharvaFooter from "../../../app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";

import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import { Tooltip } from "@mui/material";
import PatientEdit from "../Patient Form/PatientEdit/PatientEdit";

function Familylist() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});

  const [item, setItem] = useState({});
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [selectedPatient, setSelectedPatient] = useState({});

  const [openpopup, setopenpopup] = useState(false);
  const iconStyle = { fontSize: "1.5em", color: "#039147", marginRight: "9px" };
  const iconStyledelete = {
    fontSize: "1.5em",
    color: "red",
    marginRight: "9px",
  };
  const [profileId, setProfileId] = useState(auth?.profileid);
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  async function onUpdatedata(object) {
    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setOpen(true);
  }
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleClose = () => {
    setOpen(false);
  };

  async function onDelete(item) {
    item.modifieddate = new Date();
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Patient list.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      console.log(willDelete);
      if (willDelete) {
        axios.put(`${APIs.loginServiceApi}/delete`, item).then((response) => {
          console.log(response);
          getData();
        });
        swal("Your role record has been deleted!", {
          icon: "success",
        });
      } else {
        swal("Your list record is safe!");
      }
    });
  }

  const onEdit = (item) => {
    setSelectedPatient(item);
    setopenpopup(true);
  };

  function getData() {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },
        ,
        { Header: "mobile no", accessor: "mobileno" },
        { Header: "gender", accessor: "sex" },
        { Header: "Email", accessor: "emailid" },
        { Header: "status", accessor: "isactive" },

        { Header: "created date", accessor: "fcreateddate" },

        { Header: "action", accessor: "action" },
      ],
      rows: [],
    };
    axios
      .get(`${APIs.loginServiceApi}/get?createdby=${profileId}`)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          item.action = (
            <span>
              <Tooltip title="Edit">
                <Link to={{}}>
                  <FaRegEdit
                    style={iconStyle}
                    onClick={() => {
                      onEdit(item);
                    }}
                  />
                </Link>
              </Tooltip>
              <Tooltip title="Delete">
                <Link to={{}}>
                  <MdDelete
                    style={iconStyledelete}
                    onClick={() => {
                      onDelete(item);
                    }}
                  />
                </Link>
              </Tooltip>
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {/* {isMidScreen ? <></> : <Navbar />}
      {isMidScreen ? <MobileNavbar /> : <></>} */}
      <MDBox pt={3}>
        <MDBox mt={isMidScreen ? 23 : 20}>
          <Card style={{ marginRight: "25px", marginLeft: "25px" }}>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    My Family
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/app/patient/addFamilymember"}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar /> : <></>}
      {openpopup && (
        <PatientEdit
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Family Edit"}
          selectePatient={selectedPatient}
          getPatient={getData}
        />
      )}
    </>
  );
}

export default Familylist;
