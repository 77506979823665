import { Grid } from "@mui/material";
import React, { useState } from "react";
import Diet from "../../findDoctor/assets/images/diet.png";
import "./whyMedharvaCare.css";

const WhyMedharvaCareInfo = ({ index, image, heading, text }) => {
  const isEven = index % 2 === 0;
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  return (
    <Grid container xs={12} mb={2}>
      {isEven ? (
        <>
          <Grid item xs={7} pl={5}>
            <Grid item xs={12} mt={3} className="whymedharvacareitem2heading">
              {heading}
            </Grid>
            <Grid item xs={9} mt={1} className="whymedharvacareitem2text">
              {isExpanded ? text : `${text.slice(0, 300)}`}
            </Grid>
            <Grid item xs={8} mt={2} className="whymedharvacarecontainer3">
              <button className="whymedharvacareitem2button" onClick={toggleExpanded}>
                {isExpanded ? "Read less" : "Read more"}
              </button>
            </Grid>
          </Grid>
          <Grid item xs={4} pr={5} className="whymedharvacarecontainer2">
            <img src={image} alt="Logo" className="whymedharvaimagesize" />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={4} pl={5} className="whymedharvacarecontainer3">
            <img src={image} alt="Logo" className="whymedharvaimagesize" />
          </Grid>
          <Grid item xs={8}>
            <Grid item xs={12} pr={5} className="whymedharvacarecontainer4">
              <Grid item xs={11} mt={3} className="whymedharvacareitem2heading">
                {heading}
              </Grid>
              <Grid item xs={8.8} mt={1} className="whymedharvacareitem2text">
                {isExpanded ? text : `${text.slice(0, 300)}`}
              </Grid>
            </Grid>
            <Grid item xs={12} mt={2} className="whymedharvacarecontainer3">
              <button className="whymedharvacareitem2button" onClick={toggleExpanded}>
                {isExpanded ? "Read less" : "Read more"}
              </button>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default WhyMedharvaCareInfo;
