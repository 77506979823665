import * as Yup from "yup";

import checkout from "./form";
const {
  formField: { itemtype, sapcode, uom,name,packagetype },
} = checkout;

const validations = [
  Yup.object().shape({
    [packagetype.name]: Yup.string().required(packagetype.errorMsg),
    [name.name]: Yup.string().required(name.errorMsg),
    [itemtype.name]: Yup.string().required(itemtype.errorMsg),
    [sapcode.name]: Yup.string().required(sapcode.errorMsg),
    [uom.name]: Yup.string().required(uom.errorMsg),
  }),
];

export default validations;
