import { Button, Grid, TextField } from "@mui/material";
import React from "react";
import Logo from "../../assets/images/medharva-logo.png";
import Search from "../../assets/images/Search.svg";
import "./mobileNavbar.css";
import Simplification from "../../assets/images/mobileSimplification.svg";
import ProfileNavbarInfo from "app/findDoctor/footerNavbar/Sidebar/ProfileNavbarInfo/ProfileNavbarInfo";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const MobileSection2 = () => {
  const navigate = useNavigate();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  return (
    <div className="mobilenavbarcontainer2">
      <Grid item mr={2} className="mobilesection2item2">
        <img
          className="mobilesection2img"
          src={Simplification}
          alt="Medharva Logo"
        />
        &nbsp;
        <span className="mobilesection2item1">Medharva</span>
      </Grid>

      <Grid item mr={2}>
        {auth?.rolecode ? (
          <div class="popover__wrapper2">
            <a>
              <IconButton size="large">
                {/* <Icon style={{ color: "#039147" }}>account_circle</Icon> */}
                <button className="section2button3">
                  {auth && auth.name // Check if auth is not null before accessing its properties
                    ? auth.name
                        .split(" ")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")
                    : "Default Name"}
                </button>
              </IconButton>
            </a>
            <div className="popover__content2">
              <ProfileNavbarInfo />
            </div>
            {/* <Patienthovernavbar /> */}
          </div>
        ) : (
          <button
            className="section2button2"
            onClick={() => navigate("/app/authentication/sign-in/basic")}
          >
            Login
          </button>
        )}
      </Grid>
    </div>
  );
};

export default MobileSection2;
