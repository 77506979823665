import React, { useEffect, useState } from "react";
import "./login.css";
import loginsign from "../findDoctor/assets/images/loginsign.jpg";

import { Grid, Card } from "@mui/material";
import SignUp from "./signUp/SignUp";
import Signin from "./signIn/Signin";
import CustomForm from "components/Forms/FormCmp";
import { loginFormConfig, registrationFormConfig } from "components/Forms/FormCmpMd";
// import Navbar from "../findDoctor/navbar/desktopNavbar/navbar";

// import MobileNavbar from "../findDoctor/navbar/mobileNavbar/mobileNavbar";
import * as Yup from 'yup';

const Login = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 580);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);
  const [selectedOption, setSelectedOption] = useState("Login");

  const handleSwitchChange = (event) => {
    console.log(event.target.value)
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 580);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleChildData = (data) => {
    // Do something with the data received from the child
    console.log("Data received from child:", data);
    setSelectedOption(data);
  };
 

  // ------------------------------------------------------- Custom Form ---------------------------------------------------
  

  // // ------------------------------------------ Login Form ----------------------------------------------
  // const initialValues = {
  //   email: '',
  //   password: '',
  // };

  // const validationSchema = Yup.object({
  //   email: Yup.string().email('Invalid email').required('Required'),
  //   password: Yup.string().required('Required'),
  // });

  // const handleSubmit = (values) => {
  //   console.log('Login form submitted', values);
  // };



  // // ------------------------------------------------ Sing Up Form ------------------------------------------------

  // const initialRegValues = {
  //   username: '',
  //   email: '',
  //   password: '',
  //   confirmPassword: '',
  //   gender: '',
  //   hobbies: [],
  //   country: '',
  //   skills: [],
  //   newsletter: false,
  // };

  // const regValidationSchema = Yup.object({
  //   username: Yup.string().required('Required'),
  //   email: Yup.string().email('Invalid email').required('Required'),
  //   password: Yup.string().required('Required'),
  //   confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
  // });

  // const handleRegSubmit = (values) => {
  //   console.log('Registration form submitted', values);
  // };

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
  
    <Grid
      container
      justifyContent={"center"}
      alignItems={"center"}
      style={{
        height: "100vh",
        backgroundSize: "cover",
        backgroundImage: `url(${loginsign})`,
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
        overflow: "hidden",
      }}
    >
      <Card className="cardLogin">
        <Grid className="switches-containerLogin">
          <input
          className="inputlogin"
            type="radio"
            id="switchSingle"
            name="switchPlan"
            value="Login"
            checked={selectedOption === "Login"}
            onChange={handleSwitchChange}
          />
          <input
            className="inputlogin"
            type="radio"
            id="switchMulti"
            name="switchPlan"
            value="Signup"
            checked={selectedOption === "Signup"}
            onChange={handleSwitchChange}
          />
          <label className="labellogin" htmlFor="switchSingle">Login</label>
          <label className="labellogin" htmlFor="switchMulti">Signup</label>
          <Grid className="switch-wrapperLogin">
            <Grid className="switchLogin">
              <Grid className="labellogin">Login</Grid>
              <Grid className="labellogin">Signup</Grid>
            </Grid>
          </Grid>
        </Grid>

        {selectedOption === "Login" ? (
          <Grid>
            <Signin />
            {/* <CustomForm
              config={loginFormConfig}
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            /> */}
          </Grid>
        ) : (
          <Grid>
            <SignUp  onData={handleChildData}/>
            {/* <CustomForm
              config={registrationFormConfig}
              initialValues={initialRegValues}
              validationSchema={regValidationSchema}
              onSubmit={handleRegSubmit}
            /> */}
          </Grid>
        )}
      </Card>
    </Grid>
    </div>
  );
};

export default Login;
