import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import visibility from "../../findDoctor/assets/images/intractiveVR.svg";

import "../Intractive/style.css";
const IntractiveVR = () => {
  return (
    <div>
      <Grid className="intractive-a">
        <Grid className="intractive-b">
          <Grid>
            <Grid className="intractive-c">
              <Grid className="intractive-d">Interactive VR/3D Service</Grid>
            </Grid>
            <Grid>
              <div className="intractive-d1">
                <Grid className="intractive-e">
                  <Grid className="intractive-f">
                    Interactive VR service provided by Medharva is best in
                    class. Helps visualize situations better be it any. Now you
                    can train your employees without the hassle of actually
                    manifesting it.
                    <Grid className="intractive-g">
                      (VR service included only with Premium services)
                    </Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Grid className="intractive-h">
                    <img className="intractive-i" src={visibility} />
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid>
              <Grid className="intractive-j">
                <button className="intractive-k" style={{}}>
                  Get a free Demo
                </button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default IntractiveVR;
