import { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "../findDoctor/navbar/mobileNavbar/mobileNavbar";
// import Cards from "../findDoctor/akash/Cards"
import NumberUpdates from "./numberUpdates/numberUpdates";
// import Card from "./akash/Card";
import Testimonials from "./testimonials/testimonials";
// import Home from "./akash/Home";
import WhatUserSay from "./whatUserSay/whatUserSay";
import EmailSubscribe from "./emailSubscribe/emailSubscribe";
import MedharvaFooter from "../findDoctor/medharvaFooter/medharvaFooter"
import FooterNavbar from "./footerNavbar/footerNavbar";
import MedharvaPlus from "./medharvaPlus/medharvaPlus";
// import SliderCard from "./akash/SliderCard";


function FindDoctor() {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="Find Doctors" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <Home />
      {/* {isSmallScreen ? <></> : <Cards />} */}
      {isSmallScreen ? <NumberUpdates /> : <></>}
      {/* <Card pagelabel="Find Doctors"/> */}
      <MedharvaPlus />
      {isMidScreen ? <></> : <Testimonials />}
      {isSmallScreen ? <WhatUserSay /> : <></>}
      <EmailSubscribe />
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Find Doctors"/> : <></>}
    </div>
  );
}

export default FindDoctor;
