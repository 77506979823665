import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField:{firstLine,secondLine,country,state,district,subdistrict,cityVillage,pincode,addresstype},
} = checkout;

const validations = [
  Yup.object().shape({
    [firstLine.name]: Yup.string().required(firstLine.errorMsg),
    [secondLine.name]: Yup.string().required(secondLine.errorMsg),
    [country.name]: Yup.string().required(country.errorMsg),
    [state.name]: Yup.string().required(state.errorMsg),
    [district.name]: Yup.string().required(district.errorMsg),
    [subdistrict.name]: Yup.string().required(subdistrict.errorMsg),
    [cityVillage.name]: Yup.string().required(cityVillage.errorMsg),
    [pincode.name]: Yup.string().required(pincode.errorMsg),
    [addresstype.name]: Yup.string().required(addresstype.errorMsg),
  }),
];

export default validations
