/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },
    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age name is required.",
    },
    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    referalcode: {
      name: "referalcode",
      label: "Referalcode",
      type: "text",
      errorMsg: "Referalcode is required.",
    },
    rolecode: {
      name: "rolecode",
      label: "Role",
      type: "select",
      errorMsg: "Role is required.",
    },
    gender: {
      name: "gender",
      label: "Gender",
      type: "select",
      errorMsg: "Gender is required.",
    },
    experience: {
      name: "experience",
      label: "Experience",
      type: "text",
      errorMsg: "Experience is required.",
    },
    accountholdername: {
      name: "accountholdername",
      label: "Account holder name",
      type: "text",
      errorMsg: "Accountholdername is required.",
    },
    accountnumber: {
      name: "accountnumber",
      label: "Account Number",
      type: "number",
      errorMsg: "Accountnumber is required.",
    },
    
    description: {
      name: "description",
      label: "Description",
      type: "text",
      errorMsg: "Description is required.",
    },
    ifsccode: {
      name: "ifsccode",
      label: "IFSC Code",  
      type: "text",
      errorMsg: "IFSC code is required.",
    },
    bankname: {
      name: "bankname",
      label: "Bank Name",  
      type: "text",
      errorMsg: "Bankname is required.",
    },
    
    adhar: {
      name: "adhar",
      label: "Aadhaar",  
      type: "number",
      errorMsg: "Aadhaar is required.",
    },
    pan: {
      name: "pan",
      label: "Pan",  
      type: "text",
      errorMsg: "Pan is required.",
    },
    departmentvalue: {
      name: "departmentvalue",
      label: "Department",
      type: "select",
      errorMsg: "Department is required.",
    },

    designationvalue: {
      name: "designationvalue",
      label: "Designation",
      type: "select",
      errorMsg: "Designation is required.",
    },
    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    password: {
      name: "password",
      label: "Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password should be more than 6 characters.",
    },
    repeatPassword: {
      name: "repeatPassword",
      label: "Repeat Password",
      type: "password",
      errorMsg: "Password is required.",
      invalidMsg: "Your password doesn't match.",
    },
  
    phonenumber: {
      name: "phonenumber",
      label: "Phonenumber",
      type: "number",
      errorMsg: "Phone Number is required.",
    },
   
   
    state: {
      name: "state",
      label: "state",
      type: "text",
      errorMsg: "state is required.",
    },
    district: {
      name: "district",
      label: "District",
      type: "text",
      errorMsg: "district is required.",
    },
    subDistrict: {
      name: "subDistrict",
      label: "Sub-District",
      type: "text",
      errorMsg: "subDistrict is required.",
    },

    pincode: {
      name: "pincode",
      label: "pincode",
      type: "number",
      errorMsg: "pincode is required.",
    },
  },
};

export default form;
