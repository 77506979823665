/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/



import checkout from "components/Resuable/Patientsignup/schemas/form";

const {
  formField: {
    firstName,
    lastName,
   
    email,
    password,
    repeatPassword,
    gender,
    phonenumber,
    age,
    department,
    designation,
   
    district,
   
    pincode
   
  },
} = checkout;

const initialValues = {
  [firstName.name]: "",
  [lastName.name]: "",
  [age.name]: "",
  [email.name]: "",
  [password.name]: "",
  [repeatPassword.name]: "",
 
 

  [gender.name]: "",
  
  [phonenumber.name]: "",
  

 

};

export default initialValues;
