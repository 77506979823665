import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";


 
  export const deleteProjectServiceData = createAsyncThunk("deleteProjectServiceData", async (data, {rejectWithValue}) => {
  
    try {
      const response = await axios.put(
        `project-service/api/Project/v1/delete`,
        data
       
      );
      return await response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  });