

import React from "react";
import {Grid  } from "@mui/material";
import "./service.css"; 
import imag1 from "../../findDoctor/assets/images/panindia.png";
import imag2 from "../../findDoctor/assets/images/discount.png";
import imag3 from "../../findDoctor/assets/images/medicines.png";
import imag4 from "../../findDoctor/assets/images/aggriment.png";


const Ourserviceextends = () => {
 
  const cardsData = [
    {
      logo: imag1,
      description: "PAN India delhivery",
    },
    {
      logo: imag2,
      description: "Attractive Discount",
    },
    {
      logo: imag3,
      description: "Extensive Range of Product",
    },
    {
      logo: imag4,
      description: "Reputed Partners",
    },
   

  ];

  return (
  
      <Grid container className="card-container">

        <Grid container spacing={2} className="container">

          <Grid item xs={12}>
            <h2 className="page-heading">
              Why choose Medharva for online medicine order
            </h2>
          </Grid>
          {cardsData.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index} >
              <div className="card">

                <div className="image-container">
                  <Grid item xs={8} sm={10}className="cardimage">
                    <img src={card.logo} alt="Service Logo" className="image" />
                  </Grid>
                </div>
                <Grid item xs={8} sm={10}>
                <div className="description">{card.description}</div>
                </Grid>
              </div>
            </Grid>
          ))}
        </Grid>
      </Grid>
    
  );
};

export default Ourserviceextends;
