import * as Yup from "yup";

export const validationdata = Yup.object({
  
    new_password : Yup.string()
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      {
        message:
          "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
        excludeEmptyString: true,
      }
    )
    .required("Password is required."),
    confirm_password : Yup.string().required("Confirm password is required.").oneOf([Yup.ref('new_password'),null],"Password must match."),
})
 
 
