import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

function AddPharmacyDetail() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
 
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  async function onUpdatedata(object) {
  

    navigate("/app/admin/pharmacy-category", {
      state: {
        profileid: object.profileid,
        name: object.name,
      },
    });
  }

  async function createslot(object) {
  

    navigate("/app/admin/create-slot", {
      state: {
        profileid: object.profileid,
        name: object.name,
      },
    });
  }

  async function createaddress(object) {
  

    navigate("/app/admin/new-adress", {
      state: {
        profileid: object.profileid,
        name: object.name,
      },
    });
  }
 

  function getData(code) {
    setLoading(true);
    const dataObj = {
      columns: [
        { Header: "name", accessor: "name" },
        { Header: "Role", accessor: "rolename" },
        
        { Header: "category", accessor: "action" },
        { Header: "Address", accessor: "address" },

        { Header: "slot", accessor: "slot" },
      ],
      rows: [],
    };
    axios
      .get(`login-service-mongo/api/loginservice/v2/get?rolecode=${code}`)
      .then((response) => {
        response.data.data.dtoList.map((item) => {
          item.action = (
            <span>
              <MDButton
                color="info"
                size="small"
                onClick={() => {
                  onUpdatedata(item);
                }}
              >
                Add Category
              </MDButton>
            </span>
          );
          item.slot = (
            <span>
              <MDButton
                color="info"
                size="small"
                onClick={() => {
                  createslot(item);
                }}
              >
                Add slot
              </MDButton>
            </span>
          );
          item.address = (
            <span>
              <MDButton
                color="info"
                size="small"
                onClick={() => {
                  createaddress(item);
                }}
              >
                Add Address
              </MDButton>
            </span>
          );
         
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getData("PH257");
  }, []);
  return (
    <DashboardLayout>
    
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Pharmacy Detail
                  </MDTypography>
                </span>

                {/* <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={"/pages/profile/create-role"}
                >
                  <MdOutlineLibraryAdd />
                </Link> */}
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}




export default AddPharmacyDetail;