import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    gender,
    firstName,
    age,
    email,
    mobilenumber,
    relation

  },
} = checkout;

const validations = [

  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    [relation.name]: Yup.string().required(relation.errorMsg),
    [age.name]: Yup.string().required(age.errorMsg),
    // [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [mobilenumber.name]: Yup.string()
      .nullable()
      .matches(/^\d{10}$/, mobilenumber.invalidMsg)

  }),
];

export default validations;





