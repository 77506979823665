import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { aggregationListAction } from "redux/Actions/aggregationListAction";

const initialState = {
  list: [],
  loading: false,
  error: null,
};

export const getAggregationListSlice = createSlice({
  name: "getAggregationList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(aggregationListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(aggregationListAction.fulfilled, (state, action) => {
       
        state.loading = false;
      state.list = action.payload?.visitAggregationDTOList;
        state.totalPages = action.payload?.page?.totalPages
        state.error = null;
      })
      .addCase(aggregationListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getAggregationListSlice.reducer;