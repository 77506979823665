// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";

import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";

function OperationInfo({ formData }) {
  const dispatch = useDispatch();
  const { setFieldValue, setFieldError } = useFormikContext();

  const [color] = useState("#344767");

  const { formField, values, errors, touched } = formData;

  const { name } = formField;
  var { name: nameV } = values;

 
  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Operation Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={name.type}
                label=<span>
                  {name.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={name.name}
                value={nameV}
                placeholder={name.placeholder}
                error={errors.name && touched.name}
                success={nameV.length > 0 && !errors.name}
              />
            </Grid>
          

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel
                htmlFor="operation"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
              Operation<sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="operation"
                id="operation"
                value={values.operation}
                onChange={(e) => {
                  const operation = e.target.value;

                  setFieldValue("operation", operation);
                }}
              >
                <option value=""></option>
                <option value="Debit">Debit</option>
                <option value="Credit">Credit</option>
              </Select>
              {errors.operation && touched.operation && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.operation}
                  </FormHelperText>
                )}
            </FormControl>
          </Grid>
          </Grid>
        </MDBox>
    
    </MDBox>
  );
}

// typechecking props for OperationInfo
OperationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default OperationInfo;
