
import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";

import { useDispatch } from "react-redux";
import { ErrorMessage, useFormik } from "formik";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import Grid from "@mui/material/Grid";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@material-ui/core";
import { useSelector } from "react-redux";


import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";

import { getState } from "components/Resuable/StateMaster/service/State";
import { getDistrict } from "components/Resuable/DistrictMaster/service/District";
import { getSubDistrict } from "components/Resuable/subDistrict/service/SubDistirct";
import { getPincode } from "components/Resuable/CreatePincode/service/Pincode";
import CircularProgress from '@mui/material/CircularProgress';

import { APIs } from "Services/APIs";
import axios from "../../../../axiosinstance"
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

// import { updateDesignation } from "../service/Designation";




const validationSchema = Yup.object().shape({

  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  subDistrict: Yup.string().required("Sub district is required"),
      villagecode: Yup.string().required("Village code is required."),
      villagename: Yup.string().required("Village name is required."),
      shortname: Yup.string().required("Village name is required."),
      pincode:Yup.string().required("Pincode  is required."),


});

const VillageEdit = (props) => {
  const dispatch = useDispatch();
  // const { 
  //   title,
  //   selectedUser,
  //   openpopup,
  //   setopenpopup,
  //   handleClose,
  
   
 // } = props;
    const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  // const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);
  const { department } = useSelector((state) => state.department);
  const [loadingfilter, setLoadingfilter] = useState(true);

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { statelist } = useSelector((state) => state.statelist);
  const [loading, setIsLoading] = useState(false);
  const [loading1, setIsLoading1] = useState(false);

  // const { district } = useSelector((state) => state.district);
  const [district, setDistric] = useState([]);
  const [subdistrict, setSubDistrict] = useState([]);
  const [pincodelist, setPincodeList] = useState([]);
  const [create, setProjectList] = useState([]);

  const onSubmit = async (values) => {
  
    setIsLoading1(true);
    const updatedUser = {
     ...selectedUser,
      village: {
        code: values.villagecode,
        shortname: values.shortname,
        name: values.villagename,
      },
      pincode: values.pincode,
      subdistrict: values.subdistrictobj ? values.subdistrictobj.subdistrict : selectedUser.subdistrict,
      district: values.selectedDistrictobj ? values.selectedDistrictobj.district :selectedUser.district,
      state: values.selectedStatetobj ?values.selectedStatetobj.state :selectedUser.state,
      type: "VILLAGE",
      modifiedby: auth.profileid,
      modifiedbyname: auth.name,
    };

 const response = await axios.put(`${APIs.pincodeServiceApi}/update`, updatedUser);

    if (!response.error) {
      setIsLoading(false);
      handleClose();
      handleEditreset()
     
      swal("Good job!", "Data has been updated successfully!", {
        icon: "success",
      });
     
    } else {
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading1(false);
      // handleClose()
    }
  };

  const initialValues = {
    state: selectedUser?.state?.code || "",
    district: selectedUser?.district?.code || "",
    subDistrict: selectedUser?.subdistrict?.code || "",
    villagecode: selectedUser?.village?.code || "",
    villagename:selectedUser?.village?.name || "",
    shortname:selectedUser?.village?.shortname || "",
    pincode:selectedUser?.pincode || ""
  };
  

 
  async function getDistrictData(statecode) {
    const response = await dispatch(getDistrict(statecode));

    setDistric(response.payload.data.dtoList);
    setSubDistrict([]);
 
  }

  async function getSubDistrictData(statecode, districtCode) {
    const data = {
      statecode: statecode,
      districtCode: districtCode,
    };

    const response = await dispatch(getSubDistrict(data));
    setSubDistrict(response.payload?.data?.dtoList);
    setPincodeList([]);
  }

 

  useEffect(() => {
    const fetchData = async () => {
      setLoadingfilter(true);

      try {
     
        dispatch(getState());
       
        const response = await dispatch(
          getDistrict(selectedUser?.state?.code)
        );
        setDistric(response.payload.data.dtoList);

        const data = {
          statecode: selectedUser?.state?.code,
          districtCode: selectedUser?.district?.code,
        };

        const responseDistric = await dispatch(getSubDistrict(data));
        setSubDistrict(responseDistric.payload?.data?.dtoList);

       
      } catch (error) {
        // Handle errors if needed
        console.error(error);
      } finally {
        setLoadingfilter(false); // Move setLoadingfilter inside the finally block
      }
    };

    fetchData();
  }, [selectedUser]);

  return (
    <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        {title}
      </DialogTitle>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleChange }) => (
          <Form>
            <DialogContent>
              {loadingfilter ? (
                <CircularProgress
                  style={{ position: "absolute", top: "50%", left: "50%" }}
                />
              ) : (
                <>
                  <Grid container spacing={3}>
                  
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.state && touched.state}
                      >
                        <InputLabel
                          htmlFor="state"
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          State
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="state"
                          id="state"
                          value={values.state}
                          onChange={(e) => {
                            const state = e.target.value;
                           
                          
                         
                            const selectedStatetobj =
                              statelist?.find(
                                (obj) => obj.state.code === e.target.value
                              ) || {};

                            getDistrictData(e.target.value);
                            setFieldValue("district", "");
                            setFieldValue("subDistrict", "");
                            setFieldValue("selectedStatetobj", selectedStatetobj);
                            setFieldValue("state", state);
                          }}
                        >
                          <option value=""></option>
                          {statelist?.map((obj) => (
                            <option key={obj?.id} value={obj?.state?.code}>
                              {obj?.state?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.state && touched.state && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.state}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                 

                
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.district && touched.district}
                      >
                        <InputLabel
                          htmlFor="district"
                          shrink={Boolean(values.district)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="district"
                          id="district"
                          value={values.district}
                          onChange={(e) => {
                            const districtvalue = e.target.value;
                            setFieldValue("subDistrict", "");
                         
                            const selectedDistrictobj =
                              district?.find(
                                (obj) => obj.district.code === e.target.value
                              ) || {};
                            getSubDistrictData(
                              selectedDistrictobj.state?.code,
                              selectedDistrictobj.district?.code
                            );
                            setFieldValue("selectedDistrictobj", selectedDistrictobj);
                            setFieldValue("district", districtvalue);
                          }}
                        >
                          <option value=""></option>
                          {district?.map((obj) => (
                            <option key={obj?.id} value={obj?.district?.code}>
                              {obj?.district?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.district && touched.district && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.district}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl
                        fullWidth
                        error={!!errors.subDistrict && touched.subDistrict}
                      >
                        <InputLabel
                          htmlFor="subDistrict"
                          shrink={Boolean(values.subDistrict)}
                          style={{
                            paddingBottom: "2px",
                            marginTop: "2px",
                          }}
                        >
                          Sub District
                          <sup
                            style={{
                              color: "red",
                              fontSize: "small",
                              fontWeight: "bolder",
                              position: "relative",
                              top: "2px",
                            }}
                          >
                            *
                          </sup>
                        </InputLabel>
                        <Select
                          native
                          variant="standard"
                          style={{ width: "100%" }}
                          name="subDistrict"
                          id="subDistrict"
                          value={values.subDistrict}
                          onChange={(e) => {
                            const subDistrictvalue = e.target.value;
                            const selectedSubObj =
                            subdistrict.find(
                              (obj) => obj.subdistrict?.code === e.target.value
                            ) || {};
                            setFieldValue("subdistrictobj", selectedSubObj);
                            setFieldValue("subDistrict", subDistrictvalue);
                          }}
                        >
                          <option value=""></option>
                          {subdistrict?.map((obj) => (
                            <option
                              key={obj?.id}
                              value={obj?.subdistrict?.code}
                            >
                              {obj?.subdistrict?.name}
                            </option>
                          ))}
                        </Select>
                        {errors.subDistrict && touched.subDistrict && (
                          <FormHelperText error style={{ marginLeft: "0px" }}>
                            {errors.subDistrict}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                   
                    <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Village Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="villagename"
                            value={values.villagename}
                            onChange={handleChange}
                            error={errors.villagename && touched.villagename}
                            success={
                              values.villagename != null && !errors.villagename
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="villagename"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                                Village Code
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="villagecode"
                            value={values.villagecode}
                            onChange={handleChange}
                            error={errors.villagecode && touched.villagecode}
                            success={
                              values.villagecode != null && !errors.villagecode
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="villagecode"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                         <Grid item xs={12} sm={6}>
                         <MDInput
                           type="text"
                           label=<span>
                             Short Name
                             <sup
                               style={{
                                 color: "red",
                                 fontSize: "small",
                                 fontWeight: "bolder",
                                 position: "relative",
                                 top: "2px",
                               }}
                             >
                               {" "}
                               *
                             </sup>
                           </span>
                           variant="standard"
                           fullWidth
                           name="shortname"
                           value={values.shortname}
                           onChange={handleChange}
                           error={errors.shortname && touched.shortname}
                           success={
                             values.shortname != null && !errors.shortname
                           }
                         />
                         <div
                           style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                           }}
                         >
                           <ErrorMessage
                             name="shortname"
                             component="div"
                             style={{
                               color: "red",
                               fontSize: "12px",
                               textAlign: "left",
                               marginTop: "4px",
                             }}
                           />
                         </div>
                       </Grid>
                       <Grid item xs={12} sm={6}>
                         <MDInput
                           type="number"
                           label=<span>
                             Pincode
                             <sup
                               style={{
                                 color: "red",
                                 fontSize: "small",
                                 fontWeight: "bolder",
                                 position: "relative",
                                 top: "2px",
                               }}
                             >
                               {" "}
                               *
                             </sup>
                           </span>
                           variant="standard"
                           fullWidth
                           name="pincode"
                           value={values.pincode}
                           onChange={handleChange}
                           error={errors.pincode && touched.pincode}
                           success={values.pincode != null && !errors.pincode}
                         />
                         <div
                           style={{
                             display: "flex",
                             flexDirection: "column",
                             alignItems: "flex-start",
                           }}
                         >
                           <ErrorMessage
                             name="pincode"
                             component="div"
                             style={{
                               color: "red",
                               fontSize: "12px",
                               textAlign: "left",
                               marginTop: "4px",
                             }}
                           />
                         </div>
                       </Grid>
                   </Grid>
                </>
              )}
            </DialogContent>

            <DialogActions>
              <MDButton onClick={handleClose} color="light" size="small">
                Cancel
              </MDButton>

              <MDButton type="submit" color="info" size="small">
                {loading1 ? <MoonLoader color="#f2fefa" size={16} /> : "UPDATE"}
              </MDButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};







export default VillageEdit;
