import GeneralSurgery from "../assets/images/General-Surgery.png";
import OrthopedicSurgery from "../assets/images/OrthopedicSurgery.png";
import Neurosurgery from "../assets/images/neurosuregery.png";
import CardiothoracicSurgery from "../assets/images/cardiothoracicsurgery.png";
import PlasticSurgery from "../assets/images/PlasticSurgery.png";
import Urology from "../assets/images/Urology.png";
import Cardiology from "../assets/images/Cardiology.png";
import Dermatology from "../assets/images/dermatology.png";
import Endocrinology from "../assets/images/Endocrinology.png";
import Gastroenterology from "../assets/images/gastroenterology.png";
import Hematology from "../assets/images/hematologyOncology.png";
import Infectious from "../assets/images/InfectiousDisease.png";
import Nephrology from "../assets/images/Nephrology.png";
import Pulmonology from "../assets/images/pulmonologyIcon.png";
import Rheumatology from "../assets/images/Rheumatology.png";


const categoryimages = {
    "General Surgery": GeneralSurgery,
    "Orthopedic Surgery": OrthopedicSurgery,
    "Neurosurgery": Neurosurgery,
    "Cardiothoracic Surgery": CardiothoracicSurgery,
    "Plastic Surgery": PlasticSurgery,
    "Urology": Urology,
    "Cardiology": Cardiology,
    "Dermatology": Dermatology,
    "Endocrinology": Endocrinology,
    "Gastroenterology": Gastroenterology,
    "Hematology/Oncology": Hematology,
    "Infectious Disease": Infectious,
    "Nephrology": Nephrology,
    "Pulmonology": Pulmonology,
    "Rheumatology": Rheumatology,

};

export default categoryimages;
