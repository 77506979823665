// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components

import FormField from "../FormField";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { BarLoader } from "react-spinners";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { useFormikContext } from "formik";

function NurseInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [genderValue, setGenderValue] = useState(null);
  const [genderList, setGenderList] = useState("");
  const [color] = useState("#344767");
  const { setFieldValue, setFieldError } = useFormikContext();

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    email,
    gender,
    description,
    phonenumber,
    experience,
    age,
    password,
    repeatPassword,
  } = formField;
  var {
    firstName: firstNameV,
    lastName: lastNameV,
    email: emailV,
    phonenumber: phonenumberV,
    username: usernameV,

    description: descriptionV,
    age: ageV,
    experience: experienceV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  const defaultPropsGender = {
    options: !genderList
      ? [
          { name: "Male", code: "X" },
          { name: "Female", code: "y" },
        ]
      : genderList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  return (
    <MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                error={errors.lastName && touched.lastName}
                //.success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
            </Grid>
             <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={age.type}
                label=<span>
                  {age.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.gender && touched.gender}>
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={(e) => {
                    const gender = e.target.value;

                    setFieldValue("gender", gender);
                  }}
                >
                  <option value=""></option>

                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </Select>
                {errors.gender && touched.gender && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            </Grid>
            <Grid container spacing={3}>
 <Grid item xs={12} sm={6}>
 <FormField
   // type={experience.type}
   label=<span>
     {experience.label}{" "}
     <sup
       style={{
         color: "red",
         fontSize: "small",
         fontWeight: "bolder",
         position: "relative",
         top: "2px",
       }}
     >
       {" "}
       *
     </sup>
   </span>
   name={experience.name}
   value={experienceV}
   placeholder={experience.placeholder}
   error={errors.experience && touched.experience}
   success={experienceV.length > 0 && !errors.experience}
   InputLabelProps={{
     style: { paddingBottom: "2px" },
   }}
   onKeyDown={(e) => {
     if (
       !/[0-9\b\t]/.test(e.key) &&
       ![
         "ArrowLeft",
         "ArrowRight",
         "ArrowUp",
         "ArrowDown",
         "Delete",
         "Backspace",
         "Tab",
       ].includes(e.key)
     ) {
       e.preventDefault();
     }
   }}
 />
</Grid>
</Grid>
<Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label=<span>
                  {email.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={phonenumber.type}
                label=<span>
                  {phonenumber.label}{" "}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={phonenumber.name}
                value={phonenumberV}
                placeholder={phonenumber.placeholder}
                error={errors.phonenumber && touched.phonenumber}
                success={phonenumberV.length > 0 && !errors.phonenumber}
                InputLabelProps={{
                  style: { paddingBottom: "2px" },
                }}
                onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
              />
            </Grid>
            </Grid>
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label=<span>
                  {password.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label=<span>
                  {repeatPassword.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>

           

            {/* <Grid item xs={12} sm={6}>
            <FormField
            multiline rows={1} 
              type={description.type}
              label={description.label}
              name={description.name}
              value={descriptionV}
              placeholder={description.placeholder}
              error={errors.description && touched.description}
              success={descriptionV.length > 0 && !errors.description}
            />
             
          </Grid> */}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for NurseInfo
NurseInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default NurseInfo;
