import React, { useEffect, useState } from 'react'
import '../MedicalStore/stylea.css'
import { Button, Grid } from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import mrdicineimge from'../../findDoctor/assets/images/medicine03.png'
import Text02 from './Text02';
import Btnstore from './Btnstore';
const MedicineCards = () => {
  const Data1 = [
    {
     
      
      
       title: 'Antibiotic',
     role: 'for adults',
      label:'Starting at $150',
      gram:'250mg',
       buttonA:"Add to card",
     buttonB:"Buy now",
      image:mrdicineimge,
    },
    {
  
       title: 'Antibiotic',
     role: 'for adults',
      label:'Starting at $150',
      gram:'250mg',
       buttonA:"Add to card",
     buttonB:"Buy now",
      image: mrdicineimge,
    },
    {
  
       title: 'Antibiotic',
     role: 'for adults',
      label:'Starting at $150',
      gram:'250mg',
        buttonA:"Add to card",
      buttonB:"Buy now",
      image: mrdicineimge,
    },
    {
      
      title: 'Antibiotic',
     role: 'for adults',
      label:'Starting at $150',
      gram:'250mg',
        buttonA:"Add to card",
      buttonB:"Buy now",
      image: mrdicineimge,
    },
    {
     
       title: 'Antibiotic',
     role: 'for adults',
      label:'Starting at $150',
      gram:'250mg',
        buttonA:"Add to card",
      buttonB:"Buy now",
      description:'Depression, Anxiety, OCD, Anxiety, Phobias',
      image: mrdicineimge,
    },
  
  ];
  const [showTopButtnSliderA, setShowTopButtnSliderA] = useState(window.innerWidth <= 1000);
  useEffect(() => {
    const handleResize = () => {
      setShowTopButtnSliderA(window.innerWidth <= 1000);
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3.2,
    centerPadding: '68px',
    centerMode: true,
    slidesToScroll: 1,
    customPaging: (i) => (
      <button className="custom-dot">{i + 1}</button>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          centerPadding: '1%',
        
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: '10%',
        
        },
      },
      {
        breakpoint: 912,
        settings: {
          slidesToShow: 2,
          centerPadding: '5%',
        
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerPadding: '10px',
        },
      },
      
      {
        breakpoint: 758,
        settings: {
          slidesToShow: 1,
          centerPadding: '10%',
        },
      },
      {
        breakpoint: 655,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 650,
        settings: {
          dots: true,
          speed: 500,
          slidesToShow: 1.1,
          // centerMode: true,
          centerPadding: '6.9%',
          initialSlide: 0,
          slidesToScroll: 2,
          appendDots: (dots) => (
            <ul>
              {dots.slice(0, 3)}
            </ul>
          ),     
        },
        
      },
    ],
  };
  return (
    <div>
      <Grid className='Main-D-02'>
      <div  >
      {<Text02/>}
    </div>
    {!showTopButtnSliderA && <Btnstore/>}
    </Grid>
    <div className='Main-HW1' >
    <Slider {...sliderSettings}>
      {Data1.map((item, index) => (
          <div key={index}>
          <Grid className='medicine-border-1'>
            <div className='medicine-border-2'>
              <Grid className='medicine-border-3'>
                <img className='medicine-image-4' src={item.image} alt={`Card ${index}`} />
              </Grid>
              <Grid className='medicine-title-5'>
             <Grid className='medicine-title-5a'>{item.title}</Grid>
              <Grid className='medicine-title-5b'>{item.role}</Grid>
              </Grid>

              <Grid className='medicine-label'> 
                <Grid className='medicine-label-a'>{item.label}</Grid>
                <Grid className='medicine-label-a2'>{item.gram}</Grid>
                </Grid>
              <Grid className='btnmedi-card-A1' >
                 <Grid className='btnmedi-card-A2'>
                    <Button sx={{width:'120px',
                    color:"#039147",
                    backgroundColor:"#ffffff",
                    border:"1px solid #039147",
                     height:'29px', fontSize:'10px',
                     textTransform:'none',
                      lineHeight:'15px', fontWeight:"400",
                      '&:hover': {
                        backgroundColor: '#039147',
                        borderRadius:"2px",
                        color: '#ffffff',
                      },
                    }}
                      >{item.buttonA}

                    </Button >
                 </Grid>
                 <Grid className='btnmedi-card-B1'>
                    <Button sx={{width:'120px',
                    color:"#ffffff",
                    backgroundColor:"#039147",
                    border:"1px solid #039147",
                     height:'29px', fontSize:'10px',
                     textTransform:'none',
                      lineHeight:'15px', fontWeight:"400",
                      '&:hover': {
                        backgroundColor: '#ffffff',
                        borderRadius:"2px",
                        color: '#039147',
                      },
                    }}
                      >{item.buttonB}

                    </Button >
                 </Grid >
              </Grid>
              
              
            </div>
          </Grid>
        </div>
      ))}
    </Slider>
    </div>
    <div>
    {showTopButtnSliderA && <Btnstore/>}
      </div>
    </div>
  )
}

    


export default MedicineCards


// import React, { useEffect, useState } from 'react'
// import '../MedicalStore/stylea.css'
// import { Button, Grid } from '@mui/material';
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import mrdicineimge from'../images/medicine03.png'
// import Text02 from './Text02';
// import Btnstore from './Btnstore';
// const MedicineCards = () => {
//   const Data1 = [
//     {
     
      
      
//        title: 'Antibiotic',
//      role: 'for adults',
//       label:'Starting at $150',
//       gram:'250mg',
//        buttonA:"Add to card",
//      buttonB:"Buy now",
//       image:mrdicineimge,
//     },
//     {
  
//        title: 'Antibiotic',
//      role: 'for adults',
//       label:'Starting at $150',
//       gram:'250mg',
//        buttonA:"Add to card",
//      buttonB:"Buy now",
//       image: mrdicineimge,
//     },
//     {
  
//        title: 'Antibiotic',
//      role: 'for adults',
//       label:'Starting at $150',
//       gram:'250mg',
//         buttonA:"Add to card",
//       buttonB:"Buy now",
//       image: mrdicineimge,
//     },
//     {
      
//       title: 'Antibiotic',
//      role: 'for adults',
//       label:'Starting at $150',
//       gram:'250mg',
//         buttonA:"Add to card",
//       buttonB:"Buy now",
//       image: mrdicineimge,
//     },
//     {
     
//        title: 'Antibiotic',
//      role: 'for adults',
//       label:'Starting at $150',
//       gram:'250mg',
//         buttonA:"Add to card",
//       buttonB:"Buy now",
//       description:'Depression, Anxiety, OCD, Anxiety, Phobias',
//       image: mrdicineimge,
//     },
  
//   ];
//   const [showTopButtnSliderA, setShowTopButtnSliderA] = useState(window.innerWidth <= 1000);
//   useEffect(() => {
//     const handleResize = () => {
//       setShowTopButtnSliderA(window.innerWidth <= 1000);
//     };
//     handleResize();
//     window.addEventListener('resize', handleResize);
//     return () => {
//       window.removeEventListener('resize', handleResize);
//     };
//   }, []);
//   const sliderSettings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3.2,
//     centerPadding: '68px',
//     centerMode: true,
//     slidesToScroll: 1,
//     customPaging: (i) => (
//       <button className="custom-dot">{i + 1}</button>
//     ),
//     responsive: [
//       {
//         breakpoint: 1200,
//         settings: {
//           slidesToShow: 2,
          
//         }
//       },
//       {
//         breakpoint: 762,
//         settings: {
//           slidesToShow: 1.5,
          
//         }
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1.1,
//           slidesToScroll: 1,
//           centerPadding: '5.9%',
//         }
//       },
//       {
//         breakpoint: 594,
//         settings: {
//           slidesToShow: 1.1,
//           slidesToScroll: 1,
//           centerPadding: '12%',
//         }
//       },
  
//       {
//         breakpoint: 650,
//         settings: {
//           dots: true,
//           speed: 500,
//           slidesToShow: 1.1,
//           // centerMode: true,
//           centerPadding: '5.9%',
//           initialSlide: 0,
//           slidesToScroll: 2,
//           appendDots: (dots) => (
//             <ul>
//               {dots.slice(0, 3)}
//             </ul>
//           ),     
//         },
        
//       },
//     ],
//   };
//   return (
//     <div>
//       <Grid className='Main-D-01'>
//       <div  >
//       {<Text02/>}
//     </div>
//     {!showTopButtnSliderA && <Btnstore/>}
//     </Grid>
//     <div className='Main-HW' >
//     <Slider {...sliderSettings}>
//       {Data1.map((item, index) => (
//           <div key={index}>
//           <Grid className='medicine-border-1'>
//             <div className='medicine-border-2'>
//               <Grid className='medicine-border-3'>
//                 <img className='medicine-image-4' src={item.image} alt={`Card ${index}`} />
//               </Grid>
//               <Grid className='medicine-title-5'>
//              <Grid className='medicine-title-5a'>{item.title}</Grid>
//               <Grid className='medicine-title-5b'>{item.role}</Grid>
//               </Grid>

//               <Grid className='medicine-label'> 
//                 <Grid className='medicine-label-a'>{item.label}</Grid>
//                 <Grid className='medicine-label-a2'>{item.gram}</Grid>
//                 </Grid>
//               <Grid className='btnmedi-card-A1' >
//                  <Grid className='btnmedi-card-A2'>
//                     <Button sx={{width:'120px',
//                     color:"#039147",
//                     backgroundColor:"white",
//                     border:"1px solid #039147",
//                      height:'29px', fontSize:'10px',
//                      textTransform:'none',
//                       lineHeight:'15px', fontWeight:"400",
//                       '&:hover': {
//                         backgroundColor: '#039147',
//                         borderRadius:"2px",
//                         color: 'white',
//                       },
//                     }}
//                       >{item.buttonA}

//                     </Button >
//                  </Grid>
//                  <Grid className='btnmedi-card-B1'>
//                     <Button sx={{width:'120px',
//                     color:"white",
//                     backgroundColor:"#039147",
//                     border:"1px solid #039147",
//                      height:'29px', fontSize:'10px',
//                      textTransform:'none',
//                       lineHeight:'15px', fontWeight:"400",
//                       '&:hover': {
//                         backgroundColor: 'white',
//                         borderRadius:"2px",
//                         color: '#039147',
//                       },
//                     }}
//                       >{item.buttonB}

//                     </Button >
//                  </Grid >
//               </Grid>
              
              
//             </div>
//           </Grid>
//         </div>
//       ))}
//     </Slider>
//     </div>
//     <div>
     
//       </div>
//     </div>
//   )
// }

    


// export default MedicineCards

