import DashboardLayout from "components/DashboardLayout";
import DashboardNavbar from "components/Shared/DashboardNavbar";
import React, { Suspense, useEffect } from "react";
import { useParams } from "react-router-dom";
import Sidenav from "components/Sidenav";
import { useMaterialUIController } from "context";
import brandWhite from "assets/images/Medharva-logo.svg";
import brandDark from "assets/images/Medharva-logo.svg";
import { micRoutes } from "routes/micRoutes";
import AnalyticsGraph from "components/Analytics";
import MicProfile from "./profile/micProfile";
import MicAgeant from "./agent";
import ProfileCard from "components/profileCard";
import MicNurse from "./nurse";
import MicDoctor from "./doctor";
import PincodeAssociationList from "./micCreation/pinccodeAssociation/PincodeAssociationList";
import TeamAssociationList from "./micCreation/teamAssociation/TeamAssociationList";
import MicList from "./micCreation/createMic/MicList";
import Userlist from "./usermanagement/CreateUser/Userlist";
import RoleList from "./usermanagement/Role/RoleList";
import ApprovalList from "./usermanagement/approvalList";
import ViewProfile from "components/viewProfile";
import UnderConstruction from "components/underConstruction/UnderConstruction";
import DepartmentList from "components/Shared/department/DepartmentList";
import DesignationList from "components/Shared/designation/DesignationList";
import HomeSidenavSkeleton from "components/Skeleton/HomeSidenavSkeleton";
import { projectDetailsAction } from "redux/Actions/projectDetailsAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const MicLayout = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getProjectDetails } = useSelector((state) => state);
  const [controller] = useMaterialUIController();
  const { slug } = useParams();

  const {
    miniSidenav,
    direction,
    layout,
    openConfigurator,
    sidenavColor,
    transparentSidenav,
    whiteSidenav,
    darkMode,
  } = controller;

  useEffect(() => {
    dispatch(projectDetailsAction({ profileId: auth.profileid }));
  }, [auth]);

  return (
    <>
      <Suspense fallback={<HomeSidenavSkeleton />}>
        <DashboardLayout>
          <div style={{ position: "fixed", width: "100%", zIndex: "1000" }}>
            <DashboardNavbar />
          </div>
          <Sidenav
            color={sidenavColor}
            brand={
              (transparentSidenav && !darkMode) || whiteSidenav
                ? brandDark
                : brandWhite
            }
            brandName="Medharva"
            routes={micRoutes}
          />
        </DashboardLayout>

        {slug === "profile" && <MicProfile />}
        {slug === "user" && <Userlist />}
        {slug === "approval-list" && <ApprovalList />}
        {slug === "role" && <RoleList />}
        {slug === "create-department" && <DepartmentList />}
        {slug === "create-designation" && <DesignationList />}
        {slug === "agents" && <MicAgeant />}
        {slug === "nurse" && <MicNurse />}
        {slug === "doctors" && <MicDoctor />}
        {slug === "create-mic" && <MicList />}
        {slug === "pincode-association-list" && <PincodeAssociationList />}
        {slug === "team-association-list" && <TeamAssociationList />}
        {slug === "view-profile" && <ViewProfile />}
        <DashboardLayout>
          {slug === "clinicuser" && <UnderConstruction />}
          {slug === "clinicrole" && <UnderConstruction />}
          {slug === "admin" && <UnderConstruction />}
          {slug === "pharmacies" && <UnderConstruction />}
          {slug === "labs" && <UnderConstruction />}
        </DashboardLayout>
      </Suspense>
    </>
  );
};

export default MicLayout;
