import React, { useEffect, useState } from "react";
import { Grid, Card, useMediaQuery, Avatar } from "@mui/material";
import "./style1.css";
import Button from "@mui/material/Button";
import image1 from "../../findDoctor/assets/images/select122345.png";
import image2 from "../../findDoctor/assets/images/Search.svg";
import image3 from "../../findDoctor/assets/images/2837cae30b79f85d7688656edd3d843f.png";
import image4 from "../../findDoctor/assets/images/filtersort.svg";
import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";

import DropdownFilter from "./dropdownfilter";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { useDispatch } from "react-redux";
import { labAddCartAction } from "redux/Actions/labAddCart";

const options = ["Option 1", "Option 2", "Option 3"];
const SelectLabTest = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [count, setCount] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [value, setValue] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 990);
  const dispatch = useDispatch();
  const { getAddLabCart } = useSelector(state => state);

  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 990);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [labTest, setLabTest] = useState([]);

  const [userLocation, setUserLocation] = useState(null);

  const handleGetLocation = () => {
    const patientLocation = localStorage.getItem("userLocation");
    // if (!patientLocation) {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         const { latitude, longitude } = position.coords;
    //         setUserLocation({ latitude, longitude });
    //       },
    //       (error) => {
    //         console.error("Error getting user location:", error.message);
    //       }
    //     );
    //   } else {
    //     console.error("Geolocation is not supported by this browser.");
    //   }
    // }

    if (getAddLabCart.userCoordinate<=0) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            setUserLocation({ latitude, longitude });
            dispatch(labAddCartAction({ ...getAddLabCart, userCoordinate: [latitude, longitude] }))
          },
          (error) => {
            console.error("Error getting user location:", error.message);
          }
        );
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    }
  };
  useEffect(() => {
    handleGetLocation();
  }, []);

  useEffect(() => {
    if (userLocation) {
      localStorage.setItem("userLocation", JSON.stringify(userLocation));
    }
  }, [userLocation]);

  async function getLabTest() {
    setLoading(true);
    await axios
      .get(`category-service/api/Category/v1/get?type=labtest`)
      .then((response) => {
        setLabTest(response?.data?.data?.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }

  useEffect(() => {
    getLabTest();
    
  }, []);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleAddToCart = (item) => {
    const itemIndex = getAddLabCart?.selectedTestType?.findIndex(
      (selected) => selected.id === item.id
    );
  
    let updatedItems = [];
    let newCount = getAddLabCart.count;
    let newTotalWeight = getAddLabCart.totalPrice;
  
    if (itemIndex === -1) {
      // Add item to cart
      updatedItems = [...getAddLabCart?.selectedTestType, item];
      newCount += 1;
      newTotalWeight += parseInt(item.value);
    } else {
      // Remove item from cart
      updatedItems = getAddLabCart?.selectedTestType?.filter(
        (selected) => selected.id !== item.id
      );
      newCount -= 1;
      newTotalWeight -= parseInt(item.value);
    }
  
    // Update cart state
    dispatch(labAddCartAction({
      ...getAddLabCart,
      selectedTestType: updatedItems,
      count: newCount,
      totalPrice:newTotalWeight,
      labs: [], 
      selectedPrices: [], 
    }));
  
    setSelectedItems(updatedItems);
    // setTotalWeight(newTotalWeight);
  };
  const getButtonText = (item) => {
    const isSelected = getAddLabCart?.selectedTestType?.some((selected) => selected.id === item.id);
    if (isSelected) {
      const count = getAddLabCart?.selectedTestType?.filter((selected) => selected.id === item.id).length;
      return `Remove from cart (${count})`;
    }
    return 'Add to cart';
  };  
  
  const handleSubmit = () => {
    if (getAddLabCart?.selectedTestType?.length === 0) {
      swal("Oops...!", "Add Atleast one test  in cart!", "error");

      return;
    }

    localStorage.setItem(
      "labtest-code",
      getAddLabCart?.selectedTestType?.map((item) => item.code)
    );
    dispatch(labAddCartAction({ ...getAddLabCart, labtestCode:getAddLabCart?.selectedTestType?.map((item) => item.code)}))
    // alert([{ selectedItems }]);
    navigate(`/app/${auth?.rolename?.toLowerCase()}/select-lab`, {
      state: {
        item: getAddLabCart?.selectedTestType,
        // cordinates: location?.state?.cordinates,
        cordinates: userLocation,
      },
    });
  };

  const filteredData = labTest?.filter((person) => {
    try {
      return person?.name?.toLowerCase().includes(searchQuery.toLowerCase());
    } catch (error) {
      console.error("Error filtering data:", error);
      return false;
    }
  });

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const Productd = ({ price, discount }) => {
    return (
      <div className="spandiscount11">
        <span className="spandiscount">{discount}</span>
      </div>
    );
  };
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
  return (
    <>
      <div className="personclass">
        <Grid container spacing={1} mt={23} className="select1">
          <Grid
            container
            item
            xs={12}
            justifyContent={isSmallScreen ? "center" : "flex-start"}
            ml={isSmallScreen ? 0 : 6}
          >
            {isSmallScreen ? (
              <h2 className="selectlabh2">Add Lab Test</h2>
            ) : (
              <h2 className="selectlabh2">Select Lab Test</h2>
            )}
          </Grid>
          <Grid item mt={2} className="searchlabtest1">
            <input
              type="text"
              id="searchlabtestinput"
              placeholder="Search for lab tests"
              className="searchlabtestinput"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <button className="searchlabtestbutton1">
              <img
                src={image2}
                alt="Search Logo"
                className="searchlabtestimg1"
              />
            </button>
          </Grid>
          <Grid
            container
            item
            xs={12}
            mt={1}
            className="sortAndFilterContainer"
            sx={{ justifyContent: "left", alignItems: "center", gap: "10px" }}
          >
            <div>
              <DropdownFilter
                options={options}
                value={value}
                onChange={handleChange}
              />
            </div>
            <div>
              <Button
                variant="outlined"
                className="button11"
                endIcon={<Avatar src={image4} sx={{ width: 14, height: 14 }} />}
                sx={{
                  width: "auto",
                  height: "38px",
                  borderRadius: "4px",
                  fontSize: "12px",
                  color: "#000000",
                  background: "#ffffff",
                  border: "2px solid #039147",
                  borderColor: "#039147",
                  textTransform: "none",
                  "&:hover": {
                    background: "#039147",
                    color: "#ffffff",
                    border: "2px solid #039147",
                  },
                }}
              >
                Filter
              </Button>
            </div>
          </Grid>

          {filteredData?.map((person, index) => (
            <Card
              key={index}
              className="selectcard"
              sx={{ borderRadius: "8px" }}
            >
              <Grid container spacing={2}>
                <Grid container item xs={12} className="grid11">
                  <Grid item xs={6} className="grid11">
                    <h2 className="selecth3">
                      {" "}
                      {person.name}
                      <br />
                      <span>{person.desc}</span>{" "}
                    </h2>
                  </Grid>
                  <Grid item xs={6} mt={1} className="grid38">
                    {/* <img className="profileimg" alt={person.image1} src={person.profileUrl1} /> */}
                    {isSmallScreen ? (
                      <img
                        className="profileimg"
                        alt="image logo"
                        src={image3}
                      />
                    ) : (
                      <img
                        className="profileimg"
                        alt="image logo"
                        src={image1}
                      />
                    )}
                  </Grid>
                </Grid>
                <Grid container item xs={12} className="grid14">
                  <Grid item xs={7} className="grid18">
                    <h2 className="selecth44">
                      <span>
                        Starting from&nbsp;<b>₹{person.value}</b>
                      </span>
                    </h2>
                    {/* <Grid item xs={3} mt={-1} className='grid18'>

                                        <Productd discount="27% off" />
                                    </Grid> */}
                  </Grid>
                  <Grid item xs={5} className="grid17">
                    <Button
                      variant="outlined"
                      onClick={() => handleAddToCart(person)}
                      className="button11"
                      sx={{
                        width: { xs: 110, sm: 138, md: 140, lg: 145, xl: 145 },
                        height: { xs: 29, sm: 34, md: 36, lg: 41, xl: 41 },
                        borderRadius: "100px",
                        fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                        color: "#039147",
                        border: "2px solid #039147",
                        borderColor: "#039147",
                        textTransform: "none",
                        "&:hover": {
                          background: "#039147",
                          color: "#ffffff",
                          border: "2px solid #039147",
                        },
                      }}
                    >
                      {/* {getAddLabCart?.selectedTestType?.filter((item) => item.id === person.id)
                        .length > 0
                        ? `Remove from cart (${
                          getAddLabCart?.selectedTestType?.filter(
                              (item) => item.id === person.id
                            ).length
                          })`
                        : "Add to cart"} */}
                        {getButtonText(person)}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          ))}
          <Grid
            container
            item
            xs={12}
            sm={12}
            mt={15}
            mb={isMidScreen ? 10 : undefined}
            sx={{ display: "flex", justifyContent: "center", gap: "10px" }}
          >
            <Button
              variant="text"
              className="button11"
              sx={{
                // width: "151px",
                width: { xs: 100, sm: 120, md: 120, lg: 130, xl: 151 },
                height: { xs: 33, sm: 35, md: 37, lg: 56, xl: 56 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                color: "#000000",
                background: "#fdfdfd",

                textTransform: "none",
              }}
            >
              {getAddLabCart?.count} items
            </Button>
            <Button
              variant="text"
              className="button11"
              sx={{
                // width: "151px",
                width: { xs: 100, sm: 120, md: 120, lg: 130, xl: 151 },
                height: { xs: 33, sm: 35, md: 37, lg: 56, xl: 56 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                lineHeight: "20",
                color: "#000000",
                background: "#fdfdfd",

                textTransform: "none",
              }}
            >
              {getAddLabCart?.totalPrice}
            </Button>
            <Button
              variant="outlined"
              onClick={handleSubmit}
              className="button11"
              sx={{
                width: { xs: 100, sm: 315, md: 400, lg: 700, xl: 1010 },
                height: { xs: 33, sm: 35, md: 37, lg: 56, xl: 56 },
                borderRadius: "8px",
                fontSize: { xs: 10, sm: 12, md: 13, lg: 15, xl: 15 },
                color: "#ffffff",
                background: "#039147",
                border: "2px solid #039147",
                borderColor: "#039147",
                textTransform: "none",
                "&:hover": {
                  background: "#FFFFFF",
                  color: "#039147",
                  border: "2px solid #039147",
                },
              }}
            >
              Select Lab
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
export default SelectLabTest;
