
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material'
import { Field, Form, Formik } from 'formik'
import * as Yup from 'yup';
import "./SignupAgeantstyle.css";
import { MoonLoader } from "react-spinners";
import axios from "../../../axiosinstance";


const SignupSellersAgeant = () => {
    const [isLoading, setIsLoading] = useState(false);




    const validationSchema = Yup.object({
        fullName: Yup.string()
            .required(' Full Name is required !'),

        age: Yup.string()
            .required('Age is required !'),

        phoneNumber: Yup.number()
            .required('Phone Number is required !'),

        highestEducationAchieved: Yup.string()
            .required('Highest Education Achieved  is required !'),

        primaryAddress: Yup.string()
            .required('Primary Address  is required !'),

            referralCode: Yup.string()
            .required('Referral Code  is required !'),

          

    });

    const handleRequestCallback = async (values, { setSubmitting, resetForm }) => {
        setIsLoading(true);
        const formData = {
            name: values.fullName,
            age: values.age,
            emailid: values.highestEducationAchieved,
            isactive: "INACTIVE",
            rolename: "AGENT",
            rolecode: "AG2773",
            description:values.primaryAddress,    
            mobileno: values.phoneNumber,
            experience:values.referralCode,
            profileid: "string",
            recstatus: "APPROVED",
            resourcecode: "HEALTHCARE",
            status: "ACTIVE",
            syncstatus: "synced",
            createdby:"",
          };
          await axios
          .post("login-service-mongo/api/loginservice/v2/create", formData)
    
          .then((response) => {
            swal(
              "Registration Successful!",
              "Your account has been created successfully.",
              "success"
            );
            console.log(values);
            resetForm();
          })
          .catch((error) => {
            console.log(error);
            swal("Oops!", 
            (error?.response && error?.response?.data && error?.response?.data?.aceErrors) ?
              (error?.response?.data?.aceErrors?.[0]?.errorCode === "K008" ?
                "User Already Registered with the same Email id !!" :
                error?.response?.data?.aceErrors?.[0]?.errorMessage) :
              "Request failed with status code 404.",
            "warning"
          );
          
        
        resetForm();
        setIsLoading(false);
    });
  setSubmitting(false);
  setIsLoading(false);
    };
    return (
        <div className='agent-medharwa-singup-lap-a'>
            <Grid className='agent-medharwa-singup-lap-b' >
           

                <Formik
                    initialValues={{

                        fullName: '',
                        age: '',
                        phoneNumber: '',
                        highestEducationAchieved: '',
                        primaryAddress: '',
                        referralCode: '',
                        idCard: '',
                    }}

                    onSubmit={handleRequestCallback}
                    validationSchema={validationSchema}

                >
                    {({ errors, touched }) => (
                        <Form >




                            <Grid mt={2} htmlFor="fullName" className='agent-label-registraion'>Full Name</Grid>
                            <Field
                                type="text"
                                id="fullName"
                                name="fullName"
                                className='agent-input-text-area'
                            />
                            {errors.fullName && touched.fullName && <div className="agent-error">{errors.fullName}</div>}
                            <Grid mt={2} htmlFor="age" className='agent-label-registraion'>Age</Grid>
                            <Field
                                type="number"
                                id="age"
                                name="age"
                                className='agent-input-text-area'
                            />
                            {errors.age && touched.age && <div className="agent-error">{errors.age}</div>}
                            <Grid mt={2} htmlFor="phoneNumber" className='agent-label-registraion'>Phone Number</Grid>
                            <Field
                                type="number"
                                id="phoneNumber"
                                name="phoneNumber"
                                className='agent-input-text-area'
                            />
                            {errors.phoneNumber && touched.phoneNumber && <div className="agent-error">{errors.phoneNumber}</div>}
                            <Grid mt={2} htmlFor="highestEducationAchieved" className='agent-label-registraion'>Highest Education Achieved</Grid>
                            <Field
                                type="text"
                                id="highestEducationAchieved"
                                name="highestEducationAchieved"
                                className='agent-input-text-area'
                            />
                            {errors.highestEducationAchieved && touched.highestEducationAchieved && <div className="agent-error">{errors.highestEducationAchieved}</div>}
                            <Grid mt={2} htmlFor="primaryAddress" className='agent-label-registraion'>Primary Address</Grid>
                            <Field
                                type="text"
                                id="primaryAddress"
                                name="primaryAddress"
                                className='agent-input-text-area'
                            />
                            {errors.primaryAddress && touched.primaryAddress && <div className="agent-error">{errors.primaryAddress}</div>}
                            <Grid mt={2} htmlFor="referralCode" className='agent-label-registraion'>Referral Code</Grid>
                            <Field
                                type="text"
                                id="referralCode"
                                name="referralCode"
                                className='agent-input-text-area'
                            />
                            {errors.referralCode && touched.referralCode && <div className="agent-error">{errors.referralCode}</div>}
                            <Grid mt={2} htmlFor="idCard " className='label-registraion-phramyicist-id'>ID Card </Grid>

                            <label className='input-text-area-phramyicist-Upload-documents' htmlFor="idCard"><input className='input-text-area-phramyicist-Upload-documents1' type="file" name="idCard" id="idCard" accept="image/*,.pdf"/>Upload documents</label>
                            {/* {errors.idCard && touched.idCard && <div className="agent-error">{errors.idCard}</div>} */}


                            <Grid container >
                               <Grid mt={2} className='ageant-agreePolicy'>
                                <span>
                                    By signing up, you agree to our
                                    <a href="/terms"> Terms</a>,
                                    <a href="/data-policy"> Data Policy </a>
                                    and <a href="/cookies-policy"> Cookies Policy</a>.
                                </span>
                                </Grid>
                            </Grid>

                            <Grid container mb={5} justifyContent={"center"}>
                                {/* <button type="submit" className='agent-singup-aply-btn'>Apply Now </button> */}
                                <button type="submit" className="agent-singup-aply-btn" disabled={isLoading}>
          {isLoading ? <MoonLoader color="#FFFFFF" size={20} /> : "Apply Now"}
        </button>
                            </Grid>
                        </Form>
                    )}
                </Formik>

            </Grid>
        </div>
    );
}

export default SignupSellersAgeant;