import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import list from "assets/theme/components/list";
import { bookingListAction } from "redux/Actions/bookingListAction";

const initialState = {
  list: [],
  doctorList: [],
  loading: false,
  error: null,
};

export const getBookingListSlice = createSlice({
  name: "getBookingList",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(bookingListAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(bookingListAction.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload?.dtoList;
        state.doctorList = [action.payload?.dtoList[0]?.doctor];
        state.totalPages= action.payload?.page?.totalPages
        state.error = null;
      })
      .addCase(bookingListAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getBookingListSlice.reducer;