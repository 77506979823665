import React from 'react'
// import medharwacare from '../images/findmedharwacare.png';
import medharwacare  from'../../findDoctor/assets/images/Findmedhra.svg'
import { Button, Grid } from '@mui/material';
import '../FindMedharwa/style.css'
import Slider from 'react-slick';
const Fmedharwa = () => {
    const cardsa = [
        { image: medharwacare },
        { image: medharwacare },
        { image: medharwacare },
      ];
    
      const sliderSettingsa = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
       
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: true,
              slidesToShow: 1,
               
            },
          },
        ],
      };
  return (
    <div>
      <Grid mt={23} mb={5}>
      <Slider {...sliderSettingsa} >
        {cardsa.map((card, index) => (
          <div key={index}>
            <Grid className='medharwa-care-card-A' >
              <Grid className='medharwa-care-card-B' >
                <img className='medharwa-care-card-imge' src={card.image} alt={`Card ${index + 1}`} />
              </Grid>
              <div className='medharwa-care-card-C' >
                <Grid className='medharwa-care-car-D' ><b>Find a Medharwa Care</b></Grid>
                <Grid className='medharwa-care-car-E'><b>Centre near you</b></Grid>
              </div>
            <Grid  className="medharwa-care-button-container" >
          <Grid className="medharwa-care-button">
          <Button>
          <span  className="medharwa-care-button-text"
       
            > Search centres</span> 
          </Button>
       </Grid>
       </Grid>

            </Grid>
          </div>
        ))}
      </Slider>
      </Grid>
    </div>
  )
}

export default Fmedharwa
