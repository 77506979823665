
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { item } from "examples/Sidenav/styles/sidenavItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { Autocomplete, Grid, TextField } from "@mui/material";
import Accordians from "./Accordians";
import DashboardLayout from "components/DashboardLayout";
import { APIs } from "Services/APIs";

function StockList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [getResponse, setResponse] = useState([]);
  const [approvedQty, setApprovedQty] = useState(0);
  const [givenQty, setGivenQty] = useState("");
  const [warevalue, setWarevalue] = useState(null);
  const [mmuvalue, setMmuvalue] = useState(null);
  const [wareHouseList, setWarehouseList] = useState(
   []
  );

  const dataObj = {
    columns: [
      { Header: "name", accessor: "name" },

      { Header: "UOM", accessor: "uom" },
      { Header: "current stock", accessor: "currentstock" },
      { Header: "Item Type", accessor: "itemtype" },
      { Header: "mmu code", accessor: "mmucode" },
      { Header: "quantity pending", accessor: "quantitypndg" },
      { Header: "quantity rcvd", accessor: "quantityrcvd" },
      { Header: "quantity Rqst", accessor: "quantityrqst" },
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  function getWarehouseData() {


    axios
      .get(
        `${APIs.warehouseServiceApi}/get?recStatus=OPEN`
      )
      .then((response) => {
   
        setWarehouseList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  const [mmuCodeList, setmmuCodeList] = useState(null);
  const defaultPropsWharehouse = {
    options: !wareHouseList ? [{ name: "Loading...", id: 0 }] : wareHouseList,
    getOptionLabel: (option) => option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };
  const defaultPropsmmuCode = {
    options: !mmuCodeList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuCodeList,
      getOptionLabel: (option) => option?.vehicle?.name ? option?.name + " (" + option?.vehicle?.name + ")" : option?.name,
    isOptionEqualToValue: (option, value) => option?.id === value?.id,
  };

  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    justifyContent: "space-between",
    alignItems: "center",
  };




 

 async function getMmuDataByWarehouse() {
    

    await axios
      .get(
        `${APIs.mmuCreationService}/get`
      )
      .then((response) => {
        setmmuCodeList(response?.data?.data?.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  async function getDataBywharehouse(wharehouse) {
    setLoading(true);
    const dataObj = {
        columns: [
            { Header: "name", accessor: "name" },
    
            { Header: "UOM", accessor: "uom" },
            { Header: "current stock", accessor: "currentstock" },
            { Header: "Quantity demand", accessor: "quantityindemand" },
            { Header: "Item Type", accessor: "itemtype" },
            { Header: "mmu code", accessor: "mmucode" },
            { Header: "quantity pending", accessor: "quantitypndg" },
            { Header: "quantity rcvd", accessor: "quantityrcvd" },
            { Header: "quantity Rqst", accessor: "quantityrqst" },
          ],
          rows: [],
      };
    await axios
      .get(
        `${APIs.stockServiceApi}/get?whid=${wharehouse?.name}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
            dataObj.rows.push(item);
    
        });
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getDataBymmu(mmulist) {
    setLoading(true);
    const dataObj = {
        columns: [
            { Header: "name", accessor: "name" },
    
            { Header: "UOM", accessor: "uom" },
            { Header: "current stock", accessor: "currentstock" },
            { Header: "Item Type", accessor: "itemtype" },
            { Header: "mmu code", accessor: "mmucode" },
            { Header: "quantity pending", accessor: "quantitypndg" },
            { Header: "quantity rcvd", accessor: "quantityrcvd" },
            { Header: "quantity Rqst", accessor: "quantityrqst" },
          ],
          rows: [],
      };
    await axios
      .get(
        `${APIs.stockServiceApi}/get?.mmucode=${mmulist}`
      )
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
            dataObj.rows.push(item);
         

        
        });
      
        setTableDatas(dataObj);

        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
 

  useEffect(() => { 
    getWarehouseData();
  }, []);

  return (
    <DashboardLayout>
      <MDBox>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} mt={2} lineHeight={1}>
            <div style={addButtonStyle}>
                <span
                   style={{
                    alignSelf: "center",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                  Stock List
                  </MDTypography>
                </span>

              
              </div>
              <Grid container spacing={2} mt={1} mb={2}>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      {...defaultPropsWharehouse}
                      value={warevalue}
                      onChange={(event, newValue) => {
                        getDataBywharehouse(newValue);
                        getMmuDataByWarehouse(newValue);
                        setWarevalue(newValue);
                        setMmuvalue(null);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select WareHouse" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      {...defaultPropsmmuCode}
                      value={mmuvalue}
                      onChange={(event, newValue) => {
                        getDataBymmu(newValue?.code);
                        setMmuvalue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select MMU" />
                      )}
                    />
                  </Grid>
                </Grid>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
           {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}

export default StockList;

