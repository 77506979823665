import { useState, useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Import form validation, schema, and initial values
import validations from "../../../../components/Resuable/ProjectPincode/schemas/validations";
import form from "../../../../components/Resuable/ProjectPincode/schemas/form";
import initialValues from "../../../../components/Resuable/ProjectPincode/schemas/initialValues";
import { useNavigate } from "react-router-dom";
import { micappCode } from "static/micappCode";
// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";

import { createProjectpincode } from "../../../../components/Resuable/ProjectPincode/service/ProjectPincode";
import ProjectPincodeInfo from "./ProjectPincodeInfo";
// Function to define form steps
function getSteps() {
  return ["ProjectPincode Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData, data, addDataHandler) {
  switch (stepIndex) {
    case 0:
      return (
        <ProjectPincodeInfo
          formData={formData}
          data={data}
          addDataHandler={addDataHandler}
        />
      );

    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function PincodeAssociation(props) {
  const { openpopupUser, setopenpopupUser, handleClose, passData } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false); // Loader state
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const [data, setData] = useState([]);

  const addDataHandler = (newData) => {
    setData([...data, newData]);
  };
  // Function to simulate a delay
  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  // Function to handle going back to the previous step
  const handleBack = () => setActiveStep(activeStep - 1);
  const dispatch = useDispatch();

  // Function to submit the form
  const submitForm = async (values, actions) => {
    setIsLoading(true);

    const Data = {
      pincode: values?.pincodeobj,
      projectcode: values?.project,
      projectname: values?.projectobj?.name,

      createdby: auth?.profileid,
      mappingcode: micappCode?.mappingcode,
      createdbyname: auth?.name,

      recstatus: "OPEN",

      status: "ACTIVE",
      syncstatus: "synced",
    };

    const response = await dispatch(createProjectpincode(Data));

    if (!response.error) {
      actions.resetForm();

      setIsLoading(false);
      passData();
      swal("Good job!", "Data has been created successfully!", {
        icon: "success",
      });
      handleClose();
    } else {
      actions.resetForm();
      swal({
        icon: "error",
        title: "Error",
        text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "Something wents wrong.",
      });
      setIsLoading(false);
      handleClose();
    }
  };

  // Function to handle form submission
  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      // If it's the last step, submit the form
      submitForm(values, actions);
    } else {
      // If not the last step, move to the next step
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  // Render the component
  return (
    <Dialog open={openpopupUser} onClose={handleClose} fullWidth maxWidth="md">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{ mt: 8 }}
      >
        <Grid item xs={12} lg={12}>
          <Formik
            initialValues={initialValues}
            validationSchema={currentValidation}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, isSubmitting }) => (
              <Form id={formId} autoComplete="off">
                <Card>
                  <MDBox mx={2} mt={-3}>
                    <MDBox
                      variant="gradient"
                      bgColor="info"
                      borderRadius="lg"
                      coloredShadow="success"
                      mx={2}
                      mt={-3}
                      p={2}
                      mb={-1}
                      textAlign="center"
                    >
                      <MDTypography
                        variant="h4"
                        fontWeight="small"
                        color="white"
                        mt={1}
                      >
                        Pincode Association
                      </MDTypography>
                    </MDBox>
                  </MDBox>
                  <MDBox p={3}>
                    <MDBox>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                        formField,
                        errors,
                      })}
                      <MDBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <MDBox />
                        ) : (
                          <MDButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            back
                          </MDButton>
                        )}
                        <MDButton
                          type="submit"
                          variant="gradient"
                          color="info"
                          // disabled={isLoading}
                        >
                          {isLoading ? (
                            <MoonLoader color="#f2fefa" size={16} />
                          ) : (
                            "Submit"
                          )}
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Dialog>
  );
}
