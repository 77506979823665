// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "../FormField";
import { Autocomplete, CircularProgress, Select } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { APIs } from 'Services/APIs';
import axios from "../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { ErrorMessage, useFormikContext } from "formik";

function DistricInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const { setFieldValue, validateField } = useFormikContext();
  const [stateValue, setStateValue] = useState(null);
  const [stateList, setStateList] = useState([]);
  const [districValue, setDistricValue] = React.useState(null);
  const [districList, setDistricList] = useState("");
  const [stateLoading, setStateLoading] = useState(false);
  const { formField, values, errors, touched } = formData;
  
  const defaultPropsState = {
    options: !stateList
      ? [
          {
            state: {
              shortname: "- -",
              name: "Loading...",
            },
            id: 0,
          },
        ]
      : stateList,
    getOptionLabel: (option) =>
      option.state?.name + " (" + option.state?.shortname + ")",
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const { districCode, shortname, districtname, state } = formField;
  var {
    districCode: districCodeV,

    shortname: shortnameV,

    districtname: districtnameV,
  } = values;

  async function getData() {
    setStateLoading(true);
      await axios.get(`${APIs.pincodeServiceApi}/get?type=STATE`)
      .then((response) => {
        setStateList(response?.data?.data?.dtoList);

        setLoading(false);
        setStateLoading(false);
      })

      .catch((e) => {
        setStateLoading(false);
        console.log("error", e);
      });
  }
  const handleStateChange = (event, newValue) => {
    setStateValue(newValue);
    setFieldValue('stateobj', newValue?.state || null);
    setFieldValue('state', newValue?.state?.code || '');
    validateField('state');
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">District master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>

      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
 
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsState}
                value={stateValue}
                onChange={handleStateChange}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.state && touched.state}
                    success={stateValue != null && !errors.state && touched.state}
                    variant="standard"

                    label=<span>{state.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {stateLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                    />
                   <ErrorMessage
               name="state"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>   
                )}
              />
              
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={districtname.type}

                label=<span>{districtname.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={districtname.name}
                value={districtnameV}
                placeholder={districtname.placeholder}
                error={errors.districtname && touched.districtname}
                success={districtnameV.length > 0 && !errors.districtname}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={shortname.type}
                label=<span>{shortname.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={shortname.name}
                value={shortnameV}
                placeholder={shortname.placeholder}
                error={errors.shortname && touched.shortname}
                success={shortnameV.length > 0 && !errors.shortname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={districCode.type}
                label=<span>{districCode.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={districCode.name}
                value={districCodeV}
                placeholder={districCode.placeholder}
                error={errors.districCode && touched.districCode}
                success={districCodeV.length > 0 && !errors.districCode}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for DistricInfo
DistricInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default DistricInfo;
