import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import DataTable from "examples/Tables/DataTable";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DownloadIcon from "@mui/icons-material/Download";
import { downloadurl } from "../../Services/UploadDocumnet";
import swal from "sweetalert";
import PersonalDetails from "./PersonalDetails";

const Preview = (props) => {

  const [previewdata, setPreviewData] = useState(props?.data);

  const borderBottom = {
    borderBottom: ({ borders: { borderWidth }, palette: { light } }) =>
      `${borderWidth[1]} solid ${light.main}`,
  };
  const [loading, setLoading] = useState(true);
  const handleDownload = (filename) => {
    swal({
      title: "Confirm Download",
      text: `Do you want to download ${filename}?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        confirm: "Download",
      },
    }).then((willDownload) => {
      if (willDownload) {
        fetch(`${downloadurl}/${filename}`)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              URL.revokeObjectURL(blobUrl);
            }, 100);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            swal(
              "Error",
              "An error occurred while downloading the file.",
              "error"
            );
          });
      } else {
        swal("Download Cancelled", "You have cancelled the download.", "info");
      }
    });
  };

  const personalDetails = [
    {
      action: 'Role',
      accept: props?.rolename ? props?.rolename : props?.data?.personalinformation[0]?.rolename
    },
    {
      action: 'Name',
      accept: previewdata?.lastname
        ? previewdata?.firstname?.replace(/\b\w/g, c => c.toUpperCase()) + " " + previewdata?.lastname?.replace(/\b\w/g, c => c.toUpperCase())
        : previewdata?.firstname
    },

    {
      action: 'Gender',
      accept: previewdata?.gender
    },
    {
      action: 'DOB',
      accept: previewdata?.dateofbirth
    },
    {
      action: 'Phone No.',
      accept: previewdata?.phoneno
    },
    {
      action: 'Email',
      accept: previewdata?.emailId
    },
    {
      action: 'Language',
      accept: previewdata?.preferedlanguage
    },
  ]
  const commissionDetails = [
    {
      action: 'Agent Commission Type',
      accept: previewdata?.agentcomissiontype
    },

    {
      action: 'Agent Commission',
      accept: previewdata?.agentcomission
    },
    {
      action: 'Resource Commission Type',
      accept: previewdata?.resourcecomissiontype
    },
    {
      action: 'Resource Commission',
      accept: previewdata?.resourcecomission
    },

  ]

  const adressDeatils = [
    {
      action: 'Address',
      accept: 
        (previewdata?.firstline?.replace(/\b\w/g, c => c.toUpperCase()) + " " +
        previewdata?.secondline?.replace(/\b\w/g, c => c.toUpperCase()) + " " +
        (previewdata?.pincodeobj ? previewdata?.pincodeobj?.subdistrict?.name : previewdata?.city?.replace(/\b\w/g, c => c.toUpperCase())) + " " +
        (previewdata?.pincodeobj ? previewdata?.pincodeobj?.state?.name : previewdata?.state?.replace(/\b\w/g, c => c.toUpperCase())))
    },
    
    {
      action: 'Country',
      accept: previewdata?.country?.replace(/\b\w/g, c => c.toUpperCase())
    },
    {
      action: 'Zip Code',
      accept: previewdata?.pincodeobj ? previewdata?.pincodeobj?.village?.code : previewdata?.zip
    },
  ]
  return (
    <Grid mt={5} sx={{ height: "100%", width: "100%", display: "flex", justifyContent: "center", }} >
      <Grid sx={{ width: "100%", hight: "auto" }}>
        <Grid sx={{ display: "flex", justifyContent: "center" }}>
          <MDTypography variant="h5" fontWeight="bold" color="text"> Personal Details  </MDTypography>
        </Grid>
        <PersonalDetails personalDetails={personalDetails} />
        <Grid mt={2} sx={borderBottom}></Grid>
        <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <MDTypography variant="h5" fontWeight="bold" color="text">  Address Details </MDTypography>{" "}
        </Grid>
        <Grid mt={2}>
          {adressDeatils.map((item, index) => (
            <Grid key={index} item ml={2} xs={12} sm={6}  sx={{ display: "flex", gap: "5%" }}>
              <Grid item sx={{ wordWrap: "break-word" , width:"25%" }} >
                <MDTypography variant="body2" color="text" fontWeight="medium">{item.action}
                </MDTypography>
              </Grid>
              <Grid item >
                <MDTypography variant="body2" color="text" fontWeight="medium" style={{ textAlign: 'center' }}>
                  :
                </MDTypography>
              </Grid>
              <Grid item sx={{ wordWrap: "break-word" }}>
                <MDTypography variant="body2" fontWeight="regular">  {item.accept} </MDTypography>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid mt={2} sx={borderBottom}></Grid>
        {/* second */}
        {previewdata?.qualificationinformation ? (
          <>
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" color="text">  Education Details
              </MDTypography>
            </Grid>

            <DataTable
              entriesPerPage={false}
              canSearch={false}
              showTotalEntries={false}
              pagination={false}
              table={{
                columns: [
                  { Header: "Sr. No.", accessor: (row, index) => index + 1 },
                  { Header: "Name of Degree/ Diploma", accessor: "qualificationname" },
                  { Header: "Mode", accessor: "qualificationmode" },
                  { Header: "Grade", accessor: "qualificationgrade" },
                  { Header: "Start Date", accessor: "startdate" },
                  { Header: "End Date", accessor: "enddate" },
                  {
                    Header: "Download", accessor: "Download", Cell: ({ row }) => (
                      <DownloadIcon
                        fontSize="medium"
                        style={{ color: "green", cursor: "pointer" }}
                        onClick={() => handleDownload(row?.original?.documentofproof ? row?.original?.documentofproof : row?.original?.uploadDocument?.docid)}

                      />
                    )
                  },

                ],
                rows: previewdata?.qualificationinformation
              }}
            /></>) : (<> </>)}

        <Grid mt={2} sx={borderBottom}></Grid>
        {previewdata?.personalinformation ? (
          <>
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" color="text"> Work Experience </MDTypography>
            </Grid>

            <DataTable
              entriesPerPage={false}
              canSearch={false}
              showTotalEntries={false}
              pagination={false}
              table={{
                columns: [
                  { Header: "Sr. No.", accessor: (row, index) => index + 1 },
                  { Header: "job title", accessor: "jobtitle" },
                  { Header: " Name of organisation", accessor: "orgname" },
                  { Header: "Date of join", accessor: "dateofjoin" },
                  { Header: "Date of leave", accessor: "dateofleave" },
                ],
                rows: previewdata?.personalinformation
              }}
            /> </>) : (<> </>)}
        <Grid mt={2} sx={borderBottom}></Grid>
        <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
          <MDTypography variant="h5" fontWeight="bold" color="text">
            Document
          </MDTypography>
        </Grid>
        <DataTable
          entriesPerPage={false} canSearch={false} showTotalEntries={false} pagination={false}
          table={{
            columns: [
              { Header: "Sr. No.", accessor: (row, index) => index + 1 },
              { Header: "Document Name", accessor: "name" },
              {
                Header: "Download", accessor: "Download", Cell: ({ row }) => (
                  <DownloadIcon
                    fontSize="medium"
                    style={{ color: "green", cursor: "pointer" }}
                    onClick={() => handleDownload(row?.original?.documentofproof ? row?.original?.documentofproof : row?.original?.docid)}

                  />
                )
              },
            ],
            rows: previewdata?.document
          }}
        />
        <Grid mt={2} sx={borderBottom}></Grid>
        {previewdata?.agentcomissiontype && previewdata?.agentcomission ? (
          <>
            <Grid mt={2} sx={{ display: "flex", justifyContent: "center" }}>
              <MDTypography variant="h5" fontWeight="bold" color="text">  Commission  </MDTypography>
            </Grid>

            <Grid container spacing={1} ml={2} mt={2} sx={{ maxWidth: "100%" }}>
              {commissionDetails?.map((item, index) => (
                <Grid key={index} item xs={12} sm={6} sx={{ display: "flex", gap: "5%", flexWrap: "wrap" }}>
                  <Grid item sx={{ wordWrap: "break-word", width: "50%" }}>
                    <MDTypography variant="body2" color="text" fontWeight="medium" > {item.action} </MDTypography>
                  </Grid>
                  <Grid item sx={{ textAlign: 'center', wordWrap: "break-word" }}>
                    <MDTypography variant="body2" color="text" fontWeight="medium" >  : </MDTypography>
                  </Grid>
                  <Grid item sx={{ wordWrap: "break-word" }}>
                    <MDTypography variant="body2" fontWeight="regular" >   {item.accept}  </MDTypography>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </>
        ) : (<></>)}
      </Grid>
    </Grid>
  );
};
export default Preview;
