import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import Navbar from "../../findDoctor/navbar/desktopNavbar/navbar";
import { useEffect, useState } from "react";
import axios from "../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
import PreviewIcon from "@mui/icons-material/Preview";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import swal from "sweetalert";
import { MoonLoader } from 'react-spinners';
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { ROLE_CODE } from "privateComponents/codes";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import { Grid, Dialog, DialogActions, Tooltip } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { useSelector } from "react-redux";
import { APIs } from "Services/APIs";
import TrackStatus from "components/TrackStatus";
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import BookingView from "app/labTest/labTestList/Bookingview/BookingView";
import VerificationOtp from "../OTPVerification/VerificationOtp";

function CollectiveAgentTestList() {
  const navigate = useNavigate();
  const comp = "SM";
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({ rows: [], columns: [] });
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);


  useEffect(() => {
    const handleResize = () => {
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const iconStyle = { color: "#039147", marginRight: "9px", cursor: "pointer" };
  const {getMMUDetails} = useSelector(state => state)
  const [loadingId, setLoadingId] = useState(0);
  const [loadingreached, setLoadingReached] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [openpopupotp, setOpenPopupOtp] = useState(false);
  const [selecteddata, setSelectedData] = useState({});
  const [selectedotpdata, setSelectedOtpData] = useState({});
  const [openstatustracker, setOpenstatustracker] = useState(false);
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  function viewPopup(item) {
    setopenpopup(true);
    setSelectedData(item);
  }

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [activeStep, setActiveStep] = useState("");

  const handleCloseConfirmation = () => {
    setShowConfirmation(false);
  };
  const handleViewOtp = (item) => {
  
    setLoadingReached(true)
    axios
        .get(
          `${
            APIs?.otpServiceApiv2
          }/forget/password?msgCode=EDT&otpOn=EMAIL&resourceCode=HEALTHCARE&userid=${item?.patient?.emailid}`
        )
      
        .then((response) => {   
          swal(
            "Success!",
            "OTP has been sent to the patient's registered email address.",
            "success"
          );
          setOpenPopupOtp(true);
          setSelectedOtpData(item);
          setLoadingReached(false)
        })
        .catch((e) => {
          console.log(e);
       
          swal(
            "Oops!",
            e?.response?.data?.aceErrors?.[0]?.errorMessage,
            "warning"
          );
          setLoadingReached(false)
        });
  
   
  };

  const steps = [
    { label: 'SCHEDULED', date: '2 Feb 2024' },
    { label: 'REACHED', date: '3 Feb 2024' },
    { label: 'SAMPLE COLLECTED', date: '3 Feb 2024' },
    { label: 'REPORT UPLOAD SOON', date: '4 Feb 2024' },
    { label: 'REPORT UPLOAD', date: '4 Feb 2024' },
  ];

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled.";
      case 1:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, and the sample has been collected.";

      case 2:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, the sample has been collected, and the report will be uploaded soon after the test.";
      case 3:
        return "Once the form is successfully submitted, the application is sent to the verification team. Your booking is now scheduled, the sample has been collected, and the report has been uploaded.";
      default:
        return "";
    }
  };

  function statustrack(item) {
    setShowConfirmation(true);

    switch (item.recstatus) {
      case 'SCHEDULED':
        setActiveStep(1);
        break;
        case 'REACHED':
          setActiveStep(2);
          break;
      case 'SAMPLE COLLECTED':
        setActiveStep(3);
        break;
      case 'REPORT UPLOAD SOON':
        setActiveStep(4);
        break;
      case 'REPORT UPLOAD':
        setActiveStep(5);
        break;
      default:
        console.log('Unknown status:', item.recstatus);
    }
  }


  function getData() {
    setLoading(true);

    const dataObj = {
      columns: [],

      rows: [],
    };

    var api = "";

  
      dataObj.columns.push({
        Header: "Lab name",
        accessor: "mmu.name",
      });
      dataObj.columns.push({
        Header: "patient name",
        accessor: "patient.name",
      });

      api = `${APIs.bookingServiceApi}/get?mmu.code=${getMMUDetails?.data?.mmucode}&type=labtest&visitid=${auth.profileid}`;
    

    dataObj.columns.push(
      { Header: "order id", accessor: "ordernumber" },
      {
        Header: "Pickup Address",accessor:"fullAddress",
        Cell: ({ value }) => (
          <Tooltip title={value} arrow>
          <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "300px" }}>
            {value}
          </div>
          </Tooltip>
        ),
      },
      { Header: "status", accessor: "track" },
      {
        Header: "Assigned date & time/slot",
        accessor: "DateAndTime",
      },
      {
        Header: "Reached",
        accessor: "reached",
      },
      { Header: "action", accessor: "action" }
      
    );

    axios
      .get(api)
      .then((response) => {
        response?.data?.data?.dtoList?.map((item) => {
          if (
            item?.patient?.name ===
            localStorage.getItem("profile-Name").replace(/"/g, "")
          ) {
            item.patient.name = "Self";
          }
          item.action = (
            <span>
              <Tooltip title="Lab Booking Information" arrow>
              <PreviewIcon
                fontSize="medium"
                style={iconStyle}
                onClick={() => viewPopup(item)}
              />
           </Tooltip>
            </span>
          );
          item.reached=(
            <span>
            <MDButton
              variant="gradient"
              color="info"
              size="small"
              onClick={()=>handleViewOtp(item)}
              disabled={item?.recstatus != "SCHEDULED"}
            >
               {loadingreached ? <MoonLoader color="#f2fefa" size={16} /> : "Reached"}
             
            </MDButton>
          </span>
          )

          item.track = (
            <span>
                <Tooltip title="View Status" arrow>
              <TrackChangesIcon
                fontSize="medium"
                style={iconStyle}
                onClick={() => statustrack(item)}
              />
              </Tooltip>
            </span>
          );
        
          item.fullAddress = `${item?.patientaddress?.firstline}, ${item?.patientaddress?.secondline}
          ${item?.patientaddress?.city}, ${item?.patientaddress?.state}, ${item?.patientaddress?.country}, ${item?.patientaddress?.postcode}`;

      
          item.date = new Date(item?.slot?.startdate)
            .toLocaleDateString("en-GB", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            })
            .replace(/\//g, "-");

          item.timing = (() => {
            const startDate = item?.slot?.startdate;
            const endDate = item?.slot?.enddate;

            if (startDate && endDate) {
              const startHour = startDate
                .split("T")[1]
                .split(".")[0]
                .split(":");

              const endHour = endDate.split("T")[1].split(".")[0].split(":");

              const getFormattedTime = (hour, minute) => {
                const meridian = hour >= 12 ? "PM" : "AM";
                const formattedHour = hour % 12 === 0 ? 12 : hour % 12;
                return `${formattedHour}:${minute} ${meridian}`;
              };

              const startTime = getFormattedTime(
                parseInt(startHour[0]),
                startHour[1]
              );

              const endTime = getFormattedTime(
                parseInt(endHour[0]),
                endHour[1]
              );

              return `  ${startTime} - ${endTime}`;
            }

            return "";
          })();
          item.DateAndTime = `${item.date}  ${item.timing}`;
          item.fcreateddate = new Date(item.createddate).toDateString();

          dataObj.rows.push(item);
        });

        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
        setLoading(false);
      });
  }
  const handleCloseOtp = () => {
    setOpenPopupOtp(false);
  };
  useEffect(() => {
    getData(auth?.profileid);
  }, [loadingId]);

  return (
    <>
      <MDBox pt={1}>
        <MDBox
          mt={
            auth.rolecode === ROLE_CODE.collectiveagentcode
              ? 0
              : 20
          }
        >
          <Card >
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",

                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Booking List
                  </MDTypography>
                </span>


              </div>
            </MDBox>

            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Grid container mb={isMidScreen ? 10 : 3} mt={1}>
        <Footer />
      </Grid>


      {selecteddata && Object.keys(selecteddata).length > 0 && openpopup && (
        <BookingView
          openpopup={openpopup}
          setopenpopup={setopenpopup}
          title={"Lab Booking Information"}
          selecteddata={selecteddata}
        />
      )}
      {openpopupotp && (
        <VerificationOtp
          openpopupotp={openpopupotp}
          setOpenPopupOtp={setOpenPopupOtp}
          selectedotpdata={selectedotpdata}
          handleClose={handleCloseOtp}
          getData={getData}
        />
      )}

      {showConfirmation && (
        <Dialog open={showConfirmation} onClose={handleCloseConfirmation}>
          <TrackStatus getStepContent={getStepContent} steps={steps} activeStep={activeStep} />
          <DialogActions>
            <MDButton onClick={handleCloseConfirmation}>Close</MDButton>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default CollectiveAgentTestList;




