/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
    formId: "new-user-form",
    formField: {
      name: {
        name: "name",
        label: "Name",
        type: "text",
        errorMsg: " Name is required.",
      },
      testname: {
        name: "testname",
        label: "Test Name",
        type: "text",
        errorMsg: "Test name is required.",
      },
    
      investigationname:{
        name: "investigationname",
        label: "Investigation Name",
        type: "text",
        errorMsg: "Investigation name is required.",
      },
      uom:{
        name: "uom",
        label: "UOM",
        type: "text",
        errorMsg: "UOM is required.",
      },
      range: {
        name: "range",
        label: "Range",
        type: "number",
        errorMsg: "Range is required.",
      },
      investigationList: {
        name: "investigationList",
        label: "Investigation List",
        type: "number",
        errorMsg: "InvestigationList is required.",
      },
   
    },
  };
  
  export default form;