import React from 'react';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import MDTypography from 'components/MDTypography';

const getColor = (completion) => {
  if (completion === 100) {
    return 'green';
  } else if (completion >= 75) {
    return 'blue';
  } else if (completion >= 50) {
    return 'orange';
  } else {
    return 'red';
  }
};

const calculateCompletion = (qualificationList = [], visitList = [], addressList = [], categoryList = []) => {
  
  const lists = [qualificationList, visitList, addressList, categoryList];
  const totalLists = lists.length;
  const completedLists = lists.filter(list => list.length > 0).length;
  return (completedLists / totalLists) * 100;
};

const ProfileCompletion = ({ qualificationList = [], visitList = [], addressList = [], categoryList = [],title }) => {
  const completion = calculateCompletion(qualificationList, visitList, addressList, categoryList);
  const color = getColor(completion);

  return (
    <Grid >
<MDTypography variant="h6" fontWeight="medium" textTransform="capitalize" sx={{justifyContent:"center",display:"flex"}}>
          {title}
        </MDTypography>
    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    
      <CircularProgress
        variant="determinate"
        value={completion}
        size={150}
        sx={{ color }}
      />
      
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
      <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {`${Math.round(completion)}%`}
        </MDTypography>
      </Box>
    </Box>
    </Grid>
  );
};

export default ProfileCompletion;
