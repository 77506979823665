

import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 PRO React base styles
import breakpoints from "assets/theme/base/breakpoints";

// Images
import burceMars from "assets/images/bruce-mars.jpg";
import backgroundImage from "assets/images/bg-profile.jpeg";

import EditPrfile from "./EditPrfile";
import MDButton from "components/MDButton";
import ChangePassword from "components/Resuable/Change Password/ChangePassword";
import { CircularProgress, FormControlLabel, Switch, useMediaQuery } from "@mui/material";
import { ROLE_CODE } from "privateComponents/codes";
import { useSelector } from "react-redux";
import axios from "../../../../../axiosinstance";
import { getSummaryServiceAction } from 'redux/Actions/getSummaryServiceAction';
import { useDispatch } from "react-redux";
import { APIs } from "Services/APIs";
import Brightness1Icon from '@mui/icons-material/Brightness1';

function Header({ children }) {
  const dispatch = useDispatch();
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [openpopupPassword, setopenpopupPassword] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const { getSummaryServiceData } = useSelector(state => state);
  const [status, setStatus] = useState({ data: getSummaryServiceData?.list?.[0] });
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery("(max-width: 600px)");


  const [userName, setUserName] = useState(
    localStorage.getItem("mmu-auth")
      ? JSON.parse(localStorage.getItem("mmu-auth")).name
      : ""
  );

  const updateLocalStorageName = (newName) => {
    const authData = JSON.parse(localStorage.getItem("mmu-auth")) || {};

    authData.name = newName;

    localStorage.setItem("mmu-auth", JSON.stringify(authData));
  };

  const updateName = (newName) => {
    setUserName(newName);

    updateLocalStorageName(newName);
  };
  const handleEditIconClick = () => {
    setIsEditPopupOpen(true);
  };

  const handleCloseEditPopup = () => {
    setIsEditPopupOpen(false);
  };

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    /** 
         The event listener that's calling the handleTabsOrientation function when resizing the window.
        */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);


  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  var oldName = localStorage.getItem("login-auth")
    ? JSON.parse(localStorage.getItem("login-auth"))?.name
    : "";

  var firstName = oldName.split(" ").slice(0, -1).join(" ");
  var lastName = oldName.split(" ").slice(-1).join(" ");
  var firstname = firstName;
  var lastname = lastName;
  var firstname2 = firstname.charAt(0).toUpperCase() + firstname.slice(1);
  var lastname2 = lastname.charAt(0).toUpperCase() + lastname.slice(1);

  const update = async (object) => {
    setLoading(true);
    try {
      const response = await axios.put(`${APIs.summaryServiceApi}/update`, object);
      await dispatch(getSummaryServiceAction({ profileid: auth.profileid, type: "doctor" }));
      swal("Success!", {
        text: object?.isactive === "ONLINE" 
          ? "Your profile is visible to the patient." 
          : "Your profile is not visible to the patient.",
        icon: "success"
      });
      setLoading(false);
      return response; 
    } catch (error) {
      swal("Oops!", error.response?.data?.aceErrors?.[0]?.errorMessage || "something went wrong", "error");
      setLoading(false);
      await dispatch(getSummaryServiceAction({ profileid: auth.profileid, type: "doctor" }));
      throw error;
    }
  };
  
  const toggler = async (checked, request) => {
    setLoading(true); // Set loading to true when the toggle is clicked
    try {
      const data = {
        ...request,
        isactive: checked ? "ONLINE" : "OFFLINE"
      };
      await update(data);
    } catch (error) {
      // If there's an error, revert the switch back to its original state
      setStatus({ data: { ...status.data, isactive: checked ? "OFFLINE" : "ONLINE" } });
    } finally {
      setLoading(false); // Set loading to false after the update
    }
  };
  

  useEffect(() => {
    setStatus({ data: getSummaryServiceData?.list?.[0] })
  }, [getSummaryServiceData])


  return (
    <MDBox position="relative" mb={5}>
      
      <MDBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="22rem"
        borderRadius="xl"
        mt={20}
        sx={{
          backgroundImage: ({
            functions: { rgba, linearGradient },
            palette: { gradients },
          }) => `url(${backgroundImage})`,
      
          overflow: "hidden",
          '@media (max-width: 600px)': {
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} xs={12} alignItems="center">
          <Grid item xs={12} sm={6} sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
            <MDAvatar
              src={burceMars}
              alt="profile-image"
              size="xl"
              shadow="sm"

            />


            <MDBox height="100%" mt={0.5} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                {firstname2 + " " + lastname2}
                <MDTypography variant="button" color="text" fontWeight="regular">
                  {localStorage.getItem("mmu-auth")
                    ? JSON.parse(localStorage.getItem("login-auth"))?.rolename
                    : ""}
                  <span
                    style={{ marginLeft: "10px", cursor: "pointer" }}
                    onClick={handleEditIconClick}
                  >
                    <Icon
                      fontSize="medium"
                      variant="body"
                      color="info"
                      sx={{
                        verticalAlign: "middle",

                        marginBottom: "5px",
                      }}
                    >
                      edit
                    </Icon>
                  </span>
                </MDTypography>
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} sm={6} ml={isMobile ? 3 : 0} sx={{ display: "flex", justifyContent:isMobile ? "space-evenly": "flex-end" ,alignItems:"center" }}>
          {auth.rolecode === ROLE_CODE.doctorcode ? (
        <>
        
  {/* {status?.data?.isactive === "ONLINE" && (
    <FormControlLabel
     // name={item.id}
      control={
        <Switch
          onClick={(e) => toggler(e.target.checked, status?.data)}
          defaultChecked
        />
      }
      label={
        <span style={{ color: '#039147'  }}>
        Online
          &nbsp;
          <sup>
            <Brightness1Icon
              style={{ color:"#039147"  }}
             size="small"
            />
          </sup>
        </span>
      }
      style={{ color:   '3039147' }}
    />
  )}

{(status?.data?.isactive === "OFFLINE" || 
    status?.data?.isactive === null || 
    status?.data?.isactive === undefined || 
    status?.data?.isactive === "") && (
    <FormControlLabel
     //  name={item.id}
      control={
        <Switch
          onClick={(e) => toggler(e.target.checked, status?.data)}
        />
      }
      label={
        <span style={{ color: 'red' }}>
          "Offline
          &nbsp;
          <sup>
            <Brightness1Icon
              style={{ color:  "red" }}
             size="small"
            />
          </sup>
        </span>
      }
    />
  )} */}
  <FormControlLabel
  control={
    <Switch
      checked={status?.data?.isactive === "ONLINE"}
      onChange={(e) => toggler(e.target.checked, status?.data)}
    />
  }
  label={
    <span style={{ color: status?.data?.isactive === "ONLINE" ? '#039147' : 'red' }}>
      {status?.data?.isactive === "ONLINE" ? "Online" : "Offline"}
      &nbsp;
      <sup>
        <Brightness1Icon
          style={{ color: status?.data?.isactive === "ONLINE" ? "#039147" : "red" }}
          size="small"
        />
      </sup>
    </span>
  }
/>


          {/* <FormControlLabel
            control={
              <Switch
                onClick={(e) => toggler(e.target.checked, status?.data)}
                defaultChecked={status?.data?.isactive === "ONLINE"}
              />
            }
            label={
              <span style={{ color: status?.data?.isactive === "ONLINE" ? '#039147' : 'red' }}>
                {status?.data?.isactive === "ONLINE" ? "Online" : "Offline"}
                &nbsp;
                <sup>
                  <Brightness1Icon
                    style={{ color: status?.data?.isactive === "ONLINE" ? "#039147" : "red" }}
                   size="small"
                  />
                </sup>
              </span>
            }
            style={{ color: status?.data?.isactive === "ONLINE" ? 'green' : 'red' }}
          /> */}
          
          {loading && <div style={{display:"flex",alignItems:"center"}}>
            <CircularProgress size={20} />
            </div>} 
          &nbsp;
        </>
      ) : (
        <></>
      )}
            <MDButton
              size="small"
              variant="gradient"
              color="info"
              onClick={(() =>
                setopenpopupPassword(true)
              )}
              sx={{
                '@media (max-width: 600px)': {
                  height: '50px',  
                  fontSize: '12px',
                  padding: '10px 10px',
                },
              }}
            >Change Password</MDButton>
           
          </Grid>
        </Grid>
        {children}
      </Card>
      {isEditPopupOpen && (
        <EditPrfile onClose={handleCloseEditPopup} updateName={updateName} />
      )}
      <ChangePassword
        title={"Change Password"}
        openpopupPassword={openpopupPassword}
        setopenpopupPassword={setopenpopupPassword}
      />
    </MDBox>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node,
};

export default Header;
