const form = {
  formId: "new-user-form",
  formField: {
    firstName: {
      name: "firstName",
      label: "First Name",
      type: "text",
      errorMsg: "First name is required.",
    },

    age: {
      name: "age",
      label: "Age",
      type: "number",
      errorMsg: "Age is required.",
    },

    lastName: {
      name: "lastName",
      label: "Last Name",
      type: "text",
      errorMsg: "Last name is required.",
    },
    relation: {
      name: "relation",
      label: "Relation",
      type: "text",
      errorMsg: "Relation is required.",
    },
    gender: {
      name: "gender",
      label: "gender",
      type: "select",
      errorMsg: "Gender is required.",
    },

    email: {
      name: "email",
      label: "Email Address",
      type: "email",
      errorMsg: "Email address is required.",
      invalidMsg: "Your email address is invalid",
    },
    mobilenumber: {
      name: "mobilenumber",
      label: "Mobile Number",
      type: "number",
      errorMsg: "Mobile Number is required.",
      invalidMsg: "Mobile number must be exactly 10 digits.",
    },
  },
};

export default form;
