import React, { useEffect, useState } from "react";
import "./onlineConsultation.css";
import consultimge from "../../../../assets/images/consultindia.png";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import NumberUpdates from "../components/numberUpdates/numberUpdates";
import Cards from "../components/Cards";
import Card from "../components/card/Card";
import SurgicalSpecalist from "../components/surgical-specialist/SurgicalSpecalist";
import Choosespeciality from "../components/Choose a speciality/Choosespeciality";
import WhyConsultOnline from "../components/whyConsultOnline/whyConsultOnline";
import MedharvaPlus from "../components/medharvaPlus/medharvaPlus";
import WhatUserSay from "../components/whatUserSay/whatUserSay";
import EmailSubscribe from "../components/emailSubscribe/emailSubscribe";

const OnlineConsultation = ({ pageLabel }) => {
  const navigate = useNavigate();
  const cardDatas = [
    { image: consultimge },
    { image: consultimge },
    { image: consultimge },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      <Slider {...sliderSettings}>
        {cardDatas.map((card, index) => (
          <div key={index}>
            <Grid className="consult-card-A" mt={23}>
              <Grid className="consult-card-B">
                <img
                  className="consult-card-imge"
                  src={card?.image}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div className="consult-card-C">
                <Grid className="consult-car-D">Consult India’s</Grid>
                <Grid className="consult-car-E">Top doctors's Online</Grid>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Grid className="consult-car-X">Flat 80% oFF</Grid>

                  <Grid className="consult-card-y">
                    <Button>
                      <span className="consult-card-z">NEW60</span>
                    </Button>
                  </Grid>
                </div>
              </div>
              <div></div>
              <Grid className="consult-button-container">
                <Button
                  className="consult-button"
                  onClick={() =>
                    navigate("/app/home/find-doctor/speciality", {
                      state: {
                        pagelabel: "Online Consultation",
                      },
                    })
                  }
                >
                  <span className="consult-button-text"> Consult Now</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>

      {isSmallScreen ? <NumberUpdates /> : <Cards />}
      <Card pagelabel={pageLabel} />
      <NumberUpdates />
      <SurgicalSpecalist />
      {isSmallScreen? <Choosespeciality /> :<WhyConsultOnline />}
      <MedharvaPlus />
      <WhatUserSay />
      <EmailSubscribe />
    </div>
  );
};

export default OnlineConsultation;
