import React, { useEffect, useState } from "react";
import Navbar from "../findDoctor/navbar/desktopNavbar/navbar";
import MobileNavbar from "app/findDoctor/navbar/mobileNavbar/mobileNavbar";
import MedharvaFooter from "app/findDoctor/medharvaFooter/medharvaFooter";
import EmailSubscribe from "app/findDoctor/emailSubscribe/emailSubscribe";
import FooterNavbar from "app/findDoctor/footerNavbar/footerNavbar";
import MedharvaPlus from "app/findDoctor/medharvaPlus/medharvaPlus";
import MedharvaProvider from "./MProvider/MedharvaProvider";
import Pcards from "./Providercard/Pcards";
import IntractiveVR from "./Intractive/IntractiveVR";
import TechnicalMP from "./TechinicalProvider/TechnicalMP";
import Providerplan from "./For Provider/Providerplan";

const ForProviders = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 500);
  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 500);
      setIsMidScreen(window.innerWidth <= 900);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ background: "#f3f5ea", overflowX: "hidden" }}>
      {isMidScreen ? <></> : <Navbar pagelabel="For Providers" />}
      {isMidScreen ? <MobileNavbar /> : <></>}
      <MedharvaProvider />
      <Pcards />
      <IntractiveVR />
      <TechnicalMP />
      <Providerplan />
      <MedharvaPlus />
      <EmailSubscribe />
      {isMidScreen ? <></> : <MedharvaFooter />}
      {isMidScreen ? <FooterNavbar pagelabel="Providers" /> : <></>}
    </div>
  );
};

export default ForProviders;
