import * as Yup from "yup";
import checkout from "../schemas/form";

const {
  formField: {
    firstName,
      email,
    password,
    rolecode,
    repeatPassword,
    departmentvalue,
    designationvalue,
    experience,
    description,
    accountholdername,
    accountnumber,
    phonenumber,
    ifsccode,
    bankname,
    adhar,
    referalcode,
    pan,
    age,
    gender
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [firstName.name]: Yup.string().required(firstName.errorMsg),
    // [age.name]: Yup.string().required(age.errorMsg),
    [age.name]: Yup.number()
     .min(23, 'Age must be at least 23 years')
     .required(age.errorMsg),
    [email.name]: Yup.string().required(email.errorMsg).email(email.invalidMsg),
    [gender.name]: Yup.string().required(gender.errorMsg),
    [password.name]: Yup.string().required(password.errorMsg).min(6, password.invalidMsg),
    [repeatPassword.name]: Yup.string()
      .required(repeatPassword.errorMsg)
      .oneOf([Yup.ref("password"), null], repeatPassword.invalidMsg),
    [rolecode.name]: Yup.string().required(rolecode.errorMsg),
    [departmentvalue.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "DO001" || rolecode === "AG2773" || rolecode === "RE001",
      then: Yup.string().required("Department is required"),
    }),
    [designationvalue.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "D0001" || rolecode === "AG2773"|| rolecode === "RE001"|| rolecode === "DO001",
      then: Yup.string().required("Designation is required"),
    }),
    [experience.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "DO001" || rolecode === "RE001",
      then: Yup.string().required("Experience is required"),
    }),
    [description.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "DO001" || rolecode === "RE001",
      then: Yup.string().required("Experience is required"),
    }),
   
    [accountholdername.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Account Holder Name is required"),
    }),
    [accountnumber.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Account Number is required"),
    }),
    [ifsccode.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Account Number is required"),
    }),
    [pan.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().matches(
        /^[A-Z]{5}[0-9]{4}[A-Z]$/,
        "Invalid PAN card number"
      ),
    }),
    [adhar.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Addhar card is required"),
    }),
    [bankname.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Bank name is required"),
    }),
    [referalcode.name]: Yup.string().when(["rolecode"], {
      is: (rolecode) => rolecode === "AG2773",
      then: Yup.string().required("Refral Code is required"),
    }),
    
    [phonenumber.name]: Yup.string()
      .required(phonenumber.errorMsg)
      .min(10, phonenumber.invalidMsg)
      .max(10, phonenumber.invalidMsg),
  }),
];

export default validations;
