import Cardiology from "../assets/images/Specialities/Cardiology 3.png";
import CardiothoracicSurgery from "../assets/images/Specialities/cardiothoracic surgery 3.png";
import Dermatology from "../assets/images/Specialities/dermatology 1.png";
import endocrinology from "../assets/images/Specialities/endocrinology.png";
import Gastoenterology from "../assets/images/Specialities/gastroenterology 1.png";
import GeneralSurgery from "../assets/images/Specialities/GeneralSurgery 4.png";
import Hematology from "../assets/images/Specialities/Hematology.png";
import InfectiousDisease from "../assets/images/Specialities/InfectiousDisease 1.png";
import Neprology from "../assets/images/Specialities/Neprology.png";
import NeuroSurgery from "../assets/images/Specialities/Neurosurgery.png";
import OrthopedicSurgery from "../assets/images/Specialities/Orthopedic Surgery 2.png";
import PlasticSurgery from "../assets/images/Specialities/Plastic Surgery 3.png";
import Pulmonology from "../assets/images/Specialities/pulmonology.png";
import Rheumatology from "../assets/images/Specialities/Rheumatology 3.png";
import Urology from "../assets/images/Specialities/Urology 3.png";



const Specialitiesimages = {
    "General Surgery":GeneralSurgery ,
    "Orthopedic Surgery":OrthopedicSurgery ,
    "Neurosurgery":NeuroSurgery ,
    "Cardiothoracic Surgery": CardiothoracicSurgery,
    "Plastic Surgery":PlasticSurgery ,
    "Urology":Urology,
     "Cardiology": Cardiology,
     "Dermatology": Dermatology,
     "Endocrinology": endocrinology,
     "Gastroenterology": Gastoenterology ,
     "Hematology/Oncology":Hematology ,
     "Infectious Disease":InfectiousDisease ,
     "Nephrology":Neprology ,
     "Pulmonology":Pulmonology,
     "Rheumatology":Rheumatology,

};

export default Specialitiesimages;
