
import React, { useEffect, useRef, useState } from 'react';
import { Grid } from '@mui/material'
import { IonIcon } from '@ionic/react';
import { chevronDown } from 'ionicons/icons';
import "./SellersSignup.css";
import SignupSellersAgeant from './SignupSellersAgeant';
import SignupSellersDoctor from './SignupSellersDoctor';
import Pharmycist from './Pharmycist/Pharmycist';
import SinghupSellers from './SinghupSellers/SinghupSellers';


const SellersSignup = () => {
    const selectWrapperRef = useRef(null);
    const [isActive, setIsActive] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("Doctors");
    const renderSelectedComponent = () => {
        switch (selectedCountry) {
          case "Doctors":
            return  <SignupSellersDoctor />;
          case "Pharmacy":
            return  <Pharmycist />;
          case "Lab":
            return  <SinghupSellers />;
          case "Agent":
            return  <SignupSellersAgeant />;
          default:
            return null;
        }
      };
    useEffect(() => {
        const selectWrapper = selectWrapperRef.current;
        if (!selectWrapper) return;

        const selectBtn = selectWrapper.querySelector('.select-btn');
        const optionsBox = selectWrapper.querySelector('.options');

        const countries = ['Doctors', 'Pharmacy', 'Lab', 'Agent'];

        function addCountry() {
            optionsBox.innerHTML = "";
            for (let country of countries) {
                optionsBox.insertAdjacentHTML("beforeend", `
                    <li>${country}</li>
                `);
            }
        }

        addCountry();

        const handleSelectBtnClick = () => {
            setIsActive(!isActive);
        };

        const handleCountryClick = (event) => {
            const selectedCountry = event.target.textContent;
            setSelectedCountry(selectedCountry);
            setIsActive(false);
        };

        selectBtn.addEventListener("click", handleSelectBtnClick);
        optionsBox.addEventListener("click", handleCountryClick);

        return () => {
            selectBtn.removeEventListener("click", handleSelectBtnClick);
            optionsBox.removeEventListener("click", handleCountryClick);
        };
    }, [isActive]);


 
    
      
    return (
        <div className='sellers-medharwa-singup-lap-a'>
            <Grid className='sellers-medharwa-singup-lap-b' >
                <Grid className='sellers-medharwa-singup-lap-c' >
                    <Grid className='sellers-medharwa-singup-lap-d'>
                        <span className='sellers-heading-a-m' >Medharva</span>
                        <span className='sellers-heading-a-mn'>for</span>
                        <span className='sellers-heading-a-mno'>Sellers</span>
                    </Grid>
                </Grid>
                <Grid>
                    <div className='SignupAgeantdiv2' ref={selectWrapperRef}>
                        <div className={`select-wrapper ${isActive ? 'active' : ''}`}>
                            <div className="select-btn">
                                <span>{selectedCountry}</span>
                                <IonIcon icon={chevronDown} />
                            </div>
                            <div className="content">
                                <ul className="options"></ul>
                            </div>
                        </div>
                    </div>
                </Grid>
<>
              
              {renderSelectedComponent()}
              </>
            </Grid>
            
        </div>
    );
}

export default SellersSignup;