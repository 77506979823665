import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDPagination from "components/MDPagination";
import nodatafound from "../../../assets/images/products/No-data-found.png";
// Material Dashboard 2 PRO React examples
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";
import { Pagination, TableCell } from "@mui/material";
import MDAvatar from "components/MDAvatar";

function DataTable({
  entriesPerPage,
  showTotalEntries,
  table,
  isSorted,
  noEndBorder,
  setCurrentPage,
  imageMaxWidth
}) {
  const defaultValue = entriesPerPage.defaultValue ? entriesPerPage.defaultValue : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries.map((el) => el.toString())
    : ["5", "10", "15", "20", "25"];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0 } },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  const handlePagination =(event, newPage)=>{
    setCurrentPage(newPage)
    console.log(newPage, "pagination")
  }

  
 
return (

  <TableContainer sx={{ boxShadow: "none" }}>

  

        <Table {...getTableProps()}>

          <MDBox component="thead">

            {headerGroups.map((headerGroup) => (

              <TableRow {...headerGroup.getHeaderGroupProps()}>

                {headerGroup.headers.map((column) => (

                  <DataTableHeadCell

                    {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}

                    width={column.width ? column.width : "auto"}

                    align={column.align ? column.align : "left"}

                    sorted={setSortedValue(column)}

                  >

                    {column.render("Header")}

                  </DataTableHeadCell>

                ))}

              </TableRow>

            ))}

          </MDBox>
        
          <TableBody {...getTableBodyProps()}>
          {rows?.length === 0 ? (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <img
                  src={nodatafound}
                  alt="No Data Found"
                  style={{
                    width: '100%',
                    maxWidth: imageMaxWidth,
                    height: 'auto',
                  }}
                />
              </TableCell>
            </TableRow>
          ) : (
            page.map((row, key) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <DataTableBodyCell
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  ))}
                </TableRow>
              );
            })
          )}
        </TableBody>
          


        </Table>
 
        <MDBox

          display="flex"

          flexDirection={{ xs: "column", sm: "row" }}

          justifyContent="space-between"

          alignItems={{ xs: "flex-start", sm: "center" }}

          p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}

        >

          {showTotalEntries && (

            <MDBox mb={{ xs: 3, sm: 0 }}>

              <MDTypography variant="button" color="secondary" fontWeight="regular">

                Showing {entriesStart} to {entriesEnd} of {rows.length} entries

              </MDTypography>

            </MDBox>

          )}

          <Pagination

            count={Math.ceil(rows.length / 10)}

            onChange={handlePagination}

            color="primary"

          />

        </MDBox>


  </TableContainer>

);

}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: false,
  imageMaxWidth: '200px', 
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
imageMaxWidth: PropTypes.string,
};

export default DataTable;
