import PropTypes from "prop-types";
import * as React from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { APIs } from 'Services/APIs'
import FormField from "../FormField/index";
import { useEffect, useState } from "react";
import { BarLoader } from "react-spinners";
import axios from "../../../../../axiosinstance";
import { ErrorMessage, useFormikContext } from "formik";

function TypeInfo({ formData }) {
  const [loading, setLoading] = useState(false);
  const [categoryValue, setCategoryValue] = React.useState(null);
  const [categoryList, setCategoryList] = useState("");
  const [categorytypeList, setCategorytypeList] = useState("");
  const [categorytypevalue, setCategorytypeValue] = React.useState(null);
  const { setFieldValue, validateField } = useFormikContext();
  const [color] = useState("#344767");
  const { formField, values, errors, touched } = formData;
  const { typeName, typeDescrption, Category, Categorytype } = formField;
  var { typeName: typeNameV, typeDescrption: typeDescrptionV } = values;

  const defaultPropsCategorytype = {
    options: categorytypeList.length === 0
      ? [
        { name: "ITEM", code: "ITEM" },
        { name: "UOM", code: "UOM" },
        { name: "PACKAGE", code: "PACKAGE" },
        { name: "VITAL", code: "VITAL" },
        { name: "VITAL_UOM", code: "VITAL_UOM" },
        { name: "M_UOM_OPTIONS", code: "M_UOM_OPTIONS" },
        { name: "M_DOCTOR_OPTIONS", code: "M_DOCTOR_OPTIONS" },
        { name: "M_LAB_OPTIONS", code: "M_LAB_OPTIONS" },
        ]
      : categorytypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const defaultPropsCategory = {
    options: categoryList.length === 0
      ? [
        { name: "ITEM", code: "ITEM" },
        { name: "UOM", code: "UOM" },
        { name: "VITAL", code: "VITAL" },
        { name: "VITAL_UOM", code: "VITAL_UOM" },
        { name: "PACKAGE", code: "PACKAGE" },
        { name: "Currency", code: "Currency" },
        { name: "M_PRICE_OPERATION", code: "M_PRICE_OPERATION" },
        { name: "WAREHOUSE", code: "WAREHOUSE" },
        ]
      : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  async function getCategoryTypeData() {
    await axios.get(`${APIs.catgoryServiceApi}/get?type=CATEGORY_TYPE`)
      .then((response) => {
        getCategoryData();
        setCategorytypeList(response.data.data.dtoList);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  async function getCategoryData() {
      await axios.get(`${APIs.catgoryServiceApi}/get?type=CATEGORY`)
      .then((response) => {
        setCategoryList(response.data.data.dtoList);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }

  useEffect(() => {
    getCategoryTypeData();
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">Type Master</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={typeName.type}
                label=<span>{typeName.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={typeName.name}
                value={typeNameV}
                placeholder={typeName.placeholder}
                error={errors.typeName && touched.typeName}
                success={typeNameV.length > 0 && !errors.typeName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={typeDescrption.type}
                label=<span>{typeDescrption.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                name={typeDescrption.name}
                value={typeDescrptionV}
                placeholder={typeDescrption.placeholder}
                error={errors.typeDescrption && touched.typeDescrption}
                success={typeDescrptionV.length > 0 && !errors.typeDescrption}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>   
              <Autocomplete
                {...defaultPropsCategory}
                value={categoryValue}
                onChange={(event, newValue) => {
                  setCategoryValue(newValue);
                  values.Category = newValue?.name;
                  validateField('Category');
                }}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.Category && touched.Category}
                    success={categoryValue !== null && !errors.Category }
                    variant="standard"
                    label=<span>{Category.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  />
                  <ErrorMessage
               name="Category"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>     
                )}               
              />             
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                {...defaultPropsCategorytype}
                value={categorytypevalue}
                onChange={(event, newValue) => {
                  setCategorytypeValue(newValue);
                  values.Categorytype = newValue?.name; 
                  validateField('Categorytype');             
                }}
                renderInput={(params) => (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <MDInput
                    {...params}
                    error={errors.Categorytype && touched.Categorytype}
                    success={categorytypevalue !== null && !errors.Categorytype}
                    variant="standard"
                    label=<span>{Categorytype.label}<sup style={{color:"red",fontSize:"small",fontWeight:"bolder",position:"relative",top:"2px"}}> *</sup></span>
                  />
                    <ErrorMessage
               name="Categorytype"
               component="div"
               style={{ color: "red", fontSize: "12px", textAlign: "left", marginTop: '4px' }}
               />
               </div>
                )}
              />
            </Grid>
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
}
TypeInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default TypeInfo;
