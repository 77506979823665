

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// formik components
import { Formik, Form } from "formik";

// @mui material components

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples

import Footer from "examples/Footer";
import swal from "sweetalert";
import { MoonLoader } from "react-spinners";
// NewUser page components

// import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
import MDTypography from "components/MDTypography";

// NewUser layout schemas for form and form feilds

import validations from "./schemas/validations";
import form from "./schemas/form";
import initialValues from "./schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
// image
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import axios from "../../../../axiosinstance";

import { makeStyles } from "@material-ui/core/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import RegistrationInfo from "./components/RegistrationInfo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";


function getSteps() {
  return ["Admin Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <RegistrationInfo formData={formData} />;

    default:
      return null;
  }
}

function AdminRegistration() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;
  const [isLoading, setIsLoading] = useState(false);
  const [rolename, setRolename] = useState("");

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const handleBack = () => setActiveStep(activeStep - 1);
  async function otpData(code) {
    const otp = {
      createdby: "HEALTHCARE WEB UI",
      id: null,
      typevalue: "VERIFY",
      otptype: "EMAIL",
      recstatus: "OPEN",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",

      userid: code,
    };
    setIsLoading(true);
    await axios
      .post(
        // 'http://194.163.159.164:9163/api/Otps/v1/create',
        // otp
        // 'http://194.163.159.164:9163/api/Otps/v1/create',otp
        "otps-service/api/Otps/v1/create",
        otp
      )

      .then((response) => {
        navigate("/app/page/admin-otp");

        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage,
          "warning"
        );
        setIsLoading(false);
      });
  }
  const submitForm = async (values, actions) => {
   
    
    setIsLoading(true);
   
    const formData = {
      approvedby: "",
      approveddate: "",
      approver: "",
      approvertype: "",
      createddate: null,
      age: values.age,
      sex: values.gender,
      documentofproof: "",
      emailid: values.email,
      name: values.lastName
        ? values.firstName + " " + values.lastName
        : values.firstName,
      id: null,
      isactive: "",
      isloggedin: "",
      latlong: "",
     // rolename: values.role === "RE001" ? "NURSE" : values.role === "DO001" ? "DOCTOR" : values.role === "PA001" ? "PATIENT" : "UNKNOWN",
      rolename: values.role==="RE001" ? "NURSE" : "DOCTOR",
      rolecode: values.role,
description:values.description,
experience :values.experience,

      logincount: "",
      mobileno: values.mobilenumber,
      modifiedby: "",
      modifieddate: "",
      password: values.password,
      profileid: "string",
      recstatus: "PENDING",
      resourcecode: "HEALTHCARE",
      status: "ACTIVE",
      syncstatus: "synced",
      tempid: "",
      username: "",
      createdby: "HEALTHCARE WEB UI",
    };
    console.log("payloadHHHH", formData);
    await axios
      .post(
        // 'http://194.163.159.164:9162/api/loginservice/v2/create',
        // formData
        "login-service-mongo/api/loginservice/v2/create",
        formData
      )

      .then((response) => {
        var code = response.data?.data?.dto.code;
        localStorage.setItem("user-code", JSON.stringify({ code: code }));

        otpData(code);
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false);
       error.response.data ? 
        swal(
          "Oops!",
          error?.response?.data?.aceErrors[0]?.errorMessage,
          "warning"
        ) : swal(
          "Oops!",
          "Something wants wrong.",
          "warning"
        ) 
      });

    actions.setSubmitting(false);
   // actions.resetForm();

    // setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    console.log(values);

    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  const theme = responsiveFontSizes(
    createTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
        },
      },
    })
  );

  const useStyles = makeStyles((theme) => ({
    card: {
      width: "100%",
      marginBottom: "3vw",
      marginLeft: "1vw",

      [theme.breakpoints.up("md")]: {
        width: "40vw",
        marginLeft: "-8vw",
      },

      [theme.breakpoints.between("sm", "md")]: {
        width: "60vw",
        marginLeft: "-6vw",
      },
    },
  }));

  const classes = useStyles();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20} height="65vh">
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={8}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                         Registration Form
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="dark"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                         
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}






export default AdminRegistration;