import Analytics from "layouts/dashboards/analytics";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import SignInBasic from "layouts/authentication/sign-in/basic";
// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/team-4.jpg";
import ProfileSummary from "layouts/pages/Profilesummary";
import FindDoctor from "app/findDoctor/FindDoctor";

const routesLab = [
  {
    type: "collapse",

    name: localStorage.getItem("login-auth")
      ? JSON.parse(localStorage.getItem("login-auth"))?.name
      : "",

    key: "brooklyn-alice",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />,
    collapse: [
      {
        name: "My Profile",
        key: "my-profile",
        route: "/app/lab/profile",
        component: <ProfileOverview />,
      },
      {
        name: "Logout",
        key: "logout",
        route: "/app/home/find-doctors",
        component: <FindDoctor />,
      },
    ],
  },

  { type: "title", title: "Masters", key: "title-masters" },
  {
    type: "collapse",
    name: "User Management",
    key: "lab-usermanagement",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      // {
      //   name: "Department Master",
      //   key: "department-master",
      //   route: "/app/lab/department-master",
      // },
      // {
      //   name: "Designation Master",
      //   key: "designation-master",
      //   route: "/app/lab/designation-master",
      // },
      {
        name: "User List",
        key: "create-lab-user",
        route: "/app/lab/user-list",
      },
    ],
  },

  {
    type: "collapse",
    name: "Lab Project Master",
    key: "lab-project-master",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [  
          {
            name: "Lab Project List",
            key: "create-lab",
            route: "/app/lab/lab-project-list",
          
          },
        
          {
            name: "Lab Project Team",
            key: "create-labTeam",
            route: "/app/lab/lab-project-team-association",
          },
    ],
  },

  {
    type: "collapse",
    name: "Lab Master",
    key: "labmaster",
    icon: <Icon fontSize="medium">badge</Icon>,
    collapse: [
      {
        type: "item",
        name: "Lab List",
        key: "lablist",
        route: "/app/lab/lab-list",
      },
      {
        type: "item",
        name: "Lab Association",
        key: "labassociation",
        route: "/app/lab/lab-association",
      },

      {
        type: "item",
        name: "Lab Team Association",
        key: "labteamassociation",
        route: "/app/lab/lab-team-association",
      },
    ],
  },

  {
    type: "item",
    name: "Booking List",
    key: "booking-list",
    route: "/app/lab/booking-list",
  },

  {
    type: "item",
    name: "Lab Queue",
    key: "labqueue",
    route: "/app/lab/lab-queue",
  },
  {
    type: "item",
    name: "My Team",
    key: "myteam",
    route: "/app/lab/myteam",
  },

  {
    type: "item",
    name: "Search Patient",
    key: "searchpatient",
    route: "/app/lab/search-patient",
  },

  // {
  //   type: "item",
  //   name: "Slot Master",
  //   key: "createslot",
  //   route: "/app/lab/slotbooking",
  // },

  {
    type: "item",
    name: "Test Reports",
    key: "test-report",
    route: "/app/lab/test-report",
  },
];

export default routesLab;
