
import React from 'react'

function slot(date){
 
    var slotData={
         morningSlot : ['09:00', '10:00', '11:00', '12:00', '01:00','09:00', '10:00', '11:00', '12:00', '01:00'],
         eveningSlot : ['04:00', '05:00', '06:00', '07:00', '08:00'],
         date: "25-5-2023"
    }
  return slotData
}
function getData(date){
 
    var slotData={
         morningSlot : ['09:00', '10:00', '11:00', '12:00', '01:00','09:00', '10:00', '11:00', '12:00', '01:00' ],
         eveningSlot : ['04:00', '05:00', '06:00', '07:00', '08:00','04:00', '05:00', '06:00', '07:00', '08:00'],
         date: "29-5-2023"
    }
  return slotData
}


export {slot,getData} 
