import DashboardLayout from 'components/DashboardLayout'
import React ,{useEffect} from 'react'
import {Card, Grid, Box } from "@mui/material";
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard';
import AnalyticsCard from 'components/Analytics';
import ManageCard from '../../../components/ManageCard/manageCard';
import MDTypography from 'components/MDTypography';
import MDBox from 'components/MDBox';
import { Link, useNavigate } from "react-router-dom";
import { tableDataAction } from 'redux/Actions/tableDataAction';
import { useSelector } from 'react-redux';
import { APIs } from 'Services/APIs'
import { useDispatch } from 'react-redux';
import { ROLE_CODE } from "privateComponents/codes";

const Analytics = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { tableData, AuthReducer, tableActionRow } = useSelector(state => state)
  const totalCount = tableData?.rowData?.dtoList?.length
  const grapgData = [
    {icon:"", title:"Total Doctor", count:totalCount, path:'/app/mic/doctors/total-doctors'},
    {icon:"", title:"Total Transactions", count:20, path:'/app/mic/doctors/transaction'},
  ]
  const manageData = [
    { title:"Upcoming Appointments", path:'/app/mic/doctors'},
    { title:"Follow ups",path:'/app/mic/doctors'},
    { title:"Treated Patients",path:'/app/mic/doctors'},
    { title:"Referred Patients",path:'/app/mic/doctors'},
  ]
  useEffect(async()=>{
    dispatch(tableDataAction({url:`${APIs.getLoginUser}&rolecode=${ROLE_CODE.doctorcode}&isactive=${'ACTIVE'}`}))
  },[])
  
  return (
    <DashboardLayout>
    
      <Card sx={{padding:"2%", marginTop:"2%"}}>
        <Grid container spacing={3}>
            {grapgData.map((item, index)=>(
             
             <Grid item xs={12} md={6} lg={3} key={index}>
             <Link to={item.path}>
               <AnalyticsCard data={item} />
             </Link>
           </Grid>
        
            ))}
        </Grid> 
      </Card>
      <MDBox mt={2}>

      <MDTypography varient="h6">Manage</MDTypography>
      </MDBox>

      <Card sx={{padding:"2%", marginTop:"2%"}}>
      <Grid container spacing={3}>
            {manageData.map((item, index)=>(
             
            <Grid item xs={12} md={6} lg={3} key={index}>
                {/* <Link to={item.path}> */}
                <ManageCard data={item}/>
                {/* </Link> */}
            </Grid>
        
            ))}
        </Grid>
     
      </Card>
    </DashboardLayout>
  )
}

export default Analytics
