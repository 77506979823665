import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { departmentAction } from "redux/Actions/departmentAction";

const initialState = {
  departmentList:[],
  loading: false,
  error: null,
};

export const getDepartmentSlice = createSlice({
  name: "loginUserData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(departmentAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(departmentAction.fulfilled, (state, action) => {
        console.log(action.payload, "action.payload 22")
        state.loading = false;
        state.departmentList = action.payload;
        state.error = null;
      })
      .addCase(departmentAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  }
});

export default getDepartmentSlice.reducer;
