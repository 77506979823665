
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";
import swal from "sweetalert";
import { APIs } from "Services/APIs";
import axios from "axiosinstance";
import { useNavigate } from "react-router-dom";

const ViewReport = (props) => {
  const { viewReport, setViewReport, selecteddata, onClose, handleApi } = props;

  const [loading, setLoading] = useState(false);
  const [filternames, setFilterNames] = useState([]);
  const [allTestsCompleted, setAllTestsCompleted] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
const navigate = useNavigate()
  const handleClose = () => {
    setViewReport(false);
  };

  console.log("filternames", selecteddata);

  async function updateBooking(data) {
    const willDelete = await swal({
      title: "Are you sure?",
      text: `You will not be able to change the status again`,
      icon: "warning",
      buttons: ["Cancel", "Update"],
      dangerMode: true,
    });

    if (willDelete) {
      swal("Updating...", {
        icon: "info",
        buttons: false,
        closeOnClickOutside: false,
      });

      try {
        const updateData = {
          ...data,
          recstatus: "REPORT UPLOAD SOON",
        };
        await axios.put(`${APIs.bookingServiceApi}/update`, updateData);
        swal("Updated!", "Status changed successfully", "success");
        handleApi();
        handleClose();
        navigate('/app/lab/test-report')
      } catch (error) {
        swal("Error", "An error occurred while updating the status.", "error");
      }
    } else {
      swal("Cancelled", "Your status is not changed.", "info");
    }
  }

  useEffect(() => {
    const categoryCodes = selecteddata?.categorylist?.map(
      (category) => category?.parentdepcode
    );

    const filteredPricelistNames = selecteddata?.pricelist
      ?.filter((price) => categoryCodes?.includes(price?.code))
      ?.map((price) => price?.pricename);

    setFilterNames(filteredPricelistNames);

    // Check if all tests are completed
    const allCompleted = selecteddata?.pricelist?.every((price) =>
      filteredPricelistNames.includes(price?.pricename)
    );
    setAllTestsCompleted(allCompleted);
  }, [selecteddata]);

  return (
    <Dialog open={viewReport} onClose={handleClose} fullWidth maxWidth="sm">
      <DialogTitle
        style={{
          backgroundColor: "#039147",
          color: "#fff",
          textAlign: "center",
        }}
      >
        View status
      </DialogTitle>

      <div
        style={{
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {selecteddata?.pricelist?.map((name, index) => (
          <div
            key={index}
            style={{
              margin: "10px 0",
              padding: "10px",
              backgroundColor: "#fff",
              borderRadius: "4px",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
              fontSize: "16px",
              color: "#333",
              fontWeight: "500",
            }}
          >
            {name?.pricename} :{" "}
            {filternames.includes(name.pricename) ? (
              "Test Completed"
            ) : (
              <span style={{ color: "green" }}>Processing........</span>
            )}
          </div>
        ))}
      </div>

      <DialogActions>
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={handleClose}
        >
          Cancel
        </MDButton> 
        <MDButton
          variant="gradient"
          color="info"
          size="small"
          onClick={() => updateBooking(selecteddata)}
          disabled={!allTestsCompleted || loading}
        >
          {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Update"}
        </MDButton>
      </DialogActions>
    </Dialog>
  );
};

export default ViewReport;
