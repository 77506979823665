import React from "react";

// @mui material components

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components

import MDBox from "components/MDBox";

import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import Footer from "examples/Footer";

// Data

// import dataTableData from "layouts/applications/data-tables/data/dataTableData";

import { useEffect, useState } from "react";

// import { getRoles } from "@testing-library/react";

import { BarLoader } from "react-spinners";

import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";

import { Link, useNavigate } from "react-router-dom";

import PreviewIcon from "@mui/icons-material/Preview";

import axios from "../../../axiosinstance";
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";
import { useSelector } from "react-redux";
import { downloadurl } from "../../../Services/UploadDocumnet";
import DownloadIcon from "@mui/icons-material/Download";
import DataTable from "examples/Tables/DataTable";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ROLE_CODE } from "privateComponents/codes";
import { APIs } from "Services/APIs";

function PrescriptionList() {
  const comp = "SM";

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [fileurl, setFileurl] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const handleLoad = () => {
    setIsLoading(false);
  };
  const [color] = useState("#344767");

  const [tableDatas, setTableDatas] = useState({});

  const [Updatename, setUpdatename] = useState("");

  const [description, setDescription] = useState("");

  const [err, setErr] = useState("");

  const [item, setItem] = useState({});

  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);

  const iconStyle = {
    fontSize: "large",
    color: "#344767",
    marginRight: "9px",
    cursor: "pointer",
  };

  const addButtonStyle = {
    display: "flex",

    fontSize: "2em",

    flexDirection: "column",

    marginRight: "10px",
  };

  const handleDownload = (filename) => {
    swal({
      title: "Confirm Download",
      text: `Do you want to download report?`,
      icon: "info",
      buttons: {
        cancel: "Cancel",
        confirm: "Download",
      },
    }).then((willDownload) => {
      if (willDownload) {
        fetch(`${downloadurl}/${filename}`)
          .then((response) => response.blob())
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = blobUrl;
            link.download = filename;
            document.body.appendChild(link);
            link.click();
            setTimeout(() => {
              document.body.removeChild(link);
              URL.revokeObjectURL(blobUrl);
            }, 100);
          })
          .catch((error) => {
            console.error("Error downloading file:", error);
            swal(
              "Error",
              "An error occurred while downloading the file.",
              "error"
            );
          });
      } else {
        swal("Download Cancelled", "You have cancelled the download.", "info");
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  function viewData(item) {
    if (
      item.documentofproof === "" ||
      item.documentofproof == "string" ||
      item.documentofproof === null ||
      item.documentofproof === undefined
    ) {
      navigate(
        `/app/${auth.rolename?.toLowerCase()}/prescription-view?visitid=${
          item?.visitid
        }`,
        {
          state: {
            visitid: item?.visitid,
            doctor: item?.doctor?.name,
            patient: item?.patient,
            orderId: item?.ordernumber,
            status: item?.recstatus,
            modifiedate: item?.modifieddate,
          },
        }
      );
    } else {
      handleDownload(item.documentofproof);
    }
  }

  function viewdocData(item, rolecode) {
    setOpen(true);
    setFileurl(item.documentofproof);
  }

  function getData(profileId) {
    setLoading(true);

    const dataObj = {
      columns: [
        // { Header: " doctor name", accessor: "doctor.name" },

        // { Header: "experience", accessor: "doctor.experience" },

        { Header: "action", accessor: "action" },

        { Header: "order id", accessor: "ordernumber" },

        { Header: "fees", accessor: "pricelist[0].value" },

        { Header: "operation", accessor: "pricelist[0].operationtype" },
      ],

      rows: [],
    };

    const dataObjPatient = {
      columns: [
        // { Header: " doctor name", accessor: "doctor.name" },

        // { Header: "experience", accessor: "doctor.experience" },

        { Header: "action", accessor: "action" },

        { Header: "order id", accessor: "ordernumber" },

        { Header: "date", accessor: "fcreateddate" },

        { Header: "STATUS", accessor: "recstatus" },

        // { Header: "operation", accessor: "pricelist[0].operationtype" },
      ],

      rows: [],
    };

    var api = "";

    if (auth?.rolecode === ROLE_CODE.doctorcode) {
      dataObj.columns.push({
        Header: "patient name",
        accessor: "patient.name",
      });

      api = `${APIs.bookingServiceApi}/get?doctor.profileid=${profileId}&recstatus=PRESCRIBED`;

      axios

        .get(api)

        .then((response) => {
          response?.data?.data?.dtoList?.sort(
            (a, b) => new Date(b.slot.startdate) - new Date(a.slot.startdate)
          );
          response?.data?.data?.dtoList?.map((item) => {
            item.action = (
              <span>
                {item.documentofproof !== "string" ? (
                  <span>
                    <PreviewIcon
                      fontSize="medium"
                      style={iconStyle}
                      onClick={() => {
                        viewdocData(item);
                      }}
                    />
                    <DownloadIcon
                      fontSize="medium"
                      style={iconStyle}
                      onClick={() => {
                        viewData(item);
                      }}
                    />
                  </span>
                ) : (
                  <PreviewIcon
                    fontSize="medium"
                    style={iconStyle}
                    onClick={() => {
                      viewData(item);
                    }}
                  />
                )}
              </span>
            );

            item.fcreateddate = new Date(item.createddate).toDateString();

            dataObj.rows.push(item);
          });
          setTableDatas(dataObj);

          setLoading(false);
        })

        .catch((e) => {
          console.log("error", e);
        });
    } else {
      dataObjPatient.columns.push({
        Header: "doctor name",
        accessor: "doctor.name",
      });

      api = `${APIs.bookingServiceApi}/get?createdby=${profileId}`;

      axios

        .get(api)

        .then((response) => {
          response?.data?.data?.dtoList.map((item) => {
            item.action = (
              <span>
                <PreviewIcon
                  fontSize="medium"
                  color="blue"
                  style={iconStyle}
                  onClick={() => {
                    viewData(item);
                  }}
                />
              </span>
            );

            item.fcreateddate = new Date(item.createddate).toDateString();

            dataObjPatient.rows.push(item);
          });

          setTableDatas(dataObjPatient);

          setLoading(false);
        })

        .catch((e) => {
          console.log("error", e);
        });
    }
  }

  useEffect(() => {
    getData(auth?.profileid);
  }, []);

  return (
    <DashboardLayout>
      {/* <UpaarNavbar /> */}

      <MDBox pt={3}>
        <MDBox mt={14}>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",

                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    My Prescription
                  </MDTypography>
                </span>

                {/* <Link
                  style={{
                    alignSelf: "flex-end",

                    marginRight: "10px",

                    marginTop: "-23px",

                    color: "#344767",
                  }}
                  to={"/page/search-doctor"}
                >
                  <MdOutlineLibraryAdd />
                </Link> */}
              </div>
            </MDBox>

            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />

            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
        {open && (
          <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle
              style={{
                backgroundColor: "#039147",
                color: "#fff",
                textAlign: "center",
              }}
            >
              Prescription View
            </DialogTitle>
            <DialogContent>
              <MDBox mt={1}>
                <BarLoader
                  color={color}
                  loading={isloading}
                  aria-label="Loading Spinner"
                />
              </MDBox>

              <iframe
                src={`${downloadurl}/${fileurl}`}
                style={{
                  width: "100%",
                  height: "500px",
                  display: isloading ? "none" : "block",
                }}
                title="PDF Document"
                onLoad={handleLoad}
              />
            </DialogContent>
          </Dialog>
        )}
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default PrescriptionList;
