import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/medharva-logo.png";
import Search from "../../assets/images/Search.svg";
import "./navbar.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import userlogo from "../../../../assets/images/bruce-mars.jpg";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

import ProfileNavbarInfo from "app/findDoctor/footerNavbar/Sidebar/ProfileNavbarInfo/ProfileNavbarInfo";
import "./navbarhover.css";

const Section2 = () => {
  const navugate = useNavigate();
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 860);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 860);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="navbarcontainer2">
      <Grid item mr={2}>
        <a href="https://medharva.com/">
          <img className="section2img" src={Logo} alt="Medharva Logo" />
        </a>
      </Grid>
      <Grid item mr={isSmallScreen ? 3 : 5} className="section2item1">
        <a href="https://medharva.com/">Medharva</a>
      </Grid>
      <Grid item mr={isSmallScreen ? 3 : 5} className="section2item2">
        <input
          type="text"
          id="section2input"
          placeholder="Search for Doctors, Hospitals, tests and more"
          className="section2input"
        />
        <button className="section2button1">
          <img src={Search} alt="Search Logo" className="section2img1" />
        </button>
      </Grid>
      <Grid item mr={2}>
        {auth?.rolecode ? (
          <div class="popover__wrapper2">
            <a>
              <IconButton size="large">
                <button
                  className={`section2button3 ${
                    auth ? "authPresentStyle" : "authAbsentStyle"
                  }`}
                >
                  {auth && auth.name ? (
                    auth.name
                      .split(" ")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")
                  ) : (
                    <MDAvatar
                      src={userlogo}
                      alt="Brooklyn Alice"
                      style={{ width: "50px", height: "50px" }}
                    />
                  )}
                </button>
              </IconButton>
            </a>
            <div className="popover__content2">
              <ProfileNavbarInfo />
            </div>
            {/* <Patienthovernavbar /> */}
          </div>
        ) : (
          <>
          <button
            className="section2button2"
            onClick={() => navigate("/app/authentication/sign-in/basic")}
          >
            Login / Sign up
          </button>
          <button
            className="section2button2"
            onClick={() => navigate("/app/pages/users/new-doctorsignup")}
          >
           Doctor Sign up  
          </button>
          <button
            className="section2button2"
            onClick={() => navigate("/app/pages/users/new-sellerssignup")}
          >
           Sellers Sign up  
          </button>
          </>
        )}
      </Grid>
    </div>
  );
};
 
export default Section2;
