import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage, useFormikContext } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Grid } from "@mui/material";
import MDButton from "components/MDButton";
import { MoonLoader } from "react-spinners";

const TypeEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [categoryValue, setCategoryValue] = React.useState(null);
  const [categorytypevalue, setCategorytypeValue] = React.useState(null);
  const [loading, setIsLoading] = useState(false);
  const [loading1, setIsLoading1] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [categorytypeList, setCategorytypeList] = useState([]);

  const validationSchema = Yup.object({
    name: Yup.string().required("Type name is required."),
    description: Yup.string().required("Type description is required."),
    Category: Yup.string().required("Category is required."),
    Categorytype: Yup.string().required("Category type is required."),
  });
  console.log(selectedUser?.categorytype);
  const initialValues = {
    name: selectedUser?.name || "",
    description: selectedUser?.description || "",
    Category: selectedUser?.category || "",
    Categorytype: selectedUser?.categorytype || "",
  };

  const defaultPropsCategory = {
    options:
      categoryList.length === 0
        ? [
            { name: "ITEM", code: "ITEM" },
            { name: "UOM", code: "UOM" },
            { name: "PACKAGE", code: "PACKAGE" },
            { name: "VITAL", code: "VITAL" },
            { name: "VITAL_UOM", code: "VITAL_UOM" },
            { name: "Currency", code: "Currency" },
            { name: "WAREHOUSE", code: "WAREHOUSE" },
          ]
        : categoryList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const defaultPropsCategorytype = {
    options:
      categorytypeList.length === 0
        ? [
            { name: "ITEM", code: "ITEM" },
            { name: "UOM", code: "UOM" },
            { name: "PACKAGE", code: "PACKAGE" },
            { name: "VITAL", code: "VITAL" },
            { name: "VITAL_UOM", code: "VITAL_UOM" },
            { name: "M_UOM_OPTIONS", code: "M_UOM_OPTIONS" },
          ]
        : categorytypeList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };

  const onSubmit = async (values) => {
    setIsLoading1(true);
    try {
      const updatedUser = {
        ...selectedUser,
        category: values.Category,
        categorytype: values.Categorytype,
        description: values.description,
        name: values.name,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };
      await axios.put(`${APIs.typeMasterServiceApi}/update`, updatedUser);
      handleClose();
      handleEditreset();
      swal("Success!", "Type updated successfully.", "success");
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setIsLoading(false);
    }
  };

  async function getCategoryTypeData() {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/get?type=CATEGORY_TYPE`
      );
      const categorytypeData = response.data?.data?.dtoList;
      setCategorytypeList(categorytypeData);
      getCategoryData(categorytypeData);

      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function getCategoryData() {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${APIs.catgoryServiceApi}/get?type=CATEGORY`
      );
      const categoryData = response.data?.data?.dtoList;
      setCategoryList(categoryData);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    getCategoryTypeData();
  }, []);
  useEffect(() => {
    if (selectedUser) {
      setCategoryValue({ name: selectedUser.category });
      setCategorytypeValue({ name: selectedUser.categorytype });
    }
  }, [selectedUser]);

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, errors, touched, handleChange, setFieldValue }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Type Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            error={errors.name && touched.name}
                            success={values.name != null && !errors.name}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="name"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Type Description
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="description"
                            value={values.description}
                            onChange={handleChange}
                            error={errors.description && touched.description}
                            success={
                              values.description != null && !errors.description
                            }
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="description"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsCategory}
                            value={categoryValue}
                            onChange={(event, newValue) => {
                              setCategoryValue(newValue);
                              values.Category = newValue?.name;
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={errors.Category && touched.Category}
                                  success={
                                    categoryValue !== null && !errors.Category
                                  }
                                  label=<span>
                                    Category
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="Category"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsCategorytype}
                            value={categorytypevalue}
                            onChange={(event, newValue) => {
                              setCategorytypeValue(newValue);
                              values.Categorytype = newValue?.name;
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={
                                    errors.Categorytype && touched.Categorytype
                                  }
                                  success={
                                    categorytypevalue !== null &&
                                    !errors.Categorytype
                                  }
                                  label=<span>
                                    Category Type
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="Categorytype"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loading1 ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default TypeEdit;
