import React, { useState } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { Grid } from '@mui/material';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';



const theme = createTheme({
    overrides: {
        MuiStepIcon: {
            root: {
                '&$completed': {
                    color: 'green', 
                },
                '&$active': {
                    color: 'green', 
                },
            },
        
        },
    },
});

export default function TrackStatus(props ) {
  

 

    return (

        <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ height: "100%", width: "100%", mt: 10 }}
        >
            
            <Grid item xs={12} lg={12}>          
                <MDBox mx={2} mt={-2}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-5}
                        p={2}
                        mb={-2}
                        textAlign="center"
                    >
                        <MDTypography
                            variant="h5"
                            fontWeight="small"
                            color="white"
                            mt={1}
                        >
                            Submitted Successfully
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ height: "100%", }}
                >
                    <Grid item xs={12} lg={10}>
                        <Grid container spacing={2} mt={5} ml={2}>
                           
                                <ThemeProvider theme={theme}>
                                    <Stepper activeStep={props?.activeStep} orientation="vertical">
                                        {props?.steps?.map((step, index) => (
                                            <Step key={step.label}>
                                                <StepLabel>
                                                    {step.label}
                                                    <br />
                                                    <span >{props.getStepContent(index)}</span>
                                                     {/* <p>{step.date}</p>  */}
                                                </StepLabel>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </ThemeProvider>
                           
                        </Grid>                  
                    </Grid>
                </Grid>            
            </Grid>
        </Grid>

    );
}
