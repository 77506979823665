import React from "react";
import "./online.css";
import consultimge from "../../findDoctor/assets/images/consultindia.png";
import Slider from "react-slick";
import { Button, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
const OnlineHome = () => {
  const navigate = useNavigate();
  const cardDatas = [
    { image: consultimge },
    { image: consultimge },
    { image: consultimge },
  ];

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...sliderSettings}>
        {cardDatas.map((card, index) => (
          <div key={index}>
            <Grid className="consult-card-A" mt={23}>
              <Grid className="consult-card-B">
                <img
                  className="consult-card-imge"
                  src={card?.image}
                  alt={`Card ${index + 1}`}
                />
              </Grid>
              <div className="consult-card-C">
                <Grid className="consult-car-D">Consult India’s</Grid>
                <Grid className="consult-car-E">Top doctors's Online</Grid>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Grid className="consult-car-X">Flat 80% oFF</Grid>

                  <Grid className="consult-card-y">
                    <Button>
                      <span className="consult-card-z">NEW60</span>
                    </Button>
                  </Grid>
                </div>
              </div>
              <div></div>
              <Grid className="consult-button-container">
                <Button
                  className="consult-button"
                  onClick={() =>
                    navigate("/app/finddoctor/Speciality", {
                      state: {
                        pagelabel: "Online Consultation",
                      },
                    })
                  }
                >
                  <span className="consult-button-text"> Consult Now</span>
                </Button>
              </Grid>
            </Grid>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default OnlineHome;
