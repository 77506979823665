// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import axios from "axios";
import { BarLoader } from "react-spinners";

import { useDispatch } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";

import { useSelector } from "react-redux";
import { useFormikContext } from "formik";
import { getRole } from "app/Admin/AdminWorkspace/Role Master/service/Role";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";

function RegistrationInfo({ formData }) {
  const dispatch = useDispatch();
  const { department } = useSelector((state) => state.department);
  const { role, loading } = useSelector((state) => state.role);
  const { designation } = useSelector((state) => state.designation);

  const [color] = useState("#344767");

  const [designationList, setDesignationList] = useState([]);

  const { setFieldValue, setFieldError } = useFormikContext();

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    referalcode,
    phonenumber,
    bankname,
    ifsccode,
    email,
    age,
    description,
    experience,
    password,
    accountholdername,
    accountnumber,
    repeatPassword,
    adhar,
    pan,
  } = formField;
  var {
    firstName: firstNameV,
    description: descriptionV,
    experience: experienceV,
    lastName: lastNameV,
    referalcode: referalcodeV,
    password: passwordV,
    repeatPassword: repeatPasswordV,
    phonenumber: phonenumberV,
    accountnumber: accountnumberV,
    accountholdername: accountholdernameV,
    ifsccode: ifsccodeV,
    bankname: banknameV,
    email: emailV,
    adhar: adharV,
    pan: panV,
    age: ageV,
  } = values;

  function getDesignationData(code) {
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }
  useEffect(() => {
    dispatch(getRole());
    dispatch(getDepartment());
    dispatch(getDesignation());
  }, []);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        <MDTypography variant="h5">New User</MDTypography>
        <MDTypography variant="button" color="text">
          Mandatory informations
        </MDTypography>
      </MDBox>
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              error={!!errors.rolecode && touched.rolecode}
            >
              <InputLabel
                htmlFor="rolecode"
                style={{
                  marginLeft: "-14px",
                  paddingBottom: "2px",
                  marginTop: "2px",
                }}
              >
                Role
                <sup
                  style={{
                    color: "red",
                    fontSize: "small",
                    fontWeight: "bolder",
                    position: "relative",
                    top: "2px",
                  }}
                >
                  *
                </sup>
              </InputLabel>
              <Select
                native
                variant="standard"
                style={{ width: "100%" }}
                name="rolecode"
                id="rolecode"
                value={values.rolecode}
                onChange={(e) => {
                  const rolecode = e.target.value;
                  const selectedRoleObj =
                    role.find((obj) => obj.rolecode === rolecode) || {};

                  // const roleName =
                  //   e.target.options[e.target.selectedIndex].text;
                  setFieldValue("roleName", selectedRoleObj.name);
                  setFieldValue("rolecode", rolecode);
                }}
              >
                <option value=""></option>
                {role.map((obj) => (
                  <option key={obj.id} value={obj.rolecode}>
                    {obj.name}
                  </option>
                ))}
              </Select>
              {errors.rolecode && touched.rolecode && (
                <FormHelperText error style={{ marginLeft: "0px" }}>
                  {errors.rolecode}
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={firstName.type}
                label=<span>
                  {firstName.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={firstName.name}
                value={firstNameV}
                placeholder={firstName.placeholder}
                error={errors.firstName && touched.firstName}
                success={firstNameV.length > 0 && !errors.firstName}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormField
                type={lastName.type}
                label={lastName.label}
                name={lastName.name}
                value={lastNameV}
                placeholder={lastName.placeholder}
                // error={errors.lastName && touched.lastName}
                // success={lastNameV.length > 0 && !errors.lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth error={!!errors.gender && touched.gender}>
                <InputLabel
                  htmlFor="gender"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Gender
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="gender"
                  id="gender"
                  value={values.gender}
                  onChange={(e) => {
                    const gender = e.target.value;
                    const selectedDepartmentObj =
                      department.find((obj) => obj.code === gender) || {};

                    getDesignationData(e.target.value);
                    setFieldValue("department", selectedDepartmentObj);

                    setFieldValue("gender", gender);
                  }}
                >
                  <option value=""></option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Female</option>
                </Select>
                {errors.gender && touched.gender && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.gender}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={age.type}
                onKeyDown={(e) => {
                  if (!/[0-9\b\t]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                label=<span>
                  {age.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={age.name}
                value={ageV}
                placeholder={age.placeholder}
                error={errors.age && touched.age}
                success={ageV.length > 0 && !errors.age}
              />
            </Grid>
          </Grid>

          {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={username.type}
                label=<span>
                  {username.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={username.name}
                value={usernameV}
                placeholder={username.placeholder}
                error={errors.username && touched.username}
                success={usernameV.length > 0 && !errors.username}
              />
            </Grid>
        
            <Grid item xs={12} sm={6}>
              <FormControl
                fullWidth
                error={!!errors.rolecode && touched.rolecode}
              >
                <InputLabel
                  htmlFor="rolecode"
                  style={{
                    marginLeft: "-14px",
                    paddingBottom: "2px",
                    marginTop: "2px",
                  }}
                >
                  Role
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    *
                  </sup>
                </InputLabel>
                <Select
                  native
                  variant="standard"
                  style={{ width: "100%" }}
                  name="rolecode"
                  id="rolecode"
                  value={values.rolecode}
                  onChange={(e) => {
                    const rolecode = e.target.value;
                    const selectedRoleObj =
                      role.find((obj) => obj.rolecode === rolecode) || {};
                   
                    // const roleName =
                    //   e.target.options[e.target.selectedIndex].text;
                    setFieldValue("roleName", selectedRoleObj.name);  
                    setFieldValue("rolecode", rolecode);
                   
                  }}
                >
                  <option value=""></option>
                  {role.map((obj) => (
                    <option key={obj.id} value={obj.rolecode}>
                      {obj.name}
                    </option>
                  ))}
                </Select>
                {errors.rolecode && touched.rolecode && (
                  <FormHelperText error style={{ marginLeft: "0px" }}>
                    {errors.rolecode}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            
          </Grid> */}

          {(values.rolecode === "DO001" ||
            values.rolecode === "RE001" ||
            values.rolecode === "MI8236" ||
            values.rolecode === "AG2773") && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmentvalue"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Department
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      const selectedDepartmentObj =
                        department.find(
                          (obj) => obj.code === departmentvalue
                        ) || {};

                      getDesignationData(e.target.value);
                      setFieldValue("department", selectedDepartmentObj);

                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.departmentvalue && touched.departmentvalue && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.departmentvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.designationvalue && touched.designationvalue}
                >
                  <InputLabel
                    htmlFor="designationvalue"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={values.designationvalue}
                    onChange={(e) => {
                      const designationvalue = e.target.value;
                      //values.designation = newValue;
                      const designationobj =
                        designation.find(
                          (obj) => obj.code === designationvalue
                        ) || {};

                      setFieldValue("designation", designationobj);
                      setFieldValue("designationvalue", designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designationList.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  {errors.designationvalue && touched.designationvalue && (
                    <FormHelperText error style={{ marginLeft: "0px" }}>
                      {errors.designationvalue}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          )}

          {(values.rolecode === "DO001" || values.rolecode === "RE001") && (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={experience.type}
                  label=<span>
                    {experience.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={experience.name}
                  value={experienceV}
                  placeholder={experience.placeholder}
                  error={errors.experience && touched.experience}
                  success={experienceV.length > 0 && !errors.experience}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type={description.type}
                  label=<span>
                    {description.label}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </span>
                  name={description.name}
                  value={descriptionV}
                  placeholder={description.placeholder}
                  error={errors.description && touched.description}
                  success={descriptionV.length > 0 && !errors.description}
                />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                // type={phonenumber.type}
                onKeyDown={(e) => {
                  if (!/[0-9\b\t]/.test(e.key) && !['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab'].includes(e.key)) {
                    e.preventDefault();
                  }
                }}
                label=<span>
                  {phonenumber.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={phonenumber.name}
                value={phonenumberV}
                placeholder={phonenumber.placeholder}
                error={errors.phonenumber && touched.phonenumber}
                success={phonenumberV.length > 0 && !errors.phonenumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={email.type}
                label=<span>
                  {email.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV.length > 0 && !errors.email}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <FormField
                type={password.type}
                label=<span>
                  {password.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label=<span>
                  {repeatPassword.label}
                  <sup
                    style={{
                      color: "red",
                      fontSize: "small",
                      fontWeight: "bolder",
                      position: "relative",
                      top: "2px",
                    }}
                  >
                    {" "}
                    *
                  </sup>
                </span>
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
            </Grid>
          </Grid>
          {values.rolecode === "AG2773" && (
            <>
              <MDTypography variant="h5">Bank Detail</MDTypography>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={accountholdername.type}
                    label=<span>
                      {accountholdername.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={accountholdername.name}
                    value={accountholdernameV}
                    placeholder={accountholdername.placeholder}
                    error={
                      errors.accountholdername && touched.accountholdername
                    }
                    success={
                      accountholdernameV.length > 0 && !errors.accountholdername
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormField
                    type={accountnumber.type}
                    label=<span>
                      {accountnumber.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={accountnumber.name}
                    value={accountnumberV}
                    placeholder={accountnumber.placeholder}
                    error={errors.accountnumber && touched.accountnumber}
                    success={accountnumberV.length > 0 && !errors.accountnumber}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormField
                    type={adhar.type}
                    label=<span>
                      {adhar.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={adhar.name}
                    value={adharV}
                    placeholder={adhar.placeholder}
                    error={errors.adhar && touched.adhar}
                    success={adharV.length > 0 && !errors.adhar}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={pan.type}
                    label=<span>
                      {pan.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={pan.name}
                    value={panV}
                    placeholder={pan.placeholder}
                    error={errors.pan && touched.pan}
                    success={panV.length > 0 && !errors.pan}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={ifsccode.type}
                    label=<span>
                      {ifsccode.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={ifsccode.name}
                    value={ifsccodeV}
                    placeholder={ifsccode.placeholder}
                    error={errors.ifsccode && touched.ifsccode}
                    success={ifsccodeV.length > 0 && !errors.ifsccode}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={bankname.type}
                    label=<span>
                      {bankname.label}
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name={bankname.name}
                    value={banknameV}
                    placeholder={bankname.placeholder}
                    error={errors.bankname && touched.bankname}
                    success={banknameV.length > 0 && !errors.bankname}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField
                    type={referalcode.type}
                    label={referalcode.label}
                    name={referalcode.name}
                    value={referalcodeV}
                    placeholder={referalcode.placeholder}
                    error={errors.referalcode && touched.referalcode}
                    success={referalcodeV.length > 0 && !errors.referalcode}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for RegistrationInfo
RegistrationInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default RegistrationInfo;
