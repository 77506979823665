import React, { useEffect, useState } from "react";
import { Grid, CircularProgress, Card } from "@mui/material";
import Search from "../../../assets/svg/Search.svg";
import photo1 from "../../../assets/images/pexels-vika-glitter-1648377.jpg";
import "./selectSpeciality.css";
import axios from "../../../axiosinstance";
import { useLocation, useNavigate } from "react-router-dom";
import categoryimages from "static/category";
import { itemArrow } from "components/Sidenav/styles/sidenavItem";
import { useSelector } from "react-redux";
import profilePicture from "../../../assets/images/products/No-data-found.png";
import MDBox from "components/MDBox";
import ProfileSkeleton from "components/Skeleton/ProfileSkeleton";
import { ROLE_CODE } from "privateComponents/codes";


const Speciality = () => {
  const navigate = useNavigate();
 
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 560);
  const [subcategoryList, setSubcategoryList] = useState([]);
  const [selectedSpecialities, setSelectedSpecialities] = useState([]);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const [isMidScreen, setIsMidScreen] = useState(window.innerWidth <= 900);

  useEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 560);
      setIsMidScreen(window.innerWidth <= 900);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Function to check if an item code is selected
  const isItemSelected = (item) => {
    return selectedSpecialities.includes(item.code);
  };
  const filteredData = subcategoryList.filter((item) =>
    item.name.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Function to check if an item code is stored in local storage
  const isItemInLocalStorage = (item) => {
    const storedSelection = localStorage.getItem("doctor-code");
    return storedSelection && storedSelection.includes(item.code);
  };

  // Function to get selected items from local storage
  const getLocalStorageSelection = () => {
    const storedSelection = localStorage.getItem("doctor-code");
    return storedSelection ? storedSelection.split(",") : [];
  };

  // UseEffect to set selectedSpecialities from local storage on initial load
  useEffect(() => {
    const storedSelection = getLocalStorageSelection();
    setSelectedSpecialities(storedSelection);
  }, []);

  // Function to handle selection of doctors
  // const selecteddoctor = (item) => {
  //   const updatedSelection = [...selectedSpecialities];
  //   const index = updatedSelection.indexOf(item.code);
  //   if (index === -1) {
  //     updatedSelection.push(item.code);
  //   } else {
  //     updatedSelection.splice(index, 1);
  //   }
  //   setSelectedSpecialities(updatedSelection);
  //   localStorage.setItem("doctor-code", updatedSelection.join(",")); // Save selection to local storage
  // };
  const selecteddoctor = (item) => {
    const updatedSelection = [...selectedSpecialities];
    const index = updatedSelection.indexOf(item.code);
    if (index === -1) {
      updatedSelection.push(item.code);
    } else {
      updatedSelection.splice(index, 1);
    }
    setSelectedSpecialities(updatedSelection);
   
    // Save selection to local storage
    if (updatedSelection.length > 0) {
      localStorage.setItem("doctor-code", updatedSelection.join(","));
    } else {
      localStorage.removeItem("doctor-code");
    }
  };
  // Function to fetch subcategories from the server
  async function getSubCategory() {
    setLoading(true);
    try {
      const response = await axios.get(
        `category-service/api/Category/v1/getWithHierarchy?depth=2&hierarchicalcode=`
      );
      setSubcategoryList(response?.data?.data?.dtoList);
    } catch (e) {
      console.log("error", e);
    } finally {
      setLoading(false);
    }
  }

  // Fetch subcategories on component mount
  useEffect(() => {
    getSubCategory();
  }, []);

  return (
    <div>
      <Grid
        item
        mt={isSmallScreen ? 25 : 23}
        ml={isSmallScreen ? 0 : 9}
        className="selectspecialityitem"
      >
        Select Speciality
      </Grid>

      <Grid item className="selectspecialitycontainer">
        <Grid item mt={3} className="selectspecialityitem2">
          <input
            type="text"
            id="selectspecialityinput"
            placeholder="Search for specialities"
            className="selectspecialityinput"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className="selectspecialitybutton1">
            <img
              src={Search}
              alt="Search Logo"
              className="selectspecialityimg1"
            />
          </button>
        </Grid>
      </Grid>
      {loading ? (
        // <div style={{ textAlign: "center", marginTop: "90px" }}>
        //   <CircularProgress />
        // </div>
        <Grid container xs={12} justifyContent={"center"} mb={2}>
        <Grid container xs={11} justifyContent={"center"}  mt={5} spacing={2} >
        {[...Array(18)].map((_, index) => (
          <Grid item key={index}   xs={11} sm={6} md={4} lg={2}>
           
              <ProfileSkeleton variant='circular'  width={200} height={200} />
            
              <ProfileSkeleton  />
           
          </Grid>
        ))}
      </Grid>
      </Grid>
      ) : (
        <div className="selectspecialitycontainer2">
               {filteredData.length === 0 ? (
                        <Grid container  xs={11} justifyContent="center" mt={5} style={{padding: "10px"}}>
                          <Card  className="selectspecialitycard">
                                 <MDBox display="flex" justifyContent="center" alignItems="center" p={3}>
                                 
          <img
      src={profilePicture}
      alt="Brooklyn Alice"
      style={{ 
        width: '100%', 
        maxWidth: '200px', 
        height: 'auto', 
        // maxHeight:'200px'
       
      }} 
    />

         
               </MDBox>
                 </Card>
                        </Grid>
                    ) : (
                      <>
          <Grid container xs={10} mt={5} gap={5} className="selectspecialityitem3">

     
            {filteredData.map((item, index) => (
              <Grid
                item
                key={index}
                mt={3}
                className={`selectspecialitycontainer3 ${
                  isItemSelected(item) ? "selected" : ""
                }`}
                onClick={() => selecteddoctor(item)}
              >
                <img
                  src={categoryimages[item?.name]}
                  alt="Image"
                  className={`selectspecialityimg ${
                    isItemSelected(item) ? "selected" : ""
                  }`}
                />
                {isItemInLocalStorage(item) && (
                  <div className="greenTick">&#10004;</div>
                )}
                <br />
                <div className="selectspecialitytext">{item?.name}</div>
              </Grid>
            ))}
          
          </Grid>
            </>
          )}
          <Grid container xs={10} mt={15} mb={isMidScreen ? 10 : 5}>
            <div className="spacebutton">
              <div>
                <button
                  className="previous"
                  onClick={() => navigate("/app/home/find-doctors")}
                >
                  Previous
                </button>
              </div>
              <div>
              <button
                  className="next"
                  
                  
                  onClick={() => {
                  if (auth.logincount=="1") {
                   
                    navigate("/app/patient/patient-information")
                    swal(
                      "Attention",
                      "Before we proceed further, could you Please Provide Personal Information and Contact.",
                      "warning"
                    );
      
                  } else if (auth?.rolecode === ROLE_CODE?.patientcode) {
                    navigate(`/app/patient/doctor-booking`, {
                      state: {
                        codes: selectedSpecialities.map((item) => item.code),
                        pagelabel: location?.state?.pagelabel,
                      },
                    });
                  } else {
                    navigate(`/app/home/doctor-booking`, {
                      state: {
                        codes: selectedSpecialities.map((item) => item.code),
                        pagelabel: location?.state?.pagelabel,
                      },
                    });
                  }

                }}
                >
                  Next
                </button>
              </div>
            </div>
          </Grid>
        </div>
      )}
    </div>
  );
};

export default Speciality;
