
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import { APIs } from 'Services/APIs'

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { Autocomplete, FormControlLabel, Switch, Tooltip } from "@mui/material";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import AddressEdit from "app/Doctor/DoctorWorkspace/AddressInformation/new-address/AddressEdit";
import { FaRegEdit } from "react-icons/fa";

const DoctorAddressList = () => {
  const comp = "SM";
  const [loading, setLoading] = useState(false);
  const [openpopup, setOpenPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [profileId, setProfileId] = useState(JSON.parse(localStorage.getItem("login-auth"))?.profileid)
  const [role, setRole] = useState("");
  const [code, setCode] = useState(null)
  const [doctorList, setDoctorlist] = useState("")
  const [color] = useState("#344767");
  const dataObj = {
    columns: [
        { Header: "first line", accessor: "firstline" },
        { Header: "second line", accessor: "secondline" },
        { Header: "city", accessor: "city" },
        { Header: "District", accessor: "district" },
        { Header: "Sub District", accessor: "subdistrict" },
        { Header: "State", accessor: "state" },
        // { Header: "action", accessor: "action" },
        { Header: "Edit/Delete", accessor: "delete" },
       
    ],
    rows: [],
  };
  const [tableDatas, setTableDatas] = useState(dataObj);
  const iconStyle = { fontSize: "1.5em", color: "red", marginRight: "9px" };
  const iconStyles = { fontSize: "1.5em", color: "#039147", marginRight: "9px" };
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
 
   const defaultPropsDoctor = {
    options: !doctorList ? [{ name: "Loading...", id: 0 }] : doctorList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
};
  
const handleClose = () => {
  console.log("dsfsdfs");
  setOpenPopup(false);
};
async function update(object) {

  await axios.put(`${APIs.addressServiceApi}/update`, object)
   .then((response) => {

     getData(profileId)
   })
   .catch((e) => {
     console.log("error", e);
     getRole(profileId)
   });
}
const toggler = (checked, request) => {

  delete request.action;
  delete request.delete;
  if (checked) {
    request.recstatus = "PRIMARY";

  } else {
    request.recstatus = "OPEN";


  }
  update(request);
};
  async function onDelete(item) {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Address.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          // Delete the item from the server
          await axios.put(`${APIs.addressServiceApi}/delete`, item);
  
          // Update the state by filtering out the deleted item
          setTableDatas((prevData) => {
            const newRows = prevData.rows.filter((row) => row.id !== item.id);
            return { ...prevData, rows: newRows };
          });
  
          swal("Your Qualification record has been deleted!", {
            icon: "success",
          });
        } catch (error) {
          console.error("Error deleting item:", error);
          swal("Error deleting record!", {
            icon: "error",
          });
        }
      } else {
        swal("Your Qualification is safe!");
      }
    });
  }
  

  async function onUpdatedata(object) {
    setSelectedUser(object);
    setOpenPopup(true);
  }

  const getRole = async(id) => {
    
    setLoading(true);
 
      await axios.get(`${APIs.addressServiceApi}/get?profileid=${id}`)
      .then((response) => {
        response?.data?.data?.dtoList.map((item) => {
         
          if (item.recstatus == "PRIMARY") {
            // setChecked(true);
            item.action = (

              <FormControlLabel
                name={item.id}
                control={
                  <Switch
                    onClick={(e) =>
                      toggler(e.target.checked, item)
                    }
                    defaultChecked
                  />
                }
                label=""
              />

            );
          }
          if (item.recstatus == "OPEN") {
            // setChecked(true);
            item.action = (

              <FormControlLabel
                name={item.id}
                control={
                  <Switch
                    onClick={(e) =>
                      toggler(e.target.checked, item)
                    }
                  />
                }
                label=""
              />




            );
          }
          item.delete = (
            <span>
                <Tooltip title="Edit">
             <Link to={{}}>
                <FaRegEdit
                  style={iconStyles}
                  onClick={() => {
                    onUpdatedata(item);
                      setOpenPopup(true);
                  }}
                />
              </Link>
              </Tooltip>
  <Tooltip title="Delete">
              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
              </Tooltip>
            </span>
          );
          dataObj.rows.push(item);
        });
        setTableDatas(dataObj);

        // setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
    async function getDoctorData(code) {
      await axios
      .get(`${APIs.loginServiceApi}/get?rolecode=${code}&recstatus=APPROVED`)
      .then((response) => {
      

        
      
    setDoctorlist(response?.data?.data?.dtoList)
       
        // setLoader(false);
      })
      .catch((e) => {
        // setLoader(false);
        console.log("error", e);
      });
  }
    useEffect(() => {
    getDoctorData("DO001");
  }, []);
  return (
    <>
   
      <Card>
        <MDTypography
          variant="h5"
          fontWeight="medium"
          style={{ marginTop: "20px", marginLeft: "20px" }}
        >
          Address List
        </MDTypography>
        <MDBox pl={3} pr={3} lineHeight={1}>
          <div style={addButtonStyle}>
            <span
              style={{
                alignSelf: "flex-start",
                marginTop: "20px",
              }}
            ></span>
            
          </div>
        </MDBox>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={7}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              style={{ marginLeft: "20px" }}
              sx={{ width: 300 }}
              {...defaultPropsDoctor}
              onChange={(event, newValue) => {
                console.log(newValue)
            
                getRole(newValue?.profileid);
             
                
              }}
              renderInput={(params) => <TextField {...params} label="Select Doctor" />}
            />
          </Grid>
        </Grid>
        <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
        {!loading && (
          <MDBox pt={3}>
            <MDBox>
              <Card>
                <DataTable table={tableDatas} canSearch={true} />
              </Card>
            </MDBox>
          </MDBox>
        )}
      </Card>
      {/* <Footer /> */}
      {openpopup && (
          <AddressEdit
            openpopup={openpopup}
            setopenpopup={setOpenPopup}
            title={"Address Edit"}
            selectedUser={selectedUser}
            handleClose={handleClose}
            getData={getRole}
          />
        )}
    </>
  );
};




export default DoctorAddressList;