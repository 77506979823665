import { useState,useEffect } from "react";

// Import form-related components and dependencies
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Import form validation, schema, and initial values
import validations from "../schemas/validations";
import form from "../schemas/form";
import initialValues from "../schemas/initialValues";
import { useNavigate } from "react-router-dom";

// Import loader component and Redux-related dependencies
import { MoonLoader } from "react-spinners";
import { useDispatch } from "react-redux";


import { useSelector } from "react-redux";
import swal from "sweetalert";
import Dialog from "@mui/material/Dialog";
import DepartmentInfo from "./departmentInfo";

import { createDepartment } from "../service/Department";
// Function to define form steps
function getSteps() {
  return ["Department Info"];
}

// Function to get content for each form step
function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <DepartmentInfo formData={formData} />;
    default:
      return null;
  }
}

// Create the CreateBrand functional component
export default function Createdepartmnetpopup(props) {
  const { openpopupDepartment, setopenpopupDepartment } = props;
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [isLoading, setIsLoading] = useState(false); // Loader state
    const steps = getSteps();
    const { formId, formField } = form;
    const currentValidation = validations[activeStep];
    const isLastStep = activeStep === steps.length - 1;
 
  
    // Function to simulate a delay
    const sleep = (ms) =>
      new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
  
    // Function to handle going back to the previous step
    const handleBack = () => setActiveStep(activeStep - 1);
    const dispatch = useDispatch();
  
    // Function to submit the form
    const submitForm = async (values, actions) => {
      setIsLoading(true);
      console.log("Form values:", values);
  
      const requestData = {
        description: values.departmentDescrption,
  
        hierarchicalcode: values.parenthierarchical,
        id: null,
        latlong: "string",
        name: values.departmentName,
        parentdepcode: values.department,
        parenthierarchicalcode: values.parenthierarchical,
        recstatus: "OPEN",
        status: "Active",
        syncstatus: "SYNCED",
      };
  
      

      const response = await dispatch(createDepartment(requestData));

      if (!response.error) {

        
        setopenpopupDepartment(false)
        swal("Good job!", "Data has been updated successfully!", {
          icon: "success",
        });
        setIsLoading(false)
        actions.resetForm();
        
      } else {
        actions.resetForm();
        swal({
          icon: "error",
          title: "Error",
           text:
          response?.payload?.response?.data.aceErrors?.[0]?.errormessage ||
          "something wents wrong",
      
        });
        setIsLoading(false)
        handleClose()
      }



    };
  
    // Function to handle form submission
    const handleSubmit = (values, actions) => {
  if (isLastStep) {
        // If it's the last step, submit the form
        submitForm(values, actions);
      } else {
        // If not the last step, move to the next step
        setActiveStep(activeStep + 1);
        actions.setTouched({});
        actions.setSubmitting(false);
      }
    };
   

  const handleClose = () => {
    setopenpopupDepartment(false);
  };

 

  // Render the component
  return (

    <Dialog open={openpopupDepartment} onClose={handleClose} fullWidth maxWidth="md">
    
    <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%", mt: 8 }}
        >
          <Grid item xs={12} lg={12}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-3}
                        p={2}
                        mb={-2}
                        textAlign="center"
                      >
                        <MDTypography
                          variant="h4"
                          fontWeight="small"
                          color="white"
                          mt={1}
                        >
                          Department Master
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={5}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            type="submit"
                            variant="gradient"
                            color="info"
                            // disabled={isLoading}
                          >
                            {isLoading ? (
                              <MoonLoader color="#f2fefa" size={16} />
                            ) : (
                              "Submit"
                            )}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </Dialog>
    
  );
}