const form = {
  formId: "new item-form",
  formField: {
    // itemame: {
    //   name: "itemame",
    //   label: "Itemname",
    //   type: "text",
    //   errorMsg: "item is required.",
    // },
    name: {
      name: "name",
      label: "Name",
      type: "text",
      errorMsg: "Name is reqired.",
    },
    itemtype: {
      name: "itemtype",
      label: "Item Type",
      type: "text",
      errorMsg: "Item type is reqired.",
    },
    packagetype: {
      name: "packagetype",
      label: "Package Type",
      type: "select",
      errorMsg: "Package type is reqired.",
    },
    sapcode: {
      name: "sapcode",
      label: "SAP code",
      type: "text",
      errorMsg: "SAP code is required.",
    },
    uom: {
      name: "uom",
      label: "UOM",
      type: "text",
      errorMsg: "UOM is required.",
    },
  },
};

export default form;
