import React from "react";
import "./hover.css";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import Icon from "@mui/material/Icon";
import { useSelector } from "react-redux";
import MDAvatar from "components/MDAvatar";
import profilePicture from "assets/images/team-4.jpg";
import { Grid } from "@mui/material";
const Pharmacynavbar = () => {
  const reduxDispatch = useDispatch();
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  return (
    <div className="popover__content">
      {/* <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
  
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   </Grid>
   <Grid item xs={12} sm={6}>
   {auth?.name}
 
   </Grid>
   </Grid> */}
   <div className="modal-area">
   <MDAvatar src={profilePicture} alt="Brooklyn Alice" size="sm" />
   <p style={{ marginLeft: "10px",fontSize:"16px" }}>{auth?.name}</p>
   </div>


    
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person
        </Icon>

        <Link to="/app/pages/profile/profile-overview">My Profile</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          person
        </Icon>

        <Link to="/app/layout/pages/profile/new-adress">Create Address</Link>
      </div>
      
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          local_hospital
        </Icon>
       
        <Link to="/app/layout/pages/profile/create-slot">Create Slot</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          local_hospital
        </Icon>
       
        <Link to="/app/pharmacy/pharmacycategory">Create Category</Link>
      </div>
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          local_hospital
        </Icon>
       
        <Link to="/app/pharmacyprice/pharmacyprice">Create Price</Link>
      </div>
      
      
     
      <div className="modal-area">
        <Icon fontSize="medium" style={{ marginRight: "10px" }}>
          logout
        </Icon>
        <a href="#" onClick={() => reduxDispatch({ type: "LOGOUT_FULFILLED" })}>
  Log Out
</a>

      </div>
    </div>
  );
};

export default Pharmacynavbar;
