import { Grid } from "@mui/material";
import React from "react";
import "./workProcessAtMedharva.css";
import AppointmentBook from "../../findDoctor/assets/images/appointmentbook.png";
import Availability from "../../findDoctor/assets/images/avialability.png";
import HomeVisit from "../../findDoctor/assets/images/homevisit.png";
import BloodCollection from "../../findDoctor/assets/images/bloodcollection.png";
import RemotePatient from "../../findDoctor/assets/images/reportpatient.png";
import OnlineConsultation from "../../findDoctor/assets/images/onlineconsultation.png";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const workProcessAtMedharva = () => {
  const workProcessAtMedharvadata = [
    {
      image: AppointmentBook,
      about: "Book Appointments",
    },
    {
      image: Availability,
      about: "Availability",
    },
    {
      image: HomeVisit,
      about: "Home visit",
    },
    {
      image: BloodCollection,
      about: "Collect Blood",
    },
    {
      image: RemotePatient,
      about: "Get report",
    },
    {
      image: OnlineConsultation,
      about: "Online consult Doctors",
    },
  ];

  return (
    <div className="workprocessatmedharvaroot">
      <Grid
        container
        xs={11}
        mt={7}
        mb={5}
        className="workprocessatmedharvacontainer"
      >
        <Grid item xs={12} mt={5} className="workprocessatmedharvaitem">
          Work Process at Medharva
        </Grid>
        <Grid container className="workprocessatmedharvacontainer2">
          {workProcessAtMedharvadata.map((item, index) => (
            <>
              <Grid
                key={index}
                item
                mt={5}
                mb={5}
                className="workprocessatmedharvacontainer3"
              >
                <Grid item className="workprocessatmedharvacontainer2inner">
                  <Grid item ml={1} className="workprocessatmedharvaitem2">
                    <img
                      src={item.image}
                      alt="service logo"
                      className="workprocessatmedharvaimg"
                    />
                  </Grid>
                  {index !== workProcessAtMedharvadata.length - 1 && (
                    <Grid item ml={3} className="workprocessatmedharvaarrow">
                      <ChevronRightIcon fontSize="medium" />
                    </Grid>
                  )}
                </Grid>
                <Grid item mt={3} className="workprocessatmedharvaitem3">
                  {item.about === "Book Appointments" ? (
                    <>
                      Book <br /> Appointments
                    </>
                  ) : item.about === "Online consult Doctors" ? (
                    <>
                      Online consult <br /> Doctors
                    </>
                  ) : (
                    item.about
                  )}
                </Grid>
              </Grid>
            </>
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default workProcessAtMedharva;
