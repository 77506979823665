import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";

export const getSummaryServiceAction = 
   createAsyncThunk("getSummaryServiceData", async(data, {rejectWithValue}) => {
    let url;
  
    try {
      if(data && data.profileid && data.type){
        url = APIs.summaryServiceApi +`/get?profileid=${data.profileid}&type=${data.type}`;
    } else if(data && data.type){
        url = APIs.summaryServiceApi +`/get?type=${data.type}`;
      }
      else if(data && data.url){
        url = data.url;
      } else {
        url = APIs.summaryServiceApi +`/get`;
      }
        let respData = await getAllUsers(url, "")
        return respData?.dtoList || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });