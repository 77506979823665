import * as Yup from "yup";
import checkout from "./form";

const {
  formField: {frequencyDescription,countperday},
} = checkout;

const validations = [
  Yup.object().shape({
    [frequencyDescription.name]: Yup.string().required(frequencyDescription.errorMsg),
    [countperday.name]: Yup.string().required(countperday.errorMsg),
 
 
  }),
];

export default validations;
countperday