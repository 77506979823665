// import { useState } from "react";
// import {Link, useNavigate } from "react-router-dom";
// // formik components
// import { Formik, Form } from "formik";

// // @mui material components
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";

// // Material Dashboard 2 PRO React components
// import MDBox from "components/MDBox";
// import MDButton from "components/MDButton";

// import Footer from "examples/Footer";
// import swal from 'sweetalert';
// import { useLocation} from 'react-router-dom';

// // import Socials from "layouts/pages/doctor/new-doctor/components/Socials";
// // import Profile from "layouts/pages/doctor/new-doctor/components/Profile";
// import MDTypography from "components/MDTypography";
// import axios from "../../../axiosinstance"
// // NewUser layout schemas for form and form feilds

// import validations from "../components/otp/new-otp/schemas/validations";
// import form from "../components/otp/new-otp/schemas/form";
// import initialValues from "../components/otp/new-otp/schemas/initialValues";
// import CoverLayout from "layouts/authentication/components/CoverLayout";
// // image
// import bgImage from "assets/images/otp.jpeg";
// import OtpInfo from "../components/otp/new-otp/components/OtpInfo";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";



// function getSteps() {
//   return ["Otp Info"];
// }

// function getStepContent(stepIndex, formData) {
//   switch (stepIndex) {
//     case 0:
//       return <OtpInfo formData={formData} />;

//     default:
//       return null;
//   }
// }

// function AdminOtp() {
//   const navigate = useNavigate();
//   const [activeStep, setActiveStep] = useState(0);
//   const location =useLocation();
  
//    const [code,setCode]=useState(JSON.parse(localStorage.getItem('user-code')).code)
//   const steps = getSteps();
//   const { formId, formField } = form;
//   const currentValidation = validations[activeStep];
//   const isLastStep = activeStep === steps.length - 1;

//   const sleep = (ms) =>
//     new Promise((resolve) => {
//       setTimeout(resolve, ms);
//     });
//   const handleBack = () => setActiveStep(activeStep - 1);

//   const submitForm = async (values, actions) => {
   
//     console.log("hii", values);
    
 
//         await axios
//         .post(
//           `otps-service/api/Otps/v1/confirm?otp=${values.otp}&userid=${code}`
//          // `http://194.163.159.164:9163/api/Otps/v1/confirm?otp=${values.otp}&userid=${code}`
         
//         )
  
//         .then((response) => {
         
         
//           navigate("/app/pages/users/reset-password", {
//             state: {
//               email: location
              
//             },
//           });
//         }
//           )
//         .catch((e) => {
//           swal("Oops!", e.response.data.aceErrors[0].errorMessage, "warning");
         
//         });

//     actions.setSubmitting(false);
//     actions.resetForm();

//     setActiveStep(0);
//   };

//   const handleSubmit = (values, actions) => {
//     console.log(values);

//     if (isLastStep) {
//       submitForm(values, actions);
//     } else {
//       setActiveStep(activeStep + 1);
//       actions.setTouched({});
//       actions.setSubmitting(false);
//     }
//   };

//   return (
//     <DashboardLayout>
//       <DashboardNavbar />
    
//       <MDBox py={3} mb={20} height="65vh">
//         <Grid
//           container
//           justifyContent="center"
//           alignItems="center"
//           sx={{ height: "100%", mt: 8,ml:20}}
//         >
//           <Grid item xs={12} lg={8}>
//             <Formik
//               initialValues={initialValues}
//               validationSchema={currentValidation}
//               onSubmit={handleSubmit}
//             >
//               {({ values, errors, touched, isSubmitting }) => (
//                 <Form id={formId} autoComplete="off">
//                 <Card sx={{ height: "80%",width:"400px", marginTop: "50px",marginLeft:"-30px" }}>
//                     <MDBox mx={2} mt={-3}>
//                     <MDBox
//         variant="gradient"
//         bgColor="info"
//         borderRadius="lg"
//         coloredShadow="success"
//         mx={2}
//         mt={-1}
//         p={3}
//         mb={1}
//         textAlign="center"
//       >
//         <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
//          Otp
//         </MDTypography>
//         <MDTypography display="block" variant="button" color="white" my={1}>
//           Enter your OTP to register
//         </MDTypography>
//       </MDBox>
//                     </MDBox>
//                     <MDBox p={3}>
//                       <MDBox>
//                         {getStepContent(activeStep, {
//                           values,
//                           touched,
//                           formField,
//                           errors,
//                         })}
//                         <MDBox
//                           mt={2}
//                           width="100%"
//                           display="flex"
//                           justifyContent="space-between"
//                         >
//                           {activeStep === 0 ? (
//                             <MDBox />
//                           ) : (
//                             <MDButton
//                               variant="gradient"
//                               color="light"
//                               onClick={handleBack}
//                             >
//                               back
//                             </MDButton>
//                           )}
//                           <MDButton
//                             disabled={isSubmitting}
//                             type="submit"
//                             fullwidth
//                             variant="gradient"
//                             color="info"
//                             onClick={() => {
//                               // navigate('/pages/otp/new-otp')
//                               console.log("submit");
//                             }}
//                           >
//                            Verify
//                           </MDButton>
//                         </MDBox>
                       

//                       </MDBox>
//                     </MDBox>
//                   </Card>
//                 </Form>
//               )}
//             </Formik>
//           </Grid>
//         </Grid>
//       </MDBox>
//       <Footer />
//     </DashboardLayout>
//   );
// }









// export default AdminOtp;



import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import axios from "../../../axiosinstance";
import validations from "../components/otp/new-otp/schemas/validations";
import form from "../components/otp/new-otp/schemas/form";
import initialValues from "../components/otp/new-otp/schemas/initialValues";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import bgImage from "assets/images/otp.jpeg";
import OtpInfo from "../components/otp/new-otp/components/OtpInfo";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import swal from 'sweetalert';
import { useLocation } from 'react-router-dom';
import UpaarNavbar from "examples/Navbars/Navbar/UpaarNavbar";

function getSteps() {
  return ["Otp Info"];
}

function getStepContent(stepIndex, formData) {
  switch (stepIndex) {
    case 0:
      return <OtpInfo formData={formData} />;
    default:
      return null;
  }
}

function AdminOtp() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const [code, setCode] = useState(JSON.parse(localStorage.getItem('user-code'))?.code)
  const steps = getSteps();
  const { formId, formField } = form;
  const currentValidation = validations[activeStep];
  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const submitForm = async (values, actions) => {
    try {
      await axios.post(
        `otps-service/api/Otps/v1/confirm?otp=${values.otp}&userid=${code}`
      );
      navigate("/app/pages/profile/profile-overview");
     
      
    } catch (error) {
      swal("Oops!", error.response.data.aceErrors[0].errorMessage, "warning");
    }

    actions.setSubmitting(false);
    actions.resetForm();
    setActiveStep(0);
  };

  const handleSubmit = (values, actions) => {
    if (isLastStep) {
      submitForm(values, actions);
    } else {
      setActiveStep(activeStep + 1);
      actions.setTouched({});
      actions.setSubmitting(false);
    }
  };

  return (
    <DashboardLayout>
      <UpaarNavbar />
        <Grid container spacing={3} mt={12} mb={12}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{display:"grid",justifyContent:"center"}}>
            <Formik
              initialValues={initialValues}
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form id={formId} autoComplete="off" >
                  <Card sx={{ mt: "50px" }}>
                    <MDBox mx={2} mt={-3}>
                      <MDBox
                        variant="gradient"
                        bgColor="info"
                        borderRadius="lg"
                        coloredShadow="success"
                        mx={2}
                        mt={-1}
                        p={3}
                        mb={1}
                        textAlign="center"
                      >
                        <MDTypography variant="h4" fontWeight="medium" color="white" mt={2}>
                          OTP
                        </MDTypography>
                        <MDTypography display="block" variant="button" color="white" my={1}>
                          Enter your OTP to register
                        </MDTypography>
                      </MDBox>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {getStepContent(activeStep, {
                          values,
                          touched,
                          formField,
                          errors,
                        })}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {activeStep === 0 ? (
                            <MDBox />
                          ) : (
                            <MDButton
                              variant="gradient"
                              color="light"
                              onClick={handleBack}
                            >
                              Back
                            </MDButton>
                          )}
                          <MDButton
                            disabled={isSubmitting}
                            type="submit"
                            fullwidth
                            variant="gradient"
                            color="info"
                          >
                            Verify
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      <Footer />
    </DashboardLayout>
  );
}

export default AdminOtp;
