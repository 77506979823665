import React from 'react'
import mrdicine from '../../findDoctor/assets/images/medicine.png';
import '../Medicine/style1.css'
import Slider from 'react-slick';
import { Button, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
const MeddicineHome = () => {
  const navigate = useNavigate();
    const cards = [
        { image: mrdicine },
        { image: mrdicine },
        { image: mrdicine },
      ];
    
      const sliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
       
        responsive: [
          {
            breakpoint: 600,
            settings: {
              dots: true,
              slidesToShow: 1,
               
            },
          },
        ],
      };
  return (
    <div>
      <Slider {...sliderSettings} >
        {cards.map((card, index) => (
          <div key={index}>
            <Grid mt={23} className='medicine-card-A' >
              <Grid className='medicine-card-B' >
                <img className='medicine-card-imge' src={card.image} alt={`Card ${index + 1}`} />
              </Grid>
              <div className='medicine-card-C' >
                <Grid className='medicine-car-D' >Book lab tests</Grid>
                <Grid className='medicine-car-E'>Directly from your home </Grid>
              </div>
            <Grid  className="medicine-button-container" >
          <Grid className="medicine-button">
          <Button>
          <span  className="medicine-button-text"
       onClick={() => navigate("/app/pages/users/upload-prescription")}
            > Book now</span> 
          </Button>
       </Grid>
       </Grid>

            </Grid>
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default MeddicineHome
