// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import * as React from "react";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewUser page components
import FormField from "components/Resuable/Patientsignup/FormField";

import MDInput from "components/MDInput";
import { useEffect, useState } from "react";
import { Autocomplete } from "@mui/material";
import { BarLoader } from "react-spinners";

function Patientsignup({ formData }) {
  const [loading, setLoading] = useState(false);
  const [genderValue, setGenderValue] = useState(null);
  const [genderList, setGenderList] = useState("");
  const [color] = useState("#344767");
  const [isMobileView, setIsMobileView] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobileView(window.innerWidth <= 575);
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  

  const { formField, values, errors, touched } = formData;

  const {
    firstName,
    lastName,
    email,
    phonenumber,
   gender,
    age,
    password,
    repeatPassword,
    
  } = formField;
  var {
    firstName:firstNameV,
    lastName:lastNameV,
    email: emailV,
    phonenumber: phonenumberV,
    username: usernameV,
    age:ageV,
   
   
    password: passwordV,
    repeatPassword: repeatPasswordV,
  } = values;

  const defaultPropsGender = {
    options: !genderList
      ? [
          { name: "Male",code:"X" },
          { name: "Female",code:"y"},
              ]
      : genderList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.code === value.code,
  };



  
  return (
    <MDBox>
      
      <BarLoader color={color} loading={loading} aria-label="Loading Spinner" />
      {!loading && (
        <MDBox mt={1.625}>
        <Grid container spacing={isMobileView ? -3 : 3}>
            <Grid item xs={12} sm={6}>
        <FormField
              type={firstName.type}
              label={firstName.label}
              name={firstName.name}
              value={firstNameV}
              placeholder={firstName.placeholder}
              error={errors.firstName && touched.firstName}
              success={firstNameV.length > 0 && !errors.firstName}
            />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormField
              type={lastName.type}
              label={lastName.label}
              name={lastName.name}
              value={lastNameV}
              placeholder={lastName.placeholder}
              error={errors.lastName && touched.lastName}
              //success={lastNameV.length > 0 && !errors.lastName}
            />
            </Grid>
            </Grid>
            <Grid container spacing={isMobileView ? 1 : 3}>
            <Grid item xs={12} sm={6}>
            <Autocomplete
                {...defaultPropsGender}
                value={genderValue}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  setGenderValue(newValue);

                  values.gender = newValue?.name;
                  console.log(newValue);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    error={errors.gender && touched.gender}
                    success={gender.length > 0 && !errors.gender}
                    variant="standard"
                    label={gender.label}
                  />
                )}
              />
              </Grid>
              <Grid item xs={12} sm={6}>
               <FormField
              type={age.type}
              label={age.label}
              name={age.name}
              value={ageV}
              placeholder={age.placeholder}
              error={errors.age && touched.age}
              success={ageV.length > 0 && !errors.age}
              InputLabelProps={{
                style: { paddingBottom: "2px" }}}
            />
          </Grid>
          </Grid>
          <Grid container spacing={isMobileView ? -3 : 3}>
            <Grid item xs={12} sm={6}>
        <FormField
              type={email.type}
              label={email.label}
              name={email.name}
              value={emailV}
              placeholder={email.placeholder}
              error={errors.email && touched.email}
              success={emailV.length > 0 && !errors.email}
            />
             </Grid>

             <Grid item xs={12} sm={6}>
           
           <FormField
              type={phonenumber.type}
              label={phonenumber.label}
              name={phonenumber.name}
              value={phonenumberV}
              placeholder={phonenumber.placeholder}
              error={errors.phonenumber && touched.phonenumber}
              success={phonenumberV.length > 0 && !errors.phonenumber}
            />
            
           
            </Grid>
          </Grid>
          <Grid container spacing={isMobileView ? -3 : 3}>
          <Grid item xs={12} sm={6}>
        
              <FormField
                type={password.type}
                label={password.label}
                name={password.name}
                value={passwordV}
                placeholder={password.placeholder}
                error={errors.password && touched.password}
                success={passwordV.length > 0 && !errors.password}
                inputProps={{ autoComplete: "" }}
              />
             </Grid>
           <Grid item xs={12} sm={6}>
              <FormField
                type={repeatPassword.type}
                label={repeatPassword.label}
                name={repeatPassword.name}
                value={repeatPasswordV}
                placeholder={repeatPassword.placeholder}
                error={errors.repeatPassword && touched.repeatPassword}
                success={repeatPasswordV.length > 0 && !errors.repeatPassword}
                inputProps={{ autoComplete: "" }}
              />
          
          </Grid>
          </Grid>
        
        </MDBox>
      )}
    </MDBox>
  );
}

// typechecking props for Patientsignup
Patientsignup.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};



export default Patientsignup