

import React from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
// Data
// import dataTableData from "layouts/applications/data-tables/data/dataTableData";
import { useEffect, useState } from "react";
// import { getRoles } from "@testing-library/react";

import axios from "../../../../axiosinstance"
import { Autocomplete } from "@mui/material";
import { BarLoader } from "react-spinners";
// import { item } from "examples/Sidenav/styles/sidenavItem";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Rolecode from "../../../../Rolecode";

function CategoryList() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [color] = useState("#344767");
  const [tableDatas, setTableDatas] = useState({});
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = React.useState(false);
  const [profileId, setProfileId] = useState(JSON.parse(localStorage.getItem("login-auth"))?.profileid)
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const code = Rolecode();
  const linkTo = code?.Pharmacy === "PH257" ? "/app/pharmacy/pharmacycategory" : "/app/layout/pages/profile/new-category";
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };
  async function onUpdatedata(object) {
    setItem(object);

    setUpdatename(object.name);
    setDescription(object.description);
    setOpen(true);
  }

  const handleClose = () => {
    console.log("dsfsdfs");
    setOpen(false);
  };

  async function onDelete(item) {
    
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Category.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      console.log(willDelete)
      if (willDelete) {
        
            axios
        .put(
          
          "usercategory-service/api/Usercategory/v1/delete",
          item
        )
            .then((response) => {
          console.log(response);
          getData(profileId);
          // window.location.reload()
        })
        swal("Your Category record has been deleted!", {
          icon: "success",
          
        });
      } else {
        swal("Your Category record is safe!");
      }
    });
  }

 

  async function updateData() {
    item.name = Updatename;
    item.description = description;
    item.modifiedby =
      JSON.parse(localStorage.getItem("mmu-auth")).firstname +
      " " +
      JSON.parse(localStorage.getItem("mmu-auth")).lastname;
    item.modifieddate = new Date();
    await axios
      .put(
        "https://medharva.softwaremathematics.com/role-service-mongo/api/role/v2/update",
        item
      )
      .then((response) => {
        setOpen(false);
        getData();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getData(id) {
    setLoading(true);
    const dataObj = {
           columns: [
            { Header: "Categoryname", accessor: "category.name" },
            // { Header: "Categoryname", accessor: "category.subcategory" },
        { Header: "action", accessor: "action"}
     
      ],
      rows: [],
    };
    axios
      .get(
        `usercategory-service/api/Usercategory/v1/get?profileid=${id}`
      )
      .then((response) => {
        console.log("category",response)
        response?.data?.data?.dtoList.map((item) => {
          item.action = (
            <span>
              {/* <Link to={{}}>
                <FaRegEdit
                  style={iconStyle}
                  onClick={() => {
                    onUpdatedata(item);
                  }}
                />
              </Link> */}

              <Link to={{}}>
                <MdDelete
                  style={iconStyle}
                  onClick={() => {
                    onDelete(item);
                  }}
                />
              </Link>
            </span>
          );
          item.fcreateddate = new Date(item.createddate).toDateString();
         
          dataObj.rows.push(item);
        });
        console.log("res70", dataObj);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
    useEffect(() => {
    getData(profileId);
  }, []);
  return (
<>
     
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                  Category List 
                  </MDTypography>
                </span>

                <Link
                  style={{
                    alignSelf: "flex-end",
                    marginRight: "10px",
                    marginTop: "-23px",
                    color: "#344767",
                  }}
                  to={linkTo}
                >
                  <MdOutlineLibraryAdd />
                </Link>
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update MMU List</DialogTitle>
        <DialogContent style={{ width: 550, height: 390 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Name"
              variant="standard"
              fullWidth
              // value={Updatename}
              onChange={(e) => setUpdatename(e.target.value)}
            />
            <span style={{ color: "red" }}>{err}</span>
          </MDBox>
          <MDBox mb={2}>
          <Autocomplete
                // {...defaultPropssetRaspberryAssign}
                // value={selectraspberry}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  // setSelectRasberry(newValue);

                  updateObject.raspberry = newValue;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                   
                    variant="standard"
                    label="RaspberryAssign"
                  />
                )}
              />
          </MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // {...defaultPropssetMapWarehouse}
                // value={selectmap}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  // setSelectMap(newValue);
                  updateObject.warehouse = newValue;

                  // values.mapware = newValue;
                  console.log("537", selectmap);
                }}
                renderInput={(params) => (
                  <MDInput {...params} variant="standard" label="mapware" />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // {...defaultPropssetVehicleAssign}
                // value={selectvech}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  // setSelectVec(newValue);
                  updateObject.vehicle = newValue;

                  // values.VehicleAssign = newValue;
                  console.log("559", selectvech);
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="VehicleAssign"
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // {...defaultPropssetAssignEquipment}
                // value={selectassi}
                onChange={(event, newValue) => {
                  // handleChange(event);

                  // setSelectAssi(newValue);
                  console.log("366", selectassi);

                  // values.AssignEquipment = newValue?.name;
                }}
                renderInput={(params) => (
                  <MDInput
                    {...params}
                    variant="standard"
                    label="AssignEquipment"
                  />
                )}
              />
            </Grid>

           
            
            <Grid item xs={12} sm={2}>
              <MDButton
                variant="gradient"
                color="info"
                size="small"
                style={{ marginTop: "10px" }}
                onClick={() => addDataObj()}
              >
                Add
              </MDButton>
            </Grid>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sm={12}
            style={{
              border: "1px solid #727375",
              borderRadius: "5px",
              marginTop: "25px",
            }}
          >
            <DataTable
              showTotalEntries={false}
              entriesPerPage={false}
              table={tableData}
            />
          </Grid> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateData} disabled={!Updatename}>
            Update
          </Button>
        </DialogActions>
      </Dialog>
     
    </>
  );
}





export default CategoryList