/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const form = {
  formId: "role-form",
  formField: {
    resourcename: {
      name: "resourcename",
      label: "Resource Name",
      type: "text",
      errorMsg: "Resource Name is required.",
    },
    resourceDescrption: {
      name: "resourceDescrption",
      label: "Resource Description",
      type: "text",
      errorMsg: "Resource Description Is Required.",
    },
     // upload Document
     document: {
      name: "document",
      label: "Upload Invoice",
      type: "text",
      errorMsg: "Document is required.",
      
    },
  },
};

export default form;
