import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

import { useDispatch, useSelector } from "react-redux";

import MDBox from "components/MDBox";
import { Grid } from "@mui/material";

import MDButton from "components/MDButton";

import { useNavigate } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { getDepartment } from "app/Admin/AdminWorkspace/departmentMaster/service/Department";
import { getDesignation } from "app/Admin/AdminWorkspace/designationMaster/service/Designation";

import MDTypography from "components/MDTypography";
import { createUser } from "components/Resuable/UserMaster/service/User";
import axios from "../../../../axiosinstance";

function CreateAgent({ roledata }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { department } = useSelector((state) => state.department);
  const [designationList, setDesignationList] = useState([]);
  const { designation } = useSelector((state) => state.designation);

  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    gender: Yup.string().required("Gender is required."),
    accountholdername: Yup.string().required(
      "Account holder name is required."
    ),
    name: Yup.string().required("First name is required."),
    age: Yup.string().required("Age is required."),
    Phonenumber: Yup.string()
      .required("Phone number is required.")
      .matches(/^\d{10}$/, "Phone number must be 10 digits."),
    email: Yup.string()
      .required("Email is required.")
      .email("Invalid email address."),
    departmentvalue: Yup.string().required("Department is required."),
    designationvalue: Yup.string().required("Designation is required."),
    accountnumber: Yup.string().required("Account holder number is required."),
    password: Yup.string()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        {
          message:
            "Password must be at least 8 characters long and contain at least one letter, one number, and one special character.",
          excludeEmptyString: true,
        }
      )
      .required("Password is required."),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match.")
      .required("Confirm password is required."),
    adhar: Yup.string().required("Aadhaar number is required."),
    pan: Yup.string()
      .matches(/^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/, "Invalid PAN format.")
      .required("PAN number is required."),
    ifsccode: Yup.string().required("IFSC code is required."),
    bankname: Yup.string().required("Bank name is required."),
    referalcode: Yup.string().required("Referral code is required."),
  });
  function getDesignationData(code) {
    const result = designation.filter(
      (designationItem) => designationItem.deptcode === code
    );
    setDesignationList(result);
  }

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getDesignation());
  }, [dispatch]);

  async function createInvestmentprofile(object) {
    await axios
      .post(
        "/investmentprofile-service/api/investmentprofile/v1/create",
        // 'http://localhost:6012/api/investmentprofile/v1/create',
        object
      )

      .then((response) => {
        console.log("gfgfgf", response);
      })
      .catch((error) => {
        // setIsLoading(false);
        console.log(error);
      });
  }

  function addBankAccount(object, actions) {
    axios
      .post(
        `/bankaccounts1-service/api/Bankaccounts1/v1/create`,
        // `http://localhost:6001/api/Bankaccounts1/v1/create`,
        object
      )
      .then((response) => {
        console.log("data", response.data.data);
        actions.resetForm();
        // getData(profile?.profileid);
      })
      .catch((e) => {
        actions.resetForm();
        // setIsLoading(false);
        console.log("error", e);
      });
  }

  async function createprofileentity(
    object,
    investmentProfileObject,
    bankObj,
    actions
  ) {
    const response = await dispatch(createUser(object));

    if (!response.error && !loading) {
      investmentProfileObject.profileid =
        response?.payload?.data?.dto?.profileid;
      bankObj.profileid = response?.payload?.data?.dto?.profileid;
      createInvestmentprofile(investmentProfileObject);
      addBankAccount(bankObj, actions);

      // setIsLoading(false);
    } else {
      swal({
        icon: "error",
        title: "Error",

        text: error ? error?.message : "Something went wrong.",
      });
      // setIsLoading(false);
    }
  }

  async function getInvestmentprofile(
    referalcode,
    profileEntityObject,
    investmentProfileObject,
    bankObj,
    actions
  ) {
    await axios
      .get(
        `/investmentprofile-service/api/investmentprofile/v1/get?referalcode=${referalcode}`
      )

      .then((response) => {
        if (response.data.data.dtoList.length == 0) {
          // setIsLoading(false);
          swal("Alert!", "...Invalid Referal Code!");
        } else {
          investmentProfileObject.hierarchicalcode =
            response?.data?.data?.dtoList[0]?.hierarchicalcode;
          createprofileentity(
            profileEntityObject,
            investmentProfileObject,
            bankObj,
            actions
          );

          swal("Good job!", "Your registration has been done!", "success");
          setLoading(false);
        }
      })
      .catch((error) => {
        swal("Oops!", "...Something Went Wrong!");
        setLoading(false);
      });
  }

  return (
    <Formik
      initialValues={{
        lastname: "",
        gender: "",
        age: "",
        name: "",
        accountholdername: "",
        Phonenumber: "",
        email: "",
        password: "",
        confirmPassword: "",
        accountnumber: "",
        departmentvalue: "",
        designationvalue: "",
        adhar: "",
        pan: "",
        ifsccode: "",
        bankname: "",
        referalcode: "",
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, action) => {
        setLoading(true)
        if (roledata === null || Object.keys(roledata).length === 0) {
          alert("please select role first");
        } else {
          const profileEntityObject = {
            age: values.age,
            sex: values.gender,

            emailid: values.email,
            name: values.lastname
              ? values.name + " " + values.lastname
              : values.name,

            isactive: "INACTIVE",

            emailid: values.email,
            rolename: roledata.name,
            rolecode: roledata.rolecode,

            departmentcode: values.department.code,
            departmentname: values.department.name,
            designationcode: values.designation.code,
            designationname: values.designation.name,

            mobileno: values.Phonenumber,

            password: values.password,

            recstatus: "APPROVED",
            resourcecode: "HEALTHCARE",
            status: "ACTIVE",
            syncstatus: "synced",
          };
          const investmentprofileObeject = {
            age: values.age,

            closingbalance: 0,

            currency: 0,
            documentnumber: values.documentnumber,

            documenttype: values.documenttype,

            id: null,

            name: values.lastname
              ? values.name + " " + values.lastname
              : values.name,
            openingbalance: 0,

            recstatus: "APPROVED",
            referalcode: values.referalcode,
            status: "Active",
            syncstatus: "SYNCED",
            bankdetail: {
              adhar: values.adhar,
              pan: values.pan,
              bankname: values.bankname,
              ifsc: values.ifsccode,
              accountno: values.accountnumber,
              holdername: values.accountholdername,
            },
          };
          const bankObj = {
            bankname: values.bankname,

            ifsc: values.ifsccode,

            accountno: values.accountnumber,

            holdername: values.accountholdername,

            profileid: "",

            recstatus: "PRIMARY",

            status: "Active",

            syncstatus: "SYNCED",
          };
          getInvestmentprofile(
            values.referalcode,
            profileEntityObject,
            investmentprofileObeject,
            bankObj,
            action
          );
        }
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form>
          <MDBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      First Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="name"
                    id="name"
                    value={values.name}
                    onChange={(e) => {
                      const nameValue = e.target.value;
                      setFieldValue("name", nameValue);
                    }}
                    variant="standard"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label="Last Name"
                    name="lastname"
                    id="lastname"
                    value={values.lastname}
                    onChange={(e) => {
                      const lastnameValue = e.target.value;
                      setFieldValue("lastname", lastnameValue);
                    }}
                    variant="standard"
                    error={touched.lastname && Boolean(errors.lastname)}
                    helperText={touched.lastname && errors.lastname}
                    InputLabelProps={{
                      style: { paddingBottom: "2px" },
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel
                    htmlFor="gender"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Select Gender{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    fullWidth
                    variant="standard"
                    style={{ width: "100%" }}
                    name="gender"
                    id="gender"
                    value={values.gender}
                    onChange={(e) => {
                      const selectedGender = e.target.value;
                      setFieldValue("gender", selectedGender);
                    }}
                    error={touched.gender && Boolean(errors.gender)}
                  >
                    <option value=""></option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </Select>
                  <ErrorMessage
                    name="gender"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.departmentvalue && touched.departmentvalue}
                >
                  <InputLabel
                    htmlFor="departmnet"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Department{" "}
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      {" "}
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="departmentvalue"
                    id="departmentvalue"
                    value={values.departmentvalue}
                    onChange={(e) => {
                      const departmentvalue = e.target.value;
                      const selectedDepartmentObj =
                        department.find(
                          (obj) => obj.code === departmentvalue
                        ) || {};

                      getDesignationData(e.target.value);
                      setFieldValue("department", selectedDepartmentObj);

                      setFieldValue("departmentvalue", departmentvalue);
                    }}
                  >
                    <option value=""></option>
                    {department.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>

                  <ErrorMessage
                    name="departmentvalue"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl
                  fullWidth
                  error={!!errors.designationvalue && touched.designationvalue}
                >
                  <InputLabel
                    htmlFor="designationvalue"
                    style={{
                      marginLeft: "-14px",
                      paddingBottom: "2px",
                      marginTop: "2px",
                    }}
                  >
                    Designation
                    <sup
                      style={{
                        color: "red",
                        fontSize: "small",
                        fontWeight: "bolder",
                        position: "relative",
                        top: "2px",
                      }}
                    >
                      *
                    </sup>
                  </InputLabel>
                  <Select
                    native
                    variant="standard"
                    style={{ width: "100%" }}
                    name="designationvalue"
                    id="designationvalue"
                    value={values.designationvalue}
                    onChange={(e) => {
                      const designationvalue = e.target.value;
                      //values.designation = newValue;
                      const designationobj =
                        designation.find(
                          (obj) => obj.code === designationvalue
                        ) || {};

                      setFieldValue("designation", designationobj);
                      setFieldValue("designationvalue", designationvalue);
                    }}
                  >
                    <option value=""></option>
                    {designationList.map((obj) => (
                      <option key={obj.id} value={obj.code}>
                        {obj.name}
                      </option>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="designationvalue"
                    component="div"
                    style={{ color: "red", fontSize: "12px" }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Age
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="age"
                
                    id="age"
                    value={values.age}
                    onChange={(e) => {
                      const ageValue = e.target.value;
                      setFieldValue("age", ageValue);
                    }}
                    variant="standard"
                    error={touched.age && Boolean(errors.age)}
                    helperText={touched.age && errors.age}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Phone Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="Phonenumber"
                 
                    id="Phonenumber"
                    value={values.Phonenumber}
                    onChange={(e) => {
                      const PhonenumberValue = e.target.value;
                      setFieldValue("Phonenumber", PhonenumberValue);
                    }}
                    variant="standard"
                    error={touched.Phonenumber && Boolean(errors.Phonenumber)}
                    helperText={touched.Phonenumber && errors.Phonenumber}
                    onKeyDown={(e) => {
                  if (
                    !/[0-9\b\t]/.test(e.key) &&
                    ![
                      "ArrowLeft",
                      "ArrowRight",
                      "ArrowUp",
                      "ArrowDown",
                      "Delete",
                      "Backspace",
                      "Tab",
                    ].includes(e.key)
                  ) {
                    e.preventDefault();
                  }
                }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Email
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="email"
                    id="email"
                    value={values.email}
                    onChange={(e) => {
                      const emailValue = e.target.value;
                      setFieldValue("email", emailValue);
                    }}
                    variant="standard"
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label=<span>
                      Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="password"
                    id="password"
                    value={values.password}
                    onChange={(e) => {
                      const passwordValue = e.target.value;
                      setFieldValue("password", passwordValue);
                    }}
                    variant="standard"
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <TextField
                    type="password"
                    label=<span>
                      Confirm Password
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="confirmPassword"
                    id="confirmPassword"
                    value={values.confirmPassword}
                    onChange={(e) => {
                      const confirmPasswordValue = e.target.value;
                      setFieldValue("confirmPassword", confirmPasswordValue);
                    }}
                    variant="standard"
                    error={
                      touched.confirmPassword && Boolean(errors.confirmPassword)
                    }
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            <MDTypography variant="h5" mt={3}>
              Bank Detail
            </MDTypography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Account Holder Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="accountholdername"
                    id="accountholdername"
                    value={values.accountholdername}
                    onChange={(e) => {
                      const accountholdernameValue = e.target.value;
                      setFieldValue(
                        "accountholdername",
                        accountholdernameValue
                      );
                    }}
                    variant="standard"
                    error={
                      touched.accountholdername &&
                      Boolean(errors.accountholdername)
                    }
                    helperText={
                      touched.accountholdername && errors.accountholdername
                    }
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Account Holder Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="accountnumber"
                    type="number"
                    id="accountnumber"
                    value={values.accountnumber}
                    onChange={(e) => {
                      const accountnumberValue = e.target.value;
                      setFieldValue("accountnumber", accountnumberValue);
                    }}
                    variant="standard"
                    error={
                      touched.accountnumber && Boolean(errors.accountnumber)
                    }
                    helperText={touched.accountnumber && errors.accountnumber}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Aadhaar Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="adhar"
                    type="number"
                    id="adhar"
                    value={values.adhar}
                    onChange={(e) => {
                      const adharValue = e.target.value;
                      setFieldValue("adhar", adharValue);
                    }}
                    variant="standard"
                    error={touched.adhar && Boolean(errors.adhar)}
                    helperText={touched.adhar && errors.adhar}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Pan Number
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="pan"
                    id="pan"
                    value={values.pan}
                    onChange={(e) => {
                      const panValue = e.target.value;
                      setFieldValue("pan", panValue);
                    }}
                    variant="standard"
                    error={touched.pan && Boolean(errors.pan)}
                    helperText={touched.pan && errors.pan}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      IFSC Code
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="ifsccode"
                    id="ifsccode"
                    value={values.ifsccode}
                    onChange={(e) => {
                      const ifsccodeValue = e.target.value;
                      setFieldValue("ifsccode", ifsccodeValue);
                    }}
                    variant="standard"
                    error={touched.ifsccode && Boolean(errors.ifsccode)}
                    helperText={touched.ifsccode && errors.ifsccode}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Bank Name
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="bankname"
                    id="bankname"
                    value={values.bankname}
                    onChange={(e) => {
                      const bankValue = e.target.value;
                      setFieldValue("bankname", bankValue);
                    }}
                    variant="standard"
                    error={touched.bankname && Boolean(errors.bankname)}
                    helperText={touched.bankname && errors.bankname}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    label=<span>
                      Referral Code
                      <sup
                        style={{
                          color: "red",
                          fontSize: "small",
                          fontWeight: "bolder",
                          position: "relative",
                          top: "2px",
                        }}
                      >
                        {" "}
                        *
                      </sup>
                    </span>
                    name="referalcode"
                    id="referalcode"
                    value={values.referalcode}
                    onChange={(e) => {
                      const bankValue = e.target.value;
                      setFieldValue("referalcode", bankValue);
                    }}
                    variant="standard"
                    error={touched.referalcode && Boolean(errors.referalcode)}
                    helperText={touched.referalcode && errors.referalcode}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <MDBox mt={5} width="100%" display="flex" justifyContent="flex-end">
              <MDButton type="submit" variant="gradient" color="info">
                {loading ? <MoonLoader color="#f2fefa" size={16} /> : "Submit"}
              </MDButton>
            </MDBox>
          </MDBox>
        </Form>
      )}
    </Formik>
  );
}

export default CreateAgent;
