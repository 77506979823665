import React from 'react'

import healthcarechecks from'../../../../../assets/images/healthcarecheck.png'
import Fwellness from'../../../../../assets/images/Financialwellness.png' 
import { Grid } from '@mui/material'
import '../Healthcaretest/style.css'
const HealthChecks = () => {

  return (
    <div className='health-1'>
      <Grid className='health-2'>

  <div className='health-3'>
  <Grid >
  <Grid className='health-4'>
  Book lab tests and Health checks
  </Grid>
  <Grid className='health-5' >
Private online consultations with verified doctors in all specialists       
  </Grid>
    <div className='health-6' >
        <Grid className='health-7'>
            <img className='health-8'
            src={Fwellness}
              />
           <Grid ml={2} className='health-9' >
           <span>NABL- accredited labs for aaccurate results </span> 
            </Grid>           
        </Grid>
        <Grid ml={2} className='health-10'>
            <img className='health-11'
            src={Fwellness}
            />
           <Grid ml={2} className='health-12'>
           <span>Lab tests at home in 60 seconds </span>    
            </Grid>        
        </Grid>
        <Grid ml={1} className='health-13'>
            <img className='health-14'
            src={Fwellness}
            />
         <Grid ml={2} className='health-15'>
         <span>Reports delivered in 12 hrs* </span>    
            </Grid>        
        </Grid>
    </div>


   </Grid>

      <Grid className='health-16' >
        <img className='health-17'
        src={healthcarechecks}
       
        />
       </Grid>
  </div>
      </Grid>
    </div>
  )
}

export default HealthChecks


