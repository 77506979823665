import AnalyticsGraph from 'components/Analytics'
import React from 'react'
import Analytics from './Analytics'
import { useParams } from 'react-router-dom';
import ProfileCard from 'components/profileCard';
import AgentMicProfile from './AgentMicProfile';
import { Grid } from '@mui/material';
import AgentList from './agentList';
import OnboardedList from './OnboardList';
import Transactions from './Transactions';

const MicAgeant = () => {
  const { slug2 } = useParams();

  return (
    <Grid>
      {slug2 ==="analytics" && <Analytics />}
      {slug2 ==="total-agents" && <AgentList />}
      {slug2 ==="onboard-list" && <OnboardedList />}
      {slug2 ==="transaction" && <Transactions />}
      {slug2 ==="view-profile" && <AgentMicProfile />}


    </Grid>
  )
}

export default MicAgeant
