import React from "react";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import Grid from "@mui/material/Grid";
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import MDButton from "components/MDButton";
import axios from "../../../../../axiosinstance";
import { BarLoader } from "react-spinners";
import { FaRegEdit } from "react-icons/fa";
import { MdCheckBox, MdDelete, MdOutlineLibraryAdd } from "react-icons/md";
import SearchIcon from "@mui/icons-material/Search";
import { Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import DateTimePicker from 'react-datetime-picker';
import moment from "moment-timezone";
import { APIs } from "Services/APIs";
import { useSelector } from "react-redux";
import DashboardLayout from "components/DashboardLayout";

function ApprovedScheduler() {
  const comp = "SM";
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [color] = useState("#344767");
  const [enddate, setEnddate] = useState(new Date());
  const [address, setAddress] = useState("");
  const [startdate, setStartdate] = useState(new Date());
  const [title, setTitle] = useState("");
  const [Updatename, setUpdatename] = useState("");
  const [description, setDescription] = useState("");
  const [err, setErr] = useState("");
  const [item, setItem] = useState({});
  const [open, setOpen] = useState(false);
  const [defaultMMU, setDefaultMMU] = useState();
  const [mmucode, setMmucode] = useState("");
  const [mmuList, setMmuList] = useState([]);
  const [updateObject, setUpdateObject] = useState();
  const iconStyle = { fontSize: "1.5em", color: "#344767", marginRight: "9px" };
  const [checkusers, setcheckeduser] = useState([]);
  const [eventList, setEventList] = useState([]);
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  function getMMUData() {
    axios
      .get(
        `${APIs.mmuCreationService}/get`
      )
      .then((response) => {
   
        setMmuList(response?.data?.data?.dtoList);

      })
      .catch((e) => {
        console.log("error", e);
      });
  }
 
  function tConvert(time) {
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) { 
      time = time.slice(1); 
      time[5] = +time[0] < 12 ? ' am' : ' pm'; 
      time[0] = +time[0] % 12 || 12; 
    }
    return time.join(''); 
  }

  function convertTZ(date) {
    var format = "YYYY-MM-DDTHH:mm:ss.sssZ";
    var df =  moment(date, format).tz("Asia/Kolkata").format(format);
    return new Date(df).toLocaleDateString() + " " + tConvert(new Date(df).toLocaleTimeString());
}
  const addButtonStyle = {
    display: "flex",
    fontSize: "2em",
    flexDirection: "column",
    marginRight: "10px",
  };

  const handleChange = (e, resEventList) => {

    const { name, checked } = e.target;
    if (name === "allSelect") {
      let tempEvent = resEventList.map((event) => {
        return { ...event, isChecked: checked };
      });
      dataObj.rows = tempEvent;
    } else {
      let tempEvent = resEventList.map((event) => {
        event.title === name ? { ...event, isChecked: checked } : event;
        if (event.title === name) {
        }
      });
      dataObj.rows = tempEvent;
      setTableDatas(dataObj);
    }
  };
  const handleChangeV2 = (e, resEventList = eventList) => {
    const { name, checked } = e.target;
    if (name === "allSelect") {
      for (let i = 0; i < resEventList.length; i++) {
        resEventList[i].checkbox.props.checked = true;
        resEventList[i].isChecked = true;
      }
    } else {
      for (let i = 0; i < resEventList.length; i++) {
        if (resEventList[i].title === name) {
          resEventList[i].checkbox.props.checked = true;
          resEventList[i].isChecked = true;
        }
      }
    }
  };

  const dataObj = {
    columns: [
      { Header: "title", accessor: "title" },
      { Header: "address", accessor: "finalAddress" },
      { Header: "flag", accessor: "flag"},
      { Header: "start date", accessor: "startdateformated" },
      { Header: "end date", accessor: "enddateformated" },
      { Header: "action", accessor: "action" },
    ],
    rows: [],
  };

  const [tableDatas, setTableDatas] = useState(dataObj);
  const defaultPropsMMU = {
    options: !mmuList
      ? [{ name: "Loading...", id: 0, vehicle: { name: "" } }]
      : mmuList,
    getOptionLabel: (option) => option.vehicle?.name ? option.name + "(" + option.vehicle?.name + ")" : option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  async function onUpdatedata(object) {
    setTitle(object.title);
    setAddress(object.address);
    setEnddate(new Date(object.enddate));
    setStartdate(new Date(object.startdate));
    setUpdateObject(object);
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

 
  async function onDelete(item, mmu) {
    if (
      window.confirm("Are you sure that you want to delete that user record")
    ) {
      delete item.action;
      await axios
        .put(
          `${APIs.eventServiceApi}/delete`,
          item
        )
        .then((response) => {
          getData(response?.data?.data?.dto?.mmucode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  async function updateEvent(updateObject, recstatus, color) {
    updateObject.recstatus = recstatus;
    updateObject.documentofproof = color;
    updateObject.modifiedby = auth?.name;
    updateObject.approvedby = auth?.name;
    delete updateObject.action;

    if (window.confirm("Are you sure that you want to Approved this record")) {
      await axios
        .put(
          `${APIs.eventServiceApi}/update`,
          updateObject
        )
        .then((response) => {
          setOpen(false);
          getData(response?.data?.data?.dto?.mmucode);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  function getData(mmucode) {
    setLoading(true);


    axios
      .get(
        `${APIs.eventServiceApi}/get?mmucode=${mmucode}&recstatus=OPEN`
      )
      .then((response) => {
        dataObj.rows = [];

        response?.data?.data?.dtoList?.map((item) => {
          item.startdateformated=convertTZ(item.startdate);
          item.enddateformated=convertTZ(item.enddate);
          item.action = (
            <span>
              <MDButton
                variant="gradient"
                color="success"
                size="small"
                onClick={() => {
                  updateEvent(item, "APPROVED", "#2db551");
                }}
              >
                Approved
              </MDButton>
              <MDButton
                variant="gradient"
                color="error"
                size="small"
                style={{ marginLeft: "5px" }}
                onClick={() => {
                  updateEvent(item, "REJECTED", "#b52d2d");
                }}
              >
                Reject
              </MDButton>
            </span>
          );
          item.isChecked = false;

          if (typeof item?.pincode !== "undefined" && item?.pincode !== null) {
            item.finalAddress =
              item.pincode?.village?.name +
              " (" +
              item.pincode?.pincode +
              ") " +
              item.pincode?.subdistrict?.name +
              " " +
              item.pincode?.district?.name +
              " " +
              item.pincode?.state?.name;
          }

          dataObj.rows.push(item);
        });
        setEventList(response?.data?.data?.dtoList);
        setTableDatas(dataObj);
        setLoading(false);
      })
      .catch((e) => {
        console.log("error", e);
      });
  }
  useEffect(() => {
    getMMUData();

  }, []);
  return (
    <DashboardLayout>
      <MDBox pt={3}>
        <MDBox>
          <Card>
            <MDBox pl={3} pr={3} lineHeight={1}>
              <div style={addButtonStyle}>
                <span
                  style={{
                    alignSelf: "flex-start",
                    marginTop: "20px",
                  }}
                >
                  <MDTypography variant="h5" fontWeight="medium">
                    Approved Scheduler List
                  </MDTypography>
                </span>

                <Grid container spacing={3} mt={4}>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      defaultValue={defaultMMU}
                      disablePortal
                      id="combo-box-demo"
                      sx={{ width: 280 }}
                      {...defaultPropsMMU}
                      onChange={(event, newValue) => {
                        getData(newValue?.code);
                        setMmucode(newValue?.code);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="MMU" />
                      )}
                    />
                  </Grid>
                 
                  <Grid
                    item
                    xs={2}
                    sm={1}
                    style={{ fontSize: "30px", marginLeft: "10px" }}
                  >
                    <MDBox mt={2}>
                    
                    </MDBox>
                  </Grid>
                </Grid>
            
              </div>
            </MDBox>
            <BarLoader
              color={color}
              loading={loading}
              aria-label="Loading Spinner"
            />
            {!loading && <DataTable table={tableDatas} canSearch={true} />}
          </Card>
        </MDBox>
      </MDBox>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Event</DialogTitle>
        <DialogContent style={{ width: 550, height: 400 }}>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Title"
              variant="standard"
              fullWidth
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Address"
              variant="standard"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </MDBox>
          <MDBox mb={2}>
            <label>Select Start Date </label>
            <DateTimePicker onChange={setStartdate} value={startdate} />
          </MDBox>
          <MDBox mb={2}>
            <label>Select End Date </label>
            <DateTimePicker onChange={setEnddate} value={enddate} />
          </MDBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={updateEvent}>Submit</Button>
        </DialogActions>
      </Dialog>
    </DashboardLayout>
  );
}

export default ApprovedScheduler;
