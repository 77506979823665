// import React from 'react';
// import "./services2.css";
// import { Grid } from "@mui/material";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import imag1 from "../../assets/images/ourimage1.png";
// import imag2 from "../../assets/images/ourimage2.png";
// import imag3 from "../../assets/images/ourimage3.png";
// import imag4 from "../../assets/images/ourimage4.png";

// const Ourservice = () => {
//     const cardsData1 = [
//         {
//             logo: imag1,
//             name: "BP Monitor",
//             description: "Check BP under 30 sec",
//         },
//         {
//             logo: imag2,
//             name: "Spo2 Tracker",
//             description: "Track your oxygen saturation",
//         },
//         {
//             logo: imag3,
//             name: "Heart rate monitor",
//             description: "Monitor heart rate levels",
//         },
//         {
//             logo: imag4,
//             name: "Period tracker",
//             description: "Track period & ovulation",
//         },
//     ];

//     const sliderSettings = {
//         dots: false,
//         infinite: false,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 960,
//                 settings: {
//                     slidesToShow: 2,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <div>
//             <Grid container className="main-container">
//                 <Grid container spacing={2} className="container2">
//                     <Grid item xs={12}>
//                         <h2 className="mainheading">
//                             Our Service Extends Beyond Consultation, Scan & Lab Test
//                         </h2>
//                     </Grid>
//                     <Grid item xs={12}>
//                         <Slider {...sliderSettings}>
//                             {cardsData1.map((card, index) => (
//                                 <div key={index} className="card2">
//                                     <div className="image-container1">
//                                         <img src={card.logo} alt="Service Logo" className="imageour" />
//                                     </div>
//                                     <div className='textmaingrid'>
//                                         <h2 className="cardheading">{card.name}</h2>
//                                         <div className="description1">{card.description}</div>
//                                     </div>
//                                 </div>
//                             ))}
//                         </Slider>
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// };

// export default Ourservice;


// import React from 'react'
// import "./services2.css";

// import { Grid } from "@mui/material";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import imag1 from "../../assets/images/ourimage1.png";
// import imag2 from "../../assets/images/ourimage2.png";
// import imag3 from "../../assets/images/ourimage3.png";
// import imag4 from "../../assets/images/ourimage4.png";

// const Ourservice = () => {

//     const cardsData1 = [
//         {           
//             logo: imag1,
//             name: "BP Monitor",
//             description: "Check BP under 30 sec",
//         },
//         {
//             logo: imag2,
//             name: "Spo2 Tracker",
//             description: "Track your oxygen saturation",
//         },
//         {
//             logo: imag3,
//             name: "Heart rate monitor",
//             description: "Monitor heart rate levels",
//         },
//         {
//             logo: imag4,
//             name: "Period tracker",
//             description: "Track period & ovulation",
//         },


//     ];

//     const sliderSettings = {
//         dots: false,
//         infinite: true,
//         speed: 500,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         responsive: [
//             {
//                 breakpoint: 960,
//                 settings: {
//                     slidesToShow: 2,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 1,
//                 },
//             },
//         ],
//     };
//     return (
//         <div>
//             <Grid container className="main-container">
//                 <Grid container spacing={2} className="container2">
//                     <Grid item xs={12}>
//                         <h2 className="mainheading">
//                             Our Service Extends Beyond Consultation, Scan & Lab Test
//                         </h2>
//                     </Grid>
//                     <Slider {...sliderSettings}>
//                     {cardsData1.map((card, index) => (
//                         <Grid item xs={12} sm={12} md={3} key={index} >

//                             <div className="card2">

//                                 <div className="image-container1">
//                                     <Grid item xs={8} sm={10} className="cardimage1">
//                                         <img src={card.logo} alt="Service Logo" className="imageour" />
//                                     </Grid>
//                                 </div>
//                                 <Grid container item xs={12} className='textmaingrid'>
//                                 <Grid item xs={12} sm={12} className='namegrid'>
//                                     <h2 className="cardheading">{card.name}</h2>
//                                 </Grid>
//                                 <Grid item xs={12} sm={12} className='disgrid'>
//                                     <div className="description1">{card.description}</div>
//                                 </Grid>
//                                 </Grid>
//                             </div>
//                         </Grid>
//                     ))}

// </Slider>
//                 </Grid>
//             </Grid>

//         </div>
//     )
// }

// export default Ourservice

// import React from 'react';
// import './services2.css';
// import { Grid, useMediaQuery } from '@mui/material';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import imag1 from '../../assets/images/ourimage1.png';
// import imag2 from '../../assets/images/ourimage2.png';
// import imag3 from '../../assets/images/ourimage3.png';
// import imag4 from '../../assets/images/ourimage4.png';
// import imag5 from '../../assets/images/cardsliderimg.jpg';

// const Ourservice = () => {
//     const isLargeScreen = useMediaQuery('(max-width:2000px)');
//     const isSmallScreen = useMediaQuery('(max-width:600px)');

//     const cardsData1 = [
//         {
//             logo: imag1,
//             name: 'BP Monitor',
//             description: 'Check BP under 30 sec',
//         },
//         {
//             logo: imag2,
//             name: 'Spo2 Tracker',
//             description: 'Track your oxygen saturation',
//         },
//         {
//             logo: imag3,
//             name: 'Heart rate monitor',
//             description: 'Monitor heart rate levels',
//         },
//         {
//             logo: imag4,
//             name: 'Period tracker',
//             description: 'Track period & ovulation',
//         },
//     ];
// const cardsData2 = [
//     {
//         logo: imag5,
//         name: 'BP Monitor',
//         description: 'Check BP under 30 sec',
//     },
//     {
//         logo: imag5,
//         name: 'BP Monitor',
//         description: 'Check BP under 30 sec',
//     },
//     {
//         logo: imag5,
//         name: 'BP Monitor',
//         description: 'Check BP under 30 sec',
//     },
//     {
//         logo: imag5,
//         name: 'BP Monitor',
//         description: 'Check BP under 30 sec',
//     },
// ];

// const sliderSettings = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: Math.floor(window.innerWidth / 345), // Adjust the value as needed
//     slidesToScroll: 1,
//     centerPadding: '20px',
//     responsive: [
//         {
//             breakpoint: 600,
//             settings: {
//                 slidesToShow: 1,
//                 centerPadding: '0px', // Adjust padding for smaller screens
//             },
//         },
//     ],
// };
//     const sliderSettings1 = {
//         dots: false,
//         infinite: false,
//         speed: 500,
//         slidesToShow: Math.floor(window.innerWidth / 345), // Adjust the value as needed
//         slidesToScroll: 1,
//         centerPadding: '20px',
//         responsive: [
//             {
//                 breakpoint: 1480,
//                 settings: {
//                     slidesToShow: 3.9,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 1450,
//                 settings: {
//                     slidesToShow: 3.5,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 1370,
//                 settings: {
//                     slidesToShow: 3.2,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 1200,
//                 settings: {
//                     slidesToShow: 3,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 1120,
//                 settings: {
//                     slidesToShow: 2.6,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 980,
//                 settings: {
//                     slidesToShow: 2.3,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 875,
//                 settings: {
//                     slidesToShow: 2,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 768,
//                 settings: {
//                     slidesToShow: 1.8,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 708,
//                 settings: {
//                     slidesToShow: 1.5,
//                     centerPadding: '20px', 
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 0,
//                     centerPadding: '20px', 
//                 },
//             },
//         ],

//     };

//     return (
//         <div>
//             <Grid container className="main-container">
//                 <Grid container spacing={2} className="container2">
//                     <Grid item xs={12}>
//                         <h2 className="mainheading">
//                             Our Service Extends Beyond Consultation, Scan & Lab Test
//                         </h2>
//                     </Grid>
//                     <Grid className='gaps124'>
//                     {isSmallScreen && (
//                         <Grid item xs={12}>
//                             <Slider {...sliderSettings}>
//                                 {cardsData2.map((card, index) => (
//                                     <div key={index}>
//                                         <div className="card2">
//                                             <div className="image-container1">
//                                                 <img src={card.logo} alt="Service Logo" className="imageour" />
//                                             </div>
//                                             <div className="textmaingrid">
//                                                 <h2 className="cardheading">{card.name}</h2>
//                                                 <div className="description1">{card.description}</div>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 ))}
//                             </Slider>
//                         </Grid>
//                     )}
//                     </Grid>
//                     <Grid className='gaps123'> 
//                     {isLargeScreen && (
//                         <Grid item xs={12} >
//                             <Slider {...sliderSettings1}>
//                                 {cardsData1.map((card, index) => (
//                                     <Grid item xs={12} sm={6} md={3} key={index} className='gaps'>
//                                         <div className="card2">
//                                             <div className="image-container1">
//                                                 <img src={card.logo} alt="Service Logo" className="imageour" />
//                                             </div>
//                                             <div className="textmaingrid">
//                                                 <h2 className="cardheading">{card.name}</h2>
//                                                 <div className="description1">{card.description}</div>
//                                             </div>
//                                         </div>
//                                     </Grid>
//                                 ))}
//                             </Slider>
//                         </Grid>
//                     )}
//                     </Grid>
//                 </Grid>
//             </Grid>
//         </div>
//     );
// };

// export default Ourservice;


import React from 'react'
import "./services2.css";
import { Button, Grid, useMediaQuery } from "@mui/material";
import imag1 from "../../findDoctor/assets/images/ourimage1.png";
import imag2 from "../../findDoctor/assets/images/ourimage2.png";
import imag3 from "../../findDoctor/assets/images/ourimage3.png";
import imag4 from "../../findDoctor/assets/images/ourimage4.png";


import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


const Ourservice = () => {

    const cardsData1 = [
        {
            logo: imag1,
            name: "BP Monitor",
            description: "Check BP under 30 sec",
        },
        {
            logo: imag2,
            name: "Spo2 Tracker",
            description: "Track your oxygen saturation",
        },
        {
            logo: imag3,
            name: "Heart rate monitor",
            description: "Monitor heart rate levels",
        },
        {
            logo: imag4,
            name: "Period tracker",
            description: "Track period & ovulation",
        },


    ];


    return (
        <div>
            <Grid container className="main-container">
                <Grid container spacing={2} className="container2">
                    <Grid item xs={12}>
                        <h2 className="mainheading">
                            Our Service Extends Beyond Consultation, Scan & Lab Test
                        </h2>
                    </Grid>



                    {cardsData1.map((card, index) => (
                        <Grid item xs={12} sm={12} md={3} mt={2} key={index} className='grid901'>
                            <div className="card2">

                                <div className="image-container1">
                                    <Grid item xs={8} sm={10} className="cardimage1">
                                        <img src={card.logo} alt="Service Logo" className="imageour" />
                                    </Grid>
                                </div>
                                <Grid container item xs={12} ml={1} className='textmaingrid'>
                                    <Grid item xs={12} sm={12} className='namegrid'>
                                        <h2 className="cardheading">{card.name}</h2>
                                    </Grid>
                                    <Grid item xs={12} sm={12} className='disgrid'>
                                        <div className="description1">{card.description}</div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    ))}


                </Grid>
            </Grid>

        </div>
    )
}

export default Ourservice
