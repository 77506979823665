
import { Grid } from "@mui/material";
import React, { useState } from "react";
import "./surgicalspecalist.css"

const SurgicalSpecalistInfo = ({ info, image }) => {
  
  const [showMore, setShowMore] = useState(false);

  return (
    <div>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} md={6} lg={4} className="onlinecontainer2">
          <div>
            <img src={info.image} alt="Logo" className="logoimage" />
          </div>
          <div className="surgerytext">{info.label}</div>
          <div className="surgerycaption">
            {showMore ? info.fullText : info.text}
          </div>
          <button className="readmore" onClick={() => setShowMore(!showMore)}>
            {showMore ? "Read less" : "Read more"}
          </button>
        </Grid>
      </Grid>
    </div>
);
};
  
export default SurgicalSpecalistInfo;
