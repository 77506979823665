import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAllUsers } from "hooks/getAllUsers";
import axios from '../../axiosinstance';
import { APIs } from "Services/APIs";
import { labappCode } from "static/labappCode";
import { labEquipments } from "static/data/labEquipments";

export const labEquipmentListAction = 
   createAsyncThunk("labEquipmentList", async(data, {rejectWithValue}) => {
    console.log(data, labEquipments, "data, labEquipments")
    let url;
  
    try {
      if(data && data.createdby){
        url = APIs.projectServiceApi +`/get?createdby=${data.createdby}&mappingcode=${labappCode.mappingcode}`
      }else if(data && data.url){
        url = data.url
      }else if(data && data.static){
        return labEquipments;
      } else {
        url = APIs.projectServiceApi +`/get?mappingcode=${labappCode.mappingcode}`;
      }
        let respData = await getAllUsers(url)
        console.log(respData, "resp data 20");
        return respData || [];

    } catch (error) {
      return rejectWithValue(error);
    }
  });