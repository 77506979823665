import {
    Button,
    Card,
    FormControlLabel,
    Stack,
    Switch,
    Tooltip,
  } from "@mui/material";
  import FilterCmp from "components/Filters/FilterCmp.tsx";
  import React, { useEffect } from "react";
  import { useState } from "react";
  import { useDispatch } from "react-redux";
  import { useSelector } from "react-redux";
  import { tableDataAction } from "redux/Actions/tableDataAction";
  import { directoryFilter } from "static/filters/directoryFilter";
  import TableCmp from "components/Tables/TableCmp";
  import { APIs } from "Services/APIs";
  import Swal, { SweetAlertOptions } from "sweetalert2";
  import tableActionRow, {
    clearActionOfRow,
    clearActionRowData,
    setActionTypeRow,
  } from "redux/slices/tableActionRow";
  import { useLocation, useNavigate } from "react-router-dom";
  import axios from "../../../axiosinstance";
  import SkeletonFilterCmp from "components/Skeleton/SkeletonFilterCmp";
  import SkeletonTableCmp from "components/Skeleton/SkeletonTableCmp";
  import DashboardLayout from "components/DashboardLayout";
  import MDBox from "components/MDBox";
  import MDTypography from "components/MDTypography";
  import MDButton from "components/MDButton";
  import { deleteDepartmentData } from "redux/Actions/departmentAction";
  import { deleteDesignationData } from "redux/Actions/designationAction";
  import { labappCode } from "static/labappCode";
  import { deleteProjectTeamData } from "redux/Actions/projectTeamAction";
  import { FaRegEdit } from "react-icons/fa";
  import { MdDelete } from "react-icons/md";

import LabProjectteamAssociationPopup from "components/LabMaster/LabProjectMaster/LabProjectTeamAssociation/LabProjectteamAssociationPopup";
import EditlabTeam from "components/LabMaster/LabProjectMaster/LabProjectTeamAssociation/EditlabTeam";
import { ROLE_CODE } from "privateComponents/codes";
import { variables } from "static/Variables";
 

  
  
  
  const TeamAssociationLabList = () => {
    const { tableData, AuthReducer, tableActionRow, getProjectDetails } = useSelector(
      (state) => state
    );
    const { loading } = useSelector((state) => state.tableData);
    const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowData, setRowData] = useState();
    const [filterData, setFilterData] = useState({});
    const [perPage, setPerPage] = useState(5);
    const location = useLocation();
    const [selectedUser, setselectedUser] = useState(null);
    const [openpopup, setopenpopup] = useState(false);
    const [openpopuplabTeam, setopenpopuplabTeam] = useState(false);
    const [selectedProjectTeam, setselectedProjectTeam] = useState(null);
    const nameFilter = new URLSearchParams(location.search).get("name");
    const iconStyleedit = {
      fontSize: "1.5em",
      cursor: "pointer",
      color: "#039147",
    };
    const [projectList, setProjectList] = useState([]);
    const iconStyle = { fontSize: "1.5em", cursor: "pointer", color: "red" };
  
    const filters = [
      {
        type: "text",
        placeholder: "Enter name",
        variant: "outlined",
        label: "Name",
        name: "name",
        page: "allList",
      },
      {
        type: "select",
        placeholder: "Select Project",
        variant: "outlined",
        label: "Select Project",
        name: "selectedProject",
        page: "teamAssociation",
        options: projectList || []
      },
    ];

    console.log(filterData, "filterData 94")
  
    const actionButtons = (row) => {
      return (
        <Stack
          direction="row"
          sx={{ display: "flex", justifyContent: "center", gap: "5px" }}
        >
          {/* <Tooltip title="Edit">
            <div>
              <FaRegEdit
                style={iconStyleedit}
                onClick={() => {
                  dispatch(setActionTypeRow({ type: "edit", data: row }));
                  handleOpenEditDialog(row);
                }}
              />
            </div>
          </Tooltip> */}
          <Tooltip title="Delete">
            <div>
              <MdDelete
                style={iconStyle}
                onClick={() => {
                  // dispatch(setActionTypeRow({ type: "edit", data: row }));
                  onDelete(row);
                }}
              />
            </div>
          </Tooltip>
        </Stack>
      );
    };
  
    useEffect(() => {
      // if (filterData?.name || nameFilter) {
      //   dispatch(
      //     tableDataAction({
      //       url: `${APIs.projectTeamService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
      //         currentPage - 1
      //       }&size=${perPage}&st=${nameFilter || filterData.name}`,
      //     })
      //   );
      // } else {
      //   dispatch(
      //     tableDataAction({
      //       url: `${APIs.projectTeamService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
      //         currentPage - 1
      //       }&size=${perPage}`,
      //     })
      //   );
      // }

      handleTeamListData();
      getProjectList();
    }, [currentPage, perPage]);
  
    const modifiedRows = tableData?.rowData?.dtoList?.map((row) => {
      return {
        ...row,
        department: row?.projectteams?.login?.departmentname
          ? row?.projectteams?.login?.departmentname
          : "",
        designation: row?.projectteams?.login?.designationname
          ? row?.projectteams?.login?.designationname
          : "",
        role: row?.projectteams?.login?.rolename
          ? row?.projectteams?.login?.rolename
          : "",
        name: `${row?.projectteams?.login?.firstname} ${row?.projectteams?.login?.lastname} `,
        action: actionButtons(row),
      };
    });
  
    const columns = [
      { id: "name", label: "User Name" },
      { id: "projectname", label: "Lab Project Name" },
      { id: "department", label: "Department" },
      { id: "designation", label: "Designation" },
      { id: "role", label: "Role" },
      { id: "createdbyname", label: "Created By" },
      { id: "action", label: "Action" },
    ];
  
    const handleSearch = () => {
      setCurrentPage(1);
      handleTeamListData();
      // if (filterData?.name) {
      //   dispatch(
      //     tableDataAction({
      //       url: `${APIs.projectTeamService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${0}&size=${perPage}&st=${filterData.name}`,
      //     })
      //   );
      // }
    };
  
    const handleClear = () => {
      setFilterData({});
      dispatch(
        tableDataAction({
          url: `${APIs.projectTeamService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
            currentPage - 1
          }&size=${perPage}`,
        })
      );
    };
    // const passData = () => {
    //   dispatch(
    //     tableDataAction({
    //       url: `${APIs.projectTeamService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
    //         currentPage - 1
    //       }&size=${perPage}`,
    //     })
    //   );
    // };
    const handleCreate = () => {
      setopenpopuplabTeam(false);
    };
    const handleEdit = () => {
      setopenpopup(false);
    };
    const handleOpenEditDialog = (row) => {
      console.log(row)
      setselectedProjectTeam(row);
      setopenpopup(true);
    };
    function onDelete(row) {
      let newData = {...row, projectteams: {...row.projectteams, login: {...row.projectteams.login, recstatus: variables.approved}}}
    
      swal({
        title: "Are you sure?",
        text: `You will not be able to recover this ${row.projectname}!`,
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          swal("Deleting...", {
            icon: "info",
            buttons: false,
            closeOnClickOutside: false,
          }); 
          dispatch(deleteProjectTeamData(newData))
            .then(() => {
              swal("Deleted!", "Your item has been deleted.", "success");
  
              handleClear();
            })
            .catch((error) => {
              swal(
                "Error",
                "An error occurred while deleting the item.",
                "error"
              );
            });
        } else {
          swal("Cancelled", "Your item is safe.", "info");
        }
      });
    }

    const getProjectList = async () =>{
      let url = auth?.rolecode === ROLE_CODE.admincode ? `${APIs.projectServiceApi}/get` :
      `${APIs.projectServiceApi}/getByPageV2?projectcode=${getProjectDetails?.details?.projecthirarichalcode}&type=${labappCode.mappingcode}`
      await axios.get(url).then((response)=>{
        const data = response?.data?.data?.dtoList?.map((item)=>({...item,value: item.code, label:item.name})) || [];
        setProjectList(data);
      }).catch(error=>{
        setProjectList([])
      })
    }

    const handleTeamListData = () =>{
      // tableDataAction({
      //   url: `${APIs.projectTeamService}/get?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
      //     currentPage - 1
      //   }&size=${perPage}&st=${nameFilter || filterData.name}`,
      // });
      let url ="";

      if(filterData && filterData.selectedProject && filterData.name){
        url= `${APIs.projectTeamService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
          currentPage - 1}&size=${perPage}&projectcode=${filterData.selectedProject}&st=${filterData?.name}`
      } 
      else if(filterData && filterData.selectedProject){
        url= `${APIs.projectTeamService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
          currentPage - 1}&size=${perPage}&projectcode=${filterData.selectedProject}`
      }
      else if(filterData && filterData.name){
        url= `${APIs.projectTeamService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
          currentPage - 1}&size=${perPage}&st=${filterData?.name}`
      }else{
        url= `${APIs.projectTeamService}/getByPageV2?mappingcode=${getProjectDetails?.details?.projecthirarichalcode}&ispageable=true&page=${
          currentPage - 1}&size=${perPage}`
      }
      dispatch(tableDataAction({ url: url }));
      
    };

    return (
      <DashboardLayout>
        {loading ? (
          <Card style={{ marginTop: "-3%" }}>
            <>
              <SkeletonFilterCmp filterArray={filters} />
              <SkeletonTableCmp columns={columns} perPage={perPage} />
            </>
          </Card>
        ) : (
          <Card style={{ marginTop: "-3%" }}>
            <MDBox p={3} lineHeight={1}>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <MDTypography variant="h5" fontWeight="medium">
                  Lab Project Team Association List
                </MDTypography>
                <MDButton
                  color="info"
                  onClick={() => setopenpopuplabTeam(true)}
                >
                  ADD
                </MDButton>
              </div>
            </MDBox>
            <FilterCmp 
              filterArray={filters}
              filterData={filterData}
              setFilterData={setFilterData}
              handleSearch={handleSearch}
              handleClear={handleClear}
            />
            <TableCmp
              columns={columns}
              rows={modifiedRows}
              setCurrentPage={setCurrentPage}
              pageCount={tableData?.rowData?.page?.totalPages}
              setPerPage={setPerPage}
              perPage={perPage}
              currentPage={currentPage}
            />
          </Card>
        )}
        {openpopuplabTeam && (
          <LabProjectteamAssociationPopup
            openpopuplabTeam={openpopuplabTeam}
            setopenpopuplabTeam={setopenpopuplabTeam}
            handleClose={handleCreate}
            passData={handleTeamListData}
          />
        )}
        {openpopup && (
          <EditlabTeam
            openpopup={openpopup}
            setopenpopup={setopenpopup}
            title={"Lab Team Edit"}
            selectedProjectTeam={selectedProjectTeam}
            handleClose={handleEdit}
            passData={handleTeamListData}
          />
        )}
      </DashboardLayout>
    );
  };
  
  export default TeamAssociationLabList;
  