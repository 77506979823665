import { createSlice } from "@reduxjs/toolkit";
import { deleteResource } from "Services/Resources";
import { getResource } from "Services/Resources";
import { createResource } from "Services/Resources";
 
 
const initialState = {
  resource: [],
  loading: false,
  error: null,
};
 
export const resourceDetail = createSlice({
  name: "resourceDetail",
  initialState,
  extraReducers: {
  [createResource.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [createResource.fulfilled]: (state, action) => {
     
      state.loading = false;
      state.resource.push(action.payload.data.dto);
    },
    [createResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      // console.log("API Error slice:", action.payload.message);
    },
    [getResource.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getResource.fulfilled]: (state, action) => {
      state.loading = false;
      state.resource = action.payload.data.dtoList;
 
    },
    [getResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
 
    [deleteResource.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [deleteResource.fulfilled]: (state, action) => {
      state.loading = false;
      console.log(action.payload)
      const { id } = action.payload.data.dto;
      if (id) {
        state.resource = state.resource.filter((resource) => resource.id !== id);
      }
    },
    [deleteResource.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    // [updateRole.pending]: (state) => {
    //   state.loading = true;
    //   state.error = null;
    // },
    // [updateRole.fulfilled]: (state, action) => {
    //   state.loading = false;
      
    
    //   const index = state.role.findIndex(role => role.id === action.payload.data.dto.id)
    //   state.role[index] = action.payload.data.dto;
    // //   state.users.push(action.payload);
    // },
    // [updateRole.rejected]: (state, action) => {
    //   state.loading = false;
    //   state.error = action.payload;
    // },
  },
});
 
export default resourceDetail.reducer;