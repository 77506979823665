import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import DashboardLayout from "components/DashboardLayout";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "../../../../axiosinstance";
import { useNavigate } from "react-router-dom";
import { APIs } from "Services/APIs";
import swal from "sweetalert";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, Grid } from "@mui/material";
import { BarLoader, MoonLoader } from "react-spinners";
import MDButton from "components/MDButton";

const ItemEdit = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [color] = useState("#344767");
  const {
    title,
    selectedUser,
    openpopup,
    setopenpopup,
    handleClose,
    handleEditreset,
  } = props;
  const { auth } = useSelector(({ AuthReducer }) => AuthReducer);
  const [attributes, setAttributes] = useState({ keyList: [] });
  const [loading, setIsLoading] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [itemList, setItemList] = useState([]);
  const [uomlist, setUomlist] = useState([]);
  const [packagelist, setPackagelist] = useState([]);
  const [attFlag, setAttFlag] = useState(false);
  const [uomValue, setUomValue] = useState(null);
  const [packageValue, setPackageValue] = useState(null);
  const [itemTypeValue, setItemTypeValue] = useState(null);

  const validationSchema = Yup.object({
    sapcode: Yup.string().required("SAP code is required."),
    name: Yup.string().required("Name is required."),
    itemtype: Yup.string().required("Item type is required."),
    uom: Yup.string().required("UOM is required."),
    packaging: Yup.string().required("Package type is required."),
  });

  const initialValues = {
    sapcode: selectedUser?.mmucode || "",
    uom: selectedUser?.uom || "",
    itemtype: selectedUser?.type || "",
    name: selectedUser?.name || "",
    packaging: selectedUser?.packaging || "",
  };

  const defaultProps = {
    options: itemList.length === 0 ? [{ name: "Loading...", id: 0 }] : itemList,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsuom = {
    options: uomlist.length === 0 ? [{ name: "Loading...", id: 0 }] : uomlist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };
  const defaultPropsPackage = {
    options:
      packagelist.length === 0 ? [{ name: "Loading...", id: 0 }] : packagelist,
    getOptionLabel: (option) => option.name,
    isOptionEqualToValue: (option, value) => option.id === value.id,
  };

  const onSubmit = async (values) => {
    setLoadings(true);
    try {
      const updatedUser = {
        ...selectedUser,
        mmucode: values.sapcode.trim(),
        uom: values.uom,
        type: values.itemtype,
        name: values.name,
        packaging: values.packaging,
        modifiedby: auth.profileid,
        modifiedbyname: auth.name,
      };

      await axios.put(`${APIs.itemmasterServiceApi}/update`, updatedUser);
      handleClose();
      handleEditreset();
      swal("Success!", "Item updated successfully.", "success");
    } catch (error) {
      swal(
        "Oops!",
        error?.response?.data?.aceErrors[0]?.errorMessage ||
          "Something went wrong.",
        "error"
      );
    } finally {
      setLoadings(false);
    }
  };

  const getData = async () => {
    try {
      setIsLoading(true);
      const itemResponse = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=ITEM`
      );
      setItemList(itemResponse.data.data.dtoList);

      const uomResponse = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=UOM`
      );
      setUomlist(uomResponse.data.data.dtoList);

      const packageResponse = await axios.get(
        `${APIs.typeMasterServiceApi}/get?category=PACKAGE`
      );
      setPackagelist(packageResponse.data.data.dtoList);

      // Set initial autocomplete values
      setItemTypeValue(
        itemResponse.data.data.dtoList.find(
          (item) => item.name === selectedUser?.type
        ) || null
      );
      setUomValue(
        uomResponse.data.data.dtoList.find(
          (item) => item.name === selectedUser?.uom
        ) || null
      );
      setPackageValue(
        packageResponse.data.data.dtoList.find(
          (item) => item.name === selectedUser?.packaging
        ) || null
      );
      setIsLoading(false);
    } catch (error) {
      console.log("Error fetching data", error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <DashboardLayout>
      <Dialog open={openpopup} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle
          style={{
            backgroundColor: "#039147",
            color: "#fff",
            textAlign: "center",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "30vh",
              }}
            >
              <CircularProgress
                style={{ position: "absolute", top: "50%", left: "50%" }}
              />
            </div>
          ) : (
            <>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ values, handleChange, setFieldValue, errors, touched }) => (
                  <Form>
                    <MDBox mt={1.625}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              Name
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="name"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <MDInput
                            type="text"
                            label=<span>
                              SAP code
                              <sup
                                style={{
                                  color: "red",
                                  fontSize: "small",
                                  fontWeight: "bolder",
                                  position: "relative",
                                  top: "2px",
                                }}
                              >
                                {" "}
                                *
                              </sup>
                            </span>
                            variant="standard"
                            fullWidth
                            name="sapcode"
                            value={values.sapcode.trim()}
                            onChange={handleChange}
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <ErrorMessage
                              name="sapcode"
                              component="div"
                              style={{
                                color: "red",
                                fontSize: "12px",
                                textAlign: "left",
                                marginTop: "4px",
                              }}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsPackage}
                            value={packageValue}
                            onChange={(event, newValue) => {
                              setPackageValue(newValue);
                              setFieldValue("packaging", newValue?.name || "");
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={errors.packaging && touched.packaging}
                                  success={
                                    packageValue !== null && !errors.packaging
                                  }
                                  label=<span>
                                    Package Type
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="packaging"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultProps}
                            value={itemTypeValue}
                            onChange={(event, newValue) => {
                              setItemTypeValue(newValue);
                              setFieldValue("itemtype", newValue?.name || "");
                              setAttributes((prev) => ({
                                ...prev,
                                keyList: newValue?.attributes || [],
                              }));
                              setAttFlag(!!newValue?.attributes);
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={errors.itemtype && touched.itemtype}
                                  success={
                                    itemTypeValue !== null && !errors.itemtype
                                  }
                                  label=<span>
                                    Item Type
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="itemtype"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Autocomplete
                            {...defaultPropsuom}
                            value={uomValue}
                            onChange={(event, newValue) => {
                              setUomValue(newValue);
                              setFieldValue("uom", newValue?.name || "");
                            }}
                            renderInput={(params) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                }}
                              >
                                <MDInput
                                  {...params}
                                  variant="standard"
                                  error={errors.uom && touched.uom}
                                  success={uomValue !== null && !errors.uom}
                                  label=<span>
                                    UOM
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "small",
                                        fontWeight: "bolder",
                                        position: "relative",
                                        top: "2px",
                                      }}
                                    >
                                      {" "}
                                      *
                                    </sup>
                                  </span>
                                />
                                <ErrorMessage
                                  name="uom"
                                  component="div"
                                  style={{
                                    color: "red",
                                    fontSize: "12px",
                                    textAlign: "left",
                                    marginTop: "4px",
                                  }}
                                />
                              </div>
                            )}
                          />
                        </Grid>
                      </Grid>
                      {attFlag && (
                        <Grid container spacing={3}>
                          {attributes.keyList.map((att) => (
                            <Grid item xs={12} sm={3} key={att}>
                              <MDInput
                                type="text"
                                label={att}
                                name={att}
                                value={values[att] || ""}
                                onChange={handleChange}
                              />
                            </Grid>
                          ))}
                        </Grid>
                      )}
                    </MDBox>
                    <DialogActions>
                      <MDButton
                        onClick={handleClose}
                        color="light"
                        size="small"
                      >
                        Cancel
                      </MDButton>

                      <MDButton type="submit" color="info" size="small">
                        {loadings ? (
                          <MoonLoader color="#f2fefa" size={16} />
                        ) : (
                          "Update"
                        )}
                      </MDButton>
                    </DialogActions>
                  </Form>
                )}
              </Formik>
            </>
          )}
        </DialogContent>
      </Dialog>
    </DashboardLayout>
  );
};

export default ItemEdit;
