
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../../../axiosinstance";
//create User

export const createDistrict = createAsyncThunk("createDistrict", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.post(
      `pincode-service/api/pincode/v1/create`,
      data
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const getDistrict = createAsyncThunk("getDistrict", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.get(
      `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${data}`,
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
// export const getDistrict = createAsyncThunk("getDistrict", async (data, {rejectWithValue}) => {
//   console.log("formData", data);

//   try {
//     if(data===undefined){
//       const response = await axios.get(
//         `pincode-service/api/pincode/v1/get?type=DISTRICT`,
       
//       );
//       return await response.data;
//     }
//     else{
//       const response = await axios.get(
//         `pincode-service/api/pincode/v1/get?type=DISTRICT&state.code=${data}`,
       
//       );
//       return await response.data;
//     }
   
   
//   } catch (error) {
//     return rejectWithValue(error);
//   }
// });
export const updateDistrict= createAsyncThunk("updateUser", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `pincode-service/api/pincode/v1/update`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});
export const deleteDistrict = createAsyncThunk("deleteDistrict", async (data, {rejectWithValue}) => {
  console.log("formData", data);

  try {
    const response = await axios.put(
      `pincode-service/api/pincode/v1/delete`,
      data
     
    );
    return await response.data;
  } catch (error) {
    return rejectWithValue(error);
  }
});




