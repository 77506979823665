import * as Yup from "yup";
import checkout from "../schemas/form";
const {
  formField: {
    comisssion,
    commissiontype,
    investmentterm,
    amount,
    transationNo,
    enddate,
    startdate,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [commissiontype.name]: Yup.string().required(commissiontype.errorMsg),
    [startdate.name]: Yup.string().required(startdate.errorMsg),

    [amount.name]: Yup.string().required(amount.errorMsg),
    [comisssion.name]: Yup.string().required(comisssion.errorMsg),
    [investmentterm.name]: Yup.string().required(investmentterm.errorMsg),
    [transationNo.name]: Yup.string().required(transationNo.errorMsg),

    // Adding validation for enddate greater than startdate
    [enddate.name]: Yup.string()
      .required(enddate.errorMsg)
      .test(
        "is-greater-than-startdate",
        "End date must be greater than start date",
        function (value) {
          const startDateValue = this.parent[startdate.name];
          return new Date(value) > new Date(startDateValue);
        }
      ),
  }),
];

export default validations;
