import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Section1 from "./Section1";
import "./navbar.css";
import Section2 from "./Section2";
import { Link } from "react-router-dom";

const FirstPage = ({ pagelabel }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [indicatorPosition, setIndicatorPosition] = useState({
    left: 0,
    width: 0,
  });

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1300);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1300);
    };
    window.addEventListener("resize", handleResize);
    const initialSelectedItem =
      data.find((item) => item.label === pagelabel) ||
      data2.find((item) => item.label === pagelabel);
    if (initialSelectedItem) {
      const targetElement = document.querySelector(
        `.section2item4[data-label="${initialSelectedItem.label}"]`
      );
      if (targetElement) {
        setIndicatorPosition({
          left: targetElement.offsetLeft,
          width: targetElement.offsetWidth,
        });
      }
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [pagelabel]);

  const data = [
    {
      label: "Find Doctors",
      navigate: "/app/home/find-doctors",
    },
    {
      label: "Online Consultation",
      navigate: "/app/pages/users/online-consult",
    },
    {
      label: "Lab Test",
      navigate: "/app/pages/users/lab-test",
    },
    {
      label: "Medicines",
      navigate: "/app/pages/users/medicine",
    },
    {
      label: "Medharva Care",
      navigate: "/app/pages/users/medharva-care",
    },
    {
      label: "For Corporates",
      navigate: "/app/pages/users/for-corporates",
    },
    {
      label: "For Providers",
      navigate: "/app/pages/users/for-providers",
    },
  ];
  const data2 = [
    // {
    //   label: "Lab Test",
    //   navigate: "https://medharva.com/lab-test-new/",
    // },
    // {
    //   label: "Medicines",
    //   navigate: "https://medharva.com/medicine-new/",
    // },
    // {
    //   label: "Medharva Care",
    //   navigate: "https://medharva.com/medharva-care/",
    // },
    // {
    //   label: "For Corporates",
    //   navigate: "https://medharva.com/for-corporate/",
    // },
    // {
    //   label: "For Providers",
    //   navigate: "https://medharva.com/for-provider/",
    // },
  ];

  return (
    <div className="rootnavbar">
      <Grid container className="navbarcontainer1">
        <Section1 />
      </Grid>
      <Grid container mt={3}>
        <Section2 />
      </Grid>
      <Grid container className="navbarcontainer3">
        <Grid item xs={11}>
          <hr className="navbarseperator" />
        </Grid>
        <Grid
          item
          xs={isSmallScreen ? 12 : 10}
          mt={0} 
          mb={1.5}
          className="section2item3"
        >
          {data.map((item, index) => (
            <Link
              to={item.navigate}
              key={index}
              data-label={item.label}
              className="section2item4"
            >
              {item.label}
            </Link>
          ))}
          {data2.map((item, index) => (
            <a
              href={item.navigate}
              key={index}
              data-label={item.label}
              className="section2item4"
            >
              {item.label}
            </a>
          ))}
          {pagelabel && (
            <div
              className="indicator"
              style={{
                left: indicatorPosition.left,
                width: indicatorPosition.width,
              }}
            ></div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default FirstPage;

// import React, { useEffect, useState } from "react";
// import Grid from "@mui/material/Grid";
// import Section1 from "./Section1";
// import "./navbar.css";
// import Section2 from "./Section2";

// const FirstPage = () => {
//   const [selectedItem, setSelectedItem] = useState(null);
//   const [indicatorPosition, setIndicatorPosition] = useState({
//     left: 0,
//     width: 0,
//   });

//   const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1300);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth <= 1300);
//     };
//     window.addEventListener("resize", handleResize);
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const data = [
//     {
//       label: "Find Doctors",
//       navigate: "#",
//     },
//     {
//       label: "Online Consultation",
//       navigate: "#",
//     },
//     {
//       label: "Lab Test",
//       navigate: "#",
//     },
//     {
//       label: "Medicines",
//       navigate: "#",
//     },
//     {
//       label: "Medharva Care",
//       navigate: "#",
//     },
//     {
//       label: "For Corporates",
//       navigate: "#",
//     },
//     {
//       label: "For Providers",
//       navigate: "#",
//     },
//   ];

//   const handleItemClick = (index, e) => {
//     setSelectedItem(index);
//     const target = e.target;
//     setIndicatorPosition({
//       left: target.offsetLeft,
//       width: target.offsetWidth,
//     });
//   };

//   return (
//     <>
//       <Grid container className="navbarcontainer1">
//         <Section1 />
//       </Grid>
//       <Grid container mt={3}>
//         <Section2 />
//       </Grid>
//       <Grid container className="navbarcontainer3">
//         <Grid item xs={11}>
//           <hr className="navbarseperator" />
//         </Grid>
//         <Grid item xs={isSmallScreen ? 12 : 10} mt={0} className="section2item3">
//           {data.map((item, index) => (
//             <a
//               href={item.navigate}
//               key={index}
//               onClick={(e) => handleItemClick(index, e)}
//               className="section2item4"
//             >
//               {item.label}
//             </a>
//           ))}
//           <div
//             className="indicator"
//             style={{
//               left: indicatorPosition.left,
//               width: indicatorPosition.width,
//             }}
//           ></div>
//         </Grid>
//       </Grid>
//     </>
//   );
// };

// export default FirstPage;
