// import { persistor } from "redux/store";
import swal from "sweetalert";
const initialState = {
  auth: localStorage.getItem("login-auth")
    ? JSON.parse(localStorage.getItem("login-auth"))
    : {},
  Username: localStorage.getItem("profile-Name")
    ? JSON.parse(localStorage.getItem("profile-Name"))
    : {},

  // Investment: localStorage.getItem("investment-profile") ? JSON.parse(localStorage.getItem("investment-profile")) : {},
  isAuthenticated: localStorage.getItem("login-auth") ? true : false,

  errors: {},

  loading: false,
};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_AUTH_DATA":
      return {
        ...state,
        auth: action.payload,
      };

    case "LOGIN_PENDING":
      return { loading: true };

    case "LOGIN_FULFILLED":
      swal("Hello User!", "You have successfully logged in.", "success");

      if (action.payload?.data?.dto !== "") {
        localStorage.setItem(
          "login-auth",
          JSON.stringify(action.payload?.data?.dto)
        );
        localStorage.setItem(
          "profile-Name",
          JSON.stringify(action.payload?.data?.dto?.name)
        );

        // const iframe = document.createElement('iframe');
        // iframe.src = "https://medharva.com/";
        // iframe.style.display = "none";

        // window.addEventListener("load", function () {
        //     const iframeWindow = iframe.contentWindow;
        //     const data = {
        //         name: action.payload?.data?.dto?.name
        //     };

        //     iframeWindow.postMessage(JSON.stringify(data), "*");
        // });

        // document.body.appendChild(iframe);

        // const iframe = document.createElement("iframe");
        // iframe.src = "https://medharva.com/";
        // iframe.style.display = "none";

        // iframe.onload = function () {
        //   const iframeWindow = iframe.contentWindow;
        //   const data = {
        //     name: action.payload?.data?.dto?.rolecode,
        //   };

        //   iframeWindow.postMessage(JSON.stringify(data), "*");
        // };

        // document.body.appendChild(iframe);

        const iframe = document.createElement("iframe");
        iframe.src = "https://medharva.com/";
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        // Event listener for iframe load
        iframe.onload = function () {
          const iframeWindow = iframe.contentWindow;
          const data = {
            name: action.payload?.data?.dto?.rolecode,
          };

          // Post message to the iframe
          iframeWindow.postMessage(JSON.stringify(data), "*");
        };

        return {
          auth: action.payload?.data?.dto,
          isAuthenticated: true,
          loading: false,
        };
      } else {
        swal("Alert!", "...Internal Server Error!");
        localStorage.removeItem("login-auth");
        localStorage.removeItem("profile-Name");
        localStorage.removeItem("user-code");
        localStorage.removeItem("investment-profile");
        localStorage.removeItem("address");
        return {
          auth: {},
          Username: {},
          //  Investment:{},
          isAuthenticated: false,
        };
      }
    case "LOGIN_REJECTED":
      swal(
        "Alert!",
        action.payload?.aceErrors
          ? action?.payload?.aceErrors[0]?.errorMessage
          : action.payload
      );

      localStorage.removeItem("login-auth");
      localStorage.removeItem("profile-Name");
      localStorage.removeItem("user-code");
      localStorage.removeItem("admindoctor-code");
      localStorage.removeItem("investment-profile");
      localStorage.removeItem("doctor-code");
      localStorage.removeItem("dynamic-navigation");
      localStorage.removeItem("filterValue");
      localStorage.removeItem("address");
      return {
        auth: {},
        Username: {},
        //  Investment:{},
        isAuthenticated: false,
        errors: action.payload,
        loading: false,
      };

    case "LOGOUT_FULFILLED":
      localStorage.removeItem("login-auth");
      localStorage.removeItem("profile-Name");
      localStorage.removeItem("doctorprofileid");
      localStorage.removeItem("user-code");
      localStorage.removeItem("admindoctor-code");
      localStorage.removeItem("investment-profile");
      localStorage.removeItem("doctor-code");
      localStorage.removeItem("dynamic-navigation");
      localStorage.removeItem("filterValue");
      localStorage.removeItem("address");
      localStorage.removeItem('persist:root');
      localStorage.clear(); 
      console.log("log out fulfilled is called.")
      swal({
        title: "Logged Out",
        text: "You have been successfully logged out.",
        icon: "success",
        confirmButtonText: "OK",
      });

      const iframe = document.createElement("iframe");
      iframe.src = "https://medharva.com/";
      iframe.style.display = "none";

      iframe.onload = function () {
        const iframeWindow = iframe.contentWindow;
        const data = {
          name: null,
        };

        iframeWindow.postMessage(JSON.stringify(data), "*");
      };

      document.body.appendChild(iframe);

      // persistor.purge(); 
      return {
        auth: {},
        Username: {},
        //  Investment:{},
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default AuthReducer;
