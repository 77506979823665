import React from "react";
import "./centerstyle.css";
import TextField from "@mui/material/TextField";
import filter2 from '../../findDoctor/assets/images/cardiology-svgrepo-com 1.svg';
import filter3 from '../../findDoctor/assets/images/face-recognition-1-svgrepo-com 1.svg';

const categorydata = [
    { name:"Cardiology", src1:filter2 },
    { name:"Endocrinology", src1:filter3 },
    
];
const CategoryDropdown = ({ onSelect }) => {
   

    return (
        <div className="category11" >

            <div className="frame11">
                <div className="div11">
                    <TextField
                        placeholder="Search for centres"
                        variant="outlined"
                        fullWidth
                        
                        
                        classes={{ root: "div11" }}
                        InputProps={{
                            className: "div11",
                            style: {
                                border: "2px solid black",
                                borderRadius: "50px",

                            },
                        }}
                    />
                </div>
            </div>
            {categorydata.map((slot, index) => (
            <div className="frame-211">
                <div className="text-wrapper-211">{slot.name}</div>
                <img className="img11"  src={slot.src1} />
            </div>
               ))}
          
        </div>
    );
};

export default CategoryDropdown;
